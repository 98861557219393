import { 
    //Address
    ADDRESS_GET,
    ADDRESS_GET_MY,
    ADDRESS_POST,
    ADDRESS_PUT,
    ADDRESS_DELETE,

    STREET_ADDRESS,
    LOCALITY_ADDRESS,
    REGION_ADDRESS,
    POSTAL_CODE_ADDRESS,
    COUNTRY_NAME_ADDRESS,
    POP_UP_ADDRESS,
    POP_UP_ADDRESS_ID,
    SET_POP_UP_ADDRESS_ERROR,
    SET_POP_UP_ADDRESS_COMPLITE,

    KOATUU_REGION,
    KOATUU_GOROD,
} from "../../types";

import {getAPI, postAPI, putAPI, deleteAPI} from '../crud';
import {setLocal} from '../support';

//Address
export function addressPost(_url, token, datas){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/address`,
        token: token,
        actionType: ADDRESS_POST,
        data: datas,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return postAPI(data);
}

export function addressGetId(_url, token){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/address/${_url.id}`,
        token: token,
        actionType: ADDRESS_GET,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function addressPut(_url, token, datas){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/address/${_url.id}`,
        token: token,
        actionType: ADDRESS_PUT,
        data: datas,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return putAPI(data);
}

export function addressDelete(_url, token){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/address/${_url.id}`,
        token: token,
        actionType: ADDRESS_DELETE,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return deleteAPI(data);
}

export function addressesGetMy(_url, token){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/private/addresses/my${_url.params}`,
        actionType: ADDRESS_GET_MY,
        header: token
    }
    return getAPI(data);
}

///////////////
export function streetAddress(value) {
    return {
        type: STREET_ADDRESS,
        payload: value
    }
}

export function localityAddress(value) {
    return {
        type: LOCALITY_ADDRESS,
        payload: value
    }
}

export function regionAddress(value) {
    return {
        type: REGION_ADDRESS,
        payload: value
    }
}

export function postalCodeAddress(value) {
    return {
        type: POSTAL_CODE_ADDRESS,
        payload: value
    }
}

export function countryNameAddress(value) {
    return {
        type: COUNTRY_NAME_ADDRESS,
        payload: value
    }
}

export function popUpAddress(value) {
    return {
        type: POP_UP_ADDRESS,
        payload: value
    }
}

export function popUpAddressId(value) {
    return {
        type: POP_UP_ADDRESS_ID,
        payload: value
    }
}

export function setPopAddressError(value) {
    return {
        type: SET_POP_UP_ADDRESS_ERROR,
        payload: value
    }
}

export function setPopAddressComplite(value) {
    return {
        type: SET_POP_UP_ADDRESS_COMPLITE,
        payload: value
    }
}

export function setKoatuuRegion(value) {
    return {
        type: KOATUU_REGION,
        payload: value
    }
}

export function setKoatuuGorod(value) {
    return {
        type: KOATUU_GOROD,
        payload: value
    }
}