import React from 'react';
import styles from './etc.module.css';

import {
    OTHER_PROPERTY_CATEGORY_MENU_LANG,
} from '../../../../model/lang/iconCategoryMenu.js';
import {connect} from 'react-redux';

const Etc = ( {langFlag} ) => {
    return (
        <div className={styles.aukpoisk_second_elem}>
            <a href='https://www.dto.com.ua/tenders/current/etc'>
            <div className={styles.base}>
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 512 512" xmlSpace="preserve">
<g>
	<g>
		<path d="M506.57,92.315L262.982,0.776c-1.552-0.776-3.879-0.776-5.43,0L5.43,92.315C2.327,93.091,0,96.194,0,99.297v72.921
			c0,3.103,2.327,6.206,4.655,6.982l11.636,4.655v224.97c0,3.103,1.552,6.206,4.655,6.982l235.83,95.418
			c1.552,0.776,3.879,0.776,5.43,0l227.297-87.661c3.103-0.776,4.655-3.879,4.655-6.982v-230.4l12.412-4.655
			c3.103-1.552,4.654-3.879,4.654-6.982V99.297c0.776,0,0.776,0,0.776,0C512,96.194,509.673,93.091,506.57,92.315z M252.121,492.606
			L31.806,403.394V190.061l220.315,84.558V492.606z M252.121,258.327L15.515,167.564v-56.63l236.606,92.315V258.327z
			 M259.879,189.285l-230.4-89.212l230.4-83.782l222.642,83.782C397.188,134.206,341.333,156.703,259.879,189.285z M479.418,411.152
			l-211.782,81.455V275.394l211.782-83.782V411.152z M496.485,168.339c-19.394,7.758-205.576,81.455-228.849,90.764v-55.855
			l228.849-92.315V168.339z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
</div>
                <div className={styles.aukpoisk_second_elem_text}>{OTHER_PROPERTY_CATEGORY_MENU_LANG[langFlag]}</div>
            </a>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        aucyionsPath: state.start.aucyionsPath,
        search: state.start.search,
        token: state.start.token,
        searchCount: state.start.searchCount,
    }
}

//const mapDispatchToProps = {}

export default connect(mapStateToProps, null)(Etc)