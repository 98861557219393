import React, {useState, useEffect} from 'react';
import styles from './multiChbox.module.css';

const InputCheckbox = (props) => {

    const [checkBox, setCheckBox] = useState(props.checked === true ? props.checked : false);
    const [checkBoxStyle, setCheckBoxStyle] = useState(props.checked ? styles.checkBoxOn : styles.checkBox);

    function checkBoxToggle() {
        if (checkBoxStyle === styles.checkBox) {
            setCheckBoxStyle(styles.checkBoxOn);
            setCheckBox(true);
            if (props.onChange) {
                props.onChange(true);
            }
        } else {
            setCheckBoxStyle(styles.checkBox);
            setCheckBox(false);
            if (props.onChange) {
                props.onChange(false);
            }
        }
    }

    return (
        <>
            <div className={styles.inPutInfo}>
                <div className={styles.chBox} onClick={checkBoxToggle}>
                    <div className={checkBoxStyle}></div>
                    <div className={styles.chBoxLabel}>
                        {props.label}
                    </div>
                </div>
            </div>
            {props.descr ? <div className={styles.descript}>{props.descr}</div> : ''}
        </>
    )
}


export default InputCheckbox