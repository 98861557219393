import React from 'react';
import styles from './lotInformation.module.css';

import {connect} from 'react-redux';

import {reverseStr, createApostrof} from '../../../model/summNumberToSreang.js';

import TextHalf from './textHalf'
import TextFull from './textFull'

import AccessDetailsString from './auctionElements/accessDetails'
import PreviousAuctionIdString from './auctionElements/previousAuctionId'
import NormativeMonetaryValuationString from './auctionElements/normativeMonetaryValuation'
import ExpertMonetaryValuationString from './auctionElements/expertMonetaryValuation'
import BudgetSpentString from './auctionElements/budgetSpent'
import RegistrationFeeString from './auctionElements/registrationFee'
import ValueAddedTaxChargedString from './auctionElements/valueAddedTaxCharged'
import LeaseDurationString from './auctionElements/leaseDuration'
import AuctionUrlString from './auctionElements/auctionUrl'
import GuaranteeString from './auctionElements/guarantee'
import MinimalStepString from './auctionElements/minimalStep_old'
import XAdditionalInformationString from './auctionElements/xAdditionalInformation'
import MinNumberOfQualifiedBidsString from './auctionElements/minNumberOfQualifiedBids'
import SellingMethodString from './auctionElements/sellingMethod'
import LotIdentifierString from './auctionElements/lotIdentifier'
import TenderAttemptsString from './auctionElements/tenderAttempts'
import AuctionIdString from './auctionElements/auctionId'
import DescriptionString from './auctionElements/description'
import ValueString from './auctionElements/value'
import LeaseTypeString from './auctionElements/leaseType'
import SaleTypeString from './auctionElements/saleType'
import ConditionsString from './auctionElements/conditions'
import RestrictionsString from './auctionElements/restrictions'
import LotCurrentTenantString from './auctionElements/currentTenant'
import LotPreviousAuctionBidderString from './auctionElements/previousAuctionBidder'
import XPlatformLegalDetailsString from './auctionElements/xPlatformLegalDetailsNew'
import XDocumentRequirements from './auctionElements/xDocumentRequirements'
import NonRemovable from './auctionElements/nonRemovable'
import DatePublished from './auctionElements/datePublished'
import DateModified from './auctionElements/dateModified'

import SlimmerBlock from '../../elements/slimmerBlock/slimmerBlock';
import {viewStringValue} from '../../../api/valueToString.js'

const LotInformation = (props) => {
    let tmp = <>
        <ValueString />
        <DescriptionString />
        <AuctionIdString />
        <TenderAttemptsString />
        <LotIdentifierString />
        <SellingMethodString />
        <XAdditionalInformationString />
        <MinNumberOfQualifiedBidsString />
        <MinimalStepString />
        <GuaranteeString />
        <AccessDetailsString />
        <XDocumentRequirements />
        {strAuctionUrl(props)}
        {strDutchStepQuantity(props)}
        {strDutchStepPercent(props)}
        {strDiscountPreviousAuctionValue(props)}
        {strDiscountPercent(props)}
        {strPreviousAuctionId(props)}
        {strDutchStepValue(props)}

        <div className={styles.lotGaranty}>
            <p className={styles.desc}>Вартість геологічної інформації</p>
            <p>{geologicalValue(props)}</p>
        </div>
        <div className={styles.lotGaranty}>
            <p className={styles.desc}>Вартість пакета аукціонної документації, що сплачується переможцем</p>
            <p>{auctionDocsValue(props)}</p>
        </div>
        <div className={styles.lotGaranty}>
            <p className={styles.desc}>Строк на який надається дозвіл</p>
            <p>{auctionRentPeriod(props)}</p>
        </div>
    </>
    return (
        <SlimmerBlock
            title='Інформація про лот'
            content={tmp}
            slim={true}
        />
    )
}

function auctionRentPeriod(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('leaseDuration') === true){
            if(props.auctionId.leaseDuration !== null){
                return (
                    <>
                        {transformData(props.auctionId.leaseDuration, 'Y')}
                        {transformData(props.auctionId.leaseDuration, 'M')}
                        {transformData(props.auctionId.leaseDuration, 'D')}
                    </>
                )
            }
        }
    }
    return null
}

function transformData(data, type){
    switch(type){
        case 'Y':
            if(data.indexOf('Y') !== -1){
                let tmpArr = data.split('P')
                let tmpArr1 = tmpArr[1].split('Y')
                if(tmpArr1[0].indexOf('1') !== -1){
                    return tmpArr1[0] + ' рік'
                }else if(
                    tmpArr1[0].indexOf('2') !== -1 ||
                    tmpArr1[0].indexOf('3') !== -1 ||
                    tmpArr1[0].indexOf('4') !== -1
                ){
                    return tmpArr1[0] + ' роки'
                }else{
                    return tmpArr1[0] + ' років'
                }
            }
        case 'M':
            if(data.indexOf('M') !== -1){
                if(data.indexOf('Y') !== -1){
                    let tmpArr = data.split('Y')
                    let tmpArr1 = tmpArr[1].split('M')
                    if(tmpArr1[0].indexOf('1') !== -1){
                        return ' ' + tmpArr1[0] + ' місяць'
                    }else if(
                        tmpArr1[0].indexOf('2') !== -1 ||
                        tmpArr1[0].indexOf('3') !== -1 ||
                        tmpArr1[0].indexOf('4') !== -1
                    ){
                        return ' ' + tmpArr1[0] + ' місяця'
                    }else if(
                        tmpArr1[0].indexOf('4') !== -1
                    ){
                        return ' ' + tmpArr1[0] + ' місяці'
                    }else{
                        if(tmpArr1[0] !== '0') return ' ' + tmpArr1[0] + ' місяців'
                    }
                }else{
                    let tmpArr = data.split('P')
                    let tmpArr1 = tmpArr[1].split('M')
                    if(tmpArr1[0].indexOf('1') !== -1){
                        return ' ' + tmpArr1[0] + ' місяць'
                    }else if(
                        tmpArr1[0].indexOf('2') !== -1 ||
                        tmpArr1[0].indexOf('3') !== -1 ||
                        tmpArr1[0].indexOf('4') !== -1
                    ){
                        return ' ' + tmpArr1[0] + ' місяця'
                    }else if(
                        tmpArr1[0].indexOf('4') !== -1
                    ){
                        return ' ' + tmpArr1[0] + ' місяці'
                    }else{
                        if(tmpArr1[0] !== '0') return ' ' + tmpArr1[0] + ' місяців'
                    }
                }
            }
        case 'D':
            if(data.indexOf('D') !== -1){
                if(data.indexOf('M') !== -1){
                    let tmpArr = data.split('M')
                    let tmpArr1 = tmpArr[1].split('D')
                    if(tmpArr1[0].indexOf('1') !== -1){
                        return ' ' + tmpArr1[0] + ' день'
                    }else if(
                        tmpArr1[0].indexOf('2') !== -1 ||
                        tmpArr1[0].indexOf('3') !== -1 ||
                        tmpArr1[0].indexOf('4') !== -1
                    ){
                        return ' ' + tmpArr1[0] + ' дні'
                    }else{
                        if(tmpArr1[0] !== '0') return ' ' + tmpArr1[0] + ' днів'
                    }
                }else if(data.indexOf('Y') !== -1){
                    let tmpArr = data.split('Y')
                    let tmpArr1 = tmpArr[1].split('D')
                    if(tmpArr1[0].indexOf('1') !== -1){
                        return ' ' + tmpArr1[0] + ' день'
                    }else if(
                        tmpArr1[0].indexOf('2') !== -1 ||
                        tmpArr1[0].indexOf('3') !== -1 ||
                        tmpArr1[0].indexOf('4') !== -1
                    ){
                        return ' ' + tmpArr1[0] + ' дні'
                    }else{
                        if(tmpArr1[0] !== '0') return ' ' + tmpArr1[0] + ' днів'
                    }
                }else{
                    let tmpArr = data.split('P')
                    let tmpArr1 = tmpArr[1].split('D')
                    if(tmpArr1[0].indexOf('1') !== -1){
                        return ' ' + tmpArr1[0] + ' день'
                    }else if(
                        tmpArr1[0].indexOf('2') !== -1 ||
                        tmpArr1[0].indexOf('3') !== -1 ||
                        tmpArr1[0].indexOf('4') !== -1
                    ){
                        return ' ' + tmpArr1[0] + ' дні'
                    }else{
                        if(tmpArr1[0] !== '0') return ' ' + tmpArr1[0] + ' днів'
                    }
                }
            }
    }
    return null
}

function auctionDocsValue(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('documentationValue') === true){
            if(props.auctionId.documentationValue !== null){
                if(props.auctionId.documentationValue.amount !== null){
                    let tmp = viewStringValue(props.auctionId.documentationValue)
                    return tmp
                }
            }
        }
    }
    return null
}

function geologicalValue(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('geologicDataValue') === true){
            if(props.auctionId.geologicDataValue !== null){
                if(props.auctionId.geologicDataValue.amount !== null){
                    let tmp = viewStringValue(props.auctionId.geologicDataValue)
                    return tmp
                }
            }
        }
    }
    return null
}

function strDutchStepValue(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('dutchStep') === true){
            if(props.auctionId.dutchStep !== null){
                if(props.auctionId.dutchStep.dutchStepValue !== null){
                    if(props.auctionId.dutchStep.dutchStepValue.amount !== null){
                        let tmp = viewStringValue(props.auctionId.dutchStep.dutchStepValue)
                        return (
                            <div className={styles.lotGaranty}>
                                <p className={styles.desc}>Розмір кроку голландського раунду</p>
                                <p>{tmp}</p>
                            </div>
                        )
                    }
                }
            }
        }
    }
    return null
}

function strPreviousAuctionId(props){
    if(props.auctionId !== null){
        if(props.auctionId.previousAuctionId !== null){
            return (
                <div className={styles.lotGaranty}>
                    <p className={styles.desc}>Попередній аукціон</p>
                    <p>{props.auctionId.previousAuctionId}</p>
                </div>
            )
        }
    }
    return null
}

function strDiscountPercent(props){
    if(props.auctionId !== null){
        if(props.auctionId.discount !== null){
            if(props.auctionId.discount.discountPercent !== null){
                return (
                    <div className={styles.lotGaranty}>
                        <p className={styles.desc}>Знижка</p>
                        <p>{`${props.auctionId.discount.discountPercent} %`}</p>
                    </div>
                )
                
            }
        }
    }
    return null
}

function strDiscountPreviousAuctionValue(props){
    if(props.auctionId !== null){
        if(props.auctionId.discount !== null){
            if(props.auctionId.discount.discount !== null && props.auctionId.discount.discount === true){
                if(props.auctionId.discount.previousAuctionValue !== null){
                    let tmp = viewStringValue(props.auctionId.discount.previousAuctionValue)
                    return (
                        <div className={styles.lotGaranty}>
                            <p className={styles.desc}>Сума попереднього аукціона</p>
                            <p>{tmp}</p>
                        </div>
                    )
                }
            }
        }
    }
    return null
}

function strDutchStepPercent(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('dutchStep') === true){
            if(props.auctionId.dutchStep !== null){
                return (
                    <div className={styles.lotGaranty}>
                        <p className={styles.desc}>Відсоток зниження, %</p>
                        <p>{props.auctionId.dutchStep.dutchStepPercent}</p>
                    </div>
                )
            }
        }
    }
    return null
}

function strDutchStepQuantity(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('dutchStep') === true){
            if(props.auctionId.dutchStep !== null){
                return (
                    <div className={styles.lotGaranty}>
                        <p className={styles.desc}>Кількість кроків</p>
                        <p>{props.auctionId.dutchStep.dutchStepQuantity}</p>
                    </div>
                )
            }
        }
    }
    return null
}

function strAuctionUrl(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('auctionUrl') === true){
            if(props.auctionId.auctionUrl !== null){
                return (
                    <TextFull
                        title="Веб-адреса для перегляду аукціону"
                        data={props.auctionId.auctionUrl}
                    />
                )
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(LotInformation)