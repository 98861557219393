import React from 'react';
import styles from './togetherAPI_auc.module.css';

import Button from '@material-ui/core/Button';

import {connect} from 'react-redux';

import {setPopUpBidTogetherAPI, auctionsSearchTogetherAPI} from '../../../../redux/actions/auctions/auctions.js';
import {
    togetherApiBidsArr,
    togetherApiPageCurrentAuction,
    togetherApiPageNextAuction,
} from '../../../../redux/togetherApi.js';

import PopUpCreateDraftBid from './popUp/popupCreateDraftBid.js';
import {setUniversalError, changeLoader} from '../../../../redux/actions.js';

const TogetherAPI = (props) => {
    let tmp = null;
    tmp = <div className={styles.auc_base_block}>
            <div className={styles.auc_line}>
                <div className={styles.baseData + ' ' + styles.lotNumber}>№ лоту</div>
                <div className={styles.baseData + ' ' + styles.pothishn}>Id аукціону</div>
                <div className={styles.baseData + ' ' + styles.name}>Назва</div>
                <div className={styles.baseData + ' ' + styles.poroda}>Порода</div>
                <div className={styles.baseData + ' ' + styles.sort}>Сорт</div>
                <div className={styles.baseData + ' ' + styles.sklad}>Склад</div>
                <div className={styles.baseData + ' ' + styles.diametr}>Діаметр, мм</div>
                <div className={styles.baseData + ' ' + styles.dovjina}>Довжина, мм</div>
                <div className={styles.baseData + ' ' + styles.obem}>Об'єм</div>
                <div className={styles.baseData + ' ' + styles.cina}>Ціна, грн</div>
                {/*<div className={styles.baseData + ' ' + styles.summa}>Сума, грн</div>*/}
                <div className={styles.baseData + ' ' + styles.sellers}>Породавець</div>
                <div className={styles.baseData + ' ' + styles.buttonsBlock}>Операції</div>
            </div>
            {createViewLines(props)}
    </div>
    let tmpPopUpCreateDraftBid = null
    if(props.togetherApiPage.bidsDraftArr !== null){
        tmpPopUpCreateDraftBid = <PopUpCreateDraftBid />
    }
    return (
        <div className={styles.wrap_auc}>
            {tmp}
            {tmpPopUpCreateDraftBid}
        </div>
    )
}

function createViewLines(props){
    let tmp = null;
    if(props.auctions !== null && props.userProfiles !== null){
        if(props.auctions.length > 0){
            tmp = props.auctions
            .filter(
                (i) => {return i.status === 'active_tendering'}
            )
            .map(
                (i, count) => {
                    let tmpI = null;
                    if(i.items !== null){
                        if(i.items.length > 0){
                            tmpI = i.items.map(
                                (k) => {
                                    let tmpButton = null
                                    if(props.userProfiles.canCreateBid === true){
                                        //Тут смотрим и делаем чтоб віводится или нет кнопка сделать бид
                                        /*if(props.token !== null){*/
                                            //viewButtonAndGetAuctionId(props, i)
                                            //tmpButton = viewButtonAndGetAuctionId(props, i)
                                        /*}
                                        tmpButton = null*/
                                        tmpButton = <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={
                                                () => {
                                                    //let tmp = props.togetherApiPage.bidsDraftArr
                                                    //tmp.push( {auction: i, chekbox: false} )
                                                    props.togetherApiBidsArr({auction: i, chekbox: false})
                                                }
                                            }
                                        >
                                            Участь
                                        </Button>
                                    }
                                    return (
                                        <div className={styles.auc_line}>
                                            <div className={styles.baseData + ' ' + styles.lotNumber}>{count+1}</div>
                                            <div className={styles.baseData + ' ' + styles.pothishn}>{i.auctionId}</div>
                                            <div className={styles.baseData + ' ' + styles.name}>{i.title}</div>
                                            <div className={styles.baseData + ' ' + styles.poroda}>{viewSpecies(k)}</div>
                                            <div className={styles.baseData + ' ' + styles.sort}>{viewSort(k)}</div>
                                            <div className={styles.baseData + ' ' + styles.sklad}>{viewStorage(k)}</div>
                                            <div className={styles.baseData + ' ' + styles.diametr}>{viewDiametr(k)}</div>
                                            <div className={styles.baseData + ' ' + styles.dovjina}>{viewSize(k)}</div>
                                            <div className={styles.baseData + ' ' + styles.obem}>{k.quantity}</div>
                                            <div className={styles.baseData + ' ' + styles.cina}>{i.value.amount}</div>
                                            {/*<div className={styles.baseData + ' ' + styles.summa}>{i.value.amount * k.quantity}</div>*/}
                                            <div className={styles.baseData + ' ' + styles.sellers}>{i.sellingEntity.name}</div>
                                            <div className={styles.baseData + ' ' + styles.buttonsBlock}>
                                                {tmpButton}
                                            </div>
                                        </div>
                                    )
                                }
                            )
                        }
                    }
                    return tmpI
                }
            )
        }
    }
    return (
        <>
            {tmp}
            {pagination(props)}
        </>
    )
}

function pagination(props){
    let tmp = null
    if(props.togetherApiPage.pageNextAuction <= props.togetherApiPage.pageCurrentAuction){
        let pagePrev = null
        if(props.togetherApiPage.pageCurrentAuction > 0) {
            pagePrev = <div
                onClick={
                    () => {
                        let tmpOfset = (props.togetherApiPage.pageCurrentAuction - 1) * 10
                        //getActiveTenderAuction(props, tmpOfset)
                        getSearchAuctions(props, tmpOfset)
                        props.togetherApiPageCurrentAuction(props.togetherApiPage.pageCurrentAuction - 1)
                        props.togetherApiPageNextAuction(props.togetherApiPage.pageCurrentAuction)
                    }
                }
            >{props.togetherApiPage.pageCurrentAuction}</div>
        }
        tmp = <div className={styles.pagination_wrap}>
            {pagePrev}
            <div className={styles.activPG}>{props.togetherApiPage.pageCurrentAuction + 1}</div>
        </div>
    }else{
        let pagePrev = null
        if(props.togetherApiPage.pageCurrentAuction > 0) {
            pagePrev = <div
                onClick={
                    () => {
                        let tmpOfset = (props.togetherApiPage.pageCurrentAuction - 1) * 10
                        //getActiveTenderAuction(props, tmpOfset)
                        getSearchAuctions(props, tmpOfset)
                        props.togetherApiPageCurrentAuction(props.togetherApiPage.pageCurrentAuction - 1)
                        props.togetherApiPageNextAuction(props.togetherApiPage.pageCurrentAuction)
                    }
                }
            >{props.togetherApiPage.pageCurrentAuction}</div>
        }
        tmp = <div className={styles.pagination_wrap}>
            {pagePrev}
            <div className={styles.activPG}>{props.togetherApiPage.pageCurrentAuction + 1}</div>
            <div
                onClick={
                    () => {
                        let tmpOfset = (props.togetherApiPage.pageCurrentAuction + 1) * 10
                        //getActiveTenderAuction(props, tmpOfset)
                        if(props.auctions.length === 10) {
                            getSearchAuctions(props, tmpOfset)
                            props.togetherApiPageCurrentAuction(props.togetherApiPage.pageCurrentAuction + 1)
                            props.togetherApiPageNextAuction(props.togetherApiPage.pageCurrentAuction + 2)
                        }
                    }
                }
            >{props.togetherApiPage.pageNextAuction + 1}</div>
        </div>
    }
    return tmp
}

async function getActiveTenderAuction(props, data){
    await props.changeLoader(true)
    let data5 = {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag,
        params: `?offset=${data}`
    }
    let headerTogetherApi = {
        'Authorization': props.token.access_token,
        'Content-Type': 'application/json'
    }
    let dataSearch = {
        category: props.search.category,
    }
    await props.auctionsSearchTogetherAPI(data5, headerTogetherApi, dataSearch)
    await props.changeLoader(false)
}

async function getSearchAuctions(props, data7){
    await props.changeLoader(true)
    let tmpArr = [];
    if(props.togetherAPIelem.classificationsByTypeMain !== null){tmpArr.push(props.togetherAPIelem.classificationsByTypeMain)}
    if(props.togetherAPIelem.timberSortment !== null){tmpArr.push(props.togetherAPIelem.timberSortment)}
    if(props.togetherAPIelem.timberSpecies !== null){tmpArr.push(props.togetherAPIelem.timberSpecies)}
    if(props.togetherAPIelem.timberClass !== null){tmpArr.push(props.togetherAPIelem.timberClass)}
    if(props.togetherAPIelem.timberDiameter !== null){tmpArr.push(props.togetherAPIelem.timberDiameter)}

    let timberLength1 = null
    let timberLength2 = null
    if(props.togetherAPIelem.timberLength1 !== null && props.togetherAPIelem.timberLength2 !== null){
        timberLength1 = props.togetherAPIelem.timberLength1.replace('.', ',')
        timberLength2 = props.togetherAPIelem.timberLength2.replace('.', ',')
        if(timberLength1.indexOf(',') === -1 ){
            timberLength1 = timberLength1 + ',0'
        }
        if(timberLength2.indexOf(',') === -1 ){
            timberLength2 = timberLength2 + ',0'
        }
        timberLength1 = timberLength1.replace(',,', ',')
        timberLength2 = timberLength2.replace(',,', ',')
        timberLength1 = timberLength1.replace(',', '.')
        timberLength2 = timberLength2.replace(',', '.')
        /*tmpArr.push(
            timberLength1.trim() + ' - ' + timberLength2.trim()
        )*/
    }
    //if(props.togetherAPIelem.timberLength1 !== null){tmpArr.push(props.togetherAPIelem.timberLength1)}
    //if(props.togetherAPIelem.timberLength2 !== null){tmpArr.push(props.togetherAPIelem.timberLength2)}
    if(props.togetherAPIelem.timberStorage !== null){tmpArr.push(props.togetherAPIelem.timberStorage)}
    if(props.togetherAPIelem.timberProductionYear !== null){tmpArr.push(props.togetherAPIelem.timberProductionYear)}
    if(props.togetherAPIelem.timberProductionQuarter !== null){tmpArr.push(props.togetherAPIelem.timberProductionQuarter)}
    let dataSearch = {
        category: props.search.category,
        lengthFrom: parseFloat(timberLength1),
        lengthTo: parseFloat(timberLength2),
        additionalClassifications: tmpArr,
    }
    let data = {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag,
        params: `?offset=${data7}`
    }
    if(props.token !== null){
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        await props.auctionsSearchTogetherAPI(data, header, dataSearch);
        /*let data8 = {
            domen: process.env.REACT_APP_END_POINT_BD,
            version: 'v1',
            local: props.langFlag,
            //params: '?limit=200&offset=0'
        }
        let elemArr = ''
        for(let it = 0; it < props.auctions.length; it++){
            if(it === 0){
                elemArr = `ids[]=${props.auctions[it].id}`
            }else{
                elemArr = elemArr + `&ids[]=${props.auctions[it].id}`
            }
        }
        await props.auctionsSearchTestElemArr(data8, header, elemArr)*/
    }else{
        let header = {
            'Content-Type': 'application/json'
        }
        await props.auctionsSearchTogetherAPI(data, header, dataSearch);
    }
    await props.changeLoader(false)
}


async function viewButtonAndGetAuctionId(props, i){
    let tmp = null
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${i.id}/bids/check`
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка запиту даних аукціону', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }else{
            let tmpOnOff = false
            if(json.length > 0){
                for(let iter = 0; iter < json.length; iter++){
                    if(json[iter].createdByCurrentUser === true) tmpOnOff = true
                }
            }
            if(tmpOnOff === false){
                tmp = <Button
                    variant="contained"
                    color="primary"
                    onClick={
                        () => {
                            props.togetherApiBidsArr({auction: i, chekbox: false})
                        }
                    }
                >
                    Участь
                </Button>
            }
        }
    }
    return tmp
}

function viewSpecies(item){
    let tmp = null;
    if(item.hasOwnProperty('additionalClassifications') === true){
        if(item.additionalClassifications !== null){
            if(item.additionalClassifications.length > 0){
                for(let tt = 0; tt < item.additionalClassifications.length; tt++){
                    if(item.additionalClassifications[tt].scheme === "timberSpecies"){
                        tmp = item.additionalClassifications[tt].description
                    }
                }
            }
        }
    }
    return tmp
}

function viewSort(item){
    let tmp = null;
    if(item.hasOwnProperty('additionalClassifications') === true){
        if(item.additionalClassifications !== null){
            if(item.additionalClassifications.length > 0){
                for(let tt = 0; tt < item.additionalClassifications.length; tt++){
                    if(item.additionalClassifications[tt].scheme === "timberSortment"){
                        tmp = item.additionalClassifications[tt].classificationId
                    }
                }
            }
        }
    }
    return tmp
}

function viewStorage(item){
    let tmp = null;
    if(item.hasOwnProperty('additionalClassifications') === true){
        if(item.additionalClassifications !== null){
            if(item.additionalClassifications.length > 0){
                for(let tt = 0; tt < item.additionalClassifications.length; tt++){
                    if(item.additionalClassifications[tt].scheme === "timberStorage"){
                        tmp = item.additionalClassifications[tt].classificationId
                    }
                }
            }
        }
    }
    return tmp
}

function viewDiametr(item){
    let tmp = null;
    if(item.hasOwnProperty('additionalClassifications') === true){
        if(item.additionalClassifications !== null){
            if(item.additionalClassifications.length > 0){
                for(let tt = 0; tt < item.additionalClassifications.length; tt++){
                    if(item.additionalClassifications[tt].scheme === "timberDiameter"){
                        tmp = item.additionalClassifications[tt].classificationId
                    }
                }
            }
        }
    }
    return tmp
}

function viewSize(item){
    let tmp = null;
    if(item.hasOwnProperty('additionalClassifications') === true){
        if(item.additionalClassifications !== null){
            if(item.additionalClassifications.length > 0){
                for(let tt = 0; tt < item.additionalClassifications.length; tt++){
                    if(item.additionalClassifications[tt].scheme === "timberLength"){
                        tmp = item.additionalClassifications[tt].classificationId
                    }
                }
            }
        }
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        langFlag: state.start.langFlag,
        auctions: state.start.auctionsGet,
        auctionsOld: state.start.auctionsGetOld,
        search: state.start.search,
        togetherApiPage: state.start.togetherApiPage,
        togetherAPIelem: state.start.togetherAPIelem,
        userProfiles: state.start.userProfiles, 
    }
}

const mapDispatchToProps = {
    setPopUpBidTogetherAPI,    
    togetherApiBidsArr,
    setUniversalError,
    changeLoader,
    auctionsSearchTogetherAPI,
    togetherApiPageCurrentAuction,
    togetherApiPageNextAuction,
}

export default connect(mapStateToProps, mapDispatchToProps)(TogetherAPI)
