import React from 'react';
import styles from './auctionResult.module.css';

/**NEW ELEMENTS */
import Title from '../title/title'
import Tabs from '../tabs/tabs'
import RightBlock from '../rightBlock/rightBlock'

import {connect} from 'react-redux';

import AllUsersContractNew from '../contractNew/contractTypeAuctions';
import AwardNew from '../awardNew/awardTypeAuctions';

import Participants from '../participant/participants';

import ProtocolLink from '../protocolLink/protocolLink';
import Cancellations from '../cancellationsNew/newAward'
import SidebarRightViewProcedure from '../../sidebarRightViewProcedure'

import { isLAW } from '../../../redux/aucTypes';

const DescriptionLot = (props) => {
    if (props.auctionId === null) return null
    return (
        <div className={styles.wrapNew}>
            <div className={styles.mainBlock}>
                <Title />
                <div className={styles.aucProperties}>
                    <Tabs />
                    <Participants type={true} />
                    <Participants type={false} />
                    <AwardNew />
                    {isLAW(props.auctionId.sellingMethod) !== true ? <AllUsersContractNew /> : null}
                    <Cancellations />
                    {isLAW(props.auctionId.sellingMethod) ? null : <ProtocolLink /> }
                </div>
            </div>
            {/*<RightBlock />*/}
            <SidebarRightViewProcedure />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(DescriptionLot)