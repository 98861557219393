import { 
    //Documents
    DOCUMENT_GET,
    DOCUMENTS_GET,
    DOCUMENT_POST,
    DOCUMENT_PUT,
    DOCUMENT_DELETE,

    CHANGE_DOCUMENT_ID,
    CHANGE_DOCUMENT_TITLE,
    CHANGE_DOCUMENT_INDEX,
    CHANGE_DOCUMENT_DESCRIPTION,
    CHANGE_DOCUMENT_LANG,
    CHANGE_DOCUMENT_TYPE,

    CREATE_DOCUMENT_FILE_NAME,

    DOCUMENT_COUNNT_ARR_FILES,

    DOCUMENT_BID_BLOCK_STATUS,
    DOCUMENT_BID_PUBLIC_NAME,

    OLD_ARR_DOCUMENT_GET,
} from "../../types";

import {getAPI, postAPI, putAPI, deleteAPI} from '../crud';
import {setLocal} from '../support';

//Documents
export function documentPost(_url, token, datas){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/document`,
        token: token,
        actionType: DOCUMENT_POST,
        data: datas,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return postAPI(data);
}

export function documentGet(_url, token){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/document/${_url.id}`,
        token: token,
        actionType: DOCUMENT_GET,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function documentPut(_url, token, datas){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/document/${_url.id}`,
        token: token,
        actionType: DOCUMENT_PUT,
        data: datas,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return putAPI(data);
}

export function documentDelete(_url, token){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/document/${_url.id}`,
        token: token,
        actionType: DOCUMENT_DELETE,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return deleteAPI(data);
}

export function documentsGet(_url, token){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/documents${_url.params}`,
        token: token,
        actionType: DOCUMENTS_GET,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function changeDocumentId(value) {
    return {
        type: CHANGE_DOCUMENT_ID,
        payload: value
    }
}

export function changeDocumentTitle(value) {
    return {
        type: CHANGE_DOCUMENT_TITLE,
        payload: value
    }
}

export function changeDocumentIndex(value) {
    return {
        type: CHANGE_DOCUMENT_INDEX,
        payload: value
    }
}

export function changeDocumentDescription(value) {
    return {
        type: CHANGE_DOCUMENT_DESCRIPTION,
        payload: value
    }
}

export function changeDocumentLang(value) {
    return {
        type: CHANGE_DOCUMENT_LANG,
        payload: value
    }
}

export function changeDocumentFileName(value) {
    return {
        type: CREATE_DOCUMENT_FILE_NAME,
        payload: value
    }
}


export function changeDocumentArrFiles(value) {
    return {
        type: DOCUMENT_COUNNT_ARR_FILES,
        payload: value
    }
}

export function changeDocumentCountArrFiles(value) {
    return async dispatch => {
        dispatch({type: DOCUMENT_COUNNT_ARR_FILES, payload: value})
    }
}

export function changeDocumentBidBlockStatus(value) {
    return {
        type: DOCUMENT_BID_BLOCK_STATUS,
        payload: value
    }
}

export function changeDocumentBidPublicName(value) {
    return {
        type: DOCUMENT_BID_PUBLIC_NAME,
        payload: value
    }
}

export function changeDocumentType(value) {
    return {
        type: CHANGE_DOCUMENT_TYPE,
        payload: value
    }
}

export function oldArrDocumentGet(url, procedureId){
    const data = {
        //url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/document/${_url.id}`,
        url: `${url}/api/v1/uk/document/proxy/history?url=/api/procedures/${procedureId}/documents/history`,
        //url: `${}https://procedure-staging.prozorro.sale/api/procedures/${procedureId}/documents/history`,
        actionType: OLD_ARR_DOCUMENT_GET,
        header: {
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}