import { 
    //Auction Items
    AUCTION_ITEMS_GET,
    AUCTION_ID_ITEMS_GET,
    AUCTION_ITEMS_POST,
    AUCTION_ITEMS_PUT,
    AUCTION_ITEMS_DELETE,

    REG_EXP_TIMBER_DIAMETR,
    
    CHANGE_GEOLOGIV_DATA_VALUE,
    CHANGE_DOCUMENTATION_VALUE,
    CHANGE_DATE_USES,
} from "../../types";
import {getAPI, postAPI, putAPI, deleteAPI} from '../crud';
import {setLocal} from '../support';

//Auction Items
export function itemPost(_url, token, datas){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/auction/${_url.aucId}/item`,
        token: token,
        actionType: AUCTION_ITEMS_POST,
        data: datas,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return postAPI(data);
}

export function itemGetId(_url, token){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/auction/${_url.aucId}/item/${_url.id}`,
        token: token,
        actionType: AUCTION_ITEMS_GET,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function itemPut(_url, token, datas){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/auction/${_url.aucId}/item/${_url.id}`,
        token: token,
        actionType: AUCTION_ITEMS_PUT,
        data: datas,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return putAPI(data);
}

export function itemDelete(_url, token){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/auction/${_url.aucId}/item/${_url.id}`,
        token: token,
        actionType: AUCTION_ITEMS_DELETE,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return deleteAPI(data);
}

export function auctionItemGet(_url, token){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/auction/${_url.id}/item/${_url.params}`,
        token: token,
        actionType: AUCTION_ID_ITEMS_GET,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function setRecExpTimberDiametr(value) {
    return {
        type: REG_EXP_TIMBER_DIAMETR,
        payload: value
    }
}

export function changeCreateItemGeologivDataValue(value) {
    return {
        type: CHANGE_GEOLOGIV_DATA_VALUE,
        payload: value
    }
}

export function changeCreateItemDocumentationValue(value) {
    return {
        type: CHANGE_DOCUMENTATION_VALUE,
        payload: value
    }
}

export function changeCreateItemDateUse(value) {
    return {
        type: CHANGE_DATE_USES,
        payload: value
    }
}