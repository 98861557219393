import React, {useState} from 'react';
import style from './deleteAuction.module.css';

import { connect } from 'react-redux';

import Input from '../../../elements/inputs/input';
import InputDate from '../../../elements/inputs/inputDate';
import TextArea from '../../../elements/inputs/textArea';
import InputSelect from '../../../elements/inputs/inputSelect';

import {
    setPopUpDelAuction,
    auctionsGetMy,
    setDeleteAuctionDescription,
    setPopUpAuctionMyNull,
    setPopUpAuctionDeleteTitle,
    setPopUpAuctionDeleteDate,
} from '../../../../redux/actions/auctions/auctions.js';

import {
    changeDocumentFileName,
} from '../../../../redux/actions/documents/documents.js';

import {
    setDataAddress,
} from '../../../../redux/createAddress';

import {
    setUniversalError,
    changeLoader,
} from '../../../../redux/actions.js';

import {isSPD, isSPE, isBSE, isBSD, isALE, isCSE, isCSD, isLLE, isLLD, isLLP, isBRE, isBRD, isBRW,
isTIE, isSUE, isSUD, isRCE, isRCD, isGFE, isGFD, isLRE, isLSE, isLSP, isRLE, isRLD, isCLE, isCLD, isNLE, isNLD,
isLAE, isLAW, isLAP, isLPE, isAPE, isAPD} from '../../../../redux/aucTypes'

const PopUpDelAuction = (props) => {
    const [sendData, setSendData] = useState({
        docDescription: null,
        reason: null,
        delDate: null,
        description: null
    })

    function fomView(props){
        let tmp = null;
    
        if(props.popUpDelAuc.status !== null){
            tmp = <>
                <Input
                    value={sendData.docDescription} //props.popUpAuctionDeleteTitle
                    label='Опис документу'
                    onChange={ e => setSendData({...sendData, docDescription: e})} //'POPUP_AUCTION_DELETE_TITLE'
                    reqiured={true}
                />
                {viewReasonElem(props)}
                <InputDate
                    label={'Дата скасування аукціону'}
                    time={true}
                    data={{
                        target: sendData.delDate, //props.deleteDateCancelAuction,
                        label: 'Дата скасування аукціону', 
                        time: true}}
                    onChange={ e => setSendData({...sendData, delDate: e}) } //props.setDataAddress(e, 'POPUP_DATE_DELETE_AUCTION')}
                />
                {!isLAW(props.popUpDelAuc.sellingMethod)
                    && !isLPE(props.popUpDelAuc.sellingMethod)
                    && !isSUE(props.popUpDelAuc.sellingMethod)
                    && !isSUD(props.popUpDelAuc.sellingMethod) 
                    ? <TextArea
                        value={ sendData.description} // props.deleteAuctionDescription}
                        label='Причина скасування Аукціону'
                        onChange={ e => setSendData({...sendData, description: e}) } //props.setDataAddress(e, 'SET_DELETE_AUCTION_DESCRIPTION')}
                        reqiured={true}
                    />
                    : null
                }
                <div>{createView(props, sendData)}</div>
            </>
        }else{
            tmp = <div>Ви дійсно хочте видалити чернетку аукціону?</div>
        }
        return tmp
    }

    function viewReasonElem(props){
        if( isLLE(props.popUpDelAuc.sellingMethod) || isLLD(props.popUpDelAuc.sellingMethod) || isLLP(props.popUpDelAuc.sellingMethod) ) {
            return <InputSelect
                            data={{
                                target: sendData.reason,
                                label: 'Оберіть причину скасування',
                            }}
                            options={[
                                    { value: '0', name: 'Обрати', style: '', selected: true, disabled: true },
                                    { value: '1', name: 'Скасування рішення про включення об’єкта до Переліку першого типу', style: '', selected: '', disabled: '' },
                                    { value: '2', name: 'Прийняття рішення про виключення об’єкта з Переліку першого типу', style: '', selected: '', disabled: '' },
                                    { value: '3', name: 'Зміна рішення про включення об’єкта до Переліку першого типу', style: '', selected: '', disabled: '' },
                                    { value: '4', name: 'Порушення порядку підготовки аукціону', style: '', selected: '', disabled: '' },
                                    { value: '5', name: 'Виникнення технічного збою, підтвердженого адміністратором електронної торгової системи', style: '', selected: '', disabled: '' },
                                    { value: '6', name: 'Рішення суду або інші підстави', style: '', selected: '', disabled: '' },
                            ]}
                            onChange={ e => setSendData({...sendData, reason: e})}
                        />
        }
        if( 
            isSUE(props.popUpDelAuc.sellingMethod) ||
            isSUD(props.popUpDelAuc.sellingMethod) 
        ) {
            return <InputSelect
                            data={{
                                target: sendData.reason,
                                label: 'Оберіть причину скасування',
                            }}
                            options={[
                                { value: '0', name: 'Обрати', style: '', selected: true, disabled: true },
                                { value: '1', name: 'Наявність рішення суду, яке набрало законної сили', style: '', selected: '', disabled: '' },
                                { value: '3', name: 'Визнання в установленому законодавством порядку протиправними дій та/або скасування рішень Міндовкілля стосовно погоджень щодо визначення переліку ділянок надр, дозволи на користування якими виставляються на аукціон для надання спеціального дозволу на геологічне вивчення, у тому числі дослідно-промислову розробку, корисних копалин з подальшим видобуванням корисних копалин (промислову розробку родовищ)', style: '', selected: '', disabled: '' },
                                { value: '4', name: 'Зміна виду користування надрами, визначеного законодавчими актами', style: '', selected: '', disabled: '' },
                                    /*{ value: '0', name: 'Обрати', style: '', selected: true, disabled: true },
                                    { value: '1', name: 'Скасування рішення про включення об’єкта до Переліку першого типу', style: '', selected: '', disabled: '' },
                                    { value: '2', name: 'Прийняття рішення про виключення об’єкта з Переліку першого типу', style: '', selected: '', disabled: '' },
                                    { value: '3', name: 'Зміна рішення про включення об’єкта до Переліку першого типу', style: '', selected: '', disabled: '' },
                                    { value: '4', name: 'Порушення порядку підготовки аукціону', style: '', selected: '', disabled: '' },
                                    { value: '5', name: 'Виникнення технічного збою, підтвердженого адміністратором електронної торгової системи', style: '', selected: '', disabled: '' },
                                    { value: '6', name: 'Рішення суду або інші підстави', style: '', selected: '', disabled: '' },*/
                            ]}
                            onChange={ e => setSendData({...sendData, reason: e})}
                        />
        }
        if(isSPE(props.popUpDelAuc.sellingMethod) || isSPD(props.popUpDelAuc.sellingMethod)){
            return <InputSelect
                            data={{
                                target: sendData.reason,
                                label: 'Оберіть причину скасування',
                            }}
                            options={[
                                    { value: '0', name: 'Обрати', style: '', selected: true, disabled: true },
                                    { value: '1', name: "Виключення об'єкта приватизації з переліку об'єктів великої приватизації державної власності, що підлягають приватизації", style: '', selected: '', disabled: '' },
                                    { value: '2', name: "Ухвалення місцевою радою рішення про скасування попереднього рішення про приватизацію відповідного об'єкта комунальної власності", style: '', selected: '', disabled: '' },
                                    { value: '3', name: "Порушено порядок підготовки до аукціону", style: '', selected: '', disabled: '' },
                                    { value: '4', name: "Виникнення технічного збою, підтвердженого адміністратором електронної торгової системи", style: '', selected: '', disabled: '' },
                                    {/* value: '5', name: 'Рішення суду або інші підстави', style: '', selected: '', disabled: '' */},
                                    { value: '6', name: "Рішення суду", style: '', selected: '', disabled: '' },
                                    { value: '7', name: "Інша підстава за рішенням органу приватизації", style: '', selected: '', disabled: '' },
                            ]}
                            onChange={ e => setSendData({...sendData, reason: e})}
                        />
        }
        if(isLPE(props.popUpDelAuc.sellingMethod)){
            return <InputSelect
                            data={{
                                target: sendData.reason,
                                label: 'Оберіть причину скасування',
                            }}
                            options={[
                                    { value: '0', name: 'Обрати', style: '', selected: true, disabled: true },
                                    { value: '1', name: "Виключення об'єкта приватизації з переліку об'єктів великої приватизації державної власності, що підлягають приватизації", style: '', selected: '', disabled: '' },
                                    { value: '2', name: "Ухвалення місцевою радою рішення про скасування попереднього рішення про приватизацію відповідного об'єкта комунальної власності", style: '', selected: '', disabled: '' },
                                    { value: '3', name: "Порушено порядок підготовки до аукціону", style: '', selected: '', disabled: '' },
                                    { value: '4', name: "Виникнення технічного збою, підтвердженого адміністратором електронної торгової системи", style: '', selected: '', disabled: '' },
                                    /*{ value: '5', name: "Рішення суду або інші підстави", style: '', selected: '', disabled: '' },*/
                                    { value: '6', name: "Рішення суду", style: '', selected: '', disabled: '' },
                                    { value: '7', name: "Інша підстава за рішенням органу приватизації", style: '', selected: '', disabled: '' },
                            ]}
                            onChange={ e => setSendData({...sendData, reason: e})}
                        />
        }
        if(isBRW(props.popUpDelAuc.sellingMethod)){
            /*return <InputSelect
                            data={{
                                target: sendData.reason,
                                label: 'Оберіть причину скасування',
                            }}
                            options={[
                                    { value: '1', name: "Порушено порядок підготовки до аукціону", style: '', selected: '', disabled: '' },
                            ]}
                            onChange={ e => setSendData({...sendData, reason: e})}
                        />
                            */
            return null
        }
        if(isLAW(props.popUpDelAuc.sellingMethod)){
            if(sendData.reason !== '1') setSendData({...sendData, reason: '1'})
            return <InputSelect
                data={{
                    target: sendData.reason,
                    label: 'Причина скасування Аукціону',
                }}
                options={[
                        { value: '1', name: "Порушено порядок підготовки до викупу", style: '', selected: '', disabled: '' },
                ]}
                onChange={ e => setSendData({...sendData, reason: e})}
                disabled={true}
            />
        }
    }

    return (
        <div className={style.popup_wrap}>
            <div className={style.popup_message}>
                <div
                    className={style.popUpExit}
                    onClick={
                        ()=>{
                            props.setPopUpDelAuction(null)
                            const addFileInp = document.getElementById('input__fileDel');
                            if(addFileInp !== null){
                                addFileInp.value = null;
                            }
                            props.changeDocumentFileName(null)
                        }
                    }
                >
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className={style.closeSvg} fillRule="evenodd" clipRule="evenodd" d="M8.41421 6.99989L13.364 11.9496C13.7545 12.3402 13.7545 12.9733 13.364 13.3639C12.9734 13.7544 12.3403 13.7544 11.9497 13.3639L7 8.41411L2.05025 13.3639C1.65973 13.7544 1.02656 13.7544 0.636039 13.3639C0.245514 12.9733 0.245515 12.3402 0.636039 11.9496L5.58579 6.99989L0.636039 2.05015C0.245515 1.65962 0.245514 1.02646 0.636039 0.635934C1.02656 0.245409 1.65973 0.245409 2.05025 0.635934L7 5.58568L11.9497 0.635934C12.3403 0.245409 12.9734 0.245409 13.364 0.635934C13.7545 1.02646 13.7545 1.65962 13.364 2.05015L8.41421 6.99989Z" fill="#92929D"/>
                    </svg>

                </div>
                <div className={style.popUpTitle}>Скасування аукціону</div>
                <div className={style.inputWrap}>
                    {fomView(props)}
                </div>
                <div className={style.blokButton}>
                    <div
                        className={style.popupButtonYes}
                        onClick={
                            ()=>{
                                if(
                                    (props.popUpDelAuc.hasOwnProperty('procedureId') !== true ||
                                    props.popUpDelAuc.procedureId === null) &&
                                    props.popUpDelAuc.auctionId === null
                                ){
                                    if(
                                        isBSE(props.popUpDelAuc.sellingMethod) || isBSD(props.popUpDelAuc.sellingMethod) ||
                                        isALE(props.popUpDelAuc.sellingMethod) ||
                                        isCSE(props.popUpDelAuc.sellingMethod) || isCSD(props.popUpDelAuc.sellingMethod) ||
                                        isBRE(props.popUpDelAuc.sellingMethod) || isBRD(props.popUpDelAuc.sellingMethod) || isBRW(props.popUpDelAuc.sellingMethod) ||
                                        isRLE(props.popUpDelAuc.sellingMethod) || isRLD(props.popUpDelAuc.sellingMethod) ||
                                        isCLE(props.popUpDelAuc.sellingMethod) || isCLD(props.popUpDelAuc.sellingMethod) ||
                                        isSPE(props.popUpDelAuc.sellingMethod) || isSPD(props.popUpDelAuc.sellingMethod) ||
                                        isNLE(props.popUpDelAuc.sellingMethod) || isNLD(props.popUpDelAuc.sellingMethod) ||
                                        isLAE(props.popUpDelAuc.sellingMethod) || isLAW(props.popUpDelAuc.sellingMethod) || isLAP(props.popUpDelAuc.sellingMethod) ||
                                        isLPE(props.popUpDelAuc.sellingMethod) ||
                                        isAPE(props.popUpDelAuc.sellingMethod) || isAPD(props.popUpDelAuc.sellingMethod)
                                        || isLLE(props.popUpDelAuc.sellingMethod) || isLLD(props.popUpDelAuc.sellingMethod) || isLLP(props.popUpDelAuc.sellingMethod)
                                        || isLRE(props.popUpDelAuc.sellingMethod) || isLSE(props.popUpDelAuc.sellingMethod) || isLSP(props.popUpDelAuc.sellingMethod)
                                    ){
                                        if(String(props.popUpDelAuc.id).includes("-") === true){
                                            deleteMongoDrafts(props, props.popUpDelAuc.id)
                                        }else{
                                            deleteAuction(props, props.popUpDelAuc.id)
                                        }
                                    }else{
                                        deleteAuction(props, props.popUpDelAuc.id)
                                    }
                                }else{
                                    const addFileInp = document.getElementById('input__fileDel');
                                    if(
                                        //props.deleteAuctionDescription !== '' &&   
                                        props.deleteDateCancelAuction !== '' &&
                                        props.createDocuments.fileName !== '' &&
                                        addFileInp.value !== null
                                    ){
                                        if(addFileInp.files.length !== 0){
                                            props.changeLoader(true)
                                            if(
                                                isTIE(props.popUpDelAuc.sellingMethod) ||
                                                isSUE(props.popUpDelAuc.sellingMethod) || isSUD(props.popUpDelAuc.sellingMethod) ||
                                                isRCE(props.popUpDelAuc.sellingMethod) ||
                                                isRCD(props.popUpDelAuc.sellingMethod) || isGFE(props.popUpDelAuc.sellingMethod) || isGFD(props.popUpDelAuc.sellingMethod) ||
                                                isLRE(props.popUpDelAuc.sellingMethod) ||
                                                isLSE(props.popUpDelAuc.sellingMethod) || isLSP(props.popUpDelAuc.sellingMethod) ||
                                                isLLE(props.popUpDelAuc.sellingMethod) || isLLD(props.popUpDelAuc.sellingMethod) || isLLP(props.popUpDelAuc.sellingMethod) ||
                                                isBSE(props.popUpDelAuc.sellingMethod) || isBSD(props.popUpDelAuc.sellingMethod) ||
                                                isALE(props.popUpDelAuc.sellingMethod) ||
                                                isCSE(props.popUpDelAuc.sellingMethod) || isCSD(props.popUpDelAuc.sellingMethod) ||
                                                isBRE(props.popUpDelAuc.sellingMethod) || isBRD(props.popUpDelAuc.sellingMethod) || isBRW(props.popUpDelAuc.sellingMethod) ||
                                                isRLE(props.popUpDelAuc.sellingMethod) || isRLD(props.popUpDelAuc.sellingMethod) ||
                                                isCLE(props.popUpDelAuc.sellingMethod) || isCLD(props.popUpDelAuc.sellingMethod) ||
                                                isSPE(props.popUpDelAuc.sellingMethod) || isSPD(props.popUpDelAuc.sellingMethod) ||
                                                isNLE(props.popUpDelAuc.sellingMethod) || isNLD(props.popUpDelAuc.sellingMethod) ||
                                                isLAE(props.popUpDelAuc.sellingMethod) || isLAW(props.popUpDelAuc.sellingMethod) || isLAP(props.popUpDelAuc.sellingMethod) ||
                                                isLPE(props.popUpDelAuc.sellingMethod) ||
                                                isAPE(props.popUpDelAuc.sellingMethod) || isAPD(props.popUpDelAuc.sellingMethod)
                                            ){
                                                promisUploadFilesNew(props, sendData)
                                            }else{
                                                promisUploadFiles(props, sendData)
                                            }
                                        }else{
                                            let tmp = JSON.stringify({title: 'Помилка при скасуванні аукціону', message: 'Необхідно завантажити документ'})
                                            props.setUniversalError(tmp)
                                        }
                                    }
                                }
                            }
                        }
                    >Так</div>
                    <div
                        className={style.popupButtonNo}
                        onClick={
                            ()=>{
                                props.setPopUpDelAuction(null)
                                //const addFileInp = document.getElementById('input__fileDel');
                                //addFileInp.value = null;
                                props.changeDocumentFileName(null)
                            }
                        }
                    >Ні</div>
                </div>
                
            </div>
        </div>
    )
}

function createView(props, sendData){
    let tmp = null;
    tmp = (
        <div className={style.documentsForm}>
            <div className={style.groupElem}>
                {buttonCreateImg(props, sendData)}
            </div>
        </div>
    )
    return tmp
}

function buttonCreateImg(props, sendData){
    let fileElem = null;
    if(props.createDocuments.fileName === null){
        fileElem = <span className={style.input__file_button_text}>
            <span>Додати документ</span>
        </span>
    }else{
        fileElem = <span className={style.input__file_button_text}>
            <span>Доданий документ:</span> <span>{
                props.createDocuments.fileName.length > 45 ?
                    `${props.createDocuments.fileName.substr(0, 45)}...`
                    : props.createDocuments.fileName }
            </span>
        </span>
    }
    return (
        <div className={style.formLine}>
            <form 
                onSubmit={(e) => {
                    handleSubmit(e, props, sendData)
                }}
                id="addFileForm"
            >
                <div className={style.input__wrapper}>
                    <input
                        name="file"
                        type="file"
                        id="input__fileDel"
                        className={style.input + ' ' + style.input__file}
                        onChange={() => {
                            let addFileInp1 = document.getElementById('input__fileDel');
                            if (addFileInp1.files[0] !== null && addFileInp1.files[0] !== undefined) {
                                props.changeDocumentFileName(addFileInp1.files[0].name)
                            }
                        }}
                    />
                </div>
                <label for="input__fileDel" className={style.input__file_button}>
                    {fileElem}
                </label>
            </form>
        </div>
    )
}

function handleSubmit(e, props, sendData){
    e.preventDefault();
    promisUploadFiles(props, sendData)
}

async function promisUploadFilesNew(props, sendData){
    const addFileInp = document.getElementById('input__fileDel');
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload`;
    const formData = new FormData();
    formData.append('documentType', 'cancellationDetails')
    formData.append('file', addFileInp.files[0])
    const response =  await fetch(endPoint, {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': props.token.access_token,
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        props.changeLoader(false)
    }else{
        const json = await response.json()
        let body = {}
        if(
            (
                sendData.description !== null
                && sendData.description !== ''
            )
            &&(
                !isLLE(props.popUpDelAuc.sellingMethod)
                && !isLLD(props.popUpDelAuc.sellingMethod)
                && !isLLP(props.popUpDelAuc.sellingMethod)
                && !isSUE(props.popUpDelAuc.sellingMethod)
                && !isSUD(props.popUpDelAuc.sellingMethod)
                && !isBRW(props.popUpDelAuc.sellingMethod)
                && !isSPE(props.popUpDelAuc.sellingMethod)
                && !isSPD(props.popUpDelAuc.sellingMethod)
                && !isLPE(props.popUpDelAuc.sellingMethod)
            )
        ){
            body.reason = {}
            body.reason.uk_UA = sendData.description //props.deleteAuctionDescription///////
        }else if(
            isLLE(props.popUpDelAuc.sellingMethod) || isLLD(props.popUpDelAuc.sellingMethod) || isLLP(props.popUpDelAuc.sellingMethod) ||
            isSUE(props.popUpDelAuc.sellingMethod) || isSUD(props.popUpDelAuc.sellingMethod)
        ){
            //body.reason = sendData.reason
            //body.reason = {}
            //body.reason.uk_UA = sendData.description
            switch(sendData.reason){
                case '1':
                    body.reason = { uk_UA: "1"}
                    break
                case '2':
                    body.reason = { uk_UA: "2"}
                    break
                case '3':
                    body.reason = { uk_UA: "3"}
                    break
                case '4':
                    body.reason = { uk_UA: "4"}
                    break
                case '5':
                    body.reason = { uk_UA: "5"}
                    break
                case '6': 
                    body.reason = { uk_UA: "6"}
                    break;
                case '7': 
                    body.reason = { uk_UA: "7"}
                    break;
                default:
                    body.reason.uk_UA = ''
            }
        }else if(
            isSPE(props.popUpDelAuc.sellingMethod) || isSPD(props.popUpDelAuc.sellingMethod)
            || isLPE(props.popUpDelAuc.sellingMethod)
        ){
            //body.reason = sendData.reason
            //body.reason = {}
            //body.reason.uk_UA = sendData.description
            switch(sendData.reason){
                case '1':
                    body.reason = { uk_UA: "1"}
                    break
                case '2':
                    body.reason = { uk_UA: "2"}
                    break
                case '3':
                    body.reason = { uk_UA: "3"}
                    break
                case '4':
                    body.reason = { uk_UA: "4"}
                    break
                case '5':
                    body.reason = { uk_UA: "5"}
                    break
                case '6':
                    body.reason = { uk_UA: "6"}
                    break
                case '7':
                    body.reason = { uk_UA: "7"}
                    break
                default:
                    body.reason.uk_UA = ''
            }
        }else if(isBRW(props.popUpDelAuc.sellingMethod)){
            //body.reason = { uk_UA: "Порушено порядок підготовки до аукціону"}
        }
        body.datePublished = sendData.delDate //props.deleteDateCancelAuction
        body.documents = [
            {
                title: {
                    uk_UA: 'Скасування аукціону'
                },
                description: {
                    uk_UA: sendData.description
                },
                documentOf: 'cancellation',
                documentType: 'cancellationDetails',
                /*"relatedDocument": "string",
                "index": "string",
                "format": "string",*/
                language: "uk",
                token: json.jwt
            }
        ]
        if(sendData.description === null || sendData.description === ''){
            body.documents[0].description = sendData.description
        }else{
            body.documents[0].description = null
        }

        let endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.popUpDelAuc.id}/cancel`;
        
        //if(isLLE(props.popUpDelAuc.sellingMethod) || isLLD(props.popUpDelAuc.sellingMethod) || isLLP(props.popUpDelAuc.sellingMethod)) endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.popUpDelAuc.procedureId}/cancel`;
        
        const response2 = await fetch(endPoint, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
        }).catch(console.error)
        if(response2 === undefined || response2 === 'undefined'){
            props.changeLoader(false)
        }else{
            const json2 = await response2.json()
            if(json2.hasOwnProperty('message') === true){
                props.changeLoader(false)
                let tmp = JSON.stringify({title: 'Помилка при скасуванні аукціону', message: JSON.stringify(json2)})
                props.setUniversalError(tmp)
            }else{
                const addFileInp = document.getElementById('input__fileDel');
                addFileInp.value = null;
                props.changeDocumentFileName(null)
                props.setPopUpDelAuction(null)
                props.changeLoader(false)
                getAucionsList(props)
            }
        }
    }
}

async function promisUploadFiles(props, sendData){
    const addFileInp = document.getElementById('input__fileDel');
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload`;
    const formData = new FormData();
    formData.append('documentType', 'cancellationDetails')
    formData.append('file', addFileInp.files[0])
    const response =  await fetch(endPoint, {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': props.token.access_token,
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        props.changeLoader(false)
    }else{
        const json = await response.json()
        //const endPoint1 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/document`;
        const endPoint1 = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.popUpDelAuc.id}/document`;
        let body = {}
        body.title = 'Скасування аукціону'
        if(sendData.description !== '' && sendData.description !== null){
            body.description = sendData.description //props.deleteAuctionDescription
        }
        body.documentType = 'cancellationDetails'
        body.language = 'uk'
        body.documentOf = 'cancellation'
        //hash: "string",
        body.token = json.jwt 
        const response1 = await fetch(endPoint1, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
        }).catch(console.error)
        if(response1 === undefined || response1 === 'undefined'){
            props.changeLoader(false)
        }else{
            const json1 = await response1.json()
            let body = {}
            if(
                isBSE( props.popUpDelAuc.sellingMethod) || isBSD( props.popUpDelAuc.sellingMethod) ||
                isALE( props.popUpDelAuc.sellingMethod) ||
                isCSE( props.popUpDelAuc.sellingMethod) || isCSD( props.popUpDelAuc.sellingMethod) ||
                isBRE( props.popUpDelAuc.sellingMethod) || isBRD( props.popUpDelAuc.sellingMethod) || isBRW(props.popUpDelAuc.sellingMethod) ||
                isRLE( props.popUpDelAuc.sellingMethod) || isRLD( props.popUpDelAuc.sellingMethod) ||
                isCLE( props.popUpDelAuc.sellingMethod) || isCLD( props.popUpDelAuc.sellingMethod) ||
                isSPE( props.popUpDelAuc.sellingMethod) || isSPD( props.popUpDelAuc.sellingMethod) ||
                isNLE( props.popUpDelAuc.sellingMethod) || isNLD( props.popUpDelAuc.sellingMethod) ||
                isLAE( props.popUpDelAuc.sellingMethod) || isLAW( props.popUpDelAuc.sellingMethod) || isLAP( props.popUpDelAuc.sellingMethod) ||
                isLPE( props.popUpDelAuc.sellingMethod) ||
                isAPE( props.popUpDelAuc.sellingMethod) || isAPD(props.popUpDelAuc.sellingMethod)
                || isLLE(props.popUpDelAuc.sellingMethod) || isLLD(props.popUpDelAuc.sellingMethod) || isLLP(props.popUpDelAuc.sellingMethod)
                || isLRE(props.popUpDelAuc.sellingMethod) || isLSE(props.popUpDelAuc.sellingMethod) || isLSP(props.popUpDelAuc.sellingMethod)
            ){
                let tmpDoc = {}
                tmpDoc.title = {}
                tmpDoc.title.uk_UA = json1.title
                tmpDoc.documentOf = json1.documentOf
                tmpDoc.documentType = json1.documentType
                tmpDoc.token = json1.token
                body.documents = [tmpDoc]
            }else{
                body.documentIds = [json1.id]
            }
            body.datePublished = sendData.delDate //props.deleteDateCancelAuction
            if(isLLE(props.popUpDelAuc.sellingMethod) || isLLD(props.popUpDelAuc.sellingMethod) || isLLP(props.popUpDelAuc.sellingMethod)){
                body.reason = sendData.reason //props.deleteDateCancelAuctionReason
            }else{
                if(sendData.description !== null && sendData.description !== ''){
                    if(
                        isBSE( props.popUpDelAuc.sellingMethod) || isBSD( props.popUpDelAuc.sellingMethod) ||
                        isALE( props.popUpDelAuc.sellingMethod) ||
                        isCSE( props.popUpDelAuc.sellingMethod) || isCSD( props.popUpDelAuc.sellingMethod) ||
                        isBRE( props.popUpDelAuc.sellingMethod) || isBRD( props.popUpDelAuc.sellingMethod) || isBRW(props.popUpDelAuc.sellingMethod) ||
                        isRLE( props.popUpDelAuc.sellingMethod) || isRLD( props.popUpDelAuc.sellingMethod) ||
                        isCLE( props.popUpDelAuc.sellingMethod) || isCLD( props.popUpDelAuc.sellingMethod) ||
                        isSPE( props.popUpDelAuc.sellingMethod) || isSPD( props.popUpDelAuc.sellingMethod) ||
                        isNLE( props.popUpDelAuc.sellingMethod) || isNLD( props.popUpDelAuc.sellingMethod) ||
                        isLAE( props.popUpDelAuc.sellingMethod) || isLAW( props.popUpDelAuc.sellingMethod) || isLAP( props.popUpDelAuc.sellingMethod) ||
                        isLPE( props.popUpDelAuc.sellingMethod) ||
                        isAPE( props.popUpDelAuc.sellingMethod) || isAPD(props.popUpDelAuc.sellingMethod)
                        //|| isLLE( props.popUpDelAuc.sellingMethod)
                    ){
                        body.reason = {}
                        body.reason.uk_UA = sendData.description
                    }else{
                        body.reason = sendData.description //props.deleteAuctionDescription
                    }
                }
            }
            let endPoint = ''
            if(
                isBSE( props.popUpDelAuc.sellingMethod) || isBSD( props.popUpDelAuc.sellingMethod) ||
                isALE( props.popUpDelAuc.sellingMethod) ||
                isCSE( props.popUpDelAuc.sellingMethod) || isCSD( props.popUpDelAuc.sellingMethod) ||
                isBRE( props.popUpDelAuc.sellingMethod) || isBRD( props.popUpDelAuc.sellingMethod) || isBRW(props.popUpDelAuc.sellingMethod) ||
                isRLE( props.popUpDelAuc.sellingMethod) || isRLD( props.popUpDelAuc.sellingMethod) ||
                isCLE( props.popUpDelAuc.sellingMethod) || isCLD( props.popUpDelAuc.sellingMethod) ||
                isSPE( props.popUpDelAuc.sellingMethod) || isSPD( props.popUpDelAuc.sellingMethod) ||
                isNLE( props.popUpDelAuc.sellingMethod) || isNLD( props.popUpDelAuc.sellingMethod) ||
                isLAE( props.popUpDelAuc.sellingMethod) || isLAW( props.popUpDelAuc.sellingMethod) || isLAP( props.popUpDelAuc.sellingMethod) ||
                isLPE( props.popUpDelAuc.sellingMethod) ||
                isAPE( props.popUpDelAuc.sellingMethod) || isAPD(props.popUpDelAuc.sellingMethod)
                || isLLE( props.popUpDelAuc.sellingMethod) || isLLD( props.popUpDelAuc.sellingMethod) || isLLP( props.popUpDelAuc.sellingMethod)
                || isLRE(props.popUpDelAuc.sellingMethod) || isLSE(props.popUpDelAuc.sellingMethod) || isLSP(props.popUpDelAuc.sellingMethod)
            ){
                endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.popUpDelAuc.id}/cancel`;
            }else{
                endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpDelAuc.dataAuction.mysqlDraftId}/cancel`;
            }
            const response2 = await fetch(endPoint, {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response2 === undefined || response2 === 'undefined'){
                props.changeLoader(false)
            }else{
                const json2 = await response2.json()
                if(json2.hasOwnProperty('message') === true){
                    props.changeLoader(false)
                    let tmp = JSON.stringify({title: 'Помилка при скасуванні аукціону', message: JSON.stringify(json2)})
	                props.setUniversalError(tmp)
                }else{
                    const addFileInp = document.getElementById('input__fileDel');
                    addFileInp.value = null;
                    props.changeDocumentFileName(null)
                    props.setPopUpDelAuction(null)
                    props.changeLoader(false)
                    getAucionsList(props)
                }
            }
        }
    }
}


async function getAucionsList(props){
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-drafts/my`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціонів', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            const tmp = await createList(json)
            if(tmp.length > 0){
                props.setDataAddress(tmp, 'AUCTIONS_GET_MY')
            }else{
                props.setDataAddress(null, 'AUCTIONS_GET_MY')
            }

        }
    }
    props.changeLoader(false)
}

function createList(json){
    let tmp = []
    if(json.length > 0){
        for(let i = 0; i < json.length; i++){
            if(json[i].publicAuction !== null){
                tmp[i] = json[i].publicAuction
                tmp[i].dataAuction = json[i]
            }else if(json[i].oldAuctionDraft !== null){
                tmp[i] = json[i].oldAuctionDraft
                tmp[i].dataAuction = json[i]
            }else if(json[i].auctionDraft !== null){
                tmp[i] = json[i].auctionDraft
                tmp[i].dataAuction = json[i]
            }
        }
    }
    return tmp
}

async function  deleteAuction(props, aucId){

    let endPoint = ``;
    if(
        isBSE( props.popUpDelAuc.sellingMethod) || isBSD( props.popUpDelAuc.sellingMethod) ||
        isALE( props.popUpDelAuc.sellingMethod) ||
        isCSE( props.popUpDelAuc.sellingMethod) || isCSD( props.popUpDelAuc.sellingMethod) ||
        isBRE( props.popUpDelAuc.sellingMethod) || isBRD( props.popUpDelAuc.sellingMethod) || isBRW(props.popUpDelAuc.sellingMethod) ||
        isRLE( props.popUpDelAuc.sellingMethod) || isRLD( props.popUpDelAuc.sellingMethod) ||
        isCLE( props.popUpDelAuc.sellingMethod) || isCLD( props.popUpDelAuc.sellingMethod) ||
        isSPE( props.popUpDelAuc.sellingMethod) || isSPD( props.popUpDelAuc.sellingMethod) ||
        isNLE( props.popUpDelAuc.sellingMethod) || isNLD( props.popUpDelAuc.sellingMethod) ||
        isLAE( props.popUpDelAuc.sellingMethod) || isLAW( props.popUpDelAuc.sellingMethod) || isLAP( props.popUpDelAuc.sellingMethod) ||
        isLPE( props.popUpDelAuc.sellingMethod) ||
        isAPE( props.popUpDelAuc.sellingMethod) || isAPD(props.popUpDelAuc.sellingMethod)
        || isLLE( props.popUpDelAuc.sellingMethod) || isLLD( props.popUpDelAuc.sellingMethod) || isLLP( props.popUpDelAuc.sellingMethod)
        || isLRE(props.popUpDelAuc.sellingMethod) || isLSE(props.popUpDelAuc.sellingMethod) || isLSP(props.popUpDelAuc.sellingMethod)
    ){
        if(String(props.popUpDelAuc.id).includes("-") === true){
            endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.popUpDelAuc.id}`;
        }else{
            endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpDelAuc.dataAuction.mysqlDraftId}`;
        }
    }else{
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpDelAuc.dataAuction.mysqlDraftId}`;
    }
    const response1 = await fetch(endPoint, {
        method: "DELETE",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
    }else{
        const json1 = await response1.json()
        if(json1.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при скасуванні аукціону', message: JSON.stringify(json1)})
	        props.setUniversalError(tmp)
        }else{
            //const url2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${aucId}/sync`;
            //const response2 = await fetch(url2, {
            //    method: "GET",
            //    headers: {
            //        'Authorization': props.token.access_token,
            //        'Content-Type': 'application/json'
            //    }
            //}).catch(console.error)
            //if(response2 === undefined || response2 === 'undefined'){
            //}else{
            //    if(json1.hasOwnProperty('message') === true){
            //        props.setPopUpDelAuction(null)
            //    }else{
                    props.setPopUpDelAuction(null)
                    // let data = {
                    //     domen: process.env.REACT_APP_END_POINT_BD,
                    //     version: 'v1',
                    //     local: props.langFlag,
                    //     params: '?limit=10'
                    // }
                    // let header = {
                    //     'Authorization': props.token.access_token,
                    //     'Content-Type': 'application/json'
                    // }
                    //props.auctionsGetMy(data, header)
                    getAucionsList(props)
            //    }
            //}
        }
    }
}

/*
async function cancelAuction(props, aucId){
    let tmp = [];
    if(props.popUpDelAuc !== null){
        if(props.popUpDelAuc.documents.length > 0){
            for(let i = 0; i < props.popUpDelAuc.documents.length; i++){
                tmp.push(props.popUpDelAuc.documents[i].id)
            }
        }
    }
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpDelAuc.id}/cancel`;
    const response1 = await fetch(endPoint, {
        method: "post",
        body: JSON.stringify({
            documentIds: tmp,
            reason: "string",
            datePublished: new Date()
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response1 !== undefined || response1 !== 'undefined'){
        props.setPopUpAuctionMyNull()
        let data = {
            domen: process.env.REACT_APP_END_POINT_BD,
            version: 'v1',
            local: props.langFlag,
            params: '?limit=20'
        }
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        props.auctionsGetMy(data, header)
    }
}
*/

async function deleteMongoDrafts(props, id){
    props.changeLoader(true)
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-drafts/${id}`;
    const response1 = await fetch(url, {
        method: "DELETE",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
        props.changeLoader(false)
    }else{
        const json1 = await response1.json()
        if(json1.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при видаленні', message: JSON.stringify(json1)})
	        props.setUniversalError(tmp)
        }else{
            getAucionsList(props)
        }
    }
    props.setPopUpDelAuction(null)
    await props.changeLoader(false)
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        langFlag: state.start.langFlag,
        popUpDelAuc: state.start.popUpDelAuc,
        deleteAuctionDescription: state.start.deleteAuctionDescription,
        popUpAuctionDeleteTitle: state.start.popUpAuctionDeleteTitle,
        deleteDateCancelAuction: state.start.deleteDateCancelAuction,
        createDocuments: state.start.createDocuments,
        deleteDateCancelAuctionReason: state.start.deleteDateCancelAuctionReason,
    }
}

const mapDispatchToProps = {
    setPopUpDelAuction,
    auctionsGetMy,
    setDeleteAuctionDescription,
    setPopUpAuctionMyNull,
    setPopUpAuctionDeleteTitle,
    setPopUpAuctionDeleteDate,

    setUniversalError,
    changeLoader,
    changeDocumentFileName,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(PopUpDelAuction)