import React, { useState, useEffect } from 'react';
import styles from './leaseRules.module.css';
// import { useDispatch, useSelector } from 'react-redux';

import Input from '../../../../../../elements/inputs/input'
import Checkbox from '../../../../../../elements/inputs/checkBox'
import TextArea from '../../../../../../elements/inputs/textArea'
import Select from '../../../../../../elements/inputs/inputSelect'
import MultiSelect from '../../../../../../elements/inputs/multiSelect/inputMultiSelectLLE';
import Period from './period/period';

import { checkProp } from '../../../../../../../redux/check';

const LeaseRules = ({ data, updateData, disabled }) => {
    const [leaseRuless, setLeaseRules] = useState({
            intendedUse: data.intendedUse,
            //leaseDuration: data.leaseDuration, // Строк оренди P2Y11M
            intendedUseRestrictionMethod: data.intendedUseRestrictionMethod,
            intendedUseRestrictionDescription: data.intendedUseRestrictionDescription,
            additionalLeaseConditions: data.additionalLeaseConditions,
            subleaseAllowed: data.subleaseAllowed
    });

    const [hoursBy, setHoursBy] = useState(checkProp(data.schedule, 'hoursBy') ? data.schedule.hoursBy : {by: null, value: null});
    const hoursByVal = [
        { name: 'День', value: 'day'},
        { name: 'Тиждень', value: 'week'},
        { name: 'Місяць', value: 'month'}
    ]
    const [daysBy, setDaysBy] = useState(checkProp(data.schedule, 'daysBy') ? data.schedule.daysBy : {by: null, value: null});
    const daysByVal = [
        { name: 'Тиждень', value: 'week'},
        { name: 'Місяць', value: 'month'}
    ]
    const [period, setPeriod] = useState(checkProp(data.schedule, 'period') ? data.schedule.period : []);
    const [schedule, setSchedule] = useState(
        { //Пропонований графік оренди
            other: null, // Інший графік використання
            hoursBy: hoursBy,
            daysBy: daysBy,
            period: period !== null && period.length > 0 ? period : null
        });
    const [graphicHourStatus, setGraphicHourStatus] = useState(checkProp(data.schedule, 'period') && data.schedule.period.length > 0 ? true : false);
    // const [graphicEventStatus, setGraphicEventStatus] = useState(false);

    const [intendedUse, setIntendeduse] = useState(null);
    const [intendedUseList, setIntendedUseList] = useState([]);

    useEffect( () => {
        async function getintendedUseList() {
            await fetch(`${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/dictionaries/intendedUse`)
            .then( (response) => {if (response.status == 200) return response.json()})
            .then( data => setIntendedUseList(data))
        }
        if (intendedUseList.length === 0) getintendedUseList()
    }, []);

    useEffect( () => {
        setSchedule({ ...schedule, daysBy: daysBy})
    }, [daysBy]);

    useEffect(() => {
        setSchedule({ ...schedule, hoursBy: hoursBy})
    }, [hoursBy]);

    useEffect(() => {
        setSchedule({...schedule, period: period !== null && period.length > 0 ? period : null})
    }, [period]);

    useEffect(() => {
        setLeaseRules({...leaseRuless, schedule: schedule})
    }, [schedule]);

    useEffect(() => {
        updateData(leaseRuless)
    }, [leaseRuless]);

    function getLeseDuration(value, type){
        if(type === 'hours'){
            switch(value){
            case 'day': return 24
            case 'week': return 168
            case 'month': return 744
            default: return
            }
        }
        if(type === 'days'){
            switch(value){
            case 'week': return 7
            case 'month': return 31
            default: return
            }
        }
    }

    const intendedUseRestrictionMethodList = [
        {value: 'exceptDescribed', name: 'Окрім зазначеного'},
        {value: 'noRestrictions', name: 'Без обмежень'},
        {value: 'onlyDescribed', name: 'Тільки зазначене'}
    ]

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>Правила та умови передачі об`єкта в оренду</div>
            <div className={styles.subtitle}>Пропонований графік оренди</div>
            <div className={styles.scheduleBy}>
                <div className={styles.scheduleHours}>
                    <Input
                        value={hoursBy.value}
                        type='number'
                        label="Годин"
                        min={0}
                        max={getLeseDuration(hoursBy.by, 'hours')}
                        onChange={e => { 
                            if(+e <= getLeseDuration(hoursBy.by, 'hours')) setHoursBy({ ...hoursBy, value: e })
                            if(+e > getLeseDuration(hoursBy.by, 'hours')) setHoursBy({ ...hoursBy, value: getLeseDuration(hoursBy.by, 'hours') })
                            }
                        }
                        maxlength={3}
                        reqiured
                        //disabled={disabled}
                    />
                    <Select
                        data={{
                            target: hoursBy.by,
                            label: "На"
                        }}
                        onChange={e => setHoursBy({ ...hoursBy, by: e })}
                        options={hoursByVal}
                        //disabled={disabled}
                    />
                </div>
                <div className={styles.scheduleDays}>
                    <Input
                        value={daysBy.value}
                        type='number'
                        label="Днів"
                        min={0}
                        max={getLeseDuration(daysBy.by, 'days')}
                        onChange={e => { 
                            if(+e <= getLeseDuration(daysBy.by, 'days')) setDaysBy({ ...daysBy, value: e })
                            if(+e > getLeseDuration(daysBy.by, 'days')) setDaysBy({ ...daysBy, value: getLeseDuration(daysBy.by, 'days') })
                            }}
                        maxlength={2}
                        reqiured
                        //disabled={disabled}
                    />
                    <Select
                        data={{
                            target: daysBy.by,
                            label: "На"
                        }}
                        onChange={e => setDaysBy({ ...daysBy, by: e })}
                        options={daysByVal}
                        //disabled={disabled}
                    />
                </div>
            </div>
            <Checkbox 
                value={graphicHourStatus}
                onChange={ e => setGraphicHourStatus(!graphicHourStatus)} 
                label="Графік погодинного використання"
                //disabled={disabled}
            />
            {graphicHourStatus
                ? <>
                    { period !== null && period.length > 0
                        ? period.map((i, count) => {
                            return <Period 
                                key={i}
                                index={count}
                                data={i}
                                period={period}
                                setPeriod={setPeriod}
                                //disabled={disabled}
                            />
                        })
                        : null
                    }
                    <button 
                        className={styles.addPeriodButton} 
                        onClick={() => { 
                            let tmp = {
                                startDate: null,
                                endDate: null,
                                weekdays:[]
                            }
                            setPeriod([...period, tmp]) 
                            }}>
                            Додати період
                    </button>
                </>
                : null
            }
            <div className={styles.subtitle}>Цільове призначення об`єкта</div>
            <Select
                data={{
                    target: leaseRuless.intendedUseRestrictionMethod,
                    label: "Спосіб обмеження цільового призначення об`єкта"
                }}
                onChange={e => setLeaseRules( { ...leaseRuless, intendedUseRestrictionMethod: e })}
                options={intendedUseRestrictionMethodList}
                text="Не вказано"
                disabled={disabled}
            />
            {leaseRuless.intendedUseRestrictionMethod === 'exceptDescribed' ?
                <MultiSelect
                    label="Додавання цільового призначення"
                    value={leaseRuless.intendedUse}
                    onChange={(e) => {
                        setLeaseRules({ ...leaseRuless, intendedUse: e })
                    }}
                    options={intendedUseList}
                    dictionary={true}
                    maxLength={5}
                    disabled={disabled}
                /> : null
            }
            {leaseRuless.intendedUseRestrictionMethod === 'exceptDescribed' || leaseRuless.intendedUseRestrictionMethod === 'onlyDescribed' ?
                <TextArea
                    value={leaseRuless.intendedUseRestrictionDescription}
                    onChange={e => setLeaseRules({ ...leaseRuless, intendedUseRestrictionDescription: e })}
                    label="Опис обмеженнь цільового призначення об`єкта (за наявності)"
                    disabled={disabled}
                /> : null
            }
            <TextArea
                value={leaseRuless.additionalLeaseConditions}
                onChange={e => setLeaseRules({ ...leaseRuless, additionalLeaseConditions: e })}
                label="Додаткові умови оренди майна (за наявності)"
                disabled={disabled}
            />
            <Checkbox
                onChange={e => setLeaseRules({ ...leaseRuless, subleaseAllowed: e })}
                //checked={leaseRuless.subleaseAllowed}
                value={leaseRuless.subleaseAllowed}
                label="Можливість суборенди"
                disabled={disabled}
            />
        </div>
    )
}

export default LeaseRules;