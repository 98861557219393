//Elem 0 = ua, 1 = en
export const PLACEHOLDER_FIRST_FIND_LANG = ['визначте слово для пошуку', 'enter keyword for search'];

export const FIRST_SELECTED_FIRST_FIND_LANG = [
    {
        option: [
            {value: "title_direct", text: "точна відповідність"},
            {value: "org", text: "за організатором (назва/ЄДРПОУ)"},
            {value: "cav", text: "за кодом CAV"},
            {value: "auctionID", text: "за AuctionID"},
            {value: "dgfID", text: "за номером лоту в ФГВ"}
        ]
    },
    {
        option: [
            {value: "title_direct", text: "exact match"},
            {value: "org", text: "by purchaser (name / USREOU)"},
            {value: "cav", text: "by ContractID"},
            {value: "auctionID", text: "by AuctionID"},
            {value: "dgfID", text: "by lot number in DGF"}
        ]
    }
];

export const SECOND_SELECTED_FIRST_FIND_LANG = [
    {
        option: [
            {value: "1", text: "Всі регіони"},
            {value: "2809", text: "м. Київ"},
            {value: "1423", text: "АР Крим"},
            {value: "1424", text: "Вінницька область"},
            {value: "1425", text: "Волинська область"},
            {value: "1426", text: "Дніпропетровська область"},
            {value: "1427", text: "Донецька область"},
            {value: "1428", text: "Івано-Франківська область"},
            {value: "1429", text: "Житомирська область"},
            {value: "1430", text: "Закарпатська область"},
            {value: "1431", text: "Запорізька область"},
            {value: "1422", text: "Київська область"},
            {value: "1432", text: "Кіровоградська область"},
            {value: "1433", text: "Луганська область"},
            {value: "1434", text: "Львівська область"},
            {value: "1435", text: "Миколаївська область"},
            {value: "1436", text: "Одеська область"},
            {value: "1437", text: "Полтавська область"},
            {value: "1438", text: "Рівненська область"},
            {value: "1439", text: "Сумська область"},
            {value: "1440", text: "Тернопільська область"},
            {value: "1441", text: "Харківська область"},
            {value: "1443", text: "Хмельницька область"},
            {value: "1442", text: "Херсонська область"},
            {value: "1444", text: "Черкаська область"},
            {value: "1446", text: "Чернігівська область"},
            {value: "1445", text: "Чернівецька область"}
        ]
    },
    {
        option: [
            {value: "1", text: "all regions"},
            {value: "2809", text: "Kyiv"},
            {value: "1423", text: "AR Crimea"},
            {value: "1424", text: "Vinnytsia Oblast"},
            {value: "1425", text: "Volyn Oblast"},
            {value: "1426", text: "Dnipropetrovsk Oblast"},
            {value: "1427", text: "Donetsk Oblast"},
            {value: "1428", text: "Ivano-Frankivsk Oblast"},
            {value: "1429", text: "Zhytomyr Oblast"},
            {value: "1430", text: "Zakarpattia Oblast"},
            {value: "1431", text: "Zaporizhia Oblast"},
            {value: "1422", text: "Kyiv Oblast"},
            {value: "1432", text: "Kirovohrad Oblast"},
            {value: "1433", text: "Luhansk Oblast"},
            {value: "1434", text: "Lviv Oblast"},
            {value: "1435", text: "Mykolaiv Oblast"},
            {value: "1436", text: "Odessa Oblast"},
            {value: "1437", text: "Poltava Oblast"},
            {value: "1438", text: "Rivne Oblast"},
            {value: "1439", text: "Sumy Oblast"},
            {value: "1440", text: "Ternopil Oblast"},
            {value: "1441", text: "Kharkiv Oblast"},
            {value: "1443", text: "Khmelnytskyi Oblast"},
            {value: "1442", text: "Kherson Oblast"},
            {value: "1444", text: "Cherkasy Oblast"},
            {value: "1446", text: "Chernihiv Oblast"},
            {value: "1445", text: "Chernivtsi Oblast"}
        ]
    }
];

export const BUTTON_FIRST_FIND_LANG = ['Пошук', 'Search'];