import {
    addProperty,
    addPropertyMonga,
    addPropertyValue,
    addPropertyDiscount,
    addAuctionPeriodProperty,
    addBankProperty,
    addDebtorInformation,
    addSellingEntity,
    addAssetHolder
} from './utilsCreateBody'

export function сreateBodyLPE(data, old){
    if(data !== null){
        let body = {}
        // body = addPropertyMonga(data, body, 'title')
        // body = addPropertyMonga(data, body, 'description')
        body = addPropertyMonga(data, body, 'accessDetails')
        // body = addProperty(data, body, 'tenderAttempts')
        //body = addProperty(data, body, 'lotId')
        // body = addAuctionPeriodProperty(data, body, 'auctionPeriod')
        // body = addProperty(data, body, 'sellingMethod')
        //body = addPropertyMonga(data, body, 'x_documentRequirements')
        //body = addPropertyMonga(data, body, 'x_additionalInformation')
        // body = addProperty(data, body, 'minNumberOfQualifiedBids')
        // body = addProperty(data, body, 'ownershipType')
        // body = addProperty(data, body, 'valueAddedTaxCharged')
        
        // body = addPropertyValue(data, body, 'value')
        // body = addPropertyValue(data, body, 'minimalStep')
        // if(+body.tenderAttempts > 1) body = addPropertyValue(data, body, 'guarantee')
        // body = addPropertyValue(data, body, 'registrationFee')
        // body = addProperty(data, body, 'previousAuctionId')
        // body = addPropertyDiscount(data, body, 'discount')
        body = addBankProperty(data, body, 'banks')
        body = addDebtorInformation(data, body, 'sellingEntity')
        body = addAssetHolder(data, body, 'assetHolder')
        
        // body.items = old.items
        // body.documents = old.documents
        return body
    }
    return null
}