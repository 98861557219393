import {
    CREATE_LEASE_RULES_INTENDED_USE,
    CREATE_LEASE_RULES_INTENDED_USE_RESTRICTION_METHOD,
    CREATE_LEASE_RULES_INTENDED_USE_RESTRICTION_DESCRIPTION,
    CREATE_LEASE_RULES_ADDITIONAL_LEASE_CONDITIONS,
    CREATE_LEASE_RULES_SUBLEASE_ALLOWED,
    CREATE_VALUE_PERIOD,
    CREATE_REGISTRATION_FEE,
    CREATE_REGISTRY_ID,
    CREATE_VALUE_ADDED_TAX_CHARGED,
    CREATE_CALC_ON_BOOK_VALUE,

    CREATE_ITEMS_DGF_MINNUMBEROFQUALIFIEDBIDS,
    CREATE_ITEMS_DGF_VIRTUALDATAROOM,
    CREATE_ITEMS_DGF_ELIGIBILITYCRITERIA,
    CREATE_ITEMS_DGF_X_DGFPLATFORMLEGALDETAILS,
    CREATE_ITEMS_DGF_X_DGFDECISION_ID,
    CREATE_ITEMS_DGF_X_DGFDECISION_DATE,

    SET_MY_CURRENT_PAGE,
    SET_MY_CABINET_PAGE,
    CREATE_ITEMS_DGF_REALESTATEPROPS_ID,
    CREATE_ITEMS_DGF_REALESTATEPROPS_TOTALBUILDINGAREA,
    CREATE_ITEMS_DGF_REALESTATEPROPS_TOTALOBJECTAREA,
    CREATE_ITEMS_DGF_REALESTATEPROPS_USABLEAREA,
    CREATE_ITEMS_DGF_REALESTATEPROPS_CONSTRUCTIONYEAR,
    CREATE_ITEMS_DGF_REALESTATEPROPS_CONSTRUCTIONTECHNOLOGY,
    CREATE_ITEMS_DGF_REALESTATEPROPS_LIVINGAREA,
    CREATE_ITEMS_DGF_REALESTATEPROPS_KITCHENAREA,
    CREATE_ITEMS_DGF_REALESTATEPROPS_LANDAREA,
    CREATE_ITEMS_DGF_REALESTATEPROPS_LOCATIONINBUILDING,
    CREATE_ITEMS_DGF_REALESTATEPROPS_FLOORS,
    CREATE_ITEMS_DGF_REALESTATEPROPS_GENERALCONDITION,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEELECTRICITY,
    CREATE_ITEMS_DGF_REALESTATEPROPS_POWERSUPPLYCAPACITY,
    CREATE_ITEMS_DGF_REALESTATEPROPS_POWERSUPPLYCLASS,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEWATER,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESEWERAGE,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEGAS,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICECENTRALHEATING,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEAUTONOMOUSHEATING,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEHEATINGCOUNTER,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEVENTILATION,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEAIRCONDITIONING,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEPHONE,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICETV,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEINTERNET,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEELEVATOR,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESECURITYALARM,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEFIREALARM,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESDESCRIPTION,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESACCOUNTING,
    CREATE_ITEMS_DGF_REALESTATEPROPS_LANDTAX,

    CREATE_ITEMS_DGF_VEHICLEPROPS_ID,
    CREATE_ITEMS_DGF_VEHICLEPROPS_BRAND,
    CREATE_ITEMS_DGF_VEHICLEPROPS_MODEL,
    CREATE_ITEMS_DGF_VEHICLEPROPS_PRODUCTIONYEAR,
    CREATE_ITEMS_DGF_VEHICLEPROPS_ENGINECAPACITY,
    CREATE_ITEMS_DGF_VEHICLEPROPS_FUELTYPE,
    CREATE_ITEMS_DGF_VEHICLEPROPS_TRANSMISSION,
    CREATE_ITEMS_DGF_VEHICLEPROPS_COLOR,
    CREATE_ITEMS_DGF_VEHICLEPROPS_KILOMETRAGE,
    CREATE_ITEMS_DGF_VEHICLEPROPS_IDENTIFICATIONNUMBER,
    CREATE_ITEMS_DGF_VEHICLEPROPS_CONFIGURATION,
    CREATE_ITEMS_DGF_VEHICLEPROPS_CONDITION,
    CREATE_ITEMS_DGF_VEHICLEPROPS_DAMAGEPRESENCE,
    CREATE_ITEMS_DGF_VEHICLEPROPS_DAMAGEDDESCRIPTION,

    CREATE_ITEMS_DGF_LANDPROPS_ID,
    CREATE_ITEMS_DGF_LANDPROPS_LANDAREA,
    CREATE_ITEMS_DGF_LANDPROPS_CADASTRALNUMBER,
    CREATE_ITEMS_DGF_LANDPROPS_OWNERSHIPTYPE,
    CREATE_ITEMS_DGF_LANDPROPS_ENCUMBRANCES,
    CREATE_ITEMS_DGF_LANDPROPS_JOINTOWNERSHIP,
    CREATE_ITEMS_DGF_LANDPROPS_UTILITIESAVAILABILITY,
    CREATE_ITEMS_DGF_LANDPROPS_INTENDEDUSE,

    CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_ID,
    CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_QUANTITYANDNOMENCLATURE,
    CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_WORKPLACESQUANTITY,
    CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_BUILDINGSINFORMATION,
    CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_LANDPLOTINFORMATION,
    CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_MAINOBLIGATIONS,

    CREATE_ITEMS_LOCATION_LATITUDE,
    CREATE_ITEMS_LOCATION_LONGITUDE,
    CREATE_ITEMS_LOCATION_ELEVATION,

    SET_NADRA_CLASSIFICATOR_ADD,
    CHECKBOX_BID_PUBLISH,
    SET_END_POINT,
    SET_MIN_NUMBER_OF_QUALIFIED_BIDS,
    UNIVERSAL_ERROR,
    AWARD_ALL_USER_POPUP,
    AWARD_ALL_USER_POPUP_USER,
    CHANGE_LOADER,
    SET_POPUP_LOGIN,

    CHANGE_MENU,
    CHANGE_MENU_ON_OFF,
    CHANGE_WINDOW,
    CHANGE_LANG,
    CHANGE_ADVANCED_FIND,
    BID_AWARDS,
    BID_QUANTITY,
    CREATE_AUCTION_TAB,

    CREATE_AUCTION_TITLE,
    CREATE_AUCTION_DESCRIPTION,
    CREATE_AUCTION_TYPE,
    CREATE_AUCTION_TENDER_ATTEMPTS,
    CREATE_AUCTION_ACCESS_DETAILS,
    CREATE_AUCTION_XDOCUMENT_REQUIREMENTS,
    CREATE_AUCTION_XADDITIONAL_INFORMATION,
    CREATE_AUCTION_VALUE_CURRENCY,
    CREATE_AUCTION_VALUE_AMOUNT,
    CREATE_AUCTION_VALUE_TAX,
    CREATE_AUCTION_GUARANTEE_CURRENCY,
    CREATE_AUCTION_GUARANTEE_AMOUNT,
    CREATE_AUCTION_GUARANTEE_TAX,
    CREATE_AUCTION_MINIMAL_STEP_CURRENCY,
    CREATE_AUCTION_MINIMAL_STEP_AMOUNT,
    CREATE_AUCTION_MINIMAL_STEP_TAX,
    CREATE_AUCTION_DATE_START,
    CREATE_AUCTION_DATE_END,
    CREATE_AUCTION_ID,
    CREATE_ITEMS_QUANTITY,
    CREATE_ITEMS_IDS,

    CREATE_ITEMS_AUCTION_ID,
    CREATE_ITEMS_CLASSIFICATION_ID,
    CREATE_ITEMS_UNIT_VALUE_CURRENCY,
    CREATE_ITEMS_UNIT_VALUE_AMOUNT,
    CREATE_ITEMS_UNIT_VALUE_TAX,
    CREATE_ITEMS_DESCRIPTION,
    CREATE_ITEMS_ADDITIONAL_CLASSIFICATION_IDS,
    CREATE_ITEMS_ADDRESS,

    NUMBER_PAGE_LOT,
    CREATE_CHANGET_AUCTION,

    CREATE_AUCTION_LOT_ID,
    CREATE_AUCTION_RAILWAY_LOT_TYPE,

    SET_CARRY_TYPE,
    SET_WAGON_SPECIES,
    SET_LOADING_RESTRICTION,
    SET_WAGON_TYPES,

    SET_DATE_FROM_1,
    SET_DATE_TILL_1,
    SET_DATE_FROM_2,
    SET_DATE_TILL_2,
    SET_ROUTE_QUANTITY,
    SET_ROUTE_ACCOUNTING_ROUTE_NUMBER,
    SET_ROUTE_ACCOUNTING_WAGONS_PER_ROUTE_QUANTITY,
    SET_RAILWAY_CLASSIFICATOR_ADD,
    SET_CREATE_ITEM_RESTRICT,

    CHANGE_TOGETHER_API,

    CREATE_AUCTION_RAILWAY_DUTCH_STEP_QUANTITY,
    CREATE_AUCTION_RAILWAY_DUTCH_STEP_PERCENT,
    CREATE_AUCTION_RAILWAY_DUTCH_STEP_VALUE_AMOUNT,
    EDIT_AUCTION_FROM_ID,
} from "./types";

export function getMyAuction(value) {
    return {
        type: CREATE_CHANGET_AUCTION,
        payload: value
    }
}

export function changeMenu(value) {
    return {
        type: CHANGE_MENU,
        payload: value
    }
}

export function changeWindow(value) {
    return {
        type: CHANGE_WINDOW,
        payload: value
    }
}

export function changeBidAward(value) {
    return {
        type: BID_AWARDS,
        payload: value
    }
}

export function changeBidQuantity(value) {
    return {
        type: BID_QUANTITY,
        payload: value
    }
}

export function changeCreateAuctionTab(value) {
    return {
        type: CREATE_AUCTION_TAB,
        payload: value
    }
}

export function changeCreateAuctionTitle(value) {
    return {
        type: CREATE_AUCTION_TITLE,
        payload: value
    }
}

export function changeCreateAuctionDescription(value) {
    return {
        type: CREATE_AUCTION_DESCRIPTION,
        payload: value
    }
}

export function changeCreateAuctionType(value) {
    return {
        type: CREATE_AUCTION_TYPE,
        payload: value
    }
}

export function changeAdditionalClassificationIds(value) {
    return {
        type: CREATE_ITEMS_ADDITIONAL_CLASSIFICATION_IDS,
        payload: value
    }
}

export function changeCreateItemQuantity(value) {
    return {
        type: CREATE_ITEMS_QUANTITY,
        payload: value
    }
}

export function changeCreateItemIds(value) {
    return {
        type: CREATE_ITEMS_IDS,
        payload: value
    }
}

export function changeCreateAuctionTenderAttempts(value) {
    return {
        type: CREATE_AUCTION_TENDER_ATTEMPTS,
        payload: value
    }
}

export function changeCreateAuctionAccessDetails(value) {
    return {
        type: CREATE_AUCTION_ACCESS_DETAILS,
        payload: value
    }
}

export function changeCreateAuctionXDocumentRequirements(value) {
    return {
        type: CREATE_AUCTION_XDOCUMENT_REQUIREMENTS,
        payload: value
    }
}

export function changeCreateAuctionXAdditionalInformation(value) {
    return {
        type: CREATE_AUCTION_XADDITIONAL_INFORMATION,
        payload: value
    }
}

export function changeCreateAuctionValueCurrency(value) {
    return {
        type: CREATE_AUCTION_VALUE_CURRENCY,
        payload: value
    }
}

export function changeCreateAuctionValueAmount(value) {
    return {
        type: CREATE_AUCTION_VALUE_AMOUNT,
        payload: value
    }
}

export function changeCreateAuctionValueTax(value) {
    return {
        type: CREATE_AUCTION_VALUE_TAX,
        payload: value
    }
}

export function changeCreateAuctionGuaranteeCurrency(value) {
    return {
        type: CREATE_AUCTION_GUARANTEE_CURRENCY,
        payload: value
    }
}

export function changeCreateAuctionGuaranteeAmount(value) {
    return {
        type: CREATE_AUCTION_GUARANTEE_AMOUNT,
        payload: value
    }
}

export function changeCreateAuctionGuaranteeTax(value) {
    return {
        type: CREATE_AUCTION_GUARANTEE_TAX,
        payload: value
    }
}

export function changeCreateAuctionMinimalStepCurrency(value) {
    return {
        type: CREATE_AUCTION_MINIMAL_STEP_CURRENCY,
        payload: value
    }
}

export function changeCreateAuctionMinimalStepAmount(value) {
    return {
        type: CREATE_AUCTION_MINIMAL_STEP_AMOUNT,
        payload: value
    }
}

export function changeCreateAuctionMinimalStepTax(value) {
    return {
        type: CREATE_AUCTION_MINIMAL_STEP_TAX,
        payload: value
    }
}

export function changeCreateAuctionDateStart(value) {
    return {
        type: CREATE_AUCTION_DATE_START,
        payload: value
    }
}

export function changeCreateAuctionDateEnd(value) {
    return {
        type: CREATE_AUCTION_DATE_END,
        payload: value
    }
}

export function changeCreateAuctionId(value) {
    return {
        type: CREATE_AUCTION_ID,
        payload: value
    }
}

export function changeCreateItemAuctionId(value) {
    return {
        type: CREATE_ITEMS_AUCTION_ID,
        payload: value
    }
}

export function changeCreateItemClassificationId(value) {
    return {
        type: CREATE_ITEMS_CLASSIFICATION_ID,
        payload: value
    }
}

export function changeCreateItemUnitValueCurrency(value) {
    return {
        type: CREATE_ITEMS_UNIT_VALUE_CURRENCY,
        payload: value
    }
}

export function changeCreateItemUnitValueAmount(value) {
    return {
        type: CREATE_ITEMS_UNIT_VALUE_AMOUNT,
        payload: value
    }
}

export function changeCreateItemUnitValueTax(value) {
    return {
        type: CREATE_ITEMS_UNIT_VALUE_TAX,
        payload: value
    }
}

export function changeCreateItemDescription(value) {
    return {
        type: CREATE_ITEMS_DESCRIPTION,
        payload: value
    }
}



export function changeSecondMenu(value) {
    return {
        type: CHANGE_MENU_ON_OFF,
        payload: value
    }
}

export function changeLang(value) {
    return {
        type: CHANGE_LANG,
        payload: value
    }
}

export function changeAdvancedFind(value) {
    return {
        type: CHANGE_ADVANCED_FIND,
        payload: value
    }
}

export function changePageNumber(value) {
    return {
        type: NUMBER_PAGE_LOT,
        payload: value
    }
}

export function setPopUpLogin(value) {
    return {
        type: SET_POPUP_LOGIN,
        payload: value
    }
}

export function changeCreateAuctionLotIdentifier(value) {
    return {
        type: CREATE_AUCTION_LOT_ID,
        payload: value
    }
}

export function changeCreateItemRailwayLotType(value) {
    return {
        type: CREATE_AUCTION_RAILWAY_LOT_TYPE,
        payload: value
    }
}

export function changeCreateItemAddress(value) {
    return {
        type: CREATE_ITEMS_ADDRESS,
        payload: value
    }
}

export function changeLoader(value) {
    return {
        type: CHANGE_LOADER,
        payload: value
    }
}

export function setCarryType(value) {
    return {
        type: SET_CARRY_TYPE,
        payload: value
    }
}

export function changeCreateItemWagonSpecies(value) {
    return {
        type: SET_WAGON_SPECIES,
        payload: value
    }
}

export function changeCreateItemLoadingRestriction(value) {
    return {
        type: SET_LOADING_RESTRICTION,
        payload: value
    }
}

export function changeCreateItemWagonTypes(value) {
    return {
        type: SET_WAGON_TYPES,
        payload: value
    }
}


export function setCreateItemDateFrom1(value) {
    return {
        type: SET_DATE_FROM_1,
        payload: value
    }
}

export function setCreateItemDateTill1(value) {
    return {
        type: SET_DATE_TILL_1,
        payload: value
    }
}

export function setCreateItemDateFrom2(value) {
    return {
        type: SET_DATE_FROM_2,
        payload: value
    }
}

export function setCreateItemDateTill2(value) {
    return {
        type: SET_DATE_TILL_2,
        payload: value
    }
}

export function setCreateItemRouteQuantity(value) {
    return {
        type: SET_ROUTE_QUANTITY,
        payload: value
    }
}

export function setCreateItemRoutesAccountingRouteNumber(value) {
    return {
        type: SET_ROUTE_ACCOUNTING_ROUTE_NUMBER,
        payload: value
    }
}

export function setCreateItemRoutesAccountingWagonsPerRouteQuantity(value) {
    return {
        type: SET_ROUTE_ACCOUNTING_WAGONS_PER_ROUTE_QUANTITY,
        payload: value
    }
}

export function changeCreateItemClassificationAddId(value) {
    return {
        type: SET_RAILWAY_CLASSIFICATOR_ADD,
        payload: value
    }
}

export function changeCreateItemClassificationAddIdNadra(value) {
    return {
        type: SET_NADRA_CLASSIFICATOR_ADD,
        payload: value
    }
}


export function setCreateItemRestrict(value){
    return {
        type: SET_CREATE_ITEM_RESTRICT,
        payload: value
    }
}

export function setTogetherApi(){
    return {
        type: CHANGE_TOGETHER_API,
        payload: null
    }
}

export function setAwardAllUserPopUp(value){
    return {
        type: AWARD_ALL_USER_POPUP,
        payload: value
    }
}

export function setAwardAllUserPopUpUser(value){
    return {
        type: AWARD_ALL_USER_POPUP_USER,
        payload: value
    }
}

export function setUniversalError(value){
    return {
        type: UNIVERSAL_ERROR,
        payload: value
    }
}


export function minNumberOfQualifiedBids(value) {
    return {
        type: SET_MIN_NUMBER_OF_QUALIFIED_BIDS,
        payload: value
    }
}

export function setEndPoint(value) {
    return {
        type: SET_END_POINT,
        payload: value
    }
}


////////////DUTCH
export function changeCreateAuctionDutchStepQuantity(value) {
    return {
        type: CREATE_AUCTION_RAILWAY_DUTCH_STEP_QUANTITY,
        payload: value
    }
}

export function changeCreateAuctionDutchStepPercent(value) {
    return {
        type: CREATE_AUCTION_RAILWAY_DUTCH_STEP_PERCENT,
        payload: value
    }
}

export function changeCreateAuctionDutchStepValueAmount(value) {
    return {
        type: CREATE_AUCTION_RAILWAY_DUTCH_STEP_VALUE_AMOUNT,
        payload: value
    }
}

export function setEditAuctionFromId(value) {
    return {
        type: EDIT_AUCTION_FROM_ID,
        payload: value
    }
}

export function setCheckboxBidPublish(value) {
    return {
        type: CHECKBOX_BID_PUBLISH,
        payload: value
    }
}

export function changeCreateItemLocationLatitude(value) {
    return {
        type: CREATE_ITEMS_LOCATION_LATITUDE,
        payload: value
    }
}

export function changeCreateItemLocationLongitude(value) {
    return {
        type: CREATE_ITEMS_LOCATION_LONGITUDE,
        payload: value
    }
}

export function changeCreateItemLocationElevation(value) {
    return {
        type: CREATE_ITEMS_LOCATION_ELEVATION,
        payload: value
    }
}

export function changeCreateItemDgfJointPropertyComplexPropsId(value) {
    return {
        type: CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_ID,
        payload: value
    }
}

export function changeCreateItemDgfJointPropertyComplexPropsQuantityAndNomenclature(value) {
    return {
        type: CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_QUANTITYANDNOMENCLATURE,
        payload: value
    }
}
export function changeCreateItemDgfJointPropertyComplexPropsWorkplacesQuantity(value) {
    return {
        type: CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_WORKPLACESQUANTITY,
        payload: value
    }
}
export function changeCreateItemDgfJointPropertyComplexPropsBuildingsInformation(value) {
    return {
        type: CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_BUILDINGSINFORMATION,
        payload: value
    }
}
export function changeCreateItemDgfJointPropertyComplexPropsLandPlotInformation(value) {
    return {
        type: CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_LANDPLOTINFORMATION,
        payload: value
    }
}
export function changeCreateItemDgfJointPropertyComplexPropsMainObligations(value) {
    return {
        type: CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_MAINOBLIGATIONS,
        payload: value
    }
}

export function changeCreateItemDgfLandPropsId(value) {
    return {
        type: CREATE_ITEMS_DGF_LANDPROPS_ID,
        payload: value
    }
}
export function changeCreateItemDgfLandPropsLandArea(value) {
    return {
        type: CREATE_ITEMS_DGF_LANDPROPS_LANDAREA,
        payload: value
    }
}
export function changeCreateItemDgfLandPropsCadastralNumber(value) {
    return {
        type: CREATE_ITEMS_DGF_LANDPROPS_CADASTRALNUMBER,
        payload: value
    }
}
export function changeCreateItemDgfLandPropsOwnershipType(value) {
    return {
        type: CREATE_ITEMS_DGF_LANDPROPS_OWNERSHIPTYPE,
        payload: value
    }
}
export function changeCreateItemDgfLandPropsEncumbrances(value) {
    return {
        type: CREATE_ITEMS_DGF_LANDPROPS_ENCUMBRANCES,
        payload: value
    }
}
export function changeCreateItemDgfLandPropsJointOwnership(value) {
    return {
        type: CREATE_ITEMS_DGF_LANDPROPS_JOINTOWNERSHIP,
        payload: value
    }
}
export function changeCreateItemDgfLandPropsUtilitiesAvailability(value) {
    return {
        type: CREATE_ITEMS_DGF_LANDPROPS_UTILITIESAVAILABILITY,
        payload: value
    }
}
export function changeCreateItemDgfLandPropsIntendedUse(value) {
    return {
        type: CREATE_ITEMS_DGF_LANDPROPS_INTENDEDUSE,
        payload: value
    }
}

export function changeCreateItemDgfVehiclePropsId(value) {
    return {
        type: CREATE_ITEMS_DGF_VEHICLEPROPS_ID,
        payload: value
    }
}
export function changeCreateItemDgfVehiclePropsBrand(value) {
    return {
        type: CREATE_ITEMS_DGF_VEHICLEPROPS_BRAND,
        payload: value
    }
}
export function changeCreateItemDgfVehiclePropsModel(value) {
    return {
        type: CREATE_ITEMS_DGF_VEHICLEPROPS_MODEL,
        payload: value
    }
}
export function changeCreateItemDgfVehiclePropsProductionYear(value) {
    return {
        type: CREATE_ITEMS_DGF_VEHICLEPROPS_PRODUCTIONYEAR,
        payload: value
    }
}
export function changeCreateItemDgfVehiclePropsEngineCapacity(value) {
    return {
        type: CREATE_ITEMS_DGF_VEHICLEPROPS_ENGINECAPACITY,
        payload: value
    }
}
export function changeCreateItemDgfVehiclePropsFuelType(value) {
    return {
        type: CREATE_ITEMS_DGF_VEHICLEPROPS_FUELTYPE,
        payload: value
    }
}
export function changeCreateItemDgfVehiclePropsTransmission(value) {
    return {
        type: CREATE_ITEMS_DGF_VEHICLEPROPS_TRANSMISSION,
        payload: value
    }
}
export function changeCreateItemDgfVehiclePropsColor(value) {
    return {
        type: CREATE_ITEMS_DGF_VEHICLEPROPS_COLOR,
        payload: value
    }
}
export function changeCreateItemDgfVehiclePropsKilometrage(value) {
    return {
        type: CREATE_ITEMS_DGF_VEHICLEPROPS_KILOMETRAGE,
        payload: value
    }
}
export function changeCreateItemDgfVehiclePropsIdentificationNumber(value) {
    return {
        type: CREATE_ITEMS_DGF_VEHICLEPROPS_IDENTIFICATIONNUMBER,
        payload: value
    }
}
export function changeCreateItemDgfVehiclePropsConfiguration(value) {
    return {
        type: CREATE_ITEMS_DGF_VEHICLEPROPS_CONFIGURATION,
        payload: value
    }
}
export function changeCreateItemDgfVehiclePropsCondition(value) {
    return {
        type: CREATE_ITEMS_DGF_VEHICLEPROPS_CONDITION,
        payload: value
    }
}
export function changeCreateItemDgfVehiclePropsDamagePresence(value) {
    return {
        type: CREATE_ITEMS_DGF_VEHICLEPROPS_DAMAGEPRESENCE,
        payload: value
    }
}
export function changeCreateItemDgfVehiclePropsDamagedDescription(value) {
    return {
        type: CREATE_ITEMS_DGF_VEHICLEPROPS_DAMAGEDDESCRIPTION,
        payload: value
    }
}

export function changeCreateItemDgfRealEstatePropsId(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_ID,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsTotalBuildingArea(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_TOTALBUILDINGAREA,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsTotalObjectArea(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_TOTALOBJECTAREA,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsUsableArea(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_USABLEAREA,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsConstructionYear(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_CONSTRUCTIONYEAR,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsConstructionTechnology(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_CONSTRUCTIONTECHNOLOGY,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsLivingArea(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_LIVINGAREA,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsKitchenArea(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_KITCHENAREA,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsLandArea(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_LANDAREA,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsLocationInBuilding(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_LOCATIONINBUILDING,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsFloors(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_FLOORS,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsGeneralCondition(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_GENERALCONDITION,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsServiceElectricity(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEELECTRICITY,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsPowerSupplyCapacity(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_POWERSUPPLYCAPACITY,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsPowerSupplyClass(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_POWERSUPPLYCLASS,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsServiceWater(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEWATER,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsServiceSewerage(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESEWERAGE,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsServiceGas(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEGAS,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsServiceCentralHeating(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICECENTRALHEATING,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsServiceAutonomousHeating(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEAUTONOMOUSHEATING,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsServiceHeatingCounter(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEHEATINGCOUNTER,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsServiceVentilation(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEVENTILATION,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsServiceAirConditioning(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEAIRCONDITIONING,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsServicePhone(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEPHONE,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsServiceTV(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICETV,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsServiceInternet(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEINTERNET,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsServiceElevator(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEELEVATOR,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsServiceSecurityAlarm(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESECURITYALARM,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsServiceFireAlarm(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEFIREALARM,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsServicesDescription(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESDESCRIPTION,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsServicesAccounting(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESACCOUNTING,
        payload: value
    }
}
export function changeCreateItemDgfRealEstatePropsLandTax(value) {
    return {
        type: CREATE_ITEMS_DGF_REALESTATEPROPS_LANDTAX,
        payload: value
    }
}

export function changeMyCabinetCurentPage(value) {
    return {
        type: SET_MY_CABINET_PAGE,
        payload: value
    }
}

export function changeMyCurentPage(value) {
    return {
        type: SET_MY_CURRENT_PAGE,
        payload: value
    }
}

export function changeCreateAuctionMinNumberOfQualifiedBids(value) {
    return {
        type: CREATE_ITEMS_DGF_MINNUMBEROFQUALIFIEDBIDS,
        payload: value
    }
}

export function changeCreateAuctionDgfVirtualDataRoom(value) {
    return {
        type: CREATE_ITEMS_DGF_VIRTUALDATAROOM,
        payload: value
    }
}
export function changeCreateAuctionDgfEligibilityCriteria(value) {
    return {
        type: CREATE_ITEMS_DGF_ELIGIBILITYCRITERIA,
        payload: value
    }
}
export function changeCreateAuctionDgfX_dgfPlatformLegalDetails(value) {
    return {
        type: CREATE_ITEMS_DGF_X_DGFPLATFORMLEGALDETAILS,
        payload: value
    }
}
export function changeCreateAuctionDgfDecisionId(value) {
    return {
        type: CREATE_ITEMS_DGF_X_DGFDECISION_ID,
        payload: value
    }
}
export function changeCreateAuctionDgfDecisionDate(value) {
    return {
        type: CREATE_ITEMS_DGF_X_DGFDECISION_DATE,
        payload: value
    }
}

export function changeCreateAuctionСalcOnBookValue(value) {
    return {
        type: CREATE_CALC_ON_BOOK_VALUE,
        payload: value
    }
}

export function changeCreateAuctionValueAddedTaxCharged(value) {
    return {
        type: CREATE_VALUE_ADDED_TAX_CHARGED,
        payload: value
    }
}

export function changeCreateAuctionRegistryId(value) {
    return {
        type: CREATE_REGISTRY_ID,
        payload: value
    }
}

export function changeCreateAuctionRegistrationFeeAmount(value) {
    return {
        type: CREATE_REGISTRATION_FEE,
        payload: value
    }
}


export function changeCreateAuctionValuePeriod(value) {
    return {
        type: CREATE_VALUE_PERIOD,
        payload: value
    }
}

export function changeCreateAuctionLeaseRulesSubleaseAllowed(value) {
    return {
        type: CREATE_LEASE_RULES_SUBLEASE_ALLOWED,
        payload: value
    }
}

export function changeCreateAuctionLeaseRulesAdditionalLeaseConditions(value) {
    return {
        type: CREATE_LEASE_RULES_ADDITIONAL_LEASE_CONDITIONS,
        payload: value
    }
}

export function changeCreateAuctionLeaseRulesIntendedUseRestrictionDescription(value) {
    return {
        type: CREATE_LEASE_RULES_INTENDED_USE_RESTRICTION_DESCRIPTION,
        payload: value
    }
}

export function changeCreateAuctionLeaseRulesIntendedUseRestrictionMethod(value) {
    return {
        type: CREATE_LEASE_RULES_INTENDED_USE_RESTRICTION_METHOD,
        payload: value
    }
}

export function changeCreateAuctionLeaseRulesIntendedUse(value) {
    return {
        type: CREATE_LEASE_RULES_INTENDED_USE,
        payload: value
    }
}