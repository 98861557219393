import { 
    //Auction Bid's
    AUCTION_BID_GET,
    AUCTION_ID_BID_GET,
    AUCTION_BID_POST,
    AUCTION_BID_PUT,
    AUCTION_BID_DELETE,

    SET_MY_BIDS,
    SET_CURENT_BID,
    SET_CURENT_BID_DOC_CVALIF,
    SET_AUCTION_ID_BID,

    SET_CREATE_BID_ERROR,
    BID_POPUP_EDIT,
    BID_POPUP_HELP_PUBLISH,
    BID_POPUP_EDIT_DOC,
    BID_POPUP_EDIT_DOC_TITLE,
    BID_POPUP_EDIT_DOC_DESCRIPTION,
    BID_POPUP_EDIT_DOC_TYPE,
} from "../../types";

import {getAPI, postAPI, putAPI, deleteAPI} from '../crud';
import {setLocal} from '../support';

//Auction Bid's
export function auctionBidPost(_url, token, datas){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/auction/${_url.id}/bid`,
        token: token,
        actionType: AUCTION_BID_POST,
        data: datas,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return postAPI(data);
}

export function auctionBidGetId(_url, token){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/auction/${_url.id}/bid`,
        token: token,
        actionType: AUCTION_BID_GET,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function auctionBidPut(_url, token, datas){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/auction/${_url.id}/bid`,
        token: token,
        actionType: AUCTION_BID_PUT,
        data: datas,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return putAPI(data);
}

export function auctionBidDelete(_url, token){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/auction/${_url.id}/bid`,
        token: token,
        actionType: AUCTION_BID_DELETE,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return deleteAPI(data);
}

export function auctionsBidsGet(_url, token){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/auction/${_url.id}/bids${_url.params}`,
        token: token,
        actionType: AUCTION_ID_BID_GET,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function auctionsBidsGetMy(_url, header){
    const data = {
        url: `${_url.domen}/api/v1/uk/private/auctions/bids/my${_url.params}`,
        actionType: SET_MY_BIDS,
        header: header
    }
    return getAPI(data);
}

export function auctionsBidsGetId(_url, token, id){
    const data = {
        url: `${_url.domen}/api/${_url.version}/uk/auction/${id}/bids${_url.params}`,
        token: token,
        actionType: SET_CURENT_BID,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function changeCreateBidDocType(value) {
    return {
        type: SET_CURENT_BID_DOC_CVALIF,
        payload: value
    }
}


export function getAuctionsBidsId(_url, header, idAuction, idBid){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/auction/${idAuction}/bid/${idBid}`,
        actionType: SET_AUCTION_ID_BID,
        header: header
    }
    return getAPI(data);
}

export function getAuctionsBidsIdPopUp(_url, header, idAuction, idBid){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/auction/${idAuction}/bid/${idBid}`,
        actionType: BID_POPUP_EDIT,
        header: header
    }
    return getAPI(data);
}

export function changeBidErrorPopUpStatus(value){
    return {
        type: SET_CREATE_BID_ERROR,
        payload: value
    }
}

export function changeBidPopUpEdit(value){
    return {
        type: BID_POPUP_EDIT,
        payload: value
    }
}

export function changeBidPopUpHelpPublish(value){
    return {
        type: BID_POPUP_HELP_PUBLISH,
        payload: value
    }
}

export function changeBidPopUpEditDoc(value){
    return {
        type: BID_POPUP_EDIT_DOC,
        payload: value
    }
}

export function changeBidPopUpEditDocTitle(value){
    return {
        type: BID_POPUP_EDIT_DOC_TITLE,
        payload: value
    }
}

export function changeBidPopUpEditDocDescription(value){
    return {
        type: BID_POPUP_EDIT_DOC_DESCRIPTION,
        payload: value
    }
}

export function changeBidPopUpEditDocType(value){
    return {
        type: BID_POPUP_EDIT_DOC_TYPE,
        payload: value
    }
}
