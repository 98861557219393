import React from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import {PROCEDURE_NAME} from '../../../redux/procedure_v3.35.2.uk';
import {
    setDataAddress,
} from '../../../redux/createAddress';

import { getRegion } from '../../../api/dictonaries/region';

import {
    streetAddress,
    localityAddress,
    regionAddress,
    postalCodeAddress,
    countryNameAddress,
    popUpAddress,
    popUpAddressId,
    setPopAddressError,
    setPopAddressComplite,
    setKoatuuRegion, 
    setKoatuuGorod,
} from '../../../redux/actions/address/address.js';

import {
    setEditAuctionFromId,

    getMyAuction,
    changeLoader,
} from '../../../redux/actions.js';

import {
    changeDiscountPercent,
    changeDiscountPerviousAuctionCurrency,
    changeDiscountPerviousAuctionAmount,
    changeDiscountPerviousAuctionId
} from '../../../redux/actions/discount/discount.js';


import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { TimePicker, DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import { setProfileNull } from '../../../redux/actions/default/default';

const Organization = ( props ) => {
    let tmpRegion = null
    if(props.koatuu.region === null){
        getKoatuuRegion(props)
    }
    if(props.koatuu.region !== null){
        let tmpRegionOption = props.koatuu.region.map(
            (i) => {
                return <option key={i} value={`${i.classificationId}+:+${i.description}`}>{i.description}</option>
            }
        )
        tmpRegion = <TextField
            className={styles.inputText + ' ' + styles.koatuu}
            id="outlined-select-currency-native"
            select
            label={PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.relatedOrganizations.propertyOwner.address.region.legalName}
            value={props.addressNew.address.region}
            onChange={(event) => {
                props.setDataAddress(event.target.value, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                let t = event.target.value.substr(0, 2)
                props.setKoatuuGorod(null)
                props.setDataAddress('null', 'CREATE_ADDRESS_NEW_ADDRESS_LOCALITY')
                getKoatuuGorod(props, t)
            }}
            SelectProps={{
                native: true,
            }}
            helperText=""
            variant="outlined"
        >
            <option key={0} selected disabled value='null'>Обрати</option>
            {tmpRegionOption}
        </TextField>
    }else{
        tmpRegion = <TextField
            className={styles.inputText + ' ' + styles.koatuu}
            disabled
            id="outlined-select-currency-native"
            select
            label={PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.relatedOrganizations.propertyOwner.address.region.legalName}
            value={props.addressNew.address.region}
            SelectProps={{
                native: true,
            }}
            helperText=""
            variant="outlined"
        >
            <option key={0} selected disabled value='null'>Обрати</option>
        </TextField>
    }
    let tmpGorod = null
    if(props.koatuu.gorod !== null){
        let tmpGorodOption = props.koatuu.gorod.map(
            (i, count) => {
                return <option key={count+1} value={i.description}>{i.description}</option>
            }
        )
        /*tmpGorod = <TextField
            className={styles.inputText + ' ' + styles.koatuu}
            id="outlined-select-currency-native"
            select
            label={PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.relatedOrganizations.propertyOwner.address.locality.legalName}
            value={props.addressNew.address.locality}
            onChange={(event) => {
                props.setDataAddress(event.target.value, 'CREATE_ADDRESS_NEW_ADDRESS_LOCALITY')
            }}
            SelectProps={{
                native: true,
            }}
            helperText=""
            variant="outlined"
        >
            <option key={0} selected disabled value='null'>Обрати</option>
            {tmpGorodOption}
        </TextField>*/
        tmpGorod = <TextField
            required
            id="filled-required"
            className={styles.inputText}
            label={PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.relatedOrganizations.propertyOwner.address.locality.legalName}
            value={props.addressNew.address.locality}
            variant="outlined"
            onChange={
                (e) => {
                    props.setDataAddress(e.target.value, 'CREATE_ADDRESS_NEW_ADDRESS_LOCALITY')
                }
            }
            helperText=""
        />
    }else{
        /*tmpGorod = <TextField
            className={styles.inputText + ' ' + styles.koatuu}
            disabled
            id="outlined-select-currency-native"
            select
            label={PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.relatedOrganizations.propertyOwner.address.locality.legalName}
            value={props.addressNew.address.locality}
            SelectProps={{
                native: true,
            }}
            helperText=""
            variant="outlined"
        >
            <option key={0} selected disabled value='null'>Обрати</option>
        </TextField>*/
        tmpGorod = <TextField
            required
            disabled
            id="filled-required"
            className={styles.inputText}
            label={PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.relatedOrganizations.propertyOwner.address.locality.legalName}
            value={props.addressNew.address.locality}
            variant="outlined"
            onChange={
                (e) => {
                    props.setDataAddress(e.target.value, 'CREATE_ADDRESS_NEW_ADDRESS_LOCALITY')
                }
            }
            helperText=""
        />
    }
    return (
        <div className={styles.popUpAddress}>
            <div className={styles.popUpAddress_wrap}>
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            disabled
                            id="filled-required"
                            className={styles.inputText}
                            label="Найменування юридичної особи"
                            defaultValue={props.addressNew.name}
                            variant="outlined"
                            onChange={
                                (e) => {
                                    props.setDataAddress(e.target.value, 'CREATE_ADDRESS_NEW_NAME')
                                }
                            }
                            helperText=""
                        />
                    </div>
                </div>
                <div>Ідентифікатори організації або особи:</div>
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            required
                            id="filled-required"
                            className={styles.inputText}
                            label="Ідентифікатори організації або особи"
                            defaultValue={props.addressNew.identifier.identifier}
                            variant="outlined"
                            onChange={
                                (e) => {
                                    props.setDataAddress(e.target.value, 'CREATE_ADDRESS_NEW_IDENTIFIER_IDENTIFIER')
                                }
                            }
                            helperText=""
                        />
                    </div>
                    {/*<div>
                        <TextField
                            disabled
                            id="filled-required"
                            className={styles.inputText}
                            label="Тип ідентифікації юридичної особи"
                            defaultValue={props.addressNew.identifier.scheme}
                            variant="outlined"
                            onChange={
                                (e) => {
                                    props.setDataAddress(e.target.value, 'CREATE_ADDRESS_NEW_IDENTIFIER_SCHEME')
                                }
                            }
                            helperText=""
                        />
                        </div>*/}
                    <div>
                        <TextField
                            className={styles.inputText}
                            id="outlined-select-currency-native"
                            select
                            label="Тип ідентифікації юридичної особи"
                            defaultValue={props.addressNew.identifier.scheme}
                            SelectProps={{
                                native: true,
                            }}
                            onChange={
                                (e) => {
                                    props.setDataAddress(e.target.value, 'CREATE_ADDRESS_NEW_IDENTIFIER_SCHEME')
                                }
                            }
                            helperText=""
                            variant="outlined"
                        >
                            <option key={0} selected disabled value='null'>Обрати</option>
                            <option key={1} value='UA-EDR'>ЄДРПОУ</option>
                            <option key={2} value='UA-IPN'>ІПН/РНОКПП</option>
                            <option key={3} value='UA-PASSPORT'>Паспорт</option>
                            <option key={4} value='UA-ID-CARD'>ID-карта</option>
                            <option key={5} value='UA-IPN-FOP'>ІПН/РНОКПП</option>
                        </TextField>
                    </div>
                    <div>
                        <TextField
                            required
                            id="filled-required"
                            className={styles.inputText}
                            label="Повна юридична назва юридичної особи"
                            defaultValue={props.addressNew.identifier.legalName}
                            variant="outlined"
                            onChange={
                                (e) => {
                                    props.setDataAddress(e.target.value, 'CREATE_ADDRESS_NEW_IDENTIFIER_LEGAL_NAME')
                                }
                            }
                            helperText=""
                        />
                    </div>
                </div>
                {/*<div>Додаткові ідентифікатори організації або особи:</div>
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            required
                            id="filled-required"
                            className={styles.inputText}
                            label="Ідентифікатори організації або особи"
                            defaultValue={props.addressNew.additionalIdentifiers.identifier}
                            variant="outlined"
                            onChange={
                                (e) => {
                                    props.setDataAddress(e.target.value, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
                                }
                            }
                            helperText=""
                        />
                    </div>
                    <div>
                        <TextField
                            disabled
                            id="filled-required"
                            className={styles.inputText}
                            label="Тип ідентифікації юридичної особи"
                            defaultValue={props.addressNew.additionalIdentifiers.scheme}
                            variant="outlined"
                            onChange={
                                (e) => {
                                    props.setDataAddress(e.target.value, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_SCHEME')
                                }
                            }
                            helperText=""
                        />
                    </div>
                    <div>
                        <TextField
                            required
                            id="filled-required"
                            className={styles.inputText}
                            label="Повна юридична назва юридичної особи"
                            defaultValue={props.addressNew.additionalIdentifiers.legalName}
                            variant="outlined"
                            onChange={
                                (e) => {
                                    props.setDataAddress(e.target.value, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')
                                }
                            }
                            helperText=""
                        />
                    </div>
                </div>*/}
                {/*<div>Місцезнаходження юридичної особи:</div>
                <div>additionalIdentifiers</div>
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            required
                            id="filled-required"
                            className={styles.inputText}
                            label="Ідентифікатори організації або особи"
                            defaultValue={props.addressNew.address.addressIdentifier.identifier}
                            variant="outlined"
                            onChange={
                                (e) => {
                                    props.setDataAddress(e.target.value, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
                                }
                            }
                            helperText=""
                        />
                    </div>
                    <div>
                        <TextField
                            disabled
                            id="filled-required"
                            className={styles.inputText}
                            label="Тип ідентифікації юридичної особи"
                            defaultValue={props.addressNew.address.addressIdentifier.scheme}
                            variant="outlined"
                            onChange={
                                (e) => {
                                    props.setDataAddress(e.target.value, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_SCHEME')
                                }
                            }
                            helperText=""
                        />
                    </div>
                    <div>
                        <TextField
                            required
                            id="filled-required"
                            className={styles.inputText}
                            label="Повна юридична назва юридичної особи"
                            defaultValue={props.addressNew.address.addressIdentifier.name}
                            variant="outlined"
                            onChange={
                                (e) => {
                                    props.setDataAddress(e.target.value, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')
                                }
                            }
                            helperText=""
                        />
                    </div>
                </div>*/}
                <div>{PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.relatedOrganizations.propertyOwner.address.legalName}</div>
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            required
                            id="filled-required"
                            className={styles.inputText}
                            label={PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.relatedOrganizations.propertyOwner.address.streetAddress.legalName}
                            defaultValue={props.addressNew.address.streetAddress}
                            variant="outlined"
                            onChange={
                                (e) => {
                                    props.setDataAddress(e.target.value, 'CREATE_ADDRESS_NEW_ADDRESS_STREET_ADDRESS')
                                }
                            }
                            helperText=""
                        />
                    </div>
                    <div>
                        {/*<TextField
                            required
                            id="filled-required"
                            className={styles.inputText}
                            label="locality"
                            defaultValue={props.addressNew.address.locality}
                            variant="outlined"
                            onChange={
                                (e) => {
                                    props.setDataAddress(e.target.value, 'CREATE_ADDRESS_NEW_ADDRESS_LOCALITY')
                                }
                            }
                            helperText=""
                        />*/}
                        {tmpGorod}
                    </div>
                    <div>
                        {/*<TextField
                            required
                            id="filled-required"
                            className={styles.inputText}
                            label="region"
                            defaultValue={props.addressNew.address.region}
                            variant="outlined"
                            onChange={
                                (e) => {
                                    props.setDataAddress(e.target.value, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                                }
                            }
                            helperText=""
                        />*/}
                        {tmpRegion}
                    </div>
                </div>
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            required
                            id="filled-required"
                            className={styles.inputText}
                            label={PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.relatedOrganizations.propertyOwner.address.postalCode.legalName}
                            defaultValue={props.addressNew.address.postalCode}
                            variant="outlined"
                            onChange={
                                (e) => {
                                    if(String(e.target.value).length < 6){
                                        props.setDataAddress(e.target.value, 'CREATE_ADDRESS_NEW_ADDRESS_POSTAL_CODE')
                                    }
                                }
                            }
                            helperText=""
                        />
                    </div>
                    <div>
                        <TextField
                            id="filled-required"
                            className={styles.inputText}
                            label={PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.relatedOrganizations.propertyOwner.address.countryName.legalName}
                            defaultValue={props.addressNew.address.countryName}
                            variant="outlined"
                            onChange={
                                (e) => {
                                    props.setDataAddress(e.target.value, 'CREATE_ADDRESS_NEW_ADDRESS_COUNTRY_NAME')
                                }
                            }
                            helperText=""
                        />
                    </div>
                </div>
                <div>{PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.relatedOrganizations.propertyOwner.contactPoint.legalName}</div>
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            required
                            id="filled-required"
                            className={styles.inputText}
                            label={PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.relatedOrganizations.propertyOwner.contactPoint.name.legalName}
                            defaultValue={props.addressNew.contactPoint.name}
                            variant="outlined"
                            onChange={
                                (e) => {
                                    props.setDataAddress(e.target.value, 'CREATE_ADDRESS_NEW_CONTACT_POINT_NAME')
                                }
                            }
                            helperText=""
                        />
                    </div>
                    <div>
                        <TextField
                            required
                            id="filled-required"
                            className={styles.inputText}
                            label={PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.relatedOrganizations.propertyOwner.contactPoint.email.legalName}
                            defaultValue={props.addressNew.contactPoint.email}
                            variant="outlined"
                            onChange={
                                (e) => {
                                    props.setDataAddress(e.target.value, 'CREATE_ADDRESS_NEW_CONTACT_POINT_EMAIL')
                                }
                            }
                            helperText=""
                        />
                    </div>
                    <div>
                        <TextField
                            required
                            id="filled-required"
                            className={styles.inputText}
                            label={PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.relatedOrganizations.propertyOwner.contactPoint.telephone.legalName}
                            defaultValue={props.addressNew.contactPoint.telephone}
                            variant="outlined"
                            onChange={
                                (e) => {
                                    props.setDataAddress(e.target.value, 'CREATE_ADDRESS_NEW_CONTACT_POINT_TELEPHONE')
                                }
                            }
                            helperText=""
                        />
                    </div>
                </div>
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            id="filled-required"
                            className={styles.inputText}
                            label={PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.relatedOrganizations.propertyOwner.contactPoint.faxNumber.legalName}
                            defaultValue={props.addressNew.contactPoint.faxNumber}
                            variant="outlined"
                            onChange={
                                (e) => {
                                    props.setDataAddress(e.target.value, 'CREATE_ADDRESS_NEW_CONTACT_POINT_FAX_NUMBER')
                                }
                            }
                            helperText=""
                        />
                    </div>
                    <div>
                        <TextField
                            id="filled-required"
                            className={styles.inputText}
                            label={PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.relatedOrganizations.propertyOwner.contactPoint.url.legalName}
                            defaultValue={props.addressNew.contactPoint.url}
                            variant="outlined"
                            onChange={
                                (e) => {
                                    props.setDataAddress(e.target.value, 'CREATE_ADDRESS_NEW_CONTACT_POINT_URL')
                                }
                            }
                            helperText=""
                        />
                    </div>
                </div>
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            id="filled-required"
                            className={styles.inputText}
                            label={PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.relatedOrganizations.currentTenant.representativeInfo.legalName}
                            defaultValue={props.addressNew.representativeInfo}
                            variant="outlined"
                            onChange={
                                (e) => {
                                    props.setDataAddress(e.target.value, 'CREATE_ADDRESS_NEW_REPRESENTATIVE_INFO')
                                }
                            }
                            helperText=""
                        />
                    </div>
                    {dataElements(props)}
                </div>

                
                <div className={styles.bottonBlock}>
                    <Button
                        className={styles.buttonExitDocument}
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                props.changeLoader(true)
                                createContactOrganizations(props)
                                //props.setDataAddress(false, 'CREATE_ADDRESS_NEW_OPEN_CLOSE')
                            }
                        }
                    >
                        Зберегти
                    </Button>
                    <Button
                        className={styles.buttonExitDocument}
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                props.setDataAddress(null, 'CREATE_ADDRESS_NEW_ID')
                                props.setDataAddress('', 'CREATE_ADDRESS_NEW_NAME')

                                props.setDataAddress(null, 'CREATE_ADDRESS_NEW_IDENTIFIER_ID')
                                props.setDataAddress('', 'CREATE_ADDRESS_NEW_IDENTIFIER_IDENTIFIER')
                                props.setDataAddress('', 'CREATE_ADDRESS_NEW_IDENTIFIER_LEGAL_NAME')

                                props.setDataAddress(null, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_ID')
                                props.setDataAddress('', 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
                                props.setDataAddress('', 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')

                                props.setDataAddress(null, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_ID')
                                props.setDataAddress('', 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
                                props.setDataAddress('', 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')


                                props.setDataAddress(null, 'CREATE_ADDRESS_NEW_ADDRESS_ID')
                                props.setDataAddress('', 'CREATE_ADDRESS_NEW_ADDRESS_STREET_ADDRESS')
                                props.setDataAddress('null', 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                                props.setDataAddress('null', 'CREATE_ADDRESS_NEW_ADDRESS_LOCALITY')
                                props.setDataAddress('', 'CREATE_ADDRESS_NEW_ADDRESS_POSTAL_CODE')

                                props.setDataAddress('', 'CREATE_ADDRESS_NEW_CONTACT_POINT_ID')
                                props.setDataAddress('', 'CREATE_ADDRESS_NEW_CONTACT_POINT_NAME')
                                props.setDataAddress('', 'CREATE_ADDRESS_NEW_CONTACT_POINT_EMAIL')
                                props.setDataAddress('', 'CREATE_ADDRESS_NEW_CONTACT_POINT_TELEPHONE')
                                props.setDataAddress('', 'CREATE_ADDRESS_NEW_CONTACT_POINT_FAX_NUMBER')
                                props.setDataAddress('', 'CREATE_ADDRESS_NEW_CONTACT_POINT_URL')

                                props.setDataAddress('', 'CREATE_ADDRESS_NEW_REPRESENTATIVE_INFO')
                                props.setDataAddress('', 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_START_DATE')
                                props.setDataAddress('', 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_END_DATE')

                                props.setDataAddress(false, 'CREATE_ADDRESS_NEW_OPEN_CLOSE')
                                props.setDataAddress(false, 'CREATE_ADDRESS_TYPE_ORG')
                            }
                        }
                    >
                        Закрити
                    </Button>
                </div>
            </div>
        </div>
    )
}

function dataElements(props){
    if(props.typeOrg === true){
        return (
            <div>
                <div>Дата укладення та закінчення договору оренди</div>
                <div className={styles.dateSearch_line}>
                    <div>
                        <KeyboardDateTimePicker
                            className={styles.dateSearch}
                            label="Дата та час початку"
                            value={props.addressNew.currentContractTime.startDate}
                            onChange={
                                (e) => {
                                    let tmpData = new Date(e)
                                    let tmpMonth = tmpData.getMonth()+1
                                    if(tmpMonth < 10) tmpMonth = `0${tmpMonth}`
                                    let tmpDay = tmpData.getDate()
                                    if(tmpDay < 10) tmpDay = `0${tmpDay}`
                                    let tmpString = `${tmpData.getFullYear()}-${tmpMonth}-${tmpDay}`
                                    //props.setDataAddress(tmpString, 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_START_DATE')
                                    props.setDataAddress(e, 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_START_DATE')
                                }
                            }
                            onError={console.log}
                            minDate={new Date("1990-01-01T00:00")}
                            ampm={false}
                            format="yyyy-MM-dd HH:mm"
                            helperText="Формат дати: гггг-мм-дд чч:мм"
                        />
                    </div>
                    <div>
                        <KeyboardDateTimePicker
                            className={styles.dateSearch}
                            label="Дата та час закінчення"
                            value={props.addressNew.currentContractTime.endDate}
                            onChange={
                                (e) => {
                                    let tmpData = new Date(e)
                                    let tmpMonth = tmpData.getMonth()+1
                                    if(tmpMonth < 10) tmpMonth = `0${tmpMonth}`
                                    let tmpDay = tmpData.getDate()
                                    if(tmpDay < 10) tmpDay = `0${tmpDay}`
                                    let tmpString = `${tmpData.getFullYear()}-${tmpMonth}-${tmpDay}`
                                    //////props.setDataAddress(tmpString, 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_END_DATE')
                                    props.setDataAddress(e, 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_END_DATE')
                                }
                            }
                            onError={console.log}
                            minDate={new Date("1900-01-01T00:00")}
                            ampm={false}
                            format="yyyy-MM-dd HH:mm"
                            helperText="Формат дати: гггг-мм-дд чч:мм"
                        />
                    </div>
                </div>
            </div>
        )
    }
    return null
}

async function createOrganizationsIdentifier(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/identifier`
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify({
            identifier: {
                identifier: props.addressNew.identifier.identifier,
                scheme: props.addressNew.identifier.scheme,
                legalName: props.addressNew.identifier.legalName
            }
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            //let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        //props.setUniversalError(tmp)
        }else{
            const json = await response.json()
            //createOrganizationsContactPoint(props, json.id)
        }
    }
}

async function createOrganizationsContactPoint(props, identifierId){
    let endPoint = null
    let tmpMetod = null
    if(props.addressNew.contactPoint.id === null){
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/contact`
        tmpMetod = 'POST'
    }else{
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/contact/${props.addressNew.contactPoint.id}`
        tmpMetod = 'PUT'
    }
    let tmpTel = ''
    if(props.addressNew.contactPoint.telephone.indexOf('+') === -1){
        tmpTel = '+' + props.addressNew.contactPoint.telephone
    }else{
        tmpTel = props.addressNew.contactPoint.telephone
    }
    let tmpFax = ''
    if(props.addressNew.contactPoint.faxNumber.indexOf('+') === -1){
        tmpFax = '+' + props.addressNew.contactPoint.faxNumber
    }else{
        tmpFax = props.addressNew.contactPoint.faxNumber
    }

    const response = await fetch(endPoint, {
        method: tmpMetod,
        body: JSON.stringify({
            contactPoint: {
                name: props.addressNew.contactPoint.name,
                email: props.addressNew.contactPoint.email,
                telephone: tmpTel,
                faxNumber: tmpFax,
                url: props.addressNew.contactPoint.url
            }
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            //let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        //props.setUniversalError(tmp)
        }else{
            const json = await response.json()
            if(props.typeOrg === true){
                createAuctionRelatedOrganizations(props, identifierId, json.id)
            }else{
                getAddressOrg(props)
            }
        }
    }
}

async function createAuctionRelatedOrganizations(props, contactId, identifId, adsressId){
    let endPoint = null
    let tmpMetod = null
    if(props.addressNew.id === null){
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/organization`
        tmpMetod = 'POST'
    }else{
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/organization/${props.addressNew.id}`
        tmpMetod = 'PUT'
    }

    const response = await fetch(endPoint, {
        method: tmpMetod,
        body: JSON.stringify({
            //name: props.addressNew.name,
            //identifierId: identifId,
            /*additionalIdentifiers: [{
                identifier: props.addressNew.additionalIdentifiers.identifier,
                scheme: props.addressNew.additionalIdentifiers.scheme,
                legalName: props.addressNew.additionalIdentifiers.legalName
            },],*/
            //addressId: adsressId,
            //contactPointId: contactId,
            representativeInfo: props.addressNew.representativeInfo,
            //
            currentContractTime: {
                dateFrom: props.addressNew.currentContractTime.startDate,
                dateTill: props.addressNew.currentContractTime.endDate
            }
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            ///let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        ///props.setUniversalError(tmp)
        }else{
            getAddressOrg(props)
        }
    }
}

async function getAddressOrg(props){
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/organizations?limit=30&offset=0`
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            //let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        //props.setUniversalError(tmp)
        }else{
            await props.setDataAddress(json, 'GET_ADDRESS_NEW_BOOKS')
            await props.changeLoader(false)
            await props.setDataAddress(false, 'CREATE_ADDRESS_NEW_OPEN_CLOSE')
            
            await props.setDataAddress(null, 'CREATE_ADDRESS_NEW_ID')
            await props.setDataAddress('', 'CREATE_ADDRESS_NEW_NAME')

            await props.setDataAddress(null, 'CREATE_ADDRESS_NEW_IDENTIFIER_ID')
            await props.setDataAddress('', 'CREATE_ADDRESS_NEW_IDENTIFIER_IDENTIFIER')
            await props.setDataAddress('', 'CREATE_ADDRESS_NEW_IDENTIFIER_LEGAL_NAME')

            await props.setDataAddress(null, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_ID')
            await props.setDataAddress('', 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
            await props.setDataAddress('', 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')

            await props.setDataAddress(null, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_ID')
            await props.setDataAddress('', 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
            await props.setDataAddress('', 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')


            await props.setDataAddress(null, 'CREATE_ADDRESS_NEW_ADDRESS_ID')
            await props.setDataAddress('', 'CREATE_ADDRESS_NEW_ADDRESS_STREET_ADDRESS')
            await props.setDataAddress('null', 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
            await props.setDataAddress('null', 'CREATE_ADDRESS_NEW_ADDRESS_LOCALITY')
            await props.setDataAddress('', 'CREATE_ADDRESS_NEW_ADDRESS_POSTAL_CODE')

            await props.setDataAddress('', 'CREATE_ADDRESS_NEW_CONTACT_POINT_ID')
            await props.setDataAddress('', 'CREATE_ADDRESS_NEW_CONTACT_POINT_NAME')
            await props.setDataAddress('', 'CREATE_ADDRESS_NEW_CONTACT_POINT_EMAIL')
            await props.setDataAddress('', 'CREATE_ADDRESS_NEW_CONTACT_POINT_TELEPHONE')
            await props.setDataAddress('', 'CREATE_ADDRESS_NEW_CONTACT_POINT_FAX_NUMBER')
            await props.setDataAddress('', 'CREATE_ADDRESS_NEW_CONTACT_POINT_URL')

            await props.setDataAddress('', 'CREATE_ADDRESS_NEW_REPRESENTATIVE_INFO')
            await props.setDataAddress('', 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_START_DATE')
            await props.setDataAddress('', 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_END_DATE')

            await getAucionFromId(props, props.auctionsMy.id)
        }
    }
}

async function getAucionFromId(props, aucId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${aucId}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            props.setEditAuctionFromId(json)
            if(json.previousAuctionId !== null){
                props.changeDiscountPerviousAuctionId(json.previousAuctionId)
                if(json.discount !== null){
                    props.changeDiscountPerviousAuctionAmount(json.discount.previousAuctionValue.amount)
                    props.changeDiscountPercent(json.discount.discountPercent)
                }
            }
        }
    }
}

async function createContactOrganizations(props){
    let body = {}
    body.name = props.addressNew.contactPoint.name
    body.email= props.addressNew.contactPoint.email
    let endPoint = null
    let tmpMetod = null
    if(props.addressNew.contactPoint.id === null){
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/contact`
        tmpMetod = 'POST'
    }else{
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/contact/${props.addressNew.contactPoint.id}`
        tmpMetod = 'PUT'
    }
    if(props.addressNew.contactPoint.telephone.indexOf('+') === -1){
        body.telephone = '+' + props.addressNew.contactPoint.telephone
    }else{
        body.telephone = props.addressNew.contactPoint.telephone
    }
    if(props.addressNew.contactPoint.faxNumber !== null){
        if(props.addressNew.contactPoint.faxNumber === ''){
        }else{
            if(props.addressNew.contactPoint.faxNumber.indexOf('+') === -1){
                body.faxNumber = '+' + props.addressNew.contactPoint.faxNumber
            }else{
                body.faxNumber = props.addressNew.contactPoint.faxNumber
            }
        }
    }
    if(props.addressNew.contactPoint.url !== null){
        if(props.addressNew.contactPoint.url === ''){
        }else{
            body.url = props.addressNew.contactPoint.url
        }
    }
    const response = await fetch(endPoint, {
        method: tmpMetod,
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            //let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        //props.setUniversalError(tmp)
        }else{
            await createIdentifierOrganizations(props, json.id)
        }
    }
}

async function createIdentifierOrganizations(props, contactId){
    let endPoint = null
    let tmpMetod = null
    if(props.addressNew.identifier.id === null){
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/identifier`
        tmpMetod = 'POST'
    }else{
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/identifier/${props.addressNew.identifier.id}`
        tmpMetod = 'PUT'
    }
    const response = await fetch(endPoint, {
        method: tmpMetod,
        body: JSON.stringify({
            identifier: props.addressNew.identifier.identifier,
            scheme: props.addressNew.identifier.scheme,
            legalName: props.addressNew.identifier.legalName
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            //let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        //props.setUniversalError(tmp)
        }else{
            if(props.addressNew.address.id === null){
                await createAddress(props, contactId, json.id)
            }else{
                await createAddressNew(props, contactId, json.id)
            }            
            if(props.typeOrg === true){
                await createAuctionRelatedOrganizations(props, contactId, json.id, props.addressNew.address.id)
            }else{
                getAddressOrg(props)
            }
        }
    }
}

async function getKoatuuRegion(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/koatuu/byMask`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json1 = await response.json()
        if(json1.hasOwnProperty('message') === true){}
        else{
            props.setKoatuuRegion(json1)
        }
        
    }
}

async function getKoatuuGorod(props, id){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/koatuu/byMask?mask=${id}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json1 = await response.json()
        if(json1.hasOwnProperty('message') === true){}
        else{
            props.setKoatuuGorod(json1)
        }
        
    }
}

async function createAddress(props, contactId, identifId){
    let endPoint = null
    let tmpMetod = null
    if(props.addressNew.address.id === null){
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/address`;
        tmpMetod = 'POST'
    }else{
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/address/${props.addressNew.address.id}`;
        tmpMetod = 'PUT'
    }
    let r = props.addressNew.address.region.split('+:+')
    const response = await fetch(endPoint, {
        method: tmpMetod,
        body: JSON.stringify({
            streetAddress: props.addressNew.address.streetAddress,
            locality: props.addressNew.address.locality,
            region: getRegion(r[1]),
            postalCode: props.addressNew.address.postalCode,
            countryName: props.addressNew.address.countryName
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json1 = await response.json()
        if(json1.hasOwnProperty('message') === true){
            //await props.setPopAddressError(json1)
        }else{
            if(props.typeOrg === true){
                await createAuctionRelatedOrganizations(props, contactId, identifId, json1.id)
            }else{
                getAddressOrg(props)
            }
        }
    }
}

async function createAddressNew(props, contactId, identifId){
    let endPoint = null
    let tmpMetod = null
    if(props.addressNew.address.id === null){
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/address`;
        tmpMetod = 'POST'
    }else{
        let tmpType = null
        if(props.auctionsMy.relatedOrganizations !== null && props.auctionsMy.relatedOrganizations.currentTenant !== null && props.addressNew.id === props.auctionsMy.relatedOrganizations.currentTenant.id) tmpType = 'currentTenant'
        if(props.auctionsMy.relatedOrganizations !== null && props.auctionsMy.relatedOrganizations.propertyOwner !== null && props.addressNew.id === props.auctionsMy.relatedOrganizations.propertyOwner.id) tmpType = 'propertyOwner'
        if(props.auctionsMy.relatedOrganizations !== null && props.auctionsMy.relatedOrganizations.sellingEntity !== null && props.addressNew.id === props.auctionsMy.relatedOrganizations.sellingEntity.id) tmpType = 'sellingEntity'
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}/related_organizations/${tmpType}/address`;
        tmpMetod = 'PUT'
    }
    let r = props.addressNew.address.region.split('+:+')
    const response = await fetch(endPoint, {
        method: tmpMetod,
        body: JSON.stringify({
            streetAddress: props.addressNew.address.streetAddress,
            locality: props.addressNew.address.locality,
            region: getRegion(r[1]),
            postalCode: props.addressNew.address.postalCode,
            countryName: props.addressNew.address.countryName
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json1 = await response.json()
        if(json1.hasOwnProperty('message') === true){
            //await props.setPopAddressError(json1)
        }else{
            if(props.typeOrg === true){
                await createAuctionRelatedOrganizations(props, contactId, identifId, json1.id)
            }else{
                getAddressOrg(props)
            }
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctionsMy: state.start.auctionsMy,
        dictionaries: state.start.dictionaries,
        addressNew: state.start.addressNew,
        schedule: state.start.schedule,
        koatuu: state.start.koatuu,
        typeOrg: state.start.typeOrg,
    }
}

const mapDispatchToProps = {
    setDataAddress,
    setKoatuuGorod,
    setKoatuuRegion,
    changeLoader,

    setEditAuctionFromId,
    changeDiscountPerviousAuctionId,
    changeDiscountPerviousAuctionAmount,
    changeDiscountPercent,
}

export default connect(mapStateToProps, mapDispatchToProps)(Organization)
