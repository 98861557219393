import React from 'react';
import styles from './chatForm.module.css';

import {connect} from 'react-redux';

import {answerTitle, answerDiscription} from '../../../redux/actions/questions/questions.js';
import {auctionGetId, auctionGetIdToken} from '../../../redux/actions/auctions/auctions.js';

const ChatForm = (props) => {
    let tmp = <div className={styles.terminu}>
            <div className={styles.documentElem}>
                <div className={styles.bidLotCost}>
                    <div className={styles.bidLotCost_title}>Тема запитання</div>
                    <div className={styles.bidLotCost_input}>
                        <input 
                            className={styles.inputNumber}
                            value={props.questionCreate.title}
                            onChange={(e) => {
                                props.answerTitle(e.target.value)
                            }}
                        />
                    </div>
                </div>
                <div className={styles.bidLotCost}>
                    <div className={styles.bidLotCost_title}>Зміст запитання</div>
                    <div className={styles.bidLotCost_input}>
                        <textarea 
                            className={styles.inputTextArea}
                            value={props.questionCreate.description}
                            onChange={(e) => {
                                props.answerDiscription(e.target.value)
                            }}
                        />
                    </div>
                </div>
                <div className={styles.bidLotCost}>
                    <div className={styles.answerButton}
                        onClick = {
                            () => {
                                if(props.questionCreate.description !== '' && props.questionCreate.title !== ''){
                                    createAnswer(props, props.auction.id)
                                }
                            }
                        }
                    >
                        Надіслати запитання
                    </div>
                </div>
            </div>
        </div>
    return tmp
}

async function  createAnswer(props, id){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${id}/question`;
    const response1 = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify({
            title: props.questionCreate.title,
            description: props.questionCreate.description,
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
    }else{
        let tmpBaseUrl = null;
        let tmpId = null;
        if(window.location.pathname.indexOf('/tenders/') !== -1){
            tmpBaseUrl = window.location.pathname.split('/tenders/');
            tmpId = tmpBaseUrl[1].split('/')
        }
        let data = {
            domen: process.env.REACT_APP_END_POINT_BD,
            version: 'v1',
            local: props.langFlag,
            id: tmpId[0]
        }
        if(props.auction.sellingMethod !== "dgf-english" ||
            props.auction.sellingMethod !== "dgf-english-fast" ||
            props.auction.sellingMethod !== "dgf-english-fast-manual" ||
            props.auction.sellingMethod !== "dgf-english-fast-auction-manual-qualification" ||
            props.auction.sellingMethod !== "dgf-english-initial-auction" ||
            props.auction.sellingMethod !== "dgf-english-initial-auction-manual" ||
            props.auction.sellingMethod !== "dgf-english-initial-qualification" ||
            props.auction.sellingMethod !== "dgf-dutch" ||
            props.auction.sellingMethod !== "dgf-dutch-fast" ||
            props.auction.sellingMethod !== "dgf-dutch-fast-manual" ||
            props.auction.sellingMethod !== "dgf-dutch-fast-auction-manual-qualification" ||
            props.auction.sellingMethod !== "dgf-dutch-initial-auction" ||
            props.auction.sellingMethod !== "dgf-dutch-initial-qualification" ||
            props.auction.sellingMethod !== "dgf-dutch-initial-auction-manual"
        ){
            const urlAsync = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${id}/sync`;
            const responseAsync = await fetch(urlAsync, {
                method: "GET",
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(responseAsync === undefined || responseAsync === 'undefined'){
                //props.auctionGetId(data)
                props.auctionGetIdToken(data, props.token.access_token)
            }else{
                //props.auctionGetId(data)
                props.auctionGetIdToken(data, props.token.access_token)
            }
        }else{
            //props.auctionGetId(data)
            props.auctionGetIdToken(data, props.token.access_token)
        }

        props.answerTitle('')
        props.answerDiscription('')
    }
}



const mapStateToProps = state => {
    return {
        token: state.start.token,
        auction: state.start.auctionsGetId,
        langFlag: state.start.langFlag,
        questionCreate: state.start.questionCreate,
    }
}

const mapDispatchToProps = {
    auctionGetId,
    answerTitle,
    answerDiscription,
    auctionGetIdToken,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatForm)
