import React from 'react';
import styles from './lineTwoElemString.module.css';

export default function LineTwoElemString( props ) {
    return (
        <div className={styles.terminuLine}>
            <div className={styles.terminuLineText}>{props.titleString}</div>
            <div className={setStyle(props)}>{props.textString}</div>
        </div>
    )
}

function setStyle(props){
    let tmp = null;
    if(props.grey === true){
        tmp = styles.terminuLineDate + ' ' + styles.grey;
    } else {
        tmp = styles.terminuLineDate;
    }
    return tmp
}