import React,{useState} from 'react';
import styles from './jointPropertyComplexItem.module.css';

import {connect} from 'react-redux';
import { createItemCS, saveItemCS, getValues, getValuesUA } from '../utils';
import { isMongo, checkProp } from '../../../../../redux/check';

import StateRegistration from '../../../elems/stateRegistration/stateRegistration';

import TextArea from '../../../../elements/inputs/textArea'
import Input from '../../../../elements/inputs/input';
import Select from '../../../../elements/inputs/inputSelect'
import Localization from '../../../elems/localization/localization';
import Button from '../../../../elements/buttons/button';
import Checkbox from '../../../../elements/inputs/checkBox';

import {
    setDataAddress,
} from '../../../../../redux/createAddress';

import {
    getMyAuction,
    changeLoader,
    setUniversalError,
} from '../../../../../redux/actions.js';

import {popUpAddress} from '../../../../../redux/actions/address/address.js';

import {disabletElem} from '../../../disabled'
import {clearDataLot} from '../clearDataItem'

const JointPropertyComplexItem = ( {data, classification, ...props} ) => {
    const [description, setDescription] = useState(checkProp(data, 'description') ? isMongo(data.description) : null)
    const [unit, setUnit] = useState(checkProp(data, 'unit') ? data.unit.code : 'HAR')
    const [quantity, setQuantity] = useState(checkProp(data, 'quantity') ? data.quantity : null)
    const [location, setLocation] = useState(checkProp(data, 'location') ? data.location : null)
    const [address, setAdress] = useState(checkProp(data, 'address') ? data.address : null)
    const [stateRegisration, setSateRegisration] = useState(checkProp(data, 'registrationDetails') ? data.registrationDetails : null )

    const [quantityAndNomenclature, setQuantityAndNomenclature] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'quantityAndNomenclature') ? data.itemProps.quantityAndNomenclature : null)
    const [workplacesQuantity, setWorkplacesQuantity] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'workplacesQuantity') ? data.itemProps.workplacesQuantity : null)
    const [buildingsInformation, setBuildingsInformation] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'buildingsInformation') ? data.itemProps.buildingsInformation : null)
    const [landPlotInformation, setLandPlotInformation] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'landPlotInformation') ? data.itemProps.landPlotInformation : null)
    const [mainObligations, setMainObligations] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'mainObligations') ? data.itemProps.mainObligations : null)

    const [cadastral, setCadastral] = useState(checkProp(data,'itemProps') && checkProp(data.itemProps.landProps, 'cadastralNumber') ? data.itemProps.landProps.cadastralNumber : null)
    const [landArea, setLandArea] = useState(checkProp(data,'itemProps') && checkProp(data.itemProps.landProps, 'landArea') ? data.itemProps.landProps.landArea : null)
    const [ownershipType, setOwnershipType] = useState(checkProp(data,'itemProps') && checkProp(data.itemProps.landProps, 'ownershipType') ? data.itemProps.landProps.ownershipType : null)
    // const [encumbrances, setEncumbrances] = useState(checkProp(data,'itemProps') && checkProp(data.itemProps.landProps, 'encumbrances') ? data.itemProps.landProps.encumbrances : null)
    // const [jointOwnership, setJointOwnership] = useState(checkProp(data,'itemProps') && checkProp(data.itemProps.landProps, 'jointOwnership') ? data.itemProps.landProps.jointOwnership : null)
    // const [utilitiesAvailability, setUtilitiesAvailability] = useState(checkProp(data,'itemProps') && checkProp(data.itemProps.landProps, 'utilitiesAvailability') ? data.itemProps.landProps.utilitiesAvailability : null)
    const [landIntendedUse, setLandIntendedUse] = useState(checkProp(data,'itemProps') && checkProp(data.itemProps.landProps, 'landIntendedUse') ? data.itemProps.landProps.landIntendedUse : null)

    const [encumbrances, setEncumbrances] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps.landProps, 'encumbrances') ? data.itemProps.landProps.encumbrances : null)
    const [jointOwnership, setJointOwnership] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps.landProps, 'jointOwnership') ? data.itemProps.landProps.jointOwnership : null)
    const [utilitiesAvailability, setUtilitiesAvailability] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps.landProps, 'utilitiesAvailability') ? data.itemProps.landProps.utilitiesAvailability : null)


    const [hasEncumbrances, setHasEncumbrances] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps.landProps, 'hasEncumbrances') ? data.itemProps.landProps.hasEncumbrances : false)
    const [hasJointOwnership, setHasJointOwnership] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps.landProps, 'hasJointOwnership') ? data.itemProps.landProps.hasJointOwnership : false)
    const [hasUtilitiesAvailability, setHasUtilitiesAvailability] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps.landProps, 'hasUtilitiesAvailability') ? data.itemProps.landProps.hasUtilitiesAvailability : false)


    let requestBody = {
        description: {
            uk_UA: description,
        },
        classification: {
            id: classification,
            scheme: getValues(props.classificationsBseBsd, classification, 'id', 'scheme'),
            description: {
                uk_UA: getValuesUA(props.classificationsBseBsd, classification, 'id', 'description'),
            }
        },
        unit: {
            code: unit,
            name: {
                uk_UA: getValues(props.classificationsZU.unitCode, unit, 'code', 'name'),
            }
        },
        quantity: +quantity,
        address: address,
        location: location,
        itemProps: {
            quantityAndNomenclature: quantityAndNomenclature,
            workplacesQuantity: workplacesQuantity,
            buildingsInformation: buildingsInformation,
            landPlotInformation: landPlotInformation,
            mainObligations: mainObligations,
            landProps: { 
                landArea: +landArea,
                cadastralNumber: cadastral,
                ownershipType: ownershipType,
                // jointOwnership: jointOwnership === null ? 'немає' : jointOwnership,
                // encumbrances: encumbrances === null ? 'noEncumbrances' : encumbrances,
                // utilitiesAvailability: utilitiesAvailability === null ? 'немає' : utilitiesAvailability,
                landIntendedUse: landIntendedUse,
                hasEncumbrances: hasEncumbrances === true ? true : false,
                hasJointOwnership: hasJointOwnership === true ? true : false,
                hasUtilitiesAvailability: hasUtilitiesAvailability === true ? true : false,
            }
        },
        registrationDetails: stateRegisration,
        // commercialSellItemType: null
    }
    function fHasEncumbrances(requestBody){
        if(!hasEncumbrances) return requestBody
        requestBody.itemProps.landProps.encumbrances = encumbrances
        return requestBody
    }
    function fHasJointOwnership(requestBody){
        if(!hasJointOwnership) return requestBody
        requestBody.itemProps.landProps.jointOwnership = jointOwnership
        return requestBody
    }
    function fHasUtilitiesAvailability(requestBody){
        if(!hasUtilitiesAvailability) return requestBody
        requestBody.itemProps.landProps.utilitiesAvailability = utilitiesAvailability
        return requestBody
    }


    function viewButtons(props){
        if(
            props.auctionsMy.items === null ||
            props.auctionsMy.items.length === 0
        ){
            if(
                description !== null && description !== '' && landArea !== null && cadastral !== null
            ){
                    return (
                        <div className={styles.lineButtons}>
                            <Button
                                data={'Зберегти зміни'}
                                color={'blue'}
                                onClick={
                                    () => {
                                        let tmp = requestBody
                                        tmp = fHasJointOwnership(tmp)
                                        tmp = fHasEncumbrances(tmp)
                                        tmp = fHasUtilitiesAvailability(tmp)
                                        createItemZU(props, tmp)
                                        props.edit(false)
                                    }
                                }
                            />
                            <Button
                                data={'Закрити'}
                                color={'gray'}
                                onClick={
                                    () => {
                                        clearDataLot(props)
                                        props.edit(false)
                                    }
                                }
                            />
                        </div>
                    )
            }
        }else{
                if(
                    description !== null && description !== '' && landArea !== null && cadastral !== null
                ){
                    return (
                        <div className={styles.lineButtons}>
                            <Button
                                data={'Зберегти зміни'}
                                color={'blue'}
                                onClick={
                                    () => {
                                        let tmp = requestBody
                                        tmp = fHasJointOwnership(tmp)
                                        tmp = fHasEncumbrances(tmp)
                                        tmp = fHasUtilitiesAvailability(tmp)
                                        if(
                                            !checkProp(data, 'id')
                                        ){
                                            createItemZU(props, tmp)
                                        }else{
                                            saveItemZU(props, tmp, data.id)
                                        }
                                        props.edit(false)
                                    }
                                }
                            />
                            <Button
                                data={'Закрити'}
                                color={'gray'}
                                onClick={
                                    () => {
                                        clearDataLot(props)
                                        props.edit(false)
                                    }
                                }
                            />
                        </div>
                    )
                }
        }
    }

    return (
        <>
            <TextArea
                value={description}
                disabled={disabletElem(props.auctionsMy, '01')}
                label="Опис об'єкта"
                required
                onChange={(event)=>{ setDescription(event) }}
            />
            <StateRegistration 
                data={stateRegisration} 
                setValue={(event)=> setSateRegisration(event)}
            />
            <Input
                label="Обсяг та основна номенклатура продукції (робіт, послуг), у тому числі експортної"
                value={quantityAndNomenclature}
                onChange={(event)=>{ setQuantityAndNomenclature(event) }}
                disabled={disabletElem(props.auctionsMy, '01')}
            />
            <Input
                label="Кількість робочих місць, ociб"
                value={workplacesQuantity}
                onChange={(event)=>{ setWorkplacesQuantity(event)
                }}
                disabled={disabletElem(props.auctionsMy, '01')}
            />
            <Input
                label="Відомості про будівлі (споруди, приміщення) єдиного майнового комплексу в обсязі, передбаченому для інформації про нерухоме майно"
                value={buildingsInformation}
                onChange={(event)=>{ setBuildingsInformation(event) }}
                disabled={disabletElem(props.auctionsMy, '01')}
            />
            <Input
                label="Відомості про земельну ділянку, на якій розташовано єдиний майновий комплекс"
                value={landPlotInformation}
                onChange={(event)=>{ setLandPlotInformation(event) }}
                disabled={disabletElem(props.auctionsMy, '01')}
            />
            <div className={styles.twoInLine}>
                <Input
                    label="Площа ділянки, га"
                    type='number'
                    value={landArea}
                    onChange={(event)=>{ 
                        setLandArea(event)
                        setQuantity(event)
                    }}
                    disabled={disabletElem(props.auctionsMy, '01')}
                    required
                />
                <Select
                    data={{
                        label: "Одиниці виміру",
                        target: 'HAR',
                        required: true,
                    }}
                    onChange={(event) => {
                        props.setDataAddress(event, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_UNIT_CODE')
                    }}
                    options={classificationSubsoilUnitCode(props)}
                />
            </div>
            <Input
                label="Кадастровий номер"
                value={cadastral}
                onChange={(event)=>{ setCadastral(event) }}
                disabled={disabletElem(props.auctionsMy, '01')}
                descr='0000000000:00:000:0000'
                required
            />
            <Select
                data={{
                    label: "Тип власності",
                    target: ownershipType,
                    required: true,
                }}
                onChange={(event) => { setOwnershipType(event) }}
                options={[
                    {value: "compatible", name: 'Сумісна'},
                    {value: "municipal", name: 'Комунальна'},
                    {value: "private", name: 'Приватна'},
                    {value: "state", name: 'Державна'},
                    {value: "unknown", name: 'Невідомо'}
                ]}
            />
            
            <div className={styles.blokInputs}>
                <div className={styles.semiTitle}>Увага! При встановленні відмітки у полі щодо наявності обтяжень/співласників/інженерних мереж обов'язково заповніть відповідні поля з пов'язаною інформацією.</div>
                <Checkbox 
                    value={hasEncumbrances}
                    onChange={setHasEncumbrances}
                >
                    Присутні обтяження
                </Checkbox>
                <Select
                    data={{
                        label: "Інформація про наявні обтяження",
                        target: encumbrances,
                    }}
                    onChange={(event) => {
                        setEncumbrances(event)
                        // props.setDataAddress(event, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_ENCUMBRANCES')
                    }}
                    options={[
                        //{value: "noEncumbrances", name: 'Обтяження відсутні'},
                        {value: "arrest", name: 'Арешт нерухомого майна '},
                        {value: "collateral", name: 'Податкова застава'},
                        {value: "restraintsOnAlienation", name: 'Заборона відчуження'},
                        {value: "otherEncumbrances", name: 'Інші обтяження'},
                    ]}
                    disabled={hasEncumbrances ? false : true}
                />
                {/*<Input
                    label="Інформація про наявні обтяження"
                    value={encumbrances}
                    onChange={(event)=>{
                        setEncumbrances(event)
                        // props.setDataAddress(event, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_JOINT_OWNERSHIP')
                    }}
                    //error = {redBlock(props, props.createItems.landRental.itemProps.cadastralNumber)}
                    disabled={hasEncumbrances ? false : true}
                />*/}
                <Checkbox 
                    value={hasJointOwnership}
                    onChange={setHasJointOwnership}
                >
                    Наявні співвласники
                </Checkbox>
                <Input
                    label="Інформація про співвласників"
                    value={jointOwnership}
                    onChange={(event)=>{
                        setJointOwnership(event)
                        // props.setDataAddress(event, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_JOINT_OWNERSHIP')
                    }}
                    //error = {redBlock(props, props.createItems.landRental.itemProps.cadastralNumber)}
                    disabled={hasJointOwnership ? false : true}
                />
                <Checkbox 
                    value={hasUtilitiesAvailability}
                    onChange={setHasUtilitiesAvailability}
                >
                    Інженерні мережі наявні
                </Checkbox>
                <Input
                    label="Інформація про інженерні мережі"
                    value={utilitiesAvailability}
                    onChange={(event)=>{
                        setUtilitiesAvailability(event)
                        // props.setDataAddress(event, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_UTILITIES_AVAILABILITY')
                    }}
                    //error = {redBlock(props, props.createItems.landRental.itemProps.cadastralNumber)}
                    disabled={hasUtilitiesAvailability ? false : true}
                />
            </div>

            <Input
                label="Поточне використання земельної ділянки"
                value={landIntendedUse}
                onChange={(event)=>{ setLandIntendedUse(event) }}
                disabled={disabletElem(props.auctionsMy, '01')}
            />
            <Input
                label="Основні зобов’язання (договірні та позадоговірні)"
                value={mainObligations}
                onChange={(event)=>{ setMainObligations(event) }}
                disabled={disabletElem(props.auctionsMy, '01')}
            />
            <Localization address={address} location={location} setLocation={setLocation} setAdress={setAdress} />
            {viewButtons(props)}
        </>
    )
}

function classificationSubsoilUnitCode(props){
    if(props.classificationsZU.unitCode !== null){
        return props.classificationsZU.unitCode
        .filter(
            (i) => {
                return i.code === 'HAR'// || i.code === 'MTK'
            }
        )
        .map(
            (i) => {
                return { value: i.code, name: i.name, selected: true}
            }
        )
    }
}

async function createItemZU(props, requestBody){
    props.changeLoader(true)
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            await createItemCS(props, requestBody)
        }
    }
}

async function saveItemZU(props, requestBody, itemId){
    props.changeLoader(true)
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            saveItemCS(props, requestBody, itemId)
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        auctionsMy: state.start.auctionsMy,
        createItems: state.start.createItems,
        dictionaries: state.start.dictionaries,
        addressGetId: state.start.addressGetId,
        classificationsNadraEnglish: state.start.classificationsNadraEnglish,
        userProfiles: state.start.userProfiles,
        classificationsDgf: state.start.classificationsDgf,
        classificationsZU: state.start.classificationsZU,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
        classificationsBseBsd: state.start.classificationsBseBsd
    }
}

const mapDispatchToProps = {
    setDataAddress,
    changeLoader,
    getMyAuction,
    popUpAddress,
    setUniversalError,
}

export default connect(mapStateToProps, mapDispatchToProps)(JointPropertyComplexItem)