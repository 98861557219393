import {сreateBodyTIE} from './createBodyTIE'
import {сreateBodySUE} from './createBodySUE'
import {сreateBodySUD} from './createBodySUD'
import {сreateBodyRCE} from './createBodyRCE'
import {сreateBodyRCD} from './createBodyRCD'
import {сreateBodyLRE} from './createBodyLRE'
import {сreateBodyLSE} from './createBodyLSE'
import {сreateBodyLSP} from './createBodyLSP'
import {сreateBodyBSE} from './createBodyBSE'
import {сreateBodyBSD} from './createBodyBSD'
import {сreateBodyALE} from './createBodyALE'
import {сreateBodyCSE} from './createBodyCSE'
import {сreateBodyCSD} from './createBodyCSD'
import {сreateBodyBRE} from './createBodyBRE'
import {сreateBodyBRD} from './createBodyBRD'
import {сreateBodyBRW} from './createBodyBRW'

import {сreateBodyRLE} from './createBodyRLE'
import {сreateBodyRLD} from './createBodyRLD'

export function сreateBodyOld(data){
    console.log("VSE TUT")
    switch(data.base.sellingMethod){
        case "timber-english":
        case "timber-english-fast":
        case "timber-english-fast-manual":
        case "timber-english-initial-auction":
        case "timber-english-initial-qualification":
        case "timber-english-initial-qualification-fast":
            return сreateBodyTIE(data)
        case "renewables":
        case "renewables-fast":
        case "renewables-fast-manual":
        case "renewables-initial-auction":
        case "renewables-initial-qualification":
        case "renewables-initial-qualification-fast":
            return data
        case "subsoil-english":
        case "subsoil-english-fast":
        case "subsoil-english-fast-manual":
        case "subsoil-english-initial-auction":
        case "subsoil-english-initial-qualification":
            return сreateBodySUE(data)
        case "subsoil-dutch":
        case "subsoil-dutch-fast":
        case "subsoil-dutch-fast-manual":
        case "subsoil-dutch-initial-auction":
        case "subsoil-dutch-initial-qualification":
        case "subsoil-dutch-initial-auction-manual":
        case "subsoil-english-fast-auction-manual-qualification":
            return сreateBodySUD(data)
        case "railwayCargo-english":
        case "railwayCargo-english-fast":
        case "railwayCargo-english-fast-manual":
        case "railwayCargo-english-initial-auction":
        case "railwayCargo-english-initial-qualification":
            return сreateBodyRCE(data)
        case "railwayCargo-dutch":
        case "railwayCargo-dutch-fast":
        case "railwayCargo-dutch-fast-manual":
        case "railwayCargo-dutch-initial-auction":
        case "railwayCargo-dutch-initial-qualification":
        case "railwayCargo-dutch-fast-auction-manual-qualification":
        case "railwayCargo-dutch-initial-auction-manual":
            return сreateBodyRCD(data)
        case "dgf-english":
        case "dgf-english-fast":
        case "dgf-english-fast-manual":
        case "dgf-english-fast-auction-manual-qualification":
        case "dgf-english-initial-auction":
        case "dgf-english-initial-auction-manual":
        case "dgf-english-initial-qualification":
            return data
        case "legitimatePropertyLease-english":
        case "legitimatePropertyLease-english-fast":
        case "legitimatePropertyLease-english-fast-manual":
        case "legitimatePropertyLease-english-fast-auction-manual-qualification":
        case "legitimatePropertyLease-english-initial-auction":
        case "legitimatePropertyLease-english-initial-auction-manual":
        case "legitimatePropertyLease-english-initial-qualification":
        case "legitimatePropertyLease-english-initial-qualification-prod":
            return data
        case "legitimatePropertyLease-dutch":
        case "legitimatePropertyLease-dutch-fast":
        case "legitimatePropertyLease-dutch-fast-manual":
        case "legitimatePropertyLease-dutch-fast-auction-manual-qualification":
        case "legitimatePropertyLease-dutch-initial-auction":
        case "legitimatePropertyLease-dutch-initial-auction-manual":
        case "legitimatePropertyLease-dutch-initial-qualification-prod":
            return data
        case "legitimatePropertyLease-priorityEnglish":
        case "legitimatePropertyLease-priorityEnglish-fast":
        case "legitimatePropertyLease-priorityEnglish-fast-manual":
        case "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-auction":
        case "legitimatePropertyLease-priorityEnglish-initial-auction-manual":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification-prod":
            return data
        case 'landRental-english':
        case 'landRental-english-fast':
        case 'landRental-english-fast-manual':
        case 'landRental-english-fast-auction-manual-qualification':
        case 'landRental-english-initial-auction':
        case 'landRental-english-initial-auction-manual':
        case 'landRental-english-initial-qualification':
        case 'landRental-english-initial-qualification-prod':
            return сreateBodyLRE(data)
        case 'landSell-english':
        case 'landSell-english-fast':
        case 'landSell-english-fast-manual':
        case 'landSell-english-fast-auction-manual-qualification':
        case 'landSell-english-initial-auction':
        case 'landSell-english-initial-auction-manual':
        case 'landSell-english-initial-qualification':
        case 'landSell-english-initial-qualification-prod':
            return сreateBodyLSE(data)
        case 'landSell-priorityEnglish':
        case 'landSell-priorityEnglish-fast':
        case 'landSell-priorityEnglish-fast-manual':
        case 'landSell-priorityEnglish-fast-auction-manual-qualification':
        case 'landSell-priorityEnglish-initial-auction':
        case 'landSell-priorityEnglish-initial-auction-manual':
        case 'landSell-priorityEnglish-initial-qualification':
        case 'landSell-priorityEnglish-initial-qualification-prod':
            return сreateBodyLSP(data)
        case 'basicSell-english':
        case 'basicSell-english-fast':
        case 'basicSell-english-fast-manual':
        case 'basicSell-english-fast-auction-manual-qualification':
        case 'basicSell-english-initial-auction':
        case 'basicSell-english-initial-auction-manual':
        case 'basicSell-english-initial-qualification':
        case 'basicSell-english-initial-qualification-prod':
            return сreateBodyBSE(data)
        case 'basicSell-dutch':
        case 'basicSell-dutch-fast':
        case 'basicSell-dutch-fast-manual':
        case 'basicSell-dutch-fast-auction-manual-qualification':
        case 'basicSell-dutch-initial-auction':
        case 'basicSell-dutch-initial-auction-manual':
        case 'basicSell-dutch-initial-qualification':
        case 'basicSell-dutch-initial-qualification-prod':
            return сreateBodyBSD(data)
        case 'alienation-english':
        case 'alienation-english-fast':
        case 'alienation-english-fast-manual':
        case 'alienation-english-fast-auction-manual-qualification':
        case 'alienation-english-initial-auction':
        case 'alienation-english-initial-auction-manual':
        case 'alienation-english-initial-qualification':
        case 'alienation-english-initial-qualification-prod':
            return сreateBodyALE(data)
        case 'arrestedAssets-english':
        case 'arrestedAssets-english-fast':
        case 'arrestedAssets-english-fast-manual':
        case 'arrestedAssets-english-fast-auction-manual-qualification':
        case 'arrestedAssets-english-initial-auction':
        case 'arrestedAssets-english-initial-auction-manual':
        case 'arrestedAssets-english-initial-qualification':
        case 'arrestedAssets-english-initial-qualification-prod':
            return сreateBodyBSE(data)
        case 'commercialSell-english':
        case 'commercialSell-english-fast':
        case 'commercialSell-english-fast-manual':
        case 'commercialSell-english-fast-auction-manual-qualification':
        case 'commercialSell-english-initial-auction':
        case 'commercialSell-english-initial-auction-manual':
        case 'commercialSell-english-initial-qualification':
        case 'commercialSell-english-initial-qualification-prod':
            return сreateBodyCSE(data)
        case 'commercialSell-dutch':
        case 'commercialSell-dutch-fast':
        case 'commercialSell-dutch-fast-manual':
        case 'commercialSell-dutch-fast-auction-manual-qualification':
        case 'commercialSell-dutch-initial-auction':
        case 'commercialSell-dutch-initial-auction-manual':
        case 'commercialSell-dutch-initial-qualification':
        case 'commercialSell-dutch-initial-qualification-prod':
            return сreateBodyCSD(data)
        case 'bankRuptcy-english':
        case 'bankRuptcy-english-fast':
        case 'bankRuptcy-english-fast-manual':
        case 'bankRuptcy-english-fast-auction-manual-qualification':
        case 'bankRuptcy-english-initial-auction':
        case 'bankRuptcy-english-initial-auction-manual':
        case 'bankRuptcy-english-initial-qualification':
        case 'bankRuptcy-english-initial-qualification-prod':
            return сreateBodyBRE(data)
        case 'bankRuptcy-dutch':
        case 'bankRuptcy-dutch-fast':
        case 'bankRuptcy-dutch-fast-manual':
        case 'bankRuptcy-dutch-fast-auction-manual-qualification':
        case 'bankRuptcy-dutch-initial-auction':
        case 'bankRuptcy-dutch-initial-auction-manual':
        case 'bankRuptcy-dutch-initial-qualification':
        case 'bankRuptcy-dutch-initial-qualification-prod':
            return сreateBodyBRD(data)
        case 'bankRuptcy-withoutAuction':
            return сreateBodyBRW(data)
        case 'regulationsPropertyLease-english':
        case 'regulationsPropertyLease-english-ultra-fast':
        case 'regulationsPropertyLease-english-fast':
        case 'regulationsPropertyLease-english-fast-manual':
        case 'regulationsPropertyLease-english-fast-auction-manual-qualification':
        case 'regulationsPropertyLease-english-fast-auction-prod':
        case 'regulationsPropertyLease-english-initial-auction':
        case 'regulationsPropertyLease-english-initial-auction-manual':
        case 'regulationsPropertyLease-english-initial-qualification':
        case 'regulationsPropertyLease-english-initial-qualification-prod':
        case 'commercialPropertyLease-english':
        case 'commercialPropertyLease-english-ultra-fast':
        case 'commercialPropertyLease-english-fast':
        case 'commercialPropertyLease-english-fast-manual':
        case 'commercialPropertyLease-english-fast-auction-manual-qualification':
        case 'commercialPropertyLease-english-fast-auction-prod':
        case 'commercialPropertyLease-english-initial-auction':
        case 'commercialPropertyLease-english-initial-auction-manual':
        case 'commercialPropertyLease-english-initial-qualification':
        case 'commercialPropertyLease-english-initial-qualification-prod':
            return сreateBodyRLE(data)
        case "regulationsPropertyLease-dutch":
        case "regulationsPropertyLease-dutch-ultra-fast":
        case "regulationsPropertyLease-dutch-fast":
        case "regulationsPropertyLease-dutch-fast-manual":
        case "regulationsPropertyLease-dutch-fast-auction-manual-qualification":
        case "regulationsPropertyLease-dutch-fast-auction-prod":
        case "regulationsPropertyLease-dutch-initial-auction":
        case "regulationsPropertyLease-dutch-initial-auction-manual":
        case "regulationsPropertyLease-dutch-initial-qualification":
        case "regulationsPropertyLease-dutch-initial-qualification-prod":
        case "commercialPropertyLease-dutch":
        case "commercialPropertyLease-dutch-ultra-fast":
        case "commercialPropertyLease-dutch-fast":
        case "commercialPropertyLease-dutch-fast-manual":
        case "commercialPropertyLease-dutch-fast-auction-manual-qualification":
        case "commercialPropertyLease-dutch-fast-auction-prod":
        case "commercialPropertyLease-dutch-initial-auction":
        case "commercialPropertyLease-dutch-initial-auction-manual":
        case "commercialPropertyLease-dutch-initial-qualification":
        case "commercialPropertyLease-dutch-initial-qualification-prod":
            return сreateBodyRLD(data)
        default:
            return data
    }
}