import React from 'react';
import styles from './routersLot.module.css';

import { Link } from 'react-router-dom';

import {connect} from 'react-redux';

const Chat = ( props ) => {
    let sOgol = null, sChat = null;
    if(props.type){
        sOgol = styles.tabTovar_ogosha;
        sChat = styles.tabTovar_tell;
    }else{
        sOgol = styles.tabTovar_tell;
        sChat = styles.tabTovar_ogosha;
    }
    return (
        <div className={styles.tabTovar_wrap}>
            <div className={sOgol}><Link to={`/tenders/${props.pageNumber}`}>ОГОЛОШЕННЯ</Link></div>
            <div className={sChat}><Link to={`/tenders/${props.pageNumber}/questions`}>ОБГОВОРЕННЯ</Link></div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        pageNumber: state.start.pageNumber
    }
}

export default connect(mapStateToProps, null)(Chat)