import React from 'react';

import {connect} from 'react-redux';

import {changeAuctionsSwitchWindow} from '../../redux/actions/auctions/auctions.js';

import {
    getMyAuction,
    changeCreateAuctionTenderAttempts,
} from '../../redux/actions.js';

import {setBankAccountSave, setBankAccountAdd, setBankAccountDelete} from '../../redux/actions/bankAccount/bankAccount.js';

import {
    setDataAddress,
} from '../../redux/createAddress';

import Button from '@material-ui/core/Button';

const ButtonCreateAuction = ( props ) => {
    return (
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={
                    () => {
                        props.changeAuctionsSwitchWindow(2)
                        props.getMyAuction(props.auctionsMyNew)
                        props.changeCreateAuctionTenderAttempts(1)
                        props.setBankAccountSave([])
                        props.setBankAccountAdd([])
                        props.setBankAccountDelete([])
                        getAddressOrg(props)
                    }
                }
            >
                Створити чернетку аукціону
            </Button>
        </>
    )
}

async function getAddressOrg(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/organizations?limit=30&offset=0`
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            //props.setEditAuctionFromId(json)
            props.setDataAddress(json, 'GET_ADDRESS_NEW_BOOKS')
        }
    }
}

const mapStateToProps = state => {
    return {
        auctionsMyNew: state.start.auctionsMyNew,
        token: state.start.token,
        addressNew: state.start.addressNew,
    }
}

const mapDispatchToProps = {
    changeAuctionsSwitchWindow,
    getMyAuction,
    changeCreateAuctionTenderAttempts,

    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,

    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonCreateAuction)
