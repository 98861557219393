import React from 'react';
import styles from './windowAuction/windowAuction.module.css';

import {connect} from 'react-redux';

import {
    setDataAddress,
} from '../../redux/createAddress';

import Button from '@material-ui/core/Button';

const ReturnButton = ( props ) => {
    return (
        <Button
            className={styles.createAuctionZU}
            variant="contained"
            color="primary"
            onClick={
                () => {
                    props.setDataAddress(true, 'SET_CREATE_AUCTION_ZU_FLAG')
                }
            }
        >
            Створити чернетку аукціону з оренди державного/комунального майна за Id об'єкта
        </Button>
    )
}

const mapDispatchToProps = {
    setDataAddress,
}

export default connect(null, mapDispatchToProps)(ReturnButton)
