import React from 'react';
import styles from './imageAndButtonLine_IdAuction.module.css';

/*import {
    TENDER_ID_LOTS_LANG,
} from '../../../../../../../../model/lang/catalogLots.js';*/

import {connect} from 'react-redux';

const TENDER_ID_LOTS_LANG = ['Ідентифікатор аукціону', 'TenderID'];

const ImageAndButtonLine_IdAuction = ( props ) => {
    return (
        <div className={styles.identif}>
            <div className={styles.secondBlock_textPrice}>{TENDER_ID_LOTS_LANG[props.langFlag]}</div>
            <div className={styles.secondBlock_textPrice}>{viewClassification(props)}</div>
        </div>
    )
}

/**
 * Выводит идентификатор итема
 * @param {*} props 
 */
function viewClassification(props){
    let tmp = null;
    if(props.item.hasOwnProperty('items') === true){
        if(props.item.items.length > 0){
            if(props.item.items[0].hasOwnProperty('itemIdentifier') === true){
                tmp = props.item.items[0].itemIdentifier
            }
        }
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
    }
}

export default connect(mapStateToProps, null)(ImageAndButtonLine_IdAuction)