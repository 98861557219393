import React, {useState, useEffect} from 'react';
import styles from './docItem.module.css';
import {connect} from 'react-redux';

import { modifiDateString } from '../../redux/modifiDate';
import { setDataAddress } from '../../redux/createAddress';
import { getBidDocType, getAucDocType, getAwardDocType, getProlongation } from '../../redux/docTypes';
import { checkProp } from '../../redux/check';

import TextFull from '../elements/viewData/textFull';
import TextHalf from '../elements/viewData/textHalf';
import DocumentHistory from './history/history.js';
import DocLink from './buttons/buttonLink';
import EcpItem from './docEcp/ecpItem';
import EditDocPopup from './addDocPopup/addDocPopup';
import DocHistoryButton from './buttons/historyBtn';
import DocEditButton from './buttons/editBtn';
import DocDeleteButton from './buttons/deleteBtn';

import { isLAP, isLAE, isAPE, isAPD } from '../../redux/aucTypes';



const DocItem = (props) => {
    const [imagePopup, setImagePopup] = useState(false)
    const [history, setHistory] = useState(false)
    const [historyArr, setHistoryArr] = useState(null)
    const [editPopup, setEditPopup] = useState(false)

    useEffect(() => {
        if (editPopup) {
            setHistoryArr(null)
            setHistory(false)
        }
    }, [editPopup])

    let dataECP = props.dataECP || null
    let historyData = {}
    historyData.state = history
    historyData.setHistory = setHistory
    historyData.arr = historyArr
    historyData.setArr = setHistoryArr

    let editData = {}
    editData.popUp = editPopup
    editData.setPopUp = () => setEditPopup(!editPopup)
    
    let deleteData = {}
    deleteData.docType = docTypeGetter(props.scopeData.aucType, props.data.documentType, props.scopeData.typeOf, props.scopeData.aucStatus)

    function viewImg(i) {
        let imgBlock = null
        if (i.documentType === 'illustration') {
            if (i.url !== null) {
                imgBlock = <div className={styles.lotImage}
                    onClick={() => setImagePopup(!imagePopup)}
                >
                    <div className={styles.imgHover}>
                        <div className={styles.zoomIcon}>
                            <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="-6 -5 30 30">
                                <g id="Слой_2" data-name="Слой 2">
                                    <g id="Слой_1-2" data-name="Слой 1">
                                        <path className={styles.cls} fill="#fff" d="M18.14,16.86,11.2,10a6.09,6.09,0,0,0,1.32-3.8A6.26,6.26,0,0,0,0,6.2a6.23,6.23,0,0,0,6.26,6.2,6.11,6.11,0,0,0,3.62-1.16l7,6.9a.86.86,0,0,0,.62.26.88.88,0,0,0,.64-.26A.93.93,0,0,0,18.14,16.86ZM1.6,6.2a4.66,4.66,0,0,1,9.32,0,4.66,4.66,0,0,1-9.32,0Zm7.78.1a.71.71,0,0,1-.7.7H7V8.6a.7.7,0,1,1-1.4,0V7H3.82a.7.7,0,1,1,0-1.4H5.6V3.78a.7.7,0,0,1,1.4,0V5.6H8.68A.7.7,0,0,1,9.38,6.3Z" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <img src={i.url} alt={checkProp(i.title, 'uk_UA') ? i.title.uk_UA : i.title} />
                </div>
            } else {
                imgBlock = null
            }
        } else {
            imgBlock = null
        }
        return imgBlock
    }

    function imagePopUp(i) {
        return <div className={styles.imageWrapperBg}>
                    <div className={styles.imageWrapper}>
                <div className={styles.popUpClose}
                    onClick={() => setImagePopup(!imagePopup)}>&times;
                </div>
                        <div className={styles.image}>
                            <img src={i.url} />
                        </div>
                    </div>
                </div>
    }

    function viewDocument(i){
        if(i.documentType === 'digitalSignature'){
            return (
                <TextFull
                    title='Версія документа що підписаний КЕП, iD прозорро'
                    data={i.relatedDocument}
                />
            )
        }
        return null
    }

    function docTypeGetter(aucType, docType, typeOf, aucStatus) {
        switch(typeOf) {
            case 'bid':
                return getBidDocType(aucType, docType)
            case 'auction':
            case 'auctionEdit':
            case 'asset':
            case 'large_asset':
            case 'announcement':
            case 'large_announcement':
                return getAucDocType(aucType, docType)
            case 'award':
                return getAwardDocType(aucType, docType)
            case 'contract':
                if(isLAP(props.scopeData.aucType) || isLAE(props.scopeData.aucType)){
                    return listContractDoc().map((a) => {
                        if( a.value === docType) return a.name 
                    })
                }else if(isAPE(props.scopeData.aucType) || isAPD(props.scopeData.aucType)){
                    return listContractDocAPE().map((a) => {
                        if( a.value === docType) return a.name 
                    })
                }else{
                    return props?.scopeData?.docTypesList.map((a) => { if( a.value === docType) return a.name })
                }
            case 'prolongation':
                return getProlongation(aucType, docType)
            case 'cancellation':
                if(isAPE(props.scopeData.aucType) || isAPD(props.scopeData.aucType)){
                    return 'Рішення про скасування'
                }
                return 'Причини скасування'
            case 'additional_information':
                return 'Документ додаткової інформації щодо аукціону'
            default:
                return null
        }
    }

    function listContractDoc() {
        return [
            { value: 'securityPayment', name: 'Підтвердження сплати забезпечувального платежу' },
            { value: 'contractSigned', name: 'Підписаний акт/договір', main: true  },
            { value: 'contractAnnexe', name: 'Додатки до акту/договору' },
            { value: 'paymentInformation', name: 'Відомості про сплату' },
            { value: 'digitalSignature', name: 'Цифровий підпис' }
        ]
    }
    function listContractDocAPE() {
        return [
            { value: 'contractSigned', name: 'Підписаний акт/договір' },
            { value: 'contractAnnexe', name: 'Додатки до акту/договору' },
        ]
    }
    function listCancellationDoc() {
        return [
            { value: 'cancellationDetails', name: 'Причини скасування', main: null }
        ]
    }

    function viewDatePublished(i){
        if(i.datePublished !== null){
            return modifiDateString(i.datePublished, 3, false)
        }else{
            return null
        }
    }

    function viewDateModified(i){
        if(i.dateModified !== null){
            return modifiDateString(i.dateModified, 3, false)
        }else{
            return null
        }
    }

    function viewType(props){
        if(props.data.documentType === "paymentDetails"){
            return <TextHalf
                    title={`Тип документу`}
                    data= {'Документ, що підтверджує сплату'}
                />
        }
        return <TextHalf
            title={`Тип документу`}
            data= {
                props.data.documentType === "***"
                    ? "***"
                    : props.data.documentType === "securityPayment"
                        ? 'Підтвердження сплати забезпечувального платежу'
                        : docTypeGetter(props.scopeData.aucType, props.data.documentType, props.data.documentOf)
                }
        />
    }


    if (props.data.documentType === 'illustration') {
        return <div className={styles.imageItemBlock}>
                {editPopup ?
                    <EditDocPopup
                        docData={props.data}
                        scopeData={props.scopeData}
                        actionClose={()=> setEditPopup(false)}
                        actionNo={()=> setEditPopup(false)}
                    /> : null
                }
                <div className={styles.imageTitleBlock}>
                    {
                        checkProp(props.data.title, 'uk_UA')
                        ? <div className={styles.infoBlockTitle}>{props.data.title.uk_UA}</div>
                        : <div className={styles.infoBlockTitle}>{props.data.title}</div>
                    }
                    <div className={styles.btnBlock}>
                        {!history
                            ? <DocHistoryButton
                                data={props.data}
                                state={historyData}
                                scope={props.scopeData}
                            />
                            : null
                        }
                        {props?.data?.documentOf === 'contract' && props?.userProfiles?.isAdmin === true && props.data.documentType === 'securityPayment' 
                            ? <DocEditButton
                                auction={props.auctionFull}
                                data={props.data}
                                state={editData}
                                scope={props.scopeData}
                            />
                            : null
                        }
                        {props?.data?.documentOf === 'contract' && props?.auction?.privateData?.isOwner === true && props.data.documentType !== 'securityPayment' 
                            ? <DocEditButton
                                auction={props.auctionFull}
                                data={props.data}
                                state={editData}
                                scope={props.scopeData}
                            />
                            : null
                        }
                        {props?.data?.documentOf !== 'contract'
                            ? <DocEditButton
                                auction={props.auctionFull}
                                data={props.data}
                                state={editData}
                                scope={props.scopeData}
                            />
                            : null
                        }
                        <DocLink
                            data={props.data}
                            scope={props.scopeData}
                        />
                        {props?.data?.documentOf === 'contract' && props?.userProfiles?.isAdmin === true && props.data.documentType === 'securityPayment' 
                            ? <DocDeleteButton
                                // props={props}
                                auction={props.auctionFull}
                                data={props.data}
                                state={deleteData}
                                scope={props.scopeData}
                            />
                            : null
                        }
                        {props?.data?.documentOf === 'contract' && props?.auction?.privateData?.isOwner === true && props.data.documentType !== 'securityPayment' 
                            ? <DocDeleteButton
                                // props={props}
                                auction={props.auctionFull}
                                data={props.data}
                                state={deleteData}
                                scope={props.scopeData}
                            />
                            : null
                        }
                        {props?.data?.documentOf !== 'contract'
                            ? <DocDeleteButton
                                // props={props}
                                auction={props.auctionFull}
                                data={props.data}
                                state={deleteData}
                                scope={props.scopeData}
                            />
                            : null
                        }
                    </div>
                </div>
                <div className={styles.imageContentBlock}>
                    <div className={styles.imgContainer}>
                        {viewImg(props.data)}
                    </div>
                    <div className={styles.imageProps}>
                        {checkProp(props.data, 'description') ?
                            checkProp(props.data.description, 'uk_UA') ?
                                    <TextFull
                                        title='Опис документу'
                                        data={props.data.description.uk_UA}
                                    /> :
                                    <TextFull
                                        title='Опис документу'
                                        data={props.data.description}
                                    />
                            : null 
                        }
                        {checkProp(props.data, 'documentType') ? 
                            <TextFull
                                title={`Тип документу`}
                                data={props.data.documentType !== "***" ? docTypeGetter(props.scopeData.aucType, props.data.documentType, props.data.documentOf) : "***" }
                            /> : null
                        }
                        {checkProp(props.data, 'datePublished') ?
                            <TextHalf
                                title='Документ опубліковано'
                                data={viewDatePublished(props.data)}
                            /> : null
                        }
                        {checkProp(props.data, 'dateModified') ?
                            <TextHalf
                                title='Дата останнього оновлення'
                                data={viewDateModified(props.data)}
                            /> : null
                        }
                    </div>
                </div>
                { history ?
                        <DocumentHistory
                            data={historyArr}
                            documentId={props.data.documentId}
                            docType={docTypeGetter(props.scopeData.aucType, props.data.documentType, props.data.documentOf)}
                            docOf={props.data.documentOf}
                        /> : null
                }
                {imagePopup ? imagePopUp(props.data) : null}
            </div>
    } else {
        return (
            <>
                <div className={styles.infoSubBlock}>
                    {editPopup ?
                        <EditDocPopup
                            docData={props.data}
                            scopeData={props.scopeData}
                            actionClose={()=> setEditPopup(false)}
                        /> : null
                    }
                    <div className={styles.titleBlock}>
                        {checkProp(props.data.title, 'uk_UA') ? <div className={styles.infoBlockTitle}>{props.data.title.uk_UA}</div> : <div className={styles.infoBlockTitle}>{props.data.title}</div>}
                        <div className={styles.btnBlock}>
                            {
                                !history ? <DocHistoryButton
                                            data={props.data}
                                            state={historyData}
                                            scope={props.scopeData}
                                /> : null
                            }
                            {props?.data?.documentOf === 'contract'
                            && props?.userProfiles?.isAdmin === true
                            && props.data.documentType === 'securityPayment'
                                ? <DocEditButton
                                    auction={props.auctionFull}
                                    data={props.data}
                                    state={editData}
                                    scope={props.scopeData}
                                />
                                : null
                            }
                            {props?.data?.documentOf === 'contract' && props?.auction?.privateData?.isOwner === true && props.data.documentType !== 'securityPayment' 
                                ? <DocEditButton
                                    auction={props.auctionFull}
                                    data={props.data}
                                    state={editData}
                                    scope={props.scopeData}
                                />
                                : null
                            }
                            {props?.data?.documentOf !== 'contract'// && props?.auction?.privateData?.isOwner === true
                                ? <DocEditButton
                                    auction={props.auctionFull}
                                    data={props.data}
                                    state={editData}
                                    scope={props.scopeData}
                                />
                                : null
                            }
                            <DocLink
                                data={props.data}
                                privateData={checkProp(props.auction, 'privateData') ? props.auction.privateData : null}
                                scope={props.scopeData}
                            />
                            {props?.data?.documentOf === 'contract' && props?.userProfiles?.isAdmin === true && props.data.documentType === 'securityPayment' 
                                ? <DocDeleteButton
                                    // props={props}
                                    auction={props.auctionFull}
                                    data={props.data}
                                    state={deleteData}
                                    scope={props.scopeData}
                                />
                                : null
                            }
                            {props?.data?.documentOf === 'contract' && props?.auction?.privateData?.isOwner === true && props.data.documentType !== 'securityPayment' 
                                ? <DocDeleteButton
                                    // props={props}
                                    auction={props.auctionFull}
                                    data={props.data}
                                    state={deleteData}
                                    scope={props.scopeData}
                                />
                                : null
                            }
                            {props?.data?.documentOf !== 'contract'// && props?.auction?.privateData?.isOwner === true
                                ? <DocDeleteButton
                                    // props={props}
                                    auction={props.auctionFull}
                                    data={props.data}
                                    state={deleteData}
                                    scope={props.scopeData}
                                />
                                : null
                            }
                        </div>
                    </div>
                    {viewDocument(props.data)}
                    {checkProp(props.data, 'description') ?
                         checkProp(props.data.description, 'uk_UA') ?
                            props.data.description.uk_UA.length > 180 ?
                                <TextFull
                                    title='Опис документу'
                                    data={props.data.description.uk_UA}
                                /> :
                                <TextHalf
                                    title='Опис документу'
                                    data={props.data.description.uk_UA}
                                />
                            : props.data.description.length > 180 ?
                                <TextFull
                                    title='Опис документу'
                                    data={props.data.description}
                                /> :
                                <TextHalf
                                    title='Опис документу'
                                    data={props.data.description}
                                />
                         : null 
                    }
                    {checkProp(props.data, 'documentType')
                        ? viewType(props)
                        : null
                    }
                    {checkProp(props.data, 'datePublished') ?
                        <TextHalf
                            title='Документ опубліковано'
                            data={viewDatePublished(props.data)}
                        /> : null
                    }
                    {checkProp(props.data, 'dateModified') ?
                        <TextHalf
                            title='Дата останнього оновлення'
                            data={viewDateModified(props.data)}
                        /> : null
                    }
                    {dataECP !== null ? <EcpItem data={dataECP[0]} /> : null}
                    {  history ?
                            <DocumentHistory
                                data={historyArr}
                                documentId={props.data.documentId}
                                docType={docTypeGetter(props.scopeData.aucType, props.data.documentType, props.data.documentOf)}
                                docOf={props.data.documentOf}
                            /> : null
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auction: state.start.auctionFull,
        langFlag: state.start.langFlag,
        // histery: state.start.histery,
        popUpContractApprowe: state.start.popUpContractApprowe,
        arrPrivatLink: state.start.arrPrivatLink,
        userProfiles: state.start.userProfiles
    }
}

const mapDispatchToProps = {
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(DocItem)