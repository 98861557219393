import React from 'react';
import styles from './notFound.module.css';

import {connect} from 'react-redux';

const NotFound = ( props ) => {
    return (
        <div className={styles.NoPurchaseWrap}>
            <div className={styles.NoPurchase}>
                <h3>Аукціонів не знайдено</h3>
                <p>За вашим запитом нічого не знайдено</p>
                <p>Спробуйте змінити запит або змініть фільтри</p>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        search: state.start.search,
    }
}

export default connect(mapStateToProps, null)(NotFound)