import React from 'react';
import styles from './resedential.module.css';

import {
    RESIDENTIAL_REAL_ESTATE_CATEGORY_MENU_LANG,
} from '../../../../model/lang/iconCategoryMenu.js';
import {connect} from 'react-redux';

const Resedential = ( {langFlag} ) => {
    return (
        <div className={styles.aukpoisk_second_elem}>
            <a href='https://www.dto.com.ua/tenders/current/realestate'>
			<div className={styles.base}>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 512 512" xmlSpace="preserve">
<g>
	<g>
		<g>
			<path d="M135.91,462.046c-3.465-20.062-21.001-35.379-42.044-35.379c-16.802,0-31.36,9.762-38.315,23.91
				c-1.374-0.981-2.842-1.852-4.352-2.637V307.2c0-4.71-3.823-8.533-8.533-8.533c-4.71,0-8.533,3.823-8.533,8.533v136.533
				C15.309,443.733,0,459.042,0,477.867S15.309,512,34.133,512H128c14.114,0,25.6-11.486,25.6-25.6
				C153.6,475.042,146.167,465.391,135.91,462.046z M128,494.933H34.133c-9.412,0-17.067-7.654-17.067-17.067
				S24.721,460.8,34.133,460.8c7.962,0,15.036,4.625,17.604,11.52c1.442,3.866,5.436,6.144,9.515,5.41
				c4.062-0.734,7.014-4.267,7.014-8.397c0-14.114,11.486-25.6,25.6-25.6s25.6,11.486,25.6,25.6c0,4.71,3.823,8.533,8.533,8.533
				c4.702,0,8.533,3.831,8.533,8.533S132.702,494.933,128,494.933z"/>
			<path d="M503.467,68.267H341.333c-4.71,0-8.533,3.823-8.533,8.533s3.823,8.533,8.533,8.533h153.6v184.201l-232.9-232.9
				c-1.673-1.673-3.866-2.5-6.059-2.5s-4.386,0.828-6.059,2.5L17.067,269.525V85.333h93.867c4.71,0,8.533-3.823,8.533-8.533V42.667
				c0-4.71-3.823-8.533-8.533-8.533H102.4V17.067h51.2v17.067h-8.533c-4.71,0-8.533,3.823-8.533,8.533V76.8
				c0,4.71,3.823,8.533,8.533,8.533h25.6c4.71,0,8.533-3.823,8.533-8.533s-3.823-8.533-8.533-8.533H153.6V51.2h8.533
				c4.71,0,8.533-3.823,8.533-8.533V8.533c0-4.71-3.823-8.533-8.533-8.533H93.867c-4.71,0-8.533,3.823-8.533,8.533v34.133
				c0,4.71,3.823,8.533,8.533,8.533h8.533v17.067H8.533c-2.261,0-4.437,0.896-6.033,2.5C0.896,72.363,0,74.539,0,76.8l0.034,213.333
				c0,3.456,2.082,6.562,5.274,7.885c1.058,0.435,2.159,0.649,3.26,0.649c2.227,0,4.412-0.87,6.033-2.5L255.974,54.707
				l241.459,241.459c1.63,1.63,3.814,2.5,6.033,2.5c1.101,0,2.21-0.213,3.268-0.649c3.183-1.323,5.265-4.437,5.265-7.885V76.8
				C512,72.09,508.177,68.267,503.467,68.267z"/>
			<path d="M477.867,443.733V307.2c0-4.71-3.823-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v140.74
				c-1.51,0.785-2.978,1.655-4.352,2.637c-6.955-14.148-21.513-23.91-38.315-23.91c-21.043,0-38.579,15.317-42.044,35.379
				c-10.257,3.345-17.69,12.996-17.69,24.354c0,3.012,0.614,5.854,1.579,8.533H213.333c-4.71,0-8.533,3.823-8.533,8.533
				s3.823,8.533,8.533,8.533H384h34.133h59.733C496.691,512,512,496.691,512,477.867S496.691,443.733,477.867,443.733z
				 M477.867,494.933h-59.733H384c-4.702,0-8.533-3.831-8.533-8.533s3.831-8.533,8.533-8.533c4.71,0,8.533-3.823,8.533-8.533
				c0-14.114,11.486-25.6,25.6-25.6s25.6,11.486,25.6,25.6c0,4.13,2.953,7.663,7.014,8.397c4.053,0.742,8.073-1.545,9.515-5.41
				c2.568-6.895,9.643-11.52,17.604-11.52c9.412,0,17.067,7.654,17.067,17.067S487.279,494.933,477.867,494.933z"/>
			<path d="M290.133,170.667c0-18.825-15.309-34.133-34.133-34.133c-18.825,0-34.133,15.309-34.133,34.133S237.175,204.8,256,204.8
				C274.825,204.8,290.133,189.491,290.133,170.667z M238.933,170.667c0-9.412,7.654-17.067,17.067-17.067
				c9.412,0,17.067,7.654,17.067,17.067c0,9.412-7.654,17.067-17.067,17.067C246.588,187.733,238.933,180.079,238.933,170.667z"/>
			<path d="M418.133,290.133h-68.267c-4.71,0-8.533,3.823-8.533,8.533v93.867H332.8c-4.71,0-8.533,3.823-8.533,8.533
				s3.823,8.533,8.533,8.533h102.4c4.71,0,8.533-3.823,8.533-8.533s-3.823-8.533-8.533-8.533h-8.533v-93.867
				C426.667,293.956,422.844,290.133,418.133,290.133z M409.6,392.533h-51.2V358.4h51.2V392.533z M409.6,341.333h-51.2V307.2h51.2
				V341.333z"/>
			<path d="M341.333,469.333c0-4.71-3.823-8.533-8.533-8.533h-25.6V298.667c0-4.71-3.823-8.533-8.533-8.533h-85.333
				c-4.71,0-8.533,3.823-8.533,8.533V460.8h-25.6c-4.71,0-8.533,3.823-8.533,8.533v34.133c0,4.71,3.823,8.533,8.533,8.533
				s8.533-3.823,8.533-8.533v-25.6H332.8C337.51,477.867,341.333,474.044,341.333,469.333z M290.133,375.467H281.6
				c-4.71,0-8.533,3.823-8.533,8.533s3.823,8.533,8.533,8.533h8.533V460.8h-68.267V307.2h68.267V375.467z"/>
			<path d="M162.133,290.133H93.867c-4.71,0-8.533,3.823-8.533,8.533v93.867H76.8c-4.71,0-8.533,3.823-8.533,8.533
				S72.09,409.6,76.8,409.6h102.4c4.71,0,8.533-3.823,8.533-8.533s-3.823-8.533-8.533-8.533h-8.533v-93.867
				C170.667,293.956,166.844,290.133,162.133,290.133z M153.6,392.533h-51.2V358.4h51.2V392.533z M153.6,341.333h-51.2V307.2h51.2
				V341.333z"/>
		</g>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
</div>
                <div className={styles.aukpoisk_second_elem_text}>{RESIDENTIAL_REAL_ESTATE_CATEGORY_MENU_LANG[langFlag]}</div>
            </a>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        aucyionsPath: state.start.aucyionsPath,
        search: state.start.search,
        token: state.start.token,
        searchCount: state.start.searchCount,
    }
}

export default connect(mapStateToProps, null)(Resedential)