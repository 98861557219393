import React from 'react';

import {connect} from 'react-redux';

import {
    isTIE,
    isSUE, isSUD,
    isRCE, isRCD,
    isGFE, isGFD,
    isLLE, isLLD, isLLP,
    isLRE,
    isLSE, isLSP,
    isRMA,
    isBSE, isBSD,
    isALE,
    isCSE, isCSD,
    isAAE,
    isBRE, isBRD, isBRW,
    isRLE, isRLD,
    isCLE, isCLD,
    isSPE, isSPD,
    isNLE, isNLD,
    isLAE, isLAW, isLAP,
    isLPE,
    isAPE, isAPD
} from '../../../redux/aucTypes.js';

import ZU from './zu'
import Timber from './timber'
import Subsoil from './subsoil'
import Railway from './railway'
import Dgf from './dgf'
import LandRental from './landRental'
import LSP from './LSP.js'
import BasicSell from './basicSell'
import Alienation from './alienation'
import CommercialSell from './commercialSell'
import BRE from './bre'
import Rlecle from './rlecle'
import Rldcld from './rldcld'
import SPE from './spe'
import NLE from './nlenld'
import NLD from './nld'
import LAE from './lae'
import LAW from './law'
import LAP from './lap'
import APE from './ape'

const LotInformation = (props) => {
    if(props.auctionId === null) return null
    if(isTIE(props.auctionId.sellingMethod)) return <Timber />
    if(isSUE(props.auctionId.sellingMethod) || isSUD(props.auctionId.sellingMethod)) return <Subsoil />
    if(isRCE(props.auctionId.sellingMethod) || isRCD(props.auctionId.sellingMethod)) return <Railway />
    if(isGFE(props.auctionId.sellingMethod) || isGFD(props.auctionId.sellingMethod)) return <Dgf />
    if(isLLE(props.auctionId.sellingMethod) || isLLD(props.auctionId.sellingMethod) || isLLP(props.auctionId.sellingMethod)) return <ZU />
    if(isLRE(props.auctionId.sellingMethod)) return <LandRental />
    if(isLSE(props.auctionId.sellingMethod)) return <LandRental />
    if(isLSP(props.auctionId.sellingMethod)) return <LSP />
    if(isRMA(props.auctionId.sellingMethod)) return null
    if(isBSE(props.auctionId.sellingMethod) || isBSD(props.auctionId.sellingMethod)) return <BasicSell />
    if(isALE(props.auctionId.sellingMethod)) return <Alienation />
    if(isCSE(props.auctionId.sellingMethod) || isCSD(props.auctionId.sellingMethod)) return <CommercialSell />
    if(isAAE(props.auctionId.sellingMethod)) return null
    if(isBRE(props.auctionId.sellingMethod) || isBRD(props.auctionId.sellingMethod)) return <BRE />
    if(isBRW(props.auctionId.sellingMethod)) return <BRE />
    if(isRLE(props.auctionId.sellingMethod)) return <Rlecle />
    if(isRLD(props.auctionId.sellingMethod)) return <Rldcld />
    if(isCLE(props.auctionId.sellingMethod)) return <Rlecle />
    if(isCLD(props.auctionId.sellingMethod)) return <Rldcld />
    if(isSPE(props.auctionId.sellingMethod) || isSPD(props.auctionId.sellingMethod)) return <SPE />
    if(isNLE(props.auctionId.sellingMethod)) return <NLE />
    if(isNLD(props.auctionId.sellingMethod)) return <NLD />
    if(isLAE(props.auctionId.sellingMethod)) return <LAE />
    if(isLAW(props.auctionId.sellingMethod)) return <LAW />
    if(isLAP(props.auctionId.sellingMethod)) return <LAP />
    if(isLPE(props.auctionId.sellingMethod)) return <SPE />
    if(isAPE(props.auctionId.sellingMethod) || isAPD(props.auctionId.sellingMethod)) return <APE />
    return null
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(LotInformation)
