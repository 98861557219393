import React from 'react';
import styles from './informationAboutTheOrganizer.module.css';

import { connect } from 'react-redux';

import TextHalf from '../../elements/viewData/textHalf'
import TextFull from '../../elements/viewData/textFull'
import SlimmerBlock from '../../elements/slimmerBlock/slimmerBlock';

const BanksGroupe = (props) => {

    if(props.auctionId !== null){
        if(
            props.auctionId.sellingMethod === "legitimatePropertyLease-english" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-fast" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod" ||
            props.auctionId.sellingMethod === 'landRental-english' ||
            props.auctionId.sellingMethod === 'landRental-english-fast' ||
            props.auctionId.sellingMethod === 'landRental-english-fast-manual' ||
            props.auctionId.sellingMethod === 'landRental-english-fast-auction-manual-qualification' ||
            props.auctionId.sellingMethod === 'landRental-english-initial-auction' ||
            props.auctionId.sellingMethod === 'landRental-english-initial-auction-manual' ||
            props.auctionId.sellingMethod === 'landRental-english-initial-qualification' ||
            props.auctionId.sellingMethod === 'landRental-english-initial-qualification-prod' ||
            props.auctionId.sellingMethod === 'landSell-english' ||
            props.auctionId.sellingMethod === 'landSell-english-fast' ||
            props.auctionId.sellingMethod === 'landSell-english-fast-manual' ||
            props.auctionId.sellingMethod === 'landSell-english-fast-auction-manual-qualification' ||
            props.auctionId.sellingMethod === 'landSell-english-initial-auction' ||
            props.auctionId.sellingMethod === 'landSell-english-initial-auction-manual' ||
            props.auctionId.sellingMethod === 'landSell-english-initial-qualification' ||
            props.auctionId.sellingMethod === 'landSell-english-initial-qualification-prod' ||
            props.auctionId.sellingMethod === 'landSell-priorityEnglish' ||
            props.auctionId.sellingMethod === 'landSell-priorityEnglish-fast' ||
            props.auctionId.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
            props.auctionId.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
            props.auctionId.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
            props.auctionId.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
            props.auctionId.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
            props.auctionId.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod' ||
            props.auctionId.sellingMethod === 'basicSell-english' ||
            props.auctionId.sellingMethod === 'basicSell-english-fast' ||
            props.auctionId.sellingMethod === 'basicSell-english-fast-manual' ||
            props.auctionId.sellingMethod === 'basicSell-english-fast-auction-manual-qualification' ||
            props.auctionId.sellingMethod === 'basicSell-english-initial-auction' ||
            props.auctionId.sellingMethod === 'basicSell-english-initial-auction-manual' ||
            props.auctionId.sellingMethod === 'basicSell-english-initial-qualification' ||
            props.auctionId.sellingMethod === 'basicSell-english-initial-qualification-prod' ||
            props.auctionId.sellingMethod === 'basicSell-dutch' ||
            props.auctionId.sellingMethod === 'basicSell-dutch-fast' ||
            props.auctionId.sellingMethod === 'basicSell-dutch-fast-manual' ||
            props.auctionId.sellingMethod === 'basicSell-dutch-fast-auction-manual-qualification' ||
            props.auctionId.sellingMethod === 'basicSell-dutch-initial-auction' ||
            props.auctionId.sellingMethod === 'basicSell-dutch-initial-auction-manual' ||
            props.auctionId.sellingMethod === 'basicSell-dutch-initial-qualification' ||
            props.auctionId.sellingMethod === 'basicSell-dutch-initial-qualification-prod' ||
            props.auctionId.sellingMethod === 'alienation-english' ||
            props.auctionId.sellingMethod === 'alienation-english-fast' ||
            props.auctionId.sellingMethod === 'alienation-english-fast-manual' ||
            props.auctionId.sellingMethod === 'alienation-english-fast-auction-manual-qualification' ||
            props.auctionId.sellingMethod === 'alienation-english-initial-auction' ||
            props.auctionId.sellingMethod === 'alienation-english-initial-auction-manual' ||
            props.auctionId.sellingMethod === 'alienation-english-initial-qualification' ||
            props.auctionId.sellingMethod === 'alienation-english-initial-qualification-prod' ||
            props.auctionId.sellingMethod === 'commercialSell-english' ||
            props.auctionId.sellingMethod === 'commercialSell-english-fast' ||
            props.auctionId.sellingMethod === 'commercialSell-english-fast-manual' ||
            props.auctionId.sellingMethod === 'commercialSell-english-fast-auction-manual-qualification' ||
            props.auctionId.sellingMethod === 'commercialSell-english-initial-auction' ||
            props.auctionId.sellingMethod === 'commercialSell-english-initial-auction-manual' ||
            props.auctionId.sellingMethod === 'commercialSell-english-initial-qualification' ||
            props.auctionId.sellingMethod === 'commercialSell-english-initial-qualification-prod' ||
            props.auctionId.sellingMethod === 'commercialSell-dutch' ||
            props.auctionId.sellingMethod === 'commercialSell-dutch-fast' ||
            props.auctionId.sellingMethod === 'commercialSell-dutch-fast-manual' ||
            props.auctionId.sellingMethod === 'commercialSell-dutch-fast-auction-manual-qualification' ||
            props.auctionId.sellingMethod === 'commercialSell-dutch-initial-auction' ||
            props.auctionId.sellingMethod === 'commercialSell-dutch-initial-auction-manual' ||
            props.auctionId.sellingMethod === 'commercialSell-dutch-initial-qualification' ||
            props.auctionId.sellingMethod === 'commercialSell-dutch-initial-qualification-prod' ||
            props.auctionId.sellingMethod === 'bankRuptcy-english' ||
            props.auctionId.sellingMethod === 'bankRuptcy-english-fast' ||
            props.auctionId.sellingMethod === 'bankRuptcy-english-fast-manual' ||
            props.auctionId.sellingMethod === 'bankRuptcy-english-fast-auction-manual-qualification' ||
            props.auctionId.sellingMethod === 'bankRuptcy-english-initial-auction' ||
            props.auctionId.sellingMethod === 'bankRuptcy-english-initial-auction-manual' ||
            props.auctionId.sellingMethod === 'bankRuptcy-english-initial-qualification' ||
            props.auctionId.sellingMethod === 'bankRuptcy-english-initial-qualification-prod' ||
            props.auctionId.sellingMethod === 'bankRuptcy-dutch' ||
            props.auctionId.sellingMethod === 'bankRuptcy-dutch-fast' ||
            props.auctionId.sellingMethod === 'bankRuptcy-dutch-fast-manual' ||
            props.auctionId.sellingMethod === 'bankRuptcy-dutch-fast-auction-manual-qualification' ||
            props.auctionId.sellingMethod === 'bankRuptcy-dutch-initial-auction' ||
            props.auctionId.sellingMethod === 'bankRuptcy-dutch-initial-auction-manual' ||
            props.auctionId.sellingMethod === 'bankRuptcy-dutch-initial-qualification' ||
            props.auctionId.sellingMethod === 'bankRuptcy-dutch-initial-qualification-prod' ||
            props.auctionId.sellingMethod === "bankRuptcy-withoutAuction" ||
            props.auctionId.sellingMethod === 'regulationsPropertyLease-english' ||
            props.auctionId.sellingMethod === 'regulationsPropertyLease-english-ultra-fast' ||
            props.auctionId.sellingMethod === 'regulationsPropertyLease-english-fast' ||
            props.auctionId.sellingMethod === 'regulationsPropertyLease-english-fast-manual' ||
            props.auctionId.sellingMethod === 'regulationsPropertyLease-english-fast-auction-manual-qualification' ||
            props.auctionId.sellingMethod === 'regulationsPropertyLease-english-fast-auction-prod' ||
            props.auctionId.sellingMethod === 'regulationsPropertyLease-english-initial-auction' ||
            props.auctionId.sellingMethod === 'regulationsPropertyLease-english-initial-auction-manual' ||
            props.auctionId.sellingMethod === 'regulationsPropertyLease-english-initial-qualification' ||
            props.auctionId.sellingMethod === 'regulationsPropertyLease-english-initial-qualification-prod' ||
            props.auctionId.sellingMethod === 'regulationsPropertyLease-dutch' ||
            props.auctionId.sellingMethod === 'regulationsPropertyLease-dutch-ultra-fast' ||
            props.auctionId.sellingMethod === 'regulationsPropertyLease-dutch-fast' ||
            props.auctionId.sellingMethod === 'regulationsPropertyLease-dutch-fast-manual' ||
            props.auctionId.sellingMethod === 'regulationsPropertyLease-dutch-fast-auction-manual-qualification' ||
            props.auctionId.sellingMethod === 'regulationsPropertyLease-dutch-fast-auction-prod' ||
            props.auctionId.sellingMethod === 'regulationsPropertyLease-dutch-initial-auction' ||
            props.auctionId.sellingMethod === 'regulationsPropertyLease-dutch-initial-auction-manual' ||
            props.auctionId.sellingMethod === 'regulationsPropertyLease-dutch-initial-qualification' ||
            props.auctionId.sellingMethod === 'regulationsPropertyLease-dutch-initial-qualification-prod' ||
            props.auctionId.sellingMethod === 'commercialPropertyLease-english' ||
            props.auctionId.sellingMethod === 'commercialPropertyLease-english-ultra-fast' ||
            props.auctionId.sellingMethod === 'commercialPropertyLease-english-fast' ||
            props.auctionId.sellingMethod === 'commercialPropertyLease-english-fast-manual' ||
            props.auctionId.sellingMethod === 'commercialPropertyLease-english-fast-auction-manual-qualification' ||
            props.auctionId.sellingMethod === 'commercialPropertyLease-english-fast-auction-prod' ||
            props.auctionId.sellingMethod === 'commercialPropertyLease-english-initial-auction' ||
            props.auctionId.sellingMethod === 'commercialPropertyLease-english-initial-auction-manual' ||
            props.auctionId.sellingMethod === 'commercialPropertyLease-english-initial-qualification' ||
            props.auctionId.sellingMethod === 'commercialPropertyLease-english-initial-qualification-prod' ||
            props.auctionId.sellingMethod === 'commercialPropertyLease-dutch' ||
            props.auctionId.sellingMethod === 'commercialPropertyLease-dutch-ultra-fast' ||
            props.auctionId.sellingMethod === 'commercialPropertyLease-dutch-fast' ||
            props.auctionId.sellingMethod === 'commercialPropertyLease-dutch-fast-manual' ||
            props.auctionId.sellingMethod === 'commercialPropertyLease-dutch-fast-auction-manual-qualification' ||
            props.auctionId.sellingMethod === 'commercialPropertyLease-dutch-fast-auction-prod' ||
            props.auctionId.sellingMethod === 'commercialPropertyLease-dutch-initial-auction' ||
            props.auctionId.sellingMethod === 'commercialPropertyLease-dutch-initial-auction-manual' ||
            props.auctionId.sellingMethod === 'commercialPropertyLease-dutch-initial-qualification' ||
            props.auctionId.sellingMethod === 'commercialPropertyLease-dutch-initial-qualification-prod' ||
            props.auctionId.sellingMethod === 'smallPrivatization-english' ||
            props.auctionId.sellingMethod === 'smallPrivatization-english-fast' ||
            props.auctionId.sellingMethod === 'smallPrivatization-english-ultra-fast' ||
            props.auctionId.sellingMethod === 'smallPrivatization-english-fast-manual' ||
            props.auctionId.sellingMethod === 'smallPrivatization-english-fast-auction-manual-qualification' ||
            props.auctionId.sellingMethod === 'smallPrivatization-english-fast-auction-prod' ||
            props.auctionId.sellingMethod === 'smallPrivatization-english-initial-auction' ||
            props.auctionId.sellingMethod === 'smallPrivatization-english-initial-auction-manual' ||
            props.auctionId.sellingMethod === 'smallPrivatization-english-initial-qualification' ||
            props.auctionId.sellingMethod === 'smallPrivatization-english-initial-qualification-prod' ||
            props.auctionId.sellingMethod === "smallPrivatization-dutch" ||
            props.auctionId.sellingMethod === "smallPrivatization-dutch-fast" ||
            props.auctionId.sellingMethod === "smallPrivatization-dutch-ultra-fast" ||
            props.auctionId.sellingMethod === "smallPrivatization-dutch-fast-manual" ||
            props.auctionId.sellingMethod === "smallPrivatization-dutch-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "smallPrivatization-dutch-fast-auction-prod" ||
            props.auctionId.sellingMethod === "smallPrivatization-dutch-initial-auction" ||
            props.auctionId.sellingMethod === "smallPrivatization-dutch-initial-auction-manual" ||
            props.auctionId.sellingMethod === "smallPrivatization-dutch-initial-qualification" ||
            props.auctionId.sellingMethod === "smallPrivatization-dutch-initial-qualification-prod" ||

            props.auctionId.sellingMethod === "nonperformingLoans-english" ||
            props.auctionId.sellingMethod === "nonperformingLoans-english-fast" ||
            props.auctionId.sellingMethod === "nonperformingLoans-english-fast-manual" ||
            props.auctionId.sellingMethod === "nonperformingLoans-english-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "nonperformingLoans-english-fast-auction-prod" ||
            props.auctionId.sellingMethod === "nonperformingLoans-english-initial-qualification" ||
            props.auctionId.sellingMethod === "nonperformingLoans-english-initial-qualification-prod" ||
            props.auctionId.sellingMethod === "nonperformingLoans-english-initial-auction" ||
            props.auctionId.sellingMethod === "nonperformingLoans-english-initial-auction-manual" ||
            props.auctionId.sellingMethod === "nonperformingLoans-dutch" ||
            props.auctionId.sellingMethod === "nonperformingLoans-dutch-fast" ||
            props.auctionId.sellingMethod === "nonperformingLoans-dutch-fast-manual" ||
            props.auctionId.sellingMethod === "nonperformingLoans-dutch-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "nonperformingLoans-dutch-fast-auction-prod" ||
            props.auctionId.sellingMethod === "nonperformingLoans-dutch-initial-auction" ||
            props.auctionId.sellingMethod === "nonperformingLoans-dutch-initial-auction-manual" ||
            props.auctionId.sellingMethod === "nonperformingLoans-dutch-initial-qualification" ||
            props.auctionId.sellingMethod === "nonperformingLoans-dutch-initial-qualification-prod" ||

            props.auctionId.sellingMethod === "largePrivatization-english" ||
            props.auctionId.sellingMethod === "largePrivatization-english-fast" ||
            props.auctionId.sellingMethod === "largePrivatization-english-ultra-fast" ||
            props.auctionId.sellingMethod === "largePrivatization-english-fast-manual" ||
            props.auctionId.sellingMethod === "largePrivatization-english-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "largePrivatization-english-fast-auction-prod" ||
            props.auctionId.sellingMethod === "largePrivatization-english-initial-auction" ||
            props.auctionId.sellingMethod === "largePrivatization-english-initial-auction-manual" ||
            props.auctionId.sellingMethod === "largePrivatization-english-initial-qualification" ||
            props.auctionId.sellingMethod === "largePrivatization-english-initial-qualification-prod" ||

            props.auctionId.sellingMethod === "armaProperty-english" ||
            props.auctionId.sellingMethod === "armaProperty-english-ultra-fast" ||
            props.auctionId.sellingMethod === "armaProperty-english-fast" ||
            props.auctionId.sellingMethod === "armaProperty-english-fast-manual" ||
            props.auctionId.sellingMethod === "armaProperty-english-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "armaProperty-english-fast-auction-prod" ||
            props.auctionId.sellingMethod === "armaProperty-english-initial-auction" ||
            props.auctionId.sellingMethod === "armaProperty-english-initial-auction-manual" ||
            props.auctionId.sellingMethod === "armaProperty-english-initial-qualification" ||
            props.auctionId.sellingMethod === "armaProperty-english-initial-qualification-prod"
            || props.auctionId.sellingMethod === "armaProperty-dutch"
            || props.auctionId.sellingMethod === "armaProperty-dutch-ultra-fast"
            || props.auctionId.sellingMethod === "armaProperty-dutch-fast"
            || props.auctionId.sellingMethod === "armaProperty-dutch-fast-manual"
            || props.auctionId.sellingMethod === "armaProperty-dutch-fast-auction-manual-qualification"
            || props.auctionId.sellingMethod === "armaProperty-dutch-fast-auction-prod"
            || props.auctionId.sellingMethod === "armaProperty-dutch-initial-auction"
            || props.auctionId.sellingMethod === "armaProperty-dutch-initial-auction-manual"
            || props.auctionId.sellingMethod === "armaProperty-dutch-initial-qualification"
            || props.auctionId.sellingMethod === "armaProperty-dutch-initial-qualification-prod"
        ){
            if(props.auctionId.privateData.isOwner === true){
                return (
                    <SlimmerBlock
                        title={`Інформація про Банківські рахунки`}
                        content={viewBanksAccounts(props)}
                    />
                )
            }else{
                if(
                    props.auctionId.status === 'active_qualification' ||
                    props.auctionId.status === 'active_awarded' ||
                    props.auctionId.status === 'pending_payment' ||
                    props.auctionId.status === 'pending_admission' ||
                    props.auctionId.status === 'cancelled' ||
                    props.auctionId.status === 'unsuccessful' ||
                    props.auctionId.status === 'complete'
                ){
                    return (
                        <SlimmerBlock
                            title={`Інформація про Банківські рахунки`}
                            content={viewBanksAccounts(props)}
                        />
                    )
                }
            }
        }else if(props.auctionId.sellingMethod === "landArrested-english" ||
            props.auctionId.sellingMethod === "landArrested-english-ultra-fast" ||
            props.auctionId.sellingMethod === "landArrested-english-fast" ||
            props.auctionId.sellingMethod === "landArrested-english-fast-manual" ||
            props.auctionId.sellingMethod === "landArrested-english-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "landArrested-english-fast-auction-prod" ||
            props.auctionId.sellingMethod === "landArrested-english-initial-auction" ||
            props.auctionId.sellingMethod === "landArrested-english-initial-auction-manual" ||
            props.auctionId.sellingMethod === "landArrested-english-initial-qualification" ||
            props.auctionId.sellingMethod === "landArrested-english-initial-qualification-prod" ||

            props.auctionId.sellingMethod === "landArrested-withoutAuction" ||

            props.auctionId.sellingMethod === "landArrested-priorityEnglish" ||
            props.auctionId.sellingMethod === "landArrested-priorityEnglish-ultra-fast" ||
            props.auctionId.sellingMethod === "landArrested-priorityEnglish-fast" ||
            props.auctionId.sellingMethod === "landArrested-priorityEnglish-fast-manual" ||
            props.auctionId.sellingMethod === "landArrested-priorityEnglish-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "landArrested-priorityEnglish-fast-auction-prod" ||
            props.auctionId.sellingMethod === "landArrested-priorityEnglish-initial-auction" ||
            props.auctionId.sellingMethod === "landArrested-priorityEnglish-initial-auction-manual" ||
            props.auctionId.sellingMethod === "landArrested-priorityEnglish-initial-qualification" ||
            props.auctionId.sellingMethod === "landArrested-priorityEnglish-initial-qualification-prod"
        ){
            if(props.auctionId.privateData.isOwner === true){
                return (
                    <SlimmerBlock
                        title={`Банківські рахунки`}
                        content={viewBanksAccounts(props)}
                    />
                )
            }else{
                if(
                    props.auctionId.status === 'active_qualification' ||
                    props.auctionId.status === 'active_awarded' ||
                    props.auctionId.status === 'pending_payment' ||
                    props.auctionId.status === 'pending_admission' ||
                    props.auctionId.status === 'cancelled' ||
                    props.auctionId.status === 'unsuccessful' ||
                    props.auctionId.status === 'complete'
                ){
                    return (
                        <SlimmerBlock
                            title={`Банківські рахунки`}
                            content={viewBanksAccounts(props)}
                        />
                    )
                }
            }
        }
    }
    return null
}

function viewBanksAccounts(props){
    if(props.auctionId.hasOwnProperty('bankAccounts') === true){
        return props.auctionId.bankAccounts.map(
            (e) => {
                return (
                    <>
                        <TextHalf
                            title='Тип банківських рахунків'
                            data={strType(e.accountType)}
                        />
                        {viewBankPercent(e)}
                        {viewBankHolderType(e)}
                        {mapAccounts(e)}
                    </>
                )
            }
        )
    }
}

function accountsHolder(data){
    if(data.hasOwnProperty('accountHolder') === true && data.accountHolder !== null){
        if(data.accountHolder.hasOwnProperty('name') === true && data.accountHolder.name !== null){
            return (
                <>
                    <div className={styles.bankItemTitle}>Реквізити одержувача</div>
                    <TextHalf
                        title='Найменування одержувача'
                        data={strType(data.accountHolder.name.uk_UA)}
                    />
                    <TextHalf
                        title='Ідентифікатор'
                        data={strType(data.accountHolder.identifier.scheme)}
                    />
                    <TextHalf
                        title='Ідентифікатор одержувача'
                        data={strType(data.accountHolder.identifier.id)}
                    />
                    <TextHalf
                        title='Повна юридична назва організації'
                        data={strType(data.accountHolder.identifier.legalName.uk_UA)}
                    />
                </>
            )
        }
    }
    return null
}

function viewBankPercent(data){
    if(data !== null){
        if(data.hasOwnProperty('percent')){
            if(data.percent !== null){
                return (
                    <TextHalf
                        title='Відсоток'
                        data={data.percent}
                    />
                )
            }
        }
    }
    return null
}
function viewBankHolderType(data){
    if(data !== null){
        if(data.hasOwnProperty('holderType')){
            if(data.holderType !== null){
                return (
                    <TextHalf
                        title="Тип власника об'єкта"
                        data={holderType(data.holderType)}
                    />
                )
            }
        }
    }
    return null
}

function holderType(data){
    if(data === "balanceHolder"){
        return "Балансоутримувач"
    }else if(data === "landlord"){
        return "Орендодавець"
    }else if(data === "budget"){
        return "Бюджет"
    }
    return null
}

function mapAccounts(data){
    if(data.accounts !== null){
        if(data.accounts.length > 0){
            
            return data.accounts.map(
                (e) => {
                    let tmpDesc = null
                    if(e.hasOwnProperty('description') === true){
                        if(e.description !== null){
                            if(e.description.uk_UA !== null){
                                tmpDesc = <TextFull
                                    title='Опис'
                                    data={e.description.uk_UA}
                                />
                            }
                        }
                    }
                    return (
                        <div className={styles.infoBlock}>
                            <div className={styles.infoBlockTitle}>
                                <h3>Банківські рахунки</h3>
                            </div>
                            {(e.hasOwnProperty('bankName') === true && e.bankName !== null) ? <TextHalf
                                title='Назва банку'
                                data={e.bankName.uk_UA}
                            /> : null}
                            <TextHalf
                                title='Валюта'
                                data={strCurrency(e.currency)}
                            />
                            {tmpDesc}
                            {mapAccountIdentifications(e.accountIdentifications)}

                            {accountsHolder(e)}
                        </div>
                    )
                }
            )
        }
    }
    return null
}

function mapAccountIdentifications(data){
    if(data !== null){
        if(data.length > 0){
            return data.map(
                (e) => {
                    return (
                        <div className={styles.bankItem}>
                            <div className={styles.bankItemTitle}>
                               Банківські реквізити
                            </div>
                            <div className={styles.bankItemContent}>
                                {data.hasOwnProperty('description') === true ? <TextHalf
                                    title='Опис до банківських реквізитів'
                                    data={e.description.uk_UA}
                                /> : null}
                                <TextHalf
                                    title={`Ідентифікатор: ${e.scheme}`}
                                    data={e.id}
                                />
                            </div>
                        </div>
                    )
                }
            )
        }
    }
    return null
}

function strType(data){
    switch(data){
        case 'registrationFee':
            return 'Реєстраційний внесок'
        case 'guarantee':
            return 'Гарантійний внесок'
        case 'securityDeposit':
            return 'Забезпечувальний депозит'
        case 'improvementCompensation':
            return "Компенсація невід’ємних поліпшень"
        case 'other':
            return 'Інші платежі'
        case 'advancePayment':
            return 'Авансовий внесок'
        case 'lease':
            return 'Орендні платежі'
        case 'payment':
            return 'Плата за лот'
        case 'preparationPayment':
            return 'Плата за підготовку лота'
        case 'organizer':
            return 'Організатор'
        default:
            return data
    }
}

function strCurrency(data){
    switch(data){
        case 'UAH':
            return 'гривні'
        case 'USD':
            return 'долари'
        case 'EUR':
            return 'євро'
        default:
            return data
    }
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(BanksGroupe)
