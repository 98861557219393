import React, { useEffect, useState } from 'react';
import style from './createAuction.module.css';

import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {
    getMyAuction,
    setEditAuctionFromId,
} from '../../../../redux/actions.js';
import {setDataAddress} from '../../../../redux/createAddress';

import {setPopUpAuctionCreate, changeAuctionsSwitchWindow, setPopUpAuctionMyNull, auctionsGetMy} from '../../../../redux/actions/auctions/auctions.js';

import {
    changeDiscountPercent,
    changeDiscountPerviousAuctionCurrency,
    changeDiscountPerviousAuctionAmount,
    changeDiscountPerviousAuctionId,
    discountAuc,
} from '../../../../redux/actions/discount/discount.js';

import {auctionsGetAwards, auctionsGetAwardsId} from '../../../../redux/actions/awards/awards.js';
import {getAuctionsBidsId} from '../../../../redux/actions/auctionBid/auctionBid.js';
import {contractsAuctionIdGet} from '../../../../redux/actions/contracts/contracts.js';
import {
    setPopBankAccountId,
    setPopBankAccountIdArr,
    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,
} from '../../../../redux/actions/bankAccount/bankAccount.js';
import {setUniversalError} from '../../../../redux/actions.js';
import {
    changeLoader,
} from '../../../../redux/actions.js';

import ReturnButton from '../../../createAuction/returnButton'

import { isTIE, isLLD, isLLE, isLLP, isLSE, isLSP,
    isBSE, isBSD, isALE, isCSE, isCSD, isBRE, isBRD, isBRW, isRLE, isRLD, isCLE, isCLD } from '../../../../redux/aucTypes';
import { Redirect } from 'react-router-dom/cjs/react-router-dom';

const AuctionCreate = (props) => {
    const [lot, setLot] = useState(null)
    const [flag, setFlag] = useState(false)

    useEffect(() => {
        setFlag(false)
        props.discountAuc(false)
        props.changeDiscountPerviousAuctionId('')
        props.changeDiscountPercent('')
        props.changeDiscountPerviousAuctionAmount('')
        props.setDataAddress(false, 'CREATE_AUCTION_RAILWAY_DUTCH_STEP_VALUE_ADDED_TAX_INCLUDED')
    }, [])

    // useEffect(() => {
    //     setFlag(false)
    // }, [flag])

    if(flag === true) return <Redirect to={`/create_auctions/edit/${props.popUpAuctionCreate.id}`} />
    return (
        <div className={style.popup_wrap}>
            <div className={style.popup_message}>
                <div>{textForAuctionPopUp(props)}</div>
                {buttonForAuctionPopUp(props, setFlag)}
            </div>
        </div>
    )
}

async function getAucions(props, id){
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${id}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціонів', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }else{
            return json
            props.setDataAddress(json, 'EDIT_AUCTION_FROM_ID')
        }
    }
}

function buttonForAuctionPopUp(props, setFlag){
    let tmp = null;
    tmp = <>
        {/*<div className={style.popup_message_button}*/}
        {/*<Link
            to="/create_auctions "
            className={style.buttons}
        
            onClick={
                () => {
                    props.changeLoader(true)
                    setTimeout(() => 
                    {
                        auctionCloseSync(props)
                    }, 1000)//
                }
            }
        >
            Перейти до списку аукціонів
        </Link>*/}
        <ReturnButton />
        {/*</div>*/}
        {/*<div className={style.popup_message_button}*/}
        <Link
            to={setRoute(props)}
            className={style.buttons}
            onClick={
                () => {
                    props.changeLoader(true)
                    setTimeout(() => 
                    {
                        ///auctionEditSync(props)
                        document.location.reload();
                    }, 1000)
                }
            }
        >
            Продовжити редагування аукціону
        </Link>
        {/* <div
            className={style.buttons}
            onClick={
                () => {
                    setFlag(true)
                    setTimeout(() => 
                    {
                        props.setPopUpAuctionCreate(null)
                        setFlag(false)
                    }, 1000)
                    
                }
            }
        >
            Продовжити редагування аукціону
        </div> */}
    </>
    //if(flag === true) return <Redirect to={`/create_auctions/edit/${props.popUpAuctionCreate.id}`} />
    return tmp
}

function setRoute(props){
    if(
        isBSE(props.popUpAuctionCreate.sellingMethod) || isBSD(props.popUpAuctionCreate.sellingMethod) ||
        isALE(props.popUpAuctionCreate.sellingMethod) ||
        isCSE(props.popUpAuctionCreate.sellingMethod) || isCSD(props.popUpAuctionCreate.sellingMethod) ||
        isBRE(props.popUpAuctionCreate.sellingMethod) || isBRD(props.popUpAuctionCreate.sellingMethod) || isBRW(props.popUpAuctionCreate.sellingMethod) ||
        isRLE(props.popUpAuctionCreate.sellingMethod) || isRLD(props.popUpAuctionCreate.sellingMethod) ||
        isCLE(props.popUpAuctionCreate.sellingMethod) || isCLD(props.popUpAuctionCreate.sellingMethod)

    ){
        return `/create_auctions/editNew/${props.popUpAuctionCreate.id}`
    }
    return `/create_auctions/edit/${props.popUpAuctionCreate.id}`
}


async function auctionEditSync(props){
    //props.setDataAddress(null, 'CLEAR_DISCOUNT')
    props.setDataAddress(null, 'EDIT_AUCTION_FROM_ID')
    props.setDataAddress(props.popUpAuctionCreate, 'EDIT_AUCTION_FROM_ID')

    if(props.popUpAuctionCreate.discount === null){
        props.discountAuc(false)
        props.changeDiscountPerviousAuctionId(null)
        props.changeDiscountPercent(null)
        props.changeDiscountPerviousAuctionAmount(null)
        props.setDataAddress('null', 'CREATE_AUCTION_RAILWAY_DUTCH_STEP_VALUE_ADDED_TAX_INCLUDED')
    }else{
        props.discountAuc(null)
        props.discountAuc(true)
        props.changeDiscountPerviousAuctionId(null)
        props.changeDiscountPerviousAuctionId(props.popUpAuctionCreate.previousAuctionId)
        props.changeDiscountPercent(null)
        props.changeDiscountPercent(props.popUpAuctionCreate.discount.discountPercent)
        props.changeDiscountPerviousAuctionAmount(null)
        props.changeDiscountPerviousAuctionAmount(props.popUpAuctionCreate.discount.previousAuctionValue.amount)
        props.setDataAddress(null, 'CREATE_AUCTION_RAILWAY_DUTCH_STEP_VALUE_ADDED_TAX_INCLUDED')
        props.setDataAddress(props.popUpAuctionCreate.discount.previousAuctionValue.valueAddedTaxIncluded, 'CREATE_AUCTION_RAILWAY_DUTCH_STEP_VALUE_ADDED_TAX_INCLUDED')
    }

    props.setPopUpAuctionCreate(null)
    props.changeLoader(false)
}

function textForAuctionPopUp(props){
    let tmp = null;
    if(props.popUpAuctionCreate === 1){
        tmp = 'Помилка при створенні аукціона';
    }else if(props.popUpAuctionCreate === 2){
        tmp = 'Зміни в аукціоні збережено';
    }else{
        tmp = 'Аукціон створено';
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        popUpAwardReject: state.start.popUpAwardReject,
        popUpAwardError: state.start.popUpAwardError,
        popUpAuctionCreate: state.start.popUpAuctionCreate,
    }
}

const mapDispatchToProps = {
    setPopUpAuctionCreate,
    changeAuctionsSwitchWindow,
    setPopUpAuctionMyNull,
    auctionsGetMy,

    getMyAuction,
    changeDiscountPercent,
    changeDiscountPerviousAuctionCurrency,
    changeDiscountPerviousAuctionAmount,
    changeDiscountPerviousAuctionId,
    discountAuc,

    auctionsGetAwards,
    auctionsGetAwardsId,

    getAuctionsBidsId,
    contractsAuctionIdGet,
    setPopBankAccountId,
    setPopBankAccountIdArr,
    
    setUniversalError,
    setEditAuctionFromId,

    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,

    changeLoader,
    setDataAddress
}

export default connect(mapStateToProps, mapDispatchToProps)(AuctionCreate)