import React from 'react';
import style from './popupAward.module.css';

import {connect} from 'react-redux';

import {
    auctionsGetAwards,
    auctionsGetAwardsId,
    changeAwardRejectPopUpStatus,
    changeAwardErrorPopUpStatus,
    changeAwardRejectTitle,
    changeAwardRejectDescription,
    changeAwardRejectDocumentType,
    changeAwardDocumentStatus,
} from '../../../redux/actions/awards/awards.js';

import {setUniversalError, changeLoader} from '../../../redux/actions.js';

import {
    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType
} from '../../../redux/actions/documents/documents.js';
import {
    togetherApiPopUpOnOff,
    togetherApiDocTitle,
    togetherApiDocDescription,
    togetherApiDocType,
    togetherApiDocLang,
    togetherApiDocFileName,
    togetherApiECP,
} from '../../../redux/togetherApi.js';
import {ecpApiPass, ecpApiList, changeCA} from '../../../redux/ecpApi.js';

import {setDataAddress} from '../../../redux/createAddress';
import {getUpdateDataAuction} from '../../product/updateData'


const ChansellWaitingAward = ( props ) => {
    return (
        <div
            className={style.btnAccept}
            onClick={
                () => {
                    canceledWaitingAward(props)
                }
            }
        >
            Відмовитись від очікування
        </div>
    )
}

async function canceledWaitingAward(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/set_status/cancelled`
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            //let tmp = JSON.stringify({title: 'Помилка при відмові очікування аварду', message: JSON.stringify(json)})
            let tmp = null
            if(json.message[0] !== "Forbidden state - active_waiting. Cannot change award status in current state"){
                tmp = JSON.stringify({title: 'Помилка при відхиленні аварду', message: JSON.stringify(json)})
            }else{
                tmp = JSON.stringify({title: 'Помилка при відхиленні аварду', message: 'Період очікування не завершено, відмовитись від очікування неможливо.'})
            }
            props.setUniversalError(tmp)
        }else{
            /*let header = null;
            if(props.token !== null){
                header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }else{
                header = {
                    'Content-Type': 'application/json'
                }
            }
            let data5 = {
                domen: process.env.REACT_APP_END_POINT_BD,
                version: 'v1',
                local: props.langFlag
            }
            if(props.popUpAwardReject.sellingMethod !== "dgf-english" ||
                props.popUpAwardReject.sellingMethod !== "dgf-english-fast" ||
                props.popUpAwardReject.sellingMethod !== "dgf-english-fast-manual" ||
                props.popUpAwardReject.sellingMethod !== "dgf-english-fast-auction-manual-qualification" ||
                props.popUpAwardReject.sellingMethod !== "dgf-english-initial-auction" ||
                props.popUpAwardReject.sellingMethod !== "dgf-english-initial-auction-manual" ||
                props.popUpAwardReject.sellingMethod !== "dgf-english-initial-qualification" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast-manual" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast-auction-manual-qualification" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-auction" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-qualification" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-auction-manual"
            ){
                const urlAsync = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/sync`;
                const responseAsync = await fetch(urlAsync, {
                    method: "GET",
                    headers: {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
                }).catch(console.error)
                if(responseAsync === undefined || responseAsync === 'undefined'){
                    props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
                }else{
                    props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
                }
            }else{
                props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
            }*/
            await setTimeout(() => combineUpadteAward(props), 2000)
            await props.changeAwardRejectPopUpStatus(null)
            await props.changeAwardRejectTitle('')
            await props.changeAwardRejectDocumentType('null')
            await props.changeAwardRejectDescription('')
            await props.togetherApiDocFileName(null)
            await props.togetherApiECP(false)
            await props.ecpApiPass('')
            await props.changeCA('null')
            await props.changeDocumentFileName(null)
        }
    }
}

async function combineUpadteAward(props){
    await getUpdateDataAuction(props, props.auctions.id)
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        //auctions: state.start.auctionsGet,
        auctions: state.start.auctionFull,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy,
        auctionAwards: state.start.auctionAwards,
        popUpAwardReject: state.start.popUpAwardReject,
        awardReject: state.start.awardReject,
        auction: state.start.auctionsGetId,

        togetherApiPage: state.start.togetherApiPage,
        ecpData: state.start.ecpData,

        createDocuments: state.start.createDocuments,
    }
}

const mapDispatchToProps = {
    auctionsGetAwards,
    auctionsGetAwardsId,
    changeAwardRejectPopUpStatus,
    changeAwardErrorPopUpStatus,
    changeAwardRejectTitle,
    changeAwardRejectDescription,
    changeAwardRejectDocumentType,
    changeAwardDocumentStatus,

    setUniversalError,

    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType,

    togetherApiPopUpOnOff,
    togetherApiDocTitle,
    togetherApiDocDescription,
    togetherApiDocType,
    togetherApiDocLang,
    togetherApiDocFileName,
    togetherApiECP,

    ecpApiPass,
    ecpApiList,
    changeCA,

    changeLoader,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChansellWaitingAward)