import React from 'react';
import styles from '../docItem.module.css';
import { getBidHistory } from './logic/bidLogic';
import { getAucHistory } from './logic/aucLogic';
import { getContractHistory } from './logic/contractLogic';


const DocHistoryButton = (props) => {

    // function getHistory(props){
    //     switch (props.data.documentOf) {
    //         case 'bid':
    //             getBidHistory(props)
    //             break;
    //         case 'auction':
    //             getAucHistory(props)
    //             break;
    //         case 'award':
    //             getAucHistory(props)
    //             break;
    //         case 'contract':
    //             getContractHistory(props)
    //             break;
    //     }
    // }
    async function getHistory(props) {
        let url = `${props.scope.historyUrl}/${props.data.id}`
        if (props.scope.typeOf === 'bid') url = `${props.scope.historyUrl}/${props.data.documentId}`
        let headers = null
        if (props.scope.typeOf === 'bid'){
            if(props.scope.token !== null){
                headers = {
                    'Authorization': props.scope.token,
                    'Content-Type': 'application/json'    
                }
            }else{
                headers = {
                    'Content-Type': 'application/json'    
                }    
            }
        }else{
            headers = {
                'Content-Type': 'application/json'    
            }
        }
        
        const response = await fetch(url, {
        method: "GET",
        headers: headers
        }).catch(console.error)
        if(response === undefined || response === 'undefined'){
            alert('Нажаль виникла помилка і сервер не відповідає')
        }else{
            const json = await response.json()
            if(json !== null){
                await props.state.setArr(json)
            }
        }
    }

    if (props.data.dateModified !== props.data.datePublished && props.state.arr === null) { 
        return <div className={styles.historyBtn} title='Перегляд історії змін документу'
                onClick={() => {
                        if (props.state.arr === null) {
                            props.state.setHistory(!props.state.state)
                            getHistory(props)
                        }
                        if (props.state.arr !== null) {
                            props.state.setHistory(true)
                            // props.state.setArr(false)
                        }
                    }
                }
            >
                <svg className={styles.svgIcon} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className={styles.svgDownld} d="M11 4C11.5523 4 12 4.44772 12 5V10.5109L16.1139 13.7106C16.5499 14.0497 16.6284 14.678 16.2894 15.1139C15.9503 15.5499 15.322 15.6284 14.8861 15.2894L10.3861 11.7894C10.1425 11.5999 10 11.3086 10 11V5C10 4.44772 10.4477 4 11 4Z" fill="#92929D" />
                    <path className={styles.svgDownld} fillRule="evenodd" clipRule="evenodd" d="M11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0ZM11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2Z" fill="#92929D" />
                </svg>
            </div>
        }
        return null
}

export default DocHistoryButton