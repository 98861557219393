import React from 'react';
import styles from './myBids.module.css';

import { Link } from 'react-router-dom';

import {connect} from 'react-redux';
import { PROCEDURE_NAME } from '../../../redux/procedure_v3.35.2.uk';

////////////////////////
import {
    auctionsGetMy,
    changeAuctionsSwitchWindow,
    auctionsGetClassificationsByType,
    auctionsGetClassificationsByTimberSortment,
    auctionsGetClassificationsByTimberSortmentFromCashe,
    auctionsGetClassificationsByTimberSpecies,
    auctionsGetClassificationsByTimberSpeciesFromCashe,
    auctionsGetClassificationsByTimberClass,
    auctionsGetClassificationsByTimberClassFromCashe,
    auctionsGetClassificationsByTimberDiameter,
    auctionsGetClassificationsByTimberDiameterFromCashe,
    auctionsGetClassificationsByTimberLength,
    auctionsGetClassificationsByTimberLengthFromCashe,
    auctionsGetClassificationsByTimberStorage,
    auctionsGetClassificationsByTimberStorageFromCashe,
    auctionsGetClassificationsByTimberProductionYear,
    auctionsGetClassificationsByTimberProductionYearFromCashe,
    auctionsGetClassificationsByTimberProductionQuarter,
    auctionsGetClassificationsByTimberProductionQuarterFromCashe,
    auctionsGetClassificationsByWagonSpecies,
    auctionsGetClassificationsByWagonSpeciesFromCashe,
    auctionsGetClassificationsByTypeNadraEnglish,
    auctionsGetClassificationsByTypeNadraEnglishFromCashe,
    auctionsGetClassificationsByByShemaSubsoilUsageType,
    auctionsGetClassificationsByTypeRailway,
    auctionsGetClassificationsByCarryType,
    auctionsGetClassificationsByTypeRailwayCargoAdd,
    auctionsGetWagonRestrict,
    auctionsGetWagonRestrictType,
} from '../../../redux/actions/auctions/auctions.js';

import { dateTransform } from '../../../redux/modifiDate';

import {
    setDataAddress,
} from '../../../redux/createAddress';


import {
    changeCreateItemClassificationAddId,
    getMyAuction
} from '../../../redux/actions.js';

import {bankAccountGet, bankAccountShemeGet, setBankAccountSave, setBankAccountAdd, setBankAccountDelete} from '../../../redux/actions/bankAccount/bankAccount.js';

import {auctionsBidsGetMy} from  '../../../redux/actions/auctionBid/auctionBid.js';

import {unitsGetByType, unitsGetByTypeEnergy, unitsGetByTypeRailway, unitsGetByTypeSubsoil} from '../../../redux/actions/itemUnits/itemUnits.js';

import {addressesGetMy} from '../../../redux/actions/address/address.js';

import {
    workMyPageCurrentAuction,
    workMyPageNextAuction
} from '../../../redux/togetherApi.js';

////////////////////////

import {changeCreateAuctionTab} from '../../../redux/actions.js';
import {setPopUpSendAuction, setPopUpErrorAuction} from '../../../redux/actions/auctions/auctions.js';
import {classificationsGetByScheme} from '../../../redux/actions/classifications/classifications.js';
import {
    changeCreateAuctionTitle,
    changeCreateAuctionDescription,
    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,
    changeCreateAuctionId,
    changePageNumber,
    setEditAuctionFromId,
    changeLoader,
} from '../../../redux/actions.js';

import {
    changeDiscountPercent,
    changeDiscountPerviousAuctionCurrency,
    changeDiscountPerviousAuctionId,
    discountAuc,
    changeDiscountId,
} from '../../../redux/actions/discount/discount.js';

import {setUniversalError} from '../../../redux/actions.js';

import {auctionsGetAwards, auctionsGetAwardsId} from '../../../redux/actions/awards/awards.js';
import {getAuctionsBidsId} from '../../../redux/actions/auctionBid/auctionBid.js';
import {contractsAuctionIdGet} from '../../../redux/actions/contracts/contracts.js';
import {setPopBankAccountId, setPopBankAccountIdArr} from '../../../redux/actions/bankAccount/bankAccount.js';
import {oldArrDocumentGet} from '../../../redux/actions/documents/documents.js';

import {Redirect} from 'react-router-dom';

//import {AUCTIONS_GET_SEND} from '../../redux/types.js';
import {getAPI} from '../../../redux/actions/crud.js';

import CreateAuctionLoader from '../createAuctionLoader.js';
import {setLogo} from '../../../redux/actions/support.js';

import {
    setPopUpAuctionMyNull,
} from '../../../redux/actions/auctions/auctions.js';

import Paginator from './paginator/paging'
import MyBidsHeader from './myBidsHeader/myBidsHeader'
import { getShorter } from '../../../redux/check';

const MyBids = ( props ) => {
    if(props.myBids !== null){
        if(props.myBids.length > 0){
            return (
                <>
                    <MyBidsHeader />
                    <div className={styles.wrap_listAuctions}>
                        <div className={styles.header_line}>
                            <div>ID DTO</div>
                            <div>Назва аукціону</div>
                            <div className={styles.vFlex}>
                                <div>Статус аукціону</div>
                                <div>(пропозиції)</div>
                            </div>
                            <div>Поч. ціна</div>
                            <div>Моя пропозиція</div>
                            <div>Зверніть увагу</div>
                            <div>Дії</div>
                        </div>
                        {createListBids(props.myBids)}
                        <Paginator />
                    </div>
                </>
                    )
            }
    }
    return null 
}

function linkButton(i) {
    return <a href={window.location.origin + '/auctions/' + i.auction.auctionId}>
            <div className={styles.tdBtn}
                    title='Перейти до аукціону'>
                    <svg className={styles.svgLink} width="18" height="20" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className={styles.svgLinkPath} d="M17 6.99998L1 6.99999C0.447716 6.99999 -3.10193e-09 7.4477 2.10392e-08 7.99999C4.51804e-08 8.55227 0.447716 8.99999 1 8.99999L17 8.99999C17.5523 8.99999 18 8.55227 18 7.99998C18 7.4477 17.5523 6.99998 17 6.99998Z" fill="#92929D"/>
                        <path className={styles.svgLinkPath} d="M2.51859 8.00888L9.65903 1.7521C10.0744 1.38812 10.1161 0.75633 9.75211 0.340951C9.38814 -0.074429 8.75634 -0.116101 8.34096 0.247874L0.340964 7.25784C-0.114082 7.65658 -0.113582 8.36491 0.342027 8.763L8.34203 15.753C8.75792 16.1164 9.38965 16.0739 9.75304 15.658C10.1164 15.2421 10.0739 14.6103 9.65797 14.2469L2.51859 8.00888Z" fill="#92929D"/>
                    </svg>
            </div>
        </a>
            
}

function createListBids(data) {
    if(data !== null){
        return data
        .filter(
            (i) => {
                return i.auction !== null
            }
        )
        .map(
            (i) => {
                return (
                    <div key={i.id} className={styles.listAuctions} >
                        <div className={styles.listAuctions_id}>{i.id}</div>
                        <div className={styles.listAuctions_title}>
                            <div>
                                <a className={styles.link_title} href={window.location.origin + '/auctions/' + i.auction.auctionId}>
                                    {getShorter(i.auction.title.uk_UA, 68)}
                                </a>
                            </div>
                            <div style={{fontSize: '14px', color: '#696974', marginTop: '5px'}}>{i.auction.auctionId}</div>
                        </div>
                        {setTextStatus(i.auction.status, i.status)}
                        <div className={styles.listAuctions_price}>{getValue(i.auction.value)}</div>
                        <div className={styles.listAuctions_price}>{getValue(i.value)}</div>
                        {createHelpBlock(i)}
                        {linkButton(i)}
                    </div>
                )
            }
        )
    }
}

function createHelpBlock(data) {
    if (helpText(data) !== null) {
        if (helpText(data) === 'Аукціон розпочався.Перейдіть в аукціон для участі.') {
            return (
                    <div className={styles.listAuctions_help}>
                        <div className={styles.attentionsHelp}>
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.6666 8.66666V15.3333C12.6666 16.0697 13.2636 16.6667 14 16.6667C14.7363 16.6667 15.3333 16.0697 15.3333 15.3333V8.66666C15.3333 7.93028 14.7363 7.33333 14 7.33333C13.2636 7.33333 12.6666 7.93028 12.6666 8.66666Z" fill="#FFD200"/>
                                <path d="M15.3333 19.3333C15.3333 20.0697 14.7363 20.6667 14 20.6667C13.2636 20.6667 12.6666 20.0697 12.6666 19.3333C12.6666 18.5969 13.2636 18 14 18C14.7363 18 15.3333 18.5969 15.3333 19.3333Z" fill="#FFD200"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M27.3333 14C27.3333 21.3638 21.3638 27.3333 14 27.3333C6.63616 27.3333 0.666626 21.3638 0.666626 14C0.666626 6.6362 6.63616 0.666664 14 0.666664C21.3638 0.666664 27.3333 6.6362 27.3333 14ZM3.33329 14C3.33329 19.891 8.10892 24.6667 14 24.6667C19.891 24.6667 24.6666 19.891 24.6666 14C24.6666 8.10896 19.891 3.33333 14 3.33333C8.10892 3.33333 3.33329 8.10896 3.33329 14Z" fill="#FFD200"/>
                            </svg>
                        </div>
                        <div>
                            {helpText(data)}
                        </div>
                    </div>)
        }
        return (
            <div className={styles.listAuctions_help}>
                <div className={styles.attentionsHelp}>
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.6666 8.66666V15.3333C12.6666 16.0697 13.2636 16.6667 14 16.6667C14.7363 16.6667 15.3333 16.0697 15.3333 15.3333V8.66666C15.3333 7.93028 14.7363 7.33333 14 7.33333C13.2636 7.33333 12.6666 7.93028 12.6666 8.66666Z" fill="#139DD9"/>
                        <path d="M15.3333 19.3333C15.3333 20.0697 14.7363 20.6667 14 20.6667C13.2636 20.6667 12.6666 20.0697 12.6666 19.3333C12.6666 18.5969 13.2636 18 14 18C14.7363 18 15.3333 18.5969 15.3333 19.3333Z" fill="#139DD9"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M27.3333 14C27.3333 21.3638 21.3638 27.3333 14 27.3333C6.63616 27.3333 0.666626 21.3638 0.666626 14C0.666626 6.6362 6.63616 0.666664 14 0.666664C21.3638 0.666664 27.3333 6.6362 27.3333 14ZM3.33329 14C3.33329 19.891 8.10892 24.6667 14 24.6667C19.891 24.6667 24.6666 19.891 24.6666 14C24.6666 8.10896 19.891 3.33333 14 3.33333C8.10892 3.33333 3.33329 8.10896 3.33329 14Z" fill="#139DD9"/>
                    </svg>
                </div>
                <div>
                    {helpText(data)}
                </div>
            </div>
        )
    }
    return <div className={styles.listAuctions_help}></div>
}

function helpText(data) {
    // return 'test'
    if (data.status === 'draft') {
        if (data.auction.status === 'unsuccessful') {
            return null
        }
        return 'Чернетка. Пропозиція не опублікована в ЦБД'
    }
    if (data.status === 'active') {
        if (data.auction.status === 'active_auction') {
            return `Аукціон розпочався. Перейдіть в аукціон для участі.` // зависимость выше (-43)
        }
        if (data.auction.status === 'complete') {
            if ( data.hasOwnProperty('award') && data.award !== null) {
                if (data.award.status === 'active') {
                    return `Вітаємо з перемогою!`
                }
                if (data.award.status === 'unsuccessful') {
                    return `Вашу пропозицію дискваліфіковано!`
                }
                if (data.award.status === 'cancelled') {
                    return `На жаль, Ви не є переможцем в цьому аукціоні`
                }
            }
            return `На жаль, Ви не є переможцем в цьому аукціоні`
        }
        if (data.auction.status === 'active_qualification') {
            if ( data.hasOwnProperty('award') && data.award !== null) {
                if (data.award.status === 'pending') {
                    let endDate = data.award.signingPeriod !== null ?
                        `Кінцевий строк публікації протоколу: ${dateTransform(data.award.signingPeriod.endDate, 'time')}` : ''
                    return `Очікується публікація протоколу ${endDate}`
                }
                if (data.award.status === 'pending_waiting') {
                    return `Очікування рішення`
                }
                if (data.award.status === 'active') {
                    let endDate = data.award.signingPeriod !== null ?
                        `Кінцевий строк публікації протоколу: ${dateTransform(data.award.signingPeriod.endDate, 'time')}` : ''
                    return `Вітаємо з перемогою! ${endDate}`
                }
                if (data.award.status === 'unsuccessful') {
                    return `Вашу пропозицію дискваліфіковано!`
                }
                if (data.award.status === 'cancelled') {
                    return `На жаль, Ви не є переможцем в цьому аукціоні`
                }
            }
            return `Очікування рішення`
        }
        if (data.auction.status === 'active_awarded') {
            if ( data.hasOwnProperty('award') && data.award !== null) {
                if (data.award.status === 'pending') {
                    let endDate = data.award.signingPeriod !== null ?
                    `Кінцевий строк оприлюднення укладеного договору: ${dateTransform(data.award.signingPeriod.endDate, 'time')}` : ''
                    return `Очікується публікація протоколу ${endDate}`
                }
                if (data.award.status === 'pending_waiting') {
                    return `Очікування рішення`
                }
                if (data.award.status === 'active') {
                    let endDate = data.award.signingPeriod !== null ?
                    `Кінцевий строк оприлюднення укладеного договору: ${dateTransform(data.award.signingPeriod.endDate, 'time')}` : ''
                    return `Вітаємо з перемогою! ${endDate}`
                }
                if (data.award.status === 'unsuccessful') {
                    return `Вашу пропозицію дискваліфіковано!`
                }
                if (data.award.status === 'cancelled') {
                    return `На жаль, Ви не є переможцем в цьому аукціоні`
                }
            
                return `Очікування рішення`
            }
        }
        if (data.auction.status === 'cancelled') {
            return null
        }
        if (data.auction.status === 'unsuccessful') {
            return null
        }
        return `Пропозиція опублікована в ЦБД. Ви є зареєстрованим учасником аукціону`
    }
    return null
}

function getValue(a) {
    if (a !== null) {
        let amount = new Intl.NumberFormat('uk-UA').format(a.amount)
        function cur(a) {
            switch (a.currency) {
                case 'UAH':
                    return 'грн'
                case 'USD':
                    return 'долл.'
                case 'EUR':
                    return 'євро'
                default:
                    return 'грн'
            }
        }
        return `${amount} ${cur(a)}`
    }
    return null
}

function setBidStatus(data) {
    if (data === null || data === 'draft') {
        if (data.needModeration) { return <div className={styles.moderation}>(Пропозиція на модерації)</div> }
        return <div className={styles.draft}>(Чернетка)</div>
    }
    if (data === 'active') { return <div className={styles.active}>(Пропозиція активована)</div> }
    if (data === 'cancelled') { return <div className={styles.canceled}>(Пропозицію відхилено)</div>}
    if (data === 'inactive') { return <div className={styles.canceled}>(Ваша ставка не активна. Активуйте її повторно)</div>}
    return null
}

function setTextStatus(data = 'active_rectification', bid){
    switch(data){
        case 'active_rectification':
            return <div className={styles.status_darkGrey}>Редагування доступне</div>
        case 'active_tendering':
            return <div className={styles.vFlex}>
                        <div className={styles.status_green}>Прийняття заяв на участь</div>
                        <div>{setBidStatus(bid)}</div>
                    </div>
        case 'active_auction': 
            return <div className={styles.vFlex}>
                        <div className={styles.status_green}>Аукціон</div>
                        <div>{setBidStatus(bid)}</div>
                    </div>
        case 'active_qualification': 
            return <div className={styles.status_brown}>Очікується публікація протоколу</div>
        case 'active_awarded': 
            return <div className={styles.status_brown}>Очікується підписання договору</div>
        case 'pending_payment': 
            return <div className={styles.status_brown}>Очікується сплата до бюджету</div>
            //return <div className={styles.status_brown}>Очікується оплата</div>
        case 'pending_admission':
            return <div className={styles.status_black}>Аукціон завершено. Очікується виконання умов договору'</div>
        case 'cancelled':
            return <div className={styles.status_grey}>Аукціон скасовано</div>
        case 'unsuccessful':
            return <div className={styles.status_grey}>Аукціон не відбувся</div>
        case 'complete':
            return <div className={styles.status_black}>Аукціон завершено</div>
        case null: 
            return <div className={styles.status_grey}>Чернетка</div>
        default: 
            return data
    }
}



const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        auctionsMy: state.start.auctionsGetMy,
        auctionsMySingl: state.start.auctionsMy,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        auctionsMyNew: state.start.auctionsMyNew,
        userProfiles: state.start.userProfiles,
        myBids: state.start.myBids,
        myCabinetPage: state.start.myCabinetPage,
        cloneObjectId: state.start.cloneObjectId,
    }
}

const mapDispatchToProps = {
    changeCreateAuctionTab,

    changeCreateAuctionTitle,
    changeCreateAuctionDescription,
    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,
    changeCreateAuctionId,

    classificationsGetByScheme,
    getAPI,
    setPopUpSendAuction,

    auctionsGetAwards,
    auctionsGetAwardsId,
    getAuctionsBidsId,
    changePageNumber,

    contractsAuctionIdGet,
    setPopUpErrorAuction,
    setPopBankAccountId,
    setPopBankAccountIdArr,

    oldArrDocumentGet,

    changeDiscountPercent,
    changeDiscountPerviousAuctionCurrency,
    changeDiscountPerviousAuctionId,
    discountAuc,
    changeDiscountId,

    setLogo,

    setPopUpAuctionMyNull,

    setUniversalError,
    setEditAuctionFromId,

    changeLoader,

    //////////////////////////
    auctionsBidsGetMy,
    auctionsGetMy,
    changeAuctionsSwitchWindow,
    auctionsGetClassificationsByType,
    auctionsGetClassificationsByTimberSortment,
    auctionsGetClassificationsByTimberSortmentFromCashe,
    auctionsGetClassificationsByTimberSpecies,
    auctionsGetClassificationsByTimberSpeciesFromCashe,
    auctionsGetClassificationsByTimberClass,
    auctionsGetClassificationsByTimberClassFromCashe,
    auctionsGetClassificationsByTimberDiameter,
    auctionsGetClassificationsByTimberDiameterFromCashe,
    auctionsGetClassificationsByTimberLength,
    auctionsGetClassificationsByTimberLengthFromCashe,
    auctionsGetClassificationsByTimberStorage,
    auctionsGetClassificationsByTimberStorageFromCashe,
    auctionsGetClassificationsByTimberProductionYear,
    auctionsGetClassificationsByTimberProductionYearFromCashe,
    auctionsGetClassificationsByTimberProductionQuarter,
    auctionsGetClassificationsByTimberProductionQuarterFromCashe,
    auctionsGetClassificationsByWagonSpecies,
    auctionsGetClassificationsByWagonSpeciesFromCashe,
    auctionsGetClassificationsByTypeNadraEnglish,
    auctionsGetClassificationsByTypeNadraEnglishFromCashe,
    auctionsGetClassificationsByByShemaSubsoilUsageType,
    auctionsGetClassificationsByTypeRailway,
    auctionsGetClassificationsByCarryType,
    auctionsGetClassificationsByTypeRailwayCargoAdd,
    auctionsGetWagonRestrict,
    auctionsGetWagonRestrictType,
    changeCreateItemClassificationAddId,
    getMyAuction,
	bankAccountGet,
	bankAccountShemeGet,

	unitsGetByType,
	unitsGetByTypeEnergy,
	unitsGetByTypeRailway,
	unitsGetByTypeSubsoil,
    addressesGetMy,
    
    workMyPageCurrentAuction,
    workMyPageNextAuction,

    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyBids)
