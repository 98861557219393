import React from 'react';

import {connect} from 'react-redux';
import {reverseStr, createApostrof} from '../../../../model/summNumberToSreang.js';

import TextFull from '../../../elements/viewData/textFull'

import {
    isTIE,
    isSUE, isSUD,
    isRCE, isRCD,
    isGFE, isGFD,
    isLLE, isLLD, isLLP,
    isLRE,
    isLSE, isLSP,
    isRMA,
    isBSE, isBSD,
    isALE,
    isCSE, isCSD,
    isAAE,
    isBRE, isBRD, isBRW,
    isRLE, isRLD,
    isCLE, isCLD,
    isSPE, isSPD,
    isNLE, isNLD,
    isLAE, isLAW, isLAP,
    isLPE,
    isAPE, isAPD
} from '../../../../redux/aucTypes.js';

import {viewStringValue} from '../../../../api/valueToString.js'

const MinimalStepString = (props) => {
    if(props.auctionId === null) return null
    if(props.auctionId.minimalStep === null) return null
    let tmp = viewStringValue(props.auctionId.minimalStep)
    return (
        <TextFull
            title={ title( props.auctionId.sellingMethod ) }
            data={ tmp }
        />
    )
}

function title(data){
    if(isTIE(data)) return "Розмір кроку аукціону"
    // if(isSUE(data) || isSUD(data)) return <Subsoil />
    // if(isRCE(data) || isRCD(data)) return <Railway />
    if(isGFE(data) || isGFD(data)) return "Мінімальний крок аукціону"
    // if(isLLE(data) || isLLD(data) || isLLP(data)) return <ZU />
    // if(isLRE(data)) return <LandRental />
    if(isLRE(data) || isLSE(data) || isLSP(data)) return "Мінімальний крок"
    // if(isRMA(data)) return null
    if(isBSE(data) || isBSD(data)) return "Розмір кроку аукціону"
    if(isALE(data)) return "Розмір кроку аукціону"
    if(isCSE(data) || isCSD(data)) return "Розмір кроку аукціону"
    // if(isAAE(data)) return null
    if(isBRE(data) || isBRD(data)) return "Розмір кроку аукціону"
    // if(isBRW(data)) return <BRE />
    if(isRLE(data)) return "Мінімальний крок"
    if(isRLD(data)) return "Мінімальний крок"
    if(isCLE(data)) return "Мінімальний крок"
    if(isCLD(data)) return "Мінімальний крок"
    if(isSPE(data) || isSPD(data))  return "Розмір кроку аукціону"
    // if(isNLE(data)) return <NLE />
    // if(isNLD(data)) return <NLD />
    if(isLAE(data)) return "Мінімальний крок"
    // if(isLAW(data)) return <LAW />
    if(isLAP(data)) return "Мінімальний крок"
    if(isLPE(data)) return "Розмір кроку аукціону"
    if(isAPE(data)) return "Розмір кроку аукціону"
    if(isAPD(data)) return "Розмір кроку аукціону"
    return "Розмір мінімального кроку підвищення стартової орендної плати під час аукціону"
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(MinimalStepString)