import React, {useEffect} from 'react';
import styles from './landRental.module.css';

import {connect} from 'react-redux';

import 'date-fns';

import ItemsList from './itemsList/itemsList.js';
import RealEstateItem from './realEstateItem/realEstateItem'
import ReturnButton from '../../returnButton'

import {setDataAddress} from '../../../../redux/createAddress';

const DgfAnyItems = ( props ) => {


    useEffect(() => {
        getAdditionalClassificationCVZU(props)
        getAdditionalClassificationZU(props)    
    }, []);
    return (
        <div>
            <div className={styles.flexBlock}>
                <div className={styles.flexBlockElem}>
                    <div className={styles.headers}>
                    </div>
                    <ItemsList />
                </div>
                <div className={styles.flexBlockElem}>
                    <RealEstateItem />
                </div>
            </div>
            <div className={styles.bottonBlock}>
                <ReturnButton />
            </div>
        </div>
    )
}

async function getAdditionalClassificationCVZU(props){
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/byScheme/cvzu`;
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/classifications/byScheme/CVZU`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        await props.setDataAddress(json, 'CREATE_CVZU_DATA')
        //await props.setDataAddress(json, 'CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATIONS_ARR')
    }
}

async function getAdditionalClassificationZU(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/dictionaries/kvtspz?limit=1000`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        await props.setDataAddress(json, 'CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATIONS_ARR')
    }
}


const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        getAuctionsMy: state.start.auctionsGetMy,
        classificationsNadraEnglish: state.start.classificationsNadraEnglish,
        addressGetId: state.start.addressGetId,
        setMask: state.start.setMask,
        classificationsDgf: state.start.classificationsDgf,
    }
}

const mapDispatchToProps = {
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(DgfAnyItems)