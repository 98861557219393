import React, {useState} from 'react';
import styles from './input.module.css';
import {useDispatch, useSelector} from 'react-redux';

const InputText = (props) => {
    const dispatch = useDispatch()
    const [value, setValue] = useState(props.data.target)
    const [inputStyle, setInputStyle] = useState(props.data.alert ? styles.alert : styles.inPut)
    const [label, setLabel] = useState(props.data.necessarily ? `${props.data.label} *` : props.data.label)

    function keyPressDispatch(e) {
        if (e.keyCode === 13) {
            let tmp = value.trim()
            // dispatch({ payload: tmp, type: props.data.targetType })
        }
    }

    function dispValue() {
        if (value !== props.data.target) {
            let tmp = value.trim()
            // dispatch({ payload: tmp, type: props.data.targetType})
        }
    }

    return (
        <>
            <div className={styles.inPutInfo}>
                <input type={props.data.type || 'text'}
                    className={inputStyle}
                    value={value}
                    min={props.data.range ? props.data.range.min : null}
                    max={props.data.range ? props.data.range.max : null}
                    placeholder={props.data.placeholder}
                    onChange={e => {
                        setValue(e.target.value)
                        props.data.action(e.target.value)
                        // dispatch({ payload: e.target.value, type: props.data.targetType })
                    }}
                    // onKeyDown={(e) =>  keyPressDispatch(e) }
                    // onBlur={ () => dispValue() }
                />
                { props.data.label ? 
                    <div className={styles.label}>
                        {label}
                    </div> : null
                }
            </div>
            {props.data.descr ? <div className={styles.descript}>{props.data.descr}</div> : null}
        </>
    )

}


export default InputText