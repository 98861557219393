import { 
    //Item Units
    UNIT_GET,
    UNITS_GET,
    UNIT_POST,
    UNIT_PUT,
    UNIT_DELETE,
    UNITS_GET_BY_TYPE,
    UNITS_GET_BY_TYPE_ENERGY,
    UNITS_GET_BY_TYPE_RAILWAY,
    UNITS_GET_BY_TYPE_SUBSOIL,
    UNITS_GET_BY_TYPE_ZU,
} from '../../types.js';

import {getAPI, postAPI, putAPI, deleteAPI} from '../crud';
import {setLocal} from '../support';

//Item Units
export function unitPost(_url, token, datas){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/unit`,
        token: token,
        actionType: UNIT_POST,
        data: datas,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return postAPI(data);
}

export function unitGetId(_url, token){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/unit/${_url.id}`,
        token: token,
        actionType: UNIT_GET,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function unitPut(_url, token, datas){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/unit/${_url.id}`,
        token: token,
        actionType: UNIT_PUT,
        data: datas,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return putAPI(data);
}

export function unitDelete(_url, token){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/unit/${_url.id}`,
        token: token,
        actionType: UNIT_DELETE,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return deleteAPI(data);
}

export function unitsGet(_url, header){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/units${_url.params}`,
        actionType: UNITS_GET,
        header: header
    }
    return getAPI(data);
}

export function unitsGetByType(_url, header){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/units/byType/${_url.params}`,
        actionType: UNITS_GET_BY_TYPE,
        header: header
    }
    return getAPI(data);
}

export function unitsGetByTypeEnergy(_url, header){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/units/byType/renewables?limit=100`,
        actionType: UNITS_GET_BY_TYPE_ENERGY,
        header: header
    }
    return getAPI(data);
}

export function unitsGetByTypeRailway(_url, header){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/units/byType/railwayCargo-english?limit=100`,
        actionType: UNITS_GET_BY_TYPE_RAILWAY,
        header: header
    }
    return getAPI(data);
}

export function unitsGetByTypeSubsoil(_url, header){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/units/byType/subsoil-english?limit=100`,
        actionType: UNITS_GET_BY_TYPE_SUBSOIL,
        header: header
    }
    return getAPI(data);
}

export function unitsGetByTypeZU(_url, header){
    const data = {
        //url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/units/byType/unitCode?limit=100`,
        //url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/units/byType/legitimatePropertyLease-english?limit=100`,
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/units/byItemType/otherProperty?limit=100`,
        actionType: UNITS_GET_BY_TYPE_ZU,
        header: header
    }
    return getAPI(data);
}