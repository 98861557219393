import React from 'react';
import styles from './wood.module.css';

import { Link } from 'react-router-dom';

import {
    FOREST_CATEGORY_MENU_LANG,
} from '../../../../model/lang/iconCategoryMenu.js';
import {connect} from 'react-redux';

import {setAuctionCategory} from '../../../../redux/actions/support.js';
import {
    auctionsSearch,
    auctionsSearchСount,
    changeCurentPage,
} from '../../../../redux/actions/auctions/auctions.js';

import {
    changeMyCurentPage,
} from '../../../../redux/actions.js';

const Wood = ( {langFlag, aucyionsPath, search, token, searchCount, auctionsSearch, auctionsSearchСount, setAuctionCategory, changeCurentPage, changeMyCurentPage, clearSearch} ) => {
    let active = null;
    if(search.category === 'timber-english'){
        active = styles.active;
    }
    return (
            <div
                className={styles.aukpoisk_second_elem}
                onClick={
                    ()=>{
                        changeMyCurentPage(0)
                        setAuctionCategory('timber-english');
                        changeCurentPage(0);
                        let dataSearch = {}
                        dataSearch.order = search.order
                        dataSearch.region = search.region
                        dataSearch.category = 'timber-english'
                        dataSearch.priceCurrency = search.priceCurrency
                        dataSearch.priceFrom = search.priceFrom
                        dataSearch.priceTo = search.priceTo
                        dataSearch.measures = search.measures
                        dataSearch.measuresFrom = search.measuresFrom
                        dataSearch.measuresTo = search.measuresTo
                        if(search.status === null || search.status === ''){
                            dataSearch.status = null
                        }else{
                            dataSearch.status = search.status
                        }
                        let data = {
                            domen: process.env.REACT_APP_END_POINT_BD,
                            version: 'v1',
                            local: langFlag,
                            params: '?offset=0'
                        }
                        let dataS = {
                            domen: process.env.REACT_APP_END_POINT_BD,
                            version: 'v1',
                            local: langFlag,
                            params: '?onlyCount=1'
                        }
                        if(token !== null){
                            let header = {
                                'Authorization': token.access_token,
                                'Content-Type': 'application/json'
                            }
                            auctionsSearch(data, header, dataSearch);
                            auctionsSearchСount(dataS, header, dataSearch);
                        }else{
                            let header = {
                                'Content-Type': 'application/json'
                            }
                            auctionsSearch(data, header, dataSearch);
                            auctionsSearchСount(dataS, header, dataSearch);
                        }
                        clearSearch()
                    }
                }
            >
                <Link to={`${aucyionsPath}/timber${getUrlFiltersParam(search, 'timber-english')}`}>
                    <div className={styles.base + ' ' + active}>
                        <svg
                            version="1.1"
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            viewBox="0 0 512 512"
                            xmlSpace="preserve"
                        >
                            <g>
                                <g>
                                    <path d="M256,0c-49.616,0-97.742,14.215-139.182,41.107c-4.527,2.938-5.814,8.988-2.877,13.515
                                        c2.938,4.527,8.988,5.813,13.515,2.877C165.72,32.668,210.169,19.542,256,19.542c130.383,0,236.458,106.075,236.458,236.458
                                        S386.383,492.458,256,492.458S19.542,386.383,19.542,256c0-66.487,28.237-130.284,77.47-175.031
                                        c3.993-3.63,4.287-9.809,0.658-13.803c-3.63-3.994-9.809-4.289-13.803-0.658C30.569,114.951,0,184.018,0,256
                                        c0,141.16,114.84,256,256,256s256-114.84,256-256S397.16,0,256,0z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path d="M429.915,139.879c-2.999-4.484-9.069-5.691-13.553-2.688c-4.486,2.999-5.689,9.069-2.688,13.553
                                        c20.858,31.18,31.883,67.576,31.883,105.256c0,104.522-85.035,189.557-189.557,189.557S66.443,360.522,66.443,256
                                        S151.478,66.443,256,66.443c49.861,0,96.943,19.202,132.572,54.069c3.858,3.776,10.045,3.708,13.817-0.149
                                        c3.776-3.856,3.708-10.043-0.149-13.817C362.936,68.083,311.002,46.901,256,46.901C140.702,46.901,46.901,140.702,46.901,256
                                        S140.702,465.099,256,465.099S465.099,371.298,465.099,256C465.099,214.439,452.932,174.283,429.915,139.879z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path d="M256,106.83c-32.2,0-62.861,10.098-88.67,29.204c-4.338,3.211-5.25,9.329-2.04,13.666
                                        c3.213,4.341,9.331,5.252,13.666,2.04c22.42-16.596,49.061-25.368,77.044-25.368c71.477,0,129.628,58.152,129.628,129.628
                                        c0,59.617-40.336,111.335-98.09,125.766c-5.236,1.309-8.419,6.613-7.111,11.849c1.11,4.441,5.095,7.405,9.473,7.405
                                        c0.784,0,1.582-0.095,2.378-0.293C358.747,384.115,405.17,324.602,405.17,256C405.17,173.746,338.253,106.83,256,106.83z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path d="M174,356.402C143.731,331.65,126.372,295.055,126.372,256c0-27.027,8.231-52.922,23.805-74.885
                                        c3.122-4.402,2.083-10.501-2.319-13.622c-4.401-3.123-10.501-2.083-13.622,2.319C116.306,195.097,106.83,224.901,106.83,256
                                        c0,44.941,19.973,87.05,54.8,115.53c1.815,1.484,4.005,2.207,6.18,2.207c2.83,0,5.637-1.223,7.569-3.585
                                        C178.795,365.973,178.177,359.817,174,356.402z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path d="M340.682,246.229c-5.398,0-9.771,4.373-9.771,9.771c0,41.307-33.604,74.911-74.911,74.911S181.089,297.307,181.089,256
                                        s33.604-74.911,74.911-74.911c5.398,0,9.771-4.373,9.771-9.771c0-5.397-4.373-9.771-9.771-9.771
                                        c-52.082,0-94.453,42.371-94.453,94.453s42.371,94.453,94.453,94.453s94.453-42.371,94.453-94.453
                                        C350.453,250.602,346.079,246.229,340.682,246.229z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path d="M256,216.265c-21.909,0-39.735,17.826-39.735,39.735s17.826,39.735,39.735,39.735s39.735-17.826,39.735-39.735
                                        S277.909,216.265,256,216.265z M256,276.193c-11.135,0-20.193-9.058-20.193-20.193s9.058-20.193,20.193-20.193
                                        s20.193,9.058,20.193,20.193S267.135,276.193,256,276.193z"/>
                                </g>
                            </g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                        </svg>
                    </div>
                    <div className={styles.aukpoisk_second_elem_text}>{FOREST_CATEGORY_MENU_LANG[langFlag]}</div>
                </Link>
            </div>
    )
}

function getUrlFiltersParam(search, type){
    let tmp = null
    if(search.orderBy !== null){
        if(tmp === null){
            tmp = '?orderBy=' + search.orderBy
        }else{
            tmp += '&orderBy=' + search.orderBy
        }
    }
    if(search.status !== null){
        if(tmp === null){
            tmp = '?status=' + search.status
        }else{
            tmp += '&status=' + search.status
        }
    }
    if(search.region !== null){
        if(tmp === null){
            tmp = '?region=' + search.region
        }else{
            tmp += '&region=' + search.region
        }
    }
    //if(search.category !== null){
        if(tmp === null){
            tmp = `?category=${type}`
        }else{
            tmp += `&category=${type}`
        }
    //}
    if(search.priceCurrency !== null){
        if(tmp === null){
            tmp = '?priceCurrency=' + search.priceCurrency
        }else{
            tmp += '&priceCurrency=' + search.priceCurrency
        }
    }
    if(search.priceFrom !== null){
        if(tmp === null){
            tmp = '?priceFrom=' + search.priceFrom
        }else{
            tmp += '&priceFrom=' + search.priceFrom
        }
    }
    if(search.priceFromBag !== false){
        if(tmp === null){
            tmp = '?priceFromBag=true'
        }else{
            tmp += '&priceFromBag=true'
        }
    }
    if(search.priceTo !== null){
        if(tmp === null){
            tmp = '?priceTo=' + search.priceTo
        }else{
            tmp += '&priceTo=' + search.priceTo
        }
    }
    if(search.priceToBag !== false){
        if(tmp === null){
            tmp = '?priceToBag=true'
        }else{
            tmp += '&priceToBag=true'
        }
    }
    if(search.measures !== null){
        if(tmp === null){
            tmp = '?measures=' + search.measures
        }else{
            tmp += '&measures=' + search.measures
        }
    }
    if(search.measuresFrom !== null){
        if(tmp === null){
            tmp = '?measuresFrom=' + search.measuresFrom
        }else{
            tmp += '&measuresFrom=' + search.measuresFrom
        }
    }
    if(search.measuresTo !== null){
        if(tmp === null){
            tmp = '?measuresTo=' + search.measuresTo
        }else{
            tmp += '&measuresTo=' + search.measuresTo
        }
    }
    if(search.textSting !== null){
        if(tmp === null){
            tmp = '?keyword=' + search.textSting
        }else{
            tmp += '&keyword=' + search.textSting
        }
    }
    if(search.selectTypeKeyword !== null){
        if(tmp === null){
            tmp = '?selectTypeKeyword=' + search.selectTypeKeyword
        }else{
            tmp += '&selectTypeKeyword=' + search.selectTypeKeyword
        }
    }
    if(tmp !== null){
        return tmp
    }
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        aucyionsPath: state.start.aucyionsPath,
        search: state.start.search,
        token: state.start.token,
        searchCount: state.start.searchCount,
    }
}

const mapDispatchToProps = {
    auctionsSearch,
    auctionsSearchСount,
    setAuctionCategory,
    changeCurentPage,
    changeMyCurentPage,
}

export default connect(mapStateToProps, mapDispatchToProps)(Wood)