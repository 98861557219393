import React from 'react';
import styles from './landRental.module.css';

import { Link } from 'react-router-dom';

import {connect} from 'react-redux';

import {setAuctionCategory} from '../../../../redux/actions/support.js';
import {
    auctionsSearch,
    auctionsSearchСount,
    changeCurentPage,
} from '../../../../redux/actions/auctions/auctions.js';

import {
    changeMyCurentPage,
} from '../../../../redux/actions.js';
import {
    setDataAddress,
} from '../../../../redux/createAddress';


const Wood = (props) => {
    let active = null;
    if(props.search.category === 'landRental'){
        active = styles.active;
    }
    return (
        <div
            className={styles.aukpoisk_second_elem}
        >
            <Link
                onClick={
                    ()=>{
                        getAdditionalClassificationZU(props)
                        //classificationZU(props)
                        props.changeMyCurentPage(0)
                        props.setAuctionCategory('landRental');
                        props.changeCurentPage(0);
                        let dataSearch = {}
                        dataSearch.order = props.search.order
                        dataSearch.region = props.search.region
                        dataSearch.category = 'landRental'
                        dataSearch.priceCurrency = props.search.priceCurrency
                        dataSearch.priceFrom = props.search.priceFrom
                        dataSearch.priceTo = props.search.priceTo
                        dataSearch.measures = props.search.measures
                        dataSearch.measuresFrom = props.search.measuresFrom
                        dataSearch.measuresTo = props.search.measuresTo
                        if(props.search.status === null || props.search.status === ''){
                            dataSearch.status = null
                        }else{
                            dataSearch.status = props.search.status
                        }
                        let data = {
                            domen: process.env.REACT_APP_END_POINT_BD,
                            version: 'v1',
                            local: props.langFlag,
                            params: '?offset=0'
                        }
                        if(props.token !== null){
                            let header = {
                                'Authorization': props.token.access_token,
                                'Content-Type': 'application/json'
                            }
                            props.auctionsSearch(data, header, dataSearch);
                        }else{
                            let header = {
                                'Content-Type': 'application/json'
                            }
                            props.auctionsSearch(data, header, dataSearch);
                        }
                        props.clearSearch()
                    }
                }
                to={`${props.aucyionsPath}/landRental${getUrlFiltersParam(props.search, 'landRental')}`}
            >
                <div className={styles.base + ' ' + active}></div>
                <div className={styles.aukpoisk_second_elem_text}>Оренда землі</div>
            </Link>
            <div className={styles.aukpoisk_second_elem_1}>
                <Link to={`${props.aucyionsPath}/landRental-english${getUrlFiltersParam(props.search, 'landRental-english')}`}>
                    <div
                        className={styles.subMenu}
                        onClick={
                            ()=>{
                                getAdditionalClassificationZU(props)
                                //classificationZU(props)
                                props.changeMyCurentPage(0)
                                props.setAuctionCategory('landRental-english');
                                props.changeCurentPage(0);
                                let dataSearch = {}
                                dataSearch.order = props.search.order
                                dataSearch.region = props.search.region
                                dataSearch.category = 'landRental-english'
                                dataSearch.priceCurrency = props.search.priceCurrency
                                dataSearch.priceFrom = props.search.priceFrom
                                dataSearch.priceTo = props.search.priceTo
                                dataSearch.measures = props.search.measures
                                dataSearch.measuresFrom = props.search.measuresFrom
                                dataSearch.measuresTo = props.search.measuresTo
                                if(props.search.status === null || props.search.status === ''){
                                    dataSearch.status = null
                                }else{
                                    dataSearch.status = props.search.status
                                }
                                let data = {
                                    domen: process.env.REACT_APP_END_POINT_BD,
                                    version: 'v1',
                                    local: props.langFlag,
                                    params: '?offset=0'
                                }
                                if(props.token !== null){
                                    let header = {
                                        'Authorization': props.token.access_token,
                                        'Content-Type': 'application/json'
                                    }
                                    props.auctionsSearch(data, header, dataSearch);
                                }else{
                                    let header = {
                                        'Content-Type': 'application/json'
                                    }
                                    props.auctionsSearch(data, header, dataSearch);
                                }
                                props.clearSearch()
                            }
                        }
                    >
                        Оренда землі. Англієць
                    </div>
                </Link>
            </div>
        </div>
    )
}

async function getAdditionalClassificationZU(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/dictionaries/kvtspz?limit=1000?limit=1000`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            //'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        await props.setDataAddress(json, 'CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATIONS_ARR')
    }
}

function getUrlFiltersParam(search, type){
    let tmp = null
    if(search.orderBy !== null){
        if(tmp === null){
            tmp = '?orderBy=' + search.orderBy
        }else{
            tmp += '&orderBy=' + search.orderBy
        }
    }
    if(search.status !== null){
        if(tmp === null){
            tmp = '?status=' + search.status
        }else{
            tmp += '&status=' + search.status
        }
    }
    if(search.region !== null){
        if(tmp === null){
            tmp = '?region=' + search.region
        }else{
            tmp += '&region=' + search.region
        }
    }
    //if(search.category !== null){
        if(tmp === null){
            tmp = `?category=${type}`
        }else{
            tmp += `&category=${type}`
        }
    //}
    if(search.priceCurrency !== null){
        if(tmp === null){
            tmp = '?priceCurrency=' + search.priceCurrency
        }else{
            tmp += '&priceCurrency=' + search.priceCurrency
        }
    }
    if(search.priceFrom !== null){
        if(tmp === null){
            tmp = '?priceFrom=' + search.priceFrom
        }else{
            tmp += '&priceFrom=' + search.priceFrom
        }
    }
    if(search.priceFromBag !== false){
        if(tmp === null){
            tmp = '?priceFromBag=true'
        }else{
            tmp += '&priceFromBag=true'
        }
    }
    if(search.priceTo !== null){
        if(tmp === null){
            tmp = '?priceTo=' + search.priceTo
        }else{
            tmp += '&priceTo=' + search.priceTo
        }
    }
    if(search.priceToBag !== false){
        if(tmp === null){
            tmp = '?priceToBag=true'
        }else{
            tmp += '&priceToBag=true'
        }
    }
    if(search.measures !== null){
        if(tmp === null){
            tmp = '?measures=' + search.measures
        }else{
            tmp += '&measures=' + search.measures
        }
    }
    if(search.measuresFrom !== null){
        if(tmp === null){
            tmp = '?measuresFrom=' + search.measuresFrom
        }else{
            tmp += '&measuresFrom=' + search.measuresFrom
        }
    }
    if(search.measuresTo !== null){
        if(tmp === null){
            tmp = '?measuresTo=' + search.measuresTo
        }else{
            tmp += '&measuresTo=' + search.measuresTo
        }
    }
    if(search.textSting !== null){
        if(tmp === null){
            tmp = '?keyword=' + search.textSting
        }else{
            tmp += '&keyword=' + search.textSting
        }
    }
    if(search.selectTypeKeyword !== null){
        if(tmp === null){
            tmp = '?selectTypeKeyword=' + search.selectTypeKeyword
        }else{
            tmp += '&selectTypeKeyword=' + search.selectTypeKeyword
        }
    }
    if(tmp !== null){
        return tmp
    }
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        aucyionsPath: state.start.aucyionsPath,
        search: state.start.search,
        token: state.start.token,
        searchCount: state.start.searchCount,
    }
}

const mapDispatchToProps = {
    auctionsSearch,
    auctionsSearchСount,
    setAuctionCategory,
    changeCurentPage,
    changeMyCurentPage,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(Wood)