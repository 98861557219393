import React from 'react';
import styles from './togetherAPI.module.css';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';

import {connect} from 'react-redux';

import {setPopUpBidTogetherAPI} from '../../../../redux/actions/auctions/auctions.js';
import CreateAuctionLoader from '../../../createAuction/createAuctionLoader.js';

import {setUniversalError} from '../../../../redux/actions.js';

import {
    togetherApiPullAucEI,
    togetherApiSaveDataLoad,
    togetherApiSaveValue,

    togetherApiTimerRound,
    togetherApiTimerTime,
    togetherApiTimerTimeLeft,
} from '../../../../redux/togetherApi.js';

const TogetherAPIBidsHeader = (props) => {
    return (
        <div className={styles.auctionsLineElem}>
            <div className={styles.seller}>Продавець</div>
            <div className={styles.sortment}>Назва сортименту</div>
            <div className={styles.species}>Порода</div>
            <div className={styles.class}>Клас якості</div>
            <div className={styles.length}>Розміри</div>
            <div className={styles.storage}>Склад</div>
            <div className={styles.quantity}>Обсяг</div>
            <div className={styles.baseCoast}>Початкова ціна, грн.</div>
            <div className={styles.bid}>Пропозиція грн.</div>
            <div className={styles.auctionStatus}>Статус аукціону</div>
            <div className={styles.linkToAuction}>Посилання на аукціон</div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        auctions: state.start.auctionsGet,
        auctionsOld: state.start.auctionsGetOld,
        search: state.start.search,
        myBids: state.start.myBids,
        token: state.start.token,
        togetherApiPage: state.start.togetherApiPage,
    }
}

const mapDispatchToProps = {
    setPopUpBidTogetherAPI,  
    setUniversalError,  
    togetherApiPullAucEI,
    togetherApiSaveDataLoad,
    togetherApiSaveValue,
    
    togetherApiTimerRound,
    togetherApiTimerTime,
    togetherApiTimerTimeLeft,
}

export default connect(mapStateToProps, mapDispatchToProps)(TogetherAPIBidsHeader)