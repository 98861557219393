import React from 'react';
import styles from '../docItem.module.css';
import { viewBidEditDoc } from './logic/bidLogic';
import { viewAucEditDoc } from './logic/aucEditLogic';
import { viewAwardEditDoc } from './logic/awardLogic';
import { viewContractEditDoc } from './logic/contractLogic';
import { viewProlongationEditDoc } from './logic/prolongationLogic'
import { viewCancellationEditDoc } from './logic/cancellationLogic'
import { isAPE, isAPD } from '../../../redux/aucTypes';


const DocEditButton = (props) => {
    if(props.scope.typeOf === "cancellation") return null
    function viewEditDoc(props) {
        switch (props.scope.typeOf) {
            case 'bid':
                return viewBidEditDoc(props)
            case 'award':
            case 'bidAward':
                return viewAwardEditDoc(props)
            case 'auctionEdit':
                return viewAucEditDoc(props)
            case 'contract':
                return viewContractEditDoc(props)
            case 'prolongation':
                return viewProlongationEditDoc(props)
            case 'cancellation':
                return viewCancellationEditDoc(props)
            default:
                return false
        }
    }

    if (viewEditDoc(props)) {
        if(props.scope.aucType === 'landArrested-withoutAuction' && props.scope.awardStatus === "active") return null
        if(!isAPE(props.scope.aucType) && !isAPD(props.scope.aucType)){
            if(
                props.data.documentOf === "asset"
                || props.data.documentOf === "large_asset"
                || props.data.documentOf === "announcement"
                || props.data.documentOf === "large_announcement"
                || (
                    props.data.documentOf === "contract"
                    && props.scope.contractStatus === "signed"
                    && (
                        props.data.documentType === 'contractSigned'
                        || props.data.documentType === 'contractNotice'
                        || props.data.documentType === 'contractAnnexe'
                    )
                )
            ) return null
        }
        if((isAPE(props.scope.aucType) || isAPD(props.scope.aucType)) && props.scope.awardStatus === "active" && props.data.documentType === "assetNotice") return null
        return <div
                    className={styles.btnEdit}
                    title='Редагувати документ'
                    onClick={
                        () => {
                            props.state.setPopUp()
                        }
                    }
            >
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className={styles.btnEditSVG} fillRule="evenodd" clipRule="evenodd" d="M15.5679 5.8163L16.6269 4.75728L14.2927 2.42307L13.2337 3.48209L15.5679 5.8163ZM14.2315 7.15274L11.8973 4.81852L3.16537 13.5504L2.62813 16.4219L5.49958 15.8846L14.2315 7.15274ZM18.0198 3.47729C18.7267 4.18421 18.7267 5.33035 18.0198 6.03727L6.62915 17.4279C6.4944 17.5627 6.32205 17.6536 6.13473 17.6886L1.61925 18.5334C0.966291 18.6556 0.394406 18.0837 0.516572 17.4308L1.3614 12.9153C1.39645 12.728 1.48731 12.5556 1.62207 12.4209L13.0127 1.03019C13.7196 0.32327 14.8658 0.32327 15.5727 1.03019L18.0198 3.47729ZM17.6094 18.55H9.99059C8.73647 18.55 8.73647 16.65 9.99059 16.65H17.6094C18.8635 16.65 18.8635 18.55 17.6094 18.55Z" fill="#92929D"/>
                </svg>
            </div>
    }
    return null
}

export default DocEditButton