import React, { useState, useEffect, useRef } from 'react';
import styles from './period.module.css';

import Time from '../../../../../../../elements/inputs/inputTime';
import { checkProp } from '../../../../../../../../redux/check';
import Select from '../../../../../../../elements/inputs/inputSelect';
import DeleteIcon from '../../../../../../../elements/svg/deleteIcon';

const WeekDayPeriod = ({data, name, weekDaysPeriod, setWeekDaysPeriod, index, disabled, ...props}) => {
    const [hourStart, setHourStart] = useState(checkProp(data,'startTime') ? data.startTime : '00:00');
    const [hourEnd, setHourEnd] = useState(checkProp(data,'endTime') ? data.endTime : '00:00');
    const [weekday, setWeekday] = useState(checkProp(data,'weekday') ? data.weekday.uk_UA : null);

    // useEffect(() => {
    //     let tmp = {
    //         weekday: {uk_UA: weekday},
    //         endTime: hourEnd,
    //         startTime: hourStart
    //     }
    //     let tmp2 = weekDaysPeriod
    //     setWeekDaysPeriod(tmp2.splice(index, 1, tmp))
    //   }, [weekday, hourStart, hourEnd])
    

    const weekDaysList = [
        {name: 'Понеділок', value: 'Понеділок'}, 
        {name: 'Вівторок', value: 'Вівторок'}, 
        {name: 'Середа', value: 'Середа'}, 
        {name: 'Четвер', value: 'Четвер'}, 
        {name: 'П\'ятниця', value: 'П\'ятниця'}, 
        {name: 'Субота', value: 'Субота'}, 
        {name: 'Неділя', value: 'Неділя'}
    ]

    function checkValues(){
        let start = +hourStart.replace(':', '')
        let end = +hourEnd.replace(':', '')
        if ((start !== 0) && (end !== 0) && (end - start <= 5)) return true
        return false
    }

    console.log()
    return (<div className={styles.weekDaysEdit}>
            <div className={styles.weekDaysContent}>
                <Select
                    data={{
                        target: weekday,
                        label: 'Оберіть день тижня'
                    }}
                    onChange={ async (e) => {
                        setWeekday(e)
                        let tmp = weekDaysPeriod
                        tmp.forEach( (element, count) => {
                            if(index === count ) element.weekday = {uk_UA: e}
                        });
                        // await setWeekDaysPeriod(null)
                        await setWeekDaysPeriod(tmp)
                        }}
                    options={weekDaysList}
                    //disabled={disabled}
                />
                <Time
                    value={hourStart}
                    onChange={ async (e) => {
                            setHourStart(e)
                            let tmp = weekDaysPeriod
                            tmp.forEach( (element, count) => {
                                if(index === count ) element.startTime = e
                            });
                            // await setWeekDaysPeriod(null)
                            await setWeekDaysPeriod(tmp)
                    }}
                    label={'Час початку'}
                    //disabled={disabled}
                />
                <Time
                    value={hourEnd}
                    onChange={ async (e) => {
                        setHourEnd(e)
                        let tmp = weekDaysPeriod
                        tmp.forEach( (element, count) => {
                            if(index === count ) element.endTime = e
                        });
                        // await setWeekDaysPeriod(null)
                        await setWeekDaysPeriod(tmp)
                    }}
                    label={'Час завершення'}
                    error={checkValues()}
                    //disabled={disabled}
                />
                <div className={styles.weekDaysDelete} 
                    title='Видалити графік'
                    onClick={ async ()=>{
                        // alert(index)
                            let tmp = weekDaysPeriod
                            tmp.splice(index, 1)
                            // alert(JSON.stringify(tmp))
                            await setWeekDaysPeriod(null)
                            await setWeekDaysPeriod(tmp)
                    }}
                    //{disabled}
                >
                    <DeleteIcon 
                        color={'#BFBFC6'}
                        style={styles.weekDaysDeleteSVG}
                        widthHeight={[20,20]}
                        
                    />
                </div>
            </div>
            {checkValues() ? <div className={styles.alertBlock}>Час закінчення має бути більши за час початку щонайменше на 5 хвилин</div> : null}
        </div>
    )
}


export default WeekDayPeriod;