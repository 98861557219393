import React, {useEffect} from 'react';
import style from './paymentPopUp.module.css';

import {connect} from 'react-redux';

import {paymentPopup, paymentPopupCreatePdf, paymentPopupSetBank} from '../../../redux/paymentPopup.js';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import {signImg} from './sign'
import {
    isTIE, isSUE, isSUD, isRCE, isRCD, isGFE, isGFD,
    isLLE, isLLD, isLLP, isLRE, isLSE, isLSP,
    isRMA, isBSE, isBSD, isALE, isCSE, isCSD, isAAE, isBRE, isBRD, isBRW,
    isRLE, isRLD, isCLE, isCLD, isSPE, isSPD, isLPE, isLAE, isLAP, isAPE, isAPD, isNLD, isNLE
} from "../../../redux/aucTypes";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const PaymentPopUp = (props) => {

    // useEffect( () => {
    //     getIsFinAuth(props)
    // }, [])

    let tmp
    if(props.paymentPopupCreatePDF === false){
        tmp = viewDataBlock(props)
    }else{
        tmp = viewPdfBlock(props)
    }
    //if(isSPD(props.auction.sellingMethod)) return null
    return (
        <div className={style.popup_wrap}>
            <div className={style.popup_message}>
                <div
                    className={style.popUpExit}
                    onClick={
                        ()=>{
                            props.paymentPopup(null)
                            props.paymentPopupCreatePdf(false)
                            props.paymentPopupSetBank('null')
                        }
                    }
                >
                    X
                </div>
                {startPdf(props)}
                {tmp}
            </div>
        </div>
    )
}

function startPdf(props){
    if(props.paymentPopupGetData !== null){
        return (
            <div
                className={style.popUpTitle}
                onClick={
                    () => {
                        props.paymentPopupCreatePdf(true)
                    }
                }
            >
                Поповнення балансу
            </div>
        )
    }else{
        return (
            <div
                className={style.popUpTitle}
            >
                Поповнення балансу
            </div>
        )
    }
}

function viewDataBlock(props){
    let tmp = null
    if(
        isLLE(props.auction.sellingMethod) || isLLD(props.auction.sellingMethod) || isLLP(props.auction.sellingMethod) ||
        isLRE(props.auction.sellingMethod) ||
        isALE(props.auction.sellingMethod) ||
        isCSE(props.auction.sellingMethod) || isCSD(props.auction.sellingMethod) ||
        isBSE(props.auction.sellingMethod) || isBSD(props.auction.sellingMethod) ||
        isLSE(props.auction.sellingMethod) || isLSP(props.auction.sellingMethod) ||
        isRLE(props.auction.sellingMethod) || isRLD(props.auction.sellingMethod) ||
        isCLE(props.auction.sellingMethod) || isCLD(props.auction.sellingMethod) ||
        isSPE(props.auction.sellingMethod) || isSPD(props.auction.sellingMethod) ||
        isLPE(props.auction.sellingMethod) ||
        isLAE(props.auction.sellingMethod) || isLAP(props.auction.sellingMethod) ||
        isNLE(props.auction.sellingMethod) || isNLD(props.auction.sellingMethod) ||
        isAPE(props.auction.sellingMethod) || isAPD(props.auction.sellingMethod)
    ){
        if(props.paymentPopupGetData !== null){
            tmp = toCalcSumm(props, props.paymentPopupGetData[0].bill) + toCalcSumm(props, props.paymentPopupGetData[1].bill)
        }

    }else{
        if(props.paymentPopupGetData !== null){
            tmp = toCalcSumm(props, props.paymentPopupGetData[0].bill)
        }
    }
    return (
        <>
            <div className={style.inputWrap}>
                <div>Сума поповнення</div>
                <div>
                    <input
                        type='text'
                        disabled
                        id='fixSumm'
                        value = {Number(tmp).toFixed(2)}
                    />
                </div>
            </div>
            <div className={style.inputWrap}>
                <div>Обрати банк</div>
                <div>
                    {inputBank(props)}
                </div>
            </div>
            <div
                className={style.popup_message_button}
                onClick={
                    ()=>{
                        if(props.paymentPopupGetData !== null){
                            if(
                                Number(props.paymentPopupSetBanks) === 1 ||
                                Number(props.paymentPopupSetBanks) === 2 ||
                                Number(props.paymentPopupSetBanks) === 3 ||
                                Number(props.paymentPopupSetBanks) === 4 ||
                                Number(props.paymentPopupSetBanks) === 5
                            ){
                                props.paymentPopupCreatePdf(true)
                            }
                        }
                    }
                }
            >
                Отримати рахунок
            </div>
        </>
    )
}

function inputBank(props){
    let arr = [
        <option key={0} selected default value='null'>Обрати</option>,
        <option key={1} value='1'>ПАТ КБ "ПРИВАТБАНК</option>,
        /*<option key={2} value='2'>АТ "УКРБУДІНВЕСТБАНК"</option>,*/
        <option key={3} value='3'>ПАТ "ПУМБ"</option>,
        <option key={4} value='4'>АТ "БАНК АЛЬЯНС"</option>,
        <option key={4} value='5'>АТ "КРИСТАЛБАНК"</option>
    ]
    if(
        props.auction.sellingMethod.startsWith('landRental-english')   ||
        props.auction.sellingMethod.startsWith('landSell-english')   ||
        props.auction.sellingMethod.startsWith('landSell-priorityEnglish')
    ){
        props.paymentPopupSetBank('1')
        return (
            <select
                disabled
                value={props.paymentPopupSetBanks}
                onChange={
                    (e) => {
                        props.paymentPopupSetBank(e.target.value)
                    }
                }
            >
                <option key={0} value='1'>ПАТ КБ "ПРИВАТБАНК</option>
            </select>
        )
    } else {
        if (props.paymentPopupGetData !== null) {
            if (props.paymentPopupGetData[0].bill.amount >= 100000) {
                if (props.paymentPopupSetBanks === null || props.paymentPopupSetBanks === "null") props.paymentPopupSetBank('5')
                arr = [
                    <option key={0} value='null'>Обрати</option>,
                    <option key={1} value='1'>ПАТ КБ "ПРИВАТБАНК</option>,
                    /*<option key={2} value='2'>АТ "УКРБУДІНВЕСТБАНК"</option>,*/
                    <option key={3} value='3'>ПАТ "ПУМБ"</option>,
                    <option key={4} default value='4'>АТ "БАНК АЛЬЯНС"</option>,
                    <option key={4} value='5'>АТ "КРИСТАЛБАНК"</option>
                ]
            }
        }
        return (
            <select
                value={props.paymentPopupSetBanks}
                onChange={
                    (e) => {
                        props.paymentPopupSetBank(e.target.value)
                    }
                }
            >
                {arr}
            </select>
        )
    }
}

function toCalcSumm(props, data){
    //
    /*let tmp = null
    if(props.auction !== null){
        if(props.auction.value !== null){
            if(props.auction.value.amount !== null){
                let fixStav = 0
                if(props.auction.value.amount <= 300000){
                    fixStav = 340
                }else if(props.auction.value.amount > 300000 && props.auction.value.amount <= 1000000){
                    fixStav = 510
                }else{
                    fixStav = 1700
                }
                tmp = ((props.auction.value.amount / 100) * 5) + fixStav
            }
        }
    }
    if(tmp === null){
        return tmp
    }else{
        return tmp.toFixed(2)
    }*/
    if(data !== null){
        return data.amount
    }else{
        return ''
    }
}

function viewPdfBlock(props){
    if(
        isLLE(props.auction.sellingMethod) || isLLD(props.auction.sellingMethod) || isLLP(props.auction.sellingMethod) ||
        isLRE(props.auction.sellingMethod) ||
        isALE(props.auction.sellingMethod) ||
        isCSE(props.auction.sellingMethod) || isCSD(props.auction.sellingMethod) ||
        isBSE(props.auction.sellingMethod) || isBSD(props.auction.sellingMethod) ||
        isLSE(props.auction.sellingMethod) || isLSP(props.auction.sellingMethod) ||
        isRLE(props.auction.sellingMethod) || isRLD(props.auction.sellingMethod) ||
        isCLE(props.auction.sellingMethod) || isCLD(props.auction.sellingMethod) ||
        isSPE(props.auction.sellingMethod) || isSPD(props.auction.sellingMethod) ||
        isLPE(props.auction.sellingMethod) ||
        isLAE(props.auction.sellingMethod) || isLAP(props.auction.sellingMethod) ||
        isNLE(props.auction.sellingMethod) || isNLD(props.auction.sellingMethod) ||
        isAPE(props.auction.sellingMethod) || isAPD(props.auction.sellingMethod)
    ){
        return (
            <>
                {toCalcSumm(props, props.paymentPopupGetData[0].bill) > 0 &&
                    <div>
                        <div>Для Вас згенеровано документ на
                            суму {toCalcSumm(props, props.paymentPopupGetData[0].bill)} грн.
                            ({props.paymentPopupGetData[0].bill.billNumber})
                        </div>
                        <div
                            className={style.popup_message_button}
                            onClick={
                                () => {
                                    pdfMake.createPdf(getFistElemJournalPDF(props, props.paymentPopupGetData[0].bill)).download('name.pdf')
                                }
                            }
                        >
                            Завантажити
                        </div>
                    </div>
                }
                {toCalcSumm(props, props.paymentPopupGetData[1].bill) > 0 &&
                    <div>
                        <div>Для Вас згенеровано документ на
                            суму {toCalcSumm(props, props.paymentPopupGetData[1].bill)} грн.
                            ({props.paymentPopupGetData[1].bill.billNumber})
                        </div>
                        <div
                            className={style.popup_message_button}
                            onClick={
                                () => {
                                    pdfMake.createPdf(getFistElemJournalPDF(props, props.paymentPopupGetData[1].bill)).download('name.pdf')
                                }
                            }
                        >
                            Завантажити
                        </div>
                    </div>
                }
            </>
        )
    }else{
        return (
            <>
                <div>Для Вас згенеровано документ на суму {toCalcSumm(props, props.paymentPopupGetData[0].bill)} грн. ({props.paymentPopupGetData[0].bill.billNumber})</div>
                <div
                    className={style.popup_message_button}
                    onClick={
                        () => {
                            pdfMake.createPdf(getFistElemJournalPDF(props, props.paymentPopupGetData[0])).download('name.pdf')
                        }
                    }
                >
                    Завантажити
                </div>
            </>
        )
    }
}

function getFistElemJournalPDF(props, data){
    let tmp = {
        pageSize: 'A4',
        pageOrientation: 'portrait',
        content: [
            endJournal(props, data)
        ]
    }
    return tmp
}

function bankText(props){
    if(
        props.auction.sellingMethod === 'landRental-english' ||
        props.auction.sellingMethod === 'landRental-english-fast' ||
        props.auction.sellingMethod === 'landRental-english-fast-manual' ||
        props.auction.sellingMethod === 'landRental-english-fast-auction-manual-qualification' ||
        props.auction.sellingMethod === 'landRental-english-initial-auction' ||
        props.auction.sellingMethod === 'landRental-english-initial-auction-manual' ||
        props.auction.sellingMethod === 'landRental-english-initial-qualification' ||
        props.auction.sellingMethod === 'landRental-english-initial-qualification-prod' ||
        props.auction.sellingMethod === 'landSell-english' ||
        props.auction.sellingMethod === 'landSell-english-fast' ||
        props.auction.sellingMethod === 'landSell-english-fast-manual' ||
        props.auction.sellingMethod === 'landSell-english-fast-auction-manual-qualification' ||
        props.auction.sellingMethod === 'landSell-english-initial-auction' ||
        props.auction.sellingMethod === 'landSell-english-initial-auction-manual' ||
        props.auction.sellingMethod === 'landSell-english-initial-qualification' ||
        props.auction.sellingMethod === 'landSell-english-initial-qualification-prod' ||
        props.auction.sellingMethod === 'landSell-priorityEnglish' ||
        props.auction.sellingMethod === 'landSell-priorityEnglish-fast' ||
        props.auction.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
        props.auction.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
        props.auction.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
        props.auction.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
        props.auction.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
        props.auction.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod'
    ){
        return 'Банк: п/р UA503052990000026001016228049 в ПАТ КБ «ПРИВАТБАНК»'
    }else{
        if(Number(props.paymentPopupSetBanks) === 1){
            return 'Банк: п/р UA443052990000026007006219493 в ПАТ КБ «ПРИВАТБАНК»'
        }else if(Number(props.paymentPopupSetBanks) === 2){
            return 'Банк: п/р UA693803770000026007100660571 в АТ «УКРБУДІНВЕСТБАНК»'
        }else if(Number(props.paymentPopupSetBanks) === 3){
            return 'Банк: п/р UA593348510000000002600226265 в ПАТ «ПУМБ»'
        }else if(Number(props.paymentPopupSetBanks) === 4){
            return 'Банк: п/р UA703001190000026007112431002 в АТ "БАНК АЛЬЯНС"'
        }else if(Number(props.paymentPopupSetBanks) === 5){
            return 'Банк: п/р UA433390500000026005001136864 в АТ "КРИСТАЛБАНК"'
        }
    }
}

function dateText(props){
    let tmp = new Date;
    return 'від «' + tmp.getDate() + '» ' + textMonth(tmp.getMonth()) + ' ' + tmp.getFullYear() + ' р.'
}

function textMonth(data){
    switch(data){
        case 0:
            return 'січня'
        case 1:
            return 'лютого'
        case 2:
            return 'березня'
        case 3:
            return 'квітня'
        case 4:
            return 'травня'
        case 5:
            return 'червня'
        case 6:
            return 'липня'
        case 7:
            return 'серпня'
        case 8:
            return 'вересня'
        case 9:
            return 'жовтня'
        case 10:
            return 'листопада'
        case 11:
            return 'грудня'
    }
}

function dateTextEnd(props){
    let tmp = new Date;
    let tmpNew = Date.now(tmp) + ((((1000 * 60) * 60) * 24) * 10)
    let tmpEndDate = new Date(tmpNew)
    let day = tmpEndDate.getDate()
    let month = tmpEndDate.getMonth() + 1
    if(Number(day) < 10) day = '0' + day
    if(Number(month) < 10) month = '0' + month
    return day + '.' + month + '.' + tmpEndDate.getFullYear() + ' р.'
}

///////////////////////////////////////////////
//Страница
///////////////////////////////////////////////
function endJournal(props, data){
    let tmpDogovor = null
    let tmpDogovorDodatok = null
    if(data !== null){
        if(
            isLLE(props.auction.sellingMethod) || isLLD(props.auction.sellingMethod) || isLLP(props.auction.sellingMethod) ||
            isLRE(props.auction.sellingMethod) ||
            isALE(props.auction.sellingMethod) ||
            isCSE(props.auction.sellingMethod) || isCSD(props.auction.sellingMethod) ||
            isBSE(props.auction.sellingMethod) || isBSD(props.auction.sellingMethod) ||
            isLSE(props.auction.sellingMethod) || isLSP(props.auction.sellingMethod) ||
            isRLE(props.auction.sellingMethod) || isRLD(props.auction.sellingMethod) ||
            isCLE(props.auction.sellingMethod) || isCLD(props.auction.sellingMethod) ||
            isSPE(props.auction.sellingMethod) || isSPD(props.auction.sellingMethod) ||
            isLPE(props.auction.sellingMethod) ||
            isLAE(props.auction.sellingMethod) || isLAP(props.auction.sellingMethod) ||
            isNLE(props.auction.sellingMethod) || isNLD(props.auction.sellingMethod) ||
            isAPE(props.auction.sellingMethod) || isAPD(props.auction.sellingMethod)
        ){
            tmpDogovor = 'Додаток № ' + data.billNumber;
            tmpDogovorDodatok = 'до ДОГОВОРУ-ОФЕРТИ № ' + data.agreementNumber;
        }else{
            tmpDogovor = 'Додаток № ' + data.bill.billNumber;
            tmpDogovorDodatok = 'до ДОГОВОРУ-ОФЕРТИ № ' + data.bill.agreementNumber;
        }
    }else{
        tmpDogovor = 'Додаток № ';
        tmpDogovorDodatok = 'до ДОГОВОРУ-ОФЕРТИ № ';
    }
    let tmpTypeDoc = ''
    let tmpHelpDoc = ''
    let tmpHelpDoc2 = ''
    let tmpHelpDoc3 = ''
    if(
        isLLE(props.auction.sellingMethod) || isLLD(props.auction.sellingMethod) || isLLP(props.auction.sellingMethod) ||
        isLRE(props.auction.sellingMethod) ||
        isALE(props.auction.sellingMethod) ||
        isCSE(props.auction.sellingMethod) || isCSD(props.auction.sellingMethod) ||
        isBSE(props.auction.sellingMethod) || isBSD(props.auction.sellingMethod) ||
        isLSE(props.auction.sellingMethod) || isLSP(props.auction.sellingMethod) ||
        isRLE(props.auction.sellingMethod) || isRLD(props.auction.sellingMethod) ||
        isCLE(props.auction.sellingMethod) || isCLD(props.auction.sellingMethod) ||
        isSPE(props.auction.sellingMethod) || isSPD(props.auction.sellingMethod) ||
        isLPE(props.auction.sellingMethod) ||
        isLAE(props.auction.sellingMethod) || isLAP(props.auction.sellingMethod) ||
        isNLE(props.auction.sellingMethod) || isNLD(props.auction.sellingMethod) ||
        isAPE(props.auction.sellingMethod) || isAPD(props.auction.sellingMethod)
    ){
        switch (data.type) {
            case 1:
                tmpTypeDoc = `Оплата гарантійного внеску по торгам ${props.auction.auctionId}, учасник ${props.userProfiles.organization.name}, додаток № ${data.billNumber}, без ПДВ`
                tmpHelpDoc = ''
                tmpHelpDoc2 = ''
                tmpHelpDoc3 = 'Увага!!! Оплата рахунку здійснюється ВИКЛЮЧНО з рахунку учасника.'
                break
            case 2:
                tmpTypeDoc = `Оплата реєстраційного внеску по торгам ${props.auction.auctionId}, учасник ${props.userProfiles.organization.name}, додаток № ${data.billNumber}, без ПДВ`
                tmpHelpDoc = ''
                tmpHelpDoc2 = ''
                tmpHelpDoc3 = 'Увага!!! Оплата рахунку здійснюється ВИКЛЮЧНО з рахунку учасника.'
                break
            case 3:
                tmpTypeDoc = `Гарантійний внесок згідно з додатком № ${data.billNumber}`
                tmpHelpDoc = 'В призначенні платежу обов\'язково вказати номер додатку: ' + data.billNumber
                tmpHelpDoc2 = 'Оплата зараховується тільки від зазначеного в цьому додатку Учасника'
                tmpHelpDoc3 = 'Увага! Будь-яка особа, що сплачує за цим рахунком та реквізитами, вказаними у ньому, цим підтверджує, що вона діє в інтересах та за рахунок особи, зазначеної в цьому рахунку як «Учасник». Сплачені за цим рахунком грошові кошти будуть зараховані ТОВ «Держзакупівлі.Онлайн» на користь особи, зазначеної у цьому додатку в якості «Учасника»'
                break
            default:
                console.error("WRONG BILL TYPE")
        }
    }else{
        let type = data?.type ?? data?.bill?.type;
        switch (type) {
            case 1:
                tmpTypeDoc = `Оплата гарантійного внеску по торгам ${props.auction.auctionId}, учасник ${props.userProfiles.organization.name}, додаток № ${data.bill.billNumber}, без ПДВ`
                tmpHelpDoc = ''
                tmpHelpDoc2 = ''
                tmpHelpDoc3 = 'Увага!!! Оплата рахунку здійснюється ВИКЛЮЧНО з рахунку учасника.'
                break
            case 2:
                tmpTypeDoc = `Оплата реєстраційного внеску по торгам ${props.auction.auctionId}, учасник ${props.userProfiles.organization.name}, додаток № ${data.bill.billNumber}, без ПДВ`
                tmpHelpDoc = ''
                tmpHelpDoc2 = ''
                tmpHelpDoc3 = 'Увага!!! Оплата рахунку здійснюється ВИКЛЮЧНО з рахунку учасника.'
                break
            case 3:
                tmpTypeDoc = `Гарантійний внесок згідно з додатком № ${data.bill.billNumber}`
                tmpHelpDoc = 'В призначенні платежу обов\'язково вказати номер додатку: ' + data.bill.billNumber
                tmpHelpDoc2 = 'Оплата зараховується тільки від зазначеного в цьому додатку Учасника'
                tmpHelpDoc3 = 'Увага! Будь-яка особа, що сплачує за цим рахунком та реквізитами, вказаними у ньому, цим підтверджує, що вона діє в інтересах та за рахунок особи, зазначеної в цьому рахунку як «Учасник». Сплачені за цим рахунком грошові кошти будуть зараховані ТОВ «Держзакупівлі.Онлайн» на користь особи, зазначеної у цьому додатку в якості «Учасника»'
                break
            default:
                console.error("WRONG BILL TYPE")
        }
    }

// console.log(data)
    let tmpbillNumber = ''
    if(
        isLLE(props.auction.sellingMethod) || isLLD(props.auction.sellingMethod) || isLLP(props.auction.sellingMethod) ||
        isLRE(props.auction.sellingMethod) ||
        isALE(props.auction.sellingMethod) ||
        isCSE(props.auction.sellingMethod) || isCSD(props.auction.sellingMethod) ||
        isBSE(props.auction.sellingMethod) || isBSD(props.auction.sellingMethod) ||
        isLSE(props.auction.sellingMethod) || isLSP(props.auction.sellingMethod) ||
        isRLE(props.auction.sellingMethod) || isRLD(props.auction.sellingMethod) ||
        isCLE(props.auction.sellingMethod) || isCLD(props.auction.sellingMethod) ||
        isSPE(props.auction.sellingMethod) || isSPD(props.auction.sellingMethod) ||
        isLPE(props.auction.sellingMethod) ||
        isLAE(props.auction.sellingMethod) || isLAP(props.auction.sellingMethod) ||
        isNLE(props.auction.sellingMethod) || isNLD(props.auction.sellingMethod) ||
        isAPE(props.auction.sellingMethod) || isAPD(props.auction.sellingMethod)
    ){
        tmpbillNumber = data.billNumber
    }else{
        tmpbillNumber = data.bill.billNumber
    }

    let tmp = [
        {
            text: tmpDogovor,
            fontSize:12,
            alignment:'center',
            margin:[5, 5, 5, 5],
        },
        {
            text: tmpDogovorDodatok,
            fontSize:12,
            alignment:'center',
            margin:[5, 5, 5, 5],
        },
        {
            text: dateText(props),
            fontSize:12,
            alignment:'center',
            margin:[5, 5, 5, 5],
        },


        {
            columns: [
                {
                    width: 150,
                    fontSize:9,
                    text: 'Отримувач платежу'
                },
                {
                    width: '*',
                    fontSize:9,
                    text: 'ТОВ "Держзакупівлі.Онлайн"'
                },
            ]
        },
        {
            columns: [
                {
                    width: 150,
                    fontSize:9,
                    text: ''
                },
                {
                    width: '*',
                    fontSize:9,
                    text: 'ЄДРПОУ: 39008321'
                },
            ]
        },
        {
            columns: [
                {
                    width: 150,
                    fontSize:9,
                    text: ''
                },
                {
                    width: '*',
                    fontSize:9,
                    text: 'Київ, вул. Воздвиженська, 56, 04071'
                },
            ]
        },

        {
            columns: [
                {
                    width: 150,
                    fontSize:9,
                    text: ''
                },
                {
                    width: '*',
                    fontSize:9,
                    text: bankText(props)
                },
            ]
        },

        {
            columns: [
                {
                    width: 150,
                    fontSize:9,
                    text: ''
                },
                {
                    width: '*',
                    fontSize:9,
                    text: 'Платник податку на прибуток підприємств на загальних підставах'
                },
            ]
        },

        /*{
            columns: [
                {
                    width: 150,
                    fontSize:9,
                    text: ''
                },
                {
                    width: '*',
                    fontSize:9,
                    text: 'Платник ПДВ. ІПН: 390083226562'
                },
            ]
        },*/
        {
            columns: [
                {
                    width: 150,
                    fontSize:9,
                    text: ' '
                },
                {
                    width: '*',
                    fontSize:9,
                    text: ' '
                },
            ]
        },
        {
            columns: [
                {
                    width: 150,
                    fontSize:9,
                    text: 'Учасник'
                },
                {
                    width: '*',
                    fontSize:9,
                    text: props.userProfiles.organization.name
                },
            ]
        },

        {
            text: 'Рахунок-фактура № ' +  tmpbillNumber,
            fontSize:12,
            alignment:'center',
            margin:[5, 5, 5, 5],
        },
        {
            text: dateText(props),
            fontSize:12,
            alignment:'center',
            margin:[5, 5, 5, 5],
        },

        {
            table: {
                width: [50, 350, 50, 100, 100],
                border:0,
                body: [
                    [
                        '№',
                        {
                            alignment:'center',
                            text: 'Назва'
                        },
                        'Кількість',
                        'Ціна без ПДВ',
                        'Сума без ПДВ'
                    ],
                    ['1', tmpTypeDoc, '1', toCalcSummDown(props, data), toCalcSummDown(props, data)]
                ]
            }
        },
        {
            columns: [
                {
                    width: 300,
                    text: ''
                },
                {
                    width: 150,
                    text: 'Разом без ПДВ:'
                },
                {
                    width: '*',
                    alignment:'right',
                    text: toCalcSummDown(props, data)
                },
            ]
        },
        {
            columns: [
                {
                    width: 300,
                    text: ''
                },
                {
                    width: 150,
                    text: 'ПДВ:'
                },
                {
                    width: '*',
                    alignment:'right',
                    text: '0.00'
                },
            ]
        },
        {
            columns: [
                {
                    width: 300,
                    text: ''
                },
                {
                    width: 150,
                    text: 'Всього:'
                },
                {
                    width: '*',
                    alignment:'right',
                    text: toCalcSummDown(props, data)
                },
            ]
        },
        {
            text: numberToString(toCalcSummDown(props, data)),
            fontSize:10,
            alignment:'left',
            margin:[5, 5, 5, 5],
        },
        {
            text: 'Рахунок дійсний до сплати до ' + dateTextEnd(props),
            fontSize:10,
            alignment:'left',
            margin:[5, 5, 5, 5],
        },
        {
            columns: [
                {
                    width: 215,
                    text: 'Директор ТОВ "Держзакупівлі.Онлайн"',
                    margin:[5, 50, 5, 5],
                },
                {
                    image: signImg(),
                    width: 200,
                    heighy: 150,
                },
                {
                    width: '*',
                    text: 'В.В.Кольга',
                    margin:[5, 50, 5, 5],
                },
            ]
        },
        {
            text: tmpHelpDoc,
            fontSize:10,
            alignment:'left',
            margin:[5, 5, 5, 5],
        },
        {
            text: tmpHelpDoc2,
            fontSize:10,
            alignment:'left',
            margin:[5, 5, 5, 5],
        },
        {
            text: tmpHelpDoc3,
            fontSize:10,
            alignment:'left',
            margin:[5, 5, 5, 5],
        },
    ];
    return tmp
}

function toCalcSummDown(props, data){
    let tmpAmount = 0
    if(data !== null){
        if(
            isLLE(props.auction.sellingMethod) || isLLD(props.auction.sellingMethod) || isLLP(props.auction.sellingMethod) ||
            isLRE(props.auction.sellingMethod) ||
            isALE(props.auction.sellingMethod) ||
            isCSE(props.auction.sellingMethod) || isCSD(props.auction.sellingMethod) ||
            isBSE(props.auction.sellingMethod) || isBSD(props.auction.sellingMethod) ||
            isLSE(props.auction.sellingMethod) || isLSP(props.auction.sellingMethod) ||
            isRLE(props.auction.sellingMethod) || isRLD(props.auction.sellingMethod) ||
            isCLE(props.auction.sellingMethod) || isCLD(props.auction.sellingMethod) ||
            isSPE(props.auction.sellingMethod) || isSPD(props.auction.sellingMethod) ||
            isLPE(props.auction.sellingMethod) ||
            isLAE(props.auction.sellingMethod) || isLAP(props.auction.sellingMethod) ||
            isNLE(props.auction.sellingMethod) || isNLD(props.auction.sellingMethod) ||
            isAPE(props.auction.sellingMethod) || isAPD(props.auction.sellingMethod)
        ){
            tmpAmount = data.amount
        }else{
            tmpAmount = data.bill.amount
        }
        if(String(tmpAmount).indexOf('.') === true){
            return tmpAmount
        }else if(String(tmpAmount).indexOf(',') === true){
            return Number(tmpAmount).toFixed(2)
        }else{
            return Number(tmpAmount).toFixed(2)
        }
    }else{
        return ''
    }
}

function numberToString(value){
    let tmpDot = String(value).includes('.')
    let tmpDotZ = String(value).includes(',')
    let arrT = null
    let valueThousands = 0
    let valueMillions = 0
    let valueBillion = 0

    if(tmpDot === true){
        arrT = String(value).split('.')
        value = arrT[0]
    }
    if(tmpDotZ === true){
        arrT = String(value).split(',')
        value = arrT[0]
    }

    const arr0 = [
        'одна', 'дві', 'три', 'чотири', 'п\'ять',
        'шість', 'сім', 'вісім', 'дев\'ять', 'десять',
    ]

    const arr0m = [
        'один', 'два', 'три', 'чотири', 'п\'ять',
        'шість', 'сім', 'вісім', 'дев\'ять', 'десять',
    ]

    const arr1 = [
        'одинадцять', 'дванадцять', 'тринадцать', 'чотирнадцять', 'п\'ятнадцять',
        'шістнадцять', 'сімнадцять', 'вісімнадцять', 'дев\'ятнадцать',
    ]
    const arr2 = [
        'двадцять', 'тридцять', 'сорок', 'п\'ятдесят',
        'шістдесят', 'сімдесят', 'вісімдесят', 'дев\'яносто'
    ]
    const arr3 = [
        'сто', 'двісті', 'триста', 'чотириста', 'п\'ятсот',
        'шістсот', 'сімсот', 'вісімсот', 'девя\'тсот'
    ]
    let tmp = ''
    valueBillion = value
    if(value > 999999999 && value < 10999999999){
        if(value > 9999999999){
            let tmpSimbol = String(value).slice(0, 2)
            tmp += ' ' + textNumber(tmpSimbol, arr0m, 1)
            value = String(value).slice(2, String(value).length)
        }else{
            let tmpSimbol = String(value).slice(0, 1)
            tmp += ' ' + textNumber(tmpSimbol, arr0m, 1)
            value = String(value).slice(1, String(value).length)
        }
        tmp += ` ${manyBillion(valueBillion)} `
    }
    valueMillions = value
    if(value > 999999 && value < 1000000000){
        if(value > 99999999 && value < 1000000000){
            let tmpSimbol = String(value).slice(0, 1)
            tmp += ' ' + textNumber(tmpSimbol, arr3, 1)
            value = String(value).slice(1, String(value).length)
        }
        if(value > 19999999 && value < 100000000){
            let tmpSimbol = String(value).slice(0, 1)
            tmp += ' ' + textNumber(tmpSimbol, arr2, 2)
            value = String(value).slice(1, String(value).length)
        }
        if(value > 10999999 && value < 20000000){
            let tmpSimbol = String(value).slice(0, 2)
            tmp += ' ' + textNumber(tmpSimbol, arr1, 11)
            value = String(value).slice(2, String(value).length)
        }
        if(value > 999999 && value < 10999999){
            if(value > 9999999){
                let tmpSimbol = String(value).slice(0, 2)
                tmp += ' ' + textNumber(tmpSimbol, arr0m, 1)
                value = String(value).slice(2, String(value).length)
            }else{
                let tmpSimbol = String(value).slice(0, 1)
                tmp += ' ' + textNumber(tmpSimbol, arr0m, 1)
                value = String(value).slice(1, String(value).length)
            }
        }
        tmp += ` ${manyMillions(valueMillions)} `
    }
    valueThousands = value
    if(Number(value) > 999 && Number(value) < 1000000){
        if(Number(value) > 99999 && Number(value) < 1000000){
            let tmpNumber = Number(value)
            let tmpSimbol = String(tmpNumber).slice(0, 1)
            tmp += ' ' + textNumber(tmpSimbol, arr3, 1)
            value = String(value).slice(1, String(value).length)
        }
        if(Number(value) > 19999 && Number(value) < 100000){
            let tmpNumber = Number(value)
            let tmpSimbol = String(tmpNumber).slice(0, 1)
            tmp += ' ' + textNumber(tmpSimbol, arr2, 2)
            value = String(value).slice(1, String(value).length)
        }
        if(Number(value) > 10999 && Number(value) < 20000){
            //let tmpSimbol = String(value).slice(0, 2)
            let tmpNumber = Number(value)
            let tmpSimbol = String(tmpNumber).slice(0, 2)
            tmp += ' ' + textNumber(tmpSimbol, arr1, 11)
            value = String(value).slice(2, String(value).length)
        }
        if(Number(value) > 999 && Number(value) < 11000){
            if(Number(value) > 9999){
                //let tmpSimbol = String(value).slice(0, 2)
                let tmpNumber = Number(value)
                let tmpSimbol = String(tmpNumber).slice(0, 2)
                tmp += ' ' + textNumber(tmpSimbol, arr0, 1)
                value = String(value).slice(2, String(value).length)
            }else{
                //let tmpSimbol = String(value).slice(0, 1)
                let tmpNumber = Number(value)
                let tmpSimbol = String(tmpNumber).slice(0, 1)
                tmp += ' ' + textNumber(tmpSimbol, arr0, 1)
                value = String(value).slice(1, String(value).length)
                if(value.length === 4){
                    value = value[1] + value[2] + value[3]
                }
            }
        }
        tmp +=  ` ${manyThousands(valueThousands)}`
    }
    if(Number(value) > 99 && Number(value) < 1000){
        let tmpNumber = Number(value)
        let tmpSimbol = String(tmpNumber).slice(0, 1)
        tmp += ' ' + textNumber(tmpSimbol, arr3, 1)
        value = String(value).slice(1, String(value).length)
        if(value.length === 4){
            value = value[2] + value[3]
        }else if(value.length === 3){
            value = value[1] + value[2]
        }
        // if(value === '100'){
        //     value = String(value).slice(1, String(value).length)
        // }
    }
    if(Number(value) > 19 && Number(value) < 100){
        let tmpSimbol = Number(value)
        tmpSimbol = String(tmpSimbol).slice(0, 1)
        tmp += ' ' + textNumber(tmpSimbol, arr2, 2)
        value = String(value).slice(1, String(value).length)
        value = Number(value)
        if(value > 9){
            value = String(value)[1]
        }
    }
    if(Number(value) > 10 && Number(value) < 20){
        //tmp += ' ' + textNumber(value, arr1, 11)
        let tmpSimbol = Number(value)
        //tmpSimbol = String(tmpSimbol).slice(0, 1)
        tmpSimbol = String(tmpSimbol)[1]
        tmp += ' ' + textNumber(tmpSimbol, arr1, 1)
        if(Number(value) < 10){
            value = String(value).slice(1, String(value).length)
            value = Number(value)
            if(value > 9){
                value = String(value)[1]
            }
        }
    }
    if(Number(value) > 0 && Number(value) < 11){
        if(Number(value) === 10){
            let tmpSimbol = Number(value)
            tmpSimbol = String(tmpSimbol).slice(0, 2)
            tmp += ' ' + textNumber(tmpSimbol, arr0, 1)
        }else{
            let tmpSimbol = Number(value)
            tmpSimbol = String(tmpSimbol).slice(0, 1)
            tmp += ' ' + textNumber(tmpSimbol, arr0, 1)
        }
    }
    if(tmpDot === true || tmpDotZ === true){
        tmp += ` ${manyUAH(value)} ${arrT[1]} ${smalMany(Number(arrT[1]))}`
    }else{
        tmp += ` ${manyUAH(value)} 00 копійок`
    }
    return tmp
}

function smalMany(data){
    if(
        Number(data) === 11 ||
        Number(data) === 12 ||
        Number(data) === 13 ||
        Number(data) === 14
    ){
        return 'копійок'
    }else{
        let tmp = data.toString().slice(-1);
        if(Number(tmp) === 1){
            return 'копійка'
        }else if(
            Number(tmp) === 2 ||
            Number(tmp) === 3 ||
            Number(tmp) === 4
        ){
            return 'копійки'
        }else{
            return 'копійок'
        }
    }
}

function manyUAH(data){
    if(
        Number(data) === 11 ||
        Number(data) === 12 ||
        Number(data) === 13 ||
        Number(data) === 14
    ){
        return 'гривень'
    }else{
        let tmp = data.toString().slice(-1);
        if(Number(tmp) === 1){
            return 'гривня'
        }else if(
            Number(tmp) === 2 ||
            Number(tmp) === 3 ||
            Number(tmp) === 4
        ){
            return 'гривні'
        }else{
            return 'гривень'
        }
    }
}

function manyThousands(data){
    if(
        Number(data) >= 11000 && Number(data) <= 15000
    ){
        return 'тисяч'
    }else{
        if(data.toString().length >= 5){
            let tmp = data.toString().slice(-5);
            if(Number(tmp) >= 11000 && Number(tmp) <= 15000){
                return 'тисяч'
            }else{
                let tmp2 = data.toString().slice(-4);
                if(Number(tmp2) >= 1000 && Number(tmp2) < 2000){
                    return 'тисяча'
                }else if(Number(tmp2) >= 2000 && Number(tmp2) < 5000){
                    return 'тисячі'
                }else{
                    return 'тисяч'
                }
            }
        }else{
            let tmp3 = data.toString().slice(-4);
            if(Number(tmp3) >= 1000 && Number(tmp3) < 2000){
                return 'тисяча'
            }else if(Number(tmp3) >= 2000 && Number(tmp3) < 5000){
                return 'тисячі'
            }else{
                return 'тисяч'
            }
        }
    }
}

function manyMillions(data){
    if(
        data >= 11000000 && data <= 15000000
    ){
        return 'мільйонів'
    }else{
        if(data.toString().length >= 8){
            let tmp = data.toString().slice(-8);
            if(Number(tmp) >= 11000000 && Number(tmp) <= 15000000){
                return 'мільйонів'
            }else{
                let tmp2 = data.toString().slice(-7);
                if(Number(tmp2) >= 1000000 && Number(tmp2) < 2000000){
                    return 'мільйон'
                }else if(Number(tmp2) >= 2000000 && Number(tmp2) <= 5000000){
                    return 'мільйони'
                }else{
                    return 'мільйонів'
                }
            }
        }else{
            let tmp3 = data.toString().slice(-7);
            if(Number(tmp3) >= 1000000 && Number(tmp3) < 2000000){
                return 'мільйон'
            }else if(Number(tmp3) >= 2000000 && Number(tmp3) <= 5000000){
                return 'мільйони'
            }else{
                return 'мільйонів'
            }
        }
    }
}

function manyBillion(data){
    if(
        data >= 11000000000 && data <= 15000000000
    ){
        return 'мільярдів'
    }else{
        if(data.toString().length >= 11){
            let tmp = data.toString().slice(-11);
            if(Number(tmp) >= 11000000000 && Number(tmp) <= 15000000000){
                return 'мільйонів'
            }else{
                let tmp2 = data.toString().slice(-10);
                if(Number(tmp2) >= 1000000000 && Number(tmp2) < 2000000000){
                    return 'мільярд'
                }else if(Number(tmp2) >= 2000000000 && Number(tmp2) <= 5000000000){
                    return 'мільярди'
                }else{
                    return 'мільярдів'
                }
            }
        }else{
            let tmp3 = data.toString().slice(-10);
            if(Number(tmp3) >= 1000000000 && Number(tmp3) < 2000000000){
                return 'мільярд'
            }else if(Number(tmp3) >= 2000000000 && Number(tmp3) <= 5000000000){
                return 'мільярди'
            }else{
                return 'мільярдів'
            }
        }
    }
}

function textNumber(value, arr, count){
    if(value !== '0'){
        return arr[Number(value) - count]
    }else{
        return ''
    }
}

const mapStateToProps = state => {
    return {
        //auction: state.start.auctionsGetId,
        auction: state.start.auctionFull,
        paymentPopup: state.start.paymentPopup,
        paymentPopupCreatePDF: state.start.paymentPopupCreatePDF,
        paymentPopupGetData: state.start.paymentPopupGetData,
        paymentPopupSetBanks: state.start.paymentPopupSetBanks,
        userProfiles: state.start.userProfiles,
    }
}

const mapDispatchToProps = {
    paymentPopup,
    paymentPopupCreatePdf,
    paymentPopupSetBank,
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPopUp)