import React from 'react';
import styles from './windowBid.module.css';

import {connect} from 'react-redux';

import {
    changeAuctionsSwitchWindow,
    //auctionsGetMy,
    setPopUpAuctionMyNull,
} from '../../../redux/actions/auctions/auctions.js';
import {auctionsBidsGetMy} from  '../../../redux/actions/auctionBid/auctionBid.js';
import {changeCreateAuctionTab} from '../../../redux/actions.js';

import 'date-fns';
/*import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';*/

//import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
//import Select from '@material-ui/core/Select';

//import ReturnButton from '../returnButton'

const WindowBid = ( props ) => {
    let tmp = null;
    if(props.getMyCurentBid !== null && props.pageNumber !== null){
        tmp = (
            <>
                <div className={styles.textLot}>Id ставки: <span>{props.getMyCurentBid.id}</span></div>
                <div className={styles.textLot}>Статус ставки <span>{props.getMyCurentBid.status}</span></div>
                <div className={styles.textLot}>Тип аукціона: <span>{
                    props.getMyCurentBid.classification.scheme + ' ' +
                    props.getMyCurentBid.classification.classificationId + ' ' +
                    props.getMyCurentBid.classification.description
                    }</span>
                </div>
                <div
                    className={styles.textLot}
                    onClick={
                        ()=>{
                            let data = {
                                domen: process.env.REACT_APP_END_POINT_BD,
                                version: 'v1',
                                local: props.langFlag,
                                params: '?limit=100'
                            }
                            props.auctionsBidsGetId(data, props.token.access_token, props.pageNumber)
                        }
                    }
                >
                    <a href={`${window.location.protocol}tenders/${props.pageNumber}`}>Посилання на аукціон</a>
                </div>
                <div>
                    <div className={styles.textLot}>Додані документи:</div>
                    <div>
                        {createDocumentsList(props)}
                    </div>
                </div>

                <div className={styles.bottonBlock}>
                    <Button
                        className={styles.buttonExitDocument}
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                //props.setPopUpAuctionMyNull()
                                props.changeCreateAuctionTab(0);
                                props.changeAuctionsSwitchWindow(0)
                                
                                let data = {
                                    domen: process.env.REACT_APP_END_POINT_BD,
                                    version: 'v1',
                                    local: props.langFlag,
                                    params: '?limit=100'
                                }
                                let header = {
                                    'Authorization': props.token.access_token,
                                    'Content-Type': 'application/json'
                                }
                                props.auctionsBidsGetMy(data, header)
                            }
                        }
                    >
                        Повернутися до списку ставок
                    </Button>
                </div>
            </>
        )
    }
    return tmp
}

function createDocumentsList(props){
    let tmp = null
    if(props.getMyCurentBid !== null){
        if(props.getMyCurentBid.documents.length > 0){
            tmp = props.getMyCurentBid.documents.map(
                (i) => {
                    return (
                        <div className={styles.documentBlock}>
                            <div className={styles.textLot}>id документа <span>{i.id}</span></div>
                            <div className={styles.textLot}>Назва документа <span>{i.title}</span></div>
                            <div className={styles.textLot}>Опис документа <span>{i.description}</span></div>
                            <div className={styles.textLot}>Тип документа <span>{i.documentType}</span></div>
                            <div className={styles.textLot}>Посилання на документ <span>{i.url}</span></div>
                        </div>
                    )
                }
            )
        }
    }
    return tmp;
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        auctionsMy: state.start.auctionsMy,
        getMyCurentBid: state.start.getMyCurentBid,
        pageNumber: state.start.pageNumber,
    }
}

const mapDispatchToProps = {
    changeAuctionsSwitchWindow,
    auctionsBidsGetMy,
    changeCreateAuctionTab,
    setPopUpAuctionMyNull,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowBid)
