import React from 'react';
import style from './createAuction.module.css';

import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {
    getMyAuction,
    setEditAuctionFromId,
} from '../../../../redux/actions.js';

import {setPopUpAuctionCreate, changeAuctionsSwitchWindow, setPopUpAuctionMyNull, auctionsGetMy} from '../../../../redux/actions/auctions/auctions.js';

import {
    changeDiscountPercent,
    changeDiscountPerviousAuctionCurrency,
    changeDiscountPerviousAuctionAmount,
    changeDiscountPerviousAuctionId
} from '../../../../redux/actions/discount/discount.js';

import {auctionsGetAwards, auctionsGetAwardsId} from '../../../../redux/actions/awards/awards.js';
import {getAuctionsBidsId} from '../../../../redux/actions/auctionBid/auctionBid.js';
import {contractsAuctionIdGet} from '../../../../redux/actions/contracts/contracts.js';
import {
    setPopBankAccountId,
    setPopBankAccountIdArr,
    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,
} from '../../../../redux/actions/bankAccount/bankAccount.js';
import {setUniversalError} from '../../../../redux/actions.js';
import {
    changeLoader,
} from '../../../../redux/actions.js';

import {
    setDataAddress,
} from '../../../../redux/createAddress';


import ReturnButton from '../../../createAuction/returnButton'

const AuctionCreate = (props) => {
    return (
        <div className={style.popup_wrap}>
            <div className={style.popup_message}>
                <div>Аукціон створено</div>
                {buttonForAuctionPopUp(props)}
            </div>
        </div>
    )
}

function textForAuctionPopUp(props){
    let tmp = null;
    if(props.popUpAuctionCreateMonga === 1){
        tmp = 'Помилка при створенні аукціона';
    }else if(props.popUpAuctionCreateMonga === 2){
        tmp = 'Зміни в аукціоні збережено';
    }else{
        tmp = 'Аукціон створено';
    }
    return tmp
}

function buttonForAuctionPopUp(props){
    return (
        <>
            <ReturnButton />
            <Link
                to={`/create_auctions/editNew/${props.popUpAuctionCreate.id}`}
                className={style.buttons}
                onClick={
                    () => {
                        props.changeLoader(true)
                        setTimeout(() => 
                        {
                            auctionEditSync(props)
                        }, 1000)
                    }
                }
            >
                Продовжити редагування аукціону
            </Link>
        </>
    )
}

async function auctionEditSync(props){
    props.setDataAddress(null, 'POPUP_AUCTION_MONGA')
    props.changeLoader(false)
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy,
        auctionAwards: state.start.auctionAwards,
        popUpAuctionCreate: state.start.popUpAuctionCreateMonga,
    }
}

const mapDispatchToProps = {
    setPopUpAuctionCreate,
    changeAuctionsSwitchWindow,
    setPopUpAuctionMyNull,
    auctionsGetMy,

    getMyAuction,
    changeDiscountPercent,
    changeDiscountPerviousAuctionCurrency,
    changeDiscountPerviousAuctionAmount,
    changeDiscountPerviousAuctionId,

    auctionsGetAwards,
    auctionsGetAwardsId,

    getAuctionsBidsId,
    contractsAuctionIdGet,
    setPopBankAccountId,
    setPopBankAccountIdArr,
    
    setUniversalError,
    setEditAuctionFromId,

    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,

    changeLoader,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(AuctionCreate)