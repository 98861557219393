import React from 'react';
import styles from './auctionDataLine_Cod.module.css';

import {connect} from 'react-redux';

const AuctionDataLine_Cod = ( props ) => {
    return <div className={styles.secondBlock_textPrice}>Код {viewClassificationForShemes(props)} - Продукція лісівництва та лісозаготівлі</div>
}

function viewClassificationForShemes(props){
    let tmp = null;
    let tmp2 = null;
    if(props.item.hasOwnProperty('items') === true){
        if(props.item.items[0].length > 0){
            if(props.item.items[0].hasOwnProperty('classification') === true){
                if(props.item.items[0].classification.hasOwnProperty('scheme') === true){
                    tmp = props.item.items[0].classification.scheme
                }
                if(props.item.items[0].classification.hasOwnProperty('classificationId') === true){
                    tmp2 = props.item.items[0].classification.classificationId
                }
            }
        }
    }

    return (tmp + tmp2)
}


const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
    }
}

export default connect(mapStateToProps, null)(AuctionDataLine_Cod)