import { ChangeHistorySharp } from '@material-ui/icons';
import React,{useState, useEffect} from 'react';
import styles from './popDownBlock.module.css';

const PopDownBlock = (props) => {
    const [popDown, setPopDown] = useState(props.open ? true : false)
    const [state, setState] = useState({ cont: styles.popDownContent, svg: styles.svgChevron })
    let i = 0

    useEffect(() => {
        popDownToggle()
    }, [popDown])

    function popDownToggle() {
        if (popDown) {
            setState({ cont: styles.popDownContent, svg: styles.svgChevUP })
        } else {
            setState({ cont: styles.hidden, svg: styles.svgChevron })
        }
    }

        return (
            <div className={styles.popDownBlock}>
                <div className={styles.popDownTitle}
                    onClick={() => setPopDown(!popDown)}>
                    {props.title}
                    <svg className={state.svg} width="12" height="7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 7">
                        <g id="Слой_2" data-name="Слой 2">
                            <g id="Слой_1-2" data-name="Слой 1">
                                <path className={styles.svgIcon} fill="#92929d" fillRule="evenoddd" d="M6,4.59,10.29.29a1,1,0,1,1,1.42,1.42l-5,5a1,1,0,0,1-1.42,0l-5-5A1,1,0,0,1,1.71.29Z" />
                            </g>
                        </g>
                    </svg>
                </div>
                <div className={state.cont}>
                    {props.content}
                </div>
            </div>
        )
}

export default PopDownBlock