import React from 'react';
import styles from './itemsCard.module.css';

import {connect} from 'react-redux';
import { Link } from 'react-router-dom';

import {modifiDateString} from '../../../../redux/modifiDate.js'
import { setDataAddress } from '../../../../redux/createAddress';
import { isTIE, isSUE, isSUD, isRCE, isRCD, isGFE, isGFD, isLLE, isLLD,
    isLLP, isLRE, isLSE, isLSP, isRMA, isBSE, isBSD, isALE, isCSE, isCSD,
    isBRE, isBRD, isBRW, isRLE, isRLD, isCLE, isCLD, isAPE, isAPD
 } from '../../../../redux/aucTypes'

import { getAuctionsStatus } from "../../../../api/dictonaries/statusAuctions"

const ItemsCard = ( props ) => {
    let tmpTenderPeriodTo = null
    let tmpTenderPeriodToHours = null
    let tmpAuctionPeriodTo = null
    let tmpAuctionPeriodToHours = null
    if(props.item !== null){
        //if(props.item.hasOwnProperty('auctionPeriods')){
            //if(props.item.auctionPeriods !== null){
                //for(let i = 0; i < props.item.auctionPeriods.length; i++){
                    //if(props.item.auctionPeriods[i].hasOwnProperty('status')){
                    if(props.item.hasOwnProperty('tenderPeriod')){
                        if(props.item.tenderPeriod !== null){
                            if(props.item.tenderPeriod.hasOwnProperty('endDate')){
                                if(props.item.tenderPeriod.endDate !== null){
                                    tmpTenderPeriodTo = modifiDateString(props.item.tenderPeriod.endDate, 1)
                                    tmpTenderPeriodToHours = modifiDateString(props.item.tenderPeriod.endDate, 2)
                                }
                            }
                        }
                    }

                    if(props.item.hasOwnProperty('auctionPeriod')){
                        if(props.item.auctionPeriod !== null){
                            if(props.item.auctionPeriod.hasOwnProperty('startDate')){
                                if(props.item.auctionPeriod.startDate !== null){
                                    tmpAuctionPeriodTo = modifiDateString(props.item.auctionPeriod.startDate, 1)
                                    tmpAuctionPeriodToHours = modifiDateString(props.item.auctionPeriod.startDate, 2)
                                }
                            }
                        }
                    }
                //}
            //}
        //}
    }
    let tmpSumm = null
    let tmpPDV = null
    if(props.item !== null){
        if(props.item.hasOwnProperty('value')){
            if(props.item.value !== null){
                if(props.item.value.hasOwnProperty('amount')){
                    if(props.item.value.amount !== null){
                        tmpSumm = getValue(props.item.value.amount)
                    }
                }
                if(props.item.value.hasOwnProperty('valueAddedTaxIncluded')){
                    if(props.item.value.valueAddedTaxIncluded !== null){
                        tmpPDV = getPDV(props.item.value.valueAddedTaxIncluded)
                    }
                }
            }
        }
    }

    let tmpSellingEntityName = null
    if(props.item.hasOwnProperty('sellingEntity')){
        if(props.item.sellingEntity !== null){
            if(props.item.sellingEntity.hasOwnProperty('name')){
                tmpSellingEntityName = props.item.sellingEntity.name.uk_UA
            }
        }
    }
    return (
        <div className={styles.wrap}>
            <div className={styles.leftBlock}>
                {viewImg(props)}
            </div>
            <div className={styles.centralBlock}>
                <div className={styles.topBlock}>
                    <div className={styles.aucIdAndType}>
                        <div className={styles.aucIdAndType_id}>
                            {props.item.auctionId}
                            {viewBageYouAuction(props.item.createdByCurrentUser)}
                        </div>
                        {viewTypeAuction(props.item.sellingMethod)}
                    </div>
                    {props.item.sellingMethod.startsWith('legacy') ?
                        <a href={`https://prozorro.sale/auction/${props.item.auctionId}`} target="_blank">
                            <div className={styles.aucTitle}>{viewTitle(props)}</div>
                        </a>
                        :
                        <Link
                            to={`/auctions/${props.item.auctionId}`}
                            onClick={
                                () => {
                                    getPublicApiAuction(props, props.item.auctionId)
                                    props.setDataAddress(props.item.auctionId, 'SET_ID_FULL_AUCTION')
                                }
                            }
                        >
                            <div className={styles.aucTitle}>{viewTitle(props)}</div>
                        </Link>
                    }
                    <div className={styles.aucDiscription}>{viewDescription(props)}</div>
                    {props.item.sellingMethod.startsWith('legacy') ?
                        <a href={`https://prozorro.sale/auction/${props.item.auctionId}`} target="_blank">
                            <div className={styles.aucMore}>Більше</div>
                        </a>
                        :
                        <Link
                            to={`/auctions/${props.item.auctionId}`}
                            onClick={
                                () => {
                                    getPublicApiAuction(props, props.item.auctionId)
                                    props.setDataAddress(props.item.auctionId, 'SET_ID_FULL_AUCTION')
                                }
                            }
                        >
                            <div className={styles.aucMore}>Більше</div>
                        </Link>
                    }
                </div>
                <div className={styles.bottomBlock}>
                    <div className={styles.aucClassificatorCode}>Організатор</div>
                    <div className={styles.aucClassificatorName}>{tmpSellingEntityName}</div>
                </div>
            </div>
            <div className={styles.rightBlock}>
                <div className={styles.rightBlock_Status}>
                    {getAuctionsStatus(props.item.sellingMethod, props.item.status)}
                </div>
                <div className={styles.rightBlock_Date}>
                    <div className={styles.rightBlock_Start}>
                        <div className={styles.rightBlock_date_text}>Пропозиції до</div>
                        <div className={styles.rightBlock_date_time}>{tmpTenderPeriodTo}
                            <span className={styles.rightBlock_date_timeSpan}> {tmpTenderPeriodToHours}</span>
                        </div>
                    </div>
                    <div className={styles.rightBlock_Start}>
                        <div className={styles.rightBlock_date_text}>Старт аукціону</div>
                        <div className={styles.rightBlock_date_time}>{tmpAuctionPeriodTo}
                            <span className={styles.rightBlock_date_timeSpan}> {tmpAuctionPeriodToHours}</span>
                        </div>
                    </div>
                </div>
                <div className={styles.rightBlock_CostAndButtons}>
                    <div className={styles.costText}>Початкова ціна лоту{tmpPDV}
                    </div>
                    <div className={styles.costSumm}>{tmpSumm} грн</div>
                    {viewButtons(props)}
                </div>
            </div>
        </div>
    )
}

function viewBageYouAuction(data){

    if(data === true){
        return <div className={styles.badgeYellow}>Ваш аукціон</div>
    }
    return null
}

function viewTitle(props){
    let tmp = null;
    let tmpViewTitle = null
    if(props.item.hasOwnProperty('title') === true){
        tmp = props.item.title.uk_UA
        if(tmp.length <= 151){
            tmpViewTitle = tmp
        }else{
            tmpViewTitle = tmp.substr(0, 151) + ' ...'
        }
    }
    return tmpViewTitle
}

function viewDescription(props){
    let tmp = null;
    let tmpViewDescription = null
    if(props.item.hasOwnProperty('description') === true){
        if(props.item.description !== null){
            tmp = props.item.description.uk_UA
            if(tmp.length <= 200){
                tmpViewDescription = tmp
            }else{
                tmpViewDescription = tmp.substr(0, 200) + ' ...'
            }
        }
    }
    return tmpViewDescription
}

function viewButtons(props){
    if(props.userProfiles !== null){
        if(props.userProfiles.canCreateBid === true && (props.item.status === 'active_tendering' || props.item.status === 'active_auction')){
            return (
                <div className={styles.buttonsLine}>
                    <div className={styles.buttonsLineFavorites}>
                        <svg width="24" height="24" className={styles.svg_fav}>
                            <path fillRule="evenodd" clipRule="evenodd" 
                                d="M6.55459 21.8321C5.83537 22.3115 4.8877 21.7143 5.00994 20.8586L5.93923 14.3536L1.29278 9.70711C0.710095 9.12442 1.04271 8.12659 1.85847 8.01005L8.30212 7.08953L11.0807 0.606081C11.4271 -0.202027 12.5727 -0.202027 12.919 0.606081L15.6977 7.08953L22.1413 8.01005C22.9571 8.12659 23.2897 9.12442 22.707 9.70711L18.0606 14.3536L18.9898 20.8586C19.1121 21.7143 18.1644 22.3115 17.4452 21.8321L11.9999 18.2019L6.55459 21.8321ZM7.30614 18.9273L11.4452 16.1679C11.7811 15.944 12.2187 15.944 12.5546 16.1679L16.6936 18.9273L16.0099 14.1414C15.9654 13.8298 16.0702 13.5155 16.2928 13.2929L19.8786 9.70711L14.8585 8.98995C14.513 8.94059 14.2182 8.71469 14.0807 8.39392L11.9999 3.53859L9.91903 8.39392C9.78156 8.71469 9.4868 8.94059 9.14131 8.98995L4.12121 9.70711L7.707 13.2929C7.92956 13.5155 8.03435 13.8298 7.98984 14.1414L7.30614 18.9273Z"
                                fill="#92929D" />
                        </svg>
                    </div>
                    <Link
                        to={`/auctions/${props.item.auctionId}`}
                        onClick={
                            () => {
                                getPublicApiAuction(props, props.item.auctionId)
                                props.setDataAddress(props.item.auctionId, 'SET_ID_FULL_AUCTION')
                            }
                        }
                    >
                        <div className={styles.buttonGo}>Взяти участь</div>
                    </Link>
                </div>
            )
        }else{
            return (
                <div 
                    className={styles.buttonsLine}
                    onClick={
                        () => {
                            getPublicApiAuction(props, props.item.auctionId)
                            props.setDataAddress(props.item.auctionId, 'SET_ID_FULL_AUCTION')
                        }
                    }
                >
                    <Link
                        to={`/auctions/${props.item.auctionId}`}
                        onClick={
                            () => {
                                getPublicApiAuction(props, props.item.auctionId)
                                props.setDataAddress(props.item.auctionId, 'SET_ID_FULL_AUCTION')
                            }
                        }
                    >
                        <div className={styles.buttonGoOff}>Переглянути</div>
                    </Link>
                </div>
            )
        }
    }else{
        return (
            <div 
                className={styles.buttonsLine}
                onClick={
                    () => {
                        getPublicApiAuction(props, props.item.auctionId)
                        props.setDataAddress(props.item.auctionId, 'SET_ID_FULL_AUCTION')
                    }
                }
            >
                {props.item.sellingMethod.startsWith('legacy')
                    ?
                    <a href={`https://prozorro.sale/auction/${props.item.auctionId}`} target="_blank">
                        <div className={styles.buttonGoOff}>Переглянути в архіві</div>
                    </a>
                    :
                    <Link
                        to={`/auctions/${props.item.auctionId}`}
                        onClick={
                            () => {
                                getPublicApiAuction(props, props.item.auctionId)
                                props.setDataAddress(props.item.auctionId, 'SET_ID_FULL_AUCTION')
                            }
                        }
                    >
                        <div className={styles.buttonGoOff}>Переглянути</div>
                    </Link>
                }
            </div>
        )
    }
}

function viewImg(props){
    let tmpSummImg = 0
    if(props.item !== null){
        if(props.item.hasOwnProperty('documents')){
            if(props.item.documents !== null){
                for(let i = 0; i < props.item.documents.length; i++){
                    if(props.item.documents[i].documentType === 'illustration'){
                        tmpSummImg += 1
                    }
                }
                for(let i = 0; i < props.item.documents.length; i++){
                    if(props.item.documents[i].documentType === 'illustration'){
                        return (
                            <>
                                <div className={styles.avatar}>
                                    <img src={props.item.documents[i].url} alt="user" />
                                </div>
                                <div className={styles.sumFoto}>
                                    {tmpSummImg}<br />фото
                                </div>
                            </>
                        )
                    }
                }
            }
        }
    }
    return (
        <div className={styles.unactive}>
            <img src="" alt="" />
        </div>
    )
}

function getPDV(data){
    if(data === true){
        return ', з ПДВ'
    }else{
        return ', без ПДВ'
    }
}

function getValue(data){
    let amount = String(data);
    let tmp = null
    if(amount !== null){
        if(amount.indexOf('.') !== -1){
            amount = amount.split('.');
            tmp = String(amount[0]);
            tmp = tmp.split("").reverse().join("");
            let tmp1 = [];
            for(let i = 0; i < tmp.length; i++){
                if(((i+1)%3) === 0){
                    if(i !== tmp.length-1){
                        tmp1[i] = tmp[i] + ' ';
                    }else{
                        tmp1[i] = tmp[i];
                    }
                }else{
                    tmp1[i] = tmp[i];
                }
            }
            tmp = tmp1.join('')
            tmp = tmp.split("").reverse().join("");
            amount = amount[1];
        }else{
            tmp = String(amount);
            tmp = tmp.split("").reverse().join("");
            let tmp1 = [];
            for(let i = 0; i < tmp.length; i++){
                if(((i+1)%3) === 0){
                    if(i !== tmp.length-1){
                        tmp1[i] = tmp[i] + ' ';
                    }else{
                        tmp1[i] = tmp[i];
                    }
                }else{
                    tmp1[i] = tmp[i];
                }
            }
            tmp = tmp1.join('')
            tmp = tmp.split("").reverse().join("");
            amount = '00'    
        }
    }
    return tmp + ',' + amount
}

function viewTypeAuction(data){
    let tmpUrlSearch = window.location.search.slice(1)
    switch(true){
        case isTIE(data):
            if(tmpUrlSearch.indexOf('auctions') !== -1){
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Англійський аукціон</span>. <Link className={styles.offLink} to={`auctions/timber?category=timber-english`}>Ліс</Link>
                    </div>
                )
            }else{
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Англійський аукціон</span>. <Link className={styles.offLink} to={`timber?category=timber-english`}>Ліс</Link>
                    </div>
                )
            }
        case isSUE(data):
            if(tmpUrlSearch.indexOf('auctions') !== -1){
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Англійський аукціон</span>. <Link className={styles.offLink} to={`auctions/subsoil?category=subsoil-english`}>Надра</Link>
                    </div>
                )
            }else{
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Англійський аукціон</span>. <Link className={styles.offLink} to={`subsoil?category=subsoil-english`}>Надра</Link>
                    </div>
                )
            }
        case isRCE(data):
            if(tmpUrlSearch.indexOf('auctions') !== -1){
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Англійський аукціон</span>. <Link className={styles.offLink} to={`auctions/railway-cargo?category=railwayCargo-english`}>Залізничні перевезення</Link>
                    </div>
                )
            }else{
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Англійський аукціон</span>. <Link className={styles.offLink} to={`railway-cargo?category=railwayCargo-english`}>Залізничні перевезення</Link>
                    </div>
                )
            }
        case isRCD(data):
            if(tmpUrlSearch.indexOf('auctions') !== -1){
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Голандський аукціон</span>. <Link className={styles.offLink}  to={`auctions/railway-cargo?category=railwayCargo-dutch`}>Залізничні перевезення</Link>
                    </div>
                )
            }else{
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Голандський аукціон</span>. <Link className={styles.offLink}  to={`railway-cargo?category=railwayCargo-dutch`}>Залізничні перевезення</Link>
                    </div>
                )
            }
        case isSUD(data):
            if(tmpUrlSearch.indexOf('auctions') !== -1){
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Голандський аукціон</span>. <Link className={styles.offLink} to={`auctions/subsoil?category=subsoil-dutch`}>Надра</Link>
                    </div>
                )
            }else{
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Голандський аукціон</span>. <Link className={styles.offLink} to={`subsoil?category=subsoil-dutch`}>Надра</Link>
                    </div>
                )
            }
        case isGFE(data):
            if(tmpUrlSearch.indexOf('auctions') !== -1){
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Англійський аукціон</span>. <Link className={styles.offLink} to={`auctions/dgf?category=dgf-english`}>ДГФ</Link>
                    </div>
                )
            }else{
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Англійський аукціон</span>. <Link className={styles.offLink} to={`dgf?category=dgf-english`}>ДГФ</Link>
                    </div>
                )
            }
        case isGFD(data):
            if(tmpUrlSearch.indexOf('auctions') !== -1){
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Голандський аукціон</span>. <Link className={styles.offLink} to={`auctions/dgf?category=dgf-dutch`}>ДГФ</Link>
                    </div>
                )
            }else{
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Голандський аукціон</span>. <Link className={styles.offLink} to={`dgf?category=dgf-dutch`}>ДГФ</Link>
                    </div>
                )
            }
        case isLLE(data):
            if(tmpUrlSearch.indexOf('auctions') !== -1){
                return (
                    <div className={styles.aucIdAndType_type}>
                        <Link className={styles.offLink} to={`auctions/legitimatePropertyLease-english?category=legitimatePropertyLease-english`}>Оренда державного / комунального майна</Link>
                    </div>
                )
            }else{
                return (
                    <div className={styles.aucIdAndType_type}>
                        <Link className={styles.offLink} to={`legitimatePropertyLease-english?category=legitimatePropertyLease-english`}>Оренда державного / комунального майна</Link>
                    </div>
                )
            }
        case isLLD(data):
            if(tmpUrlSearch.indexOf('auctions') !== -1){
                return (
                    <div className={styles.aucIdAndType_type}>
                        <Link className={styles.offLink} to={`auctions/legitimatePropertyLease-dutch?category=legitimatePropertyLease-dutch`}>Оренда державного / комунального майна з покроковим зниженням</Link>
                    </div>
                )
            }else{
                return (
                    <div className={styles.aucIdAndType_type}>
                        <Link className={styles.offLink} to={`legitimatePropertyLease-dutch?category=legitimatePropertyLease-dutch`}>Оренда державного / комунального майна з покроковим зниженням</Link>
                    </div>
                )
            }
        case isLRE(data):
            if(tmpUrlSearch.indexOf('auctions') !== -1){
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Земельні торги</span>. <Link className={styles.offLink} to={`auctions/landRental-english?category=landRental-english`}>оренда</Link>
                    </div>
                )
            }else{
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Земельні торги</span>. <Link className={styles.offLink} to={`landRental-english?category=landRental-english`}>оренда</Link>
                    </div>
                )
            }
        case isLSE(data):
            if(tmpUrlSearch.indexOf('auctions') !== -1){
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Земельні торги</span>. <Link className={styles.offLink} to={`auctions/landSell-english?category=landSell-english`}>продаж</Link>
                    </div>
                )
            }else{
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Земельні торги</span>. <Link className={styles.offLink} to={`landSell-english?category=landSell-english`}>продаж</Link>
                    </div>
                )
            }
        case isLSP(data):
            if(tmpUrlSearch.indexOf('auctions') !== -1){
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Земельні торги</span>. <Link className={styles.offLink} to={`auctions/landSell-priorityEnglish?category=landSell-priorityEnglish`}>продаж з переважним правом</Link>
                    </div>
                )
            }else{
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Земельні торги</span>. <Link className={styles.offLink} to={`landSell-priorityEnglish?category=landSell-priorityEnglish`}>продаж з переважним правом</Link>
                    </div>
                )
            }
        case isBSE(data):
            if(tmpUrlSearch.indexOf('auctions') !== -1){
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Англійський аукціон</span>. <Link className={styles.offLink} to={`auctions/basicSell?category=basicSell-english`}>Базовий продаж</Link>
                    </div>
                )
            }else{
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Англійський аукціон</span>. <Link className={styles.offLink} to={`basicSell?category=basicSell-english`}>Базовий продаж</Link>
                    </div>
                )
            }
        case isBSD(data):
            if(tmpUrlSearch.indexOf('auctions') !== -1){
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Голандський аукціон</span>. <Link className={styles.offLink}  to={`auctions/basicSell?category=basicSell-dutch`}>Базовий продаж</Link>
                    </div>
                )
            }else{
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Голандський аукціон</span>. <Link className={styles.offLink}  to={`basicSell?category=basicSell-dutch`}>Базовий продаж</Link>
                    </div>
                )
            }
        case isALE(data):
            if(tmpUrlSearch.indexOf('auctions') !== -1){
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Відчуження</span> <Link className={styles.offLink}  to={`auctions/alienation?category=alienation`}>майна</Link>
                    </div>
                )
            }else{
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Відчуження</span> <Link className={styles.offLink}  to={`alienation?category=alienation`}>майна</Link>
                    </div>
                )
            }
        case isCSE(data):
            if(tmpUrlSearch.indexOf('auctions') !== -1){
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Англійський аукціон</span>. <Link className={styles.offLink} to={`auctions/commercialSell?category=commercialSell-english`}>Комерційний продаж</Link>
                    </div>
                )
            }else{
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Англійський аукціон</span>. <Link className={styles.offLink} to={`commercialSell?category=commercialSell-english`}>Комерційний продаж</Link>
                    </div>
                )
            }
        case isCSD(data):
            if(tmpUrlSearch.indexOf('auctions') !== -1){
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Голандський аукціон</span>. <Link className={styles.offLink}  to={`auctions/commercialSell?category=commercialSell-dutch`}>Комерційний продаж</Link>
                    </div>
                )
            }else{
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Голандський аукціон</span>. <Link className={styles.offLink}  to={`commercialSell?category=commercialSell-dutch`}>Комерційний продаж</Link>
                    </div>
                )
            }
        case isBRE(data):
            if(tmpUrlSearch.indexOf('auctions') !== -1){
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Англійський аукціон</span>. <Link className={styles.offLink} to={`auctions/bankRuptcy?category=bankRuptcy-english`}>Продаж майна боржників у справах про банкрутство</Link>
                    </div>
                )
            }else{
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Англійський аукціон</span>. <Link className={styles.offLink} to={`bankRuptcy?category=bankRuptcy-english`}>Продаж майна боржників у справах про банкрутство</Link>
                    </div>
                )
            }
        case isBRD(data):
            if(tmpUrlSearch.indexOf('auctions') !== -1){
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Голандський аукціон</span>. <Link className={styles.offLink}  to={`auctions/bankRuptcy?category=bankRuptcy-dutch`}>Продаж майна боржників у справах про банкрутство</Link>
                    </div>
                )
            }else{
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Голандський аукціон</span>. <Link className={styles.offLink}  to={`bankRuptcy?category=bankRuptcy-dutch`}>Продаж майна боржників у справах про банкрутство</Link>
                    </div>
                )
            }
        case isBRW(data):
            if(tmpUrlSearch.indexOf('auctions') !== -1){
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Англійський аукціон</span>. <Link className={styles.offLink} to={`auctions/bankRuptcy?category=bankRuptcy-english`}>Продаж майна боржників у справах про банкрутство</Link>
                    </div>
                )
            }else{
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Англійський аукціон</span>. <Link className={styles.offLink} to={`bankRuptcy?category=bankRuptcy-english`}>Продаж майна боржників у справах про банкрутство</Link>
                    </div>
                )
            }


        case isRLE(data):
            if(tmpUrlSearch.indexOf('auctions') !== -1){
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Англійський аукціон</span>. <Link className={styles.offLink} to={`auctions/regulationsPropertyLease?category=regulationsPropertyLease-english`}>Оренда за регламентом</Link>
                    </div>
                )
            }else{
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Англійський аукціон</span>. <Link className={styles.offLink} to={`regulationsPropertyLease?category=regulationsPropertyLease-english`}>Оренда за регламентом</Link>
                    </div>
                )
            }
        case isRLD(data):
            if(tmpUrlSearch.indexOf('auctions') !== -1){
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Голандський аукціон</span>. <Link className={styles.offLink}  to={`auctions/regulationsPropertyLease?category=regulationsPropertyLease-dutch`}>Оренда за регламентом</Link>
                    </div>
                )
            }else{
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Голандський аукціон</span>. <Link className={styles.offLink}  to={`regulationsPropertyLease?category=regulationsPropertyLease-dutch`}>Комерційна оренда</Link>
                    </div>
                )
            }
        case isCLE(data):
            if(tmpUrlSearch.indexOf('auctions') !== -1){
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Англійський аукціон</span>. <Link className={styles.offLink} to={`auctions/commercialPropertyLease?category=commercialPropertyLease-english`}>Комерційна оренда</Link>
                    </div>
                )
            }else{
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Англійський аукціон</span>. <Link className={styles.offLink} to={`commercialPropertyLease?category=commercialPropertyLease-english`}>Комерційна оренда</Link>
                    </div>
                )
            }
        case isCLD(data):
            if(tmpUrlSearch.indexOf('auctions') !== -1){
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Голандський аукціон</span>. <Link className={styles.offLink}  to={`auctions/commercialPropertyLease?category=commercialPropertyLease-dutch`}>Комерційна оренда</Link>
                    </div>
                )
            }else{
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Голандський аукціон</span>. <Link className={styles.offLink}  to={`commercialPropertyLease?category=commercialPropertyLease-dutch`}>Оренда за регламентом</Link>
                    </div>
                )
            }
        case isAPE(data):
            if(tmpUrlSearch.indexOf('auctions') !== -1){
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Англійський аукціон</span>. <Link className={styles.offLink}  to={`auctions/armaProperty?category=armaProperty-english`}>Арештовані активи АРМА</Link>
                    </div>
                )
            }else{
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Англійський аукціон</span>. <Link className={styles.offLink}  to={`armaProperty?category=armaProperty-english`}>Арештовані активи АРМА</Link>
                    </div>
                )
            }
        case isAPD(data):
            if(tmpUrlSearch.indexOf('auctions') !== -1){
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Голандський аукціон</span>. <Link className={styles.offLink}  to={`auctions/armaProperty?category=armaProperty-dutch`}>Арештовані активи АРМА</Link>
                    </div>
                )
            }else{
                return (
                    <div className={styles.aucIdAndType_type}>
                        <span>Голандський аукціон</span>. <Link className={styles.offLink}  to={`armaProperty?category=armaProperty-dutch`}>Арештовані активи АРМА</Link>
                    </div>
                )
            }

        case data.startsWith('legacy'):
            return (
                <div className={styles.aucIdAndType_type}>
                    <Link className={styles.offLink} to={`legacy?category=legacy`}>Архівний аукціон ЦБД2</Link>
                </div>
            )

        default:
            return null
    }
}

async function getPublicApiAuction(props, aucId){
    //const url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/auction/${aucId}`
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/auction/by_public_id/${aucId}`
    let header = null
    if(props.token !== null){
        header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }else{
        header = {
            'Content-Type': 'application/json'
        }
    }
    const response = await fetch(url, {
        method: "GET",
        headers: header
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
        }else{
            props.setDataAddress(json, 'SET_FULL_AUCTION')
        }
    }
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        auctions: state.start.auctionsGet,
        auctionsOld: state.start.auctionsGetOld,
        search: state.start.search,
        togetherApi: state.start.togetherApi,
        token: state.start.token,
        togetherAPIelem: state.togetherAPIelem,
        userProfiles: state.start.userProfiles,
    }
}

const mapDispatchToProps = {
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemsCard)
