import React from 'react';
import styles from './informationAboutTheOrganizer.module.css';

import { connect } from 'react-redux';

import TextHalf from '../../elements/viewData/textHalf'
import TextFull from '../../elements/viewData/textFull'
import SlimmerBlock from '../../elements/slimmerBlock/slimmerBlock';
import PopDown from '../../elements/viewData/popdownBlock/popDownBlock'

import {modifiDateString} from '../../../redux/modifiDate'

import DocItem from '../../documents/docItem';

const AdditionalInformation = (props) => {

    if(props.auctionId !== null){
        return (
            <SlimmerBlock
                title={`Додаткова інформація`}
                content={viewBanksAccounts(props)}
            />
        )
    }
    return null
}

function viewBanksAccounts(props){
    if(props.auctionId.hasOwnProperty('additionalInformation') === true){
        return props.auctionId.additionalInformation.map(
            (e, count) => {
                return (
                    <div  style={{display: "flex", flexWrap: "wrap", border: '1px solid #c6c6c6', borderRadius: "6px", padding: "10px 18px", marginBottom: "15px"}}>
                        {e.description !== null
                            ? <TextHalf
                                title='Опис додаткової інформації'
                                data={e.description.uk_UA}
                            />
                            : null
                        }
                        {e.initiator !== null
                            ? <TextHalf
                                title='Ініціатор публікації додаткової інформації'
                                data={selectInitiator(e.initiator)}
                            />
                            : null
                        }
                        {e.initiatorOther !== null
                            ? <TextHalf
                                title='Ініціатор публікації'
                                data={e.initiatorOther.uk_UA}
                            />
                            : null
                        }
                        {e.reason !== null
                            ? <TextHalf
                                title='Причина публікації додаткової інформації'
                                data={selectReason(e.reason)}
                            />
                            : null
                        }
                        {e.reasonOther !== null
                            ? <TextHalf
                                title='Причина публікації'
                                data={e.reasonOther.uk_UA}
                            />
                            : null
                        }
                        <TextHalf
                            title='Дата публікації'
                            data={e.datePublished ? modifiDateString(e.datePublished, 3) : ''}
                        />
                        <TextHalf
                            title='Дата модифікації'
                            data={e.dateModified ? modifiDateString(e.dateModified, 3) : ''}
                        />
                        {viewContractDocuments(props, e, count)}
                    </div>
                )
            }
        )
    }
}

function selectInitiator(data){
    if(data === "organizer") return 'Організатор аукціону'
    if(data === "winnerBroker") return 'Майданчик переможця'
    if(data === "participantBroker") return 'Майданчик учасника'
    if(data === "prozorroSales") return 'АТ Прозоро.Продажі'
    if(data === "auctionParticipant") return 'Учасник аукціону'
    if(data === "auctionWinner") return 'Переможець аукціону'
    if(data === "other") return 'Інше'
    if(data === "notSpecified") return 'Не визначено'
    return data
}

function selectReason(data){
    if(data === "error") return 'Помилка'
    if(data === "courtDecision") return 'Судове рішення'
    if(data === "dgfDecision") return 'Рішення ФГВФО'
    if(data === "other") return 'Інше'
    if(data === "notSpecified") return 'Не визначено'
    return data
}

function viewContractDocuments(props, data, count, setPopAddDoc){
    if(data !== null){
        if (data.documents !== null) {
            if(data.documents !== null){
                return <PopDown
                    title={`Додані документи (${data.documents.length})`}
                    content={
                        <>
                            {viewDocContract(data, count, props, setPopAddDoc)}
                        </>
                    }
                />
            }
        }
    }
    return null
}

function viewDocContract(data, countContract, props, setPopAddDoc) { // документы организатора
    
    function dataECPMapper(data, t) {
        let tmp = data
            .filter(
            (item) => {
                return item.documentType === 'digitalSignature' && item.relatedDocument === t.id
            })
        if (tmp.length === 0) return null
        return tmp
    }
    
    if(data !== null){
        if(data === undefined || data === 'undefined'){
        }else{
            let tmp = data.documents
            .map(
                (t) => {

                        const getHistoryURL = (props, data) => {
                            return `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auctionId.id}/additional_information/${data.id}/documents`
                        }
                        
                        const getBidOwner = (props, data) => {
                            if (props.auctionId.privateData.hasBids) {
                                for (let key in props.auctionId.privateData.bids) {
                                    if (data.bidId === props.auctionId.privateData.bids[key].bidId) return true
                                }
                            }
                            return false
                        }
                     
                        let tmp = null
                        const docScopeData = {}
                        docScopeData.aucId = props.auctionId.id
                        docScopeData.aucStatus = props.auctionId.status
                        docScopeData.aucType = props.auctionId.sellingMethod
                        docScopeData.reload = () => {
                            //if (!props.auctionId.privateData.isOwner) getUpdateDataBid(props, props.auctionsAllBids.arr.id)
                            //getUpdateDataAuction(props, props.auctionId.id)
                        }
                        docScopeData.aucOwner = props.auctionId.privateData.isOwner
                        docScopeData.historyUrl = getHistoryURL(props, data)
                        
                           
                        //docScopeData.bidOwner = getBidOwner( props, data)
                        docScopeData.docTypesList = [
                            { value: 'additionalInformationDocument ', name: 'additionalInformationDocument ', main: true },
                        ]
                        docScopeData.awardStatus = data.status
                        docScopeData.docList = data.documents

                        if(props.auctionId.privateData.isOwner){
                            docScopeData.typeOf = 'additional_information'
                            docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/award/${data.id}/documents`
                            docScopeData.patchNewDocFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/award/${data.id}/documents`
                            docScopeData.patchNewDocWoutFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/award/${data.id}/documents`
                        }
                        return (
                            <DocItem
                                data={t}
                                scopeData={docScopeData}
                                dataECP={ dataECPMapper(data.documents, t) }
                                key={t.id}
                            />
                        )
                }
            )
            return tmp
        }
    }
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(AdditionalInformation)
