import React from 'react';
import styles from './togetherAPI.module.css';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';

import {connect} from 'react-redux';

import {setPopUpBidTogetherAPI} from '../../../../redux/actions/auctions/auctions.js';
import CreateAuctionLoader from '../../../createAuction/createAuctionLoader.js';

import {setUniversalError} from '../../../../redux/actions.js';

import {
    togetherApiPullAucEI,
    togetherApiSaveDataLoad,
    togetherApiSaveValue,

    togetherApiTimerRound,
    togetherApiTimerTime,
    togetherApiTimerTimeLeft,
} from '../../../../redux/togetherApi.js';

import TogetherAPITimer from './togetherAPITimer.js';

const TogetherAPIEIrightElem = (props) => {
    return (
        <div className={styles.onlineAuctionElem}>
            <div>Аукціон: {props.togetherApiPage.saveDataSoket._id}</div>
            <div>Назва: {props.togetherApiPage.pullAucEI.auction.title}</div>
            <div>
                <div>детальніше про аукціон:</div>
                <div>Номер лоту: {props.togetherApiPage.pullAucEI.auction.lotIdentifier}</div>
                <div>Організатор: {props.togetherApiPage.pullAucEI.auction.sellingEntity.name}</div>
                <div>Початкова ціна: {props.togetherApiPage.pullAucEI.auction.value.amount} грн</div>
                <div>Гарантійний внесок: {props.togetherApiPage.pullAucEI.auction.guarantee.amount} грн</div>
                <div>Крок аукціону: {props.togetherApiPage.pullAucEI.auction.minimalStep.amount} грн</div>
                <div>Опис: {props.togetherApiPage.pullAucEI.auction.description}</div>
                <div>АКТИВИ</div>
                <div>{props.togetherApiPage.pullAucEI.auction.items[0].quantity}</div>
                <div>Кількість: {props.togetherApiPage.pullAucEI.auction.items[0].quantity} {props.togetherApiPage.pullAucEI.auction.items[0].unit.symbol}</div>
                <div>Адреса: {props.togetherApiPage.pullAucEI.auction.items[0].address.streetAddress}‚ {props.togetherApiPage.pullAucEI.auction.items[0].address.locality}‚ {props.togetherApiPage.pullAucEI.auction.items[0].address.countryName}‚ {props.togetherApiPage.pullAucEI.auction.items[0].address.postalCode}</div>
                <div>
                    Посилання на аукціон: 
                    <a href={window.location.origin + '/tenders/' + props.togetherApiPage.pullAucEI.auction.id}  title="Посилання на аукціон">
                        <IconButton aria-label="edit">
                            <LinkIcon/>
                        </IconButton>
                    </a>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        auctions: state.start.auctionsGet,
        auctionsOld: state.start.auctionsGetOld,
        search: state.start.search,
        myBids: state.start.myBids,
        token: state.start.token,
        togetherApiPage: state.start.togetherApiPage,
    }
}

const mapDispatchToProps = {
    setPopUpBidTogetherAPI,  
    setUniversalError,  
    togetherApiPullAucEI,
    togetherApiSaveDataLoad,
    togetherApiSaveValue,
    
    togetherApiTimerRound,
    togetherApiTimerTime,
    togetherApiTimerTimeLeft,
}

export default connect(mapStateToProps, mapDispatchToProps)(TogetherAPIEIrightElem)