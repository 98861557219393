import React from 'react';
import style from './createRestriction.module.css';

import {connect} from 'react-redux';

import TextField from '@material-ui/core/TextField';

import {getMyAuction} from '../../../../redux/actions.js';

import {
    setCreateRestrictionFlag,
    setCreateLoadObject,
    setCreateLoadObjectCode,
    setCreateLoadObjectName,
    setCreateUnloadObject,
    setCreateUnloadObjectCode,
    setCreateUnloadObjectName,
    changeAuctionsItemsSwitchWindow,
} from '../../../../redux/actions/auctions/auctions.js';

import {setUniversalError, changeLoader} from '../../../../redux/actions.js';
import {setEditAuctionFromId,} from '../../../../redux/actions.js';

const AuctionBankAccount = ( props ) => {
    return (
        <div className={style.popup_wrap}>
            <div className={style.popup_message}>
                <div>Обмеження полігону навантаження/вивантаження</div>
                <div>
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        label="Ознака об'єкту полігону навантаження"
                        value={props.createRestriction.loadObject}
                        onChange={(event)=>{
                            props.setCreateLoadObject(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        {createSelectLoadObject(props)}
                    </TextField>
                </div>
                <div>
                    <TextField
                        label="Код об'єкту полігону навантаження"
                        value={props.createRestriction.loadObjectCode}
                        onChange={(event) => {
                            props.setCreateLoadObjectCode(event.target.value)
                        }}
                        variant="outlined"
                        helperText=" "
                    />
                </div>
                <div>
                    <TextField
                        label="Найменування об'єкту полігону навантаження"
                        value={props.createRestriction.loadObjectName}
                        onChange={(event) => {
                            props.setCreateLoadObjectName(event.target.value)
                        }}
                        variant="outlined"
                        helperText=" "
                    />
                </div>
                <div>
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        label="Ознака об'єкту полігону вивантаження"
                        value={props.createRestriction.unloadObject}
                        onChange={(event)=>{
                            props.setCreateUnloadObject(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        {createSelectLoadObject(props)}
                    </TextField>
                </div>
                <div>
                    <TextField
                        label="Код об'єкту полігону вивантаження"
                        value={props.createRestriction.unloadObjectCode}
                        onChange={(event) => {
                            props.setCreateUnloadObjectCode(event.target.value)
                        }}
                        variant="outlined"
                        helperText=" "
                    />
                </div>
                <div>
                    <TextField
                        label="Найменування об'єкту полігону вивантаження"
                        value={props.createRestriction.unloadObjectName}
                        onChange={(event) => {
                            props.setCreateUnloadObjectName(event.target.value)
                        }}
                        variant="outlined"
                        helperText=" "
                    />
                </div>
                


                <div className={style.popup_message_button}
                    onClick={
                        () => {
                            if(props.createRestrictionFlag === -1){
                                createRestrict(props)
                            }else{
                                saveRestrict(props)
                            }
                            props.setCreateRestrictionFlag(null)
                        }
                    }
                >
                    Зберегти
                </div>
                


                <div className={style.popup_message_button}
                    onClick={
                        () => {
                            props.setCreateRestrictionFlag(null)
                            props.setCreateLoadObject('null')
                            props.setCreateLoadObjectCode('')
                            props.setCreateLoadObjectName('')
                            props.setCreateUnloadObject('null')
                            props.setCreateUnloadObjectCode('')
                            props.setCreateUnloadObjectName('')
                        }
                    }
                >
                    Закрити
                </div>
            </div>
        </div>
    )
}

function createSelectLoadObject(props){
    let tmp = null;
    if(props.createRestriction.setArrRestrict !== null){
        tmp = props.createRestriction.setArrRestrict.map(
            (i, count) => {
                return <option key={count} value={i.classificationId}>{i.description}</option>
            }
        )
    }
    return tmp
}

async function createRestrict(props){
    props.changeLoader(true)
    const url33 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}/item/${props.createItems.id}/restriction`
    const response = await fetch(url33, {
        method: "POST",
        body: JSON.stringify({
            loadObject: props.createRestriction.loadObject,
            loadObjectCode: props.createRestriction.loadObjectCode,
            loadObjectName: props.createRestriction.loadObjectName,
            unloadObject: props.createRestriction.unloadObject,
            unloadObjectCode: props.createRestriction.unloadObjectCode,
            unloadObjectName: props.createRestriction.unloadObjectName
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json1 = await response.json()
        if(json1.hasOwnProperty('message') === true){
            props.changeLoader(false)
            props.setCreateLoadObject('null')
            props.setCreateLoadObjectCode('')
            props.setCreateLoadObjectName('')
            props.setCreateUnloadObject('null')
            props.setCreateUnloadObjectCode('')
            props.setCreateUnloadObjectName('')
            props.changeAuctionsItemsSwitchWindow(0);
        }
        if(json1.hasOwnProperty('id') === true){
            const url2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}`;
            const response2 = await fetch(url2, {
                    method: "GET",
                    headers: {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
            }).catch(console.error)
            if(response2 === undefined || response2 === 'undefined'){
            }else{
                const json2 = await response2.json()
                if(json2.hasOwnProperty('message') === true){
                    props.changeLoader(false)
                    props.setCreateLoadObject('null')
                    props.setCreateLoadObjectCode('')
                    props.setCreateLoadObjectName('')
                    props.setCreateUnloadObject('null')
                    props.setCreateUnloadObjectCode('')
                    props.setCreateUnloadObjectName('')
                    props.changeAuctionsItemsSwitchWindow(0);
                }else{
                    props.changeLoader(false)
                    props.setCreateLoadObject('null')
                    props.setCreateLoadObjectCode('')
                    props.setCreateLoadObjectName('')
                    props.setCreateUnloadObject('null')
                    props.setCreateUnloadObjectCode('')
                    props.setCreateUnloadObjectName('')
                    props.changeAuctionsItemsSwitchWindow(0);
                    getAucionFromId(props, props.auctionsMy.id)
                }
            }
        }
    }
}

async function saveRestrict(props){
    props.changeLoader(true)
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}/item/${props.createItems.id}/restriction/${props.createRestrictionFlag}`
    const response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify({
            loadObject: props.createRestriction.loadObject,
            loadObjectCode: props.createRestriction.loadObjectCode,
            loadObjectName: props.createRestriction.loadObjectName,
            unloadObject: props.createRestriction.unloadObject,
            unloadObjectCode: props.createRestriction.unloadObjectCode,
            unloadObjectName: props.createRestriction.unloadObjectName
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json1 = await response.json()
        if(json1.hasOwnProperty('message') === true){
            props.changeLoader(false)
            props.setCreateLoadObject('null')
            props.setCreateLoadObjectCode('')
            props.setCreateLoadObjectName('')
            props.setCreateUnloadObject('null')
            props.setCreateUnloadObjectCode('')
            props.setCreateUnloadObjectName('')
            props.changeAuctionsItemsSwitchWindow(0);
        }
        if(json1.hasOwnProperty('id') === true){
            const url2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}`;
            const response2 = await fetch(url2, {
                    method: "GET",
                    headers: {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
            }).catch(console.error)
            if(response2 === undefined || response2 === 'undefined'){
            }else{
                const json2 = await response2.json()
                if(json2.hasOwnProperty('message') === true){
                    props.changeLoader(false)
                    props.setCreateLoadObject('null')
                    props.setCreateLoadObjectCode('')
                    props.setCreateLoadObjectName('')
                    props.setCreateUnloadObject('null')
                    props.setCreateUnloadObjectCode('')
                    props.setCreateUnloadObjectName('')
                    props.changeAuctionsItemsSwitchWindow(0);
                }else{
                    props.changeLoader(false)
                    props.setCreateLoadObject('null')
                    props.setCreateLoadObjectCode('')
                    props.setCreateLoadObjectName('')
                    props.setCreateUnloadObject('null')
                    props.setCreateUnloadObjectCode('')
                    props.setCreateUnloadObjectName('')
                    props.changeAuctionsItemsSwitchWindow(0);
                    getAucionFromId(props, props.auctionsMy.id)
                }
            }
        }
    }
}

////////////////////////////
async function getAucionFromId(props, aucId){
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${aucId}`;
    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            props.setEditAuctionFromId(null)
            props.setEditAuctionFromId(json)
            /*if(json.previousAuctionId !== null){
                props.changeDiscountPerviousAuctionId(json.previousAuctionId)
                if(json.discount !== null){
                    props.changeDiscountPerviousAuctionAmount(json.discount.previousAuctionValue.amount)
                    props.changeDiscountPercent(json.discount.discountPercent)
                }
            }*/
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        userProfiles: state.start.userProfiles,
        
        createRestrictionFlag: state.start.createRestrictionFlag,
        createRestriction: state.start.createRestriction,    
    }
}

const mapDispatchToProps = {
    getMyAuction,
    setCreateRestrictionFlag,
    setCreateLoadObject,
    setCreateLoadObjectCode,
    setCreateLoadObjectName,
    setCreateUnloadObject,
    setCreateUnloadObjectCode,
    setCreateUnloadObjectName,
    changeLoader,
    setUniversalError,
    setEditAuctionFromId,
    changeAuctionsItemsSwitchWindow,
}

export default connect(mapStateToProps, mapDispatchToProps)(AuctionBankAccount)