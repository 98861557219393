import { checkProp } from "../../../../redux/check"
// HISTORY >
// export function getBidHistory(props) {
//     if (props.data.dateModified !== props.data.datePublished) {
//         props.state.setHistory(!props.state.state)
//         getBidDocumentHistory(props)
//     }
// }

// async function getBidDocumentHistory(props) {
//     let endPoint = process.env.REACT_APP_END_POINT_BD
//     let url = `${endPoint}/api/v2/uk/public/history/auction/${props.scope.aucId}/bids/${props.scope.bidIdentifier}/documents`
//     const response = await fetch(url, {
//     method: "GET",
//     headers: {
//         'Content-Type': 'application/json'
//     }
//     }).catch(console.error)
//     if(response === undefined || response === 'undefined'){
//         alert('Нажаль виникла помилка і сервер не відповідає')
//     }else{
//         const json = await response.json()
//         if(json !== null){
//             await props.state.setArr(json)
//         }
//     }
// }

// EDIT_BUTTON
export function viewBidEditDoc(props) {
    if (props.scope.aucStatus === "active_tendering" &&
        props.data.documentType !== 'digitalSignature') {
        if(props.scope.aucOwner === true) return true
        if(props.scope.bidOwner === true) return true
    }
    return false
}

//DOWNLOAD_BUTTON
export async function getViewBidDocuments(props){
    let tmpArr = []
    tmpArr = props.arrPrivatLink
    //if(props.data.scope === 'private'){
    if (props.data._ds_scope === "private") {
        if(props.token !== null){

            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.scope.aucId}/bid/${props.scope.bidId}/documents/${props.data.id}/download?returnLink=1`
            const response = await fetch(endPoint, {
                method: "GET",
                headers: {
                    'Authorization': props.token.access_token,
                    ///'Content-Type': 'application/json'
                }
            })
            .catch(console.error)
            if(response === undefined || response === 'undefined'){
                return null
            }else{
                let url = await response.json()
                return url.link
            }
        }
    }else if(props.data.scope === "private") {
        if(props.token !== null){
            
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.scope.bidId}/documents/${props.data.id}/download?returnLink=1`

            const response = await fetch(endPoint, {
                method: "GET",
                headers: {
                    'Authorization': props.token.access_token,
                    ///'Content-Type': 'application/json'
                }
            })
            .catch(console.error)
            if(response === undefined || response === 'undefined'){
                return null
            }else{
                let url = await response.json()
                return url.link
            }
        }
    }
    return null
}

// DELETE_BUTTON
export function viewBidDeleteDoc(props) {
   
    if ((props.scope.bidStatus === null || props.scope.bidStatus === 'draft') &&
        props.scope.aucStatus === 'active_tendering' && props.scope.bidModeration !== true) {
        return true
    }
    return false
}

export async function deleteBidDocuments(props) {
    props.changeLoader(true)
    const endPoint = `${props.scope.deleteURL}/${props.data.id}`
    const response = await fetch(endPoint, {
        method: "DELETE",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .catch(console.error)
    if(response === undefined || response === 'undefined'){
        props.changeLoader(false)
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            props.changeLoader(false)
            //let tmp = JSON.stringify({title: 'Помилка додання документу до чернетки', message: JSON.stringify(json4)})
            //props.setUniversalError(tmp)
        }else{
            let data = {
                domen: process.env.REACT_APP_END_POINT_BD,
                version: 'v1',
                local: 'uk',
                params: '?limit=20'
            }
            await props.scope.reload()
            await props.changeLoader(false)
        }
    }
}

//KEP URL Create
export function bidKEPCreate(props) {
    // let url = {}
    let tmpUrlNew = null
    let tmpUrl = null
    let tmpUrlKep = null

    if (checkProp(props.docData, 'id')) {
            tmpUrl = `${props.scopeData.patchNewDocFilesURL}/${props.docData.id}`
            tmpUrlNew = props.scopeData.uploadNewDocURL
            if (checkProp(props.scopeData, 'docList') && props.scopeData.docList.length > 0) {
                for (let key in props.scopeData.docList) {
                    if (props.scopeData.docList[key].relatedDocument === props.docData.id) {
                        // tmpRelatedDocument = props.scopeData.docList[key].documentId
                        tmpUrlKep = `${props.scopeData.patchNewDocFilesURL}/${props.scopeData.docList[key].documentId}`
                    }
                }
            }
    } else {
        tmpUrlNew = props.scopeData.uploadNewDocURL
    }

    return {
        urlNew: tmpUrlNew,
        url: tmpUrl,
        urlKep: tmpUrlKep,
    }

}
