import React from 'react';
import styles from './itemsList.module.css';

import {connect} from 'react-redux';
import {
    changeCreateItemAuctionId,
    changeCreateItemClassificationId,
    changeCreateItemUnitValueCurrency,
    changeCreateItemUnitValueAmount,
    changeCreateItemUnitValueTax,
    changeCreateItemDescription,
    changeAdditionalClassificationIds,
    changeCreateItemQuantity,
    changeCreateItemIds,
    changeLoader,
    getMyAuction,
    changeCreateItemClassificationAddIdNadra,
    changeCreateItemAddress,
} from '../../../../../redux/actions.js';
import {auctionsGetMy,
    auctionsGetMyId,
    changeAuctionsItemsSwitchWindow,
    changeAuctionsSwitchWindow,
    auctionsGetClassificationsByTypeEnergy,
    auctionsGetClassificationsByGenerationType,
    changeTimberSortment,
    changeTimberSpecies,
    changeTimberClass,
    changeTimberDiameter,
    changeTimberLength,
    changeTimberStorage,
    changeTimberProductionYear,
    changeTimberProductionQuarter,
    changeCreateItemUnitCode,
    changeCreateItemGenerationType,
} from '../../../../../redux/actions/auctions/auctions.js';
import {setDataAddress} from '../../../../../redux/createAddress';

import 'date-fns';
/*import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';*/

//import TextField from '@material-ui/core/TextField';
//import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

const ItemsList = ( props ) => {
    let tmp = null;
    /*if(props.auctionsList !== null){
        if(props.auctionsList.length > 0){
            tmp = props.auctionsList
            .filter(
                (i) => {
                    return i.id === props.auctionsMy.id
                }
            )
        }
    }*/

    let tmpArr = null;
    //if(tmp !== null){
    if(props.auctionsMy !== null){
        //if(tmp.length > 0){
            //tmpArr = tmp[0].items.map(
            tmpArr = props.auctionsMy.items.map(
                (i) => {
                    return (
                        <div key={i.id} className={styles.auctionsLine}>
                            <div className={styles.auctionsLineElem}>
                                <div className={styles.itemsId}>Id лота: {i.id}</div>
                                <div>Опис лота: {i.description}</div>
                            </div>
                            <div className={styles.auctionsLineElem}>
                                <div
                                    onClick={
                                        () => {
                                            props.changeAuctionsItemsSwitchWindow(2);
                                            
                                            props.changeCreateItemAuctionId(props.auctionsMy.id)
                                            props.changeCreateItemIds(i.id)
                                            props.changeCreateItemClassificationId(i.classification.id)
                                            props.changeCreateItemDescription(i.description)
                                            //props.changeCreateItemAuctionId(i.unitCode)
                                            props.changeCreateItemQuantity(i.quantity)
                                            //props.changeCreateItemAddress(i.address.id)
                                            if(i.address !== null){
                                                props.setDataAddress(i.address.id, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS')
                                                
                                                props.setDataAddress(i.address.id, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_ID')
                                                props.setDataAddress(i.address.countryName, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_COUNTRY_NAME')
                                                props.setDataAddress(i.address.region, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_REGION')
                                                props.setDataAddress(i.address.locality, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_LOCALITY')
                                                props.setDataAddress(i.address.streetAddress, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_STREET_ADDRESS')
                                                props.setDataAddress(i.address.postalCode, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_POSTAL_CODE')
                                            }
                                            if(i.location !== null){
                                                props.setDataAddress(i.location.latitude, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE')
                                                props.setDataAddress(i.location.longitude, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE')
                                            }


                                            if(i.additionalClassifications.length > 0){
                                                props.changeCreateItemClassificationAddIdNadra(i.additionalClassifications[0].id)
                                            }else{
                                                props.changeCreateItemClassificationAddIdNadra('')
                                            }
                                            props.changeCreateItemUnitCode(i.unit.code)

                                            let data = {
                                                domen: process.env.REACT_APP_END_POINT_BD,
                                                version: 'v1',
                                                local: props.langFlag,
                                                params: '?limit=20'
                                            }
                                            let header = {
                                                'Authorization': props.token.access_token,
                                                'Content-Type': 'application/json'
                                            }
                                            props.auctionsGetClassificationsByTypeEnergy(data, header)
                                            props.auctionsGetClassificationsByGenerationType(data, header)
                                        }
                                    }
                                >
                                    <IconButton aria-label="edit">
                                        <EditIcon />
                                    </IconButton>
                                </div>
                                <div
                                    onClick={
                                        () => {
                                            props.changeLoader(true)
                                            deleteItem(props, props.auctionsMy.id, i.id)
                                            //props.changeAuctionsSwitchWindow(0)
                                        }
                                    }
                                >
                                    <IconButton aria-label="delete">
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    )
                }
            )
        //}
    }
    return tmpArr
}

async function  deleteItem(props, aucId, itemId){
    /*const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${aucId}/item/${itemId}`;
    const response1 = await fetch(endPoint, {
        method: "delete",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .then(
        (response1) =>{
            if(response1 !== undefined || response1 !== 'undefined'){
                let data = {
                    domen: process.env.REACT_APP_END_POINT_BD,
                    version: 'v1',
                    local: props.langFlag,
                    params: '?limit=20'
                }
                let header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                props.auctionsGetMy(data, header)
                props.auctionsGetMyId(data, header, props.auctionsMy.id)
            }
        }
    )
    .catch(console.error)*/
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${aucId}/item/${itemId}`;
    const response1 = await fetch(endPoint, {
        method: "DELETE",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
    }else{
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}`;
        props.changeLoader(true)
        const response2 = await fetch(endPoint2, {
                method: "GET",
                headers: header
        }).catch(console.error)
        if(response2 === undefined || response2 === 'undefined'){
        }else{
            props.changeAuctionsItemsSwitchWindow(0);
            const json2 = await response2.json()
            if(json2.hasOwnProperty('message') === true){
                props.changeLoader(false)
            }else{
                props.getMyAuction(json2)
                props.changeLoader(false)
            }
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy
    }
}

const mapDispatchToProps = {
    changeCreateItemAuctionId,
    changeCreateItemClassificationId,
    changeCreateItemUnitValueCurrency,
    changeCreateItemUnitValueAmount,
    changeCreateItemUnitValueTax,
    changeCreateItemDescription,
    changeAuctionsItemsSwitchWindow,
    auctionsGetMy,
    auctionsGetMyId,
    changeAuctionsSwitchWindow,
    changeAdditionalClassificationIds,
    changeCreateItemQuantity,
    changeCreateItemIds,
    //auctionsGetClassificationsByType,
    auctionsGetClassificationsByTypeEnergy,
    auctionsGetClassificationsByGenerationType,
    //auctionsGetClassificationsByTimberSortment,
    //auctionsGetClassificationsByTimberSpecies,
    //auctionsGetClassificationsByTimberClass,
    //auctionsGetClassificationsByTimberDiameter,
    //auctionsGetClassificationsByTimberLength,
    //auctionsGetClassificationsByTimberStorage,
    //auctionsGetClassificationsByTimberProductionYear,
    //auctionsGetClassificationsByTimberProductionQuarter,
    changeTimberSortment,
    changeTimberSpecies,
    changeTimberClass,
    changeTimberDiameter,
    changeTimberLength,
    changeTimberStorage,
    changeTimberProductionYear,
    changeTimberProductionQuarter,
    changeCreateItemUnitCode,
    changeCreateItemGenerationType,
    changeLoader,
    getMyAuction,
    changeCreateItemClassificationAddIdNadra,
    changeCreateItemAddress,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemsList)
