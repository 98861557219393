import React from 'react';
import styles from './warehouseComposition.module.css';

import {connect} from 'react-redux';

const TextHalf = (props) => {
    return (
        <div className={styles.lotHalf + ' ' + styles.lotFullLink}>
            <p className={styles.desc}>{props.title}</p>
            <p><a href={props.data} target='blank'>{props.text}</a></p>
        </div>
    )
}

export default connect(null, null)(TextHalf)
