import React from 'react';
import styles from './answerLot.module.css';

import ButtonAnswer from './buttonAnswer/buttonAnswer.js';

import {connect} from 'react-redux';

const AnswerLot = ( props ) => {
    let tmp = null;
    if(props.auction !== null){
        tmp = props.auction.questions.map(
            (i) => {
                return (
                    <div className={styles.answerWrap}>
                        <div className={styles.answerWrap_line}><span>Заголовок:</span> {i.title}</div>
                        <div className={styles.answerWrap_line}><span>Текст:</span> {i.description}</div>
                        {/*<div className={styles.answerWrap_line}><span>Відповідь:</span> {i.answer}</div>*/}
                        {buyerButton(props, i)}
                    </div>
                )
            }
        )
    }
    if(tmp === null){
        tmp = 'Наразі немає жодного запитання від учасників'
    }else if(tmp.length === 0){
        tmp = 'Наразі немає жодного запитання від учасників'
    }
    let tmpB = <div className={styles.nullBlock}></div>
    return (
        <div className={styles.answerBlock}>
            {tmp}
            {tmpB}
        </div>
    )
}

function buyerButton(props, i){
    if(props.auction.createdByCurrentUser === true){
        if(i.answer !== null){
            return <div className={styles.answerWrap_line}><span>Відповідь:</span> {i.answer}</div>
        }
        let dateChatStart = null
        let dateChatEnd = null
        if(props.auction !== null){
            if(props.auction.questionPeriod !== null) {
                dateChatStart = new Date(props.auction.questionPeriod.startDate)
                dateChatEnd = new Date(props.auction.questionPeriod.endDate)
            }
            let tmpStart = null
            let tmpEnd = null
            if(dateChatStart !== null && dateChatEnd !== null) {
                tmpStart = dateChatStart.getTime()
                tmpEnd = dateChatEnd.getTime()
                if(tmpStart < Date.now() && tmpEnd > Date.now()){
                    return <ButtonAnswer
                        itemAnswer={i.id}
                    />
                }
            }
        }
        return <div className={styles.answerWrap_line}><span>Відповідь:</span> {i.answer}</div>
    }
    return <div className={styles.answerWrap_line}><span>Відповідь:</span> {i.answer}</div>
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        //auction: state.start.auctionsGetId,
        auction: state.start.auctionFull,
        langFlag: state.start.langFlag,
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(AnswerLot)