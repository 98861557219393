import React from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import {changeCreateAuctionXDocumentRequirements} from '../../../redux/actions.js';

import TextField from '@material-ui/core/TextField';
import InputText from '../../elements/inputs/input';

import {disabletElem} from '../disabled'

const WindowAuctionDocumentRequirements = ( props ) => {
    if(props.auctionsMy !== null){
        // if(props.auctionsMy.sellingMethod === "subsoil-english"
        // || props.auctionsMy.sellingMethod === "subsoil-english-fast"
        // || props.auctionsMy.sellingMethod === "subsoil-english-fast-manual"
        // || props.auctionsMy.sellingMethod === "subsoil-english-initial-auction"
        // || props.auctionsMy.sellingMethod === "subsoil-english-initial-qualification"
        // || props.auctionsMy.sellingMethod === "subsoil-dutch"
        // || props.auctionsMy.sellingMethod === "subsoil-dutch-fast"
        // || props.auctionsMy.sellingMethod === "subsoil-dutch-fast-manual"
        // || props.auctionsMy.sellingMethod === "subsoil-dutch-initial-auction"
        // || props.auctionsMy.sellingMethod === "subsoil-dutch-initial-qualification"
        // || props.auctionsMy.sellingMethod === "subsoil-dutch-initial-auction-manual"
        // || props.auctionsMy.sellingMethod === "subsoil-english-fast-auction-manual-qualification") return null
        return viewNameInput(props)
    }
    return null
}

function viewNameInput(props){
    let tmpDocumentRequirements = ''
    if(props.auctionsMy.hasOwnProperty('xDocumentRequirements') && props.auctionsMy.xDocumentRequirements !== null){
        tmpDocumentRequirements = props.auctionsMy.xDocumentRequirements
    }else if(props.auctionsMy.hasOwnProperty('x_documentRequirements') && props.auctionsMy.x_documentRequirements !== null){
        tmpDocumentRequirements = props.auctionsMy.x_documentRequirements.uk_UA
    }
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        return null
    }else if(
        props.auctionsMy.sellingMethod === 'landRental-english' ||
        props.auctionsMy.sellingMethod === 'landRental-english-fast' ||
        props.auctionsMy.sellingMethod === 'landRental-english-fast-manual' ||
        props.auctionsMy.sellingMethod === 'landRental-english-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'landRental-english-initial-auction' ||
        props.auctionsMy.sellingMethod === 'landRental-english-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'landRental-english-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'landRental-english-initial-qualification-prod' ||
        props.auctionsMy.sellingMethod === 'landSell-english' ||
        props.auctionsMy.sellingMethod === 'landSell-english-fast' ||
        props.auctionsMy.sellingMethod === 'landSell-english-fast-manual' ||
        props.auctionsMy.sellingMethod === 'landSell-english-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'landSell-english-initial-auction' ||
        props.auctionsMy.sellingMethod === 'landSell-english-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'landSell-english-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'landSell-english-initial-qualification-prod' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod'
    ){
        if(props.typeElem === 1){
            return <InputText
                        disabled={disabletElem(props.auctionsMy, '00')}
                        label={'Вимоги до оформлення документів'}
                        value={props.auctionsMy.xDocumentRequirements}
                        onChange={
                            (e) => {
                                props.changeCreateAuctionXDocumentRequirements(e)
                            }
                        }
                    />
        }else{
            return <div className={styles.inputMax}>
                <TextField
                    disabled={disabletElem(props.auctionsMy, '00')}
                    className={styles.inputMax}
                    id="filled-required"
                    label="Вимоги до оформлення документів"
                    defaultValue={tmpDocumentRequirements}
                    variant="outlined"
                    onChange={(event)=>{
                        props.changeCreateAuctionXDocumentRequirements(event.target.value)
                    }}
                />
            </div>
        }
    }else if(
        props.auctionsMy.sellingMethod === 'timber-english' ||
        props.auctionsMy.sellingMethod === 'timber-english-fast' ||
        props.auctionsMy.sellingMethod === 'timber-english-fast-manual' ||
        props.auctionsMy.sellingMethod === 'timber-english-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'timber-english-initial-auction' ||
        props.auctionsMy.sellingMethod === 'timber-english-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'timber-english-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'timber-english-initial-qualification-prod'
    ){
        if(props.typeElem === 1){
            return <InputText
                        disabled={disabletElem(props.auctionsMy, '00')}
                        label={'Вимоги до оформлення документів'}
                        value={props.auctionsMy.xDocumentRequirements}
                        onChange={
                            (e) => {
                                props.changeCreateAuctionXDocumentRequirements(e)
                            }
                        }
                        required
                    />
        }else{
            return <div className={styles.inputMax}>
                <TextField
                    disabled={disabletElem(props.auctionsMy, '00')}
                    className={styles.inputMax}
                    id="filled-required"
                    label="Вимоги до оформлення документів"
                    defaultValue={tmpDocumentRequirements}
                    variant="outlined"
                    onChange={(event)=>{
                        props.changeCreateAuctionXDocumentRequirements(event.target.value)
                    }}
                    required
                />
            </div>
        }
    }else{
        if(props.typeElem === 1){
            return <InputText
                        disabled={disabletElem(props.auctionsMy, '00')}
                        label={'Вимоги до оформлення документів'}
                        value={props.auctionsMy.xDocumentRequirements}
                        onChange={
                            (e) => {
                                props.changeCreateAuctionXDocumentRequirements(e)
                            }
                        }
                    />
        }else{
            return <div className={styles.inputMax}>
                <TextField
                    disabled={disabletElem(props.auctionsMy, '00')}
                    className={styles.inputMax}
                    id="filled-required"
                    label="Вимоги до оформлення документів"
                    defaultValue={tmpDocumentRequirements}
                    variant="outlined"
                    onChange={(event)=>{
                        props.changeCreateAuctionXDocumentRequirements(event.target.value)
                    }}
                />
            </div>
        }
    }
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
        auctionsZUAttemp: state.start.auctionsZUAttemp
    }
}

const mapDispatchToProps = {
    changeCreateAuctionXDocumentRequirements,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuctionDocumentRequirements)
