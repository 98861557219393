import React from 'react';
import styles from './yellowBlock.module.css';

import {connect} from 'react-redux';

import {modifiDateString} from '../../../../redux/modifiDate'

const YellowBlock = (props) => {
    let rectificationPeriod = null;
    let enquiryPeriod = null;
    if(props.auction !== null){
        for (let key in props.auction.auctionPeriods) {
            if(props.auction.auctionPeriods[key].status === 'questionPeriod'){
                rectificationPeriod = modifiDateString(props.auction.auctionPeriods[key].endDate, 3, false)
            }
            if(props.auction.auctionPeriods[key].status === 'enquiryPeriod'){
                enquiryPeriod = modifiDateString(props.auction.auctionPeriods[key].endDate, 3, false)
            }
        }
    }
    return (
        <div className={styles.yellowBlock}>
            <div className={styles.bank_right}>
                <div className={styles.bank_rightBlacktext}>ПЕРІОД УТОЧНЕНЬ</div>
                <div className={styles.bank_rightBlacktext}>до {rectificationPeriod}</div>
            </div>
            <div className={styles.bank_right}>
                <div className={styles.bank_rightBlacktext}>ПОДАННЯ ПРОПОЗИЦІЙ</div>
                <div className={styles.bank_rightBlacktext}>до {enquiryPeriod}</div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auction: state.start.auctionsGetId,
        langFlag: state.start.langFlag,
        profile: state.start.userProfiles
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(YellowBlock)
