import React, {useState, useEffect} from 'react';
import styles from '../procedure.module.css'

import {useSelector} from 'react-redux';
import {PROCEDURE_NAME} from '../../../../../../redux/procedure_v3.35.2.uk';

import { isMongo, checkProp } from '../../../../../../redux/check';

import TextArea from '../../../../../elements/inputs/textArea'
import Input from '../../../../../elements/inputs/input';
import Select from '../../../../../elements/inputs/inputSelect'
import Button from '../../../../../elements/buttons/button';
import InputAmount from '../../../../../elements/inputs/inputAmount';
import InputDate from '../../../../../elements/inputs/inputDate';
import Checkbox from '../../../../../elements/inputs/checkBox';

import BankAccount from '../../bankAccount/bankAccount';

import { disabletElem } from '../../../../disabled';
import LocationOrg from '../../../../elems/localization/locationOrg';

import { сreateBody } from '../lib/createBody';
import { setTenderAttemptList } from '../lib/utils';

import {editAuc, createAuc, editAucAwardPatch} from '../../../createEditAndCloneAuction/edit/editTabMonga'
import { setDutchStepQuantityList } from '../lib/utils';

const AuctionBSE = ( {data, ...props} ) => {
    const auction = useSelector(state => state.start.auctionsMy)

    //const [lotId, setLotId] = useState(checkProp(data, 'lotId') ? data.lotId : null)
    const [title, setTitle] = useState(checkProp(data, 'title') ? isMongo(data.title) : null)
    const [description, setDescription] = useState(checkProp(data, 'description') ? isMongo(data.description) : null)
    const [tenderAttempts, setTenderAttempts] = useState(checkProp(data, 'tenderAttempts') ? data.tenderAttempts : 1)
    const [accessDetails, setAccessDetails] = useState(checkProp(data, 'accessDetails') ? isMongo(data.accessDetails) : null)
    //const [x_documentRequirements, setX_documentRequirements] = useState(checkProp(data, 'x_documentRequirements') ? isMongo(data.x_documentRequirements) : null)
    //const [x_additionalInformation, setX_additionalInformation] = useState(checkProp(data, 'x_additionalInformation') ? isMongo(data.x_additionalInformation) : null)
    //const [minNumberOfQualifiedBids, setMinNumberOfQualifiedBids] = useState(1)
    const [previousAuctionId, setPreviousAuctionId] = useState(checkProp(data, 'previousAuctionId') ? data.previousAuctionId : null)
    const [ownershipType, setOwnershipType] = useState(checkProp(data, 'ownershipType') ? data.ownershipType : "state")
    const [discountPercent, setDiscountPercent] = useState(checkProp(data,'discount') && checkProp(data.discount, 'discountPercent') ? data.discount.discountPercent : null)
    const [previousAuctionValue, setPreviousAuctionValue] = useState(checkProp(data,'discount') && checkProp(data.discount, 'previousAuctionValue') ? data.discount.previousAuctionValue : null)
    const [value, setValue] = useState(checkProp(data, 'value') ? data.value : null)
    const [guarantee, setGuarantee] = useState(checkProp(data, 'guarantee') ? data.guarantee : null)
    const [minimalStep, setMinimalStep] = useState(checkProp(data, 'minimalStep') ? data.minimalStep : null)
    const [registrationFee, setRegistrationFee] = useState(checkProp(data, 'registrationFee') ? data.registrationFee : {
        amount: checkProp(guarantee,'amount') ? guarantee.amount : 1420,
        currency: checkProp(guarantee,'currency') ? guarantee.currency : "UAH",
    })
    const [valueAddedTaxCharged, setValueAddedTaxCharged] = useState(checkProp(data, 'valueAddedTaxCharged') ? data.valueAddedTaxCharged : true)
    const [startDate, setStartDate] = useState(checkProp(data, 'auctionPeriod') && checkProp(data.auctionPeriod, 'startDate') ? data.auctionPeriod.startDate : null)
    const [bankAccounts, setBankAccounts] = useState(checkProp(data,'bankAccounts') ? data.bankAccounts : [])
    const [discount, setDiscount] = useState(checkProp(data,'discount') && checkProp(data.discount,'discount') ? data.discount.discount : false)
    //const [isPerishable, setIsPerishable] = useState(checkProp(data, 'isPerishable') ? data.isPerishable : false)
    
    const [seInformationName, setSeInformationName] = useState((checkProp(data, 'sellingEntity') &&  checkProp(data.sellingEntity, 'name')) ? isMongo(data.sellingEntity.name) : null)
    const [seRepresentativeInfo, setSeRepresentativeInfo] = useState((checkProp(data, 'sellingEntity') && checkProp(data.sellingEntity, 'representativeInfo')) ? data.sellingEntity.representativeInfo : null)

    const [assetHolderInformationName, setAssetHolderInformationName] = useState((checkProp(data, 'assetHolder') &&  checkProp(data.assetHolder, 'name')) ? isMongo(data.assetHolder.name) : null)
    const [assetHolderRepresentativeInfo, setAssetHolderRepresentativeInfo] = useState((checkProp(data, 'assetHolder') && checkProp(data.assetHolder, 'representativeInfo')) ? data.assetHolder.representativeInfo : null)

    const [contactPointName, setContactPointName] = useState((checkProp(data, 'assetHolder') && checkProp(data.assetHolder, 'contactPoint') && checkProp(data.assetHolder.contactPoint, 'name')) ? isMongo(data.assetHolder.contactPoint.name) : null)
    const [contactPointEmail, setContactPointEmail] = useState((checkProp(data, 'assetHolder') && checkProp(data.assetHolder, 'contactPoint') && checkProp(data.assetHolder.contactPoint, 'email')) ? data.assetHolder.contactPoint.email : null)
    const [contactPointTelephone, setContactPointTelephone] = useState((checkProp(data, 'assetHolder') && checkProp(data.assetHolder, 'contactPoint') && checkProp(data.assetHolder.contactPoint, 'telephone')) ? data.assetHolder.contactPoint.telephone : null)
    const [contactPointFaxNumber, setContactPointFaxNumber] = useState((checkProp(data, 'assetHolder') && checkProp(data.assetHolder, 'contactPoint') && checkProp(data.assetHolder.contactPoint, 'faxNumber')) ? data.assetHolder.contactPoint.faxNumber : null)
    const [contactPointUrl, setContactPointUrl] = useState((checkProp(data, 'assetHolder') && checkProp(data.assetHolder, 'contactPoint') && checkProp(data.assetHolder.contactPoint, 'url')) ? data.assetHolder.contactPoint.url : null)

    const [seContactPointName, setSeContactPointName] = useState((checkProp(data, 'sellingEntity') && checkProp(data.sellingEntity, 'contactPoint') && checkProp(data.sellingEntity.contactPoint, 'name')) ? isMongo(data.sellingEntity.contactPoint.name) : null)
    const [seContactPointEmail, setSeContactPointEmail] = useState((checkProp(data, 'sellingEntity') && checkProp(data.sellingEntity, 'contactPoint') && checkProp(data.sellingEntity.contactPoint, 'email')) ? data.sellingEntity.contactPoint.email : null)
    const [seContactPointTelephone, setSeContactPointTelephone] = useState((checkProp(data, 'sellingEntity') && checkProp(data.sellingEntity, 'contactPoint') && checkProp(data.sellingEntity.contactPoint, 'telephone')) ? data.sellingEntity.contactPoint.telephone : null)
    const [seContactPointFaxNumber, setSeContactPointFaxNumber] = useState((checkProp(data, 'sellingEntity') && checkProp(data.sellingEntity, 'contactPoint') && checkProp(data.sellingEntity.contactPoint, 'faxNumber')) ? data.sellingEntity.contactPoint.faxNumber : null)
    const [seContactPointUrl, setSeContactPointUrl] = useState((checkProp(data, 'sellingEntity') && checkProp(data.sellingEntity, 'contactPoint') && checkProp(data.sellingEntity.contactPoint, 'url')) ? data.sellingEntity.contactPoint.url : null)

    const [identifierLegalName, setIdentifierLegalName] = useState((checkProp(data, 'assetHolder') && checkProp(data.assetHolder, 'identifier') && checkProp(data.assetHolder.identifier, 'legalName')) ? isMongo(data.assetHolder.identifier.legalName) : null)
    const [identifierScheme, setIdentifierScheme] = useState((checkProp(data, 'assetHolder') && checkProp(data.assetHolder, 'identifier') && checkProp(data.assetHolder.identifier, 'scheme')) ? data.assetHolder.identifier.scheme : null)
    const [identifierId, setIdentifierId] = useState((checkProp(data, 'assetHolder') && checkProp(data.assetHolder, 'identifier') && checkProp(data.assetHolder.identifier, 'id')) ? data.assetHolder.identifier.id : null)

    const [seIdentifierLegalName, setSeIdentifierLegalName] = useState((checkProp(data, 'sellingEntity') && checkProp(data.sellingEntity, 'identifier') && checkProp(data.sellingEntity.identifier, 'legalName')) ? isMongo(data.sellingEntity.identifier.legalName) : null)
    const [seIdentifierScheme, setSeIdentifierScheme] = useState((checkProp(data, 'sellingEntity') && checkProp(data.sellingEntity, 'identifier') && checkProp(data.sellingEntity.identifier, 'scheme')) ? data.sellingEntity.identifier.scheme : null)
    const [seIdentifierId, setSeIdentifierId] = useState((checkProp(data, 'sellingEntity') && checkProp(data.sellingEntity, 'identifier') && checkProp(data.sellingEntity.identifier, 'id')) ? data.sellingEntity.identifier.id : null)

    const [address, setAdress] = useState(
        (checkProp(data, 'assetHolder')
        && checkProp(data.assetHolder, 'address')
    ) ? data.assetHolder.address : null)

    const [seAddress, setSeAdress] = useState(
        (checkProp(data, 'sellingEntity')
        && checkProp(data.sellingEntity, 'address')
    ) ? data.sellingEntity.address : null)

    const [dutchStepQuantity, setDutchStepQuantity] = useState(checkProp(data, 'dutchStep') && checkProp(data.dutchStep, 'dutchStepQuantity') ? data.dutchStep.dutchStepQuantity : 99)
    const [dutchStepPercent, setDutchStepPercent] = useState(checkProp(data, 'dutchStep') && checkProp(data.dutchStep, 'dutchStepPercent') ? data.dutchStep.dutchStepPercent : null)
    const [dutchStepValue, setDutchStepValue] = useState(checkProp(data, 'dutchStep') && checkProp(data.dutchStep, 'dutchStepValue') ? data.dutchStep.dutchStepValue : null)

    const [saleCondition, setSaleCondition] = useState(checkProp(data, 'saleCondition') ? data.saleCondition : null)
    

    let requestBody = {
        title: {
            uk_UA: title
        },
        description: {
            uk_UA: description
        },
        accessDetails: {
            uk_UA: accessDetails
        },
        tenderAttempts: +tenderAttempts,
        //lotId: lotId,
        auctionPeriod: {
            startDate: startDate
        },
        sellingMethod: data.sellingMethod,
        /*x_documentRequirements: {
            uk_UA: x_documentRequirements
        },
        x_additionalInformation: {
            uk_UA: x_additionalInformation
        },*/
        //minNumberOfQualifiedBids: +minNumberOfQualifiedBids,
        ownershipType: ownershipType,
        valueAddedTaxCharged: valueAddedTaxCharged,
        value: {
            currency: checkProp(value,'currency') ? value.currency : null,
            amount: checkProp(value,'amount') ? +value.amount : null,
            valueAddedTaxIncluded: checkProp(value,'addTax') ? value.addTax : null
        },
        minimalStep: {
            currency: checkProp(minimalStep,'currency') ? minimalStep.currency : null,
            amount: checkProp(minimalStep,'amount') ? +minimalStep.amount : null,
        },
        guarantee: {
            currency: checkProp(guarantee,'currency') ? guarantee.currency : null,
            amount: checkProp(guarantee,'amount') ? +guarantee.amount : null
        },
        registrationFee: {
            currency: checkProp(registrationFee,'currency') ? registrationFee.currency : null,
            amount: checkProp(registrationFee,'amount') ? +registrationFee.amount : null
        },
        previousAuctionId: previousAuctionId,
        // discount: {
        //     discount: discount,
        //     discountPercent: discountPercent,
        //     previousAuctionValue:{
        //         currency: checkProp(previousAuctionValue,'currency') ? previousAuctionValue.currency : null,
        //         amount: checkProp(previousAuctionValue,'amount') ? +previousAuctionValue.amount : null,
        //         valueAddedTaxIncluded: checkProp(previousAuctionValue,'addTax') ? previousAuctionValue.addTax : null
        //     }
        // },
        bankAccounts: bankAccounts,
        sellingEntity: {
            /*name: {
                uk_UA: seInformationName,
            },*/
            identifier: {
                scheme: seIdentifierScheme,
                id: seIdentifierId,
                legalName: {
                    uk_UA: seIdentifierLegalName
                }
            },
            address: seAddress,
            representativeInfo: seRepresentativeInfo,
            contactPoint: {
                name: {
                    uk_UA: seContactPointName
                },
                email: seContactPointEmail,
                telephone: seContactPointTelephone,
                faxNumber: seContactPointFaxNumber,
                url: seContactPointUrl
            }
        },
        assetHolder: {
            /*name: {
                uk_UA: assetHolderInformationName,
            },*/
            identifier: {
                scheme: identifierScheme,
                id: identifierId,
                legalName: {
                    uk_UA: identifierLegalName
                }
            },
            address: address,
            representativeInfo: assetHolderRepresentativeInfo,
            contactPoint: {
                name: {
                    uk_UA: contactPointName
                },
                email: contactPointEmail,
                telephone: contactPointTelephone,
                faxNumber: contactPointFaxNumber,
                url: contactPointUrl
            }
        },
        dutchStep: {
            dutchStepQuantity: dutchStepQuantity,
            dutchStepPercent: dutchStepPercent,
            dutchStepValue: {
                currency: checkProp(dutchStepValue,'currency') ? dutchStepValue.currency : null,
                amount: checkProp(dutchStepValue,'amount') ? +dutchStepValue.amount : null
            }
        }
    }

    function discountBlock(){
        if (tenderAttempts > 1){
            return(
                <div className={styles.costBlock}>
                    <div className={styles.costBlockTitle}>Дані попереднього аукціону</div>
                    <div className={styles.twoInLine}>
                        <Input //"Ідентифікатор попереднього аукціону"
                            label={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.previousAuctionId.legalName} 
                            value={previousAuctionId}
                            onChange={(e)=>{ setPreviousAuctionId(e) }}
                            disabled={disabletElem(auction, '01')}
                            required
                        />
                    </div>
                    {/* discount 
                    ? 
                        <>
                            <Checkbox 
                                value={discount}
                                onChange={setDiscount}
                            >
                            Застосувати знижку
                            </Checkbox>
                            <div 
                            // className={styles.twoInLine}
                                style={{width: '60%', height: '180px', display: 'flex', flexFlow: 'column', justifyContent: 'space-between'}}
                            >
                                <InputAmount //"Стартова вартість попереднього аукціону"
                                    title={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.discount.previousAuctionValue.legalName}
                                    lable={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.discount.previousAuctionValue.amount.legalName}
                                    data={{
                                        amount: checkProp(previousAuctionValue,'amount') ? previousAuctionValue.amount : null,
                                        currency: checkProp(previousAuctionValue,'currency') ? previousAuctionValue.currency : null,
                                        addTax: checkProp(previousAuctionValue,'valueAddedTaxIncluded') ? previousAuctionValue.valueAddedTaxIncluded : true
                                    }}
                                    onChange={(e) => { setPreviousAuctionValue(e) }}
                                    disabled={disabletElem(auction, '01')}
                                    required
                                    tax
                                />
                                <Input //"Розмір знижки від попереднього аукціону, %"
                                    label={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.discount.discountPercent.legalName} 
                                    value={discountPercent}
                                    type='number'
                                    onChange={(e)=>{ setDiscountPercent(e) }}
                                    disabled={disabletElem(auction, '01')}
                                    required
                                />
                            </div>
                        </>
                    : 
                        <Checkbox 
                            value={discount}
                            onChange={setDiscount}
                        >
                            Застосувати знижку
                    </Checkbox>*/}
                </div>
            )
        } else {
            requestBody.discount = null
        }
        return null
    }
//
    function disabledInput(auction, cod, type){
        if(
            auction.status === 'complete' ||
            auction.status === 'cancelled' ||
            auction.status === 'unsuccessful'
        ){
            return true
        }
        if(
            auction.status === 'active_auction' ||
            auction.status === 'active_qualification' ||
            auction.status === 'pending_payment' ||
            auction.status === 'active_awarded'
        ){
            if(!type){
                return false
            }else{
                return true
            }
        }
        return disabletElem(auction, cod)
    }

    function viewSaleCondition(){
        if(saleCondition !== null){
            return (
                <Select
                    data={{
                        label: "Наявність умов продажу", 
                        target: ownershipType,
                        // info: 'Отака от штука-дрюка',
                        // required: true,
                    }}
                    onChange={(e) => { setOwnershipType(e) }}
                    options={
                        [
                            {value: 'yes', name: 'Так'},
                            {value: 'no', name: 'Ні'},
                            {value: 'notSpecified', name: 'Не визначено'},
                        ]
                    }
                    disabled={true}
                />
            )
        }
        return null
    }

    let tmpDateEdit = auction !== null && auction.clarificationPeriod !== null ? new Date(auction.clarificationPeriod.endDate).getTime() : 0
    //tmpDateEdit = tmpDateEdit + 7200000

    return (
        <>
            {/*<div>
                <div>Посилання на ІП</div>
                <div>{data.}</div>
            </div>*/}
            {/*<Input //"Номер лота"
                label={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.lotId.legalName} 
                value={lotId}
                onChange={(e)=>{ setLotId(e) }}
                disabled={disabletElem(auction, '01')}
                required
            />*/}
            <Input //Назва аукціону"
                label="Назва аукціону *"
                value={title}
                onChange={(e)=>{ setTitle(e) }}
                disabled={true}
            />
            <TextArea //"Опис аукціону"
                value={description}
                label="Опис аукціону *"
                onChange={(e)=>{ setDescription(e) }}
                disabled={true}
            />
            <Select //'Лот виставляється',
                data={{
                    label: "Лот виставляється", 
                    target: tenderAttempts,
                    // info: 'Отака от штука-дрюка',
                    required: true,
                }}
                onChange={(e) => { setTenderAttempts(e) }}
                options={setTenderAttemptList()}
                disabled={true}
            />
            <Input //"Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
                label="Порядок ознайомлення з майном, час і місце проведення огляду об'єкта"
                value={accessDetails}
                onChange={(e)=>{ setAccessDetails(e) }}
                /*disabled={disabletElem(auction, '01')}*/
            />
            {/*<Input //"Перелік та вимоги до оформлення документів"
                label={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.x_documentRequirements.legalName} 
                value={x_documentRequirements}
                onChange={(e)=>{ setX_documentRequirements(e) }}
                disabled={disabletElem(auction, '01')}
            />
            <Input
                label={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.x_additionalInformation.legalName} //"Додаткові відомості"
                value={x_additionalInformation}
                onChange={(e)=>{ setX_additionalInformation(e) }}
                disabled={disabletElem(auction, '01')}
            />*/}
            <Select //"Тип власності об’єкта малої приватизації" 
                data={{
                    label: "Тип власності об’єкта малої приватизації", 
                    target: ownershipType,
                    // info: 'Отака от штука-дрюка',
                    // required: true,
                }}
                onChange={(e) => { setOwnershipType(e) }}
                options={
                    [
                        {value: 'state', name: 'Державна'},
                        {value: 'municipal', name: 'Комунальна'},
                        {value: 'compatible', name: 'Сумісна'},
                    ]
                }
                disabled={true}
            />

            {viewSaleCondition()}
            
            {/*<Select //"Мінімальна кількість заяв" 
                data={{
                    label: "Мінімальна кількість заяв", 
                    target: minNumberOfQualifiedBids,
                    // info: 'Отака от штука-дрюка',
                    // required: true,
                }}
                onChange={(e) => { setMinNumberOfQualifiedBids(e) }}
                options={
                    [
                        {value: 'null', name: 'Обрати', selected: true},
                        {value: '1', name: '1'},
                    ]
                }
                disabled={true}
            />*/}

            <div className={styles.costBlock}>
                <div className={styles.costBlockTitle}>Інформація про Організатора аукціону</div>
                <Input //Повна юридична назва організації або ПІБ
                    label='Повна юридична назва організації або ПІБ'
                    value={seInformationName}
                    onChange={(e)=>{ setSeInformationName(e) }}
                    disabled={true}
                />
                <div className={styles.orgBlock}>
                    <div className={styles.costBlockTitle}>Ідентифікатори організації або особи</div>
                    <Input //Ідентифікатори організації
                        label='Повна юридична назва організації'
                        value={seIdentifierLegalName}
                        onChange={(e)=>{ setSeIdentifierLegalName(e) }}
                        required
                    />
                    <Select 
                        data={{
                            label: 'Ідентифікатори організації',
                            target: seIdentifierScheme,
                            // info: 'Отака от штука-дрюка',
                        }}
                        onChange={(e) => { setSeIdentifierScheme(e) }}
                        options={[
                            {name: 'Обрати', value: null},
                            {name: 'ЄДРПОУ', value: 'UA-EDR'},
                            {name: 'ІПН/РНОКПП', value: 'UA-IPN'},
                            {name: 'Паспорт', value: 'UA-PASSPORT'},
                            {name: 'ID-карта', value: 'UA-ID-CARD'},
                            {name: 'ІПН/РНОКПП (ФОП)', value: 'UA-IPN-FOP'}
                        ]}
                    />
                    <Input //Код ЄДРПОУ або ІПН або паспорт
                        label='Код ЄДРПОУ або ІПН або паспорт'
                        value={seIdentifierId}
                        onChange={(e)=>{ setSeIdentifierId(e) }}
                        required
                    />
                </div>
                <LocationOrg
                    address={seAddress}
                    setAdress={setSeAdress}
                    dataText='Місцезнаходження організатора'
                />
                <div className={styles.orgBlock}>
                    <div className={styles.costBlockTitle}>Контактна особа</div>
                    <Input //ПІБ
                        label='ПІБ'
                        value={seContactPointName}
                        onChange={(e)=>{ setSeContactPointName(e) }}
                        required
                    />
                    <Input //Адреса електронної пошти
                        label='Адреса електронної пошти'
                        value={seContactPointEmail}
                        onChange={(e)=>{ setSeContactPointEmail(e) }}
                        required
                    />
                    <Input //Номер телефону
                        label='Номер телефону'
                        value={seContactPointTelephone}
                        onChange={(e)=>{ setSeContactPointTelephone(e) }}
                        required
                    />
                    <Input //Номер факсу
                        label='Номер факсу'
                        value={seContactPointFaxNumber}
                        onChange={(e)=>{ setSeContactPointFaxNumber(e) }}
                    />
                    <Input //Веб адреса
                        label='Веб адреса'
                        value={seContactPointUrl}
                        onChange={(e)=>{ setSeContactPointUrl(e) }}
                    />
                </div>
                <Input //№ свідоцтва про право на провадження діяльності арбітражного керуючого
                    label='Інформація щодо підтвердження повноважень'
                    value={seRepresentativeInfo}
                    onChange={(e)=>{ setSeRepresentativeInfo(e) }}
                    required
                />
            </div>
            <div className={styles.costBlock}>
                <div className={styles.costBlockTitle}>Інформація про балансоутримувача</div>
                <Input //Повна юридична назва організації або ПІБ
                    label='Повна юридична назва організації або ПІБ'
                    value={assetHolderInformationName}
                    onChange={(e)=>{ setAssetHolderInformationName(e) }}
                    disabled={true}
                />
                <div className={styles.orgBlock}>
                    <div className={styles.costBlockTitle}>Ідентифікатори організації або особи</div>
                    <Input //Ідентифікатори організації
                        label='Повна юридична назва організації'
                        value={identifierLegalName}
                        onChange={(e)=>{ setIdentifierLegalName(e) }}
                        required
                    />
                    <Select 
                        data={{
                            label: 'Ідентифікатори організації',
                            target: identifierScheme,
                            // info: 'Отака от штука-дрюка',
                            required: true,
                        }}
                        onChange={(e) => { setIdentifierScheme(e) }}
                        options={[
                            {name: 'Обрати', value: null},
                            {name: 'ЄДРПОУ', value: 'UA-EDR'},
                            {name: 'ІПН/РНОКПП', value: 'UA-IPN'},
                            {name: 'Паспорт', value: 'UA-PASSPORT'},
                            {name: 'ID-карта', value: 'UA-ID-CARD'},
                            {name: 'ІПН/РНОКПП (ФОП)', value: 'UA-IPN-FOP'}
                        ]}
                        /*disabled={disabledInput(auction, '01', true)}*/
                    />
                    <Input //Код ЄДРПОУ або ІПН або паспорт
                        label='Код ЄДРПОУ або ІПН або паспорт'
                        value={identifierId}
                        onChange={(e)=>{ setIdentifierId(e) }}
                        /*disabled={disabledInput(auction, '01', true)}*/
                        required
                    />
                </div>
                <LocationOrg
                    address={address}
                    setAdress={setAdress}
                    dataText='Місцезнаходження балансоутримувача'
                    /*disabled={disabledInput(auction, '01', true)}*/
                />
                <div className={styles.orgBlock}>
                    <div className={styles.costBlockTitle}>Контактна особа</div>
                    <Input //ПІБ
                        label='ПІБ'
                        value={contactPointName}
                        onChange={(e)=>{ setContactPointName(e) }}
                        required
                    />
                    <Input //Адреса електронної пошти
                        label='Адреса електронної пошти'
                        value={contactPointEmail}
                        onChange={(e)=>{ setContactPointEmail(e) }}
                        /*disabled={disabledInput(auction, '01', true)}*/
                        required
                    />
                    <Input //Номер телефону
                        label='Номер телефону'
                        value={contactPointTelephone}
                        onChange={(e)=>{ setContactPointTelephone(e) }}
                        /*disabled={disabledInput(auction, '01', true)}*/
                        required
                    />
                    <Input //Номер факсу
                        label='Номер факсу'
                        value={contactPointFaxNumber}
                        onChange={(e)=>{ setContactPointFaxNumber(e) }}
                        /*disabled={disabledInput(auction, '01', true)}*/
                    />
                    <Input //Веб адреса
                        label='Веб адреса'
                        value={contactPointUrl}
                        onChange={(e)=>{ setContactPointUrl(e) }}
                        /*disabled={disabledInput(auction, '01', true)}*/
                    />
                </div>
                <Input //Інформація щодо підтвердження повноважень
                    label='Інформація щодо підтвердження повноважень'
                    value={assetHolderRepresentativeInfo}
                    onChange={(e)=>{ setAssetHolderRepresentativeInfo(e) }}
                    required
                />
            </div>


            <Select //'Кількість кроків',
                data={{
                    label: PROCEDURE_NAME['basicSell-dutch'].BasicSellDutchProcedure.dutchStep.dutchStepQuantity.legalName, 
                    target: dutchStepQuantity,
                    // info: 'Отака от штука-дрюка',
                    // required: true,
                }}
                disabled={disabledInput(auction, '01', true)}
                onChange={(e) => { setDutchStepQuantity(e) }}
                options={ setDutchStepQuantityList() }
            />
            <Input
                label={PROCEDURE_NAME['basicSell-dutch'].BasicSellDutchProcedure.dutchStep.dutchStepPercent.legalName} //"Кількість кроків"
                value={dutchStepPercent}
                type='number'
                onChange={(e)=>{ setDutchStepPercent(e) }}
                disabled={true}
            />
            <InputAmount //"Розмір кроку голландського раунду, грн"
                title={PROCEDURE_NAME['basicSell-dutch'].BasicSellDutchProcedure.dutchStep.dutchStepValue.legalName}
                lable={PROCEDURE_NAME['basicSell-dutch'].BasicSellDutchProcedure.dutchStep.dutchStepValue.amount.legalName}
                data={{
                    amount: checkProp(dutchStepValue,'amount') ? dutchStepValue.amount : null,
                    currency: checkProp(dutchStepValue,'currency') ? dutchStepValue.currency : null,
                    // addTax: checkProp(dutchStepValue,'valueAddedTaxIncluded') ? value.valueAddedTaxIncluded : true
                }}
                onChange={ e => setDutchStepValue(e) }
                disabled={true}
                tax
            />


            {discountBlock()}
            <div className={styles.costBlock}>
                    <div className={styles.costBlockTitle}>Вартісні характеристики аукціону</div>
                    <InputAmount //"Стартова ціна лота"
                        title="Стартова ціна лота *"
                        lable={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.value.amount.legalName}
                        data={{
                            amount: checkProp(value,'amount') ? value.amount : null,
                            currency: checkProp(value,'currency') ? value.currency : null,
                            addTax: checkProp(value,'valueAddedTaxIncluded') ? value.valueAddedTaxIncluded : true
                        }}
                        onChange={(e) => {
                                    setValue(e)
                                    // if(Number(e.amount) <= 20000){
                                    //     setRegistrationFee({registrationFee, amount: 17})
                                    // }else if(Number(e.amount) <= 50000){
                                    //     setRegistrationFee({registrationFee, amount: 119})
                                    // }else if(Number(e.amount) <= 200000){
                                    //     setRegistrationFee({registrationFee, amount: 340})
                                    // }else if(Number(e.amount) <= 1000000){
                                    //     setRegistrationFee({registrationFee, amount: 510})
                                    // }else if(Number(e.amount) > 1000000){
                                    //     setRegistrationFee({registrationFee, amount: 1700})
                                    // }
                        }}
                        disabled={true}
                        tax
                    />
                    {tenderAttempts > 1 ? <InputAmount //"Гарантійний внесок"
                        title="Гарантійний внесок *"
                        lable={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.guarantee.amount.legalName}
                        data={{
                            amount: checkProp(guarantee,'amount') ? guarantee.amount : null,
                            currency: checkProp(guarantee,'currency') ? guarantee.currency : null,
                        }}
                        onChange={(e) => { setGuarantee(e) }}
                        disabled={tmpDateEdit  > Date.now() ? false : true}
                        info={tmpDateEdit > Date.now() ? 'Період уточнення' : 'Період уточнення завершено'}
                    /> : null}
                    <InputAmount //"Розмір кроку аукціону"
                        title="Розмір кроку аукціону *"
                        lable={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.minimalStep.amount.legalName}
                        data={{
                            amount: checkProp(minimalStep,'amount') ? minimalStep.amount : null,
                            currency: checkProp(minimalStep,'currency') ? minimalStep.currency : null,
                        }}
                        onChange={(e) => { setMinimalStep(e) }}
                        disabled={true}
                    />
                    <InputAmount //"Реєстраційний внесок"
                        title="Реєстраційний внесок *"
                        lable={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.registrationFee.amount.legalName}
                        data={{
                            amount: checkProp(registrationFee,'amount') ? registrationFee.amount : 1420,
                            currency: checkProp(registrationFee,'currency') ? registrationFee.currency : "UAH",
                        }}
                        onChange={(e) => { setRegistrationFee(e) }}
                        disabled={true}
                    />
                    <div style={{height: '20px'}}></div>
                    <Checkbox 
                        value={valueAddedTaxCharged}
                        onChange={setValueAddedTaxCharged}
                        disabled={true}
                    >
                        На фінальну суму нараховується ПДВ
                    </Checkbox>
                    
                    {/* <Select 
                        data={{
                            label: 'На фінальну суму нараховується ПДВ',
                            target: valueAddedTaxCharged,
                            // info: 'Отака от штука-дрюка',
                            // required: true,
                        }}
                        onChange={(e) => { setValueAddedTaxCharged(e) }}
                        options={[
                            {name: 'Обрати', value: null},
                            {name: 'з ПДВ', value: true},
                            {name: 'без ПДВ', value: false},
                        ]}
                        disabled={disabletElem(auction, '01')}
                    /> */}
            </div>
            <div className={styles.startDate}>
            <div className={styles.costBlockTitle}>
                {`${PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.auctionPeriod.startDate.legalName} аукціону`}
            </div>
                <InputDate
                    data={{
                        target: startDate,
                        time: true
                    }}
                    onChange={ e => setStartDate(e) }
                    disabled={true}
                />
            </div>
            <BankAccount bankAccounts={bankAccounts} setBankAccounts={setBankAccounts} />

            <div className={styles.bottomButtonBlock}>
                <Button
                    height={'50px'}
                    font={'16px'}
                    color='blue'
                    data={'Зберегти зміни'}
                    onClick={
                        () =>{
                            let tmp = null
                            let bodyObj = {
                               base: requestBody,
                            }
                            tmp = сreateBody(bodyObj, auction)
                            if(auction.id !== null && auction.id !== ''){
                                editAuc(props.updateAuc, tmp)
                                //editAucPatch(props.updateAuc, tmp)
                            }else{
                                createAuc(props.updateAuc, tmp)
                            }
                        }
                    }
                />
            </div>
        </>
    )
}

export default AuctionBSE