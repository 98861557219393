import React from 'react';
import styles from './togetherAPI.module.css';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';

import {connect} from 'react-redux';

import {setPopUpBidTogetherAPI} from '../../../../redux/actions/auctions/auctions.js';
import CreateAuctionLoader from '../../../createAuction/createAuctionLoader.js';

import {setUniversalError} from '../../../../redux/actions.js';

import {
    togetherApiPullAucEI,
    togetherApiSaveDataLoad,
    togetherApiSaveValue,

    togetherApiTimerRound,
    togetherApiTimerTime,
    togetherApiTimerTimeLeft,
} from '../../../../redux/togetherApi.js';

import {modifiDateString} from '../../../../redux/modifiDate'

const TogetherAPIEIleftElem = (props) => {
    return (
        <div className={styles.onlineAuctionElem}>
            <div>Хід аукціону</div>
            <div>Дата проведення: </div>
            <div>Старт аукціону: {modifiDateString(props.togetherApiPage.saveDataSoket.public_meta.timeline[0].start, 3, false)}</div>
            <div>Раунд 1: {modifiDateString(props.togetherApiPage.saveDataSoket.public_meta.timeline[1].start, 3, false)} - {modifiDateString(props.togetherApiPage.saveDataSoket.public_meta.timeline[1].end, 3, false)}</div>
            <div>Раунд 2: {modifiDateString(props.togetherApiPage.saveDataSoket.public_meta.timeline[2].start, 3, false)} - {modifiDateString(props.togetherApiPage.saveDataSoket.public_meta.timeline[2].end, 3, false)}</div>
            <div>Раунд 3: {modifiDateString(props.togetherApiPage.saveDataSoket.public_meta.timeline[3].start, 3, false)} - {modifiDateString(props.togetherApiPage.saveDataSoket.public_meta.timeline[3].end, 3, false)}</div>
            <div>Оголошення результатів: {modifiDateString(props.togetherApiPage.saveDataSoket.public_meta.timeline[4].end, 3, false)}</div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        auctions: state.start.auctionsGet,
        auctionsOld: state.start.auctionsGetOld,
        search: state.start.search,
        myBids: state.start.myBids,
        token: state.start.token,
        togetherApiPage: state.start.togetherApiPage,
    }
}

const mapDispatchToProps = {
    setPopUpBidTogetherAPI,  
    setUniversalError,  
    togetherApiPullAucEI,
    togetherApiSaveDataLoad,
    togetherApiSaveValue,
    
    togetherApiTimerRound,
    togetherApiTimerTime,
    togetherApiTimerTimeLeft,
}

export default connect(mapStateToProps, mapDispatchToProps)(TogetherAPIEIleftElem)