import React from 'react';

import {connect} from 'react-redux';

import {
    setDataAddress,
} from '../../../redux/createAddress';

import TextField from '@material-ui/core/TextField';

import {disabletElem} from '../disabled'

const IsPerishable = ( props ) => {
    if(props.auctionsMy !== null){
        if(
            props.auctionsMy.sellingMethod === 'basicSell-english' ||
            props.auctionsMy.sellingMethod === 'basicSell-dutch'
        ){
            return (
                <div>
                    <div>Майно, що швидко псується</div>
                    <TextField
                        disabled={offItems(props)}
                        id="outlined-select-currency-native"
                        select
                        value={props.auctionsMy.isPerishable}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'SET_IS_PERISHABLE')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        variant="outlined"
                    >
                        <option key={0} selected disabled value='null'>Обрати</option>
                        <option key={1} value='true'>Так</option>
                        <option key={2} value='false'>Ні</option>
                    </TextField>
                </div>
            )
        }
    }
    return null
}

function offItems(props){
    if(props.auctionsMy.status !== null) return 'disabled'
    return disabletElem(props.auctionsMy, '00')
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
    }
}

const mapDispatchToProps = {
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(IsPerishable)