import React from 'react';
import styles from './imageAndButtonLine_ButtonBlock.module.css';

import { Link } from 'react-router-dom';

/*import {
    BUTTON_DISCUSSION_LOTS_LANG,
    BUTTON_PARTICIPATE_LOTS_LANG,
} from '../../../../../../../model/lang/catalogLots.js';*/

import {connect} from 'react-redux';
import {changePageNumber} from '../../../../../../../redux/actions.js';
import {auctionGetId} from '../../../../../../../redux/actions/auctions/auctions';

const BUTTON_DISCUSSION_LOTS_LANG = ['ОБГОВОРЕННЯ', 'DISCUSSION'];
const BUTTON_PARTICIPATE_LOTS_LANG = ['ВЗЯТИ УЧАСТЬ', 'PARTICIPATE'];

const ImageAndButtonLine_ButtonBlock = ( props ) => {
    let tmp = null
    if(props.userProfiles !== null){
        if(props.userProfiles.canCreateBid === true){
            if(props.item.status === 'active_tendering'){
                tmp = <div>
                    <div className={styles.identif_three}>
                        <div
                            className={styles.secondBlock_textBread}
                            onClick={
                                () => {
                                    props.changePageNumber(props.item.id)
                                }
                            }
                        >
                            <Link to={`/tenders/${props.item.id}/questions`}>
                                {BUTTON_DISCUSSION_LOTS_LANG[props.langFlag]}
                                <div></div>
                            </Link>
                        </div>
                        <div
                            className={styles.secondBlock_textAuk}
                            onClick={
                                () => {
                                    props.changePageNumber(props.item.id)
                                }
                            }
                        >
                            <Link to={`/tenders/${props.item.id}`}>
                                {BUTTON_PARTICIPATE_LOTS_LANG[props.langFlag]}
                                <div></div>
                            </Link>
                        </div>
                    </div>
                </div>
            }
        }
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
    }
}

const mapDispatchToProps = {
    changePageNumber,
    auctionGetId
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageAndButtonLine_ButtonBlock)