import React from 'react';
import styles from './warehouseComposition.module.css';

import {connect} from 'react-redux';

import TextHalf from './textHalf'
import TextFull from './textFull'
import TextFullHeader from './textFullHeader'
import {modifiDateString, transformData} from '../../../../redux/modifiDate.js'

const StructureLot = (props) => {
    let tmp = null;
    if(props.auction !== null){
        if(props.auction.items.length === 0){
            tmp = <div className={styles.documentElem + ' ' + styles.scladLotuElem}>Додані товари відсутні</div>
        }else{
            /*tmp = props.auction.items
            .map(
                (i, count) => {
                    return viewTypeAuction(i, count, props)
                }
            )*/
            let count = 0
            tmp = []
            for (let key in props.auction.items) {
                /*let tmpZuMap = null
                if(props.auctionId.items[key].location !== null){
                    if((props.auctionId.items[key].location.latitude !== null && props.auctionId.items[key].location.latitude !== '')  && (props.auctionId.items[key].location.longitude !== null && props.auctionId.items[key].location.longitude !== '')){
                        tmpZuMap = <ZuMap
                        latitude = {props.auctionId.items[key].location.latitude}
                        longitude = {props.auctionId.items[key].location.longitude}
                        />
                    }
                }*/
                tmp[count] = <>
                    {viewTypeAuction(props.auction.items[key], count, props)}
                    {/*tmpZuMap*/}
                </>
                count = count++
            }
        }
    }
    return (
        <div className={styles.infoBlock}>
            <h3>СКЛАД ЛОТУ</h3>
            {tmp}
        </div>
    )
}

function viewTypeAuction(data, count, props){
    let tmpAddresPostalCode = null;
    let tmpAddresCountryName = null;
    let tmpAddresRegion = null;
    let tmpAddresLlocality = null;
    let tmpAddresIstreetAddress = null;
    let codKTU = null;
    if(data.hasOwnProperty('address') === true){
        if(data.address !== null){
            if(data.address.hasOwnProperty('postalCode') === true){
                tmpAddresPostalCode = data.address.postalCode;
            }
            if(data.address.hasOwnProperty('countryName') === true){
                tmpAddresCountryName = data.address.countryName.uk_UA;
            }
            if(data.address.hasOwnProperty('region') === true){
                tmpAddresRegion = data.address.region.uk_UA;
            }
            if(data.address.hasOwnProperty('locality') === true){
                tmpAddresLlocality = data.address.locality.uk_UA;
            }
            if(data.address.hasOwnProperty('streetAddress') === true){
                tmpAddresIstreetAddress = data.address.streetAddress.uk_UA;
            }
        }
        if(data.address.hasOwnProperty('addressID') === true){
            if(data.address.addressID !== null){
                if(data.address.addressID.hasOwnProperty('id') === true){
                    if(data.address.addressID.id !== null){
                        codKTU = data.address.addressID.id
                    }
                }
            }
        }
    }

    let tmpClassificationScheme = null
    let tmpClassificationId = null
    let tmpClassificationDescription = null
    if(data.classification !== null){
        if(data.classification.hasOwnProperty('description')){
            tmpClassificationScheme = data.classification.scheme
            tmpClassificationId = data.classification.id
            tmpClassificationDescription = data.classification.description.uk_UA
        }
    }
    let address = `${tmpAddresPostalCode}, ${tmpAddresCountryName}, ${tmpAddresRegion}, ${tmpAddresLlocality}, ${tmpAddresIstreetAddress}`
    let tmpClassificationNameStr = `Класифікатор об'єкта ${tmpClassificationScheme}`
    let tmpClassificationSchemeStr = `${tmpClassificationId} - ${tmpClassificationDescription}`
    return (
        <>
            <div className={styles.borderLine}>
            {/*viewTitle(data)*/}
            </div>
            <TextFull
                title="Опис об'єкта"
                data={data.description.uk_UA}
            />
            <TextFull
                title={tmpClassificationNameStr}
                data={tmpClassificationSchemeStr}
            />
            {data.hasOwnProperty('additionalClassifications') ? <TextFull
                title="Код CPVs:"
                data={additionalClassificationsId(data)}
            /> : null}
            <TextHalf
                title='Місцезнаходження'
                data={address}
            />
            <TextHalf
                title="Код об’єктів адміністративно-територіального устрою україни"
                data={codKTU}
            />
            {/*viewLeaseDuration(props)*/}



            <TextHalf
                title="Кількість одиниць"
                data={data.quantity}
            />
            {unitName(data)}
        </>
    )
}

function viewTitle(data){
    if(data !== null){
        if(data.title !== null){
            return (
                <TextFull
                    title="Назва об'єкта"
                    data={data.title}
                />
            )
        }
    }
    return null
}

function viewLeaseDuration(props){
    if(props.auction !== null){
        if(props.auction.leaseDuration !== null){
            let tmp = transformData(props.auction.leaseDuration, 'Y') + ' ' + transformData(props.auction.leaseDuration, 'M') + ' ' + transformData(props.auction.leaseDuration, 'D')
            return (
                <TextHalf
                    title="Строк, на який надається дозвіл"
                    data={tmp}
                />
            )
        }
    }
    return null
}

function leaseDuration(data){
    if(data !== null){
        if(data.indexOf("P") === 1 && data.indexOf("Y") === 1 && data.indexOf("M") === 1){
            let tmp = data.split('P');
            let tmp1 = tmp[1].split('Y');
            let tmp2 = tmp1[1].split('M');
            let tmp3 = tmp2[1].split('D');
            let tmpYear = null
            let tmpMonth = null
            let tmpDay = null
            if(Number(tmp1[0]) > 0){
                if(Number(tmp1[0]) === 1){
                    tmpYear = tmp1[0] + ' рік '
                }else if(Number(tmp1[0]) > 1 && Number(tmp1[0]) <= 4){
                    tmpYear = tmp1[0] + ' роки '
                }else if(Number(tmp1[0]) > 4 && Number(tmp1[0]) <= 20){
                    tmpYear = tmp1[0] + ' років '
                }else{
                    tmpYear = tmp1[0] + ' р. '
                }
            }
            if(Number(tmp2[0]) > 0){
                if(Number(tmp2[0]) === 1){
                    tmpMonth = tmp2[0] + ' місяць '
                }else if(Number(tmp2[0]) > 1 && Number(tmp2[0]) <= 4){
                    tmpMonth = tmp2[0] + ' місяці '
                }else if(Number(tmp2[0]) > 4 && Number(tmp2[0]) <= 20){
                    tmpMonth = tmp2[0] + ' місяців '
                }
            }
            if(Number(tmp3[0]) > 0){
                if(Number(tmp3[0]) === 1){
                    tmpDay = tmp3[0] + ' день'
                }else if(Number(tmp3[0]) > 1 && Number(tmp3[0]) <= 4){
                    tmpDay = tmp3[0] + ' дні'
                }else if(Number(tmp3[0]) > 4 && Number(tmp3[0]) <= 20){
                    tmpDay = tmp3[0] + ' днів'
                }else if(Number(tmp3[0]) === 21){
                    tmpDay = tmp3[0] + ' день'
                }else if(Number(tmp3[0]) > 21 && Number(tmp3[0]) <= 24){
                    tmpDay = tmp3[0] + ' дні'
                }else{
                    tmpDay = tmp3[0] + ' днів'
                }
            }
            let tmpStringes = ''
            if(tmpYear !== null){
                tmpStringes = tmpStringes + tmpYear
            }
            if(tmpMonth !== null){
                tmpStringes = tmpStringes + ' ' + tmpMonth
            }
            if(tmpDay !== null){
                tmpStringes = tmpStringes + ' ' + tmpDay
            }
            return tmpStringes
        }
    }
}

function additionalClassificationsId(data){
    if(data.hasOwnProperty('additionalClassifications') === true){
        if(data.additionalClassifications.length > 0){
            return `${data.additionalClassifications[0].id} - ${data.additionalClassifications[0].description.uk_UA}`
        }
    }
    return null
}

function unitName(data){
    if(data !== null){
        if(data.unit !== null){
            if(data.unit.name !== null){
                return <TextHalf
                    title="Одиниці виміру"
                    data={data.unit.name.uk_UA}
                />
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        //auction: state.start.auctionsGetId,
        auction: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(StructureLot)
