import React from 'react';
import style from './popupAward.module.css';

import {connect} from 'react-redux';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import {
    auctionsGetAwards,
    auctionsGetAwardsId,
    changeAwardRejectPopUpStatus,
    changeAwardErrorPopUpStatus,
    changeAwardRejectTitle,
    changeAwardRejectDescription,
    changeAwardRejectRestrict,
    changeAwardRejectDocumentType,
    changeAwardDocumentStatus,
} from '../../../redux/actions/awards/awards.js';

import {setUniversalError, changeLoader} from '../../../redux/actions.js';

import {
    togetherApiPopUpOnOff,
    togetherApiDocTitle,
    togetherApiDocDescription,
    togetherApiDocType,
    togetherApiDocLang,
    togetherApiDocFileName,
    togetherApiECP,
} from '../../../redux/togetherApi.js';
import {ecpApiPass, ecpApiList, changeCA} from '../../../redux/ecpApi.js';

import {
    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType
} from '../../../redux/actions/documents/documents.js';

//import ButtonDeleteDocument from './BttonDeleteDocument.js';
import ApproveOrRejectAward from './buttonsBlock.js';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';

import {editAwardDoc} from '../../../redux/editAwardDoc.js';

import {modifiDateString} from '../../../redux/modifiDate'
import {contractsAuctionIdGet} from '../../../redux/actions/contracts/contracts'
import { isLAP, isLAW, isLAE } from '../../../redux/aucTypes';

const DeleteBidDocument = ( props ) => {
    chekContracts(props)
    let tmpButtonTypeDoc = null
    if(props.userProfiles.canCreateBid === true){
        if(props.awardDocument.statusDoc !== 4){
            props.changeAwardDocumentStatus(4)
        }
    }else{
        if(props.popUpAwardReject !== null){
            if(props.awardDocument.statusDoc === 0){
                if(props.auctionAwards !== null){
                    if(props.auctionAwards.length > 1){
                        if(
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-fast" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
                        ){
                            if(props.popUpAwardReject.status !== 'active'){
                                tmpButtonTypeDoc = <div className={style.blockButtonsTypeDoc}>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(1)}}>Затвердити</Button>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(3)}}>Діскваліфікація</Button>
                                </div>
                            }else{
                                tmpButtonTypeDoc = <div className={style.blockButtonsTypeDoc}>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(3)}}>Діскваліфікація</Button>
                                </div>
                            }
                        }else{
                            if(chekContracts(props)){
                                tmpButtonTypeDoc = <div className={style.blockButtonsTypeDoc}>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(3)}}>Діскваліфікація</Button>
                                </div>
                            }else{
                                tmpButtonTypeDoc = <div className={style.blockButtonsTypeDoc}>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(1)}}>Затвердити</Button>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(3)}}>Діскваліфікація</Button>
                                </div>
                            }
                        }
                    }else{
                        if(
                            props.popUpAwardReject.sellingMethod === "railwayCargo-english" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-english-fast" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-english-fast-manual" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-english-initial-auction" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-english-initial-qualification" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-dutch" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-dutch-fast" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-dutch-fast-manual" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-dutch-initial-auction" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-dutch-initial-qualification" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-dutch-fast-auction-manual-qualification" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-dutch-initial-auction-manual"
                        ){
                            tmpButtonTypeDoc = <div className={style.blockButtonsTypeDoc}>
                                <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(1)}}>Затвердити</Button>
                                <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(3)}}>Діскваліфікація</Button>
                            </div>
                        }else if(
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-fast" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
                        ){
                            if(props.popUpAwardReject.status !== 'active'){
                                tmpButtonTypeDoc = <div className={style.blockButtonsTypeDoc}>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(1)}}>Затвердити</Button>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(3)}}>Діскваліфікація</Button>
                                </div>
                            }else{
                                tmpButtonTypeDoc = <div className={style.blockButtonsTypeDoc}>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(3)}}>Діскваліфікація</Button>
                                </div>
                            }
                        }else{
                            if(chekContracts(props)){
                                tmpButtonTypeDoc = <div className={style.blockButtonsTypeDoc}>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(3)}}>Діскваліфікація</Button>
                                </div>
                            }else{
                                tmpButtonTypeDoc = <div className={style.blockButtonsTypeDoc}>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(1)}}>Затвердити</Button>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(3)}}>Діскваліфікація</Button>
                                </div>
                            }
                        }
                    }
                }
            }else if(props.awardDocument.statusDoc === 1){
                if(props.auctionAwards !== null){
                    if(props.auctionAwards.length > 1){
                        if(chekContracts(props)){
                            tmpButtonTypeDoc = <div className={style.blockButtonsTypeDoc}>
                                <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(3)}}>Діскваліфікація</Button>
                            </div>
                        }else{
                            tmpButtonTypeDoc = <div className={style.blockButtonsTypeDoc}>
                                <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(1)}}>Затвердити</Button>
                                <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(3)}}>Діскваліфікація</Button>
                            </div>
                        }
                    }else{
                        if(
                            props.popUpAwardReject.sellingMethod === "railwayCargo-english" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-english-fast" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-english-fast-manual" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-english-initial-auction" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-english-initial-qualification" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-dutch" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-dutch-fast" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-dutch-fast-manual" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-dutch-initial-auction" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-dutch-initial-qualification" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-dutch-fast-auction-manual-qualification" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-dutch-initial-auction-manual"
                        ){
                            tmpButtonTypeDoc = <div className={style.blockButtonsTypeDoc}>
                                    <Button variant="contained" color="primary" onClick = {() => {props.changeAwardDocumentStatus(1)}}>Затвердити</Button>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(3)}}>Діскваліфікація</Button>
                                </div>
                        }else if(
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-fast" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
                        ){
                            if(props.popUpAwardReject.status !== 'active'){
                                tmpButtonTypeDoc = <div className={style.blockButtonsTypeDoc}>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(1)}}>Затвердити</Button>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(3)}}>Діскваліфікація</Button>
                                </div>
                            }else{
                                tmpButtonTypeDoc = <div className={style.blockButtonsTypeDoc}>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(3)}}>Діскваліфікація</Button>
                                </div>
                            }
                        }else{
                            tmpButtonTypeDoc = <div className={style.blockButtonsTypeDoc}>
                                    <Button variant="contained" color="primary" onClick = {() => {props.changeAwardDocumentStatus(1)}}>Затвердити</Button>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(2)}}>Відхилення</Button>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(3)}}>Діскваліфікація</Button>
                                </div>
                        }
                    }
                }
            }else if(props.awardDocument.statusDoc === 2){
                if(props.auctionAwards !== null){
                    if(props.auctionAwards.length > 1){
                        if(chekContracts(props)){
                            tmpButtonTypeDoc = <div className={style.blockButtonsTypeDoc}>
                                <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(3)}}>Діскваліфікація</Button>
                            </div>
                        }else{
                            tmpButtonTypeDoc = <div className={style.blockButtonsTypeDoc}>
                                <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(1)}}>Затвердити</Button>
                                <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(3)}}>Діскваліфікація</Button>
                            </div>
                        }
                    }else{
                        if(
                            props.popUpAwardReject.sellingMethod === "railwayCargo-english" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-english-fast" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-english-fast-manual" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-english-initial-auction" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-english-initial-qualification" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-dutch" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-dutch-fast" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-dutch-fast-manual" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-dutch-initial-auction" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-dutch-initial-qualification" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-dutch-fast-auction-manual-qualification" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-dutch-initial-auction-manual"
                        ){
                            tmpButtonTypeDoc = <div className={style.blockButtonsTypeDoc}>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(1)}}>Затвердити</Button>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(3)}}>Діскваліфікація</Button>
                                </div>
                        }else if(
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-fast" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
                        ){
                            if(props.popUpAwardReject.status !== 'active'){
                                tmpButtonTypeDoc = <div className={style.blockButtonsTypeDoc}>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(1)}}>Затвердити</Button>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(3)}}>Діскваліфікація</Button>
                                </div>
                            }else{
                                tmpButtonTypeDoc = <div className={style.blockButtonsTypeDoc}>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(3)}}>Діскваліфікація</Button>
                                </div>
                            }
                        }else{
                            tmpButtonTypeDoc = <div className={style.blockButtonsTypeDoc}>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(1)}}>Затвердити</Button>
                                    <Button variant="contained" color="primary" onClick = {() => {props.changeAwardDocumentStatus(2)}}>Відхилення</Button>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(3)}}>Діскваліфікація</Button>
                                </div>
                        }
                    }
                }
            }else if(props.awardDocument.statusDoc === 3){
                if(props.auctionAwards !== null){
                    if(props.auctionAwards.length > 1){
                        if(chekContracts(props)){
                            tmpButtonTypeDoc = <div className={style.blockButtonsTypeDoc}>
                                <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(3)}}>Діскваліфікація</Button>
                            </div>
                        }else{
                            tmpButtonTypeDoc = <div className={style.blockButtonsTypeDoc}>
                                <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(1)}}>Затвердити</Button>
                                <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(3)}}>Діскваліфікація</Button>
                            </div>
                        }
                    }else{
                        if(
                            props.popUpAwardReject.sellingMethod === "railwayCargo-english" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-english-fast" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-english-fast-manual" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-english-initial-auction" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-english-initial-qualification" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-dutch" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-dutch-fast" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-dutch-fast-manual" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-dutch-initial-auction" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-dutch-initial-qualification" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-dutch-fast-auction-manual-qualification" ||
                            props.popUpAwardReject.sellingMethod === "railwayCargo-dutch-initial-auction-manual"
                        ){
                            tmpButtonTypeDoc = <div className={style.blockButtonsTypeDoc}>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(1)}}>Затвердити</Button>
                                    <Button variant="contained" color="primary" onClick = {() => {props.changeAwardDocumentStatus(3)}}>Діскваліфікація</Button>
                                </div>
                        }else if(
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-fast" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                            props.popUpAwardReject.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
                        ){
                            if(props.popUpAwardReject.status !== 'active'){
                                tmpButtonTypeDoc = <div className={style.blockButtonsTypeDoc}>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(1)}}>Затвердити</Button>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(3)}}>Діскваліфікація</Button>
                                </div>
                            }else{
                                tmpButtonTypeDoc = <div className={style.blockButtonsTypeDoc}>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(3)}}>Діскваліфікація</Button>
                                </div>
                            }
                        }else{
                            tmpButtonTypeDoc = <div className={style.blockButtonsTypeDoc}>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(1)}}>Затвердити</Button>
                                    <Button variant="contained" onClick = {() => {props.changeAwardDocumentStatus(2)}}>Відхилення</Button>
                                    <Button variant="contained" color="primary" onClick = {() => {props.changeAwardDocumentStatus(3)}}>Діскваліфікація</Button>
                                </div>
                        }
                    }
                }
            }
        }
    }
    return (
        <>
            <div className={style.wrapMain}>
                <div className={style.wrap}>
                    <div
                        className={style.close}
                        onClick={() => {
                            props.changeAwardRejectPopUpStatus(null)
                            props.changeAwardRejectTitle('')
                            props.changeAwardRejectDocumentType('null')
                            props.changeAwardRejectDescription('')
                            props.togetherApiDocFileName(null)
                            props.togetherApiECP(false)
                            props.ecpApiPass('')
                            props.changeCA('null')
                            props.changeDocumentFileName(null)
                        }}
                    >✕</div>

                    <div className={style.popup_main_doc}>
                        <div className={style.dataContracts}>
                            <div className={style.dataContractsTitle}>Дії з учасником</div>
                            <ApproveOrRejectAward />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function chekContracts(props){
    if(props.contractAuctionIdGet !== null && props.popUpAwardReject){
        for(let i = 0; i < props.contractAuctionIdGet.length; i++){
            if(props.popUpAwardReject.awardId === props.contractAuctionIdGet[i].award.id){
                if(props.contractAuctionIdGet[i].lotPaymentConfirmation === false){
                    return true                    
                }
            }
        }
    }
    return false
}


/////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
function viewTypeFormAddDoc(props){
    let tmp = null
    if(props.awardDocument.statusDoc === 0){
        tmp = null
    }else if(props.awardDocument.statusDoc === 1){
        tmp = createViewFormAddDoc(props, 1)
    }else if(props.awardDocument.statusDoc === 2){
        tmp = createViewFormAddDoc(props, 2)
    }else if(props.awardDocument.statusDoc === 3){
        tmp = createViewFormAddDoc(props, 3)
    }else if(props.awardDocument.statusDoc === 4){
        tmp = createViewFormAddDoc(props, 4)
    }
    return tmp
}

function createViewFormAddDoc(props, type){
    let tmpStyle = null
    if(props.togetherApiPage.ecpOnOff === false){
        tmpStyle = style.pseudoCheckboxOn
    }else{
        tmpStyle = style.pseudoCheckboxOff
    }
    return (
        <div className={style.addDocElem}>
            {/*Завантаження нового документу*/}
            <div className={style.chekbox}>
                <div
                    className={tmpStyle}
                    onClick={
                        () => {
                            if(props.togetherApiPage.ecpOnOff === false){
                                props.togetherApiECP(true)
                                epListCertificateAuthority(props)
                            }else{
                                props.togetherApiECP(false)
                            }
                        }
                    }
                >Створення КЕП</div>
            </div>
            {viewAddNewDoc(props, type)}
        </div>
    )
}
/////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////


function viewDocuments(props){
    let tmp = null;
    if(props.popUpAwardReject !== null){
        if(props.popUpAwardReject.awards !== null){
            if(props.auctionAwards !== null){
                if(props.auctionAwards.length > 0){                  
                    if(props.auctionAwards[props.popUpAwardReject.awards].documents !== null){
                        if(props.auctionAwards[props.popUpAwardReject.awards].documents.length > 0){
                            tmp = props.auctionAwards[props.popUpAwardReject.awards].documents.map(
                                (i, count) => {
                                    let tmpText = null
                                    if(i.documentType === 'digitalSignature') tmpText = 'Документ з КЕП'
                                    let datePublished = null
                                    let dateModified = null
                                    if(i.datePublished !== null){
                                        datePublished = modifiDateString(i.datePublished, 3, false)
                                    }
                                    if(i.datePublished !== null){
                                        dateModified = modifiDateString(i.dateModified, 3, false)
                                    }
                                    return (
                                        /*<div
                                            key={count}
                                            className={style.documentsBlock}
                                        >
                                            <div>
                                                <div className={style.documentsBlock}>
                                                    Назва документу {i.title}    
                                                </div>
                                                <div className={style.documentsBlock}>
                                                    Опис документу: {i.description}    
                                                </div>
                                                <div className={style.documentsBlock}>
                                                    <div>Тип документу: {viewDataType(i.documentType)}</div>
                                                    <div><a href={i.url} target='_blank'>Посилання на документ</a></div>
                                                </div>
                                                <div className={style.documentsBlock}>
                                                    <div>Дата створення: {datePublished}</div>
                                                    <div>Дата редагування: {dateModified}</div>
                                                </div>
                                                <div>{tmpText}</div>
                                            </div>
                                            <div>
                                                {viewButtonEditDocument(props, i)}
                                                {viewButtonDeleteDocument(props, i)}
                                            </div>
                                        </div>*/
                                        <>
                                            <a href={i.url} target='_blank'>
                                                <div className={style.lineWrapAwards}>
                                                    <div className={style.lineWrapAwards_text}>
                                                        <div className={style.lineWrapAwards + ' ' + style.notCanter}>
                                                            <div className={style.lineWrapAwards_title}>Документ №:</div><div className={style.lineWrapAwards_content}>{count+1}</div>
                                                        </div>
                                                        <div className={style.lineWrapAwards + ' ' + style.notCanter}>
                                                            <div className={style.lineWrapAwards_title}>Назва:</div><div className={style.lineWrapAwards_content}>{i.title}</div>
                                                        </div>
                                                        <div className={style.lineWrapAwards + ' ' + style.notCanter}>
                                                            <div className={style.lineWrapAwards_title}>Опис:</div><div className={style.lineWrapAwards_content}>{i.description}</div>
                                                        </div>
                                                        <div className={style.lineWrapAwards}>
                                                            <div className={style.lineWrapAwards_title + ' ' + style.notCanter}>Тип документу:</div><div className={style.lineWrapAwards_content + ' ' + style.notCanter}>{viewDataType(i.documentType)}</div>
                                                        </div>
                                                    </div>
                                                    <div className={style.lineWrapAwards_date}>
                                                        <div className={style.lineWrapAwards}>
                                                            <div className={style.lineWrapAwards_title + ' ' + style.notCanter}>Дата створення:</div><div className={style.lineWrapAwards_contentDate}>{datePublished}</div>
                                                        </div>
                                                        <div className={style.lineWrapAwards}>
                                                            <div className={style.lineWrapAwards_title + ' ' + style.notCanter}>Дата редагування:</div><div className={style.lineWrapAwards_contentDate}>{dateModified}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                            <div className={style.blockButton}>
                                                {combineButton(
                                                    props,
                                                    i,
                                                    props.auctionAwards[props.popUpAwardReject.awards].bid.id,
                                                    props.auctionAwards[props.popUpAwardReject.awards]
                                                )}
                                            </div>
                                        </>
                                    )
                                }
                            )
                        }    
                    }
                }
            }
        }
    }
    if(tmp === null){
        //tmp = <div>Завантажені документи відсутні</div>
        tmp = null
    }else if(tmp.length === 0){
        //tmp = <div>Завантажені документи відсутні</div>
        tmp = null
    }
    return tmp
}

function viewDataType(dataType){
    switch (dataType){
	case 'auctionProtocol': return 'Протокол аукціону'
	case 'rejectionProtocol': return 'Документ, що підтверджує дискваліфікацію'
	case 'act': return 'Документ, що підтверджує відмову'
	case 'digitalSignature': return 'Документ з КЕП'
    case 'transferAct': return 'Акт приймання-передачі'
    default: return null
    }
}

function viewDocumentsAucProt(props){
    let tmp = null;
    if(props.popUpAwardReject !== null){
        if(props.popUpAwardReject.awards !== null){
            if(props.auctionAwards !== null){
                if(props.auctionAwards.length > 0){                  
                    if(props.auctionAwards[props.popUpAwardReject.awards].bid.documents !== null){
                        if(props.auctionAwards[props.popUpAwardReject.awards].bid.documents.length > 0){
                            tmp = props.auctionAwards[props.popUpAwardReject.awards].bid.documents
                            .filter(
                                (i) => {
                                    return i.documentType === "auctionProtocol"
                                }
                            )
                            .map(
                                (i, count) => {
                                    let tmpText = null
                                    if(i.documentType === 'digitalSignature') tmpText = 'Документ з КЕП'
                                    let datePublished = null
                                    let dateModified = null
                                    if(i.datePublished !== null){
                                        datePublished = modifiDateString(i.datePublished, 3, false)
                                    }
                                    if(i.datePublished !== null){
                                        dateModified = modifiDateString(i.dateModified, 3, false)
                                    }
                                    return (
                                        /*<div
                                            key={count}
                                            className={style.documentsBlock}
                                        >
                                            <div>
                                                <div>
                                                    Документ завантажений покупцем
                                                </div>
                                                <div className={style.documentsBlock}>
                                                    Назва документу {i.title}    
                                                </div>
                                                <div className={style.documentsBlock}>
                                                    Опис документу: {i.description}    
                                                </div>
                                                <div className={style.documentsBlock}>
                                                    <div>Тип документу: {viewDataType(i.documentType)}</div>
                                                    <div><a href={i.url} target='_blank'>Посилання на документ</a></div>
                                                </div>
                                                <div className={style.documentsBlock}>
                                                    <div>Дата створення: {datePublished}</div>
                                                    <div>Дата редагування: {dateModified}</div>
                                                </div>
                                                <div>{tmpText}</div>
                                            </div>
                                            <div>
                                                {viewButtonEditDocument(props, i, props.auctionAwards[props.popUpAwardReject.awards].bid.id)}            
                                                {viewButtonDeleteDocument(props, i, props.auctionAwards[props.popUpAwardReject.awards].bid.id)}
                                            </div>
                                        </div>*/
                                        <>
                                            <a href={i.url} target='_blank'>
                                                <div className={style.lineWrapAwards}>
                                                    <div className={style.lineWrapAwards_text}>
                                                        <div className={style.lineWrapAwards + ' ' + style.notCanter}>
                                                            <div className={style.lineWrapAwards_title}>Документ №:</div><div className={style.lineWrapAwards_content}>{count+1}</div>
                                                        </div>
                                                        <div className={style.lineWrapAwards + ' ' + style.notCanter}>
                                                            <div className={style.lineWrapAwards_title}>Назва:</div><div className={style.lineWrapAwards_content}>{i.title}</div>
                                                        </div>
                                                        <div className={style.lineWrapAwards + ' ' + style.notCanter}>
                                                            <div className={style.lineWrapAwards_title}>Опис:</div><div className={style.lineWrapAwards_content}>{i.description}</div>
                                                        </div>
                                                        <div className={style.lineWrapAwards + ' ' + style.notCanter}>
                                                            <div className={style.lineWrapAwards_title}>Тип документу:</div><div className={style.lineWrapAwards_content + ' ' + style.notCanter}>{viewDataType(i.documentType)}</div>
                                                        </div>
                                                    </div>
                                                    <div className={style.lineWrapAwards_date}>
                                                        <div className={style.lineWrapAwards + ' ' + style.notCanter}>
                                                            <div className={style.lineWrapAwards_title}>Дата створення:</div><div className={style.lineWrapAwards_contentDate}>{datePublished}</div>
                                                        </div>
                                                        <div className={style.lineWrapAwards + ' ' + style.notCanter}>
                                                            <div className={style.lineWrapAwards_title}>Дата редагування:</div><div className={style.lineWrapAwards_contentDate}>{dateModified}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                            <div className={style.blockButton}>
                                                {combineButton(
                                                    props,
                                                    i,
                                                    props.auctionAwards[props.popUpAwardReject.awards].bid.id,
                                                    props.auctionAwards[props.popUpAwardReject.awards]
                                                )}
                                            </div>
                                        </>
                                    )
                                }
                            )
                        }    
                    }
                }
            }
        }
    }
    if(tmp === null){
        //tmp = <div>Завантажені документи відсутні</div>
        tmp = null
    }else if(tmp.length === 0){
        //tmp = <div>Завантажені документи відсутні</div>
        tmp = null
    }
    return tmp
}

function combineButton(props, i, bidId = null, ownerToken){
    if(ownerToken.bid.createdByCurrentUser === true){
        if(i.documentOf !== "award"){
            return <>
                {viewButtonEditDocument(props, i, bidId)}
                {viewButtonDeleteDocument(props, i, bidId)}
            </>
        }else{
            return null
        }
    }else{
        if(i.documentOf !== "bid"){
            return <>
                {viewButtonEditDocument(props, i, bidId)}
                {viewButtonDeleteDocument(props, i, bidId)}
            </>
        }else{
            return null
        }
    }
}

function viewButtonEditDocument(props, i, bidId = null){
    let tmp = null
    if(props.popUpAwardReject !== null){
        if(props.popUpAwardReject.awards !== null){
            if(props.auctionAwards !== null){
                if(props.auctionAwards.length > 0){
                    if(props.auctionAwards[props.popUpAwardReject.awards].status !== 'active'){
                        //if(i.createdByCurrentUser === true){
                            tmp = <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={
                                        () => {
                                            let tmpObj = {
                                                document: i,
                                                bidId: bidId,
                                                status: 'AWARD'
                                            }
                                            props.editAwardDoc(tmpObj)
                                        }
                                    }
                                >
                                    Редагувати
                                </Button>
                        //}
                    }
                }
            }
        }
    }
    return tmp
}

function viewButtonDeleteDocument(props, i, bidId = null){
    let tmp = null
    if(props.popUpAwardReject !== null){
        if(props.popUpAwardReject.awards !== null){
            if(props.auctionAwards !== null){
                if(props.auctionAwards.length > 0){
                    if(props.auctionAwards[props.popUpAwardReject.awards].status !== 'active'){
                        //if(i.createdByCurrentUser === true){
                            tmp = <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={
                                        () => {
                                            deleteAwardDocument(props, i, bidId)
                                        }
                                    }
                                >
                                    Видалити
                                </Button>
                        //}
                    }
                }
            }
        }
    }
    //return tmp
    return null
}

async function deleteAwardDocument(props, id, bidId){
    //if(bidId === null){
    //let endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/documents/${id.id}`
    //}else{
        //endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/bid/${bidId}/documents/${id.id}`
    //}

    let endPoint = null
    if(props.userProfiles.canCreateBid === true){
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/bid/${bidId}/documents/${id.id}`;
    }else{
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/documents/${id.id}`
    }
    const response = await fetch(endPoint, {
        method: "DELETE",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при видаленні документу', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }else{
            let header = null;
            if(props.token !== null){
                header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }else{
                header = {
                    'Content-Type': 'application/json'
                }
            }
            let data5 = {
                domen: process.env.REACT_APP_END_POINT_BD,
                version: 'v1',
                local: props.langFlag
            }
            if(props.popUpAwardReject.sellingMethod !== "dgf-english" ||
                props.popUpAwardReject.sellingMethod !== "dgf-english-fast" ||
                props.popUpAwardReject.sellingMethod !== "dgf-english-fast-manual" ||
                props.popUpAwardReject.sellingMethod !== "dgf-english-fast-auction-manual-qualification" ||
                props.popUpAwardReject.sellingMethod !== "dgf-english-initial-auction" ||
                props.popUpAwardReject.sellingMethod !== "dgf-english-initial-auction-manual" ||
                props.popUpAwardReject.sellingMethod !== "dgf-english-initial-qualification" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast-manual" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast-auction-manual-qualification" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-auction" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-qualification" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-auction-manual"
            ){
                const urlAsync = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/sync`;
                const responseAsync = await fetch(urlAsync, {
                    method: "GET",
                    headers: {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
                }).catch(console.error)
                if(responseAsync === undefined || responseAsync === 'undefined'){
                    await props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
                    await props.contractsAuctionIdGet(data5, header, props.popUpAwardReject.aucId)
                }else{
                    await props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
                    await props.contractsAuctionIdGet(data5, header, props.popUpAwardReject.aucId)
                }
            }else{
                await props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
                await props.contractsAuctionIdGet(data5, header, props.popUpAwardReject.aucId)
            }
        }
    }
}

function viewAddNewDoc(props, type){
    if(props.auctionAwards[props.popUpAwardReject.awards].bid.createdByCurrentUser === true){
        if(props.auctionAwards[props.popUpAwardReject.awards].status === 'pending_waiting'){
            return null
        }else{
            return (
                <>
                    <div className={style.uploadDoc}>
                        <div>
                            <TextField
                                required
                                id="filled-required"
                                className={style.inputText}
                                label="Назва документу"
                                variant="outlined"
                                value={props.awardReject.title}
                                onChange={
                                    (e) => {
                                        props.changeAwardRejectTitle(e.target.value)
                                    }
                                }
                            />
                        </div>
                        {viewType(props, type)}
                        {viewDescription(props, type)}
                    </div>
                    <div className={style.uploadDoc}>
                        {createView(props)}
                    </div>
                </>
            )    
        }
    }else{
        if(props.auctionAwards[props.popUpAwardReject.awards].status !== 'pending_waiting'){
            return (
                <>
                    <div className={style.uploadDoc}>
                        <div>
                            <TextField
                                required
                                id="filled-required"
                                className={style.inputText}
                                label="Назва документу"
                                variant="outlined"
                                value={props.awardReject.title}
                                onChange={
                                    (e) => {
                                        props.changeAwardRejectTitle(e.target.value)
                                    }
                                }
                            />
                        </div>
                        {viewType(props, type)}
                        {viewDescription(props, type)}
                        
                    </div>
                    <div className={style.uploadDoc + ' ' + style.widthBlok}>
                        {createView(props)}
                    </div>
                </>
            )
        }else{
            return null
        }
    }
}


///////////////////////////////////////////////////////////////////////////////////

function blocEcpData(props){
    let fileElem = null;
    if(props.togetherApiPage.docData.fileName === null){
        fileElem = <span className={style.input__file_button_text}>
            <span>Вибрати ключ КЕП</span>
        </span>
    }else{
        fileElem = <span className={style.input__file_button_text}>
            <span>Обраний ключ КЕП:</span> <span>{props.togetherApiPage.docData.fileName}</span>
        </span>
    }
    return (
        <div className={style.uploadDoc + ' ' + style.widthBlok}>
            <div>
                <input
                    name="file"
                    type="file"
                    id="input__fileECP"
                    className={style.input + ' ' + style.input__file}
                    onChange={() => {
                        let addFileInp1 = document.getElementById('input__fileECP');
                        props.togetherApiDocFileName(addFileInp1.files[0].name)
                    }}
                />
                <label for="input__fileECP" className={style.input__file_button}>
                    {fileElem}
                </label>
            </div>
            {listCA(props)}
            <div>
                <div>
                    <TextField
                        required
                        id="filled-required"
                        className={style.inputText}
                        label="Пароль"
                        variant="outlined"
                        value={props.ecpData.pass}
                        onChange={
                            (e) => {
                                props.ecpApiPass(e.target.value)
                            }
                        }
                    />
                </div>
            </div>
        </div>
    )
}

function listCA(props){
    return <div className={style.groupElem}>
        <div>
            <TextField
                id="outlined-select-currency-native"
                className={style.selectWidth}
                select
                label="КНЕПД/АЦСК"
                value={props.togetherApiPage.listChoise}
                onChange={(event)=>{
                    props.changeCA(event.target.value)
                }}
                variant="outlined"
            >
                <option key='null' selected disabled value="null">Обрати</option>
                {optionCA(props)}
            </TextField>
        </div>
    </div>
}

function optionCA(props){
    let tmp = null
    if(props.togetherApiPage.list !== null){
        if(props.togetherApiPage.list.ca.length > 0){
            tmp = props.togetherApiPage.list.ca.map(
                (i, count) => {
                    return <option key={count} value={i.id}>{i.name}</option>
                }
            )
        }
    }
    return tmp
}


///////////////////////////////////////////////////////////////////////////////////

function viewType(props, type){
    let tmp = null
    if(props.popUpAwardReject !== null){
        if(props.popUpAwardReject.awards !== null){
            if(props.auctionAwards !== null){
                if(props.auctionAwards.length > 0){
                    if(props.auctionAwards[props.popUpAwardReject.awards].bid.createdByCurrentUser === true){
                        if(props.auctionAwards[props.popUpAwardReject.awards].status === 'pending' ||
                            props.auctionAwards[props.popUpAwardReject.awards].status === 'active'
                        ){
                            tmp = <div>
                            <div>Обрати тип документу</div>
                                <select
                                    className={style.validfilterSelect}
                                    value={props.awardReject.type}
                                    onChange={
                                        (e) => {
                                            props.changeAwardRejectDocumentType(e.target.value)
                                        }
                                    }
                                >
                                    <option key='null' selected disabled value="null">Обрати</option>
                                    {viewOptionsDocType(props, 1)}
                                </select>
                            </div>
                        }else{
                            tmp = <div>
                            <div>Оберить тип документу</div>
                                <select
                                    className={style.validfilterSelect}
                                    value={props.awardReject.type}
                                    onChange={
                                        (e) => {
                                            props.changeAwardRejectDocumentType(e.target.value)
                                        }
                                    }
                                >
                                    <option key='null' selected disabled value="null">Обрати</option>
                                    {viewOptionsDocType(props, 2)}
                                </select>
                            </div>
                        }
                    }else{
                        tmp = <div>
                        <div>Оберить тип документу</div>
                            <select
                                className={style.validfilterSelect}
                                value={props.awardReject.type}
                                onChange={
                                    (e) => {
                                        props.changeAwardRejectDocumentType(e.target.value)
                                    }
                                }
                            >
                                <option key='null' selected disabled value="null">Обрати</option>
                                {viewOptionsDocType(props, 3, type)}
                            </select>
                        </div>
                    }
                }
            }
        }
    }
    return tmp
}

function viewOptionsDocType(props, type, typeDoc){
    if(type === 1){
        return (
            <>
                {viewOption(props, 'auctionProtocol', 'Протокол аукціону')}
            </>
        )
    }else if(type === 2){
        return (
            <>
                {viewOption(props, 'act', 'Документ, що підтверджує відмову')}
            </>
        )
    }else if(type === 3){
        if(typeDoc === 1){
            return viewOption(props, 'auctionProtocol', 'Протокол аукціону')
        }else if(typeDoc === 2){
            return viewOption(props, 'rejectionProtocol', 'Документ, що підтверджує дискваліфікацію')
        }else if(typeDoc === 3){
            if(chekContracts(props)){
                return (
                    <>
                        {viewOption(props, 'rejectionProtocol', 'Документ, що підтверджує дискваліфікацію')}
                    </>
                )
            }else{
                return (
                    <>
                        {viewOption(props, 'rejectionProtocol', 'Документ, що підтверджує дискваліфікацію')}
                        {viewOption(props, 'act', 'Документ, що підтверджує відмову')}
                    </>
                )
            }
                
        }
    }
}

function viewOption(props, type, typeText){
    /*if(props.popUpAwardReject !== null){
        if(props.popUpAwardReject.awards !== null){
            if(props.auctionAwards !== null){
                if(props.auctionAwards.length > 0){                  
                    if(props.auctionAwards[props.popUpAwardReject.awards].documents !== null){
                        if(props.auctionAwards[props.popUpAwardReject.awards].documents.length > 0){
                            for(let t = 0; t < props.auctionAwards[props.popUpAwardReject.awards].documents.length; t++){
                                if(props.auctionAwards[props.popUpAwardReject.awards].documents[t].documentType === type){
                                    if(props.auctionAwards[props.popUpAwardReject.awards].documents[t].createdByCurrentUser === true){
                                        return null
                                    }
                                }
                            }
                        }    
                    }
                }
            }
        }
    }*/
    return <option value={type}>{typeText}</option>
}

function viewDescription(props, type){
    let tmp = null
    //if(props.awardReject.type === 'rejectionProtocol' || props.awardReject.type === 'act'){
    /*if(type === 3){
        if(props.auctionAwards[props.popUpAwardReject.awards].auction.createdByCurrentUser !== false){ 
            tmp =  <div>
                <TextField
                    id="outlined-select-currency-native"
                    className={style.selectWidth}
                    select
                    label="Причина діскваліфікації"
                    value={props.awardReject.restrict}
                    onChange={(event)=>{
                        //props.changeAwardRejectRestrict(event.target.value)
                        props.changeAwardRejectDescription(' ')
                    }}
                    helperText="Причина діскваліфікації"
                    variant="outlined"
                >
                    <option key='null' selected disabled value="null">Обрати</option>
                    <option value='Відмовився від підписання протоколу'>Відмовився від підписання протоколу</option>
                    <option value='Невідповідність встановленим вимогам'>Невідповідність встановленим вимогам</option>
                    <option value='Відмовився від підписання договору купівлі-продажу лота'>Відмовився від підписання договору купівлі-продажу лота</option>
                    <option value='Не підписав договір купівлі-продажу лота в установлений строк'>Не підписав договір купівлі-продажу лота в установлений строк</option>
                    <option value='Вчасно не здійснив передоплату за договором купівлі-продажу лота'>Вчасно не здійснив передоплату за договором купівлі-продажу лота</option>
                </TextField>
            </div>
        }else{
            tmp = <TextField
                required
                id="filled-required"
                className={style.inputText}
                label="Опис"
                variant="outlined"
                value={props.awardReject.description}
                onChange={
                    (e) => {
                        props.changeAwardRejectDescription(e.target.value)
                    }
                }
            />
        }
    }else{*/
        tmp = <TextField
            id="filled-required"
            className={style.inputText}
            label="Опис"
            variant="outlined"
            value={props.awardReject.description}
            onChange={
                (e) => {
                    props.changeAwardRejectDescription(e.target.value)
                }
            }
        />
    //}
    return tmp
}

function createView(props){
    let tmp = null;
    tmp = (
        <div className={style.documentsForm + ' ' + style.widthBlok}>
            <div className={style.groupElem + ' ' + style.widthBlok}>
                {buttonCreateImg(props)}
            </div>
        </div>
    )
    return tmp
}

function buttonCreateImg(props){
    let tmpEcp = null
    if(props.togetherApiPage.ecpOnOff === true){
        tmpEcp = blocEcpData(props)
    }
    let fileElem = null;
    if(props.createDocuments.fileName === null){
        fileElem = <span className={style.input__file_button_text}>
            <span>Додати документ</span>
        </span>
    }else{
        fileElem = <span className={style.input__file_button_text}>
            <span>Доданий документ:</span> <span>{props.createDocuments.fileName}</span>
        </span>
    }
    return (
        <div className={style.formLine + ' ' + style.widthBlok}>
            <form 
                onSubmit={(e) => {
                    handleSubmit(e, props)
                }}
                id="addFileForm"
                className={style.widthBlok}
            >
                <div className={style.input__wrapper}>
                    <input
                        name="file"
                        type="file"
                        id="input__fileDel"
                        className={style.input + ' ' + style.input__file}
                        onChange={
                            (e) => {
                                let addFileInp1 = document.getElementById('input__fileDel');
                                props.changeDocumentFileName(addFileInp1.files[0].name)
                            }
                        }
                    />
                    <label for="input__fileDel" className={style.input__file_button}>
                        {fileElem}
                    </label>
                </div>
                <div className={style.uploadDoc + ' ' + style.widthBlok}>
                    {tmpEcp}
                </div>
                {onOffButtonAddDoc(props)}
            </form>
        </div>
    )
}

function onOffButtonAddDoc(props){
    let tmp = null;
    if(props.awardReject.title !== null !== null && props.awardReject.type !== null){
        if(props.togetherApiPage.ecpOnOff === false){
            if(
                props.awardReject.title !== null &&
                props.awardReject.type !== null &&
                //props.awardReject.description !== null &&
                props.createDocuments.fileName !== null &&
                props.awardReject.title !== 'null' &&
                props.awardReject.type !== 'null' &&
                //props.awardReject.description !== 'null' &&
                props.createDocuments.fileName !== 'null' &&
                props.awardReject.title !== '' &&
                props.awardReject.type !== '' &&
                //props.awardReject.description !== '' &&
                props.createDocuments.fileName !== ''
            ){
                tmp = <button
                    type="submit"
                    className={style.popup_message_button}
                >Додати документ</button>
            }else{
                tmp = <div
                    className={style.popup_message_button + ' ' + style.popup_message_buttonOff}
                >
                    Додати документ
                </div>
            }
        }else{
            if(
                props.awardReject.title !== null &&
                props.awardReject.type !== null &&
                //props.awardReject.description !== null &&
                props.createDocuments.fileName !== null &&
                props.awardReject.title !== 'null' &&
                props.awardReject.type !== 'null' &&
                //props.awardReject.description !== 'null' &&
                props.createDocuments.fileName !== 'null' &&
                props.awardReject.title !== '' &&
                props.awardReject.type !== '' &&
                //props.awardReject.description !== '' &&
                props.createDocuments.fileName !== '' &&
                props.ecpData.pass !== null &&
                props.togetherApiPage.listChoise !== null &&
                props.togetherApiPage.docData.fileName !== null &&
                props.ecpData.pass !== 'null' &&
                props.togetherApiPage.listChoise !== 'null' &&
                props.togetherApiPage.docData.fileName !== 'null' &&
                props.ecpData.pass !== '' &&
                props.togetherApiPage.listChoise !== '' &&
                props.togetherApiPage.docData.fileName !== ''
            ){
                tmp = <button
                    type="submit"
                    className={style.popup_message_button}
                >Додати документ</button>
            }else{
                tmp = <div
                    className={style.popup_message_button + ' ' + style.popup_message_buttonOff}
                >
                    Додати документ
                </div>
            }
        }
    }else{
        tmp = <div
            className={style.popup_message_button + ' ' + style.popup_message_buttonOff}
        >
            Додати документ
        </div>
    }
    return tmp
}

function handleSubmit(e, props){
    e.preventDefault()
    if(props.togetherApiPage.ecpOnOff === true){
        signetDoc(props)
    }else{
        uploadNewDoc(props)
    }
}


///////////////////////////////////////////////////////////////////////////////////
async function epUploadDataProzzoro(props){
    const addFileInp = document.getElementById('input__fileDel');
    let endPoint = ``;
    if(
        props.popUpAwardReject.sellingMethod === "dgf-english" ||
        props.popUpAwardReject.sellingMethod === "dgf-english-fast" ||
        props.popUpAwardReject.sellingMethod === "dgf-english-fast-manual" ||
        props.popUpAwardReject.sellingMethod === "dgf-english-fast-auction-manual-qualification" ||
        props.popUpAwardReject.sellingMethod === "dgf-english-initial-auction" ||
        props.popUpAwardReject.sellingMethod === "dgf-english-initial-auction-manual" ||
        props.popUpAwardReject.sellingMethod === "dgf-english-initial-qualification" ||
        props.popUpAwardReject.sellingMethod === "dgf-dutch" ||
        props.popUpAwardReject.sellingMethod === "dgf-dutch-fast" ||
        props.popUpAwardReject.sellingMethod === "dgf-dutch-fast-manual" ||
        props.popUpAwardReject.sellingMethod === "dgf-dutch-fast-auction-manual-qualification" ||
        props.popUpAwardReject.sellingMethod === "dgf-dutch-initial-auction" ||
        props.popUpAwardReject.sellingMethod === "dgf-dutch-initial-qualification" ||
        props.popUpAwardReject.sellingMethod === "dgf-dutch-initial-auction-manual"

    ){
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload?isDgf=1`;
    }else{
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload`;
    }
    const formData = new FormData();
    formData.append('documentType', props.createDocuments.documentType)
    formData.append('file', addFileInp.files[0])
    const response =  await fetch(endPoint, {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': props.token.access_token,
        }
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

async function epCreateDocProzzoro(props, data){
    let tmpDisc = null
    if(props.createDocuments.description !== null && props.createDocuments.description !== ''){
        tmpDisc = props.createDocuments.description
    }
    const json = await data
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/document`;
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify({
            title: props.createDocuments.title,
            description: tmpDisc,
            documentType: props.createDocuments.documentType,
            language: props.createDocuments.language,              //не меняем
            documentOf: 'award',          //не меняем
            //hash: "string",
            token: json.jwt                 //не меняем
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

async function epAddDocToAucProzzoro(props, data){
    const json = await data
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}/documents/${json.id}`;
    let endPoint2 = null
    if(props.awardReject.type !== 'auctionProtocol'){
        if(props.userProfiles.canCreateBid === true){
            endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/bid/${props.popUpAwardReject.bidId.id}/documents/${json.id}`
        }else{
            endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/documents/${json.id}`
        }
    }else{
        endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/documents/${json.id}`
    }
    const response = await fetch(endPoint2, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        let tmp = await response.json()
        for(let i = 0; i < tmp.documents.length; i++){
            if(tmp.documents[i].id === json.id) return tmp.documents[i].documentId
        }
        return null
    }
}



///////////////////////////////////////////

/////////////////////////////////////////////
//Креплю КЕП
/////////////////////////////////////////////
async function signetDoc(props){
    await props.changeLoader(true)
    let prozzoroJWTFile = await epUploadDataProzzoro(props)
    let propzzoroDocId = await epCreateDocProzzoro(props, prozzoroJWTFile)
    let prozzoroStringId = await epAddDocToAucProzzoro(props, propzzoroDocId)
    if(prozzoroStringId !== null || prozzoroStringId !== undefined || prozzoroStringId !== 'undefined'){
        let data = await epSessionStart()       //Создаем сессию
        await epSessionTest(data)               //Проверка создания сессии
        await epSessionDataLoad(data)           //Загрузка документа
        await epMetadata(props, data)                  //Загрузка метадаты
        let param = {
            caId: props.togetherApiPage.listChoise,
            cadesType: "CAdESXLong",
            cleanUpForm: false,
            dataToSignQualifier: "notSignedBefore",
            dataTsVerifyOption: "ignore",
            duplicateSign: false,
            embedCertificateType: "signerAndCaCert",
            embedDataTs: "true",
            embedDataTsChecked: true,
            embedSignatureTs: "true",
            embedSignatureTsChecked: true,
            expandCreateDSCadesType: false,
            expandCreateDSTSVerify: false,
            expandCreateDSType: true,
            generateQR: false,
            showUpgradeCard: false,
            signatureTsVerifyOption: "ignore",
            signatureType: "attached",
            tsAdditionalVerifying: false,
        }
        await epSessionSetParam2(data, param)   //Настройка параметров сессии
        await epSessionMainContainer(data)      //Загрузка ключа
        await epCreateEP(props, data)                  //Подгрузка пароля ключа
        let data1 = await epGetDataEP(data)                 //Получить данные
        await epGetCreatorDataEP(data)          //Получить данные
        await epSessionDelete(data)             //Закрытие сессии*/
        await epUploadData(props, data1, prozzoroStringId)
        //Тут добавить создание документа и привязку его к биду
    }
    await props.changeLoader(false)
}

/**
 * Создание сессии
 */
async function epSessionStart(){
    let url = 'https://caas-api.dzo.com.ua/ccs/api/v1/ticket';
    const response = await fetch(url, {
            method: "POST",
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Проверка наличия сессии
 */
async function epSessionTest(data){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}`;
        const response = await fetch(url, {
        method: "GET",
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Загрузить данные сессии
 * Заменить
 */ 
async function epSessionDataLoad(data){
    const addFileInp = document.getElementById('input__fileDel');
    const url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}/data`;
    const formData = new FormData();
    formData.append('uploadTicketData', addFileInp.files[0])
    const response = await fetch(url, {
        method: "POST",
        /*headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            base64Data: btoa(addFileInp.files[0])
        })*/
        body: formData
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Передача мета данных
 */ 
async function epMetadata(props, data){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}/metadata`;

    const response = await fetch(url, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            metaData: props.createDocuments.fileName
        })
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Загрузить данные сессии
 */
async function epSessionSetParam2(data, param){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}/option`;
    const response = await fetch(url, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(param)
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Загрузить ключевой контейнер сессии
 */
async function epSessionMainContainer(data){
    const addFileInpEP = document.getElementById('input__fileECP');
    const file = await toBase64(addFileInpEP.files[0])

    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}/keyStore`;
    const response = await fetch(url, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json' //Base64
        },
        body: JSON.stringify({
            "base64Data": file.substr(37)
        })
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    //reader.readAsBinaryString(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

/**
 * Создать ЭП
 */
async function epCreateEP(props, data){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}/ds/creator`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            //keyStorePassword: "tect3"
            keyStorePassword: props.ecpData.pass
        })
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Получить данные ЭП
 */
async function epGetDataEP(data){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}/ds/base64Data`;
    const response =  fetch(url, {
        method: "GET",
    })
    .then(
        (response) => {
           return response.json()
        }
    )
    .catch(console.error)
    return response
}

async function epUploadData(props, data, relatedDocument){
    const base64 = `data:application/pkcs7-signature;base64,${data.base64Data}`  //Файл в бейс 64
    fetch(base64)
    .then(res => res.blob())
    .then(blob => {
        upload(props, blob, relatedDocument)
    })
}

async function upload(props, blob, relatedDocument) {
    return new Promise(function(resolve, reject){
        let request = new XMLHttpRequest();
        let jsonData;
        request.onload = function(e) {
            if(request.status === 200 && request.readyState === 4){
                jsonData = JSON.parse(request.responseText);
                const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/document`;
                let tmpDisc = null
                if(props.createDocuments.description !== null && props.createDocuments.description !== ''){
                    tmpDisc = props.createDocuments.description
                }
                let typeOfAward = 'award'
                //if(props.awardReject.type === 'auctionProtocol') typeOfAward = 'bid'
                const response = fetch(endPoint, {
                    method: "POST",
                    body: JSON.stringify({
                        title: props.awardReject.title,
                        description: tmpDisc,
                        documentType: 'digitalSignature',
                        language: 'uk',
                        documentOf: typeOfAward,          //не меняем
                        //hash: "string",
                        token: jsonData.jwt,
                        relatedDocument: relatedDocument
                    }),
                    headers: {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
                })
                .then(
                    async (response) => {
                        const json = await response.json()
                        let endPoint2 = null
                        if(props.awardReject.type === 'auctionProtocol'){
                            if(props.userProfiles.canCreateBid === true){
                                endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/bid/${props.popUpAwardReject.bidId.id}/documents/${json.id}`
                            }else{
                                endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/documents/${json.id}`
                            }
                        }else{
                            endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/documents/${json.id}`
                        }
                        const response2 = await fetch(endPoint2, {
                            method: "POST",
                            headers: {
                                'Authorization': props.token.access_token,
                                'Content-Type': 'application/json'
                            }
                        }).catch(console.error)
                        if(response2 === undefined || response2 === 'undefined'){
                        }else{
                            let header = {
                                'Authorization': props.token.access_token,
                                'Content-Type': 'application/json'
                            }
                            const endPoint3 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}`;
                            let response3 = null
                            if(props.popUpAwardReject.sellingMethod !== "dgf-english" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-english-fast" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-english-fast-manual" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-english-fast-auction-manual-qualification" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-english-initial-auction" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-english-initial-auction-manual" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-english-initial-qualification" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-dutch" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast-manual" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast-auction-manual-qualification" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-auction" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-qualification" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-auction-manual"
                            ){
                                const urlAsync = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/sync`;
                                const responseAsync = await fetch(urlAsync, {
                                    method: "GET",
                                    headers: {
                                        'Authorization': props.token.access_token,
                                        'Content-Type': 'application/json'
                                    }
                                }).catch(console.error)
                                if(responseAsync === undefined || responseAsync === 'undefined'){
                                    response3 = await fetch(endPoint3, {
                                        method: "GET",
                                        headers: header
                                    }).catch(console.error)
                                }else{
                                    response3 = await fetch(endPoint3, {
                                        method: "GET",
                                        headers: header
                                    }).catch(console.error)
                                }
                            }else{
                                response3 = await fetch(endPoint3, {
                                    method: "GET",
                                    headers: header
                                }).catch(console.error)
                            }

                            
                            if(response3 === undefined || response3 === 'undefined'){
                            }else{
                                const json3 = await response3.json()
                                if(json3.hasOwnProperty('message') === true){
                                    //props.changeLoader(false)
                                }else{
                                    let header = null;
                                    if(props.token !== null){
                                        header = {
                                            'Authorization': props.token.access_token,
                                            'Content-Type': 'application/json'
                                        }
                                    }else{
                                        header = {
                                            'Content-Type': 'application/json'
                                        }
                                    }
                                    let data5 = {
                                        domen: process.env.REACT_APP_END_POINT_BD,
                                        version: 'v1',
                                        local: props.langFlag
                                    }

                                    if(props.popUpAwardReject.sellingMethod !== "dgf-english" ||
                                        props.popUpAwardReject.sellingMethod !== "dgf-english-fast" ||
                                        props.popUpAwardReject.sellingMethod !== "dgf-english-fast-manual" ||
                                        props.popUpAwardReject.sellingMethod !== "dgf-english-fast-auction-manual-qualification" ||
                                        props.popUpAwardReject.sellingMethod !== "dgf-english-initial-auction" ||
                                        props.popUpAwardReject.sellingMethod !== "dgf-english-initial-auction-manual" ||
                                        props.popUpAwardReject.sellingMethod !== "dgf-english-initial-qualification" ||
                                        props.popUpAwardReject.sellingMethod !== "dgf-dutch" ||
                                        props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast" ||
                                        props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast-manual" ||
                                        props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast-auction-manual-qualification" ||
                                        props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-auction" ||
                                        props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-qualification" ||
                                        props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-auction-manual"
                                    ){
                                        const urlAsync = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/sync`;
                                        const responseAsync = await fetch(urlAsync, {
                                            method: "GET",
                                            headers: {
                                                'Authorization': props.token.access_token,
                                                'Content-Type': 'application/json'
                                            }
                                        }).catch(console.error)
                                        if(responseAsync === undefined || responseAsync === 'undefined'){
                                            props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
                                            props.contractsAuctionIdGet(data5, header, props.popUpAwardReject.aucId)
                                        }else{
                                            props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
                                            props.contractsAuctionIdGet(data5, header, props.popUpAwardReject.aucId)
                                        }
                                    }else{
                                        props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
                                        props.contractsAuctionIdGet(data5, header, props.popUpAwardReject.aucId)
                                    }

                                    props.changeAwardRejectTitle('')
                                    props.changeAwardRejectDocumentType('null')
                                    props.changeAwardRejectDescription('')
                                    props.togetherApiDocFileName(null)
                                    props.togetherApiECP(false)
                                    props.ecpApiPass('')
                                    props.changeCA('null')
                                    props.changeDocumentFileName(null)
                                    /*
                                    props.changeDocumentId(null)
                                    props.changeDocumentTitle('')
                                    props.changeDocumentDescription('')
                                    props.changeDocumentType('null')
                                    props.changeDocumentLang('null')
                                    const addFileInp = document.getElementById('input__fileDel');
                                    addFileInp.value = null;
                                    const addFileInpEP = document.getElementById('input__fileECP');
                                    addFileInpEP.value = null;
                                    props.ecpApiPass('')
                                    props.changeDocumentFileName(null)
                                    props.changeLoader(false)*/
                                }
                            }
                        }
                    }
                )
                resolve(jsonData);
            }else{
                reject(request.status);
            }
        }
        request.onerror = function (error) {
            reject(error);
        }
        let fd = new FormData();
        //fd.append('name', "test.txt");
        let fileName = props.createDocuments.fileName + '.p7s'
        fd.append('name', fileName)
        fd.append("file", blob);
        //fd.append('documentType', 'illustration')
        fd.append('documentType', 'digitalSignature')
        fd.append('Content-Type', 'multipart/form-data')
        fd.append('Authorization', props.token.access_token)
        if(
            props.popUpAwardReject.sellingMethod === "dgf-english" ||
            props.popUpAwardReject.sellingMethod === "dgf-english-fast" ||
            props.popUpAwardReject.sellingMethod === "dgf-english-fast-manual" ||
            props.popUpAwardReject.sellingMethod === "dgf-english-fast-auction-manual-qualification" ||
            props.popUpAwardReject.sellingMethod === "dgf-english-initial-auction" ||
            props.popUpAwardReject.sellingMethod === "dgf-english-initial-auction-manual" ||
            props.popUpAwardReject.sellingMethod === "dgf-english-initial-qualification" ||
            props.popUpAwardReject.sellingMethod === "dgf-dutch" ||
            props.popUpAwardReject.sellingMethod === "dgf-dutch-fast" ||
            props.popUpAwardReject.sellingMethod === "dgf-dutch-fast-manual" ||
            props.popUpAwardReject.sellingMethod === "dgf-dutch-fast-auction-manual-qualification" ||
            props.popUpAwardReject.sellingMethod === "dgf-dutch-initial-auction" ||
            props.popUpAwardReject.sellingMethod === "dgf-dutch-initial-qualification" ||
            props.popUpAwardReject.sellingMethod === "dgf-dutch-initial-auction-manual"
        
        ){
            request.open('POST', `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload?isDgf=1`, true);
        }else{
            request.open('POST', `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload`, true);
        }
        request.send(fd);
    })
}

/**
 * Получить данные ЭП
 */
async function epGetCreatorDataEP(data){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}/ds/creator`;
    const response =  fetch(url, {
        method: "GET",
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Удаление сессии
 * @param {*} data 
 */
async function epSessionDelete(data){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}`;
    const response = await fetch(url, {
        method: "DELETE",
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}
///////////////////////////////////////////

async function uploadNewDoc(props){
    props.changeLoader(true)
    const addFileInp = document.getElementById('input__fileDel');
    let endPoint = ``;
    if(
        props.popUpAwardReject.sellingMethod === "dgf-english" ||
        props.popUpAwardReject.sellingMethod === "dgf-english-fast" ||
        props.popUpAwardReject.sellingMethod === "dgf-english-fast-manual" ||
        props.popUpAwardReject.sellingMethod === "dgf-english-fast-auction-manual-qualification" ||
        props.popUpAwardReject.sellingMethod === "dgf-english-initial-auction" ||
        props.popUpAwardReject.sellingMethod === "dgf-english-initial-auction-manual" ||
        props.popUpAwardReject.sellingMethod === "dgf-english-initial-qualification" ||
        props.popUpAwardReject.sellingMethod === "dgf-dutch" ||
        props.popUpAwardReject.sellingMethod === "dgf-dutch-fast" ||
        props.popUpAwardReject.sellingMethod === "dgf-dutch-fast-manual" ||
        props.popUpAwardReject.sellingMethod === "dgf-dutch-fast-auction-manual-qualification" ||
        props.popUpAwardReject.sellingMethod === "dgf-dutch-initial-auction" ||
        props.popUpAwardReject.sellingMethod === "dgf-dutch-initial-qualification" ||
        props.popUpAwardReject.sellingMethod === "dgf-dutch-initial-auction-manual"

    ){
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload?isDgf=1`;
    }else{
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload`;
    }
    const formData = new FormData();
    formData.append('documentType', props.awardReject.type)
    formData.append('file', addFileInp.files[0])
    const response =  await fetch(endPoint, {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': props.token.access_token,
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        await props.changeLoader(false)
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при збереженні файлу', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }else{
            const endPoint1 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/document`;
            let typeOfAward = 'award'
            //if(props.awardReject.type === 'auctionProtocol') typeOfAward = 'bid'
            if(props.awardReject.type === 'auctionProtocol'){
                if(props.userProfiles.canCreateBid === true){
                    typeOfAward = 'bid'
                }else{
                    typeOfAward = 'award'
                }
            }else{
                typeOfAward = 'award'
            }
            let tmpDisc = null
            if(props.createDocuments.description !== null && props.createDocuments.description !== ''){
                tmpDisc = props.createDocuments.description
            }
            const response1 = await fetch(endPoint1, {
                method: "POST",
                body: JSON.stringify({
                    title: props.awardReject.title,
                    description: tmpDisc,
                    documentType: props.awardReject.type,
                    language: 'uk',
                    documentOf: typeOfAward,
                    //documentOf: 'award',
                    token: json.jwt
                }),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response1 === undefined || response1 === 'undefined'){
                await props.changeLoader(false)
            }else{
                const json1 = await response1.json()
                if(json1.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при збереженні документу', message: JSON.stringify(json1)})
                    props.setUniversalError(tmp)
                }else{
                    let endPoint2 = null
                    if(props.awardReject.type === 'auctionProtocol'){
                        if(props.userProfiles.canCreateBid === true){
                            endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/bid/${props.popUpAwardReject.bidId}/documents/${json1.id}`
                        }else{
                            endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/documents/${json1.id}`
                        }
                    }else{
                        endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/documents/${json1.id}`
                    }
                    const response2 = await fetch(endPoint2, {
                        method: "POST",
                        headers: {
                            'Authorization': props.token.access_token,
                            'Content-Type': 'application/json'
                        }
                    }).catch(console.error)
                    if(response2 === undefined || response2 === 'undefined'){
                        await props.changeLoader(false)
                    }else{
                        const json2 = await response2.json()
                        if(json2.hasOwnProperty('message') === true){
                            let tmp = JSON.stringify({title: 'Помилка при доданні документу до аварду', message: JSON.stringify(json2)})
                            props.setUniversalError(tmp)
                        }else{
                            props.changeAwardRejectTitle(null)
                            props.changeAwardRejectDescription(null)
                            props.changeAwardRejectDocumentType(null)
                            const addFileInp = document.getElementById('input__fileDel');
                            addFileInp.value = null;
                            let header = null;
                            if(props.token !== null){
                                header = {
                                    'Authorization': props.token.access_token,
                                    'Content-Type': 'application/json'
                                }
                            }else{
                                header = {
                                    'Content-Type': 'application/json'
                                }
                            }
                            let data5 = {
                                domen: process.env.REACT_APP_END_POINT_BD,
                                version: 'v1',
                                local: props.langFlag
                            }
                            if(props.popUpAwardReject.sellingMethod !== "dgf-english" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-english-fast" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-english-fast-manual" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-english-fast-auction-manual-qualification" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-english-initial-auction" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-english-initial-auction-manual" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-english-initial-qualification" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-dutch" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast-manual" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast-auction-manual-qualification" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-auction" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-qualification" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-auction-manual"
                            ){
                                const urlAsync = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/sync`;
                                const responseAsync = await fetch(urlAsync, {
                                    method: "GET",
                                    headers: {
                                        'Authorization': props.token.access_token,
                                        'Content-Type': 'application/json'
                                    }
                                }).catch(console.error)
                                if(responseAsync === undefined || responseAsync === 'undefined'){
                                    await props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
                                    await props.contractsAuctionIdGet(data5, header, props.popUpAwardReject.aucId)
                                }else{
                                    await props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
                                    await props.contractsAuctionIdGet(data5, header, props.popUpAwardReject.aucId)
                                }
                            }else{
                                await props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
                                await props.contractsAuctionIdGet(data5, header, props.popUpAwardReject.aucId)
                            }

                            await props.changeAwardRejectTitle('')
                            await props.changeAwardRejectDocumentType('null')
                            await props.changeAwardRejectDescription('')
                            await props.togetherApiDocFileName(null)
                            await props.togetherApiECP(false)
                            await props.ecpApiPass('')
                            await props.changeCA('null')
                            await props.changeDocumentFileName(null)
                            await props.changeLoader(false)
                        }                
                    }
                }
            }
        }
    }
}

function viewApproveOrRejectAward(props){
    let tmp = null
    if(props.popUpAwardReject !== null){
        if(props.popUpAwardReject.awards !== null){
            if(props.auctionAwards !== null){
                if(props.auctionAwards.length > 0){
                    let tmpTextSeller = false
                    if(props.auctionAwards[props.popUpAwardReject.awards].documents !== null){
                        if(props.auctionAwards[props.popUpAwardReject.awards].documents.length > 0){
                            for(let ty = 0; ty < props.auctionAwards[props.popUpAwardReject.awards].documents.length; ty++){
                                if(props.auctionAwards[props.popUpAwardReject.awards].documents[ty].documentType === 'act'){
                                    if(props.auctionAwards[props.popUpAwardReject.awards].documents[ty].createdByCurrentUser === true){
                                        tmpTextSeller = true
                                    }
                                }
                            }
                        }
                    }
                    if(props.auctionAwards[props.popUpAwardReject.awards].bid.createdByCurrentUser === true){
                        if(props.auctionAwards[props.popUpAwardReject.awards].status === 'pending_waiting'){
                            //if(tmpTextSeller === true){
                                tmp =  <div
                                    className={style.btnAccept}
                                    onClick={
                                        () => {
                                            canceledWaitingAward(props)
                                        }
                                    }
                                >
                                    Відмовитись від очікування
                                </div>
                            //}else{
                            //    tmp = <div>Для відхилення ставки необхідно додати акт</div>
                            //}
                        }
                    }
                    let tmpTextBuyerReject = false
                    let tmpTextBuyerAppruve = false
                    if(props.auctionAwards[props.popUpAwardReject.awards].documents !== null){
                        if(props.auctionAwards[props.popUpAwardReject.awards].documents.length > 0){
                            for(let ty = 0; ty < props.auctionAwards[props.popUpAwardReject.awards].documents.length; ty++){
                                if(props.auctionAwards[props.popUpAwardReject.awards].documents[ty].createdByCurrentUser === true){
                                    if(props.auctionAwards[props.popUpAwardReject.awards].documents[ty].documentType === 'act' || props.auctionAwards[props.popUpAwardReject.awards].documents[ty].documentType === 'rejectionProtocol'){
                                        tmpTextBuyerReject = true
                                    }
                                }
                            }
                        }
                    }
                    if(props.auctionAwards[props.popUpAwardReject.awards].bid.documents !== null){
                        if(props.auctionAwards[props.popUpAwardReject.awards].bid.documents.length > 0){
                            for(let ty = 0; ty < props.auctionAwards[props.popUpAwardReject.awards].bid.documents.length; ty++){
                                if(props.auctionAwards[props.popUpAwardReject.awards].bid.documents[ty].createdByCurrentUser === true){
                                    if(props.auctionAwards[props.popUpAwardReject.awards].bid.documents[ty].documentType === 'auctionProtocol'){
                                        tmpTextBuyerAppruve = true
                                    }
                                }
                            }
                        }
                    }
                    if(props.auctionAwards[props.popUpAwardReject.awards].auction.createdByCurrentUser === true){
                        let buttonApprove = null
                        let buttonReject = null
                        if(tmpTextBuyerAppruve === true){
                            if(props.auctionAwards[props.popUpAwardReject.awards].status !== 'active'){
                                buttonApprove =  <div
                                    className={style.btnAccept}
                                    onClick={
                                        () => {
                                            approveWinerAward(props)
                                        }
                                    }
                                >
                                    {isLAW(props.auctions.sellingMethod)
                                        ? "Підтвердження підписання акту"
                                        : null
                                    }
                                    {isLAE(props.auctions.sellingMethod) || isLAP(props.auctions.sellingMethod)
                                        ? "Підтвердження протоколу"
                                        : null
                                    }
                                    {(!isLAE(props.auctions.sellingMethod) && !isLAW(props.auctions.sellingMethod) && !isLAP(props.auctions.sellingMethod))
                                        ? "Протокол затверджено"
                                        : null
                                    }
                                </div>
                            }else{
                                buttonApprove = null
                            }
                        }

                        switch(props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod){
                            case "railwayCargo-english":
                            case "railwayCargo-english-fast":
                            case "railwayCargo-english-fast-manual":
                            case "railwayCargo-english-initial-auction":
                            case "railwayCargo-english-initial-qualification":
                            case "railwayCargo-dutch":
                            case "railwayCargo-dutch-fast":
                            case "railwayCargo-dutch-fast-manual":
                            case "railwayCargo-dutch-initial-auction":
                            case "railwayCargo-dutch-initial-qualification":
                            case "railwayCargo-dutch-fast-auction-manual-qualification":
                            case "railwayCargo-dutch-initial-auction-manual":
                                buttonApprove =  <div
                                    className={style.btnAccept}
                                    onClick={
                                        () => {
                                            approveWinerAward(props)
                                        }
                                    }
                                >
                                    Протокол затверджено
                                </div>
                                break;
                            default:
                                break;
                        }

                        if(props.auctionAwards[props.popUpAwardReject.awards].status === 'cancelled' || props.auctionAwards[props.popUpAwardReject.awards].status === 'unsuccessful '){
                            tmpTextBuyerReject = null
                        }
                        if(tmpTextBuyerReject === true){
                            if(props.auctionAwards[props.popUpAwardReject.awards].status !== 'pending_waiting'){
                                buttonReject =  <div
                                    className={style.btnAccept}
                                    onClick={
                                        () => {
                                            if(props.auctionAwards.length === 1){
                                                if(props.auctionAwards[props.popUpAwardReject.awards].status === 'active'){
                                                    let tmpSellingMethod = props.auctionsMy.sellingMethod.indexOf('railwayCargo-english')
                                                    if( tmpSellingMethod === -1) rejectWinerAward(props, props.auctionAwards[props.popUpAwardReject.awards].auction.contracts[0].id)
                                                    else rejectWinerAward1(props)
                                                }else{
                                                    rejectWinerAward1(props)
                                                }
                                            }else{
                                                if(props.auctionAwards[props.popUpAwardReject.awards].status === 'active'){
                                                    rejectWinerAward(props, props.auctionAwards[props.popUpAwardReject.awards].auction.contracts[0].id)
                                                }else{
                                                    rejectMultyAward(props)
                                                }
                                            }
                                        }
                                    }
                                >
                                    Відхилити
                                </div>
                            }
                        }
                        tmp = <>
                            {buttonReject}
                            {buttonApprove}
                        </>
                    }
                }
            }
        }
    }
    return tmp
}

async function canceledWaitingAward(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/set_status/cancelled`
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при відмові очікування аварду', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }else{
            let header = null;
            if(props.token !== null){
                header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }else{
                header = {
                    'Content-Type': 'application/json'
                }
            }
            let data5 = {
                domen: process.env.REACT_APP_END_POINT_BD,
                version: 'v1',
                local: props.langFlag
            }

            if(props.popUpAwardReject.sellingMethod !== "dgf-english" ||
                props.popUpAwardReject.sellingMethod !== "dgf-english-fast" ||
                props.popUpAwardReject.sellingMethod !== "dgf-english-fast-manual" ||
                props.popUpAwardReject.sellingMethod !== "dgf-english-fast-auction-manual-qualification" ||
                props.popUpAwardReject.sellingMethod !== "dgf-english-initial-auction" ||
                props.popUpAwardReject.sellingMethod !== "dgf-english-initial-auction-manual" ||
                props.popUpAwardReject.sellingMethod !== "dgf-english-initial-qualification" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast-manual" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast-auction-manual-qualification" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-auction" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-qualification" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-auction-manual"
            ){
                const urlAsync = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/sync`;
                const responseAsync = await fetch(urlAsync, {
                    method: "GET",
                    headers: {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
                }).catch(console.error)
                if(responseAsync === undefined || responseAsync === 'undefined'){
                    props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
                    props.contractsAuctionIdGet(data5, header, props.popUpAwardReject.aucId)
                }else{
                    props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
                    props.contractsAuctionIdGet(data5, header, props.popUpAwardReject.aucId)
                }
            }else{
                props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
                props.contractsAuctionIdGet(data5, header, props.popUpAwardReject.aucId)
            }
            
            props.changeAwardRejectPopUpStatus(null)
            props.changeAwardRejectTitle('')
            props.changeAwardRejectDocumentType('null')
            props.changeAwardRejectDescription('')
            props.togetherApiDocFileName(null)
            props.togetherApiECP(false)
            props.ecpApiPass('')
            props.changeCA('null')
            props.changeDocumentFileName(null)
        }
    }
}

async function rejectMultyAward(props){
    let tmpReason = null
    if(props.awardReject !== null){
        if(props.awardReject.hasOwnProperty('restrict')){
            tmpReason = props.awardReject.restrict
        }else{
            tmpReason = '4'
        }
    }
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/set_status/unsuccessful`
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            //reason: '4'
            reason: tmpReason
        })
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        //Поставить задержку 2-3 секунді
        const url2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/sync`;
        const response2 = await fetch(url2, {
            method: "GET",
            headers: {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
        }).catch(console.error)
    }
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при відхиленні аварду', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }else{
            let header = null;
            if(props.token !== null){
                header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }else{
                header = {
                    'Content-Type': 'application/json'
                }
            }
            let data5 = {
                domen: process.env.REACT_APP_END_POINT_BD,
                version: 'v1',
                local: props.langFlag
            }

            if(props.popUpAwardReject.sellingMethod !== "dgf-english" ||
                props.popUpAwardReject.sellingMethod !== "dgf-english-fast" ||
                props.popUpAwardReject.sellingMethod !== "dgf-english-fast-manual" ||
                props.popUpAwardReject.sellingMethod !== "dgf-english-fast-auction-manual-qualification" ||
                props.popUpAwardReject.sellingMethod !== "dgf-english-initial-auction" ||
                props.popUpAwardReject.sellingMethod !== "dgf-english-initial-auction-manual" ||
                props.popUpAwardReject.sellingMethod !== "dgf-english-initial-qualification" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast-manual" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast-auction-manual-qualification" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-auction" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-qualification" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-auction-manual"
            ){
                const urlAsync = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/sync`;
                const responseAsync = await fetch(urlAsync, {
                    method: "GET",
                    headers: {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
                }).catch(console.error)
                if(responseAsync === undefined || responseAsync === 'undefined'){
                    props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
                    props.contractsAuctionIdGet(data5, header, props.popUpAwardReject.aucId)
                }else{
                    props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
                    props.contractsAuctionIdGet(data5, header, props.popUpAwardReject.aucId)
                }
            }else{
                props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
                props.contractsAuctionIdGet(data5, header, props.popUpAwardReject.aucId)
            }
            props.changeAwardRejectPopUpStatus(null)
            props.changeAwardRejectTitle('')
            props.changeAwardRejectDocumentType('null')
            props.changeAwardRejectDescription('')
            props.togetherApiDocFileName(null)
            props.togetherApiECP(false)
            props.ecpApiPass('')
            props.changeCA('null')
            props.changeDocumentFileName(null)
        }
    }
}

async function rejectWinerAward(props, contractId){
    //const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/contract/${contractId}/approve/set_status/cancelled`
    //const response = await fetch(endPoint, {
    //    method: "POST",
    //    headers: {
    //        'Authorization': props.token.access_token,
    //        'Content-Type': 'application/json'
    //    }
    //}).catch(console.error)
    //if(response !== undefined || response !== 'undefined'){
    //    const json = await response.json()
        //const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/set_status/cancelled?reason=4`
        //const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/set_status/unsuccessful`
        let tmpReason = null
        if(props.awardReject !== null){
            if(props.awardReject.hasOwnProperty('restrict')){
                tmpReason = props.awardReject.restrict
            }else{
                tmpReason = '4'
            }
        }
        const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/set_status/unsuccessful`
        const response2 = await fetch(endPoint2, {
            method: "POST",
            headers: {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                //reason: '4'
                reason: tmpReason
            })
        }).catch(console.error)
        if(response2 === undefined || response2 === 'undefined'){
        }else{
            const json2 = await response2.json()
            if(json2.hasOwnProperty('message') === true){
                let tmp = JSON.stringify({title: 'Помилка при відхиленні аварду', message: JSON.stringify(json2)})
                props.setUniversalError(tmp)
            }else{
                let header = null;
                if(props.token !== null){
                    header = {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
                }else{
                    header = {
                        'Content-Type': 'application/json'
                    }
                }
                let data5 = {
                    domen: process.env.REACT_APP_END_POINT_BD,
                    version: 'v1',
                    local: props.langFlag
                }
                if(props.popUpAwardReject.sellingMethod !== "dgf-english" ||
                    props.popUpAwardReject.sellingMethod !== "dgf-english-fast" ||
                    props.popUpAwardReject.sellingMethod !== "dgf-english-fast-manual" ||
                    props.popUpAwardReject.sellingMethod !== "dgf-english-fast-auction-manual-qualification" ||
                    props.popUpAwardReject.sellingMethod !== "dgf-english-initial-auction" ||
                    props.popUpAwardReject.sellingMethod !== "dgf-english-initial-auction-manual" ||
                    props.popUpAwardReject.sellingMethod !== "dgf-english-initial-qualification" ||
                    props.popUpAwardReject.sellingMethod !== "dgf-dutch" ||
                    props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast" ||
                    props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast-manual" ||
                    props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast-auction-manual-qualification" ||
                    props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-auction" ||
                    props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-qualification" ||
                    props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-auction-manual"
                ){
                    const urlAsync = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/sync`;
                    const responseAsync = await fetch(urlAsync, {
                        method: "GET",
                        headers: {
                            'Authorization': props.token.access_token,
                            'Content-Type': 'application/json'
                        }
                    }).catch(console.error)
                    if(responseAsync === undefined || responseAsync === 'undefined'){
                        props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
                        props.contractsAuctionIdGet(data5, header, props.popUpAwardReject.aucId)
                    }else{
                        props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
                        props.contractsAuctionIdGet(data5, header, props.popUpAwardReject.aucId)
                    }
                }else{
                    props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
                    props.contractsAuctionIdGet(data5, header, props.popUpAwardReject.aucId)
                }
                props.changeAwardRejectPopUpStatus(null)
                props.changeAwardRejectTitle('')
                props.changeAwardRejectDocumentType('null')
                props.changeAwardRejectDescription('')
                props.togetherApiDocFileName(null)
                props.togetherApiECP(false)
                props.ecpApiPass('')
                props.changeCA('null')
                props.changeDocumentFileName(null)
            }
        }
    //}
}

async function rejectWinerAward1(props){
    /*const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/contract/${contractId}/approve/set_status/cancelled`
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response !== undefined || response !== 'undefined'){
        const json = await response.json()*/
        let tmpReason = null
        if(props.awardReject !== null){
            if(props.awardReject.hasOwnProperty('restrict')){
                tmpReason = props.awardReject.restrict
            }else{
                tmpReason = '4'
            }
        }
        let endPoint2 = null
        let tmpSellingMethod = props.auctionsMy.sellingMethod.indexOf('railwayCargo-english')
        if( tmpSellingMethod === -1){
            endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/set_status/cancelled?reason=${tmpReason}`
        }else{
            endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/set_status/unsuccessful`
        }
        //Для леса этот урл
        //const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/set_status/cancelled?reason=4`
        //const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/reject`
        //const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/set_status/unsuccessful?reason=4`
        const response2 = await fetch(endPoint2, {
            method: "POST",
            headers: {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
        }).catch(console.error)
        if(response2 === undefined || response2 === 'undefined'){
        }else{
            const json2 = await response2.json()
            if(json2.hasOwnProperty('message') === true){
                let tmp = JSON.stringify({title: 'Помилка при відхиленні аварду', message: JSON.stringify(json2)})
                props.setUniversalError(tmp)
            }else{
                let header = null;
                if(props.token !== null){
                    header = {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
                }else{
                    header = {
                        'Content-Type': 'application/json'
                    }
                }
                let data5 = {
                    domen: process.env.REACT_APP_END_POINT_BD,
                    version: 'v1',
                    local: props.langFlag
                }
                if(props.popUpAwardReject.sellingMethod !== "dgf-english" ||
                    props.popUpAwardReject.sellingMethod !== "dgf-english-fast" ||
                    props.popUpAwardReject.sellingMethod !== "dgf-english-fast-manual" ||
                    props.popUpAwardReject.sellingMethod !== "dgf-english-fast-auction-manual-qualification" ||
                    props.popUpAwardReject.sellingMethod !== "dgf-english-initial-auction" ||
                    props.popUpAwardReject.sellingMethod !== "dgf-english-initial-auction-manual" ||
                    props.popUpAwardReject.sellingMethod !== "dgf-english-initial-qualification" ||
                    props.popUpAwardReject.sellingMethod !== "dgf-dutch" ||
                    props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast" ||
                    props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast-manual" ||
                    props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast-auction-manual-qualification" ||
                    props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-auction" ||
                    props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-qualification" ||
                    props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-auction-manual"
                ){
                    const urlAsync = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/sync`;
                    const responseAsync = await fetch(urlAsync, {
                        method: "GET",
                        headers: {
                            'Authorization': props.token.access_token,
                            'Content-Type': 'application/json'
                        }
                    }).catch(console.error)
                    if(responseAsync === undefined || responseAsync === 'undefined'){
                        props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
                        props.contractsAuctionIdGet(data5, header, props.popUpAwardReject.aucId)
                    }else{
                        props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
                        props.contractsAuctionIdGet(data5, header, props.popUpAwardReject.aucId)
                    }
                }else{
                    props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
                    props.contractsAuctionIdGet(data5, header, props.popUpAwardReject.aucId)
                }

                props.changeAwardRejectPopUpStatus(null)
                props.changeAwardRejectTitle('')
                props.changeAwardRejectDocumentType('null')
                props.changeAwardRejectDescription('')
                props.togetherApiDocFileName(null)
                props.togetherApiECP(false)
                props.ecpApiPass('')
                props.changeCA('null')
                props.changeDocumentFileName(null)
            }
        }
    //}
}

async function approveWinerAward(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/approve`
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            //props.changeAwardErrorPopUpStatus(json)
            let tmp = JSON.stringify({title: 'Помилка при затвердженні аварду', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }else{
            let header = null;
            if(props.token !== null){
                header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }else{
                header = {
                    'Content-Type': 'application/json'
                }
            }
            let data5 = {
                domen: process.env.REACT_APP_END_POINT_BD,
                version: 'v1',
                local: props.langFlag
            }
            if(props.popUpAwardReject.sellingMethod !== "dgf-english" ||
                props.popUpAwardReject.sellingMethod !== "dgf-english-fast" ||
                props.popUpAwardReject.sellingMethod !== "dgf-english-fast-manual" ||
                props.popUpAwardReject.sellingMethod !== "dgf-english-fast-auction-manual-qualification" ||
                props.popUpAwardReject.sellingMethod !== "dgf-english-initial-auction" ||
                props.popUpAwardReject.sellingMethod !== "dgf-english-initial-auction-manual" ||
                props.popUpAwardReject.sellingMethod !== "dgf-english-initial-qualification" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast-manual" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast-auction-manual-qualification" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-auction" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-qualification" ||
                props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-auction-manual"
            ){
                const urlAsync = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/sync`;
                const responseAsync = await fetch(urlAsync, {
                    method: "GET",
                    headers: {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
                }).catch(console.error)
                if(responseAsync === undefined || responseAsync === 'undefined'){
                    props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
                    props.contractsAuctionIdGet(data5, header, props.popUpAwardReject.aucId)
                }else{
                    props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
                    props.contractsAuctionIdGet(data5, header, props.popUpAwardReject.aucId)
                }
            }else{
                props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
                props.contractsAuctionIdGet(data5, header, props.popUpAwardReject.aucId)
            }

            props.changeAwardRejectPopUpStatus(null)
            props.changeAwardRejectTitle('')
            props.changeAwardRejectDocumentType('null')
            props.changeAwardRejectDescription('')
            props.togetherApiDocFileName(null)
            props.togetherApiECP(false)
            props.ecpApiPass('')
            props.changeCA('null')
            props.changeDocumentFileName(null)
        }
    }
}

/**
 * 
 * 
 */

//Получение списка выдавателей КЕП
async function epListCertificateAuthority(props){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/certificateAuthority/supported`;
        const response = await fetch(url, {
        method: "GET",
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    props.ecpApiList(response)
}
/**
 * 
 */
const mapStateToProps = state => {
    return {
        token: state.start.token,
        //auctions: state.start.auctionsGet,
        auctions: state.start.auctionFull,

        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy,
        auctionAwards: state.start.auctionAwards,
        popUpAwardReject: state.start.popUpAwardReject,
        awardReject: state.start.awardReject,
        auction: state.start.auctionsGetId,
        auctionMys: state.start.auctionMy,

        togetherApiPage: state.start.togetherApiPage,
        ecpData: state.start.ecpData,

        createDocuments: state.start.createDocuments,

        awardDocument: state.start.awardDocument,
        contractAuctionIdGet: state.start.contractAuctionIdGet,
    }
}

const mapDispatchToProps = {
    auctionsGetAwards,
    auctionsGetAwardsId,
    changeAwardRejectPopUpStatus,
    changeAwardErrorPopUpStatus,
    changeAwardRejectTitle,
    changeAwardRejectDescription,
    changeAwardRejectRestrict,
    changeAwardRejectDocumentType,
    changeAwardDocumentStatus,

    setUniversalError,

    togetherApiPopUpOnOff,
    togetherApiDocTitle,
    togetherApiDocDescription,
    togetherApiDocType,
    togetherApiDocLang,
    togetherApiDocFileName,
    togetherApiECP,

    ecpApiPass,
    ecpApiList,
    changeCA,

    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType,

    changeLoader,
    editAwardDoc,
    contractsAuctionIdGet,
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteBidDocument)