import React from 'react';
import styles from './answer.module.css';

import ButtonAnswer from './buttonAnswer/buttonAnswer.js';

import {modifiDateString} from '../../../redux/modifiDate'

import {connect} from 'react-redux';

const Answer = ( props ) => {
    //TODO: ДОРАБОТАТЬ ВОПРОСІ В МОНГЕ отключил проверки!!!!
    
    /*function getAnswer() {
        if(props.answer){
            return (
                <div className={styles.answer}>
                    <div  className={styles.description}>Відповідь організатора</div>
                    <div className={styles.subject}>{props.answer}</div>
                </div>
            )
        } else {return ''}
    }

    return (
        <div className={styles.answerBlock}>
            <h3 className={styles.title}>Інформація про доставку</h3>
            <div className={styles.question}>
                <div className={styles.description}>Зміст запитання</div>
                <div className={styles.subject}>{props.question}</div>
            </div>
            {getAnswer()}
        </div>
    )*/
    let tmp = null;
    if(props.auction !== null){
        tmp = props.auction.questions.map(
            (i) => {
                return (
                    <div className={styles.answerBlock}>
                        <h3 className={styles.title}>{i.title.uk_UA}</h3>
                        <div className={styles.question}>
                            <div className={styles.description}>Зміст запитання</div>
                            <div className={styles.subject}>{i.description.uk_UA}</div>
                        </div>
                        {editButton(props, i)}
                        {buyerButton(props, i)}
                    </div>
                )
            }
        )
    }
    if(tmp === null){
        tmp = 'Наразі немає жодного запитання від учасників'
    }else if(tmp.length === 0){
        tmp = 'Наразі немає жодного запитання від учасників'
    }
    return (
        <div className={styles.answerBlock}>
            {tmp}
        </div>
    )
}

function buyerButton(props, i){
    if(props.auction.privateData.isOwner === true){
        if(i.answer !== null){
            return <div className={styles.answer}>
                <div  className={styles.description}>Відповідь організатора</div>
                <div className={styles.subject}>{i.answer.uk_UA}</div>
            </div>
        }else{
            let dateChatStart = null
            let dateChatEnd = null
            if(props.auction !== null){
                if(props.auction.questionPeriod !== null) {
                    dateChatStart = new Date(props.auction.questionPeriod.startDate)
                    dateChatEnd = new Date(props.auction.questionPeriod.endDate)
                }
                let tmpStart = null
                let tmpEnd = null
                if(dateChatStart !== null && dateChatEnd !== null) {
                    tmpStart = dateChatStart.getTime()
                    tmpEnd = dateChatEnd.getTime()
                    if(tmpStart < Date.now() && tmpEnd > Date.now()){
                        return <ButtonAnswer
                            itemAnswer={i.id}
                            edit='ANSWER'
                        />
                    }
                }
            }
            return answerOwner(i)
        }
    }else{
        return answerOwner(i)
    }
}

function answerOwner(data){
    if(data.answer !== null){
        return (
            <div className={styles.answer}>
                <div  className={styles.description}>Відповідь організатора</div>
                <div className={styles.subject}>{data.answer.uk_UA}</div>
            </div>
        )
    }
    return null
}

function editButton(props, i){
    let dateChatStart = null
    let dateChatEnd = null
    if(props.auction !== null){
        if(props.auction.questionPeriod !== null) {
            dateChatStart = new Date(props.auction.questionPeriod.startDate)
            dateChatEnd = new Date(props.auction.questionPeriod.endDate)
        }
        let tmpStart = null
        let tmpEnd = null
        let closeEdit = null
        if(i.canUpdateUntil !== null){
            closeEdit = new Date(i.canUpdateUntil)
            closeEdit = closeEdit.getTime()
            if(closeEdit > Date.now()){
                if(dateChatStart !== null && dateChatEnd !== null) {
                    tmpStart = dateChatStart.getTime()
                    tmpEnd = dateChatEnd.getTime()
                    if(tmpStart < Date.now() && tmpEnd > Date.now()){
                        return (
                            <>
                                <ButtonAnswer
                                    item={i}
                                    edit='EDIT'
                                />
                                <div className={styles.description + ' ' + styles.help}>Редагування доступне до {modifiDateString(i.canUpdateUntil, 3, false)}</div>
                            </>
                        )
                    }
                }    
            }
        }else{
            if(dateChatStart !== null && dateChatEnd !== null) {
                tmpStart = dateChatStart.getTime()
                tmpEnd = dateChatEnd.getTime()
                if(tmpStart < Date.now() && tmpEnd > Date.now()){
                    return (
                        <ButtonAnswer
                            item={i}
                            edit='EDIT'
                        />
                    )
                }
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auction: state.start.auctionFull,
        langFlag: state.start.langFlag,
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Answer)