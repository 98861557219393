import React from 'react';
import styles from './informationAboutTheOrganizer.module.css';

import {connect} from 'react-redux';

import { checkProp } from '../../../redux/check';
import {modifiDateString} from '../../../redux/modifiDate'

import { isTIE, isSUE, isSUD, isRCE, isRCD, isGFE, isGFD, isLLE, isLLD,
    isLLP, isLRE, isLSE, isLSP, isRMA, isBSE, isBSD, isALE, isCSE, isCSD,
    isBRE, isBRD, isBRW, isLAE, isLAW, isLAP, isAPE, isAPD } from '../../../redux/aucTypes';

import TextHalf from '../../elements/viewData/textHalf'

import ContactPoints from '../../contactPoint/contactPoint'

const InformationAboutTheOrganizer = (props) => {
    return (
        <div className={styles.infoBlock}>
            <h3>{ isAPE(props.auctionId.sellingMethod) || isAPD(props.auctionId.sellingMethod) ? "Інформація про Замовника" : "Інформація про Організатора"}</h3>
            <div className={styles.orgTitle}>
                <p className={styles.desc}>{isAPE(props.auctionId.sellingMethod) || isAPD(props.auctionId.sellingMethod) ? "Найменування замовника" : "Найменування організатора" }</p>
                <p>{contactName(props)}</p>
            </div>
            <div className={styles.orgContact1}>
                <p className={styles.desc}>Контактні дані</p>
                {contactPoint(props)}
            </div>
            <div className={styles.orgId}>
                <p className={styles.desc}>Код ЄДРПОУ або ІПН або паспорт</p>
                <p>{contactEDRPOU(props)}</p>
            </div>
            <div className={styles.orgAdress}>
                <p className={styles.desc}>Юридична адреса</p>
                <p>{contactAddress(props)}</p>
            </div>
            {contactKOATUU(props)}
            {checkProp(props.auctionId.sellingEntity, 'representativeCertificateNumber')
            ? <div className={styles.orgAdress}>
                <p className={styles.desc}>№ свідоцтва про право на провадження діяльності арбітражного керуючого</p>
                <p>{props.auctionId.sellingEntity.representativeCertificateNumber}</p>
            </div>
            : null}
            {checkProp(props.auctionId.sellingEntity, 'representativeCertificateDate')
            ? <div className={styles.orgAdress}>
                <p className={styles.desc}>Дата видачі про право на провадження діяльності арбітражного керуючого</p>
                <p>{modifiDateString(props.auctionId.sellingEntity.representativeCertificateDate, 1, false)}</p>
            </div>
            : null}
            {representativeInfo(props)}
        </div>
    )
}

function representativeInfo(props){
    let tmp = ''
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('sellingEntity') === true){
            if(props.auctionId.sellingEntity !== null){
                if(props.auctionId.sellingEntity.hasOwnProperty('representativeInfo')){
                    if(props.auctionId.sellingEntity.representativeInfo !== null){
                        tmp = <div className={styles.orgTitle}>
                            <p className={styles.desc}>Інформація щодо підтвердження повноважень</p>
                            <p>{props.auctionId.sellingEntity.representativeInfo}</p>
                        </div>
                    }
                }
            }
        }
    }
    return tmp
}

function contactKOATUU(props){
    let tmp = ''
    if(props.auctionId !== null){
        if(
            props.auctionId.sellingMethod === "legitimatePropertyLease-english" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-fast" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
        ){
            if(props.auctionId.hasOwnProperty('relatedOrganizations')){
                if(props.auctionId.relatedOrganizations !== null){
                    if(props.auctionId.relatedOrganizations.hasOwnProperty('sellingEntity')){
                        if(props.auctionId.relatedOrganizations.sellingEntity !== null){
                            if(props.auctionId.relatedOrganizations.sellingEntity.hasOwnProperty('address')){
                                if(props.auctionId.relatedOrganizations.sellingEntity.address !== null){
                                    if(props.auctionId.relatedOrganizations.sellingEntity.address.hasOwnProperty('addressID')){
                                        if(props.auctionId.relatedOrganizations.sellingEntity.address.addressID !== null){
                                            if(props.auctionId.relatedOrganizations.sellingEntity.address.addressID.hasOwnProperty('id')){
                                                if(props.auctionId.relatedOrganizations.sellingEntity.address.addressID.id !== null){
                                                    tmp = <div className={styles.orgAdress}>
                                                        <p className={styles.desc}>КОАТУУ</p>
                                                        <p>{props.auctionId.relatedOrganizations.sellingEntity.address.addressID.id}</p>
                                                    </div>
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return tmp
}

function contactName(props){
    let tmp = ''
    let tmpBreBrd = ''
    if(isBRE(props.auctionId.sellingMethod) || isBRD(props.auctionId.sellingMethod) || isBRW(props.auctionId.sellingMethod)) tmpBreBrd = 'Арбітражний керуючий '
    if(props.auctionId !== null){
        if(
            props.auctionId.sellingMethod === "legitimatePropertyLease-english" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-fast" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
        ){
            if(props.auctionId.hasOwnProperty('relatedOrganizations')){
                if(props.auctionId.relatedOrganizations !== null){
                    if(props.auctionId.relatedOrganizations.hasOwnProperty('sellingEntity')){
                        if(props.auctionId.relatedOrganizations.sellingEntity !== null){
                            if(props.auctionId.relatedOrganizations.sellingEntity.hasOwnProperty('name')){
                                if(props.auctionId.relatedOrganizations.sellingEntity.name !== null){
                                    tmp += props.auctionId.relatedOrganizations.sellingEntity.name.uk_UA
                                }
                            }
                        }
                    }
                }
            }
        }else{
            if(props.auctionId.hasOwnProperty('sellingEntity')){
                if(props.auctionId.sellingEntity !== null){
                    if(props.auctionId.sellingEntity.hasOwnProperty('name')){
                        if(props.auctionId.sellingEntity.name !== null){
                            tmp += tmpBreBrd + props.auctionId.sellingEntity.name.uk_UA
                        }
                    }
                }
            }
        }
    }
    return tmp
}

function contactPoint(props){
    if(props.auctionId !== null){
        if(
            props.auctionId.sellingMethod === "legitimatePropertyLease-english" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-fast" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
        ){
            if(props.auctionId.hasOwnProperty('relatedOrganizations')){
                if(props.auctionId.relatedOrganizations !== null){
                    if(props.auctionId.relatedOrganizations.hasOwnProperty('sellingEntity')){
                        if(props.auctionId.relatedOrganizations.sellingEntity !== null){
                            if(props.auctionId.relatedOrganizations.sellingEntity.hasOwnProperty('contactPoint')){
                                if(props.auctionId.relatedOrganizations.sellingEntity.contactPoint !== null){
                                    return contactPoints(props.auctionId.relatedOrganizations.sellingEntity.contactPoint)
                                }
                            }
                        }
                    }
                }
            }
        }else{
            if(props.auctionId.hasOwnProperty('sellingEntity')){
                if(props.auctionId.sellingEntity !== null){
                    if(props.auctionId.sellingEntity.hasOwnProperty('contactPoint')){
                        if(props.auctionId.sellingEntity.contactPoint !== null){
                            return contactPoints(props.auctionId.sellingEntity.contactPoint)
                        }
                    }
                }
            }
        }
    }
    return null
}

function contactPoints(e){
    if(e !== null){
        return <ContactPoints data={e} />
    }
    return null
}

// function contactPointName(e){
//     let tmp = ''
//     if(e.hasOwnProperty('name')){
//         if(e.name !== null){
//             tmp = e.name.uk_UA
//         }
//     }
//     return tmp
// }
// function contactPointEmail(e){
//     let tmp = ''
//     if(e.hasOwnProperty('email')){
//         if(e.email !== null){
//             tmp = e.email
//         }
//     }
//     return tmp
// }
// function contactPointTelephone(e){
//     let tmp = ''
//     if(e.hasOwnProperty('telephone')){
//         if(e.telephone !== null){
//             tmp = e.telephone
//         }
//     }
//     return tmp
// }
// function contactPointFaxNumber(e){
//     let tmp = ''
//     if(e.hasOwnProperty('faxNumber')){
//         if(e.faxNumber !== null){
//             tmp = e.faxNumber
//         }
//     }
//     return tmp
// }
// function contactPointUrl(e){
//     let tmp = ''
//     if(e.hasOwnProperty('url')){
//         if(e.url !== null){
//             tmp = e.url
//         }
//     }
//     return tmp
// }

function contactEDRPOU(props){
    let tmp = ''
    if(props.auctionId !== null){
        if(
            props.auctionId.sellingMethod === "legitimatePropertyLease-english" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-fast" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
        ){
            if(props.auctionId.hasOwnProperty('relatedOrganizations')){
                if(props.auctionId.relatedOrganizations !== null){
                    if(props.auctionId.relatedOrganizations.hasOwnProperty('sellingEntity')){
                        if(props.auctionId.relatedOrganizations.sellingEntity !== null){
                            if(props.auctionId.relatedOrganizations.sellingEntity.hasOwnProperty('identifier')){
                                if(props.auctionId.relatedOrganizations.sellingEntity.identifier !== null){
                                    if(props.auctionId.relatedOrganizations.sellingEntity.hasOwnProperty('identifier')){
                                        if(props.auctionId.relatedOrganizations.sellingEntity.identifier.id !== null){
                                            tmp += props.auctionId.relatedOrganizations.sellingEntity.identifier.id
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }else{
            if(props.auctionId.hasOwnProperty('sellingEntity')){
                if(props.auctionId.sellingEntity !== null){
                    if(props.auctionId.sellingEntity.hasOwnProperty('identifier')){
                        if(props.auctionId.sellingEntity.identifier !== null){
                            if(props.auctionId.sellingEntity.hasOwnProperty('identifier')){
                                if(props.auctionId.sellingEntity.identifier.id !== null){
                                    tmp += props.auctionId.sellingEntity.identifier.id
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return tmp
}

function contactAddress(props){
    let tmp = ''
    if(props.auctionId !== null){
        if(
            props.auctionId.sellingMethod === "legitimatePropertyLease-english" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-fast" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
        ){
            if(props.auctionId.hasOwnProperty('relatedOrganizations')){
                if(props.auctionId.relatedOrganizations !== null){
                    if(props.auctionId.relatedOrganizations.hasOwnProperty('sellingEntity')){
                        if(props.auctionId.relatedOrganizations.sellingEntity !== null){
                            if(props.auctionId.relatedOrganizations.sellingEntity.hasOwnProperty('address')){
                                if(props.auctionId.relatedOrganizations.sellingEntity.address !== null){
                                    if(props.auctionId.relatedOrganizations.sellingEntity.address.hasOwnProperty('postalCode')){
                                        if(props.auctionId.relatedOrganizations.sellingEntity.address.postalCode !== null){
                                            tmp += props.auctionId.relatedOrganizations.sellingEntity.address.postalCode + ', '
                                        }
                                    }
                                    if(props.auctionId.relatedOrganizations.sellingEntity.address.hasOwnProperty('countryName')){
                                        if(props.auctionId.relatedOrganizations.sellingEntity.address.countryName !== null){
                                            tmp += props.auctionId.relatedOrganizations.sellingEntity.address.countryName.uk_UA + ', '
                                        }
                                    }
                                    if(props.auctionId.relatedOrganizations.sellingEntity.address.hasOwnProperty('region')){
                                        if(props.auctionId.relatedOrganizations.sellingEntity.address.region !== null){
                                            tmp += props.auctionId.relatedOrganizations.sellingEntity.address.region.uk_UA + ', '
                                        }
                                    }
                                    if(props.auctionId.relatedOrganizations.sellingEntity.address.hasOwnProperty('locality')){
                                        if(props.auctionId.relatedOrganizations.sellingEntity.address.locality !== null){
                                            tmp += props.auctionId.relatedOrganizations.sellingEntity.address.locality.uk_UA + ', '
                                        }
                                    }
                                    if(props.auctionId.relatedOrganizations.sellingEntity.address.hasOwnProperty('streetAddress')){
                                        if(props.auctionId.relatedOrganizations.sellingEntity.address.streetAddress !== null){
                                            tmp += props.auctionId.relatedOrganizations.sellingEntity.address.streetAddress.uk_UA
                                        }
                                    }                                    
                                }
                            }
                        }
                    }
                }
            }
        }else{
            if(props.auctionId.hasOwnProperty('sellingEntity')){
                if(props.auctionId.sellingEntity !== null){
                    if(props.auctionId.sellingEntity.hasOwnProperty('address')){
                        if(props.auctionId.sellingEntity.address !== null){
                            if(props.auctionId.sellingEntity.address.hasOwnProperty('locality')){
                                if(props.auctionId.sellingEntity.address.locality !== null){
                                    tmp += props.auctionId.sellingEntity.address.locality.uk_UA + ', '
                                }
                            }
                            if(props.auctionId.sellingEntity.address.hasOwnProperty('streetAddress')){
                                if(props.auctionId.sellingEntity.address.streetAddress !== null){
                                    tmp += props.auctionId.sellingEntity.address.streetAddress.uk_UA + ', '
                                }
                            }
                            if(props.auctionId.sellingEntity.address.hasOwnProperty('region')){
                                if(props.auctionId.sellingEntity.address.region !== null){
                                    tmp += props.auctionId.sellingEntity.address.region.uk_UA + ', '
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(InformationAboutTheOrganizer)
