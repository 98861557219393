import React from 'react';
import styles from './privatization.module.css';

import {
    SMALL_PRIVATIZATION_CATEGORY_MENU_LANG,
} from '../../../../model/lang/iconCategoryMenu.js';
import {
    setDataAddress,
} from '../../../../redux/createAddress';

import {connect} from 'react-redux';

const Privatization = ( {langFlag} ) => {
    return (
        <div className={styles.aukpoisk_second_elem}>
            <a href='https://www.dto.com.ua/tenders/current/privatization'>
			<div className={styles.base}>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 512 512" xmlSpace="preserve">
<g>
	<g>
		<path d="M263.493,443.733H149.716c-6.284,0-11.378,5.095-11.378,11.378c0,6.283,5.094,11.378,11.378,11.378h113.778
			c14.833,0,27.488,9.511,32.183,22.756H26.51c4.696-13.245,17.35-22.756,32.184-22.756H81.45c6.284,0,11.378-5.095,11.378-11.378
			c0-6.283-5.094-11.378-11.378-11.378H58.695c-31.369,0-56.889,25.52-56.889,56.889c0,6.283,5.094,11.378,11.378,11.378h295.822
			c6.283,0,11.378-5.095,11.378-11.378C320.382,469.253,294.863,443.733,263.493,443.733z"/>
	</g>
</g>
<g>
	<g>
		<path d="M126.733,452.892c-0.137-0.728-0.364-1.446-0.649-2.139c-0.284-0.684-0.626-1.343-1.047-1.957
			c-0.41-0.627-0.887-1.207-1.411-1.731s-1.104-1.001-1.729-1.411c-0.614-0.421-1.274-0.762-1.957-1.047
			c-0.694-0.284-1.411-0.512-2.139-0.647c-1.456-0.297-2.97-0.297-4.437,0c-0.728,0.135-1.445,0.363-2.128,0.647
			c-0.694,0.284-1.354,0.626-1.968,1.047c-0.626,0.41-1.206,0.887-1.729,1.411s-1.001,1.104-1.411,1.731
			c-0.41,0.614-0.762,1.273-1.047,1.957c-0.284,0.693-0.501,1.411-0.648,2.139c-0.148,0.728-0.228,1.479-0.228,2.219
			c0,0.738,0.08,1.49,0.228,2.219c0.148,0.728,0.364,1.445,0.648,2.127c0.284,0.694,0.637,1.354,1.047,1.968
			c0.41,0.626,0.887,1.206,1.411,1.729s1.104,1.002,1.729,1.411c0.614,0.41,1.274,0.762,1.968,1.047
			c0.683,0.284,1.399,0.512,2.128,0.648c0.728,0.148,1.479,0.228,2.219,0.228s1.49-0.08,2.219-0.228
			c0.728-0.136,1.445-0.364,2.128-0.648c0.694-0.284,1.354-0.637,1.968-1.047c0.626-0.408,1.206-0.887,1.729-1.411
			s1.001-1.104,1.411-1.729c0.41-0.614,0.762-1.274,1.047-1.968c0.284-0.683,0.512-1.398,0.649-2.127
			c0.148-0.729,0.228-1.48,0.228-2.219C126.96,454.372,126.88,453.62,126.733,452.892z"/>
	</g>
</g>
<g>
	<g>
		<path d="M506.865,449.847L273.55,216.533l44.249-44.249l8.045,8.045c2.222,2.221,5.134,3.333,8.045,3.333
			c2.912,0,5.823-1.112,8.045-3.333l32.181-32.181c2.133-2.134,3.333-5.028,3.333-8.045s-1.199-5.911-3.333-8.045L245.392,3.331
			c-4.443-4.442-11.647-4.442-16.092,0L197.12,35.512c-2.133,2.134-3.333,5.028-3.333,8.045s1.199,5.911,3.333,8.045l8.045,8.045
			L76.44,188.374l-8.045-8.045c-4.443-4.442-11.647-4.442-16.092,0L20.123,212.51c-2.133,2.134-3.333,5.028-3.333,8.045
			s1.199,5.911,3.333,8.045l128.725,128.725c2.222,2.221,5.134,3.333,8.045,3.333s5.824-1.112,8.045-3.333l32.181-32.181
			c2.133-2.135,3.333-5.028,3.333-8.045s-1.199-5.911-3.333-8.045l-8.045-8.044l36.203-36.203L458.59,498.12
			c2.222,2.221,5.134,3.333,8.045,3.333c2.912,0,5.823-1.112,8.045-3.333l32.181-32.181c2.133-2.135,3.333-5.028,3.333-8.045
			C510.194,454.877,508.999,451.981,506.865,449.847z M237.346,27.468L349.98,140.101l-16.09,16.09L221.256,43.559L237.346,27.468z
			 M156.894,333.188L44.26,220.555l16.09-16.09l8.045,8.044l0.001,0.001l96.544,96.544c0.003,0.003,0.007,0.006,0.01,0.009
			l8.035,8.035L156.894,333.188z M172.984,284.918l-80.453-80.453L221.256,75.741l80.452,80.452L172.984,284.918z M466.638,473.981
			L241.369,248.714l8.447-8.447l7.644-7.642l225.269,225.267L466.638,473.981z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
</div>
                <div className={styles.aukpoisk_second_elem_text}>{SMALL_PRIVATIZATION_CATEGORY_MENU_LANG[langFlag]}</div>
            </a>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        aucyionsPath: state.start.aucyionsPath,
        search: state.start.search,
        token: state.start.token,
        searchCount: state.start.searchCount,
    }
}

const mapDispatchToProps = {
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(Privatization)