import React from 'react';
import style from './bidEdit.module.css';

import {connect} from 'react-redux';

import {changeBidPopUpHelpPublish} from '../../../../redux/actions/auctionBid/auctionBid.js';
import {auctionsBidsGetId} from '../../../../redux/actions/auctionBid/auctionBid.js';
import {setUniversalError, changeLoader} from '../../../../redux/actions.js';
import {setDataAddress} from '../../../../redux/createAddress';

import {getUpdateDataBid} from '../../updateData'
import {
    saveArrBids,
    saveAuctionId
} from '../../../../redux/allAuctionBids.js';

const BidHelpPublish = ( props ) => {
    let tmp = null
    if(props.bidPopUpHelpPublish !== null){
        if(props.bidPopUpHelpPublish.hasOwnProperty('type') === true){
            if(props.bidPopUpHelpPublish.type === 2){
                tmp = <div className={style.popup_wrap}>
                    <div className={style.popup_message}>
                        <div>Публікація ставки неможлива без документів</div>
                        <div className={style.buttonClose}
                            onClick={
                                () => {
                                    props.changeBidPopUpHelpPublish(null)
                                }
                            }
                        >
                            Закрити
                        </div>
                    </div>
                </div>
            }else{
                tmp = <div className={style.popup_wrap}>
                    <div className={style.popup_message}>
                        <div>З вашого рахунку буде списано залогову суму за участь в Аукціоні.</div>
                        <div>Підтвердіть згоду.</div>
                        <div className={style.popup_btnBlock}>
                            <div className={style.buttonClose}
                                onClick={
                                    () => {
                                        if(props.bidPopUpHelpPublish.status === 'inactive'){
                                            sendBid(props, props.bidPopUpHelpPublish.tmpId, props.bidPopUpHelpPublish.elemBidId)
                                        }else{
                                            if(props.bidPopUpHelpPublish.owner !== null){
                                                sendBid(props, props.bidPopUpHelpPublish.tmpId, props.bidPopUpHelpPublish.elemBidId)
                                            }else{
                                                sendDraft(props, props.bidPopUpHelpPublish.tmpId, props.bidPopUpHelpPublish.elemBidId)
                                            }
                                        }
                                        props.changeBidPopUpHelpPublish(null)
                                    }
                                }
                            >
                                Так
                            </div>
                            <div className={style.buttonNo}
                                onClick={
                                    () => {
                                        props.changeBidPopUpHelpPublish(null)
                                    }
                                }
                            >
                            Ні
                            </div>
                        </div>
                    </div>
                </div>
            }
        }
    }
    return tmp
}

async function sendDraft(props, bidId){
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.bidPopUpHelpPublish.elemBidId}/send_draft`;
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        props.changeLoader(false)
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            const json = await response.json()
            let tmp = JSON.stringify({title: "Помилка при створенні: ", message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            props.changeLoader(false)
        }else{
            await sendBid(props, bidId)
        }
    }
    props.changeLoader(false)
}

async function sendBid(props, bidId){
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.bidPopUpHelpPublish.elemBidId}/publish`;
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .then(
        async (response) => {
            if(props.auctionId.sellingMethod !== "dgf-english" ||
                props.auctionId.sellingMethod !== "dgf-english-fast" ||
                props.auctionId.sellingMethod !== "dgf-english-fast-manual" ||
                props.auctionId.sellingMethod !== "dgf-english-fast-auction-manual-qualification" ||
                props.auctionId.sellingMethod !== "dgf-english-initial-auction" ||
                props.auctionId.sellingMethod !== "dgf-english-initial-auction-manual" ||
                props.auctionId.sellingMethod !== "dgf-english-initial-qualification" ||
                props.auctionId.sellingMethod !== "dgf-dutch" ||
                props.auctionId.sellingMethod !== "dgf-dutch-fast" ||
                props.auctionId.sellingMethod !== "dgf-dutch-fast-manual" ||
                props.auctionId.sellingMethod !== "dgf-dutch-fast-auction-manual-qualification" ||
                props.auctionId.sellingMethod !== "dgf-dutch-initial-auction" ||
                props.auctionId.sellingMethod !== "dgf-dutch-initial-qualification" ||
                props.auctionId.sellingMethod !== "dgf-dutch-initial-auction-manual"
            ){
                /*const urlAsync = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionId.id}/sync`;
                const responseAsync = await fetch(urlAsync, {
                    method: "GET",
                    headers: {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
                }).catch(console.error)
                if(responseAsync === undefined || responseAsync === 'undefined'){
                    let tmp = JSON.stringify({title: "Помилка при публікації: ", message: ''})
                    props.setUniversalError(tmp)
                    let data = {
                        domen: process.env.REACT_APP_END_POINT_BD,
                        version: 'v1',
                        local: props.langFlag,
                        params: '?limit=20'
                    }
                    getUpdateDataBid(props, props.bidPopUpHelpPublish.elemBidId)
                }else{*/
                    const json = await response.json()
                    if(json.hasOwnProperty('success') === true){
                        if(json.success === true){
                            getUpdateDataBid(props, props.bidPopUpHelpPublish.elemBidId)
                            //props.auctionsBidsGetId(data, props.token.access_token, props.auctions.id)
                            let tmp = JSON.stringify({title: "Публікація пропозиції", message: 'Пропозиція відправлена на модерацію'})
                            props.setUniversalError(tmp)
                        }else{
                            let tmp = JSON.stringify({title: "Помилка при публікації: ", message: ''})
                            props.setUniversalError(tmp)
                        }
                    }else{
                        let tmp = JSON.stringify({title: "Помилка при публікації: ", message: ''})
                        props.setUniversalError(tmp)
                    }
                //}
            }else{
                const json = await response.json()
                if(json.hasOwnProperty('success') === true){
                    if(json.success === true){
                        getUpdateDataBid(props, props.bidPopUpHelpPublish.elemBidId)
                        let tmp = JSON.stringify({title: "Публікація пропозиції", message: 'Пропозиція відправлена на модерацію'})
                        props.setUniversalError(tmp)
                    }else{
                        let tmp = JSON.stringify({title: "Помилка при публікації: ", message: ''})
                        props.setUniversalError(tmp)
                    }
                }else{
                    let tmp = JSON.stringify({title: "Помилка при публікації: ", message: ''})
                    props.setUniversalError(tmp)
                }
            }
        }
    )
    .catch(console.error)
    props.changeLoader(false)
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        //auctions: state.start.auctionsGet,
        auctions: state.start.auctionFull,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy,
        auctionAwards: state.start.auctionAwards,
        popUpAwardReject: state.start.popUpAwardReject,
        popUpAwardError: state.start.popUpAwardError,
        bidPopUpEdit: state.start.bidPopUpEdit,
        bidPopUpHelpPublish: state.start.bidPopUpHelpPublish,
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
        curentBidLot: state.start.curentBidLot,

    }
}

const mapDispatchToProps = {
    changeBidPopUpHelpPublish,
    auctionsBidsGetId,
    setUniversalError,
    changeLoader,

    setDataAddress,
    saveArrBids,
    saveAuctionId,
}

export default connect(mapStateToProps, mapDispatchToProps)(BidHelpPublish)