import React from 'react';
import style from './createAddress.module.css';

import {connect} from 'react-redux';

import TextField from '@material-ui/core/TextField';

import {
    streetAddress,
    localityAddress,
    regionAddress,
    postalCodeAddress,
    countryNameAddress,
    popUpAddress,
    popUpAddressId,
    setPopAddressError,
    setPopAddressComplite,
    setKoatuuRegion, 
    setKoatuuGorod,
} from '../../../../redux/actions/address/address.js';

import {
    setDataAddress,
} from '../../../../redux/createAddress';

import { getRegion } from '../../../../api/dictonaries/region';

async function getKoatuuRegion(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/koatuu/byMask`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json1 = await response.json()
        if(json1.hasOwnProperty('message') === true){}
        else{
            props.setKoatuuRegion(json1)
        }
        
    }
}

const AuctionBankAccount = ( props ) => {
    if(props.koatuu.region === null){
        getKoatuuRegion(props)
    }

    let tmpRegion = null
    if(props.koatuu.region !== null){
        let tmpRegionOption = props.koatuu.region.map(
            (i) => {
                return <option key={i} value={`${i.classificationId}+:+${i.description}`}>{i.description}</option>
            }
        )
        tmpRegion = <TextField
            className={style.inputBank}
            id="outlined-select-currency-native"
            select
            label="Область"
            value={props.address.region}
            onChange={(event) => {
                props.regionAddress(event.target.value)
            }}
            SelectProps={{
                native: true,
            }}
            helperText=""
            variant="outlined"
        >
            <option key={0} selected disabled value='null'>Обрати</option>
            {tmpRegionOption}
        </TextField>
    }else{
        tmpRegion = <TextField
            className={style.inputBank}
            disabled
            id="outlined-select-currency-native"
            select
            label="Область"
            value={props.address.region}
            SelectProps={{
                native: true,
            }}
            helperText=""
            variant="outlined"
        >
            <option key={0} selected disabled value='null'>Обрати</option>
        </TextField>
    }
    let tmpGorod = null
    if(props.koatuu.gorod !== null){
        let tmpGorodOption = props.koatuu.gorod.map(
            (i, count) => {
                return <option key={count+1} value={i.description}>{i.description}</option>
            }
        )
        tmpGorod = <TextField
            className={style.inputBank}
            id="outlined-select-currency-native"
            select
            label="Населений пункт"
            value={props.address.locality}
            onChange={(event) => {
                props.localityAddress(event.target.value)
            }}
            SelectProps={{
                native: true,
            }}
            helperText=""
            variant="outlined"
        >
            <option key={0} selected disabled value='null'>Обрати</option>
            {tmpGorodOption}
        </TextField>
    }else{
        tmpGorod = <TextField
            className={style.inputBank}
            disabled
            id="outlined-select-currency-native"
            select
            label="Населений пункт"
            value={props.address.locality}
            SelectProps={{
                native: true,
            }}
            helperText=""
            variant="outlined"
        >
            <option key={0} selected disabled value='null'>Обрати</option>
        </TextField>
    }
    return (
        <div className={style.popup_wrap}>
            <div className={style.popup_message}>
                <div>Додати нову адресу</div>
                <div>
                    <TextField
                        className={style.inputBank}
                        disabled
                        label="Країна"
                        value={props.address.countryName}
                        onChange={(event) => {
                            props.countryNameAddress(event.target.value)
                        }}
                        variant="outlined"
                        helperText=" "
                    />
                </div>
                <div>
                    {tmpRegion}
                </div>
                <div>
                    <TextField
                        className={style.addressSity}
                        label="Населений пункт"
                        value={props.address.locality}
                        onChange={(event) => {
                            props.localityAddress(event.target.value)
                        }}
                        variant="outlined"
                        helperText=" "
                    />
                </div>
                <div>
                    <TextField
                        className={style.inputBank}
                        label="Поштовий індекс"
                        value={props.address.postalCode}
                        onChange={(event) => {
                            if(String(event.target.value).length < 6){
                                props.postalCodeAddress(event.target.value)
                            }
                        }}
                        variant="outlined"
                        helperText=" "
                        type='number'
                    />
                </div>
                <div>
                    <TextField
                        className={style.inputBank}
                        label="Вулиця, буд. №"
                        value={props.address.streetAddress}
                        onChange={(event) => {
                            props.streetAddress(event.target.value)
                        }}
                        variant="outlined"
                        helperText=" "
                    />
                </div>
                <div className={style.popup_message_button}
                    onClick={
                        () => {
                            if(
                                props.address.region !== 'null' &&
                                props.address.locality !== 'null' &&
                                props.address.postalCode !== '' &&
                                props.address.streetAddress !== ''
                            ){
                                if(props.address.id === null){
                                    if(props.createItems.addressId === null){
                                        createAddress(props)
                                    }else{
                                        editAddress(props)
                                    }
                                }else{
                                    editAddress(props)
                                }
                                props.popUpAddress(false)
                                props.popUpAddressId(null)
                            }
                        }
                    }
                >
                    Зберегти
                </div>
                <div className={style.popup_message_button}
                    onClick={
                        () => {
                            props.setDataAddress(null, 'SET_POP_UP_ADDRESS_ID')
                            props.popUpAddress(false)
                            props.streetAddress('')
                            props.localityAddress('null')
                            props.regionAddress('null')
                            props.postalCodeAddress('')
                            props.countryNameAddress(null)
                            props.popUpAddressId(null)
                        }
                    }
                >
                    Закрити
                </div>
            </div>
        </div>
    )
}

async function createAddress(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/address`;
    let r = props.address.region.split('+:+')
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify({
            addressIdentifier: {
                scheme: 'koatuu',
                identifier: r[0],
                name: r[1]
            },
            streetAddress: props.address.streetAddress,
            locality: props.address.locality,
            region: getRegion(r[1]),
            postalCode: props.address.postalCode,
            countryName:  'Україна'
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json1 = await response.json()
        if(json1.hasOwnProperty('message') === true){
            await props.setPopAddressError(json1)
            props.streetAddress('')
            props.localityAddress('null')
            props.regionAddress('null')
            props.postalCodeAddress('')
            props.countryNameAddress(null)
            props.setDataAddress(null, 'SET_POP_UP_ADDRESS_ID')
        }
        if(json1.hasOwnProperty('id') === true){
            await props.setPopAddressComplite(true)
            props.streetAddress('')
            props.localityAddress('null')
            props.regionAddress('null')
            props.postalCodeAddress('')
            props.countryNameAddress(null)
            props.setDataAddress(null, 'SET_POP_UP_ADDRESS_ID')
        }
    }
}

async function editAddress(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/address/${props.address.id}`;
    let r = props.address.region.split('+:+')
    const response = await fetch(endPoint, {
        method: "PUT",
        body: JSON.stringify({
            streetAddress: props.address.streetAddress,
            locality: props.address.locality,
            region: getRegion(r[1]),
            postalCode: props.address.postalCode,
            countryName: 'Україна'
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json1 = await response.json()
        if(json1.hasOwnProperty('message') === true){
            await props.setPopAddressError(json1)
            props.streetAddress('')
            props.localityAddress('null')
            props.regionAddress('null')
            props.postalCodeAddress('')
            props.countryNameAddress(null)
            props.setDataAddress(null, 'SET_POP_UP_ADDRESS_ID')
        }
        if(json1.hasOwnProperty('id') === true){
            await props.setPopAddressComplite(true)
            props.streetAddress('')
            props.localityAddress('null')
            props.regionAddress('null')
            props.postalCodeAddress('')
            props.countryNameAddress(null)
            props.setDataAddress(null, 'SET_POP_UP_ADDRESS_ID')
        }
    }
}


const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        address: state.start.address,
        koatuu: state.start.koatuu,
        addressGetId: state.start.addressGetId,
    }
}

const mapDispatchToProps = {
    streetAddress,
    localityAddress,
    regionAddress,
    postalCodeAddress,
    countryNameAddress,
    popUpAddress,
    popUpAddressId,
    setPopAddressError,
    setPopAddressComplite,
    setKoatuuRegion,
    setKoatuuGorod,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(AuctionBankAccount)