import React from 'react';
import styles from './nadra.module.css';

import { Link } from 'react-router-dom';

import {connect} from 'react-redux';

import {setAuctionCategory} from '../../../../redux/actions/support.js';
import {auctionsSearch, auctionsSearchСount, changeCurentPage} from '../../../../redux/actions/auctions/auctions.js';

const Nadra = ( {langFlag, aucyionsPath, search, token, auctionsSearch, auctionsSearchСount, setAuctionCategory, changeCurentPage, clearSearch} ) => {
    let active = null;
    if(search.category === 'subsoil-english'){
        active = styles.active;
    }
    return (
        <div
            className={styles.aukpoisk_second_elem}
        >
            <Link
                onClick={
                    ()=>{
                        setAuctionCategory('subsoil-all');
                        changeCurentPage(0);
                        let dataSearch = {}
                        dataSearch.order = search.order
                        dataSearch.region = search.region
                        dataSearch.category = 'subsoil-all'
                        dataSearch.priceCurrency = search.priceCurrency
                        dataSearch.priceFrom = search.priceFrom
                        dataSearch.priceTo = search.priceTo
                        dataSearch.measures = search.measures
                        dataSearch.measuresFrom = search.measuresFrom
                        dataSearch.measuresTo = search.measuresTo
                        if(search.status === null || search.status === ''){
                            dataSearch.status = null
                        }else{
                            dataSearch.status = search.status
                        }
                        let data = {
                            domen: process.env.REACT_APP_END_POINT_BD,
                            version: 'v1',
                            local: langFlag,
                            params: '?offset=0'
                        }
                        if(token !== null){
                            let header = {
                                'Authorization': token.access_token,
                                'Content-Type': 'application/json'
                            }
                            auctionsSearch(data, header, dataSearch);
                        }else{
                            let header = {
                                'Content-Type': 'application/json'
                            }
                            auctionsSearch(data, header, dataSearch);
                        }
                        clearSearch()
                    }
                }
                to={`${aucyionsPath}/subsoil${getUrlFiltersParam(search, 'subsoil-all')}`}
            >
                <div className={styles.base + ' ' + active}>
                    <svg height="454pt" viewBox="0 -3 454.792 454" width="454pt" xmlns="http://www.w3.org/2000/svg">
                        <path d="m399.433594 104.636719-.058594.015625 21.6875 43.917968 33.730469-9.070312-4.753907-73.222656-32.542968-65.882813-33.65625 9.074219 4.679687 73.214844zm0 0"/><path d="m2.398438 416.324219h448v32h-448zm0 0"/><path d="m197.839844 185.4375c-1.777344-.898438-3.511719-1.824219-5.191406-2.875-1-.621094-1.953126-1.285156-2.914063-1.964844-.796875-.554687-1.636719-1.035156-2.398437-1.601562l-59.335938 221.328125h16.550781l57.050781-212.992188c-.304687-.128906-.585937-.3125-.898437-.449219-.960937-.4375-1.894531-.957031-2.863281-1.445312zm0 0"/>
                        <path d="m289.519531 117.5625 86.863281-23.308594-3.621093-7.339844-.523438-8.09375-87.359375 23.382813c.875 1.953125 1.601563 4 2.234375 5.984375.152344.464844.367188.90625.511719 1.367188.804688 2.628906 1.4375 5.300781 1.894531 8.007812zm0 0"/>
                        <path d="m266.3125 101.570312c-10.757812-16.042968-30.089844-24.0625-49.042969-20.347656-18.957031 3.714844-33.828125 18.441406-37.734375 37.355469l-.96875 7.050781v.054688c-.113281.875-.167968 1.757812-.167968 2.640625 0 8.261719 2.121093 16.386719 6.167968 23.589843 1.363282 2.367188 2.925782 4.605469 4.671875 6.699219 8.21875 10.140625 20.230469 16.476563 33.242188 17.535157 13.011719 1.058593 25.890625-3.253907 35.640625-11.9375.09375-.085938.207031-.152344.304687-.230469 10.074219-9.128907 15.867188-22.058594 15.972657-35.65625v-.503907c-.0625-9.351562-2.875-18.480468-8.085938-26.25zm-15.066406 46.824219c-4.140625 5.179688-9.800782 8.933594-16.183594 10.738281-5.644531 1.574219-11.609375 1.574219-17.253906 0-6.285156-1.769531-11.871094-5.433593-16-10.488281-1.253906-1.472656-2.375-3.054687-3.34375-4.730469-2.667969-4.761718-4.066406-10.128906-4.066406-15.589843l.195312-2.25c.988281-13.609375 10.503906-25.09375 23.691406-28.601563 13.191406-3.507812 27.152344 1.738282 34.769532 13.058594 7.742187 11.660156 7.011718 26.996094-1.808594 37.863281zm0 0"/>
                        <path d="m164.335938 143.78125c-.351563-1.425781-.582032-2.875-.839844-4.320312-.214844-1.226563-.472656-2.402344-.617188-3.6875 0-.273438-.101562-.539063-.136718-.800782l-162.742188 43.566406 4.167969 15.511719 162.230469-43.480469c-.398438-1.070312-.644532-2.199218-.992188-3.292968-.34375-1.097656-.773438-2.3125-1.070312-3.496094zm0 0"/>
                        <path d="m263.121094 180.5625c-.992188.699219-1.96875 1.386719-3 2.027344-1.679688 1.046875-3.417969 1.964844-5.183594 2.855468-.953125.480469-1.890625 1.007813-2.867188 1.4375-.308593.128907-.589843.320313-.902343.449219l57.054687 212.992188h16.578125l-59.328125-221.351563c-.746094.550782-1.578125 1.046875-2.351562 1.589844zm0 0"/>
                        <path d="m226.398438 112.324219c-8.339844-.023438-15.265626 6.429687-15.832032 14.75l-.160156 1.746093c.046875 2.523438.710938 4.996094 1.9375 7.199219.527344.878907 1.128906 1.707031 1.800781 2.480469 3.984375 4.90625 10.515625 6.960938 16.589844 5.222656 3.226563-.9375 6.074219-2.859375 8.152344-5.496094 4.332031-5.40625 4.691406-12.988281.890625-18.78125-3-4.453124-8.011719-7.121093-13.378906-7.121093zm0 0"/>
                        <path d="m10.460938 200.421875 15.5625-3.714844 48.15625 201.753907-15.5625 3.714843zm0 0"/>
                    </svg>
                </div>
                <div className={styles.aukpoisk_second_elem_text}>Користування надрами</div>
            </Link>
            <div className={styles.aukpoisk_second_elem_1}>
                <Link to={`${aucyionsPath}/subsoil${getUrlFiltersParam(search, 'subsoil-english')}`}>
                    <div
                        className={styles.subMenu}
                        onClick={
                            ()=>{
                                setAuctionCategory('subsoil-english');
                                changeCurentPage(0);
                                let dataSearch = {}
                                dataSearch.order = search.order
                                dataSearch.region = search.region
                                dataSearch.category = 'subsoil-english'
                                dataSearch.priceCurrency = search.priceCurrency
                                dataSearch.priceFrom = search.priceFrom
                                dataSearch.priceTo = search.priceTo
                                dataSearch.measures = search.measures
                                dataSearch.measuresFrom = search.measuresFrom
                                dataSearch.measuresTo = search.measuresTo
                                if(search.status === null || search.status === ''){
                                    dataSearch.status = null
                                }else{
                                    dataSearch.status = search.status
                                }
                                let data = {
                                    domen: process.env.REACT_APP_END_POINT_BD,
                                    version: 'v1',
                                    local: langFlag,
                                    params: '?offset=0'
                                }
                                if(token !== null){
                                    let header = {
                                        'Authorization': token.access_token,
                                        'Content-Type': 'application/json'
                                    }
                                    auctionsSearch(data, header, dataSearch);
                                }else{
                                    let header = {
                                        'Content-Type': 'application/json'
                                    }
                                    auctionsSearch(data, header, dataSearch);
                                }
                                clearSearch()
                            }
                        }
                    >
                        Користування надрами. Англієць
                    </div>
                </Link>
                <Link to={`${aucyionsPath}/subsoil${getUrlFiltersParam(search, 'subsoil-dutch')}`}>
                    <div
                        className={styles.subMenu}
                        onClick={
                            ()=>{
                                setAuctionCategory('subsoil-dutch');
                                changeCurentPage(0);
                                let dataSearch = {}
                                dataSearch.order = search.order
                                dataSearch.region = search.region
                                dataSearch.category = 'subsoil-dutch'
                                dataSearch.priceCurrency = search.priceCurrency
                                dataSearch.priceFrom = search.priceFrom
                                dataSearch.priceTo = search.priceTo
                                dataSearch.measures = search.measures
                                dataSearch.measuresFrom = search.measuresFrom
                                dataSearch.measuresTo = search.measuresTo
                                if(search.status === null || search.status === ''){
                                    dataSearch.status = null
                                }else{
                                    dataSearch.status = search.status
                                }
                                let data = {
                                    domen: process.env.REACT_APP_END_POINT_BD,
                                    version: 'v1',
                                    local: langFlag,
                                    params: '?offset=0'
                                }
                                if(token !== null){
                                    let header = {
                                        'Authorization': token.access_token,
                                        'Content-Type': 'application/json'
                                    }
                                    auctionsSearch(data, header, dataSearch);
                                }else{
                                    let header = {
                                        'Content-Type': 'application/json'
                                    }
                                    auctionsSearch(data, header, dataSearch);
                                }
                                clearSearch()
                            }
                        }
                    >
                        Користування надрами. Голандець
                    </div>
                </Link>
            </div>
        </div>
    )
}

function getUrlFiltersParam(search, type){
    let tmp = null
    if(search.orderBy !== null){
        if(tmp === null){
            tmp = '?orderBy=' + search.orderBy
        }else{
            tmp += '&orderBy=' + search.orderBy
        }
    }
    if(search.status !== null){
        if(tmp === null){
            tmp = '?status=' + search.status
        }else{
            tmp += '&status=' + search.status
        }
    }
    if(search.region !== null){
        if(tmp === null){
            tmp = '?region=' + search.region
        }else{
            tmp += '&region=' + search.region
        }
    }
    //if(search.category !== null){
        if(tmp === null){
            tmp = `?category=${type}`
        }else{
            tmp += `&category=${type}`
        }
    //}
    if(search.priceCurrency !== null){
        if(tmp === null){
            tmp = '?priceCurrency=' + search.priceCurrency
        }else{
            tmp += '&priceCurrency=' + search.priceCurrency
        }
    }
    if(search.priceFrom !== null){
        if(tmp === null){
            tmp = '?priceFrom=' + search.priceFrom
        }else{
            tmp += '&priceFrom=' + search.priceFrom
        }
    }
    if(search.priceFromBag !== false){
        if(tmp === null){
            tmp = '?priceFromBag=true'
        }else{
            tmp += '&priceFromBag=true'
        }
    }
    if(search.priceTo !== null){
        if(tmp === null){
            tmp = '?priceTo=' + search.priceTo
        }else{
            tmp += '&priceTo=' + search.priceTo
        }
    }
    if(search.priceToBag !== false){
        if(tmp === null){
            tmp = '?priceToBag=true'
        }else{
            tmp += '&priceToBag=true'
        }
    }
    if(search.measures !== null){
        if(tmp === null){
            tmp = '?measures=' + search.measures
        }else{
            tmp += '&measures=' + search.measures
        }
    }
    if(search.measuresFrom !== null){
        if(tmp === null){
            tmp = '?measuresFrom=' + search.measuresFrom
        }else{
            tmp += '&measuresFrom=' + search.measuresFrom
        }
    }
    if(search.measuresTo !== null){
        if(tmp === null){
            tmp = '?measuresTo=' + search.measuresTo
        }else{
            tmp += '&measuresTo=' + search.measuresTo
        }
    }
    if(search.textSting !== null){
        if(tmp === null){
            tmp = '?keyword=' + search.textSting
        }else{
            tmp += '&keyword=' + search.textSting
        }
    }
    if(search.selectTypeKeyword !== null){
        if(tmp === null){
            tmp = '?selectTypeKeyword=' + search.selectTypeKeyword
        }else{
            tmp += '&selectTypeKeyword=' + search.selectTypeKeyword
        }
    }
    if(tmp !== null){
        return tmp
    }
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        aucyionsPath: state.start.aucyionsPath,
        search: state.start.search,
        token: state.start.token,
    }
}

const mapDispatchToProps = {
    auctionsSearch,
    auctionsSearchСount,
    setAuctionCategory,
    changeCurentPage,
}

export default connect(mapStateToProps, mapDispatchToProps)(Nadra)