import React from 'react';
import style from './contractApprowe.module.css';

import {connect} from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import {auctionsGetAwards, auctionsGetAwardsId, changeAwardRejectPopUpStatus, changeAwardErrorPopUpStatus, changeAwardApprowePopUpStatus} from '../../../../redux/actions/awards/awards.js';
import {
    changeContractApprowePopUpStatus,
    changeContractErrorPopUpStatus,

    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,
} from '../../../../redux/actions/contracts/contracts.js';

import ContractUniversal from '../popupContract.js';

const ContractApproweMain = ( props ) => {
    let tmp = null;

    if(props.popUpContractApprowe !== null){
        switch(props.popUpContractApprowe.sellingMethod){
            case "timber-english":
            case "timber-english-fast":
            case "timber-english-fast-manual":
            case "timber-english-initial-auction":
            case "timber-english-initial-qualification":
            case "timber-english-initial-qualification-fast":
                //tmp = <ContractApproweTimber />
                tmp = <ContractUniversal />
                break;
            case "renewables":
            case "renewables-fast":
            case "renewables-fast-manual":
            case "renewables-initial-auction":
            case "renewables-initial-qualification":
            case "renewables-initial-qualification-fast":
                //tmp = <ContractApproweEnergy />
                tmp = <ContractUniversal />
                break;
            case "subsoil-english":
            case "subsoil-english-fast":
            case "subsoil-english-fast-manual":
            case "subsoil-english-initial-auction":
            case "subsoil-english-initial-qualification":
            case "subsoil-dutch":
            case "subsoil-dutch-fast":
            case "subsoil-dutch-fast-manual":
            case "subsoil-dutch-initial-auction":
            case "subsoil-dutch-initial-qualification":
            case "subsoil-dutch-initial-auction-manual":
            case "subsoil-english-fast-auction-manual-qualification":
                tmp = <ContractUniversal />
                //tmp = <ContractApproweEnergy />
                break;
            case "railwayCargo-english":
            case "railwayCargo-english-fast":
            case "railwayCargo-english-fast-manual":
            case "railwayCargo-english-initial-auction":
            case "railwayCargo-english-initial-qualification":
                tmp = <ContractUniversal />
                //tmp = <ContractApproweEnergy />
                break;
            case "legitimatePropertyLease-english":
            case "legitimatePropertyLease-english-fast":
            case "legitimatePropertyLease-english-fast-manual":
            case "legitimatePropertyLease-english-fast-auction-manual-qualification":
            case "legitimatePropertyLease-english-initial-auction":
            case "legitimatePropertyLease-english-initial-auction-manual":
            case "legitimatePropertyLease-english-initial-qualification":
            case "legitimatePropertyLease-english-initial-qualification-prod":
            case "legitimatePropertyLease-dutch":
            case "legitimatePropertyLease-dutch-fast":
            case "legitimatePropertyLease-dutch-fast-manual":
            case "legitimatePropertyLease-dutch-fast-auction-manual-qualification":
            case "legitimatePropertyLease-dutch-initial-auction":
            case "legitimatePropertyLease-dutch-initial-auction-manual":
            case "legitimatePropertyLease-dutch-initial-qualification-prod":
            case "legitimatePropertyLease-priorityEnglish":
            case "legitimatePropertyLease-priorityEnglish-fast":
            case "legitimatePropertyLease-priorityEnglish-fast-manual":
            case "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification":
            case "legitimatePropertyLease-priorityEnglish-initial-auction":
            case "legitimatePropertyLease-priorityEnglish-initial-auction-manual":
            case "legitimatePropertyLease-priorityEnglish-initial-qualification":
            case "legitimatePropertyLease-priorityEnglish-initial-qualification-prod":
                tmp = <ContractUniversal />
                //tmp = <ContractApproweEnergy />
                break;
            case 'landRental-english':
            case 'landRental-english-fast':
            case 'landRental-english-fast-manual':
            case 'landRental-english-fast-auction-manual-qualification':
            case 'landRental-english-initial-auction':
            case 'landRental-english-initial-auction-manual':
            case 'landRental-english-initial-qualification':
            case 'landRental-english-initial-qualification-prod':
                tmp = <ContractUniversal />
                break;
            case 'landSell-english':
            case 'landSell-english-fast':
            case 'landSell-english-fast-manual':
            case 'landSell-english-fast-auction-manual-qualification':
            case 'landSell-english-initial-auction':
            case 'landSell-english-initial-auction-manual':
            case 'landSell-english-initial-qualification':
            case 'landSell-english-initial-qualification-prod':
                tmp = <ContractUniversal />
                break;
            case 'landSell-priorityEnglish':
            case 'landSell-priorityEnglish-fast':
            case 'landSell-priorityEnglish-fast-manual':
            case 'landSell-priorityEnglish-fast-auction-manual-qualification':
            case 'landSell-priorityEnglish-initial-auction':
            case 'landSell-priorityEnglish-initial-auction-manual':
            case 'landSell-priorityEnglish-initial-qualification':
            case 'landSell-priorityEnglish-initial-qualification-prod':
                tmp = <ContractUniversal /> 
                break;
            case "dgf-english":
            case "dgf-english-fast":
            case "dgf-english-fast-manual":
            case "dgf-english-fast-auction-manual-qualification":
            case "dgf-english-initial-auction":
            case "dgf-english-initial-auction-manual":
            case "dgf-english-initial-qualification":
            case "dgf-dutch":
            case "dgf-dutch-fast":
            case "dgf-dutch-fast-manual":
            case "dgf-dutch-fast-auction-manual-qualification":
            case "dgf-dutch-initial-auction":
            case "dgf-dutch-initial-qualification":
            case "dgf-dutch-initial-auction-manual":
                tmp = <ContractUniversal />
                break;
            case 'basicSell-english':
            case 'basicSell-english-fast':
            case 'basicSell-english-fast-manual':
            case 'basicSell-english-fast-auction-manual-qualification':
            case 'basicSell-english-initial-auction':
            case 'basicSell-english-initial-auction-manual':
            case 'basicSell-english-initial-qualification':
            case 'basicSell-english-initial-qualification-prod':
                tmp = <ContractUniversal />
                break;
            case 'basicSell-dutch':
            case 'basicSell-dutch-fast':
            case 'basicSell-dutch-fast-manual':
            case 'basicSell-dutch-fast-auction-manual-qualification':
            case 'basicSell-dutch-initial-auction':
            case 'basicSell-dutch-initial-auction-manual':
            case 'basicSell-dutch-initial-qualification':
            case 'basicSell-dutch-initial-qualification-prod':
                tmp = <ContractUniversal />
                break;
            case 'alienation-english':
            case 'alienation-english-fast':
            case 'alienation-english-fast-manual':
            case 'alienation-english-fast-auction-manual-qualification':
            case 'alienation-english-initial-auction':
            case 'alienation-english-initial-auction-manual':
            case 'alienation-english-initial-qualification':
            case 'alienation-english-initial-qualification-prod':
                tmp = <ContractUniversal />
                break;
            case 'commercialSell-english':
            case 'commercialSell-english-fast':
            case 'commercialSell-english-fast-manual':
            case 'commercialSell-english-fast-auction-manual-qualification':
            case 'commercialSell-english-initial-auction':
            case 'commercialSell-english-initial-auction-manual':
            case 'commercialSell-english-initial-qualification':
            case 'commercialSell-english-initial-qualification-prod':
                tmp = <ContractUniversal />
                break;
            case 'commercialSell-dutch':
            case 'commercialSell-dutch-fast':
            case 'commercialSell-dutch-fast-manual':
            case 'commercialSell-dutch-fast-auction-manual-qualification':
            case 'commercialSell-dutch-initial-auction':
            case 'commercialSell-dutch-initial-auction-manual':
            case 'commercialSell-dutch-initial-qualification':
            case 'commercialSell-dutch-initial-qualification-prod':
                tmp = <ContractUniversal />
                break;
            case 'bankRuptcy-english':
            case 'bankRuptcy-english-fast':
            case 'bankRuptcy-english-fast-manual':
            case 'bankRuptcy-english-fast-auction-manual-qualification':
            case 'bankRuptcy-english-initial-auction':
            case 'bankRuptcy-english-initial-auction-manual':
            case 'bankRuptcy-english-initial-qualification':
            case 'bankRuptcy-english-initial-qualification-prod':
                tmp = <ContractUniversal />
                break;
            case 'bankRuptcy-dutch':
            case 'bankRuptcy-dutch-fast':
            case 'bankRuptcy-dutch-fast-manual':
            case 'bankRuptcy-dutch-fast-auction-manual-qualification':
            case 'bankRuptcy-dutch-initial-auction':
            case 'bankRuptcy-dutch-initial-auction-manual':
            case 'bankRuptcy-dutch-initial-qualification':
            case 'bankRuptcy-dutch-initial-qualification-prod':
                tmp = <ContractUniversal />
                break;
            case 'bankRuptcy-withoutAuction':
                tmp = <ContractUniversal />
                break;
            case 'commercialPropertyLease-english':
            case 'commercialPropertyLease-english-ultra-fast':
            case 'commercialPropertyLease-english-fast':
            case 'commercialPropertyLease-english-fast-manual':
            case 'commercialPropertyLease-english-fast-auction-manual-qualification':
            case 'commercialPropertyLease-english-fast-auction-prod':
            case 'commercialPropertyLease-english-initial-auction':
            case 'commercialPropertyLease-english-initial-auction-manual':
            case 'commercialPropertyLease-english-initial-qualification':
            case 'commercialPropertyLease-english-initial-qualification-prod':
            case 'commercialPropertyLease-dutch':
            case 'commercialPropertyLease-dutch-ultra-fast':
            case 'commercialPropertyLease-dutch-fast':
            case 'commercialPropertyLease-dutch-fast-manual':
            case 'commercialPropertyLease-dutch-fast-auction-manual-qualification':
            case 'commercialPropertyLease-dutch-fast-auction-prod':
            case 'commercialPropertyLease-dutch-initial-auction':
            case 'commercialPropertyLease-dutch-initial-auction-manual':
            case 'commercialPropertyLease-dutch-initial-qualification':
            case 'commercialPropertyLease-dutch-initial-qualification-prod':
            case 'regulationsPropertyLease-english':
            case 'regulationsPropertyLease-english-ultra-fast':
            case 'regulationsPropertyLease-english-fast':
            case 'regulationsPropertyLease-english-fast-manual':
            case 'regulationsPropertyLease-english-fast-auction-manual-qualification':
            case 'regulationsPropertyLease-english-fast-auction-prod':
            case 'regulationsPropertyLease-english-initial-auction':
            case 'regulationsPropertyLease-english-initial-auction-manual':
            case 'regulationsPropertyLease-english-initial-qualification':
            case 'regulationsPropertyLease-english-initial-qualification-prod':
            case 'regulationsPropertyLease-dutch':
            case 'regulationsPropertyLease-dutch-ultra-fast':
            case 'regulationsPropertyLease-dutch-fast':
            case 'regulationsPropertyLease-dutch-fast-manual':
            case 'regulationsPropertyLease-dutch-fast-auction-manual-qualification':
            case 'regulationsPropertyLease-dutch-fast-auction-prod':
            case 'regulationsPropertyLease-dutch-initial-auction':
            case 'regulationsPropertyLease-dutch-initial-auction-manual':
            case 'regulationsPropertyLease-dutch-initial-qualification':
            case 'regulationsPropertyLease-dutch-initial-qualification-prod':
                tmp = <ContractUniversal />
                break;
            case 'smallPrivatization-english':
            case "smallPrivatization-english-fast":
            case "smallPrivatization-english-ultra-fast":
            case "smallPrivatization-english-fast-manual":
            case "smallPrivatization-english-fast-auction-manual-qualification":
            case "smallPrivatization-english-fast-auction-prod":
            case "smallPrivatization-english-initial-auction":
            case "smallPrivatization-english-initial-auction-manual":
            case "smallPrivatization-english-initial-qualification":
            case "smallPrivatization-english-initial-qualification-prod":
            case "smallPrivatization-dutch":
            case "smallPrivatization-dutch-fast":
            case "smallPrivatization-dutch-ultra-fast":
            case "smallPrivatization-dutch-fast-manual":
            case "smallPrivatization-dutch-fast-auction-manual-qualification":
            case "smallPrivatization-dutch-fast-auction-prod":
            case "smallPrivatization-dutch-initial-auction":
            case "smallPrivatization-dutch-initial-auction-manual":
            case "smallPrivatization-dutch-initial-qualification":
            case "smallPrivatization-dutch-initial-qualification-prod":
                tmp = <ContractUniversal />
                break;
            case "nonperformingLoans-english":
            case "nonperformingLoans-english-fast":
            case "nonperformingLoans-english-fast-manual":
            case "nonperformingLoans-english-fast-auction-manual-qualification":
            case "nonperformingLoans-english-fast-auction-prod":
            case "nonperformingLoans-english-initial-qualification":
            case "nonperformingLoans-english-initial-qualification-prod":
            case "nonperformingLoans-english-initial-auction":
            case "nonperformingLoans-english-initial-auction-manual": 
            case "nonperformingLoans-dutch, nonperformingLoans-dutch-fast":
            case "nonperformingLoans-dutch-fast-manual":
            case "nonperformingLoans-dutch-fast-auction-manual-qualification":
            case "nonperformingLoans-dutch-fast-auction-prod":
            case "nonperformingLoans-dutch-initial-auction":
            case "nonperformingLoans-dutch-initial-auction-manual":
            case "nonperformingLoans-dutch-initial-qualification":
            case "nonperformingLoans-dutch-initial-qualification-prod":
                tmp = <ContractUniversal />
                break;
            case 'landArrested-english':
            case 'landArrested-english-ultra-fast':
            case 'landArrested-english-fast':
            case 'landArrested-english-fast-manual':
            case 'landArrested-english-fast-auction-manual-qualification':
            case 'landArrested-english-fast-auction-prod':
            case 'landArrested-english-initial-auction':
            case 'landArrested-english-initial-auction-manual':
            case 'landArrested-english-initial-qualification':
            case 'landArrested-english-initial-qualification-prod':
            case 'landArrested-withoutAuction':
            case "landArrested-priorityEnglish":
            case "landArrested-priorityEnglish-ultra-fast":
            case "landArrested-priorityEnglish-fast":
            case "landArrested-priorityEnglish-fast-manual":
            case "landArrested-priorityEnglish-fast-auction-manual-qualification":
            case "landArrested-priorityEnglish-fast-auction-prod":
            case "landArrested-priorityEnglish-initial-auction":
            case "landArrested-priorityEnglish-initial-auction-manual":
            case "landArrested-priorityEnglish-initial-qualification":
            case "landArrested-priorityEnglish-initial-qualification-prod":
                tmp = <ContractUniversal />
                break;
            case "largePrivatization-english":
            case "largePrivatization-english-fast":
            case "largePrivatization-english-ultra-fast":
            case "largePrivatization-english-fast-manual":
            case "largePrivatization-english-fast-auction-manual-qualification":
            case "largePrivatization-english-fast-auction-prod":
            case "largePrivatization-english-initial-auction":
            case "largePrivatization-english-initial-auction-manual":
            case "largePrivatization-english-initial-qualification":
            case "largePrivatization-english-initial-qualification-prod":
                tmp = <ContractUniversal />
                break;
            case "armaProperty-english":
            case "armaProperty-english-ultra-fast":
            case "armaProperty-english-fast":
            case "armaProperty-english-fast-manual":
            case "armaProperty-english-fast-auction-manual-qualification":
            case "armaProperty-english-fast-auction-prod":
            case "armaProperty-english-initial-auction":
            case "armaProperty-english-initial-auction-manual":
            case "armaProperty-english-initial-qualification":
            case "armaProperty-english-initial-qualification-prod":
            case "armaProperty-dutch":
            case "armaProperty-dutch-ultra-fast":
            case "armaProperty-dutch-fast":
            case "armaProperty-dutch-fast-manual":
            case "armaProperty-dutch-fast-auction-manual-qualification":
            case "armaProperty-dutch-fast-auction-prod":
            case "armaProperty-dutch-initial-auction":
            case "armaProperty-dutch-initial-auction-manual":
            case "armaProperty-dutch-initial-qualification":
            case "armaProperty-dutch-initial-qualification-prod":
                tmp = <ContractUniversal />
                break;
            default:
                break;
        }
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy,
        auctionAwards: state.start.auctionAwards,
        popUpAwardReject: state.start.popUpAwardReject,
        popUpAwardApprowe: state.start.popUpAwardApprowe,
        popUpContractApprowe: state.start.popUpContractApprowe,
        timberContractPut: state.start.timberContractPut,
    }
}

const mapDispatchToProps = {
    auctionsGetAwards,
    auctionsGetAwardsId,
    changeAwardRejectPopUpStatus,
    changeAwardErrorPopUpStatus,
    changeAwardApprowePopUpStatus,
    changeContractApprowePopUpStatus,
    changeContractErrorPopUpStatus,
    
    setContractTitle,
    setContractDescription,
    setContractContractNumber,


    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractApproweMain)