import React from 'react';

import {connect} from 'react-redux';

import TimberItems from './auctionType/timberItems.js';
import EnergyAuction from './auctionType/energyType.js';
import SubsoilItems from './auctionType/subsoilItems.js';
import RailwayItems from './auctionType/railWayItems.js';
import DgfAuction from './auctionType/dgf.js';
import WarehouseComposition from './auctionType/warehouseComposition'
import LandRental from './auctionType/landRental'
import BasicSell from './auctionType/basicSell'
import Alienation from './auctionType/alienation'
import CommercialSell from './auctionType/commercialSell'
import BRE from './auctionType/bre'
import BREW from './auctionType/breWithout'
import RLECLE from './auctionType/RleRldCleCld'
import SPE from './auctionType/spe'
import LAE from './auctionType/lae'
import LAW from './auctionType/law'
import NLE from './auctionType/nlenld'
import APE from './auctionType/ape'

const StructureLot = (props) => {
    let tmp = null;
    if(props.auction !== null){
        switch(true){
            case props.auction.sellingMethod.startsWith("timber-english"):
                tmp = <TimberItems />
                break;
            case props.auction.sellingMethod.startsWith("renewables"):
                tmp = <EnergyAuction />
                break;
            case props.auction.sellingMethod.startsWith("subsoil"):
                tmp = <SubsoilItems />
                break;
            case props.auction.sellingMethod.startsWith("railwayCargo"):
                tmp = <RailwayItems />
                break;
            case props.auction.sellingMethod.startsWith("dgf"):
                tmp = <DgfAuction />
                break;
            case props.auction.sellingMethod.startsWith("legitimatePropertyLease"):
                tmp = <WarehouseComposition />
                break;
            case props.auction.sellingMethod.startsWith("landRental"):
            case props.auction.sellingMethod.startsWith("landSell"):
                tmp = <LandRental />
                break;
            case props.auction.sellingMethod.startsWith("basicSell"):
                tmp = <BasicSell />
                break;
            case props.auction.sellingMethod.startsWith("alienation"):
                tmp = <Alienation />
                break;
            case props.auction.sellingMethod.startsWith("commercialSell"):
                tmp = <CommercialSell />
                break;
            case props.auction.sellingMethod.startsWith("bankRuptcy-english"):
            case props.auction.sellingMethod.startsWith("bankRuptcy-dutch"):
                tmp = <BRE />
                break;
            case props.auction.sellingMethod.startsWith("bankRuptcy-withoutAuction"):
                tmp = <BREW />
                break;
            case props.auction.sellingMethod.startsWith("regulationsPropertyLease"):
            case props.auction.sellingMethod.startsWith("commercialPropertyLease"):
                tmp = <RLECLE />
                break;
            case props.auction.sellingMethod.startsWith("smallPrivatization"):
            case props.auction.sellingMethod.startsWith("largePrivatization"):
                tmp = <SPE />
                break;
            case props.auction.sellingMethod.startsWith("nonperformingLoans"):
                tmp = <NLE />
                break;
            case props.auction.sellingMethod.startsWith("landArrested-english"):
            case props.auction.sellingMethod.startsWith("landArrested-priorityEnglish"):
                tmp = <LAE />
                break;
            case props.auction.sellingMethod.startsWith("landArrested-withoutAuction"):
            case "landArrested-withoutAuction":
                tmp = <LAW />
                break;
            case props.auction.sellingMethod.startsWith("armaProperty"):
                tmp = <APE />
                break;
            default:
                break;
        }
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        auction: state.start.auctionFull,
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(StructureLot)
