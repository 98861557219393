import React from 'react';
import styles from './mainFind.module.css';
import stylesZu from './mainFindZu.module.css';

import {connect} from 'react-redux';
import { Link } from 'react-router-dom';

//import TextField from '@material-ui/core/TextField';

import {
    PLACEHOLDER_FIRST_FIND_LANG,
    FIRST_SELECTED_FIRST_FIND_LANG,
    SECOND_SELECTED_FIRST_FIND_LANG,
    BUTTON_FIRST_FIND_LANG
} from '../../../model/lang/firstFindLine.js';
import {
    auctionsSearch,
    auctionsSearchTest,
    auctionsSearchСount,
    auctionsSearchOld,
    auctionsSearchСountOld,
    changeCurentPage,
    setSearchTextSting,
    setSearchSelectTypeKeyword,
    changeSearchDateStart,
    changeSearchDateEnd,
    changeStatusAuction,
} from '../../../redux/actions/auctions/auctions';

import {setAuctionCategory} from '../../../redux/actions/support.js';
import {setDataAddress} from '../../../redux/createAddress';
import {
    streetAddress,
    localityAddress,
    regionAddress,
    postalCodeAddress,
    countryNameAddress,
    popUpAddress,
    popUpAddressId,
    setPopAddressError,
    setPopAddressComplite,
    setKoatuuRegion, 
    setKoatuuGorod,
} from '../../../redux/actions/address/address.js';

//import SnoozeIcon from "@material-ui/icons/Snooze";
//import AlarmIcon from "@material-ui/icons/AddAlarm";
//import { IconButton, InputAdornment } from "@material-ui/core";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import TextField from '@material-ui/core/TextField';

import { getRegion } from '../../../api/dictonaries/region';

import MainBlock from './mainBlock.js';

const MainFind = ( props ) => {
    let advansButton = null;
    if(props.search.category === 'dgf'){
        advansButton = <Link to={`${props.aucyionsPath}/dgf`}>
            {BUTTON_FIRST_FIND_LANG[props.langFlag]}
        </Link>
    }else if(props.search.category === 'subsoil-all'){
        advansButton = <Link to={`${props.aucyionsPath}/subsoil`}>
            {BUTTON_FIRST_FIND_LANG[props.langFlag]}
        </Link>
    }else if(props.search.category === 'railway-all'){
        advansButton = <Link to={`${props.aucyionsPath}/railway-cargo`}>
            {BUTTON_FIRST_FIND_LANG[props.langFlag]}
        </Link>
    }else if(props.search.category === 'timber-english'){
        advansButton = <Link to={`${props.aucyionsPath}/timber`}>
            {BUTTON_FIRST_FIND_LANG[props.langFlag]}
        </Link>
    }else if(props.search.category === 'renewables'){
        advansButton = <Link to={`${props.aucyionsPath}/renewables`}>
            {BUTTON_FIRST_FIND_LANG[props.langFlag]}
        </Link>
    }else{
        advansButton = <Link to={`${props.aucyionsPath}`}>
            {BUTTON_FIRST_FIND_LANG[props.langFlag]}
        </Link>
    }
    return (
        <div className={styles.aukpoisk_first}>
            <MainBlock />
            {/* <div
                className={styles.aukpoisk_four_second_wrap}
            >
                <div className={styles.searchElem}>
                    <div className={styles.searchElem_line}>
                        <div className={styles.validfilterBlock}>
                            <input
                                type="text"
                                name="filter[search]"
                                data-format="price"
                                placeholder={PLACEHOLDER_FIRST_FIND_LANG[props.langFlag]}
                                className={styles.validfilter}
                                value = {props.search.textSting}
                                onChange={
                                    (e)=>{
                                        props.setSearchTextSting(e.target.value)
                                    }
                                }
                            />
                        </div>
                        <div className={styles.validfilterBlockS}>
                            <select
                                className={styles.validfilterSelect}
                                value = {props.search.selectTypeKeyword}
                                onChange={
                                    (e) => {
                                        props.setSearchSelectTypeKeyword(e.target.value)
                                    }
                                }
                            >
                                <option key={0} value='0'>Оберить крітерій пошуку</option>
                                <option key={1} value='1'>ID аукціону</option>
                                <option key={3} value='3'>Організатор / ЕДРПОУ / ІПН</option>
                                <option key={2} value='2'>Назва аукціону,опис аукціону, опис лоту</option>
                                <option key={4} value='4'>Ідентифікатор об’єкта</option>
                            </select>
                        </div>
                        {handleID(props, advansButton)}
                    </div>


                    <div className={styles.searchElem_line + ' ' + styles.linemid}>
                        <div className={styles.dateSearch}>
                            <div>Дата початку аукціону</div>
                            <KeyboardDateTimePicker
                                className={styles.dateSearch}
                                value={props.search.startDateFrom}
                                onChange={
                                    (e) => {
                                        props.changeSearchDateStart(e)
                                    }
                                }
                                onError={console.log}
                                minDate={new Date("1900-01-01T00:00")}
                                ampm={false}
                                format="yyyy/MM/dd HH:mm"
                            />
                        </div>
                        <div className={styles.dateSearch}>
                            <div>Дата завершення аукціону</div>
                            <KeyboardDateTimePicker
                                className={styles.dateSearch}
                                value={props.search.startDateTo}
                                onChange={
                                    (e) => {
                                        props.changeSearchDateEnd(e)
                                    }
                                }
                                onError={console.log}
                                minDate={new Date("1900-01-01T00:00")}
                                ampm={false}
                                format="yyyy/MM/dd HH:mm"
                            />
                        </div>
                        <div className={styles.dateSearch}>
                            <div>Статус аукціону</div>
                            <select
                                className={styles.validfilterSelect}
                                value = {props.search.status}
                                onChange={
                                    (e) => {
                                        props.changeStatusAuction(e.target.value)
                                        //getSearchArr(props, e.target.value)
                                    }
                                }
                            >
                                <option key='1' value=''>Всі статуси</option>
                                <option key='2' value="active_rectification">Редагування доступне</option>
                                <option key='3' value="active_tendering">Прийняття заяв на участь</option>
                                <option key='4' value="active_auction">Аукціон</option>
                                <option key='5' value="active_qualification">Очікується опублікування протоколу</option>
                                <option key='6' value="active_awarded">Очікується підписання договору</option>
                                <option key='7' value="complete">Аукціон завершено. Договір підписано</option>
                                <option key='8' value="unsuccessful">Аукціон не відбувся</option>
                                <option key='9' value="cancelled">Торги відмінено</option>
                            </select>
                        </div>
                    </div>
                    <div className={styles.searchElem_line + ' ' + styles.linemid}>
                        <div className={stylesZu.area_wrap}>
                            <div className={stylesZu.area_data_wrap}>
                                <div>Вартість процедури від</div>
                                <input
                                    type="number"
                                    data-format="price"
                                    value = {props.search.priceFrom}
                                    onChange={
                                        (e)=>{
                                            props.setDataAddress(e.target.value, 'SET_PRICE_FROM')
                                        }
                                    }
                                />
                            </div>
                            <div className={stylesZu.area_data_wrap}>
                                <div>Вартість процедури до</div>
                                <input
                                    type="number"
                                    data-format="price"
                                    value = {props.search.priceTo}
                                    onChange={
                                        (e)=>{
                                            props.setDataAddress(e.target.value, 'SET_PRICE_TO')
                                        }
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    {viewAddSearchZU(props)}
                    {landRentalFind(props)}
                </div>
            </div>*/}
        </div>
    )
}

function viewAddSearchZU(props){
    if(
        props.search.category === 'legitimatePropertyLease' ||
        props.search.category === 'legitimatePropertyLease-english' ||
        props.search.category === 'legitimatePropertyLease-dutch' ||
        props.search.category === 'legitimatePropertyLease-priorityEnglish'
    ){
        let tmpRegion = null
        if(props.koatuu.region === null){
            getKoatuuRegion(props)
        }
        if(props.koatuu.region !== null){
            let tmpRegionOption = props.koatuu.region.map(
                (i) => {
                    return <option key={i} value={`${i.classificationId}+:+${i.description}`}>{i.description}</option>
                }
            )
            tmpRegion = <select
                className={styles.validfilterSelect}
                value={props.addressNew.address.region}
                onChange={(event) => {
                    props.setDataAddress(event.target.value, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                    let t = event.target.value.substr(0, 2)
                    props.setKoatuuGorod(null)
                    props.setDataAddress('null', 'CREATE_ADDRESS_NEW_ADDRESS_LOCALITY')
                    getKoatuuGorod(props, t)
                }}
            >
                <option key={0} selected value='null'>Обрати</option>
                {tmpRegionOption}
            </select>
        }else{
            tmpRegion = <select
                className={styles.validfilterSelect}
                value={props.addressNew.address.region}
            >
                <option key={0} selected disabled value='null'>Обрати</option>
            </select>
        }
        let tmpGorod = null
        if(props.koatuu.gorod !== null){
            let tmpGorodOption = props.koatuu.gorod.map(
                (i, count) => {
                    return <option key={count+1} value={i.description}>{i.description}</option>
                }
            )
            tmpGorod = <select
                className={styles.validfilterSelect}
                value={props.addressNew.address.locality}
                onChange={(event) => {
                    props.setDataAddress(event.target.value, 'CREATE_ADDRESS_NEW_ADDRESS_LOCALITY')
                }}
            >
                <option key={0} selected disabled value='null'>Обрати</option>
                {tmpGorodOption}
            </select>
        }else{
            tmpGorod = <select
                className={styles.validfilterSelect}
                value={props.addressNew.address.locality}
            >
                <option key={0} selected disabled value='null'>Обрати</option>
            </select>
        }
        return (
            <>
                <div className={stylesZu.classification_wrap}>
                    <div>
                        <div>Класифікатор об'єкта</div>
                        <select
                            className={styles.validfilterSelect}
                            value={props.search.classification}
                            onChange={(event)=>{
                                props.setDataAddress(event.target.value, 'SERCH_CLASSIFICATION')
                            }}
                        >
                            <option key='0' value="null">Обрати</option>
                            {classificationIdOption(props)}
                        </select>
                    </div>
                    <div>
                        <div>Додатковий класифікатор об'єкта</div>
                        <select
                            className={styles.validfilterSelect + ' ' + stylesZu.dop}
                            value={props.search.additionalClassifications}
                            onChange={(event)=>{
                                props.setDataAddress(event.target.value, 'SERCH_ADDITIONAL_CLASSIFICATION')
                            }}
                        >
                            <option key='0' value="null">Обрати</option>
                            {addClassificationIdOption(props)}
                        </select>
                    </div>
                </div>
                <div className={stylesZu.regionArea_wrap}>
                    <div className={stylesZu.region_wrap}>
                        <div className={stylesZu.region_data_wrap}>
                            <div>Місцезнаходження об’єкта область</div>
                            {tmpRegion}
                        </div>
                        <div className={stylesZu.region_data_wrap}>
                            <div>Місцезнаходження об’єкта місто</div>
                            {tmpGorod}
                        </div>
                    </div>
                    <div className={stylesZu.area_wrap}>
                        <div className={stylesZu.area_data_wrap}>
                            <div>Площа об'єкта, що здається в оренду від</div>
                            <input
                                type="text"
                                data-format="price"
                                value = {props.search.areaFrom}
                                onChange={
                                    (e)=>{
                                        props.setDataAddress(e.target.value, 'SEARCH_AREA_FROM')
                                    }
                                }
                            />
                        </div>
                        <div className={stylesZu.area_data_wrap}>
                            <div>Площа об'єкта, що здається в оренду до</div>
                            <input
                                type="text"
                                data-format="price"
                                value = {props.search.areaTo}
                                onChange={
                                    (e)=>{
                                        props.setDataAddress(e.target.value, 'SEARCH_AREA_TO')
                                    }
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className={stylesZu.leaseDuration_wrap}>
                <div>
                    <div>Строк оренди від</div>
                    <div className={stylesZu.leaseDuration_data_wrap}>
                        <div className={stylesZu.leaseDuration_data}>
                            <div>Років</div>
                            <input
                                type="number"
                                data-format="price"
                                value = {props.search.leaseDurationFrom.year}
                                onChange={
                                    (e)=>{
                                        props.setDataAddress(e.target.value, 'SEARCH_LEASE_DURATION_FROM_YEAR')
                                    }
                                }
                            />
                        </div>
                        <div className={stylesZu.leaseDuration_data}>
                            <div>Міяців</div>
                            <input
                                type="number"
                                data-format="price"
                                value = {props.search.leaseDurationFrom.month}
                                onChange={
                                    (e)=>{
                                        props.setDataAddress(e.target.value, 'SEARCH_LEASE_DURATION_FROM_MONTH')
                                    }
                                }
                            />
                        </div>
                        <div className={stylesZu.leaseDuration_data}>
                            <div>Днів</div>
                            <input
                                type="number"
                                data-format="price"
                                value = {props.search.leaseDurationFrom.day}
                                onChange={
                                    (e)=>{
                                        props.setDataAddress(e.target.value, 'SEARCH_LEASE_DURATION_FROM_DAY')
                                    }
                                }
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div>Строк оренди до</div>
                    <div className={stylesZu.leaseDuration_data_wrap}>
                        <div className={stylesZu.leaseDuration_data}>
                            <div>Років</div>
                            <input
                                type="number"
                                data-format="price"
                                value = {props.search.leaseDurationTo.year}
                                onChange={
                                    (e)=>{
                                        props.setDataAddress(e.target.value, 'SEARCH_LEASE_DURATION_TO_YEAR')
                                    }
                                }
                            />
                        </div>
                        <div className={stylesZu.leaseDuration_data}>
                            <div>Міяців</div>
                            <input
                                type="number"
                                data-format="price"
                                value = {props.search.leaseDurationTo.month}
                                onChange={
                                    (e)=>{
                                        props.setDataAddress(e.target.value, 'SEARCH_LEASE_DURATION_TO_MONTH')
                                    }
                                }
                            />
                        </div>
                        <div className={stylesZu.leaseDuration_data}>
                            <div>Днів</div>
                            <input
                                type="number"
                                data-format="price"
                                value = {props.search.leaseDurationTo.day}
                                onChange={
                                    (e)=>{
                                        props.setDataAddress(e.target.value, 'SEARCH_LEASE_DURATION_TO_DAY')
                                    }
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

function landRentalFind(props){
    if(
        props.search.category === 'landSell'
        || props.search.category === 'landSell-english'
        || props.search.category === 'landSell-priorityEnglish'
        || props.search.category === 'landRental'
        || props.search.category === 'landRental-english'
        || props.search.category === 'basicSell-all'
        || props.search.category === 'basicSell-english'
        || props.search.category === 'basicSell-dutch'
        || props.search.category === 'alienation'
        || props.search.category === 'alienation-english'
        || props.search.category === 'commercialSell-all'
        || props.search.category === 'commercialSell-english'
        || props.search.category === 'commercialSell-dutch'
        || props.search.category === 'landArrested'
        || props.search.category === 'landArrested-english'
        || props.search.category === 'landArrested-withoutAuction'
        || props.search.category === 'landArrested-priorityEnglish'
    ){
        let tmpRegion = null
        if(props.koatuu.region === null){
            getKoatuuRegion(props)
        }
        if(props.koatuu.region !== null){
            let tmpRegionOption = props.koatuu.region.map(
                (i) => {
                    return <option key={i} value={`${i.classificationId}+:+${i.description}`}>{i.description}</option>
                }
            )
            tmpRegion = <select
                className={styles.validfilterSelect}
                value={props.addressNew.address.region}
                onChange={(event) => {
                    props.setDataAddress(event.target.value, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                    let t = event.target.value.substr(0, 2)
                    props.setKoatuuGorod(null)
                    props.setDataAddress('null', 'CREATE_ADDRESS_NEW_ADDRESS_LOCALITY')
                    getKoatuuGorod(props, t)
                }}
            >
                <option key={0} selected value='null'>Обрати</option>
                {tmpRegionOption}
            </select>
        }else{
            tmpRegion = <select
                className={styles.validfilterSelect}
                value={props.addressNew.address.region}
            >
                <option key={0} selected value='null'>Обрати</option>
            </select>
        }
        let tmpGorod = null
        if(props.koatuu.gorod !== null){
            let tmpGorodOption = props.koatuu.gorod.map(
                (i, count) => {
                    return <option key={count+1} value={i.description}>{i.description}</option>
                }
            )
            tmpGorod = <select
                className={styles.validfilterSelect}
                value={props.addressNew.address.locality}
                onChange={(event) => {
                    props.setDataAddress(event.target.value, 'CREATE_ADDRESS_NEW_ADDRESS_LOCALITY')
                }}
            >
                <option key={0} selected disabled value='null'>Обрати</option>
                {tmpGorodOption}
            </select>
        }else{
            tmpGorod = <select
                className={styles.validfilterSelect}
                value={props.addressNew.address.locality}
            >
                <option key={0} selected disabled value='null'>Обрати</option>
            </select>
        }
        //if(props.createItems === null){
            //if(props.createItems.landRental === null){
                if(props.createItems.landRental.additionalClassificationsArr === null){
                    getAdditionalClassificationZU(props)
                    loadMainClassifAll(props)
                }
            //}
        //}
        let tmpNumberOfCurrentTenants = null
        if(
            props.search.category === 'landSell-priorityEnglish'
            || props.search.category === 'landArrested-priorityEnglish'
        ){
            tmpNumberOfCurrentTenants = <select
                className={styles.validfilterSelect}
                value={props.search.numberOfCurrentTenants}
                onChange={(event) => {
                    props.setDataAddress(event.target.value, 'CREATE_SEARCH_NUMBER_OF_CURRENT_TENANTS')
                }}
            >
                <option key={0} selected disabled value='null'>Обрати</option>
                <option key={1} value='1'>1</option>
                <option key={2} value='2'>2</option>
            </select>
        }
        return (
            <>
                <div className={stylesZu.regionArea_wrap}>
                    <div className={stylesZu.region_wrap}>
                        <div className={stylesZu.region_data_wrap}>
                            <div>Місцезнаходження об’єкта область</div>
                            {tmpRegion}
                        </div>
                        <div className={stylesZu.region_data_wrap}>
                            <div>Місцезнаходження об’єкта місто</div>
                            {tmpGorod}
                        </div>
                        {tmpNumberOfCurrentTenants !== null ? <div className={stylesZu.region_data_wrap}>
                            <div>Кількість учасників з переважним правом</div>
                            {tmpNumberOfCurrentTenants}
                        </div> : null}
                    </div>
                </div>
                {lendRentalEtc(props)}
                {basicSell(props)}
                {alienation(props)}
                {landArrestedEtc(props)}
            </>
        )
    }
    return null
}

function basicSell(props){
    if(
        props.search.category === 'basicSell-all' ||
        props.search.category === 'basicSell-english' ||
        props.search.category === 'basicSell-dutch'
    ){
        return (
            <>
                <div className={stylesZu.classification_wrap + ' ' + stylesZu.addInputs}>
                    <div>
                        <div>Основний класифікатор</div>
                        <select
                            className={styles.validfilterSelect}
                            value={props.createItems.zu.baseClasif}
                            onChange={(event)=>{
                                props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
                                smartClasif(props, event.target.value)
                            }}
                        >
                            {mainGroops()}
                        </select>
                    </div>
                    <div>
                        <div>Класифікатори</div>
                        <select
                            className={styles.validfilterSelect}
                            value={props.search.classification}
                            onChange={(event)=>{
                                props.setDataAddress(event.target.value, 'SERCH_CLASSIFICATION')
                            }}
                        >
                            <option key='0' value="null">Обрати</option>
                            {classificationIdOption(props)}
                        </select>
                    </div>
                </div>
                <div className={stylesZu.classification_wrap + ' ' + stylesZu.addInputs}>
                    <div>
                        <div>Тип об'єкта (майна)</div>
                        <select
                            className={styles.validfilterSelect}
                            value={props.search.itemType}
                            onChange={(event)=>{
                                props.setDataAddress(event.target.value, 'SERCH_ITEM_TYPE')
                            }}
                        >
                            <option key='null' selected value="null">Обрати</option>
                            <option key='1' value="land">Земельна ділянка</option>
                            <option key='2' value="jointPropertyComplex">Об'єкт ЄМК</option>
                            <option key='3' value="vehicle">Транспортний засіб</option>
                            <option key='4' value="realEstate">Нерухоме майно</option>
                            <option key='1' value="otherProperty">Інше</option>
                        </select>
                    </div>
                    <div>
                        <div>Фільтр для майна що швидко псується</div>
                        <select
                            className={styles.validfilterSelect}
                            value={props.search.isPerishable}
                            onChange={(event)=>{
                                props.setDataAddress(event.target.value, 'SERCH_IS_PERISHABLE')
                            }}
                        >
                            <option key='0' value="null">Обрати</option>
                            <option key='1' value="true">Так</option>
                            <option key='2' value="false">Ні</option>
                        </select>
                    </div>
                </div>
            </>
        )
    }
}

function alienation(props){
    if(
        props.search.category === 'alienation' ||
        props.search.category === 'alienation-english' ||
        props.search.category === 'commercialSell-all' ||
        props.search.category === 'commercialSell-english' ||
        props.search.category === 'commercialSell-dutch'
    ){
        return (
            <>
                <div className={stylesZu.classification_wrap + ' ' + stylesZu.addInputs}>
                    <div>
                        <div>Основний класифікатор</div>
                        <select
                            className={styles.validfilterSelect}
                            value={props.createItems.zu.baseClasif}
                            onChange={(event)=>{
                                props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
                                smartClasif(props, event.target.value)
                            }}
                        >
                            {mainGroopsA()}
                        </select>
                    </div>
                    <div>
                        <div>Класифікатори</div>
                        <select
                            className={styles.validfilterSelect}
                            value={props.search.classification}
                            onChange={(event)=>{
                                props.setDataAddress(event.target.value, 'SERCH_CLASSIFICATION')
                            }}
                        >
                            <option key='0' value="null">Обрати</option>
                            {classificationIdOption(props)}
                        </select>
                    </div>
                </div>
                <div className={stylesZu.classification_wrap + ' ' + stylesZu.addInputs}>
                    <div>
                        <div>Тип об'єкта (майна)</div>
                        <select
                            className={styles.validfilterSelect}
                            value={props.search.itemType}
                            onChange={(event)=>{
                                props.setDataAddress(event.target.value, 'SERCH_ITEM_TYPE')
                            }}
                        >
                            <option key='null' selected value="null">Обрати</option>
                            <option key='1' value="land">Земельна ділянка</option>
                            <option key='2' value="jointPropertyComplex">Об'єкт ЄМК</option>
                            <option key='3' value="vehicle">Транспортний засіб</option>
                            <option key='4' value="realEstate">Нерухоме майно</option>
                            <option key='1' value="otherProperty">Інше</option>
                        </select>
                    </div>
                </div>
            </>
        )
    }
}

function mainGroops(){
    return (
        <>
            <option>Обрати</option>
            <option key='03000000-1' value='03'>CAV 03 Сільськогосподарська, фермерська продукція, продукція рибальства, лісівництва та супутня продукція (та усі вкладені коди)</option>
            <option key='04000000-8' value='04'>CAV 04 Нерухоме майно (та усі вкладені коди)</option>
            <option key='05000000-5' value='05'>CAV 05 Цілісний майновий комплекс або майновий комплекс - комплекс активів підприємства (та усі вкладені коди)</option>
            <option key='06000000-2' value='06'>CAV 06 Земельні ділянки (та усі вкладені коди)</option>
            <option key='15000000-8' value='15'>CAV 15 Продукти харчування, напої, тютюн та супутня продукція</option>
            <option key='16000000-5' value='16'>CAV 16 Сільськогосподарська техніка (та усі вкладені коди)</option>
            <option key='30000000-9' value='30'>CAV 30 Офісна та комп’ютерна техніка, устаткування та приладдя, крім меблів та пакетів програмного забезпечення (та усі вкладені коди)</option>
            <option key='32000000-3' value='32'>CAV 32 Радіо-, телевізійна, комунікаційна, телекомунікаційна та супутня апаратура й обладнання (та усі вкладені коди)</option>
            <option key='34000000-7' value='34'>CAV 34 Транспортне обладнання та допоміжне приладдя до нього (та усі вкладені коди)</option>
            <option key='39000000-2' value='39'>CAV 39 Меблі (у тому числі офісні меблі), меблево-декоративні вироби, побутова техніка (крім освітлювального обладнання) та засоби для чищення (та усі вкладені коди)</option>
            <option key='42000000-6' value='42'>CAV 42 Промислова техніка (та усі вкладені коди)</option>
            <option key='07000000-9' value='07'>CAV 07 Права вимоги за кредитними договорами (та усі вкладені коди)</option>
            <option key='08000000-6' value='08'>CAV 08 Корпоративні права (та усі вкладені коди)</option>
            <option key='45000000-7' value='45'>CAV 45 Будівельні роботи (та усі вкладені коди)</option>
            <option key='48000000-8' value='48'>CAV 48 Пакети програмного забезпечення та інформаційні системи (та усі вкладені коди)</option>
            <option key='50000000-5' value='50'>CAV 50 Послуги з ремонту і технічного обслуговування (та усі вкладені коди)</option>
            <option key='51000000-9' value='51'>CAV 51 Послуги зі встановлення (крім програмного забезпечення) (та усі вкладені коди)</option>
            <option key='55000000-0' value='55'>CAV 55 Готельні, ресторанні послуги та послуги з роздрібної торгівлі (та усі вкладені коди)</option>
            <option key='60000000-8' value='60'>CAV 60 Транспортні послуги (крім транспортування відходів) (та усі вкладені коди)</option>
            <option key='63000000-9' value='63'>CAV 63 Додаткові та допоміжні транспортні послуги; послуги туристичних агентств (та усі вкладені коди)</option>
            <option key='64000000-6' value='64'>CAV 64 Поштові та телекомунікаційні послуги (та усі вкладені коди)</option>
            <option key='65000000-3' value='65'>CAV 65 Комунальні послуги (та усі вкладені коди)</option>
            <option key='66000000-0' value='66'>CAV 66 Фінансові та страхові послуги (та усі вкладені коди)</option>
            <option key='71000000-8' value='71'>CAV 71 Архітектурні, будівельні, інженерні та інспекційні послуги (та усі вкладені коди)</option>
            <option key='72000000-5' value='72'>CAV 72 Послуги у сфері інформаційних технологій: консультування, розробка програмного забезпечення, послуги мережі Інтернет і послуги з підтримки (та усі вкладені коди)</option>
            <option key='73000000-2' value='73'>CAV 73 Послуги у сфері НДДКР та пов’язані консультаційні послуги (та усі вкладені коди)</option>
            <option key='75000000-6' value='75'>CAV 75 Адміністративні, оборонні послуги та послуги у сфері соціального захисту (та усі вкладені коди)</option>
            <option key='76000000-3' value='76'>CAV 76 Послуги, пов’язані з нафтогазовою промисловістю (та усі вкладені коди)</option>
            <option key='77000000-0' value='77'>CAV 77 Послуги у сфері сільського господарства, лісівництва, рослинництва, водного господарства та бджільництва (та усі вкладені коди)</option>
            <option key='79000000-4' value='79'>CAV 79 Ділові послуги: юридичні, маркетингові, консультаційні, кадрові, поліграфічні та охоронні (та усі вкладені коди)</option>
            <option key='80000000-4' value='80'>CAV 80 Послуги у сфері освіти та навчання (та усі вкладені коди)</option>
            <option key='85000000-9' value='85'>CAV 85 Послуги у сфері охорони здоров’я та соціальної допомоги (та усі вкладені коди)</option>
            <option key='90000000-7' value='90'>CAV 90 Послуги у сферах поводження зі стічними водами та сміттям, послуги у сферах санітарії та охорони довкілля (та усі вкладені коди)</option>
            <option key='92000000-1' value='92'>CAV 92 Послуги у сфері відпочинку, культури та спорту (та усі вкладені коди)</option>
            <option key='98000000-3' value='98'>CAV 98 Інші громадські, соціальні та особисті послуги (та усі вкладені коди)</option>
        </>
    )
}

function mainGroopsA(){
    return (
        <>
            <option>Обрати</option>
            <option key='04000000-8' value='04'>CAV 04 Нерухоме майно (та усі вкладені коди)</option>
            <option key='05000000-5' value='05'>CAV 05 Цілісний майновий комплекс або майновий комплекс - комплекс активів підприємства (та усі вкладені коди)</option>
            <option key='16000000-5' value='16'>CAV 16 Сільськогосподарська техніка (та усі вкладені коди)</option>
            <option key='18000000-9' value='18'>CAV 18 Одяг, взуття, сумки та аксесуари (та усі вкладені коди)</option>
            <option key='19000000-6' value='19'>CAV 19 Шкіряні та текстильні, пластмасові та гумові матеріали (та усі вкладені коди)</option>
            <option key='22000000-0' value='22'>CAV 22 Друкована та супутня продукція (та усі вкладені коди)</option>
            <option key='30000000-9' value='30'>CAV 30 Офісна та комп’ютерна техніка, устаткування та приладдя, крім меблів та пакетів програмного забезпечення (та усі вкладені коди)</option>
            <option key='31000000-6' value='31'>CAV 31 Електротехнічне устаткування, апаратура, обладнання та матеріали; освітлювальне устаткування (та усі вкладені коди)</option>
            <option key='32000000-3' value='32'>CAV 32 Радіо-, телевізійна, комунікаційна, телекомунікаційна та супутня апаратура й обладнання (та усі вкладені коди)</option>
            <option key='33000000-0' value='33'>CAV 33 Медичне обладнання, фармацевтична продукція та засоби особистої гігієни (та усі вкладені коди)</option>
            <option key='34000000-7' value='34'>CAV 34 Транспортне обладнання та допоміжне приладдя до нього (та усі вкладені коди)</option>
            <option key='35000000-4' value='35'>CAV 35 Охоронне, протипожежне, поліцейське та оборонне обладнання (та усі вкладені коди)</option>
            <option key='37000000-8' value='37'>CAV 37 Музичні інструменти, спортивні товари, ігри, іграшки, ремісничі, художні матеріали та приладдя (та усі вкладені коди)</option>
            <option key='38000000-5' value='38'>CAV 38 Лабораторне, оптичне та високоточне обладнання (крім лінз) (та усі вкладені коди)</option>
            <option key='39000000-2' value='39'>CAV 39 Меблі (у тому числі офісні меблі), меблево-декоративні вироби, побутова техніка (крім освітлювального обладнання) та засоби для чищення (та усі вкладені коди)</option>
            <option key='42000000-6' value='42'>CAV 42 Промислова техніка (та усі вкладені коди)</option>
            <option key='43000000-3' value='43'>CAV 43 Гірничодобувне та будівельне обладнання (та усі вкладені коди)</option>
            <option key='44000000-0' value='44'>CAV 44 Конструкції та конструкційні матеріали; допоміжна будівельна продукція (крім електроапаратури) (та усі вкладені коди)</option>
        </>
    )
}

async function smartClasif(props, data){
    let tmp0 = await getDictionaryJointPropertyComplex(props, data)
    //props.setDataAddress(tmp0, 'SET_CLASSIFICATIONS_BSE_BSD')
    props.setDataAddress(tmp0, 'SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD')
}

function lendRentalEtc(props){
    if(
        props.search.category === 'landSell' ||
        props.search.category === 'landSell-english' ||
        props.search.category === 'landSell-priorityEnglish' ||
        props.search.category === 'landRental' ||
        props.search.category === 'landRental-english'
    ){
        return (
            <>
                <div className={stylesZu.classification_wrap + ' ' + stylesZu.addInputs}>
                    <div>
                        <div>Класифікатор об'єкта</div>
                        <select
                            className={styles.validfilterSelect}
                            value={props.search.classification}
                            onChange={(event)=>{
                                props.setDataAddress(event.target.value, 'SERCH_CLASSIFICATION')
                            }}
                        >
                            <option key='0' value="null">Обрати</option>
                            {classificationIdOption(props)}
                        </select>
                    </div>
                </div>
                <div className={styles.addInputs}>
                    <div className={stylesZu.addInputs}>
                        <div className={stylesZu.area_data_wrap}>
                            <div>Площа земельної ділянки від</div>
                            <input
                                type="number"
                                data-format="price"
                                value = {props.search.landAreaFrom}
                                onChange={
                                    (e)=>{
                                        props.setDataAddress(e.target.value, 'SET_LAND_AREA_FROM')
                                    }
                                }
                            />
                        </div>
                        <div className={stylesZu.area_data_wrap}>
                            <div>Площа земельної ділянки до</div>
                            <input
                                type="number"
                                data-format="price"
                                value = {props.search.landAreaTo}
                                onChange={
                                    (e)=>{
                                        props.setDataAddress(e.target.value, 'SET_LAND_AREA_TO')
                                    }
                                }
                            />
                        </div>
                        <div className={stylesZu.area_data_wrap}>
                            <div>Кадастровий номер</div>
                            <input
                                type="text"
                                data-format="price"
                                value = {props.search.cadastralNumber}
                                onChange={
                                    (e)=>{
                                        props.setDataAddress(e.target.value, 'SET_CADASTRAL_NUMBER')
                                    }
                                }
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }
    return null
}

function landArrestedEtc(props){
    if(
        props.search.category === 'landArrested' ||
        props.search.category === 'landArrested-english' ||
        props.search.category === 'landArrested-withoutAuction' ||
        props.search.category === 'landArrested-priorityEnglish'
    ){
        return (
            <>
                <div className={stylesZu.classification_wrap + ' ' + stylesZu.addInputs}>
                    <div>
                        <div>Класифікатор об'єкта</div>
                        <select
                            className={styles.validfilterSelect}
                            value={props.search.classification}
                            onChange={(event)=>{
                                props.setDataAddress(event.target.value, 'SERCH_CLASSIFICATION')
                            }}
                        >
                            <option key='0' value="null">Обрати</option>
                            {classificationIdOptionLAE(props)}
                        </select>
                    </div>
                </div>
                <div className={styles.addInputs}>
                    <div className={stylesZu.addInputs}>
                        <div className={stylesZu.area_data_wrap}>
                            <div>Площа земельної ділянки від</div>
                            <input
                                type="number"
                                data-format="price"
                                value = {props.search.landAreaFrom}
                                onChange={
                                    (e)=>{
                                        props.setDataAddress(e.target.value, 'SET_LAND_AREA_FROM')
                                    }
                                }
                            />
                        </div>
                        <div className={stylesZu.area_data_wrap}>
                            <div>Площа земельної ділянки до</div>
                            <input
                                type="number"
                                data-format="price"
                                value = {props.search.landAreaTo}
                                onChange={
                                    (e)=>{
                                        props.setDataAddress(e.target.value, 'SET_LAND_AREA_TO')
                                    }
                                }
                            />
                        </div>
                        <div className={stylesZu.area_data_wrap}>
                            <div>Кадастровий номер</div>
                            <input
                                type="text"
                                data-format="price"
                                value = {props.search.cadastralNumber}
                                onChange={
                                    (e)=>{
                                        props.setDataAddress(e.target.value, 'SET_CADASTRAL_NUMBER')
                                    }
                                }
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }
    return null
}

async function loadMainClassifAll(props){
    let tmp = await getDictionaryJointPropertyComplex(props, '06121000-6')
    let tmp2 = tmp.concat(await getDictionaryJointPropertyComplex(props, '06111000-3'))
    let tmp3 = tmp2.concat(await getDictionaryJointPropertyComplex(props, '06112000-0'))
    let tmp4 = tmp3.concat(await getDictionaryJointPropertyComplex(props, '06122000-3'))
    let tmp5 = tmp4.concat(await getDictionaryJointPropertyComplex(props, '06123000-0'))
    let tmp6 = tmp5.concat(await getDictionaryJointPropertyComplex(props, '06124000-7'))
    let tmp7 = tmp6.concat(await getDictionaryJointPropertyComplex(props, '06125000-4'))
    let tmp8 = tmp7.concat(await getDictionaryJointPropertyComplex(props, '06126000-1'))
    let tmp9 = tmp8.concat(await getDictionaryJointPropertyComplex(props, '06127000-8'))
    let tmp10 = tmp9.concat(await getDictionaryJointPropertyComplex(props, '06128000-5'))
    let tmp11 = tmp10.concat(await getDictionaryJointPropertyComplex(props, '06129000-2'))
    //props.setDataAddress(tmp11, 'SERCH_CLASSIFICATION')
    props.setDataAddress(tmp11, 'SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD')
}

async function getDictionaryJointPropertyComplex(props, mask){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/autocomplete/classification_id?limit=1000&offset=0&classification_id=${mask}&scheme=CAV&mask=${mask}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            //'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        let json = await response.json()
        return json
        /*let tmp = props.classificationsDgf.classificationsByTypeMain
        let tmp2 = []
        if(tmp !== null){
            if(tmp.length > 0){
                tmp2 = tmp.concat(json);
            }
        }*/

        //props.setDataAddress(json[0].classificationId, 'SERCH_CLASSIFICATION')
        //props.setDataAddress(tmp2, 'SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD')
    }
}

function classificationIdOption(props){
    if(props.classificationsDgf.classificationsByTypeMain !== null){
        return props.classificationsDgf.classificationsByTypeMain.map(
            (i, count) => {
                return <option key={count} value={i.classificationId}>{i.scheme + ' ' + i.classificationId + ' ' + i.description}</option>
            }
        )
    }
}
function classificationIdOptionLAE(props){
    if(props.classificationsDgf.classificationsByTypeMain !== null){
        return props.classificationsDgf.classificationsByTypeMain
        /*.filter()(
            (i) => {
                return i.classificationId[0] === '0' && i.classificationId[0] === '6'
            }
        )*/
        .map(
            (i, count) => {
                return <option key={count} value={i.classificationId}>{i.scheme + ' ' + i.classificationId + ' ' + i.description}</option>
            }
        )
    }
}
function addClassificationIdOption(props){
    if(props.classificationsDgf.classificationsAdd !== null){
        if(props.classificationsDgf.classificationsAdd.length > 0){
            return props.classificationsDgf.classificationsAdd.map(
                (i, count) => {
                    let tmp = i[0]
                    if(tmp === undefined || tmp === 'undefined'){
                    }else{
                        if(tmp !== null){
                            if(tmp.length > 0){
                                return <option key={count} value={i[0].classificationId}>{i[0].scheme + ' ' + i[0].classificationId + ' ' + i[0].description}</option>
                            }
                        }
                    }
                    return null
                }
            )
        }
    }
    return null
}

async function getCPUObj(props){
    let endPoint = ``
    if(Number(process.env.REACT_APP_BUILD_STATUS) === 0){
        //endPoint = `https://registry.dto.com.ua/api/1/uk/public/registry_objects/byGlobalId/${props, props.search.textSting}`
        endPoint = `https://registry.dto.com.ua/api/1/uk/public/registry_objects/byGlobalId/${props, props.search.textSting}`
    }else{
        //endPoint = `https://staging-registry.dto.com.ua/object/${props, props.search.textSting}`
        endPoint = `https://sandbox-registry.dto.com.ua/object/${props, props.search.textSting}`
        //endPoint = `https://dev-registry.dto.com.ua/object/${props, props.search.textSting}`
    }
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            /*'Authorization': props.token.access_token,*/
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            //let tmp = JSON.stringify({title: "Помилка при створенні Тип переліку, до якого віднесено об'єкт", message: JSON.stringify(json)})
            //props.setUniversalError(tmp)
        }else{
            //props.setDataAddress(json, 'SET_CREATE_AUCTION_ZU_DATA')
            await viewButtonSearch(props, json.id)
        }
    }
}

function handleID(props, advansButton){
    return (
        <div className={styles.buttonSearch}>
            <Link to={`${props.aucyionsPath}/search${getUrlFiltersParam(props.search)}`}>
                <div
                    className={styles.findStart}
                    onClick={
                        ()=>{
                            // if(props.search.selectTypeKeyword !== ''){
                                // if(props.search.selectTypeKeyword === '4'){
                                //     if(props.search.textSting.indexOf('-') === -1){
                                //         viewButtonSearch(props, props.search.textSting)
                                //     }else{
                                //         getCPUObj(props)
                                //     }
                                // }else{
                                    // viewButtonSearch(props, props.search.textSting)
                                // }
                            // }else{
                                viewButtonSearch(props, props.search.textSting)
                            // }
                        }
                    }
                >
                    {advansButton}
                </div>
            </Link>
        </div>
    )
}

async function viewButtonSearch(props, objId){
    let keyword, authorName, classification, auctionId = null;
    let selectTypeKeyword = null;
    let startDateFrom = null;
    let startDateTo = null;
    let registryId = null

    let priceFrom = null
    let priceTo = null
    if(props.search.selectTypeKeyword !== '') selectTypeKeyword = props.search.selectTypeKeyword
    if(props.search.startDateFrom !== null && props.search.startDateFrom !== '') startDateFrom = props.search.startDateFrom
    if(props.search.startDateTo !== null && props.search.startDateTo !== '') startDateTo = props.search.startDateTo

    if(props.search.priceFrom !== null && props.search.priceFrom !== '') priceFrom = props.search.priceFrom
    if(props.search.priceTo !== null && props.search.priceTo !== '') priceTo = props.search.priceTo
    switch (selectTypeKeyword){
        case '0':
            keyword = props.search.textSting;
            authorName = null;
            classification = null;
            auctionId = null;
            registryId = null;
            break;
        case '1':
            keyword = null;
            authorName = null;
            classification = null;
            auctionId = props.search.textSting;
            registryId = null;
            break;
        case '2':
            keyword = props.search.textSting;
            authorName = null;
            classification = null;
            auctionId = null;
            registryId = null;
            break;
        case '3':
            keyword = null;
            authorName = props.search.textSting;
            classification = null;
            auctionId = null;
            registryId = null;
            break;
        case '4':
            keyword = null;
            authorName = null;
            classification = null;
            auctionId = null;
            registryId = objId;
            break;
        default:
            keyword = props.search.textSting;
            authorName = null;
            classification = null;
            auctionId = null;
            break;
    }

    let dataSearch = {}
    dataSearch.keyword = keyword
    dataSearch.authorName = authorName
    dataSearch.classification = classification
    dataSearch.auctionId = auctionId
    dataSearch.category = props.search.category
    dataSearch.startDateFrom = startDateFrom
    dataSearch.startDateTo = startDateTo
    dataSearch.registryId = registryId;
    dataSearch.priceFrom = priceFrom
    dataSearch.priceTo = priceTo
    if(props.search.numberOfCurrentTenants !== null
        && props.search.numberOfCurrentTenants !== 'null'
        /// && (props.search.category === 'landSell-priorityEnglish'
        // || props.search.category === 'landArrested-priorityEnglish')
    ){
        dataSearch.currentTenantsCount = Number(props.search.numberOfCurrentTenants)
    }else{
        dataSearch.currentTenantsCount = null
    }
    if(props.search.status === null){
        dataSearch.status = null
    }else if(props.search.status === ''){
        dataSearch.status = [
            "active_rectification",
            "active_tendering",
            "active_auction",
            "active_qualification",
            "active_awarded",
            "complete",
            "unsuccessful",
            "cancelled"
        ]
    }else{
        dataSearch.status = [props.search.status]
    }
    
    if(
        props.search.category === 'basicSell-all' ||
        props.search.category === 'basicSell-english' ||
        props.search.category === 'basicSell-dutch' ||
        props.search.category === 'alienation' ||
        props.search.category === 'alienation-english' ||
        props.search.category === 'commercialSell-all' ||
        props.search.category === 'commercialSell-english' ||
        props.search.category === 'commercialSell-dutch'

    ){
        if(props.createItems.zu.baseClasif !== 'null' && props.createItems.zu.baseClasif !== null)  dataSearch.classificationMain = props.createItems.zu.baseClasif
        if(props.search.classification !== null && props.search.classification !== 'null') dataSearch.classification = props.search.classification
        if(props.addressNew.address.region !== null && props.addressNew.address.region !== 'null'){
            let r = props.addressNew.address.region.split('+:+')
            dataSearch.region = getRegion(r[1])
        }
        if(props.addressNew.address.locality !== null && props.addressNew.address.locality !== 'null') dataSearch.locality = props.addressNew.address.locality
        if(props.search.itemType !== null && props.search.itemType !== 'null') dataSearch.itemType = props.search.itemType
        if(props.search.isPerishable !== null && props.search.isPerishable !== 'null') dataSearch.isPerishable = props.search.isPerishable
    }


    if(
        props.search.category === 'landSell' ||
        props.search.category === 'landSell-english' ||
        props.search.category === 'landSell-priorityEnglish' ||
        props.search.category === 'landRental' ||
        props.search.category === 'landRental-english'
    ){
        if(props.search.classification !== null && props.search.classification !== 'null') dataSearch.classification = props.search.classification
        if(props.search.additionalClassifications !== null && props.search.additionalClassifications !== 'null') dataSearch.additionalClassifications = props.search.additionalClassifications
        if(props.addressNew.address.region !== null && props.addressNew.address.region !== 'null'){
            let r = props.addressNew.address.region.split('+:+')
            dataSearch.region = getRegion(r[1])
        }
        if(props.addressNew.address.locality !== null && props.addressNew.address.locality !== 'null') dataSearch.locality = props.addressNew.address.locality
        if(props.search.cadastralNumber !== null && props.search.cadastralNumber !== '') dataSearch.cadastralNumber = props.search.cadastralNumber
        if(props.search.landAreaTo !== null && props.search.landAreaTo !== '') dataSearch.landAreaTo = Number(props.search.landAreaTo)
        if(props.search.landAreaFrom !== null && props.search.landAreaFrom !== '') dataSearch.landAreaFrom = Number(props.search.landAreaFrom)
        
    }
    if(props.search.category === 'legitimatePropertyLease' || props.search.category === 'legitimatePropertyLease-english' || props.search.category === 'legitimatePropertyLease-dutch'){
        if(props.search.classification !== null && props.search.classification !== 'null') dataSearch.classification = props.search.classification
        if(props.search.additionalClassifications !== null && props.search.additionalClassifications !== 'null') dataSearch.additionalClassifications = props.search.additionalClassifications
        if(props.search.areaFrom !== null && props.search.areaFrom !== '') dataSearch.areaFrom = Number(props.search.areaFrom)
        if(props.search.areaTo !== null && props.search.areaTo !== '') dataSearch.areaTo = Number(props.search.areaTo)
        if(props.addressNew.address.region !== null && props.addressNew.address.region !== 'null'){
            let r = props.addressNew.address.region.split('+:+')
            dataSearch.region = getRegion(r[1])
        }
        if(props.addressNew.address.locality !== null && props.addressNew.address.locality !== 'null' && props.addressNew.address.locality !== '') dataSearch.locality = props.addressNew.address.locality

        let tmpLeaseDurationFrom = 0
        if(props.search.leaseDurationFrom.year !== ''){
            tmpLeaseDurationFrom = tmpLeaseDurationFrom + ((((365 * 24) * 60) * 60) * props.search.leaseDurationFrom.year)
        }
        if(props.search.leaseDurationFrom.month !== ''){
            tmpLeaseDurationFrom = tmpLeaseDurationFrom + ((((31 * 24) * 60) * 60) * props.search.leaseDurationFrom.month)
        }
        if(props.search.leaseDurationFrom.day !== ''){
            tmpLeaseDurationFrom = tmpLeaseDurationFrom + (((24 * 60) * 60) * props.search.leaseDurationFrom.day)
        }
        if(tmpLeaseDurationFrom !== 0){
            dataSearch.leaseDurationFrom = tmpLeaseDurationFrom
        }
        let tmpLeaseDurationTo = 0
        if(props.search.leaseDurationTo.year !== ''){
            tmpLeaseDurationTo = tmpLeaseDurationTo + ((((365 * 24) * 60) * 60) * props.search.leaseDurationTo.year)
        }
        if(props.search.leaseDurationTo.month !== ''){
            tmpLeaseDurationTo = tmpLeaseDurationTo + ((((31 * 24) * 60) * 60) * props.search.leaseDurationTo.month)
        }
        if(props.search.leaseDurationTo.day !== ''){
            tmpLeaseDurationTo = tmpLeaseDurationTo + (((24 * 60) * 60) * props.search.leaseDurationTo.day)
        }
        if(tmpLeaseDurationTo !== 0){
            dataSearch.leaseDurationTo = tmpLeaseDurationTo
        }


    }
    if(
        props.search.category === 'landArrested' ||
        props.search.category === 'landArrested-english' ||
        props.search.category === 'landArrested-withoutAuction' ||
        props.search.category === 'landArrested-priorityEnglish'
    ){
        if(props.search.classification !== null && props.search.classification !== 'null') dataSearch.classification = props.search.classification
        if(props.search.additionalClassifications !== null && props.search.additionalClassifications !== 'null') dataSearch.additionalClassifications = props.search.additionalClassifications
        if(props.addressNew.address.region !== null && props.addressNew.address.region !== 'null'){
            let r = props.addressNew.address.region.split('+:+')
            dataSearch.region = getRegion(r[1])
        }
        if(props.addressNew.address.locality !== null && props.addressNew.address.locality !== 'null') dataSearch.locality = props.addressNew.address.locality
        if(props.search.cadastralNumber !== null && props.search.cadastralNumber !== '') dataSearch.cadastralNumber = props.search.cadastralNumber
        if(props.search.landAreaTo !== null && props.search.landAreaTo !== '') dataSearch.landAreaTo = Number(props.search.landAreaTo)
        if(props.search.landAreaFrom !== null && props.search.landAreaFrom !== '') dataSearch.landAreaFrom = Number(props.search.landAreaFrom)
    }

    /*let dataSearch = {
        order: props.search.order,
        status: props.search.status,
        region: props.search.region,
        category: props.search.category, 
        priceCurrency: props.search.priceCurrency,
        priceFrom: props.search.priceFrom,
        priceTo: props.search.priceTo,
        measures: props.search.measures,
        measuresFrom: props.search.measuresFrom,
        measuresTo: props.search.measuresTo

        //"order": "asc",       сортировка
        //"orderBy": "string",    любой критерий по которому ищем в базовый поиск
        //"keyword": "string",    по словам по которому ищем в базовый поиск
        //"authorName": "string", по создателю по которому ищем в базовый поиск
        //"auctionId": "string",  по айди аукциона по которому ищем в базовый поиск
        //"itemId": "string",     по айди итема по которому ищем в базовый поиск
        //"region": "string",     по региону --- не пашет
        //"status": "active_rectification",   по статусу
        //"category": "string",   по категории
        //"priceCurrency": "UAH", валюта
        //"priceFrom": 0,         мин цена
        //"priceTo": 0,           макс цена
        //"measures": "string",   ед.измерения
        //"measuresFrom": 0,      ед.измерения от
        //"measuresTo": 0,        ед.измерения до
        //"startDateFrom": "string",  дата начала
        //"startDateTo": "string",    дата окончания
        //"classification": "string",     классификатор
        //"additionalClassifications": [  доп классификатор
            //"string"
        //]
    };*/
    let data = {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag,
        params: '?offset=0'
    }
    let dataS = {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag,
        params: ''
    }
    if(props.token !== null){
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        props.auctionsSearch(data, header, dataSearch);
        //props.auctionsSearchСount(dataS, header, dataSearch);
        /////////////////
        //Закрыть
        //props.auctionsSearchOld(data, header, dataSearch);
        //props.auctionsSearchСountOld(dataS, header, dataSearch);
    }else{
        let header = {
            'Content-Type': 'application/json'
        }
        props.auctionsSearch(data, header, dataSearch);
        //props.auctionsSearchСount(dataS, header, dataSearch);
        /////////////////
        //Закрыть
        //props.auctionsSearchOld(data, header, dataSearch);
        //props.auctionsSearchСountOld(dataS, header, dataSearch);
    }
}

function getUrlFiltersParam(search){
    let tmp = null
    if(search.isPerishable !== null && search.isPerishable !== 'null'){
        if(tmp === null){
            tmp = '?isPerishable=' + search.isPerishable
        }else{
            tmp += '&isPerishable=' + search.isPerishable
        }
    }
    if(search.itemType !== null && search.itemType !== 'null'){
        if(tmp === null){
            tmp = '?itemType=' + search.itemType
        }else{
            tmp += '&itemType=' + search.itemType
        }
    }
    if(search.orderBy !== null){
        if(tmp === null){
            tmp = '?orderBy=' + search.orderBy
        }else{
            tmp += '&orderBy=' + search.orderBy
        }
    }
    if(search.status !== null){
        if(tmp === null){
            tmp = '?status=' + search.status
        }else{
            tmp += '&status=' + search.status
        }
    }
    if(search.region !== null && search.region !== 'null'){
        if(tmp === null){
            tmp = '?region=' + search.region
        }else{
            tmp += '&region=' + search.region
        }
    }
    if(search.category !== null){
        if(tmp === null){
            tmp = `?category=` + search.category
        }else{
            tmp += `&category=` + search.category
        }
    }
    if(search.priceCurrency !== null){
        if(tmp === null){
            tmp = '?priceCurrency=' + search.priceCurrency
        }else{
            tmp += '&priceCurrency=' + search.priceCurrency
        }
    }
    if(search.priceFrom !== null){
        if(tmp === null){
            tmp = '?priceFrom=' + search.priceFrom
        }else{
            tmp += '&priceFrom=' + search.priceFrom
        }
    }
    if(search.priceFromBag !== false){
        if(tmp === null){
            tmp = '?priceFromBag=true'
        }else{
            tmp += '&priceFromBag=true'
        }
    }
    if(search.priceTo !== null){
        if(tmp === null){
            tmp = '?priceTo=' + search.priceTo
        }else{
            tmp += '&priceTo=' + search.priceTo
        }
    }
    if(search.priceToBag !== false){
        if(tmp === null){
            tmp = '?priceToBag=true'
        }else{
            tmp += '&priceToBag=true'
        }
    }
    if(search.measures !== null){
        if(tmp === null){
            tmp = '?measures=' + search.measures
        }else{
            tmp += '&measures=' + search.measures
        }
    }
    if(search.measuresFrom !== null){
        if(tmp === null){
            tmp = '?measuresFrom=' + search.measuresFrom
        }else{
            tmp += '&measuresFrom=' + search.measuresFrom
        }
    }
    if(search.measuresTo !== null){
        if(tmp === null){
            tmp = '?measuresTo=' + search.measuresTo
        }else{
            tmp += '&measuresTo=' + search.measuresTo
        }
    }

    switch (search.selectTypeKeyword){
        case '1':
            if(search.textSting !== null){
                if(tmp === null){
                    tmp = '?auctionId=' + search.textSting
                }else{
                    tmp += '&auctionId=' + search.textSting
                }
            }
            break;
        case '2':
            if(search.textSting !== null){
                if(tmp === null){
                    tmp = '?keyword=' + search.textSting
                }else{
                    tmp += '&keyword=' + search.textSting
                }
            }
            break;
        case '3':
            if(search.textSting !== null){
                if(tmp === null){
                    tmp = '?authorName=' + search.textSting
                }else{
                    tmp += '&authorName=' + search.textSting
                }
            }
            break;
        default:
            if(search.textSting !== null){
                if(tmp === null){
                    tmp = '?keyword=' + search.textSting
                }else{
                    tmp += '&keyword=' + search.textSting
                }
            }
            break;
    }
    if(search.selectTypeKeyword !== null){
        if(tmp === null){
            tmp = '?selectTypeKeyword=' + search.selectTypeKeyword
        }else{
            tmp += '&selectTypeKeyword=' + search.selectTypeKeyword
        }
    }
    /*if(search.startDateFrom !== null){
        if(tmp === null){
            tmp = '?startDateFrom=' + search.startDateFrom
        }else{
            tmp += '&startDateFrom=' + search.startDateFrom
        }
    }
    if(search.startDateTo !== null){
        if(tmp === null){
            tmp = '?startDateTo=' + search.startDateT
        }else{
            tmp += '&startDateTo=' + search.startDateT
        }
    }*/
    if(tmp !== null){
        return tmp
    }
}

function getSearchArr(props, value){
    let dataSearch = {
        keyword: props.search.keyword,
        authorName: props.search.authorName,
        classification: props.search.classification,
        auctionId: props.search.auctionId,
        category: props.search.category,
        startDateFrom: props.search.startDateFrom,
        startDateTo: props.search.startDateTo,
        status: props.search.status,
    }
    if(props.token !== null){
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        props.auctionsSearchTest(props, header, dataSearch);
        //props.auctionsSearchOld(data, header, props.search);
    }else{
        let header = {
            'Content-Type': 'application/json'
        }
        props.auctionsSearchTest(props, header, dataSearch);
        //props.auctionsSearchOld(data, header, props.search);
    }
}

async function getKoatuuRegion(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/koatuu/byMask`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            /*'Authorization': props.token.access_token,*/
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json1 = await response.json()
        if(json1.hasOwnProperty('message') === true){}
        else{
            props.setKoatuuRegion(json1)
        }
        
    }
}

async function getKoatuuGorod(props, id){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/koatuu/byMask?mask=${id}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            /*'Authorization': props.token.access_token,*/
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json1 = await response.json()
        if(json1.hasOwnProperty('message') === true){}
        else{
            props.setKoatuuGorod(json1)
        }
        
    }
}

async function getAdditionalClassificationZU(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/dictionaries/kvtspz?limit=1000`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            //'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        await props.setDataAddress(json, 'CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATIONS_ARR')
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        langFlag: state.start.langFlag,
        aucyionsPath: state.start.aucyionsPath,
        search: state.start.search,
        bildStatus: state.start.bildStatus,
        classificationsDgf: state.start.classificationsDgf,
        koatuu: state.start.koatuu,
        addressNew: state.start.addressNew,
        createItems: state.start.createItems,
    }
}


const mapDispatchToProps = {
    auctionsSearch,
    auctionsSearchTest,
    auctionsSearchСount,
    auctionsSearchOld,
    auctionsSearchСountOld,
    changeCurentPage,
    setSearchTextSting,
    setSearchSelectTypeKeyword,
    changeSearchDateStart,
    changeSearchDateEnd,
    changeStatusAuction,

    setAuctionCategory,
    setDataAddress,

    setKoatuuRegion, 
    setKoatuuGorod,
}

export default connect(mapStateToProps, mapDispatchToProps)(MainFind)