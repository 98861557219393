import React, {useState} from 'react';
import styles from './textArea.module.css';
import {useDispatch, useSelector} from 'react-redux';

const TextArea = (props) => {
    const dispatch = useDispatch()
    // const target = useSelector(state => state.start.search[props.value])
    const [inputText, setInputText] = useState(
        props.text && props.text !== undefined && props.text !== 'undefined' ? props.text : null
    )
    const [value, setValue] = useState(props.data.target || '')
    const [label, setLabel] = useState(props.data.necessarily ? `${props.data.label} *` : props.data.label)
    const [textAreaAlert, setTextAreaAlert] = useState(props.data.alert ? styles.alert : styles.textArea)

    function keyPressDispatch(e) {
        if (e.keyCode === 13) {
            let tmp = value.trim()
            // dispatch({ payload: tmp, type: props.data.targetType })
        }
    }

    function dispValue() {
        if (value !== props.data.target) {
            let tmp = value.trim()
            // dispatch({ payload: tmp, type: props.data.targetType})
        }
    }

    return (
        <>
            <div className={styles.inPutInfo}>
                <textarea type="text"
                    className={textAreaAlert}
                    value={value}
                    placeholder={props.data.placeholder}
                    onInput={e => {
                        setValue(e.target.value)
                        props.data.action(e.target.value)
                    }}
                    // onKeyDown={(e) =>  keyPressDispatch(e) }
                    // onBlur={ () => dispValue() }
                />
                { props.data.label ? 
                    <div className={styles.label}>
                        {label}
                    </div> : null
                }
            </div>
            {props.data.descr ? <div className={styles.descript}>{props.data.descr}</div> : null}
        </>
    )

}


export default TextArea