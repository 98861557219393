import React from 'react';
import styles from './auctionDataLine.module.css';

import {connect} from 'react-redux';

import AuctionDataLineLeft from './auctionDataLine_Left/auctionDataLine_Left.js';
import AuctionDataLineRight from './auctionDataLine_Right/auctionDataLine_Right.js';

const AuctionDataLine = ( props ) => {
    return (
        <div className={styles.secondBlock}>
            <AuctionDataLineLeft item={props.item} />
            <AuctionDataLineRight item={props.item} />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
    }
}

export default connect(mapStateToProps, null)(AuctionDataLine)