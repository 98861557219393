import React from 'react';
import styles from './greyLineText.module.css';

import {connect} from 'react-redux';

const GreyLineText = (props) => {
    return (
        <div className={styles.bank_right}>
            <div className={styles.bank_rightGreytext}>{props.title}</div>
            <div className={styles.bank_rightGreytext}>{props.date}</div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auction: state.start.auctionsGetId,
        langFlag: state.start.langFlag,
        profile: state.start.userProfiles
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(GreyLineText)
