import React, {useState, useEffect} from 'react';
import styles from './createAuction/procedure/procedure.module.css'
import {useSelector} from 'react-redux';

import { DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";

import {connect} from 'react-redux';

import {setDataAddress} from '../../../redux/createAddress';
import { isMongo, checkProp } from '../../../redux/check';
import {PROCEDURE_NAME} from '../../../redux/procedure_v3.35.2.uk';

import TextField from '@material-ui/core/TextField';
import InputDate from '../../elements/inputs/inputDate';

import TextArea from '../../elements/inputs/textArea'
import Input from '../../elements/inputs/input';
import Select from '../../elements/inputs/inputSelect';
import Button from '../../elements/buttons/button';
import InputAmount from '../../elements/inputs/inputAmount';
import LocationOrg from '../elems/localization/locationOrg';

import {
    setKoatuuRegion, 
} from '../../../redux/actions/address/address.js';

import {disabletElem, onOffCurrentTenat} from '../disabled'

import PreviousAuctionBidderfrom from '../../product/lotInformation/auctionElements/previousAuctionBidder'

import { getRegion } from '../../../api/dictonaries/region';

const LandCurrentTenant_LSP = ( props ) => {
    const auction = useSelector(state => state.start.auctionsMy)
    const [nameCurrentTenants, setNameCurrentTenants] = useState((checkProp(auction, 'currentTenants') && checkProp(auction.currentTenants[0], 'name')) ? isMongo(auction.currentTenants[0].name) : null)
    const [nameCurrentTenants2, setNameCurrentTenants2] = useState((checkProp(auction, 'currentTenants') && checkProp(auction.currentTenants[1], 'name')) ? isMongo(auction.currentTenants[1].name) : null)
    const [identifierLegalNameCurrentTenants, setIdentifierLegalNameCurrentTenants] = useState((checkProp(auction, 'currentTenants') && checkProp(auction.currentTenants[0], 'identifier') && checkProp(auction.currentTenants[0].identifier, 'legalName')) ? isMongo(auction.currentTenants[0].identifier.legalName) : null)
    const [identifierLegalNameCurrentTenants2, setIdentifierLegalNameCurrentTenants2] = useState((checkProp(auction, 'currentTenants') && checkProp(auction.currentTenants[1], 'identifier') && checkProp(auction.currentTenants[1].identifier, 'legalName')) ? isMongo(auction.currentTenants[1].identifier.legalName) : null)
    const [identifierSchemeCurrentTenants, setIdentifierSchemeCurrentTenants] = useState((checkProp(auction, 'currentTenants') && checkProp(auction.currentTenants[0], 'identifier') && checkProp(auction.currentTenants[0].identifier, 'scheme')) ? auction.currentTenants[0].identifier.scheme : null)
    const [identifierSchemeCurrentTenants2, setIdentifierSchemeCurrentTenants2] = useState((checkProp(auction, 'currentTenants') && checkProp(auction.currentTenants[1], 'identifier') && checkProp(auction.currentTenants[1].identifier, 'scheme')) ? auction.currentTenants[1].identifier.scheme : null)
    const [identifierIdCurrentTenants, setIdentifierIdCurrentTenants] = useState((checkProp(auction, 'currentTenants') && checkProp(auction.currentTenants[0], 'identifier') && checkProp(auction.currentTenants[0].identifier, 'id')) ? auction.currentTenants[0].identifier.id : null)
    const [identifierIdCurrentTenants2, setIdentifierIdCurrentTenants2] = useState((checkProp(auction, 'currentTenants') && checkProp(auction.currentTenants[1], 'identifier') && checkProp(auction.currentTenants[1].identifier, 'id')) ? auction.currentTenants[1].identifier.id : null)
    const [addressCurrentTenants, setAdressCurrentTenants] = useState(
        (checkProp(auction, 'currentTenants')
        && checkProp(auction.currentTenants[0], 'address')
    ) ? auction.currentTenants[0].address : null)
    const [addressCurrentTenants2, setAdressCurrentTenants2] = useState(
        (checkProp(auction, 'currentTenants')
        && checkProp(auction.currentTenants[1], 'address')
    ) ? auction.currentTenants[1].address : null)

    const [contactRepresentativeInfoCurrentTenants, setContactRepresentativeInfoCurrentTenants] = useState((checkProp(auction, 'currentTenants') && checkProp(auction.currentTenants[0], 'representativeInfo')) ? auction.currentTenants[0].representativeInfo : null)
    const [contactRepresentativeInfoCurrentTenants2, setContactRepresentativeInfoCurrentTenants2] = useState((checkProp(auction, 'currentTenants') && checkProp(auction.currentTenants[1], 'representativeInfo')) ? auction.currentTenants[1].representativeInfo : null)
    
    
    const [priority, setContactPriority] = useState((checkProp(auction, 'currentTenants') && checkProp(auction.currentTenants[0], 'priority')) ? auction.currentTenants[0].priority : null)
    const [priority2, setContactPriority2] = useState((checkProp(auction, 'currentTenants') && checkProp(auction.currentTenants[1], 'priority')) ? auction.currentTenants[1].priority : null)


    const [contactValueCurrentTenants, setContactValueCurrentTenants] = useState(
        (checkProp(auction, 'currentTenants') && checkProp(auction.currentTenants[0], 'currentTenantValue'))
        ? auction.currentTenants[0].currentTenantValue
        : null)
    const [contactValueCurrentTenants2, setContactValueCurrentTenants2] = useState(
        (checkProp(auction, 'currentTenants') && checkProp(auction.currentTenants[1], 'currentTenantValue'))
        ? auction.currentTenants[1].currentTenantValue
        : null)
    const [contactTimeDateFromCurrentTenants, setContactTimeDateFromCurrentTenants] = useState((checkProp(auction, 'currentTenants') && checkProp(auction.currentTenants[0], 'currentContractTime') && checkProp(auction.currentTenants[0].currentContractTime, 'dateFrom')) ? auction.currentTenants[0].currentContractTime.dateFrom : null)
    const [contactTimeDateFromCurrentTenants2, setContactTimeDateFromCurrentTenants2] = useState((checkProp(auction, 'currentTenants') && checkProp(auction.currentTenants[1], 'currentContractTime') && checkProp(auction.currentTenants[1].currentContractTime, 'dateFrom')) ? auction.currentTenants[1].currentContractTime.dateFrom : null)
    const [contactTimeDateTillCurrentTenants, setContactTimeDateTillCurrentTenants] = useState((checkProp(auction, 'currentTenants') && checkProp(auction.currentTenants[0], 'currentContractTime') && checkProp(auction.currentTenants[0].currentContractTime, 'dateTill')) ? auction.currentTenants[0].currentContractTime.dateTill : null)
    const [contactTimeDateTillCurrentTenants2, setContactTimeDateTillCurrentTenants2] = useState((checkProp(auction, 'currentTenants') && checkProp(auction.currentTenants[1], 'currentContractTime') && checkProp(auction.currentTenants[1].currentContractTime, 'dateTill')) ? auction.currentTenants[1].currentContractTime.dateTill : null)

    const [numberOfCurrentTenants, setNumberOfCurrentTenants] = useState(checkProp(auction, 'numberOfCurrentTenants') ? auction.numberOfCurrentTenants : 1)

    //let requestBodyCT = createPATCH()


    function createPATCH(){
        let tmp = null
        if(numberOfCurrentTenants === 2){
            tmp = {
                currentTenants: [
                    {
                        identifier: {
                            scheme: identifierSchemeCurrentTenants,
                            id: `${identifierIdCurrentTenants}`,
                            legalName: {
                                uk_UA: identifierLegalNameCurrentTenants
                            }
                        },
                        address: addressCurrentTenants,
                        representativeInfo: contactRepresentativeInfoCurrentTenants,
                        currentTenantValue: {
                            currency: checkProp(contactValueCurrentTenants,'currency') ? contactValueCurrentTenants.currency : null,
                            amount: checkProp(contactValueCurrentTenants,'amount') && contactValueCurrentTenants?.amount !== '' ? +contactValueCurrentTenants.amount : null,
                        },
                        currentContractTime: {
                            dateFrom: contactTimeDateFromCurrentTenants,
                            dateTill: contactTimeDateTillCurrentTenants
                        },
                        priority: priority
                    },
                    {
                        identifier: {
                            scheme: identifierSchemeCurrentTenants2,
                            id: `${identifierIdCurrentTenants2}`,
                            legalName: {
                                uk_UA: identifierLegalNameCurrentTenants2
                            }
                        },
                        address: addressCurrentTenants2,
                        representativeInfo: contactRepresentativeInfoCurrentTenants2,
                        currentTenantValue: {
                            currency: checkProp(contactValueCurrentTenants2,'currency') ? contactValueCurrentTenants2.currency : null,
                            amount: checkProp(contactValueCurrentTenants2,'amount') && contactValueCurrentTenants2?.amount !== '' ? +contactValueCurrentTenants2.amount : null,
                        },
                        currentContractTime: {
                            dateFrom: contactTimeDateFromCurrentTenants2,
                            dateTill: contactTimeDateTillCurrentTenants2
                        },
                        priority: priority2
                    }
                ]
            }
            if(tmp.currentTenants[0].representativeInfo === null || tmp.currentTenants[0].representativeInfo === ''){
                delete tmp.currentTenants[0].representativeInfo
            }
            if(tmp.currentTenants[1].representativeInfo === null || tmp.currentTenants[1].representativeInfo === ''){
                delete tmp.currentTenants[1].representativeInfo
            }
            if(tmp.currentTenants[0].currentContractTime.dateFrom === null || tmp.currentTenants[0].currentContractTime.dateTill === null){
                //tmp.currentTenants[0].currentContractTime = null
                delete tmp.currentTenants[0].currentContractTime
            }
            if(tmp.currentTenants[1].currentContractTime.dateFrom === null || tmp.currentTenants[1].currentContractTime.dateTill === null){
                //tmp.currentTenants[1].currentContractTime = null
                delete tmp.currentTenants[1].currentContractTime
            }
            if(tmp.currentTenants[0].currentTenantValue.amount === null || tmp.currentTenants[0].currentTenantValue.amount === '' || tmp.currentTenants[0].currentTenantValue.amount === 0){
                //tmp.currentTenants[0].currentTenantValue = null
                delete tmp.currentTenants[0].currentTenantValue
            }
            if(tmp.currentTenants[1].currentTenantValue.amount === null || tmp.currentTenants[1].currentTenantValue.amount === '' || tmp.currentTenants[1].currentTenantValue.amount === 0){
                //tmp.currentTenants[1].currentTenantValue = null
                delete tmp.currentTenants[1].currentTenantValue
            }
            return tmp
        }
        if(numberOfCurrentTenants === 1){
            tmp = {
                currentTenants: [
                    {
                        identifier: {
                            scheme: identifierSchemeCurrentTenants,
                            id: `${identifierIdCurrentTenants}`,
                            legalName: {
                                uk_UA: identifierLegalNameCurrentTenants
                            }
                        },
                        address: addressCurrentTenants,
                        representativeInfo: contactRepresentativeInfoCurrentTenants,
                        currentTenantValue: {
                            currency: checkProp(contactValueCurrentTenants,'currency') ? contactValueCurrentTenants.currency : null,
                            amount: checkProp(contactValueCurrentTenants,'amount') && contactValueCurrentTenants?.amount !== '' ? +contactValueCurrentTenants.amount : null,
                        },
                        currentContractTime: {
                            dateFrom: contactTimeDateFromCurrentTenants,
                            dateTill: contactTimeDateTillCurrentTenants
                        },
                        priority: priority
                    }
                ]
            }
            if(tmp.currentTenants[0].representativeInfo === null || tmp.currentTenants[0].representativeInfo === ''){
                delete tmp.currentTenants[0].representativeInfo
            }
            if(tmp.currentTenants[0].currentContractTime.dateFrom === null || tmp.currentTenants[0].currentContractTime.dateTill === null){
                //tmp.currentTenants[0].currentContractTime = null
                delete tmp.currentTenants[0].currentContractTime
            }
            if(tmp.currentTenants[0].currentTenantValue.amount === null || tmp.currentTenants[0].currentTenantValue.amount === '' || tmp.currentTenants[0].currentTenantValue.amount === 0){
                //tmp.currentTenants[0].currentTenantValue = null
                delete tmp.currentTenants[0].currentTenantValue
            }
        }
        return tmp
    }

    function disabledTransferPriorityPeriod(status, time=null){
        if(status === null) return false
        if(status === "active_rectification") return false
        if(time === null) return false
        if(status === "active_tendering"){
            if(time.hasOwnProperty("endDate")){
                let dateNow = new Date()
                let currentTimeZoneOffsetInHours = (dateNow.getTimezoneOffset() / 60) * -1
                let tmpDate = new Date(time.endDate)
                let tmp = tmpDate.getTime()
                tmp = tmpDate.getTime() + ((1000 * 60) * 60) * currentTimeZoneOffsetInHours

                if( tmp > Date.now()){
                    return false
                }
            }
        }
        return true
    }

    function colUch(status){
        /// if(status === null){
        //     return [
        //         {value: 1, name: '1'}
        //     ]
        // }
        return [
            {value: 1, name: '1'},
            {value: 2, name: '2'}
        ]
    }


    //console.log(numberOfCurrentTenants, addressCurrentTenants, addressCurrentTenants2)
    return (
        <>
            <Select //"Кількість учасників з переважним правом" 
                data={{
                    label: "Кількість учасників з переважним правом", 
                    target: numberOfCurrentTenants,
                    // info: 'Отака от штука-дрюка',
                    required: true,
                }}
                disabled={disabletElem(auction, '01')}
                onChange={(e) => { 
                    props.setDataAddress( e, "SET_NUMBER_OF_CURRENT_TENANTS")
                    setNumberOfCurrentTenants(e)
                }}
                options={colUch(auction.status)}
            />
            <div className={styles.costBlock}>
                <div className={styles.costBlock}>
                    <div className={styles.costBlockTitle}>Інформація про учасника з переважним правом</div>
                    <Input //Повна юридична назва організації або ПІБ
                        label='Повна юридична назва організації або ПІБ'
                        value={identifierLegalNameCurrentTenants}
                        onChange={(e)=>{ setNameCurrentTenants(e) }}
                        disabled='true'
                    />
                    <div className={styles.orgBlock}>
                        <div className={styles.costBlockTitle}>Ідентифікатори організації або особи</div>
                        <Input //Ідентифікатори організації
                            label='Повна юридична назва організації'
                            value={identifierLegalNameCurrentTenants}
                            onChange={(e)=>{ 
                                setIdentifierLegalNameCurrentTenants(e)
                            }}
                            required
                            disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                        />
                        <Select 
                            data={{
                                label: 'Ідентифікатори організації',
                                target: identifierSchemeCurrentTenants,
                                // info: 'Отака от штука-дрюка',
                                required: true,
                            }}
                            onChange={(e) => { setIdentifierSchemeCurrentTenants(e) }}
                            options={[
                                {name: 'Обрати', value: null},
                                {name: 'ЄДРПОУ', value: 'UA-EDR'},
                                {name: 'ІПН/РНОКПП', value: 'UA-IPN'},
                                {name: 'Паспорт', value: 'UA-PASSPORT'},
                                {name: 'ID-карта', value: 'UA-ID-CARD'},
                                {name: 'ІПН/РНОКПП (ФОП)', value: 'UA-IPN-FOP'}
                            ]}
                            disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                        />
                        <Input //Код ЄДРПОУ або ІПН або паспорт
                            label='Код ЄДРПОУ або ІПН або паспорт'
                            value={identifierIdCurrentTenants}
                            onChange={(e)=>{ setIdentifierIdCurrentTenants(e) }}
                            disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                            required
                        />
                    </div>
                    <LocationOrg
                        address={addressCurrentTenants}
                        setAdress={setAdressCurrentTenants}
                        dataText='Місцезнаходження учасника'
                        disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                    />
                    <Input //№ свідоцтва про право на провадження діяльності арбітражного керуючого
                        label='Інформація щодо підтвердження повноважень'
                        value={contactRepresentativeInfoCurrentTenants}
                        onChange={(e)=>{ setContactRepresentativeInfoCurrentTenants(e) }}
                        disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                    />
                    <Select 
                        data={{
                            label: 'Учасник',
                            target: priority,
                            // info: 'Отака от штука-дрюка',
                            required: true,
                        }}
                        onChange={(e) => { 
                            setContactPriority(e)
                            if(e === 0){
                                setContactPriority2(1)
                            }else{
                                setContactPriority2(0)
                            }
                        }}
                        options={[
                            {name: 'Обрати', value: null},
                            {name: 'З переважним правом', value: 0},
                            {name: 'З переважним правом другої черги', value: 1},
                        ]}
                        disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                    />
                    <div className={styles.costBlock}>
                        <InputAmount //"Гарантійний внесок"
                            title="Орендна ставка (сума на рік)"
                            lable={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.guarantee.amount.legalName}
                            data={{
                                amount: checkProp(contactValueCurrentTenants,'amount') ? contactValueCurrentTenants.amount : '',
                                currency: checkProp(contactValueCurrentTenants,'currency') ? contactValueCurrentTenants.currency : null,
                                auctionType: 'brd'
                            }}
                            onChange={(e) => { setContactValueCurrentTenants(e) }}
                            disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                        />
                    </div>
                    <div className={styles.startDate}>
                        <div className={styles.costBlockTitle}>Дата початку та закінчення договору оренди</div>
                        <div>Період з</div>
                        <InputDate
                            data={{
                                target: contactTimeDateFromCurrentTenants,
                                time: true
                            }}
                            onChange={ e => setContactTimeDateFromCurrentTenants(e) }
                            disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                        />
                        <div>Період по</div>
                        <InputDate
                            data={{
                                target: contactTimeDateTillCurrentTenants,
                                time: true
                            }}
                            onChange={ e => setContactTimeDateTillCurrentTenants(e) }
                            disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                        />
                    </div>
                </div>
                {numberOfCurrentTenants === 2
                    ? <div className={styles.costBlock}>
                        <div className={styles.costBlockTitle}>Інформація про учасника з переважним правом</div>
                        <Input //Повна юридична назва організації або ПІБ
                            label='Повна юридична назва організації або ПІБ'
                            value={identifierLegalNameCurrentTenants2}
                            onChange={(e)=>{ setNameCurrentTenants2(e) }}
                            disabled='true'
                        />
                        <div className={styles.orgBlock}>
                            <div className={styles.costBlockTitle}>Ідентифікатори організації або особи</div>
                            <Input //Ідентифікатори організації
                                label='Повна юридична назва організації'
                                value={identifierLegalNameCurrentTenants2}
                                onChange={(e)=>{ 
                                    setIdentifierLegalNameCurrentTenants2(e)
                                }}
                                required
                                disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                            />
                            <Select 
                                data={{
                                    label: 'Ідентифікатори організації',
                                    target: identifierSchemeCurrentTenants2,
                                    // info: 'Отака от штука-дрюка',
                                    required: true,
                                }}
                                onChange={(e) => { setIdentifierSchemeCurrentTenants2(e) }}
                                options={[
                                    {name: 'Обрати', value: null},
                                    {name: 'ЄДРПОУ', value: 'UA-EDR'},
                                    {name: 'ІПН/РНОКПП', value: 'UA-IPN'},
                                    {name: 'Паспорт', value: 'UA-PASSPORT'},
                                    {name: 'ID-карта', value: 'UA-ID-CARD'},
                                    {name: 'ІПН/РНОКПП (ФОП)', value: 'UA-IPN-FOP'}
                                ]}
                                disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                            />
                            <Input //Код ЄДРПОУ або ІПН або паспорт
                                label='Код ЄДРПОУ або ІПН або паспорт'
                                value={identifierIdCurrentTenants2}
                                onChange={(e)=>{ setIdentifierIdCurrentTenants2(e) }}
                                disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                                required
                            />
                        </div>
                        <LocationOrg
                            address={addressCurrentTenants2}
                            setAdress={setAdressCurrentTenants2}
                            dataText='Місцезнаходження учасника'
                            disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                        />
                        <Input //№ свідоцтва про право на провадження діяльності арбітражного керуючого
                            label='Інформація щодо підтвердження повноважень'
                            value={contactRepresentativeInfoCurrentTenants2}
                            onChange={(e)=>{ setContactRepresentativeInfoCurrentTenants2(e) }}
                            disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                        />
                        <Select 
                            data={{
                                label: 'Учасник',
                                target: priority2,
                                /// info: 'Отака от штука-дрюка',
                                required: true,
                            }}
                            onChange={(e) => { 
                                setContactPriority2(e)
                                if(e === 0){
                                    setContactPriority(1)
                                }else{
                                    setContactPriority(0)
                                }
                            }}
                            options={[
                                {name: 'Обрати', value: null},
                                {name: 'З переважним правом', value: 0},
                                {name: 'З переважним правом другої черги', value: 1},
                            ]}
                            disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                        />
                        <div className={styles.costBlock}>
                            <InputAmount //"Гарантійний внесок"
                                title="Орендна ставка (сума на рік)"
                                lable={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.guarantee.amount.legalName}
                                data={{
                                    amount: checkProp(contactValueCurrentTenants2,'amount') ? contactValueCurrentTenants2.amount : '',
                                    currency: checkProp(contactValueCurrentTenants2,'currency') ? contactValueCurrentTenants2.currency : null,
                                    auctionType: 'brd'
                                }}
                                onChange={(e) => { setContactValueCurrentTenants2(e) }}
                                disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                            />
                        </div>
                        <div className={styles.startDate}>
                            <div className={styles.costBlockTitle}>Дата початку та закінчення договору оренди</div>
                            <div>Період з</div>
                            <InputDate
                                data={{
                                    target: contactTimeDateFromCurrentTenants2,
                                    time: true
                                }}
                                onChange={ e => setContactTimeDateFromCurrentTenants2(e) }
                                disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                            />
                            <div>Період по</div>
                            <InputDate
                                data={{
                                    target: contactTimeDateTillCurrentTenants2,
                                    time: true
                                }}
                                onChange={ e => setContactTimeDateTillCurrentTenants2(e) }
                                disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                            />
                        </div>
                    </div>
                : null
                }
            </div>
            <div className={styles.bottomButtonBlock}>
                <Button
                    height={'50px'}
                    font={'16px'}
                    color='blue'
                    data={'Прийняти зміни'}
                    onClick={
                        () =>{
                            props.setDataAddress( createPATCH(), "SET_NEW_CURRENT_TENANT")
                        }
                    }
                />
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
        koatuu: state.start.koatuu,
        token: state.start.token,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow
    }
}

const mapDispatchToProps = {
    setDataAddress,
    setKoatuuRegion,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(LandCurrentTenant_LSP)