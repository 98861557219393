import React from 'react';
import styles from './newQuestion.module.css';

import {connect} from 'react-redux';

import {answerTitle, answerDiscription} from '../../../redux/actions/questions/questions.js';
import {auctionGetId, auctionGetIdToken} from '../../../redux/actions/auctions/auctions.js';
import {setDataAddress} from '../../../redux/createAddress';

const NewQuestion = ( props ) => {

    return (
        <div className={styles.newQuestionBlock}>
            <h3 className={styles.title}>Нове запитання</h3>
                <input type="text"
                    className={styles.inputStyle}
                    placeholder='Тема запитання'
                    value={props.questionCreate.title}
                    onChange={(e) => {
                        props.answerTitle(e.target.value)
                    }}
                />
                <textarea 
                    className={styles.textArea}
                    placeholder='Зміст запитання'
                    value={props.questionCreate.description}
                    onChange={(e) => {
                        props.answerDiscription(e.target.value)
                    }}
                >
                </textarea>
                <div
                    className={styles.blueBtn}
                    onClick = {
                        () => {
                            if(props.questionCreate.description !== '' && props.questionCreate.title !== ''){
                                createAnswer(props, props.auction.id)
                            }
                        }
                    }
                >
                    Надіслати
                </div>

        </div>
    )
}


async function  createAnswer(props, id){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${id}/question`;
    const response1 = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify({
            title: props.questionCreate.title,
            description: props.questionCreate.description,
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
    }else{
        let tmpBaseUrl = null;
        let tmpId = null;
        if(window.location.pathname.indexOf('/auctions/') !== -1){
            tmpBaseUrl = window.location.pathname.split('/auctions/');
            tmpId = tmpBaseUrl[1].split('/')
        }
        getPublicApiAuction(props, tmpId[0])
        props.answerTitle('')
        props.answerDiscription('')
    }
}

async function getPublicApiAuction(props, aucId){
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/auction/by_public_id/${aucId}`
    let header = null
    if(props.token !== null){
        header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }else{
        header = {
            'Content-Type': 'application/json'
        }
    }
    const response = await fetch(url, {
        method: "GET",
        headers: header
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        //props.setDataAddress(null, 'SET_FULL_AUCTION')
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){

        }else{
            props.setDataAddress(json, 'SET_FULL_AUCTION')
        }
    }
}




const mapStateToProps = state => {
    return {
        token: state.start.token,
        //auction: state.start.auctionsGetId,
        auction: state.start.auctionFull,
        langFlag: state.start.langFlag,
        questionCreate: state.start.questionCreate,
    }
}

const mapDispatchToProps = {
    auctionGetId,
    answerTitle,
    answerDiscription,
    auctionGetIdToken,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(NewQuestion)