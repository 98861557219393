import React from 'react';
import styles from './idLot.module.css';

import {connect} from 'react-redux';

import LineTwoElemString from '../lineTwoElemString/lineTwoElemString.js';

const IdLot = (props) => {
    let idAuction = null;
    let lotNumber = null
    let linkProzoro = null
    if(props.auction !== null){
        idAuction = props.auction.auctionId;
        lotNumber = props.auction.lotIdentifier;
        if(props.auction.auctionId !== null){
            let tmpLink = 'https://prozorro.sale/auction/' + props.auction.auctionId
            linkProzoro = <a className={styles.colorLink} href={tmpLink} target="_blank" rel="noopener noreferrer">
                <div className={setStyle(props)}>{idAuction}</div> 
            </a>
        }
    }    
    return (
        <div className={styles.lotId}>
            <LineTwoElemString 
                titleString='Номер лоту у Замовника'
                textString={lotNumber}
                grey={true}
            />
            <div className={styles.terminuLine}>
                <div className={styles.terminuLineText}>Ідентифікатор аукціону</div>
                {linkProzoro}
            </div>
        </div>
    )
}

function setStyle(props){
    let tmp = null;
    if(props.grey === true){
        tmp = styles.terminuLineDate + ' ' + styles.grey;
    } else {
        tmp = styles.terminuLineDate;
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auction: state.start.auctionsGetId,
        langFlag: state.start.langFlag,
    }
}

export default connect(mapStateToProps, null)(IdLot)