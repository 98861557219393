import React from 'react';
import styles from './titleLot.module.css';

import {reverseStr, createApostrof} from '../../../model/summNumberToSreang.js';
import {modifiDateString} from '../../../redux/modifiDate.js'

import {connect} from 'react-redux';

const TitleLot = ( props ) => {
    let title = null;
    let date = null;

    let amount = null;
    let tmp = null;
    let tmpPDV = null

    if(props.auction !== null){
        title = viewTitle(props.auction);
        date = viewDateDayCreateAuction(props.auction);
        amount = String(viewAmount(props.auction));
        if(amount !== null){
            if(amount.indexOf('.') !== -1){
                amount = amount.split('.');
                //tmp = props.createApostrof(props.reverseStr(amount[0]));
                //tmp = props.reverseStr(tmp);
                tmp = String(amount[0]);
                tmp = tmp.split("").reverse().join("");
                let tmp1 = [];
                for(let i = 0; i < tmp.length; i++){
                    if(((i+1)%3) === 0){
                        if(i !== tmp.length-1){
                            tmp1[i] = tmp[i] + '\'';
                        }else{
                            tmp1[i] = tmp[i];
                        }
                    }else{
                        tmp1[i] = tmp[i];
                    }
                }
                tmp = tmp1.join('')
                tmp = tmp.split("").reverse().join("");
                amount = amount[1];
            }else{
                tmp = String(amount);
                tmp = tmp.split("").reverse().join("");
                let tmp1 = [];
                for(let i = 0; i < tmp.length; i++){
                    if(((i+1)%3) === 0){
                        if(i !== tmp.length-1){
                            tmp1[i] = tmp[i] + '\'';
                        }else{
                            tmp1[i] = tmp[i];
                        }
                    }else{
                        tmp1[i] = tmp[i];
                    }
                }
                tmp = tmp1.join('')
                tmp = tmp.split("").reverse().join("");
                amount = '00'    
            }
        }
        if(props.auction.value.valueAddedTaxIncluded === true){
            tmpPDV = '(з ПДВ)'
        }else{
            tmpPDV = '(без ПДВ)'
        }

    }

    let imgBlock = null
    if(props.auction !== null){
        if(props.auction.documents !== null){
            if(props.auction.documents.length > 0){
                for(let tmpT = 0; tmpT < props.auction.documents.length; tmpT++){
                    if(props.auction.documents[tmpT].documentType === 'illustration'){
                        if(props.auction.documents[tmpT].url !== null){
                            imgBlock = <div className={styles.illustration}>
                                <img className={styles.illustrationImg} src={props.auction.documents[tmpT].url} alt={props.auction.documents[tmpT].title} />
                            </div>
                        }else{
                            imgBlock = null
                        }
                    }
                }
            }
        }
    }
    return (
        <div className={styles.headerTovar}>
            <div className={styles.headerTovarTitle}>
                <div className={styles.headerTovarDate}>Оголошення аукціону з продажу від {date}</div>
                <h1 className={styles.headerTovarText}>{title}</h1>
            </div>
            <div className={styles.headerTovarStartCoast}>
                <div className={styles.headerTovarStartCoastText}>Початкова ціна продажу лоту</div>
                <div className={styles.headerTovarText + ' ' + styles.headerTitleTovarSumm}>{tmp}<span>,{amount} грн.{tmpPDV}</span></div>
                {imgBlock}
            </div>
        </div>
    )
}

function viewTitle(auction){
    let tmp = null;
    if(auction.hasOwnProperty('title') === true){
        tmp = String(auction.title);
    }
    return tmp
}

function viewDateDayCreateAuction(auction){
    let tmp = null;
    if(auction.hasOwnProperty('createdAt') === true){
        if(auction.createdAt !== null){
            tmp = modifiDateString(auction.createdAt, 1);
        }
    }
    return tmp
}

function viewAmount(auction){
    let tmp = null;
    if(auction.hasOwnProperty('value') === true){
        if(auction.value.hasOwnProperty('amount') === true){
            if(auction.value.amount !== null){
                tmp = auction.value.amount;
            }
        }
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        forestArrTwo: state.start.forestArrTwo,
        pageNumber: state.start.pageNumber,
        auction: state.start.auctionsGetId
    }
}

const mapDispatchToProps = {
    reverseStr,
    createApostrof,
}

export default connect(mapStateToProps, mapDispatchToProps)(TitleLot)
