import React from 'react';
import styles from './greyLine.module.css';

import {connect} from 'react-redux';

import {modifiDateString} from '../../../../../redux/modifiDate.js'

const START_TEXT_0 = ['Оголошення аукціону з продажу майна від', 'EN - Оголошення аукціону з продажу майна від'];
//const START_TEXT_1 = ['Ви ще не берете участь в обговоренні цього аукціону', 'EN - Ви ще не берете участь в обговоренні цього аукціону'];
//const START_TEXT_2 = ['Ви ще не берете участь в цьому аукціоні', 'EN - Ви ще не берете участь в цьому аукціоні'];
//const START_TEXT_3 = ['Додати аукціон у вибране', 'EN - Додати аукціон у вибране'];

const GreyLineTop = ( props ) => {
    let tmp = null;
    if(props.item.hasOwnProperty('cDate') === true){
        if(props.item.createdAt !== null){
            tmp = modifiDateString(props.item.cDate, 1) + ' ' + modifiDateString(props.item.cDate, 2)
        }
    }
    return (
        <div className={styles.firstBlock}>
            <div className={styles.header}>{START_TEXT_0[props.langFlag]} {tmp}</div>
            <div className={styles.icons}>
                {/*<div className={styles.obgovorennya}></div>
                <div className={styles.uchast}></div>
                <div className={styles.izbrane}></div>
                <div className={styles.inviz}>{START_TEXT_1[props.langFlag]}</div>
                <div className={styles.inviz}>{START_TEXT_2[props.langFlag]}</div>
                <div className={styles.inviz}>{START_TEXT_3[props.langFlag]}</div>*/}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
    }
}

export default connect(mapStateToProps, null)(GreyLineTop)
