import React, {useState, useEffect} from 'react'
import styles from './ecpCheckBlock.module.css'

import {connect} from 'react-redux';
// import 

import {
    togetherApiPopUpOnOff,
    togetherApiDocTitle,
    togetherApiDocDescription,
    togetherApiDocType,
    togetherApiDocLang,
    togetherApiDocFileName,
    togetherApiECP,
} from '../../../redux/togetherApi';
import { ecpApiPass, ecpApiList, changeCA } from '../../../redux/ecpApi';
import KEPTypeSelect from '../../documents/docType/docTypeSelect';
import Input from '../../documents/docInputs/input';

import {kepMain, kepMainEdit} from '../../../redux/kep2'
import { checkProp } from '../../../redux/check';

const ESignCheckBlock = (props) => {
    const [chBoxEsign, setChBoxEsign] = useState(false)
    // const [nameEsign, setNameEsign] = useState('Завантажити електронний підпис')
    const [KEPType, setKEPType] = useState(null)
    const [KEPFile, setKEPFile] = useState(null)
    const [KEPTypeList, setKEPTypeList] = useState(null)
    const [KEPpass, setKEPpass] = useState('')
    const [file, setFile] = useState(props.fileFile)

    let KEPpassData = {
        label: 'Пароль від електронного підпису',
        target: KEPpass,
        action: setKEPpass,
        type: 'password',
        necessarily: true
    }

    if (chBoxEsign) {
        if (KEPTypeList === null) {
            epListCertificateAuthority(props, setKEPTypeList)
            optionCA(props, KEPTypeList)
        }
    }

    function ecpBlockInputs() {
        let kepType = {
            label: 'КНЕДП/АЦСК',
            target: KEPType,
            action: setKEPType,
            necessarily: true,
            docList: optionCA(props, KEPTypeList),
            disabled: false
        }

        return (
            <>
                <label className={styles.btnESign}>
                    { KEPFile ? (KEPFile.name.length > 45 ? KEPFile.name.substr(0, 45) + '...' : KEPFile.name) : 'Завантажити електронний підпис' }
                    <input type="file"
                        name="file"
                        id="eSignFile"
                        onInput={(e) => {
                            e.preventDefault()
                            setKEPFile(e.target.files[0])
                            // setNameEsign(KEPFile.name.length > 45 ? KEPFile.name.substr(0, 45) + '...' : KEPFile.name)
                        }}>
                    </input>
                </label>
                {listCA(props, kepType)}
                <Input data={KEPpassData} />
                {/* <input 
                    className={styles.inputPwd}
                    type='password'
                    placeholder='Пароль від електронного підпису'
                    value={props.ecpData.pass}
                    onInput={(e) => {
                        // props.changeBidAward(e.target.value)
                        // props.changeBidPopUpEditDocTitle(e.target.value)
                        props.ecpApiPass(e.target.value)
                    }}
                /> */}
                {buttonsBlock(props, { file: KEPFile, type: KEPType, pass: KEPpass })}
            </>)
    }
    return (
        <div className={styles.wrapEsign}>
            <div className={styles.chekbox}>
                { props.active ? <div
                                    className={chBoxEsign ? styles.pseudoCheckboxOn : styles.pseudoCheckboxOff}
                                    onClick={() => {
                                        setChBoxEsign(!chBoxEsign)
                                        if (!props.dataFilesAtribute.viewBtns) {
                                            props.dataFilesAtribute.setViewBtns(true)
                                            // epListCertificateAuthority(props, setKEPTypeList)
                                        } else {
                                            props.dataFilesAtribute.setViewBtns(false)
                                        }
                                    }}
                                >
                                </div>
                              :
                    <div className={styles.pseudoCheckboxInActive}></div>
                }
                {/* {disabledCheckbox(props, epListCertificateAuthority, file)} */}
                <div className={ props.active ? styles.chekboxLabel : styles.chekboxLabelinActive}>Електронний підпис
                    {/* <span>Не потребує зовсім, або підписаний в іншому сервісі (Наприклад, Документ.Онлайн)</span> */}
                </div>
            </div>
            {chBoxEsign ?
                <>
                    {ecpBlockInputs()}
                </>
                : null
            }
        </div>
    )
}

function buttonsBlock(props, KEPdata){
    return (
        <div className={styles.bottomPart}>
            <div className={styles.btnCancel}
                onClick={
                    ()=>{
                        if(props.dataFilesAtribute.viewBtns){
                            props.dataFilesAtribute.setViewBtns(false)
                            props.dataFilesAtribute.actionNo()
                        }else{
                            props.dataFilesAtribute.actionNo()
                        }
                    }
                }
            >
                Скасувати
            </div>
            {viewButtos(props, KEPdata)}
        </div>
    )
}

function viewButtos(props, KEPdata) {
    if(
        props.dataFilesAtribute.fileName !== '' &&
        props.dataFilesAtribute.fileName !== null &&
        props.dataFilesAtribute.fileType !== null &&
        props.dataFilesAtribute.fileType !== 'null' &&
        KEPdata.file !== null &&
        KEPdata.type !== null &&
        KEPdata.pass !== ''
    ){
        return (
            <div className={styles.btnDone}
                onClick={
                    async () => {
                        if (props.dataFilesAtribute.documentOf === 'bid'){
                            if(checkProp(props, 'dataFilesAtribute')){
                                if(checkProp(props.dataFilesAtribute, 'bidId')){
                                    await kepMainEdit(props, KEPdata)
                                }else{
                                    await kepMain(props, KEPdata)
                                }
                            }else{
                                if(props.dataFilesAtribute.id === null){
                                    await kepMain(props, KEPdata)
                                }else{
                                    await kepMainEdit(props, KEPdata)
                                }
                            }
                        }else if(props.dataFilesAtribute.documentOf === 'bidAward'){
                            if(props.dataFilesAtribute.id === null){
                                await kepMain(props, KEPdata)
                            }else{
                                await kepMainEdit(props, KEPdata)
                            }
                        }else if(props.dataFilesAtribute.documentOf === 'contract'){
                            if(props.dataFilesAtribute.id === null){
                                await kepMain(props, KEPdata)
                            }else{
                                await kepMainEdit(props, KEPdata)
                            }
                        }else if(props.dataFilesAtribute.documentOf === 'award'){
                            if(props.dataFilesAtribute.id === null){
                                await kepMain(props, KEPdata)
                            }else{
                                await kepMainEdit(props, KEPdata)
                            }
                        }else if(props.dataFilesAtribute.documentOf === 'auction' || props.dataFilesAtribute.documentOf === 'auctionEdit'){
                            if(props.dataFilesAtribute.id === null){
                                await kepMain(props, KEPdata)
                            }else{
                                await kepMainEdit(props, KEPdata)
                            }
                        }
                        await props.dataFilesAtribute.setViewBtns(false)
                    }
                }
            >
                Зберегти
            </div>
        )
    }
    return null
}

function listCA(props, kepType){
    return  <KEPTypeSelect
                data={kepType}
            />
}

function optionCA(props, KEPTypeList) {
    let tmp = null
    if(KEPTypeList !== null){
        if(KEPTypeList.length > 0){
            tmp = KEPTypeList.map(
                (i, count) => {
                    // return <option key={count} value={i.id}>{i.name}</option>
                    return {value: i.id, name: i.name}
                }
            )
        }
    }
    return tmp
}

//Получение списка выдавателей КЕП
async function epListCertificateAuthority(props, setKEPTypeList){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/certificateAuthority/supported`;
        const response = await fetch(url, {
        method: "GET",
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    await setKEPTypeList(response.ca)
}


const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctionId: state.start.auctionsGetId,
        langFlag: state.start.langFlag,
        pageNumber: state.start.pageNumber,
        auctionsGetIdNotFound: state.start.auctionsGetIdNotFound,
        bidAward: state.start.bidAward,
        documentCount: state.start.documentCount,
        curentBidLot: state.start.curentBidLot,
        bidCvalification: state.start.bidCvalification,
        bidPopUpEdit: state.start.bidPopUpEdit,
        bidQuantity: state.start.bidQuantity,
        bidEdit: state.start.bidEdit,

        togetherApiPage: state.start.togetherApiPage,
        ecpData: state.start.ecpData,

        createDocuments: state.start.createDocuments,
        popUpContractUniversal: state.start.popUpContractUniversal,
    }
}

const mapDispatchToProps = {
    togetherApiECP,
    ecpApiPass,
    ecpApiList,
    changeCA,
}

export default connect(mapStateToProps, mapDispatchToProps)(ESignCheckBlock)