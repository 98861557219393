import React from 'react';
import style from './popup.module.css';

import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import {
    togetherApiPopUpOnOff,
    togetherApiDocTitle,
    togetherApiDocDescription,
    togetherApiDocType,
    togetherApiDocLang,
    togetherApiDocFileName,
    togetherApiECP,
    togetherApiBidsArr,
    setDraftBidSumm,
} from '../../../../../redux/togetherApi.js';

import {ecpApiPass} from '../../../../../redux/ecpApi.js';

import {setUniversalError} from '../../../../../redux/actions.js';

const BidError = ( props ) => {
    let tmp = null
    if(Number(props.togetherApiPage.bidsDraftSumm) >= props.togetherApiPage.bidsDraftArr.auction.value.amount){
        tmp = <Button
            variant="contained"
            color="primary"
            onClick={
                () => {
                    saveDraftBid(props)
                }
            }
        >
            Додати в чернетки
        </Button>
    }else{
        tmp = <Button
            variant="contained"
            color="disabled"
        >
            Додати в чернетки
        </Button>
    }
    return (
        <div className={style.popup_wrap}>
            <div className={style.popup_message}>
                <div
                    className={style.popup_closeX}
                    onClick={
                        () => {
                            props.togetherApiBidsArr(null)
                            props.setDraftBidSumm('')
                        }
                    }
                >X</div>
                <div className={style.title}>Cтворення чернетки</div>
                <div className={style.groupElem}>
                    <div>
                        <TextField
                            required
                            id="filled-required"
                            className={style.inputText}
                            label="Сума ставки"
                            variant="outlined"
                            value={props.togetherApiPage.bidsDraftSumm}
                            onChange={
                                (e) => {
                                    props.setDraftBidSumm(e.target.value)
                                }
                            }
                        />
                    </div>
                </div>
                {tmp}
            </div>
        </div>
    )
}

async function saveDraftBid(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.togetherApiPage.bidsDraftArr.auction.id}/bid`;
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify({
            classificationId: props.togetherApiPage.bidsDraftArr.auction.items[0].classification.id,
            unitCode: props.togetherApiPage.bidsDraftArr.auction.items[0].unit.code,
            documentIds: [],
            qualified: true,
            value: {
                currency: 'UAH',
                amount: props.togetherApiPage.bidsDraftSumm
            },
            quantity: props.togetherApiPage.bidsDraftArr.auction.items[0].quantity
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка створення чернетки ставки', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            props.setDraftBidSumm('')
            props.togetherApiBidsArr(null)
        }else{
            props.setDraftBidSumm('')
            props.togetherApiBidsArr(null)
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        togetherApiPage: state.start.togetherApiPage,
    }
}

const mapDispatchToProps = {
    togetherApiPopUpOnOff,
    togetherApiDocTitle,
    togetherApiDocDescription,
    togetherApiDocType,
    togetherApiDocLang,
    togetherApiDocFileName,
    setUniversalError,
    togetherApiECP,
    ecpApiPass,
    togetherApiBidsArr,
    setDraftBidSumm,
}

export default connect(mapStateToProps, mapDispatchToProps)(BidError)