import {addProperty, addPropertyMonga, addPropertyValue, addPropertyDiscount,
    addAuctionPeriodProperty, addBankProperty, addArrest, addOrganizationEntity,
    addContactPoint, addAdditionalInformation, addDutchStep
} from './utilsCreateBody'

export function сreateBodyAPD(data, auction = null){
    if(data !== null){
        let body = {}
        if(auction !== null){
            if(auction.sellingMethod !== data.base.sellingMethod) body = addProperty(data, body, 'sellingMethod')
        }else{
            body = addProperty(data, body, 'sellingMethod')
        }
        if(auction !== null){
            if(auction.title.uk_UA !== data.base.title.uk_UA) body = addPropertyMonga(data, body, 'title')
        }else{
            body = addPropertyMonga(data, body, 'title')
        }
        if(auction !== null){
            if(auction.description.uk_UA !== data.base.description.uk_UA) body = addPropertyMonga(data, body, 'description')
        }else{
            body = addPropertyMonga(data, body, 'description')
        }
        if(auction !== null){
            if(auction.lotId !== data.base.lotId) body = addProperty(data, body, 'lotId')
        }else{
            body = addProperty(data, body, 'lotId')
        }
        if(auction !== null){
            if(auction.tenderAttempts !== data.base.tenderAttempts) body = addProperty(data, body, 'tenderAttempts')
        }else{
            body = addProperty(data, body, 'tenderAttempts')
        }
        if(auction !== null){
            let objA = {}
            let objB = {}
            if (auction.accessDetails === null){
                objA.uk_UA = null
            }else{
                objA = auction.accessDetails
            }
            if (data.base.accessDetails === null){
                objB.uk_UA = null
            }else{
                objB = data.base.accessDetails
            }
            if(objA.uk_UA !== objB.uk_UA) body = addPropertyMonga(data, body, 'accessDetails')
        }else{
            body = addPropertyMonga(data, body, 'accessDetails')
        }
        if(auction !== null){
            let objA = {}
            let objB = {}
            if (auction.x_documentRequirements === null){
                objA.uk_UA = null
            }else{
                objA = auction.x_documentRequirements
            }
            if (data.base.x_documentRequirements === null){
                objB.uk_UA = null
            }else{
                objB = data.base.x_documentRequirements
            }
            if(objA.uk_UA !== objB.uk_UA) body = addPropertyMonga(data, body, 'x_documentRequirements')
        }else{
            body = addPropertyMonga(data, body, 'x_documentRequirements')
        }
        if(auction !== null){
            let objA = {}
            let objB = {}
            if (auction.x_additionalInformation === null){
                objA.uk_UA = null
            }else{
                objA = auction.x_additionalInformation
            }
            if (data.base.x_additionalInformation === null){
                objB.uk_UA = null
            }else{
                objB = data.base.x_additionalInformation
            }
            if(objA.uk_UA !== objB.uk_UA) body = addPropertyMonga(data, body, 'x_additionalInformation')
        }else{
            body = addPropertyMonga(data, body, 'x_additionalInformation')
        }
        // if(auction !== null){
        //     if(auction.minNumberOfQualifiedBids !== data.base.minNumberOfQualifiedBids) body = addProperty(data, body, 'minNumberOfQualifiedBids')
        // }else{
        //     body = addProperty(data, body, 'minNumberOfQualifiedBids')
        // }
        if(auction !== null){
            let obj = {}
                obj.id = auction.auctionPeriod.id
                obj.startDate = data.base.auctionPeriod.startDate
                obj.endDate = auction.auctionPeriod.endDate
            if(JSON.stringify(auction.auctionPeriod) !== JSON.stringify(obj)){
                if(data.base.isPerishable !== true) body = addAuctionPeriodProperty(data, body, 'auctionPeriod')
            }
        }else{
            if(data.base.isPerishable !== true) body = addAuctionPeriodProperty(data, body, 'auctionPeriod')
        }
        if(auction !== null){
            if(auction.valueAddedTaxCharged !== data.base.valueAddedTaxCharged) body = addProperty(data, body, 'valueAddedTaxCharged')
        }else{
            body = addProperty(data, body, 'valueAddedTaxCharged')
        }
        if(auction !== null){
            if((auction.value.amount !== data.base.value.amount)
            || (auction.value.valueAddedTaxIncluded !== data.base.value.valueAddedTaxIncluded)
            ) body = addPropertyValue(data, body, 'value')
        }else{
            body = addPropertyValue(data, body, 'value')
        }
        if(auction !== null){
            if(auction.minimalStep.amount !== data.base.minimalStep.amount){
                if(data.base.minimalStep.amount !== null
                    && data.base.minimalStep.amount !== ''
                    && data.base.minimalStep.amount !== 0
                    && data.base.minimalStep.amount !== '0'
                ){
                    body = addPropertyValue(data, body, 'minimalStep')
                }else{
                    body.minimalStep = null
                }
            }
        }else{
            if(data.base.minimalStep.amount !== null
                && data.base.minimalStep.amount !== ''
                && data.base.minimalStep.amount !== 0
                && data.base.minimalStep.amount !== '0'
            ){
                body = addPropertyValue(data, body, 'minimalStep')
            }else{
                body.minimalStep = null
            }
        }
        if(auction !== null){
            if(auction.guarantee.amount !== data.base.guarantee.amount) body = addPropertyValue(data, body, 'guarantee')
        }else{
            body = addPropertyValue(data, body, 'guarantee')
        }
        if(auction !== null){
            if(auction.registrationFee.amount !== data.base.registrationFee.amount) body = addPropertyValue(data, body, 'registrationFee')
        }else{
            body = addPropertyValue(data, body, 'registrationFee')
        }
        if(auction !== null){
            if((auction.preparationValue.amount !== data.base.preparationValue.amount)
            || (auction.preparationValue.valueAddedTaxIncluded !== data.base.preparationValue.valueAddedTaxIncluded)
            ) body = addPropertyValue(data, body, 'preparationValue')
        }else{
            body = addPropertyValue(data, body, 'preparationValue')
        }
        if(auction !== null){
            if(auction.previousAuctionId !== data.base.previousAuctionId) body = addProperty(data, body, 'previousAuctionId')
        }else{
            body = addProperty(data, body, 'previousAuctionId')
        }
        // if(auction !== null){
        //     if(JSON.stringify(auction.banks) !== JSON.stringify(data.base.banks)) body = addBankProperty(data, body, 'banks')
        // }else{
            body = addBankProperty(data, body, 'banks')
        // }
        if(auction !== null){
            if(auction.isPerishable !== data.base.isPerishable) body = addProperty(data, body, 'isPerishable')
        }else{
            body = addProperty(data, body, 'isPerishable')
        }
        if(auction !== null && auction.organizationEntity !== null){
            let obj = {}//data.base.organizationEntity
            obj.id = auction.organizationEntity.id
            obj.name = auction.organizationEntity.name
            obj.name.en_US = null
            obj.identifier = {}
            obj.identifier.id = auction.organizationEntity.identifier.id
            obj.identifier.legalName = data.base.organizationEntity.identifier.legalName
            obj.identifier.legalName.en_US = null
            obj.identifier.scheme = data.base.organizationEntity.identifier.scheme

            obj.address = {}
            obj.address.id = auction.organizationEntity.address.id
            obj.address.addressID = null
            obj.address.streetAddress = data.base.organizationEntity.address.streetAddress
            //obj.address.streetAddress.en_US = null
            obj.address.locality = data.base.organizationEntity.address.locality
            obj.address.locality.en_US = null
            obj.address.region = data.base.organizationEntity.address.region
            obj.address.region.en_US = null
            obj.address.postalCode = data.base.organizationEntity.address.postalCode
            obj.address.countryName = data.base.organizationEntity.address.countryName

            obj.contactPoint = {}
            obj.contactPoint.name = data.base.organizationEntity.contactPoint.name
            obj.contactPoint.email = data.base.organizationEntity.contactPoint.email
            obj.contactPoint.telephone = data.base.organizationEntity.contactPoint.telephone
            obj.contactPoint.faxNumber = data.base.organizationEntity.contactPoint.faxNumber
            obj.contactPoint.url = data.base.organizationEntity.contactPoint.url
            
            obj.currentContractTime = auction.organizationEntity.currentContractTime
            obj.currentTenantValue = auction.organizationEntity.currentTenantValue
            obj.representativeInfo = data.base.organizationEntity.representativeInfo

            if(JSON.stringify(auction.organizationEntity) !== JSON.stringify(obj)) body = addOrganizationEntity(data, body, 'organizationEntity')
        }else{
            body = addOrganizationEntity(data, body, 'organizationEntity')
        }
        if(auction !== null){
            let obj = {}
            obj.id = auction.contactPoint.id
            obj.name = data.base.contactPoint.name
            obj.name.en_US = null
            obj.email = data.base.contactPoint.email
            obj.telephone = data.base.contactPoint.telephone
            obj.faxNumber = data.base.contactPoint.faxNumber
            obj.url = data.base.contactPoint.url
            if(JSON.stringify(auction.contactPoint) !== JSON.stringify(obj)) body = addContactPoint(data, body, 'contactPoint')
        }else{
            body = addContactPoint(data, body, 'contactPoint')
        }
        body = addDutchStep(data, body, 'dutchStep')

        //body = addAdditionalInformation(data, body, 'additionalInformation')
        return body
    }
    return null
}