import React from 'react';
import styles from './profile.module.css';

import {connect} from 'react-redux';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const Profile = (props) => {
    let tmp = null;
    if(props.token !== null){
        tmp = (
            <div className={styles.profile_wrap}>
                <div className={styles.profileContent}>
                    <div>
                        <div className={styles.profileTitle}>Інформація про організацію</div>
                        <div className={styles.profile_elem}>
                            <div className={styles.groupElem}>
                                <div>
                                    <Select
                                        id="outlined-select-currency-native"
                                        className={styles.selectWidth}
                                        select
                                        value={0}
                                        label="Країна реєстрації"
                                        onChange={(event)=>{
                                            
                                        }}
                                        variant="outlined"
                                    >
                                        <option disabled value={0}>Выберите страну</option>
                                        <option>Україна</option>
                                    </Select>
                                </div>
                            </div>
                            <div className={styles.groupElem}>
                                <div>
                                    <Select
                                        id="outlined-select-currency-native"
                                        className={styles.selectWidth}
                                        select
                                        label="Схема реєстрації"
                                        value={0}
                                        onChange={(event)=>{
                                            
                                        }}
                                        variant="outlined"
                                    >
                                        <option disabled value={0}>Выберите схему регистрации</option>
                                        <option>Реєстр юридичних осіб ФОП (UA-EDR)</option>
                                    </Select>
                                </div>
                            </div>
                            <div className={styles.groupElem}>
                                <div>
                                    <Select
                                        id="outlined-select-currency-native"
                                        className={styles.selectWidth}
                                        select
                                        label="Тип підприємства"
                                        value={0}
                                        onChange={(event)=>{
                                            
                                        }}
                                        variant="outlined"
                                    >
                                        <option disabled value={0}>Выберите тип предприятия</option>
                                        <option>Фізична особа</option>
                                    </Select>
                                </div>
                            </div>
                            <div className={styles.groupElem}>
                                <div>
                                    <TextField
                                        className={styles.selectWidth}
                                        id="outlined-multiline-static"
                                        label="Назва юридичної особи"
                                        value=''
                                        variant="outlined"
                                        onChange={(event)=>{
                                            
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={styles.groupElem}>
                                <div>
                                    <TextField
                                        className={styles.selectWidth}
                                        id="outlined-multiline-static"
                                        label="Код ЄДРПОУ / ІПН"
                                        value=''
                                        variant="outlined"
                                        onChange={(event)=>{
                                            
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={styles.groupElem}>
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked="false"
                                                onChange={
                                                    () => {

                                                    }
                                                }
                                                name="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label="Є фінансовою компанією"
                                    />
                                </div>
                            </div>
                            <div className={styles.groupElem}>
                                <div>
                                    <TextField
                                        className={styles.selectWidth}
                                        id="outlined-multiline-static"
                                        label="Опис"
                                        value=''
                                        variant="outlined"
                                        onChange={(event)=>{
                                            
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={styles.groupElem}>
                                <div>
                                    <Select
                                        id="outlined-select-currency-native"
                                        className={styles.selectWidth}
                                        select
                                        label="Статус платника ПДВ"
                                        value={0}
                                        onChange={(event)=>{
                                            
                                        }}
                                        variant="outlined"
                                    >
                                        <option disabled value={0}>Выберите статус плательщика ПДВ</option>
                                        <option>Не є платником ПДВ</option>
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className={styles.profileTitle}>Дані про уповноважену особу (для укладання договору)</div>
                        <div className={styles.profile_elem}>
                            <div className={styles.groupElem}>
                                <div>
                                    <TextField
                                        className={styles.selectWidth}
                                        id="outlined-multiline-static"
                                        label="ПІБ"
                                        value=''
                                        variant="outlined"
                                        onChange={(event)=>{
                                            
                                        }}
                                        helperText="в родовому відмінку (Іванова Івана Івановича)"
                                    />
                                </div>
                            </div>
                            <div className={styles.groupElem}>
                                <div>
                                    <TextField
                                        className={styles.selectWidth}
                                        id="outlined-multiline-static"
                                        label="Посада"
                                        value=''
                                        variant="outlined"
                                        onChange={(event)=>{
                                            
                                        }}
                                        helperText="ві родовому відмінку (Директора, Голови Правління...)"
                                    />
                                </div>
                            </div>
                            <div className={styles.groupElem}>
                                <div>
                                    <TextField
                                        className={styles.selectWidth}
                                        id="outlined-multiline-static"
                                        label="Документ, що уповноважує діяти від імені підприємства (в т.ч. підписувати документи)"
                                        value=''
                                        variant="outlined"
                                        onChange={(event)=>{
                                            
                                        }}
                                        helperText="в родовому відмінку (Статуту, Довіренності, Наказу...)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={styles.profileTitle}>Спосіб та параметри повернення сум гарантійних забезпечень</div>
                        <div className={styles.profile_elem}>
                            <div className={styles.groupElem}>
                                <div>
                                    <Select
                                        id="outlined-select-currency-native"
                                        className={styles.selectWidth}
                                        select
                                        label="Форма повернення"
                                        value={0}
                                        onChange={(event)=>{
                                            
                                        }}
                                        variant="outlined"
                                    >
                                        <option disabled value={0}>Выберите вариант возврата средств</option>
                                        <option>Повернення на поточний рахунок</option>
                                    </Select>
                                </div>
                            </div>
                            <div className={styles.groupElem}>
                                <div>
                                    <TextField
                                        className={styles.selectWidth}
                                        id="outlined-multiline-static"
                                        label="МФО банку"
                                        value=''
                                        variant="outlined"
                                        onChange={(event)=>{
                                            
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={styles.groupElem}>
                                <div>
                                    <TextField
                                        className={styles.selectWidth}
                                        id="outlined-multiline-static"
                                        label="Номер рахунку / Номер карткового рахунку"
                                        value=''
                                        variant="outlined"
                                        onChange={(event)=>{
                                            
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={styles.profileTitle}>Юридична адреса</div>
                        <div className={styles.profile_elem}>
                            <div className={styles.groupElem}>
                                <div>
                                    <Select
                                        id="outlined-select-currency-native"
                                        className={styles.selectWidth}
                                        select
                                        label="Регіон / область"
                                        value={0}
                                        onChange={(event)=>{
                                            
                                        }}
                                        variant="outlined"
                                    >
                                        <option disabled value={0}>Выберите область</option>
                                        <option>м. Київ</option>
                                    </Select>
                                </div>
                            </div>
                            <div className={styles.groupElem}>
                                <div>
                                    <TextField
                                        className={styles.selectWidth}
                                        id="outlined-multiline-static"
                                        label="Населений пункт"
                                        value=''
                                        variant="outlined"
                                        onChange={(event)=>{
                                            
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={styles.groupElem}>
                                <div>
                                    <TextField
                                        className={styles.selectWidth}
                                        id="outlined-multiline-static"
                                        label="Адреса"
                                        value=''
                                        variant="outlined"
                                        onChange={(event)=>{
                                            
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={styles.groupElem}>
                                <div>
                                    <TextField
                                        className={styles.selectWidth}
                                        id="outlined-multiline-static"
                                        label="Поштовий індекс"
                                        value=''
                                        variant="outlined"
                                        onChange={(event)=>{
                                            
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={styles.profileTitle}>Контактна особа</div>
                        <div className={styles.profile_elem}>
                            <div className={styles.groupElem}>
                                <div>
                                    <TextField
                                        className={styles.selectWidth}
                                        id="outlined-multiline-static"
                                        label="Прізвище"
                                        value=''
                                        variant="outlined"
                                        onChange={(event)=>{
                                            
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={styles.groupElem}>
                                <div>
                                    <TextField
                                        className={styles.selectWidth}
                                        id="outlined-multiline-static"
                                        label="І'мя"
                                        value=''
                                        variant="outlined"
                                        onChange={(event)=>{
                                            
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={styles.groupElem}>
                                <div>
                                    <TextField
                                        className={styles.selectWidth}
                                        id="outlined-multiline-static"
                                        label="По-батькові"
                                        value=''
                                        variant="outlined"
                                        onChange={(event)=>{
                                            
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={styles.groupElem}>
                                <div>
                                    <TextField
                                        className={styles.selectWidth}
                                        id="outlined-multiline-static"
                                        label="Телефон"
                                        value=''
                                        variant="outlined"
                                        onChange={(event)=>{
                                            
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={styles.groupElem}>
                                <div>
                                    <TextField
                                        className={styles.selectWidth}
                                        id="outlined-multiline-static"
                                        label="Факс"
                                        value=''
                                        variant="outlined"
                                        onChange={(event)=>{
                                            
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={styles.groupElem}>
                                <div>
                                    <TextField
                                        className={styles.selectWidth}
                                        id="outlined-multiline-static"
                                        label="Моб.телефон"
                                        value=''
                                        variant="outlined"
                                        onChange={(event)=>{
                                            
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={styles.groupElem}>
                                <div>
                                    <TextField
                                        className={styles.selectWidth}
                                        id="outlined-multiline-static"
                                        label="Офіційний сайт"
                                        value=''
                                        variant="outlined"
                                        onChange={(event)=>{
                                            
                                        }}
                                    />
                                </div>
                            </div>


                            <div>
                                <div>Коротко про організацію</div>
                                <div>Вказана вами в цьому полі інформація буде доступна для публічного ознайомлення та виконуватиме презентаційну функцію профілю вашої організації</div>
                            </div>
                            <div>
                                <div>Логотип організації</div>
                                <div>Наявність логотипу організації значно підвищуе його відвідуваність</div>
                            </div>
                        </div>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={
                                () => {
                                    
                                }
                            }
                        >
                            Зберегти
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
    }
}
  
//const mapDispatchToProps = {}
  
export default connect(mapStateToProps, null)(Profile)