import React from 'react';
import styles from './protocolLink.module.css';

import {connect} from 'react-redux';

const ProtocolLink = (props) => {
    let tmpPDF = null
    let tmpHTML = null
    let tmpDOC = null

    if (props.auction !== null) {
        if(
            props.auction.status !== 'active_awarded' &&
            props.auction.status !== 'active_qualification' &&
            props.auction.status !== 'pending_payment' &&
            props.auction.status !== 'complete' &&
            props.auction.status !== 'cancelled' &&
            props.auction.status !== 'unsuccessful'
        ){
            return null
        }
    }

    return (
        <div className={styles.protocolBlock}>
            <h3 className={styles.protocolTitle}>Посилання на протокол аукціону</h3>
            <div className={styles.btnWrap}>
                <div className={styles.btnElem}>
                    <div
                        className={styles.colorLink}
                        onClick={()=>{
                            getViewDocuments(props, "Протокол.pdf", `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/auction/${props.auction.id}/protocol/download`)
                        }}
                    >
                        <div className={styles.protocolButton}>Потокол аукціону у форматі PDF</div> 
                    </div>
                </div>
                <div className={styles.btnElem}>
                    <div
                        className={styles.colorLink}
                        onClick={()=>{
                            getViewDocuments(props, "Протокол.doc", `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/auction/${props.auction.id}/protocol/doc`)
                        }}
                    >
                        <div className={styles.protocolButton}>Потокол аукціону у форматі WORD</div> 
                    </div>
                </div>
                <div className={styles.btnElem}>
                    <div
                        className={styles.colorLink}
                        onClick={()=>{
                            getViewDocuments(props, "Протокол.html", `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/auction/${props.auction.id}/protocol/print`)
                        }}
                    >
                        <div className={styles.protocolButton}>Потокол аукціону у форматі HTML</div> 
                    </div>
                </div>
            </div>
        </div>
    )
}


async function getViewDocuments(props, fileName, url){
    await cross_download(url, fileName, props)
    /*let header = null
    if(props.token !== null){
        header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }else{
        header = {
            'Content-Type': 'application/json'
        }
    }
    let endPoint = url
    const response = await fetch(endPoint, {
        method: 'GET',
        headers: header
    })
    .catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        let url = response.url
        await cross_download(url, fileName)
    }   */
}

function cross_download(url, fileName, props) {
    var req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "blob";
    var __fileName = fileName
    /*__fileName = res.Headers["Content-Disposition"] != null ?
                    res.Headers["Content-Disposition"].Replace("attachment; filename=", "").Replace("\"", "") :
                    res.Headers["Location"] != null ? Path.GetFileName(res.Headers["Location"]) : 
                    Path.GetFileName(url).Contains('?') || Path.GetFileName(url).Contains('=') ?
                    Path.GetFileName(res.ResponseUri.ToString()) : defaultFileName;*/
    req.onload = function (event) {
        var blob = req.response;
        var contentType = req.getResponseHeader("Content-type");
        if (window.navigator.msSaveOrOpenBlob) {
            // Internet Explorer
            window.navigator.msSaveOrOpenBlob(new Blob([blob], {type: contentType}), fileName);
        } else {
            //var downloading = browser.downloads.download
            var link = document.createElement('a');
            document.body.appendChild(link);
            link.download = __fileName;
            link.href = window.URL.createObjectURL(blob);
            link.click();
            document.body.removeChild(link); //remove the link when done
        }
    };
    req.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    if(props.token !== null){
        req.setRequestHeader('Authorization', props.token.access_token,);
    }
    req.send();
}



const mapStateToProps = state => {
    return {
        token: state.start.token,
        auction: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(ProtocolLink)
