import {
    HISTERY_AUCTIONS,
    HISTERY_AWARDS,
    HISTERY_CONTRACTS,
    HISTERY_BIDS,
} from "./types";

export function histeryAuctions(value) {
    return {
        type: HISTERY_AUCTIONS,
        payload: value
    }
}

export function histeryAwards(value) {
    return {
        type: HISTERY_AWARDS,
        payload: value
    }
}

export function histeryContrasts(value) {
    return {
        type: HISTERY_CONTRACTS,
        payload: value
    }
}

export function histeryBids(value) {
    return {
        type: HISTERY_BIDS,
        payload: value
    }
}