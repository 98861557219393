import React from 'react';

import {connect} from 'react-redux';

import {changeBidPopUpEdit} from '../../../../redux/actions/auctionBid/auctionBid.js';
import {changeBidAward, changeBidQuantity} from '../../../../redux/actions.js';

import BidEditPopUp from '../../../product/newBidLot/viewBid/bidEditNewPopup'
import {changeDocumentBidBlockStatus} from '../../../../redux/actions/documents/documents.js';

const BidError = ( props ) => {
    return <BidEditPopUp />
}



const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy,
        auctionAwards: state.start.auctionAwards,
        popUpAwardReject: state.start.popUpAwardReject,
        popUpAwardError: state.start.popUpAwardError,
        bidPopUpEdit: state.start.bidPopUpEdit,
    }
}

const mapDispatchToProps = {
    changeBidPopUpEdit,
    changeBidAward,
    changeDocumentBidBlockStatus,
    changeBidQuantity,
}

export default connect(mapStateToProps, mapDispatchToProps)(BidError)