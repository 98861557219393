import React from 'react';
import styles from './auctionDataLine_Description.module.css';

import {connect} from 'react-redux';

const AuctionDataLine_Description = ( props ) => {
    return <div className={styles.secondBlock_textPrice}>{viewDescription(props)}</div>
}

function viewDescription(props){
    let tmp = null;
    if(props.item.hasOwnProperty('tenderDescription') === true){
        tmp = props.item.tenderDescription
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
    }
}

export default connect(mapStateToProps, null)(AuctionDataLine_Description)