import React from 'react';
import styles from './imageAndButtonLine.module.css';

import {connect} from 'react-redux';

import ImageAndButtonLineLeft from './imageAndButtonLine_Left/imageAndButtonLine_Left.js'; 
import ImageAndButtonLineRight from './imageAndButtonLine_Right/imageAndButtonLine_Right.js';

const ImageAndButtonLine = ( props ) => {
    return (
        <div className={styles.threeBlock}>
            <ImageAndButtonLineLeft item={props.item} />
            <ImageAndButtonLineRight item={props.item} />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
    }
}

export default connect(mapStateToProps, null)(ImageAndButtonLine)