import React from 'react';

import TextHalf from '../textHalf'
import TextFull from '../textFull'

import { checkProp } from '../../../../../redux/check';


const Address = (props) => {
    let tmpAddresPostalCode = '';
    let tmpAddresCountryName = '';
    let tmpAddresRegion = '';
    let tmpAddresLlocality = '';
    let tmpAddresIstreetAddress = '';
    let codKTU = null;
    if(checkProp(props.data, 'address')){
        if(checkProp(props.data.address,'postalCode')){
            tmpAddresPostalCode = props.data.address.postalCode;
        }
        if(checkProp(props.data.address,'countryName')){
            tmpAddresCountryName = props.data.address.countryName.uk_UA;
        }
        if(checkProp(props.data.address,'region')){
            tmpAddresRegion = props.data.address.region.uk_UA;
        }
        if(checkProp(props.data.address,'locality')){
            tmpAddresLlocality = props.data.address.locality.uk_UA;
        }
        if(checkProp(props.data.address,'streetAddress')){
            tmpAddresIstreetAddress = props.data.address.streetAddress.uk_UA;
        }
        if(checkProp(props.data.address,'addressIdentifier')){
            if(checkProp(props.data.address.addressIdentifier,'identifier')){
                codKTU = props.data.address.addressIdentifier.identifier
            }
        }
    }

    let address = ''
    if(tmpAddresPostalCode !== '') address = address + tmpAddresPostalCode + ', '
    if(tmpAddresCountryName !== '') address = address + tmpAddresCountryName + ', '
    if(tmpAddresRegion !== '') address = address + tmpAddresRegion + ', '
    if(tmpAddresLlocality !== '') address = address + tmpAddresLlocality + ', '
    if(tmpAddresIstreetAddress !== '') address = address + tmpAddresIstreetAddress
    
    return (
        <TextHalf
            title={props.title}
            data={address}
        />
    )
}

export default Address