import React from 'react';
import styles from './bankRuptcy.module.css';

import { Link } from 'react-router-dom';

import {connect} from 'react-redux';

import {setAuctionCategory} from '../../../../redux/actions/support.js';
import {auctionsSearch, auctionsSearchСount, changeCurentPage} from '../../../../redux/actions/auctions/auctions.js';

import {changeMyCurentPage} from '../../../../redux/actions.js';
import {setDataAddress} from '../../../../redux/createAddress';

const Nadra = ( {langFlag, aucyionsPath, search, token, searchCount, auctionsSearch, auctionsSearchСount, setAuctionCategory, changeCurentPage, changeMyCurentPage, setDataAddress, clearSearch} ) => {
    let active = null;
    if(search.category === 'bankRuptcy'){
        active = styles.active;
    }
    return (
            <div
                className={styles.aukpoisk_second_elem}
            >
                <Link
                    onClick={
                        ()=>{
                            changeMyCurentPage(0)
                            setAuctionCategory('bankRuptcy-all');
                            changeCurentPage(0);
                            let dataSearch = {}
                            dataSearch.order = search.order
                            dataSearch.region = search.region
                            dataSearch.category = 'bankRuptcy-all'
                            dataSearch.priceCurrency = search.priceCurrency
                            dataSearch.priceFrom = search.priceFrom
                            dataSearch.priceTo = search.priceTo
                            dataSearch.measures = search.measures
                            dataSearch.measuresFrom = search.measuresFrom
                            dataSearch.measuresTo = search.measuresTo
                            if(search.status === null || search.status === ''){
                                dataSearch.status = null
                            }else{
                                dataSearch.status = search.status
                            }
                            let data = {
                                domen: process.env.REACT_APP_END_POINT_BD,
                                version: 'v1',
                                local: langFlag,
                                params: '?offset=0'
                            }
                            if(token !== null){
                                let header = {
                                    'Authorization': token.access_token,
                                    'Content-Type': 'application/json'
                                }
                                auctionsSearch(data, header, dataSearch);
                            }else{
                                let header = {
                                    'Content-Type': 'application/json'
                                }
                                auctionsSearch(data, header, dataSearch);
                            }
                            clearSearch()
                        }
                    }
                    to={`${aucyionsPath}/bankRuptcy${getUrlFiltersParam(search, 'bankRuptcy-all')}`}
                >
                    <div className={styles.base + ' ' + active}>
                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="300.000000pt" height="300.000000pt" viewBox="0 0 300.000000 300.000000" preserveAspectRatio="xMidYMid meet">
                            <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)" stroke="none">
                                <path d="M1350 2985 c-25 -6 -62 -19 -83 -28 -20 -9 -40 -17 -44 -19 -28 -12
                                -100 -92 -119 -133 -27 -57 -29 -72 -12 -129 16 -51 122 -162 169 -175 8 -2
                                39 -13 69 -24 42 -15 82 -20 165 -20 132 -1 138 1 240 49 108 50 183 147 180
                                230 -2 56 -45 125 -106 174 -28 22 -54 40 -59 40 -4 0 -25 8 -46 17 -59 25
                                -60 25 -189 28 -74 2 -137 -2 -165 -10z"/>
                                <path d="M1046 2383 c-11 -3 -103 -39 -205 -80 -102 -42 -190 -76 -196 -77 -5
                                -1 -20 -8 -32 -14 -13 -7 -23 -9 -23 -5 0 4 -4 3 -8 -3 -4 -6 -58 -33 -121
                                -60 -121 -51 -171 -84 -171 -114 0 -24 34 -100 142 -315 124 -248 131 -254
                                270 -248 104 5 98 8 210 -112 l83 -88 1 -501 c0 -276 3 -533 5 -573 5 -84 22
                                -113 93 -157 41 -26 58 -30 133 -33 48 -1 89 1 92 5 4 4 22 15 43 24 20 9 49
                                34 65 54 l28 37 1 441 c2 582 -4 516 43 516 l39 0 7 -115 c4 -66 2 -205 -5
                                -328 -7 -116 -10 -252 -7 -302 16 -256 16 -257 102 -300 50 -25 72 -30 136
                                -30 65 0 84 4 135 30 99 51 92 3 99 710 2 270 6 501 9 515 6 31 145 161 201
                                189 30 15 55 19 100 16 42 -2 72 2 101 15 40 17 45 25 167 269 70 138 127 260
                                127 272 0 35 -52 73 -172 124 -62 26 -115 51 -118 54 -3 3 -12 7 -20 8 -27 3
                                -345 135 -352 147 -4 6 -8 8 -8 4 0 -4 -24 1 -54 12 -49 17 -91 18 -487 18
                                -239 0 -443 -2 -453 -5z m485 -122 c4 -1 5 -19 3 -41 -2 -22 0 -40 4 -40 4 0
                                8 -19 9 -42 1 -24 4 -74 8 -113 4 -38 9 -83 10 -100 3 -34 15 -180 18 -209 2
                                -22 -91 -99 -111 -91 -6 2 -32 19 -56 37 l-45 33 6 60 c3 33 6 74 8 90 2 17 6
                                64 9 105 3 41 8 98 11 125 3 28 7 74 10 104 7 68 9 75 17 81 5 4 66 4 99 1z
                                m608 -186 c72 -30 135 -55 140 -55 5 0 14 -7 21 -15 7 -8 16 -12 21 -9 5 3 9
                                0 9 -5 0 -6 3 -10 8 -10 18 3 32 -2 32 -12 0 -6 -33 -84 -74 -172 -40 -89 -81
                                -186 -91 -215 -19 -58 -22 -60 -132 -92 l-63 -17 0 26 c0 14 -3 162 -6 329 -4
                                166 -4 302 -1 302 3 0 64 -25 136 -55z m-1143 -274 l0 -320 -41 6 c-22 3 -51
                                10 -65 16 -14 6 -29 9 -34 8 -4 -1 -14 3 -20 9 -7 5 -16 7 -19 3 -6 -5 -9 4
                                -7 20 2 13 -50 139 -105 252 -97 202 -96 178 -12 211 40 16 77 29 82 29 6 0
                                12 3 15 7 7 9 172 76 191 77 13 1 15 -35 15 -318z"/>
                            </g>
                        </svg>
                    </div>
                    <div className={styles.aukpoisk_second_elem_text}>Продаж майна боржників у справах про банкрутство</div>
                </Link>
                <div className={styles.aukpoisk_second_elem_1}>
                    <Link to={`${aucyionsPath}/bankRuptcy${getUrlFiltersParam(search, 'bankRuptcy-english')}`}>
                        <div
                            className={styles.subMenu}
                            onClick={
                                ()=>{
                                    changeMyCurentPage(0)
                                    setAuctionCategory('bankRuptcy-english');
                                    changeCurentPage(0);
                                    let dataSearch = {}
                                    dataSearch.order = search.order
                                    dataSearch.region = search.region
                                    dataSearch.category = 'bankRuptcy-english'
                                    dataSearch.priceCurrency = search.priceCurrency
                                    dataSearch.priceFrom = search.priceFrom
                                    dataSearch.priceTo = search.priceTo
                                    dataSearch.measures = search.measures
                                    dataSearch.measuresFrom = search.measuresFrom
                                    dataSearch.measuresTo = search.measuresTo
                                    if(search.status === null || search.status === ''){
                                        dataSearch.status = null
                                    }else{
                                        dataSearch.status = search.status
                                    }
                                    let data = {
                                        domen: process.env.REACT_APP_END_POINT_BD,
                                        version: 'v1',
                                        local: langFlag,
                                        params: '?offset=0'
                                    }
                                    if(token !== null){
                                        let header = {
                                            'Authorization': token.access_token,
                                            'Content-Type': 'application/json'
                                        }
                                        auctionsSearch(data, header, dataSearch);
                                    }else{
                                        let header = {
                                            'Content-Type': 'application/json'
                                        }
                                        auctionsSearch(data, header, dataSearch);
                                    }
                                    clearSearch()
                                }
                            }
                        >
                            Продаж майна боржників у справах про банкрутство, Англійський аукціон
                        </div>
                    </Link>
                    <Link to={`${aucyionsPath}/bankRuptcy${getUrlFiltersParam(search, 'bankRuptcy-dutch')}`}>
                        <div
                            className={styles.subMenu}
                            onClick={
                                ()=>{
                                    changeMyCurentPage(0)
                                    setAuctionCategory('bankRuptcy-dutch');
                                    changeCurentPage(0);
                                    let dataSearch = {}
                                    dataSearch.order = search.order
                                    dataSearch.region = search.region
                                    dataSearch.category = 'bankRuptcy-dutch'
                                    dataSearch.priceCurrency = search.priceCurrency
                                    dataSearch.priceFrom = search.priceFrom
                                    dataSearch.priceTo = search.priceTo
                                    dataSearch.measures = search.measures
                                    dataSearch.measuresFrom = search.measuresFrom
                                    dataSearch.measuresTo = search.measuresTo
                                    if(search.status === null || search.status === ''){
                                        dataSearch.status = null
                                    }else{
                                        dataSearch.status = search.status
                                    }
                                    let data = {
                                        domen: process.env.REACT_APP_END_POINT_BD,
                                        version: 'v1',
                                        local: langFlag,
                                        params: '?offset=0'
                                    }
                                    if(token !== null){
                                        let header = {
                                            'Authorization': token.access_token,
                                            'Content-Type': 'application/json'
                                        }
                                        auctionsSearch(data, header, dataSearch);
                                    }else{
                                        let header = {
                                            'Content-Type': 'application/json'
                                        }
                                        auctionsSearch(data, header, dataSearch);
                                    }
                                    clearSearch()
                                }
                            }
                        >
                            Продаж майна боржників у справах про банкрутство, Голландський аукціон
                        </div>
                    </Link>
                    <Link to={`${aucyionsPath}/bankRuptcy${getUrlFiltersParam(search, 'bankRuptcy-withoutAuction')}`}>
                        <div
                            className={styles.subMenu}
                            onClick={
                                ()=>{
                                    changeMyCurentPage(0)
                                    setAuctionCategory('bankRuptcy-withoutAuction');
                                    changeCurentPage(0);
                                    let dataSearch = {}
                                    dataSearch.order = search.order
                                    dataSearch.region = search.region
                                    dataSearch.category = 'bankRuptcy-withoutAuction'
                                    dataSearch.priceCurrency = search.priceCurrency
                                    dataSearch.priceFrom = search.priceFrom
                                    dataSearch.priceTo = search.priceTo
                                    dataSearch.measures = search.measures
                                    dataSearch.measuresFrom = search.measuresFrom
                                    dataSearch.measuresTo = search.measuresTo
                                    if(search.status === null || search.status === ''){
                                        dataSearch.status = null
                                    }else{
                                        dataSearch.status = search.status
                                    }
                                    let data = {
                                        domen: process.env.REACT_APP_END_POINT_BD,
                                        version: 'v1',
                                        local: langFlag,
                                        params: '?offset=0'
                                    }
                                    if(token !== null){
                                        let header = {
                                            'Authorization': token.access_token,
                                            'Content-Type': 'application/json'
                                        }
                                        auctionsSearch(data, header, dataSearch);
                                    }else{
                                        let header = {
                                            'Content-Type': 'application/json'
                                        }
                                        auctionsSearch(data, header, dataSearch);
                                    }
                                    clearSearch()
                                }
                            }
                        >
                            Продаж майна боржників у справах про банкрутство без аукціону
                        </div>
                    </Link>
                </div>
            </div>
    )
}

function getUrlFiltersParam(search, type){
    let tmp = null
    if(search.orderBy !== null){
        if(tmp === null){
            tmp = '?orderBy=' + search.orderBy
        }else{
            tmp += '&orderBy=' + search.orderBy
        }
    }
    if(search.status !== null){
        if(tmp === null){
            tmp = '?status=' + search.status
        }else{
            tmp += '&status=' + search.status
        }
    }
    if(search.itemType !== null){
        if(tmp === null){
            tmp = '?itemType=land'
        }else{
            tmp += '&itemType=land'
        }
    }
    if(search.region !== null){
        if(tmp === null){
            tmp = '?region=' + search.region
        }else{
            tmp += '&region=' + search.region
        }
    }
    //if(search.category !== null){
        if(tmp === null){
            tmp = `?category=${type}`
        }else{
            tmp += `&category=${type}`
        }
    //}
    if(search.priceCurrency !== null){
        if(tmp === null){
            tmp = '?priceCurrency=' + search.priceCurrency
        }else{
            tmp += '&priceCurrency=' + search.priceCurrency
        }
    }
    if(search.priceFrom !== null){
        if(tmp === null){
            tmp = '?priceFrom=' + search.priceFrom
        }else{
            tmp += '&priceFrom=' + search.priceFrom
        }
    }
    if(search.priceFromBag !== false){
        if(tmp === null){
            tmp = '?priceFromBag=true'
        }else{
            tmp += '&priceFromBag=true'
        }
    }
    if(search.priceTo !== null){
        if(tmp === null){
            tmp = '?priceTo=' + search.priceTo
        }else{
            tmp += '&priceTo=' + search.priceTo
        }
    }
    if(search.priceToBag !== false){
        if(tmp === null){
            tmp = '?priceToBag=true'
        }else{
            tmp += '&priceToBag=true'
        }
    }
    if(search.measures !== null){
        if(tmp === null){
            tmp = '?measures=' + search.measures
        }else{
            tmp += '&measures=' + search.measures
        }
    }
    if(search.measuresFrom !== null){
        if(tmp === null){
            tmp = '?measuresFrom=' + search.measuresFrom
        }else{
            tmp += '&measuresFrom=' + search.measuresFrom
        }
    }
    if(search.measuresTo !== null){
        if(tmp === null){
            tmp = '?measuresTo=' + search.measuresTo
        }else{
            tmp += '&measuresTo=' + search.measuresTo
        }
    }
    if(search.textSting !== null){
        if(tmp === null){
            tmp = '?keyword=' + search.textSting
        }else{
            tmp += '&keyword=' + search.textSting
        }
    }
    if(search.selectTypeKeyword !== null){
        if(tmp === null){
            tmp = '?selectTypeKeyword=' + search.selectTypeKeyword
        }else{
            tmp += '&selectTypeKeyword=' + search.selectTypeKeyword
        }
    }
    if(tmp !== null){
        return tmp
    }
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        aucyionsPath: state.start.aucyionsPath,
        search: state.start.search,
        token: state.start.token,
        searchCount: state.start.searchCount,
    }
}

const mapDispatchToProps = {
    auctionsSearch,
    auctionsSearchСount,
    setAuctionCategory,
    changeCurentPage,
    changeMyCurentPage,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(Nadra)