import React from 'react';
import styles from './informationAboutTheOrganizer.module.css';

import {connect} from 'react-redux';

const DebtorInformation = (props) => {
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('debtorInformation') === true){
            if(props.auctionId.debtorInformation !== null){
                return (
                    <div className={styles.infoBlock}>
                        <h3>Інформація про боржника</h3>
                        <div className={styles.orgTitle}>
                            <p className={styles.desc}>Повна юридична назва організації або ПІБ</p>
                            <p>{contactName(props)}</p>
                        </div>
                        <h3>Ідентифікатори організації або особи</h3>
                        <div className={styles.orgId}>
                            <p className={styles.desc}>Ідентифікатори організації</p>
                            <p>{contactScheme(props)}</p>
                        </div>
                        <div className={styles.orgId}>
                            <p className={styles.desc}>Код ЄДРПОУ або ІПН або паспорт</p>
                            <p>{contactEDRPOU(props)}</p>
                        </div>
                        <div className={styles.orgId}>
                            <p className={styles.desc}>Повна юридична назва організації</p>
                            <p>{contactLegalName(props)}</p>
                        </div>
                        <div className={styles.orgAdress}>
                            <p className={styles.desc}>Місцезнаходження Організатора аукціону</p>
                            <p>{contactAddress(props)}</p>
                        </div>
                        {/*<div className={styles.orgTitle}>
                            <p className={styles.desc}>Інформація щодо підтвердження повноважень</p>
                            <p>{representativeInfo(props)}</p>
                        </div>*/}
                        <div className={styles.orgContact}>
                            <p className={styles.desc}>Контактна особа</p>
                            <p>{contactPoint(props, 1)}</p>
                            <p>{contactPoint(props, 2)}</p>
                        </div>
                    </div>
                )
            }
        }
    }
    return null
}

function contactName(props){
    let tmp = ''
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('debtorInformation') === true){
            if(props.auctionId.debtorInformation !== null){
                if(props.auctionId.debtorInformation.hasOwnProperty('name')){
                    if(props.auctionId.debtorInformation.name !== null){
                        tmp += props.auctionId.debtorInformation.name.uk_UA
                    }
                }
            }
        }
    }
    return tmp
}
function representativeInfo(props){
    let tmp = ''
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('debtorInformation') === true){
            if(props.auctionId.debtorInformation !== null){
                if(props.auctionId.debtorInformation.hasOwnProperty('representativeInfo')){
                    if(props.auctionId.debtorInformation.representativeInfo !== null){
                        tmp += props.auctionId.debtorInformation.representativeInfo
                    }
                }
            }
        }
    }
    return tmp
}

function contactPoint(props, flag){
    let tmp = ''
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('debtorInformation') === true){
            if(props.auctionId.debtorInformation !== null){
                if(props.auctionId.debtorInformation.hasOwnProperty('contactPoint')){
                    if(props.auctionId.debtorInformation.contactPoint !== null){
                        if(flag === 1){
                            if(props.auctionId.debtorInformation.contactPoint.hasOwnProperty('name')){
                                if(props.auctionId.debtorInformation.contactPoint.name !== null){
                                    tmp += props.auctionId.debtorInformation.contactPoint.name.uk_UA + ', '
                                }
                            }
                        }else if(flag === 2){
                            if(props.auctionId.debtorInformation.contactPoint.hasOwnProperty('email')){
                                if(props.auctionId.debtorInformation.contactPoint.email !== null){
                                    tmp += props.auctionId.debtorInformation.contactPoint.email + ', '
                                }
                            }
                            if(props.auctionId.debtorInformation.contactPoint.hasOwnProperty('telephone')){
                                if(props.auctionId.debtorInformation.contactPoint.telephone !== null){
                                    tmp += props.auctionId.debtorInformation.contactPoint.telephone + ', '
                                }
                            }
                            if(props.auctionId.debtorInformation.contactPoint.hasOwnProperty('faxNumber')){
                                if(props.auctionId.debtorInformation.contactPoint.faxNumber !== null){
                                    tmp += props.auctionId.debtorInformation.contactPoint.faxNumber + ', '
                                }
                            }
                            if(props.auctionId.debtorInformation.contactPoint.hasOwnProperty('url')){
                                if(props.auctionId.debtorInformation.contactPoint.url !== null){
                                    tmp += props.auctionId.debtorInformation.contactPoint.url + ', '
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return tmp
}

function contactScheme(props){
    let tmp = ''
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('debtorInformation')){
            if(props.auctionId.debtorInformation !== null){
                if(props.auctionId.debtorInformation.hasOwnProperty('identifier')){
                    if(props.auctionId.debtorInformation.identifier !== null){
                        if(props.auctionId.debtorInformation.identifier.hasOwnProperty('scheme')){
                            if(props.auctionId.debtorInformation.identifier.scheme !== null){
                                tmp += props.auctionId.debtorInformation.identifier.scheme
                            }
                        }
                    }
                }
            }
        }
    }
    return tmp
}
function contactEDRPOU(props){
    let tmp = ''
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('debtorInformation')){
            if(props.auctionId.debtorInformation !== null){
                if(props.auctionId.debtorInformation.hasOwnProperty('identifier')){
                    if(props.auctionId.debtorInformation.identifier !== null){
                        if(props.auctionId.debtorInformation.identifier.hasOwnProperty('id')){
                            if(props.auctionId.debtorInformation.identifier.id !== null){
                                tmp += props.auctionId.debtorInformation.identifier.id
                            }
                        }
                    }
                }
            }
        }
    }
    return tmp
}
function contactLegalName(props){
    let tmp = ''
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('debtorInformation')){
            if(props.auctionId.debtorInformation !== null){
                if(props.auctionId.debtorInformation.hasOwnProperty('identifier')){
                    if(props.auctionId.debtorInformation.identifier !== null){
                        if(props.auctionId.debtorInformation.identifier.hasOwnProperty('legalName')){
                            if(props.auctionId.debtorInformation.identifier.legalName !== null){
                                if(props.auctionId.debtorInformation.identifier.legalName.hasOwnProperty('uk_UA')){
                                    if(props.auctionId.debtorInformation.identifier.legalName.uk_UA !== null){
                                        tmp += props.auctionId.debtorInformation.identifier.legalName.uk_UA
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return tmp
}

function contactAddress(props){
    let tmp = ''
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('debtorInformation')){
            if(props.auctionId.debtorInformation !== null){
                if(props.auctionId.debtorInformation.hasOwnProperty('address')){
                    if(props.auctionId.debtorInformation.address !== null){
                        if(props.auctionId.debtorInformation.address.hasOwnProperty('postalCode')){
                            if(props.auctionId.debtorInformation.address.postalCode !== null){
                                tmp += props.auctionId.debtorInformation.address.postalCode + ', '
                            }
                        }
                        if(props.auctionId.debtorInformation.address.hasOwnProperty('countryName')){
                            if(props.auctionId.debtorInformation.address.countryName !== null){
                                tmp += props.auctionId.debtorInformation.address.countryName.uk_UA + ', '
                            }
                        }
                        if(props.auctionId.debtorInformation.address.hasOwnProperty('region')){
                            if(props.auctionId.debtorInformation.address.region !== null){
                                tmp += props.auctionId.debtorInformation.address.region.uk_UA + ', '
                            }
                        }
                        if(props.auctionId.debtorInformation.address.hasOwnProperty('locality')){
                            if(props.auctionId.debtorInformation.address.locality !== null){
                                tmp += props.auctionId.debtorInformation.address.locality.uk_UA + ', '
                            }
                        }
                        if(props.auctionId.debtorInformation.address.hasOwnProperty('streetAddress')){
                            if(props.auctionId.debtorInformation.address.streetAddress !== null){
                                tmp += props.auctionId.debtorInformation.address.streetAddress.uk_UA
                            }
                        }                                    
                    }
                }
            }
        }
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
        userProfiles: state.start.userProfiles
    }
}

export default connect(mapStateToProps, null)(DebtorInformation)
