import React from 'react';
import styles from './warehouseComposition.module.css';

import {connect} from 'react-redux';

import TextHalf from './textHalf'
import TextFull from './textFull'
import TextFullHeader from './textFullHeader'
import {modifiDateString} from '../../../../redux/modifiDate.js'

import ZuMap from '../../../map/zuMapsNotEdit'

const StructureLot = (props) => {
    let tmp = null;
    if(props.auction !== null){
        if(props.auction.items.length > 0){
            tmp = props.auction.items.map(
                (i, count) => {
                    let tmpZuMap = null
                    if(i.hasOwnProperty('location') === true){
                        if(i.location !== null){
                            if((i.location.latitude !== null && i.location.latitude !== '')  && (i.location.longitude !== null && i.location.longitude !== '')){
                                tmpZuMap = <ZuMap
                                latitude = {i.location.latitude}
                                longitude = {i.location.longitude}
                                />
                            }
                        }
                    }
                    return (
                        <>
                            {viewTypeAuction(i, count)}
                            {tmpZuMap}
                        </>
                    )
                }
            )
        }else{
            tmp = <div className={styles.documentElem + ' ' + styles.scladLotuElem}>Додані товари відсутні</div>
        }
    }
    

    return (
        <div className={styles.infoBlock}>
            <h3>СКЛАД ЛОТУ</h3>
            {tmp}
        </div>
    )
}

function viewTypeAuction(data, count){
    let tmpAddresPostalCode = '';
    let tmpAddresCountryName = '';
    let tmpAddresRegion = '';
    let tmpAddresLlocality = '';
    let tmpAddresIstreetAddress = '';
    let codKTU = null;
    if(data.hasOwnProperty('address') === true){
        if(data.address !== null){
            if(data.address.hasOwnProperty('postalCode') === true){
                if(data.address.postalCode !== null) tmpAddresPostalCode = data.address.postalCode;
            }
            if(data.address.hasOwnProperty('countryName') === true){
                if(data.address.countryName !== null) tmpAddresCountryName = data.address.countryName.uk_UA;
            }
            if(data.address.hasOwnProperty('region') === true){
                if(data.address.region !== null) tmpAddresRegion = data.address.region.uk_UA;
            }
            if(data.address.hasOwnProperty('locality') === true){
                if(data.address.locality !== null) tmpAddresLlocality = data.address.locality.uk_UA;
            }
            if(data.address.hasOwnProperty('streetAddress') === true){
                if(data.address.streetAddress !== null) tmpAddresIstreetAddress = data.address.streetAddress.uk_UA;
            }
            if(data.address.hasOwnProperty('addressIdentifier') === true){
                if(data.address.addressIdentifier !== null){
                    if(data.address.addressIdentifier.hasOwnProperty('identifier') === true){
                        if(data.address.addressIdentifier.identifier !== null){
                            codKTU = data.address.addressIdentifier.identifier
                        }
                    }
                }
            }
        }
    }

    let tmpClassificationScheme = null
    let tmpClassificationId = null
    let tmpClassificationDescription = null
    if(data.classification !== null){
        if(data.classification.hasOwnProperty('description')){
            tmpClassificationScheme = data.classification.scheme
            tmpClassificationId = data.classification.id
            tmpClassificationDescription = data.classification.description.uk_UA
        }
    }
    let address = ''// `${tmpAddresPostalCode}, ${tmpAddresCountryName}, ${tmpAddresRegion}, ${tmpAddresLlocality}, ${tmpAddresIstreetAddress}`
    if(tmpAddresPostalCode !== '') address = address + tmpAddresPostalCode + ', '
    if(tmpAddresCountryName !== '') address = address + tmpAddresCountryName + ', '
    if(tmpAddresRegion !== '') address = address + tmpAddresRegion + ', '
    if(tmpAddresLlocality !== '') address = address + tmpAddresLlocality + ', '
    if(tmpAddresIstreetAddress !== '') address = address + tmpAddresIstreetAddress
    let tmpClassificationNameStr = `Класифікатор об'єкта ${tmpClassificationScheme}`
    let tmpClassificationSchemeStr = `${tmpClassificationId} - ${tmpClassificationDescription}`
    return (
        <>
            <div className={styles.borderLine}>
            {/*<TextFull
                title="Назва об'єкта"
                data={data.title}
            />*/}
            </div>
            <TextFull
                title="Опис об'єкта"
                data={data.description.uk_UA}
            />
            <TextFull
                title={tmpClassificationNameStr}
                data={tmpClassificationSchemeStr}
            />
            {AddClassificationScheme(data)}
            <TextHalf
                title='Місцезнаходження'
                data={address}
            />
            <TextHalf
                title='КОАТУУ'
                data={codKTU}
            />
            {zuRegistrationDetails(data)}
            <TextHalf
                title="Тип об'єкта майна"
                data={strTypeObject(data.itemType)}
            />
            <TextHalf
                title="Кількість одиниць"
                data={data.quantity}
            />
            {unitName(data)}
            {zuItemType(data)}
            {dgfLocation(data)}
        </>
    )
}

function AddClassificationScheme(data){
    if(data !== null){
        if(data.hasOwnProperty('additionalClassifications') === true){
            if(data.additionalClassifications !== null){
                if(data.additionalClassifications.length > 0){
                    return data.additionalClassifications.map(
                        (e) => {
                            let tmpAdd = e.scheme + ' ' + e.id + ' - ' + e.description.uk_UA
                            return <TextHalf
                                title="Додатковий класифікатор об'єкта"
                                data={tmpAdd}
                            />
                        }
                    )
                }
            }
        }
    }
    return null
}

function unitName(data){
    if(data !== null){
        if(data.unit !== null){
            if(data.unit.name !== null){
                return <TextHalf
                    title="Одиниці виміру"
                    data={data.unit.name.uk_UA}
                />
            }
        }
    }
    return null
}

function strTypeObject(data){
    if(data === 'otherProperty'){
        return "Інше окреме індивідуально визначене майно"
    }else if(data === 'jointPropertyComplex'){
        return "ЄМК"
    }else if(data === "vehicle"){
        return "Транспортний засіб"
    }else if(data === 'realEstate'){
        return "Нерухоме майно"
    }
}

function zuRegistrationDetails(data){
    if(data !== null){
        if(data.hasOwnProperty('registrationDetails') === true){
            if(data.registrationDetails !== null){
                let tmp = null
                if(data.registrationDetails.hasOwnProperty('registrationDate')
                    && data.registrationDetails.registrationDate !== null){
                    tmp = modifiDateString(data.registrationDetails.registrationDate, 1)
                }
                
                return <>
                    <TextFullHeader
                        title="Інформація щодо реєстрації:"
                        data=''
                    />
                    <TextHalf
                        title="Стан державної реєстрації об'єкту"
                        data={strRegistrationStatus(data.registrationDetails.registrationStatus)}
                    />
                    {data.registrationDetails.hasOwnProperty('registrationID') && data.registrationDetails.registrationID !== null 
                        ? <TextHalf
                            title="Номер запису"
                            data={data.registrationDetails.registrationID}
                        />
                        : null
                    }
                    {tmp !== null
                        ?<TextHalf
                            title="Дата реєстрації"
                            data={tmp}
                        />
                        : null
                    }
                </>
            }
        }
    }
    return null
}
function strRegistrationStatus(data){
    if(data === 'notRegistered'){
        return 'Не зареєстровано'
    }else if(data === 'oldRegistration'){
        return 'Зареєстровано до 1 січня 2013 р. відповідно до законодавства, що діяло на момент виникнення речового права на майно'
    }else if(data === 'properlyRegistered'){
        return 'Зареєстровано в Державному реєстрі речових прав на нерухоме майно'
    }else if(data === 'registrationIsNotRequired'){
        return 'Реєстрація не вимагається'
    }
}

function dgfLocation(data){
    if(data !== null){
        if(data.hasOwnProperty('location') === true){
            if(data.location !== null){
                if(data.hasOwnProperty('location') === true){
                    return <>
                        <TextFullHeader
                            title="Географічні координати місця розташування:"
                            data=''
                        />
                        <TextHalf
                            title="Широта"
                            data={data.location.latitude}
                        />
                        <TextHalf
                            title="Довгота"
                            data={data.location.longitude}
                        />
                        {/*<TextHalf
                            title="Висота"
                            data={data.elevation}
                        />*/}
                    </>
                }
            }
        }
    }
    return null
}

function zuItemType(data){
    if(data.itemType === "otherProperty") return null
    if(data.itemType === 'jointPropertyComplex') return dgfJointPropertyComplexProps(data.jpcProps)
    if(data.itemType === 'vehicle') return dgfVehicleProps(data.vehicleProps)
    if(data.itemType === 'realEstate') return dgfRealEstateProps(data.reProps)
}

function dgfRealEstateProps(data){
    if(data !== null){
        return <>
            <TextFull
                title="Технічні характеристики, нерухомість:"
                data=''
            />
            {data.hasOwnProperty('totalBuildingArea') === true && data.totalBuildingArea !== null  && data.totalBuildingArea !== '' && data.totalBuildingArea !== 0
                ? <TextHalf
                    title="Загальна площа будівлі, кв.м."
                    data={data.totalBuildingArea}
                />
                : null
            }
            {data.hasOwnProperty('totalObjectArea') === true && data.totalObjectArea !== null && data.totalObjectArea !== '' && data.totalObjectArea !== 0
                ? <TextHalf
                    title="Загальна площа об'єкта в будівлі, кв.м."
                    data={data.totalObjectArea}
                />
                : null
            }
            {data.hasOwnProperty('usableArea') === true && data.usableArea !== null && data.usableArea !== '' && data.usableArea !== 0
                ? <TextHalf
                    title="Корисна площа об'єкта в будівлі, кв.м."
                    data={data.usableArea}
                />
                : null
            }
            {data.hasOwnProperty('constructionYear') === true && data.constructionYear !== null && data.constructionYear !== '' && data.constructionYear !== 0
                ? <TextHalf
                    title="Рік будівництва"
                    data={data.constructionYear}
                />
                : null
            }
            {strConstructionTechnology(data)}
            {data.hasOwnProperty('livingArea') === true && data.livingArea !== null && data.livingArea !== '' && data.livingArea !== 0
                ? <TextHalf
                    title="Житлова площа, кв. м."
                    data={data.livingArea}
                />
                : null
            }
            {data.hasOwnProperty('kitchenArea') === true && data.kitchenArea !== null && data.kitchenArea !== '' && data.kitchenArea !== 0
                ? <TextHalf
                    title="Площа кухні, кв. м."
                    data={data.kitchenArea}
                />
                : null
            }
            {data.hasOwnProperty('landArea') === true && data.landArea !== null && data.landArea !== '' && data.landArea !== 0
                ? <TextHalf
                    title="Площа ділянки, кв. м."
                    data={data.landArea}
                />
                : null
            }
            {strLocationInBuilding(data)}
            {data.hasOwnProperty('floors') === true && data.floors !== null && data.floors !== '' && data.floors !== 0
                ? <TextHalf
                    title="Номер поверху або поверхів"
                    data={data.floors}
                />
                : null
            }
            {data.hasOwnProperty('generalCondition') === true && data.generalCondition !== null && data.generalCondition !== '' && data.generalCondition !== 0
                ? <TextHalf
                    title="Технічний стан об'єкта"
                    data={data.generalCondition}
                />
                : null
            }
            {data.hasOwnProperty('serviceElectricity') === true && data.serviceElectricity !== null && data.serviceElectricity !== '' && data.serviceElectricity !== 0
                ? <TextHalf
                    title="Чи приєднаний об'єкт до електромережі"
                    data={viewTrueOrFalse(data.serviceElectricity)}
                />
                : null
            }
            {data.hasOwnProperty('powerSupplyCapacity') === true && data.powerSupplyCapacity !== null && data.powerSupplyCapacity !== '' && data.powerSupplyCapacity !== 0
                ? <TextHalf
                    title="Потужність електромережі, кВт"
                    data={data.powerSupplyCapacity}
                />
                : null
            }
            {strPowerSupplyClass(data)}
            {data.hasOwnProperty('serviceWater') === true && data.serviceWater !== null && data.serviceWater !== '' && data.serviceWater !== 0
                ? <TextHalf
                    title="Водозабезпечення присутнє"
                    data={viewTrueOrFalse(data.serviceWater)}
                />
                : null
            }
            {data.hasOwnProperty('serviceSewerage') === true && data.serviceSewerage !== null && data.serviceSewerage !== '' && data.serviceSewerage !== 0
                ? <TextHalf
                    title="Каналізація присутня"
                    data={viewTrueOrFalse(data.serviceSewerage)}
                />
                : null
            }
            {data.hasOwnProperty('serviceGas') === true && data.serviceGas !== null && data.serviceGas !== '' && data.serviceGas !== 0
                ? <TextHalf
                    title="Газифікація присутня"
                    data={viewTrueOrFalse(data.serviceGas)}
                />
                : null
            }
            {data.hasOwnProperty('serviceCentralHeating') === true && data.serviceCentralHeating !== null && data.serviceCentralHeating !== '' && data.serviceCentralHeating !== 0
                ? <TextHalf
                    title="Централізоване опалення присутнє"
                    data={viewTrueOrFalse(data.serviceCentralHeating)}
                />
                : null
            }
            {data.hasOwnProperty('serviceAutonomousHeating') === true && data.serviceAutonomousHeating !== null && data.serviceAutonomousHeating !== '' && data.serviceAutonomousHeating !== 0
                ? <TextHalf
                    title="Автономне опалення присутнє"
                    data={viewTrueOrFalse(data.serviceAutonomousHeating)}
                />
                : null
            }
            {data.hasOwnProperty('serviceHeatingCounter') === true && data.serviceHeatingCounter !== null && data.serviceHeatingCounter !== '' && data.serviceHeatingCounter !== 0
                ? <TextHalf
                    title="Лічильник опалення присутній"
                    data={viewTrueOrFalse(data.serviceHeatingCounter)}
                />
                : null
            }
            {data.hasOwnProperty('serviceVentilation') === true && data.serviceVentilation !== null && data.serviceVentilation !== '' && data.serviceVentilation !== 0
                ? <TextHalf
                    title="Вентиляція присутня"
                    data={viewTrueOrFalse(data.serviceVentilation)}
                />
                : null
            }
            {data.hasOwnProperty('serviceAirConditioning') === true && data.serviceAirConditioning !== null && data.serviceAirConditioning !== '' && data.serviceAirConditioning !== 0
                ? <TextHalf
                    title="Кондиціонування присутнє"
                    data={viewTrueOrFalse(data.serviceAirConditioning)}
                />
                : null
            }
            {data.hasOwnProperty('servicePhone') === true && data.servicePhone !== null && data.servicePhone !== '' && data.servicePhone !== 0
                ? <TextHalf
                    title="Телефонізація присутня"
                    data={viewTrueOrFalse(data.servicePhone)}
                />
                : null
            }
            {data.hasOwnProperty('serviceTV') === true && data.serviceTV !== null && data.serviceTV !== '' && data.serviceTV !== 0
                ? <TextHalf
                    title="Телебачення присутнє"
                    data={viewTrueOrFalse(data.serviceTV)}
                />
                : null
            }
            {data.hasOwnProperty('serviceInternet') === true && data.serviceInternet !== null && data.serviceInternet !== '' && data.serviceInternet !== 0
                ? <TextHalf
                    title="Інтернет присутній"
                    data={viewTrueOrFalse(data.serviceInternet)}
                />
                : null
            }
            {data.hasOwnProperty('serviceElevator') === true && data.serviceElevator !== null && data.serviceElevator !== '' && data.serviceElevator !== 0
                ? <TextHalf
                    title="Ліфт присутній"
                    data={viewTrueOrFalse(data.serviceElevator)}
                />
                : null
            }
            {data.hasOwnProperty('serviceSecurityAlarm') === true && data.serviceSecurityAlarm !== null && data.serviceSecurityAlarm !== '' && data.serviceSecurityAlarm !== 0
                ? <TextHalf
                    title="Охоронна сигналізація присутня"
                    data={viewTrueOrFalse(data.serviceSecurityAlarm)}
                />
                : null
            }
            {data.hasOwnProperty('serviceFireAlarm') === true && data.serviceFireAlarm !== null && data.serviceFireAlarm !== '' && data.serviceFireAlarm !== 0
                ? <TextHalf
                    title="Пожежна сигналізація присутня"
                    data={viewTrueOrFalse(data.serviceFireAlarm)}
                />
                : null
            }
            {data.hasOwnProperty('servicesDescription') === true && data.servicesDescription !== null && data.servicesDescription !== '' && data.servicesDescription !== 0
                ? <TextHalf
                    title="Додаткова інформація щодо комунікацій, що є в об'єкті"
                    data={data.servicesDescription}
                />
                : null
            }
            {data.hasOwnProperty('servicesAccounting') === true && data.servicesAccounting !== null && data.servicesAccounting !== '' && data.servicesAccounting !== 0
                ? <TextHalf
                    title="Інформація про наявність окремих особових рахунків на об'єкт, відкритих постачальниками комунальних послуг"
                    data={data.servicesAccounting}
                />
                : null
            }
            {data.hasOwnProperty('landTax') === true && data.landTax !== null && data.landTax !== '' && data.landTax !== 0
                ? <TextHalf
                    title="Інформація щодо компенсації сплати земельного податку за користування земельною ділянкою, на якій розташований об'єкт"
                    data={data.landTax}
                />
                : null
            }
        </>
    }
    return null
}
function strConstructionTechnology(data){
    if(data.hasOwnProperty("constructionTechnology") !== true) return null
    if(data.constructionTechnology === null) return null
    if(data.constructionTechnology === '') return null
    if(data.constructionTechnology === 0) return null
    if(data.constructionTechnology !== null){
        let tmp = ''
        if(data.constructionTechnology === 'brick'){
            tmp = 'Цегла'
        }else if(data.constructionTechnology === 'insulatedPanel'){
            tmp = 'Утеплена панель'
        }else if(data.constructionTechnology === 'monolithicFrame'){
            tmp = 'Монолітно-каркасна'
        }else if(data.constructionTechnology === 'other'){
            tmp = 'Iнше'
        }else if(data.constructionTechnology === 'panel'){
            tmp = 'Панель'
        }
        return (
            <TextHalf
                title="Технологія будівництва"
                data={tmp}
            />
        )
    }
}
function strLocationInBuilding(data){
    if(data.hasOwnProperty("locationInBuilding") !== true) return null
    if(data.locationInBuilding === null) return null
    if(data.locationInBuilding === "") return null
    if(data.locationInBuilding === 0) return null
    if(data.locationInBuilding !== null){
        let tmp = ''
        if(data.locationInBuilding === 'aboveGround'){
            tmp = 'Надземний'
        }else if(data.locationInBuilding === 'attic'){
            tmp = 'Мансардний'
        }else if(data.locationInBuilding === 'basement'){
            tmp = 'Підвальний'
        }else if(data.locationInBuilding === 'pedimental'){
            tmp = 'Цокольний'
        }else if(data.locationInBuilding === 'technical'){
            tmp = 'Технічний'
        }
        return (
            <TextHalf
                title="Місце розташування об’єкта в будівлі"
                data={tmp}
            />
        )
    }
}
function strPowerSupplyClass(data){
    if(data.hasOwnProperty('powerSupplyClass') !== true) return null
    if(data.powerSupplyClass === null) return null
    if(data.powerSupplyClass === '') return null
    if(data.powerSupplyClass === 0) return null
    if(data.powerSupplyClass !== null){
        let tmp = ''
        if(data.powerSupplyClass === 'first'){
            tmp = 'Перший ступінь'
        }else if(data.powerSupplyClass === 'over50'){
            tmp = 'Понад 50 кВт'
        }else if(data.powerSupplyClass === 'second'){
            tmp = 'Другий ступінь'
        }
        return (
            <TextHalf
                title="Ступінь потужності електромережі"
                data={tmp}
            />
        )
    }
}

function dgfJointPropertyComplexProps(data){
    if(data === null) return null
    return <>
        <TextFull
            title="Технічні характеристики, єдиний майновий комплекс:"
            data=''
        />
        {data.hasOwnProperty('quantityAndNomenclature') === true && data.quantityAndNomenclature !== null && data.quantityAndNomenclature !== '' && data.quantityAndNomenclature !== 0
            ? <TextHalf
                title="Обсяг та основна номенклатура продукції (робіт, послуг), у тому числі експортної"
                data={data.quantityAndNomenclature}
            />
            : null
        }
        {data.hasOwnProperty('workplacesQuantity') === true && data.workplacesQuantity !== null && data.workplacesQuantity !== '' && data.workplacesQuantity !== 0
            ? <TextHalf
                title="Кількість робочих місць, ociб"
                data={data.workplacesQuantity}
            />
            : null
        }
        {data.hasOwnProperty('buildingsInformation') === true && data.buildingsInformation !== null && data.buildingsInformation !== '' && data.buildingsInformation !== 0
            ? <TextHalf
                title="Відомості про будівлі (споруди, приміщення) єдиного майнового комплексу в обсязі, передбаченому для інформації про нерухоме майно"
                data={data.buildingsInformation}
            />
            : null
        }
        {data.hasOwnProperty('landPlotInformation') === true && data.landPlotInformation !== null && data.landPlotInformation !== '' && data.landPlotInformation !== 0
            ? <TextHalf
                title="Відомості про земельну ділянку, на якій розташовано єдиний майновий комплекс"
                data={data.landPlotInformation}
            />
            : null
        }
        {data.hasOwnProperty('mainObligations') === true && data.mainObligations !== null && data.mainObligations !== '' && data.mainObligations !== 0
            ? <TextHalf
                title="Основні зобов’язання (договірні та позадоговірні)"
                data={data.mainObligations}
            />
            : null
        }
        {dgfLandProps(data.landProps)}
    </>
}

function dgfVehicleProps(data){
    if(data !== null){
        return <>
            <TextFull
                title="Технічні характеристики, транспортний засіб:"
                data=''
            />
            {data.hasOwnProperty('brand') === true && data.brand !== null && data.brand !== '' && data.brand !== 0
                ? <TextHalf
                    title="Марка транспортного засобу"
                    data={data.brand}
                />
                : null
            }
            {data.hasOwnProperty('model') === true && data.model !== null && data.model !== '' && data.model !== 0
                ? <TextHalf
                    title="Модель транспортного засобу"
                    data={data.model}
                />
                : null
            }
            {data.hasOwnProperty('productionYear') === true && data.productionYear !== null && data.productionYear !== '' && data.productionYear !== 0
                ? <TextHalf
                    title="Рік випуску"
                    data={data.productionYear}
                />
                : null
            }
            {data.hasOwnProperty('engineCapacity') === true && data.engineCapacity !== null && data.engineCapacity !== '' && data.engineCapacity !== 0
                ? <TextHalf
                    title="Об’єм двигуна, см³"
                    data={data.engineCapacity}
                />
                : null
            }
            {strFuelType(data)}
            {strTransmissionType(data)}
            {data.hasOwnProperty('color') === true && data.color !== null && data.color !== '' && data.color !== 0
                ? <TextHalf
                    title="Колір"
                    data={data.color}
                />
                : null
            }
            {data.hasOwnProperty('kilometrage') === true && data.kilometrage !== null && data.kilometrage !== '' && data.kilometrage !== 0
                ? <TextHalf
                    title="Пробіг, км"
                    data={data.kilometrage}
                />
                : null
            }
            {data.hasOwnProperty('identificationNumber') === true && data.identificationNumber !== null && data.identificationNumber !== '' && data.identificationNumber !== 0
                ? <TextHalf
                    title="Номер кузова або шасі"
                    data={data.identificationNumber}
                />
                : null
            }
            {data.hasOwnProperty('configuration') === true && data.configuration !== null && data.configuration !== '' && data.configuration !== 0
                ? <TextHalf
                    title="Комплектація"
                    data={data.configuration}
                />
                : null
            }
            {data.hasOwnProperty('condition') === true && data.condition !== null && data.condition !== '' && data.condition !== 0
                ? <TextHalf
                    title="Технічний стан"
                    data={data.condition}
                />
                : null
            }
            {data.hasOwnProperty('damagePresence') === true && data.damagePresence !== null && data.damagePresence !== '' && data.damagePresence !== 0
                ? <TextHalf
                    title="Потреба у ремонті"
                    data={viewTrueOrFalse(data.damagePresence)}
                />
                : null
            }
            {data.hasOwnProperty('damagedDescription') === true && data.damagedDescription !== null && data.damagedDescription !== '' && data.damagedDescription !== 0
                ? <TextHalf
                    title="Опис пошкоджень"
                    data={data.damagedDescription}
                />
                : null
            }
        </>
    }
}

function strFuelType(data){
    if(data.hasOwnProperty('engineCapacity') !== true) return null
    if(data.engineCapacity === null) return null
    if(data.engineCapacity === '') return null
    if(data.engineCapacity === 0) return null
    if(data.fuelType !== null){
        let tmp = ''
        if(data.fuelType === 'diesel'){
            tmp = 'Дизель'
        }else if(data.fuelType === 'electric'){
            tmp = 'Електро'
        }else if(data.fuelType === 'gasoline'){
            tmp = 'Бензин'
        }else if(data.fuelType === 'hybrid'){
            tmp = 'Гібридний'
        }else if(data.fuelType === 'liquefiedPetroleumGas'){
            tmp = 'Зріджений нафтовий газ'
        }else if(data.fuelType === 'naturalGas'){
            tmp = 'Газ'
        }else if(data.fuelType === 'other'){
            tmp = 'Інший'
        }
        return (
            <TextHalf
                title="Тип палива"
                data={tmp}
            />
        )
    }
}

function strTransmissionType(data){
    if(data.hasOwnProperty('transmission') !== true) return null
    if(data.transmission === null) return null
    if(data.transmission === '') return null
    if(data.transmission === 0) return null
    if(data.transmission !== null){
        let tmp = ''
        if(data.transmission === 'automatic'){
            tmp = 'Автоматична'
        }else if(data.transmission === 'manual'){
            tmp = 'Механічна'
        }else if(data.transmission === 'other'){
            tmp = 'Інше'
        }else if(data.transmission === 'semiAutomatic'){
            tmp = 'Напівавтоматична'
        }
        return (
            <TextHalf
                title="Тип коробки передач"
                data={tmp}
            />
        )
    }
}

function dgfLandProps(data){
    if(data === null) return null
    return <>
        <TextFull
            title="Характеристики земельної ділянки:"
            data=''
        />
        {data.hasOwnProperty('landArea') === true && data.landArea !== null && data.landArea !== '' && data.landArea !== 0
            ? <TextHalf
                title="Площа ділянки, га"
                data={data.landArea}
            />
            : null
        }
        {data.hasOwnProperty('cadastralNumber') === true && data.cadastralNumber !== null && data.cadastralNumber !== '' && data.cadastralNumber !== 0
            ? <TextHalf
                title="Кадастровий номер"
                data={data.cadastralNumber}
            />
            : null
        }
        {strOwnershipType(data)}
        {data.hasOwnProperty('landIntendedUse') === true && data.landIntendedUse !== null && data.landIntendedUse !== '' && data.landIntendedUse !== 0
            ? <TextHalf
                title="Поточне використання земельної ділянки"
                data={data.landIntendedUse}
            />
            : null
        }
        {data.hasOwnProperty('hasEncumbrances') === true
            ? <TextHalf
                title="Присутні обтяження"
                data={data.hasEncumbrances === true ? 'наявні' : 'відсутні'}
            />
            : null
        }
        {data.hasOwnProperty('hasEncumbrances') === true && data.hasEncumbrances === true
            ? <TextHalf
                title="Інформація про наявні обтяження"
                data={getEncumbrances(data.encumbrances)}
            />
            : null
        }
        {data.hasOwnProperty('hasJointOwnership') === true
            ? <TextHalf
                title="Наявні співвласники"
                data={data.hasJointOwnership === true ? 'наявні' : 'відсутні'}
            />
            : null
        }
        {data.hasOwnProperty('hasJointOwnership') === true && data.hasJointOwnership === true
            ? <TextHalf
                title="Інформація про співвласників"
                data={data.jointOwnership}
            />
            : null
        }
        {data.hasOwnProperty('hasUtilitiesAvailability') === true
            ? <TextHalf
                title="Інженерні мережі наявні"
                data={data.hasUtilitiesAvailability === true ? 'наявні' : 'відсутні'}
            />
            : null
        }
        {data.hasOwnProperty('hasUtilitiesAvailability') === true && data.hasUtilitiesAvailability === true
            ? <TextHalf
                title="Інформація про інженерні мережі"
                data={data.utilitiesAvailability}
            />
            : null
        }
    </>
}

function getEncumbrances(data){
    switch(data){
        case "noEncumbrances": return 'Обтяження відсутні'
        case "arrest": return 'Арешт нерухомого майна'
        case "collateral": return 'Податкова застава'
        case "restraintsOnAlienation": return 'Заборона відчуження'
        case "otherEncumbrances": return 'Інші обтяження'
        default: return null
    }
}


function strOwnershipType(data){
    if(data.hasOwnProperty('ownershipType') !== true) return null
    if(data.ownershipType === null) return null
    if(data.ownershipType === '') return null
    if(data.ownershipType === 0) return null
    if(data.ownershipType !== null){
        let tmp = ''
        if(data.ownershipType === 'compatible'){
            tmp = 'Сумісна'
        }else if(data.ownershipType === 'municipal'){
            tmp = 'Комунальна'
        }else if(data.ownershipType === 'private'){
            tmp = 'Приватна'
        }else if(data.ownershipType === 'state'){
            tmp = 'Державна'
        }else if(data.ownershipType === 'unknown'){
            tmp = 'Невідомо'
        }
        return (
            <TextHalf
                title="Тип власності"
                data={tmp}
            />
        )
    }
}

function strEncumbrances(data){
    if(data.hasOwnProperty('encumbrances') !== true) return null
    if(data.encumbrances === null) return null
    if(data.encumbrances === '') return null
    if(data.encumbrances === 0) return null
    if(data.encumbrances !== null){
        let tmp = ''
        if(data.encumbrances === 'arrest'){
            tmp = 'Арешт нерухомого майна'
        }else if(data.encumbrances === 'collateral'){
            tmp = 'Податкова застава'
        }else if(data.encumbrances === 'noEncumbrances'){
            tmp = 'Обтяження відсутні'
        }else if(data.encumbrances === 'otherEncumbrances'){
            tmp = 'Інші обтяження'
        }else if(data.encumbrances === 'restraintsOnAlienation'){
            tmp = 'Заборона відчуження'
        }
        return (
            <TextHalf
                title="Наявність обтяжень"
                data={tmp}
            />
        )
    }
}

function viewTrueOrFalse(data){
    if(data) return 'Так'
    return 'Ні'
}

const mapStateToProps = state => {
    return {
        auction: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(StructureLot)