export const AUC_TYPES = {
    TIE: [ // "Ліс: Англійський аукціон"
        "timber-english",
        "timber-english-fast",
        "timber-english-fast-manual",
        "timber-english-initial-auction",
        "timber-english-initial-qualification",
        "timber-english-initial-qualification-fast",
        "timber-english-initial-auction-manual",
    ],
    SUE: [ // "Надра: Англійський аукціон"
        "subsoil-english",
        "subsoil-english-fast",
        "subsoil-english-ultra-fast",
        "subsoil-english-fast-manual",
        "subsoil-english-initial-auction",
        "subsoil-english-initial-qualification",
        "subsoil-english-fast-auction-manual-qualification"
    ],
    SUD: [ // "Надра: Голандський аукціон"
        "subsoil-dutch",
        "subsoil-dutch-fast",
        "subsoil-dutch-ultra-fast",
        "subsoil-dutch-fast-manual",
        "subsoil-dutch-initial-auction",
        "subsoil-dutch-initial-qualification",
        "subsoil-dutch-initial-auction-manual",
        "subsoil-dutch-fast-auction-manual-qualification",
        "subsoil-dutch-fast-auction-prod",
        "subsoil-dutch-initial-qualification-prod"
    ],
    RCE: [ // "Залізничні перевезення: Англійський аукціон"
        "railwayCargo-english",
        "railwayCargo-english-fast",
        "railwayCargo-english-fast-manual",
        "railwayCargo-english-initial-auction",
        "railwayCargo-english-initial-qualification"
    ],
    RCD: [ // "Залізничні перевезення: Голандський аукціон"
        "railwayCargo-dutch",
        "railwayCargo-dutch-fast",
        "railwayCargo-dutch-fast-manual",
        "railwayCargo-dutch-initial-auction",
        "railwayCargo-dutch-initial-qualification",
        "railwayCargo-dutch-fast-auction-manual-qualification"
    ],
    GFE: [ // "ДГФ: Англійський аукціон"
        "dgf-english",
        "dgf-english-fast",
        "dgf-english-fast-manual",
        "dgf-english-fast-auction-manual-qualification",
        "dgf-english-initial-auction",
        "dgf-english-initial-auction-manual",
        "dgf-english-initial-qualification"
    ],
    GFD: [ // "ДГФ: Голандський аукціон"
        "dgf-dutch",
        "dgf-dutch-fast",
        "dgf-dutch-fast-manual",
        "dgf-dutch-fast-auction-manual-qualification",
        "dgf-dutch-initial-auction",
        "dgf-dutch-initial-qualification",
        "dgf-dutch-initial-auction-manual"
    ],
    LLE: [ // "Оренда державного / комунального майна"
        "legitimatePropertyLease-english",
        "legitimatePropertyLease-english-fast",
        "legitimatePropertyLease-english-fast-manual",
        "legitimatePropertyLease-english-fast-auction-manual-qualification",
        "legitimatePropertyLease-english-initial-auction",
        "legitimatePropertyLease-english-initial-auction-manual",
        "legitimatePropertyLease-english-initial-qualification",
        "legitimatePropertyLease-english-initial-qualification-prod"
    ],
    LLD: [ // "Оренда державного / комунального майна з покроковим зниженням"
        "legitimatePropertyLease-dutch",
        "legitimatePropertyLease-dutch-fast",
        "legitimatePropertyLease-dutch-fast-manual",
        "legitimatePropertyLease-dutch-fast-auction-manual-qualification",
        "legitimatePropertyLease-dutch-initial-auction",
        "legitimatePropertyLease-dutch-initial-auction-manual",
        "legitimatePropertyLease-dutch-initial-qualification-prod"
    ],
    LLP: [ // "Оренда державного / комунального майна з використанням переважного права"
        "legitimatePropertyLease-priorityEnglish",
        "legitimatePropertyLease-priorityEnglish-fast",
        "legitimatePropertyLease-priorityEnglish-fast-manual",
        "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification",
        "legitimatePropertyLease-priorityEnglish-initial-auction",
        "legitimatePropertyLease-priorityEnglish-initial-auction-manual",
        "legitimatePropertyLease-priorityEnglish-initial-qualification",
        "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ],
    LRE: [ // "Земельні торги - оренда"
        'landRental-english',
        'landRental-english-fast',
        'landRental-english-fast-manual',
        'landRental-english-fast-auction-manual-qualification',
        'landRental-english-initial-auction',
        'landRental-english-initial-auction-manual',
        'landRental-english-initial-qualification',
        'landRental-english-initial-qualification-prod'
    ],
    LSE: [ // "Земельні торги - продаж"
        'landSell-english',
        'landSell-english-fast',
        'landSell-english-fast-manual',
        'landSell-english-fast-auction-manual-qualification',
        'landSell-english-initial-auction',
        'landSell-english-initial-auction-manual',
        'landSell-english-initial-qualification',
        'landSell-english-initial-qualification-prod'
    ],
    LSP: [ // "Продаж землі: З використанням переважного права"
        'landSell-priorityEnglish',
        'landSell-priorityEnglish-fast',
        'landSell-priorityEnglish-fast-manual',
        'landSell-priorityEnglish-fast-auction-manual-qualification',
        'landSell-priorityEnglish-initial-auction',
        'landSell-priorityEnglish-initial-auction-manual',
        'landSell-priorityEnglish-initial-qualification',
        'landSell-priorityEnglish-initial-qualification-prod'
    ],
    RMA: [ // "Зелена енергія"
        "renewables",
        "renewables-fast",
        "renewables-fast-manual",
        "renewables-initial-auction",
        "renewables-initial-qualification",
        "renewables-initial-qualification-fast"
    ],
    BSE: [ // " Базовий продаж - англієць"
        "basicSell-english",
        "basicSell-english-fast",
        "basicSell-english-fast-manual",
        "basicSell-english-fast-auction-manual-qualification",
        "basicSell-english-initial-auction",
        "basicSell-english-initial-auction-manual",
        "basicSell-english-initial-qualification",
        "basicSell-english-initial-qualification-prod",
    ],
    BSD: [ // " Базовий продаж - голандець"
        "basicSell-dutch",
        "basicSell-dutch-fast",
        "basicSell-dutch-fast-manual",
        "basicSell-dutch-fast-auction-manual-qualification",
        "basicSell-dutch-initial-auction",
        "basicSell-dutch-initial-auction-manual",
        "basicSell-dutch-initial-qualification",
        "basicSell-dutch-initial-qualification-prod",
    ],
    ALE: [ // "Відчуження майна"
        "alienation-english",
        "alienation-english-fast",
        "alienation-english-fast-manual",
        "alienation-english-fast-auction-manual-qualification",
        "alienation-english-initial-auction",
        "alienation-english-initial-auction-manual",
        "alienation-english-initial-qualification",
        "alienation-english-initial-qualification-prod",
    ],
    CSE: [ // Комерційні торги - продаж (англійський аукціон)
        'commercialSell-english',
        "commercialSell-english-fast",
        "commercialSell-english-fast-manual",
        "commercialSell-english-fast-auction-manual-qualification",
        "commercialSell-english-initial-auction",
        "commercialSell-english-initial-auction-manual",
        "commercialSell-english-initial-qualification",
        "commercialSell-english-initial-qualification-prod",
    ],
    CSD: [ // Комерційні торги - продаж (голландський аукціон)
        'commercialSell-dutch',
        "commercialSell-dutch-fast",
        "commercialSell-dutch-fast-manual",
        "commercialSell-dutch-fast-auction-manual-qualification",
        "commercialSell-dutch-initial-auction",
        "commercialSell-dutch-initial-auction-manual",
        "commercialSell-dutch-initial-qualification",
        "commercialSell-dutch-initial-qualification-prod",
    ],
    AAE: [ // Арештоване майно
        'arrestedAssets-english',
        "arrestedAssets-english-fast",
        "arrestedAssets-english-fast-manual",
        "arrestedAssets-english-fast-auction-manual-qualification",
        "arrestedAssets-english-initial-auction",
        "arrestedAssets-english-initial-auction-manual",
        "arrestedAssets-english-initial-qualification",
        "arrestedAssets-english-initial-qualification-prod",
    ],
    BRE: [ // Продаж майна боржників у справах про банкрутство (Банкрутство - англійський аукціон)
        'bankRuptcy-english',
        "bankRuptcy-english-fast",
        "bankRuptcy-english-fast-manual",
        "bankRuptcy-english-fast-auction-manual-qualification",
        "bankRuptcy-english-initial-auction",
        "bankRuptcy-english-initial-auction-manual",
        "bankRuptcy-english-initial-qualification",
        "bankRuptcy-english-initial-qualification-prod",
    ],
    BRD: [
        'bankRuptcy-dutch',
        "bankRuptcy-dutch-fast",
        "bankRuptcy-dutch-fast-manual",
        "bankRuptcy-dutch-fast-auction-manual-qualification",
        "bankRuptcy-dutch-initial-auction",
        "bankRuptcy-dutch-initial-auction-manual",
        "bankRuptcy-dutch-initial-qualification",
        "bankRuptcy-dutch-initial-qualification-prod",
    ],
    BRW: [
        'bankRuptcy-withoutAuction',
    ],
    RLE: [
        "regulationsPropertyLease-english",
        "regulationsPropertyLease-english-ultra-fast",
        "regulationsPropertyLease-english-fast",
        "regulationsPropertyLease-english-fast-manual",
        "regulationsPropertyLease-english-fast-auction-manual-qualification",
        "regulationsPropertyLease-english-fast-auction-prod",
        "regulationsPropertyLease-english-initial-auction",
        "regulationsPropertyLease-english-initial-auction-manual",
        "regulationsPropertyLease-english-initial-qualification",
        "regulationsPropertyLease-english-initial-qualification-prod"
    ],
    RLD: [
        "regulationsPropertyLease-dutch",
        "regulationsPropertyLease-dutch-ultra-fast",
        "regulationsPropertyLease-dutch-fast",
        "regulationsPropertyLease-dutch-fast-manual",
        "regulationsPropertyLease-dutch-fast-auction-manual-qualification",
        "regulationsPropertyLease-dutch-fast-auction-prod",
        "regulationsPropertyLease-dutch-initial-auction",
        "regulationsPropertyLease-dutch-initial-auction-manual",
        "regulationsPropertyLease-dutch-initial-qualification",
        "regulationsPropertyLease-dutch-initial-qualification-prod"
    ],
    CLE: [
        "commercialPropertyLease-english",
        "commercialPropertyLease-english-ultra-fast",
        "commercialPropertyLease-english-fast",
        "commercialPropertyLease-english-fast-manual",
        "commercialPropertyLease-english-fast-auction-manual-qualification",
        "commercialPropertyLease-english-fast-auction-prod",
        "commercialPropertyLease-english-initial-auction",
        "commercialPropertyLease-english-initial-auction-manual",
        "commercialPropertyLease-english-initial-qualification",
        "commercialPropertyLease-english-initial-qualification-prod"
    ],
    CLD: [
        "commercialPropertyLease-dutch",
        "commercialPropertyLease-dutch-ultra-fast",
        "commercialPropertyLease-dutch-fast",
        "commercialPropertyLease-dutch-fast-manual",
        "commercialPropertyLease-dutch-fast-auction-manual-qualification",
        "commercialPropertyLease-dutch-fast-auction-prod",
        "commercialPropertyLease-dutch-initial-auction",
        "commercialPropertyLease-dutch-initial-auction-manual",
        "commercialPropertyLease-dutch-initial-qualification",
        "commercialPropertyLease-dutch-initial-qualification-prod"
    ],
    SPE: [
        "smallPrivatization-english",
        "smallPrivatization-english-ultra-fast",
        "smallPrivatization-english-fast",
        "smallPrivatization-english-fast-manual",
        "smallPrivatization-english-fast-auction-manual-qualification",
        "smallPrivatization-english-fast-auction-prod",
        "smallPrivatization-english-initial-auction",
        "smallPrivatization-english-initial-auction-manual",
        "smallPrivatization-english-initial-qualification",
        "smallPrivatization-english-initial-qualification-prod"
    ],
    SPD: [
        "smallPrivatization-dutch",
        "smallPrivatization-dutch-fast",
        "smallPrivatization-dutch-ultra-fast",
        "smallPrivatization-dutch-fast-manual",
        "smallPrivatization-dutch-fast-auction-manual-qualification",
        "smallPrivatization-dutch-fast-auction-prod",
        "smallPrivatization-dutch-initial-auction",
        "smallPrivatization-dutch-initial-auction-manual",
        "smallPrivatization-dutch-initial-qualification",
        "smallPrivatization-dutch-initial-qualification-prod"
    ],
    NLE: [ // " Базовий продаж - англієць"
        "nonperformingLoans-english",
        "nonperformingLoans-english-fast",
        "nonperformingLoans-english-fast-manual",
        "nonperformingLoans-english-fast-auction-manual-qualification",
        "nonperformingLoans-english-fast-auction-prod",
        "nonperformingLoans-english-initial-qualification",
        "nonperformingLoans-english-initial-qualification-prod",
        "nonperformingLoans-english-initial-auction",
        "nonperformingLoans-english-initial-auction-manual"
    ],
    NLD: [ // " Базовий продаж - голандець"
        "nonperformingLoans-dutch",
        "nonperformingLoans-dutch-fast",
        "nonperformingLoans-dutch-fast-manual",
        "nonperformingLoans-dutch-fast-auction-manual-qualification",
        "nonperformingLoans-dutch-fast-auction-prod",
        "nonperformingLoans-dutch-initial-auction",
        "nonperformingLoans-dutch-initial-auction-manual",
        "nonperformingLoans-dutch-initial-qualification",
        "nonperformingLoans-dutch-initial-qualification-prod"
    ],
    LAE: [ // "арештована земля - англієць"
        "landArrested-english",
        "landArrested-english-ultra-fast",
        "landArrested-english-fast",
        "landArrested-english-fast-manual",
        "landArrested-english-fast-auction-manual-qualification",
        "landArrested-english-fast-auction-prod",
        "landArrested-english-initial-auction",
        "landArrested-english-initial-auction-manual",
        "landArrested-english-initial-qualification",
        "landArrested-english-initial-qualification-prod"
    ],
    LAW: [ // "арештована земля - викуп"
        "landArrested-withoutAuction"
    ],

    LAP : [
        "landArrested-priorityEnglish",
        "landArrested-priorityEnglish-ultra-fast",
        "landArrested-priorityEnglish-fast",
        "landArrested-priorityEnglish-fast-manual",
        "landArrested-priorityEnglish-fast-auction-manual-qualification",
        "landArrested-priorityEnglish-fast-auction-prod",
        "landArrested-priorityEnglish-initial-auction",
        "landArrested-priorityEnglish-initial-auction-manual",
        "landArrested-priorityEnglish-initial-qualification",
        "landArrested-priorityEnglish-initial-qualification-prod"
    ],

    LPE: [
        "largePrivatization-english",
        "largePrivatization-english-fast",
        "largePrivatization-english-ultra-fast",
        "largePrivatization-english-fast-manual",
        "largePrivatization-english-fast-auction-manual-qualification",
        "largePrivatization-english-fast-auction-prod",
        "largePrivatization-english-initial-auction",
        "largePrivatization-english-initial-auction-manual",
        "largePrivatization-english-initial-qualification",
        "largePrivatization-english-initial-qualification-prod"
    ],

    APE: [
        "armaProperty-english",
        "armaProperty-english-ultra-fast",
        "armaProperty-english-fast",
        "armaProperty-english-fast-manual",
        "armaProperty-english-fast-auction-manual-qualification",
        "armaProperty-english-fast-auction-prod",
        "armaProperty-english-initial-auction",
        "armaProperty-english-initial-auction-manual",
        "armaProperty-english-initial-qualification",
        "armaProperty-english-initial-qualification-prod"
    ],

    APD: [
        "armaProperty-dutch",
        "armaProperty-dutch-ultra-fast",
        "armaProperty-dutch-fast",
        "armaProperty-dutch-fast-manual",
        "armaProperty-dutch-fast-auction-manual-qualification",
        "armaProperty-dutch-fast-auction-prod",
        "armaProperty-dutch-initial-auction",
        "armaProperty-dutch-initial-auction-manual",
        "armaProperty-dutch-initial-qualification",
        "armaProperty-dutch-initial-qualification-prod"
    ]
}

// "Ліс: Англійський аукціон" - 'timber-english'
export function isTIE(type) {
    return AUC_TYPES.TIE.indexOf(type) > -1
}
// "Надра: Англійський аукціон" - "subsoil-english"
export function isSUE(type) {
    return AUC_TYPES.SUE.indexOf(type) > -1
}
// "Надра: Голандський аукціон" - "subsoil-dutch"
export function isSUD(type) {
    return AUC_TYPES.SUD.indexOf(type) > -1
}
// "Залізничні перевезення: Англійський аукціон" - "railwayCargo-english"
export function isRCE(type) {
    return AUC_TYPES.RCE.indexOf(type) > -1
}
// "Залізничні перевезення: Голандський аукціон" - "railwayCargo-dutch"
export function isRCD(type) {
    return AUC_TYPES.RCD.indexOf(type) > -1
}
// "ДГФ: Англійський аукціон" - "dgf-english"
export function isGFE(type) {
    return AUC_TYPES.GFE.indexOf(type) > -1
}
// "ДГФ: Голандський аукціон" - "dgf-dutch"
export function isGFD(type) {
    return AUC_TYPES.GFD.indexOf(type) > -1
}
// "Оренда державного / комунального майна" - "legitimatePropertyLease-english"
export function isLLE(type) {
    return AUC_TYPES.LLE.indexOf(type) > -1
}
// "Оренда державного / комунального майна з покроковим зниженням" - "legitimatePropertyLease-dutch"
export function isLLD(type) {
    return AUC_TYPES.LLD.indexOf(type) > -1
}
// "Оренда державного / комунального майна з використанням переважного права" - "legitimatePropertyLease-priorityEnglish"
export function isLLP(type) {
    return AUC_TYPES.LLP.indexOf(type) > -1
}
// "Земельні торги - оренда" - 'landRental-english'
export function isLRE(type) {
    return AUC_TYPES.LRE.indexOf(type) > -1
}
// "Земельні торги - продаж" - 'landSell-english'
export function isLSE(type) {
    return AUC_TYPES.LSE.indexOf(type) > -1
}
// "Продаж землі: З використанням переважного права" - 'landSell-priorityEnglish'
export function isLSP(type) {
    return AUC_TYPES.LSP.indexOf(type) > -1
}
// "Зелена енергія" - "renewables"
export function isRMA(type) {
    return AUC_TYPES.RMA.indexOf(type) > -1
}
// "Базовий продаж" - "basicSell-english"
export function isBSE(type) {
    return AUC_TYPES.BSE.indexOf(type) > -1
}
// "Базовий продаж" - "basicSell-dutch"
export function isBSD(type) {
    return AUC_TYPES.BSD.indexOf(type) > -1
}
// "Відчуження майна" - "alienation-english"
export function isALE(type) {
    return AUC_TYPES.ALE.indexOf(type) > -1
}
// "Комерційні торги - продаж (англійський аукціон)" - "commercialSell-english"
export function isCSE(type) {
    return AUC_TYPES.CSE.indexOf(type) > -1
}
// "Комерційні торги - продаж (голландський аукціон)" - "commercialSell-english"
export function isCSD(type) {
    return AUC_TYPES.CSD.indexOf(type) > -1
}
 //Арештоване майно
export function isAAE(type) {
    return AUC_TYPES.AAE.indexOf(type) > -1
}
// Продаж майна боржників у справах про банкрутство (Банкрутство - англійський аукціон)
export function isBRE(type) {
    return AUC_TYPES.BRE.indexOf(type) > -1
}
// Продаж майна боржників у справах про банкрутство (Банкрутство - голландський аукціон)
export function isBRD(type) {
    return AUC_TYPES.BRD.indexOf(type) > -1
}
// Продаж майна боржників у справах про банкрутство (Банкрутство - Відсутні заяви на участь і аукціон)
export function isBRW(type) {
    return AUC_TYPES.BRW.indexOf(type) > -1
}
export function isRLE(type) {
    return AUC_TYPES.RLE.indexOf(type) > -1
}
export function isRLD(type) {
    return AUC_TYPES.RLD.indexOf(type) > -1
}
export function isCLE(type) {
    return AUC_TYPES.CLE.indexOf(type) > -1
}
export function isCLD(type) {
    return AUC_TYPES.CLD.indexOf(type) > -1
}
export function isSPE(type) {
    return AUC_TYPES.SPE.indexOf(type) > -1
}
export function isSPD(type) {
    return AUC_TYPES.SPD.indexOf(type) > -1
}
export function isNLE(type) {
    return AUC_TYPES.NLE.indexOf(type) > -1
}
// "Базовий продаж" - "basicSell-dutch"
export function isNLD(type) {
    return AUC_TYPES.NLD.indexOf(type) > -1
}
export function isLAE(type) {
    return AUC_TYPES.LAE.indexOf(type) > -1
}
export function isLAW(type) {
    return AUC_TYPES.LAW.indexOf(type) > -1
}
export function isLAP(type) {
    return AUC_TYPES.LAP.indexOf(type) > -1
}
export function isLPE(type) {
    return AUC_TYPES.LPE.indexOf(type) > -1
}
export function isAPE(type) {
    return AUC_TYPES.APE.indexOf(type) > -1
}
export function isAPD(type) {
    return AUC_TYPES.APD.indexOf(type) > -1
}

export function viewAuctionType(data){
    if(data !== null){
        switch(true){
            case isTIE(data):
                return "Ліс: англійський аукціон"
            case isSUE(data):
                return "Надра: англійський аукціон"
            case isSUD(data):
                return "Надра: голандський аукціон"
            case isRCE(data):
                return "Залізничні перевезення: англійський аукціон"
            case isRCD(data):
                return "Залізничні перевезення: голандський аукціон"
            case isGFE(data):
                return "ДГФ: англійський аукціон"
            case isGFD(data):
                return "ДГФ: голандський аукціон"
            case isLLE(data):
                return "Оренда державного / комунального майна"
            case isLLD(data):
                return "Оренда державного / комунального майна з покроковим зниженням"
            case isLLP(data):
                return "Оренда державного / комунального майна з використанням переважного права"
            case isLRE(data):
                return "Земельні торги - оренда"
            case isLSE(data):
                return "Земельні торги - продаж"
            case isLSP(data):
                return "Земельні торги - продаж з переважним правом"
            case isRMA(data):
                return "Зелена енергія"
            case isBSE(data):
                return "Базовий продаж: англійський аукціон"
            case isBSD(data):
                return "Базовий продаж: голандський аукціон"
            case isALE(data):
                return "Відчуження майна"
            case isCSE(data):
                return "Комерційні торги - продаж (англійський аукціон)"
            case isCSD(data):
                return "Комерційні торги - продаж (голландський аукціон)"
            case isAAE(data):
                return "Арештоване майно"
            case isBRE(data):
                return "Банкрутство - англійський аукціон"
            case isBRD(data):
                return "Банкрутство - голландський аукціон"
            case isBRW(data):
                return "Продаж майна боржників у справах про банкрутство без аукціону"

            case isRLE(data):
                return "Оренда за регламентом (англійський аукціон)"
            case isRLD(data):
                return "Оренда за регламентом (голландський аукціон)"
            case isCLE(data):
                return "Комерційна оренда (англійський аукціон)"
            case isCLD(data):
                return "Комерційна оренда (голландський аукціон)"
            case isSPE(data):
                return "Продаж об'єктів малої приватизації на \"англійському аукціоні\""
            case isSPD(data):
                return "Продаж об'єктів малої приватизації на \"голландському аукціоні\""
            case isNLE(data):
                return "Продаж непрацюючих кредитів державних банків: англійський аукціон"
            case isNLD(data):
                return "Продаж непрацюючих кредитів державних банків: голандський аукціон"
            case isLAE(data):
                return "Арештована земля англійський аукціон"
            case isLAW(data):
                return "Арештована земля. Викуп"
            case isLAP(data):
                return "Арештована земля: з переважним правом"
            case isLPE(data):
                return "Продаж об'єктів великої приватизації на \"англійському аукціоні\""
            case isAPE(data):
                return "Арештовані активи АРМА. Англійський аукціон"
            case isAPD(data):
                return "Арештовані активи АРМА. Голандський аукціон"
            default:
                return null
        }
    }
    return null
}