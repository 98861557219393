import React from 'react';
import styles from './realEstateItem.module.css';

import {connect} from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import {
    setDataAddress,
} from '../../../../../redux/createAddress';

import {
    getMyAuction,
    changeLoader,
} from '../../../../../redux/actions.js';

import {popUpAddress} from '../../../../../redux/actions/address/address.js';
import { DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
//import ZuMap from '../../../../map/zuMaps'
import ZuMap from '../../../../map/zuMaps'

import {disabletElem} from '../../../disabled'

const RealEstateItem = ( props ) => {
    /*let tmp = 'null'
    if(props.createItems.zu.realEstateItem.additionalClassifications !== null){
        tmp = props.createItems.zu.realEstateItem.additionalClassifications.id
    }*/
    let tmpZuMap = null
    /*f(props.createItems.zu.realEstateItem.location !== null){
        if(
            (props.createItems.zu.realEstateItem.location.latitude !== null && props.createItems.zu.realEstateItem.location.latitude !== '')  &&
            (props.createItems.zu.realEstateItem.location.longitude !== null && props.createItems.zu.realEstateItem.location.longitude !== '')){
            tmpZuMap = <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <ZuMap
                        latitude = {props.createItems.zu.realEstateItem.location.latitude}
                        longitude = {props.createItems.zu.realEstateItem.location.longitude}
                    />
                </div>
            </div>
        }
    }*/
    tmpZuMap = <div className={styles.groupElem + ' ' + styles.groupElemCategory + ' ' + styles.groupElemCategoryTwo}>
        <div>
            <ZuMap
                latitude = {props.createItems.landRental.location.latitude}
                longitude = {props.createItems.landRental.location.longitude}
            />
        </div>
    </div>
    let closeDraftElem = false
    if(
        props.auctionsMy.status === null ||
        props.auctionsMy.status === '' ||
        props.auctionsMy.tenderAttempts === '1' ||
        props.auctionsMy.tenderAttempts === 1
    ){
        closeDraftElem = true
    }
    return (
        <>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Назва об'єкта"
                        variant="outlined"
                        value={props.createItems.zu.realEstateItem.title}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_TITLE')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Опис об'єкта"
                        variant="outlined"
                        multiline
                        rows={10}
                        value={props.createItems.zu.realEstateItem.description}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_DESCRIPTION')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        id="outlined-select-currency-native"
                        select
                        label="Основний класифікатор"
                        value={props.createItems.zu.realEstateItem.classification}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_CLASSIFICATION')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Основний класифікатор"
                        variant="outlined"
                    >
                        <option key='0' selected disabled value="null">Обрати</option>
                        {classificationIdOption(props)}
                    </TextField>
                </div>
            </div>
            {/*<div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        label="Додатковий класифікатор об'єкта"
                        value={tmp}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_ADDITIONAL_CLASSIFICATION')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Додатковий класифікатор об'єкта"
                        variant="outlined"
                    >
                        <option key='0' selected disabled value="null">Обрати</option>
                        {addClassificationIdOption(props)}
                    </TextField>
                </div>
            </div>*/}
            {/*<div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.zu.realEstateItem.address)}
                        disabled={disabletElem(props.auctionsMy, '00')}
                        required
                        id="outlined-select-currency-native"
                        select
                        label="Адреса"
                        value={props.createItems.zu.realEstateItem.address}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_ADDRESS')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Оберіть адресу"
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        {addressArr(props)}
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <Button
                        disabled={disabletElem(props.auctionsMy, '00')}
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                props.setDataAddress(true, 'SET_POPUP_ADDRESSES')
                            }
                        }
                    >
                        Створити та редагувати адресу
                    </Button>
                </div>
            </div>
            <div>Координати</div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.zu.realEstateItem.location.latitude)}
                        required
                        disabled={disabletElem(props.auctionsMy, '11')}
                        id="outlined-select-currency-native"
                        label="Широта"
                        value={props.createItems.zu.realEstateItem.location.latitude}
                        onChange={(event) => {
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_LOCATION_LATITUDE')
                        }}
                        name="numberformat"
                        id="formatted-numberformat-input"
                        variant="outlined"
                        helperText=" "
                    />
                </div>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.zu.realEstateItem.location.longitude)}
                        required
                        disabled={disabletElem(props.auctionsMy, '11')}
                        id="outlined-select-currency-native"
                        label="Довгота"
                        value={props.createItems.zu.realEstateItem.location.longitude}
                        onChange={(event) => {
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_LOCATION_LONGITUDE')
                        }}
                        name="numberformat"
                        id="formatted-numberformat-input"
                        variant="outlined"
                        helperText=" "
                    />
                </div>
            </div>*/}
            {tmpZuMap}
            <div className={styles.lineButtons}>
                <div className={styles.editButton}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={
                            ()=>{
                                setNewAddress(props)
                            }
                        }
                    >
                        Додати обрані координати
                    </Button>
                </div>
            </div>
            <div>Адреса</div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled
                        error = {redBlock(props, props.createItems.landRental.addressItem.countryName)}
                        required
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Країна"
                        variant="outlined"
                        value={props.createItems.landRental.addressItem.countryName}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_COUNTRY_NAME')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled
                        error = {redBlock(props.createItems.landRental.addressItem.region)}
                        required
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Область"
                        variant="outlined"
                        value={props.createItems.landRental.addressItem.region}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_REGION')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.landRental.addressItem.locality)}
                        required
                        disabled={disabletElem(props.auctionsMy, '01')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Населенний пункт"
                        variant="outlined"
                        value={props.createItems.landRental.addressItem.locality}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_LOCALITY')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.landRental.addressItem.streetAddress)}
                        required
                        disabled={disabletElem(props.auctionsMy, '01')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Вулиця, буд. №"
                        variant="outlined"
                        value={props.createItems.landRental.addressItem.streetAddress}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_STREET_ADDRESS')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.landRental.addressItem.postalCode)}
                        required
                        disabled={disabletElem(props.auctionsMy, '00')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Поштовий індекс"
                        variant="outlined"
                        value={props.createItems.landRental.addressItem.postalCode}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_POSTAL_CODE')
                        }}
                    />
                </div>
            </div>


            <div>Координати</div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.landRental.location.latitude)}
                        disabled
                        id="outlined-select-currency-native"
                        label="Широта"
                        value={props.createItems.landRental.location.latitude}
                        onChange={(event) => {
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE')
                        }}
                        name="numberformat"
                        id="formatted-numberformat-input"
                        variant="outlined"
                        helperText=" "
                    />
                </div>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.landRental.location.longitude)}
                        disabled
                        id="outlined-select-currency-native"
                        label="Довгота"
                        value={props.createItems.landRental.location.longitude}
                        onChange={(event) => {
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE')
                        }}
                        name="numberformat"
                        id="formatted-numberformat-input"
                        variant="outlined"
                        helperText=" "
                    />
                </div>
            </div>
            <div>Інформація щодо реєстрації</div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.zu.realEstateItem.registrationDetails.registrationStatus)}
                        required
                        disabled={disabletElem(props.auctionsMy, '00')}
                        id="outlined-select-currency-native"
                        select
                        label="Стан державної реєстрації об'єкту"
                        value={props.createItems.zu.realEstateItem.registrationDetails.registrationStatus}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Оберіть стан державної реєстрації об''єкту"
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="notRegistered">Не зареєстровано</option>
                        <option key='2' value="oldRegistration">Зареєстровано до 1 січня 2013 р. відповідно до законодавства, що діяло на момент виникнення речового права на майно</option>
                        <option key='3' value="properlyRegistered">Зареєстровано в Державному реєстрі речових прав на нерухоме майно</option>
                        <option key='4' value="registrationIsNotRequired">Реєстрація не вимагається</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '00')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Номер запису"
                        variant="outlined"
                        value={props.createItems.zu.realEstateItem.registrationDetails.registrationID}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_ID')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>Дата реєстрації</div>
                <div>
                    <KeyboardDateTimePicker
                        disabled={disabletElem(props.auctionsMy, '00')}
                        className={styles.dateSearch}
                        value={props.createItems.zu.realEstateItem.registrationDetails.registrationDate}
                        onChange={
                            (e) => {
                                //let tmpData = new Date(e)
                                //let tmpString = `${tmpData.getFullYear()}-${tmpData.getMonth()+1}-${tmpData.getDate()}T${tmpData.getHours()}:${tmpData.getMinutes()}:00+00:00`
                                props.setDataAddress(e, 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_DATE')
                            }
                        }
                        onError={console.log}
                        minDate={new Date("1900-01-01T00:00")}
                        ampm={false}
                        format="yyyy/MM/dd HH:mm"
                        helperText="Формат дати: гггг/мм/дд чч:мм"
                    />
                    {/*<TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Дата реєстрації"
                        variant="outlined"
                        value={props.createItems.zu.realEstateItem.registrationDetails.registrationDate}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_DATE')
                        }}
                    />*/}
                </div>
            </div>

            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Загальна площа будівлі, кв.м."
                        variant="outlined"
                        value={props.createItems.zu.realEstateItem.reProps.totalBuildingArea}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_TOTAL_BUILDING_AREA')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.zu.realEstateItem.reProps.totalObjectArea)}
                        required
                        disabled={disabletElem(props.auctionsMy, '00')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Загальна площа об'єкта в будівлі, кв.м."
                        variant="outlined"
                        value={props.createItems.zu.realEstateItem.reProps.totalObjectArea}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_TOTAL_OBJECT_AREA')
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_QUANTITY')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Корисна площа об'єкта в будівлі, кв.м."
                        variant="outlined"
                        value={props.createItems.zu.realEstateItem.reProps.usableArea}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_USABLE_AREA')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Рік будівництва"
                        variant="outlined"
                        value={props.createItems.zu.realEstateItem.reProps.constructionYear}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_CONSTRUCTION_YEAR')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        id="outlined-select-currency-native"
                        select
                        label="Технологія будівництва"
                        value={props.createItems.zu.realEstateItem.reProps.constructionTechnology}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_CONSTRUCTION_TECHNOLOGY')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Оберіть одиницю виміру"
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="brick">Цегла</option>
                        <option key='2' value="insulatedPanel">Утеплена панель</option>
                        <option key='3' value="monolithicFrame">Монолітно-каркасна</option>
                        <option key='4' value="other">Iнше</option>
                        <option key='5' value="panel">Панель</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Житлова площа, кв. м"
                        variant="outlined"
                        value={props.createItems.zu.realEstateItem.reProps.livingArea}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LIVING_AREA')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Площа кухні, кв. м"
                        variant="outlined"
                        value={props.createItems.zu.realEstateItem.reProps.kitchenArea}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_KITCHEN_AREA')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Площа ділянки, кв. м"
                        variant="outlined"
                        value={props.createItems.zu.realEstateItem.reProps.landArea}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LAND_AREA')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        id="outlined-select-currency-native"
                        select
                        label="Місце розташування об’єкта в будівлі"
                        value={props.createItems.zu.realEstateItem.reProps.locationInBuilding}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LOCATION_IN_BUILDING')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Оберіть одиницю виміру"
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="aboveGround">Надземний</option>
                        <option key='2' value="attic">Мансардний</option>
                        <option key='3' value="basement">Підвальний</option>
                        <option key='4' value="pedimental">Цокольний</option>
                        <option key='5' value="technical">Технічний</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Номер поверху або поверхів"
                        variant="outlined"
                        value={props.createItems.zu.realEstateItem.reProps.floors}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_FLOORS')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Технічний стан об'єкта"
                        variant="outlined"
                        value={props.createItems.zu.realEstateItem.reProps.generalCondition}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_GENERAL_CONDITION')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        id="outlined-select-currency-native"
                        select
                        label="Чи приєднаний об'єкт до електромережі"
                        variant="outlined"
                        value={props.createItems.zu.realEstateItem.reProps.serviceElectricity}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_ELECTRICITY')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="false">Ні</option>
                        <option key='2' value="true">Так</option>
                    </TextField>
                </div>
            </div>
            {/*<div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Потужність електромережі, кВт"
                        variant="outlined"
                        value={props.createItems.zu.realEstateItem.reProps.powerSupplyCapacity}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SUPPLY_CAPACITY')
                        }}
                    />
                </div>
            </div>*/}
            {viewPowerSupplyCapacity(props, closeDraftElem)}
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        id="outlined-select-currency-native"
                        select
                        label="Ступінь потужності електромережі"
                        value={props.createItems.zu.realEstateItem.reProps.powerSupplyClass}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SUPPLY_CLASS')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="first">Перший ступінь</option>
                        <option key='2' value="over50">Понад 50 кВт</option>
                        <option key='3' value="second">Другий ступінь</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        id="outlined-select-currency-native"
                        select
                        label="Водозабезпечення присутнє"
                        value={props.createItems.zu.realEstateItem.reProps.serviceWater}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_WATER')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="false">Ні</option>
                        <option key='2' value="true">Так</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        id="outlined-select-currency-native"
                        select
                        label="Каналізація присутня"
                        value={props.createItems.zu.realEstateItem.reProps.serviceSewerage}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_SEWERAGE')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="false">Ні</option>
                        <option key='2' value="true">Так</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        id="outlined-select-currency-native"
                        select
                        label="Газифікація присутня"
                        value={props.createItems.zu.realEstateItem.reProps.serviceGas}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_GAS')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="false">Ні</option>
                        <option key='2' value="true">Так</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        id="outlined-select-currency-native"
                        select
                        label="Централізоване опалення присутнє"
                        value={props.createItems.zu.realEstateItem.reProps.serviceCentralHeating}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_CENTRAL_HEATING')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="false">Ні</option>
                        <option key='2' value="true">Так</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        id="outlined-select-currency-native"
                        select
                        label="Автономне опалення присутнє"
                        value={props.createItems.zu.realEstateItem.reProps.serviceAutonomousHeating}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_AUTONOMOUS_HEATING')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="false">Ні</option>
                        <option key='2' value="true">Так</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        id="outlined-select-currency-native"
                        select
                        label="Лічильник опалення присутній"
                        value={props.createItems.zu.realEstateItem.reProps.serviceHeatingCounter}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_HEATING_COUNTER')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="false">Ні</option>
                        <option key='2' value="true">Так</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        id="outlined-select-currency-native"
                        select
                        label="Вентиляція присутня"
                        value={props.createItems.zu.realEstateItem.reProps.serviceVentilation}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_VENTILATION')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="false">Ні</option>
                        <option key='2' value="true">Так</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        id="outlined-select-currency-native"
                        select
                        label="Кондиціонування присутнє"
                        value={props.createItems.zu.realEstateItem.reProps.serviceAirConditioning}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_AIR_CONDITIONING')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="false">Ні</option>
                        <option key='2' value="true">Так</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        id="outlined-select-currency-native"
                        select
                        label="Телефонізація присутня"
                        value={props.createItems.zu.realEstateItem.reProps.servicePhone}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_PHONE')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="false">Ні</option>
                        <option key='2' value="true">Так</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        id="outlined-select-currency-native"
                        select
                        label="Телебачення присутнє"
                        value={props.createItems.zu.realEstateItem.reProps.serviceTV}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_TV')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="false">Ні</option>
                        <option key='2' value="true">Так</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        id="outlined-select-currency-native"
                        select
                        label="Інтернет присутній"
                        value={props.createItems.zu.realEstateItem.reProps.serviceInternet}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_INTERNET')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="false">Ні</option>
                        <option key='2' value="true">Так</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        id="outlined-select-currency-native"
                        select
                        label="Ліфт присутній"
                        value={props.createItems.zu.realEstateItem.reProps.serviceElevator}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_ELEVATOR')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="false">Ні</option>
                        <option key='2' value="true">Так</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        id="outlined-select-currency-native"
                        select
                        label="Охоронна сигналізація присутня"
                        value={props.createItems.zu.realEstateItem.reProps.serviceSecurityAlarm}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_SECURITY_ALARM')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="false">Ні</option>
                        <option key='2' value="true">Так</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        id="outlined-select-currency-native"
                        select
                        label="Пожежна сигналізація присутня"
                        value={props.createItems.zu.realEstateItem.reProps.serviceFireAlarm}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_FIRE_ALARM')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="false">Ні</option>
                        <option key='2' value="true">Так</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Додаткова інформація щодо комунікацій, що є в об''єкті"
                        variant="outlined"
                        value={props.createItems.zu.realEstateItem.reProps.servicesDescription}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SERVICES_DESCRIPTION')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Інформація про наявність окремих особових рахунків на об'єкт, відкритих постачальниками комунальних послуг"
                        variant="outlined"
                        value={props.createItems.zu.realEstateItem.reProps.servicesAccounting}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SERVICES_ACCOUNTING')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Інформація щодо компенсації сплати земельного податку за користування земельною ділянкою, на якій розташований об'єкт"
                        variant="outlined"
                        value={props.createItems.zu.realEstateItem.reProps.landTax}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LAND_TAX')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.zu.realEstateItem.quantity)}
                        disabled={disabletElem(props.auctionsMy, '11')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Площа об'єкта, що здається в оренду"
                        variant="outlined"
                        value={props.createItems.zu.realEstateItem.quantity}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_QUANTITY')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.zu.realEstateItem.unitCode)}
                        disabled={disabletElem(props.auctionsMy, '00')}
                        required
                        id="outlined-select-currency-native"
                        select
                        label="Одиниці виміру"
                        value={props.createItems.zu.realEstateItem.unitCode}
                        onChange={(event)=>{
                            //props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_UNIT_CODE')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Оберіть одиницю виміру"
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        {classificationSubsoilUnitCode(props)}
                    </TextField>
                </div>
            </div>
            {viewButtons(props)}
        </>
    )
}

function setNewAddress(props) {
    let map = window.parent.document.getElementById("target").contentWindow.document.getElementById('map');
    let tmpLat = map.dataset.lat.split('.')
    let tmpLng = map.dataset.lng.split('.')
    let newAddress = {
        //address: `${map.dataset.city !== '' ? map.dataset.city : ''}${map.dataset.street !== '' ? ', ' + map.dataset.street : ''}${map.dataset.house !== '' ? ', будинок ' + map.dataset.house + ',' : ''}`,
        address: `${map.dataset.street !== '' ? map.dataset.street : ''}${map.dataset.house !== '' ? ', будинок ' + map.dataset.house + ',' : ''}`,
        postcode: map.dataset.postcode,
        region: map.dataset.region,
        //lat: map.dataset.lat,
        lat: `${tmpLat[0]}.${tmpLat[1].slice(0, 5)}`,
        //lng: map.dataset.lng,
        lng: `${tmpLng[0]}.${tmpLng[1].slice(0, 5)}`,
        locality: map.dataset.city,
        street: map.dataset.street
    }
    if (map.dataset.country === 'Україна' || map.dataset.country === 'Донецька область'){
        if(map.dataset.country === 'Донецька область'){
            newAddress.country = 'Україна'
        }else{
            newAddress.country = map.dataset.country
        }
    }
    props.setDataAddress(newAddress.country, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_COUNTRY_NAME')
    props.setDataAddress(newAddress.region, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_REGION')
    props.setDataAddress(newAddress.locality, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_LOCALITY')
    props.setDataAddress(newAddress.address, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_STREET_ADDRESS')
    props.setDataAddress(newAddress.postcode, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_POSTAL_CODE')

    props.setDataAddress(newAddress.lat, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE')
    props.setDataAddress(newAddress.lng, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE')
}

function blokedInput(props, type){
    if(props.createItems.landRental.id !== null){
        return disabletElem(props.auctionsMy, type)
    }else if(
        props.auctionsMy.items === null ||
        props.auctionsMy.items.length === 0
    ){
        return disabletElem(props.auctionsMy, type)
    }else{
        return 'disabled'
    }
}

function blockedInput(props){
    if(props.auctionsMy.status !== 'active_rectification'){
        return 'disabled'
    }
    return null
}

function viewPowerSupplyCapacity(props, closeDraftElem){
    if(props.createItems.zu.realEstateItem.reProps.serviceElectricity === 'true' || props.createItems.zu.realEstateItem.reProps.serviceElectricity === true){
        return (
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Потужність електромережі, кВт"
                        variant="outlined"
                        value={props.createItems.zu.realEstateItem.reProps.powerSupplyCapacity}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SUPPLY_CAPACITY')
                        }}
                    />
                </div>
            </div>
        )
    }
}

function classificationIdOption(props){
    if(props.classificationsDgf.classificationsByTypeMain !== null){
        return props.classificationsDgf.classificationsByTypeMain.map(
            (i, count) => {
                return <option key={count} value={i.id}>{i.scheme + ' ' + i.classificationId + ' ' + i.description}</option>
            }
        )
    }
}
function addClassificationIdOption(props){
    if(props.classificationsDgf.classificationsAdd !== null){
        return props.classificationsDgf.classificationsAdd.map(
            (i, count) => {
                return <option key={count} value={i[0].id}>{i[0].scheme + ' ' + i[0].classificationId + ' ' + i[0].description}</option>
            }
        )
    }
}

function redBlock(props, amount){
    if(props.auctionsMy === undefined || props.auctionsMy === 'undefined'){
        return null
    }else{
        if(props.auctionsMy !== null){
            if(
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
            ){
                if(amount === null || amount === '' || amount === 'null'){
                    return 'error'
                }
                return null
            }
        }
    }
    return null
}

function viewButtons(props){
    if(
        props.auctionsMy.items === null ||
        props.auctionsMy.items.length === 0
    ){
        if(
            //props.createItems.zu.realEstateItem.address !== 'null' &&
            //props.createItems.zu.realEstateItem.location.latitude !== '' &&
            //props.createItems.zu.realEstateItem.location.longitude !== '' &&
            props.createItems.zu.realEstateItem.reProps.totalObjectArea !== '' &&
            props.createItems.zu.realEstateItem.unitCode !== 'null'
        ){     
            return (
                <div className={styles.lineButtons}>
                    <div className={styles.editButton}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={
                                () => {
                                    createItemZU(props)
                                }
                            }
                        >
                            Зберегти зміни
                        </Button>
                    </div>
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={
                                () => {
                                    props.setDataAddress('null', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')

                                    props.setDataAddress(null, 'CREATE_ITEMS_REAL_ESTATEITEM_ID')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_TITLE')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_DESCRIPTION')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_CLASSIFICATION')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_ADDRESS')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_LOCATION_LATITUDE')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_LOCATION_LONGITUDE')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_ID')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_DATE')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_QUANTITY')
                                    //props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_UNIT_CODE')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_TOTAL_BUILDING_AREA')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_TOTAL_OBJECT_AREA')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_USABLE_AREA')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_CONSTRUCTION_YEAR')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_CONSTRUCTION_TECHNOLOGY')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LIVING_AREA')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_KITCHEN_AREA')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LAND_AREA')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LOCATION_IN_BUILDING')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_FLOORS')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_GENERAL_CONDITION')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_ELECTRICITY')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SUPPLY_CAPACITY')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SUPPLY_CLASS')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_WATER')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_SEWERAGE')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_GAS')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_CENTRAL_HEATING')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_AUTONOMOUS_HEATING')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_HEATING_COUNTER')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_VENTILATION')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_AIR_CONDITIONING')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_PHONE')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_TV')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_INTERNET')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_ELEVATOR')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_SECURITY_ALARM')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_FIRE_ALARM')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SERVICES_DESCRIPTION')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SERVICES_ACCOUNTING')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LAND_TAX')
                                }
                            }
                        >
                            Закрити
                        </Button>
                    </div>
                </div>
            )
        }
    }else{
        if(
            //props.createItems.zu.realEstateItem.address !== 'null' &&
            //props.createItems.zu.realEstateItem.location.latitude !== '' &&
            //props.createItems.zu.realEstateItem.location.longitude !== '' &&
            props.createItems.zu.realEstateItem.reProps.totalObjectArea !== '' &&
            props.createItems.zu.realEstateItem.unitCode !== 'null'
        ){
            return (
                <div className={styles.lineButtons}>
                    <div className={styles.editButton}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={
                                () => {
                                    if(props.createItems.zu.realEstateItem.id === null){
                                        createItemZU(props)
                                    }else{
                                        saveItemZU(props)
                                    }
                                }
                            }
                        >
                            Зберегти зміни
                        </Button>
                    </div>
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={
                                () => {
                                    props.setDataAddress('null', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')

                                    props.setDataAddress(null, 'CREATE_ITEMS_REAL_ESTATEITEM_ID')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_TITLE')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_DESCRIPTION')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_CLASSIFICATION')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_ADDRESS')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_LOCATION_LATITUDE')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_LOCATION_LONGITUDE')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_ID')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_DATE')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_QUANTITY')
                                    //props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_UNIT_CODE')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_TOTAL_BUILDING_AREA')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_TOTAL_OBJECT_AREA')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_USABLE_AREA')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_CONSTRUCTION_YEAR')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_CONSTRUCTION_TECHNOLOGY')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LIVING_AREA')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_KITCHEN_AREA')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LAND_AREA')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LOCATION_IN_BUILDING')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_FLOORS')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_GENERAL_CONDITION')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_ELECTRICITY')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SUPPLY_CAPACITY')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SUPPLY_CLASS')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_WATER')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_SEWERAGE')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_GAS')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_CENTRAL_HEATING')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_AUTONOMOUS_HEATING')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_HEATING_COUNTER')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_VENTILATION')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_AIR_CONDITIONING')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_PHONE')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_TV')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_INTERNET')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_ELEVATOR')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_SECURITY_ALARM')
                                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_FIRE_ALARM')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SERVICES_DESCRIPTION')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SERVICES_ACCOUNTING')
                                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LAND_TAX')
                                }
                            }
                        >
                            Закрити
                        </Button>
                    </div>
                </div>
            )
        }
    }
}

function textData(value){
    let tmp = ''
    if(value !== ''){
        tmp = value.split('T');
        return tmp[0]
    }
    return tmp[0]
}

function elementDisabled(props){
    let tmpStatus = null;
    if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            tmpStatus = 'disabled'
        }
    }
    return tmpStatus
}

function classificationSubsoilUnitCode(props){
    if(props.classificationsZU.unitCode !== null){
        return props.classificationsZU.unitCode
        .filter(
            (i) => {
                return i.code === 'MTK'
            }
        )
        .map(
            (i, count) => {
                return <option key={count} value={i.code}>{i.name}</option>
            }
        )
    }
}

function addressArr(props){
    if(props.addressGetId !== null){
        return props.addressGetId.map(
            (i, count) => {
                return <option key={count} value={i.id}>{i.postalCode + ' ' + i.region + ' ' + i.locality + ' ' + i.streetAddress}</option>
            }
        )
    }
}

function elementDisabledCreate(props){
    let tmpStatus = null;
    if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            tmpStatus = 'disabled'
        }
    }
    return tmpStatus
}

async function createItemZU(props){
    props.changeLoader(true)
    //let tmpAdress = await createAddress(props)
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            let body = {}
            if(props.createItems.zu.realEstateItem.title !== '') body.title = props.createItems.zu.realEstateItem.title
            if(props.createItems.zu.realEstateItem.description !== '') body.description = props.createItems.zu.realEstateItem.description
            if(props.createItems.zu.realEstateItem.classification !== '') body.classificationId = Number(props.createItems.zu.realEstateItem.classification)
            //additionalClassificationIds: [props.createItems.zu.realEstateItem.additionalClassification],
            //if(props.createItems.zu.realEstateItem.address !== '') body.addressId = Number(props.createItems.zu.realEstateItem.address)
            /*if(props.createItems.zu.realEstateItem.location.latitude !== '' && props.createItems.zu.realEstateItem.location.longitude !== ''){
                body.location = {
                    latitude: Number(props.createItems.zu.realEstateItem.location.latitude),
                    longitude: Number(props.createItems.zu.realEstateItem.location.longitude),
                }
            }*/
            //body.addressId = tmpAdress
            body.address = {
                addressIdentifier: {
                    scheme: 'koatuu',
                    identifier: setKoatuu(props.createItems.landRental.addressItem.region),
                    name: props.createItems.landRental.addressItem.region
                },
                streetAddress: props.createItems.landRental.addressItem.streetAddress,
                locality: props.createItems.landRental.addressItem.locality,
                region: props.createItems.landRental.addressItem.region,
                postalCode: props.createItems.landRental.addressItem.postalCode,
                countryName: props.createItems.landRental.addressItem.countryName
            }
            body.location = {
                latitude: Number(props.createItems.landRental.location.latitude),
                longitude: Number(props.createItems.landRental.location.longitude),
            }
            if(props.createItems.zu.realEstateItem.itemType !== '') body.itemType = props.createItems.zu.realEstateItem.itemType
            if(props.createItems.zu.realEstateItem.quantity !== '') {
                body.quantity = props.createItems.zu.realEstateItem.quantity
            }else{
                body.quantity = 0.00001
            }
            if(props.createItems.zu.realEstateItem.unitCode !== '') body.unitCode = props.createItems.zu.realEstateItem.unitCode
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}/item`;
            const response = await fetch(endPoint, {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
                props.changeLoader(false)
            }else{
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    props.changeLoader(false)
                    let tmp = JSON.stringify({title: 'Помилка при створенні лоту', message: JSON.stringify(json)})
                    props.setUniversalError(tmp)
                }else{
                    await createRegDetal(props, json.id)
                    await saveItemZuVehicleProps(props, json.id, 'POST')
                    props.setDataAddress('null', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')

                    props.setDataAddress(null, 'CREATE_ITEMS_REAL_ESTATEITEM_ID')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_TITLE')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_DESCRIPTION')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_CLASSIFICATION')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_ADDRESS')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_LOCATION_LATITUDE')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_LOCATION_LONGITUDE')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_ID')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_DATE')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_QUANTITY')
                    //props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_UNIT_CODE')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_TOTAL_BUILDING_AREA')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_TOTAL_OBJECT_AREA')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_USABLE_AREA')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_CONSTRUCTION_YEAR')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_CONSTRUCTION_TECHNOLOGY')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LIVING_AREA')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_KITCHEN_AREA')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LAND_AREA')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LOCATION_IN_BUILDING')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_FLOORS')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_GENERAL_CONDITION')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_ELECTRICITY')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SUPPLY_CAPACITY')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SUPPLY_CLASS')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_WATER')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_SEWERAGE')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_GAS')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_CENTRAL_HEATING')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_AUTONOMOUS_HEATING')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_HEATING_COUNTER')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_VENTILATION')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_AIR_CONDITIONING')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_PHONE')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_TV')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_INTERNET')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_ELEVATOR')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_SECURITY_ALARM')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_FIRE_ALARM')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SERVICES_DESCRIPTION')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SERVICES_ACCOUNTING')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LAND_TAX')
                }
            }
        }
    }
}

async function createAddress(props){
    let endPoint = null
    let tmpMetod = null
    if(props.createItems.landRental.addressItem.id === null){
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/address`;
        tmpMetod = 'POST'
    }else{
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/address/${props.createItems.landRental.addressItem.id}`;
        tmpMetod = 'PUT'
    }
    const response = await fetch(endPoint, {
        method: tmpMetod,
        body: JSON.stringify({
            addressIdentifier: {
                scheme: 'koatuu',
                identifier: setKoatuu(props.createItems.landRental.addressItem.region),
                name: props.createItems.landRental.addressItem.region
            },
            streetAddress: props.createItems.landRental.addressItem.streetAddress,
            locality: props.createItems.landRental.addressItem.locality,
            region: props.createItems.landRental.addressItem.region,
            postalCode: props.createItems.landRental.addressItem.postalCode,
            countryName: props.createItems.landRental.addressItem.countryName
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json1 = await response.json()
        if(json1.hasOwnProperty('message') === true){
            //await props.setPopAddressError(json1)
        }else{
            return await json1.id
        }
    }
}

function setKoatuu(data){
	switch(data){
        case 'Севастополь': return "8500000000"
        case 'Київ': return "8000000000"
        case 'Чернігівська область': return "7400000000"
        case 'Чернівецька область': return "7300000000"
        case 'Черкаська область': return "7100000000"
        case 'Хмельницька область': return "6800000000"
        case 'Херсонська область': return "6500000000"
        case 'Харківська область': return "6300000000"
        case 'Тернопільська область': return "6100000000"
        case 'Сумська область': return "5900000000"
        case 'Рівненська область': return "5600000000"
        case 'Полтавська область': return "5300000000"
        case 'Одеська область': return "5100000000"
        case 'Миколаївська область': return "4800000000"
        case 'Львівська область': return "4600000000"
        case 'Луганська область': return "4400000000"
        case 'Кіровоградська область': return "3500000000"
        case 'Київська область': return "3200000000"
        case 'Івано-Франківська область': return "2600000000"
        case 'Запорізька область': return "2300000000"
        case 'Закарпатська область': return "2100000000"
        case 'Житомирська область': return "1800000000"
        case 'Донецька область': return "1400000000"
        case 'Дніпропетровська область': return "1200000000"
        case 'Волинська область': return "0700000000"
        case 'Вінницька область': return "0500000000"
        case 'Автономна Республіка Крим': return "0100000000"
	}
}

async function createRegDetal(props, jsonId){
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            let body = {}
            if(
                props.createItems.zu.realEstateItem.registrationDetails.registrationStatus !== '' &&
                props.createItems.zu.realEstateItem.registrationDetails.registrationStatus !== 'null' &&
                props.createItems.zu.realEstateItem.registrationDetails.registrationStatus !== null
            ){
                body.registrationStatus = props.createItems.zu.realEstateItem.registrationDetails.registrationStatus
            }
            if(
                props.createItems.zu.realEstateItem.registrationDetails.registrationID !== '' &&
                props.createItems.zu.realEstateItem.registrationDetails.registrationID !== 'null' &&
                props.createItems.zu.realEstateItem.registrationDetails.registrationID !== null
            ){
                body.registrationID = props.createItems.zu.realEstateItem.registrationDetails.registrationID
            }
            if(
                props.createItems.zu.realEstateItem.registrationDetails.registrationDate !== '' &&
                props.createItems.zu.realEstateItem.registrationDetails.registrationDate !== 'null' &&
                props.createItems.zu.realEstateItem.registrationDetails.registrationDate !== null
            ){
                body.registrationDate = props.createItems.zu.realEstateItem.registrationDetails.registrationDate
            }
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}/item/${jsonId}/registration_details`;
            const response = await fetch(endPoint, {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
                props.changeLoader(false)
            }else{
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    props.changeLoader(false)
                    let tmp = JSON.stringify({title: 'Помилка при збереженні даних реєстрації', message: JSON.stringify(json)})
                    props.setUniversalError(tmp)
                }
            }
        }
    }
}

async function saveItemZU(props){
    props.changeLoader(true)
    //let tmpAdress = await createAddress(props)
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            let body = {}
            if(props.createItems.zu.realEstateItem.title !== '') body.title = props.createItems.zu.realEstateItem.title
            if(props.createItems.zu.realEstateItem.description !== '') body.description = props.createItems.zu.realEstateItem.description
            if(props.createItems.zu.realEstateItem.classification !== '') body.classificationId = Number(props.createItems.zu.realEstateItem.classification)
            //additionalClassificationIds: [props.createItems.zu.realEstateItem.additionalClassification],
            /*if(props.createItems.zu.realEstateItem.address !== '') body.addressId = Number(props.createItems.zu.realEstateItem.address)
            if(props.createItems.zu.realEstateItem.location.latitude !== '' && props.createItems.zu.realEstateItem.location.longitude !== ''){
                body.location = {
                    latitude: Number(props.createItems.zu.realEstateItem.location.latitude),
                    longitude: Number(props.createItems.zu.realEstateItem.location.longitude),
                }
            }*/
            //body.addressId = tmpAdress
            body.address = {
                addressIdentifier: {
                    scheme: 'koatuu',
                    identifier: setKoatuu(props.createItems.landRental.addressItem.region),
                    name: props.createItems.landRental.addressItem.region
                },
                streetAddress: props.createItems.landRental.addressItem.streetAddress,
                locality: props.createItems.landRental.addressItem.locality,
                region: props.createItems.landRental.addressItem.region,
                postalCode: props.createItems.landRental.addressItem.postalCode,
                countryName: props.createItems.landRental.addressItem.countryName
            }
            body.location = {
                latitude: Number(props.createItems.landRental.location.latitude),
                longitude: Number(props.createItems.landRental.location.longitude),
            }
            if(props.createItems.zu.realEstateItem.itemType !== '') body.itemType = props.createItems.zu.realEstateItem.itemType
            if(props.createItems.zu.realEstateItem.quantity !== '') {
                body.quantity = props.createItems.zu.realEstateItem.quantity
            }else{
                body.quantity = 0.00001
            }
            if(props.createItems.zu.realEstateItem.unitCode !== '') body.unitCode = props.createItems.zu.realEstateItem.unitCode
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}/item/${props.createItems.zu.realEstateItem.id}`;
            const response = await fetch(endPoint, {
                method: "PUT",
                body: JSON.stringify(body),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
                props.changeLoader(false)
            }else{
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    props.changeLoader(false)
                    let tmp = JSON.stringify({title: 'Помилка при оновленні лоту', message: JSON.stringify(json)})
                    props.setUniversalError(tmp)
                }else{
                    await createRegDetal(props, json.id)
                    await saveItemZuVehicleProps(props, json.id, 'POST')
                    props.setDataAddress('null', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')

                    props.setDataAddress(null, 'CREATE_ITEMS_REAL_ESTATEITEM_ID')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_TITLE')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_DESCRIPTION')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_CLASSIFICATION')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_ADDRESS')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_LOCATION_LATITUDE')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_LOCATION_LONGITUDE')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_ID')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_DATE')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_QUANTITY')
                    //props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_UNIT_CODE')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_TOTAL_BUILDING_AREA')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_TOTAL_OBJECT_AREA')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_USABLE_AREA')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_CONSTRUCTION_YEAR')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_CONSTRUCTION_TECHNOLOGY')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LIVING_AREA')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_KITCHEN_AREA')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LAND_AREA')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LOCATION_IN_BUILDING')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_FLOORS')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_GENERAL_CONDITION')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_ELECTRICITY')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SUPPLY_CAPACITY')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SUPPLY_CLASS')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_WATER')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_SEWERAGE')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_GAS')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_CENTRAL_HEATING')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_AUTONOMOUS_HEATING')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_HEATING_COUNTER')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_VENTILATION')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_AIR_CONDITIONING')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_PHONE')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_TV')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_INTERNET')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_ELEVATOR')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_SECURITY_ALARM')
                    props.setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_FIRE_ALARM')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SERVICES_DESCRIPTION')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SERVICES_ACCOUNTING')
                    props.setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LAND_TAX')
                }
            }
        }
    }
}

async function saveItemZuVehicleProps(props, itemId, flag){
    let body = {}
    if(props.createItems.zu.realEstateItem.reProps.totalBuildingArea !== '') body.totalBuildingArea = props.createItems.zu.realEstateItem.reProps.totalBuildingArea
    if(props.createItems.zu.realEstateItem.reProps.totalObjectArea !== '') {
        body.totalObjectArea = props.createItems.zu.realEstateItem.reProps.totalObjectArea
    }else{
        body.totalObjectArea = 0.00001
    }
    if(props.createItems.zu.realEstateItem.reProps.usableArea !== '') body.usableArea = props.createItems.zu.realEstateItem.reProps.usableArea
    if(props.createItems.zu.realEstateItem.reProps.constructionYear !== '') body.constructionYear = props.createItems.zu.realEstateItem.reProps.constructionYear
    if(props.createItems.zu.realEstateItem.reProps.constructionTechnology !== null && props.createItems.zu.realEstateItem.reProps.constructionTechnology !== 'null') body.constructionTechnology = props.createItems.zu.realEstateItem.reProps.constructionTechnology
    if(props.createItems.zu.realEstateItem.reProps.livingArea !== '') body.livingArea = props.createItems.zu.realEstateItem.reProps.livingArea
    if(props.createItems.zu.realEstateItem.reProps.kitchenArea !== '') body.kitchenArea = props.createItems.zu.realEstateItem.reProps.kitchenArea
    if(props.createItems.zu.realEstateItem.reProps.landArea !== '') body.landArea = props.createItems.zu.realEstateItem.reProps.landArea
    if(props.createItems.zu.realEstateItem.reProps.locationInBuilding !== null && props.createItems.zu.realEstateItem.reProps.locationInBuilding !== 'null') body.locationInBuilding = props.createItems.zu.realEstateItem.reProps.locationInBuilding
    if(props.createItems.zu.realEstateItem.reProps.floors !== '') body.floors = props.createItems.zu.realEstateItem.reProps.floors
    if(props.createItems.zu.realEstateItem.reProps.generalCondition !== '') body.generalCondition = props.createItems.zu.realEstateItem.reProps.generalCondition
    if(props.createItems.zu.realEstateItem.reProps.serviceElectricity !== null && props.createItems.zu.realEstateItem.reProps.serviceElectricity !== 'null') body.serviceElectricity = isBoolean(props.createItems.zu.realEstateItem.reProps.serviceElectricity)
    if(props.createItems.zu.realEstateItem.reProps.powerSupplyCapacity !== null && props.createItems.zu.realEstateItem.reProps.powerSupplyCapacity !== 'null') body.powerSupplyCapacity = isBoolean(props.createItems.zu.realEstateItem.reProps.powerSupplyCapacity)
    if(props.createItems.zu.realEstateItem.reProps.serviceWater !== null && props.createItems.zu.realEstateItem.reProps.serviceWater !== 'null') body.serviceWater = isBoolean(props.createItems.zu.realEstateItem.reProps.serviceWater)
    if(props.createItems.zu.realEstateItem.reProps.serviceSewerage !== null && props.createItems.zu.realEstateItem.reProps.serviceSewerage !== 'null') body.serviceSewerage = isBoolean(props.createItems.zu.realEstateItem.reProps.serviceSewerage)
    if(props.createItems.zu.realEstateItem.reProps.serviceGas !== null && props.createItems.zu.realEstateItem.reProps.serviceGas !== 'null') body.serviceGas = isBoolean(props.createItems.zu.realEstateItem.reProps.serviceGas)
    if(props.createItems.zu.realEstateItem.reProps.serviceCentralHeating !== null && props.createItems.zu.realEstateItem.reProps.serviceCentralHeating !== 'null') body.serviceCentralHeating = isBoolean(props.createItems.zu.realEstateItem.reProps.serviceCentralHeating)
    if(props.createItems.zu.realEstateItem.reProps.serviceAutonomousHeating !== null && props.createItems.zu.realEstateItem.reProps.serviceAutonomousHeating !== 'null') body.serviceAutonomousHeating = isBoolean(props.createItems.zu.realEstateItem.reProps.serviceAutonomousHeating)
    if(props.createItems.zu.realEstateItem.reProps.serviceHeatingCounter !== null && props.createItems.zu.realEstateItem.reProps.serviceHeatingCounter !== 'null') body.serviceHeatingCounter = isBoolean(props.createItems.zu.realEstateItem.reProps.serviceHeatingCounter)
    if(props.createItems.zu.realEstateItem.reProps.serviceVentilation !== null && props.createItems.zu.realEstateItem.reProps.serviceVentilation !== 'null') body.serviceVentilation = isBoolean(props.createItems.zu.realEstateItem.reProps.serviceVentilation)
    if(props.createItems.zu.realEstateItem.reProps.serviceAirConditioning !== null && props.createItems.zu.realEstateItem.reProps.serviceAirConditioning !== 'null') body.serviceAirConditioning = isBoolean(props.createItems.zu.realEstateItem.reProps.serviceAirConditioning)
    if(props.createItems.zu.realEstateItem.reProps.servicePhone !== null && props.createItems.zu.realEstateItem.reProps.servicePhone !== 'null') body.servicePhone = isBoolean(props.createItems.zu.realEstateItem.reProps.servicePhone)
    if(props.createItems.zu.realEstateItem.reProps.serviceTV !== null && props.createItems.zu.realEstateItem.reProps.serviceTV !== 'null') body.serviceTV = isBoolean(props.createItems.zu.realEstateItem.reProps.serviceTV)
    if(props.createItems.zu.realEstateItem.reProps.serviceInternet !== null && props.createItems.zu.realEstateItem.reProps.serviceInternet !== 'null') body.serviceInternet = isBoolean(props.createItems.zu.realEstateItem.reProps.serviceInternet)
    if(props.createItems.zu.realEstateItem.reProps.serviceElevator !== null && props.createItems.zu.realEstateItem.reProps.serviceElevator !== 'null') body.serviceElevator = isBoolean(props.createItems.zu.realEstateItem.reProps.serviceElevator)
    if(props.createItems.zu.realEstateItem.reProps.serviceSecurityAlarm !== null && props.createItems.zu.realEstateItem.reProps.serviceSecurityAlarm !== 'null') body.serviceSecurityAlarm = isBoolean(props.createItems.zu.realEstateItem.reProps.serviceSecurityAlarm)
    if(props.createItems.zu.realEstateItem.reProps.serviceFireAlarm !== null && props.createItems.zu.realEstateItem.reProps.serviceFireAlarm !== 'null') body.serviceFireAlarm = isBoolean(props.createItems.zu.realEstateItem.reProps.serviceFireAlarm)

    if(props.createItems.zu.realEstateItem.reProps.servicesDescription !== '') body.servicesDescription = props.createItems.zu.realEstateItem.reProps.servicesDescription
    if(props.createItems.zu.realEstateItem.reProps.servicesAccounting !== '') body.servicesAccounting = props.createItems.zu.realEstateItem.reProps.servicesAccounting
    if(props.createItems.zu.realEstateItem.reProps.landTax !== '') body.landTax = props.createItems.zu.realEstateItem.reProps.landTax
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}/item/${itemId}/property_props/RealEstateProps`;
    const response = await fetch(endPoint, {
        method: flag,
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        props.changeLoader(false)
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            props.changeLoader(false)
            let tmp = JSON.stringify({title: 'Помилка при збереженні доданих даних', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            let header = {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
            const endPoint2t = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}`;
            const response2t = await fetch(endPoint2t, {
                    method: "GET",
                    headers: header
            }).catch(console.error)
            if(response2t === undefined || response2t === 'undefined'){
                props.changeLoader(false)
            }else{
                const json2t = await response2t.json()
                if(json2t.hasOwnProperty('message') === true){
                    props.changeLoader(false)
                    let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json2t)})
	                props.setUniversalError(tmp)
                }else{
                    props.getMyAuction(json2t)
                    props.changeLoader(false)
                }
            }
        }
    }
}

function isBoolean(data){
	if(data === true || data === 'true') return true
	return false
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        auctionsMy: state.start.auctionsMy,
        createItems: state.start.createItems,
        dictionaries: state.start.dictionaries,
        addressGetId: state.start.addressGetId,
        classificationsNadraEnglish: state.start.classificationsNadraEnglish,
        userProfiles: state.start.userProfiles,
        classificationsDgf: state.start.classificationsDgf,
        classificationsZU: state.start.classificationsZU,
        auctionsZUAttemp: state.start.auctionsZUAttemp
    }
}

const mapDispatchToProps = {
    setDataAddress,
    changeLoader,
    getMyAuction,
    popUpAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(RealEstateItem)