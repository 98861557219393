import React from 'react';
import styles from './auctionDataLine_NameOrganithation.module.css';

import {connect} from 'react-redux';

const AuctionDataLine_NameOrganithation = ( props ) => {
    return <div className={styles.secondBlock_textPrice}>{viewName(props)} пропонує</div>
}

function viewName(props){
    let tmp = null;
    if(props.item.hasOwnProperty('procurementEntity') === true){
        if(props.item.procurementEntity.hasOwnProperty('name') === true){
            tmp = props.item.title
        }
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
    }
}

export default connect(mapStateToProps, null)(AuctionDataLine_NameOrganithation)