import React from 'react';
import styles from './blueBlockRegistr.module.css';

export default function BlueBlockRegistr() {
    return (
        <div className={styles.blueBlock}>
            <div>Зареєструйтесь та візьміть участь в аукціоні</div>
            <div className={styles.blueBlock_wrap}></div>
            <div>або авторизуйтеся, якщо Ви вже є Учасником</div>
        </div>
    )
}