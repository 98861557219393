import React, {useState, useEffect} from 'react';
import styles from './cse.module.css';

import {connect, useDispatch} from 'react-redux';
import {getMyAuction} from '../../../../redux/actions.js';
import {setDataAddress} from '../../../../redux/createAddress';
import { changeLoader } from '../../../../redux/actions.js';

import Select from '../../../elements/inputs/inputSelect';

import ItemsList from './itemsList/itemsList.js';

import ReturnButton from '../../returnButton'
import Sidebar from '../../auctionEditNew/components/sidebar/sidebar'

import LandItem from './items/landItem'
import OtherPropertyItemNew from './items/otherPropertyItemNew'
import JointPropertyComplexItem from './items/jointPropertyComplexItem'
import VehicleItem from './items/vehicleItem'
import RealEstateItem from './items/realEstateItem'
import { unitsGetByTypeZU } from '../../../../redux/actions/itemUnits/itemUnits';

import { clearDataLot } from '../basicSell/clearDataItem';

// import CreateAuctionLoader from '../../createAuctionLoader.js';
import { checkProp } from '../../../../redux/check';


const LAE = ( props ) => {
    const [edit, setEdit] = useState(false)
    const [editData, setData] = useState(null)
    const dispatch = useDispatch()
    // const [classification, setClassification] = useState( checkProp(editData.classification, 'id') ? editData.classification.id : null)

    useEffect(() => {
        getAdditionalClassificationKvtspz(props)
        if(props.auctionsMy.items && props.auctionsMy.items.length === 0) setEdit(true)
        if(props.classificationsZU === null){
            let data = {
                domen: process.env.REACT_APP_END_POINT_BD,
                version: 'v1',
                local: props.langFlag,
                // params: 'timber-english?limit=100',
                header:{
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }
            dispatch(unitsGetByTypeZU(data, data.header))
        }
        props.setDataAddress('06', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
        smartClasif(props, '06')
    }, [])
        
    let tmpText = 'Додавання лота';
    if(props.auctionsMy !== null && props.auctionsMy.items !== null && !edit && props.auctionsMy.items.length === 0){
        if(!edit && props.auctionsMy.items.length === 0) tmpText = 'Додайте лот'
        if(!edit && props.auctionsMy.items.length > 0) tmpText = 'Список лотів'
        if(edit && checkProp(editData, 'id')) tmpText = 'Додавання лота'
        if(props.auctionsMy.items.length > 0 && editData !== null && edit ) tmpText = 'Редагування лота'
    }else{
        tmpText = 'Додайте лот'
    }

    function whatToRender(){
        if (!edit) {
            return  <div className={styles.wrapContent}>
                        <div className={styles.headers}></div>
                        <ItemsList edit={setEdit} setEdit={setData} />
                    </div>
        }
        if (edit || editData !== {}){
            return <div className={styles.wrapContent}>
                    {viewSelectTypeItems(props)}
                    {/*viewMainClassif(props)*/}
                    {viewTypeItems(props, setEdit, editData)}
                </div>
        }
        if (edit || editData === {}){
            return <div className={styles.wrapContent}>
                    {viewSelectTypeItems(props)}
                    {/*viewMainClassif(props)*/}
                    {viewTypeItems(props, setEdit)}
                </div>
        }
    }
    
    return (
        <div className={styles.mainWrap}>
            <Sidebar data={props.auctionsMy} />
            <div className={styles.wrapBlock}>
                <div className={styles.wrapBlockTitle}>
                    {tmpText}
                    {
                        !edit
                        ? props?.auctionsMy?.items?.length === 0
                            ? <button className={styles.editButton} 
                                    onClick={()=> {
                                    setEdit(true)
                                    setData(null)
                                    props.setDataAddress('06', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
                                    smartClasif(props, '06')
                                }}>
                                    Додати лот
                                </button>
                            : null
                        : <button className={styles.cancel} 
                                onClick={()=> {
                                clearDataLot(props)
                                setEdit(false)
                                setData(null)
                            }}>
                                Скасувати
                            </button>
                    }
                </div>
                {whatToRender()}
                <div className={styles.buttonBack}>
                    <ReturnButton />
                </div>
            </div>
            
        </div>
    )
}

async function getAdditionalClassificationKvtspz(props){
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/dictionaries/kvtspz?limit=1000`;
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/byScheme/cvzu`;
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/classifications/byScheme/CVZU`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        await props.setDataAddress(json, 'CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATIONS_ARR')
    }
}

function viewSelectTypeItems(props){
    let tmp = null
    if(
        props.createItems.zu.vehicleItem.id !== null ||
        props.createItems.zu.realEstateItem.id !== null ||
        props.createItems.landRental.id !== null ||
        props.createItems.zu.jointPropertyComplexItem.id !== null
    ){
        tmp = 'disabled'
    }
    return (
        <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
            <div>
                <Select
                    data={{
                        label: "Основний класифікатор",
                        target: props.createItems.zu.baseClasif,
                        info: 'Дане поле автогенеруються на рівні ЦБД'
                    }}
                    onChange={(event) => {
                        props.setDataAddress(event, 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
                        props.setDataAddress(null, 'SET_CLASSIFICATIONS_BSE_BSD')
                        props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_CLASSIFICATION')
                        smartClasif(props, event)
                    }}
                    options={mainCAV}
                    disabled={true}
                />
            </div>
        </div>
    )
}

const mainCAV = [
    {key: '06000000-2', value: '06', name: 'CAV 06 Земельні ділянки (та усі вкладені коди)'},
]

async function smartClasif(props, data){
    let tmp0 = await getDictionaryJointPropertyComplex(props, data)
    props.setDataAddress(tmp0, 'SET_CLASSIFICATIONS_BSE_BSD')
}

function viewMainClassif(props){
    if(props.classificationsBseBsd !== null){ // TODO: CSE-CSD ??
        return (<>
            <Select
                    data={{
                        label: "Вкладені коди основного класифікатора",
                        target: props.createItems.landRental.classification,
                        info: 'Дане поле автогенеруються на рівні ЦБД'
                    }}
                    onChange={(event) => {
                        let tmp = null
                            if(props.classificationsBseBsd !== null){
                                tmp = props.classificationsBseBsd.filter(
                                    (i) => {
                                        return i.id === event
                                    }
                                )
                            }
                            if(tmp !== null && tmp.length > 0){
                                if(tmp[0].id !== null && tmp[0].id !== 'null'){
                                    if(
                                        tmp[0].id === '07121000-3' ||
                                        tmp[0].id === '07221000-4' ||
                                        (tmp[0].id[0] === '0' && tmp[0].id[1] === '4')
                                    ){
                                        props.setDataAddress('realEstate', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
                                    }else if(
                                        (tmp[0].id[0] === '0' && tmp[0].id[1] === '6') ||
                                        (
                                            tmp[0].id[0] === '0' &&
                                            tmp[0].id[1] === '7' &&
                                            tmp[0].id[2] === '1' &&
                                            tmp[0].id[3] === '2' &&
                                            tmp[0].id[4] === '3'
                                        ) ||
                                        (
                                            tmp[0].id[0] === '0' &&
                                            tmp[0].id[1] === '7' &&
                                            tmp[0].id[2] === '2' &&
                                            tmp[0].id[3] === '2' &&
                                            tmp[0].id[4] === '3'
                                        )
                                    ){
                                        props.setDataAddress('otherProperty', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
                                    }else if(
                                        tmp[0].id === '07126000-8' ||
                                        tmp[0].id === '07226000-9' ||
                                        (tmp[0].id[0] === '3' && tmp[0].id[1] === '4')
                                    ){
                                        props.setDataAddress('vehicle', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
                                    }else if(
                                        tmp[0].id === '07122000-0' ||
                                        tmp[0].id === '07222000-1' ||
                                        (tmp[0].id[0] === '0' && tmp[0].id[1] === '5')
                                    ){
                                        props.setDataAddress('jointPropertyComplex', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
                                    }else{
                                        props.setDataAddress(null, 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
                                    }
                                }
                                /*let arr = []
                                if(typeof props.createItems.landRental.classification === 'object'){
                                    arr = [...props.createItems.landRental.classification, tmp[0].id]
                                }else{
                                    arr = [tmp[0].id]
                                }
                                props.setDataAddress(arr, 'CREATE_ITEMS_LAND_RENTAL_CLASSIFICATION')*/
                                props.setDataAddress(tmp[0].id, 'CREATE_ITEMS_LAND_RENTAL_CLASSIFICATION')
                            }
                        }}
                    options={classificationIdOption(props)}
                    disabled={true}
                />
            </>
        )
    }else{
        if(props.createItems.zu.baseClasif !== 'null'){
            return (
                <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                    <div>
                        Зачекайте. Вкладені коди основного класифікатора завантажуються
                    </div>
                </div>
            )
        }
    }
}

function classificationIdOption(props){
    let collatore = new Intl.Collator('uk-UA')
    if(props.classificationsBseBsd !== null){
        let tmp = props.classificationsBseBsd
        tmp.sort(
            (a, b) => collatore.compare(a.id, b.id)
        )
        return tmp.map(
            (i) => {
                return { key: i.id, value: i.id, name: `${ i.scheme + ' ' + i.id + ' ' + i.description.uk_UA }`}
            }
        )
    }
}

function viewTypeItems(props, setEdit, data){
    return <LandItem edit={setEdit} data={data} classification={props.createItems.landRental.classification} />
}

async function getDictionaryJointPropertyComplex(props, mask){
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/autocomplete/classification_id?limit=1000&offset=0&classification_id=${mask}&scheme=CAV&mask=${mask}`;
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/classifications/autocomplete/classification_id?limit=1000&offset=0&classification_id=${mask}&scheme=CAV&mask=${mask}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        return await response.json()
    }
}

async function getAdditionalClassificationZU(props, mask){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/autocomplete/keyword?keyword=${mask}&scheme=CPVS&limit=100`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        return await response.json()
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        getAuctionsMy: state.start.auctionsGetMy,
        classificationsNadraEnglish: state.start.classificationsNadraEnglish,
        addressGetId: state.start.addressGetId,
        setMask: state.start.setMask,
        classificationsDgf: state.start.classificationsDgf,
        classificationsBseBsd: state.start.classificationsBseBsd,
        classificationsZU: state.start.classificationsZU.unitCode
    }
}

const mapDispatchToProps = {
    // getMyAuction,
    getMyAuction,
    setDataAddress,
    changeLoader,
    unitsGetByTypeZU
}

export default connect(mapStateToProps, mapDispatchToProps)(LAE)