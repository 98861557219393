import React from 'react';
import styles from './itemsList.module.css';

import {connect} from 'react-redux';

import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

import {auctionsGetAwards, auctionsGetAwardsId} from '../../../../redux/actions/awards/awards.js';
import {setUniversalError, changeLoader} from '../../../../redux/actions.js';
import {contractsAuctionIdGet} from '../../../../redux/actions/contracts/contracts.js';
import {
    changeContractApprowePopUpStatus,
    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,
    setContractDatePaid,
} from '../../../../redux/actions/contracts/contracts.js';

const ItemsList = ( props ) => {
    let tmp = null;
    if(props.contractAuctionIdGet !== null){
        tmp = props.contractAuctionIdGet.map(
            (i, count) => {
                let tmpContr = null
                if(props.auctionsMy.status !== 'unsuccessful' && props.auctionsMy.status !== 'cancelled' && props.auctionsMy.status !== 'complete'){
                    if(i.status === 'pending' || i.status === 'signed'){
                        tmpContr = (
                            <div className={styles.auctionsLineElem}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={
                                        () => {
                                            if(i.title !== null){
                                                if(props.timberContractPut.title === '') props.setContractTitle(i.title)
                                            }
                                            if(i.description !== null){
                                                if(props.timberContractPut.description === '') props.setContractDescription(i.description)
                                            }
                                            if(i.contractNumber !== null){
                                                if(props.timberContractPut.contractNumber === '') props.setContractContractNumber(i.contractNumber)
                                            }
                                            if(i.value !== null){
                                                if(i.value.amount !== null){
                                                    if(props.timberContractPut.xValueUAH.amount === null) props.setContractAmount(i.value.amount)
                                                    
                                                }
                                            }
                                            if(i.dateSigned !== null){
                                                if(props.timberContractPut.dateSigned === '') {
                                                    let tmp = i.dateSigned.split('+')
                                                    props.setContractDateSigned(tmp[0])
                                                }
                                            }
                                            if(i.x_prepaymentDetailsDate !== null){
                                                if(props.timberContractPut.contractTime.dateFrom === '') {
                                                    let tmp = i.x_prepaymentDetailsDate.split('+')
                                                    props.setContractDateFrom(tmp[0])
                                                }
                                            }
                                            if(i.contractTime !== null){
                                                if(i.contractTime.endDate !== null){
                                                    if(props.timberContractPut.contractTime.endDate === '') {
                                                        let tmp = i.contractTime.endDate.split('+')
                                                        props.setContractEndDate(tmp[0])
                                                    }
                                                }
                                                if(i.contractTime.startDate !== null){
                                                    if(props.timberContractPut.contractTime.startDate === '') {
                                                        let tmp = i.contractTime.startDate.split('+')
                                                        props.setContractStartDate(tmp[0])
                                                    }
                                                }
                                            }
                                            props.changeContractApprowePopUpStatus({
                                                aucId: i.award.auction.id,
                                                contractId: i.id,
                                                sellingMethod: i.award.auction.sellingMethod,
                                                count: count,
                                                fullAuction: i.award.auction,
                                                typeView: 1,
                                            })
                                        }
                                    }
                                >
                                    Документи контракту
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={
                                        () => {
                                            if(i.title !== null){
                                                if(props.timberContractPut.title === '') props.setContractTitle(i.title)
                                            }
                                            if(i.description !== null){
                                                if(props.timberContractPut.description === '') props.setContractDescription(i.description)
                                            }
                                            if(i.contractNumber !== null){
                                                if(props.timberContractPut.contractNumber === '') props.setContractContractNumber(i.contractNumber)
                                            }
                                            if(i.value.amount !== null){
                                                if(props.timberContractPut.xValueUAH.amount === null) props.setContractAmount(i.value.amount)
                                            }
                                            if(i.dateSigned !== null){
                                                if(props.timberContractPut.dateSigned === '') {
                                                    let tmp = i.dateSigned.split('+')
                                                    props.setContractDateSigned(tmp[0])
                                                }
                                            }
                                            if(i.x_prepaymentDetailsDate !== null){
                                                if(props.timberContractPut.contractTime.dateFrom === '') {
                                                    let tmp = i.x_prepaymentDetailsDate.split('+')
                                                    props.setContractDateFrom(tmp[0])
                                                }
                                            }
                                            if(i.contractTime !== null){
                                                if(i.contractTime.endDate !== null){
                                                    if(props.timberContractPut.contractTime.endDate === '') {
                                                        let tmp = i.contractTime.endDate.split('+')
                                                        props.setContractEndDate(tmp[0])
                                                    }
                                                }
                                                if(i.contractTime.startDate !== null){
                                                    if(props.timberContractPut.contractTime.startDate === '') {
                                                        let tmp = i.contractTime.startDate.split('+')
                                                        props.setContractStartDate(tmp[0])
                                                    }
                                                }
                                            }
                                            if(i.datePaid !== null){
                                                if(props.timberContractPut.datePaid === '') {
                                                    let tmp = i.datePaid.split('+')
                                                    props.setContractDatePaid(tmp[0])
                                                }
                                            }

                                            props.changeContractApprowePopUpStatus({
                                                aucId: i.award.auction.id,
                                                contractId: i.id,
                                                sellingMethod: i.award.auction.sellingMethod,
                                                count: count,
                                                fullAuction: i.award.auction,
                                                typeView: 2,
                                            })
                                        }
                                    }
                                >
                                    Дані контракту
                                </Button>
                                {viewApproveContract(
                                    props,
                                    {
                                        aucId: i.award.auction.id,
                                        contractId: i.id,
                                        sellingMethod: i.award.auction.sellingMethod,
                                        count: count,
                                        fullAuction: i.award.auction,
                                        typeView: 2,
                                    }
                                )}
                            </div>
                        )
                    }else{
                        tmpContr = (
                            <div className={styles.auctionsLineElem}>
                                <Button
                                    disabled="disabled"
                                    variant="contained"
                                    color="disabled"
                                >
                                    Документи контракту
                                </Button>
                                <Button
                                    disabled="disabled"
                                    variant="contained"
                                    color="disabled"
                                >
                                    Дані контракту
                                </Button>
                                {viewApproveContract(
                                    props,
                                    {
                                        aucId: i.award.auction.id,
                                        contractId: i.id,
                                        sellingMethod: i.award.auction.sellingMethod,
                                        count: count,
                                        fullAuction: i.award.auction,
                                        typeView: 2,
                                    }
                                )}
                            </div>
                        )
                    }
                }else{
                    tmpContr = (
                        <div>
                            Аукціон завершено, робота с документами заблоковано
                        </div>
                    )
                }
                return (
                    <div key={i.id} className={styles.auctionsLine}>
                        <div className={styles.auctionsLineElem}>
                            <div className={styles.documentsId}>Id контракту: {i.id}</div>
                            <div>Назва контракту: {i.title}</div>
                            {/*<div>Статус : {viewTextStatusBidUkr(i.status)}</div>*/}
                        </div>
                        {tmpContr}
                    </div>
                )
            }
        )
    }
    return tmp
}

async function promisApproveContract(props, data){
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${data.aucId}/contract/${data.contractId}/approve/set_status/active`
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        props.changeLoader(false)
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при затвердженні контракту', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            props.changeLoader(false)
        }else{
            let data1 = {
                domen: process.env.REACT_APP_END_POINT_BD,
                version: 'v1',
                local: props.langFlag
            }
            let headers1 = {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
            await props.contractsAuctionIdGet(data1, headers1, data.aucId)
            await props.changeLoader(false)
        }
    }
    await props.changeLoader(false)
}

function viewTextStatusBidUkr(status){
    switch(status){
        case 'pending':
            //return 'Очікується протокол'
            return 'Очікується договір'
        case 'pending_waiting':
            return 'Очікується рішення'
        case 'active':
            //return 'Переможець. Очікується договір'
            return 'Договір підтверджено'
        case 'unsuccessful':
            return 'Дискваліфіковано'
        case 'cancelled':
            return 'Учасник не став переможцем'
    }
    return status
}


function viewApproveContract(props, data){
    if(props.contractAuctionIdGet !== null){
        if(props.contractAuctionIdGet.length > 0){
            if(props.contractAuctionIdGet[data.count].status === 'pending' || props.contractAuctionIdGet[data.count].status === 'paid'){
                let tmpMainDoc = false
                if(props.contractAuctionIdGet[data.count].documents !== null){
                    if(props.contractAuctionIdGet[data.count].documents.length > 0){
                        for(let t = 0; t < props.contractAuctionIdGet[data.count].documents.length; t++){
                            if(props.contractAuctionIdGet[data.count].documents[t].documentType === 'contractSigned'){
                                //if(props.contractAuctionIdGet[data.count].documents[t].createdByCurrentUser === true){
                                if(props.contractAuctionIdGet[data.count].award.auction.createdByCurrentUser === true){
                                    tmpMainDoc = true
                                }
                            }
                        }
                    }
                }
                if(
                    props.contractAuctionIdGet[data.count].title !== null &&
                    props.contractAuctionIdGet[data.count].title !== '' &&
                    props.contractAuctionIdGet[data.count].contractNumber !== null &&
                    props.contractAuctionIdGet[data.count].contractNumber !== '' &&
                    props.contractAuctionIdGet[data.count].dateSigned !== null &&
                    props.contractAuctionIdGet[data.count].dateSigned !== '' &&
                    tmpMainDoc === true
                ){
                    return (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={
                                () => {
                                    promisApproveContract(props, data)
                                }
                            }
                        >
                            Затвердити контракт
                        </Button>
                    )
                }else{
                    return (
                        <Button
                            disabled
                            variant="contained"
                        >
                            Затвердити контракт
                        </Button>
                    )
                }
            }
        }
    }
    return (
        <Button
            disabled
            variant="contained"
        >
            Затвердити контракт
        </Button>
    )
}


//Авард апрув
async function awardApprove(props, aucId, awardId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${aucId}/award/${awardId}/approve`;
    const response1 = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
    }else{
        let data1 = {
            domen: process.env.REACT_APP_END_POINT_BD,
            version: 'v1',
            local: props.langFlag
        }
        let headers1 = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        props.auctionsGetAwards(data1, headers1, aucId)
    }
}


//Авард реджект
async function awardReject(props, aucId, awardId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${aucId}/award/${awardId}/set_status/unsuccessful`;
    const response1 = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
    }else{
        let data1 = {
            domen: process.env.REACT_APP_END_POINT_BD,
            version: 'v1',
            local: props.langFlag
        }
        let headers1 = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        props.auctionsGetAwards(data1, headers1, aucId)
    }
}

/*Авард создать документ
/api/{version}/{_locale}/auction/{auction}/award/{award}/documents/{document}
ну и нужна сама загрузка документа

Авард апрув документа
/api/{version}/{_locale}/auction/{auction}/award/{award}/approve_documents

Авард комплит верефикацион
/api/{version}/{_locale}/auction/{auction}/complete_verification
*/

async function  deleteDocument(props, id){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/document/${id}`;
    const response1 = await fetch(endPoint, {
        method: "DELETE",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
    }else{
        let data = {
            domen: process.env.REACT_APP_END_POINT_BD,
            version: 'v1',
            local: props.langFlag,
            params: '?limit=20'
        }
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        props.auctionsGetMy(data, header)
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy,
        auctionAwards: state.start.auctionAwards,
        contractAuctionIdGet: state.start.contractAuctionIdGet,
        timberContractPut: state.start.timberContractPut,
        getMyCurentBid: state.start.getMyCurentBid,
        myBids: state.start.myBids
    }
}

const mapDispatchToProps = {
    auctionsGetAwards,
    auctionsGetAwardsId,
    changeContractApprowePopUpStatus,

    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,
    setContractDatePaid,

    setUniversalError,
    contractsAuctionIdGet,
    changeLoader,
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemsList)
