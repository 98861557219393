import React from 'react';
import styles from './createAuction.module.css';

import {connect} from 'react-redux';

import {
    getMyAuction,
    changeCreateAuctionTitle,
    changeCreateAuctionDescription,
    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,
    changeCreateAuctionLotIdentifier,
    changeCreateAuctionTab,
    setCarryType,

    changeCreateItemClassificationId,
    changeCreateItemUnitValueCurrency,
    changeCreateItemUnitValueAmount,
    changeCreateItemUnitValueTax,
    changeCreateItemDescription,
    changeAdditionalClassificationIds,
    changeCreateItemQuantity,
    changeMyCabinetCurentPage,
} from '../../redux/actions.js';

import {setBankAccountSave, setBankAccountAdd, setBankAccountDelete} from '../../redux/actions/bankAccount/bankAccount.js';

import {
    changeAuctionsSwitchWindow,
    auctionsGetMy,
    setPopUpAuctionCreate,
    setPopUpAuctionMyNull,
    changeCreateItemGenerationType,
    changeCreateItemUnitCode,
} from '../../redux/actions/auctions/auctions.js';

import {changePopUpBankAccount, setPopBankAccountId, setPopBankAccountIdArr} from '../../redux/actions/bankAccount/bankAccount.js';

import {
    changeCreateItemGeologivDataValue,
    changeCreateItemDocumentationValue,
    changeCreateItemDateUse
} from '../../redux/actions/auctionItems/auctionItems.js';

import {
    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType
} from '../../redux/actions/documents/documents.js';

import {
    changeDiscountPercent,
    changeDiscountPerviousAuctionCurrency,
    changeDiscountPerviousAuctionId,
    discountAuc,
} from '../../redux/actions/discount/discount.js';

import NextPage from './next'
import PrevPage from './prev'

const pagingButton = ( props ) => {
    let tmp = null
    let tmpCurrentNumber = props.myCabinetPage + 1
    if(props.auctionsGetMyData !== null){
        if(props.auctionsGetMyData.length === 10){
            if(props.myCabinetPage === 0){
                tmp = <div className={styles.paging_wrap}>
                    <div className={styles.countPage}>{tmpCurrentNumber}</div>
                    <div 
                        className={styles.paging}
                        onClick={
                            () => {
                                handlerNext(props)
                            }
                        }
                    >
                        <NextPage />
                    </div>
                </div>
            }else{
                tmp = <div className={styles.paging_wrap}>
                    <div 
                        className={styles.paging}
                        onClick={
                            () => {
                                handlerPrev(props)
                            }
                        }
                    >
                        <PrevPage />
                    </div>
                    <div className={styles.countPage}>{tmpCurrentNumber}</div>
                    <div 
                        className={styles.paging}
                        onClick={
                            () => {
                                handlerNext(props)
                            }
                        }
                    >
                        <NextPage />
                    </div>
                </div>
            }
        }else{
            if(props.myCabinetPage === 0){
                tmp = null
            }else{
                tmp = <div className={styles.paging_wrap}>
                    <div 
                        className={styles.paging}
                        onClick={
                            () => {
                                handlerPrev(props)
                            }
                        }
                    >
                        <PrevPage />
                    </div>
                    <div className={styles.countPage}>{tmpCurrentNumber}</div>
                </div>
            }
        }
    }
    return tmp
}

function handlerNext(props){
    let tmp = props.myCabinetPage + 1
    let data = {}
    data.domen = process.env.REACT_APP_END_POINT_BD
    data.version = 'v1'
    data.local = props.langFlag
    data.params = `?offset=${tmp}0` 
    let header = {
        'Authorization': props.token.access_token,
        'Content-Type': 'application/json'
    }
    props.changeMyCabinetCurentPage(tmp)
    props.auctionsGetMy(data, header)
}

function handlerPrev(props){
    let tmp = props.myCabinetPage - 1
    let data = {}
    data.domen = process.env.REACT_APP_END_POINT_BD
    data.version = 'v1'
    data.local = props.langFlag
    if(tmp === 0){
        data.params = `?offset=${tmp}`
    }else{
        data.params = `?offset=${tmp}0` 
    }
    let header = {
        'Authorization': props.token.access_token,
        'Content-Type': 'application/json'
    }
    props.changeMyCabinetCurentPage(tmp)
    props.auctionsGetMy(data, header)
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        langFlag: state.start.langFlag,
        auctionsGetMyData: state.start.auctionsGetMy,
        myCabinetPage: state.start.myCabinetPage,
    }
}

const mapDispatchToProps = {
    changeCreateAuctionTitle,
    changeCreateAuctionDescription,

    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,

    changeAuctionsSwitchWindow,

    auctionsGetMy,
    getMyAuction,
    changeCreateAuctionLotIdentifier,

    changePopUpBankAccount,
    setPopBankAccountId,
    setPopBankAccountIdArr,
    changeCreateAuctionTab,

    setPopUpAuctionCreate,

    changeCreateItemGeologivDataValue,
    changeCreateItemDocumentationValue,
    changeCreateItemDateUse,
    setCarryType,

    setPopUpAuctionMyNull,

    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType,

    changeCreateItemClassificationId,
    changeCreateItemUnitValueCurrency,
    changeCreateItemUnitValueAmount,
    changeCreateItemUnitValueTax,
    changeCreateItemDescription,
    changeAdditionalClassificationIds,
    changeCreateItemQuantity,

    changeCreateItemGenerationType,
    changeCreateItemUnitCode,

    changeDiscountPercent,
    changeDiscountPerviousAuctionCurrency,
    changeDiscountPerviousAuctionId,

    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,

    discountAuc,
    changeMyCabinetCurentPage,
}

export default connect(mapStateToProps, mapDispatchToProps)(pagingButton)
