import React from 'react';

import { connect } from 'react-redux';

import AuthUser from './authUser/authUser.js';
import NotAuthUser from './notAuthUser/notAuthUser.js';

const Faq = (props) => {
    if(props.token === null){
        return <NotAuthUser />
    }else{
        return <AuthUser />
    }
}

const mapStateToProps = state => {
    return {
        token:  state.start.token,
    }
}

export default connect(mapStateToProps, null)(Faq)