import React from 'react';
import styles from './blueBlockPayment.module.css';

import {connect} from 'react-redux';

import {paymentPopup, paymentPopupGetData} from '../../../../redux/paymentPopup.js';

const BlueBlockPayment = (props) => {
    return (
        <div className={styles.blueBlock}
            onClick={
                () => {
                    getInvoiceData(props)
                    props.paymentPopup(props.auction)
                }
            }
        >
            <div>Сформувати рахунок в розмірі<br/>гарантійного внеску</div>
        </div>
    )
}

////////////////////////////
async function getInvoiceData(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/userProfile/payments/bill/auction/${props.auctionId.id}/guarantee`
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/user/profile/bill/auction/${props.auction.id}/guarantee`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            
        }else{
            props.paymentPopupGetData(json)
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auction: state.start.auctionsGetId,
    }
}

const mapDispatchToProps = {
    paymentPopup,
    paymentPopupGetData,
}

export default connect(mapStateToProps, mapDispatchToProps)(BlueBlockPayment)