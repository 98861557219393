import React from 'react';
import style from './popupContractAppruve.module.css';

import {connect} from 'react-redux';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { TimePicker, DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";

import {
    auctionsGetAwards,
    auctionsGetAwardsId,
    changeAwardRejectPopUpStatus,
    changeAwardErrorPopUpStatus,
    changeAwardRejectTitle,
    changeAwardRejectDescription,
    changeAwardRejectDocumentType,
} from '../../../redux/actions/awards/awards.js';

import {
    changeContractApprowePopUpStatus,
    changeContractErrorPopUpStatus,

    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,

    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
    setContractDatePaid,
} from '../../../redux/actions/contracts/contracts.js';

import {setUniversalError, changeLoader} from '../../../redux/actions.js';

import {contractsAuctionIdGet} from '../../../redux/actions/contracts/contracts.js';

import {
    togetherApiPopUpOnOff,
    togetherApiDocTitle,
    togetherApiDocDescription,
    togetherApiDocType,
    togetherApiDocLang,
    togetherApiDocFileName,
    togetherApiECP,
} from '../../../redux/togetherApi.js';
import {ecpApiPass, ecpApiList, changeCA} from '../../../redux/ecpApi.js';

import {
    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType
} from '../../../redux/actions/documents/documents.js';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';

import InstructionBlock from '../../elements/viewData/instructionBlock/instructionBlock';
import InputText from '../../elements/inputs/input';
import TextArea from '../../elements/inputs/textArea';
import InputDate from '../../elements/inputs/inputDate';
import InputAmount from '../../elements/inputs/inputAmount';




import {setDataAddress} from '../../../redux/createAddress';
import {getUpdateDataAuction} from '../../product/updateData'
import { isTIE, isSUE, isSUD, isRCE, isRCD, isGFE, isGFD, isLLE, isLLD, isLLP,
    isLRE, isLSE, isLSP, isRMA, isBSE, isBSD, isALE, isCSE, isCSD, isAAE, isBRE, isBRD, isBRW,
    isRLE, isRLD, isCLE, isCLD, isSPE, isSPD, isLPE
 } from '../../../redux/aucTypes';

//Блокировка кнопки если нет документа contractSigned (Підписаний договір)
//Очиска полей при закрытии попапа
import ContractRouter from './aucType/contractRouter'

const DeleteBidDocument = ( props ) => {
    return <ContractRouter />
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        //auctions: state.start.auctionsGet,
        auctions: state.start.auctionFull,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy,
        auctionAwards: state.start.auctionAwards,
        popUpAwardReject: state.start.popUpAwardReject,
        awardReject: state.start.awardReject,
        auction: state.start.auctionsGetId,
        contractAuctionIdGet: state.start.contractAuctionIdGet,
        popUpContractApprowe: state.start.popUpContractApprowe,
        popUpContractUniversal: state.start.popUpContractUniversal,
        timberContractPut: state.start.timberContractPut,

        togetherApiPage: state.start.togetherApiPage,
        ecpData: state.start.ecpData,

        createDocuments: state.start.createDocuments,
    }
}

const mapDispatchToProps = {
    auctionsGetAwards,
    auctionsGetAwardsId,
    changeAwardRejectPopUpStatus,
    changeAwardErrorPopUpStatus,
    changeAwardRejectTitle,
    changeAwardRejectDescription,
    changeAwardRejectDocumentType,

    setUniversalError,
    changeContractApprowePopUpStatus,

    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,

    changeContractErrorPopUpStatus,

    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,
    
    contractsAuctionIdGet,

    togetherApiPopUpOnOff,
    togetherApiDocTitle,
    togetherApiDocDescription,
    togetherApiDocType,
    togetherApiDocLang,
    togetherApiDocFileName,
    togetherApiECP,

    ecpApiPass,
    ecpApiList,
    changeCA,

    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType,

    changeLoader,

    setContractDatePaid,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteBidDocument)