import React from 'react';
import style from './contractDel.module.css';

import {connect} from 'react-redux';

import {setUniversalError, changeLoader} from '../../../../redux/actions.js';
import {contractsAuctionIdGet} from '../../../../redux/actions/contracts/contracts.js';
import {setDataAddress} from '../../../../redux/createAddress';


const PopUpContractDel = (props) => {
    let tmpName = ''
    for(let tk = 0; tk < props.popUpContractDel.fullAuction.contracts.length; tk++){
        if(props.popUpContractDel.fullAuction.contracts[tk].id === props.popUpContractDel.contractId){
            tmpName = props.popUpContractDel.fullAuction.contracts[tk].buyers[0].name.uk_UA
        }
    }
    return (
        <div className={style.popup_wrap}>
            <div className={style.popup_message}>
                <div
                    className={style.popUpExit}
                    onClick={
                        ()=>{
                            props.setDataAddress(null, 'SET_CONTRACT_POPUP_DEL')
                        }
                    }
                >
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className={style.svgIcon} fillRule="evenodd" clipRule="evenodd" d="M8.41421 6.99989L13.364 11.9496C13.7545 12.3402 13.7545 12.9733 13.364 13.3639C12.9734 13.7544 12.3403 13.7544 11.9497 13.3639L7 8.41411L2.05025 13.3639C1.65973 13.7544 1.02656 13.7544 0.636039 13.3639C0.245514 12.9733 0.245515 12.3402 0.636039 11.9496L5.58579 6.99989L0.636039 2.05015C0.245515 1.65962 0.245514 1.02646 0.636039 0.635934C1.02656 0.245409 1.65973 0.245409 2.05025 0.635934L7 5.58568L11.9497 0.635934C12.3403 0.245409 12.9734 0.245409 13.364 0.635934C13.7545 1.02646 13.7545 1.65962 13.364 2.05015L8.41421 6.99989Z" fill="#92929D"/>
                    </svg>

                </div>
                <div className={style.popUpTitle}>
                    Обраний вами варіант підтверджує, що оплата за лот не здійснена учасником {tmpName}, необхідно його дискваліфікувати?
                </div>
                <div className={style.popup_btnBlock}>
                    <div
                        className={style.popup_yes_button}
                        onClick={
                            ()=>{
                                //if(props.contractAuctionIdGet[0].lotPaymentConfirmation === null){
                                    promisContractLRUpdate(props, props.popUpContractDel)
                                //}
                            }
                        } 
                    >Так</div>
                    <div
                        className={style.popup_no_button}
                        onClick={
                            ()=>{
                                props.setDataAddress(null, 'SET_CONTRACT_POPUP_DEL')
                            }
                        } 
                        >Ні</div>
                </div>
            </div>
        </div>
    )
}


async function promisContractLRUpdate(props, data){
    props.changeLoader(true)
    let endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${data.aucId}/contract/${data.contractId}/confirm_payment?lotPaymentConfirmation=false`
    let body = {}
    body.lotPaymentConfirmation = false
    const response = await fetch(endPoint, {
        method: 'PATCH',
        /*body: JSON.stringify(body),*/
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при затвердженні контракту', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            props.changeLoader(false)
        }else{
            /*let data1 = {
                domen: process.env.REACT_APP_END_POINT_BD,
                version: 'v1',
                local: 'uk'
            }
            let headers1 = {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }*/
            //await props.contractsAuctionIdGet(data1, headers1, data.aucId)
            props.setDataAddress(json, 'SET_FULL_AUCTION')
            await props.changeLoader(false)
            //await promisApproveContract(props, data)
        }
    }
    await props.setDataAddress(null, 'SET_CONTRACT_POPUP_DEL')
    await props.changeLoader(false)
}

async function promisApproveContract(props, data){
    props.changeLoader(true)
    //let endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${data.aucId}/contract/${data.contractId}/approve/set_status/cancelled`
    //let endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${data.aucId}/contract/${data.contractId}/approve/set_status/cancelled`
    let endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${data.aucId}/award/${data.award}/set_status/unsuccessful`
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify({
            reason: 4
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при затвердженні контракту', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            props.changeLoader(false)
        }else{
            let data1 = {
                domen: process.env.REACT_APP_END_POINT_BD,
                version: 'v1',
                local: props.langFlag
            }
            let headers1 = {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
            await props.contractsAuctionIdGet(data1, headers1, data.aucId)
            await props.changeLoader(false)
        }
    }
    await props.changeLoader(false)
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        popUpContractDel: state.start.popUpContractDel,
        contractAuctionIdGet: state.start.contractAuctionIdGet,
    }
}

const mapDispatchToProps = {
    setUniversalError,
    changeLoader,
    contractsAuctionIdGet,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(PopUpContractDel)