import React from 'react';
import styles from './auctionDataLine_Left.module.css';

import {connect} from 'react-redux';

import AuctionDataLineNameOrganithation from './auctionDataLine_NameOrganithation/auctionDataLine_NameOrganithation.js';
import AuctionDataLineTitle from './auctionDataLine_Title/auctionDataLine_Title.js';
import AuctionDataLineDescription from './auctionDataLine_Description/auctionDataLine_Description.js';
import AuctionDataLineCod from './auctionDataLine_Cod/auctionDataLine_Cod.js';


const AuctionDataLine_Left = ( props ) => {
    return (
        <div className={styles.secondBlock_text}>
            <AuctionDataLineNameOrganithation item={props.item} />
            <AuctionDataLineTitle item={props.item} />
            <AuctionDataLineDescription item={props.item} />
            <AuctionDataLineCod item={props.item} />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
    }
}

export default connect(mapStateToProps, null)(AuctionDataLine_Left)