import React from 'react';
import styles from './styles.module.css';

import {connect} from 'react-redux';

import {viewStringValue} from '../../api/valueToString'

const ProcedureStartValue = (props) => {
    //let value = null;
    let tmp = ''
    if(props.auctionId !== null){
        //value = fMinimalStep(props.auctionId);
        if(props.auctionId.value !== null){
            //tmp = startCostAuction(value) + typeCurunsy(props.auctionId.value.currency) + taxStatusAuction(props);
            tmp = viewStringValue(props.auctionId.value)
        }
    }
    return (
        <div className={styles.lotStartPriceBlock}>
            <p>Початкова ціна продажу лоту</p>
            {/*<p className={styles.lotStartPrice}>{startCostAuction(props.auctionId)}<span> грн{taxStatusAuction(props.auctionId)}</span></p>*/}
            <p className={styles.lotStartPrice}>{tmp}</p>

        </div>
    )
}

function typeCurunsy(data){
    switch(data){
        case 'UAH': return ' грн.'
        case 'USD': return ' usd.'
        case 'EUR': return ' eur.'
    }
    return null
}

function taxStatusAuction(props){
    let tmp = ''
    if(props.auctionId !== null){
        if(props.auctionId.value !== null){
            if(props.auctionId.value.valueAddedTaxIncluded !== null){
                if(props.auctionId.value.valueAddedTaxIncluded){
                    tmp += ', з ПДВ'
                }else{
                    tmp += ', без ПДВ'
                }
            }
        }
    }
    return tmp
}

function startCostAuction(data){
    let tmp = null
    let amount = null
    if(data !== null){
        amount = String(data);
        if(amount !== null){
            if(amount.indexOf('.') !== -1){
                amount = amount.split('.');
                tmp = String(amount[0]);
                tmp = tmp.split("").reverse().join("");
                let tmp1 = [];
                for(let i = 0; i < tmp.length; i++){
                    if(((i+1)%3) === 0){
                        if(i !== tmp.length-1){
                            tmp1[i] = tmp[i] + '\'';
                        }else{
                            tmp1[i] = tmp[i];
                        }
                    }else{
                        tmp1[i] = tmp[i];
                    }
                }
                tmp = tmp1.join('')
                tmp = tmp.split("").reverse().join("");
                amount = Number(amount[1]);
                if(amount < 10) amount = amount + "0"
            }else{
                tmp = String(amount);
                tmp = tmp.split("").reverse().join("");
                let tmp1 = [];
                for(let i = 0; i < tmp.length; i++){
                    if(((i+1)%3) === 0){
                        if(i !== tmp.length-1){
                            tmp1[i] = tmp[i] + '\'';
                        }else{
                            tmp1[i] = tmp[i];
                        }
                    }else{
                        tmp1[i] = tmp[i];
                    }
                }
                tmp = tmp1.join('')
                tmp = tmp.split("").reverse().join("");
                amount = '00'    
            }
        }
    }
    return tmp + ',' + amount
}
 
function fMinimalStep(auction){
    let tmp = null;
    if(auction.hasOwnProperty('value') === true){
        if(auction.value !== null){
            if(auction.value.hasOwnProperty('amount') === true){
                if(auction.value.amount !== null){
                    tmp = String(auction.value.amount);
                }
            }
        }
    }
    return tmp
}

// function startCostAuction(data){
//     let tmp = null
//     let amount = null
//     if(data !== null){
//         amount = String(viewAmount(data));
//         if(amount !== null){
//             if(amount.indexOf('.') !== -1){
//                 amount = amount.split('.');
//                 tmp = String(amount[0]);
//                 tmp = tmp.split("").reverse().join("");
//                 let tmp1 = [];
//                 for(let i = 0; i < tmp.length; i++){
//                     if(((i+1)%3) === 0){
//                         if(i !== tmp.length-1){
//                             tmp1[i] = tmp[i] + '\'';
//                         }else{
//                             tmp1[i] = tmp[i];
//                         }
//                     }else{
//                         tmp1[i] = tmp[i];
//                     }
//                 }
//                 tmp = tmp1.join('')
//                 tmp = tmp.split("").reverse().join("");
//                 amount = amount[1];
//             }else{
//                 tmp = String(amount);
//                 tmp = tmp.split("").reverse().join("");
//                 let tmp1 = [];
//                 for(let i = 0; i < tmp.length; i++){
//                     if(((i+1)%3) === 0){
//                         if(i !== tmp.length-1){
//                             tmp1[i] = tmp[i] + '\'';
//                         }else{
//                             tmp1[i] = tmp[i];
//                         }
//                     }else{
//                         tmp1[i] = tmp[i];
//                     }
//                 }
//                 tmp = tmp1.join('')
//                 tmp = tmp.split("").reverse().join("");
//                 amount = '00'    
//             }
//         }
//     }
//     console.log(amount)
//     return tmp + ',' + amount
// }

// function taxStatusAuction(data){
//     let tmp = ''
//     if(data !== null){
//         if(data.value !== null){
//             if(data.value.valueAddedTaxIncluded !== null){
//                 if(data.value.valueAddedTaxIncluded){
//                     tmp += '., з ПДВ'
//                 }else{
//                     tmp += '., без ПДВ'
//                 }
//             }
//         }
//     }
//     return tmp
// }

// function viewAmount(auction){
//     let tmp = null;
//     if(auction.hasOwnProperty('value') === true){
//         if(auction.value !== null){
//             if(auction.value.hasOwnProperty('amount') === true){
//                 if(auction.value.amount !== null){
//                     tmp = auction.value.amount;
//                 }
//             }
//         }
//     }
//     return tmp
// }

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(ProcedureStartValue)