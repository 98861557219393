import React from 'react';

import {connect} from 'react-redux';

import {
    togetherApiTimerRound,
    togetherApiTimerTime,
    togetherApiTimerTimeLeft,
} from '../../../../redux/togetherApi.js'

const TogetherAPITimer = (props) => {
    let tmp = null
    tmp = <div>{props.togetherApiPage.timer.timeLeft}</div>
    return tmp
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        auctions: state.start.auctionsGet,
        auctionsOld: state.start.auctionsGetOld,
        search: state.start.search,
        togetherApiPage: state.start.togetherApiPage,
        userProfiles: state.start.userProfiles,
    }
}

const mapDispatchToProps = {
    togetherApiTimerRound,
    togetherApiTimerTime,
    togetherApiTimerTimeLeft,
}

export default connect(mapStateToProps, mapDispatchToProps)(TogetherAPITimer)