import {addProperty, addPropertyValue, previousAuctionId} from './utils'

export function сreateBodyLRE(data){
    if(data !== null){
        let body = {}
        body = addProperty(data, body, 'title')
        body = addProperty(data, body, 'description')
        body = addProperty(data, body, 'accessDetails')
        body = addProperty(data, body, 'tenderAttempts')
        body = addProperty(data, body, 'lotIdentifier')
        body = addProperty(data, body, 'startDate')
        body = addProperty(data, body, 'sellingMethod')
        body = addProperty(data, body, 'xDocumentRequirements')
        body = addProperty(data, body, 'xAdditionalInformation')
        body = addProperty(data, body, 'minNumberOfQualifiedBids')
        body = addPropertyValue(data, body, 'value')
        if(data.base.minimalStep !== null
            && data.base.minimalStep !== ''
            && data.base.minimalStep.amount !== null
            && data.base.minimalStep.amount !== ''
            && data.base.minimalStep.amount !== 0
            && data.base.minimalStep.amount !== '0'
        ){
            body = addPropertyValue(data, body, 'minimalStep')
        }else{
            body.minimalStep = null
        }
        //body = addPropertyValue(data, body, 'minimalStep')
        body.leaseType = 'landRental'
        body = addPropertyValue(data, body, 'guarantee')
        body = addPropertyValue(data, body, 'expertMonetaryValuation')
        body = addPropertyValue(data, body, 'budgetSpent')
        body = addPropertyValue(data, body, 'normativeMonetaryValuation')
        body = addPropertyValue(data, body, 'registrationFee')
        body = addProperty(data, body, 'restrictions')
        body = addProperty(data, body, 'conditions')
        body = addProperty(data, body, 'leaseDuration')
        body = previousAuctionId(data, body, 'previousAuctionId')
        return body
    }
    return null
}