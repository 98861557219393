import React from 'react';

import {connect} from 'react-redux';

import TextFull from '../../../elements/viewData/textFull'

const IsTaxInvoiceProvided = (props) => {
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('isTaxInvoiceProvided') === true){
            if(props.auctionId.isTaxInvoiceProvided !== null){
                let tmp = null
                if(props.auctionId.isTaxInvoiceProvided === true){
                    tmp = 'Так'
                }else{
                    tmp = 'Ні'
                }
                return (
                    <TextFull
                        title="Податкова накладна надається?"
                        data={tmp}
                    />
                )
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(IsTaxInvoiceProvided)