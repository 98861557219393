import React from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import {
    changeCreateAuctionMinNumberOfQualifiedBids,
    changeCreateAuctionDgfVirtualDataRoom,
    changeCreateAuctionDgfEligibilityCriteria,
    changeCreateAuctionDgfX_dgfPlatformLegalDetails,
    changeCreateAuctionDgfDecisionId,
    changeCreateAuctionDgfDecisionDate,
} from '../../../redux/actions.js';

import TextField from '@material-ui/core/TextField';

import {setDataAddress} from '../../../redux/createAddress';

import LandCurrentTenant from './landCurrentTenant'
import LandCurrentTenant_LSP from './landCurrentTenant_lsp'

const LandRental = ( props ) => {
    let tmp = 1
    if(props.auctionsMy.minNumberOfQualifiedBids !== 'null') tmp = props.auctionsMy.minNumberOfQualifiedBids
    return (
        <>
            {/*<div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled
                        required
                        id="filled-required"
                        className={styles.inputText}
                        label="Мінімальна кількість заяв"
                        defaultValue={tmp}
                        variant="outlined"
                        helperText=""
                    />
                </div>
            </div>*/}
            {/*<div className={styles.groupElem}>
                <div>
                    <TextField
                        required
                        id="filled-required"
                        className={styles.inputText}
                        label="Реквізити майданчиків"
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_DGF_X_DGFPLATFORMLEGALDETAILS')
                        }}
                        value={props.auctionsMy.xDgfPlatformLegalDetails}
                        variant="outlined"
                        helperText=""
                    />
                </div>
            </div>*/}
            <LandCurrentTenant_LSP />
        </>
    )
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
        dictionaries: state.start.dictionaries,
    }
}

const mapDispatchToProps = {
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(LandRental)
