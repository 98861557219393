import React from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import {
    changeCreateAuctionMinNumberOfQualifiedBids,
} from '../../../redux/actions.js';

import TextField from '@material-ui/core/TextField';
import Select from '../../elements/inputs/inputSelect';

import {disabletElem} from '../disabled'

const WindowAuctionDutchSteps = ( props ) => {
    let tmpDutchStepQuantity = null
    if(props.auctionsMy.minNumberOfQualifiedBids === null || props.auctionsMy.minNumberOfQualifiedBids === 'null'){
        tmpDutchStepQuantity = 2
        props.changeCreateAuctionMinNumberOfQualifiedBids(2)
    }else{
        tmpDutchStepQuantity = props.auctionsMy.minNumberOfQualifiedBids
    }
   
    if(props.typeElem === 1){
        return (
            <Select
                data={{
                    label:"Мінімальна кількість заяв",
                    target: tmpDutchStepQuantity,
                    info: 'оберіть тип процедури, за яким проходитиме аукціон'
                }}
                onChange={(event)=>{
                            props.changeCreateAuctionMinNumberOfQualifiedBids(+event)
                        }}
                options={viewOptionDutchStepQuantity(props, 1)}
                disabled={disabletElem(props.auctionsMy, '00')}
            />
        )
    }else{
        return (
            <>
                <div className={styles.groupElem}>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '00')}
                        id="outlined-select-currency-native"
                        className={styles.inputText}
                        select
                        label="Мінімальна кількість заяв"
                        defaultValue={tmpDutchStepQuantity}
                        SelectProps={{
                            native: true,
                        }}
                        onChange={(event)=>{
                            props.changeCreateAuctionMinNumberOfQualifiedBids(Number(event.target.value))
                        }}
                        variant="outlined"
                    >
                        {viewOptionDutchStepQuantity(props)}
                    </TextField>
                </div>
            </>
        )
    }
    return null
}

function convertStrToNumber(data){
    let str = ''
    if(data.indexOf(',') !== -1){
        str = data.replace(',', '.')
    }else{
        str = data
    }
    let strArr = null
    if(str.indexOf('.') !== -1){
        strArr = str.split('.')
        if(strArr.length < 3){
            if(strArr[1].length < 3){
                return str
            }else{
                return strArr[0] + '.' + strArr[1].slice(0, 2)
            }
        }else{
            if(strArr[1].length === 0){
                return strArr[0] + '.'
            }else if(strArr[1].length === 1){
                return strArr[0] + '.' + strArr[1].slice(0, 1)
            }else if(strArr[1].length === 2){
                return strArr[0] + '.' + strArr[1].slice(0, 2)
            }    
        }
    }
    return str
}

function viewOptionDutchStepQuantity(props, n){
    if(
        props.auctionsMy.sellingMethod === 'alienation-english' ||
        props.auctionsMy.sellingMethod === 'alienation-english-fast' ||
        props.auctionsMy.sellingMethod === 'alienation-english-fast-manual' ||
        props.auctionsMy.sellingMethod === 'alienation-english-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'alienation-english-initial-auction' ||
        props.auctionsMy.sellingMethod === 'alienation-english-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'alienation-english-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'alienation-english-initial-qualification-prod'
    ){
        return n === 1 ? [{name: 1, value: 1}] : <option key={1} value={2}>{2}</option>
    }else if(
        props.auctionsMy.sellingMethod === 'basicSell-dutch' ||
        props.auctionsMy.sellingMethod === 'basicSell-dutch-fast' ||
        props.auctionsMy.sellingMethod === 'basicSell-dutch-fast-manual' ||
        props.auctionsMy.sellingMethod === 'basicSell-dutch-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'basicSell-dutch-initial-auction' ||
        props.auctionsMy.sellingMethod === 'basicSell-dutch-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'basicSell-dutch-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'basicSell-dutch-initial-qualification-prod' ||
        props.auctionsMy.sellingMethod === 'commercialSell-dutch' ||
        props.auctionsMy.sellingMethod === 'commercialSell-dutch-fast' ||
        props.auctionsMy.sellingMethod === 'commercialSell-dutch-fast-manual' ||
        props.auctionsMy.sellingMethod === 'commercialSell-dutch-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-auction' ||
        props.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-qualification-prod' ||
        props.auctionsMy.sellingMethod === 'bankRuptcy-dutch' ||
        props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-fast' ||
        props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-fast-manual' ||
        props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-auction' ||
        props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-qualification-prod'
    ){
        let tmp = []
        for(let i = 0; i < 99; i++){
            tmp[i] = n === 1 ? {name: i+1, value: i+1} : <option key={i} value={i+1}>{i+1}</option>
        }
        return tmp
    }
    let tmp = []
    for(let i = 0; i < 2; i++){
        tmp[i] = n === 1 ? {name: i+1, value: i+1} : <option key={i} value={i+1}>{i+1}</option>
    }
    return tmp
}

function elementDisabled(props){
    let tmpStatus = null;
    if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            tmpStatus = 'disabled'
        }
    }
    return tmpStatus
}


const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
        auctionsZUAttemp: state.start.auctionsZUAttemp
    }
}

const mapDispatchToProps = {
    changeCreateAuctionMinNumberOfQualifiedBids
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuctionDutchSteps)
