import React from 'react';

import {connect} from 'react-redux';

import AllContract from './newAward'

const ContractTypeAuctions = (props) => {
    if(props.auctionId !== null){
        switch(props.auctionId.sellingMethod){
            case "timber-english":
            case "timber-english-fast":
            case "timber-english-fast-manual":
            case "timber-english-initial-auction":
            case "timber-english-initial-qualification":
            case "timber-english-initial-qualification-fast":
                return <AllContract />
            case "renewables":
            case "renewables-fast":
            case "renewables-fast-manual":
            case "renewables-initial-auction":
            case "renewables-initial-qualification":
            case "renewables-initial-qualification-fast":
                return <AllContract />
            case "subsoil-english":
            case "subsoil-english-fast":
            case "subsoil-english-fast-manual":
            case "subsoil-english-initial-auction":
            case "subsoil-english-initial-qualification":
            case "subsoil-dutch":
            case "subsoil-dutch-fast":
            case "subsoil-dutch-fast-manual":
            case "subsoil-dutch-initial-auction":
            case "subsoil-dutch-initial-qualification":
            case "subsoil-dutch-initial-auction-manual":
            case "subsoil-english-fast-auction-manual-qualification":
                return <AllContract />
            case "railwayCargo-english":
            case "railwayCargo-english-fast":
            case "railwayCargo-english-fast-manual":
            case "railwayCargo-english-initial-auction":
            case "railwayCargo-english-initial-qualification":
            case "railwayCargo-dutch":
            case "railwayCargo-dutch-fast":
            case "railwayCargo-dutch-fast-manual":
            case "railwayCargo-dutch-initial-auction":
            case "railwayCargo-dutch-initial-qualification":
            case "railwayCargo-dutch-fast-auction-manual-qualification":
            case "railwayCargo-dutch-initial-auction-manual":
                return <AllContract />
            case "dgf-english":
            case "dgf-english-fast":
            case "dgf-english-fast-manual":
            case "dgf-english-fast-auction-manual-qualification":
            case "dgf-english-initial-auction":
            case "dgf-english-initial-auction-manual":
            case "dgf-english-initial-qualification":
            case "dgf-dutch":
            case "dgf-dutch-fast":
            case "dgf-dutch-fast-manual":
            case "dgf-dutch-fast-auction-manual-qualification":
            case "dgf-dutch-initial-auction":
            case "dgf-dutch-initial-qualification":
            case "dgf-dutch-initial-auction-manual":
                return <AllContract />
            case "legitimatePropertyLease-english":
            case "legitimatePropertyLease-english-fast":
            case "legitimatePropertyLease-english-fast-manual":
            case "legitimatePropertyLease-english-fast-auction-manual-qualification":
            case "legitimatePropertyLease-english-initial-auction":
            case "legitimatePropertyLease-english-initial-auction-manual":
            case "legitimatePropertyLease-english-initial-qualification":
            case "legitimatePropertyLease-english-initial-qualification-prod":
            case "legitimatePropertyLease-dutch":
            case "legitimatePropertyLease-dutch-fast":
            case "legitimatePropertyLease-dutch-fast-manual":
            case "legitimatePropertyLease-dutch-fast-auction-manual-qualification":
            case "legitimatePropertyLease-dutch-initial-auction":
            case "legitimatePropertyLease-dutch-initial-auction-manual":
            case "legitimatePropertyLease-dutch-initial-qualification-prod":
            case "legitimatePropertyLease-priorityEnglish":
            case "legitimatePropertyLease-priorityEnglish-fast":
            case "legitimatePropertyLease-priorityEnglish-fast-manual":
            case "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification":
            case "legitimatePropertyLease-priorityEnglish-initial-auction":
            case "legitimatePropertyLease-priorityEnglish-initial-auction-manual":
            case "legitimatePropertyLease-priorityEnglish-initial-qualification":
            case "legitimatePropertyLease-priorityEnglish-initial-qualification-prod":
                return <AllContract />
            case 'landRental-english':
            case 'landRental-english-fast':
            case 'landRental-english-fast-manual':
            case 'landRental-english-fast-auction-manual-qualification':
            case 'landRental-english-initial-auction':
            case 'landRental-english-initial-auction-manual':
            case 'landRental-english-initial-qualification':
            case 'landRental-english-initial-qualification-prod':
                return <AllContract />
            case 'landSell-english':
            case 'landSell-english-fast':
            case 'landSell-english-fast-manual':
            case 'landSell-english-fast-auction-manual-qualification':
            case 'landSell-english-initial-auction':
            case 'landSell-english-initial-auction-manual':
            case 'landSell-english-initial-qualification':
            case 'landSell-english-initial-qualification-prod':
                return <AllContract />
            case 'landSell-priorityEnglish':
            case 'landSell-priorityEnglish-fast':
            case 'landSell-priorityEnglish-fast-manual':
            case 'landSell-priorityEnglish-fast-auction-manual-qualification':
            case 'landSell-priorityEnglish-initial-auction':
            case 'landSell-priorityEnglish-initial-auction-manual':
            case 'landSell-priorityEnglish-initial-qualification':
            case 'landSell-priorityEnglish-initial-qualification-prod':
                return <AllContract />
            case 'basicSell-english':
            case 'basicSell-english-fast':
            case 'basicSell-english-fast-manual':
            case 'basicSell-english-fast-auction-manual-qualification':
            case 'basicSell-english-initial-auction':
            case 'basicSell-english-initial-auction-manual':
            case 'basicSell-english-initial-qualification':
            case 'basicSell-english-initial-qualification-prod':
                return <AllContract />
            case 'basicSell-dutch':
            case 'basicSell-dutch-fast':
            case 'basicSell-dutch-fast-manual':
            case 'basicSell-dutch-fast-auction-manual-qualification':
            case 'basicSell-dutch-initial-auction':
            case 'basicSell-dutch-initial-auction-manual':
            case 'basicSell-dutch-initial-qualification':
            case 'basicSell-dutch-initial-qualification-prod':
                return <AllContract />
            case 'alienation-english':
            case 'alienation-english-fast':
            case 'alienation-english-fast-manual':
            case 'alienation-english-fast-auction-manual-qualification':
            case 'alienation-english-initial-auction':
            case 'alienation-english-initial-auction-manual':
            case 'alienation-english-initial-qualification':
            case 'alienation-english-initial-qualification-prod':
                return <AllContract />
            case 'commercialSell-english':
            case 'commercialSell-english-fast':
            case 'commercialSell-english-fast-manual':
            case 'commercialSell-english-fast-auction-manual-qualification':
            case 'commercialSell-english-initial-auction':
            case 'commercialSell-english-initial-auction-manual':
            case 'commercialSell-english-initial-qualification':
            case 'commercialSell-english-initial-qualification-prod':
                return <AllContract />
            case 'commercialSell-dutch':
            case 'commercialSell-dutch-fast':
            case 'commercialSell-dutch-fast-manual':
            case 'commercialSell-dutch-fast-auction-manual-qualification':
            case 'commercialSell-dutch-initial-auction':
            case 'commercialSell-dutch-initial-auction-manual':
            case 'commercialSell-dutch-initial-qualification':
            case 'commercialSell-dutch-initial-qualification-prod':
                return <AllContract />
            case 'bankRuptcy-english':
            case 'bankRuptcy-english-fast':
            case 'bankRuptcy-english-fast-manual':
            case 'bankRuptcy-english-fast-auction-manual-qualification':
            case 'bankRuptcy-english-initial-auction':
            case 'bankRuptcy-english-initial-auction-manual':
            case 'bankRuptcy-english-initial-qualification':
            case 'bankRuptcy-english-initial-qualification-prod':
                return <AllContract />
            case 'bankRuptcy-dutch':
            case 'bankRuptcy-dutch-fast':
            case 'bankRuptcy-dutch-fast-manual':
            case 'bankRuptcy-dutch-fast-auction-manual-qualification':
            case 'bankRuptcy-dutch-initial-auction':
            case 'bankRuptcy-dutch-initial-auction-manual':
            case 'bankRuptcy-dutch-initial-qualification':
            case 'bankRuptcy-dutch-initial-qualification-prod':
                return <AllContract />
            case 'bankRuptcy-withoutAuction':
                return <AllContract />
            case 'regulationsPropertyLease-english':
            case 'regulationsPropertyLease-english-ultra-fast':
            case 'regulationsPropertyLease-english-fast':
            case 'regulationsPropertyLease-english-fast-manual':
            case 'regulationsPropertyLease-english-fast-auction-manual-qualification':
            case 'regulationsPropertyLease-english-fast-auction-prod':
            case 'regulationsPropertyLease-english-initial-auction':
            case 'regulationsPropertyLease-english-initial-auction-manual':
            case 'regulationsPropertyLease-english-initial-qualification':
            case 'regulationsPropertyLease-english-initial-qualification-prod':
            case 'regulationsPropertyLease-dutch':
            case 'regulationsPropertyLease-dutch-ultra-fast':
            case 'regulationsPropertyLease-dutch-fast':
            case 'regulationsPropertyLease-dutch-fast-manual':
            case 'regulationsPropertyLease-dutch-fast-auction-manual-qualification':
            case 'regulationsPropertyLease-dutch-fast-auction-prod':
            case 'regulationsPropertyLease-dutch-initial-auction':
            case 'regulationsPropertyLease-dutch-initial-auction-manual':
            case 'regulationsPropertyLease-dutch-initial-qualification':
            case 'regulationsPropertyLease-dutch-initial-qualification-prod':
            case 'commercialPropertyLease-english':
            case 'commercialPropertyLease-english-ultra-fast':
            case 'commercialPropertyLease-english-fast':
            case 'commercialPropertyLease-english-fast-manual':
            case 'commercialPropertyLease-english-fast-auction-manual-qualification':
            case 'commercialPropertyLease-english-fast-auction-prod':
            case 'commercialPropertyLease-english-initial-auction':
            case 'commercialPropertyLease-english-initial-auction-manual':
            case 'commercialPropertyLease-english-initial-qualification':
            case 'commercialPropertyLease-english-initial-qualification-prod':
            case 'commercialPropertyLease-dutch':
            case 'commercialPropertyLease-dutch-ultra-fast':
            case 'commercialPropertyLease-dutch-fast':
            case 'commercialPropertyLease-dutch-fast-manual':
            case 'commercialPropertyLease-dutch-fast-auction-manual-qualification':
            case 'commercialPropertyLease-dutch-fast-auction-prod':
            case 'commercialPropertyLease-dutch-initial-auction':
            case 'commercialPropertyLease-dutch-initial-auction-manual':
            case 'commercialPropertyLease-dutch-initial-qualification':
            case 'commercialPropertyLease-dutch-initial-qualification-prod':
                return <AllContract />
            case "smallPrivatization-english":
            case "smallPrivatization-english-fast":
            case "smallPrivatization-english-ultra-fast":
            case "smallPrivatization-english-fast-manual":
            case "smallPrivatization-english-fast-auction-manual-qualification":
            case "smallPrivatization-english-fast-auction-prod":
            case "smallPrivatization-english-initial-auction":
            case "smallPrivatization-english-initial-auction-manual":
            case "smallPrivatization-english-initial-qualification":
            case "smallPrivatization-english-initial-qualification-prod":
            case "smallPrivatization-dutch":
            case "smallPrivatization-dutch-fast":
            case "smallPrivatization-dutch-ultra-fast":
            case "smallPrivatization-dutch-fast-manual":
            case "smallPrivatization-dutch-fast-auction-manual-qualification":
            case "smallPrivatization-dutch-fast-auction-prod":
            case "smallPrivatization-dutch-initial-auction":
            case "smallPrivatization-dutch-initial-auction-manual":
            case "smallPrivatization-dutch-initial-qualification":
            case "smallPrivatization-dutch-initial-qualification-prod":
                return <AllContract />
            case 'nonperformingLoans-english':
            case 'nonperformingLoans-english-fast':
            case 'nonperformingLoans-english-fast-manual':
            case 'nonperformingLoans-english-fast-auction-manual-qualification':
            case 'nonperformingLoans-english-fast-auction-prod':
            case 'nonperformingLoans-english-initial-qualification':
            case 'nonperformingLoans-english-initial-qualification-prod':
            case 'nonperformingLoans-english-initial-auction':
            case 'nonperformingLoans-english-initial-auction-manual':
            case 'nonperformingLoans-dutch':
            case 'nonperformingLoans-dutch-fast':
            case 'nonperformingLoans-dutch-fast-manual':
            case 'nonperformingLoans-dutch-fast-auction-manual-qualification':
            case 'nonperformingLoans-dutch-fast-auction-prod':
            case 'nonperformingLoans-dutch-initial-auction':
            case 'nonperformingLoans-dutch-initial-auction-manual':
            case 'nonperformingLoans-dutch-initial-qualification':
            case 'nonperformingLoans-dutch-initial-qualification-prod':
                return <AllContract />
            case 'landArrested-english':
            case 'landArrested-english-ultra-fast':
            case 'landArrested-english-fast':
            case 'landArrested-english-fast-manual':
            case 'landArrested-english-fast-auction-manual-qualification':
            case 'landArrested-english-fast-auction-prod':
            case 'landArrested-english-initial-auction':
            case 'landArrested-english-initial-auction-manual':
            case 'landArrested-english-initial-qualification':
            case 'landArrested-english-initial-qualification-prod':
            case 'landArrested-withoutAuction':
            case "landArrested-priorityEnglish":
            case "landArrested-priorityEnglish-ultra-fast":
            case "landArrested-priorityEnglish-fast":
            case "landArrested-priorityEnglish-fast-manual":
            case "landArrested-priorityEnglish-fast-auction-manual-qualification":
            case "landArrested-priorityEnglish-fast-auction-prod":
            case "landArrested-priorityEnglish-initial-auction":
            case "landArrested-priorityEnglish-initial-auction-manual":
            case "landArrested-priorityEnglish-initial-qualification":
            case "landArrested-priorityEnglish-initial-qualification-prod":
                return <AllContract />
            case "largePrivatization-english":
            case "largePrivatization-english-fast":
            case "largePrivatization-english-ultra-fast":
            case "largePrivatization-english-fast-manual":
            case "largePrivatization-english-fast-auction-manual-qualification":
            case "largePrivatization-english-fast-auction-prod":
            case "largePrivatization-english-initial-auction":
            case "largePrivatization-english-initial-auction-manual":
            case "largePrivatization-english-initial-qualification":
            case "largePrivatization-english-initial-qualification-prod":
                return <AllContract />
            case "armaProperty-english":
            case "armaProperty-english-ultra-fast":
            case "armaProperty-english-fast":
            case "armaProperty-english-fast-manual":
            case "armaProperty-english-fast-auction-manual-qualification":
            case "armaProperty-english-fast-auction-prod":
            case "armaProperty-english-initial-auction":
            case "armaProperty-english-initial-auction-manual":
            case "armaProperty-english-initial-qualification":
            case "armaProperty-english-initial-qualification-prod":
            case "armaProperty-dutch":
            case "armaProperty-dutch-ultra-fast":
            case "armaProperty-dutch-fast":
            case "armaProperty-dutch-fast-manual":
            case "armaProperty-dutch-fast-auction-manual-qualification":
            case "armaProperty-dutch-fast-auction-prod":
            case "armaProperty-dutch-initial-auction":
            case "armaProperty-dutch-initial-auction-manual":
            case "armaProperty-dutch-initial-qualification":
            case "armaProperty-dutch-initial-qualification-prod":
                return <AllContract />
            default:
                return null
        }
    }
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(ContractTypeAuctions)
