export const getRegion = (data) => {
    switch(data){
        case "АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ":
        case "Автономна Республіка Крим":
        case "0100000000":
        case "0100000000+:+АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ":
            return "Автономна Республіка Крим"
        case "ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ":
        case "Вінницька область":
        case "0500000000":
        case "0500000000+:+ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ":
            return "Вінницька область"
        case "ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК":
        case "Волинська область":
        case "0700000000":
        case "0700000000+:+ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК":
            return "Волинська область"
        case "ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО":
        case "Дніпропетровська область":
        case "1200000000":
        case "1200000000+:+ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО":
            return "Дніпропетровська область"
        case "ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК":
        case "Донецька область":
        case "1400000000":
        case "1400000000+:+ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК":
            return "Донецька область"
        case "ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР":
        case "Житомирська область":
        case "1800000000":
        case "1800000000+:+ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР":
            return "Житомирська область"
        case "ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД":
        case "Закарпатська область":
        case "2100000000":
        case "2100000000+:+ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД":
            return "Закарпатська область"
        case "ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ":
        case "Запорізька область":
        case "2300000000":
        case "2300000000+:+ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ":
            return "Запорізька область"
        case "ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК":
        case "Івано-Франківська область":
        case "2600000000":
        case "2600000000+:+ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК":
            return "Івано-Франківська область"
        case "КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ":
        case "Київська область":
        case "3200000000":
        case "3200000000+:+КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ":
            return "Київська область"
        case "КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ":
        case "Кіровоградська область":
        case "3500000000":
        case "3500000000+:+КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ":
            return "Кіровоградська область"
        case "ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК":
        case "Луганська область":
        case "4400000000":
        case "4400000000+:+ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК":
            return "Луганська область"
        case "ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ":
        case "Львівська область":
        case "4600000000":
        case "4600000000+:+ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ":
            return "Львівська область"
        case "МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ":
        case "Миколаївська область":
        case "4800000000":
        case "4800000000+:+МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ":
            return "Миколаївська область"
        case "ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА":
        case "Одеська область":
        case "5100000000":
        case "5100000000+:+ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА":
            return "Одеська область"
        case "ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА":
        case "Полтавська область":
        case "5300000000":
        case "5300000000+:+ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА":
            return "Полтавська область"
        case "РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ":
        case "Рівненська область":
        case "5600000000":
        case "5600000000+:+РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ":
            return "Рівненська область"
        case "СУМСЬКА ОБЛАСТЬ/М.СУМИ":
        case "Сумська область":
        case "5900000000":
        case "5900000000+:+СУМСЬКА ОБЛАСТЬ/М.СУМИ":
            return "Сумська область"
        case "ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ":
        case "Тернопільська область":
        case "6100000000":
        case "6100000000+:+ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ":
            return "Тернопільська область"
        case "ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ":
        case "Харківська область":
        case "6300000000":
        case "6300000000+:+ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ":
            return "Харківська область"
        case "ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН":
        case "Херсонська область":
        case "6500000000":
        case "6500000000+:+ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН":
            return "Херсонська область"
        case "ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ":
        case "Хмельницька область":
        case "6800000000":
        case "6800000000+:+ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ":
            return "Хмельницька область"
        case "ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ":
        case "Черкаська область":
        case "7100000000":
        case "7100000000+:+ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ":
            return "Черкаська область"
        case "ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ":
        case "Чернівецька область":
        case "7300000000":
        case "7300000000+:+ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ":
            return "Чернівецька область"
        case "ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ":
        case "Чернігівська область":
        case "7400000000":
        case "7400000000+:+ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ":
            return "Чернігівська область"
        case "М.КИЇВ":
        case "Київ":
        case "8000000000":
        case "8000000000+:+М.КИЇВ":
            return "Київ"
        case "М.СЕВАСТОПОЛЬ":
        case "Севастополь":
        case "8500000000":
        case "8500000000+:+М.СЕВАСТОПОЛЬ":
            return "Севастополь"
        default: return data
    }
}
export const setRegion = (data) => {
    switch(data){
        case "Автономна Республіка Крим":
        case "АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ":
            return "0100000000+:+АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ"
        case "Вінницька область":
        case "ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ":
            return "0500000000+:+ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ"
        case "Волинська область":
        case "ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК":
            return "0700000000+:+ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК"
        case "Дніпропетровська область":
        case "ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО":
            return "1200000000+:+ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО"
        case "Донецька область":
        case "ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК":
            return "1400000000+:+ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК"
        case "Житомирська область":
        case "ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР":
            return "1800000000+:+ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР"
        case "Закарпатська область":
        case "ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД":
            return "2100000000+:+ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД"
        case "Запорізька область":
        case "ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ":
            return "2300000000+:+ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ"
        case "Івано-Франківська область":
        case "ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК":
            return "2600000000+:+ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК"
        case "Київська область":
        case "КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ":
            return "3200000000+:+КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ"
        case "Кіровоградська область":
        case "КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ":
            return "3500000000+:+КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ"
        case "Луганська область":
        case "ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК":
            return "4400000000+:+ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК"
        case "Львівська область":
        case "ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ":
            return "4600000000+:+ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ"
        case "Миколаївська область":
        case "МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ":
            return "4800000000+:+МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ"
        case "Одеська область":
        case "ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА":
            return "5100000000+:+ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА"
        case "Полтавська область":
        case "ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА":
            return "5300000000+:+ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА"
        case "Рівненська область":
        case "РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ":
            return "5600000000+:+РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ"
        case "Сумська область":
        case "СУМСЬКА ОБЛАСТЬ/М.СУМИ":
            return "5900000000+:+СУМСЬКА ОБЛАСТЬ/М.СУМИ"
        case "Тернопільська область":
        case "ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ":
            return "6100000000+:+ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ"
        case "Харківська область":
        case "ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ":
            return "6300000000+:+ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ"
        case "Херсонська область":
        case "ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН":
            return "6500000000+:+ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН"
        case "Хмельницька область":
        case "ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ":
            return "6800000000+:+ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ"
        case "Черкаська область":
        case "ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ":
            return "7100000000+:+ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ"
        case "Чернівецька область":
        case "ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ":
            return "7300000000+:+ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ"
        case "Чернігівська область":
        case "ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ":
            return "7400000000+:+ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ"
        case "Київ":
        case "М.КИЇВ":
            return "8000000000+:+М.КИЇВ"
        case "Севастополь":
        case "М.СЕВАСТОПОЛЬ":
            return "8500000000+:+М.СЕВАСТОПОЛЬ"
    }
}