import React from 'react';
import styles from './news.module.css';

import { Link } from 'react-router-dom';

import {
    setDataAddress,
} from '../../../redux/createAddress';

import {NEWS_TITLE_TOP, NEWS_TITLE_BOTTOM} from '../../../model/lang/topMenu.js';
import {connect} from 'react-redux';

import {
    auctionsGetMy,
    changeAuctionsSwitchWindow,
    auctionsGetClassificationsByType,
    auctionsGetClassificationsByTimberSortment,
    auctionsGetClassificationsByTimberSortmentFromCashe,
    auctionsGetClassificationsByTimberSpecies,
    auctionsGetClassificationsByTimberSpeciesFromCashe,
    auctionsGetClassificationsByTimberClass,
    auctionsGetClassificationsByTimberClassFromCashe,
    auctionsGetClassificationsByTimberDiameter,
    auctionsGetClassificationsByTimberDiameterFromCashe,
    auctionsGetClassificationsByTimberLength,
    auctionsGetClassificationsByTimberLengthFromCashe,
    auctionsGetClassificationsByTimberStorage,
    auctionsGetClassificationsByTimberStorageFromCashe,
    auctionsGetClassificationsByTimberProductionYear,
    auctionsGetClassificationsByTimberProductionYearFromCashe,
    auctionsGetClassificationsByTimberProductionQuarter,
    auctionsGetClassificationsByTimberProductionQuarterFromCashe,
    auctionsGetClassificationsByWagonSpecies,
    auctionsGetClassificationsByWagonSpeciesFromCashe,
    auctionsGetClassificationsByTypeNadraEnglish,
    auctionsGetClassificationsByTypeNadraEnglishFromCashe,
    auctionsGetClassificationsByByShemaSubsoilUsageType,
    auctionsGetClassificationsByTypeRailway,
    auctionsGetClassificationsByCarryType,
    auctionsGetClassificationsByTypeRailwayCargoAdd,
    auctionsGetWagonRestrict,
    auctionsGetWagonRestrictType,
    setPopUpAuctionMyNull,
} from '../../../redux/actions/auctions/auctions.js';

import {actionSetValueRedax} from '../../../redux/action.js';

import {
    changeCreateItemClassificationAddId,
    getMyAuction,
    changeLoader,
    changeMyCabinetCurentPage,
} from '../../../redux/actions.js';

import {
    setDictionariesOwnershipType,
    setDictionariesEncumbrances,
    setDictionariesPowerSupplyClass,
    setDictionariesFloorType,
    setDictionariesConstructionTechnology,
    setDictionariesFuelType,
    setDictionariesTransmissionType,
    setDictionariesEligibilityCriteria,
} from '../../../redux/dictionaries.js';

import {bankAccountGet, bankAccountShemeGet, openBankWindows} from '../../../redux/actions/bankAccount/bankAccount.js';

import {auctionsBidsGetMy} from  '../../../redux/actions/auctionBid/auctionBid.js';

import {localClearToken} from '../../../redux/actions/default/default';

import {unitsGetByType, unitsGetByTypeEnergy, unitsGetByTypeRailway, unitsGetByTypeSubsoil, unitsGetByTypeZU} from '../../../redux/actions/itemUnits/itemUnits.js';

import SvgNews from './news.svg';
import SvgArrow from './user_menu_arrowdown.svg';

import {addressesGetMy, setKoatuuRegion} from '../../../redux/actions/address/address.js';
import {
    workMyPageCurrentAuction,
    workMyPageNextAuction
} from '../../../redux/togetherApi.js';

const News = (props) => {
    let tmpTypeAccount = null
    if(props.userProfiles !== null){
        if(
            props.userProfiles.canCreateAuction === true ||
            props.userProfiles.canCreateLandAuction === true ||
            props.userProfiles.canCreateLeaseAuction === true ||
            props.userProfiles.canCreateAlienation === true ||
            props.userProfiles.canCreateBasicSell === true ||
            props.userProfiles.canCreateCommercialSellAuction === true ||
            props.userProfiles.canCreateBankruptAuction === true ||
            props.userProfiles.canCreatePrivatization === true ||
            props.userProfiles.canCreateArma === true// ||
        ){
            tmpTypeAccount = 'Мої аукціони'
        }else if(props.userProfiles.canCreateBid === true){
            tmpTypeAccount = 'Мої пропозиції'
        }
    }
    let tmp = null;
    if(props.token === null){
        tmp = (
            <div className={styles.wrapNews} >
                <a className={styles.korusne_link} href="https://news.dto.com.ua">
                    <div className={styles.wrap_link}>
                        <div className={styles.korusne_img}>
                            <img src={SvgNews} alt='' />
                            <div className={styles.korusne_title}>{NEWS_TITLE_TOP[props.langFlag]}</div>
                        </div>
                        <div className={styles.korusne_text}>{NEWS_TITLE_BOTTOM[props.langFlag]}</div>
                    </div>
                </a>
            </div>
        )
    }else{
        let tmpBalance = null
        if(props.userProfiles !== null){
            tmpBalance = props.userProfiles.balance
        }
        tmp = (
            <div className={styles.wrapNews} >
                <div className={styles.wrap_link}>
                    <div className={styles.korusne_img}>
                        <div className={styles.korusne_title}>МІЙ КАБІНЕТ</div>
                        <img className={styles.svgArrow} src={SvgArrow} alt='' />
                    </div>
                    <div className={styles.korusne_text}>Меню користувача</div>
                    <div className={styles.popUp}>
                        <div className={styles.popUp_menuElem}>Баланс {tmpBalance} грн.</div>
                        <a href="https://www.dto.com.ua/helpful/faq">
                            <div className={styles.popUp_menuElem}>Запитання та відповіді</div>
                        </a>
                        <a href="https://www.dto.com.ua/helpful/presentations">
                            <div className={styles.popUp_menuElem}>Корисне</div>
                        </a>
                        <a href="https://news.dto.com.ua">
                            <div className={styles.popUp_menuElem}>Новини</div>
                        </a>
                        {/*<Link to={`/profile`}>*/}
                        <a href="https://www.dto.com.ua/registration/sellers">
                        {/*<a href="https://beta.dto.com.ua/registration/confirm">*/}
                            <div className={styles.popUp_menuElem}>Профіль</div>
                        </a>
                        {/*</Link>*/}
                        <div
                            className={styles.popUp_menuElem}
                            onClick={
                                () => {
                                    let data = {
                                        domen: process.env.REACT_APP_END_POINT_BD,
                                        version: 'v1',
                                        local: props.langFlag,
                                        params: '?limit=25'
                                    }
                                    let header = {
                                        'Authorization': props.token.access_token,
                                        'Content-Type': 'application/json'
                                    }
                                    props.bankAccountGet(data, header)
                                    props.openBankWindows(true)
                                }
                            }
                        >
                            Банки
                        </div>
                        {/*<div
                            className={styles.popUp_menuElem}
                            onClick={
                                () => {
                                    let header5 = {
                                        'Authorization': props.token.access_token,
                                        'Content-Type': 'application/json'
                                    }
                                    let data2 = {
                                        domen: process.env.REACT_APP_END_POINT_BD,
                                        version: 'v1',
                                        local: props.langFlag,
                                        params: '?limit=100'
                                    }
                                    props.addressesGetMy(data2, header5)
                                    getKoatuuRegion(props)
                                    props.setDataAddress(true, 'SET_POPUP_ADDRESSES')
                                }
                            }
                        >
                            Адреси
                        </div>*/}
                        {/*<div
                            className={styles.popUp_menuElem}
                            onClick={
                                () => {
                                    getAddressOrg(props)
                                    getKoatuuRegion(props)
                                    props.setDataAddress(true, 'SET_POPUP_ORGANIZATIONS')
                                }
                            }
                        >
                            Організації
                        </div>*/}
                        <Link to={`/create_auctions`}>
                            <div
                                className={styles.popUp_menuElem}
                                onClick={
                                    () => {
                                        props.setDataAddress('all', 'SET_MY_AUCTIONS_STATUS')
                                        props.getMyAuction(null)
                                        props.setPopUpAuctionMyNull()
                                        props.changeAuctionsSwitchWindow(0)
                                        
                                        let dataHelp = {
                                            domen: process.env.REACT_APP_END_POINT_BD,
                                            version: 'v1',
                                            local: props.langFlag,
                                            params: '?onlyCount=1'
                                        }
                                        let header = {
                                            'Authorization': props.token.access_token,
                                            'Content-Type': 'application/json'
                                        }
                                        let header5 = {
                                            'Authorization': props.token.access_token,
                                            'Content-Type': 'application/json'
                                        }
                                        //props.auctionsGetMy(dataHelp, header)
                                        //props.auctionsBidsGetMy(dataHelp, header)

                                        //if(classificationsGets === null){
                                            let data1 = {
                                                domen: process.env.REACT_APP_END_POINT_BD,
                                                version: 'v1',
                                                local: props.langFlag,
                                                params: 'timber-english?limit=100'
                                            }
                                            props.unitsGetByType(data1, header)
                                            props.unitsGetByTypeEnergy(data1, header)
                                            props.unitsGetByTypeRailway(data1, header)
                                            props.unitsGetByTypeSubsoil(data1, header)
                                            props.unitsGetByTypeZU(data1, header)

                                            let data2 = {
                                                domen: process.env.REACT_APP_END_POINT_BD,
                                                version: 'v1',
                                                local: props.langFlag,
                                                params: '?limit=100'
                                            }
                                            //TODO: Нерабочий АПИ 20211018
                                            // props.addressesGetMy(data2, header5)
                                        //}

                                        let data5 = {
                                            domen: process.env.REACT_APP_END_POINT_BD,
                                            version: 'v1',
                                            local: props.langFlag,
                                            params: '?limit=100'
                                        }
                                        props.auctionsGetClassificationsByType(data5, header5)
                                        // props.auctionsGetClassificationsByTimberSortment(data5, header5)
                                        // props.auctionsGetClassificationsByTimberSpecies(data5, header5)
                                        // props.auctionsGetClassificationsByTimberClass(data5, header5)
                                        // props.auctionsGetClassificationsByTimberDiameter(data5, header5)
                                        // props.auctionsGetClassificationsByTimberLength(data5, header5)
                                        // props.auctionsGetClassificationsByTimberStorage(data5, header5)
                                        // props.auctionsGetClassificationsByTimberProductionYear(data5, header5)
                                        // props.auctionsGetClassificationsByTimberProductionQuarter(data5, header5)
                                        props.auctionsGetClassificationsByWagonSpecies(data5, header5)
                                        props.auctionsGetClassificationsByTypeNadraEnglish(data5, header5)
                                        props.auctionsGetClassificationsByByShemaSubsoilUsageType(data5, header5)
                                        props.auctionsGetClassificationsByCarryType(data5, header5)
                                        props.auctionsGetClassificationsByTypeRailwayCargoAdd(data5, header5)
                                        
                                        props.auctionsGetClassificationsByTypeRailway(data5, header5)

                                        //TODO: Нерабочий АПИ 20211018
                                        //props.bankAccountShemeGet(data5, header5)
                                        
                                        
                                        
                                        //props.changeCreateItemClassificationAddId(data5, header5)
                                        props.auctionsGetWagonRestrict(data5, header5)
                                        props.auctionsGetWagonRestrictType(data5, header5)
                                        
                                        //concatDataClassification(props)
                                        getAdditionalClassificationZU(props)
                                        getAdditionalClassificationCVZU(props)

                                        getData(props)
                                        //getDictionariesOwnershipType(props)
                                        //getDictionariesEncumbrances(props)
                                        //getDictionariesPowerSupplyClass(props)
                                        //getDictionariesFloorType(props)
                                        //getDictionariesConstructionTechnology(props)
                                        //getDictionariesFuelType(props)
                                        //getDictionariesTransmissionType(props)
                                        //getDictionariesEligibilityCriteria(props)
                                    }
                                }
                            >
                                {tmpTypeAccount}
                            </div>
                        </Link>
                        {/*<Link to={`/finance`}>*/}
                        <a href="https://www.dto.com.ua/cabinet/finance">
                            <div className={styles.popUp_menuElem}>Фінанси</div>
                        </a>
                        {/*</Link>*/}
                        {/*<Link to={`/settings`}>*/}
                        <a href="https://www.dto.com.ua/cabinet/settings">
                            <div className={styles.popUp_menuElem}>Налаштування</div>
                        </a>
                        {/*</Link>*/}
                        <Link to={`/posts`}>
                            <div className={styles.popUp_menuElem}>Повідомлення</div>
                        </Link>
                        <div
                            className={styles.popUp_menuElem + ' ' + styles.popUp_menuElemEnd}
                            onClick={() => {props.localClearToken()}}
                        >
                            Вихід
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return tmp
}

async function getAdditionalClassificationCVZU(props){
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/byScheme/cvzu`;
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/classifications/byScheme/CVZU`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        await props.setDataAddress(json, 'CREATE_CVZU_DATA')
    }
}

/////////////////////////////////////////////////////
//Новый аук
async function concatDataClassification(props){
    let tmpArr0 = await getDictionaryJointPropertyComplex(props, '06121000-6')
    let tmpArr1 = await tmpArr0.concat(await getDictionaryJointPropertyComplex(props, '06111000-3'))
    let tmpArr2 = await tmpArr1.concat(await getDictionaryJointPropertyComplex(props, '06112000-0'))
    let tmpArr3 = await tmpArr2.concat(await getDictionaryJointPropertyComplex(props, '06122000-3'))
    let tmpArr4 = await tmpArr3.concat(await getDictionaryJointPropertyComplex(props, '06123000-0'))
    let tmpArr5 = await tmpArr4.concat(await getDictionaryJointPropertyComplex(props, '06124000-7'))
    let tmpArr6 = await tmpArr5.concat(await getDictionaryJointPropertyComplex(props, '06125000-4'))
    let tmpArr7 = await tmpArr6.concat(await getDictionaryJointPropertyComplex(props, '06126000-1'))
    let tmpArr8 = await tmpArr7.concat(await getDictionaryJointPropertyComplex(props, '06127000-8'))
    let tmpArr9 = await tmpArr8.concat(await getDictionaryJointPropertyComplex(props, '06128000-5'))
    let tmpArr10 = await tmpArr9.concat(await getDictionaryJointPropertyComplex(props, '06129000-2'))
    props.setDataAddress(tmpArr10, 'SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD')
}

async function getDictionaryJointPropertyComplex(props, mask){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/autocomplete/classification_id?limit=1000&offset=0&classification_id=${mask}&scheme=CAV&mask=${mask}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        return await response.json()
    }
}

async function getAdditionalClassificationZU(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/dictionaries/kvtspz?limit=1000`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        await props.setDataAddress(json, 'CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATIONS_ARR')
    }
}
/////////////////////////////////////////////////////







async function getAddressOrg(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/organizations?limit=30&offset=0`
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            //props.setEditAuctionFromId(json)
            props.setDataAddress(json, 'GET_ADDRESS_NEW_BOOKS')
        }
    }
}

async function getKoatuuRegion(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/koatuu/byMask`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json1 = await response.json()
        if(json1.hasOwnProperty('message') === true){}
        else{
            props.setKoatuuRegion(json1)
        }
        
    }
}
async function getData(props){
    
    let data = {}
    data.domen = process.env.REACT_APP_END_POINT_BD
    data.version = 'v1'
    data.local = props.langFlag
    data.params = `?offset=0`
    props.changeMyCabinetCurentPage(0)
    let header = {
        'Authorization': props.token.access_token,
        'Content-Type': 'application/json'
    }

    props.auctionsBidsGetMy(data, header)
    props.bankAccountGet(data, header)
    //props.auctionsGetMy(data, header)
    getAucionsList(props)
    await props.workMyPageCurrentAuction(0)
    if(props.auctionsMy !== null){
        if(props.auctionsMy.length === 10) {
            await props.workMyPageNextAuction(1)
        }else{
            await props.workMyPageNextAuction(null)
        }
    }
}



async function getAucionsList(props){
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-drafts/my`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціонів', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            const tmp = await createList(json)
            if(tmp.length > 0){
                props.setDataAddress(tmp, 'AUCTIONS_GET_MY')
            }else{
                props.setDataAddress(null, 'AUCTIONS_GET_MY')
            }

        }
    }
    props.changeLoader(false)
}

function createList(json){
    let tmp = []
    if(json.length > 0){
        for(let i = 0; i < json.length; i++){
            if(json[i].publicAuction !== null){
                tmp[i] = json[i].publicAuction
                tmp[i].dataAuction = json[i]
            }else if(json[i].oldAuctionDraft !== null){
                tmp[i] = json[i].oldAuctionDraft
                tmp[i].dataAuction = json[i]
            }else if(json[i].auctionDraft !== null){
                tmp[i] = json[i].auctionDraft
                tmp[i].dataAuction = json[i]
            }
        }
    }
    return tmp
}












////////////////////////////////////////////
async function getDictionariesOwnershipType(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/dictionaries/ownershipType`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні словнику', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            props.setDictionariesOwnershipType(json)
        }
    }
}
async function getDictionariesEncumbrances(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/dictionaries/encumbrances`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні словнику', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            props.setDictionariesEncumbrances(json)
        }
    }
}
async function getDictionariesPowerSupplyClass(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/dictionaries/powerSupplyClass`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні словнику', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            props.setDictionariesPowerSupplyClass(json)
        }
    }
}
async function getDictionariesFloorType(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/dictionaries/floorType`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні словнику', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            props.setDictionariesFloorType(json)
        }
    }
}
async function getDictionariesConstructionTechnology(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/dictionaries/constructionTechnology`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні словнику', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            props.setDictionariesConstructionTechnology(json)
        }
    }
}
async function getDictionariesFuelType(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/dictionaries/fuelType`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні словнику', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            props.setDictionariesFuelType(json)
        }
    }
}
async function getDictionariesTransmissionType(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/dictionaries/transmissionType`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні словнику', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            props.setDictionariesTransmissionType(json)
        }
    }
}
async function getDictionariesEligibilityCriteria(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/dictionaries/eligibilityCriteria`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні словнику', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            //props.setDictionariesEligibilityCriteria(json)
            props.actionSetValueRedax(json, 'SET_DICTIONARIES_ELIGIBILITYCRITERIA')
        }
    }
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        token:  state.start.token,
        classificationsGets: state.start.classificationsGets,
        userProfiles: state.start.userProfiles,
        auctionsMy: state.start.auctionsGetMy,
        userProfiles: state.start.userProfiles,
        myCabinetPage: state.start.myCabinetPage,
    }
}

const mapDispatchToProps = {
    auctionsGetMy,
    localClearToken,
    changeAuctionsSwitchWindow,
    unitsGetByType,
    unitsGetByTypeEnergy,
    unitsGetByTypeRailway,
    unitsGetByTypeSubsoil,
    unitsGetByTypeZU,
    addressesGetMy,

    auctionsGetClassificationsByType,
    auctionsGetClassificationsByTimberSortment,
    auctionsGetClassificationsByTimberSortmentFromCashe,
    auctionsGetClassificationsByTimberSpecies,
    auctionsGetClassificationsByTimberSpeciesFromCashe,
    auctionsGetClassificationsByTimberClass,
    auctionsGetClassificationsByTimberClassFromCashe,
    auctionsGetClassificationsByTimberDiameter,
    auctionsGetClassificationsByTimberDiameterFromCashe,
    auctionsGetClassificationsByTimberLength,
    auctionsGetClassificationsByTimberLengthFromCashe,
    auctionsGetClassificationsByTimberStorage,
    auctionsGetClassificationsByTimberStorageFromCashe,
    auctionsGetClassificationsByTimberProductionYear,
    auctionsGetClassificationsByTimberProductionYearFromCashe,
    auctionsGetClassificationsByTimberProductionQuarter,
    auctionsGetClassificationsByTimberProductionQuarterFromCashe,
    auctionsGetClassificationsByWagonSpecies,
    auctionsGetClassificationsByWagonSpeciesFromCashe,

    auctionsGetClassificationsByTypeNadraEnglish,
    auctionsGetClassificationsByTypeNadraEnglishFromCashe,
    auctionsGetClassificationsByByShemaSubsoilUsageType,
    auctionsGetClassificationsByTypeRailway,
    auctionsGetClassificationsByCarryType,
    auctionsGetClassificationsByTypeRailwayCargoAdd,

    auctionsBidsGetMy,
    bankAccountGet,
    bankAccountShemeGet,

    changeCreateItemClassificationAddId,
    auctionsGetWagonRestrict,
    auctionsGetWagonRestrictType,
    getMyAuction,
    setPopUpAuctionMyNull,

    workMyPageCurrentAuction,
    workMyPageNextAuction,
    openBankWindows,

    setDictionariesOwnershipType,
    setDictionariesEncumbrances,
    setDictionariesPowerSupplyClass,
    setDictionariesFloorType,
    setDictionariesConstructionTechnology,
    setDictionariesFuelType,
    setDictionariesTransmissionType,
    setDictionariesEligibilityCriteria,
    changeMyCabinetCurentPage,
    changeLoader,

    actionSetValueRedax,
    setDataAddress,
    setKoatuuRegion,
}

export default connect(mapStateToProps, mapDispatchToProps)(News)