import React from 'react';

import {connect} from 'react-redux';

import ViewBid from '../viewBid/viewBid.js';

const BidLot = ( props ) => {
    let tmp = null;
    if(props.auctionsAllBids.arr !== null){
        return <ViewBid elemBid={props.auctionsAllBids.arr}/>
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        auctionsAllBids: state.start.auctionsAllBids,
    }
}

export default connect(mapStateToProps, null)(BidLot)