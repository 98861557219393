//Elem 0 = ua, 1 = en
export const FOREST_CATEGORY_MENU_LANG = ['Ліс', 'Forest '];

export const BANKRUPTCY_CATEGORY_MENU_LANG = ['Банкрутство', 'Bankruptcy'];

export const SECONDARY_RAW_MATERIALS_SCRAP_CATEGORY_MENU_LANG = ['Металобрухт, вторсировина', 'Secondary raw materials, scrap'];

export const SMALL_PRIVATIZATION_CATEGORY_MENU_LANG = ['Мала приватизація', 'Small privatization'];

export const CLAIM_RIGHTS_CATEGORY_MENU_LANG = ['Права і вимоги', 'Claim rights'];

export const RESIDENTIAL_REAL_ESTATE_CATEGORY_MENU_LANG = ['Житлова нерухомість', 'Residential Real Estate'];

export const NON_RESIDENTIAL_REAL_ESTATE_CATEGORY_MENU_LANG = ['Нежитлова нерухомість', 'Non-residential Real Estate'];

export const GROUND_SECTION_CATEGORY_MENU_LANG = ['Земельні ділянки', 'Ground section'];

export const VEHICLES_CATEGORY_MENU_LANG = ['Транспортні засоби', 'Vehicles'];

export const LEASE_CATEGORY_MENU_LANG = ['Оренда', 'Lease'];

export const USE_OF_SUBSOIL_CATEGORY_MENU_LANG = ['Користування надрами', 'Use of subsoil'];

export const OTHER_PROPERTY_CATEGORY_MENU_LANG = ['Інше майно', 'Other property'];