import React from 'react';
import styles from './disqualifiedBidsDetails.module.css';

import {connect} from 'react-redux';

import TextHalf from '../../elements/viewData/textHalf'
import SlimmerBlock from '../../elements/slimmerBlock/slimmerBlock'
import { isLSE, isLRE, isLSP, isLAE, isLAW, isLAP, isAPE, isAPD } from '../../../redux/aucTypes';
import { checkProp, getIdentifierType } from '../../../redux/check';
import ContactPoint from '../../elements/contactPoint/contactPoint';

const InformationAboutTheOrganizer = (props) => {
    if(props.auctionId !== null){
        if(
            isLRE(props.auctionId.sellingMethod) ||
            isLSE(props.auctionId.sellingMethod) ||
            isLSP(props.auctionId.sellingMethod) 
        ){
            if(props.auctionId.disqualifiedBidsDetails !== null){
                return (
                    <SlimmerBlock
                        title='Дискваліфіковані учасники з попереднього аукціону'
                        content={viewUnit(props)}
                    />
                )
            }else if(
                props.auctionId.hasOwnProperty('disqualifiedBids') === true
            ){
                if(props.auctionId.disqualifiedBids !== null && props.auctionId.disqualifiedBids.length > 0){
                    return (
                        <SlimmerBlock
                            title='Дискваліфіковані учасники з попереднього аукціону'
                            content={viewUnitID(props)}
                        />
                    )
                }
            }
        }else if(
            isLAE(props.auctionId.sellingMethod) || isLAW(props.auctionId.sellingMethod) || isLAP(props.auctionId.sellingMethod)
        ){
            if(props.auctionId.disqualifiedBidsDetails !== null){
                return (
                    <SlimmerBlock
                        title='Дискваліфіковані учасники з попереднього аукціону'
                        content={viewUnit(props)}
                    />
                )
            }else if(
                props.auctionId.hasOwnProperty('disqualifiedBids') === true
            ){
                if(props.auctionId.disqualifiedBids !== null && props.auctionId.disqualifiedBids.length > 0){
                    return (
                        <SlimmerBlock
                            title='Дискваліфіковані учасники з попереднього аукціону'
                            content={viewUnitID(props)}
                        />
                    )
                }
            }
        }else if(
            isAPE(props.auctionId.sellingMethod) || isAPD(props.auctionId.sellingMethod)
        ){
            if(props.auctionId.disqualifiedBidsDetails !== null){
                return (
                    <SlimmerBlock
                        title='Дискваліфіковані учасники з попереднього аукціону'
                        content={viewUnit(props)}
                    />
                )
            }else if(
                props.auctionId.hasOwnProperty('disqualifiedBids') === true
            ){
                if(props.auctionId.disqualifiedBids !== null && props.auctionId.disqualifiedBids.length > 0){
                    return (
                        <SlimmerBlock
                            title='Дискваліфіковані учасники з попереднього аукціону'
                            content={viewUnitID(props)}
                        />
                    )
                }
            }
        }
    }
    return null
}

function viewUnitID(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('disqualifiedBids') !== null){
            if(props.auctionId.disqualifiedBids !== null){
                if(props.auctionId.disqualifiedBids.length > 0){
                    return props.auctionId.disqualifiedBids.map(
                        (i, count) => {
                            return <TextHalf
                                title={`ЄДРПОУ учасника ${count+1}`}
                                data={i}
                            />
                        }
                    )
                }
            }
        }
    }
}

function viewUnit(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('disqualifiedBidsDetails') !== null){
            if(props.auctionId.disqualifiedBidsDetails !== null){
                let tmp = []
                let count = 0
                for (let key in props.auctionId.disqualifiedBidsDetails) {
                    tmp[count] = <div className={styles.infoBlock}>
                        {checkProp(props.auctionId.disqualifiedBidsDetails[key], 'name') && checkProp(props.auctionId.disqualifiedBidsDetails[key].name, 'uk_UA') ?
                            <div className={styles.infoBlockTitle}>
                               {props.auctionId.disqualifiedBidsDetails[key].name.uk_UA}
                            </div> : null
                        }
                        {checkProp(props.auctionId.disqualifiedBidsDetails[key], 'name') && checkProp(props.auctionId.disqualifiedBidsDetails[key].name, 'uk_UA') ?
                            <TextHalf
                                title={getIdentifierType(props.auctionId.disqualifiedBidsDetails[key].identifier.scheme)}
                                data={props.auctionId.disqualifiedBidsDetails[key].identifier.id}
                            /> : null
                        }
                        {checkProp(props.auctionId.disqualifiedBidsDetails[key], 'address') ?
                            <TextHalf
                                title={'Юридична адреса'}
                                data={contactKOATUU(props.auctionId.disqualifiedBidsDetails[key])}
                            /> : null
                        }
                        < ContactPoint
                            title='Контактні дані'
                            data={props.auctionId.disqualifiedBidsDetails[key].contactPoint}
                        />
                    </div>
                    count++
                }
                return tmp
            }
        }
    }
    return null
}

function contactKOATUU(data){
    if(data !== null){
        if(data.hasOwnProperty('address')){
            if(data.address !== null){
                return (
                    `${data.address.postalCode}, ${data.address.countryName.uk_UA}, ${data.address.region.uk_UA}, ${data.address.locality.uk_UA}, ${data.address.streetAddress.uk_UA}`
                )
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(InformationAboutTheOrganizer)
