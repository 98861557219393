import React, { Fragment } from 'react';
import styles from './windowAwards.module.css';

import {connect} from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import {
    changeAuctionsSwitchWindow,
    auctionsGetMy,
    setPopUpAuctionMyNull,
} from '../../../redux/actions/auctions/auctions.js';
import {changeCreateAuctionTab} from '../../../redux/actions.js';

import {
    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType
} from '../../../redux/actions/documents/documents.js';

import ListDocuments from './itemsList/itemsList.js';
import ReturnButton from '../returnButton'
import {modifiDateString} from '../../../redux/modifiDate'

const WindowAuction = ( props ) => {
    let tmpText = 'Інформація про учасника';
    let tmpAward = null;
    if(props.itemAward !== null && props.userProfiles.canCreateAuction === true){
        let tmpClassification = <>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Опис ставки:</div>
                <div className={styles.lineWrapAwards_content}></div>
            </div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Опис схеми:</div>
                <div className={styles.lineWrapAwards_content}></div>
            </div>
        </>
        if(props.itemAward.bid.classification !== null){
            tmpClassification = <>
                <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                    <div className={styles.lineWrapAwards_title}>Опис ставки:</div>
                    <div className={styles.lineWrapAwards_content}>{props.itemAward.bid.classification.description}</div>
                </div>
                <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                    <div className={styles.lineWrapAwards_title}>Опис схеми:</div>
                    <div className={styles.lineWrapAwards_content}>{props.itemAward.bid.classification.scheme + ' ' + props.itemAward.bid.classification.classificationId}</div>
                </div>
            </> 
        }
        let tmpQuantity = <div>Кількість:</div>
        if(props.itemAward.bid.quantity !== null && props.itemAward.bid.unit !== null){
            tmpQuantity = <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Кількість:</div>
                <div className={styles.lineWrapAwards_content}>{props.itemAward.bid.quantity + ' ' + props.itemAward.bid.unit.symbol}</div>
            </div>
        }
        tmpAward = <div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Аукціон Id:</div>
                <div className={styles.lineWrapAwards_content}>{props.itemAward.auction.id}</div>
            </div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Аукціон від:</div>
                <div className={styles.lineWrapAwards_content}>{modifiDateString(props.itemAward.auction.createdAt, 3, false)}</div>
            </div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Ідентіфікатор аукціону:</div>
                <div className={styles.lineWrapAwards_content}>{props.itemAward.auction.auctionId}</div>
            </div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Дата початку кваліфікації учасника аукціону:</div>
                <div className={styles.lineWrapAwards_content}>{modifiDateString(props.itemAward.auction.startDate, 3, false)}</div>
            </div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Найменування аукціону:</div>
                <div className={styles.lineWrapAwards_content}>{props.itemAward.auction.title}</div>
            </div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Опис аукціону:</div>
                <div className={styles.lineWrapAwards_content}>{props.itemAward.auction.description}</div>
            </div>

            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Покупець:</div>
                <div className={styles.lineWrapAwards_content}>{props.itemAward.bid.bidders[0].name}</div>
            </div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Контактні дані назва:</div>
                <div className={styles.lineWrapAwards_content}>{props.itemAward.bid.bidders[0].contactPoint.name}</div>
            </div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Контактні дані телефон:</div>
                <div className={styles.lineWrapAwards_content}>{props.itemAward.bid.bidders[0].contactPoint.telephone}</div>
            </div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Контактні дані факс:</div>
                <div className={styles.lineWrapAwards_content}>{props.itemAward.bid.bidders[0].contactPoint.faxNumber}</div>
            </div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Контактні дані e-mail:</div>
                <div className={styles.lineWrapAwards_content}>{props.itemAward.bid.bidders[0].contactPoint.email}</div>
            </div>

            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Адреса:</div>
                <div className={styles.lineWrapAwards_content}>{
                    props.itemAward.bid.bidders[0].address.postalCode + ' ' + 
                    props.itemAward.bid.bidders[0].address.countryName + ' ' +
                    props.itemAward.bid.bidders[0].address.region + ' ' +
                    props.itemAward.bid.bidders[0].address.locality + ' ' +
                    props.itemAward.bid.bidders[0].address.streetAddress
                }</div>
            </div>
            

            {tmpClassification}
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Цінова пропозиція:</div>
                <div className={styles.lineWrapAwards_content}>{props.itemAward.bid.value.amount + ' ' + props.itemAward.bid.value.currency}</div>
            </div>
            {tmpQuantity}
            <div>
                {viewDocument(props)}
            </div>
            <div>Документи пропозиції:</div>
            {viewBidDoc(props, props.itemAward)}
        </div>
    }else if(props.itemAward !== null && props.userProfiles.canCreateBid === true){
        tmpAward = <div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Аукціон Id:</div>
                <div className={styles.lineWrapAwards_content}>{props.itemAward.auction.id}</div>
            </div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Аукціон від:</div>
                <div className={styles.lineWrapAwards_content}>{modifiDateString(props.itemAward.auction.createdAt, 3, false)}</div>
            </div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Ідентіфікатор аукціону:</div>
                <div className={styles.lineWrapAwards_content}>{props.itemAward.auction.auctionId}</div>
            </div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Дата проведення аукціону:</div>
                <div className={styles.lineWrapAwards_content}>{modifiDateString(props.itemAward.auction.startDate, 3, false)}</div>
            </div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Найменування аукціону:</div>
                <div className={styles.lineWrapAwards_content}>{props.itemAward.auction.title}</div>
            </div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Опис аукціону:</div>
                <div className={styles.lineWrapAwards_content}>{props.itemAward.auction.description}</div>
            </div>
            <div>
                {viewDocument(props)}
            </div>
            <div>Документи пропозиції:</div>
            {viewBidDoc(props, props.itemAward)}
        </div>
    }
    return (
        <div className={styles.documentWrap}>
            <div className={styles.flexBlock}>
                <div className={styles.flexBlockElem}>
                    {/*<div className={styles.headers}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={
                                () => {
                                    props.changeDocumentId(null)
                                    props.changeDocumentTitle('')
                                    props.changeDocumentDescription('')
                                    props.changeDocumentLang('uk')
                                }
                            }
                        >
                            Створити новий документ
                        </Button>
                        </div>*/}
                    <ListDocuments />
                </div>
                <div className={styles.flexBlockElem}>
                    <div className={styles.headers}>{tmpText}</div>
                    {tmpAward}
                </div>
            </div>
            {/*<Button
                className={styles.buttonExitDocument}
                variant="contained"
                color="primary"
                onClick={
                    () => {
                        props.changeCreateAuctionTab(0);
                        props.changeAuctionsSwitchWindow(0)
                        props.setPopUpAuctionMyNull()
                    }
                }
            >
                Повернутися до списку аукціонів
            </Button>*/}
            <ReturnButton />
        </div>
    )
}

function viewBidDoc(props, data){
    let tmp = null
    if(data !== null){
        if(data.bid !== null){
            if(data.bid.documents.length > 0){
                tmp = data.bid.documents.map(
                    (e, count) => {
                        let tmpStart = null
                        let tmpEdit = null
                        if(e.datePublished !== null) tmpStart = modifiDateString(e.datePublished, 3, false)
                        if(e.dateModified !== null) tmpEdit = modifiDateString(e.dateModified, 3, false)
                        return (
                            /*<div className={styles.lineWrap}>
                                <div className={styles.line}>
                                    <div>{count+1}</div>
                                    <div>Назва: {e.title}</div>
                                    <div>Дата створення: {tmpStart}</div>
                                </div>
                                <div className={styles.line}>
                                    <div>
                                        <a href={e.url}>Посилання</a>
                                    </div>
                                    <div>Тип документу: {viewDataType(e.documentType)}</div>
                                    <div>Дата редагування: {tmpEdit}</div>
                                </div>
                                <div className={styles.line}>
                                    <div className={styles.lineDiscrip}>Опис: {e.description}</div>
                                </div>
                            </div>*/
                            <a href={e.url} target='_blank'>
                                <div className={styles.lineWrapAwards}>
                                    <div className={styles.lineWrapAwards_text}>
                                        <div className={styles.lineWrapAwards}>
                                            <div className={styles.lineWrapAwards_title}>Документ №:</div><div className={styles.lineWrapAwards_content}>{count+1}</div>
                                        </div>
                                        <div className={styles.lineWrapAwards}>
                                            <div className={styles.lineWrapAwards_title}>Назва:</div><div className={styles.lineWrapAwards_content}>{e.title}</div>
                                        </div>
                                        <div className={styles.lineWrapAwards}>
                                            <div className={styles.lineWrapAwards_title}>Опис:</div><div className={styles.lineWrapAwards_content}>{e.description}</div>
                                        </div>
                                        <div className={styles.lineWrapAwards}>
                                            <div className={styles.lineWrapAwards_title}>Тип документу:</div><div className={styles.lineWrapAwards_content}>{viewDataType(e.documentType)}</div>
                                        </div>
                                        <div className={styles.lineWrapAwards}>
                                            <div className={styles.lineWrapAwards_title}>Дата створення:</div><div className={styles.lineWrapAwards_contentDate}>{tmpStart}</div>
                                        </div>
                                        <div className={styles.lineWrapAwards}>
                                            <div className={styles.lineWrapAwards_title}>Дата редагування:</div><div className={styles.lineWrapAwards_contentDate}>{tmpEdit}</div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        )
                    }
                )
            }
        }
    }
    return <div>{tmp}</div>
}

function viewDataType(dataType){
    switch (dataType){
        case 'commercialProposal': return 'Заява на участь'
        case 'x_nonSanctionedStatement': return 'Заява про непоширення обмежувальних заходів (санкції)'
        case 'qualificationDocuments': return 'Документи що підтверджують кваліфікацію'
        case 'x_passport': return 'Копія паспорта або документа, що посвідчує особу'
        case 'x_IPN': return 'Копія ІПН/РНОКПП'
        case 'x_tenderersRegisterExtract': return 'Витяг з ЄДРПОУ'
        case 'x_nonResidentRegistrations': return 'Документ про реєстрацію у державі її місцезнаходження (нерезидент)'
        case 'eligibilityDocuments': return 'Документи що підтверджують відповідність'
        case 'x_tenderersGuarantee': return 'Фінансове забезпечення'
        case 'x_tenderersLand': return 'Право на земельну ділянку'
        case 'x_tenderersElectricalGrid': return 'Договір про приєднання об\'єкта електроенергетики'
        case 'x_tenderersBeneficialOwner': return 'Інформація про кінцевого бенефіціарного власника (або його відсутність)'
        case 'x_tenderersGoverningBody': return 'Інформація про органи управління'
        case 'x_tenderersRelatedParties': return 'Інформація про пов\'язаних осіб'
        case 'x_tenderersGenerationType': return 'Довідка про вид ВДЕ (для технологічно нейтральних)'
        case 'digitalSignature': return 'Цифровий підпис'
        case 'rejectionProtocol': return 'Документ, що підтверджує дискваліфікацію'
        case 'auctionProtocol': return 'Протокол аукціону'
        case 'act': return 'Документ, що підтверджує відмову'
        case 'contractSigned': return 'Акт'
        case 'contractAnnexe': return 'Додатки до акту'
        case 'courtOrder': return 'Постанова'
        default: return null
    }
}

function documentOption(props){
    let docTypeOption = null;
    if(props.userProfiles.canCreateBid === false){
        switch(props.auctionsMy.sellingMethod){
            case "timber-english":
            case "timber-english-fast":
            case "timber-english-fast-manual":
            case "timber-english-initial-auction":
            case "timber-english-initial-qualification":
            case "timber-english-initial-qualification-fast":
                docTypeOption = <TextField
                    id="outlined-select-currency-native"
                    className={styles.selectWidth}
                    select
                    label="Тип документа"
                    value={props.createDocuments.documentType}
                    onChange={(event)=>{
                        props.changeDocumentType(event.target.value)
                    }}
                    helperText="Выберите тип документа"
                    variant="outlined"
                >
                    <option key={0} selected disabled value='null'>Выбрать</option>
                    <option key={2} value='commercialProposal'>Заява на участь</option>
                    <option key={3} value='qualificationDocuments'>Документи що підтверджують кваліфікацію</option>
                    <option key={4} value='x_passport'>Копія паспорта або документа, що посвідчує особу</option>
                    <option key={5} value='x_IPN'>Копія ІПН/РНОКПП</option>
                    <option key={6} value='x_tenderersRegisterExtract'>Витяг з ЄДРПОУ</option>
                    <option key={7} value='x_nonResidentRegistrations'>Документ про реєстрацію у державі її місцезнаходження (нерезидент)</option>
                    <option key={8} value='x_nonSanctionedStatement'>Заява про непоширення обмежувальних заходів (санкції)</option>
                    <option key={9} value='eligibilityDocuments'>Документи що підтверджують відповідність</option>
                </TextField>
                break;
            case "renewables":
            case "renewables-fast":
            case "renewables-fast-manual":
            case "renewables-initial-auction":
            case "renewables-initial-qualification":
            case "renewables-initial-qualification-fast":
                docTypeOption = <TextField
                        id="outlined-select-currency-native"
                        className={styles.selectWidth}
                        select
                        label="Тип документа"
                        value={props.createDocuments.documentType}
                        onChange={(event)=>{
                            props.changeDocumentType(event.target.value)
                        }}
                        helperText="Выберите тип документа"
                        variant="outlined"
                    >
                        <option key={0} selected disabled value='null'>Выбрать</option>
                        <option key={2} value='x_tenderersGuarantee'>Фінансове забезпечення</option>
                        <option key={3} value='x_tenderersLand'>Право на земельну ділянку</option>
                        <option key={4} value='x_tenderersElectricalGrid'>Договір про приєднання об'єкта електроенергетики</option>
                        <option key={5} value='x_tenderersRegisterExtract'>Витяг з ЄДРПОУ</option>
                        <option key={6} value='x_tenderersBeneficialOwner'>Інформація про кінцевого бенефіціарного власника (або його відсутність)</option>
                        <option key={7} value='x_tenderersGoverningBody'>Інформація про органи управління</option>
                        <option key={8} value='x_tenderersRelatedParties'>Інформація про пов'язаних осіб</option>
                        <option key={9} value='x_tenderersGenerationType'>Довідка про вид ВДЕ (для технологічно нейтральних)</option>
                        <option key={10} value='commercialProposal'>Заява на участь</option>
                    </TextField>
                break;
            case "subsoil-english":
            case "subsoil-english-fast":
            case "subsoil-english-fast-manual":
            case "subsoil-english-initial-auction":
            case "subsoil-english-initial-qualification":
                docTypeOption = <TextField
                        id="outlined-select-currency-native"
                        className={styles.selectWidth}
                        select
                        label="Тип документа"
                        value={props.createDocuments.documentType}
                        onChange={(event)=>{
                            props.changeDocumentType(event.target.value)
                        }}
                        helperText="Выберите тип документа"
                        variant="outlined"
                    >
                        <option key={0} selected disabled value='null'>Выбрать</option>
                        <option key={2} value='commercialProposal'>Заява на участь</option>
                        <option key={3} value='qualificationDocuments'>Документи що підтверджують кваліфікацію</option>
                        <option key={4} value='x_passport'>Копія паспорта або документа, що посвідчує особу</option>
                        <option key={5} value='x_IPN'>Копія ІПН/РНОКПП</option>
                        <option key={6} value='x_nonSanctionedStatement'>Заява про непоширення обмежувальних заходів (санкції)</option>
                        <option key={7} value='eligibilityDocuments'>Документи що підтверджують відповідність</option>
                    </TextField>
                break;
            case "railwayCargo-english":
            case "railwayCargo-english-fast":
            case "railwayCargo-english-fast-manual":
            case "railwayCargo-english-initial-auction":
            case "railwayCargo-english-initial-qualification":
            case "railwayCargo-dutch":
            case "railwayCargo-dutch-fast":
            case "railwayCargo-dutch-fast-manual":
            case "railwayCargo-dutch-initial-auction":
            case "railwayCargo-dutch-initial-qualification":
            case "railwayCargo-dutch-fast-auction-manual-qualification":
            case "railwayCargo-dutch-initial-auction-manual":
                docTypeOption = <TextField
                        id="outlined-select-currency-native"
                        className={styles.selectWidth}
                        select
                        label="Тип документа"
                        value={props.createDocuments.documentType}
                        onChange={(event)=>{
                            props.changeDocumentType(event.target.value)
                        }}
                        helperText="Выберите тип документа"
                        variant="outlined"
                    >
                        <option key={0} selected disabled value='null'>Выбрать</option>
                        <option key={2} value='commercialProposal'>Заява на участь</option>
                        <option key={3} value='qualificationDocuments'>Документи що підтверджують кваліфікацію</option>
                        <option key={4} value='eligibilityDocuments'>Документи що підтверджують відповідність</option>
                    </TextField>
                break;
            default:
                break;
        }
    }else{
        switch(props.itemAward.auction.sellingMethod){
            case "timber-english":
            case "timber-english-fast":
            case "timber-english-fast-manual":
            case "timber-english-initial-auction":
            case "timber-english-initial-qualification":
            case "timber-english-initial-qualification-fast":
                docTypeOption = <TextField
                            id="outlined-select-currency-native"
                            className={styles.selectWidth}
                            select
                            label="Тип документа"
                            value={props.createDocuments.documentType}
                            onChange={(event)=>{
                                props.changeDocumentType(event.target.value)
                            }}
                            helperText="Выберите тип документа"
                            variant="outlined"
                        >
                            <option key={0} selected disabled value='null'>Выбрать</option>
                            <option key={2} value='commercialProposal'>Заява на участь</option>
                            <option key={3} value='qualificationDocuments'>Документи що підтверджують кваліфікацію</option>
                            <option key={4} value='x_passport'>Копія паспорта або документа, що посвідчує особу</option>
                            <option key={5} value='x_IPN'>Копія ІПН/РНОКПП</option>
                            <option key={6} value='x_tenderersRegisterExtract'>Витяг з ЄДРПОУ</option>
                            <option key={7} value='x_nonResidentRegistrations'>Документ про реєстрацію у державі її місцезнаходження (нерезидент)</option>
                            <option key={8} value='x_nonSanctionedStatement'>Заява про непоширення обмежувальних заходів (санкції)</option>
                            <option key={9} value='eligibilityDocuments'>Документи що підтверджують відповідність</option>
                        </TextField>
                break;
            case "renewables":
            case "renewables-fast":
            case "renewables-fast-manual":
            case "renewables-initial-auction":
            case "renewables-initial-qualification":
            case "renewables-initial-qualification-fast":
                docTypeOption = <TextField
                        id="outlined-select-currency-native"
                        className={styles.selectWidth}
                        select
                        label="Тип документа"
                        value={props.createDocuments.documentType}
                        onChange={(event)=>{
                            props.changeDocumentType(event.target.value)
                        }}
                        helperText="Выберите тип документа"
                        variant="outlined"
                    >
                        <option key={0} selected disabled value='null'>Выбрать</option>
                        <option key={2} value='x_tenderersGuarantee'>Фінансове забезпечення</option>
                        <option key={3} value='x_tenderersLand'>Право на земельну ділянку</option>
                        <option key={4} value='x_tenderersElectricalGrid'>Договір про приєднання об'єкта електроенергетики</option>
                        <option key={5} value='x_tenderersRegisterExtract'>Витяг з ЄДРПОУ</option>
                        <option key={6} value='x_tenderersBeneficialOwner'>Інформація про кінцевого бенефіціарного власника (або його відсутність)</option>
                        <option key={7} value='x_tenderersGoverningBody'>Інформація про органи управління</option>
                        <option key={8} value='x_tenderersRelatedParties'>Інформація про пов'язаних осіб</option>
                        <option key={9} value='x_tenderersGenerationType'>Довідка про вид ВДЕ (для технологічно нейтральних)</option>
                        <option key={10} value='commercialProposal'>Заява на участь</option>
                    </TextField>
                break;
            case "subsoil-english":
            case "subsoil-english-fast":
            case "subsoil-english-fast-manual":
            case "subsoil-english-initial-auction":
            case "subsoil-english-initial-qualification":
                docTypeOption = <TextField
                        id="outlined-select-currency-native"
                        className={styles.selectWidth}
                        select
                        label="Тип документа"
                        value={props.createDocuments.documentType}
                        onChange={(event)=>{
                            props.changeDocumentType(event.target.value)
                        }}
                        helperText="Выберите тип документа"
                        variant="outlined"
                    >
                        <option key={0} selected disabled value='null'>Выбрать</option>
                        <option key={2} value='commercialProposal'>Заява на участь</option>
                        <option key={3} value='qualificationDocuments'>Документи що підтверджують кваліфікацію</option>
                        <option key={4} value='x_passport'>Копія паспорта або документа, що посвідчує особу</option>
                        <option key={5} value='x_IPN'>Копія ІПН/РНОКПП</option>
                        <option key={6} value='x_nonSanctionedStatement'>Заява про непоширення обмежувальних заходів (санкції)</option>
                        <option key={7} value='eligibilityDocuments'>Документи що підтверджують відповідність</option>
                    </TextField>
                break;
            case "railwayCargo-english":
            case "railwayCargo-english-fast":
            case "railwayCargo-english-fast-manual":
            case "railwayCargo-english-initial-auction":
            case "railwayCargo-english-initial-qualification":
            case "railwayCargo-dutch":
            case "railwayCargo-dutch-fast":
            case "railwayCargo-dutch-fast-manual":
            case "railwayCargo-dutch-initial-auction":
            case "railwayCargo-dutch-initial-qualification":
            case "railwayCargo-dutch-fast-auction-manual-qualification":
            case "railwayCargo-dutch-initial-auction-manual":
                docTypeOption = <TextField
                        id="outlined-select-currency-native"
                        className={styles.selectWidth}
                        select
                        label="Тип документа"
                        value={props.createDocuments.documentType}
                        onChange={(event)=>{
                            props.changeDocumentType(event.target.value)
                        }}
                        helperText="Выберите тип документа"
                        variant="outlined"
                    >
                        <option key={0} selected disabled value='null'>Выбрать</option>
                        <option key={2} value='commercialProposal'>Заява на участь</option>
                        <option key={3} value='qualificationDocuments'>Документи що підтверджують кваліфікацію</option>
                        <option key={4} value='eligibilityDocuments'>Документи що підтверджують відповідність</option>
                    </TextField>
                break;
            default:
                break;
        }
    }
    return docTypeOption
}

function viewDocument(props){
    let tmp = null;
    if(props.itemAward.documents !== null){
        if(props.itemAward.documents.length > 0){
            tmp = props.itemAward.documents.map(
                (i, count) => {
                    /*let tmpDel = null;
                    if(props.auctionsMy.createdByCurrentUser === true){
                        tmpDel = <div
                            className={styles.documentInfoBlock_text}
                            onClick={
                                () => {
                                    deleteDocument(props, i.id)
                                }
                            }
                        >Видалити</div>
                    }*/
                    return (
                        <a href={i.url} target='_blank'>
                            <div className={styles.lineWrapAwards}>
                                <div className={styles.lineWrapAwards_text}>
                                    <div className={styles.lineWrapAwards}>
                                        <div className={styles.lineWrapAwards_title}>Документ №:</div><div className={styles.lineWrapAwards_content}>{count+1}</div>
                                    </div>
                                    <div className={styles.lineWrapAwards}>
                                        <div className={styles.lineWrapAwards_title}>Назва:</div><div className={styles.lineWrapAwards_content}>{i.title}</div>
                                    </div>
                                    <div className={styles.lineWrapAwards}>
                                        <div className={styles.lineWrapAwards_title}>Опис:</div><div className={styles.lineWrapAwards_content}>{i.description}</div>
                                    </div>
                                    <div className={styles.lineWrapAwards}>
                                        <div className={styles.lineWrapAwards_title}>Тип документу:</div><div className={styles.lineWrapAwards_content}>{viewDataType(i.documentType)}</div>
                                    </div>
                                    <div className={styles.lineWrapAwards}>
                                        <div className={styles.lineWrapAwards_title}>Дата створення:</div><div className={styles.lineWrapAwards_contentDate}>{modifiDateString(i.datePublished, 3, false)}</div>
                                    </div>
                                    <div className={styles.lineWrapAwards}>
                                        <div className={styles.lineWrapAwards_title}>Дата редагування:</div><div className={styles.lineWrapAwards_contentDate}>{modifiDateString(i.dateModified, 3, false)}</div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    )
                }
            )
        }
    }
    if(tmp === null){
        tmp = <div>Доданих документів не знайдено</div>
    }else if(tmp.length === 0){
        tmp = <div>Доданих документів не знайдено</div>
    }
    return tmp
}

function buttonCreateImg(props){
    if(props.createDocuments.id === null){
        let fileElem = null;
        if(props.createDocuments.fileName === null){
            fileElem = <span className={styles.input__file_button_text}>
                <span>Додати документ</span>
            </span>
        }else{
            fileElem = <span className={styles.input__file_button_text}>
                <span>Доданий документ:</span> <span>{props.createDocuments.fileName}</span>
            </span>
        }
        return (
            <div className={styles.formLine}>
                <form 
                    onSubmit={(e) => {
                        handleSubmit(e, props)
                    }}
                    id="addFileForm"
                >
                    <div className={styles.input__wrapper}>
                        <input
                            name="file"
                            type="file"
                            id="input__file"
                            className={styles.input + ' ' + styles.input__file}
                            onChange={() => {
                                let addFileInp1 = document.getElementById('input__file');
                                props.changeDocumentFileName(addFileInp1.files[0].name)
                            }}
                        />
                        <label for="input__file" className={styles.input__file_button}>
                            {fileElem}
                        </label>
                    </div>
                    <div className={styles.lineButtons}>
                        <div className={styles.editButton}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Зберегти
                            </Button>
                        </div>
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={
                                    () => {
                                        props.changeDocumentId(null)
                                        props.changeDocumentTitle('')
                                        props.changeDocumentDescription('')
                                        props.changeDocumentLang('uk')
                                    }
                                }
                            >
                                Закрити
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }else{
        return (
            <div className={styles.lineButtons}>
                <div className={styles.editButton}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                promisSaveEditDocuments(props)
                            }
                        }
                    >
                        Редагувати
                    </Button>
                </div>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                props.changeDocumentId(null)
                                props.changeDocumentTitle('')
                                props.changeDocumentDescription('')
                                props.changeDocumentLang('uk')
                            }
                        }
                    >
                        Закрити
                    </Button>
                </div>
            </div>
        )
    }
}

async function promisSaveEditDocuments(props){
    let tmpDisc = null
    if(props.createDocuments.description !== null && props.createDocuments.description !== ''){
        tmpDisc = props.createDocuments.description
    }
    const endPoint33 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/${props.createDocuments.id}`;
    const response33 = await fetch(endPoint33, {
        method: "PUT",
        body: JSON.stringify({
            title: props.createDocuments.title,
            description: tmpDisc,
            //documentType: 'illustration',   //не меняем
            language: props.createDocuments.language,
            //documentOf: 'auction',          //не меняем
            //hash: "string",
            //token: json.jwt                 //не меняем
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response33 === undefined || response33 === 'undefined'){
    }else{
        let data = {
            domen: process.env.REACT_APP_END_POINT_BD,
            version: 'v1',
            local: props.langFlag,
            params: '?limit=100'
        }
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        props.auctionsGetMy(data, header)
    }
}

function handleSubmit(e, props){
    e.preventDefault();
    promisUploadFiles(props)
}

async function promisUploadFiles(props){
    const addFileInp = document.getElementById('input__file');
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload`;
    const formData = new FormData();
    formData.append('documentType', props.createDocuments.documentType)
    formData.append('file', addFileInp.files[0])
    const response =  await fetch(endPoint, {
        method: "post",
        body: formData,
        headers: {
            'Authorization': props.token.access_token,
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        let tmpDisc = null
        if(props.createDocuments.description !== null && props.createDocuments.description !== ''){
            tmpDisc = props.createDocuments.description
        }
        const json = await response.json()
        const endPoint1 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/document`;
        const response1 = await fetch(endPoint1, {
            method: "post",
            body: JSON.stringify({
                title: props.createDocuments.title,
                description: tmpDisc,
                documentType: props.createDocuments.documentType,
                language: props.createDocuments.language,              //не меняем
                documentOf: 'auction',          //не меняем
                //hash: "string",
                token: json.jwt                 //не меняем
            }),
            headers: {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
        }).catch(console.error)
        if(response1 === undefined || response1 === 'undefined'){
        }else{
            const json1 = await response1.json()
            const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}/award/${props.itemAward.id}`;
            const response2 = await fetch(endPoint2, {
                method: "put",
                body: JSON.stringify({
                    documentIds: [...takeIdDocuments(props), json1.id]
                }),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response2 === undefined || response2 === 'undefined'){
            }else{
                let data = {
                    domen: process.env.REACT_APP_END_POINT_BD,
                    version: 'v1',
                    local: props.langFlag,
                    params: '?limit=100'
                }
                let header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                props.auctionsGetMy(data, header)
            }
        }
    }
}

async function  deleteDocument(props, id){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/${id}`;
    const response1 = await fetch(endPoint, {
        method: "delete",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
    }else{
        /*let data = {
            domen: process.env.REACT_APP_END_POINT_BD,
            version: 'v1',
            local: props.langFlag,
            params: '?limit=100'
        }
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        props.auctionsGetMy(data, header)*/
    }
}

function takeAuction(props){
    let tmp = null;
    if(props.auctionsMy !== null){
        tmp = props.auctionsMy
        .filter(
            (i) => {
                return i.id === props.createAuction.id
            }
        )
        .filter(
            (i) => {
                return i.items.length > 0
            }
        )
    }
    return tmp
}

function takeIdDocuments(props){
    let tmp = null;
    if(props.itemAward !== null){
        tmp = props.itemAward
    }
    let tmpItems = [];
    if(tmp !== null){
        for(let i = 0; i < tmp.documents.length; i++){
            tmpItems[i] = tmp.documents[i].id
        }
    }
    return tmpItems
}

function createView(props){
    let tmp = null;
    tmp = (
        <div className={styles.documentsForm}>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        required
                        id="filled-required"
                        className={styles.inputText}
                        label="Заголовок"
                        variant="outlined"
                        value={props.createDocuments.title}
                        onChange={
                            (e) => {
                                props.changeDocumentTitle(e.target.value)
                            }
                        }
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        required
                        id="filled-required"
                        className={styles.inputText}
                        label="Описание"
                        variant="outlined"
                        value={props.createDocuments.description}
                        onChange={
                            (e) => {
                                props.changeDocumentDescription(e.target.value)
                            }
                        }
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    {documentOption(props)}
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        id="outlined-select-currency-native"
                        className={styles.selectWidth}
                        select
                        label="Язык документа"
                        value={props.createDocuments.language}
                        onChange={(event)=>{
                            props.changeDocumentLang(event.target.value)
                        }}
                        helperText="Выберите язык документа"
                        variant="outlined"
                    >
                        <option key={0} selected disabled value='null'>Выбрать</option>
                        <option value='uk'>український</option>
                        <option value='en'>англійський</option>
                        <option value='ru'>російський</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                {buttonCreateImg(props)}
            </div>
        </div>
    )
    return tmp
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        auctionsMy: state.start.auctionsMy,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        createDocuments: state.start.createDocuments,
        itemAward: state.start.itemAward,
        userProfiles: state.start.userProfiles
    }
}

const mapDispatchToProps = {
    changeAuctionsSwitchWindow,
    auctionsGetMy,
    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType,
    changeCreateAuctionTab,
    setPopUpAuctionMyNull,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuction)