import React, {useState} from 'react';
import styles from './bankAccounts.module.css';

import {useSelector} from 'react-redux';

import DeleteIcon from '../../../../elements/svg/deleteIcon';
import CancelIcon from '../../../../elements/svg/cancelIcon';

import { checkProp, isMongo } from '../../../../../redux/check';
import {isBSE, isBSD, isCSE, isCSD, isALE,
    isLLE, isLLD, isLLP, isBRE, isBRD, isBRW,
    isRLE, isRLD, isCLE, isCLD, isSPE, isSPD,
    isNLE, isNLD, isLAW, isLAE, isLAP, isLPE, isAPE, isAPD} from '../../../../../redux/aucTypes'

const BankItem = ( { data, bankAccounts, setBankAccounts, group, disabledBtn, allDel, ...props} ) => {
    const auctionsMy = useSelector(state => state.start.auctionsMy)
    const bankNew = (checkProp(data, 'new') && data.new === true)
    const bankDel = (checkProp(data, 'del') && data.del === true)

    let tmpBankName = isMongo(data.bankName)
    let tmpBankDescription = isMongo(data.description)
    let tmpAccount = checkProp(data.accountIdentifications[data.accountIdentifications.length - 1], 'identifier')
    ? data.accountIdentifications[data.accountIdentifications.length - 1].identifier : data.accountIdentifications[data.accountIdentifications.length - 1].id

    function setDelStyle(color){
        if (!bankDel) return {color: color, display: 'flex', flexFlow: 'row', gap: '8px', alignItems: 'center'}
        else return {color: '#BFBFC6', display: 'flex', flexFlow: 'row', gap: '8px', alignItems: 'center'}
    }

    function setItemStyle(){
        if (bankNew) return `${styles.bankWrap} ${styles.bankNew}`
        if (bankDel) return `${styles.bankWrap} ${styles.bankDelete}`
        return styles.bankWrap
    }

    function elementDisabledCreate(){
        if(auctionsMy.status !== null){
            if(auctionsMy.status !== 'active_rectification'){
                let tmpStartData = null
                let tmpEndData = null
                if(auctionsMy.rectificationPeriod !== null){
                    tmpStartData = new Date(auctionsMy.rectificationPeriod.startDate).getTime()
                    tmpEndData = new Date(auctionsMy.rectificationPeriod.endDate).getTime()
                }
                if(
                    (
                        isBSE(auctionsMy.sellingMethod) || isBSD(auctionsMy.sellingMethod) ||
                        isALE(auctionsMy.sellingMethod) ||
                        isCSE(auctionsMy.sellingMethod) || isCSD(auctionsMy.sellingMethod) ||
                        isRLE(auctionsMy.sellingMethod) || isRLD(auctionsMy.sellingMethod) ||
                        isCLE(auctionsMy.sellingMethod) || isCLD(auctionsMy.sellingMethod) ||
                        isNLE(auctionsMy.sellingMethod) || isNLD(auctionsMy.sellingMethod) ||
                        isAPE(auctionsMy.sellingMethod) || isAPD(auctionsMy.sellingMethod)
                        || isLLE(auctionsMy.sellingMethod) || isLLD(auctionsMy.sellingMethod) || isLLP(auctionsMy.sellingMethod)
                        ) &&
                    (tmpStartData < Date.now() && tmpEndData > Date.now())
                ){
                    return null
                }else if(isBRE(auctionsMy.sellingMethod) || isBRD(auctionsMy.sellingMethod) || isBRW(auctionsMy.sellingMethod)){
                    if(
                        auctionsMy.status === 'complete' ||
                        auctionsMy.status === 'cancelled' ||
                        auctionsMy.status === 'unsuccessful'
                    ){
                        return 'disabled'
                    }else if(
                        auctionsMy.status === 'active_auction' ||
                        auctionsMy.status === 'pending_payment' ||
                        auctionsMy.status === 'active_qualification'
                    ){
                        return null
                    }else if(auctionsMy.status === 'active_awarded'){
                        if(auctionsMy.contracts !== null && auctionsMy.contracts.length > 0){
                            let tmp = auctionsMy.contracts.filter(
                                (e) => {
                                    return (e.status === 'pending')
                                }
                            )
                            
                            if(tmp.length > 0) return 'disabled'
                        }
                    }
                    return null
                }else if(isSPE(auctionsMy.sellingMethod) || isSPD(auctionsMy.sellingMethod)){
                    return null
                }else if(isLAW(auctionsMy.sellingMethod)){
                    return null
                }else if(isLPE(auctionsMy.sellingMethod)){
                    return null
                }else{
                    return 'disabled'
                }
            }
        }
        return null
    }

    function holderType(){
        if(isLLE(auctionsMy.sellingMethod) || isLLD(auctionsMy.sellingMethod) || isLLP(auctionsMy.sellingMethod)){
            return "1"
        }
        return null
    }

    return (
        <div 
            className={setItemStyle()}
            key={data.id}
            disabled={elementDisabledCreate()}
        >
            {bankNew ? <div className={styles.bankTmpGroup}>Банк буде додано після збереження змін</div> : null }
            <div className={styles.banksNameLine}>
                <div style={setDelStyle('#696974')}>{tmpBankName}</div>
                <div style={setDelStyle('#92929D')}>
                    {data.currency} 
                    <div style={{fontSize: '13px'}}>{tmpAccount}</div>
                </div>
                <div style={setDelStyle('#92929D')}>{tmpBankDescription}</div>
            </div>
            { !allDel ?
                <button className={styles.deleteBtn}
                    disabled={elementDisabledCreate()}
                    title={ bankDel ? 'Відмінити' : 'Видалити' }
                    variant="contained"
                    onClick={
                        () => {
                            const tmpBanks  = JSON.parse(JSON.stringify(bankAccounts))
                                if( !bankDel ){
                                    if(bankNew){
                                        for (let key in tmpBanks){
                                            if (group === tmpBanks[key].accountType){
                                                const index = tmpBanks[key].accounts.findIndex( i => i.id === data.id)
                                                tmpBanks[key].accounts.splice(index, 1)

                                                setBankAccounts(tmpBanks)
                                            }
                                        }
                                    }
                                    if(!bankNew){
                                        for (let key in tmpBanks){
                                            if (group === tmpBanks[key].accountType){
                                                tmpBanks[key].accounts.forEach( i => {
                                                    if(i.id === data.id){
                                                        i.del = true
                                                    }
                                                })

                                                setBankAccounts(tmpBanks)
                                            }
                                        }
                                    }
                                }
                                else if( bankDel ){
                                    for (let key in tmpBanks){
                                        if (group === tmpBanks[key].accountType){
                                            tmpBanks[key].accounts.forEach( i => {
                                                if(i.id === data.id){
                                                    delete i.del
                                                }
                                            })

                                            setBankAccounts(tmpBanks)
                                        }
                                    }
                                }
                        }
                    }
                >
                    { !bankDel ? <DeleteIcon /> : <CancelIcon /> }
                </button> : <div style={{display: 'flex', width: '33px', height: '30px', marginLeft: '30px'}}></div>
            }
        </div>
    )


}

export default BankItem