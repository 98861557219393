import React from 'react';
import styles from './informationAboutTheOrganizer.module.css';

import {connect} from 'react-redux';

import { isBRD, isNLD } from '../../../../redux/aucTypes';

import TextFull from '../../../elements/viewData/textFull'
import TextHalf from '../../../elements/viewData/textHalf'

import {viewStringValue} from '../../../../api/valueToString.js'

const DutchStepString = (props) => {
    if(props.auctionId === null) return null
    if(props.auctionId.hasOwnProperty('dutchStep') === true){
        if(props.auctionId.dutchStep !== null){
            return (
                <div className={styles.infoBlock}>
                    {isBRD(props.auctionId.sellingMethod) ? null : <h4 style={{width:"100%"}}>Розмір кроку аукціону</h4>}
                    {props.auctionId.dutchStep.dutchStepQuantity !== null ? <TextHalf
                        title="Кількість кроків"
                        data={props.auctionId.dutchStep.dutchStepQuantity}
                    /> : null}
                    {props.auctionId.dutchStep.dutchStepPercent !== null ? <TextHalf
                        title="Відсоток зниження, %"
                        data={props.auctionId.dutchStep.dutchStepPercent}
                    /> : null}
                    {viewValie(props.auctionId.dutchStep, props)}
                </div>
            )
        }
    }
    return null
}

function viewValie(data, props){
    if(data.dutchStepValue === null) return null
    let text = "Сума"
    if(isBRD(props.auctionId.sellingMethod) || isNLD(props.auctionId.sellingMethod)) text = "Розмір кроку голландського раунду, грн"
    let tmp = viewStringValue(props.auctionId.dutchStep.dutchStepValue)
    return (
        <TextFull
            title={text}
            data={tmp}
        />
    )
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(DutchStepString)