import {addProperty, addPropertyMonga, addPropertyValue, addPropertyDiscount, addAuctionPeriodProperty, addBankProperty, addDebtorInformation, addSellingEntity, addDutchStep} from './utilsCreateBody'

export function сreateBodyBRD(data, oldData=null){
    if(data !== null){
        let body = {}
        if(oldData === null){
            body = addPropertyMonga(data, body, 'title')
            body = addPropertyMonga(data, body, 'description')
            if(data.base.accessDetails !== null){
                body = addPropertyMonga(data, body, 'accessDetails')
            }else{
                body.accessDetails = null
            }
            body = addProperty(data, body, 'tenderAttempts')
            body = addProperty(data, body, 'lotId')
            body = addAuctionPeriodProperty(data, body, 'auctionPeriod')
            body = addProperty(data, body, 'sellingMethod')
            if(data.base.x_documentRequirements !== null){
                body = addPropertyMonga(data, body, 'x_documentRequirements')
            }else{
                body.x_documentRequirements = null
            }
            if(data.base.x_additionalInformation !== null){
                body = addPropertyMonga(data, body, 'x_additionalInformation')
            }else{
                body.x_additionalInformation = null
            }
            body = addProperty(data, body, 'valueAddedTaxCharged')
            
            body = addPropertyValue(data, body, 'value')
            body = addPropertyValue(data, body, 'guarantee')
            
            body = addProperty(data, body, 'previousAuctionId')
            body = addPropertyDiscount(data, body, 'discount')
            body = addBankProperty(data, body, 'banks')
            
            if(data.base.restrictions !== null){
                body = addPropertyMonga(data, body, 'restrictions')
            }else{
                body.restrictions = null
            }
            body = addProperty(data, body, 'isTaxInvoiceProvided')
            body = addPropertyMonga(data, body, 'economicCourtName')
            body = addPropertyMonga(data, body, 'bankruptcyCaseNumber')
            body = addPropertyMonga(data, body, 'sellingMethodType')
            body = addDebtorInformation(data, body, 'debtorInformation')

            body = addSellingEntity(data, body, 'sellingEntity')
            body = addDutchStep(data, body, 'dutchStep')
        }else{
            if(
                data.base['title'] !== null
                && data.base['title'].uk_UA !== null
            ){
                body = addPropertyMonga(data, body, 'title')
            }
            if(
                data.base['description'] !== null
                && data.base['description'].uk_UA !== null
            ){
                body = addPropertyMonga(data, body, 'description')
            }
            if(data.base.accessDetails !== null){
                if(data.base['accessDetails'].uk_UA !== null) body = addPropertyMonga(data, body, 'accessDetails')
            }else{
                body.accessDetails = null
            }
            
            
            body = addProperty(data, body, 'tenderAttempts')
            if(Number(data.base['tenderAttempts']) === 1){
                if(data.base['previousAuctionId'] !== null) body.previousAuctionId = null
                if(data.base['discount'] !== null) body.discount = null
            }else{

                if(data.base['previousAuctionId'] !== null) body = addProperty(data, body, 'previousAuctionId')
                if(data.base['discount'] !== null && data.base['discount'].discount !== null && data.base['discount'].discount !== false) body = addPropertyDiscount(data, body, 'discount')
            }

            body = addProperty(data, body, 'lotId')
            if(data.base['auctionPeriod'] !== null && data.base['auctionPeriod'].startDate !== null) body = addAuctionPeriodProperty(data, body, 'auctionPeriod')
            if(data.base['sellingMethod']) body = addProperty(data, body, 'sellingMethod')
            
            
            if(data.base.x_documentRequirements !== null){
                if(
                    data.base['x_documentRequirements'] !== null
                    && data.base['x_documentRequirements'].uk_UA !== null
                ){
                    body = addPropertyMonga(data, body, 'x_documentRequirements')
                }
            }else{
                body.x_documentRequirements = null
            }
            if(data.base.x_additionalInformation !== null){
                if(
                    data.base['x_additionalInformation'] !== null
                    && data.base['x_additionalInformation'].uk_UA !== null
                ){
                    body = addPropertyMonga(data, body, 'x_additionalInformation')
                }
            }else{
                body.x_additionalInformation = null
            }

            
            body = addProperty(data, body, 'valueAddedTaxCharged')
            
            if(
                //data.base['value'].currency !== oldData['value'].currency ||
                data.base['value'].currency !== '' &&
                data.base['value'].currency !== null &&
                data.base['value'].currency !== 'null'
            ) body = addPropertyValue(data, body, 'value')
            // if(
            //     data.base['minimalStep'].currency === '' ||
            //     data.base['minimalStep'].currency === null ||
            //     data.base['minimalStep'].currency === 'null'
            // ) body = addPropertyValue(data, body, 'minimalStep')
            if(
                data.base['guarantee'].currency !== '' &&
                data.base['guarantee'].currency !== null &&
                data.base['guarantee'].currency !== 'null'
            ) body = addPropertyValue(data, body, 'guarantee')
            
            if(data.base['bankAccounts'] !== null && data.base['bankAccounts'].length > 0) body = addBankProperty(data, body, 'banks')

            /*if(data.base['value'].amount !== oldData['value'].amount || data.base['value'].valueAddedTaxIncluded !== oldData['value'].valueAddedTaxIncluded) body = addPropertyValue(data, body, 'value')
            if(data.base['minimalStep'].amount !== oldData['minimalStep'].amount) body = addPropertyValue(data, body, 'minimalStep')
            if(data.base['guarantee'].amount !== oldData['guarantee'].amount) body = addPropertyValue(data, body, 'guarantee')*/
            
            
            
            if(data.base.restrictions !== null){
                if(data.base['restrictions'].uk_UA !== null) body = addPropertyMonga(data, body, 'restrictions')
            }else{
                body.restrictions = null
            }
            body = addProperty(data, body, 'isTaxInvoiceProvided')
            if(
                data.base['economicCourtName'] !== null
                && data.base['economicCourtName'].uk_UA !== null
            ) body = addPropertyMonga(data, body, 'economicCourtName')
            if(data.base['bankruptcyCaseNumber'] !== null && data.base['bankruptcyCaseNumber'].uk_UA !== null) body = addPropertyMonga(data, body, 'bankruptcyCaseNumber')
            if(data.base['sellingMethodType'].uk_UA !== null) body = addPropertyMonga(data, body, 'sellingMethodType')
            if(
                data.base['debtorInformation'].identifier.scheme !== null
                || data.base['debtorInformation'].identifier.legalName.uk_UA !== null
                || data.base['debtorInformation'].identifier.id !== null
                || data.base['debtorInformation'].address.countryName.uk_UA !== null
                || data.base['debtorInformation'].address.region.uk_UA !== null
                || data.base['debtorInformation'].address.locality.uk_UA !== null
                || data.base['debtorInformation'].address.streetAddress.uk_UA !== null
                || data.base['debtorInformation'].address.postalCode !== null
                || data.base['debtorInformation'].contactPoint.name.uk_UA !== null
                || data.base['debtorInformation'].contactPoint.email !== null
                || data.base['debtorInformation'].contactPoint.telephone !== null
                || data.base['debtorInformation'].contactPoint.faxNumber !== null
                || data.base['debtorInformation'].contactPoint.url !== null
            ) body = addDebtorInformation(data, body, 'debtorInformation')

            body = addSellingEntity(data, body, 'sellingEntity')

            if(data.base['dutchStep'] !== null){
                body = addDutchStep(data, body, 'dutchStep')
            }
        }
        return body
    }
    return null
}

function checkDelBank(data){
    for(let i = 0; i < data.length; i++){
        if(data[i].hasOwnProperty('del')) return true
    }
    return false
}