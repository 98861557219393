import React from 'react';
import styles from './navTitleBar.module.css';

import {connect} from 'react-redux';
import Button from '../../../../../elements/buttons/button';
import Info from '../../../../../elements/viewData/instructionBlock/instructionBlock'

import {setDataAddress} from '../../../../../../redux/createAddress';
import { isLLE, isLLD, isLLP,
    isBSE, isBSD,
    isALE,
    isCSE, isCSD,
    isBRE, isBRD, isBRW,
    isRLE, isRLD,
    isCLE, isCLD,
    isSPE, isSPD,
    isNLE, isNLD,
    isLAE, isLAW, isLAP,
    isLPE,
    isAPE, isAPD,
    isLRE, isLSE, isLSP
} from '../../../../../../redux/aucTypes';


const NavTitleBar = (props) => {
    if(isAPE(props.auctionsMy.sellingMethod) || isAPD(props.auctionsMy.sellingMethod)){
        let tmpEnd = null
        if(props.auctionsMy.hasOwnProperty("rectificationPeriod")
            && props.auctionsMy.rectificationPeriod !== null
            && props.auctionsMy.rectificationPeriod.endDate !== null
        ) tmpEnd = new Date(props.auctionsMy.rectificationPeriod.endDate)
        let tmpEnd1 = null
        if(tmpEnd !== null) tmpEnd1 = tmpEnd.getTime()
        if(tmpEnd === null || tmpEnd1 > Date.now()){
            return onTab(props)
        }else{
            return offTab(props)
        }
    }
    return onTab(props)
}

function onTab(props){
    let tmpOgol = null
    let tmpZaput = null
    let tmpRezul = null
    if(props.createAuctionTab === 1){
        tmpOgol = styles.objNavItem
        tmpZaput = styles.activeNavItem
        tmpRezul = styles.objNavItem
    }else if(props.createAuctionTab === 2){
        tmpOgol = styles.objNavItem
        tmpZaput = styles.objNavItem
        tmpRezul = styles.activeNavItem
    }else{
        tmpOgol = styles.activeNavItem
        tmpZaput = styles.objNavItem
        tmpRezul = styles.objNavItem
    }

    let tmpInfo = null
    if(isLLE(props.auctionsMy.sellingMethod) || isLLD(props.auctionsMy.sellingMethod) || isLLP(props.auctionsMy.sellingMethod)){
        tmpInfo = <Info
            title='Важлива інформація'
            content="Увага! Основна інформація в аукціон переноситься з об’єкту переліку. За потреби спочатку внесіть необхідні зміни до об’єкту у переліку і вже після цього переходити до етапу публікації аукціону."
        />
    }
    return (
        <div className={styles.navBlock}>
            <div className={styles.objNav}>
                {checkEditDoc(props)
                    ? <div className={tmpOgol} onClick = {() => {props.setDataAddress(0, 'CREATE_AUCTION_TAB')}}>
                        Аукціон
                    </div>
                    : <div className={tmpOgol + ' ' + styles.off}>
                        Аукціон
                    </div>
                }
                {(props.auctionsMy.id !== null && props.auctionsMy.id !== '') && checkEditDoc(props)
                    ? <div className={tmpZaput} onClick = { () => {if(props.auctionsMy.id !== null && props.auctionsMy.id !== '') props.setDataAddress(1, 'CREATE_AUCTION_TAB')}}>
                        Склад лоту
                    </div> 
                    : <div className={tmpZaput + ' ' + styles.off}>
                        Склад лоту
                    </div>
                }
                {(props.auctionsMy.id !== null && props.auctionsMy.id !== '')
                    ? <div className={tmpRezul} onClick = {() => {if(props.auctionsMy.id !== null && props.auctionsMy.id !== '') props.setDataAddress(2, 'CREATE_AUCTION_TAB')}}>
                        Всі документи та пов'язані додатки
                    </div>
                    : <div className={tmpRezul + ' ' + styles.off}>
                        Всі документи та пов'язані додатки
                    </div>
                }
            </div>
            <div className={styles.infoBlock}>
                Увага! Всі внесені зміни будуть збережені тільки після натискання кнопки "Зберегти зміни"
            </div>
            {tmpInfo}
        </div>
    )
}
function offTab(props){
    let tmpOgol = null
    let tmpZaput = null
    let tmpRezul = null
    if(props.createAuctionTab === 1){
        tmpOgol = styles.objNavItem
        tmpZaput = styles.activeNavItem
        tmpRezul = styles.objNavItem
    }else if(props.createAuctionTab === 2){
        tmpOgol = styles.objNavItem
        tmpZaput = styles.objNavItem
        tmpRezul = styles.activeNavItem
    }else{
        tmpOgol = styles.activeNavItem
        tmpZaput = styles.objNavItem
        tmpRezul = styles.objNavItem
    }
    return (
        <div className={styles.navBlock}>
            <div className={styles.objNav}>
                <div className={tmpOgol + ' ' + styles.off}>
                    Аукціон
                </div>
                <div className={tmpZaput + ' ' + styles.off}>
                    Склад лоту
                </div>
                <div className={tmpRezul + ' ' + styles.off}>
                    Всі документи та пов'язані додатки
                </div>
            </div>
        </div>
    )
}

function clarifications(props){
    if(props.auctionsMy !== null && props.auctionsMy.documents !== null && props.auctionsMy.documents.length > 0){
        for(let i = 0; i < props.auctionsMy.documents.length; i++){
            if(props.auctionsMy.documents[i].documentType ==='clarifications') return true
        }
    }
    return false
}
function checkEditDoc(props){
    if(props.auctionsMy === null && props.auctionsMy.status === null) return false
    //if((isLAE(props.auctionsMy.sellingMethod) || isLAW(props.auctionsMy.sellingMethod) || isLAP(props.auctionsMy.sellingMethod)) && props.auctionsMy.status === 'active_tendering') return false
    if((isLAE(props.auctionsMy.sellingMethod) || isLAW(props.auctionsMy.sellingMethod)) && props.auctionsMy.status === 'active_tendering') return false
    if((isLLE(props.auctionsMy.sellingMethod) || isLLD(props.auctionsMy.sellingMethod) || isLLP(props.auctionsMy.sellingMethod)) && props.auctionsMy.status === 'active_tendering') return false
    if(isLRE(props.auctionsMy.sellingMethod) || isLSE(props.auctionsMy.sellingMethod)){
        if(props.auctionsMy.status === null) return true
        if(props.auctionsMy.status === 'active_rectification' && clarifications(props)) return true
        return false
    }
    if(isLSP(props.auctionsMy.sellingMethod)){
        if(props.auctionsMy.status === null) return true
        if(props.auctionsMy.status === 'active_rectification' && clarifications(props)) return true
        if(props.auctionsMy.status === 'active_tendering') return true
        return false
    }
    if(
        props.auctionsMy.status === null
        || props.auctionsMy.status === 'active_rectification'
        || props.auctionsMy.status === 'active_tendering'
        || props.auctionsMy.status === 'active_auction'
        || props.auctionsMy.status === 'active_qualification'
        || props.auctionsMy.status === 'pending_payment'
        || props.auctionsMy.status === 'active_awarded'
    ) return true
    if(props.auctionsMy !== null && props.auctionsMy.documents !== null && props.auctionsMy.documents.length > 0){
        for(let i = 0; i < props.auctionsMy.documents.length; i++){
            if(props.auctionsMy.documents[i].documentType ==='clarifications') return true
        }
    }
    return false
}

const mapStateToProps = state => {
    return {
        createAuctionTab: state.start.createAuctionTab,
        auctionsMyNew: state.start.auctionsMyNew,
    }
}

const mapDispatchToProps = {
    setDataAddress
}

export default connect(mapStateToProps, mapDispatchToProps)(NavTitleBar)