import React from 'react';
import styles from './informationAboutTheOrganizer.module.css';

import {connect} from 'react-redux';

import TextHalf from '../../elements/viewData/textHalf'

import ContactPoints from '../../contactPoint/contactPoint'

const PropertyOwner = (props) => {
    if(props.auctionId !== null){
        if(
            props.auctionId.sellingMethod === "legitimatePropertyLease-english" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-fast" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
        ){
            return (
                <div className={styles.infoBlock}>
                    <h3>Інформація про Балансоутримувача</h3>
                    <div className={styles.orgTitle}>
                        <p className={styles.desc}>Найменування балансоутримувача</p>
                        <p>{contactName(props)}</p>
                    </div>
                    <div className={styles.orgContact1}>
                        <p className={styles.desc}>Контактні дані</p>
                        {contactPoint(props)}
                    </div>
                    <div className={styles.orgId}>
                        <p className={styles.desc}>Код ЄДРПОУ або ІПН або паспорт</p>
                        <p>{contactEDRPOU(props)}</p>
                    </div>
                    <div className={styles.orgAdress}>
                        <p className={styles.desc}>Юридична адреса</p>
                        <p>{contactAddress(props)}</p>
                    </div>
                    <div className={styles.orgAdress}>
                        <p className={styles.desc}>КОАТУУ</p>
                        <p>{contactKOATUU(props)}</p>
                    </div>
                </div>
            )
        }
    }
    return null
}

function contactKOATUU(props){
    let tmp = ''
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('relatedOrganizations')){
            if(props.auctionId.relatedOrganizations !== null){
                if(props.auctionId.relatedOrganizations.hasOwnProperty('propertyOwner')){
                    if(props.auctionId.relatedOrganizations.propertyOwner !== null){
                        if(props.auctionId.relatedOrganizations.propertyOwner.hasOwnProperty('address')){
                            if(props.auctionId.relatedOrganizations.propertyOwner.address !== null){
                                if(props.auctionId.relatedOrganizations.propertyOwner.address.hasOwnProperty('addressID')){
                                    if(props.auctionId.relatedOrganizations.propertyOwner.address.addressID !== null){
                                        if(props.auctionId.relatedOrganizations.propertyOwner.address.addressID.hasOwnProperty('id')){
                                            if(props.auctionId.relatedOrganizations.propertyOwner.address.addressID.id !== null){
                                                tmp = props.auctionId.relatedOrganizations.propertyOwner.address.addressID.id
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return tmp
}

function contactName(props){
    let tmp = ''
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('relatedOrganizations')){
            if(props.auctionId.relatedOrganizations !== null){
                if(props.auctionId.relatedOrganizations.hasOwnProperty('propertyOwner')){
                    if(props.auctionId.relatedOrganizations.propertyOwner !== null){
                        if(props.auctionId.relatedOrganizations.propertyOwner.hasOwnProperty('name')){
                            if(props.auctionId.relatedOrganizations.propertyOwner.name !== null){
                                tmp += props.auctionId.relatedOrganizations.propertyOwner.name.uk_UA
                            }
                        }
                    }
                }
            }
        }
    }
    return tmp
}


function contactPoint(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('relatedOrganizations')){
            if(props.auctionId.relatedOrganizations !== null){
                if(props.auctionId.relatedOrganizations.hasOwnProperty('propertyOwner')){
                    if(props.auctionId.relatedOrganizations.propertyOwner !== null){
                        if(props.auctionId.relatedOrganizations.propertyOwner.hasOwnProperty('contactPoint')){
                            if(props.auctionId.relatedOrganizations.propertyOwner.contactPoint !== null){
                                return contactPoints(props.auctionId.relatedOrganizations.propertyOwner.contactPoint)
                                // return (
                                //     <div className={styles.contactPoint}>
                                //         <TextHalf
                                //             title='ПІБ'
                                //             data={contactPointName(props.auctionId.relatedOrganizations.propertyOwner.contactPoint)}
                                //         />
                                //         <TextHalf
                                //             title='Адреса електронної пошти'
                                //             data={contactPointEmail(props.auctionId.relatedOrganizations.propertyOwner.contactPoint)}
                                //         />
                                //         <TextHalf
                                //             title='Номер телефону'
                                //             data={contactPointTelephone(props.auctionId.relatedOrganizations.propertyOwner.contactPoint)}
                                //         />
                                //         <TextHalf
                                //             title='Номер факсу'
                                //             data={contactPointFaxNumber(props.auctionId.relatedOrganizations.propertyOwner.contactPoint)}
                                //         />
                                //         <TextHalf
                                //             title='Веб адреса'
                                //             data={contactPointUrl(props.auctionId.relatedOrganizations.propertyOwner.contactPoint)}
                                //         />
                                //     </div>
                                // )
                            }
                        }
                    }
                }
            }
        }
    }
    return null
}

function contactPoints(e){
    if(e !== null){
        return <ContactPoints data={e} />
    }
    return null
}

function contactPointName(data){
    if(data.hasOwnProperty('name')){
        if(data.name !== null){
            return data.name.uk_UA
        }
    }
    return ''
}
function contactPointEmail(data){
    if(data.hasOwnProperty('email')){
        if(data.email !== null){
            return data.email
        }
    }
    return ''
}
function contactPointTelephone(data){
    if(data.hasOwnProperty('telephone')){
        if(data.telephone !== null){
            return data.telephone
        }
    }
    return ''
}
function contactPointFaxNumber(data){
    if(data.hasOwnProperty('faxNumber')){
        if(data.faxNumber !== null){
            return data.faxNumber
        }
    }
    return ''
}
function contactPointUrl(data){
    if(data.hasOwnProperty('url')){
        if(data.url !== null){
            return data.url
        }
    }
    return ''
}


function contactEDRPOU(props){
    let tmp = ''
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('relatedOrganizations')){
            if(props.auctionId.relatedOrganizations !== null){
                if(props.auctionId.relatedOrganizations.hasOwnProperty('propertyOwner')){
                    if(props.auctionId.relatedOrganizations.propertyOwner !== null){
                        if(props.auctionId.relatedOrganizations.propertyOwner.hasOwnProperty('identifier')){
                            if(props.auctionId.relatedOrganizations.propertyOwner.identifier !== null){
                                if(props.auctionId.relatedOrganizations.propertyOwner.hasOwnProperty('identifier')){
                                    if(props.auctionId.relatedOrganizations.propertyOwner.identifier.id !== null){
                                        tmp += props.auctionId.relatedOrganizations.propertyOwner.identifier.id
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return tmp
}

function contactAddress(props){
    let tmp = ''
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('relatedOrganizations')){
            if(props.auctionId.relatedOrganizations !== null){
                if(props.auctionId.relatedOrganizations.hasOwnProperty('propertyOwner')){
                    if(props.auctionId.relatedOrganizations.propertyOwner !== null){
                        if(props.auctionId.relatedOrganizations.propertyOwner.hasOwnProperty('address')){
                            if(props.auctionId.relatedOrganizations.propertyOwner.address !== null){
                                if(props.auctionId.relatedOrganizations.propertyOwner.address.hasOwnProperty('postalCode')){
                                    if(props.auctionId.relatedOrganizations.propertyOwner.address.postalCode !== null){
                                        tmp += props.auctionId.relatedOrganizations.propertyOwner.address.postalCode + ', '
                                    }
                                }
                                if(props.auctionId.relatedOrganizations.propertyOwner.address.hasOwnProperty('countryName')){
                                    if(props.auctionId.relatedOrganizations.propertyOwner.address.countryName !== null){
                                        tmp += props.auctionId.relatedOrganizations.propertyOwner.address.countryName.uk_UA + ', '
                                    }
                                }
                                if(props.auctionId.relatedOrganizations.propertyOwner.address.hasOwnProperty('region')){
                                    if(props.auctionId.relatedOrganizations.propertyOwner.address.region !== null){
                                        tmp += props.auctionId.relatedOrganizations.propertyOwner.address.region.uk_UA + ', '
                                    }
                                }
                                if(props.auctionId.relatedOrganizations.propertyOwner.address.hasOwnProperty('locality')){
                                    if(props.auctionId.relatedOrganizations.propertyOwner.address.locality !== null){
                                        tmp += props.auctionId.relatedOrganizations.propertyOwner.address.locality.uk_UA + ', '
                                    }
                                }
                                if(props.auctionId.relatedOrganizations.propertyOwner.address.hasOwnProperty('streetAddress')){
                                    if(props.auctionId.relatedOrganizations.propertyOwner.address.streetAddress !== null){
                                        tmp += props.auctionId.relatedOrganizations.propertyOwner.address.streetAddress.uk_UA
                                    }
                                }                                    
                            }
                        }
                    }
                }
            }
        }
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
        userProfiles: state.start.userProfiles
    }
}

export default connect(mapStateToProps, null)(PropertyOwner)
