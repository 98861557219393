import React from 'react';
import style from './answerAuction.module.css';

import {connect} from 'react-redux';

import {setPopUpAnswerAuction, auctionsGetMy, auctionGetId, auctionGetIdToken} from '../../../../redux/actions/auctions/auctions.js';
import {answerDiscription} from '../../../../redux/actions/questions/questions.js';
import {setDataAddress} from '../../../../redux/createAddress';

const AnswerAuction = ( props ) => {
    return (
        <div className={style.popup_wrap}>
            <div className={style.popup_message}>
                <div className={style.newQuestionBlock}>
                    <h3 className={style.title}>Відповідь</h3>
                        <textarea 
                            className={style.textArea}
                            placeholder='Зміст запитання'
                            onChange={(e) => {
                                props.answerDiscription(e.target.value)
                            }}
                        >
                        </textarea>
                        <div className={style.btnBlock}>
                            <div
                                className={style.blueBtn}
                                onClick = {
                                    () => {
                                        createAnswer(props, props.questionCreate.popUp)
                                        props.answerDiscription(null)
                                        props.setPopUpAnswerAuction(null)
                                    }
                                }
                            >
                                Надіслати
                            </div>
                            <div
                                className={style.blueBtn}
                                onClick = {
                                    () => {
                                        props.setPopUpAnswerAuction(null)
                                    }
                                }
                            >
                                Закрити
                            </div>
                        </div>
                </div>
            </div>
        </div>
    )
}

async function  createAnswer(props, id){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctions.id}/question/${id}/answer`;
    const response1 = await fetch(endPoint, {
        method: "PATCH",
        body: JSON.stringify({
            answer: props.questionCreate.description
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
    }else{
        await props.answerDiscription('')
        await response1.json()
        getPublicApiAuction(props)
    }
}

async function getPublicApiAuction(props){
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/auction/${props.auctions.id}`
    let header = null
    if(props.token !== null){
        header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }else{
        header = {
            'Content-Type': 'application/json'
        }
    }
    const response = await fetch(url, {
        method: "GET",
        headers: header
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        //props.setDataAddress(null, 'SET_FULL_AUCTION')
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){

        }else{
            props.setDataAddress(json, 'SET_FULL_AUCTION')
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        //auctions: state.start.auctionsGet,
        auctions: state.start.auctionFull,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy,
        auctionAwards: state.start.auctionAwards,
        popUpAwardReject: state.start.popUpAwardReject,
        popUpAwardError: state.start.popUpAwardError,
        questionCreate: state.start.questionCreate,
        pageNumber: state.start.pageNumber,
    }
}

const mapDispatchToProps = {
    setPopUpAnswerAuction,
    answerDiscription,
    auctionsGetMy,
    auctionGetId,
    auctionGetIdToken,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(AnswerAuction)