import React from 'react';
import styles from './createProcedures.module.css';

import {connect} from 'react-redux';

import CreateAuction from './create/createAuction'
import EditAuction from './edit/editAuction'
import CloneAuction from './clone/cloneAuction'

const NewDiz = ( props ) => {
    if(props.auctionsMy !== null){
        if(props.auctionsSwitchWindow !== null){
            return nesDiz(props)
        }
    }
    return null
}

function nesDiz(props){
    return (
        <div className={styles.wrapProcedure}>
            <div></div>
            <div className={styles.procedureMainBlock}>
                <div className={styles.procedureLeftBlock}>1</div>
                <div className={styles.procedureRightBlock}>2</div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        auctionsMy: state.start.auctionsMy,
    }
}

export default connect(mapStateToProps, null)(NewDiz)