import React, {useState} from 'react';
import styles from './participant.module.css';

import {connect} from 'react-redux';

import {setDataAddress} from '../../../redux/createAddress';
import PopDownBlock from '../../elements/viewData/popdownBlock/popDownBlock';
import DocItem from '../../documents/docItem';
import TextHalf from '../../elements/viewData/textHalf';
import TextFull from '../../elements/viewData/textFull';
import { isLLD, isSPE, isNLE, isNLD, isLAP, isLPE, isAPE, isAPD } from '../../../redux/aucTypes';
import { checkProp } from '../../../redux/check';
import {startCostAuction} from '../util';
import {viewStringValue} from '../../../api/valueToString.js'

const Participant = (props) => {
    const [dropDomn, setdropDomn] = useState(false)
    
    let tmpValue = null
    let tmpValueSneeck = null
    if(
        !isLLD(props.auctionId.sellingMethod) 
    ){
        tmpValue = <TextHalf title='Цінова пропозиція' data={proposUser(props.e)} />
        tmpValueSneeck = props.e.hasOwnProperty('initialValueAmount')
        ? props.e.initialValueAmount !== null
            ? <TextHalf title='Закрита цінова пропозиція' data={proposUserSneeck(props.e)} />
            : null
        : null
    }
    return (
        <div className={styles.infoBlock}>
            <div className={styles.title_wrap}>
                <h3></h3>
                <div
                    className={dropDomn ? styles.title_button + ' ' + styles.active : styles.title_button}
                    onClick={() => { setdropDomn(!dropDomn) }}
                >Заява на участь</div>
            </div>
            <TextHalf title='Назва учасника' data={nameUser(props.e)} />
            <TextHalf title='Статус кваліфікації учасника аукціону' data={props.type ? statusUser(props, props.e) : statusUserAll(props, props.e)} />
            {tmpValue}
            {tmpValueSneeck}
            <div className={styles.dropContent}>
                {dropDomn ? <div className={styles.dropContent}>
                            {identifier(props.e)}
                            {contactPoint(props.e)}
                            {documentsUser(props, props.e)}
                        </div> : null
                }
            </div>
        </div>
    )
}

function dataECPMapper(data, t) {
    let tmp = data.documents
        .filter(
        (item) => {
            return item.documentType === 'digitalSignature' && item.relatedDocument === t.id
        })
    if (tmp.length === 0) return null
    return tmp
}

function dataECPMapperForCount(data) {
    let tmp = data.documents
        .filter(
        (item) => {
            return item.documentType !== 'digitalSignature'
        })
    return tmp.length
}

function documentsUser(props, i) {
    const docScopeData = {
        aucType: props.auctionId.sellingMethod,
        historyUrl: `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auctionId.id}/bids/${i.id}/documents`,
        aucId: props.auctionId.id,
        bidId: i.id
    }
    let docArr = i.documents
                    // Отключил так как не виводит на учасниках
                    // .filter(
                    //     (item) => {
                    //         if(!isSPE(props.auctionId.sellingMethod) || !isLPE(props.auctionId.sellingMethod)){
                    //             return item.documentType !== 'digitalSignature'
                    //         }
                    //         return item
                    // })
                    .map(
                        (item, count) => {
                            return (
                                <DocItem
                                    data={item}
                                    scopeData={docScopeData}
                                    // dataFuncOpenPopUp={setPopAddDoc}
                                    dataECP={ dataECPMapper(i, item) }
                                />
                            )
                        }
                    )

    return (
        <>
            <PopDownBlock
                title={`Документи заяви (${docArr.length})`}
                content={docArr}
            />
        </>
    )
}

function contactPoint(data){
    if(data.bidders !== null){
        if(data.bidders.length > 0){
            if(checkProp(data.bidders[0], 'contactPoint')){
                return (
                    <PopDownBlock
                        title={`Контактна особа`}
                        content={
                            <>
                                <TextHalf title='ПІБ' data={data.bidders[0].contactPoint.name.uk_UA} />
                                <TextHalf title='Номер телефону' data={data.bidders[0].contactPoint.telephone} />
                                { checkProp(data.bidders[0].contactPoint, 'faxNumber') ?
                                    <TextHalf title='Номер факсу' data={data.bidders[0].contactPoint.faxNumber}
                                    /> : null }
                                { checkProp(data.bidders[0].contactPoint, 'email') ? <TextHalf title='Адреса електронної пошти'
                                    data={<a href={`mailto:${data.bidders[0].contactPoint.email}`}>{data.bidders[0].contactPoint.email}</a>}
                                /> : null }
                                { checkProp(data.bidders[0].contactPoint, 'url') ?
                                    <TextHalf title='Веб адреса'
                                        data={<a href={`${data.bidders[0].contactPoint.url}`}>{data.bidders[0].contactPoint.url}</a>}
                                /> : null }
                            </>
                        }
                    />
                )
            }
        }
    }
    return null
}

function identifier(data){
    if(data.bidders !== null){
        if(data.bidders.length > 0){
            if(data.bidders[0].hasOwnProperty('identifier') === true){
                if(data.bidders[0].identifier !== null){
                    return (
                        <PopDownBlock
                            title={`Ідентифікатори організації або особи`}
                            content={
                                <>
                                    <TextFull title='Повна юридична назва організації' data={data.bidders[0].identifier.legalName.uk_UA} />
                                    <TextHalf title={viewIdentyfierType(data.bidders[0].identifier.scheme) || 'Код ЄДРПОУ або ІПН або паспорт'} data={data.bidders[0].identifier.id} />
                                </>
                            }
                        />
                    )
                }
            }
        }
    }
    return null
}

function nameUser(data){
    return data.bidders[0].name.uk_UA
}

function proposUser(data){
    if(data === null) return 0
    if(data.value === null) return 0
    let tmp = viewStringValue(data.value)
    return tmp
}

function proposUserSneeck(data){
    if(data === null) return 0
    if(data.initialValueAmount === null) return 0
    let tmpCost = {
        amount: data.initialValueAmount,
        currency: "UAH"
    }
    let tmp = viewStringValue(tmpCost)
    return tmp
}

function statusUser(props, i){
    let tmpTextWinner = null
    if(props.awards !== null){
        tmpTextWinner = viewStatusAwards(props.awards.status, props)
    }
    return tmpTextWinner
}

function statusUserAll(props, i){
    if(i === null) return null
    let tmpTextWinner = 'Учасник не став переможцем'
    if(props.auctionFull !== null){
        if(props.auctionFull.awards.length > 0){
            for(let tti = 0; tti < props.auctionFull.awards.length; tti++){
                if(i.id === props.auctionFull.awards[tti].bidId) {
                    tmpTextWinner = viewStatusAwards(props.auctionFull.awards[tti].status, props)
                }
            }
        }
    }
    return tmpTextWinner
}

function viewStatusAwards(status, props){
    switch(status){
        case 'pending':
            if(isNLE(props.auctionFull.sellingMethod) || isNLD(props.auctionFull.sellingMethod)){
                return 'Очікується оплата'    
            }
            if(isLAP(props.auctionFull.sellingMethod) || isAPE(props.auctionFull.sellingMethod) || isAPD(props.auctionFull.sellingMethod)){
                return 'Очікується протокол'
            }
            return 'Очікується протокол'
        case 'pending_admission':
            return 'Очікується рішення про викуп'
        case 'pending_waiting':
            return 'Очікується рішення'
        case 'pending_payment':
            return 'Очікується оплата'
        case 'signed':
            if(isLAP(props.auctionFull.sellingMethod)){
                return 'Акт/договір підтверджено'
            }
            return 'Акт/договір підтверджено'
        case 'active':
            if(isNLE(props.auctionFull.sellingMethod) || isNLD(props.auctionFull.sellingMethod)){
                return 'Переможець'
            }
            if(isLAP(props.auctionFull.sellingMethod)){
                return 'Переможець. Очікується акт/договір'
            }
            if(isAPE(props.auctionFull.sellingMethod) || isAPD(props.auctionFull.sellingMethod)){
                return 'Переможець. Очікується акт/договір та оплата'
            }
            return 'Переможець. Очікується договір'
        case 'unsuccessful':
            return 'Дискваліфіковано'
        case 'cancelled':
            return 'Учасник не став переможцем'
        default:
            return 'Учасник не став переможцем'
    }
}

function viewIdentyfierType(a) {
    if (a !== null && a !== undefined) {
        switch (a) {
            case 'UA-EDR':
                return 'ЄДРПОУ'
            case 'UA-IPN':
                return 'ІПН/РНОКПП'
            case 'UA-PASSPORT':
                return 'Паспорт'
            case 'UA-ID-CARD':
                return 'ID-карта'
            case 'UA-IPN-FOP':
                return 'ІПН/РНОКПП'
            default:
                return null
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        auctionAwards: state.start.auctionAwards,
        auctionId: state.start.auctionFull,
        auctionsAllBids: state.start.auctionsAllBids,
        auctionFull: state.start.auctionFull,
        token: state.start.token,
        arrPrivatLink: state.start.arrPrivatLink,
    }
}

const mapDispatchToProps = {
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(Participant)