import React, {useState} from 'react';
import styles from './dropDownMenu.module.css';

import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {changeCreateAuctionTab} from '../../../../redux/actions.js';
import {changeAuctionsSwitchWindow, auctionsGetMy, setPopUpSendAuction, setPopUpErrorAuction} from '../../../../redux/actions/auctions/auctions.js';
import {classificationsGetByScheme} from '../../../../redux/actions/classifications/classifications.js';
import {
    getMyAuction,
    changeCreateAuctionTitle,
    changeCreateAuctionDescription,
    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,
    changeCreateAuctionId,
    setEditAuctionFromId,
    changeLoader,
} from '../../../../redux/actions.js';

import {
    changeDiscountPercent,
    changeDiscountPerviousAuctionCurrency,
    changeDiscountPerviousAuctionAmount,
    changeDiscountPerviousAuctionId,
    discountAuc,
    changeDiscountId,
} from '../../../../redux/actions/discount/discount.js';

import {
    setDataAddress,
} from '../../../../redux/createAddress';

import {
    setPopUpAuctionMyNull,
} from '../../../../redux/actions/auctions/auctions.js';

import {setUniversalError} from '../../../../redux/actions.js';

import {auctionsGetAwards, auctionsGetAwardsId} from '../../../../redux/actions/awards/awards.js';
import {getAuctionsBidsId} from '../../../../redux/actions/auctionBid/auctionBid.js';
import {contractsAuctionIdGet} from '../../../../redux/actions/contracts/contracts.js';
import {setPopBankAccountId, setPopBankAccountIdArr, setBankAccountSave, setBankAccountAdd, setBankAccountDelete} from '../../../../redux/actions/bankAccount/bankAccount.js';
import {oldArrDocumentGet} from '../../../../redux/actions/documents/documents.js';

import {getAPI} from '../../../../redux/actions/crud.js';

import {setPopUpDelAuction} from '../../../../redux/actions/auctions/auctions.js';

import DropDownMenuLinks from './dropDownMenu_Links'
import { checkProp } from '../../../../redux/check';

import {
    isBRD, isBRE, isBRW,
    isALE,
    isBSD, isBSE,
    isCSD, isCSE,
    isLRE, isLSE, isLSP,
    isRLE, isRLD,
    isCLE, isCLD,
    isSPE, isSPD,
    isNLE, isNLD,
    isLAE, isLAW, isLAP,
    isLPE,
    isAPE, isAPD,
    isLLE, isLLD, isLLP
} from '../../../../redux/aucTypes';

import {toAuctionDraftId, toAuctionDraftCopy} from '../../../../api/auctions/auctionEndpoints'

const DropDownMenu = (props) => {
    const [dropDomnMenu, setdropDomnMenu] = useState(styles.noDisplay)
    const [buttonActivate, setbuttonActivate] = useState(styles.tdBtn)
    const [popUpCadastr, setPopUpCadastr] = useState(null)

    function dropDomnToggle() {
        if (dropDomnMenu === styles.noDisplay) {
            setdropDomnMenu(styles.dropDown)
            setbuttonActivate(styles.btnActive)
        }
        else { 
            setdropDomnMenu(styles.noDisplay)
            setbuttonActivate(styles.tdBtn)
        }
    }
    
    return (
        <>
            <div className={styles.tdBtnWrap}>
                <div className={buttonActivate} onClick={dropDomnToggle}>
                    <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg" viewBox="-11 1 46 4">
                        <g id="Слой_2" data-name="Слой 2">
                            <g id="Слой_1-2" data-name="Слой 1">
                            <path className={styles.svgIcon} d="M5.33,2.67A2.67,2.67,0,1,0,2.67,5.34,2.66,2.66,0,0,0,5.33,2.67Z"/>
                            <path className={styles.svgIcon} d="M14.67,2.67A2.67,2.67,0,1,0,12,5.34,2.67,2.67,0,0,0,14.67,2.67Z"/>
                            <path className={styles.svgIcon} d="M24,2.67a2.67,2.67,0,1,0-2.67,2.67A2.66,2.66,0,0,0,24,2.67Z"/>
                            </g>
                        </g>
                    </svg>
                </div>
                <div className={dropDomnMenu}>
                    <div className={styles.dropdownContent}>
                        <ul className={styles.menuList}>
                            <DropDownMenuLinks 
                                i={props.i}
                                dropDomnToggle={dropDomnToggle}
                            />
                            {ReadAndWriteProcedure(props, props.i, dropDomnToggle)}
                            {!isSPE(props.i.sellingMethod) && !isSPD(props.i.sellingMethod) && !isLPE(props.i.sellingMethod)
                                ? buttonPublishAuctionNew(props, props.i, dropDomnToggle, setPopUpCadastr)
                                : null
                            }
                            {buttonsСansallAndDeleteAuction(props, dropDomnToggle)}

                            {/* Отключены до монги */}
                            {/*{buttonsСopyAuction(props, dropDomnToggle)}
                            {cloneZU(props, props.i, props.count, dropDomnToggle)}*/}
                            {/* Отключены до монги */}
                            {!isSPE(props.i.sellingMethod) && !isSPD(props.i.sellingMethod) && !isLPE(props.i.sellingMethod)
                                ? cloneZU(props, props.i)
                                : null
                            }
                            {isLLE(props.i.sellingMethod)
                                ? cloneZU1(props, props.i)
                                : null
                            }
                            {!isSPE(props.i.sellingMethod) && !isSPD(props.i.sellingMethod)
                                && !isLAW(props.i.sellingMethod) && !isLPE(props.i.sellingMethod)
                                && !isLLE(props.i.sellingMethod)// && props.i.tenderAttempts !== 1 && props.i.tenderAttempts !== 3)
                                ? <li
                                    onClick={
                                        ()=>{
                                            if(
                                                !isBSE(props.i.sellingMethod) && !isBSD(props.i.sellingMethod) &&
                                                !isALE(props.i.sellingMethod) &&
                                                !isCSE(props.i.sellingMethod) && !isCSD(props.i.sellingMethod) &&
                                                !isBRE(props.i.sellingMethod) && !isBRD(props.i.sellingMethod) && !isBRW(props.i.sellingMethod) &&
                                                !isRLE(props.i.sellingMethod) && !isRLD(props.i.sellingMethod) &&
                                                !isCLE(props.i.sellingMethod) && !isCLD(props.i.sellingMethod) &&
                                                !isNLE(props.i.sellingMethod) && !isNLD(props.i.sellingMethod) &&
                                                !isLAE(props.i.sellingMethod) && !isLAP(props.i.sellingMethod) &&
                                                !isAPE(props.i.sellingMethod) && !isAPD(props.i.sellingMethod) &&
                                                //!isLLE(props.i.sellingMethod) && !isLLD(props.i.sellingMethod) && !isLLP(props.i.sellingMethod)
                                                !isLLD(props.i.sellingMethod) && !isLLP(props.i.sellingMethod) &&
                                                !isLRE(props.i.sellingMethod) && !isLSE(props.i.sellingMethod) && !isLSP(props.i.sellingMethod)
                                            ){
                                                cloneAucNew(props, props.i.dataAuction.mysqlDraftId)
                                            }else{
                                                if(!isLLE(props.i.sellingMethod)){
                                                    cloneAucNewMonga(props, props.i.id, props.i.auctionId)
                                                }else{
                                                    if(props.i.tenderAttempts !== 1 && props.i.tenderAttempts !== 3){
                                                        cloneAucNewMonga(props, props.i.id, props.i.auctionId)
                                                    }
                                                }
                                            }
                                        }
                                    }
                                >
                                    Створити копію аукціону
                                </li>
                                : null
                            }
                            {(isLAE(props.i.sellingMethod) || isLAP(props.i.sellingMethod)) && (props.i.status === "cancelled" || props.i.status === "unsuccessful")
                                ? <li
                                    onClick={
                                        ()=>{
                                            copyTo(props, props.i.id, "landArrested-withoutAuction")
                                        }
                                    }
                                >
                                    Створити Арештована земля: викуп
                                </li>
                                : null
                            }
                            {viewCompliteAuction(props, props.i, dropDomnToggle)}
                        </ul>
                    </div>
                    <div className={styles.dropdownBlock} onClick={dropDomnToggle}></div>
                </div>
            </div>
            {popUpCadastr !== null ? <div className={styles.popup_wrap}>
            <div className={styles.popup_message}>
                <div
                    className={styles.popUpExit}
                    onClick={
                        ()=>{
                            setPopUpCadastr(null)
                        }
                    }
                >
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className={styles.closeSvg} fillRule="evenodd" clipRule="evenodd" d="M8.41421 6.99989L13.364 11.9496C13.7545 12.3402 13.7545 12.9733 13.364 13.3639C12.9734 13.7544 12.3403 13.7544 11.9497 13.3639L7 8.41411L2.05025 13.3639C1.65973 13.7544 1.02656 13.7544 0.636039 13.3639C0.245514 12.9733 0.245515 12.3402 0.636039 11.9496L5.58579 6.99989L0.636039 2.05015C0.245515 1.65962 0.245514 1.02646 0.636039 0.635934C1.02656 0.245409 1.65973 0.245409 2.05025 0.635934L7 5.58568L11.9497 0.635934C12.3403 0.245409 12.9734 0.245409 13.364 0.635934C13.7545 1.02646 13.7545 1.65962 13.364 2.05015L8.41421 6.99989Z" fill="#92929D"/>
                    </svg>

                </div>
                <div className={styles.popUpTitle}>Для вказаної вами земельної ділянки вже було опублікований аукціон іншим організатором, ви впевнені, що хочете продовжити публікацію?</div>
                <div className={styles.blokButton}>
                    <div
                        className={styles.popupButtonYes}
                        onClick={
                            ()=>{
                                if(!isLAE(props.i.sellingMethod) && !isLAW(props.i.sellingMethod) && !isLAP(props.i.sellingMethod)
                                    && !isLRE(props.i.sellingMethod) && !isLSE(props.i.sellingMethod) && !isLSP(props.i.sellingMethod)
                                ){
                                    sendAuction(props, popUpCadastr.id)
                                    setPopUpCadastr(null)
                                }else{
                                    sendAuctionMonga(props, popUpCadastr.id)
                                    setPopUpCadastr(null)
                                }
                            }
                        }
                    >Так</div>
                    <div
                        className={styles.popupButtonNo}
                        onClick={
                            ()=>{
                                setPopUpCadastr(null)
                            }
                        }
                    >Ні</div>
                </div>
                
            </div>
        </div> : null}
        </>
    )
}

//Просмотр/редагування
function ReadAndWriteProcedure(props, i, data){
    if(isLRE(i.sellingMethod) || isLSE(i.sellingMethod) || isLSP(i.sellingMethod)){
        if(
            i.status === 'active_auction'
            || i.status === 'active_qualification'
            || i.status === 'active_awarded'
            || i.status === 'unsuccessful'
            || i.status === 'cancelled'
            || i.status === 'complete'
        ) return null
        return (
            <Link to={`/create_auctions/editNew/${props.i.id}`} title="Посилання на аукціон">
                <li
                    onClick={
                        ()=>{
                            data()
                            createButton(props, true)
                            props.changeCreateAuctionTab(2)
                            props.changeAuctionsSwitchWindow(1)
                        }
                    }
                    >
                    Редагувати
                </li>
            </Link>
        )
    }else if(isLLE(i.sellingMethod) || isLLD(i.sellingMethod) || isLLP(i.sellingMethod)){
        if(
            i.status === 'active_auction'
            || i.status === 'active_qualification'
            || i.status === 'active_awarded'
            || i.status === 'unsuccessful'
            || i.status === 'cancelled'
            || i.status === 'complete'
        ) return null
        return (
            <Link to={`/create_auctions/editNew/${props.i.id}`} title="Посилання на аукціон">
                <li
                    onClick={
                        ()=>{
                            data()
                            createButton(props, true)
                            props.changeCreateAuctionTab(2)
                            props.changeAuctionsSwitchWindow(1)
                        }
                    }
                    >
                    Редагувати
                </li>
            </Link>
        )
    }else if(isBRW(i.sellingMethod) &&  i.status === 'active_awarded'){
        return (
            <Link to={`/create_auctions/editNew/${props.i.id}`} title="Посилання на аукціон">
                <li
                    onClick={
                        ()=>{
                            data()
                            createButton(props, true)
                            props.changeCreateAuctionTab(2)
                            props.changeAuctionsSwitchWindow(1)
                        }
                    }
                    >
                    Редагувати
                </li>
            </Link>
        )
    }else if(
        isLAW(i.sellingMethod)
        && (
            i.status !== 'unsuccessful'
            && i.status !== 'cancelled'
            && i.status !== 'complete'
        )
    ){
        if(i?.awards[0]?.status === 'active') return null
        return (
            <Link to={`/create_auctions/editNew/${props.i.id}`} title="Посилання на аукціон">
                <li
                    onClick={
                        ()=>{
                            data()
                            createButton(props, true)
                            props.changeCreateAuctionTab(2)
                            props.changeAuctionsSwitchWindow(1)
                        }
                    }
                    >
                    Редагувати
                </li>
            </Link>
        )
    }else if(
        (isSPE(i.sellingMethod) || isSPD(i.sellingMethod) || isLPE(i.sellingMethod))
        && (
            i.status !== 'unsuccessful'
            && i.status !== 'cancelled'
            && i.status !== 'complete'
        )
    ){
        return (
            <Link to={`/create_auctions/editNew/${props.i.id}`} title="Посилання на аукціон">
                <li
                    onClick={
                        ()=>{
                            data()
                            createButton(props, true)
                            props.changeCreateAuctionTab(2)
                            props.changeAuctionsSwitchWindow(1)
                        }
                    }
                    >
                    Редагувати
                </li>
            </Link>
        )
    }else if(
        (isAPE(i.sellingMethod) || isAPD(i.sellingMethod))
        && (
            i.status !== 'active_auction'
            && i.status !== 'active_qualification'
            && i.status !== 'pending_payment'
            && i.status !== 'active_awarded'
            && i.status !== 'unsuccessful'
            && i.status !== 'cancelled'
            && i.status !== 'complete'
        )
    ){
        let tmpEnd = null
        if(i.rectificationPeriod !== null && i.rectificationPeriod.endDate !== null) tmpEnd = new Date(i.rectificationPeriod.endDate)
        let tmpEnd1 = null
        if(tmpEnd !== null) tmpEnd1 = tmpEnd.getTime()
        if(tmpEnd === null || tmpEnd1 > Date.now()){
            return (
                <Link to={`/create_auctions/editNew/${props.i.id}`} title="Посилання на аукціон">
                    <li
                        onClick={
                            ()=>{
                                data()
                                createButton(props, true)
                                props.changeCreateAuctionTab(2)
                                props.changeAuctionsSwitchWindow(1)
                            }
                        }
                        >
                        Редагувати
                    </li>
                </Link>
            )
        }else{
            return (
                <Link to={`/create_auctions/editNew/${props.i.id}`} title="Посилання на аукціон">
                    <li
                        onClick={
                            ()=>{
                                data()
                                createButton(props, true)
                                props.changeCreateAuctionTab(2)
                                props.changeAuctionsSwitchWindow(1)
                            }
                        }
                        >
                        Редагувати
                    </li>
                </Link>
            )
        }
    }else if(
        i.status === null ||
        i.status === ''
    ){
        if(checkProp( props.i, 'dataAuction') && props.i.dataAuction.mysqlDraftId !== null){ //TODO: добаил чекпроп, бо тут падало на том, что нету датаАукшн
            return <Link to={`/create_auctions/edit/${props.i.dataAuction.mysqlDraftId}`} title="Посилання на аукціон">
                <li
                    onClick={
                        ()=>{
                            props.setDataAddress('null', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
                            props.setDataAddress(null, 'CREATE_ITEMS_LAND_RENTAL_ID')
                            props.setDataAddress(null, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ID')
                            props.setDataAddress(null, 'CREATE_ITEMS_VEHICLE_ITEM_ID')
                            props.setDataAddress(null, 'CREATE_ITEMS_REAL_ESTATEITEM_ID')
                            data()
                            createButton(props)
                            if(
                                // i.sellingMethod === "legitimatePropertyLease-english" ||
                                // i.sellingMethod === "legitimatePropertyLease-english-fast" ||
                                // i.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                                // i.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                                // i.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                                // i.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                                // i.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                                // i.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                                // i.sellingMethod === "legitimatePropertyLease-dutch" ||
                                // i.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                                // i.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                                // i.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                                // i.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                                // i.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                                // i.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                                // i.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                                // i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                                // i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                                // i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                                // i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                                // i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                                // i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                                // i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod" ||
                                // i.sellingMethod === 'landRental-english' ||
                                // i.sellingMethod === 'landRental-english-fast' ||
                                // i.sellingMethod === 'landRental-english-fast-manual' ||
                                // i.sellingMethod === 'landRental-english-fast-auction-manual-qualification' ||
                                // i.sellingMethod === 'landRental-english-initial-auction' ||
                                // i.sellingMethod === 'landRental-english-initial-auction-manual' ||
                                // i.sellingMethod === 'landRental-english-initial-qualification' ||
                                // i.sellingMethod === 'landRental-english-initial-qualification-prod' ||
                                i.sellingMethod === 'landSell-english' ||
                                i.sellingMethod === 'landSell-english-fast' ||
                                i.sellingMethod === 'landSell-english-fast-manual' ||
                                i.sellingMethod === 'landSell-english-fast-auction-manual-qualification' ||
                                i.sellingMethod === 'landSell-english-initial-auction' ||
                                i.sellingMethod === 'landSell-english-initial-auction-manual' ||
                                i.sellingMethod === 'landSell-english-initial-qualification' ||
                                i.sellingMethod === 'landSell-english-initial-qualification-prod' ||
                                i.sellingMethod === 'landSell-priorityEnglish' ||
                                i.sellingMethod === 'landSell-priorityEnglish-fast' ||
                                i.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
                                i.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
                                i.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
                                i.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
                                i.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
                                i.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod'
                            ){
                                props.setDataAddress(true, 'SET_BRIDGE_OPEN_POPUP')
                                props.setDataAddress(true, 'SET_BRIDGE_OPEN_BUTTON')
                            }
                            props.changeAuctionsSwitchWindow(1)
                        }
                    }
                >
                    Редагувати
                </li>
            </Link>
        }else{
            return <Link to={`/create_auctions/editNew/${props.i.id}`} title="Посилання на аукціон">
                <li
                    onClick={
                        ()=>{
                            props.setDataAddress('null', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
                            props.setDataAddress(null, 'CREATE_ITEMS_LAND_RENTAL_ID')
                            props.setDataAddress(null, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ID')
                            props.setDataAddress(null, 'CREATE_ITEMS_VEHICLE_ITEM_ID')
                            props.setDataAddress(null, 'CREATE_ITEMS_REAL_ESTATEITEM_ID')
                            data()
                            createButton(props)
                            if(
                                // i.sellingMethod === "legitimatePropertyLease-english" ||
                                // i.sellingMethod === "legitimatePropertyLease-english-fast" ||
                                // i.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                                // i.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                                // i.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                                // i.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                                // i.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                                // i.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                                i.sellingMethod === "legitimatePropertyLease-dutch" ||
                                i.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                                i.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                                i.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                                i.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                                i.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                                i.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                                i.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                                i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                                i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                                i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                                i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                                i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                                i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                                i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod" ||
                                i.sellingMethod === 'landRental-english' ||
                                i.sellingMethod === 'landRental-english-fast' ||
                                i.sellingMethod === 'landRental-english-fast-manual' ||
                                i.sellingMethod === 'landRental-english-fast-auction-manual-qualification' ||
                                i.sellingMethod === 'landRental-english-initial-auction' ||
                                i.sellingMethod === 'landRental-english-initial-auction-manual' ||
                                i.sellingMethod === 'landRental-english-initial-qualification' ||
                                i.sellingMethod === 'landRental-english-initial-qualification-prod' ||
                                i.sellingMethod === 'landSell-english' ||
                                i.sellingMethod === 'landSell-english-fast' ||
                                i.sellingMethod === 'landSell-english-fast-manual' ||
                                i.sellingMethod === 'landSell-english-fast-auction-manual-qualification' ||
                                i.sellingMethod === 'landSell-english-initial-auction' ||
                                i.sellingMethod === 'landSell-english-initial-auction-manual' ||
                                i.sellingMethod === 'landSell-english-initial-qualification' ||
                                i.sellingMethod === 'landSell-english-initial-qualification-prod' ||
                                i.sellingMethod === 'landSell-priorityEnglish' ||
                                i.sellingMethod === 'landSell-priorityEnglish-fast' ||
                                i.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
                                i.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
                                i.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
                                i.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
                                i.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
                                i.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod'
                            ){
                                props.setDataAddress(true, 'SET_BRIDGE_OPEN_POPUP')
                                props.setDataAddress(true, 'SET_BRIDGE_OPEN_BUTTON')
                            }
                            props.changeAuctionsSwitchWindow(1)
                        }
                    }
                >
                    Редагувати
                </li>
            </Link>
        }
    }else if(i.status === 'active_rectification'){
        if(checkProp( props.i, 'dataAuction') && props.i.dataAuction.mysqlDraftId !== null){ //TODO: добаил чекпроп, бо тут падало на том, что нету датаАукшн
            return <Link to={`/create_auctions/edit/${props.i.dataAuction.mysqlDraftId}`} title="Посилання на аукціон">
                <li
                    onClick={
                        ()=>{
                            props.setDataAddress('null', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
                            props.setDataAddress(null, 'CREATE_ITEMS_LAND_RENTAL_ID')
                            props.setDataAddress(null, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ID')
                            props.setDataAddress(null, 'CREATE_ITEMS_VEHICLE_ITEM_ID')
                            props.setDataAddress(null, 'CREATE_ITEMS_REAL_ESTATEITEM_ID')
                            data()
                            createButton(props)
                            if(
                                // i.sellingMethod === "legitimatePropertyLease-english" ||
                                // i.sellingMethod === "legitimatePropertyLease-english-fast" ||
                                // i.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                                // i.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                                // i.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                                // i.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                                // i.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                                // i.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                                i.sellingMethod === "legitimatePropertyLease-dutch" ||
                                i.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                                i.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                                i.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                                i.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                                i.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                                i.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                                i.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                                i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                                i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                                i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                                i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                                i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                                i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                                i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod" ||
                                i.sellingMethod === 'landRental-english' ||
                                i.sellingMethod === 'landRental-english-fast' ||
                                i.sellingMethod === 'landRental-english-fast-manual' ||
                                i.sellingMethod === 'landRental-english-fast-auction-manual-qualification' ||
                                i.sellingMethod === 'landRental-english-initial-auction' ||
                                i.sellingMethod === 'landRental-english-initial-auction-manual' ||
                                i.sellingMethod === 'landRental-english-initial-qualification' ||
                                i.sellingMethod === 'landRental-english-initial-qualification-prod' ||
                                i.sellingMethod === 'landSell-english' ||
                                i.sellingMethod === 'landSell-english-fast' ||
                                i.sellingMethod === 'landSell-english-fast-manual' ||
                                i.sellingMethod === 'landSell-english-fast-auction-manual-qualification' ||
                                i.sellingMethod === 'landSell-english-initial-auction' ||
                                i.sellingMethod === 'landSell-english-initial-auction-manual' ||
                                i.sellingMethod === 'landSell-english-initial-qualification' ||
                                i.sellingMethod === 'landSell-english-initial-qualification-prod' ||
                                i.sellingMethod === 'landSell-priorityEnglish' ||
                                i.sellingMethod === 'landSell-priorityEnglish-fast' ||
                                i.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
                                i.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
                                i.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
                                i.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
                                i.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
                                i.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod'
                            ){
                                props.setDataAddress(true, 'SET_BRIDGE_OPEN_POPUP')
                                props.setDataAddress(true, 'SET_BRIDGE_OPEN_BUTTON')
                            }
                            props.changeCreateAuctionTab(2)
                            props.changeAuctionsSwitchWindow(1)
                        }
                    }
                >
                    Редагувати
                </li>
            </Link>
        }else{
            return <Link to={`/create_auctions/editNew/${props.i.id}`} title="Посилання на аукціон">
                <li
                    onClick={
                        ()=>{
                            props.setDataAddress('null', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
                            props.setDataAddress(null, 'CREATE_ITEMS_LAND_RENTAL_ID')
                            props.setDataAddress(null, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ID')
                            props.setDataAddress(null, 'CREATE_ITEMS_VEHICLE_ITEM_ID')
                            props.setDataAddress(null, 'CREATE_ITEMS_REAL_ESTATEITEM_ID')
                            data()
                            createButton(props)
                            if(
                                // i.sellingMethod === "legitimatePropertyLease-english" ||
                                // i.sellingMethod === "legitimatePropertyLease-english-fast" ||
                                // i.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                                // i.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                                // i.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                                // i.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                                // i.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                                // i.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                                i.sellingMethod === "legitimatePropertyLease-dutch" ||
                                i.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                                i.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                                i.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                                i.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                                i.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                                i.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                                i.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                                i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                                i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                                i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                                i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                                i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                                i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                                i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod" ||
                                i.sellingMethod === 'landRental-english' ||
                                i.sellingMethod === 'landRental-english-fast' ||
                                i.sellingMethod === 'landRental-english-fast-manual' ||
                                i.sellingMethod === 'landRental-english-fast-auction-manual-qualification' ||
                                i.sellingMethod === 'landRental-english-initial-auction' ||
                                i.sellingMethod === 'landRental-english-initial-auction-manual' ||
                                i.sellingMethod === 'landRental-english-initial-qualification' ||
                                i.sellingMethod === 'landRental-english-initial-qualification-prod' ||
                                i.sellingMethod === 'landSell-english' ||
                                i.sellingMethod === 'landSell-english-fast' ||
                                i.sellingMethod === 'landSell-english-fast-manual' ||
                                i.sellingMethod === 'landSell-english-fast-auction-manual-qualification' ||
                                i.sellingMethod === 'landSell-english-initial-auction' ||
                                i.sellingMethod === 'landSell-english-initial-auction-manual' ||
                                i.sellingMethod === 'landSell-english-initial-qualification' ||
                                i.sellingMethod === 'landSell-english-initial-qualification-prod' ||
                                i.sellingMethod === 'landSell-priorityEnglish' ||
                                i.sellingMethod === 'landSell-priorityEnglish-fast' ||
                                i.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
                                i.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
                                i.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
                                i.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
                                i.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
                                i.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod'
                            ){
                                props.setDataAddress(true, 'SET_BRIDGE_OPEN_POPUP')
                                props.setDataAddress(true, 'SET_BRIDGE_OPEN_BUTTON')
                            }
                            props.changeCreateAuctionTab(2)
                            props.changeAuctionsSwitchWindow(1)
                        }
                    }
                >
                    Редагувати
                </li>
            </Link>
        }
    }else if(i.status === 'active_tendering'){
        if(
            i.sellingMethod === 'landSell-priorityEnglish' ||
            i.sellingMethod === 'landSell-priorityEnglish-fast' ||
            i.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
            i.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
            i.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
            i.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
            i.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
            i.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod'
        ){
            if(props.i.dataAuction.mysqlDraftId !== null){
                return (
                    <Link to={`/create_auctions/edit/${props.i.dataAuction.mysqlDraftId}`} title="Посилання на аукціон">
                        <li
                            onClick={
                                ()=>{
                                    data()
                                    createButton(props)
                                    props.setDataAddress(true, 'SET_BRIDGE_OPEN_POPUP')
                                    props.setDataAddress(true, 'SET_BRIDGE_OPEN_BUTTON')
                                    props.changeAuctionsSwitchWindow(1)
                                }
                            }
                        >
                            Редагувати
                        </li>
                    </Link>
                )
            }else{
                return (
                    <Link to={`/create_auctions/editNew/${props.i.id}`} title="Посилання на аукціон">
                        <li
                            onClick={
                                ()=>{
                                    data()
                                    createButton(props)
                                    props.setDataAddress(true, 'SET_BRIDGE_OPEN_POPUP')
                                    props.setDataAddress(true, 'SET_BRIDGE_OPEN_BUTTON')
                                    props.changeAuctionsSwitchWindow(1)
                                }
                            }
                        >
                            Редагувати
                        </li>
                    </Link>
                )
            }
        }else if(
            isBSE(i.sellingMethod) || isBSD(i.sellingMethod) ||
            isALE(i.sellingMethod) ||
            isCSE(i.sellingMethod) || isCSD(i.sellingMethod) ||
            isBRE(i.sellingMethod) || isBRD(i.sellingMethod) || isBRW(i.sellingMethod) ||
            isRLE(i.sellingMethod) || isRLD(i.sellingMethod) ||
            isCLE(i.sellingMethod) || isCLD(i.sellingMethod) ||
            isNLE(i.sellingMethod) || isNLD(i.sellingMethod) ||
            isLAE(i.sellingMethod) || isLAW(i.sellingMethod) || isLAP(i.sellingMethod) ||
            isAPE(i.sellingMethod) || isAPD(i.sellingMethod) ||
            isLLE(i.sellingMethod) || isLLD(i.sellingMethod) || isLLP(i.sellingMethod)
            || isLRE(i.sellingMethod) || isLSE(i.sellingMethod) || isLSP(i.sellingMethod)
        ){
            if(props.i.dataAuction.mysqlDraftId !== null){
                return (
                    <Link to={`/create_auctions/edit/${props.i.dataAuction.mysqlDraftId}`} title="Посилання на аукціон">
                        <li
                            onClick={
                                ()=>{
                                    data()
                                    createButton(props)
                                    props.setDataAddress(null, 'CREATE_ITEMS_LAND_RENTAL_ID')
                                    props.setDataAddress(null, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ID')
                                    props.setDataAddress(null, 'CREATE_ITEMS_VEHICLE_ITEM_ID')
                                    props.setDataAddress(null, 'CREATE_ITEMS_REAL_ESTATEITEM_ID')
                                    props.changeCreateAuctionTab(2)
                                    props.changeAuctionsSwitchWindow(1)
                                    if(isLAE(i.sellingMethod) || isLAW(i.sellingMethod) || isLAP(i.sellingMethod)){
                                        props.setDataAddress(2, 'CREATE_AUCTION_TAB')
                                    }
                                }
                            }
                        >
                            Редагувати
                        </li>
                    </Link>
                )
            }else{
                if(checkEditDoc(props)){
                    return (
                        <Link to={`/create_auctions/editNew/${props.i.id}`} title="Посилання на аукціон">
                            <li
                                onClick={
                                    ()=>{
                                        data()
                                        createButton(props)
                                        props.setDataAddress(null, 'CREATE_ITEMS_LAND_RENTAL_ID')
                                        props.setDataAddress(null, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ID')
                                        props.setDataAddress(null, 'CREATE_ITEMS_VEHICLE_ITEM_ID')
                                        props.setDataAddress(null, 'CREATE_ITEMS_REAL_ESTATEITEM_ID')
                                        props.changeAuctionsSwitchWindow(1)
                                    }
                                }
                            >
                                Редагувати
                            </li>
                        </Link>
                    )
                }else{
                    return (
                        <Link to={`/create_auctions/editNew/${props.i.id}`} title="Посилання на аукціон">
                            <li
                                onClick={
                                    ()=>{
                                        data()
                                        createButton(props)
                                        props.setDataAddress(null, 'CREATE_ITEMS_LAND_RENTAL_ID')
                                        props.setDataAddress(null, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ID')
                                        props.setDataAddress(null, 'CREATE_ITEMS_VEHICLE_ITEM_ID')
                                        props.setDataAddress(null, 'CREATE_ITEMS_REAL_ESTATEITEM_ID')
                                        props.changeAuctionsSwitchWindow(1)
                                        props.changeCreateAuctionTab(2)
                                    }
                                }
                            >
                                Редагувати
                            </li>
                        </Link>
                    )
                }
            }
        }else{
            if(props.i.dataAuction.mysqlDraftId !== null){
                return (
                    <Link to={`/create_auctions/edit/${props.i.dataAuction.mysqlDraftId}`} title="Посилання на аукціон">
                        <li
                            onClick={
                                ()=>{
                                    data()
                                    createButton(props, true)
                                    props.changeCreateAuctionTab(2)
                                    if(
                                        i.sellingMethod === "legitimatePropertyLease-english" ||
                                        i.sellingMethod === "legitimatePropertyLease-english-fast" ||
                                        i.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                                        i.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                                        i.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                                        i.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                                        i.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                                        i.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                                        i.sellingMethod === "legitimatePropertyLease-dutch" ||
                                        i.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                                        i.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                                        i.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                                        i.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                                        i.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                                        i.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                                        i.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                                        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                                        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                                        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                                        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                                        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                                        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                                        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
                                    ){
                                        props.setDataAddress(true, 'SET_BRIDGE_OPEN_POPUP')
                                        props.setDataAddress(true, 'SET_BRIDGE_OPEN_BUTTON')
                                        props.changeAuctionsSwitchWindow(1)
                                    }
                                }
                            }
                            >
                            Редагувати документи
                        </li>
                    </Link>
                )
            }else{
                return (
                    <Link to={`/create_auctions/editNew/${props.i.id}`} title="Посилання на аукціон">
                        <li
                            onClick={
                                ()=>{
                                    data()
                                    createButton(props, true)
                                    props.changeCreateAuctionTab(2)
                                    if(
                                        i.sellingMethod === "legitimatePropertyLease-english" ||
                                        i.sellingMethod === "legitimatePropertyLease-english-fast" ||
                                        i.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                                        i.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                                        i.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                                        i.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                                        i.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                                        i.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                                        i.sellingMethod === "legitimatePropertyLease-dutch" ||
                                        i.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                                        i.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                                        i.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                                        i.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                                        i.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                                        i.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                                        i.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                                        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                                        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                                        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                                        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                                        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                                        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                                        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
                                    ){
                                        props.setDataAddress(true, 'SET_BRIDGE_OPEN_POPUP')
                                        props.setDataAddress(true, 'SET_BRIDGE_OPEN_BUTTON')
                                        props.changeAuctionsSwitchWindow(1)
                                    }
                                }
                            }
                            >
                            Редагувати документи
                        </li>
                    </Link>
                )
            }
        }
    }else{
        if(
            i.status === 'active_auction'
            && (i.sellingMethod === 'alienation-english'
            || i.sellingMethod === 'alienation-english-fast'
            || i.sellingMethod === 'alienation-english-fast-manual'
            || i.sellingMethod === 'alienation-english-fast-auction-manual-qualification'
            || i.sellingMethod === 'alienation-english-initial-auction'
            || i.sellingMethod === 'alienation-english-initial-auction-manual'
            || i.sellingMethod === 'alienation-english-initial-qualification'
            || i.sellingMethod === 'alienation-english-initial-qualification-prod')
        ){
            if(props.i.dataAuction.mysqlDraftId === null){
                return (
                    <Link to={`/create_auctions/edit/${props.i.dataAuction.mysqlDraftId}`} title="Посилання на аукціон">
                        <li
                            onClick={
                                ()=>{
                                    data()
                                    createButton(props, true)
                                    props.changeCreateAuctionTab(2)
                                    props.changeAuctionsSwitchWindow(1)
                                }
                            }
                            >
                            Редагувати документи
                        </li>
                    </Link>
                )
            }else{
                return (
                    <Link to={`/create_auctions/editNew/${props.i.id}`} title="Посилання на аукціон">
                        <li
                            onClick={
                                ()=>{
                                    data()
                                    createButton(props, true)
                                    props.changeCreateAuctionTab(2)
                                    props.changeAuctionsSwitchWindow(1)
                                }
                            }
                            >
                            Редагувати документи
                        </li>
                    </Link>
                )
            }
        }else if(
            (isBRE(props.i.sellingMethod) || isBRD(props.i.sellingMethod))
            && i.status !== 'complete'
            && i.status !== 'cancelled'
            && i.status !== 'unsuccessful'
        ){
            return (
                <Link to={`/create_auctions/editNew/${props.i.id}`} title="Посилання на аукціон">
                    <li
                        onClick={
                            ()=>{
                                data()
                                createButton(props, true)
                                props.changeCreateAuctionTab(0)
                                props.changeAuctionsSwitchWindow(1)
                            }
                        }
                        >
                        Редагувати
                    </li>
                </Link>
            )
        }
    }
    return null
}

function checkEditDoc(props){
    if(props.i !== null !== null && props.i.status !== null){
        if(props.i !== null && props.i.documents !== null && props.i.documents.length > 0){
            for(let i = 0; i < props.i.documents.length; i++){
                if(props.i.documents[i].documentType ==='clarifications') return true
            }
        }
        return false
    }else{
        return true
    }
}

async function createButton(props){
    props.setBankAccountSave([])
    props.setBankAccountAdd([])
    props.setBankAccountDelete([])
    createArrayBankAccounts(props, props.i)
    if(props.i.discount !== null){
        props.changeDiscountPercent(props.i.discount.discountPercent)
        props.changeDiscountId(props.i.discount.id)
        props.discountAuc(props.i.discount.discount)
        if(props.i.discount.previousAuctionValue !== null){
            props.changeDiscountPerviousAuctionCurrency(props.i.discount.previousAuctionValue.currency)
            props.changeDiscountPerviousAuctionAmount(props.i.discount.previousAuctionValue.amount)
        }
    }
    props.changeDiscountPerviousAuctionId(props.i.previousAuctionId)
    await getAucionFromId(props, props.i, 1)
}

function createArrayBankAccounts(props, i){
    let tmp = [];
    if(i !== null){
        if(checkProp(i, 'bankAccounts') && i.bankAccounts.length > 0){
            for(let t = 0; t < i.bankAccounts.length; t++){
                tmp[t] = i.bankAccounts[t].id
            }
        }
    }
    if(tmp !== null){
        props.setBankAccountSave(tmp)
        props.setPopBankAccountIdArr(tmp)
    }
}

async function getAucionFromId(props, aucId, type, tenderAttempts=null, tenderAtemptVariants){
    props.changeLoader(true)
    let endPoint = ''
    if(aucId.dataAuction.mysqlDraftId !== null){
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${aucId.dataAuction.mysqlDraftId}`;
    }else{
        if(aucId.dataAuction.mongoPublicId !== null){
            endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/auction/${aucId.id}`;
        }else{
            //endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-draft/${aucId.id}`;
            endPoint = toAuctionDraftId(aucId.id)
        }
    }
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            if(type === 3){
                json.status = null
                if(
                    json.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                    json.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                    json.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                    json.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                    json.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                    json.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                    json.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                    json.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
                ){
                    json.tenderAttempts = json.tenderAttempts + 1
                }
            }
            await setAuctionEdit(props, json)
            await discountUpdate(props, json)
            await props.setEditAuctionFromId(json)
            if(tenderAttempts === '2'){
                await props.setDataAddress(tenderAttempts, 'CREATE_AUCTION_TENDER_ATTEMPTS')
            }else if(tenderAttempts === '3'){
                if(tenderAtemptVariants === true){
                    await props.setDataAddress(tenderAttempts, 'CREATE_AUCTION_TENDER_ATTEMPTS')
                    if(Number(process.env.REACT_APP_BUILD_STATUS) === 0){
                        await props.setDataAddress("legitimatePropertyLease-dutch", 'CREATE_AUCTION_TYPE')
                    }else{
                        await props.setDataAddress("legitimatePropertyLease-dutch-fast-manual", 'CREATE_AUCTION_TYPE')
                    }
                }else{
                    await props.setDataAddress(tenderAttempts, 'CREATE_AUCTION_TENDER_ATTEMPTS')
                }
            }else if(tenderAttempts === '4'){
                await props.setDataAddress(tenderAttempts, 'CREATE_AUCTION_TENDER_ATTEMPTS')
                if(Number(process.env.REACT_APP_BUILD_STATUS) === 0){
                    await props.setDataAddress("legitimatePropertyLease-dutch", 'CREATE_AUCTION_TYPE')
                }else{
                    await props.setDataAddress("legitimatePropertyLease-dutch-fast-manual", 'CREATE_AUCTION_TYPE')
                }
            }
            if(type===1){
                props.changeDiscountPerviousAuctionId(json.previousAuctionId)
            }
            if(json.value !== null){
                //await props.changeDiscountPerviousAuctionAmount(json.value.amount)
            }
            //if(type === 3){
                //await props.setDataAddress(null, 'CLONE_AUCTIONS_STATUS_CHANGE')
            //}

            if(aucId.dataAuction.mongoDraftId === null){
                await getAddressOrg(props, json, type)
            }
        }
    }
    props.changeLoader(false)
}

async function setAuctionEdit(props, data){
    props.setDataAddress(data, 'EDIT_AUCTION_FROM_ID')
    props.setDataAddress(data, 'CREATE_AUCTIONS_ZU_ATTEMP')
    props.changeDiscountPerviousAuctionId(data.auctionId)
}

async function discountUpdate(props, data){
    if(data.previousAuctionId !== null){
        if(data.discount !== null){
            if(data.discount.previousAuctionValue !== null){
                props.changeDiscountPerviousAuctionAmount(data.discount.previousAuctionValue.amount)
                props.setDataAddress(data.discount.previousAuctionValue.valueAddedTaxIncluded, 'CREATE_AUCTION_RAILWAY_DUTCH_STEP_VALUE_ADDED_TAX_INCLUDED')
            }
            props.discountAuc(data.discount.discount)
            props.changeDiscountId(data.discount.id)
            props.changeDiscountPercent(data.discount.discountPercent)
        }
    }
}

async function getAddressOrg(props, auction, type=1){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/organizations?limit=30&offset=0`
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            let tmpArr = json
            if(auction.relatedOrganizations !== null && auction.relatedOrganizations !== 'null'){
                //if(auction.relatedOrganizations.currentTenant !== null){
                if(auction.relatedOrganizations.currentTenant !== null){
                    let flag = false
                    for(let i = 0; i < tmpArr.length; i++){
                        if(tmpArr[i].id === auction.relatedOrganizations.currentTenant.id){
                            flag = true
                        }
                    }
                    if(flag === false){
                        let tmpObj = auction.relatedOrganizations.currentTenant
                        tmpObj.name = tmpObj.contactPoint.name 
                        tmpArr.push(tmpObj)
                        //props.setDataAddress(tmpArr, 'GET_ADDRESS_NEW_BOOKS')
                    }else{
                        //props.setDataAddress(json, 'GET_ADDRESS_NEW_BOOKS')
                    }
                }else{
                    //props.setDataAddress(json, 'GET_ADDRESS_NEW_BOOKS')
                }
                if(auction.relatedOrganizations.sellingEntity !== null){
                    let flag = false
                    for(let i = 0; i < tmpArr.length; i++){
                        if(tmpArr[i].id === auction.relatedOrganizations.sellingEntity.id){
                            flag = true
                        }
                    }
                    if(flag === false){
                        let tmpObj = auction.relatedOrganizations.sellingEntity
                        tmpObj.name = tmpObj.contactPoint.name 
                        tmpArr.push(tmpObj)
                        //props.setDataAddress(tmpArr, 'GET_ADDRESS_NEW_BOOKS')
                    }else{
                        //props.setDataAddress(json, 'GET_ADDRESS_NEW_BOOKS')
                    }
                }else{
                    //props.setDataAddress(json, 'GET_ADDRESS_NEW_BOOKS')
                }
                if(auction.relatedOrganizations.propertyOwner !== null){
                    let flag = false
                    for(let i = 0; i < tmpArr.length; i++){
                        if(tmpArr[i].id === auction.relatedOrganizations.propertyOwner.id){
                            flag = true
                        }
                    }
                    if(flag === false){
                        let tmpObj = auction.relatedOrganizations.propertyOwner
                        tmpObj.name = tmpObj.contactPoint.name 
                        tmpArr.push(tmpObj)
                        //props.setDataAddress(tmpArr, 'GET_ADDRESS_NEW_BOOKS')
                    }else{
                        //props.setDataAddress(json, 'GET_ADDRESS_NEW_BOOKS')
                    }
                }else{
                    //props.setDataAddress(json, 'GET_ADDRESS_NEW_BOOKS')
                }
            }
            props.setDataAddress(tmpArr, 'GET_ADDRESS_NEW_BOOKS')
        }
    }
    if(type === 3){
        await props.setDataAddress(null, 'CLONE_AUCTIONS_STATUS_CHANGE')
    }
    //if(props.auctionsSwitchWindow !== type) props.changeAuctionsSwitchWindow(type)
    props.changeLoader(false)
}

//Публікація аукціону
function buttonPublishAuctionNew(props, i, data, setPopUpCadastr){
    let tmp = null
    if(i.status === null){
        switch(i.sellingMethod){
            case "railwayCargo-english":
            case "railwayCargo-english-fast":
            case "railwayCargo-english-fast-manual":
            case "railwayCargo-english-initial-auction":
            case "railwayCargo-english-initial-qualification":
            case "railwayCargo-dutch":
            case "railwayCargo-dutch-fast":
            case "railwayCargo-dutch-fast-manual":
            case "railwayCargo-dutch-initial-auction":
            case "railwayCargo-dutch-initial-qualification":
            case "railwayCargo-dutch-fast-auction-manual-qualification":
            case "railwayCargo-dutch-initial-auction-manual":
                if(checkItemsPublish(i) === true){
                    tmp = <li onClick={()=>{
                        data()
                        sendAuction(props, i.id)
                    }}>Публікація аукціону</li>
                    }
                break;
            case "timber-english":
            case "timber-english-fast":
            case "timber-english-fast-manual":
            case "timber-english-initial-auction":
            case "timber-english-initial-qualification":
            case "timber-english-initial-qualification-fast":
                if(checkItemsPublish(i) === true && checkDocumentsPublish(i) === true){
                    tmp = <li onClick={()=>{
                        data()
                        sendAuction(props, i.id)
                    }}>Публікація аукціону</li>
                }
                break;
            case "subsoil-english":
            case "subsoil-english-fast":
            case "subsoil-english-fast-manual":
            case "subsoil-english-initial-auction":
            case "subsoil-english-initial-qualification":
            case "subsoil-dutch":
            case "subsoil-dutch-fast":
            case "subsoil-dutch-fast-manual":
            case "subsoil-dutch-initial-auction":
            case "subsoil-dutch-initial-qualification":
            case "subsoil-dutch-initial-auction-manual":
            case "subsoil-english-fast-auction-manual-qualification":
                if(checkItemsPublish(i) === true/* && checkDocumentsPublish(i) === true*/){
                    tmp = <li onClick={()=>{
                        data()
                        sendAuction(props, i.id)
                    }}>Публікація аукціону</li>
                }
                break;
            // case "legitimatePropertyLease-english":
            // case "legitimatePropertyLease-english-fast":
            // case "legitimatePropertyLease-english-fast-manual":
            // case "legitimatePropertyLease-english-fast-auction-manual-qualification":
            // case "legitimatePropertyLease-english-initial-auction":
            // case "legitimatePropertyLease-english-initial-auction-manual":
            // case "legitimatePropertyLease-english-initial-qualification":
            // case "legitimatePropertyLease-english-initial-qualification-prod":
            // case "legitimatePropertyLease-dutch":
            // case "legitimatePropertyLease-dutch-fast":
            // case "legitimatePropertyLease-dutch-fast-manual":
            // case "legitimatePropertyLease-dutch-fast-auction-manual-qualification":
            // case "legitimatePropertyLease-dutch-initial-auction":
            // case "legitimatePropertyLease-dutch-initial-auction-manual":
            // case "legitimatePropertyLease-dutch-initial-qualification-prod":
            // case "legitimatePropertyLease-priorityEnglish":
            // case "legitimatePropertyLease-priorityEnglish-fast":
            // case "legitimatePropertyLease-priorityEnglish-fast-manual":
            // case "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification":
            // case "legitimatePropertyLease-priorityEnglish-initial-auction":
            // case "legitimatePropertyLease-priorityEnglish-initial-auction-manual":
            // case "legitimatePropertyLease-priorityEnglish-initial-qualification":
            // case "legitimatePropertyLease-priorityEnglish-initial-qualification-prod":
            //     if(checkItemsPublish(i) === true && checkDocumentsPublish(i) === true){
            //         tmp = <li onClick={()=>{
            //             data()
            //             verificationOfMandatoryData(props, i.id, i.registryId)
            //             //putchZUReestr(props, i.id, i.registryId)
            //         }}>Публікація аукціону</li>
            //     }
            //     break;
            default:
                switch(true){
                    case isBSE(i.sellingMethod):
                    case isBSD(i.sellingMethod):
                        if(isTestFish(i) === true){
                            if(checkItemsPublish(i) === true){
                                return tmp = <li onClick={()=>{
                                    data()
                                    sendAuctionMonga(props, i.id)
                                }}>Публікація аукціону</li>
                            }
                        }else{
                            return null
                        }
                        break;
                    case isALE(i.sellingMethod):
                    case isCSE(i.sellingMethod):
                    case isCSD(i.sellingMethod):
                    case isBRE(i.sellingMethod):
                    case isBRD(i.sellingMethod):
                    case isBRD(i.sellingMethod):
                    case isBRW(i.sellingMethod):
                    case isRLE(i.sellingMethod):
                    case isRLD(i.sellingMethod):
                    case isCLE(i.sellingMethod):
                    case isCLD(i.sellingMethod):
                    case isNLE(i.sellingMethod):
                    case isNLD(i.sellingMethod):
                    case isAPE(i.sellingMethod):
                    case isAPD(i.sellingMethod):
                    case isLLE(i.sellingMethod):
                    case isLLD(i.sellingMethod):
                    case isLLP(i.sellingMethod):
                    // case isLRE(i.sellingMethod):
                    // case isLSE(i.sellingMethod):
                    // case isLSP(i.sellingMethod):
                        if(checkItemsPublish(i) === true){
                            return tmp = <li onClick={()=>{
                                data()
                                sendAuctionMonga(props, i.id)
                            }}>Публікація аукціону</li>
                        }
                        break;
                    case isLAE(i.sellingMethod):
                    case isLAP(i.sellingMethod):
                        if(checkItemsPublish(i) === true){
                            return tmp = <li onClick={()=>{
                                data()
                                testCadastrMonga(props, i, setPopUpCadastr)
                            }}>Публікація аукціону</li>
                        }
                        break;
                    case isLAW(i.sellingMethod):
                        if(checkItemsPublish(i) === true){
                            return tmp = <li onClick={()=>{
                                data()
                                testCadastrMonga(props, i, setPopUpCadastr)
                            }}>Публікація аукціону</li>
                        }
                        break;
                }
                if(checkItemsPublish(i) === true){
                    tmp = <li onClick={()=>{
                        data()
                        if(isLRE(i.sellingMethod) || isLSE(i.sellingMethod) || isLSP(i.sellingMethod)){
                            testCadastr(props, i, setPopUpCadastr)
                        }else{
                            //sendAuction(props, i.id)
                            sendAuctionMonga(props, i.id)
                        }
                    }}>Публікація аукціону</li>
                }
                break;
        }
    }
    return tmp
}

function isTestFish(data){
    if(data.hasOwnProperty("items")){
        if(data.items !== null){
            if(data.items.length > 0){
                if(data.items[0].hasOwnProperty("classification")){
                    if(data.items[0].classification !== null){
                        if(data.items[0].classification.hasOwnProperty("id")){
                            if(data.items[0].classification.id === "77700000-7"){
                                if(data.hasOwnProperty("auctionPeriod")){
                                    if(data.auctionPeriod !== null){
                                        if(data.auctionPeriod.hasOwnProperty("startDate")){
                                            if(data.auctionPeriod.startDate !== null){
                                                let dateStartAuc = new Date(data.auctionPeriod.startDate)
                                                let dateNow = Date.now()
                                                dateNow = dateNow + (86400000 * 9)
                                                let tmpStart = dateStartAuc.getTime()
                                                if(tmpStart > dateNow) return true
                                            }
                                        }
                                    }
                                }
                            }else{
                                return true
                            }
                        }
                    }
                }
            }
        }
    }
    return false
}

async function verificationOfMandatoryData(props, id){
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${id}`;
    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    // .then(
    //     async function(response){
    //         // let str = ''
    //         // if(response.status === 200){
    //         //     const json = await response.json()
    //         //     // if(json.sellingMethod === null){
    //         //     //     str += `Тип процедури, `
    //         //     // }
    //         //     if(
    //         //         json.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
    //         //         json.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
    //         //         json.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
    //         //         json.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
    //         //         json.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
    //         //         json.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
    //         //         json.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
    //         //         json.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    //         //     ){
    //         //         if(json.currentTenantGuaranteeFee === null){
    //         //             str += `Гарантійний внесок чинного орендаря, `
    //         //         }else{
    //         //             if(json.currentTenantGuaranteeFee.amount === null){
    //         //                 str += `Гарантійний внесок чинного орендаря, `
    //         //             }
    //         //         }
    //         //     }
    //         //     // if(json.startDate === null){
    //         //     //     str += `Початок аукціону, `
    //         //     // }
    //         //     // if(json.registryId === null){
    //         //     //     str += `Ідентифікатор об'єкта, `
    //         //     // }
    //         //     // if(json.title === null){
    //         //     //     str += `Назва аукціону, `
    //         //     // }
    //         //     // if(json.description === null){
    //         //     //     str += `Опис аукціону, `
    //         //     // }
    //         //     if(
    //         //         json.sellingMethod === "legitimatePropertyLease-english" ||
    //         //         json.sellingMethod === "legitimatePropertyLease-english-fast" ||
    //         //         json.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
    //         //         json.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
    //         //         json.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
    //         //         json.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
    //         //         json.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
    //         //         json.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
    //         //         json.sellingMethod === "legitimatePropertyLease-dutch" ||
    //         //         json.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
    //         //         json.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
    //         //         json.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
    //         //         json.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
    //         //         json.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
    //         //         json.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod"
    //         //     ){
    //         //         if(
    //         //             json.relatedOrganizations === null ||
    //         //             json.relatedOrganizations.propertyOwner === null ||
    //         //             json.relatedOrganizations.sellingEntity === null ||
    //         //             json.relatedOrganizations.ownershipType === null
    //         //         ){
    //         //             str += `Організації, задіяні в керуванні об'єктом, `
    //         //         }
    //         //     }else if(
    //         //         json.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
    //         //         json.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
    //         //         json.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
    //         //         json.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
    //         //         json.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
    //         //         json.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
    //         //         json.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
    //         //         json.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    //         //     ){
    //         //         if(
    //         //             json.relatedOrganizations === null ||
    //         //             json.relatedOrganizations.propertyOwner === null ||
    //         //             json.relatedOrganizations.sellingEntity === null ||
    //         //             json.relatedOrganizations.ownershipType === null ||
    //         //             json.relatedOrganizations.currentTenant === null
    //         //         ){
    //         //             str += `Організації, задіяні в керуванні об'єктом, `
    //         //         }
    //         //         //if(json.relatedOrganizations.currentTenants !== null && json.relatedOrganizations.currentTenants.length > 0){
    //         //             if(json.relatedOrganizations.currentTenant.hasOwnProperty("currentContractTime")){
    //         //                 if(json.relatedOrganizations.currentTenant.currentContractTime === null){
    //         //                     str += `Дата укладення та закінчення договору оренди, `
    //         //                 }else{
    //         //                     if(json.relatedOrganizations.currentTenant.currentContractTime.startDate === null){
    //         //                         str += `Дата укладення та закінчення договору оренди - період з, `
    //         //                     }
    //         //                     if(json.relatedOrganizations.currentTenant.currentContractTime.endDate === null){
    //         //                         str += `Дата укладення та закінчення договору оренди - період по, `
    //         //                     }
    //         //                     if(json.relatedOrganizations.currentTenant.currentContractTime.startDate !== null && json.relatedOrganizations.currentTenant.currentContractTime.endDate !== null){
    //         //                         let tmpStart = new Date(json.relatedOrganizations.currentTenant.currentContractTime.startDate)
    //         //                         let tmpEnd = new Date(json.relatedOrganizations.currentTenant.currentContractTime.endDate)
    //         //                         if(tmpStart.getTime() > tmpEnd.getTime()){
    //         //                             str += `Дата укладення та закінчення договору оренди - дата закінчення не може бути раніше за дату укладання, `
    //         //                         }
    //         //                     }
    //         //                 }
    //         //             }else{
    //         //                 str += `Відсутні данні поля currentContractTime`
    //         //             }
    //         //         //}
    //         //     }
    //         //     if(json.leaseRules === null){
    //         //         str += `Правила та умови передачі об'єкта в оренду, `
    //         //     }else{
    //         //         if(json.leaseRules.intendedUseRestrictionMethod === null){
    //         //             str += `Спосіб обмеження цільового призначення об'єкта, `
    //         //         }
    //         //     }
    //         //     // if(
    //         //     //     json.bankAccountsGroups === null ||
    //         //     //     json.bankAccountsGroups.length === 0
    //         //     // ){
    //         //     //     str += `Банківські рахунки, `
    //         //     // }
    //         //     // if(json.value === null){
    //         //     //     str += `Сума стартової орендної плати, `
    //         //     // }else{
    //         //     //     if(json.value.amount === null){
    //         //     //         str += `Сума стартової орендної плати, `
    //         //     //     }
    //         //     // }
    //         //     if(json.leaseDuration === null){
    //         //         str += `Строк оренди, `
    //         //     }
    //         //     // if(json.guarantee === null){
    //         //     //     str += `Гарантійний внесок, `
    //         //     // }else{
    //         //     //     if(json.guarantee.amount === null){
    //         //     //         str += `Гарантійний внесок, `
    //         //     //     }
    //         //     // }
    //         //     // if(json.registrationFee === null){
    //         //     //     str += `Реєстраційний внесок, `
    //         //     // }else{
    //         //     //     if(json.registrationFee.amount === null){
    //         //     //         str += `Реєстраційний внесок, `
    //         //     //     }
    //         //     // }
    //         //     // if(json.minimalStep === null){
    //         //     //     str += `Розмір мінімального кроку підвищення стартової орендної плати під час аукціону, `
    //         //     // }else{
    //         //     //     if(json.minimalStep.amount === null){
    //         //     //         str += `Розмір мінімального кроку підвищення стартової орендної плати під час аукціону, `
    //         //     //     }
    //         //     // }
    //         //     // if(json.tenderAttempts === null){
    //         //     //     str += `Порядковий номер аукціону, `
    //         //     // }
    //         //     // if(json.items === null && json.items.length > 0){
    //         //     //     str += `Склад лота, `
    //         //     // }else{
    //         //     //     for(let ity = 0; ity < json.items.length; ity++){
    //         //     //         if(json.items[ity].address === null){
    //         //     //             str += `Місцезнаходження, `
    //         //     //         }
    //         //     //         if(json.items[ity].location === null){
    //         //     //             str += `Координати, `
    //         //     //         }else{
    //         //     //             if(json.items[ity].location.latitude === null){
    //         //     //                 str += `Широта, `
    //         //     //             }
    //         //     //             if(json.items[ity].location.longitude === null){
    //         //     //                 str += `Довгота, `
    //         //     //             }
    //         //     //         }
    //         //     //         if(json.items[ity].registrationDetails === null){
    //         //     //             str += `Інформація щодо реєстрації, `
    //         //     //         }else{
    //         //     //             if(
    //         //     //                 json.items[ity].registrationDetails.registrationStatus === 'notRegistered' ||
    //         //     //                 json.items[ity].registrationDetails.registrationStatus === 'registrationIsNotRequired'
    //         //     //             ){
    //         //     //             }else{
    //         //     //                 if(json.items[ity].registrationDetails.registrationStatus === null){
    //         //     //                     str += `Стан державної реєстрації об'єкту, `
    //         //     //                 }
    //         //     //                 if(json.items[ity].registrationDetails.registrationID === null){
    //         //     //                     str += `Якщо у полі "Стан державної реєстрації об'єкту" обрано інше значення від "Не зареєстровано", поле "Номер запису" повинно бути заповнено, `
    //         //     //                 }
    //         //     //                 if(json.items[ity].registrationDetails.registrationDate === null){
    //         //     //                     str += `Якщо у полі "Стан державної реєстрації об'єкту" обрано інше значення від "Не зареєстровано", поле "Дата реєстрації" повинно бути заповнено, `
    //         //     //                 }
    //         //     //             }
    //         //     //         }
    //         //     //         if(json.items[ity].itemType === null){
    //         //     //             str += `Тип об'єкта майна, `
    //         //     //         }
    //         //     //         if(json.items[ity].quantity === null){
    //         //     //             str += `Кількість одиниць, `
    //         //     //         }
    //         //     //         if(json.items[ity].unit === null){
    //         //     //             str += `Одиниці виміру, `
    //         //     //         }else{
    //         //     //             if(json.items[ity].unit.code === null){
    //         //     //                 str += `Код одиниці виміру, `
    //         //     //             }                        
    //         //     //         }
    //         //     //         if(json.items[ity].itemType === 'jointPropertyComplex'){
    //         //     //             if(json.items[ity].jointPropertyComplexProps === null){
    //         //     //                 str += `Технічні характеристики, єдиний майновий комплекс, `
    //         //     //             }
    //         //     //         }else if(json.items[ity].itemType === 'vehicle'){
    //         //     //             if(json.items[ity].vehicleProps === null){
    //         //     //                 str += `Технічні характеристики, транспортний засіб", `
    //         //     //             }
    //         //     //         }else if(json.items[ity].itemType === 'realEstate'){
    //         //     //             if(json.items[ity].realEstateProps === null){
    //         //     //                 str += `Технічні характеристики, нерухомість", `
    //         //     //             }else{
    //         //     //                 if(json.items[ity].realEstateProps.totalObjectArea === null){
    //         //     //                     str += `Загальна площа об'єкта в будівлі, кв.м.", `
    //         //     //                 }
    //         //     //             }
    //         //     //         }
    //         //     //     }
    //         //     // }
                
    //         //     if(str !== ''){
    //         //         let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: str})
    //         //         props.changeLoader(false)
    //         //         props.setUniversalError(tmp)
    //         //     }
    //         // }
    //         return str
    //     }
    // )
    .then(
        async function(response){
            //console.log(response)
            //if(response === ''){
                putchZUReestr(props, id)
            //}
        }
    )
    .catch(console.error)
}

function checkItemsPublish(i){
    return true
    if(i.hasOwnProperty('items')){
        if(i.items !== null){
            if(i.items.length > 0){
                return true
            }
        }
    }
    return false
}

function checkDocumentsPublish(i){
    if(i.hasOwnProperty('documents') !== true){
        return false
    }
    let tmpTechnicalSpecifications = false
    let tmpContractProforma = false
    let tmpX_auctionDocumentationPackage = false
    let tmpX_submissionBidProcedure = false
    let tmpX_nonSanctionedRequirements = false
    let tmpX_brokerPaymentConditions = false
    let tmpX_qualificationSigningTerms = false
    let tmpEvaluationCriteria = false
    if(
        i.sellingMethod === "timber-english" ||
        i.sellingMethod === "timber-english-fast" ||
        i.sellingMethod === "timber-english-fast-manual" ||
        i.sellingMethod === "timber-english-initial-auction" ||
        i.sellingMethod === "timber-english-initial-qualification" ||
        i.sellingMethod === "timber-english-initial-qualification-fast"
    ){
        if(i.documents !== null){
            for(let tr = 0; tr < i.documents.length; tr++){
                if(i.documents[tr].documentType === "technicalSpecifications") tmpTechnicalSpecifications = true
                //if(i.documents[tr].documentType === "contractProforma") tmpContractProforma = true
                tmpContractProforma = true
            }
        }
    }else if(
        i.sellingMethod === "subsoil-english" ||i.sellingMethod === "subsoil-english-fast" ||
        i.sellingMethod === "subsoil-english-fast-manual" ||
        i.sellingMethod === "subsoil-english-initial-auction" ||
        i.sellingMethod === "subsoil-english-initial-qualification" ||
        i.sellingMethod === "subsoil-dutch" ||
        i.sellingMethod === "subsoil-dutch-fast" ||
        i.sellingMethod === "subsoil-dutch-fast-manual" ||
        i.sellingMethod === "subsoil-dutch-initial-auction" ||
        i.sellingMethod === "subsoil-dutch-initial-qualification" ||
        i.sellingMethod === "subsoil-dutch-initial-auction-manual" ||
        i.sellingMethod === "subsoil-english-fast-auction-manual-qualification"
    ){
        if(i.documents !== null){
            for(let tr = 0; tr < i.documents.length; tr++){
                if(i.documents[tr].documentType === "x_auctionDocumentationPackage") tmpX_auctionDocumentationPackage = true
                if(i.documents[tr].documentType === "x_submissionBidProcedure") tmpX_submissionBidProcedure = true
                if(i.documents[tr].documentType === "x_nonSanctionedRequirements") tmpX_nonSanctionedRequirements = true
                if(i.documents[tr].documentType === "x_brokerPaymentConditions") tmpX_brokerPaymentConditions = true
                if(i.documents[tr].documentType === "x_qualificationSigningTerms") tmpX_qualificationSigningTerms = true
                if(i.documents[tr].documentType === "evaluationCriteria") tmpEvaluationCriteria = true
            }
        }
        if(
            tmpX_auctionDocumentationPackage === true &&
            tmpX_submissionBidProcedure === true &&
            tmpX_nonSanctionedRequirements === true &&
            tmpX_brokerPaymentConditions === true &&
            tmpX_qualificationSigningTerms === true &&
            tmpEvaluationCriteria === true
        ){
            return true
        }else{
            return false
        }
    }else if(
        i.sellingMethod === "legitimatePropertyLease-english" ||
        i.sellingMethod === "legitimatePropertyLease-english-fast" ||
        i.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        i.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        i.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        i.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        i.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        i.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        i.sellingMethod === "legitimatePropertyLease-dutch" ||
        i.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        i.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        i.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        i.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        i.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        i.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        i.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        let tmpContractProformaZU = false
        let tmpIllustrationZU = false
        if(i.documents !== null){
            for(let tr = 0; tr < i.documents.length; tr++){
                if(i.documents[tr].documentType === "contractProforma") tmpContractProformaZU = true
                if(i.documents[tr].documentType === "illustration") tmpIllustrationZU = true
            }
        }
        if(
            tmpContractProformaZU === true &&
            tmpIllustrationZU === true
        ){
            return true
        }else{
            return false
        }
    }


	if(tmpTechnicalSpecifications === true && tmpContractProforma === true){
		return true
	}else{
		return false
	}
}

async function testCadastr(props, data, setPopUpCadastr){
    let url = ``
    let cadastralNumber = null
    cadastralNumber = (isLRE(data.sellingMethod) || isLSE(data.sellingMethod) || isLSP(data.sellingMethod)) ?
        data?.items[0]?.itemProps?.cadastralNumber :
        data?.items[0]?.landProps?.cadastralNumber;
    if (cadastralNumber == null ) {
        let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: 'Кадастровий номер вказано невірно'})
        props.setUniversalError(tmp)
        return
    };

    url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/check/auction/byCadastralNumber/${cadastralNumber}`;
    const response1 = await fetch(url, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .catch(console.error)
    if(response1 !== null){
        if(response1 === undefined || response1 === 'undefined'){
            props.changeLoader(false)
            let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: ''})
            props.setUniversalError(tmp)
        }else{
            const json1 = await response1.json()
            if(json1.hasOwnProperty('message') === true){
                let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: JSON.stringify(json1)})
                props.changeLoader(false)
                props.setUniversalError(tmp)
            }else{
                //await setTimeout(() => syncAucMonga(props), 2000)
                if(json1.length === 0){
                    //sendAuction(props, data.id)
                    sendAuctionMonga(props, data.id)
                }else{
                    setPopUpCadastr(data)
                }
            }
        }
    }
}

async function testCadastrMonga(props, data, setPopUpCadastr){
    if(data.items !== null) {
        if(data.items.length > 0) {
            if(data.items[0].itemProps !== null) {
                if(data.items[0].itemProps.cadastralNumber !== null) {
                    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/check/auction/byCadastralNumber/${data.items[0].itemProps.cadastralNumber}`;
                    const response1 = await fetch(url, {
                        method: "POST",
                        body: JSON.stringify({
                            excludeCurrentUser: true,
                            //sellingMethod: data.sellingMethod
                        }),
                        headers: {
                            'Authorization': props.token.access_token,
                            'Content-Type': 'application/json'
                        }
                    })
                    .catch(console.error)
                    if(response1 !== null){
                        if(response1 === undefined || response1 === 'undefined'){
                            props.changeLoader(false)
                            let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: ''})
                            props.setUniversalError(tmp)
                        }else{
                            const json1 = await response1.json()
                            if(json1.hasOwnProperty('message') === true){
                                let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: JSON.stringify(json1)})
                                props.changeLoader(false)
                                props.setUniversalError(tmp)
                            }else{
                                //await setTimeout(() => syncAucMonga(props), 2000)
                                if(json1.length === 0){
                                    sendAuctionMonga(props, data.id)
                                }else{
                                    setPopUpCadastr(data)
                                }
                            }
                        }
                    }
                }else{
                    let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: 'Не не заповнено поле кадастрового номеру'})
                    props.setUniversalError(tmp)
                }
            }else{
                let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: 'Не не заповнено поле кадастрового номеру'})
                props.setUniversalError(tmp)
            }
        }else{
            let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: 'Не створений лот'})
            props.setUniversalError(tmp)
        }
    }else{
        let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: 'Не створений лот'})
        props.setUniversalError(tmp)
    }
}

async function sendAuction(props, id){
    props.changeLoader(true)
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${id}/send`;
    const response1 = await fetch(url, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .then(
        async function(response){
            if(response.status === 400 || response.status === '400'){
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    //let tmpText = JSON.stringify(json)
                    // if(tmpText === `{"message":{"documents":"document with documentType=x_itemPlan is required"}}`){
                    //     tmpText = 'Необхідно додати документ з типом "Поверховий план об\'єкта або план поверха"'
                    // }
                    
                    //let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: tmpText})
                    let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: JSON.stringify(json)})
                    props.changeLoader(false)
                    props.setUniversalError(tmp)
                    return null
                }
            }
            if(response.status === 401){
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    //let tmpText = JSON.stringify(json)
                    // if(tmpText === `{"message":{"documents":"document with documentType=x_itemPlan is required"}}`){
                    //     tmpText = 'Необхідно додати документ з типом "Поверховий план об\'єкта або план поверха"'
                    // }
                    //let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: tmpText})
                    let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: JSON.stringify(json)})
                    props.changeLoader(false)
                    props.setUniversalError(tmp)
                    return null
                }
            }
            if(response.status === 403){
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: JSON.stringify(json)})
                    props.changeLoader(false)
                    props.setUniversalError(tmp)
                    return null
                }
            }
            if(response.status === 404){
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: JSON.stringify(json)})
                    props.changeLoader(false)
                    props.setUniversalError(tmp)
                    return null
                }
            }
            if(response.status === 500){
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: JSON.stringify(json)})
                    props.changeLoader(false)
                    props.setUniversalError(tmp)
                    return null
                }
            }
            if(response.status === 501){
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: JSON.stringify(json)})
                    props.changeLoader(false)
                    props.setUniversalError(tmp)
                    return null
                }
            }
            if(response.status === 502){
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: JSON.stringify(json)})
                    props.changeLoader(false)
                    props.setUniversalError(tmp)
                    return null
                }
            }
            if(response.status === 503){
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: JSON.stringify(json)})
                    props.changeLoader(false)
                    props.setUniversalError(tmp)
                    return null
                }
            }
            if(response.status === 504){
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: JSON.stringify(json)})
                    props.changeLoader(false)
                    props.setUniversalError(tmp)
                    return null
                }
            }
            if(response.status === 200){
                return response
            }
        }
    )
    .catch(console.error)
    if(response1 !== null){
    /*** */
        if(response1 === undefined || response1 === 'undefined'){
            props.changeLoader(false)
            let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: ''})
            props.setUniversalError(tmp)
        }else{
            const json1 = await response1.json()
            if(json1.hasOwnProperty('message') === true){
                let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: JSON.stringify(json1)})
                props.changeLoader(false)
                props.setUniversalError(tmp)
            }else{
                await setTimeout(() => syncAuc(props, id), 2000)
                
            }
        }
    }
}

async function sendAuctionMonga(props, id){
    props.changeLoader(true)
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-drafts/${id}/send`;
    const response1 = await fetch(url, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .then(
        async function(response){
            if(response.status === 400 || response.status === '400'){
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    let tmpText = JSON.stringify(json)
                    //let tmpText = json
                    if(tmpText === `{"message":{"documents":"document with documentType=x_itemPlan is required"}}`){
                        tmpText = 'Необхідно додати документ з типом "Поверховий план об\'єкта або план поверха"'
                    }
                    let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: tmpText, sellingMethod: props.i.sellingMethod})
                    props.changeLoader(false)
                    props.setUniversalError(tmp)
                    return null
                }else{
                    let tmpText = 'Помилка без сповіщення'
                    let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: tmpText, sellingMethod: props.i.sellingMethod})
                    props.changeLoader(false)
                    props.setUniversalError(tmp)
                    return null
                }
            }
            if(response.status === 401){
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    let tmpText = JSON.stringify(json)
                    if(tmpText === `{"message":{"documents":"document with documentType=x_itemPlan is required"}}`){
                        tmpText = 'Необхідно додати документ з типом "Поверховий план об\'єкта або план поверха"'
                    }
                    let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: tmpText})
                    props.changeLoader(false)
                    props.setUniversalError(tmp)
                    return null
                }
            }
            if(response.status === 403){
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: JSON.stringify(json)})
                    props.changeLoader(false)
                    props.setUniversalError(tmp)
                    return null
                }
            }
            if(response.status === 404){
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: JSON.stringify(json)})
                    props.changeLoader(false)
                    props.setUniversalError(tmp)
                    return null
                }
            }
            if(response.status === 500){
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: JSON.stringify(json)})
                    props.changeLoader(false)
                    props.setUniversalError(tmp)
                    return null
                }
            }
            if(response.status === 501){
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: JSON.stringify(json)})
                    props.changeLoader(false)
                    props.setUniversalError(tmp)
                    return null
                }
            }
            if(response.status === 502){
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: JSON.stringify(json)})
                    props.changeLoader(false)
                    props.setUniversalError(tmp)
                    return null
                }
            }
            if(response.status === 503){
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: JSON.stringify(json)})
                    props.changeLoader(false)
                    props.setUniversalError(tmp)
                    return null
                }
            }
            if(response.status === 504){
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: JSON.stringify(json)})
                    props.changeLoader(false)
                    props.setUniversalError(tmp)
                    return null
                }
            }
            if(response.status === 200){
                return response
            }
        }
    )
    .catch(console.error)
    if(response1 !== null){
    /*** */
        if(response1 === undefined || response1 === 'undefined'){
            props.changeLoader(false)
            let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: ''})
            props.setUniversalError(tmp)
        }else{
            const json1 = await response1.json()
            if(json1.hasOwnProperty('message') === true){
                let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: JSON.stringify(json1)})
                props.changeLoader(false)
                props.setUniversalError(tmp)
            }else{
                await setTimeout(() => syncAucMonga(props), 2000)
                
            }
        }
    }
}

async function syncAuc(props, id){
    const url2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${id}/sync`; 
    const response2 = await fetch(url2, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response2 === undefined || response2 === 'undefined'){
        props.setPopUpAuctionMyNull()
        let data = {}
        data.domen = process.env.REACT_APP_END_POINT_BD
        data.version = 'v1'
        data.local = props.langFlag
        if(props.myCabinetPage === 0){
            data.params = `?offset=${props.myCabinetPage}`
        }else{
            data.params = `?offset=${props.myCabinetPage}0` 
        }
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        //props.auctionsGetMy(data, header)
        props.changeLoader(false)
    }else{
        props.setPopUpAuctionMyNull()
        let data = {}
        data.domen = process.env.REACT_APP_END_POINT_BD
        data.version = 'v1'
        data.local = props.langFlag
        if(props.myCabinetPage === 0){
            data.params = `?offset=${props.myCabinetPage}`
        }else{
            data.params = `?offset=${props.myCabinetPage}0` 
        }
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        //getAucionsList(props)
        props.changeLoader(false)
    }
    getAucionsList(props)
    props.changeLoader(false)
}

async function copyTo(props, id, sellingMethod){
    const url2 = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${id}/copy_to/${sellingMethod}`; 
    const response = await fetch(url2, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response !== null){
    /*** */
        if(response === undefined || response === 'undefined'){
            props.changeLoader(false)
            let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: ''})
            props.setUniversalError(tmp)
        }else{
            const json1 = await response.json()
            if(json1.hasOwnProperty('message') === true){
                let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: JSON.stringify(json1)})
                props.changeLoader(false)
                props.setUniversalError(tmp)
            }else{
                await setTimeout(() => syncAucMonga(props), 2000)
                
            }
        }
    }
}

async function syncAucMonga(props){
    getAucionsList(props)
    props.changeLoader(false)
}

async function putchZUReestr(props, idAuc, idZu){
    props.changeLoader(true)
    await sendAuction(props, idAuc)
}

//Видалити аукціон
function buttonsСansallAndDeleteAuction(props, data){
    let tmp = null;
    if(props.i.status !== 'complete' && props.i.status !== 'cancelled' && props.i.status !== 'unsuccessful'){
        tmp = <li
            onClick={
                ()=>{
                    data()
                    props.setPopUpDelAuction(props.i)
                }
            }
            title="Видалення аукціону"
        >Видалити/скасувати аукціон</li>
    }
    return tmp
}

async function deleteMongoDrafts(props, id){
    props.changeLoader(true)
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-drafts/${id}`;
    const response1 = await fetch(url, {
        method: "DELETE",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
        props.changeLoader(false)
    }else{
        const json1 = await response1.json()
        if(json1.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при видаленні', message: JSON.stringify(json1)})
	        props.setUniversalError(tmp)
        }else{
            getAucionsList(props)
        }
    }
    await props.changeLoader(false)
}

async function getAucionsList(props){
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-drafts/my`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціонів', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            const tmp = await createList(json)
            if(tmp.length > 0){
                props.setDataAddress(tmp, 'AUCTIONS_GET_MY')
            }else{
                props.setDataAddress(null, 'AUCTIONS_GET_MY')
            }

        }
    }
    props.changeLoader(false)
}

function createList(json){
    let tmp = []
    if(json.length > 0){
        for(let i = 0; i < json.length; i++){
            if(json[i].publicAuction !== null){
                tmp[i] = json[i].publicAuction
                tmp[i].dataAuction = json[i]
            }else if(json[i].oldAuctionDraft !== null){
                tmp[i] = json[i].oldAuctionDraft
                tmp[i].dataAuction = json[i]
            }else if(json[i].auctionDraft !== null){
                tmp[i] = json[i].auctionDraft
                tmp[i].dataAuction = json[i]
            }
        }
    }
    return tmp
}
//Створення копії аукціону
function buttonsСopyAuction(props, data){
    if(
        props.i.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.i.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.i.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.i.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.i.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.i.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod"
    ){
        return (
            <li
                onClick={
                    ()=>{
                        data()
                        //props.changeAuctionsSwitchWindow(3)
                        if(props.i.dataAuction.mysqlDraftId !== null){
                            getCopyAuc(props, props.i.dataAuction.mysqlDraftId)
                        }else{
                            getCopyAuc(props, props.auctionsMy[props.count].id)
                        }
                        props.getMyAuction(props.auctionsMy[props.count])
                        createArrayBankAccounts(props, props.i)
                        props.changeCreateAuctionTenderAttempts(props.i.tenderAttempts)
                        if(props.i.discount !== null){
                            //props.changeDiscountPercent(i.discount.discountPercent)
                            //props.changeDiscountPerviousAuctionCurrency(i.discount.previousAuctionValue.currency)
                            //props.changeDiscountPerviousAuctionAmount(i.discount.previousAuctionValue.amount)
                            //props.changeDiscountPerviousAuctionAmount(i.value.amount)
                        }


                        props.changeDiscountPerviousAuctionId(props.i.auctionId)
                        //props.changeDiscountPerviousAuctionId(i.procedureId)
                        
                        
                        props.setEditAuctionFromId(null)
                        getAucionFromId(props, props.i.id, 3)
                        //getAddressOrg(props)
                        if(
                            props.i.sellingMethod === "legitimatePropertyLease-english" ||
                            props.i.sellingMethod === "legitimatePropertyLease-english-fast" ||
                            props.i.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                            props.i.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                            props.i.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                            props.i.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                            props.i.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                            props.i.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                            props.i.sellingMethod === "legitimatePropertyLease-dutch" ||
                            props.i.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                            props.i.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                            props.i.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                            props.i.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                            props.i.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                            props.i.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                            props.i.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                            props.i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                            props.i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                            props.i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                            props.i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                            props.i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                            props.i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                            props.i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
                        ){
                            props.setDataAddress(true, 'SET_BRIDGE_OPEN_POPUP')
                            props.setDataAddress(true, 'SET_BRIDGE_OPEN_BUTTON')
                        }
                    }
                }
            >
                Створення копії аукціону за методом покрокового зниження
            </li>
        )
    }else if(
        props.i.sellingMethod !== "legitimatePropertyLease-english" &&
        props.i.sellingMethod !== "legitimatePropertyLease-english-fast" &&
        props.i.sellingMethod !== "legitimatePropertyLease-english-fast-manual" &&
        props.i.sellingMethod !== "legitimatePropertyLease-english-fast-auction-manual-qualification" &&
        props.i.sellingMethod !== "legitimatePropertyLease-english-initial-auction" &&
        props.i.sellingMethod !== "legitimatePropertyLease-english-initial-auction-manual" &&
        props.i.sellingMethod !== "legitimatePropertyLease-english-initial-qualification" &&
        props.i.sellingMethod !== "legitimatePropertyLease-english-initial-qualification-prod"
    ){
        return (
            <li
                onClick={
                    ()=>{
                        data()
                        //props.changeAuctionsSwitchWindow(3)
                        if(props.i.dataAuction.mysqlDraftId !== null){
                            getCopyAuc(props, props.i.dataAuction.mysqlDraftId)
                        }else{
                            getCopyAuc(props, props.auctionsMy[props.count].id)
                        }
                        props.getMyAuction(props.auctionsMy[props.count])
                        createArrayBankAccounts(props, props.i)
                        props.changeCreateAuctionTenderAttempts(props.i.tenderAttempts)
                        if(props.i.discount !== null){
                            //props.changeDiscountPercent(i.discount.discountPercent)
                            //props.changeDiscountPerviousAuctionCurrency(i.discount.previousAuctionValue.currency)
                            //props.changeDiscountPerviousAuctionAmount(i.discount.previousAuctionValue.amount)
                            //props.changeDiscountPerviousAuctionAmount(i.value.amount)
                        }


                        props.changeDiscountPerviousAuctionId(props.i.auctionId)
                        //props.changeDiscountPerviousAuctionId(i.procedureId)
                        
                        
                        props.setEditAuctionFromId(null)
                        getAucionFromId(props, props.i.id, 3)
                        //getAddressOrg(props)
                        if(
                            props.i.sellingMethod === "legitimatePropertyLease-english" ||
                            props.i.sellingMethod === "legitimatePropertyLease-english-fast" ||
                            props.i.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                            props.i.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                            props.i.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                            props.i.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                            props.i.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                            props.i.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                            props.i.sellingMethod === "legitimatePropertyLease-dutch" ||
                            props.i.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                            props.i.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                            props.i.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                            props.i.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                            props.i.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                            props.i.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                            props.i.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                            props.i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                            props.i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                            props.i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                            props.i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                            props.i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                            props.i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                            props.i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
                        ){
                            props.setDataAddress(true, 'SET_BRIDGE_OPEN_POPUP')
                            props.setDataAddress(true, 'SET_BRIDGE_OPEN_BUTTON')
                        }
                    }
                }
            >
                Створення копії аукціону
            </li>
        )
    }
    return null
}

async function getCopyAuc(props, id){
    let endPoint = ``
    if(props.i.dataAuction.mysqlDraftId !== null){
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.i.dataAuction.mysqlDraftId}`
    }else{
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy[props.count].id}`
    }
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${id}`
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            props.setDataAddress(json, 'CREATE_AUCTIONS_ZU_ATTEMP')
        }
    }
}

function cloneZU(props, i){
    if(
        i.status === 'cancelled' ||
        i.status === 'unsuccessful' ||
        i.status === 'complete'
    ){
        if(
            i.sellingMethod === "legitimatePropertyLease-english" ||
            i.sellingMethod === "legitimatePropertyLease-english-fast" ||
            i.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
            i.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
            i.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
            i.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
            i.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
            i.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod"
        ){
            if(i.tenderAttempts === 1){
                return (
                    <>
                        <li
                            onClick={
                                ()=>{
                                    cloneAucNewMonga(props, props.i.id, props.i.auctionId, '?type=discount')
                                    //cloneAucNewMonga(props, props.i.id, props.i.auctionId)
                                }
                            }
                        >
                            Створити копію аукціону зі зниженням стартової ціни
                        </li>
                        {/* <li
                            onClick={
                                ()=>{
                                    cloneAucNewMonga(props, props.i.id, props.i.auctionId, '?type=discount')
                                    //cloneAucNewMonga(props, props.i.id, props.i.auctionId)
                                }
                            }
                        >
                            Створити аукціон за методом покрокового зниження
                        </li> */}
                    </>
                )
            }else if(i.tenderAttempts === 2){
                return (
                    <>
                        <li
                            onClick={
                                ()=>{
                                    cloneAucNewMonga(props, props.i.id, props.i.auctionId)
                                }
                            }
                        >
                            Створити копію аукціону зі зниженням стартової ціни
                        </li>
                        <li
                            onClick={
                                ()=>{
                                    //cloneAucNew(props, props.i.dataAuction.mysqlDraftId, '?type=discount')
                                    cloneAucNewMonga(props, props.i.id, props.i.auctionId, '?type=discount')
                                }
                            }
                        >
                            Створити аукціон за методом покрокового зниження
                        </li>
                    </>
                )
            }else if(i.tenderAttempts === 3){
                return (
                    <>
                        <li
                            onClick={
                                ()=>{
                                    //cloneAucNew(props, props.i.dataAuction.mysqlDraftId, '?type=discount')
                                    cloneAucNewMonga(props, props.i.id, props.i.auctionId, '?type=discount')
                                }
                            }
                        >
                            Створити аукціон за методом покрокового зниження
                        </li>
                    </>
                )
            }
        }
    }
}

function cloneZU1(props, i){
    if(i.tenderAttempts === 2) return null
    return (
        <>
            <li
                onClick={
                    ()=>{
                        cloneAucNewMonga(props, props.i.id, props.i.auctionId)
                    }
                }
            >
                Створити копію аукціону
            </li>
        </>
    )
}

//Завершення аукціону
function viewCompliteAuction(props, i, data){
    let tmp = null
    let tmpSellingMethod = null
    if(i !== null){
        if(i.hasOwnProperty('sellingMethod') === true){
            if(i.sellingMethod !== null){
                tmpSellingMethod = i.sellingMethod.indexOf('railwayCargo')
            }
            if( tmpSellingMethod === -1){
                if(i.hasOwnProperty('contracts') === true){
                    if(i.contracts.length > 0){
                        if(i.contracts.length > 0){
                            for(let t=0; t < i.contracts.length; t++){
                                if(i.contracts[t].status === 'active'){
                                    tmp = <li
                                        onClick={
                                            () => {
                                                data()
                                                if(
                                                    isBSE(i.sellingMethod) || isBSD(i.sellingMethod) ||
                                                    isALE(i.sellingMethod) ||
                                                    isCSE(i.sellingMethod) || isCSD(i.sellingMethod) ||
                                                    isBRE(i.sellingMethod) || isBRD(i.sellingMethod) || isBRW(i.sellingMethod) ||
                                                    isRLE(i.sellingMethod) || isRLD(i.sellingMethod) ||
                                                    isCLE(i.sellingMethod) || isCLD(i.sellingMethod)
                                                ){
                                                    compliteAuctionMonga(props, i.id)
                                                }else{
                                                    compliteAuction(props, i.id)
                                                }
                                            }
                                        }
                                    >
                                        Завершення аукціону
                                    </li>
                                }
                            }
                        }
                    }
                }
            }else{
                if(i.status === 'active_qualification'){
                    if(i.hasOwnProperty('awards') === true){
                        if(i.awards.length > 0){
                            for(let iAwards = 0; iAwards < i.awards.length; iAwards++){
                                if(i.awards[iAwards].status === 'active'){
                                    tmp = <li
                                        onClick={
                                            () => {
                                                data()
                                                if(
                                                    isBSE(i.sellingMethod) || isBSD(i.sellingMethod) ||
                                                    isALE(i.sellingMethod) ||
                                                    isCSE(i.sellingMethod) || isCSD(i.sellingMethod) ||
                                                    isBRE(i.sellingMethod) || isBRD(i.sellingMethod) || isBRW(i.sellingMethod) ||
                                                    isRLE(i.sellingMethod) || isRLD(i.sellingMethod) ||
                                                    isCLE(i.sellingMethod) || isCLD(i.sellingMethod)
                                                ){
                                                    compliteAuctionMonga(props, i.id)
                                                }else{
                                                    compliteAuction(props, i.id)
                                                }
                                            }
                                        }
                                    >
                                        Завершення аукціону
                                    </li>
                                    break
                                }
                            }
                        }
                    }
                }
            }
            if(i.status === "unsuccessful" || i.status === "cancelled" || i.status === "complete") tmp = null
        }
    }
    let tmpContractDoc = false
    let tmpContractStatus = false
    //if(i.contracts !== null){
    //    if(i.contracts.length > 0){
    //        for(let t = 0; t < i.contracts.length; t++){
    //            if(i.contracts[t].status === 'active'){
    //                tmpContractStatus = true
    //            }
    //        }
    //    }
    //}
    return tmp
}

async function compliteAuction(props, id){
    props.changeLoader(true)
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${id}/complete`;
    const response1 = await fetch(url, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
        props.changeLoader(false)
    }else{
        const json1 = await response1.json()
        if(json1.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завершенні аукціону', message: JSON.stringify(json1)})
	        props.setUniversalError(tmp)
        }else{
            const url2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${id}/sync`;
            const response2 = await fetch(url2, {
                method: "GET",
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response2 === undefined || response2 === 'undefined'){
                const json2 = await response2.json()
                props.setPopUpAuctionMyNull()
                let data = {}
                data.domen = process.env.REACT_APP_END_POINT_BD
                data.version = 'v1'
                data.local = props.langFlag
                if(props.myCabinetPage === 0){
                    data.params = `?offset=${props.myCabinetPage}`
                }else{
                    data.params = `?offset=${props.myCabinetPage}0` 
                }
                let header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                props.auctionsGetMy(data, header)
            }else{
                const json2 = await response2.json()
                props.setPopUpAuctionMyNull()
                let data = {}
                data.domen = process.env.REACT_APP_END_POINT_BD
                data.version = 'v1'
                data.local = props.langFlag
                if(props.myCabinetPage === 0){
                    data.params = `?offset=${props.myCabinetPage}`
                }else{
                    data.params = `?offset=${props.myCabinetPage}0` 
                }
                let header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                props.auctionsGetMy(data, header)
            }
        }
        props.changeLoader(false)
    }
}

async function compliteAuctionMonga(props, id){
    props.changeLoader(true)
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${id}/complete`;
    const response1 = await fetch(url, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
        props.changeLoader(false)
    }else{
        const json1 = await response1.json()
        if(json1.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завершенні аукціону', message: JSON.stringify(json1)})
	        props.setUniversalError(tmp)
        }else{
            await getAucionsList(props)   
        }
        props.changeLoader(false)
    }
}

async function cloneAucNew(props, id, data='?type=copy'){
    props.changeLoader(true)
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${id}/clone${data}`;
    const response1 = await fetch(url, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
        props.changeLoader(false)
    }else{
        const json1 = await response1.json()
        if(json1.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при створенні копії', message: JSON.stringify(json1)})
	        props.setUniversalError(tmp)
        }else{
            props.setPopUpAuctionMyNull()
            let data = {}
            data.domen = process.env.REACT_APP_END_POINT_BD
            data.version = 'v1'
            data.local = props.langFlag
            if(props.myCabinetPage === 0){
                data.params = `?offset=${props.myCabinetPage}`
            }else{
                data.params = `?offset=${props.myCabinetPage}0` 
            }
            let header = {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
            //await props.auctionsGetMy(data, header)
            props.setDataAddress(null, 'AUCTIONS_GET_MY')
            await getAucionsList(props)
        }
    }
    await props.changeLoader(false)
}

async function cloneAucNewMonga(props, id, auctionId, data=''){
    props.changeLoader(true)
    let url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${id}/copy${data}`;
    //if(auctionId === null) url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-draft/${id}/copy`;
    if(auctionId === null) url = toAuctionDraftCopy(id);
    const response1 = await fetch(url, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
        props.changeLoader(false)
    }else{
        const json1 = await response1.json()
        if(json1.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при створенні копії', message: JSON.stringify(json1)})
	        props.setUniversalError(tmp)
        }else{
            props.setPopUpAuctionMyNull()
            props.setDataAddress(null, 'AUCTIONS_GET_MY')
            await getAucionsList(props)
        }
    }
    await props.changeLoader(false)
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        auctionsMy: state.start.auctionsGetMy,
        auctionsMySingl: state.start.auctionsMy,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        auctionsMyNew: state.start.auctionsMyNew,
        userProfiles: state.start.userProfiles,
        myBids: state.start.myBids,
        myCabinetPage: state.start.myCabinetPage,
        cloneObjectId: state.start.cloneObjectId,
    }
}

const mapDispatchToProps = {
    changeAuctionsSwitchWindow,

    getMyAuction,

    changeCreateAuctionTitle,
    changeCreateAuctionDescription,
    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,
    changeCreateAuctionId,

    classificationsGetByScheme,
    auctionsGetMy,
    getAPI,
    setPopUpSendAuction,

    auctionsGetAwards,
    auctionsGetAwardsId,
    getAuctionsBidsId,

    contractsAuctionIdGet,
    setPopUpErrorAuction,
    setPopBankAccountId,
    setPopBankAccountIdArr,

    oldArrDocumentGet,

    changeDiscountId,
    changeDiscountPercent,
    changeDiscountPerviousAuctionCurrency,
    changeDiscountPerviousAuctionAmount,
    changeDiscountPerviousAuctionId,
    discountAuc,

    setUniversalError,
    setEditAuctionFromId,

    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,

    setDataAddress,
    setPopUpDelAuction,

    changeLoader,
    setPopUpAuctionMyNull,

    changeCreateAuctionTab,
}

export default connect(mapStateToProps, mapDispatchToProps)(DropDownMenu)