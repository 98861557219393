import React from 'react';

import {connect} from 'react-redux';
import {setAuctionPath} from '../../../../redux/actions/support.js';
import {
    auctionsGet,
    changeLotsFlag,
    auctionsSearch,
    auctionsSearchOld,
    auctionsSearchСount,
    auctionsSearchСountOld,
    changeCurentPage,
    auctionsSearchMin,
    changeStatusAuction,
} from '../../../../redux/actions/auctions/auctions';
import {changeSecondMenu} from '../../../../redux/actions.js';

const PaginationButton = ( props ) => {
    return (
        <div
            className={props.styleData}
            onClick={
                () => {
                    window.scrollTo(0, 0)
                    getPageSearch(props, searchData(props, props.pageGet))
                    props.changeCurentPage(props.pageChange)
                }
            }
        >
            {props.pageGet+1}
        </div>
    )
}

function searchData(props, count){
    return {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag,
        params: '?offset=' + count * 10
    }
}

function getPageSearch(props, data){
    let keyword, authorName, classification, auctionId = null;
    let selectTypeKeyword = null;
    let startDateFrom = null;
    let startDateTo = null;
    if(props.search.selectTypeKeyword !== ''){
        selectTypeKeyword = props.search.selectTypeKeyword
        startDateFrom = props.search.startDateFrom
        startDateTo = props.search.startDateTo
    }
    switch (selectTypeKeyword){
        case '0':
            keyword = props.search.textSting;
            authorName = null;
            classification = null;
            auctionId = null;
            break;
        case '1':
            keyword = null;
            authorName = null;
            classification = null;
            auctionId = props.search.textSting;
            break;
        case '2':
            keyword = props.search.textSting;
            authorName = null;
            classification = null;
            auctionId = null;
            break;
        case '3':
            keyword = null;
            authorName = props.search.textSting;
            classification = null;
            auctionId = null;
            break;
        default:
            keyword = props.search.textSting;
            authorName = null;
            classification = null;
            auctionId = null;
            break;
    }
    let dataSearch = {
        keyword: keyword,
        authorName: authorName,
        classification: classification,
        auctionId: auctionId,
        category: props.search.category,
        status: props.search.status,
        startDateFrom: startDateFrom,
        startDateTo: startDateTo
    }
    if(props.token !== null){
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        props.auctionsSearch(data, header, dataSearch);
    }else{
        let header = {
            'Content-Type': 'application/json'
        }
        props.auctionsSearch(data, header, dataSearch);
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        auctionsFlag: state.start.auctionsGetFlag,
        langFlag: state.start.langFlag,
        search: state.start.search,
        searchCount: state.start.searchCount,
    }
}

const mapDispatchToProps = {
    auctionsGet,
    changeLotsFlag,
    auctionsSearch,
    auctionsSearchOld,
    auctionsSearchСount,
    auctionsSearchСountOld,
    changeCurentPage,
    auctionsSearchMin,
    changeSecondMenu,
    changeStatusAuction,
    setAuctionPath,
}

export default connect(mapStateToProps, mapDispatchToProps)(PaginationButton)