import React from 'react';
import styles from './imageAndButtonLine_Img.module.css';

import {connect} from 'react-redux';

const ImageAndButtonLine_Img = ( props ) => {
    return (
        <div className={styles.secondBlock_img}>
            <img src="https://www.dto.com.ua/images/default_auction.png" alt="" />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
    }
}

export default connect(mapStateToProps, null)(ImageAndButtonLine_Img)