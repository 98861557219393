import React, { useEffect } from 'react';
import styles from './product.module.css';

import { Switch, Route } from 'react-router-dom'

import Opisanie from './opisanie/opisanie'
import Chat from './chat/chat'
import AuctionResult from './auctionResult/auctionResult'

import {connect} from 'react-redux';
import {changePageNumber} from '../../redux/actions.js';
import {auctionGetId, auctionGetIdToken} from '../../redux/actions/auctions/auctions.js';
import {auctionsBidsGetId} from '../../redux/actions/auctionBid/auctionBid.js';
import {setLogo} from '../../redux/actions/support.js';
import {auctionsGetAwards} from '../../redux/actions/awards/awards.js';
import {contractsAuctionIdGet, saveHistoryDocsContracts} from '../../redux/actions/contracts/contracts.js';
import {setDataAddress} from '../../redux/createAddress';

import {
    histeryAuctions,
    histeryAwards,
    histeryContrasts,
    histeryBids,
} from '../../redux/histery.js';

import {
    saveArrBids,
    saveAuctionId
} from '../../redux/allAuctionBids.js';

import ImgSVG from './imgSVG'

import {getUpdateDataAuction, getUpdateDataBid} from './updateData'

import Errors from '../errors/errors'
import PopUpLoader from '../popUp/loader/loader.js';

import {backendAPI} from '../lib/backendAPI'
import {errorAPI} from '../lib/errorAPI'

const Lot = (props) => {
    //TODO: Подключить новую монгу
    if(props.logo === null){
        props.setLogo(window.location.pathname.length)
    }else{
        if(window.location.pathname.length === 1){
            if(props.logo !== window.location.pathname.length){
                props.setLogo(null)
            }
        }else{
            if(props.logo !== window.location.pathname.length){
                props.setLogo(window.location.pathname.length)
            }
        }
    }
    if(props.auctionId !== null){
        if(props.auctionsAllBids.arr === null){
            if(props.auctionId.hasOwnProperty('privateData')){
                if(props.auctionId.privateData !== null){
                    if(props.auctionId.privateData.bids !== null){
                        if(props.auctionId.privateData.bids.length > 0){
                            getDocumentsAuctionBidsAll(props, props.auctionId.privateData.bids[0].id)
                        }
                    }
                }
            }
        }
    }

    let tmpId = setPageId()
    setStatePageNumber(props)
    useEffect(() => {
        //getUpdateDataAuction(props, tmpId[0])
        combineSetData(props, tmpId[0])
    }, [])
    let viewTmp = null;
    if(/*props.auctionsGetIdNotFound === false && */props.auctionId !== null){
        if(Number(props.auctionId)){
            viewTmp = <Errors errorCode={props.auctionId}/>
        }else{
            viewTmp = (
                <Switch>
                    <Route exact path='/auctions/:number'
                        render={ () => <Opisanie /> }
                    />
                    <Route exact path='/auctions/:number/questions'
                        render={ () => <Chat /> }
                    />
                    <Route exact path='/auctions/:number/auctionResult'
                        render={ () => <AuctionResult /> }
                    />
                </Switch>
            )
        }
    }else{
        viewTmp = <PopUpLoader />
    }
    return (
        <div className={styles.lotWrap} >
            <div className={styles.lotContent}>
                {viewTmp}
            </div>
        </div>
    )
}

async function combineSetData(props, aucId){
    let tmpData = {}
    tmpData.endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/auction/by_public_id/${aucId}`
    tmpData.body = {}
    tmpData.body.method = "GET"
    if(props.token !== null){
        tmpData.body.headers = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }else{
        tmpData.body.headers = {
            'Content-Type': 'application/json'
        }
    }
    let tmpResponse = await backendAPI(tmpData)
    let tmpResponse2 = await errorAPI(tmpResponse)
    if(tmpResponse2.hasOwnProperty('message') === true){
        let tmp = JSON.stringify({title: 'Помилка', message: JSON.stringify(tmpResponse2)})
        props.setUniversalError(tmp)
    }else{
        if(tmpResponse2 !== null && !Number(tmpResponse2)){
            if(tmpResponse2.awards !== null){
                if(tmpResponse2.awards.length > 0){
                    if(tmpResponse2.awards[0].id === null){
                        tmpData.endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/auction/${tmpResponse2.id}/sync`
                        let tmpResponse3 = await backendAPI(tmpData)
                        tmpResponse2 = await errorAPI(tmpResponse3)
                    }
                }
            }
        }
        await props.setDataAddress(tmpResponse2, 'SET_FULL_AUCTION')
    }
}

function setBaseUrl(){
    if(window.location.pathname.indexOf('/auctions/') !== -1){
        return window.location.pathname.split('/auctions/');
    }
    return null
}

function setPageId(){
    let tmp = setBaseUrl()
    if(tmp !== null){
        return tmp[1].split('/')
    }
    return null
}

function setStatePageNumber(props){
    let tmp = setPageId()
    if(tmp !== null){
        if(props.pageNumber === null){
            props.changePageNumber(tmp[0])
        }
    }
}

async function getDocumentsAuctionBidsAll(props, id){
    let header = null
    if(props.token !== null){
        header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }else{
        header = {
            'Content-Type': 'application/json'
        }
    }
    //const url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${id}/bids/all`;
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${id}`

    const response = await fetch(url, {
        method: "GET",
        headers: header
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            //let tmp = JSON.stringify({title: 'Помилка при відхиленні аварду', message: JSON.stringify(json2)})
            //props.setUniversalError(tmp)
        }else{
            await props.saveArrBids(json)
        }
    }
}

async function viewMinSumm(props, idAuc){
    let endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/userProfile/payments/bill/auction/${props.auctionId.id}/guarantee/summ`
    //let endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/user/profile/bill/auction/${idAuc}/guarantee/summ`
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        let tmp = {
            aucId: idAuc,
            summ: json.summ
        }
        props.setDataAddress(tmp, 'SET_BID_NEEDED_SUMM')
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
        langFlag: state.start.langFlag,
        pageNumber: state.start.pageNumber,
        auctionsGetIdNotFound: state.start.auctionsGetIdNotFound,
        histery: state.start.histery,
        contractAuctionIdGet: state.start.contractAuctionIdGet,
        historyDocsContracts: state.start.historyDocsContracts,
        auctionsAllBids: state.start.auctionsAllBids,
        //auctionFull: state.start.auctionFull,
        auctionFullId: state.start.auctionFullId,
        bidSumm: state.start.bidSumm,
    }
}

const mapDispatchToProps = {
    changePageNumber,
    auctionGetId,
    auctionGetIdToken,
    auctionsBidsGetId,
    setLogo,
    auctionsGetAwards,
    contractsAuctionIdGet,

    histeryAuctions,
    histeryAwards,
    histeryContrasts,
    histeryBids,

    saveArrBids,
    saveAuctionId,

    saveHistoryDocsContracts,
    setDataAddress,

}

export default connect(mapStateToProps, mapDispatchToProps)(Lot)
