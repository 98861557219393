import React from 'react';
import styles from './myAuctionsHeader.module.css';

import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {setDataAddress} from '../../../../redux/createAddress'

import {
    changeAuctionsSwitchWindow,
    auctionsGetMy,
} from '../../../../redux/actions/auctions/auctions';

import {
    getMyAuction,
    changeCreateAuctionTenderAttempts,
    changeMyCabinetCurentPage,
} from '../../../../redux/actions';

import {setBankAccountSave, setBankAccountAdd, setBankAccountDelete} from '../../../../redux/actions/bankAccount/bankAccount';

const MyAuctionsHeader = ( props ) => {
    return (
        <div className={styles.wrap_listAuctions}>
                <div className={styles.wrap_listAuctions_topLine}>
                    <div className={styles.listAuctions_title}>Мої аукціони</div>
                    <div className={styles.wrapButtons}>
                        {button(props)}
                        {buttonID(props)}
                    </div>
            </div>
        </div>
    )
}

function button(props){
    if(props.userProfiles.canCreatePrivatization) return null
    if(
        props.userProfiles.canCreateAuction === true ||
        props.userProfiles.canCreateLandAuction === true ||
        props.userProfiles.canCreateAlienation === true ||
        props.userProfiles.canCreateBasicSell === true ||
        props.userProfiles.canCreateCommercialSellAuction === true ||
        props.userProfiles.canCreateBankruptAuction === true ||
        props.userProfiles.canCreateArma === true// ||
        //props.userProfiles.canCreatePrivatization === true
    ){
        return (
            <Link to={`/create_auctions/new`} title="Посилання на аукціон">
                <div
                    className={styles.buttons}
                    onClick={
                        () => {
                            props.changeAuctionsSwitchWindow(2)
                            props.getMyAuction(props.auctionsMyNew)
                            props.changeCreateAuctionTenderAttempts(1)
                            props.setBankAccountSave([])
                            props.setBankAccountAdd([])
                            props.setBankAccountDelete([])
                            getAddressOrg(props)
                        }
                    }
                >Створити аукціон</div>
            </Link>
        )
    }
}

function buttonID(props){
    if(props.userProfiles.canCreateLeaseAuction === true){
        return (
            <div
                className={styles.buttons}
                onClick={
                    () => {
                        props.setDataAddress(true, 'SET_CREATE_AUCTION_ZU_FLAG')
                    }
                }
            >Створити аукціон з оренди державного/комунального майна за Id об'єкта</div>
        )
    }
}

async function getAddressOrg(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/organizations?limit=30&offset=0`
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        return null
    }else{
        const json = await response.json()
        /*if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            //props.setDataAddress(json, 'GET_ADDRESS_NEW_BOOKS')
        }*/
        responseGetData(json)
    }
}

function responseGetData(data){
    if(data.hasOwnProperty('message') === true){
        return null
    }else{
        return data
    }
}

const mapStateToProps = state => {
    return {
        auctionsMyNew: state.start.auctionsMyNew,
        token: state.start.token,
        userProfiles: state.start.userProfiles,
    }
}

const mapDispatchToProps = {
    changeAuctionsSwitchWindow,
    getMyAuction,
    changeCreateAuctionTenderAttempts,

    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,

    setDataAddress,

    auctionsGetMy,
    changeMyCabinetCurentPage,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAuctionsHeader)
