import React from 'react';

import {connect} from 'react-redux';

import {setDataAddress} from '../../../redux/createAddress';

import Select from '../../elements/inputs/inputSelect';

import {disabletElem} from '../disabled'
import { isALE, isBSD, isBSE, isCSD, isCSE, isBRE, isBRD } from '../../../redux/aucTypes';

const ValueAddedTaxCharged = ( props ) => {
    if(
        isBSE(props.auctionsMy.sellingMethod) || isBSD(props.auctionsMy.sellingMethod) ||
        isALE(props.auctionsMy.sellingMethod) ||
        isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
        isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod)
    ){
        if(props.auctionsMy.valueAddedTaxCharged === null) props.setDataAddress(false, 'SET_VALUE_ADDED_TAX_CHARGED_BASIC')
        return (
            <div>
                <div style={{width: '100%', margin: '0 0 15px'}}>На фінальну суму нараховується ПДВ</div>
                <Select
                    data={{
                        label:"ПДВ",
                        target: props.auctionsMy.valueAddedTaxCharged,
                        descr:'Оберіть включати ПДВ чи ні'
                        // info: 'оберіть тип процедури, за яким проходитиме аукціон'
                    }}
                    onChange={(event)=>{
                        props.setDataAddress(event, 'SET_VALUE_ADDED_TAX_CHARGED_BASIC')
                            }}
                    options={[
                        {name: 'Обрати', value: null},
                        {name: 'з ПДВ', value: true},
                        {name: 'без ПДВ', value: false},
                    ]}
                    disabled={disabletElem(props.auctionsMy, '00')}
                />
                {/* <TextField
                    disabled={disabletElem(props.auctionsMy, '00')}
                    id="outlined-select-currency-native"
                    select
                    value={props.auctionsMy.valueAddedTaxCharged}
                    onChange={(event)=>{
                        props.setDataAddress(event.target.value, 'SET_VALUE_ADDED_TAX_CHARGED_BASIC')
                    }}
                    SelectProps={{
                        native: true,
                    }}
                    helperText="Оберіть включати ПДВ чи ні"
                    variant="outlined"
                >
                    <option key={0} selected disabled value='null'>Обрати</option>
                    <option key={1} value='true'>з ПДВ</option>
                    <option key={2} value='false'>без ПДВ</option>
                </TextField> */}
            </div>
        )
    }
    return null
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
    }
}

const mapDispatchToProps = {
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(ValueAddedTaxCharged)