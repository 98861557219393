export const PROCEDURE_NAME = {
  "base": {
    "MultiLang": {
      "uk_UA": {
        "legalName": "Назва українською"
      },
      "en_US": {
        "legalName": "Назва англійською"
      }
    },
    "Period": {
      "startDate": {
        "legalName": "Дата та час початку"
      },
      "endDate": {
        "legalName": "Дата та час завершення"
      }
    },
    "LifeTime": {
      "dateFrom": {
        "legalName": "Період з"
      },
      "dateTill": {
        "legalName": "Період по"
      }
    },
    "ValueWithoutVAT": {
      "currency": {
        "legalName": "Валюта"
      },
      "amount": {
        "legalName": "Сума"
      }
    },
    "Value": {
      "currency": {
        "legalName": "Валюта"
      },
      "amount": {
        "legalName": "Сума"
      },
      "valueAddedTaxIncluded": {
        "legalName": "Податок"
      }
    },
    "DutchStep": {
      "dutchStepQuantity": {
        "legalName": "Кількість кроків"
      },
      "dutchStepPercent": {
        "legalName": "Відсоток зниження, %"
      },
      "dutchStepValue": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Розмір кроку голландського раунду, грн"
      },
      "legalName": "Крок голландського раунду"
    },
    "DutchMinimalStep": {
      "currency": {
        "legalName": "Валюта"
      },
      "amount": {
        "legalName": "Сума"
      },
      "valueAddedTaxIncluded": {
        "legalName": "Податок"
      },
      "legalName": "Розмір кроку аукціону"
    },
    "AddressIdentifier": {
      "scheme": {
        "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
      },
      "name": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва адміністративно-територіальних об’єктів України"
      },
      "id": {
        "legalName": "Код адміністративно-територіальних об’єктів України"
      }
    },
    "Address": {
      "addressID": {
        "scheme": {
          "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
        },
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Назва адміністративно-територіальних об’єктів України"
        },
        "id": {
          "legalName": "Код адміністративно-територіальних об’єктів України"
        },
        "legalName": "Код об’єктів адміністративно-територіального устрою України"
      },
      "streetAddress": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Адреса"
      },
      "locality": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Населений пункт"
      },
      "region": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Область"
      },
      "postalCode": {
        "legalName": "Поштовий індекс"
      },
      "countryName": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Країна"
      }
    },
    "AddressUa": {
      "addressID": {
        "scheme": {
          "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
        },
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Назва адміністративно-територіальних об’єктів України"
        },
        "id": {
          "legalName": "Код адміністративно-територіальних об’єктів України"
        },
        "legalName": "Код об’єктів адміністративно-територіального устрою України"
      },
      "streetAddress": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Адреса"
      },
      "locality": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Населений пункт"
      },
      "region": {
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Область"
      },
      "postalCode": {
        "legalName": "Поштовий індекс"
      },
      "countryName": {
        "en_US": {
          "legalName": "Назва англійською"
        }
      }
    },
    "ContactPoint": {
      "name": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "ПІБ"
      },
      "email": {
        "legalName": "Адреса електронної пошти"
      },
      "telephone": {
        "legalName": "Номер телефону"
      },
      "faxNumber": {
        "legalName": "Номер факсу"
      },
      "url": {
        "legalName": "Веб адреса"
      }
    },
    "SellingEntityIdentifier": {
      "scheme": {
        "legalName": "Тип ідентифікації Організатора аукціону"
      },
      "legalName": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Повна юридична назва Організатора аукціону"
      },
      "id": {
        "legalName": "Ідентифікатор Організатора аукціону"
      }
    },
    "SellingEntity": {
      "name": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Найменування Організатора аукціону"
      },
      "identifier": {
        "scheme": {
          "legalName": "Тип ідентифікації Організатора аукціону"
        },
        "legalName": "Ідентифікатор Організатора аукціону",
        "id": {
          "legalName": "Ідентифікатор Організатора аукціону"
        }
      },
      "address": {
        "addressID": {
          "scheme": {
            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
          },
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Назва адміністративно-територіальних об’єктів України"
          },
          "id": {
            "legalName": "Код адміністративно-територіальних об’єктів України"
          },
          "legalName": "Код об’єктів адміністративно-територіального устрою України"
        },
        "streetAddress": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Адреса"
        },
        "locality": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Населений пункт"
        },
        "region": {
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Область"
        },
        "postalCode": {
          "legalName": "Поштовий індекс"
        },
        "countryName": {
          "en_US": {
            "legalName": "Назва англійською"
          }
        },
        "legalName": "Місцезнаходження Організатора аукціону"
      },
      "contactPoint": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "ПІБ"
        },
        "email": {
          "legalName": "Адреса електронної пошти"
        },
        "telephone": {
          "legalName": "Номер телефону"
        },
        "faxNumber": {
          "legalName": "Номер факсу"
        },
        "url": {
          "legalName": "Веб адреса"
        },
        "legalName": "Представник"
      },
      "representativeInfo": {
        "legalName": "Інформація щодо підтвердження повноважень"
      }
    },
    "Classification": {
      "scheme": {
        "legalName": "Схема класифікатора"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис коду класифікатора"
      },
      "id": {
        "legalName": "Код класифікатора"
      }
    },
    "AdditionalClassification": {
      "scheme": {
        "legalName": "Схема додаткового класифікатору"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис додаткового класифікатору"
      },
      "id": {
        "legalName": "Код додаткового класифікатору"
      }
    },
    "Unit": {
      "code": {
        "legalName": "Код одиниці виміру"
      },
      "name": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва одиниці виміру"
      }
    },
    "Item": {
      "id": {
        "legalName": "Внутрішній ідентифікатор об'єкта"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис об'єкта"
      },
      "classification": {
        "scheme": {
          "legalName": "Схема класифікатора"
        },
        "description": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Опис коду класифікатора"
        },
        "id": {
          "legalName": "Код класифікатора"
        },
        "legalName": "Класифікатор об'єкта"
      },
      "unit": {
        "code": {
          "legalName": "Код одиниці виміру"
        },
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Назва одиниці виміру"
        },
        "legalName": "Одиниці виміру об'єкта"
      },
      "quantity": {
        "legalName": "Кількість одиниць"
      },
      "address": {
        "addressID": {
          "scheme": {
            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
          },
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Назва адміністративно-територіальних об’єктів України"
          },
          "id": {
            "legalName": "Код адміністративно-територіальних об’єктів України"
          },
          "legalName": "Код об’єктів адміністративно-територіального устрою України"
        },
        "streetAddress": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Адреса"
        },
        "locality": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Населений пункт"
        },
        "region": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Область"
        },
        "postalCode": {
          "legalName": "Поштовий індекс"
        },
        "countryName": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Країна"
        },
        "legalName": "Місцезнаходження"
      }
    },
    "Identifier": {
      "scheme": {
        "legalName": "Ідентифікатори організації"
      },
      "legalName": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Повна юридична назва організації"
      },
      "id": {
        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
      }
    },
    "OrganizationWithoutContacts": {
      "name": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Повна юридична назва організації або ПІБ"
      },
      "identifier": {
        "scheme": {
          "legalName": "Ідентифікатори організації"
        },
        "legalName": "Ідентифікатори організації або особи",
        "id": {
          "legalName": "Код ЄДРПОУ або ІПН або паспорт"
        }
      },
      "address": {
        "addressID": {
          "scheme": {
            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
          },
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Назва адміністративно-територіальних об’єктів України"
          },
          "id": {
            "legalName": "Код адміністративно-територіальних об’єктів України"
          },
          "legalName": "Код об’єктів адміністративно-територіального устрою України"
        },
        "streetAddress": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Адреса"
        },
        "locality": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Населений пункт"
        },
        "region": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Область"
        },
        "postalCode": {
          "legalName": "Поштовий індекс"
        },
        "countryName": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Країна"
        },
        "legalName": "Адреса"
      },
      "representativeInfo": {
        "legalName": "Інформація щодо підтвердження повноважень"
      }
    },
    "Organization": {
      "name": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Повна юридична назва організації або ПІБ"
      },
      "identifier": {
        "scheme": {
          "legalName": "Ідентифікатори організації"
        },
        "legalName": "Ідентифікатори організації або особи",
        "id": {
          "legalName": "Код ЄДРПОУ або ІПН або паспорт"
        }
      },
      "address": {
        "addressID": {
          "scheme": {
            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
          },
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Назва адміністративно-територіальних об’єктів України"
          },
          "id": {
            "legalName": "Код адміністративно-територіальних об’єктів України"
          },
          "legalName": "Код об’єктів адміністративно-територіального устрою України"
        },
        "streetAddress": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Адреса"
        },
        "locality": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Населений пункт"
        },
        "region": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Область"
        },
        "postalCode": {
          "legalName": "Поштовий індекс"
        },
        "countryName": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Країна"
        },
        "legalName": "Адреса"
      },
      "representativeInfo": {
        "legalName": "Інформація щодо підтвердження повноважень"
      },
      "contactPoint": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "ПІБ"
        },
        "email": {
          "legalName": "Адреса електронної пошти"
        },
        "telephone": {
          "legalName": "Номер телефону"
        },
        "faxNumber": {
          "legalName": "Номер факсу"
        },
        "url": {
          "legalName": "Веб адреса"
        },
        "legalName": "Контактна особа"
      }
    },
    "Document": {
      "id": {
        "legalName": "Ідентифікатор документу"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва документу"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис документу"
      },
      "url": {
        "legalName": "Посилання на документ"
      },
      "relatedDocument": {
        "legalName": "Пов'язаний документ"
      },
      "documentOf": {
        "legalName": "Об'єкт документу"
      },
      "documentType": {
        "legalName": "Тип документу"
      },
      "datePublished": {
        "legalName": "Дата публікації документу"
      },
      "dateModified": {
        "legalName": "Остання дата редагування документу"
      },
      "index": {
        "legalName": "Параметр сортування ілюстрацій"
      },
      "format": {
        "legalName": "Формат документу"
      },
      "language": {
        "legalName": "Мова документу"
      },
      "hash": {
        "legalName": "Хеш документу"
      }
    },
    "CancellationDocument": {
      "id": {
        "legalName": "Ідентифікатор документу"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва документу"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис документу"
      },
      "url": {
        "legalName": "Посилання на документ"
      },
      "relatedDocument": {
        "legalName": "Пов'язаний документ"
      },
      "datePublished": {
        "legalName": "Дата публікації документу"
      },
      "dateModified": {
        "legalName": "Остання дата редагування документу"
      },
      "index": {
        "legalName": "Параметр сортування ілюстрацій"
      },
      "format": {
        "legalName": "Формат документу"
      },
      "language": {
        "legalName": "Мова документу"
      },
      "hash": {
        "legalName": "Хеш документу"
      }
    },
    "Question": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "id": {
        "legalName": "Ідентифікатор запитання"
      },
      "author": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "ПІБ"
        },
        "email": {
          "legalName": "Адреса електронної пошти"
        },
        "telephone": {
          "legalName": "Номер телефону"
        },
        "faxNumber": {
          "legalName": "Номер факсу"
        },
        "url": {
          "legalName": "Веб адреса"
        },
        "legalName": "Автор запитання"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва запитання"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Текст запитання"
      },
      "datePublished": {
        "legalName": "Дата публікації запитання"
      },
      "dateModified": {
        "legalName": "Дата редагування запитання"
      },
      "dateAnswered": {
        "legalName": "Дата відповіді на запитання"
      },
      "answer": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Відповідь на запитання"
      }
    },
    "Bid": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "id": {
        "legalName": "Ідентифікатор заяви на участь"
      },
      "bidders": {
        "legalName": "Інформація про учасника"
      },
      "datePublished": {
        "legalName": "Дата заяви на участь"
      },
      "dateModified": {
        "legalName": "Остання дата редагування ставки"
      },
      "status": {
        "legalName": "Статус заяви на участь"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Цінова пропозиція"
      },
      "documents": {
        "legalName": "Документи до заяви про участь"
      },
      "participationUrl": {
        "legalName": "Веб-адреса для участі в аукціоні"
      }
    },
    "VerificationDocumentInfo": {
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис документа"
      },
      "id": {
        "legalName": "Номер документа"
      },
      "date": {
        "legalName": "Дата видачі документа"
      }
    },
    "AccountIdentification": {
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис до банківських реквізитів"
      },
      "scheme": {
        "legalName": "Тип банківського ідентифікатора"
      },
      "id": {
        "legalName": "Ідентифікатор"
      }
    },
    "BankAccount": {
      "id": {
        "legalName": "Ідентифікатор банківського рахунку"
      },
      "bankName": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва банку"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис"
      },
      "accountIdentifications": {
        "legalName": "Банківські реквізити"
      }
    },
    "Award": {
      "id": {
        "legalName": "Ідентифікатор кваліфікації учасника аукціону"
      },
      "bidId": {
        "legalName": "Ідентифікатор заяви на участь учасника аукціону"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
      },
      "status": {
        "legalName": "Статус кваліфікації учасника аукціону"
      },
      "terminationReason": {
        "legalName": "Причина дискваліфікації/відмови учаснику"
      },
      "datePublished": {
        "legalName": "Дата початку кваліфікації учасника аукціону"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Цінова пропозиція"
      },
      "buyers": {
        "legalName": "Інформація про учасника"
      },
      "items": {
        "legalName": "Склад лоту"
      },
      "documents": {
        "legalName": "Документи до аварду"
      },
      "dateModified": {
        "legalName": "Дата редагування"
      }
    },
    "Contract": {
      "id": {
        "legalName": "Внутрішній ідентифікатор контракту"
      },
      "awardId": {
        "legalName": "Ідентифікатор переможця"
      },
      "contractNumber": {
        "legalName": "Номер договору"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва договору"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис договору"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Вартість договору"
      },
      "contractTotalValue": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Заключна вартість договору"
      },
      "items": {
        "legalName": "Склад лоту"
      },
      "buyers": {
        "legalName": "Переможець"
      },
      "status": {
        "legalName": "Статус договору"
      },
      "dateSigned": {
        "legalName": "Дата підписання договору"
      },
      "datePublished": {
        "legalName": "Дата створення об'єкта договіру"
      },
      "dateModified": {
        "legalName": "Остання дата редагування договіру"
      },
      "contractTime": {
        "dateFrom": {
          "legalName": "Період з"
        },
        "dateTill": {
          "legalName": "Період по"
        },
        "legalName": "Строк дії договору"
      },
      "documents": {
        "legalName": "Документи та додатки пов’язані з договором"
      }
    },
    "Cancellation": {
      "id": {
        "legalName": "Ідентифікатор об'єкта скасування"
      },
      "reason": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Причина скасування Аукціону"
      },
      "datePublished": {
        "legalName": "Дата скасування"
      }
    },
    "Discount": {
      "previousAuctionValue": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Стартова вартість попереднього аукціону"
      },
      "discountPercent": {
        "legalName": "Розмір знижки від попереднього аукціону, %"
      },
      "discount": {
        "legalName": "Знижка"
      }
    },
    "Location": {
      "latitude": {
        "legalName": "Широта"
      },
      "longitude": {
        "legalName": "Довгота"
      },
      "elevation": {
        "legalName": "Висота"
      }
    },
    "CommonClientBid": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "id": {
        "legalName": "Ідентифікатор заяви на участь"
      },
      "bidders": {
        "legalName": "Інформація про учасника"
      },
      "datePublished": {
        "legalName": "Дата заяви на участь"
      },
      "dateModified": {
        "legalName": "Остання дата редагування ставки"
      },
      "status": {
        "legalName": "Статус заяви на участь"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Цінова пропозиція"
      },
      "documents": {
        "legalName": "Документи до заяви про участь"
      },
      "participationUrl": {
        "legalName": "Веб-адреса для участі в аукціоні"
      }
    },
    "RegistrationDetails": {
      "registrationStatus": {
        "legalName": "Cтатус реєстрації"
      },
      "registrationID": {
        "legalName": "Номер запису"
      },
      "registrationDate": {
        "legalName": "Дата реєстрації"
      }
    },
    "LandProps": {
      "landArea": {
        "legalName": "Площа ділянки, га"
      },
      "cadastralNumber": {
        "legalName": "Кадастровий номер"
      },
      "ownershipType": {
        "legalName": "Тип власності"
      },
      "encumbrances": {
        "legalName": "Наявність обтяжень"
      },
      "jointOwnership": {
        "legalName": "Наявність співвласників"
      },
      "utilitiesAvailability": {
        "legalName": "Наявність інженерних мереж"
      },
      "landIntendedUse": {
        "legalName": "Поточне використання земельної ділянки"
      }
    },
    "RealEstateProps": {
      "totalBuildingArea": {
        "legalName": "Загальна площа будівлі, кв.м."
      },
      "totalObjectArea": {
        "legalName": "Загальна площа об''єкта в будівлі, кв.м."
      },
      "usableArea": {
        "legalName": "Корисна площа об''єкта в будівлі, кв.м."
      },
      "constructionYear": {
        "legalName": "Рік будівництва"
      },
      "constructionTechnology": {
        "legalName": "Технологія будівництва"
      },
      "livingArea": {
        "legalName": "Житлова площа, кв. м"
      },
      "kitchenArea": {
        "legalName": "Площа кухні, кв. м"
      },
      "landArea": {
        "legalName": "Площа ділянки, кв. м"
      },
      "locationInBuilding": {
        "legalName": "Місце розташування об’єкта в будівлі"
      },
      "floors": {
        "legalName": "Номер поверху або поверхів"
      },
      "generalCondition": {
        "legalName": "Технічний стан об'єкта"
      },
      "serviceElectricity": {
        "legalName": "Чи приєднаний об'єкт до електромережі"
      },
      "powerSupplyCapacity": {
        "legalName": "Потужність електромережі, кВт"
      },
      "powerSupplyClass": {
        "legalName": "Ступінь потужності електромережі"
      },
      "serviceWater": {
        "legalName": "Водозабезпечення присутнє"
      },
      "serviceSewerage": {
        "legalName": "Каналізація присутня"
      },
      "serviceGas": {
        "legalName": "Газифікація присутня"
      },
      "serviceCentralHeating": {
        "legalName": "Централізоване опалення присутнє"
      },
      "serviceAutonomousHeating": {
        "legalName": "Автономне опалення присутнє"
      },
      "serviceHeatingCounter": {
        "legalName": "Лічильник опалення присутній"
      },
      "serviceVentilation": {
        "legalName": "Вентиляція присутня"
      },
      "serviceAirConditioning": {
        "legalName": "Кондиціонування присутнє"
      },
      "servicePhone": {
        "legalName": "Телефонізація присутня"
      },
      "serviceTV": {
        "legalName": "Телебачення присутнє"
      },
      "serviceInternet": {
        "legalName": "Інтернет присутній"
      },
      "serviceElevator": {
        "legalName": "Ліфт присутній"
      },
      "serviceSecurityAlarm": {
        "legalName": "Охоронна сигналізація присутня"
      },
      "serviceFireAlarm": {
        "legalName": "Пожежна сигналізація присутня"
      },
      "servicesDescription": {
        "legalName": "Додаткова інформація щодо комунікацій, що є в об''єкті"
      },
      "servicesAccounting": {
        "legalName": "Інформація про наявність окремих особових рахунків на об'єкт, відкритих постачальниками комунальних послуг"
      },
      "landTax": {
        "legalName": "Інформація щодо компенсації сплати земельного податку за користування земельною ділянкою, на якій розташований об'єкт"
      }
    },
    "JointPropertyComplexProps": {
      "quantityAndNomenclature": {
        "legalName": "Обсяг та основна номенклатура продукції (робіт, послуг), у тому числі експортної"
      },
      "workplacesQuantity": {
        "legalName": "Кількість робочих місць, ociб"
      },
      "buildingsInformation": {
        "legalName": "Відомості про будівлі (споруди, приміщення) єдиного майнового комплексу в обсязі, передбаченому для інформації про нерухоме майно"
      },
      "landPlotInformation": {
        "legalName": "Відомості про земельну ділянку, на якій розташовано єдиний майновий комплекс"
      },
      "landProps": {
        "landArea": {
          "legalName": "Площа ділянки, га"
        },
        "cadastralNumber": {
          "legalName": "Кадастровий номер"
        },
        "ownershipType": {
          "legalName": "Тип власності"
        },
        "encumbrances": {
          "legalName": "Наявність обтяжень"
        },
        "jointOwnership": {
          "legalName": "Наявність співвласників"
        },
        "utilitiesAvailability": {
          "legalName": "Наявність інженерних мереж"
        },
        "landIntendedUse": {
          "legalName": "Поточне використання земельної ділянки"
        },
        "legalName": "Характеристики земельної ділянки"
      },
      "mainObligations": {
        "legalName": "Основні зобов’язання (договірні та позадоговірні)"
      }
    },
    "VehicleProps": {
      "brand": {
        "legalName": "Марка транспортного засобу"
      },
      "model": {
        "legalName": "Модель транспортного засобу"
      },
      "productionYear": {
        "legalName": "Рік випуску"
      },
      "engineCapacity": {
        "legalName": "Об’єм двигуна, см³"
      },
      "fuelType": {
        "legalName": "Тип палива"
      },
      "transmission": {
        "legalName": "Тип коробки передач"
      },
      "color": {
        "legalName": "Колір"
      },
      "kilometrage": {
        "legalName": "Пробіг, км"
      },
      "identificationNumber": {
        "legalName": "Номер кузова або шасі (VIN-код)"
      },
      "configuration": {
        "legalName": "Комплектація"
      },
      "condition": {
        "legalName": "Технічний стан"
      },
      "damagedDescription": {
        "legalName": "Опис пошкоджень"
      },
      "damagePresence": {
        "legalName": "Потреба у ремонті"
      }
    },
    "ManagingEntityIdentifier": {
      "scheme": {
        "legalName": "Тип ідентифікації юридичної особи"
      },
      "legalName": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Повна юридична назва юридичної особи"
      },
      "id": {
        "legalName": "Ідентифікатор юридичної особи"
      }
    },
    "ManagingEntity": {
      "name": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Найменування юридичної особи"
      },
      "identifier": {
        "scheme": {
          "legalName": "Тип ідентифікації юридичної особи"
        },
        "legalName": "Ідентифікатор юридичної особи",
        "id": {
          "legalName": "Ідентифікатор юридичної особи"
        }
      },
      "address": {
        "addressID": {
          "scheme": {
            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
          },
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Назва адміністративно-територіальних об’єктів України"
          },
          "id": {
            "legalName": "Код адміністративно-територіальних об’єктів України"
          },
          "legalName": "Код об’єктів адміністративно-територіального устрою України"
        },
        "streetAddress": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Адреса"
        },
        "locality": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Населений пункт"
        },
        "region": {
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Область"
        },
        "postalCode": {
          "legalName": "Поштовий індекс"
        },
        "countryName": {
          "en_US": {
            "legalName": "Назва англійською"
          }
        },
        "legalName": "Місцезнаходження юридичної особи"
      },
      "contactPoint": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "ПІБ"
        },
        "email": {
          "legalName": "Адреса електронної пошти"
        },
        "telephone": {
          "legalName": "Номер телефону"
        },
        "faxNumber": {
          "legalName": "Номер факсу"
        },
        "url": {
          "legalName": "Веб адреса"
        },
        "legalName": "Контактна особа"
      }
    },
    "Schedule": {
      "hoursBy": {
        "value": {
          "legalName": "Години"
        }
      },
      "daysBy": {
        "value": {
          "legalName": "Дні"
        }
      },
      "period": {
        "legalName": "Період дії графіку"
      },
      "other": {
        "legalName": "Інший графік використання"
      }
    },
    "LeaseRules": {
      "schedule": {
        "hoursBy": {
          "value": {
            "legalName": "Години"
          }
        },
        "daysBy": {
          "value": {
            "legalName": "Дні"
          }
        },
        "period": {
          "legalName": "Період дії графіку"
        },
        "other": {
          "legalName": "Інший графік використання"
        },
        "legalName": "Пропонований графік оренди"
      },
      "intendedUse": {
        "legalName": "Цільове призначення об'єкта"
      },
      "intendedUseRestrictionMethod": {
        "legalName": "Спосіб обмеження цільового призначення об'єкта"
      },
      "intendedUseRestrictionDescription": {
        "legalName": "Опис обмеженнь цільового призначення об'єкта (за наявності)"
      },
      "additionalLeaseConditions": {
        "legalName": "Додаткові умови оренди майна"
      },
      "subleaseAllowed": {
        "legalName": "Можливість суборенди"
      }
    }
  },
  "basicSell": {
    "AdditionalClassification": {
      "scheme": {
        "legalName": "Схема додаткового класифікатору"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис додаткового класифікатору"
      },
      "id": {
        "legalName": "Код додаткового класифікатору"
      }
    },
    "Classification": {
      "scheme": {
        "legalName": "Схема класифікатора"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис класифікатора"
      },
      "id": {
        "legalName": "Код класифікатора"
      }
    },
    "Item": {
      "id": {
        "legalName": "Внутрішній ідентифікатор об'єкта"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис об'єкта"
      },
      "classification": {
        "scheme": {
          "legalName": "Схема класифікатора"
        },
        "description": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Опис класифікатора"
        },
        "id": {
          "legalName": "Код класифікатора"
        },
        "legalName": "Класифікатор об'єкта"
      },
      "unit": {
        "code": {
          "legalName": "Код одиниці виміру"
        },
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Назва одиниці виміру"
        },
        "legalName": "Одиниці виміру"
      },
      "quantity": {
        "legalName": "Кількість одиниць"
      },
      "address": {
        "addressID": {
          "scheme": {
            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
          },
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Назва адміністративно-територіальних об’єктів України"
          },
          "id": {
            "legalName": "Код адміністративно-територіальних об’єктів України"
          },
          "legalName": "Код об’єктів адміністративно-територіального устрою України"
        },
        "streetAddress": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Адреса"
        },
        "locality": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Населений пункт"
        },
        "region": {
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Область"
        },
        "postalCode": {
          "legalName": "Поштовий індекс"
        },
        "countryName": {
          "en_US": {
            "legalName": "Назва англійською"
          }
        },
        "legalName": "Місцезнаходження"
      },
      "additionalClassifications": {
        "legalName": "Додатковий класифікатор"
      },
      "location": {
        "latitude": {
          "legalName": "Широта"
        },
        "longitude": {
          "legalName": "Довгота"
        },
        "elevation": {
          "legalName": "Висота"
        },
        "legalName": "Географічні координати місця розташування"
      },
      "basicSellItemType": {
        "legalName": "Тип об'єкту майна"
      },
      "itemProps": {
        "legalName": "Технічні характеристики, нерухомість"
      }
    },
    "Award": {
      "id": {
        "legalName": "Ідентифікатор кваліфікації учасника аукціону"
      },
      "bidId": {
        "legalName": "Ідентифікатор заяви на участь учасника аукціону"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
      },
      "status": {
        "legalName": "Статус кваліфікації учасника аукціону"
      },
      "terminationReason": {
        "legalName": "Причина дискваліфікації/відмови учаснику"
      },
      "datePublished": {
        "legalName": "Дата початку кваліфікації учасника аукціону"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Цінова пропозиція"
      },
      "buyers": {
        "legalName": "Інформація про учасника"
      },
      "documents": {
        "legalName": "Документи до аварду"
      },
      "dateModified": {
        "legalName": "Дата редагування"
      },
      "signingPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період підписання договору"
      },
      "verificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період підписання протоколу"
      }
    },
    "Contract": {
      "id": {
        "legalName": "Внутрішній ідентифікатор контракту"
      },
      "awardId": {
        "legalName": "Ідентифікатор переможця"
      },
      "contractNumber": {
        "legalName": "Номер договору"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва договору"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис договору"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Вартість договору"
      },
      "contractTotalValue": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Заключна вартість договору"
      },
      "buyers": {
        "legalName": "Переможець"
      },
      "status": {
        "legalName": "Статус договору"
      },
      "dateSigned": {
        "legalName": "Дата підписання договору"
      },
      "datePublished": {
        "legalName": "Дата створення об'єкта договіру"
      },
      "dateModified": {
        "legalName": "Остання дата редагування договіру"
      },
      "contractTime": {
        "dateFrom": {
          "legalName": "Період з"
        },
        "dateTill": {
          "legalName": "Період по"
        },
        "legalName": "Строк дії договору"
      },
      "documents": {
        "legalName": "Документи та додатки пов’язані з договором"
      }
    }
  },
  "basicSell-english": {
    "Bid": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "id": {
        "legalName": "Ідентифікатор заяви на участь"
      },
      "bidders": {
        "legalName": "Інформація про учасника"
      },
      "datePublished": {
        "legalName": "Дата заяви на участь"
      },
      "dateModified": {
        "legalName": "Остання дата редагування ставки"
      },
      "status": {
        "legalName": "Статус заяви на участь"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Цінова пропозиція"
      },
      "documents": {
        "legalName": "Документи до заяви про участь"
      },
      "participationUrl": {
        "legalName": "Веб-адреса для участі в аукціоні"
      },
      "inactiveBidDate": {
        "legalName": "Дата деактивації заяви на участь"
      }
    },
    "basicSellEnglishProcedure": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "_id": {
        "legalName": "Внутрішній ідентифікатор аукціону"
      },
      "datePublished": {
        "legalName": "Дата публікації процедури"
      },
      "dateModified": {
        "legalName": "Остання дата зміни процедури"
      },
      "auctionId": {
        "legalName": "Ідентифікатор аукціону"
      },
      "previousAuctionId": {
        "legalName": "Ідентифікатор попереднього аукціону"
      },
      "sellingMethod": {
        "legalName": "Тип процедури"
      },
      "sellingEntity": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Найменування Організатора аукціону"
        },
        "identifier": {
          "scheme": {
            "legalName": "Тип ідентифікації Організатора аукціону"
          },
          "legalName": "Ідентифікатор Організатора аукціону",
          "id": {
            "legalName": "Ідентифікатор Організатора аукціону"
          }
        },
        "address": {
          "addressID": {
            "scheme": {
              "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
            },
            "name": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Назва адміністративно-територіальних об’єктів України"
            },
            "id": {
              "legalName": "Код адміністративно-територіальних об’єктів України"
            },
            "legalName": "Код об’єктів адміністративно-територіального устрою України"
          },
          "streetAddress": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Адреса"
          },
          "locality": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Населений пункт"
          },
          "region": {
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Область"
          },
          "postalCode": {
            "legalName": "Поштовий індекс"
          },
          "countryName": {
            "en_US": {
              "legalName": "Назва англійською"
            }
          },
          "legalName": "Місцезнаходження Організатора аукціону"
        },
        "contactPoint": {
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "ПІБ"
          },
          "email": {
            "legalName": "Адреса електронної пошти"
          },
          "telephone": {
            "legalName": "Номер телефону"
          },
          "faxNumber": {
            "legalName": "Номер факсу"
          },
          "url": {
            "legalName": "Веб адреса"
          },
          "legalName": "Представник"
        },
        "representativeInfo": {
          "legalName": "Інформація щодо підтвердження повноважень"
        },
        "legalName": "Інформація про Організатора аукціону"
      },
      "lotId": {
        "legalName": "Номер лота"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва аукціону"
      },
      "auctionUrl": {
        "legalName": "Веб-адреса для перегляду аукціону"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис аукціону"
      },
      "accessDetails": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
      },
      "bankAccount": {
        "legalName": "Банківський рахунок організатора"
      },
      "x_documentRequirements": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Перелік та вимоги до оформлення документів"
      },
      "x_additionalInformation": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Додаткові відомості"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Стартова ціна лота"
      },
      "discount": {
        "previousAuctionValue": {
          "currency": {
            "legalName": "Валюта"
          },
          "amount": {
            "legalName": "Сума"
          },
          "valueAddedTaxIncluded": {
            "legalName": "Податок"
          },
          "legalName": "Стартова вартість попереднього аукціону"
        },
        "discountPercent": {
          "legalName": "Розмір знижки від попереднього аукціону, %"
        },
        "discount": {
          "legalName": "Знижка"
        },
        "legalName": "Знижка"
      },
      "guarantee": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Гарантійний внесок"
      },
      "minimalStep": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Розмір кроку аукціону"
      },
      "minNumberOfQualifiedBids": {
        "legalName": "Мінімальна кількість заяв"
      },
      "tenderAttempts": {
        "legalName": "Лот виставляється"
      },
      "items": {
        "legalName": "Склад лота"
      },
      "documents": {
        "legalName": "Всі документи аукціону та пов'язані додатки"
      },
      "bids": {
        "legalName": "Заява на участь"
      },
      "questions": {
        "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
      },
      "awards": {
        "legalName": "Рішення щодо кваліфікації учасників"
      },
      "contracts": {
        "legalName": "Договір"
      },
      "rectificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період редагування лота"
      },
      "enquiryPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період відповідей"
      },
      "tenderPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період подання заяви на участь в аукціоні"
      },
      "auctionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Дата та час проведення аукціону"
      },
      "questionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період запитань"
      },
      "qualificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період кваліфікації"
      },
      "status": {
        "legalName": "Статус процедури"
      },
      "cancellations": {
        "legalName": "Скасування Аукціону"
      },
      "registrationFee": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Реєстраційний внесок"
      }
    }
  },
  "basicSell-dutch": {
    "Bid": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "id": {
        "legalName": "Ідентифікатор заяви на участь"
      },
      "bidders": {
        "legalName": "Інформація про учасника"
      },
      "datePublished": {
        "legalName": "Дата заяви на участь"
      },
      "dateModified": {
        "legalName": "Остання дата редагування ставки"
      },
      "status": {
        "legalName": "Статус заяви на участь"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Цінова пропозиція"
      },
      "documents": {
        "legalName": "Документи до заяви про участь"
      },
      "participationUrl": {
        "legalName": "Веб-адреса для участі в аукціоні"
      },
      "inactiveBidDate": {
        "legalName": "Дата деактивації заяви на участь"
      }
    },
    "BasicSellDutchProcedure": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "_id": {
        "legalName": "Внутрішній ідентифікатор аукціону"
      },
      "datePublished": {
        "legalName": "Дата публікації процедури"
      },
      "dateModified": {
        "legalName": "Остання дата зміни процедури"
      },
      "auctionId": {
        "legalName": "Ідентифікатор аукціону"
      },
      "previousAuctionId": {
        "legalName": "Ідентифікатор попереднього аукціону"
      },
      "sellingMethod": {
        "legalName": "Тип процедури"
      },
      "sellingEntity": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Найменування Організатора аукціону"
        },
        "identifier": {
          "scheme": {
            "legalName": "Тип ідентифікації Організатора аукціону"
          },
          "legalName": "Ідентифікатор Організатора аукціону",
          "id": {
            "legalName": "Ідентифікатор Організатора аукціону"
          }
        },
        "address": {
          "addressID": {
            "scheme": {
              "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
            },
            "name": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Назва адміністративно-територіальних об’єктів України"
            },
            "id": {
              "legalName": "Код адміністративно-територіальних об’єктів України"
            },
            "legalName": "Код об’єктів адміністративно-територіального устрою України"
          },
          "streetAddress": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Адреса"
          },
          "locality": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Населений пункт"
          },
          "region": {
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Область"
          },
          "postalCode": {
            "legalName": "Поштовий індекс"
          },
          "countryName": {
            "en_US": {
              "legalName": "Назва англійською"
            }
          },
          "legalName": "Місцезнаходження Організатора аукціону"
        },
        "contactPoint": {
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "ПІБ"
          },
          "email": {
            "legalName": "Адреса електронної пошти"
          },
          "telephone": {
            "legalName": "Номер телефону"
          },
          "faxNumber": {
            "legalName": "Номер факсу"
          },
          "url": {
            "legalName": "Веб адреса"
          },
          "legalName": "Представник"
        },
        "representativeInfo": {
          "legalName": "Інформація щодо підтвердження повноважень"
        },
        "legalName": "Інформація про Організатора аукціону"
      },
      "lotId": {
        "legalName": "Номер лота"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва аукціону"
      },
      "auctionUrl": {
        "legalName": "Веб-адреса для перегляду аукціону"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис аукціону"
      },
      "accessDetails": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
      },
      "bankAccount": {
        "legalName": "Банківський рахунок організатора"
      },
      "x_documentRequirements": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Перелік та вимоги до оформлення документів"
      },
      "x_additionalInformation": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Інші відомості"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Стартова ціна лота"
      },
      "discount": {
        "previousAuctionValue": {
          "currency": {
            "legalName": "Валюта"
          },
          "amount": {
            "legalName": "Сума"
          },
          "valueAddedTaxIncluded": {
            "legalName": "Податок"
          },
          "legalName": "Стартова вартість попереднього аукціону"
        },
        "discountPercent": {
          "legalName": "Розмір знижки від попереднього аукціону, %"
        },
        "discount": {
          "legalName": "Знижка"
        },
        "legalName": "Знижка"
      },
      "guarantee": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Гарантійний внесок"
      },
      "minimalStep": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Розмір кроку аукціону"
      },
      "dutchStep": {
        "dutchStepQuantity": {
          "legalName": "Кількість кроків"
        },
        "dutchStepPercent": {
          "legalName": "Відсоток зниження, %"
        },
        "dutchStepValue": {
          "currency": {
            "legalName": "Валюта"
          },
          "amount": {
            "legalName": "Сума"
          },
          "valueAddedTaxIncluded": {
            "legalName": "Податок"
          },
          "legalName": "Розмір кроку голландського раунду, грн"
        },
        "legalName": "Розмір кроку аукціону"
      },
      "tenderAttempts": {
        "legalName": "Лот виставляється"
      },
      "items": {
        "legalName": "Склад лота"
      },
      "documents": {
        "legalName": "Всі документи аукціону та пов'язані додатки"
      },
      "bids": {
        "legalName": "Заява на участь"
      },
      "questions": {
        "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
      },
      "awards": {
        "legalName": "Рішення щодо кваліфікації учасників"
      },
      "contracts": {
        "legalName": "Договір"
      },
      "rectificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період редагування лота"
      },
      "enquiryPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період відповідей"
      },
      "tenderPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період подання заяви на участь в аукціоні"
      },
      "auctionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Дата та час проведення аукціону"
      },
      "questionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період запитань"
      },
      "qualificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період кваліфікації"
      },
      "status": {
        "legalName": "Статус процедури"
      },
      "cancellations": {
        "legalName": "Скасування Аукціону"
      },
      "registrationFee": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Реєстраційний внесок"
      }
    }
  },
  "dgf": {
    "AdditionalClassification": {
      "scheme": {
        "legalName": "Схема додаткового класифікатору"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис додаткового класифікатору"
      },
      "id": {
        "legalName": "Код додаткового класифікатору"
      }
    },
    "Classification": {
      "scheme": {
        "legalName": "Схема класифікатора"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис класифікатора"
      },
      "id": {
        "legalName": "Код класифікатора"
      }
    },
    "Item": {
      "id": {
        "legalName": "Внутрішній ідентифікатор об'єкта"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис об'єкта"
      },
      "classification": {
        "scheme": {
          "legalName": "Схема класифікатора"
        },
        "description": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Опис класифікатора"
        },
        "id": {
          "legalName": "Код класифікатора"
        },
        "legalName": "Класифікатор об'єкта"
      },
      "unit": {
        "code": {
          "legalName": "Код одиниці виміру"
        },
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Назва одиниці виміру"
        },
        "legalName": "Одиниці виміру"
      },
      "quantity": {
        "legalName": "Кількість одиниць"
      },
      "address": {
        "addressID": {
          "scheme": {
            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
          },
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Назва адміністративно-територіальних об’єктів України"
          },
          "id": {
            "legalName": "Код адміністративно-територіальних об’єктів України"
          },
          "legalName": "Код об’єктів адміністративно-територіального устрою України"
        },
        "streetAddress": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Адреса"
        },
        "locality": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Населений пункт"
        },
        "region": {
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Область"
        },
        "postalCode": {
          "legalName": "Поштовий індекс"
        },
        "countryName": {
          "en_US": {
            "legalName": "Назва англійською"
          }
        },
        "legalName": "Місцезнаходження"
      },
      "additionalClassifications": {
        "legalName": "Додатковий класифікатор"
      },
      "location": {
        "latitude": {
          "legalName": "Широта"
        },
        "longitude": {
          "legalName": "Довгота"
        },
        "elevation": {
          "legalName": "Висота"
        },
        "legalName": "Географічні координати місця розташування"
      },
      "dgfItemType": {
        "legalName": "Тип об'єкта"
      },
      "itemProps": {
        "legalName": "Технічні характеристики активу"
      },
      "registrationDetails": {
        "registrationStatus": {
          "legalName": "Cтатус реєстрації"
        },
        "registrationID": {
          "legalName": "Номер запису"
        },
        "registrationDate": {
          "legalName": "Дата реєстрації"
        },
        "legalName": "Інформація щодо реєстрації"
      }
    },
    "Award": {
      "id": {
        "legalName": "Ідентифікатор кваліфікації учасника аукціону"
      },
      "bidId": {
        "legalName": "Ідентифікатор заяви на участь учасника аукціону"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
      },
      "status": {
        "legalName": "Статус кваліфікації учасника аукціону"
      },
      "terminationReason": {
        "legalName": "Причина дискваліфікації/відмови учаснику"
      },
      "datePublished": {
        "legalName": "Дата початку кваліфікації учасника аукціону"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Цінова пропозиція"
      },
      "buyers": {
        "legalName": "Інформація про учасника"
      },
      "documents": {
        "legalName": "Документи до аварду"
      },
      "dateModified": {
        "legalName": "Дата редагування"
      },
      "signingPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період підписання договору"
      }
    },
    "Prolongation": {
      "id": {
        "legalName": "Ідентифікатор об'єкту пролонгації"
      },
      "awardId": {
        "legalName": "Ідентифікатор переможця"
      },
      "decisionId": {
        "legalName": "Номер рішення згідно якого проводиться пролонгація"
      },
      "reason": {
        "legalName": "Причина рішення згідно якого проводиться пролонгація"
      },
      "description": {
        "legalName": "Пояснення"
      },
      "prolongationDate": {
        "legalName": "Дата набуття чинності рішення"
      },
      "prolongationTerm": {
        "legalName": "Строк пролонгації"
      },
      "documents": {
        "legalName": "Документи щодо пролонгації"
      }
    },
    "Contract": {
      "id": {
        "legalName": "Внутрішній ідентифікатор контракту"
      },
      "awardId": {
        "legalName": "Ідентифікатор переможця"
      },
      "contractNumber": {
        "legalName": "Номер договору"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва договору"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис договору"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Вартість договору"
      },
      "contractTotalValue": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Заключна вартість договору"
      },
      "buyers": {
        "legalName": "Переможець"
      },
      "status": {
        "legalName": "Статус договору"
      },
      "dateSigned": {
        "legalName": "Дата підписання договору"
      },
      "datePublished": {
        "legalName": "Дата створення об'єкта договіру"
      },
      "dateModified": {
        "legalName": "Остання дата редагування договіру"
      },
      "contractTime": {
        "dateFrom": {
          "legalName": "Період з"
        },
        "dateTill": {
          "legalName": "Період по"
        },
        "legalName": "Строк дії договору"
      },
      "documents": {
        "legalName": "Документи та додатки пов’язані з договором"
      },
      "datePaid": {
        "legalName": "Дата сплати"
      }
    },
    "Cancellation": {
      "id": {
        "legalName": "Ідентифікатор об'єкта скасування"
      },
      "reason": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Причина скасування Аукціону"
      },
      "datePublished": {
        "legalName": "Дата скасування"
      }
    }
  },
  "dgf-english": {
    "Bid": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "id": {
        "legalName": "Ідентифікатор заяви на участь"
      },
      "bidders": {
        "legalName": "Інформація про учасника"
      },
      "datePublished": {
        "legalName": "Дата заяви на участь"
      },
      "dateModified": {
        "legalName": "Остання дата редагування ставки"
      },
      "status": {
        "legalName": "Статус заяви на участь"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Цінова пропозиція"
      },
      "documents": {
        "legalName": "Документи до заяви про участь"
      },
      "participationUrl": {
        "legalName": "Веб-адреса для участі в аукціоні"
      },
      "initialValueAmount": {
        "legalName": "Початкова сума"
      }
    },
    "DgfEnglishProcedure": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "_id": {
        "legalName": "Внутрішній ідентифікатор аукціону"
      },
      "datePublished": {
        "legalName": "Дата публікації процедури"
      },
      "dateModified": {
        "legalName": "Остання дата зміни процедури"
      },
      "auctionId": {
        "legalName": "Ідентифікатор аукціону"
      },
      "previousAuctionId": {
        "legalName": "Номер попереднього аукціону"
      },
      "sellingMethod": {
        "legalName": "Тип процедури"
      },
      "sellingEntity": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Найменування Організатора аукціону"
        },
        "identifier": {
          "scheme": {
            "legalName": "Тип ідентифікації Організатора аукціону"
          },
          "legalName": "Ідентифікатор Організатора аукціону",
          "id": {
            "legalName": "Ідентифікатор Організатора аукціону"
          }
        },
        "address": {
          "addressID": {
            "scheme": {
              "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
            },
            "name": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Назва адміністративно-територіальних об’єктів України"
            },
            "id": {
              "legalName": "Код адміністративно-територіальних об’єктів України"
            },
            "legalName": "Код об’єктів адміністративно-територіального устрою України"
          },
          "streetAddress": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Адреса"
          },
          "locality": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Населений пункт"
          },
          "region": {
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Область"
          },
          "postalCode": {
            "legalName": "Поштовий індекс"
          },
          "countryName": {
            "en_US": {
              "legalName": "Назва англійською"
            }
          },
          "legalName": "Місцезнаходження Організатора аукціону"
        },
        "contactPoint": {
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "ПІБ"
          },
          "email": {
            "legalName": "Адреса електронної пошти"
          },
          "telephone": {
            "legalName": "Номер телефону"
          },
          "faxNumber": {
            "legalName": "Номер факсу"
          },
          "url": {
            "legalName": "Веб адреса"
          },
          "legalName": "Представник"
        },
        "representativeInfo": {
          "legalName": "Інформація щодо підтвердження повноважень"
        },
        "legalName": "Інформація про Організатора аукціону"
      },
      "lotId": {
        "legalName": "Номер лота"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва аукціону"
      },
      "auctionUrl": {
        "legalName": "Веб-адреса для перегляду аукціону"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис аукціону"
      },
      "eligibilityCriteria": {
        "legalName": "Умови участі фінансових установ"
      },
      "decision": {
        "decisionId": {
          "legalName": "Номер рішення"
        },
        "decisionDate": {
          "legalName": "Дата рішення"
        },
        "legalName": "Рішення ФГВФО про затвердження умов продажу"
      },
      "accessDetails": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
      },
      "bankAccount": {
        "legalName": "Банківський рахунок"
      },
      "x_documentRequirements": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Перелік та вимоги до оформлення документів"
      },
      "x_additionalInformation": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Додаткові відомості"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Стартова ціна лота"
      },
      "discount": {
        "previousAuctionValue": {
          "currency": {
            "legalName": "Валюта"
          },
          "amount": {
            "legalName": "Сума"
          },
          "valueAddedTaxIncluded": {
            "legalName": "Податок"
          },
          "legalName": "Стартова вартість попереднього аукціону"
        },
        "discountPercent": {
          "legalName": "Розмір знижки від попереднього аукціону, %"
        },
        "discount": {
          "legalName": "Знижка"
        },
        "legalName": "Знижка"
      },
      "guarantee": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Гарантійний внесок"
      },
      "minimalStep": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Розмір кроку аукціону"
      },
      "minNumberOfQualifiedBids": {
        "legalName": "Мінімальна кількість заяв"
      },
      "tenderAttempts": {
        "legalName": "Лот виставляється"
      },
      "virtualDataRoom": {
        "legalName": "Посилання на VDR (Virtual Data Room)"
      },
      "dgfPublicAssetCertificate": {
        "legalName": "Посилання на публічний паспорт активу"
      },
      "x_dgfPlatformLegalDetails": {
        "legalName": "Юридична інформація майданчиків"
      },
      "items": {
        "legalName": "Склад лота"
      },
      "documents": {
        "legalName": "Всі документи аукціону та пов'язані додатки"
      },
      "bids": {
        "legalName": "Заява на участь"
      },
      "questions": {
        "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
      },
      "prolongations": {
        "legalName": "Інформація щодо пролонгації"
      },
      "awards": {
        "legalName": "Рішення щодо кваліфікації учасників"
      },
      "contracts": {
        "legalName": "Договір"
      },
      "rectificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період редагування лота"
      },
      "enquiryPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період відповідей"
      },
      "tenderPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період подання заяви на участь в аукціоні"
      },
      "auctionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Дата та час проведення аукціону"
      },
      "questionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період запитань"
      },
      "qualificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період кваліфікації"
      },
      "status": {
        "legalName": "Статус процедури"
      },
      "unsuccessfulReason": {
        "legalName": "Причина, за якою аукціон не відбувся"
      },
      "cancellations": {
        "legalName": "Скасування Аукціону"
      }
    }
  },
  "dgf-dutch": {
    "Bid": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "id": {
        "legalName": "Ідентифікатор заяви на участь"
      },
      "bidders": {
        "legalName": "Інформація про учасника"
      },
      "datePublished": {
        "legalName": "Дата заяви на участь"
      },
      "dateModified": {
        "legalName": "Остання дата редагування ставки"
      },
      "status": {
        "legalName": "Статус заяви на участь"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Цінова пропозиція"
      },
      "documents": {
        "legalName": "Документи до заяви про участь"
      },
      "participationUrl": {
        "legalName": "Веб-адреса для участі в аукціоні"
      }
    },
    "DgfDutchProcedure": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "_id": {
        "legalName": "Внутрішній ідентифікатор аукціону"
      },
      "datePublished": {
        "legalName": "Дата публікації процедури"
      },
      "dateModified": {
        "legalName": "Остання дата зміни процедури"
      },
      "auctionId": {
        "legalName": "Ідентифікатор аукціону"
      },
      "previousAuctionId": {
        "legalName": "Номер попереднього аукціону"
      },
      "sellingMethod": {
        "legalName": "Тип процедури"
      },
      "sellingEntity": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Найменування Організатора аукціону"
        },
        "identifier": {
          "scheme": {
            "legalName": "Тип ідентифікації Організатора аукціону"
          },
          "legalName": "Ідентифікатор Організатора аукціону",
          "id": {
            "legalName": "Ідентифікатор Організатора аукціону"
          }
        },
        "address": {
          "addressID": {
            "scheme": {
              "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
            },
            "name": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Назва адміністративно-територіальних об’єктів України"
            },
            "id": {
              "legalName": "Код адміністративно-територіальних об’єктів України"
            },
            "legalName": "Код об’єктів адміністративно-територіального устрою України"
          },
          "streetAddress": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Адреса"
          },
          "locality": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Населений пункт"
          },
          "region": {
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Область"
          },
          "postalCode": {
            "legalName": "Поштовий індекс"
          },
          "countryName": {
            "en_US": {
              "legalName": "Назва англійською"
            }
          },
          "legalName": "Місцезнаходження Організатора аукціону"
        },
        "contactPoint": {
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "ПІБ"
          },
          "email": {
            "legalName": "Адреса електронної пошти"
          },
          "telephone": {
            "legalName": "Номер телефону"
          },
          "faxNumber": {
            "legalName": "Номер факсу"
          },
          "url": {
            "legalName": "Веб адреса"
          },
          "legalName": "Представник"
        },
        "representativeInfo": {
          "legalName": "Інформація щодо підтвердження повноважень"
        },
        "legalName": "Інформація про Організатора аукціону"
      },
      "lotId": {
        "legalName": "Номер лота"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва аукціону"
      },
      "auctionUrl": {
        "legalName": "Веб-адреса для перегляду аукціону"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис аукціону"
      },
      "eligibilityCriteria": {
        "legalName": "Умови участі фінансових установ"
      },
      "decision": {
        "decisionId": {
          "legalName": "Номер рішення"
        },
        "decisionDate": {
          "legalName": "Дата рішення"
        },
        "legalName": "Рішення ФГВФО про затвердження умов продажу"
      },
      "accessDetails": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
      },
      "bankAccount": {
        "legalName": "Банківський рахунок"
      },
      "x_documentRequirements": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Перелік та вимоги до оформлення документів"
      },
      "x_additionalInformation": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Додаткові відомості"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Стартова ціна лота"
      },
      "discount": {
        "previousAuctionValue": {
          "currency": {
            "legalName": "Валюта"
          },
          "amount": {
            "legalName": "Сума"
          },
          "valueAddedTaxIncluded": {
            "legalName": "Податок"
          },
          "legalName": "Стартова вартість попереднього аукціону"
        },
        "discountPercent": {
          "legalName": "Розмір знижки від попереднього аукціону, %"
        },
        "discount": {
          "legalName": "Знижка"
        },
        "legalName": "Знижка"
      },
      "guarantee": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Гарантійний внесок"
      },
      "minimalStep": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Розмір кроку аукціону"
      },
      "dutchStep": {
        "dutchStepQuantity": {
          "legalName": "Кількість кроків"
        },
        "dutchStepPercent": {
          "legalName": "Відсоток зниження, %"
        },
        "dutchStepValue": {
          "currency": {
            "legalName": "Валюта"
          },
          "amount": {
            "legalName": "Сума"
          },
          "valueAddedTaxIncluded": {
            "legalName": "Податок"
          },
          "legalName": "Розмір кроку голландського раунду, грн"
        },
        "legalName": "Розмір кроку аукціону"
      },
      "tenderAttempts": {
        "legalName": "Лот виставляється"
      },
      "virtualDataRoom": {
        "legalName": "Посилання на VDR (Virtual Data Room)"
      },
      "dgfPublicAssetCertificate": {
        "legalName": "Посилання на публічний паспорт активу"
      },
      "x_dgfPlatformLegalDetails": {
        "legalName": "Юридична інформація майданчиків"
      },
      "items": {
        "legalName": "Склад лота"
      },
      "documents": {
        "legalName": "Всі документи аукціону та пов'язані додатки"
      },
      "bids": {
        "legalName": "Заява на участь"
      },
      "questions": {
        "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
      },
      "prolongations": {
        "legalName": "Інформація щодо пролонгації"
      },
      "awards": {
        "legalName": "Рішення щодо кваліфікації учасників"
      },
      "contracts": {
        "legalName": "Договір"
      },
      "rectificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період редагування лота"
      },
      "enquiryPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період відповідей"
      },
      "tenderPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період подання заяви на участь в аукціоні"
      },
      "auctionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Дата та час проведення аукціону"
      },
      "questionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період запитань"
      },
      "qualificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період кваліфікації"
      },
      "status": {
        "legalName": "Статус процедури"
      },
      "unsuccessfulReason": {
        "legalName": "Причина, за якою аукціон не відбувся"
      },
      "cancellations": {
        "legalName": "Скасування Аукціону"
      }
    }
  },
  "legitimatePropertyLease": {
    "Value": {
      "currency": {
        "legalName": "Валюта"
      },
      "amount": {
        "legalName": "Сума"
      },
      "valueAddedTaxIncluded": {
        "legalName": "Податок"
      }
    },
    "RelatedOrganizations": {
      "propertyOwner": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Найменування юридичної особи"
        },
        "identifier": {
          "scheme": {
            "legalName": "Тип ідентифікації юридичної особи"
          },
          "legalName": "Ідентифікатор юридичної особи",
          "id": {
            "legalName": "Ідентифікатор юридичної особи"
          }
        },
        "address": {
          "addressID": {
            "scheme": {
              "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
            },
            "name": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Назва адміністративно-територіальних об’єктів України"
            },
            "id": {
              "legalName": "Код адміністративно-територіальних об’єктів України"
            },
            "legalName": "Код об’єктів адміністративно-територіального устрою України"
          },
          "streetAddress": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Адреса"
          },
          "locality": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Населений пункт"
          },
          "region": {
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Область"
          },
          "postalCode": {
            "legalName": "Поштовий індекс"
          },
          "countryName": {
            "en_US": {
              "legalName": "Назва англійською"
            }
          },
          "legalName": "Місцезнаходження юридичної особи"
        },
        "contactPoint": {
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "ПІБ"
          },
          "email": {
            "legalName": "Адреса електронної пошти"
          },
          "telephone": {
            "legalName": "Номер телефону"
          },
          "faxNumber": {
            "legalName": "Номер факсу"
          },
          "url": {
            "legalName": "Веб адреса"
          },
          "legalName": "Контактна особа"
        },
        "legalName": "Балансоутримувач"
      },
      "sellingEntity": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Найменування юридичної особи"
        },
        "identifier": {
          "scheme": {
            "legalName": "Тип ідентифікації юридичної особи"
          },
          "legalName": "Ідентифікатор юридичної особи",
          "id": {
            "legalName": "Ідентифікатор юридичної особи"
          }
        },
        "address": {
          "addressID": {
            "scheme": {
              "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
            },
            "name": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Назва адміністративно-територіальних об’єктів України"
            },
            "id": {
              "legalName": "Код адміністративно-територіальних об’єктів України"
            },
            "legalName": "Код об’єктів адміністративно-територіального устрою України"
          },
          "streetAddress": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Адреса"
          },
          "locality": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Населений пункт"
          },
          "region": {
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Область"
          },
          "postalCode": {
            "legalName": "Поштовий індекс"
          },
          "countryName": {
            "en_US": {
              "legalName": "Назва англійською"
            }
          },
          "legalName": "Місцезнаходження юридичної особи"
        },
        "contactPoint": {
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "ПІБ"
          },
          "email": {
            "legalName": "Адреса електронної пошти"
          },
          "telephone": {
            "legalName": "Номер телефону"
          },
          "faxNumber": {
            "legalName": "Номер факсу"
          },
          "url": {
            "legalName": "Веб адреса"
          },
          "legalName": "Контактна особа"
        },
        "legalName": "Орендодавець"
      },
      "currentTenant": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Повна юридична назва організації або ПІБ"
        },
        "identifier": {
          "scheme": {
            "legalName": "Ідентифікатори організації"
          },
          "legalName": "Ідентифікатори організації або особи",
          "id": {
            "legalName": "Код ЄДРПОУ або ІПН або паспорт"
          }
        },
        "address": {
          "addressID": {
            "scheme": {
              "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
            },
            "name": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Назва адміністративно-територіальних об’єктів України"
            },
            "id": {
              "legalName": "Код адміністративно-територіальних об’єктів України"
            },
            "legalName": "Код об’єктів адміністративно-територіального устрою України"
          },
          "streetAddress": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Адреса"
          },
          "locality": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Населений пункт"
          },
          "region": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Область"
          },
          "postalCode": {
            "legalName": "Поштовий індекс"
          },
          "countryName": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Країна"
          },
          "legalName": "Адреса"
        },
        "representativeInfo": {
          "legalName": "Інформація щодо підтвердження повноважень"
        },
        "contactPoint": {
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "ПІБ"
          },
          "email": {
            "legalName": "Адреса електронної пошти"
          },
          "telephone": {
            "legalName": "Номер телефону"
          },
          "faxNumber": {
            "legalName": "Номер факсу"
          },
          "url": {
            "legalName": "Веб адреса"
          },
          "legalName": "Контактна особа"
        },
        "currentContractTime": {
          "dateFrom": {
            "legalName": "Період з"
          },
          "dateTill": {
            "legalName": "Період по"
          },
          "legalName": "Дата укладення та закінчення договору оренди"
        }
      },
      "ownershipType": {
        "legalName": "Тип власності"
      }
    },
    "RelatedOrganizationsPriorityLease": {
      "propertyOwner": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Найменування юридичної особи"
        },
        "identifier": {
          "scheme": {
            "legalName": "Тип ідентифікації юридичної особи"
          },
          "legalName": "Ідентифікатор юридичної особи",
          "id": {
            "legalName": "Ідентифікатор юридичної особи"
          }
        },
        "address": {
          "addressID": {
            "scheme": {
              "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
            },
            "name": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Назва адміністративно-територіальних об’єктів України"
            },
            "id": {
              "legalName": "Код адміністративно-територіальних об’єктів України"
            },
            "legalName": "Код об’єктів адміністративно-територіального устрою України"
          },
          "streetAddress": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Адреса"
          },
          "locality": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Населений пункт"
          },
          "region": {
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Область"
          },
          "postalCode": {
            "legalName": "Поштовий індекс"
          },
          "countryName": {
            "en_US": {
              "legalName": "Назва англійською"
            }
          },
          "legalName": "Місцезнаходження юридичної особи"
        },
        "contactPoint": {
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "ПІБ"
          },
          "email": {
            "legalName": "Адреса електронної пошти"
          },
          "telephone": {
            "legalName": "Номер телефону"
          },
          "faxNumber": {
            "legalName": "Номер факсу"
          },
          "url": {
            "legalName": "Веб адреса"
          },
          "legalName": "Контактна особа"
        },
        "legalName": "Балансоутримувач"
      },
      "sellingEntity": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Найменування юридичної особи"
        },
        "identifier": {
          "scheme": {
            "legalName": "Тип ідентифікації юридичної особи"
          },
          "legalName": "Ідентифікатор юридичної особи",
          "id": {
            "legalName": "Ідентифікатор юридичної особи"
          }
        },
        "address": {
          "addressID": {
            "scheme": {
              "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
            },
            "name": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Назва адміністративно-територіальних об’єктів України"
            },
            "id": {
              "legalName": "Код адміністративно-територіальних об’єктів України"
            },
            "legalName": "Код об’єктів адміністративно-територіального устрою України"
          },
          "streetAddress": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Адреса"
          },
          "locality": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Населений пункт"
          },
          "region": {
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Область"
          },
          "postalCode": {
            "legalName": "Поштовий індекс"
          },
          "countryName": {
            "en_US": {
              "legalName": "Назва англійською"
            }
          },
          "legalName": "Місцезнаходження юридичної особи"
        },
        "contactPoint": {
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "ПІБ"
          },
          "email": {
            "legalName": "Адреса електронної пошти"
          },
          "telephone": {
            "legalName": "Номер телефону"
          },
          "faxNumber": {
            "legalName": "Номер факсу"
          },
          "url": {
            "legalName": "Веб адреса"
          },
          "legalName": "Контактна особа"
        },
        "legalName": "Орендодавець"
      },
      "currentTenant": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Повна юридична назва організації або ПІБ"
        },
        "identifier": {
          "scheme": {
            "legalName": "Ідентифікатори організації"
          },
          "legalName": "Ідентифікатори організації або особи",
          "id": {
            "legalName": "Код ЄДРПОУ або ІПН або паспорт"
          }
        },
        "address": {
          "addressID": {
            "scheme": {
              "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
            },
            "name": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Назва адміністративно-територіальних об’єктів України"
            },
            "id": {
              "legalName": "Код адміністративно-територіальних об’єктів України"
            },
            "legalName": "Код об’єктів адміністративно-територіального устрою України"
          },
          "streetAddress": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Адреса"
          },
          "locality": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Населений пункт"
          },
          "region": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Область"
          },
          "postalCode": {
            "legalName": "Поштовий індекс"
          },
          "countryName": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Країна"
          },
          "legalName": "Адреса"
        },
        "representativeInfo": {
          "legalName": "Інформація щодо підтвердження повноважень"
        },
        "currentContractTime": {
          "dateFrom": {
            "legalName": "Період з"
          },
          "dateTill": {
            "legalName": "Період по"
          },
          "legalName": "Дата укладення та закінчення договору оренди"
        }
      },
      "ownershipType": {
        "legalName": "Тип власності"
      }
    },
    "RegistrationDetails": {
      "registrationStatus": {
        "legalName": "Стан державної реєстрації об''єкту"
      },
      "registrationID": {
        "legalName": "Номер запису"
      },
      "registrationDate": {
        "legalName": "Дата реєстрації"
      }
    },
    "BankAccounts": {
      "bankAccountsUa": {
        "legalName": "Банківські рахунки в національній валюті"
      },
      "bankAccountsForeign": {
        "legalName": "Банківські рахунки в іноземній валюті"
      }
    },
    "Item": {
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва об'єкта"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис об'єкта"
      },
      "classification": {
        "scheme": {
          "legalName": "Схема класифікатора"
        },
        "description": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Опис коду класифікатора"
        },
        "id": {
          "legalName": "Код класифікатора"
        },
        "legalName": "Основний класифікатор об'єкта"
      },
      "additionalClassification": {
        "description": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Опис додаткового класифікатору"
        },
        "legalName": "Додатковий класифікатор об'єкта"
      },
      "address": {
        "addressID": {
          "scheme": {
            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
          },
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Назва адміністративно-територіальних об’єктів України"
          },
          "id": {
            "legalName": "Код адміністративно-територіальних об’єктів України"
          },
          "legalName": "Код об’єктів адміністративно-територіального устрою України"
        },
        "streetAddress": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Адреса"
        },
        "locality": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Населений пункт"
        },
        "region": {
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Область"
        },
        "postalCode": {
          "legalName": "Поштовий індекс"
        },
        "countryName": {
          "en_US": {
            "legalName": "Назва англійською"
          }
        },
        "legalName": "Місцезнаходження"
      },
      "location": {
        "latitude": {
          "legalName": "Широта"
        },
        "longitude": {
          "legalName": "Довгота"
        },
        "elevation": {
          "legalName": "Висота"
        },
        "legalName": "Координати"
      },
      "registrationDetails": {
        "registrationStatus": {
          "legalName": "Стан державної реєстрації об''єкту"
        },
        "registrationID": {
          "legalName": "Номер запису"
        },
        "registrationDate": {
          "legalName": "Дата реєстрації"
        },
        "legalName": "Інформація щодо реєстрації"
      }
    },
    "RealEstateItem": {
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва об'єкта"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис об'єкта"
      },
      "classification": {
        "scheme": {
          "legalName": "Схема класифікатора"
        },
        "description": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Опис коду класифікатора"
        }
      },
      "additionalClassification": {
        "description": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Опис додаткового класифікатору"
        },
        "legalName": "Додатковий класифікатор об'єкта"
      },
      "address": {
        "addressID": {
          "scheme": {
            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
          },
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Назва адміністративно-територіальних об’єктів України"
          },
          "id": {
            "legalName": "Код адміністративно-територіальних об’єктів України"
          },
          "legalName": "Код об’єктів адміністративно-територіального устрою України"
        },
        "streetAddress": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Адреса"
        },
        "locality": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Населений пункт"
        },
        "region": {
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Область"
        },
        "postalCode": {
          "legalName": "Поштовий індекс"
        },
        "countryName": {
          "en_US": {
            "legalName": "Назва англійською"
          }
        },
        "legalName": "Місцезнаходження"
      },
      "location": {
        "latitude": {
          "legalName": "Широта"
        },
        "longitude": {
          "legalName": "Довгота"
        },
        "elevation": {
          "legalName": "Висота"
        },
        "legalName": "Координати"
      },
      "registrationDetails": {
        "registrationStatus": {
          "legalName": "Стан державної реєстрації об''єкту"
        },
        "registrationID": {
          "legalName": "Номер запису"
        },
        "registrationDate": {
          "legalName": "Дата реєстрації"
        },
        "legalName": "Інформація щодо реєстрації"
      },
      "id": {
        "legalName": "Внутрішній ідентифікатор об'єкта"
      },
      "itemType": {
        "legalName": "Тип об'єкта майна"
      },
      "reProps": {
        "totalBuildingArea": {
          "legalName": "Загальна площа будівлі, кв.м."
        },
        "totalObjectArea": {
          "legalName": "Загальна площа об''єкта в будівлі, кв.м."
        },
        "usableArea": {
          "legalName": "Корисна площа об''єкта в будівлі, кв.м."
        },
        "constructionYear": {
          "legalName": "Рік будівництва"
        },
        "constructionTechnology": {
          "legalName": "Технологія будівництва"
        },
        "livingArea": {
          "legalName": "Житлова площа, кв. м"
        },
        "kitchenArea": {
          "legalName": "Площа кухні, кв. м"
        },
        "landArea": {
          "legalName": "Площа ділянки, кв. м"
        },
        "locationInBuilding": {
          "legalName": "Місце розташування об’єкта в будівлі"
        },
        "floors": {
          "legalName": "Номер поверху або поверхів"
        },
        "generalCondition": {
          "legalName": "Технічний стан об'єкта"
        },
        "serviceElectricity": {
          "legalName": "Чи приєднаний об'єкт до електромережі"
        },
        "powerSupplyCapacity": {
          "legalName": "Потужність електромережі, кВт"
        },
        "powerSupplyClass": {
          "legalName": "Ступінь потужності електромережі"
        },
        "serviceWater": {
          "legalName": "Водозабезпечення присутнє"
        },
        "serviceSewerage": {
          "legalName": "Каналізація присутня"
        },
        "serviceGas": {
          "legalName": "Газифікація присутня"
        },
        "serviceCentralHeating": {
          "legalName": "Централізоване опалення присутнє"
        },
        "serviceAutonomousHeating": {
          "legalName": "Автономне опалення присутнє"
        },
        "serviceHeatingCounter": {
          "legalName": "Лічильник опалення присутній"
        },
        "serviceVentilation": {
          "legalName": "Вентиляція присутня"
        },
        "serviceAirConditioning": {
          "legalName": "Кондиціонування присутнє"
        },
        "servicePhone": {
          "legalName": "Телефонізація присутня"
        },
        "serviceTV": {
          "legalName": "Телебачення присутнє"
        },
        "serviceInternet": {
          "legalName": "Інтернет присутній"
        },
        "serviceElevator": {
          "legalName": "Ліфт присутній"
        },
        "serviceSecurityAlarm": {
          "legalName": "Охоронна сигналізація присутня"
        },
        "serviceFireAlarm": {
          "legalName": "Пожежна сигналізація присутня"
        },
        "servicesDescription": {
          "legalName": "Додаткова інформація щодо комунікацій, що є в об''єкті"
        },
        "servicesAccounting": {
          "legalName": "Інформація про наявність окремих особових рахунків на об'єкт, відкритих постачальниками комунальних послуг"
        },
        "landTax": {
          "legalName": "Інформація щодо компенсації сплати земельного податку за користування земельною ділянкою, на якій розташований об'єкт"
        },
        "legalName": "Технічні характеристики, нерухомість"
      },
      "quantity": {
        "legalName": "Площа об'єкта, що здається в оренду"
      },
      "unit": {
        "code": {
          "legalName": "Одиниці виміру"
        },
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Назва одиниці виміру"
        }
      }
    },
    "JointPropertyComplexItem": {
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва об'єкта"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис об'єкта"
      },
      "classification": {
        "scheme": {
          "legalName": "Схема класифікатора"
        },
        "description": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Опис коду класифікатора"
        },
        "id": {
          "legalName": "Код класифікатора"
        },
        "legalName": "Основний класифікатор об'єкта"
      },
      "additionalClassification": {
        "description": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Опис додаткового класифікатору"
        },
        "legalName": "Додатковий класифікатор об'єкта"
      },
      "address": {
        "addressID": {
          "scheme": {
            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
          },
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Назва адміністративно-територіальних об’єктів України"
          },
          "id": {
            "legalName": "Код адміністративно-територіальних об’єктів України"
          },
          "legalName": "Код об’єктів адміністративно-територіального устрою України"
        },
        "streetAddress": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Адреса"
        },
        "locality": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Населений пункт"
        },
        "region": {
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Область"
        },
        "postalCode": {
          "legalName": "Поштовий індекс"
        },
        "countryName": {
          "en_US": {
            "legalName": "Назва англійською"
          }
        },
        "legalName": "Місцезнаходження"
      },
      "location": {
        "latitude": {
          "legalName": "Широта"
        },
        "longitude": {
          "legalName": "Довгота"
        },
        "elevation": {
          "legalName": "Висота"
        },
        "legalName": "Координати"
      },
      "registrationDetails": {
        "registrationStatus": {
          "legalName": "Стан державної реєстрації об''єкту"
        },
        "registrationID": {
          "legalName": "Номер запису"
        },
        "registrationDate": {
          "legalName": "Дата реєстрації"
        },
        "legalName": "Інформація щодо реєстрації"
      },
      "id": {
        "legalName": "Внутрішній ідентифікатор об'єкта"
      },
      "itemType": {
        "legalName": "Тип об'єкта майна"
      },
      "jpcProps": {
        "quantityAndNomenclature": {
          "legalName": "Обсяг та основна номенклатура продукції (робіт, послуг), у тому числі експортної"
        },
        "workplacesQuantity": {
          "legalName": "Кількість робочих місць, ociб"
        },
        "buildingsInformation": {
          "legalName": "Відомості про будівлі (споруди, приміщення) єдиного майнового комплексу в обсязі, передбаченому для інформації про нерухоме майно"
        },
        "landPlotInformation": {
          "legalName": "Відомості про земельну ділянку, на якій розташовано єдиний майновий комплекс"
        },
        "landProps": {
          "landArea": {
            "legalName": "Площа ділянки, га"
          },
          "cadastralNumber": {
            "legalName": "Кадастровий номер"
          },
          "ownershipType": {
            "legalName": "Тип власності"
          },
          "encumbrances": {
            "legalName": "Наявність обтяжень"
          },
          "jointOwnership": {
            "legalName": "Наявність співвласників"
          },
          "utilitiesAvailability": {
            "legalName": "Наявність інженерних мереж"
          },
          "landIntendedUse": {
            "legalName": "Поточне використання земельної ділянки"
          },
          "legalName": "Характеристики земельної ділянки"
        },
        "mainObligations": {
          "legalName": "Основні зобов’язання (договірні та позадоговірні)"
        },
        "legalName": "Технічні характеристики, єдиний майновий комплекс"
      },
      "quantity": {
        "legalName": "Площа або кількість одиниць"
      },
      "unit": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Назва одиниці виміру"
        },
        "legalName": "Одиниці виміру"
      }
    },
    "VehicleItem": {
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва об'єкта"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис об'єкта"
      },
      "classification": {
        "scheme": {
          "legalName": "Схема класифікатора"
        },
        "description": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Опис коду класифікатора"
        }
      },
      "additionalClassification": {
        "description": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Опис додаткового класифікатору"
        },
        "legalName": "Додатковий класифікатор об'єкта"
      },
      "address": {
        "addressID": {
          "scheme": {
            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
          },
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Назва адміністративно-територіальних об’єктів України"
          },
          "id": {
            "legalName": "Код адміністративно-територіальних об’єктів України"
          },
          "legalName": "Код об’єктів адміністративно-територіального устрою України"
        },
        "streetAddress": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Адреса"
        },
        "locality": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Населений пункт"
        },
        "region": {
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Область"
        },
        "postalCode": {
          "legalName": "Поштовий індекс"
        },
        "countryName": {
          "en_US": {
            "legalName": "Назва англійською"
          }
        },
        "legalName": "Місцезнаходження"
      },
      "location": {
        "latitude": {
          "legalName": "Широта"
        },
        "longitude": {
          "legalName": "Довгота"
        },
        "elevation": {
          "legalName": "Висота"
        },
        "legalName": "Координати"
      },
      "registrationDetails": {
        "registrationStatus": {
          "legalName": "Стан державної реєстрації об''єкту"
        },
        "registrationID": {
          "legalName": "Номер запису"
        },
        "registrationDate": {
          "legalName": "Дата реєстрації"
        },
        "legalName": "Інформація щодо реєстрації"
      },
      "id": {
        "legalName": "Внутрішній ідентифікатор об'єкта"
      },
      "itemType": {
        "legalName": "Тип об'єкта майна"
      },
      "vehicleProps": {
        "brand": {
          "legalName": "Марка транспортного засобу"
        },
        "model": {
          "legalName": "Модель транспортного засобу"
        },
        "productionYear": {
          "legalName": "Рік випуску"
        },
        "engineCapacity": {
          "legalName": "Об’єм двигуна, см³"
        },
        "fuelType": {
          "legalName": "Тип палива"
        },
        "transmission": {
          "legalName": "Тип коробки передач"
        },
        "color": {
          "legalName": "Колір"
        },
        "kilometrage": {
          "legalName": "Пробіг, км"
        },
        "identificationNumber": {
          "legalName": "Номер кузова або шасі (VIN-код)"
        },
        "configuration": {
          "legalName": "Комплектація"
        },
        "condition": {
          "legalName": "Технічний стан"
        },
        "damagedDescription": {
          "legalName": "Опис пошкоджень"
        },
        "damagePresence": {
          "legalName": "Потреба у ремонті"
        },
        "legalName": "Технічні характеристики, транспортний засіб"
      },
      "quantity": {
        "legalName": "Кількість одиниць"
      },
      "unit": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Назва одиниці виміру"
        }
      }
    },
    "OtherPropertyItem": {
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва об'єкта"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис об'єкта"
      },
      "classification": {
        "scheme": {
          "legalName": "Схема класифікатора"
        },
        "description": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Опис коду класифікатора"
        }
      },
      "additionalClassification": {
        "description": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Опис додаткового класифікатору"
        },
        "legalName": "Додатковий класифікатор об'єкта"
      },
      "address": {
        "addressID": {
          "scheme": {
            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
          },
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Назва адміністративно-територіальних об’єктів України"
          },
          "id": {
            "legalName": "Код адміністративно-територіальних об’єктів України"
          },
          "legalName": "Код об’єктів адміністративно-територіального устрою України"
        },
        "streetAddress": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Адреса"
        },
        "locality": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Населений пункт"
        },
        "region": {
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Область"
        },
        "postalCode": {
          "legalName": "Поштовий індекс"
        },
        "countryName": {
          "en_US": {
            "legalName": "Назва англійською"
          }
        },
        "legalName": "Місцезнаходження"
      },
      "location": {
        "latitude": {
          "legalName": "Широта"
        },
        "longitude": {
          "legalName": "Довгота"
        },
        "elevation": {
          "legalName": "Висота"
        },
        "legalName": "Координати"
      },
      "registrationDetails": {
        "registrationStatus": {
          "legalName": "Стан державної реєстрації об''єкту"
        },
        "registrationID": {
          "legalName": "Номер запису"
        },
        "registrationDate": {
          "legalName": "Дата реєстрації"
        },
        "legalName": "Інформація щодо реєстрації"
      },
      "id": {
        "legalName": "Внутрішній ідентифікатор об'єкта"
      },
      "itemType": {
        "legalName": "Тип об'єкта майна"
      },
      "quantity": {
        "legalName": "Кількість одиниць"
      },
      "unit": {
        "code": {
          "legalName": "Код одиниці виміру"
        },
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Назва одиниці виміру"
        },
        "legalName": "Одиниці виміру"
      }
    },
    "Award": {
      "id": {
        "legalName": "Ідентифікатор кваліфікації учасника аукціону"
      },
      "bidId": {
        "legalName": "Ідентифікатор заяви на участь учасника аукціону"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
      },
      "status": {
        "legalName": "Статус кваліфікації учасника аукціону"
      },
      "terminationReason": {
        "legalName": "Причина дискваліфікації/відмови учаснику"
      },
      "datePublished": {
        "legalName": "Дата початку кваліфікації учасника аукціону"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Цінова пропозиція"
      },
      "buyers": {
        "legalName": "Інформація про учасника"
      },
      "items": {
        "legalName": "Склад лота"
      },
      "documents": {
        "legalName": "Документи до аварду"
      },
      "dateModified": {
        "legalName": "Дата редагування"
      },
      "signingPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період підписання договору"
      },
      "verificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період підписання протоколу"
      }
    },
    "Contract": {
      "id": {
        "legalName": "Внутрішній ідентифікатор контракту"
      },
      "awardId": {
        "legalName": "Ідентифікатор переможця"
      },
      "contractNumber": {
        "legalName": "Номер договору"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва договору"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис договору"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Вартість договору"
      },
      "contractTotalValue": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Заключна вартість договору з урахуванням податків"
      },
      "items": {
        "legalName": "Склад лота"
      },
      "buyers": {
        "legalName": "Переможець"
      },
      "status": {
        "legalName": "Статус договору"
      },
      "dateSigned": {
        "legalName": "Дата підписання договору"
      },
      "datePublished": {
        "legalName": "Дата створення об'єкта договіру"
      },
      "dateModified": {
        "legalName": "Остання дата редагування договіру"
      },
      "contractTime": {
        "dateFrom": {
          "legalName": "Період з"
        },
        "dateTill": {
          "legalName": "Період по"
        },
        "legalName": "Строк дії договору"
      },
      "documents": {
        "legalName": "Документи та додатки пов’язані з договором"
      }
    },
    "Cancellation": {
      "id": {
        "legalName": "Ідентифікатор об'єкта скасування"
      },
      "reason": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Причина скасування Аукціону"
      },
      "datePublished": {
        "legalName": "Дата скасування"
      },
      "cancellationOf": {
        "legalName": "Об'єкт скасування"
      }
    }
  },
  "legitimatePropertyLease-english": {
    "Bid": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "id": {
        "legalName": "Ідентифікатор заяви на участь"
      },
      "bidders": {
        "legalName": "Інформація про учасника"
      },
      "datePublished": {
        "legalName": "Дата заяви на участь"
      },
      "dateModified": {
        "legalName": "Остання дата редагування ставки"
      },
      "status": {
        "legalName": "Статус заяви на участь"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Цінова пропозиція"
      },
      "documents": {
        "legalName": "Документи до заяви про участь"
      },
      "participationUrl": {
        "legalName": "Веб-адреса для участі в аукціоні"
      },
      "initialValueAmount": {
        "legalName": "Закрита цінова пропозиція"
      }
    },
    "legitimatePropertyLeaseEnglishProcedure": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "_id": {
        "legalName": "Внутрішній ідентифікатор аукціону"
      },
      "datePublished": {
        "legalName": "Дата публікації процедури"
      },
      "dateModified": {
        "legalName": "Остання дата зміни процедури"
      },
      "auctionId": {
        "legalName": "Ідентифікатор аукціону"
      },
      "previousAuctionId": {
        "legalName": "Номер попереднього аукціону"
      },
      "sellingMethod": {
        "legalName": "Тип процедури"
      },
      "registryObjectId": {
        "legalName": "Ідентифікатор об'єкта"
      },
      "registryId": {
        "legalName": "Ідентифікатор об'єкта"
      },
      "lotId": {
        "legalName": "Номер лота"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва аукціону"
      },
      "auctionUrl": {
        "legalName": "Веб-адреса для перегляду аукціону"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис аукціону"
      },
      "accessDetails": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
      },
      "relatedOrganizations": {
        "propertyOwner": {
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Найменування юридичної особи"
          },
          "identifier": {
            "scheme": {
              "legalName": "Тип ідентифікації юридичної особи"
            },
            "legalName": "Ідентифікатор юридичної особи",
            "id": {
              "legalName": "Ідентифікатор юридичної особи"
            }
          },
          "address": {
            "addressID": {
              "scheme": {
                "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
              },
              "name": {
                "uk_UA": {
                  "legalName": "Назва українською"
                },
                "en_US": {
                  "legalName": "Назва англійською"
                },
                "legalName": "Назва адміністративно-територіальних об’єктів України"
              },
              "id": {
                "legalName": "Код адміністративно-територіальних об’єктів України"
              },
              "legalName": "Код об’єктів адміністративно-територіального устрою України"
            },
            "streetAddress": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Адреса"
            },
            "locality": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Населений пункт"
            },
            "region": {
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Область"
            },
            "postalCode": {
              "legalName": "Поштовий індекс"
            },
            "countryName": {
              "en_US": {
                "legalName": "Назва англійською"
              }
            },
            "legalName": "Місцезнаходження юридичної особи"
          },
          "contactPoint": {
            "name": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "ПІБ"
            },
            "email": {
              "legalName": "Адреса електронної пошти"
            },
            "telephone": {
              "legalName": "Номер телефону"
            },
            "faxNumber": {
              "legalName": "Номер факсу"
            },
            "url": {
              "legalName": "Веб адреса"
            },
            "legalName": "Контактна особа"
          },
          "legalName": "Балансоутримувач"
        },
        "sellingEntity": {
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Найменування юридичної особи"
          },
          "identifier": {
            "scheme": {
              "legalName": "Тип ідентифікації юридичної особи"
            },
            "legalName": "Ідентифікатор юридичної особи",
            "id": {
              "legalName": "Ідентифікатор юридичної особи"
            }
          },
          "address": {
            "addressID": {
              "scheme": {
                "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
              },
              "name": {
                "uk_UA": {
                  "legalName": "Назва українською"
                },
                "en_US": {
                  "legalName": "Назва англійською"
                },
                "legalName": "Назва адміністративно-територіальних об’єктів України"
              },
              "id": {
                "legalName": "Код адміністративно-територіальних об’єктів України"
              },
              "legalName": "Код об’єктів адміністративно-територіального устрою України"
            },
            "streetAddress": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Адреса"
            },
            "locality": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Населений пункт"
            },
            "region": {
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Область"
            },
            "postalCode": {
              "legalName": "Поштовий індекс"
            },
            "countryName": {
              "en_US": {
                "legalName": "Назва англійською"
              }
            },
            "legalName": "Місцезнаходження юридичної особи"
          },
          "contactPoint": {
            "name": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "ПІБ"
            },
            "email": {
              "legalName": "Адреса електронної пошти"
            },
            "telephone": {
              "legalName": "Номер телефону"
            },
            "faxNumber": {
              "legalName": "Номер факсу"
            },
            "url": {
              "legalName": "Веб адреса"
            },
            "legalName": "Контактна особа"
          },
          "legalName": "Орендодавець"
        },
        "currentTenant": {
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Повна юридична назва організації або ПІБ"
          },
          "identifier": {
            "scheme": {
              "legalName": "Ідентифікатори організації"
            },
            "legalName": "Ідентифікатори організації або особи",
            "id": {
              "legalName": "Код ЄДРПОУ або ІПН або паспорт"
            }
          },
          "address": {
            "addressID": {
              "scheme": {
                "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
              },
              "name": {
                "uk_UA": {
                  "legalName": "Назва українською"
                },
                "en_US": {
                  "legalName": "Назва англійською"
                },
                "legalName": "Назва адміністративно-територіальних об’єктів України"
              },
              "id": {
                "legalName": "Код адміністративно-територіальних об’єктів України"
              },
              "legalName": "Код об’єктів адміністративно-територіального устрою України"
            },
            "streetAddress": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Адреса"
            },
            "locality": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Населений пункт"
            },
            "region": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Область"
            },
            "postalCode": {
              "legalName": "Поштовий індекс"
            },
            "countryName": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Країна"
            },
            "legalName": "Адреса"
          },
          "representativeInfo": {
            "legalName": "Інформація щодо підтвердження повноважень"
          },
          "contactPoint": {
            "name": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "ПІБ"
            },
            "email": {
              "legalName": "Адреса електронної пошти"
            },
            "telephone": {
              "legalName": "Номер телефону"
            },
            "faxNumber": {
              "legalName": "Номер факсу"
            },
            "url": {
              "legalName": "Веб адреса"
            },
            "legalName": "Контактна особа"
          },
          "currentContractTime": {
            "dateFrom": {
              "legalName": "Період з"
            },
            "dateTill": {
              "legalName": "Період по"
            },
            "legalName": "Дата укладення та закінчення договору оренди"
          }
        },
        "ownershipType": {
          "legalName": "Тип власності"
        },
        "legalName": "Організації, задіяні в керуванні об'єктом"
      },
      "leaseRules": {
        "schedule": {
          "hoursBy": {
            "value": {
              "legalName": "Години"
            }
          },
          "daysBy": {
            "value": {
              "legalName": "Дні"
            }
          },
          "period": {
            "legalName": "Період дії графіку"
          },
          "other": {
            "legalName": "Інший графік використання"
          },
          "legalName": "Пропонований графік оренди"
        },
        "intendedUse": {
          "legalName": "Цільове призначення об'єкта"
        },
        "intendedUseRestrictionMethod": {
          "legalName": "Спосіб обмеження цільового призначення об'єкта"
        },
        "intendedUseRestrictionDescription": {
          "legalName": "Опис обмеженнь цільового призначення об'єкта (за наявності)"
        },
        "additionalLeaseConditions": {
          "legalName": "Додаткові умови оренди майна"
        },
        "subleaseAllowed": {
          "legalName": "Можливість суборенди"
        },
        "legalName": "Правила та умови передачі об'єкта в оренду"
      },
      "listType": {
        "legalName": "Тип переліку, до якого віднесено об'єкт"
      },
      "bankAccounts": {
        "registrationFeeAccounts": {
          "bankAccountsUa": {
            "legalName": "Банківські рахунки в національній валюті"
          },
          "bankAccountsForeign": {
            "legalName": "Банківські рахунки в іноземній валюті"
          },
          "legalName": "Банківські рахунки для реєстраційного внеску"
        },
        "guaranteeAccounts": {
          "bankAccountsUa": {
            "legalName": "Банківські рахунки в національній валюті"
          },
          "bankAccountsForeign": {
            "legalName": "Банківські рахунки в іноземній валюті"
          },
          "legalName": "Банківські рахунки для гарантійного внеску"
        },
        "advancePaymentAccounts": {
          "balanceHolderBankAccounts": {
            "bankAccountsUa": {
              "legalName": "Банківські рахунки в національній валюті"
            },
            "bankAccountsForeign": {
              "legalName": "Банківські рахунки в іноземній валюті"
            },
            "sharingPercent": {
              "legalName": "Відсоток балансоутримувача, %"
            },
            "legalName": "Банківські рахунки балансоутримувача для авансового внеску"
          },
          "leaseHolderAccounts": {
            "bankAccountsUa": {
              "legalName": "Банківські рахунки в національній валюті"
            },
            "bankAccountsForeign": {
              "legalName": "Банківські рахунки в іноземній валюті"
            },
            "sharingPercent": {
              "legalName": "Відсоток орендодавця, %"
            },
            "legalName": "Банківські рахунки орендодавця для авансового внеску"
          },
          "legalName": "Банківські рахунки для авансового внеску"
        },
        "securityDepositAccounts": {
          "bankAccountsUa": {
            "legalName": "Банківські рахунки в національній валюті"
          },
          "bankAccountsForeign": {
            "legalName": "Банківські рахунки в іноземній валюті"
          },
          "legalName": "Банківські рахунки для забезпечувального депозиту"
        },
        "improvementsCompensationAccounts": {
          "bankAccountsUa": {
            "legalName": "Банківські рахунки в національній валюті"
          },
          "bankAccountsForeign": {
            "legalName": "Банківські рахунки в іноземній валюті"
          },
          "legalName": "Банківські рахунки для компенсації невід'ємних поліпшень"
        },
        "otherAccounts": {
          "bankAccountsUa": {
            "legalName": "Банківські рахунки в національній валюті"
          },
          "bankAccountsForeign": {
            "legalName": "Банківські рахунки в іноземній валюті"
          },
          "legalName": "Інші банківські рахунки"
        },
        "legalName": "Банківські рахунки"
      },
      "x_additionalInformation": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Додаткові відомості"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Стартова орендна плата за період"
      },
      "valueAddedTaxCharged": {
        "legalName": "На фінальну суму нараховується ПДВ"
      },
      "calcOnBookValue": {
        "legalName": "Стартова орендна плата розрахована з урахуванням балансової вартості"
      },
      "valuePeriod": {
        "legalName": "Стартова вартість за"
      },
      "leaseDuration": {
        "legalName": "Строк оренди"
      },
      "discount": {
        "previousAuctionValue": {
          "currency": {
            "legalName": "Валюта"
          },
          "amount": {
            "legalName": "Сума"
          },
          "valueAddedTaxIncluded": {
            "legalName": "Податок"
          },
          "legalName": "Стартова вартість попереднього аукціону"
        },
        "discountPercent": {
          "legalName": "Розмір знижки від попереднього аукціону, %"
        },
        "discount": {
          "legalName": "Знижка"
        },
        "legalName": "Знижки"
      },
      "guarantee": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "legalName": "Гарантійний внесок"
      },
      "registrationFee": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "legalName": "Реєстраційний внесок"
      },
      "minimalStep": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Розмір мінімального кроку підвищення стартової орендної плати під час аукціону"
      },
      "minNumberOfQualifiedBids": {
        "legalName": "Мінімальна кількість заяв"
      },
      "tenderAttempts": {
        "legalName": "Порядковий номер аукціону"
      },
      "items": {
        "legalName": "Склад лота"
      },
      "documents": {
        "legalName": "Всі документи аукціону та пов'язані додатки"
      },
      "bids": {
        "legalName": "Заява на участь"
      },
      "questions": {
        "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
      },
      "awards": {
        "legalName": "Рішення щодо кваліфікації учасників"
      },
      "contracts": {
        "legalName": "Договір"
      },
      "rectificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період редагування лота"
      },
      "enquiryPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період відповідей"
      },
      "tenderPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період подання заяви на участь в аукціоні"
      },
      "auctionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Дата та час проведення аукціону"
      },
      "questionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період запитань"
      },
      "qualificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період кваліфікації"
      },
      "status": {
        "legalName": "Статус процедури"
      },
      "cancellations": {
        "legalName": "Скасування Аукціону"
      }
    }
  },
  "legitimatePropertyLease-dutch": {
    "Bid": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "id": {
        "legalName": "Ідентифікатор заяви на участь"
      },
      "bidders": {
        "legalName": "Інформація про учасника"
      },
      "datePublished": {
        "legalName": "Дата заяви на участь"
      },
      "dateModified": {
        "legalName": "Остання дата редагування ставки"
      },
      "status": {
        "legalName": "Статус заяви на участь"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Розмір закритої цінової пропозиції"
      },
      "documents": {
        "legalName": "Документи до заяви про участь"
      },
      "participationUrl": {
        "legalName": "Веб-адреса для участі в аукціоні"
      }
    },
    "legitimatePropertyLeaseDutchProcedure": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "_id": {
        "legalName": "Внутрішній ідентифікатор аукціону"
      },
      "datePublished": {
        "legalName": "Дата публікації процедури"
      },
      "dateModified": {
        "legalName": "Остання дата зміни процедури"
      },
      "auctionId": {
        "legalName": "Ідентифікатор аукціону"
      },
      "previousAuctionId": {
        "legalName": "Номер попереднього аукціону"
      },
      "sellingMethod": {
        "legalName": "Тип процедури"
      },
      "registryObjectId": {
        "legalName": "Ідентифікатор об'єкта"
      },
      "registryId": {
        "legalName": "Ідентифікатор об'єкта"
      },
      "lotId": {
        "legalName": "Номер лота"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва аукціону"
      },
      "auctionUrl": {
        "legalName": "Веб-адреса для перегляду аукціону"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис аукціону"
      },
      "accessDetails": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
      },
      "relatedOrganizations": {
        "propertyOwner": {
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Найменування юридичної особи"
          },
          "identifier": {
            "scheme": {
              "legalName": "Тип ідентифікації юридичної особи"
            },
            "legalName": "Ідентифікатор юридичної особи",
            "id": {
              "legalName": "Ідентифікатор юридичної особи"
            }
          },
          "address": {
            "addressID": {
              "scheme": {
                "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
              },
              "name": {
                "uk_UA": {
                  "legalName": "Назва українською"
                },
                "en_US": {
                  "legalName": "Назва англійською"
                },
                "legalName": "Назва адміністративно-територіальних об’єктів України"
              },
              "id": {
                "legalName": "Код адміністративно-територіальних об’єктів України"
              },
              "legalName": "Код об’єктів адміністративно-територіального устрою України"
            },
            "streetAddress": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Адреса"
            },
            "locality": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Населений пункт"
            },
            "region": {
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Область"
            },
            "postalCode": {
              "legalName": "Поштовий індекс"
            },
            "countryName": {
              "en_US": {
                "legalName": "Назва англійською"
              }
            },
            "legalName": "Місцезнаходження юридичної особи"
          },
          "contactPoint": {
            "name": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "ПІБ"
            },
            "email": {
              "legalName": "Адреса електронної пошти"
            },
            "telephone": {
              "legalName": "Номер телефону"
            },
            "faxNumber": {
              "legalName": "Номер факсу"
            },
            "url": {
              "legalName": "Веб адреса"
            },
            "legalName": "Контактна особа"
          },
          "legalName": "Балансоутримувач"
        },
        "sellingEntity": {
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Найменування юридичної особи"
          },
          "identifier": {
            "scheme": {
              "legalName": "Тип ідентифікації юридичної особи"
            },
            "legalName": "Ідентифікатор юридичної особи",
            "id": {
              "legalName": "Ідентифікатор юридичної особи"
            }
          },
          "address": {
            "addressID": {
              "scheme": {
                "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
              },
              "name": {
                "uk_UA": {
                  "legalName": "Назва українською"
                },
                "en_US": {
                  "legalName": "Назва англійською"
                },
                "legalName": "Назва адміністративно-територіальних об’єктів України"
              },
              "id": {
                "legalName": "Код адміністративно-територіальних об’єктів України"
              },
              "legalName": "Код об’єктів адміністративно-територіального устрою України"
            },
            "streetAddress": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Адреса"
            },
            "locality": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Населений пункт"
            },
            "region": {
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Область"
            },
            "postalCode": {
              "legalName": "Поштовий індекс"
            },
            "countryName": {
              "en_US": {
                "legalName": "Назва англійською"
              }
            },
            "legalName": "Місцезнаходження юридичної особи"
          },
          "contactPoint": {
            "name": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "ПІБ"
            },
            "email": {
              "legalName": "Адреса електронної пошти"
            },
            "telephone": {
              "legalName": "Номер телефону"
            },
            "faxNumber": {
              "legalName": "Номер факсу"
            },
            "url": {
              "legalName": "Веб адреса"
            },
            "legalName": "Контактна особа"
          },
          "legalName": "Орендодавець"
        },
        "currentTenant": {
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Повна юридична назва організації або ПІБ"
          },
          "identifier": {
            "scheme": {
              "legalName": "Ідентифікатори організації"
            },
            "legalName": "Ідентифікатори організації або особи",
            "id": {
              "legalName": "Код ЄДРПОУ або ІПН або паспорт"
            }
          },
          "address": {
            "addressID": {
              "scheme": {
                "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
              },
              "name": {
                "uk_UA": {
                  "legalName": "Назва українською"
                },
                "en_US": {
                  "legalName": "Назва англійською"
                },
                "legalName": "Назва адміністративно-територіальних об’єктів України"
              },
              "id": {
                "legalName": "Код адміністративно-територіальних об’єктів України"
              },
              "legalName": "Код об’єктів адміністративно-територіального устрою України"
            },
            "streetAddress": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Адреса"
            },
            "locality": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Населений пункт"
            },
            "region": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Область"
            },
            "postalCode": {
              "legalName": "Поштовий індекс"
            },
            "countryName": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Країна"
            },
            "legalName": "Адреса"
          },
          "representativeInfo": {
            "legalName": "Інформація щодо підтвердження повноважень"
          },
          "contactPoint": {
            "name": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "ПІБ"
            },
            "email": {
              "legalName": "Адреса електронної пошти"
            },
            "telephone": {
              "legalName": "Номер телефону"
            },
            "faxNumber": {
              "legalName": "Номер факсу"
            },
            "url": {
              "legalName": "Веб адреса"
            },
            "legalName": "Контактна особа"
          },
          "currentContractTime": {
            "dateFrom": {
              "legalName": "Період з"
            },
            "dateTill": {
              "legalName": "Період по"
            },
            "legalName": "Дата укладення та закінчення договору оренди"
          }
        },
        "ownershipType": {
          "legalName": "Тип власності"
        },
        "legalName": "Організації, задіяні в керуванні об'єктом"
      },
      "leaseRules": {
        "schedule": {
          "hoursBy": {
            "value": {
              "legalName": "Години"
            }
          },
          "daysBy": {
            "value": {
              "legalName": "Дні"
            }
          },
          "period": {
            "legalName": "Період дії графіку"
          },
          "other": {
            "legalName": "Інший графік використання"
          },
          "legalName": "Пропонований графік оренди"
        },
        "intendedUse": {
          "legalName": "Цільове призначення об'єкта"
        },
        "intendedUseRestrictionMethod": {
          "legalName": "Спосіб обмеження цільового призначення об'єкта"
        },
        "intendedUseRestrictionDescription": {
          "legalName": "Опис обмеженнь цільового призначення об'єкта (за наявності)"
        },
        "additionalLeaseConditions": {
          "legalName": "Додаткові умови оренди майна"
        },
        "subleaseAllowed": {
          "legalName": "Можливість суборенди"
        },
        "legalName": "Правила та умови передачі об'єкта в оренду"
      },
      "listType": {
        "legalName": "Тип переліку, до якого віднесено об'єкт"
      },
      "bankAccounts": {
        "registrationFeeAccounts": {
          "bankAccountsUa": {
            "legalName": "Банківські рахунки в національній валюті"
          },
          "bankAccountsForeign": {
            "legalName": "Банківські рахунки в іноземній валюті"
          },
          "legalName": "Банківські рахунки для реєстраційного внеску"
        },
        "guaranteeAccounts": {
          "bankAccountsUa": {
            "legalName": "Банківські рахунки в національній валюті"
          },
          "bankAccountsForeign": {
            "legalName": "Банківські рахунки в іноземній валюті"
          },
          "legalName": "Банківські рахунки для гарантійного внеску"
        },
        "advancePaymentAccounts": {
          "balanceHolderBankAccounts": {
            "bankAccountsUa": {
              "legalName": "Банківські рахунки в національній валюті"
            },
            "bankAccountsForeign": {
              "legalName": "Банківські рахунки в іноземній валюті"
            },
            "sharingPercent": {
              "legalName": "Відсоток балансоутримувача, %"
            },
            "legalName": "Банківські рахунки балансоутримувача для авансового внеску"
          },
          "leaseHolderAccounts": {
            "bankAccountsUa": {
              "legalName": "Банківські рахунки в національній валюті"
            },
            "bankAccountsForeign": {
              "legalName": "Банківські рахунки в іноземній валюті"
            },
            "sharingPercent": {
              "legalName": "Відсоток орендодавця, %"
            },
            "legalName": "Банківські рахунки орендодавця для авансового внеску"
          },
          "legalName": "Банківські рахунки для авансового внеску"
        },
        "securityDepositAccounts": {
          "bankAccountsUa": {
            "legalName": "Банківські рахунки в національній валюті"
          },
          "bankAccountsForeign": {
            "legalName": "Банківські рахунки в іноземній валюті"
          },
          "legalName": "Банківські рахунки для забезпечувального депозиту"
        },
        "improvementsCompensationAccounts": {
          "bankAccountsUa": {
            "legalName": "Банківські рахунки в національній валюті"
          },
          "bankAccountsForeign": {
            "legalName": "Банківські рахунки в іноземній валюті"
          },
          "legalName": "Банківські рахунки для компенсації невід'ємних поліпшень"
        },
        "otherAccounts": {
          "bankAccountsUa": {
            "legalName": "Банківські рахунки в національній валюті"
          },
          "bankAccountsForeign": {
            "legalName": "Банківські рахунки в іноземній валюті"
          },
          "legalName": "Інші банківські рахунки"
        },
        "legalName": "Банківські рахунки"
      },
      "x_additionalInformation": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Додаткові відомості"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Стартова орендна плата за період"
      },
      "valueAddedTaxCharged": {
        "legalName": "На фінальну суму нараховується ПДВ"
      },
      "calcOnBookValue": {
        "legalName": "Стартова орендна плата розрахована з урахуванням балансової вартості"
      },
      "valuePeriod": {
        "legalName": "Стартова вартість за"
      },
      "leaseDuration": {
        "legalName": "Строк оренди"
      },
      "discount": {
        "previousAuctionValue": {
          "currency": {
            "legalName": "Валюта"
          },
          "amount": {
            "legalName": "Сума"
          },
          "valueAddedTaxIncluded": {
            "legalName": "Податок"
          },
          "legalName": "Стартова вартість попереднього аукціону"
        },
        "discountPercent": {
          "legalName": "Розмір знижки від попереднього аукціону, %"
        },
        "discount": {
          "legalName": "Знижка"
        },
        "legalName": "Знижки"
      },
      "guarantee": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Гарантійний внесок"
      },
      "registrationFee": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Реєстраційний внесок"
      },
      "minimalStep": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Розмір кроку аукціону"
      },
      "dutchStep": {
        "dutchStepQuantity": {
          "legalName": "Кількість кроків"
        },
        "dutchStepPercent": {
          "legalName": "Відсоток зниження, %"
        },
        "dutchStepValue": {
          "currency": {
            "legalName": "Валюта"
          },
          "amount": {
            "legalName": "Сума"
          },
          "valueAddedTaxIncluded": {
            "legalName": "Податок"
          },
          "legalName": "Розмір кроку голландського раунду, грн"
        },
        "legalName": "Розмір кроку аукціону"
      },
      "tenderAttempts": {
        "legalName": "Лот виставляється"
      },
      "items": {
        "legalName": "Склад лота"
      },
      "documents": {
        "legalName": "Всі документи аукціону та пов'язані додатки"
      },
      "bids": {
        "legalName": "Заява на участь"
      },
      "questions": {
        "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
      },
      "awards": {
        "legalName": "Рішення щодо кваліфікації учасників"
      },
      "contracts": {
        "legalName": "Договір"
      },
      "rectificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період редагування лота"
      },
      "enquiryPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період відповідей"
      },
      "tenderPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період подання заяви на участь в аукціоні"
      },
      "auctionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Дата та час проведення аукціону"
      },
      "questionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період запитань"
      },
      "qualificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період кваліфікації"
      },
      "status": {
        "legalName": "Статус процедури"
      },
      "cancellations": {
        "legalName": "Скасування Аукціону"
      }
    }
  },
  "legitimatePropertyLease-priorityEnglish": {
    "legitimatePropertyLeasePriorityEnglishProcedure": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "_id": {
        "legalName": "Внутрішній ідентифікатор аукціону"
      },
      "datePublished": {
        "legalName": "Дата публікації процедури"
      },
      "dateModified": {
        "legalName": "Остання дата зміни процедури"
      },
      "auctionId": {
        "legalName": "Ідентифікатор аукціону"
      },
      "previousAuctionId": {
        "legalName": "Номер попереднього аукціону"
      },
      "sellingMethod": {
        "legalName": "Тип процедури"
      },
      "registryObjectId": {
        "legalName": "Ідентифікатор об'єкта"
      },
      "registryId": {
        "legalName": "Ідентифікатор об'єкта"
      },
      "lotId": {
        "legalName": "Номер лота"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва аукціону"
      },
      "auctionUrl": {
        "legalName": "Веб-адреса для перегляду аукціону"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис аукціону"
      },
      "accessDetails": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
      },
      "relatedOrganizations": {
        "propertyOwner": {
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Найменування юридичної особи"
          },
          "identifier": {
            "scheme": {
              "legalName": "Тип ідентифікації юридичної особи"
            },
            "legalName": "Ідентифікатор юридичної особи",
            "id": {
              "legalName": "Ідентифікатор юридичної особи"
            }
          },
          "address": {
            "addressID": {
              "scheme": {
                "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
              },
              "name": {
                "uk_UA": {
                  "legalName": "Назва українською"
                },
                "en_US": {
                  "legalName": "Назва англійською"
                },
                "legalName": "Назва адміністративно-територіальних об’єктів України"
              },
              "id": {
                "legalName": "Код адміністративно-територіальних об’єктів України"
              },
              "legalName": "Код об’єктів адміністративно-територіального устрою України"
            },
            "streetAddress": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Адреса"
            },
            "locality": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Населений пункт"
            },
            "region": {
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Область"
            },
            "postalCode": {
              "legalName": "Поштовий індекс"
            },
            "countryName": {
              "en_US": {
                "legalName": "Назва англійською"
              }
            },
            "legalName": "Місцезнаходження юридичної особи"
          },
          "contactPoint": {
            "name": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "ПІБ"
            },
            "email": {
              "legalName": "Адреса електронної пошти"
            },
            "telephone": {
              "legalName": "Номер телефону"
            },
            "faxNumber": {
              "legalName": "Номер факсу"
            },
            "url": {
              "legalName": "Веб адреса"
            },
            "legalName": "Контактна особа"
          },
          "legalName": "Балансоутримувач"
        },
        "sellingEntity": {
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Найменування юридичної особи"
          },
          "identifier": {
            "scheme": {
              "legalName": "Тип ідентифікації юридичної особи"
            },
            "legalName": "Ідентифікатор юридичної особи",
            "id": {
              "legalName": "Ідентифікатор юридичної особи"
            }
          },
          "address": {
            "addressID": {
              "scheme": {
                "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
              },
              "name": {
                "uk_UA": {
                  "legalName": "Назва українською"
                },
                "en_US": {
                  "legalName": "Назва англійською"
                },
                "legalName": "Назва адміністративно-територіальних об’єктів України"
              },
              "id": {
                "legalName": "Код адміністративно-територіальних об’єктів України"
              },
              "legalName": "Код об’єктів адміністративно-територіального устрою України"
            },
            "streetAddress": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Адреса"
            },
            "locality": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Населений пункт"
            },
            "region": {
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Область"
            },
            "postalCode": {
              "legalName": "Поштовий індекс"
            },
            "countryName": {
              "en_US": {
                "legalName": "Назва англійською"
              }
            },
            "legalName": "Місцезнаходження юридичної особи"
          },
          "contactPoint": {
            "name": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "ПІБ"
            },
            "email": {
              "legalName": "Адреса електронної пошти"
            },
            "telephone": {
              "legalName": "Номер телефону"
            },
            "faxNumber": {
              "legalName": "Номер факсу"
            },
            "url": {
              "legalName": "Веб адреса"
            },
            "legalName": "Контактна особа"
          },
          "legalName": "Орендодавець"
        },
        "currentTenant": {
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Повна юридична назва організації або ПІБ"
          },
          "identifier": {
            "scheme": {
              "legalName": "Ідентифікатори організації"
            },
            "legalName": "Ідентифікатори організації або особи",
            "id": {
              "legalName": "Код ЄДРПОУ або ІПН або паспорт"
            }
          },
          "address": {
            "addressID": {
              "scheme": {
                "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
              },
              "name": {
                "uk_UA": {
                  "legalName": "Назва українською"
                },
                "en_US": {
                  "legalName": "Назва англійською"
                },
                "legalName": "Назва адміністративно-територіальних об’єктів України"
              },
              "id": {
                "legalName": "Код адміністративно-територіальних об’єктів України"
              },
              "legalName": "Код об’єктів адміністративно-територіального устрою України"
            },
            "streetAddress": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Адреса"
            },
            "locality": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Населений пункт"
            },
            "region": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Область"
            },
            "postalCode": {
              "legalName": "Поштовий індекс"
            },
            "countryName": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Країна"
            },
            "legalName": "Адреса"
          },
          "representativeInfo": {
            "legalName": "Інформація щодо підтвердження повноважень"
          },
          "contactPoint": {
            "name": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "ПІБ"
            },
            "email": {
              "legalName": "Адреса електронної пошти"
            },
            "telephone": {
              "legalName": "Номер телефону"
            },
            "faxNumber": {
              "legalName": "Номер факсу"
            },
            "url": {
              "legalName": "Веб адреса"
            },
            "legalName": "Контактна особа"
          },
          "currentContractTime": {
            "dateFrom": {
              "legalName": "Період з"
            },
            "dateTill": {
              "legalName": "Період по"
            },
            "legalName": "Дата укладення та закінчення договору оренди"
          }
        },
        "ownershipType": {
          "legalName": "Тип власності"
        },
        "legalName": "Організації, задіяні в керуванні об'єктом"
      },
      "leaseRules": {
        "schedule": {
          "hoursBy": {
            "value": {
              "legalName": "Години"
            }
          },
          "daysBy": {
            "value": {
              "legalName": "Дні"
            }
          },
          "period": {
            "legalName": "Період дії графіку"
          },
          "other": {
            "legalName": "Інший графік використання"
          },
          "legalName": "Пропонований графік оренди"
        },
        "intendedUse": {
          "legalName": "Цільове призначення об'єкта"
        },
        "intendedUseRestrictionMethod": {
          "legalName": "Спосіб обмеження цільового призначення об'єкта"
        },
        "intendedUseRestrictionDescription": {
          "legalName": "Опис обмеженнь цільового призначення об'єкта (за наявності)"
        },
        "additionalLeaseConditions": {
          "legalName": "Додаткові умови оренди майна"
        },
        "subleaseAllowed": {
          "legalName": "Можливість суборенди"
        },
        "legalName": "Правила та умови передачі об'єкта в оренду"
      },
      "listType": {
        "legalName": "Тип переліку, до якого віднесено об'єкт"
      },
      "bankAccounts": {
        "registrationFeeAccounts": {
          "bankAccountsUa": {
            "legalName": "Банківські рахунки в національній валюті"
          },
          "bankAccountsForeign": {
            "legalName": "Банківські рахунки в іноземній валюті"
          },
          "legalName": "Банківські рахунки для реєстраційного внеску"
        },
        "guaranteeAccounts": {
          "bankAccountsUa": {
            "legalName": "Банківські рахунки в національній валюті"
          },
          "bankAccountsForeign": {
            "legalName": "Банківські рахунки в іноземній валюті"
          },
          "legalName": "Банківські рахунки для гарантійного внеску"
        },
        "advancePaymentAccounts": {
          "balanceHolderBankAccounts": {
            "bankAccountsUa": {
              "legalName": "Банківські рахунки в національній валюті"
            },
            "bankAccountsForeign": {
              "legalName": "Банківські рахунки в іноземній валюті"
            },
            "sharingPercent": {
              "legalName": "Відсоток балансоутримувача, %"
            },
            "legalName": "Банківські рахунки балансоутримувача для авансового внеску"
          },
          "leaseHolderAccounts": {
            "bankAccountsUa": {
              "legalName": "Банківські рахунки в національній валюті"
            },
            "bankAccountsForeign": {
              "legalName": "Банківські рахунки в іноземній валюті"
            },
            "sharingPercent": {
              "legalName": "Відсоток орендодавця, %"
            },
            "legalName": "Банківські рахунки орендодавця для авансового внеску"
          },
          "legalName": "Банківські рахунки для авансового внеску"
        },
        "securityDepositAccounts": {
          "bankAccountsUa": {
            "legalName": "Банківські рахунки в національній валюті"
          },
          "bankAccountsForeign": {
            "legalName": "Банківські рахунки в іноземній валюті"
          },
          "legalName": "Банківські рахунки для забезпечувального депозиту"
        },
        "improvementsCompensationAccounts": {
          "bankAccountsUa": {
            "legalName": "Банківські рахунки в національній валюті"
          },
          "bankAccountsForeign": {
            "legalName": "Банківські рахунки в іноземній валюті"
          },
          "legalName": "Банківські рахунки для компенсації невід'ємних поліпшень"
        },
        "otherAccounts": {
          "bankAccountsUa": {
            "legalName": "Банківські рахунки в національній валюті"
          },
          "bankAccountsForeign": {
            "legalName": "Банківські рахунки в іноземній валюті"
          },
          "legalName": "Інші банківські рахунки"
        },
        "legalName": "Банківські рахунки"
      },
      "x_additionalInformation": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Додаткові відомості"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Стартова орендна плата за період"
      },
      "valueAddedTaxCharged": {
        "legalName": "На фінальну суму нараховується ПДВ"
      },
      "calcOnBookValue": {
        "legalName": "Стартова орендна плата розрахована з урахуванням балансової вартості"
      },
      "valuePeriod": {
        "legalName": "Стартова вартість за"
      },
      "leaseDuration": {
        "legalName": "Строк оренди"
      },
      "discount": {
        "previousAuctionValue": {
          "currency": {
            "legalName": "Валюта"
          },
          "amount": {
            "legalName": "Сума"
          },
          "valueAddedTaxIncluded": {
            "legalName": "Податок"
          },
          "legalName": "Стартова вартість попереднього аукціону"
        },
        "discountPercent": {
          "legalName": "Розмір знижки від попереднього аукціону, %"
        },
        "discount": {
          "legalName": "Знижка"
        },
        "legalName": "Знижки"
      },
      "guarantee": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Гарантійний внесок"
      },
      "currentTenantGuaranteeFee": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Гарантійний внесок чинного орендаря"
      },
      "registrationFee": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Реєстраційний внесок"
      },
      "minimalStep": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Розмір мінімального кроку підвищення стартової орендної плати під час аукціону"
      },
      "minNumberOfQualifiedBids": {
        "legalName": "Мінімальна кількість заяв"
      },
      "tenderAttempts": {
        "legalName": "Порядковий номер аукціону"
      },
      "items": {
        "legalName": "Склад лота"
      },
      "documents": {
        "legalName": "Всі документи аукціону та пов'язані додатки"
      },
      "bids": {
        "legalName": "Заява на участь"
      },
      "questions": {
        "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
      },
      "awards": {
        "legalName": "Рішення щодо кваліфікації учасників"
      },
      "contracts": {
        "legalName": "Договір"
      },
      "rectificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період редагування лота"
      },
      "enquiryPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період відповідей"
      },
      "tenderPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період подання заяви на участь в аукціоні"
      },
      "auctionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Дата та час проведення аукціону"
      },
      "questionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період запитань"
      },
      "qualificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період кваліфікації"
      },
      "status": {
        "legalName": "Статус процедури"
      },
      "cancellations": {
        "legalName": "Скасування Аукціону"
      }
    }
  },
  "railwayCargo": {
    "Classification": {
      "scheme": {
        "legalName": "Схема класифікатора"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис коду класифікатора"
      },
      "id": {
        "legalName": "Код класифікатора"
      }
    },
    "Unit": {
      "code": {
        "legalName": "Код одиниці виміру"
      }
    },
    "AdditionalClassification": {
      "scheme": {
        "legalName": "Схема додаткового класифікатору"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис додаткового класифікатору"
      },
      "id": {
        "legalName": "Код додаткового класифікатору"
      }
    },
    "Item": {
      "id": {
        "legalName": "Внутрішній ідентифікатор об'єкта"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис об'єкта"
      },
      "classification": {
        "scheme": {
          "legalName": "Схема класифікатора"
        },
        "description": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Опис коду класифікатора"
        },
        "id": {
          "legalName": "Код класифікатора"
        }
      },
      "unit": {
        "code": {
          "legalName": "Код одиниці виміру"
        }
      },
      "quantity": {
        "legalName": "Кількість вагонів"
      },
      "address": {
        "addressID": {
          "scheme": {
            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
          },
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Назва адміністративно-територіальних об’єктів України"
          },
          "id": {
            "legalName": "Код адміністративно-територіальних об’єктів України"
          },
          "legalName": "Код об’єктів адміністративно-територіального устрою України"
        },
        "streetAddress": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Адреса"
        },
        "locality": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Населений пункт"
        },
        "region": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Область"
        },
        "postalCode": {
          "legalName": "Поштовий індекс"
        },
        "countryName": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Країна"
        },
        "legalName": "Місцезнаходження"
      },
      "additionalClassifications": {
        "legalName": "Додатковий класифікатор"
      },
      "routeQuantity": {
        "legalName": "Кількість маршрутів"
      },
      "routesAccounting": {
        "wagonsPerRouteQuantity": {
          "legalName": "Кількість вагонів по кожному маршруту"
        },
        "routeNumber": {
          "legalName": "Порядковий номер маршруту"
        },
        "legalName": "Облік маршрутів"
      },
      "location": {
        "latitude": {
          "legalName": "Широта"
        },
        "longitude": {
          "legalName": "Довгота"
        },
        "elevation": {
          "legalName": "Висота"
        },
        "legalName": "Географічні координати розташування"
      },
      "requestLifeTime": {
        "dateFrom": {
          "legalName": "Період з"
        },
        "dateTill": {
          "legalName": "Період по"
        },
        "legalName": "Період проставлення електронної заявки"
      },
      "loadLifeTime": {
        "dateFrom": {
          "legalName": "Період з"
        },
        "dateTill": {
          "legalName": "Період по"
        },
        "legalName": "Період подачі вагону"
      },
      "wagonTypes": {
        "legalName": "Перелік кодів типів вагонів"
      },
      "wagonSpecies": {
        "legalName": "Код роду вагонів"
      },
      "loadingRestriction": {
        "legalName": "Полігон навантаження"
      },
      "auctionRestriction": {
        "legalName": "Обмеження полігону навантаження/ вивантаження"
      }
    },
    "Award": {
      "id": {
        "legalName": "Ідентифікатор кваліфікації учасника аукціону"
      },
      "bidId": {
        "legalName": "Ідентифікатор заяви на участь учасника аукціону"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
      },
      "status": {
        "legalName": "Статус кваліфікації учасника аукціону"
      },
      "terminationReason": {
        "legalName": "Причина дискваліфікації/відмови учаснику"
      },
      "datePublished": {
        "legalName": "Дата початку кваліфікації учасника аукціону"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Цінова пропозиція"
      },
      "buyers": {
        "legalName": "Інформація про учасника"
      },
      "items": {
        "legalName": "Склад лота"
      },
      "documents": {
        "legalName": "Документи до аварду"
      },
      "dateModified": {
        "legalName": "Дата редагування"
      },
      "verificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період підписання протоколу"
      }
    },
    "SellingEntity": {
      "name": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Найменування Організатора аукціону"
      },
      "identifier": {
        "scheme": {
          "legalName": "Тип ідентифікації Організатора аукціону"
        },
        "legalName": "Ідентифікатор Організатора аукціону",
        "id": {
          "legalName": "Ідентифікатор Організатора аукціону"
        }
      },
      "address": {
        "addressID": {
          "scheme": {
            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
          },
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Назва адміністративно-територіальних об’єктів України"
          },
          "id": {
            "legalName": "Код адміністративно-територіальних об’єктів України"
          },
          "legalName": "Код об’єктів адміністративно-територіального устрою України"
        },
        "streetAddress": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Адреса"
        },
        "locality": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Населений пункт"
        },
        "region": {
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Область"
        },
        "postalCode": {
          "legalName": "Поштовий індекс"
        },
        "countryName": {
          "en_US": {
            "legalName": "Назва англійською"
          }
        },
        "legalName": "Місцезнаходження Організатора аукціону"
      },
      "contactPoint": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "ПІБ"
        },
        "email": {
          "legalName": "Адреса електронної пошти"
        },
        "telephone": {
          "legalName": "Номер телефону"
        },
        "faxNumber": {
          "legalName": "Номер факсу"
        },
        "url": {
          "legalName": "Веб адреса"
        },
        "legalName": "Представник"
      },
      "representativeInfo": {
        "legalName": "Інформація щодо підтвердження повноважень"
      }
    }
  },
  "railwayCargo-english": {
    "Bid": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "id": {
        "legalName": "Ідентифікатор заяви на участь"
      },
      "bidders": {
        "legalName": "Інформація про учасника"
      },
      "datePublished": {
        "legalName": "Дата заяви на участь"
      },
      "dateModified": {
        "legalName": "Остання дата редагування ставки"
      },
      "status": {
        "legalName": "Статус заяви на участь"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Цінова пропозиція"
      },
      "documents": {
        "legalName": "Документи до заяви про участь"
      },
      "participationUrl": {
        "legalName": "Веб-адреса для участі в аукціоні"
      },
      "initialValueAmount": {
        "legalName": "Початкова сума"
      }
    },
    "RailwayCargoEnglishProcedure": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "_id": {
        "legalName": "Внутрішній ідентифікатор аукціону"
      },
      "datePublished": {
        "legalName": "Дата публікації процедури"
      },
      "dateModified": {
        "legalName": "Остання дата зміни процедури"
      },
      "auctionId": {
        "legalName": "Ідентифікатор аукціону"
      },
      "carryType": {
        "legalName": "Тип аукціону"
      },
      "previousAuctionId": {
        "legalName": "Номер попереднього аукціону"
      },
      "sellingMethod": {
        "legalName": "Тип процедури"
      },
      "sellingEntity": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Найменування Організатора аукціону"
        },
        "identifier": {
          "scheme": {
            "legalName": "Тип ідентифікації Організатора аукціону"
          },
          "legalName": "Ідентифікатор Організатора аукціону",
          "id": {
            "legalName": "Ідентифікатор Організатора аукціону"
          }
        },
        "address": {
          "addressID": {
            "scheme": {
              "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
            },
            "name": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Назва адміністративно-територіальних об’єктів України"
            },
            "id": {
              "legalName": "Код адміністративно-територіальних об’єктів України"
            },
            "legalName": "Код об’єктів адміністративно-територіального устрою України"
          },
          "streetAddress": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Адреса"
          },
          "locality": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Населений пункт"
          },
          "region": {
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Область"
          },
          "postalCode": {
            "legalName": "Поштовий індекс"
          },
          "countryName": {
            "en_US": {
              "legalName": "Назва англійською"
            }
          },
          "legalName": "Місцезнаходження Організатора аукціону"
        },
        "contactPoint": {
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "ПІБ"
          },
          "email": {
            "legalName": "Адреса електронної пошти"
          },
          "telephone": {
            "legalName": "Номер телефону"
          },
          "faxNumber": {
            "legalName": "Номер факсу"
          },
          "url": {
            "legalName": "Веб адреса"
          },
          "legalName": "Представник"
        },
        "representativeInfo": {
          "legalName": "Інформація щодо підтвердження повноважень"
        },
        "legalName": "Інформація про Організатора аукціону"
      },
      "lotId": {
        "legalName": "Номер лота"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва аукціону"
      },
      "auctionUrl": {
        "legalName": "Веб-адреса для перегляду аукціону"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис аукціону"
      },
      "accessDetails": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Порядок та можливий час ознайомлення з лотом"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Стартова ціна"
      },
      "discount": {
        "previousAuctionValue": {
          "currency": {
            "legalName": "Валюта"
          },
          "amount": {
            "legalName": "Сума"
          },
          "valueAddedTaxIncluded": {
            "legalName": "Податок"
          },
          "legalName": "Стартова вартість попереднього аукціону"
        },
        "discountPercent": {
          "legalName": "Розмір знижки від попереднього аукціону, %"
        },
        "discount": {
          "legalName": "Знижка"
        },
        "legalName": "Знижки"
      },
      "minimalStep": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Розмір кроку аукціону"
      },
      "minNumberOfQualifiedBids": {
        "legalName": "Мінімальна кількість заяв"
      },
      "tenderAttempts": {
        "legalName": "Лот виставляється"
      },
      "items": {
        "legalName": "Склад лота"
      },
      "documents": {
        "legalName": "Всі документи аукціону та пов'язані додатки"
      },
      "bids": {
        "legalName": "Заява на участь"
      },
      "questions": {
        "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
      },
      "awards": {
        "legalName": "Рішення щодо кваліфікації учасників"
      },
      "enquiryPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період відповідей"
      },
      "tenderPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період подання заяви на участь в аукціоні"
      },
      "auctionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Дата та час проведення аукціону"
      },
      "questionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період запитань"
      },
      "qualificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період кваліфікації"
      },
      "status": {
        "legalName": "Статус процедури"
      },
      "cancellations": {
        "legalName": "Скасування Аукціону"
      },
      "registrationFee": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Реєстраційний внесок"
      }
    }
  },
  "railwayCargo-dutch": {
    "Bid": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "id": {
        "legalName": "Ідентифікатор заяви на участь"
      },
      "bidders": {
        "legalName": "Інформація про учасника"
      },
      "datePublished": {
        "legalName": "Дата заяви на участь"
      },
      "dateModified": {
        "legalName": "Остання дата редагування ставки"
      },
      "status": {
        "legalName": "Статус заяви на участь"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Цінова пропозиція"
      },
      "documents": {
        "legalName": "Документи до заяви про участь"
      },
      "participationUrl": {
        "legalName": "Веб-адреса для участі в аукціоні"
      }
    },
    "RailwayCargoDutchProcedure": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "_id": {
        "legalName": "Внутрішній ідентифікатор аукціону"
      },
      "datePublished": {
        "legalName": "Дата публікації процедури"
      },
      "dateModified": {
        "legalName": "Остання дата зміни процедури"
      },
      "auctionId": {
        "legalName": "Ідентифікатор аукціону"
      },
      "carryType": {
        "legalName": "Тип аукціону"
      },
      "previousAuctionId": {
        "legalName": "Номер попереднього аукціону"
      },
      "sellingMethod": {
        "legalName": "Тип процедури"
      },
      "sellingEntity": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Найменування Організатора аукціону"
        },
        "identifier": {
          "scheme": {
            "legalName": "Тип ідентифікації Організатора аукціону"
          },
          "legalName": "Ідентифікатор Організатора аукціону",
          "id": {
            "legalName": "Ідентифікатор Організатора аукціону"
          }
        },
        "address": {
          "addressID": {
            "scheme": {
              "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
            },
            "name": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Назва адміністративно-територіальних об’єктів України"
            },
            "id": {
              "legalName": "Код адміністративно-територіальних об’єктів України"
            },
            "legalName": "Код об’єктів адміністративно-територіального устрою України"
          },
          "streetAddress": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Адреса"
          },
          "locality": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Населений пункт"
          },
          "region": {
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Область"
          },
          "postalCode": {
            "legalName": "Поштовий індекс"
          },
          "countryName": {
            "en_US": {
              "legalName": "Назва англійською"
            }
          },
          "legalName": "Місцезнаходження Організатора аукціону"
        },
        "contactPoint": {
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "ПІБ"
          },
          "email": {
            "legalName": "Адреса електронної пошти"
          },
          "telephone": {
            "legalName": "Номер телефону"
          },
          "faxNumber": {
            "legalName": "Номер факсу"
          },
          "url": {
            "legalName": "Веб адреса"
          },
          "legalName": "Представник"
        },
        "representativeInfo": {
          "legalName": "Інформація щодо підтвердження повноважень"
        },
        "legalName": "Інформація про Організатора аукціону"
      },
      "lotId": {
        "legalName": "Номер лота"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва аукціону"
      },
      "auctionUrl": {
        "legalName": "Веб-адреса для перегляду аукціону"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис аукціону"
      },
      "accessDetails": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Порядок та можливий час ознайомлення з лотом"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Стартова ціна"
      },
      "discount": {
        "previousAuctionValue": {
          "currency": {
            "legalName": "Валюта"
          },
          "amount": {
            "legalName": "Сума"
          },
          "valueAddedTaxIncluded": {
            "legalName": "Податок"
          },
          "legalName": "Стартова вартість попереднього аукціону"
        },
        "discountPercent": {
          "legalName": "Розмір знижки від попереднього аукціону, %"
        },
        "discount": {
          "legalName": "Знижка"
        },
        "legalName": "Знижки"
      },
      "minimalStep": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Розмір кроку аукціону"
      },
      "dutchStep": {
        "dutchStepQuantity": {
          "legalName": "Кількість кроків"
        },
        "dutchStepPercent": {
          "legalName": "Відсоток зниження, %"
        },
        "dutchStepValue": {
          "currency": {
            "legalName": "Валюта"
          },
          "amount": {
            "legalName": "Сума"
          },
          "valueAddedTaxIncluded": {
            "legalName": "Податок"
          },
          "legalName": "Розмір кроку голландського раунду, грн"
        },
        "legalName": "Розмір кроку аукціону"
      },
      "tenderAttempts": {
        "legalName": "Лот виставляється"
      },
      "items": {
        "legalName": "Склад лота"
      },
      "documents": {
        "legalName": "Всі документи аукціону та пов'язані додатки"
      },
      "bids": {
        "legalName": "Заява на участь"
      },
      "questions": {
        "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
      },
      "awards": {
        "legalName": "Рішення щодо кваліфікації учасників"
      },
      "enquiryPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період відповідей"
      },
      "tenderPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період подання заяви на участь в аукціоні"
      },
      "auctionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Дата та час проведення аукціону"
      },
      "questionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період запитань"
      },
      "qualificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період кваліфікації"
      },
      "status": {
        "legalName": "Статус процедури"
      },
      "cancellations": {
        "legalName": "Скасування Аукціону"
      },
      "registrationFee": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Реєстраційний внесок"
      }
    }
  },
  "renewables": {
    "AdditionalClassification": {
      "scheme": {
        "legalName": "Схема додаткового класифікатору"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис додаткового класифікатору"
      },
      "id": {
        "legalName": "Код додаткового класифікатору"
      }
    },
    "Identifier": {
      "scheme": {
        "legalName": "Ідентифікатори організації"
      },
      "legalName": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Повна юридична назва організації"
      },
      "id": {
        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
      }
    },
    "Value": {
      "currency": {
        "legalName": "Валюта"
      },
      "amount": {
        "legalName": "Сума"
      },
      "valueAddedTaxIncluded": {
        "legalName": "Податок"
      }
    },
    "Question": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "id": {
        "legalName": "Ідентифікатор запитання"
      },
      "author": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "ПІБ"
        },
        "email": {
          "legalName": "Адреса електронної пошти"
        },
        "telephone": {
          "legalName": "Номер телефону"
        },
        "faxNumber": {
          "legalName": "Номер факсу"
        },
        "url": {
          "legalName": "Веб адреса"
        },
        "legalName": "Автор запитання"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва запитання"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Текст запитання"
      },
      "datePublished": {
        "legalName": "Дата публікації запитання"
      },
      "dateModified": {
        "legalName": "Дата редагування запитання"
      },
      "dateAnswered": {
        "legalName": "Дата відповіді на запитання"
      },
      "answer": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Відповідь на запитання"
      },
      "questionOf": {
        "legalName": "Об'єкт запитання"
      }
    },
    "Unit": {
      "code": {
        "legalName": "Код одиниці виміру"
      },
      "name": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва одиниці виміру"
      }
    },
    "Classification": {
      "scheme": {
        "legalName": "Схема класифікатора"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис коду класифікатора"
      },
      "id": {
        "legalName": "Код класифікатора"
      }
    },
    "Item": {
      "id": {
        "legalName": "Внутрішній ідентифікатор об'єкта"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис об'єкта"
      },
      "classification": {
        "scheme": {
          "legalName": "Схема класифікатора"
        },
        "description": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Опис коду класифікатора"
        },
        "id": {
          "legalName": "Код класифікатора"
        },
        "legalName": "Класифікатор об'єкта"
      },
      "unit": {
        "code": {
          "legalName": "Код одиниці виміру"
        },
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Назва одиниці виміру"
        },
        "legalName": "Одиниці виміру об'єкта"
      },
      "quantity": {
        "legalName": "Розмір частки річної квоти"
      },
      "address": {
        "addressID": {
          "scheme": {
            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
          },
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Назва адміністративно-територіальних об’єктів України"
          },
          "id": {
            "legalName": "Код адміністративно-територіальних об’єктів України"
          },
          "legalName": "Код об’єктів адміністративно-територіального устрою України"
        },
        "streetAddress": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Адреса"
        },
        "locality": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Населений пункт"
        },
        "region": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Область"
        },
        "postalCode": {
          "legalName": "Поштовий індекс"
        },
        "countryName": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Країна"
        },
        "legalName": "Місцезнаходження"
      },
      "additionalClassifications": {
        "legalName": "Додатковий класифікатор"
      },
      "location": {
        "latitude": {
          "legalName": "Широта"
        },
        "longitude": {
          "legalName": "Довгота"
        },
        "elevation": {
          "legalName": "Висота"
        },
        "legalName": "Географічні координати місця розташування"
      }
    },
    "Award": {
      "id": {
        "legalName": "Ідентифікатор кваліфікації/дискваліфікації учасника аукціону"
      },
      "bidId": {
        "legalName": "Ідентифікатор заяви на участь учасника аукціону"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва рішення щодо кваліфікованого/дискваліфікованого учасника аукціону"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис рішення учасника аукціону"
      },
      "status": {
        "legalName": "Статус кваліфікованого/дискваліфікованого учасника аукціону"
      },
      "terminationReason": {
        "legalName": "Причина дискваліфікації/відмови учаснику"
      },
      "datePublished": {
        "legalName": "Дата рішення про підписання договору"
      },
      "value": {
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Цінова пропозиція"
      },
      "buyers": {
        "legalName": "Відомості про всі кваліфікації/дискваліфікації учасників аукціону"
      },
      "items": {
        "legalName": "Склад лоту"
      },
      "documents": {
        "legalName": "Документи до аварду"
      },
      "dateModified": {
        "legalName": "Дата редагування"
      },
      "signingPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період публікації протоколу/підписання договору"
      },
      "admissionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період прийняття рішення щодо набуття статусу переможця"
      }
    },
    "Bid": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "id": {
        "legalName": "Ідентифікатор заяви на участь"
      },
      "bidders": {
        "legalName": "Інформація про учасника"
      },
      "datePublished": {
        "legalName": "Дата заяви на участь"
      },
      "dateModified": {
        "legalName": "Остання дата редагування ставки"
      },
      "status": {
        "legalName": "Статус заяви на участь"
      },
      "value": {
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Цінова пропозиція за 1 кВт⋅год"
      },
      "documents": {
        "legalName": "Документи до заяви про участь"
      },
      "participationUrl": {
        "legalName": "Веб-адреса для участі в аукціоні"
      },
      "classification": {
        "scheme": {
          "legalName": "Схема класифікатора"
        },
        "description": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Опис коду класифікатора"
        },
        "id": {
          "legalName": "Код класифікатора"
        },
        "legalName": "Класифікатор об'єкта"
      },
      "additionalClassifications": {
        "legalName": "Додатковий класифікатор"
      },
      "unit": {
        "code": {
          "legalName": "Код одиниці виміру"
        },
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Назва одиниці виміру"
        },
        "legalName": "Одиниці виміру"
      },
      "quantity": {
        "legalName": "Розмір частки квоти в заяві"
      },
      "qualified": {
        "legalName": "Відповідність учасника"
      },
      "initialValueAmount": {
        "legalName": "Початкова сума"
      }
    },
    "Cancellation": {
      "id": {
        "legalName": "Ідентифікатор об'єкта скасування"
      },
      "reason": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Причина скасування Аукціону"
      },
      "datePublished": {
        "legalName": "Дата скасування"
      },
      "cancellationOf": {
        "legalName": "Об'єкт скасування"
      }
    },
    "Contract": {
      "id": {
        "legalName": "Внутрішній ідентифікатор контракту"
      },
      "awardId": {
        "legalName": "Ідентифікатор переможця"
      },
      "contractNumber": {
        "legalName": "Номер договору"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва договору"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис договору"
      },
      "value": {
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Вартість договору"
      },
      "contractTotalValue": {
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Заключна вартість договору"
      },
      "items": {
        "legalName": "Склад лоту"
      },
      "buyers": {
        "legalName": "Інформація про учасника"
      },
      "status": {
        "legalName": "Статус договору"
      },
      "dateSigned": {
        "legalName": "Дата підписання договору"
      },
      "datePublished": {
        "legalName": "Дата створення об'єкта договіру"
      },
      "dateModified": {
        "legalName": "Остання дата редагування договіру"
      },
      "contractTime": {
        "dateFrom": {
          "legalName": "Період з"
        },
        "dateTill": {
          "legalName": "Період по"
        },
        "legalName": "Строк дії договору"
      },
      "documents": {
        "legalName": "Документи та додатки пов’язані з договором"
      },
      "x_valueUAH": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Загальна вартість договору в грн."
      }
    },
    "SellingEntity": {
      "name": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Найменування Замовника аукціону"
      },
      "identifier": {
        "scheme": {
          "legalName": "Тип ідентифікації Організатора аукціону"
        },
        "legalName": "Ідентифікатори Замовника аукціону",
        "id": {
          "legalName": "Ідентифікатор Організатора аукціону"
        }
      },
      "address": {
        "addressID": {
          "scheme": {
            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
          },
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Назва адміністративно-територіальних об’єктів України"
          },
          "id": {
            "legalName": "Код адміністративно-територіальних об’єктів України"
          },
          "legalName": "Код об’єктів адміністративно-територіального устрою України"
        },
        "streetAddress": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Адреса"
        },
        "locality": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Населений пункт"
        },
        "region": {
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Область"
        },
        "postalCode": {
          "legalName": "Поштовий індекс"
        },
        "countryName": {
          "en_US": {
            "legalName": "Назва англійською"
          }
        },
        "legalName": "Місцезнаходження Замовника аукціону"
      },
      "contactPoint": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "ПІБ"
        },
        "email": {
          "legalName": "Адреса електронної пошти"
        },
        "telephone": {
          "legalName": "Номер телефону"
        },
        "faxNumber": {
          "legalName": "Номер факсу"
        },
        "url": {
          "legalName": "Веб адреса"
        },
        "legalName": "Контактна особа"
      },
      "representativeInfo": {
        "legalName": "Інформація щодо підтвердження повноважень"
      },
      "x_verificationDocuments": {
        "legalName": "Ліцензія"
      }
    },
    "GreenProcedure": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "_id": {
        "legalName": "Внутрішній ідентифікатор аукціону"
      },
      "datePublished": {
        "legalName": "Дата створення процедури в ЦБД"
      },
      "dateModified": {
        "legalName": "Остання дата редагування процедури"
      },
      "auctionId": {
        "legalName": "Ідентифікатор аукціону"
      },
      "previousAuctionId": {
        "legalName": "Номер попереднього аукціону"
      },
      "sellingMethod": {
        "legalName": "Тип процедури"
      },
      "sellingEntity": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Найменування Замовника аукціону"
        },
        "identifier": {
          "scheme": {
            "legalName": "Тип ідентифікації Організатора аукціону"
          },
          "legalName": "Ідентифікатори Замовника аукціону",
          "id": {
            "legalName": "Ідентифікатор Організатора аукціону"
          }
        },
        "address": {
          "addressID": {
            "scheme": {
              "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
            },
            "name": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Назва адміністративно-територіальних об’єктів України"
            },
            "id": {
              "legalName": "Код адміністративно-територіальних об’єктів України"
            },
            "legalName": "Код об’єктів адміністративно-територіального устрою України"
          },
          "streetAddress": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Адреса"
          },
          "locality": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Населений пункт"
          },
          "region": {
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Область"
          },
          "postalCode": {
            "legalName": "Поштовий індекс"
          },
          "countryName": {
            "en_US": {
              "legalName": "Назва англійською"
            }
          },
          "legalName": "Місцезнаходження Замовника аукціону"
        },
        "contactPoint": {
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "ПІБ"
          },
          "email": {
            "legalName": "Адреса електронної пошти"
          },
          "telephone": {
            "legalName": "Номер телефону"
          },
          "faxNumber": {
            "legalName": "Номер факсу"
          },
          "url": {
            "legalName": "Веб адреса"
          },
          "legalName": "Контактна особа"
        },
        "representativeInfo": {
          "legalName": "Інформація щодо підтвердження повноважень"
        },
        "x_verificationDocuments": {
          "legalName": "Ліцензія"
        },
        "legalName": "Інформація про Замовника аукціону"
      },
      "lotId": {
        "legalName": "Номер лоту"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Заголовок аукціону"
      },
      "auctionUrl": {
        "legalName": "Веб-адреса для перегляду аукціону"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис аукціону"
      },
      "accessDetails": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Порядок та можливий час ознайомлення з лотом"
      },
      "bankAccount": {
        "legalName": "Банківські реквізити"
      },
      "x_documentRequirements": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Вимоги до оформлення документів"
      },
      "x_additionalInformation": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Додаткові відомості"
      },
      "x_quantityLimit": {
        "legalName": "80% сукупної величини потужності учасників"
      },
      "value": {
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Цінова пропозиція (max)"
      },
      "guarantee": {
        "amount": {
          "legalName": "Розмір банківської гарантії"
        },
        "valueAddedTaxIncluded": {
          "legalName": "ПДВ"
        },
        "legalName": "Банківська гарантія за 1 кВт"
      },
      "minimalStep": {
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Мінімальний крок аукціону"
      },
      "minNumberOfQualifiedBids": {
        "legalName": "Мінімальна кількість заяв учасників"
      },
      "tenderAttempts": {
        "legalName": "Лот виставляється"
      },
      "items": {
        "legalName": "Склад лоту"
      },
      "documents": {
        "legalName": "Всі документи аукціону та пов'язані додатки"
      },
      "bids": {
        "legalName": "Заява на участь"
      },
      "questions": {
        "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
      },
      "awards": {
        "legalName": "Всі кваліфікації (дискваліфікації та визначення переможця)"
      },
      "contracts": {
        "legalName": "Договір"
      },
      "rectificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період редагування лота"
      },
      "enquiryPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період відповідей"
      },
      "tenderPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період подання заяви на участь в аукціоні"
      },
      "auctionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Дата та час проведення аукціону"
      },
      "waitingPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період очікування кваліфікації/дискваліфікації переможців"
      },
      "verificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період перевірки документів"
      },
      "questionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період запитань"
      },
      "status": {
        "legalName": "Статус процедури"
      },
      "cancellations": {
        "legalName": "Скасування Аукціону"
      }
    },
    "MinimalStep": {
      "currency": {
        "legalName": "Валюта"
      },
      "amount": {
        "legalName": "Сума"
      },
      "valueAddedTaxIncluded": {
        "legalName": "Податок"
      }
    },
    "RenewablesMultiAwardsProcedure": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "_id": {
        "legalName": "Внутрішній ідентифікатор аукціону"
      },
      "datePublished": {
        "legalName": "Дата створення процедури в ЦБД"
      },
      "dateModified": {
        "legalName": "Остання дата редагування процедури"
      },
      "auctionId": {
        "legalName": "Ідентифікатор аукціону"
      },
      "previousAuctionId": {
        "legalName": "Номер попереднього аукціону"
      },
      "sellingMethod": {
        "legalName": "Тип процедури"
      },
      "sellingEntity": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Найменування Замовника аукціону"
        },
        "identifier": {
          "scheme": {
            "legalName": "Тип ідентифікації Організатора аукціону"
          },
          "legalName": "Ідентифікатори Замовника аукціону",
          "id": {
            "legalName": "Ідентифікатор Організатора аукціону"
          }
        },
        "address": {
          "addressID": {
            "scheme": {
              "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
            },
            "name": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Назва адміністративно-територіальних об’єктів України"
            },
            "id": {
              "legalName": "Код адміністративно-територіальних об’єктів України"
            },
            "legalName": "Код об’єктів адміністративно-територіального устрою України"
          },
          "streetAddress": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Адреса"
          },
          "locality": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Населений пункт"
          },
          "region": {
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Область"
          },
          "postalCode": {
            "legalName": "Поштовий індекс"
          },
          "countryName": {
            "en_US": {
              "legalName": "Назва англійською"
            }
          },
          "legalName": "Місцезнаходження Замовника аукціону"
        },
        "contactPoint": {
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "ПІБ"
          },
          "email": {
            "legalName": "Адреса електронної пошти"
          },
          "telephone": {
            "legalName": "Номер телефону"
          },
          "faxNumber": {
            "legalName": "Номер факсу"
          },
          "url": {
            "legalName": "Веб адреса"
          },
          "legalName": "Контактна особа"
        },
        "representativeInfo": {
          "legalName": "Інформація щодо підтвердження повноважень"
        },
        "x_verificationDocuments": {
          "legalName": "Ліцензія"
        },
        "legalName": "Інформація про Замовника аукціону"
      },
      "lotId": {
        "legalName": "Номер лоту"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Заголовок аукціону"
      },
      "auctionUrl": {
        "legalName": "Веб-адреса для перегляду аукціону"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис аукціону"
      },
      "accessDetails": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Порядок та можливий час ознайомлення з лотом"
      },
      "bankAccount": {
        "legalName": "Банківські реквізити"
      },
      "x_documentRequirements": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Вимоги до оформлення документів"
      },
      "x_additionalInformation": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Додаткові відомості"
      },
      "x_quantityLimit": {
        "legalName": "80% сукупної величини потужності учасників"
      },
      "value": {
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Цінова пропозиція (max)"
      },
      "guarantee": {
        "amount": {
          "legalName": "Розмір банківської гарантії"
        },
        "valueAddedTaxIncluded": {
          "legalName": "ПДВ"
        },
        "legalName": "Банківська гарантія за 1 кВт"
      },
      "minimalStep": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        }
      },
      "minNumberOfQualifiedBids": {
        "legalName": "Мінімальна кількість заяв учасників"
      },
      "tenderAttempts": {
        "legalName": "Лот виставляється"
      },
      "items": {
        "legalName": "Склад лоту"
      },
      "documents": {
        "legalName": "Всі документи аукціону та пов'язані додатки"
      },
      "bids": {
        "legalName": "Заява на участь"
      },
      "questions": {
        "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
      },
      "awards": {
        "legalName": "Всі кваліфікації (дискваліфікації та визначення переможця)"
      },
      "contracts": {
        "legalName": "Договір"
      },
      "rectificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період редагування лота"
      },
      "enquiryPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період відповідей"
      },
      "tenderPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період подання заяви на участь в аукціоні"
      },
      "auctionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Дата та час проведення аукціону"
      },
      "waitingPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період очікування кваліфікації/дискваліфікації переможців"
      },
      "verificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період перевірки документів"
      },
      "questionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період запитань"
      },
      "status": {
        "legalName": "Статус процедури"
      },
      "cancellations": {
        "legalName": "Скасування Аукціону"
      }
    }
  },
  "subsoil": {
    "Classification": {
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис коду класифікатора"
      },
      "id": {
        "legalName": "Класифікатор об'єкта"
      }
    },
    "Unit": {
      "name": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва одиниці виміру"
      }
    },
    "AdditionalClassification": {
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис додаткового класифікатору"
      },
      "id": {
        "legalName": "Код додаткового класифікатору"
      }
    },
    "Item": {
      "id": {
        "legalName": "Внутрішній ідентифікатор об'єкта"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис об'єкта"
      },
      "classification": {
        "description": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Опис коду класифікатора"
        },
        "id": {
          "legalName": "Класифікатор об'єкта"
        }
      },
      "unit": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Назва одиниці виміру"
        }
      },
      "quantity": {
        "legalName": "Кількість одиниць"
      },
      "address": {
        "addressID": {
          "scheme": {
            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
          },
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Назва адміністративно-територіальних об’єктів України"
          },
          "id": {
            "legalName": "Код адміністративно-територіальних об’єктів України"
          },
          "legalName": "Код об’єктів адміністративно-територіального устрою України"
        },
        "streetAddress": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Адреса"
        },
        "locality": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Назва ділянки надр"
        },
        "region": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Область"
        },
        "postalCode": {
          "legalName": "Поштовий індекс"
        },
        "countryName": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Країна"
        }
      },
      "additionalClassifications": {
        "legalName": "Додатковий класифікатор об'єкта"
      }
    },
    "Award": {
      "id": {
        "legalName": "Ідентифікатор кваліфікації учасника аукціону"
      },
      "bidId": {
        "legalName": "Ідентифікатор заяви на участь учасника аукціону"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
      },
      "terminationReason": {
        "legalName": "Причина дискваліфікації/відмови учаснику"
      },
      "datePublished": {
        "legalName": "Дата початку кваліфікації учасника аукціону"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Цінова пропозиція"
      },
      "buyers": {
        "legalName": "Інформація про учасника"
      },
      "dateModified": {
        "legalName": "Дата редагування"
      },
      "signingPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період підписання договору"
      },
      "paymentPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період сплати до бюджету"
      },
      "admissionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період виконання умов договору"
      },
      "verificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період підписання протоколу"
      }
    },
    "Contract": {
      "id": {
        "legalName": "Внутрішній ідентифікатор контракту"
      },
      "awardId": {
        "legalName": "Ідентифікатор переможця"
      },
      "contractNumber": {
        "legalName": "Номер договору"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва договору"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис договору"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Вартість договору"
      },
      "contractTotalValue": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Заключна вартість договору"
      },
      "buyers": {
        "legalName": "Переможець"
      },
      "dateSigned": {
        "legalName": "Дата підписання договору"
      },
      "datePublished": {
        "legalName": "Дата створення об'єкта договіру"
      },
      "dateModified": {
        "legalName": "Остання дата редагування договіру"
      },
      "contractTime": {
        "dateFrom": {
          "legalName": "Період з"
        },
        "dateTill": {
          "legalName": "Період по"
        },
        "legalName": "Строк дії договору"
      },
      "documents": {
        "legalName": "Документи та додатки пов'язані з договором"
      },
      "datePaid": {
        "legalName": "Дата сплати до державного бюджету України"
      }
    },
    "Cancellation": {
      "id": {
        "legalName": "Ідентифікатор об'єкта скасування"
      },
      "reason": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Причина скасування Аукціону"
      },
      "datePublished": {
        "legalName": "Дата скасування"
      }
    },
    "RegistrationFee": {
      "currency": {
        "legalName": "Валюта"
      },
      "valueAddedTaxIncluded": {
        "legalName": "Податок"
      }
    }
  },
  "subsoil-english": {
    "Bid": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "id": {
        "legalName": "Ідентифікатор заяви на участь"
      },
      "bidders": {
        "legalName": "Інформація про учасника"
      },
      "datePublished": {
        "legalName": "Дата заяви на участь"
      },
      "dateModified": {
        "legalName": "Остання дата редагування ставки"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Цінова пропозиція"
      },
      "participationUrl": {
        "legalName": "Веб-адреса для участі в аукціоні"
      },
      "initialValueAmount": {
        "legalName": "Початкова сума"
      }
    },
    "SubsoilEnglishProcedure": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "_id": {
        "legalName": "Внутрішній ідентифікатор аукціону"
      },
      "datePublished": {
        "legalName": "Дата публікації процедури"
      },
      "dateModified": {
        "legalName": "Остання дата зміни процедури"
      },
      "auctionId": {
        "legalName": "Ідентифікатор аукціону"
      },
      "previousAuctionId": {
        "legalName": "Номер попереднього аукціону"
      },
      "sellingMethod": {
        "legalName": "Тип процедури"
      },
      "sellingEntity": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Найменування Організатора аукціону"
        },
        "identifier": {
          "legalName": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Повна юридична назва Організатора аукціону"
          },
          "id": {
            "legalName": "Ідентифікатор Організатора аукціону"
          }
        },
        "address": {
          "addressID": {
            "scheme": {
              "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
            },
            "name": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Назва адміністративно-територіальних об’єктів України"
            },
            "id": {
              "legalName": "Код адміністративно-територіальних об’єктів України"
            },
            "legalName": "Код об’єктів адміністративно-територіального устрою України"
          },
          "streetAddress": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Адреса"
          },
          "locality": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Населений пункт"
          },
          "region": {
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Область"
          },
          "postalCode": {
            "legalName": "Поштовий індекс"
          },
          "countryName": {
            "en_US": {
              "legalName": "Назва англійською"
            }
          },
          "legalName": "Місцезнаходження Організатора аукціону"
        },
        "contactPoint": {
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "ПІБ"
          },
          "email": {
            "legalName": "Адреса електронної пошти"
          },
          "telephone": {
            "legalName": "Номер телефону"
          },
          "faxNumber": {
            "legalName": "Номер факсу"
          },
          "url": {
            "legalName": "Веб адреса"
          },
          "legalName": "Представник"
        },
        "representativeInfo": {
          "legalName": "Інформація щодо підтвердження повноважень"
        },
        "legalName": "Інформація про Організатора аукціону"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва аукціону"
      },
      "lotId": {
        "legalName": "Номер лота"
      },
      "auctionUrl": {
        "legalName": "Веб-адреса для перегляду аукціону"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис аукціону"
      },
      "accessDetails": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Порядок та можливий час ознайомлення з лотом"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Стартова ціна лота"
      },
      "discount": {
        "previousAuctionValue": {
          "currency": {
            "legalName": "Валюта"
          },
          "amount": {
            "legalName": "Сума"
          },
          "valueAddedTaxIncluded": {
            "legalName": "Податок"
          },
          "legalName": "Стартова вартість попереднього аукціону"
        },
        "discountPercent": {
          "legalName": "Розмір знижки від попереднього аукціону, %"
        },
        "discount": {
          "legalName": "Знижка"
        },
        "legalName": "Знижки"
      },
      "tenderAttempts": {
        "legalName": "Лот виставляється"
      },
      "minimalStep": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Розмір кроку аукціону"
      },
      "guarantee": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Гарантійний внесок"
      },
      "geologicDataValue": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Вартість геологічної інформації;"
      },
      "documentationValue": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Вартість пакета аукціонної документації, що сплачується переможцем;"
      },
      "leaseDuration": {
        "legalName": "Строк, на який надається дозвіл"
      },
      "minNumberOfQualifiedBids": {
        "legalName": "Мінімальна кількість заяв"
      },
      "items": {
        "legalName": "Склад лота"
      },
      "x_documentRequirements": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Вимоги до оформлення документів"
      },
      "documents": {
        "legalName": "Всі документи аукціону та пов'язані додатки"
      },
      "bids": {
        "legalName": "Заява на участь"
      },
      "questions": {
        "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
      },
      "awards": {
        "legalName": "Рішення щодо кваліфікації учасників"
      },
      "contracts": {
        "legalName": "Договір"
      },
      "rectificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період редагування лота"
      },
      "enquiryPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період відповідей"
      },
      "tenderPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період подання заяви на участь в аукціоні"
      },
      "auctionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Дата та час проведення аукціону"
      },
      "qualificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період кваліфікації"
      },
      "questionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період запитань"
      },
      "status": {
        "legalName": "Статус процедури"
      },
      "cancellations": {
        "legalName": "Скасування Аукціону"
      },
      "registrationFee": {
        "currency": {
          "legalName": "Валюта"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Реєстраційний внесок"
      }
    }
  },
  "subsoil-dutch": {
    "Bid": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "id": {
        "legalName": "Ідентифікатор заяви на участь"
      },
      "bidders": {
        "legalName": "Інформація про учасника"
      },
      "datePublished": {
        "legalName": "Дата заяви на участь"
      },
      "dateModified": {
        "legalName": "Остання дата редагування ставки"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Цінова пропозиція"
      },
      "documents": {
        "legalName": "Документи до заяви про участь"
      },
      "participationUrl": {
        "legalName": "Веб-адреса для участі в аукціоні"
      }
    },
    "SubsoilDutchProcedure": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "_id": {
        "legalName": "Внутрішній ідентифікатор аукціону"
      },
      "datePublished": {
        "legalName": "Дата публікації процедури"
      },
      "dateModified": {
        "legalName": "Остання дата зміни процедури"
      },
      "auctionId": {
        "legalName": "Ідентифікатор аукціону"
      },
      "previousAuctionId": {
        "legalName": "Номер попереднього аукціону"
      },
      "sellingMethod": {
        "legalName": "Тип процедури"
      },
      "sellingEntity": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Найменування Організатора аукціону"
        },
        "identifier": {
          "legalName": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Повна юридична назва Організатора аукціону"
          },
          "id": {
            "legalName": "Ідентифікатор Організатора аукціону"
          }
        },
        "address": {
          "addressID": {
            "scheme": {
              "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
            },
            "name": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Назва адміністративно-територіальних об’єктів України"
            },
            "id": {
              "legalName": "Код адміністративно-територіальних об’єктів України"
            },
            "legalName": "Код об’єктів адміністративно-територіального устрою України"
          },
          "streetAddress": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Адреса"
          },
          "locality": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Населений пункт"
          },
          "region": {
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Область"
          },
          "postalCode": {
            "legalName": "Поштовий індекс"
          },
          "countryName": {
            "en_US": {
              "legalName": "Назва англійською"
            }
          },
          "legalName": "Місцезнаходження Організатора аукціону"
        },
        "contactPoint": {
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "ПІБ"
          },
          "email": {
            "legalName": "Адреса електронної пошти"
          },
          "telephone": {
            "legalName": "Номер телефону"
          },
          "faxNumber": {
            "legalName": "Номер факсу"
          },
          "url": {
            "legalName": "Веб адреса"
          },
          "legalName": "Представник"
        },
        "representativeInfo": {
          "legalName": "Інформація щодо підтвердження повноважень"
        },
        "legalName": "Інформація про Організатора аукціону"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва аукціону"
      },
      "lotId": {
        "legalName": "Номер лота"
      },
      "auctionUrl": {
        "legalName": "Веб-адреса для перегляду аукціону"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис аукціону"
      },
      "accessDetails": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Порядок та можливий час ознайомлення з лотом"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Стартова ціна лота"
      },
      "discount": {
        "previousAuctionValue": {
          "currency": {
            "legalName": "Валюта"
          },
          "amount": {
            "legalName": "Сума"
          },
          "valueAddedTaxIncluded": {
            "legalName": "Податок"
          },
          "legalName": "Стартова вартість попереднього аукціону"
        },
        "discountPercent": {
          "legalName": "Розмір знижки від попереднього аукціону, %"
        },
        "discount": {
          "legalName": "Знижка"
        },
        "legalName": "Знижки"
      },
      "tenderAttempts": {
        "legalName": "Лот виставляється"
      },
      "minimalStep": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Розмір кроку аукціону"
      },
      "dutchStep": {
        "dutchStepQuantity": {
          "legalName": "Кількість кроків"
        },
        "dutchStepPercent": {
          "legalName": "Відсоток зниження, %"
        },
        "dutchStepValue": {
          "currency": {
            "legalName": "Валюта"
          },
          "amount": {
            "legalName": "Сума"
          },
          "valueAddedTaxIncluded": {
            "legalName": "Податок"
          },
          "legalName": "Розмір кроку голландського раунду, грн"
        },
        "legalName": "Розмір кроку аукціону"
      },
      "guarantee": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Гарантійний внесок"
      },
      "geologicDataValue": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Вартість геологічної інформації;"
      },
      "documentationValue": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Вартість пакета аукціонної документації, що сплачується переможцем;"
      },
      "leaseDuration": {
        "legalName": "Строк, на який надається дозвіл"
      },
      "items": {
        "legalName": "Склад лота"
      },
      "x_documentRequirements": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Вимоги до оформлення документів"
      },
      "documents": {
        "legalName": "Всі документи аукціону та пов'язані додатки"
      },
      "bids": {
        "legalName": "Заява на участь"
      },
      "questions": {
        "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
      },
      "awards": {
        "legalName": "Рішення щодо кваліфікації учасників"
      },
      "contracts": {
        "legalName": "Договір"
      },
      "rectificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період редагування лота"
      },
      "enquiryPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період відповідей"
      },
      "tenderPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період подання заяви на участь в аукціоні"
      },
      "auctionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Дата та час проведення аукціону"
      },
      "qualificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період кваліфікації"
      },
      "questionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період запитань"
      },
      "status": {
        "legalName": "Статус процедури"
      },
      "cancellations": {
        "legalName": "Скасування Аукціону"
      },
      "registrationFee": {
        "currency": {
          "legalName": "Валюта"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Реєстраційний внесок"
      }
    }
  },
  "timber": {
    "Unit": {
      "name": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва одиниці виміру"
      }
    },
    "AdditionalClassification": {
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис додаткового класифікатору"
      },
      "scheme": {
        "legalName": "Схема додаткового класифікатору"
      },
      "id": {
        "legalName": "Код додаткового класифікатору"
      }
    },
    "Classification": {
      "scheme": {
        "legalName": "Схема класифікатора"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис класифікатора"
      },
      "id": {
        "legalName": "Код класифікатора"
      }
    },
    "Identifier": {
      "scheme": {
        "legalName": "Ідентифікатори організації"
      },
      "legalName": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Повна юридична назва організації"
      },
      "id": {
        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
      }
    },
    "PrepaymentDetails": {
      "date": {
        "legalName": "Дата передоплати"
      },
      "prepayment": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Розмір передоплати"
      }
    },
    "Organization": {
      "name": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Повна юридична назва організації або ПІБ"
      },
      "identifier": {
        "scheme": {
          "legalName": "Ідентифікатори організації"
        },
        "legalName": "Ідентифікатори організації",
        "id": {
          "legalName": "Код ЄДРПОУ або ІПН або паспорт"
        }
      },
      "address": {
        "addressID": {
          "scheme": {
            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
          },
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Назва адміністративно-територіальних об’єктів України"
          },
          "id": {
            "legalName": "Код адміністративно-територіальних об’єктів України"
          },
          "legalName": "Код об’єктів адміністративно-територіального устрою України"
        },
        "streetAddress": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Адреса"
        },
        "locality": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Населений пункт"
        },
        "region": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Область"
        },
        "postalCode": {
          "legalName": "Поштовий індекс"
        },
        "countryName": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Країна"
        },
        "legalName": "Адреса"
      },
      "representativeInfo": {
        "legalName": "Інформація щодо підтвердження повноважень"
      },
      "contactPoint": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "ПІБ"
        },
        "email": {
          "legalName": "Адреса електронної пошти"
        },
        "telephone": {
          "legalName": "Номер телефону"
        },
        "faxNumber": {
          "legalName": "Номер факсу"
        },
        "url": {
          "legalName": "Веб адреса"
        },
        "legalName": "Контактна особа"
      },
      "x_verificationDocuments": {
        "description": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Опис документа"
        },
        "id": {
          "legalName": "Номер документа"
        },
        "date": {
          "legalName": "Дата видачі документа"
        },
        "legalName": "Реквізити документу, що підтверджує повноваження"
      }
    },
    "BankAccount": {
      "id": {
        "legalName": "Ідентифікатор банківського рахунку"
      },
      "bankName": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва банку"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис"
      },
      "accountIdentifications": {
        "legalName": "Банківські реквізити"
      }
    }
  },
  "timber-english": {
    "Item": {
      "id": {
        "legalName": "Внутрішній ідентифікатор об'єкта"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис об'єкта"
      },
      "classification": {
        "scheme": {
          "legalName": "Схема класифікатора"
        },
        "description": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Опис класифікатора"
        },
        "id": {
          "legalName": "Код класифікатора"
        },
        "legalName": "Класифікатор об'єкта"
      },
      "unit": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Назва одиниці виміру"
        },
        "legalName": "Одиниці виміру"
      },
      "quantity": {
        "legalName": "Розмір партії деревини"
      },
      "address": {
        "addressID": {
          "scheme": {
            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
          },
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Назва адміністративно-територіальних об’єктів України"
          },
          "id": {
            "legalName": "Код адміністративно-територіальних об’єктів України"
          },
          "legalName": "Код об’єктів адміністративно-територіального устрою України"
        },
        "streetAddress": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Адреса"
        },
        "locality": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Населений пункт"
        },
        "region": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Область"
        },
        "postalCode": {
          "legalName": "Поштовий індекс"
        },
        "countryName": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Країна"
        },
        "legalName": "Місцезнаходження"
      },
      "additionalClassifications": {
        "legalName": "Додатковий класифікатор"
      },
      "unitValue": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Стартова ціна за 1 м.куб"
      }
    },
    "Award": {
      "id": {
        "legalName": "Ідентифікатор кваліфікації учасника аукціону"
      },
      "bidId": {
        "legalName": "Ідентифікатор заяви на участь учасника аукціону"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
      },
      "status": {
        "legalName": "Статус кваліфікації учасника аукціону"
      },
      "terminationReason": {
        "legalName": "Причина дискваліфікації/відмови учаснику"
      },
      "datePublished": {
        "legalName": "Дата початку кваліфікації учасника аукціону"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Цінова пропозиція"
      },
      "buyers": {
        "legalName": "Інформація про учасника"
      },
      "items": {
        "legalName": "Склад лота"
      },
      "documents": {
        "legalName": "Документи до аварду"
      },
      "dateModified": {
        "legalName": "Дата редагування"
      },
      "signingPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період підписання договору"
      },
      "verificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період підписання протоколу"
      }
    },
    "Bid": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "id": {
        "legalName": "Ідентифікатор заяви на участь"
      },
      "bidders": {
        "legalName": "Інформація про учасника"
      },
      "datePublished": {
        "legalName": "Дата розміщення заяви на участь"
      },
      "dateModified": {
        "legalName": "Остання дата редагування ставки"
      },
      "status": {
        "legalName": "Статус заяви на участь"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Цінова пропозиція"
      },
      "documents": {
        "legalName": "Документи до заяви про участь"
      },
      "participationUrl": {
        "legalName": "Веб-адреса для участі в аукціоні"
      },
      "initialValueAmount": {
        "legalName": "Початкова сума"
      }
    },
    "Contract": {
      "id": {
        "legalName": "Внутрішній ідентифікатор контракту"
      },
      "awardId": {
        "legalName": "Ідентифікатор переможця"
      },
      "contractNumber": {
        "legalName": "Номер договору"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва договору"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис договору"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Вартість договору"
      },
      "contractTotalValue": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Заключна вартість договору"
      },
      "items": {
        "legalName": "Склад лоту"
      },
      "buyers": {
        "legalName": "Переможець"
      },
      "status": {
        "legalName": "Статус договору"
      },
      "dateSigned": {
        "legalName": "Дата підписання договору"
      },
      "datePublished": {
        "legalName": "Дата створення об'єкта договіру"
      },
      "dateModified": {
        "legalName": "Остання дата редагування договіру"
      },
      "contractTime": {
        "dateFrom": {
          "legalName": "Період з"
        },
        "dateTill": {
          "legalName": "Період по"
        },
        "legalName": "Строк дії договору"
      },
      "documents": {
        "legalName": "Документи та додатки пов’язані з договором"
      },
      "x_prepaymentDetails": {
        "date": {
          "legalName": "Дата передоплати"
        },
        "prepayment": {
          "currency": {
            "legalName": "Валюта"
          },
          "amount": {
            "legalName": "Сума"
          },
          "valueAddedTaxIncluded": {
            "legalName": "Податок"
          },
          "legalName": "Розмір передоплати"
        },
        "legalName": "Інформація про передоплату"
      }
    },
    "TimberEnglishProcedure": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "_id": {
        "legalName": "Внутрішній ідентифікатор аукціону"
      },
      "datePublished": {
        "legalName": "Дата публікації процедури"
      },
      "dateModified": {
        "legalName": "Остання дата зміни процедури"
      },
      "auctionId": {
        "legalName": "Ідентифікатор аукціону"
      },
      "previousAuctionId": {
        "legalName": "Номер попереднього аукціону"
      },
      "sellingMethod": {
        "legalName": "Тип процедури"
      },
      "sellingEntity": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Найменування Організатора аукціону"
        },
        "identifier": {
          "scheme": {
            "legalName": "Тип ідентифікації Організатора аукціону"
          },
          "legalName": "Ідентифікатор Організатора аукціону",
          "id": {
            "legalName": "Ідентифікатор Організатора аукціону"
          }
        },
        "address": {
          "addressID": {
            "scheme": {
              "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
            },
            "name": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Назва адміністративно-територіальних об’єктів України"
            },
            "id": {
              "legalName": "Код адміністративно-територіальних об’єктів України"
            },
            "legalName": "Код об’єктів адміністративно-територіального устрою України"
          },
          "streetAddress": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Адреса"
          },
          "locality": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Населений пункт"
          },
          "region": {
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Область"
          },
          "postalCode": {
            "legalName": "Поштовий індекс"
          },
          "countryName": {
            "en_US": {
              "legalName": "Назва англійською"
            }
          },
          "legalName": "Місцезнаходження Організатора аукціону"
        },
        "contactPoint": {
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "ПІБ"
          },
          "email": {
            "legalName": "Адреса електронної пошти"
          },
          "telephone": {
            "legalName": "Номер телефону"
          },
          "faxNumber": {
            "legalName": "Номер факсу"
          },
          "url": {
            "legalName": "Веб адреса"
          },
          "legalName": "Представник"
        },
        "representativeInfo": {
          "legalName": "Інформація щодо підтвердження повноважень"
        },
        "legalName": "Інформація про Організатора аукціону"
      },
      "lotId": {
        "legalName": "Номер лота"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва аукціону"
      },
      "auctionUrl": {
        "legalName": "Веб-адреса для перегляду аукціону"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис аукціону"
      },
      "accessDetails": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Порядок та можливий час ознайомлення з лотом"
      },
      "bankAccount": {
        "legalName": "Банківський рахунок"
      },
      "x_documentRequirements": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Перелік та вимоги до оформлення документів"
      },
      "x_additionalInformation": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Додаткові відомості"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Стартова ціна лота"
      },
      "discount": {
        "previousAuctionValue": {
          "currency": {
            "legalName": "Валюта"
          },
          "amount": {
            "legalName": "Сума"
          },
          "valueAddedTaxIncluded": {
            "legalName": "Податок"
          },
          "legalName": "Стартова вартість попереднього аукціону"
        },
        "discountPercent": {
          "legalName": "Розмір знижки від попереднього аукціону, %"
        },
        "discount": {
          "legalName": "Знижка"
        },
        "legalName": "Знижки"
      },
      "guarantee": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Гарантійний внесок"
      },
      "minimalStep": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Розмір кроку аукціону"
      },
      "minNumberOfQualifiedBids": {
        "legalName": "Мінімальна кількість заяв"
      },
      "tenderAttempts": {
        "legalName": "Лот виставляється"
      },
      "items": {
        "legalName": "Склад лота"
      },
      "documents": {
        "legalName": "Всі документи аукціону та пов'язані додатки"
      },
      "bids": {
        "legalName": "Заява на участь"
      },
      "questions": {
        "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
      },
      "awards": {
        "legalName": "Рішення щодо кваліфікації учасників"
      },
      "contracts": {
        "legalName": "Договір"
      },
      "rectificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період редагування лота"
      },
      "enquiryPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період відповідей"
      },
      "tenderPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період подання заяви на участь в аукціоні"
      },
      "auctionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Дата та час проведення аукціону"
      },
      "questionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період запитань"
      },
      "qualificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період кваліфікації"
      },
      "status": {
        "legalName": "Статус процедури"
      },
      "cancellations": {
        "legalName": "Скасування Аукціону"
      },
      "registrationFee": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Реєстраційний внесок"
      }
    }
  },
  "timber-multiAwards": {
    "Item": {
      "id": {
        "legalName": "Внутрішній ідентифікатор об'єкта"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис об'єкта"
      },
      "classification": {
        "scheme": {
          "legalName": "Схема класифікатора"
        },
        "description": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Опис класифікатора"
        },
        "id": {
          "legalName": "Код класифікатора"
        },
        "legalName": "Класифікатор об'єкта"
      },
      "unit": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Назва одиниці виміру"
        },
        "legalName": "Одиниці виміру"
      },
      "quantity": {
        "legalName": "Розмір партії деревини"
      },
      "address": {
        "addressID": {
          "scheme": {
            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
          },
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Назва адміністративно-територіальних об’єктів України"
          },
          "id": {
            "legalName": "Код адміністративно-територіальних об’єктів України"
          },
          "legalName": "Код об’єктів адміністративно-територіального устрою України"
        },
        "streetAddress": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Адреса"
        },
        "locality": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Населений пункт"
        },
        "region": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Область"
        },
        "postalCode": {
          "legalName": "Поштовий індекс"
        },
        "countryName": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Країна"
        },
        "legalName": "Місцезнаходження"
      },
      "additionalClassifications": {
        "legalName": "Додатковий класифікатор"
      }
    },
    "Award": {
      "id": {
        "legalName": "Ідентифікатор кваліфікації учасника аукціону"
      },
      "bidId": {
        "legalName": "Ідентифікатор заяви на участь учасника аукціону"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
      },
      "status": {
        "legalName": "Статус кваліфікації учасника аукціону"
      },
      "terminationReason": {
        "legalName": "Причина дискваліфікації/відмови учаснику"
      },
      "datePublished": {
        "legalName": "Дата початку кваліфікації учасника аукціону"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Цінова пропозиція"
      },
      "buyers": {
        "legalName": "Інформація про учасника"
      },
      "items": {
        "legalName": "Склад лота"
      },
      "documents": {
        "legalName": "Документи до аварду"
      },
      "dateModified": {
        "legalName": "Дата редагування"
      },
      "signingPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період підписання договору"
      },
      "verificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період підписання протоколу"
      },
      "quantity": {
        "legalName": "Розмір партії деревини"
      },
      "unit": {
        "code": {
          "legalName": "Код одиниці виміру"
        },
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Назва одиниці виміру"
        },
        "legalName": "Одиниці виміру"
      }
    },
    "Contract": {
      "id": {
        "legalName": "Внутрішній ідентифікатор контракту"
      },
      "awardId": {
        "legalName": "Ідентифікатор переможця"
      },
      "contractNumber": {
        "legalName": "Номер договору"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва договору"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис договору"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Вартість договору"
      },
      "contractTotalValue": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Заключна вартість договору"
      },
      "items": {
        "legalName": "Склад лоту"
      },
      "buyers": {
        "legalName": "Переможець"
      },
      "status": {
        "legalName": "Статус договору"
      },
      "dateSigned": {
        "legalName": "Дата підписання договору"
      },
      "datePublished": {
        "legalName": "Дата створення об'єкта договіру"
      },
      "dateModified": {
        "legalName": "Остання дата редагування договіру"
      },
      "contractTime": {
        "dateFrom": {
          "legalName": "Період з"
        },
        "dateTill": {
          "legalName": "Період по"
        },
        "legalName": "Строк дії договору"
      },
      "documents": {
        "legalName": "Документи та додатки пов’язані з договором"
      },
      "x_prepaymentDetails": {
        "date": {
          "legalName": "Дата передоплати"
        },
        "prepayment": {
          "currency": {
            "legalName": "Валюта"
          },
          "amount": {
            "legalName": "Сума"
          },
          "valueAddedTaxIncluded": {
            "legalName": "Податок"
          },
          "legalName": "Розмір передоплати"
        },
        "legalName": "Інформація про передоплату"
      }
    },
    "Bid": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "id": {
        "legalName": "Ідентифікатор заяви на участь"
      },
      "bidders": {
        "legalName": "Інформація про учасника"
      },
      "datePublished": {
        "legalName": "Дата розміщення заяви на участь"
      },
      "dateModified": {
        "legalName": "Остання дата редагування ставки"
      },
      "status": {
        "legalName": "Статус заяви на участь"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Цінова пропозиція за 1 м.куб"
      },
      "documents": {
        "legalName": "Документи до заяви про участь"
      },
      "participationUrl": {
        "legalName": "Веб-адреса для участі в аукціоні"
      },
      "quantity": {
        "legalName": "Розмір партії деревини"
      },
      "unit": {
        "code": {
          "legalName": "Код одиниці виміру"
        },
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Назва одиниці виміру"
        },
        "legalName": "Одиниці виміру"
      },
      "initialValueAmount": {
        "legalName": "Початкова сума"
      }
    },
    "TimberMultiAwardsProcedure": {
      "owner": {
        "legalName": "Ідентифікатор майданчика"
      },
      "_id": {
        "legalName": "Внутрішній ідентифікатор аукціону"
      },
      "datePublished": {
        "legalName": "Дата публікації процедури"
      },
      "dateModified": {
        "legalName": "Остання дата зміни процедури"
      },
      "auctionId": {
        "legalName": "Ідентифікатор аукціону"
      },
      "previousAuctionId": {
        "legalName": "Номер попереднього аукціону"
      },
      "sellingMethod": {
        "legalName": "Тип процедури"
      },
      "sellingEntity": {
        "name": {
          "uk_UA": {
            "legalName": "Назва українською"
          },
          "en_US": {
            "legalName": "Назва англійською"
          },
          "legalName": "Найменування Організатора аукціону"
        },
        "identifier": {
          "scheme": {
            "legalName": "Тип ідентифікації Організатора аукціону"
          },
          "legalName": "Ідентифікатор Організатора аукціону",
          "id": {
            "legalName": "Ідентифікатор Організатора аукціону"
          }
        },
        "address": {
          "addressID": {
            "scheme": {
              "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
            },
            "name": {
              "uk_UA": {
                "legalName": "Назва українською"
              },
              "en_US": {
                "legalName": "Назва англійською"
              },
              "legalName": "Назва адміністративно-територіальних об’єктів України"
            },
            "id": {
              "legalName": "Код адміністративно-територіальних об’єктів України"
            },
            "legalName": "Код об’єктів адміністративно-територіального устрою України"
          },
          "streetAddress": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Адреса"
          },
          "locality": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Населений пункт"
          },
          "region": {
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "Область"
          },
          "postalCode": {
            "legalName": "Поштовий індекс"
          },
          "countryName": {
            "en_US": {
              "legalName": "Назва англійською"
            }
          },
          "legalName": "Місцезнаходження Організатора аукціону"
        },
        "contactPoint": {
          "name": {
            "uk_UA": {
              "legalName": "Назва українською"
            },
            "en_US": {
              "legalName": "Назва англійською"
            },
            "legalName": "ПІБ"
          },
          "email": {
            "legalName": "Адреса електронної пошти"
          },
          "telephone": {
            "legalName": "Номер телефону"
          },
          "faxNumber": {
            "legalName": "Номер факсу"
          },
          "url": {
            "legalName": "Веб адреса"
          },
          "legalName": "Представник"
        },
        "representativeInfo": {
          "legalName": "Інформація щодо підтвердження повноважень"
        },
        "legalName": "Інформація про Організатора аукціону"
      },
      "lotId": {
        "legalName": "Номер лота"
      },
      "title": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Назва аукціону"
      },
      "auctionUrl": {
        "legalName": "Веб-адреса для перегляду аукціону"
      },
      "description": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Опис аукціону"
      },
      "accessDetails": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Порядок та можливий час ознайомлення з лотом"
      },
      "bankAccount": {
        "legalName": "Банківський рахунок"
      },
      "x_documentRequirements": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Перелік та вимоги до оформлення документів"
      },
      "x_additionalInformation": {
        "uk_UA": {
          "legalName": "Назва українською"
        },
        "en_US": {
          "legalName": "Назва англійською"
        },
        "legalName": "Додаткові відомості"
      },
      "value": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Стартова ціна лота"
      },
      "discount": {
        "previousAuctionValue": {
          "currency": {
            "legalName": "Валюта"
          },
          "amount": {
            "legalName": "Сума"
          },
          "valueAddedTaxIncluded": {
            "legalName": "Податок"
          },
          "legalName": "Стартова вартість попереднього аукціону"
        },
        "discountPercent": {
          "legalName": "Розмір знижки від попереднього аукціону, %"
        },
        "discount": {
          "legalName": "Знижка"
        },
        "legalName": "Знижки"
      },
      "guarantee": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Гарантійний внесок"
      },
      "minimalStep": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Розмір кроку аукціону"
      },
      "minNumberOfQualifiedBids": {
        "legalName": "Мінімальна кількість заяв"
      },
      "minQuantity": {
        "legalName": "Мінімальний розмір партії деревини"
      },
      "tenderAttempts": {
        "legalName": "Лот виставляється"
      },
      "items": {
        "legalName": "Склад лота"
      },
      "documents": {
        "legalName": "Всі документи аукціону та пов'язані додатки"
      },
      "bids": {
        "legalName": "Заява на участь"
      },
      "questions": {
        "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
      },
      "awards": {
        "legalName": "Рішення щодо кваліфікації учасників"
      },
      "contracts": {
        "legalName": "Договір"
      },
      "rectificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період редагування лота"
      },
      "enquiryPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період відповідей"
      },
      "tenderPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період подання заяви на участь в аукціоні"
      },
      "auctionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Дата та час проведення аукціону"
      },
      "questionPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період запитань"
      },
      "qualificationPeriod": {
        "startDate": {
          "legalName": "Дата та час початку"
        },
        "endDate": {
          "legalName": "Дата та час завершення"
        },
        "legalName": "Період кваліфікації"
      },
      "status": {
        "legalName": "Статус процедури"
      },
      "cancellations": {
        "legalName": "Скасування Аукціону"
      },
      "registrationFee": {
        "currency": {
          "legalName": "Валюта"
        },
        "amount": {
          "legalName": "Сума"
        },
        "valueAddedTaxIncluded": {
          "legalName": "Податок"
        },
        "legalName": "Реєстраційний внесок"
      }
    }
  }
}