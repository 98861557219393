import React from 'react';
import styles from './catalog.module.css';

import Button from '@material-ui/core/Button';

import {setTogetherApi, changeLoader, setUniversalError} from '../../../redux/actions.js';
import {
    togetherApiAuctionClear,
    setGetTimberSpecies,
    setGetTimberSortment,
    setGetTimberStorage,
    setGetTimberDiameter,
    setGetTimberClass,

    togetherApiPageCurrentAuction,
    togetherApiPageNextAuction,
} from '../../../redux/togetherApi.js';

import ItemsCard from './itemsCard/itemsCard'
import CartProductOld from './productOld/productOld.js';
import TogetherAPI from './togetherAPI/togetherAPI.js';

import {connect} from 'react-redux';

import {
    auctionsSearchTogetherAPI,
    auctionsGetClassificationsByType,
    auctionsGetClassificationsByTimberSortment,
    auctionsGetClassificationsByTimberSortmentFromCashe,
    auctionsGetClassificationsByTimberSpecies,
    auctionsGetClassificationsByTimberSpeciesFromCashe,
    auctionsGetClassificationsByTimberClass,
    auctionsGetClassificationsByTimberClassFromCashe,
    auctionsGetClassificationsByTimberDiameter,
    auctionsGetClassificationsByTimberDiameterFromCashe,
    auctionsGetClassificationsByTimberLength,
    auctionsGetClassificationsByTimberLengthFromCashe,
    auctionsGetClassificationsByTimberStorage,
    auctionsGetClassificationsByTimberStorageFromCashe,
    auctionsGetClassificationsByTimberProductionYear,
    auctionsGetClassificationsByTimberProductionYearFromCashe,
    auctionsGetClassificationsByTimberProductionQuarter,
    auctionsGetClassificationsByTimberProductionQuarterFromCashe,
    auctionsSearchTestElemArr,
} from '../../../redux/actions/auctions/auctions.js';

const CURRENT_AUCTIONS_LOTS_LANG = ['АКТУАЛЬНІ АУКЦІОНИ', 'CURRENT AUCTIONS'];

const Catalog = (props) => {
    let tmp = null
    if(props.userProfiles !== null){
        if(props.userProfiles.canCreateBid === true || props.userProfiles.canCreateAuction === true){
            tmp = buttonTogetherAPI(props)
        }
    }
    return (
        <section className = { styles.wrap } >
            <header className={styles.header_title}>
                <h1 className={styles.titleText}>
                    {CURRENT_AUCTIONS_LOTS_LANG[props.langFlag]}
                </h1>
                {tmp}
            </header>
            {componentTogetherAPI(props)}
        </section>
    )
}

function componentTogetherAPI(props){
    let tmp = null;
    switch(props.search.category){
        case "timber-english":
        case "timber-english-fast":
        case "timber-english-fast-manual":
        case "timber-english-initial-auction":
        case "timber-english-initial-qualification":
        case "timber-english-initial-qualification-fast":
            if(props.togetherApi === true){
                tmp = <TogetherAPI />
            }else{
                tmp = <>
                    {logicTypeAuction(props.auctions)}
                    {createMoreProduct(props.auctions)}
                    {createMoreProductOld(props.auctionsOld)}
                </>
            }
            break;
        default:
            tmp = <>
                    {logicTypeAuction(props.auctions)}
                    {createMoreProduct(props.auctions)}
                    {createMoreProductOld(props.auctionsOld)}
                </>
            break;
    }
    return tmp
}

function buttonTogetherAPI(props){
    let tmp = null;
    switch(props.search.category){
        case "timber-english":
        case "timber-english-fast":
        case "timber-english-fast-manual":
        case "timber-english-initial-auction":
        case "timber-english-initial-qualification":
        case "timber-english-initial-qualification-fast":
            if(props.token !== null){
                tmp = <Button
                    variant="contained"
                    color="primary"
                    onClick={
                        () => {
                            combainGetAllInterface(props)
                        }
                    }
                >
                    Єдиний інтерфейс
                </Button>
            }
            break;
        default:
            break;
    }
    return tmp
}

async function combainGetAllInterface(props){
    await props.changeLoader(true)
    await props.setTogetherApi()
    /*let header5 = null;
    if(props.token !== null){
        header5 = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        let data8 = {
            domen: process.env.REACT_APP_END_POINT_BD,
            version: 'v1',
            local: props.langFlag,
            //params: '?limit=200&offset=0'
        }
        let elemArr = ''
        for(let it = 0; it < props.auctions.length; it++){
            if(it === 0){
                elemArr = `ids[]=${props.auctions[it].id}`
            }else{
                elemArr = elemArr + `&ids[]=${props.auctions[it].id}`
            }
        }
        props.auctionsSearchTestElemArr(data8, header5, elemArr)
    }else{
        header5 = {
            'Content-Type': 'application/json'
        }
    }*/
    let header5 = {
        'Authorization': props.token.access_token,
        'Content-Type': 'application/json'
    }
    let data5 = {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag,
        params: '?limit=100'
    }
    await props.auctionsGetClassificationsByType(data5, header5)

    //await props.auctionsGetClassificationsByTimberSortment(data5, header5)
    //await props.auctionsGetClassificationsByTimberSpecies(data5, header5)
    //await props.auctionsGetClassificationsByTimberClass(data5, header5)
    //await props.auctionsGetClassificationsByTimberDiameter(data5, header5)
    //await props.auctionsGetClassificationsByTimberLength(data5, header5)
    //await props.auctionsGetClassificationsByTimberStorage(data5, header5)
    //await props.auctionsGetClassificationsByTimberProductionYear(data5, header5)
    //await props.auctionsGetClassificationsByTimberProductionQuarter(data5, header5)
    // let tmpLocalTimberSortment = null
    // if (localStorage.getItem('timberSortment') !== null){
    //     tmpLocalTimberSortment = JSON.parse(localStorage.getItem('timberSortment'))
    //     if((tmpLocalTimberSortment.time - 60000) <= Date.now()){
    //         props.auctionsGetClassificationsByTimberSortment(data5, header5)
    //     }else{
    //         props.auctionsGetClassificationsByTimberSortmentFromCashe(tmpLocalTimberSortment.dictonary)
    //     }
    // }else{
    //     props.auctionsGetClassificationsByTimberSortment(data5, header5)
    // }
    // //props.auctionsGetClassificationsByTimberSortment(data5, header5)
    // let tmpLocalTimberSpecies = null
    // if (localStorage.getItem('timberSpecies') !== null){
    //     tmpLocalTimberSpecies = JSON.parse(localStorage.getItem('timberSpecies'))
    //     if((tmpLocalTimberSpecies.time - 60000) <= Date.now()){
    //         props.auctionsGetClassificationsByTimberSpecies(data5, header5)
    //     }else{
    //         props.auctionsGetClassificationsByTimberSpeciesFromCashe(tmpLocalTimberSpecies.dictonary)
    //     }
    // }else{
    //     props.auctionsGetClassificationsByTimberSpecies(data5, header5)
    // }
    // //props.auctionsGetClassificationsByTimberSpecies(data5, header5)
    // let tmpLocalTimberClass = null
    // if (localStorage.getItem('timberClass') !== null){
    //     tmpLocalTimberClass = JSON.parse(localStorage.getItem('timberClass'))
    //     if((tmpLocalTimberClass.time - 60000) <= Date.now()){
    //         props.auctionsGetClassificationsByTimberClass(data5, header5)
    //     }else{
    //         props.auctionsGetClassificationsByTimberClassFromCashe(tmpLocalTimberClass.dictonary)
    //     }
    // }else{
    //     props.auctionsGetClassificationsByTimberClass(data5, header5)
    // }
    // //props.auctionsGetClassificationsByTimberClass(data5, header5)
    // let tmpLocalTimberDiameter = null
    // if (localStorage.getItem('timberDiameter') !== null){
    //     tmpLocalTimberDiameter = JSON.parse(localStorage.getItem('timberDiameter'))
    //     if((tmpLocalTimberDiameter.time - 60000) <= Date.now()){
    //         props.auctionsGetClassificationsByTimberDiameter(data5, header5)
    //     }else{
    //         props.auctionsGetClassificationsByTimberDiameterFromCashe(tmpLocalTimberDiameter.dictonary)
    //     }
    // }else{
    //     props.auctionsGetClassificationsByTimberDiameter(data5, header5)
    // }
    // //props.auctionsGetClassificationsByTimberDiameter(data5, header5)
    // let tmpLocalTimberLength = null
    // if (localStorage.getItem('timberLength') !== null){
    //     tmpLocalTimberLength = JSON.parse(localStorage.getItem('timberLength'))
    //     if((tmpLocalTimberLength.time - 60000) <= Date.now()){
    //         props.auctionsGetClassificationsByTimberLength(data5, header5)
    //     }else{
    //         props.auctionsGetClassificationsByTimberLengthFromCashe(tmpLocalTimberLength.dictonary)
    //     }
    // }else{
    //     props.auctionsGetClassificationsByTimberLength(data5, header5)
    // }
    // //props.auctionsGetClassificationsByTimberLength(data5, header5)
    // let tmpLocalTimberStorage = null
    // if (localStorage.getItem('timberStorage') !== null){
    //     tmpLocalTimberStorage = JSON.parse(localStorage.getItem('timberStorage'))
    //     if((tmpLocalTimberStorage.time - 60000) <= Date.now()){
    //         props.auctionsGetClassificationsByTimberStorage(data5, header5)
    //     }else{
    //         props.auctionsGetClassificationsByTimberStorageFromCashe(tmpLocalTimberStorage.dictonary)
    //     }
    // }else{
    //     props.auctionsGetClassificationsByTimberStorage(data5, header5)    
    // }
    // //props.auctionsGetClassificationsByTimberStorage(data5, header5)
    // let tmpLocalTimberYear = null
    // if (localStorage.getItem('timberYear') !== null){
    //     tmpLocalTimberYear = JSON.parse(localStorage.getItem('timberYear'))
    //     if((tmpLocalTimberYear.time - 60000) <= Date.now()){
    //         props.auctionsGetClassificationsByTimberProductionYear(data5, header5)
    //     }else{
    //         props.auctionsGetClassificationsByTimberProductionYearFromCashe(tmpLocalTimberYear.dictonary)
    //     }
    // }else{
    //     props.auctionsGetClassificationsByTimberProductionYear(data5, header5)
    // }
    // //props.auctionsGetClassificationsByTimberProductionYear(data5, header5)
    // let tmpLocalTimberQuarter = null
    // if (localStorage.getItem('timberQuarter') !== null){
    //     tmpLocalTimberQuarter = JSON.parse(localStorage.getItem('timberQuarter'))
    //     if((tmpLocalTimberQuarter.time - 60000) <= Date.now()){
    //         props.auctionsGetClassificationsByTimberProductionQuarter(data5, header5)
    //     }else{
    //         props.auctionsGetClassificationsByTimberProductionQuarterFromCashe(tmpLocalTimberQuarter.dictonary)
    //     }
    // }else{
    //     props.auctionsGetClassificationsByTimberProductionQuarter(data5, header5)
    // }
    //props.auctionsGetClassificationsByTimberProductionQuarter(data5, header5)


    await proxyGetTimberSpecies(props)
    await proxyGetTimberSortment(props)
    await proxyGetTimberStorage(props)
    await proxyGetTimberDiameter(props)
    await proxyGetTimberClass(props)

    let data6 = {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag,
        params: '?offset=0'
    }
    let headerTogetherApi = {
        'Authorization': props.token.access_token,
        'Content-Type': 'application/json'
    }
    let dataSearch = {
        category: props.search.category,
    }
    await props.auctionsSearchTogetherAPI(data6, headerTogetherApi, dataSearch)


    await props.togetherApiPageCurrentAuction(0)
    if(props.auctions.length === 10) {
        await props.togetherApiPageNextAuction(1)
    }else{
        await props.togetherApiPageNextAuction(0)
    }
    await props.changeLoader(false)
}

async function proxyGetTimberSpecies(props){
    let url = ``;
    //if(Number(process.env.REACT_APP_BUILD_STATUS) === 0){
        //url = `https://procedure.prozorro.sale/api/classifiers/timberSpecies`;
    //}else{
        url = `https://procedure-sandbox.prozorro.sale/api/dictionaries/timberSpecies`;
        //url = `https://procedure-staging.prozorro.sale/api/classifiers/timberSpecies`;
    //}
    const response = await fetch(url, {
        method: "GET",
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    props.setGetTimberSpecies(response)
}

async function proxyGetTimberSortment(props){
    let url = `https://procedure-sandbox.prozorro.sale/api/dictionaries/timberSortment`;
    const response = await fetch(url, {
        method: "GET",
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    props.setGetTimberSortment(response)
}

async function proxyGetTimberStorage(props){
    let url = `https://procedure-sandbox.prozorro.sale/api/dictionaries/timberStorage`;
    const response = await fetch(url, {
        method: "GET",
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    props.setGetTimberStorage(response)
}

async function proxyGetTimberDiameter(props){
    let url = `https://procedure-sandbox.prozorro.sale/api/dictionaries/timberDiameter`;
    const response = await fetch(url, {
        method: "GET",
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    props.setGetTimberDiameter(response)
}

async function proxyGetTimberClass(props){
    let url = `https://procedure-sandbox.prozorro.sale/api/dictionaries/timberClass`;
    const response = await fetch(url, {
        method: "GET",
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    props.setGetTimberClass(response)
}

function logicTypeAuction(auctions){
    let tmp = null;
    if(window.location.pathname.length <= 1){
        if(auctions !== null){
            tmp = auctions
            .filter(
                (e) => {return e.items.length > 0}
            )
            .map(
                (item) => {
                    return (
                        <>
                            <ItemsCard 
                                key={item.id}
                                item={item}
                            />
                        </>
                    )
                }
            )
        }
    }
    return tmp
}

function createMoreProduct(auctions){
    let tmp = null;
    if(window.location.pathname.length >= 1){
        if(auctions !== null){
            tmp = auctions
            .map(
                (item) => {
                    return (
                        <>
                            {/*<CartProduct
                                key={item.id}
                                item={item}
                            />*/}
                            <ItemsCard 
                                key={item.id}
                                item={item}
                            />
                        </>
                    )
                }
            )
        }
    }
    return tmp
}

function createMoreProductOld(auctions){
    let tmp = null;
    if(window.location.pathname.length > 1){
        if(auctions !== null){
            tmp = auctions
            .map(
                (item) => {
                    return (
                        <CartProductOld
                            key={item.id}
                            item={item}
                        />
                    )
                }
            )
        }
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        auctions: state.start.auctionsGet,
        auctionsOld: state.start.auctionsGetOld,
        search: state.start.search,
        togetherApi: state.start.togetherApi,
        token: state.start.token,
        togetherAPIelem: state.togetherAPIelem,
        userProfiles: state.start.userProfiles,
    }
}

const mapDispatchToProps = {
    setTogetherApi,
    auctionsGetClassificationsByType,
    auctionsGetClassificationsByTimberSortment,
    auctionsGetClassificationsByTimberSortmentFromCashe,
    auctionsGetClassificationsByTimberSpecies,
    auctionsGetClassificationsByTimberSpeciesFromCashe,
    auctionsGetClassificationsByTimberClass,
    auctionsGetClassificationsByTimberClassFromCashe,
    auctionsGetClassificationsByTimberDiameter,
    auctionsGetClassificationsByTimberDiameterFromCashe,
    auctionsGetClassificationsByTimberLength,
    auctionsGetClassificationsByTimberLengthFromCashe,
    auctionsGetClassificationsByTimberStorage,
    auctionsGetClassificationsByTimberStorageFromCashe,
    auctionsGetClassificationsByTimberProductionYear,
    auctionsGetClassificationsByTimberProductionYearFromCashe,
    auctionsGetClassificationsByTimberProductionQuarter,
    auctionsGetClassificationsByTimberProductionQuarterFromCashe,
    auctionsSearchTestElemArr,

    togetherApiAuctionClear,

    setGetTimberSpecies,
    setGetTimberSortment,
    setGetTimberStorage,
    setGetTimberDiameter,
    setGetTimberClass,
    auctionsSearchTogetherAPI,
    changeLoader,
    setUniversalError,

    togetherApiPageCurrentAuction,
    togetherApiPageNextAuction,
}

export default connect(mapStateToProps, mapDispatchToProps)(Catalog)
