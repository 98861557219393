import React from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import {
    changeCreateAuctionMinNumberOfQualifiedBids,
    changeCreateAuctionDgfVirtualDataRoom,
    changeCreateAuctionDgfEligibilityCriteria,
    changeCreateAuctionDgfX_dgfPlatformLegalDetails,
    changeCreateAuctionDgfDecisionId,
    changeCreateAuctionDgfDecisionDate,
} from '../../../redux/actions.js';

import TextField from '@material-ui/core/TextField';

const WindowAuctionDgf = ( props ) => {
    let tmp = null;
    if(props.typeElem === 1){
        tmp = <>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabled(props)}
                        required
                        id="filled-required"
                        className={styles.inputText}
                        label="Мінімальна кількість заяв"
                        defaultValue={props.auctionsMy.minNumberOfQualifiedBids}
                        variant="outlined"
                        onChange={
                            (e) => {
                                props.changeCreateAuctionMinNumberOfQualifiedBids(e.target.value)
                            }
                        }
                        helperText=""
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabled(props)}
                        required
                        id="filled-required"
                        className={styles.inputText}
                        label="Посилання на VDR (Virtual Data Room)"
                        defaultValue={props.auctionsMy.virtualDataRoom}
                        variant="outlined"
                        onChange={
                            (e) => {
                                props.changeCreateAuctionDgfVirtualDataRoom(e.target.value)
                            }
                        }
                        helperText=""
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabled(props)}
                        required
                        id="filled-required"
                        className={styles.inputText}
                        label="Юридична інформація майданчиків"
                        defaultValue={props.auctionsMy.x_dgfPlatformLegalDetails}
                        variant="outlined"
                        onChange={
                            (e) => {
                                props.changeCreateAuctionDgfX_dgfPlatformLegalDetails(e.target.value)
                            }
                        }
                        helperText=""
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabled(props)}
                        required
                        id="filled-required"
                        className={styles.inputText}
                        label="Номер рішення"
                        defaultValue={props.auctionsMy.decision.decisionId}
                        variant="outlined"
                        onChange={
                            (e) => {
                                props.changeCreateAuctionDgfDecisionId(e.target.value)
                            }
                        }
                        helperText=""
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabled(props)}
                        required
                        id="filled-required"
                        className={styles.inputText}
                        label="Дата рішення"
                        defaultValue={props.auctionsMy.decision.decisionDate}
                        variant="outlined"
                        onChange={
                            (e) => {
                                props.changeCreateAuctionDgfDecisionDate(e.target.value)
                            }
                        }
                        helperText=""
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabled(props)}
                        id="outlined-select-currency-native"
                        select
                        label="Умови участі фінансових установ"
                        value={props.auctionsMy.eligibilityCriteria}
                        onChange={(event)=>{
                            props.changeCreateAuctionDgfEligibilityCriteria(event.target.value)
                            //ownershipType	string
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        {dictionariesEligibilityCriteria(props)}
                    </TextField>
                </div>
            </div>
        </>
    }else{
        tmp = <>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        required
                        id="filled-required"
                        className={styles.inputText}
                        label="Мінімальна кількість заяв"
                        defaultValue={props.auctionsMy.minNumberOfQualifiedBids}
                        variant="outlined"
                        onChange={
                            (e) => {
                                props.changeCreateAuctionMminNumberOfQualifiedBids(e.target.value)
                            }
                        }
                        helperText=""
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        required
                        id="filled-required"
                        className={styles.inputText}
                        label="Посилання на VDR (Virtual Data Room)"
                        defaultValue={props.auctionsMy.virtualDataRoom}
                        variant="outlined"
                        onChange={
                            (e) => {
                                props.changeCreateAuctionDgfVirtualDataRoom(e.target.value)
                            }
                        }
                        helperText=""
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        required
                        id="filled-required"
                        className={styles.inputText}
                        label="Юридична інформація майданчиків"
                        defaultValue={props.auctionsMy.x_dgfPlatformLegalDetails}
                        variant="outlined"
                        onChange={
                            (e) => {
                                props.changeCreateAuctionDgfX_dgfPlatformLegalDetails(e.target.value)
                            }
                        }
                        helperText=""
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        required
                        id="filled-required"
                        className={styles.inputText}
                        label="Номер рішення"
                        defaultValue={props.auctionsMy.decision.decisionId}
                        variant="outlined"
                        onChange={
                            (e) => {
                                props.changeCreateAuctionDgfDecisionId(e.target.value)
                            }
                        }
                        helperText=""
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        required
                        id="filled-required"
                        className={styles.inputText}
                        label="Дата рішення"
                        defaultValue={props.auctionsMy.decision.decisionDate}
                        variant="outlined"
                        onChange={
                            (e) => {
                                props.changeCreateAuctionDgfDecisionDate(e.target.value)
                            }
                        }
                        helperText=""
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        label="Умови участі фінансових установ"
                        value={props.auctionsMy.eligibilityCriteria}
                        onChange={(event)=>{
                            props.changeCreateAuctionDgfEligibilityCriteria(event.target.value)
                            //ownershipType	string
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        {dictionariesEligibilityCriteria(props)}
                    </TextField>
                </div>
            </div>
        </>
    }
    return tmp
}

function dictionariesEligibilityCriteria(props){
    if(props.dictionaries.eligibilityCriteria !== null){
        return props.dictionaries.eligibilityCriteria.map(
            (i, count) => {
                return <option key={count} value={i.name}>{i.description}</option>
            }
        )
    }
}

function elementDisabled(props){
    let tmpStatus = null;
    if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            tmpStatus = 'disabled'
        }
    }
    return tmpStatus
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
        dictionaries: state.start.dictionaries,
    }
}

const mapDispatchToProps = {
    changeCreateAuctionMinNumberOfQualifiedBids,
    changeCreateAuctionDgfVirtualDataRoom,
    changeCreateAuctionDgfEligibilityCriteria,
    changeCreateAuctionDgfX_dgfPlatformLegalDetails,
    changeCreateAuctionDgfDecisionId,
    changeCreateAuctionDgfDecisionDate,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuctionDgf)
