//import {сreateBodyTIE} from './createBodyTIE'
//import {сreateBodySUE} from './createBodySUE'
//import {сreateBodySUD} from './createBodySUD'
//import {сreateBodyRCE} from './createBodyRCE'
//import {сreateBodyRCD} from './createBodyRCD'
//import {сreateBodyLRE} from './createBodyLRE'
//import {сreateBodyLSE} from './createBodyLSE'
//import {сreateBodyLSP} from './createBodyLSP'
import {сreateBodyLRE} from './createBodyLRE'
import {сreateBodyLSE} from './createBodyLSE'
import {сreateBodyLSP} from './createBodyLSP'

import {сreateBodyLLE} from './createBodyLLE'
import {сreateBodyLLD} from './createBodyLLD'
import {сreateBodyLLP} from './createBodyLLP'

import {сreateBodyBSE} from './createBodyBSE'
import {сreateBodyBSD} from './createBodyBSD'
import {сreateBodyALE} from './createBodyALE'
import {сreateBodyCSE} from './createBodyCSE'
import {сreateBodyCSD} from './createBodyCSD'
import {сreateBodyBRE} from './createBodyBRE'
import {сreateBodyBRD} from './createBodyBRD'
import {сreateBodyBRW} from './createBodyBRW'

import {сreateBodyRLE} from './createBodyRLE'
import {сreateBodyRLD} from './createBodyRLD'

import {сreateBodySPE} from './createBodySPE'
import {сreateBodySPD} from './createBodySPD'

import {сreateBodyNLE} from './createBodyNLE'
import {сreateBodyNLD} from './createBodyNLD'

import {сreateBodyLAE} from './createBodyLAE'
import {сreateBodyLAW} from './createBodyLAW'
import {сreateBodyLAP, сreateBodyLAPCT} from './createBodyLAP'

import {сreateBodyLPE} from './createBodyLPE'
import {сreateBodyAPE} from './createBodyAPE'
import {сreateBodyAPD} from './createBodyAPD'


import { isBSE, isBSD, isALE,
    isBRD, isBRE, isBRW,
    isCSD, isCSE, isRLE, isRLD, isCLE, isCLD, isSPE, isSPD,
    isNLE, isNLD,
    isLAE, isLAW, isLAP,
    isLPE,
    isAPE, isAPD,
    isLLE, isLLD, isLLP,
    isLRE, isLSE, isLSP
} from '../../../../../../redux/aucTypes'

export function сreateBody(data, oldData = null, auction){
    switch(true){
        case isLRE(data.base.sellingMethod):
            return сreateBodyLRE(data)
        case isLSE(data.base.sellingMethod):
            return сreateBodyLSE(data)
        case isLSP(data.base.sellingMethod):
            return сreateBodyLSP(data)
        case isLLE(data.base.sellingMethod):
            return сreateBodyLLE(data)
        case isLLD(data.base.sellingMethod):
            return сreateBodyLLD(data)
        case isLLP(data.base.sellingMethod):
            return сreateBodyLLP(data)
        case isBSE(data.base.sellingMethod):
            return сreateBodyBSE(data)
        case isBSD(data.base.sellingMethod):
            return сreateBodyBSD(data)
        case isALE(data.base.sellingMethod):
            return сreateBodyALE(data)
        case isCSE(data.base.sellingMethod):
            return сreateBodyCSE(data)
        case isCSD(data.base.sellingMethod):
            return сreateBodyCSD(data)
        case isBRE(data.base.sellingMethod):
            return сreateBodyBRE(data, oldData)
        case isBRW(data.base.sellingMethod):
            return сreateBodyBRW(data, oldData)
        case isBRD(data.base.sellingMethod):
            return сreateBodyBRD(data, oldData)
        case isRLE(data.base.sellingMethod):
        case isCLE(data.base.sellingMethod):
            return сreateBodyRLE(data, oldData)
        case isRLD(data.base.sellingMethod):
        case isCLD(data.base.sellingMethod):
            return сreateBodyRLD(data, oldData)
        case isSPE(data.base.sellingMethod):
            return сreateBodySPE(data, oldData)
        case isSPD(data.base.sellingMethod):
            return сreateBodySPD(data, oldData)
        case isNLE(data.base.sellingMethod):
            return сreateBodyNLE(data)
        case isNLD(data.base.sellingMethod):
            return сreateBodyNLD(data)
        case isLAE(data.base.sellingMethod):
            return сreateBodyLAE(data)
        case isLAW(data.base.sellingMethod):
            return сreateBodyLAW(data)
        case isLAP(data.base.sellingMethod):
            return сreateBodyLAP(data)
        case isLPE(data.base.sellingMethod):
            return сreateBodyLPE(data, oldData)
        case isAPE(data.base.sellingMethod):
            return сreateBodyAPE(data, auction)
        case isAPD(data.base.sellingMethod):
            return сreateBodyAPD(data, auction)
        default:
            return data.body
    }
}
export function сreateBodyCT(data, oldData = null){
    switch(true){
        case isLRE(data.base.sellingMethod):
            return сreateBodyLRE(data)
        case isLLE(data.base.sellingMethod):
            return сreateBodyLLE(data)
        case isLLD(data.base.sellingMethod):
            return сreateBodyLLD(data)
        case isLLP(data.base.sellingMethod):
            return сreateBodyLLP(data)
        case isBSE(data.base.sellingMethod):
            return сreateBodyBSE(data)
        case isBSD(data.base.sellingMethod):
            return сreateBodyBSD(data)
        case isALE(data.base.sellingMethod):
            return сreateBodyALE(data)
        case isCSE(data.base.sellingMethod):
            return сreateBodyCSE(data)
        case isCSD(data.base.sellingMethod):
            return сreateBodyCSD(data)
        case isBRE(data.base.sellingMethod):
            return сreateBodyBRE(data, oldData)
        case isBRW(data.base.sellingMethod):
            return сreateBodyBRW(data, oldData)
        case isBRD(data.base.sellingMethod):
            return сreateBodyBRD(data, oldData)
        case isRLE(data.base.sellingMethod):
        case isCLE(data.base.sellingMethod):
            return сreateBodyRLE(data, oldData)
        case isRLD(data.base.sellingMethod):
        case isCLD(data.base.sellingMethod):
            return сreateBodyRLD(data, oldData)
        case isSPE(data.base.sellingMethod):
            return сreateBodySPE(data, oldData)
        case isSPD(data.base.sellingMethod):
            return сreateBodySPD(data, oldData)
        case isNLE(data.base.sellingMethod):
            return сreateBodyNLE(data)
        case isNLD(data.base.sellingMethod):
            return сreateBodyNLD(data)
        case isLAE(data.base.sellingMethod):
            return сreateBodyLAE(data)
        case isLAW(data.base.sellingMethod):
            return сreateBodyLAW(data)
        case isLAP(data.base.sellingMethod):
            return сreateBodyLAPCT(data)
        case isLPE(data.base.sellingMethod):
            return сreateBodyLPE(data, oldData)
        case isAPE(data.base.sellingMethod):
            return сreateBodyAPE(data)
        case isAPD(data.base.sellingMethod):
            return сreateBodyAPD(data)
        default:
            return data.body
    }
}