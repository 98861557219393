import React from 'react';
import styles from './createAuctionZU.module.css';

import {connect} from 'react-redux';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import {setDataAddress} from '../../../redux/createAddress';

import {auctionsGetMy} from '../../../redux/actions/auctions/auctions.js';

import {changeLoader, setUniversalError} from '../../../redux/actions.js';

const ReturnButton = ( props ) => {
    return (
        <div className={styles.popup_wrap}>
            <div className={styles.popup_message}>
                <div className={styles.popup_message}>
                    <div className={styles.title}>Попередження</div>
                    <div className={styles.title}>Увага!</div>
                    <div>Основна інформація в аукціон переноситься з об'єкту переліку.</div>
                    <div className={styles.baseText}>За потреби спочатку внесіть необхідні зміни до об'єкту у переліку і вже після цього переходити до етапу публікації аукціону</div>
                </div>
                <div className={styles.title}>Введіть Id об'єкта та натиснить створити чернетку</div>
                <div className={styles.inputMessage}>
                    <TextField
                        id="filled-required"
                        label="Ідентифікатор об'єкта"
                        defaultValue={props.createAuctionZU.objectId}
                        variant="outlined"
                        onChange={
                            (e) => {
                                props.setDataAddress(e.target.value, 'SET_CREATE_AUCTION_ZU_OBJECT_ID')
                                if(e.target.value !== '') {
                                    //getObjectId(props, e.target.value)
                                    //getCPUObj(props, e.target.value)
                                }
                            }
                        }
                        helperText=""
                    />
                </div>
                <div className={styles.button_wrap}>
                    <Button
                        className={styles.createAuctionZU}
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                if(props.createAuctionZU.objectId.indexOf('-') === -1){
                                    getObjectId(props, props.createAuctionZU.objectId)
                                }else{
                                    getCPUObj(props, props.createAuctionZU.objectId)
                                    //createObject(props, props.createAuctionZU.data.registryId)
                                }
                            }
                        }
                    >
                        Створити чернетку
                    </Button>
                    <Button
                        className={styles.createAuctionZU}
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                props.setDataAddress(false, 'SET_CREATE_AUCTION_ZU_FLAG')
                                props.setDataAddress('', 'SET_CREATE_AUCTION_ZU_OBJECT_ID')
                                props.setDataAddress(null, 'SET_CREATE_AUCTION_ZU_DATA')
                            }
                        }
                    >
                        Закрити
                    </Button>
                </div>
            </div>
        </div>
    )
}


async function getCPUObj(props, objId){
    let endPoint = ``
    if(Number(process.env.REACT_APP_BUILD_STATUS) === 0){
        //endPoint = `https://registry.dto.com.ua/api/v1/uk/public/registry_objects/by_public_id/${objId}`
        endPoint = `https://registry.dto.com.ua/api/v2/uk/public/registry_objects/by_public_id/${objId}`
        //endPoint = `https://registry.dto.com.ua/api/1/uk/public/registry_objects/byGlobalId/${objId}`
    }else{
        //endPoint = `https://staging-registry.dto.com.ua/api/1/uk/public/registry_objects/byGlobalId/${objId}`
        //endPoint = `https://sandbox-registry.dto.com.ua/api/1/uk/public/registry_objects/byGlobalId/${objId}`
        //endPoint = `https://sandbox-registry.dto.com.ua/api/v1/uk/public/registry_objects/by_public_id/${objId}`
        endPoint = `https://sandbox-registry.dto.com.ua/api/v2/uk/public/registry_objects/by_public_id/${objId}`        
        //endPoint = `https://dev-registry.dto.com.ua/api/1/uk/public/registry_objects/byGlobalId/${objId}`
    }
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            /*'Authorization': props.token.access_token,*/
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            //let tmp = JSON.stringify({title: "Увага", message: 'Щоб створити аукціон ви маєте бути орендодавцем для поточного об\'єкта'})
            //props.setUniversalError(tmp)
        }else{
            //props.setDataAddress(json, 'SET_CREATE_AUCTION_ZU_DATA')
            await createObject(props, json.id)
        }
    }
}

async function getObjectId(props, objId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/bridge/${objId}/raw`
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            //let tmp = JSON.stringify({title: "Помилка при створенні Тип переліку, до якого віднесено об'єкт", message: JSON.stringify(json)})
            //props.setUniversalError(tmp)
        }else{
            //props.setDataAddress(json, 'SET_CREATE_AUCTION_ZU_DATA')
            await createObject(props, json.registryId)
        }
    }
}

async function createObject(props, objId){
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/auction/bridge/${objId}`
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-draft/bridge/${objId}`
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: "Помилка при створенні Тип переліку, до якого віднесено об'єкт", message: JSON.stringify(json.message[0])})
            props.setUniversalError(tmp)

            props.setDataAddress(false, 'SET_CREATE_AUCTION_ZU_FLAG')
            let data = {}
            data.domen = process.env.REACT_APP_END_POINT_BD
            data.version = 'v1'
            data.local = props.langFlag
            if(props.myCabinetPage === 0){
                data.params = `?offset=${props.myCabinetPage}`
            }else{
                data.params = `?offset=${props.myCabinetPage}0` 
            }
            let header = {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
            //props.auctionsGetMy(data, header)
            getAucionsList(props)
        }else{
            props.setDataAddress(false, 'SET_CREATE_AUCTION_ZU_FLAG')
            props.setDataAddress('', 'SET_CREATE_AUCTION_ZU_OBJECT_ID')
            props.setDataAddress(null, 'SET_CREATE_AUCTION_ZU_DATA')
            props.setDataAddress(null, 'AUCTIONS_GET_MY')
            let data = {}
            data.domen = process.env.REACT_APP_END_POINT_BD
            data.version = 'v1'
            data.local = props.langFlag
            if(props.myCabinetPage === 0){
                data.params = `?offset=${props.myCabinetPage}`
            }else{
                data.params = `?offset=${props.myCabinetPage}0` 
            }
            let header = {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
            //props.auctionsGetMy(data, header)
            getAucionsList(props)
        }
    }
}


/////////////////////////////////////
async function getAucionsList(props){
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-drafts/my`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціонів', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            const tmp = await createList(json)
            if(tmp.length > 0){
                props.setDataAddress(tmp, 'AUCTIONS_GET_MY')
            }else{
                props.setDataAddress(null, 'AUCTIONS_GET_MY')
            }
        }
    }
    props.changeLoader(false)
}

function createList(json){
    let tmp = []
    if(json.length > 0){
        for(let i = 0; i < json.length; i++){
            if(json[i].publicAuction !== null){
                tmp[i] = json[i].publicAuction
                tmp[i].dataAuction = json[i]
            }else if(json[i].oldAuctionDraft !== null){
                tmp[i] = json[i].oldAuctionDraft
                tmp[i].dataAuction = json[i]
            }else if(json[i].auctionDraft !== null){
                tmp[i] = json[i].auctionDraft
                tmp[i].dataAuction = json[i]
            }
        }
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        langFlag: state.start.langFlag,
        createAuctionZU: state.start.createAuctionZU,
        myCabinetPage: state.start.myCabinetPage,
    }
}

const mapDispatchToProps = {
    auctionsGetMy,
    setDataAddress,
    setUniversalError,
    changeLoader,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReturnButton)