import React from 'react';
import styles from './login.module.css';

import {
    LOGIN_TITLE_TOP,
    LOGIN_TITLE_BOTTOM_FIRST,
    LOGIN_TITLE_BOTTOM_SECOND,
    //LOGIN_TITLE_TOP_EXIT
} from '../../../model/lang/topMenu.js';

import {connect} from 'react-redux';

import {postGetToken, localClearToken, setProfileNull, setIntervalRef, setSinglPasword} from '../../../redux/actions/default/default';
import {setPopUpLogin, setTogetherApi} from '../../../redux/actions.js';

import svgSetting from './user_menu_settings.svg';
import svgNotif from './user_menu_notifications.svg';
import svgExit from './user_menu_exit.svg';

import PopUpLogin from '../../popUp/login/login';

const Login = (props) => {
    return (
        <div className={styles.wrapLogin} >
            {popUpLoginForm(props)}
            {viewButtonLogin(props)}
        </div>
    )
}

function popUpLoginForm(props){
    if(props.popUpStatus.login === true) return <PopUpLogin />
}

function viewButtonLogin(props){
    let tmp = null;
    if(props.token === null){
        tmp = (
            <div className={styles.afterLogin}>
                <div
                    className={styles.login}
                    onClick={
                        () => {
                            props.setPopUpLogin(true)
                        }
                    }
                >
                    {LOGIN_TITLE_TOP[props.langFlag]}
                </div>
                <br />
                <div className={styles.register}>
                    {/**LOGIN_TITLE_BOTTOM_SECOND[props.langFlag]} <a href="https://beta.dto.com.ua/registration" className={styles.signin}>{LOGIN_TITLE_BOTTOM_FIRST[props.langFlag]}</a>*/}
                    {LOGIN_TITLE_BOTTOM_SECOND[props.langFlag]} <a href="https://www.dto.com.ua/registration/sellers" className={styles.signin}>{LOGIN_TITLE_BOTTOM_FIRST[props.langFlag]}</a>
                </div>
            </div>
        )
    }else{
        tmp = (
            <>
                <div>
                    <div className={styles.exit}></div>
                    <img className={styles.svgLink} src={svgSetting} alt=''/>
                    <img className={styles.svgLink} src={svgNotif} alt=''/>
                    <img 
                        className={styles.svgLink}
                        src={svgExit}
                        onClick={
                            () => {
                                props.setProfileNull(null)
                                props.localClearToken()
                                if(props.keySetIntervalRefresh !== null){
                                    clearInterval(props.keySetIntervalRefresh)
                                    props.setIntervalRef(null)
                                }
                                if(props.togetherApi === true) props.setTogetherApi()
                                window.location.reload()
                            }
                        }
                        alt=''
                    />
                </div>
                <div>
                    <div
                        className={styles.singlUsePassword}
                        onClick={
                            ()=>{
                                getOneUsePassword(props)
                            }
                        }
                    >
                        Створити пароль разового доступу
                    </div>
                </div>
                {popUpSinglePass(props)}
            </>
        )
    }
    return tmp
}

function popUpSinglePass(props){
    if(props.singlPassword !== null){
        return (
            <div className={styles.wrapBidPopUPEditDoc}>
                <div className={styles.wrapBidPopUPEditDoc_blank}>
                    <div className={styles.wrapBidPopUPEditDoc_text}>
                        Пароль відправлено на вашу пошту
                    </div>
                    <div
                        className={styles.login + ' ' + styles.closeSingolPass}
                        onClick={() => {
                            props.setSinglPasword(null)
                        }}
                    >
                        Закрити
                    </div>
                </div>
            </div>
        )
    }
}

async function getOneUsePassword(props){
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/user/temporary_pass`;
    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            //let tmp = JSON.stringify({title: 'Помилка при відхиленні аварду', message: JSON.stringify(json2)})
            //props.setUniversalError(tmp)
        }else{
            if(json.hasOwnProperty('success') === true) props.setSinglPasword('Пароль вислан на вашу пошту')
        }
    }
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        token:  state.start.token,
        popUpStatus: state.start.popUpStatus,
        keySetIntervalRefresh: state.start.keySetIntervalRefresh,
        togetherApi: state.start.togetherApi,
        singlPassword: state.start.singlPassword,
    }
}

const mapDispatchToProps = {
    postGetToken,
    localClearToken,
    setPopUpLogin,
    setProfileNull,
    setIntervalRef,
    setTogetherApi,
    setSinglPasword,
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)