import React from 'react';
import styles from './lang.module.css';

import {LANG_TITLE_TOP, LANG_TITLE_BOTTOM} from '../../../model/lang/topMenu.js';
import {connect} from 'react-redux';
import {changeLang} from '../../../redux/actions.js';

const Lang = (props) => {
    return viewlang(props.langFlag, props.changeLang)
}

function viewlang(langFlag, changeLang){
    let tmp = null;
    let lang = langFlag;
    if(lang === 0){
        tmp = <div className={styles.wrapLang} >
            <div
                className={styles.langUA}
                onClick={
                    () => { changeLang(0); }
                }
                title={LANG_TITLE_TOP[langFlag]}
            ></div>
            <div
                className={styles.langEN}
                onClick={
                    () => { changeLang(1); }
                }
                title={LANG_TITLE_BOTTOM[langFlag]}
            ></div>
        </div>
    }else{
        tmp = <div className={styles.wrapLang} >
            <div
                className={styles.langEN}
                onClick={
                    () => { changeLang(1); }
                }
                title={LANG_TITLE_BOTTOM[langFlag]}
            ></div>
            <div
                className={styles.langUA}
                onClick={
                    () => { changeLang(0); }
                }
                title={LANG_TITLE_TOP[langFlag]}
            ></div>
        </div>
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag
    }
}

const mapDispatchToProps = {
    changeLang
}

export default connect(mapStateToProps, mapDispatchToProps)(Lang)