import {
    isTIE, isSUE, isSUD, isRCE, isRCD, isGFE, isGFD, isLLE, isLLD, isLLP, isLRE,
    isLSE, isLSP, isRMA, isBSE, isBSD, isALE, isCSE, isCSD, isAAE, isBRE, isBRD, isBRW,
    isRLE, isRLD, isCLE, isCLD, isSPE, isSPD, isNLE, isNLD, isLAE, isLAW, isLAP, isLPE,
    isAPE, isAPD
} from '../../redux/aucTypes';

export function getAuctionsStatus(type, status){
    if (isTIE(type)) {
        switch(status){
            case "active_rectification": return "Редагування доступне"
            case "active_tendering": return "Прийняття заяв на участь"
            case "active_auction": return "Аукціон"
            case "active_qualification": return "Очікується опублікування протоколу"
            case "active_awarded": return "Очікується підписання договору"
            case "cancelled": return "Аукціон відмінено"
            case "unsuccessful": return "Аукціон не відбувся"
            case "complete": return "Аукціон завершено"
            default: return "Чернетка"
        }
    }
    if (isSUE(type) || isSUD(type)) {
        switch(status){
            case "active_rectification": return "Редагування доступне"
            case "active_tendering": return "Прийняття заяв на участь"
            case "active_auction": return "Аукціон"
            case "active_qualification": return "Очікується опублікування протоколу"
            case "active_awarded": return "Очікується підписання договору"
            case "pending_payment": return "Очікується сплата до бюджету"
            case "pending_admission": return "Аукціон завершено. Очікується виконання умов договору"
            case "cancelled": return "Аукціон відминено"
            case "unsuccessful": return "Аукціон не відбувся"
            case "complete": return "Аукціон завершено"
            default: return "Чернетка"
        }
    }
    if (isRCE(type) || isRCD(type)) {
        switch(status){
            case "active_tendering": return "Прийняття заяв на участь"
            case "active_auction": return "Аукціон"
            case "active_qualification": return "Очікується опублікування протоколу та завершення аукціону"
            case "cancelled": return "Аукціон відмінено"
            case "unsuccessful": return "Аукціон не відбувся"
            case "complete": return "Аукціон завершено"
            default: return "Чернетка"
        }
    }
    if (isGFE(type) || isGFD(type)) {
        switch(status){
            case "active_tendering": return "Прийняття заяв на участь"
            case "active_auction": return "Аукціон"
            case "active_awarded": return "Очікується підписання договору"
            case "cancelled": return "Аукціон відмінено"
            case "unsuccessful": return "Аукціон не відбувся"
            case "complete": return "Аукціон завершено"
            default: return "Чернетка"
        }
    }
    if (isLLE(type) || isLLD(type) || isLLP(type)) {
        switch(status){
            case "active_rectification": return "Редагування доступне"
            case "active_tendering": return "Прийняття заяв на участь"
            case "active_auction": return "Аукціон"
            case "active_qualification": return "Очікується опублікування протоколу"
            case "active_awarded": return "Очікується підписання договору"
            case "cancelled": return "Аукціон відмінено"
            case "unsuccessful": return "Аукціон не відбувся"
            case "complete": return "Аукціон завершено. Договір підписано"
            default: return "Чернетка"
        }
    }
    if (isLRE(type) || isLSE(type) || isLSP(type)) {
        switch(status){
            case "active_rectification": return "Редагування доступне"
            case "active_tendering": return "Прийняття заяв на участь"
            case "active_auction": return "Земельні торги"
            case "active_qualification": return "Очікується оприлюднення протоколу"
            case "active_awarded": return "Очікується підписання договору/акту"
            case "pending_payment": return "Очікується оплата"
            case "cancelled": return "Торги скасовано"
            case "unsuccessful": return "Торги не відбулись"
            case "complete": return "Торги завершено. Договір/акт підписано"
            default: return "Чернетка"
        }
    }
    if (isBSE(type) || isBSD(type)) {
        switch(status){
            case "active_tendering": return "Прийняття заяв на участь"
            case "active_auction": return "Аукціон"
            case "active_qualification": return "Очікується опублікування протоколу"
            case "active_awarded": return "Очікується підписання договору"
            case "cancelled": return "Аукціон відмінено"
            case "unsuccessful": return "Аукціон не відбувся"
            case "complete": return "Аукціон завершено"
            default: return "Чернетка"
        }
    }
    if (isALE(type)) {
        switch(status){
            case "active_tendering": return "Прийняття заяв на участь"
            case "active_auction": return "Аукціон"
            case "active_qualification": return "Очікується опублікування протоколу"
            case "active_awarded": return "Очікується підписання договору"
            case "cancelled": return "Аукціон відмінено"
            case "unsuccessful": return "Аукціон не відбувся"
            case "complete": return "Аукціон завершено"
            default: return "Чернетка"
        }
    }
    if (isCSE(type) || isCSD(type)) {
        switch(status){
            case "active_tendering": return "Прийняття заяв на участь"
            case "active_auction": return "Аукціон"
            case "active_qualification": return "Очікується опублікування протоколу"
            case "active_awarded": return "Очікується підписання договору"
            case "cancelled": return "Аукціон відмінено"
            case "unsuccessful": return "Аукціон не відбувся"
            case "complete": return "Аукціон завершено"
            default: return "Чернетка"
        }
    }
    if (isBRE(type) || isBRD(type) || isBRW(type)) {
        switch(status){
            case "active_tendering": return "Прийняття заяв на участь"
            case "active_auction": return "Аукціон"
            case "active_qualification": return "Очікується опублікування протоколу"
            case "active_awarded": return "Очікується підписання акту/договору"
            case "pending_payment": return "Очікується оплата"
            case "cancelled": return "Аукціон відмінено"
            case "unsuccessful": return "Аукціон не відбувся"
            case "complete": return "Аукціон завершено. Акт/договір підписано"
            default: return "Чернетка"
        }
    }
    if (isRLE(type) || isRLD(type)) {
        switch(status){
            case "active_tendering": return "Прийняття заяв на участь"
            case "active_auction": return "Аукціон"
            case "active_qualification": return "Очікується опублікування протоколу"
            case "active_awarded": return "Очікується підписання договору"
            case "cancelled": return "Аукціон відмінено"
            case "unsuccessful": return "Аукціон не відбувся"
            case "complete": return "Аукціон завершено. Договір підписано"
            default: return "Чернетка"
        }
    }
    if (isCLE(type) || isCLD(type)) {
        switch(status){
            case "active_tendering": return "Прийняття заяв на участь"
            case "active_auction": return "Аукціон"
            case "active_qualification": return "Очікується опублікування протоколу"
            case "active_awarded": return "Очікується підписання договору"
            case "cancelled": return "Аукціон відмінено"
            case "unsuccessful": return "Аукціон не відбувся"
            case "complete": return "Аукціон завершено. Договір підписано"
            default: return "Чернетка"
        }
    }
    if (isNLE(type) || isNLD(type)) {
        switch(status){
            case "active_tendering": return "Прийняття заяв на участь"
            case "active_auction": return "Аукціон"
            case "active_awarded": return "Очікується підписання договору"
            case "pending_payment": return "Очікується оплата"
            case "cancelled": return "Аукціон скасовано"
            case "unsuccessful": return "Аукціон не відбувся"
            case "complete": return "Аукціон завершено. Договір підписано"
            default: return "Чернетка"
        }
    }
    if (isSPE(type) || isSPD(type)) {
        switch(status){
            case "active_tendering": return "Прийняття заяв на участь"
            case "active_auction": return "Аукціон"
            case "active_qualification": return "Очікується опублікування протоколу"
            case "active_awarded": return "Очікується підписання договору"
            case "pending_payment": return "Очікується оплата"
            case "cancelled": return "Аукціон відмінено"
            case "unsuccessful": return "Аукціон не відбувся"
            case "complete": return "Аукціон завершено. Договір підписано"
            default: return "Чернетка"
        }
    }
    if (isLAW(type)) {
        switch(status){
            case "active_awarded": return "Очікується підписання акту"
            case "complete": return "Викуп завершено. Акт підписано"
            case "cancelled": return "Викуп скасовано"
            default: return "Чернетка"
        }
    }
    if (isLAE(type)) {
        switch(status){
            case "active_rectification": return "Редагування доступне"
            case "active_tendering": return "Прийняття заяв на участь"
            case "active_auction": return "Земельні торги"
            case "active_qualification": return "Очікується опублікування протоколу"
            case "active_awarded": return "Очікується підписання акту"
            case "pending_payment": return "Очікується оплата"
            case "complete": return "Торги завершено. Акт/договір підписано"
            case "cancelled": return "Торги скасовано"
            case "unsuccessful": return "Торги не відбулися"
            default: return "Чернетка"
        }
    }
    if (isLAP(type)) {
        switch(status){
            case "active_rectification": return "Редагування доступне"
            case "active_tendering": return "Прийняття заяв на участь"
            case "active_auction": return "Земельні торги"
            case "active_qualification": return "Очікується оприлюднення протоколу"
            case "active_awarded": return "Очікується підписання акту/договору"
            case "pending_payment": return "Очікується оплата"
            case "complete": return "Торги завершено. Акт підписано"
            case "cancelled": return "Торги скасовано"
            case "unsuccessful": return "Торги не відбулися"
            default: return "Чернетка"
        }
    }
    if (isLPE(type)) {
        switch(status){
            case "active_tendering": return "Прийняття заяв на участь"
            case "active_auction": return "Аукціон"
            case "active_qualification": return "Очікується опублікування протоколу"
            case "pending_payment": return "Очікується оплата"
            case "active_awarded": return "Очікується підписання договору"
            case "complete": return "Аукціон завершено. Договір підписано"
            case "cancelled": return "Аукціон відмінено"
            case "unsuccessful": return "Аукціон не відбувся"
            default: return "Чернетка"
        }
    }
    if (isAPE(type) || isAPD(type)) {
        switch(status){
            case "active_tendering": return "Прийняття заяв на участь"
            case "active_auction": return "Аукціон"
            case "active_qualification": return "Очікується оприлюднення протоколу"
            case "pending_payment": return "Очікується оплата"
            case "active_awarded": return "Очікується підписання акту/договору"
            case "complete": return "Аукціон завершено. Акт/договір підписано"
            case "cancelled": return "Аукціон скасовано"
            case "unsuccessful": return "Аукціон не відбувся"
            default: return "Чернетка"
        }
    }
    switch(status){
        case "active_rectification": return "Редагування доступне"
        case "active_tendering": return "Прийняття заяв на участь"
        case "active_auction": return "Аукціон"
        case "active_qualification": return "Очікується опублікування протоколу"
        case "active_awarded": return "Очікується підписання договору"
        case "pending_payment": return "Очікується сплата до бюджету"
        case "pending_admission": return "Аукціон завершено. Очікується виконання умов договору"
        case "pending_payment": return "Очікується оплата"
        case "cancelled": return "Аукціон скасовано"
        case "unsuccessful": return "Аукціон не відбувся"
        case "complete": return "Аукціон завершено"
        default: return "Чернетка"
    }
}

export function getAuctionsPeriods(type, status){
    switch(status){
        case "start":
            if(isLAE(type) || isLAW(type) || isLAP(type)) return "Торги оголошено"
            return "Аукціон оголошено"
        case "active_rectification": return "Період редагування"
        case "active_tendering":
            if(isAPE(type) || isAPD(type)) return "Період подання пропозицій"
            return "Період подання пропозицій"
        case "questionPeriod": 
            if(isLAE(type) || isLAW(type) || isLAP(type)) return "Період запитань"
            if(isAPE(type) || isAPD(type)) return "Період запитань та відповідей"
            return "Період уточнень"
        case "transferPriorityPeriod": return "Період передачі переважного права"
        case "active_auction":
            if(isLAE(type) || isLAW(type) || isLAP(type)) return "Період аукціону"
            if(isAPE(type) || isAPD(type)) return "Період аукціону"
            return "Період аукціону"
        case "active_qualification":
            if(isLAE(type) || isLAW(type) || isLAP(type)) return "Очікується оприлюднення протоколу"
            if(isLPE(type)) return "Період кваліфікації"
            if(isAPE(type) || isAPD(type)) return "Період кваліфікації"
            return "Очікується публікація протоколу"
        case "active_awarded":
            if(isLAE(type) || isLAW(type)) return "Очікується підписання акту/договору"
            if(isLAP(type)) return "Період підписання акту"
            if(isAPE(type) || isAPD(type)) return "Період кваліфікації"
            return "Очікується підписання договору"
        case "pending_payment": return "Очікується оплата"
        case "pending_admission": return "Період публікації рішення про викуп"
        case "auctionEnd": return "Завершення торгів"
        case "pending_payment":
            if(isAPE(type) || isAPD(type)) return "Очікується оплата"
            return ""
        case "cancelled":
            if(isAPE(type) || isAPD(type)) return "Аукціон скасовано"
            return ""
        case "unsuccessful":
            if(isAPE(type) || isAPD(type)) return "Аукціон не відбувся"
            return ""
        case "complete":
            if(isAPE(type) || isAPD(type)) return "Аукціон завершено. Акт/договір підписано"
            return ""
        default: return ""
    }
}