import React from 'react';

import {connect} from 'react-redux';

import {
    isRLE, isRLD, isCLE, isCLD, isBRD, isBRE, isBRW, isAAE, isALE, isBSD, isBSE, isCSD, isCSE, 
    isLLD, isLLE, isLLP, isLRE, isLSE, isLSP, isRCD, isRCE, isSUD, isSUE, isTIE, isSPE, isSPD,
    isNLE, isNLD, isLAE, isLAW, isLAP, isLPE, isGFE, isGFD, isRMA, isAPE, isAPD} from '../../../redux/aucTypes';


import Timber from './timber/timber.js';
import Energy from './energy/energy.js';
import Railway from './railway/railway.js';
import Subsoil from './subsoil/subsoil.js';
import Dgf from './dgf/dgf.js';
import LegitimatePropertyLease from './legitimatePropertyLease/legitimatePropertyLease'
//import LegitimatePropertyLease from './LLELLDLLP/commSell'
import LandRental from './landRental/landRental'
//import BasicSell from './basicSell/basicSell'
import BasicSell from './bsebsd/bsebsd'
//import Alienation from './alienation/alienation'
import Alienation from './ale/ale'
import CommSell from './commSell/commSell';
import BRESell from './BRE/BRE.js';
import BRWSell from './BRW/BRW.js';
import RLERLD from './RLERLD/RLERLD.js';

import NLE from './nlenld/nlenld'
import LAE from './lae/lae'
import LAW from './law/law'
import LAP from './lap/lap'
import LPE from './lpe/bsebsd'
import CLE from './CLE/bsebsd'
import RLE from './RLE/bsebsd'
import APE from './ape/bsebsd'
import LLE from './lle/bsebsd'
import LRE from './lre/bsebsd'

const WindowItems = ( props ) => {
    if(props.auctionsMy === null) return null
    if(isTIE(props.auctionsMy.sellingMethod)) return <Timber />
    if(isRMA(props.auctionsMy.sellingMethod)) return <Energy />
    if(isSUD(props.auctionsMy.sellingMethod) || isSUE(props.auctionsMy.sellingMethod)) return <Subsoil />
    if(isRCD(props.auctionsMy.sellingMethod) || isRCE(props.auctionsMy.sellingMethod)) return <Railway />
    if(isGFE(props.auctionsMy.sellingMethod) || isGFD(props.auctionsMy.sellingMethod)) return <Dgf />
    
    
    //if(isLLD(props.auctionsMy.sellingMethod) || isLLE(props.auctionsMy.sellingMethod) || isLLP(props.auctionsMy.sellingMethod)) return <LegitimatePropertyLease />
    if(isLLE(props.auctionsMy.sellingMethod) || isLLD(props.auctionsMy.sellingMethod) || isLLP(props.auctionsMy.sellingMethod)) return <LLE />
    
    
    if(isLRE(props.auctionsMy.sellingMethod) || isLSE(props.auctionsMy.sellingMethod) || isLSP(props.auctionsMy.sellingMethod)) return <LRE />
    //if(isLSE(props.auctionsMy.sellingMethod) || isLSP(props.auctionsMy.sellingMethod)) return <LandRental />
    
    
    if(isBSD(props.auctionsMy.sellingMethod) || isBSE(props.auctionsMy.sellingMethod)) return <BasicSell />
    if(isALE(props.auctionsMy.sellingMethod)) return <Alienation />
    if(isCSD(props.auctionsMy.sellingMethod) || isCSE(props.auctionsMy.sellingMethod)) return <CommSell />
    // if(isAAE(props.auctionsMy.sellingMethod))
    if(isBRD(props.auctionsMy.sellingMethod) || isBRE(props.auctionsMy.sellingMethod)) return <BRESell />
    if(isBRW(props.auctionsMy.sellingMethod)) return <BRWSell />
    if(isRLE(props.auctionsMy.sellingMethod) || isRLD(props.auctionsMy.sellingMethod)) return <RLE />
    if(isCLE(props.auctionsMy.sellingMethod) || isCLD(props.auctionsMy.sellingMethod)) return <CLE />
    if(isSPE(props.auctionsMy.sellingMethod) || isSPD(props.auctionsMy.sellingMethod)) return <LPE />
    if(isNLE(props.auctionsMy.sellingMethod) || isNLD(props.auctionsMy.sellingMethod)) return <NLE />
    if(isLAE(props.auctionsMy.sellingMethod)) return <LAE />
    if(isLAW(props.auctionsMy.sellingMethod)) return <LAW />
    if(isLAP(props.auctionsMy.sellingMethod)) return <LAP />
    if(isLPE(props.auctionsMy.sellingMethod)) return <LPE />

    if(isAPE(props.auctionsMy.sellingMethod) || isAPD(props.auctionsMy.sellingMethod)) return <APE />
    return null

    // let tmp = null;
    // if(props.auctionsMy !== null){
    //     switch(props.auctionsMy.sellingMethod){
    //         // case "timber-english":
    //         // case "timber-english-fast":
    //         // case "timber-english-fast-manual":
    //         // case "timber-english-initial-auction":
    //         // case "timber-english-initial-qualification":
    //         // case "timber-english-initial-qualification-fast":
    //         //     tmp = <Timber />
    //         //     break;
    //         // case "renewables":
    //         // case "renewables-fast":
    //         // case "renewables-fast-manual":
    //         // case "renewables-initial-auction":
    //         // case "renewables-initial-qualification":
    //         // case "renewables-initial-qualification-fast":
    //         //     tmp = <Energy />
    //         //     break;
    //         // case "subsoil-english":
    //         // case "subsoil-english-fast":
    //         // case "subsoil-english-fast-manual":
    //         // case "subsoil-english-initial-auction":
    //         // case "subsoil-english-initial-qualification":
    //         // case "subsoil-dutch":
    //         // case "subsoil-dutch-fast":
    //         // case "subsoil-dutch-fast-manual":
    //         // case "subsoil-dutch-initial-auction":
    //         // case "subsoil-dutch-initial-qualification":
    //         // case "subsoil-dutch-initial-auction-manual":
    //         // case "subsoil-english-fast-auction-manual-qualification":
    //         //     tmp = <Subsoil />
    //         //     break;
    //         // case "railwayCargo-english":
    //         // case "railwayCargo-english-fast":
    //         // case "railwayCargo-english-fast-manual":
    //         // case "railwayCargo-english-initial-auction":
    //         // case "railwayCargo-english-initial-qualification":
    //         // case "railwayCargo-dutch":
    //         // case "railwayCargo-dutch-fast":
    //         // case "railwayCargo-dutch-fast-manual":
    //         // case "railwayCargo-dutch-initial-auction":
    //         // case "railwayCargo-dutch-initial-qualification":
    //         // case "railwayCargo-dutch-fast-auction-manual-qualification":
    //         // case "railwayCargo-dutch-initial-auction-manual":
    //         //     tmp = <Railway />
    //         //     break;
    //         // case "dgf-english":
    //         // case "dgf-english-fast":
    //         // case "dgf-english-fast-manual":
    //         // case "dgf-english-fast-auction-manual-qualification":
    //         // case "dgf-english-initial-auction":
    //         // case "dgf-english-initial-auction-manual":
    //         // case "dgf-english-initial-qualification":
    //         //     tmp = <Dgf />
    //         //     break;
    //         // case "legitimatePropertyLease-english":
    //         // case "legitimatePropertyLease-english-fast":
    //         // case "legitimatePropertyLease-english-fast-manual":
    //         // case "legitimatePropertyLease-english-fast-auction-manual-qualification":
    //         // case "legitimatePropertyLease-english-initial-auction":
    //         // case "legitimatePropertyLease-english-initial-auction-manual":
    //         // case "legitimatePropertyLease-english-initial-qualification":
    //         // case "legitimatePropertyLease-english-initial-qualification-prod":
    //         // case "legitimatePropertyLease-dutch":
    //         // case "legitimatePropertyLease-dutch-fast":
    //         // case "legitimatePropertyLease-dutch-fast-manual":
    //         // case "legitimatePropertyLease-dutch-fast-auction-manual-qualification":
    //         // case "legitimatePropertyLease-dutch-initial-auction":
    //         // case "legitimatePropertyLease-dutch-initial-auction-manual":
    //         // case "legitimatePropertyLease-dutch-initial-qualification-prod":
    //         // case "legitimatePropertyLease-priorityEnglish":
    //         // case "legitimatePropertyLease-priorityEnglish-fast":
    //         // case "legitimatePropertyLease-priorityEnglish-fast-manual":
    //         // case "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification":
    //         // case "legitimatePropertyLease-priorityEnglish-initial-auction":
    //         // case "legitimatePropertyLease-priorityEnglish-initial-auction-manual":
    //         // case "legitimatePropertyLease-priorityEnglish-initial-qualification":
    //         // case "legitimatePropertyLease-priorityEnglish-initial-qualification-prod":
    //         //     tmp = <LegitimatePropertyLease />
    //         //     break;
    //         // case 'landRental-english':
    //         // case 'landRental-english-fast':
    //         // case 'landRental-english-fast-manual':
    //         // case 'landRental-english-fast-auction-manual-qualification':
    //         // case 'landRental-english-initial-auction':
    //         // case 'landRental-english-initial-auction-manual':
    //         // case 'landRental-english-initial-qualification':
    //         // case 'landRental-english-initial-qualification-prod':
    //         //     tmp = <LandRental />
    //         //     break;
    //         // case 'landSell-english':
    //         // case 'landSell-english-fast':
    //         // case 'landSell-english-fast-manual':
    //         // case 'landSell-english-fast-auction-manual-qualification':
    //         // case 'landSell-english-initial-auction':
    //         // case 'landSell-english-initial-auction-manual':
    //         // case 'landSell-english-initial-qualification':
    //         // case 'landSell-english-initial-qualification-prod':
    //         //     tmp = <LandRental />
    //         //     break;
    //         // case 'landSell-priorityEnglish':
    //         // case 'landSell-priorityEnglish-fast':
    //         // case 'landSell-priorityEnglish-fast-manual':
    //         // case 'landSell-priorityEnglish-fast-auction-manual-qualification':
    //         // case 'landSell-priorityEnglish-initial-auction':
    //         // case 'landSell-priorityEnglish-initial-auction-manual':
    //         // case 'landSell-priorityEnglish-initial-qualification':
    //         // case 'landSell-priorityEnglish-initial-qualification-prod':
    //         //     tmp = <LandRental />
    //         //     break;
    //         // case 'basicSell-english':
    //         // case 'basicSell-english-fast':
    //         // case 'basicSell-english-fast-manual':
    //         // case 'basicSell-english-fast-auction-manual-qualification':
    //         // case 'basicSell-english-initial-auction':
    //         // case 'basicSell-english-initial-auction-manual':
    //         // case 'basicSell-english-initial-qualification':
    //         // case 'basicSell-english-initial-qualification-prod':
    //         //     tmp = <BasicSell />
    //         //     break;
    //         // case 'basicSell-dutch':
    //         // case 'basicSell-dutch-fast':
    //         // case 'basicSell-dutch-fast-manual':
    //         // case 'basicSell-dutch-fast-auction-manual-qualification':
    //         // case 'basicSell-dutch-initial-auction':
    //         // case 'basicSell-dutch-initial-auction-manual':
    //         // case 'basicSell-dutch-initial-qualification':
    //         // case 'basicSell-dutch-initial-qualification-prod':
    //         //     tmp = <BasicSell />
    //         //     break;
    //         // case 'alienation-english':
    //         // case 'alienation-english-fast':
    //         // case 'alienation-english-fast-manual':
    //         // case 'alienation-english-fast-auction-manual-qualification':
    //         // case 'alienation-english-initial-auction':
    //         // case 'alienation-english-initial-auction-manual':
    //         // case 'alienation-english-initial-qualification':
    //         // case 'alienation-english-initial-qualification-prod':
    //         //     tmp = <Alienation />
    //         //     break;
    //         // case 'commercialSell-english':
    //         // case 'commercialSell-english-fast':
    //         // case 'commercialSell-english-fast-manual':
    //         // case 'commercialSell-english-fast-auction-manual-qualification':
    //         // case 'commercialSell-english-initial-auction':
    //         // case 'commercialSell-english-initial-auction-manual':
    //         // case 'commercialSell-english-initial-qualification':
    //         // case 'commercialSell-english-initial-qualification-prod':
    //         // case 'commercialSell-dutch':
    //         // case 'commercialSell-dutch-fast':
    //         // case 'commercialSell-dutch-fast-manual':
    //         // case 'commercialSell-dutch-fast-auction-manual-qualification':
    //         // case 'commercialSell-dutch-initial-auction':
    //         // case 'commercialSell-dutch-initial-auction-manual':
    //         // case 'commercialSell-dutch-initial-qualification':
    //         // case 'commercialSell-dutch-initial-qualification-prod':
    //         //     tmp = <CommSell />
    //         //     break;
    //         // case 'bankRuptcy-english':
    //         // case 'bankRuptcy-english-fast':
    //         // case 'bankRuptcy-english-fast-manual':
    //         // case 'bankRuptcy-english-fast-auction-manual-qualification':
    //         // case 'bankRuptcy-english-initial-auction':
    //         // case 'bankRuptcy-english-initial-auction-manual':
    //         // case 'bankRuptcy-english-initial-qualification':
    //         // case 'bankRuptcy-english-initial-qualification-prod':
    //         // case 'bankRuptcy-dutch':
    //         // case 'bankRuptcy-dutch-fast':
    //         // case 'bankRuptcy-dutch-fast-manual':
    //         // case 'bankRuptcy-dutch-fast-auction-manual-qualification':
    //         // case 'bankRuptcy-dutch-initial-auction':
    //         // case 'bankRuptcy-dutch-initial-auction-manual':
    //         // case 'bankRuptcy-dutch-initial-qualification':
    //         // case 'bankRuptcy-dutch-initial-qualification-prod':
    //         //     tmp = <BRESell />
    //         //     break
    //         // case 'bankRuptcy-withoutAuction':
    //         //     tmp = <BRWSell />
    //         //     break
    //         // case "regulationsPropertyLease-english":
    //         // case "regulationsPropertyLease-english-ultra-fast":
    //         // case "regulationsPropertyLease-english-fast":
    //         // case "regulationsPropertyLease-english-fast-manual":
    //         // case "regulationsPropertyLease-english-fast-auction-manual-qualification":
    //         // case "regulationsPropertyLease-english-fast-auction-prod":
    //         // case "regulationsPropertyLease-english-initial-auction":
    //         // case "regulationsPropertyLease-english-initial-auction-manual":
    //         // case "regulationsPropertyLease-english-initial-qualification":
    //         // case "regulationsPropertyLease-english-initial-qualification-prod":
    //         // case "regulationsPropertyLease-dutch":
    //         // case "regulationsPropertyLease-dutch-ultra-fast":
    //         // case "regulationsPropertyLease-dutch-fast":
    //         // case "regulationsPropertyLease-dutch-fast-manual":
    //         // case "regulationsPropertyLease-dutch-fast-auction-manual-qualification":
    //         // case "regulationsPropertyLease-dutch-fast-auction-prod":
    //         // case "regulationsPropertyLease-dutch-initial-auction":
    //         // case "regulationsPropertyLease-dutch-initial-auction-manual":
    //         // case "regulationsPropertyLease-dutch-initial-qualification":
    //         // case "regulationsPropertyLease-dutch-initial-qualification-prod":
    //         //     //tmp = <RLERLD />
    //         //     tmp = <CLE />
    //         //     break
    //         // case 'commercialPropertyLease-english':
    //         // case 'commercialPropertyLease-english-ultra-fast':
    //         // case 'commercialPropertyLease-english-fast':
    //         // case 'commercialPropertyLease-english-fast-manual':
    //         // case 'commercialPropertyLease-english-fast-auction-manual-qualification':
    //         // case 'commercialPropertyLease-english-fast-auction-prod':
    //         // case 'commercialPropertyLease-english-initial-auction':
    //         // case 'commercialPropertyLease-english-initial-auction-manual':
    //         // case 'commercialPropertyLease-english-initial-qualification':
    //         // case 'commercialPropertyLease-english-initial-qualification-prod':
    //         // case "commercialPropertyLease-dutch":
    //         // case "commercialPropertyLease-dutch-ultra-fast":
    //         // case "commercialPropertyLease-dutch-fast":
    //         // case "commercialPropertyLease-dutch-fast-manual":
    //         // case "commercialPropertyLease-dutch-fast-auction-manual-qualification":
    //         // case "commercialPropertyLease-dutch-fast-auction-prod":
    //         // case "commercialPropertyLease-dutch-initial-auction":
    //         // case "commercialPropertyLease-dutch-initial-auction-manual":
    //         // case "commercialPropertyLease-dutch-initial-qualification":
    //         // case "commercialPropertyLease-dutch-initial-qualification-prod":
    //         //     tmp = <CLE />
    //         //     break
    //         // case "smallPrivatization-english":
    //         // case "smallPrivatization-english-fast":
    //         // case "smallPrivatization-english-ultra-fast":
    //         // case "smallPrivatization-english-fast-manual":
    //         // case "smallPrivatization-english-fast-auction-manual-qualification":
    //         // case "smallPrivatization-english-fast-auction-prod":
    //         // case "smallPrivatization-english-initial-auction":
    //         // case "smallPrivatization-english-initial-auction-manual":
    //         // case "smallPrivatization-english-initial-qualification":
    //         // case "smallPrivatization-english-initial-qualification-prod":
    //         // case "smallPrivatization-dutch":
    //         // case "smallPrivatization-dutch-fast":
    //         // case "smallPrivatization-dutch-ultra-fast":
    //         // case "smallPrivatization-dutch-fast-manual":
    //         // case "smallPrivatization-dutch-fast-auction-manual-qualification":
    //         // case "smallPrivatization-dutch-fast-auction-prod":
    //         // case "smallPrivatization-dutch-initial-auction":
    //         // case "smallPrivatization-dutch-initial-auction-manual":
    //         // case "smallPrivatization-dutch-initial-qualification":
    //         // case "smallPrivatization-dutch-initial-qualification-prod":
    //         //     tmp = <LPE />
    //         //     break
    //         // case "nonperformingLoans-english":
    //         // case "nonperformingLoans-english-fast":
    //         // case "nonperformingLoans-english-fast-manual":
    //         // case "nonperformingLoans-english-fast-auction-manual-qualification":
    //         // case "nonperformingLoans-english-fast-auction-prod":
    //         // case "nonperformingLoans-english-initial-qualification":
    //         // case "nonperformingLoans-english-initial-qualification-prod":
    //         // case "nonperformingLoans-english-initial-auction":
    //         // case "nonperformingLoans-english-initial-auction-manual":
    //         // case "nonperformingLoans-dutch":
    //         // case "nonperformingLoans-dutch-fast":
    //         // case "nonperformingLoans-dutch-fast-manual":
    //         // case "nonperformingLoans-dutch-fast-auction-manual-qualification":
    //         // case "nonperformingLoans-dutch-fast-auction-prod":
    //         // case "nonperformingLoans-dutch-initial-auction":
    //         // case "nonperformingLoans-dutch-initial-auction-manual":
    //         // case "nonperformingLoans-dutch-initial-qualification":
    //         // case "nonperformingLoans-dutch-initial-qualification-prod":
    //         //     tmp = <NLE />
    //         //     break
    //         // case "landArrested-english":
    //         // case "landArrested-english-ultra-fast":
    //         // case "landArrested-english-fast":
    //         // case "landArrested-english-fast-manual":
    //         // case "landArrested-english-fast-auction-manual-qualification":
    //         // case "landArrested-english-fast-auction-prod":
    //         // case "landArrested-english-initial-auction":
    //         // case "landArrested-english-initial-auction-manual":
    //         // case "landArrested-english-initial-qualification":
    //         // case "landArrested-english-initial-qualification-prod":
    //         //     tmp = <LAE />
    //         //     break
    //         // case "landArrested-withoutAuction":
    //         //     tmp = <LAW />
    //         //     break
    //         // case "landArrested-priorityEnglish":
    //         // case "landArrested-priorityEnglish-ultra-fast":
    //         // case "landArrested-priorityEnglish-fast":
    //         // case "landArrested-priorityEnglish-fast-manual":
    //         // case "landArrested-priorityEnglish-fast-auction-manual-qualification":
    //         // case "landArrested-priorityEnglish-fast-auction-prod":
    //         // case "landArrested-priorityEnglish-initial-auction":
    //         // case "landArrested-priorityEnglish-initial-auction-manual":
    //         // case "landArrested-priorityEnglish-initial-qualification":
    //         // case "landArrested-priorityEnglish-initial-qualification-prod":
    //         //     tmp = <LAP />
    //         //     break
    //         // case "largePrivatization-english":
    //         // case "largePrivatization-english-fast":
    //         // case "largePrivatization-english-ultra-fast":
    //         // case "largePrivatization-english-fast-manual":
    //         // case "largePrivatization-english-fast-auction-manual-qualification":
    //         // case "largePrivatization-english-fast-auction-prod":
    //         // case "largePrivatization-english-initial-auction":
    //         // case "largePrivatization-english-initial-auction-manual":
    //         // case "largePrivatization-english-initial-qualification":
    //         // case "largePrivatization-english-initial-qualification-prod":
    //         //     tmp = <LPE />
    //         //     break
    //         // default:
    //         //     tmp = null
    //         //     break;
    //     }
    // }
    // return tmp
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
    }
}

export default connect(mapStateToProps, null)(WindowItems)
