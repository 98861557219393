import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';

import styles from './bankAccounts.module.css';
import BankAccountsNew from './bankAccountsNew';

import {
    bankAccountGet,
    changePopUpBankAccount,
    setPopBankAccountId,
    setPopBankAccountIdArr,
    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,
    openBankWindows,
} from '../../../../../redux/actions/bankAccount/bankAccount.js';

const BankAccount =( { bankAccounts, setBankAccounts, ...props})=> {
    // const [bankAccounts, setBankAccounts] = useState(!!data && data.length > 0 ? data : []);
    const [newBanks, setNewBankArr] = useState([]);
    const [deleteBanks, setDelBankArr] = useState([]);

    async function setBanks(data){
        for (let key in data){
            if (data[key].accounts.length === 0) {
                const index = data.indexOf(data[key])
                data.splice(index, 1)
            }
        }
        await setBankAccounts(null)
        setBankAccounts(data)
    }

    return (
        <div className={styles.bankBlock}>
            <div className={styles.bankBlockTitle}>Банківські реквізити *</div>
            <BankAccountsNew 
                bankAccounts={bankAccounts}
                setBankAccounts={setBanks}

                newBankArr={newBanks}
                deleteBanks={deleteBanks}
                setNewBankArr={setNewBankArr}
                setDelBankArr={setDelBankArr}
            />
        </div>
    )
}

 
const mapStateToProps = state => {
    return {
        // token: state.start.token,
        // auctions: state.start.auctionsGet,
        // langFlag: state.start.langFlag,
        // createAuctionTab: state.start.createAuctionTab,
        // createAuction: state.start.createAuction,
        // auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        // endPoint: state.start.endPoint,
        // classificationsGets: state.start.classificationsGet,
        auctionsMy: state.start.auctionsMy,
        bankAccount: state.start.bankAccount,
        bankAccountId: state.start.bankAccountId,
        bankAccountIdArr: state.start.bankAccountIdArr,
        //discount: state.start.discount,
        //userProfiles: state.start.userProfiles,
        // carryType: state.start.carryType,
        bankAccountWork: state.start.bankAccountWork,
        // addressNew: state.start.addressNew,
        bankAccountZU: state.start.bankAccountZU,
        createBankAccountZU: state.start.createBankAccountZU,
        // schedule: state.start.schedule,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
        //shedullePeriod: state.start.shedullePeriod,
        deleteBankGroops: state.start.deleteBankGroops,
        deleteCurentBank: state.start.deleteCurentBank
    }
}

const mapDispatchToProps = {


    // auctionsGetMy,
    // getMyAuction,
    // changeCreateAuctionLotIdentifier,

    changePopUpBankAccount,
    setPopBankAccountId,
    setPopBankAccountIdArr,
    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,

    // setDataAddress,

    // changeLoader,
    openBankWindows,
    bankAccountGet
}

export default connect(mapStateToProps, mapDispatchToProps)(BankAccount)