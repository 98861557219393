import React from 'react'
import styles from './bidEditNewPopup.module.css'
import {connect} from 'react-redux';
import TextHalf from '../../../elements/viewData/textHalf';

import {changeBidAward} from '../../../../redux/actions.js';
import {changeBidPopUpEdit, auctionsBidsGetId} from '../../../../redux/actions/auctionBid/auctionBid.js';
import {setDataAddress} from '../../../../redux/createAddress';
import {changeLoader} from '../../../../redux/actions.js';

import InstructionBlock from '../../../elements/viewData/instructionBlock/instructionBlock';

import {
    saveArrBids,
} from '../../../../redux/allAuctionBids.js';

const BidEditPopUp = (props) => {
    let tmpHealp = null
    if(props.bidEdit.status === "active"){
        tmpHealp = <InstructionBlock
            title='Важлива інформація'
            content={
                <>
                    <p>Увага! Всі зміни, внесені до Вашої початкової закритої пропозиції після перевірки та підтвердження майданчиком, повторно не перевіряються та є повністю Вашою відповідальністю!</p>
                    <p>Натискаючи "Зберегти", Ви погоджуєтесь з умовами.</p>
                    {/*<p><a className={styles.instrLink} href='https://www.dto.com.ua/info/rules' target='blank'>Пройти фінансову ідентифікацію організації</a></p>*/}
                </>
            }
        />
    }
    return (
        <div className={styles.wrapMain}>
            <div className={styles.wrap}>
                <div
                    className={styles.close}
                    onClick={
                        //props.actionClose
                        ()=>{
                            props.changeBidPopUpEdit(null)
                        }
                    }
                >✕</div>
                <h3 className={styles.popupTitle}>Редагувати пропозицію</h3>
                <p className={styles.description}>Відкорегуйте вашу пропозицію</p>
                {tmpHealp}
                <TextHalf
                    title='Початкова ціна продажу лоту'
                    data={`${viewBaseAuctionValue(props)} грн.`}
                />
                <div className={styles.bidLotCost_input}>
                    <input 
                        className={styles.inputNumber}
                        type='number'
                        value={viewValue(props)}
                        onChange={(e) => {
                            props.setDataAddress(e.target.value, 'SET_EDIT_BID_VALUE')
                        }}
                    />
                    <div className={styles.bidLotCost_title}>Цінова пропозиція</div>
                </div>
                <div className={styles.bidLotCost_descr}>{`Пропозиція має бути вища за ${viewBaseAuctionValue(props)} грн.`}</div>
                <div className={styles.bottomPart}>
                <div className={styles.btnCancel}
                    onClick={
                        //props.actionNo
                        ()=>{
                            props.changeBidPopUpEdit(null)
                        }
                    }
                >
                    Скасувати
                </div>
                <div className={styles.btnDone}
                    onClick={
                        //props.actionYes
                        ()=>{
                            updateBid(props)
                        }
                    }
                >
                    Зберегти</div>
                </div>
            </div>
        </div>
    )
}

function viewValue(props){
    if(props.bidEdit !== null){
        if(props.bidEdit.value !== null){
            return props.bidEdit.value.amount
        }
    }
    return null
}

function viewBaseAuctionValue(props){
    if(props.auctionId !== null){
        if(props.auctionId.value !== null){
            return startCostAuction(props.auctionId.value.amount)
        }
    }
    return null
}

function startCostAuction(data){
    let tmp = null
    let amount = null
    if(data !== null){
        amount = String(data);
        if(amount !== null){
            if(amount.indexOf('.') !== -1){
                amount = amount.split('.');
                tmp = String(amount[0]);
                tmp = tmp.split("").reverse().join("");
                let tmp1 = [];
                for(let i = 0; i < tmp.length; i++){
                    if(((i+1)%3) === 0){
                        if(i !== tmp.length-1){
                            tmp1[i] = tmp[i] + '\'';
                        }else{
                            tmp1[i] = tmp[i];
                        }
                    }else{
                        tmp1[i] = tmp[i];
                    }
                }
                tmp = tmp1.join('')
                tmp = tmp.split("").reverse().join("");
                amount = amount[1];
            }else{
                tmp = String(amount);
                tmp = tmp.split("").reverse().join("");
                let tmp1 = [];
                for(let i = 0; i < tmp.length; i++){
                    if(((i+1)%3) === 0){
                        if(i !== tmp.length-1){
                            tmp1[i] = tmp[i] + '\'';
                        }else{
                            tmp1[i] = tmp[i];
                        }
                    }else{
                        tmp1[i] = tmp[i];
                    }
                }
                tmp = tmp1.join('')
                tmp = tmp.split("").reverse().join("");
                amount = '00'    
            }
        }
    }
    return tmp + ',' + amount
}

async function updateBid(props){
    props.changeLoader(true)
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionId.id}/bid/${props.bidEdit.id}`;
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.bidEdit.id}`;
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/bid/${props.bidEdit.id}`;
    let tmp = 0
    if(props.bidEdit.value.amount !== '' && props.bidEdit.value.amount !== null){
        if(String(props.bidEdit.value.amount).indexOf(',')){
            tmp = String(props.bidEdit.value.amount).replace(/,/gi, '.')
        }else{
            tmp = String(props.bidEdit.value.amount)
        }
    }
    const response = await fetch(endPoint, {
        method: "PUT",
        body: JSON.stringify({
            //classificationId: props.bidPopUpEdit.classification.id,
            //unitCode: props.bidPopUpEdit.unit.code,
            //qualified: true,
            value: {
                currency: props.bidEdit.value.currency,
                amount: Number(tmp),
                valueAddedTaxIncluded: true,
            },
            //quantity: props.bidQuantity
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    if(response === undefined || response === 'undefined'){
        props.changeBidPopUpEdit(null)
        props.changeLoader(false)
        return null
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            props.changeBidPopUpEdit(null)
            props.changeLoader(false)
            return null
        }else{
            getPublicApiAuction(props)
        }
    }
    props.changeBidPopUpEdit(null)
    props.changeLoader(false)
}

///////////////////////////////////////
async function getPublicApiAuction(props){
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/auction/${props.auctionId.id}`
    let header = null
    if(props.token !== null){
        header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }else{
        header = {
            'Content-Type': 'application/json'
        }
    }
    const response = await fetch(url, {
        method: "GET",
        headers: header
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){

        }else{
            props.setDataAddress(json, 'SET_FULL_AUCTION')
            getDocumentsAuctionBidsAll(props, json.id)
        }
    }
}


async function getDocumentsAuctionBidsAll(props, id){
    let header = null
    if(props.token !== null){
        header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }else{
        header = {
            'Content-Type': 'application/json'
        }
    }
    //const url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${id}/bids/all`;
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.bidEdit.id}`

    const response = await fetch(url, {
        method: "GET",
        headers: header
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            //let tmp = JSON.stringify({title: 'Помилка при відхиленні аварду', message: JSON.stringify(json2)})
            //props.setUniversalError(tmp)
        }else{
            await props.saveArrBids(json)
        }
    }
}

const mapStateToProps = state => {
    return {
        bidAward: state.start.bidAward,
        bidEdit: state.start.bidPopUpEdit,
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,

        token: state.start.token,
    }
}

const mapDispatchToProps = {
    changeBidAward,
    changeBidPopUpEdit,
    setDataAddress,
    changeLoader,
    auctionsBidsGetId,
    saveArrBids,
}

export default connect(mapStateToProps, mapDispatchToProps)(BidEditPopUp)