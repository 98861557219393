import React, {useState, useEffect} from 'react';
import { checkProp } from '../../../redux/check';
import styles from './inputDate.module.css';

const InputDate = (props) => {
    let xTime = new Date()
    let currentxTime = Math.abs(xTime.getTimezoneOffset()) / 60 // поправка на часовой пояс -3

    const [datePopdown, setdatePopdown] = useState(false)

    const [wrapper, setWrapper] = useState(styles.noDisplay)
    // const [placeholder, setPlaceholder] = useState('рррр-мм-дд')
    const [complete, setComplete] = useState(!!props.disabled ? styles.disabled : styles.inPutDate)
    const [alert, setAlert] = useState(props.data.alertText ? { view: true, text: props.data.alertText }: { view: false, text: '' })
    const [MONTH, setMonthCurrent] = useState(() => {
            if (props.data.target !== '' && props.data.target !== null && props.data.target !== undefined && props.data.target !== 'undefined') {
                let tmpStamp = Date.parse(props.data.target)
                // let tmpDiff = 3600000 * currentxTime
                // let tmpRes = tmpStamp + tmpDiff
                let res = new Date(tmpStamp)

                let tmpMonth = res.getMonth()
                return Number(tmpMonth)
            }
            let tmp = new Date()
            return  tmp.getMonth() 
        }
    )
    const [month, setMonth] = useState(() => {
        if (MONTH === undefined || MONTH === 'undefined') {
            let tmp = new Date()
            return tmp.getMonth()
        } else {
            if (MONTH !== '') {
                return MONTH
            } else {
                let tmp = new Date()
                return tmp.getMonth()
            }
        }
    }
    )
    const [YEAR, setYearCurrent] = useState(() => {
            if (props.data.target !== '' && props.data.target !== null && props.data.target !== undefined && props.data.target !== 'undefined') {
                let tmpStamp = Date.parse(props.data.target)
                // let tmpDiff = 3600000 * currentxTime
                // let tmpRes = tmpStamp + tmpDiff
                let res = new Date(tmpStamp)

                let tmpYear = res.getFullYear()
                return Number(tmpYear)
            }
            let tmp = new Date()
            return  tmp.getFullYear()
        }
    )
    const [year, setYear] = useState(() => {
        if (YEAR === undefined || YEAR === 'undefined') {
            let tmp = new Date()
            return tmp.getFullYear()
        } else {
            if (YEAR !== '') {
                return YEAR
            } else {
                let tmp = new Date()
                return tmp.getFullYear()
            }
        }
    }
    )
    const [monthSumDay, setMonthSumDay] = useState(null)
    const [calendarDay, setCalendarDay] = useState(() => {
        if (props.data.target !== '' && props.data.target !== null && props.data.target !== undefined && props.data.target !== 'undefined') {
            let tmpStamp = Date.parse(props.data.target)
            // let tmpDiff = 3600000 * currentxTime
            // let tmpRes = tmpStamp + tmpDiff
            let res = new Date(tmpStamp)

            let tmpDay = res.getDate()
            return Number(tmpDay)
        }
        let tmp = new Date()
        return  tmp.getDate()
    })
    const [calendarCurrentDay, setCalendarCurrentDay] = useState(()=>{
        let tmp = new Date()
        return  tmp.getDate()
    })
    const [time, setTime] = useState(() => {
            let date = new Date()
            if (props.data.time){
                let tmp = `${date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`
                if (props.data.target !== '' &&
                    props.data.target !== null &&
                    props.data.target !== undefined &&
                    props.data.target !== 'undefined') {
                    tmp = dateTransForm(props.data.target, true)
                    return tmp
                }
                return tmp
            }
        }
    )
    // const [calendarData, setCalendarData] = useState(props.data.target === '' || props.data.target === null || props.data.target === undefined || props.data.target === 'undefined' ? props.placeholder : props.data.target) 
    // const dayToday = new Date().getDate()
    const monthToday = new Date().getMonth()
    const yearToday = new Date().getFullYear()
    const [value, setValue] = useState(checkProp(props.data,'target') ? dateTransForm(props.data.target) : '')

    useEffect(() => { //TODO: после перехода на локальный стейт проверить и возможно убрать
        setValue(dateTransForm(props.data.target))
    }, [props.data.target])

    // useEffect(() => {
    //     if(alert.view) setComplete(styles.inPutAlert)
    // }, [alert])

    useEffect(() => {
        if(alert.view) setComplete(styles.inPutAlert)
        if(!!props.disabled) setComplete(styles.disabled)
        if(props.data.alert === true) setComplete(styles.inPutAlert)
        else setComplete(styles.inPutDate)
    }, [props.data.alert])

    function dateToggle() {
        if(!!props.disabled) {
            setComplete(`${styles.disabled}`)
            return
        }
        if (!datePopdown){
            setWrapper(styles.wrapper)
            setdatePopdown(!datePopdown)
            setComplete(props.data.alert ? styles.inPutAlert : `${styles.inPutDate} ${styles.focus}`)
        } else {
            setWrapper(styles.noDisplay)
            setdatePopdown(!datePopdown)
            setComplete(props.data.alert ? styles.inPutAlert : styles.inPutDate)
        }
    }

    function dateCancel() {
        setWrapper(styles.noDisplay)
        setdatePopdown(!datePopdown)
        setComplete(props.data.alert ? styles.inPutAlert : styles.inPutDate)
        setCalendarDay(calendarCurrentDay)
        setMonthCurrent(monthToday)
        setMonth(monthToday)
        setYearCurrent(yearToday)
        setYear(yearToday)
        //setValue('')
        setTime('')
        // setCalendarData('')
      }

    function dateTransForm(x , time) {
        if (x === undefined || x === '' || !x){
            if(props.placeholder !== null){
                return props.placeholder
            }else{
                return "Оберіть дату"
            }
        }
        let tmpStamp = Date.parse(x)
        let res = new Date(tmpStamp)

        let tmpYear = res.getFullYear()
        let tmpMonth = +res.getMonth() + 1
        tmpMonth = `${tmpMonth < 10 ? '0' + tmpMonth : tmpMonth}`
        let tmpDate = `${res.getDate() < 10 ? '0' + res.getDate() : res.getDate()}`
        let tmpTime = `${res.getHours()}:${res.getMinutes() < 10 ? '0' + res.getMinutes() : res.getMinutes()}`

        let resDate = `${tmpYear}-${tmpMonth}-${tmpDate}`

        // if (t === 1) return `${tmpYear}-${tmpMonth}-${tmpDate}`
        if (props.data.time) resDate = `${resDate} ${tmpTime}`
        if(time) return tmpTime
        return resDate
    }

    let tmpDateStr = ''
    let tmpDateDisp = ''

    if(value === '' || value === null){
        if(year !== null && month !== null && calendarDay !== null){
            let tmpStrMonth = ''
            let tmpStrDay = ''
            if(MONTH+1 < 10){
                tmpStrMonth = MONTH + 1
                tmpStrMonth = '0' + tmpStrMonth
            }else{
                tmpStrMonth = MONTH + 1
            }
            if(calendarDay < 10){
                tmpStrDay = '0' + calendarDay
            }else{
                tmpStrDay = calendarDay
            }
            if (props.data.time && time === '') {
                setTime('09:00')
            }

            tmpDateStr = props.data.time ? `${YEAR}-${tmpStrMonth}-${tmpStrDay} ${time}` : `${YEAR}-${tmpStrMonth}-${tmpStrDay}`
            tmpDateDisp = `${YEAR}-${tmpStrMonth}-${tmpStrDay}T${props.data.time ? time : '09:00'}:00.000Z`
        }
    }else{
            let tmpStrMonth = ''
            let tmpStrDay = ''
            // let tmpTime = time
            if(MONTH+1 < 10){
                //tmpStrMonth = month + 1
                tmpStrMonth = MONTH + 1
                tmpStrMonth = '0' + tmpStrMonth
            }else{
                tmpStrMonth = MONTH + 1
            }
            if(calendarDay < 10){
                tmpStrDay = '0' + calendarDay
            }else{
                tmpStrDay = calendarDay
            }
            if (props.data.time && time === '') {
                setTime('09:00')
            }
        
            tmpDateStr = props.data.time ? `${YEAR}-${tmpStrMonth}-${tmpStrDay} ${time}` : `${YEAR}-${tmpStrMonth}-${tmpStrDay}`
            tmpDateDisp = `${YEAR}-${tmpStrMonth}-${tmpStrDay}T${props.data.time ? time : '09:00'}:00.000Z`
    }

    function setDate(a) {
        // setCalendarData(tmpDateStr)
        dateToggle()
        // setComplete(`${styles.inPutDate} ${styles.complete}`)
        setValue(a)
    }

    function changeMonth(props, flag){
        if(flag === 1){
            let tmp = month + 1
            if(tmp === 12) {
                tmp = 0
                setYear(year + 1)
            }
            setMonth(tmp)
        }else{
            let tmp = month - 1
            if(tmp === -1) {
                tmp = 11
                setYear(year - 1)
            }
            setMonth(tmp)
        }
    }

    function createDay(){
        return (
            <div className={styles.titleCalendar}>
                <div className={styles.daysTitle}>ПН</div>
                <div className={styles.daysTitle}>ВТ</div>
                <div className={styles.daysTitle}>СР</div>
                <div className={styles.daysTitle}>ЧТ</div>
                <div className={styles.daysTitle}>ПТ</div>
                <div className={styles.daysTitle}>СБ</div>
                <div className={styles.daysTitle}>НД</div>
            </div>
        )
    }

    function setDayGrid(props){
        let tmpDate = []
        for (let i = 0; i < monthSumDay; i++){
            if (calendarDay === i + 1 && month === MONTH && year === YEAR) {
                tmpDate[i] = <div key={i}
                    className={styles.days + ' ' + styles.daysCurent}
                    onClick={
                        ()=>{
                            setCalendarDay(i+1)
                            setYearCurrent(year)
                            setMonthCurrent(month)
                        }
                    }
                >{i+1}</div>
            } else if (calendarCurrentDay === i+1 && month === monthToday && year === yearToday){
                tmpDate[i] = <div key={i}
                    className={styles.days + ' ' + styles.daysToday}
                    onClick={
                        ()=>{
                            setCalendarDay(i+1)
                            setYearCurrent(year)
                            setMonthCurrent(month)
                        }
                    }
                >{i+1}</div>
            }else{
                tmpDate[i] = <div key={i}
                    className={styles.days}
                    onClick={
                        ()=>{
                            setCalendarDay(i+1)
                            setYearCurrent(year)
                            setMonthCurrent(month)
                        }
                    }
                >{i+1}</div>
            }
        }
        let tmpDateFirst = new Date(year, month, 1).getDay()
        if(tmpDateFirst === 0) tmpDateFirst = 7
        let emptyBlock = []
        for(let i = 1; i < tmpDateFirst; i++){
            emptyBlock[i] = <div key={i} className={styles.days + ' ' + styles.inactive}></div>
        }

        if((tmpDate.length + emptyBlock.length) === 28){
            return (
                <div className={styles.calendarBlockVerySmall}>
                    {emptyBlock}
                    {tmpDate}
                </div>
            )
        }else if((tmpDate.length + emptyBlock.length) < 37){
            return (
                <div className={styles.calendarBlockSmall}>
                    {emptyBlock}
                    {tmpDate}
                </div>
            )
        }else{
            return (
                <div className={styles.calendarBlock}>
                    {emptyBlock}
                    {tmpDate}
                </div>
            )
        }
    }

    function isLeapYear (year) {
        return year % 4 === 0 && ( year % 100 !== 0 || year % 400 === 0 )
    }

    function viewTextMonth(props, data){
        switch(data){
            case 0:
                if(monthSumDay !== 31)
                setMonthSumDay(31)
                return "січень"
            case 1:
                if(isLeapYear(year) === true){
                    if(monthSumDay !== 29)
                setMonthSumDay(29)
                }else{
                    if(monthSumDay !== 28)
                setMonthSumDay(28)
                }
                return "лютий"
            case 2:
                if(monthSumDay !== 31)
                setMonthSumDay(31)
                return "березень"
            case 3:
                if(monthSumDay !== 30)
                setMonthSumDay(30)
                return "квітень"
            case 4:
                if(monthSumDay !== 31)
                setMonthSumDay(31)
                return "травень"
            case 5:
                if(monthSumDay !== 30)
                setMonthSumDay(30)
                return "червень"
            case 6:
                if(monthSumDay !== 31)
                setMonthSumDay(31)
                return "липень"
            case 7:
                if(monthSumDay !== 31)
                setMonthSumDay(31)
                return "серпень"
            case 8:
                if(monthSumDay !== 30)
                setMonthSumDay(30)
                return "вересень"
            case 9:
                if(monthSumDay !== 31)
                setMonthSumDay(31)
                return "жовтень"
            case 10:
                if(monthSumDay !== 30)
                setMonthSumDay(30)
                return "листопад"
            case 11:
                if(monthSumDay !== 31)
                setMonthSumDay(31)
                return "грудень"
        }
    }
    
    const timePicker = props.data.time ? ( <div className={styles.timePicker}>
        Час:
        <input type="time"
            value={
                time
            }
            onInput={e => setTime(e.target.value)}
            className={styles.hours} min="0" max="23" />
    </div>) : null
    
    function dateChecker(a, b, props) {
        let reg = ''
        if (a !== '') {
            if (props.data.time) { reg = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]$/ }
            else { reg = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/ }
            if (reg.test(a)) {
                setAlert({ view: false, text: '' })
                dateDispatcher(b, props)
            } else {
                setAlert({ view: true, text: 'Не вірно введена дата' })
            }
        }
        else { setAlert({ view: false, text: '' }) }
    }

    function dateDispatcher(a, props) {
        let tmpStamp = Date.parse(a)
        let tmpDiff = 3600000 * currentxTime
        let tmpRes = tmpStamp - tmpDiff
        let res = new Date(tmpRes).toISOString()
        props.onChange(res)

        //dispatch({ payload: res, type: props.data.targetType })
        //props.onChange(res)
    }

    function dateMatcher(a) {
        let len = a.length

        if (len === 0) { setComplete(styles.inPutDate)}
        if (len === 1) {
            if (!/[1-2]/.test(a[len-1])) {
                setComplete(styles.inPutAlert)
                setValue(a.substring(0, len - 1))
            } else { setComplete(styles.inPutDate)}
        }

        if (len === 2) {
            if (a[0] === 2) {
                if (!/[0]/.test(a[1])) {
                    setValue(a.substring(0, len - 1))
                    setComplete(styles.inPutAlert)
                }
            }
            if (a[0] === 1) {
                    if (!/[9]/.test(a[1])) {
                        setValue(a.substring(0, len - 1))
                        setComplete(styles.inPutAlert)
                    }
                } 
        } else { setComplete(styles.inPutDate) }

        if (len === 3) {
            if (!/[0-9]/.test(a[len - 1])) {
                setValue(a.substring(0, len - 1))
                setComplete(styles.inPutAlert)
            } else {setComplete(styles.inPutDate)}
        }

        if (len === 4) {
            if (!/[0-9]/.test(a[len - 1])) {
                setValue(a.substring(0, len - 1))
                setComplete(styles.inPutAlert)
            } else if (/[0-9]/.test(a[len - 1])){
                setValue(a + '-')
            }
        } else {setComplete(styles.inPutDate)}

        if (len === 5) {
            if (!/[-]/.test(a[len - 1])) {
                setValue(a.substring(0, len - 1))
                setComplete(styles.inPutAlert)
            } else {setComplete(styles.inPutDate)}
        }
        // month
        if (len === 6) {
            if (!/[0-1]/.test(a[len - 1])) {
                setValue(a.substring(0, len - 1))
                setComplete(styles.inPutAlert)
            } else {setComplete(styles.inPutDate)}
        }

        if (len === 7) {
            if (a[5] === 0) {
                if (!/[1-9]/.test(a[6])) {
                    setValue(a.substring(0, len - 1))
                    setComplete(styles.inPutAlert)
                } else if (/[1-9]/.test(a[6])) {setValue(a + '-')}
            } 
            if (a[5] === 1) {
                    if (!/[0-2]/.test(a[6])) {
                        setValue(a.substring(0, len - 1))
                        setComplete(styles.inPutAlert)
                    } else if (/[0-2]/.test(a[6])) {setValue(a + '-')}
                }
        } else {
            setComplete(styles.inPutDate)
        }
        // day
        if (len === 8) {
            if (!/[-]/.test(a[len - 1])) {
                setValue(a.substring(0, len - 1))
                setComplete(styles.inPutAlert)
            } else if (/[0-9]/.test(a[7])) {setValue(a + '-')} 
        } else {setComplete(styles.inPutDate)}

        if (len === 9) {
            if (!/[0-3]/.test(a[len - 1])) {
                setValue(a.substring(0, len - 1))
                setComplete(styles.inPutAlert)
            } else {setComplete(styles.inPutDate)}
        }

        if (len === 10) {
            if (a[8] === 0) {
                if (!/[1-9]/.test(a[9])) {
                    setValue(a.substring(0, len - 1))
                    setComplete(styles.inPutAlert)
                } else if (/[1-9]/.test(a[9]) && props.data.time) {setValue(a + ' ')}
            } 
            if (a[8] === 1) {
                    if (!/[0-9]/.test(a[9])) {
                        setValue(a.substring(0, len - 1))
                        setComplete(styles.inPutAlert)
                    } else if (/[0-9]/.test(a[9]) && props.data.time) {setValue(a + ' ')}
            }
            if (a[8] === 2) {
                    if (!/[0-9]/.test(a[9])) {
                        setValue(a.substring(0, len - 1))
                        setComplete(styles.inPutAlert)
                    } else if (/[0-9]/.test(a[9]) && props.data.time) {setValue(a + ' ')}
            }
            if (a[8] === 3) {
                    if (!/[0-1]/.test(a[9])) {
                        setValue(a.substring(0, len - 1))
                        setComplete(styles.inPutAlert)
                    } else if (/[0-1]/.test(a[9]) && props.data.time) {setValue(a + ' ')}
            }
        } else {
            setComplete(styles.inPutDate)
        }
        // hours
        if (len === 12) {
            if (!/[0-2]/.test(a[len - 1])) {
                setValue(a.substring(0, len - 1))
                setComplete(styles.inPutAlert)
            } else {setComplete(styles.inPutDate)}
        }

        if (len === 13) {
            if (a[11] === 0) {
                if (!/[0-9]/.test(a[12])) {
                    setValue(a.substring(0, len - 1))
                    setComplete(styles.inPutAlert)
                } else if (/[0-9]/.test(a[12]) && props.data.time) {setValue(a + ':')}
            } 
            if (a[11] === 1) {
                    if (!/[0-9]/.test(a[12])) {
                        setValue(a.substring(0, len - 1))
                        setComplete(styles.inPutAlert)
                    } else if (/[0-9]/.test(a[12]) && props.data.time) {setValue(a + ':')}
            }
            if (a[11] === 2) {
                    if (!/[0-3]/.test(a[12])) {
                        setValue(a.substring(0, len - 1))
                        setComplete(styles.inPutAlert)
                    } else if (/[0-3]/.test(a[12]) && props.data.time) {setValue(a + ':')}
            }
        } else {
            setComplete(styles.inPutDate)
        }
    // minutes
        if (len === 15) {
            if (!/[0-5]/.test(a[len - 1])) {
                setValue(a.substring(0, len - 1))
                setComplete(styles.inPutAlert)
            } else {setComplete(styles.inPutDate)}
        }

        if (len === 16) {
            if (!/[0-9]/.test(a[len - 1])) {
                setValue(a.substring(0, len - 1))
                setComplete(styles.inPutAlert)
            } else {setComplete(styles.inPutDate)}
        }
    }

    return (
        <>
            <div className={styles.inPutWrap}>
                <div className={complete}
                    onClick={dateToggle}
                    title='Натисніть для вводу дати'>
                    <div className={styles.beforeInput}
                        onClick={dateToggle}
                        title='Натисніть для вводу дати'
                    ></div>
                    <div className={styles.hiddenInput}>{value}</div>
                    {/* <input className={styles.hiddenInput}
                        value={value}
                        placeholder={props.data.time ? 'рррр-мм-дд гг:хх' : 'рррр-мм-дд'}
                        maxlength={props.data.time ? '16' : '10'}
                        // onChange={(e) => dispatch({ payload: e.target.value, type: props.data.targetType })}
                        onInput={e => setValue(e.target.value)}
                        onChange={e => dateMatcher(e.target.value)}
                        onFocus={() => {
                            // setPlaceHolder(placeholder) 
                            setComplete(props.alert ? styles.inPutAlert : `${styles.inPutDate} ${styles.focus}`)
                        }}
                        onBlur={() => {
                            dateChecker(value)
                            // setPlaceHolder(placeholder)
                            // setComplete(props.alert ? styles.inPutAlert : styles.inPutDate)
                            // dispatch({ payload: value, type: props.data.targetType })
                        }}
                        
                    /> */}
                    <div className={styles.afterInput}
                        onClick={dateToggle}
                        title='Натисніть для вводу дати'>
                        {alert.view ? alert.text : null}
                    </div>
                </div>
                        <div className={styles.label}>
                            {props.data.required ? `${props.data.label} *` : props.data.label}
                        </div>
                        <div className={wrapper} >
                            {/* date popdown */}
                            { datePopdown ? <div className={styles.datePopdown} >
                                <div className={styles.dateTitle}>{props.data.required ? `${props.data.label} *` : props.data.label}</div>
                                <div className={styles.topControls}>
                                    <div className={styles.fieldMonth}>
                                        <div className={styles.backWd}
                                        onClick={
                                            ()=>{changeMonth(props, 2)}
                                        }
                                        >
                                            <div className={styles.bwd}></div>
                                        </div>
                                        {viewTextMonth(props, month)}
                                        <div className={styles.forWd}
                                        onClick={
                                            ()=>{changeMonth(props, 1)}
                                        }>
                                            <div className={styles.fwd}></div>
                                        </div>
                                    </div>
                                    <div className={styles.fieldYear}>
                                        <div className={styles.backWd}
                                        onClick={
                                            ()=>{setYear(year - 1)}
                                        }
                                        >
                                            <div className={styles.bwd}></div>
                                        </div>
                                        {year}
                                        <div className={styles.forWd}
                                        onClick={
                                            ()=>{setYear(year + 1)}
                                        }
                                        >
                                            <div className={styles.fwd}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.caleendarWrap}>
                                    {createDay()}
                                    {setDayGrid(props)}
                                        {timePicker}
                                    <div className={styles.preview}>{tmpDateStr}</div>
                                    <div className={styles.bottomPart}>
                                        <div className={styles.greyBtn}
                                            onClick={() => {
                                                // dateCancel()
                                                dateToggle()
                                            }}
                                        >
                                            ВІДМІНИТИ
                                        </div>
                                        <div className={styles.blueBtn}
                                            onClick={() => {
                                                dateChecker(tmpDateStr, tmpDateDisp, props)
                                                setDate(tmpDateStr)
                                            }}
                                        >
                                            ПРИЙНЯТИ
                                        </div>
                                    </div>
                                </div>
                            </div> : null }
                    </div>
                {props.data.desc ? <div className={styles.descript}>{props.data.desc}</div> : null}
            </div>
        </>
    )
}

export default InputDate