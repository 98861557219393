import React from 'react';
import styles from './navTitleBar.module.css';

import {connect} from 'react-redux';

import {setDataAddress} from '../../../../../../redux/createAddress';

const NavTitleBar = (props) => {
    let tmpOgol = null
    let tmpZaput = null
    let tmpRezul = null
    if(props.createAuctionTab === 1){
        tmpOgol = styles.objNavItem
        tmpZaput = styles.activeNavItem
        tmpRezul = styles.objNavItem
    }else if(props.createAuctionTab === 2){
        tmpOgol = styles.objNavItem
        tmpZaput = styles.objNavItem
        tmpRezul = styles.activeNavItem
    }else{
        tmpOgol = styles.activeNavItem
        tmpZaput = styles.objNavItem
        tmpRezul = styles.objNavItem
    }

    return (
        <div className={styles.navBlock}>
            <div className={styles.objNav}>
                <div className={tmpOgol}
                    onClick = {
                        () => {
                            props.setDataAddress(0, 'CREATE_AUCTION_TAB')
                        }
                    }
                >
                    Аукціон
                </div>
                <div className={tmpZaput}
                    onClick = {
                        () => {
                            props.setDataAddress(1, 'CREATE_AUCTION_TAB')
                        }
                    }
                >
                    Склад лоту
                </div>
                <div className={tmpRezul}
                    onClick = {
                        () => {
                            props.setDataAddress(2, 'CREATE_AUCTION_TAB')
                        }
                    }
                >
                    Всі документи та пов'язані додатки
                </div>
            </div>
        </div>
    )
}

//props.changeCreateAuctionTab(props.count);
//CREATE_AUCTION_TAB

const mapStateToProps = state => {
    return {
        createAuctionTab: state.start.createAuctionTab,
    }
}

const mapDispatchToProps = {
    setDataAddress
}

export default connect(mapStateToProps, mapDispatchToProps)(NavTitleBar)