import React from 'react';
import styles from './windowContract.module.css';

import {connect} from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import {
    changeAuctionsSwitchWindow,
    auctionsGetMy,
    setPopUpAuctionMyNull,
} from '../../../redux/actions/auctions/auctions.js';
import {changeCreateAuctionTab} from '../../../redux/actions.js';

import {
    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType
} from '../../../redux/actions/documents/documents.js';

import ListDocuments from './itemsList/itemsList.js';
import ReturnButton from '../returnButton'

const WindowAuction = ( props ) => {
    let tmpText = null;
    if(props.createDocuments.id === null){
        tmpText = 'Створення нового документу';
    }else{
        tmpText = 'Редагування документу';
    }
    return (
        <div className={styles.documentWrap}>
            <div className={styles.flexBlock}>
                <div className={styles.flexBlockElem}>
                    {/*<div className={styles.headers}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={
                                () => {
                                    props.changeDocumentId(null)
                                    props.changeDocumentTitle('')
                                    props.changeDocumentDescription('')
                                    props.changeDocumentLang('uk')
                                }
                            }
                        >
                            Створити новий документ
                        </Button>
                        </div>*/}
                    <ListDocuments />
                    {/*createViewListDocuments(props)*/}
                </div>
                {/*<div className={styles.flexBlockElem}>
                    <div className={styles.headers}>{tmpText}</div>
                    {createView(props)}
                    </div>*/}
            </div>
            {/*<Button
                className={styles.buttonExitDocument}
                variant="contained"
                color="primary"
                onClick={
                    () => {
                        props.setPopUpAuctionMyNull()
                        props.changeCreateAuctionTab(0)
                        props.changeAuctionsSwitchWindow(0)
                    }
                }
            >
                Повернутися до списку аукціонів
            </Button>*/}
            <ReturnButton />
        </div>
    )
}

function buttonCreateImg(props){
    if(props.createDocuments.id === null){
        let fileElem = null;
        if(props.createDocuments.fileName === null){
            fileElem = <span className={styles.input__file_button_text}>
                <span>Додати документ</span>
            </span>
        }else{
            fileElem = <span className={styles.input__file_button_text}>
                <span>Доданий документ:</span> <span>{props.createDocuments.fileName}</span>
            </span>
        }
        return (
            <div className={styles.formLine}>
                <form 
                    onSubmit={(e) => {
                        handleSubmit(e, props)
                    }}
                    id="addFileForm"
                >
                    <div className={styles.input__wrapper}>
                        <input
                            name="file"
                            type="file"
                            id="input__file"
                            className={styles.input + ' ' + styles.input__file}
                            onChange={() => {
                                let addFileInp1 = document.getElementById('input__file');
                                props.changeDocumentFileName(addFileInp1.files[0].name)
                            }}
                        />
                        <label for="input__file" className={styles.input__file_button}>
                            {fileElem}
                        </label>
                    </div>
                    <div className={styles.lineButtons}>
                        <div className={styles.editButton}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Зберегти
                            </Button>
                        </div>
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={
                                    () => {
                                        props.changeDocumentId(null)
                                        props.changeDocumentTitle('')
                                        props.changeDocumentDescription('')
                                        props.changeDocumentLang('uk')
                                    }
                                }
                            >
                                Закрити
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }else{
        return (
            <div className={styles.lineButtons}>
                <div className={styles.editButton}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                promisSaveEditDocuments(props)
                            }
                        }
                    >
                        Редагувати
                    </Button>
                </div>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                props.changeDocumentId(null)
                                props.changeDocumentTitle('')
                                props.changeDocumentDescription('')
                                props.changeDocumentLang('uk')
                            }
                        }
                    >
                        Закрити
                    </Button>
                </div>
            </div>
        )
    }
}

async function promisSaveEditDocuments(props){
    let tmpDisc = null
    if(props.createDocuments.description !== null && props.createDocuments.description !== ''){
        tmpDisc = props.createDocuments.description
    }
    const endPoint33 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/${props.createDocuments.id}`;
    const response33 = await fetch(endPoint33, {
        method: "PUT",
        body: JSON.stringify({
            title: props.createDocuments.title,
            description: tmpDisc,
            //documentType: 'illustration',   //не меняем
            language: props.createDocuments.language,
            //documentOf: 'auction',          //не меняем
            //hash: "string",
            //token: json.jwt                 //не меняем
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response33 === undefined || response33 === 'undefined'){
    }else{
        let data = {
            domen: process.env.REACT_APP_END_POINT_BD,
            version: 'v1',
            local: props.langFlag,
            params: '?limit=100'
        }
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        props.auctionsGetMy(data, header)
    }
}

function handleSubmit(e, props){
    e.preventDefault();
    promisUploadFiles(props)
}

async function promisUploadFiles(props){
    const addFileInp = document.getElementById('input__file');
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload`;
    const formData = new FormData();
    formData.append('documentType', props.createDocuments.documentType)
    formData.append('file', addFileInp.files[0])
    const response =  await fetch(endPoint, {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': props.token.access_token,
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        let tmpDisc = null
        if(props.createDocuments.description !== null && props.createDocuments.description !== ''){
            tmpDisc = props.createDocuments.description
        }
        const json = await response.json()
        const endPoint1 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/document`;
        const response1 = await fetch(endPoint1, {
            method: "POST",
            body: JSON.stringify({
                title: props.createDocuments.title,
                description: tmpDisc,
                documentType: props.createDocuments.documentType,
                language: props.createDocuments.language,              //не меняем
                documentOf: 'auction',          //не меняем
                //hash: "string",
                token: json.jwt                 //не меняем
            }),
            headers: {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
        }).catch(console.error)
        if(response1 === undefined || response1 === 'undefined'){
        }else{
            const json1 = await response1.json()
            const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}`;
            const response2 = await fetch(endPoint2, {
                method: "PUT",
                body: JSON.stringify({
                    documentIds: [...takeIdDocuments(props), json1.id]
                }),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response2 === undefined || response2 === 'undefined'){
            }else{
                let data = {
                    domen: process.env.REACT_APP_END_POINT_BD,
                    version: 'v1',
                    local: props.langFlag,
                    params: '?limit=100'
                }
                let header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                props.auctionsGetMy(data, header)
            }
        }
    }
}

async function  deleteDocument(props, id){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/${id}`;
    const response1 = await fetch(endPoint, {
        method: "DELETE",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
    }else{
        let data = {
            domen: process.env.REACT_APP_END_POINT_BD,
            version: 'v1',
            local: props.langFlag,
            params: '?limit=100'
        }
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        props.auctionsGetMy(data, header)
    }
}

function takeAuction(props){
    let tmp = null;
    if(props.auctionsMy !== null){
        tmp = props.auctionsMy
        .filter(
            (i) => {
                return i.id === props.createAuction.id
            }
        )
        .filter(
            (i) => {
                return i.items.length > 0
            }
        )
    }
    return tmp
}

function takeIdDocuments(props){
    let tmp = null;
    if(props.auctionsMy !== null){
        tmp = props.auctionsMy
    }
    let tmpItems = [];
    if(tmp !== null){
        for(let i = 0; i < tmp.documents.length; i++){
            tmpItems[i] = tmp.documents[i].id
        }
    }
    return tmpItems
}

function createViewListDocuments(props){
    let tmp = null;
    if(props.auctionsMy !== null){
        tmp = props.auctionsMy
    }
    let tmpItems = null;
    if(tmp !== null){
            tmpItems = tmp.documents.map(
                (i) => {
                    return (
                        <div key={i.id} className={styles.auctionsLine}>
                            <div className={styles.auctionsLineElem}>
                                <div className={styles.documentsId}>Id документу: {i.id}</div>
                                <div>Назва документу: {i.title}</div>
                            </div>
                            <div className={styles.auctionsLineElem}>
                                <div
                                    onClick={
                                        () => {
                                            props.changeDocumentId(i.id)
                                            props.changeDocumentTitle(i.title)
                                            props.changeDocumentDescription(i.description)
                                            props.changeDocumentLang(i.language)
                                            props.changeDocumentType(i.documentType)
                                        }
                                    }
                                >
                                    <IconButton aria-label="edit">
                                        <EditIcon />
                                    </IconButton>
                                </div>
                                <div
                                    onClick={
                                        ()=>{
                                            deleteDocument(props, i.id)
                                            props.changeAuctionsSwitchWindow(0)
                                        }
                                    }
                                >
                                    <IconButton aria-label="delete">
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    )
                }
            )
    }
    return tmpItems
}

function createView(props){
    let tmp = null;
    tmp = (
        <div className={styles.documentsForm}>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        required
                        id="filled-required"
                        className={styles.inputText}
                        label="Заголовок"
                        variant="outlined"
                        value={props.createDocuments.title}
                        onChange={
                            (e) => {
                                props.changeDocumentTitle(e.target.value)
                            }
                        }
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        required
                        id="filled-required"
                        className={styles.inputText}
                        label="Описание"
                        variant="outlined"
                        value={props.createDocuments.description}
                        onChange={
                            (e) => {
                                props.changeDocumentDescription(e.target.value)
                            }
                        }
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        id="outlined-select-currency-native"
                        className={styles.selectWidth}
                        select
                        label="Тип документа"
                        value={props.createDocuments.documentType}
                        onChange={(event)=>{
                            props.changeDocumentType(event.target.value)
                        }}
                        helperText="Выберите тип документа"
                        variant="outlined"
                    >
                        <option value='technicalSpecifications '>Технічні специфікації</option>
                        <option value='illustration'>Ілюстрації</option>
                        <option value='notice'>Паспорт торгів</option>
                        <option value='evaluationCriteria'>Кваліфікаційні вимоги</option>
                        <option value='x_presentation'>Презентація</option>
                        <option value='digitalSignature'>Цифровий підпис</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        id="outlined-select-currency-native"
                        className={styles.selectWidth}
                        select
                        label="Язык документа"
                        value={props.createDocuments.language}
                        onChange={(event)=>{
                            props.changeDocumentLang(event.target.value)
                        }}
                        helperText="Выберите язык документа"
                        variant="outlined"
                    >
                        <option value='uk'>український</option>
                        <option value='en'>англійський</option>
                        <option value='ru'>російський</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                {buttonCreateImg(props)}
            </div>
        </div>
    )
    return tmp
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        auctionsMy: state.start.auctionsMy,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        createDocuments: state.start.createDocuments,
    }
}

const mapDispatchToProps = {
    changeAuctionsSwitchWindow,
    auctionsGetMy,
    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType,
    changeCreateAuctionTab,
    setPopUpAuctionMyNull,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuction)
