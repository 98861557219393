import React from 'react';
import styles from './informationAboutTheOrganizer.module.css';

import {connect} from 'react-redux';

import TextHalf from '../../elements/viewData/textHalf'

import {modifiDateString} from '../../../redux/modifiDate'

import ContactPoints from '../../contactPoint/contactPoint'

const DebtorInformation = (props) => {
    if(props.auctionId !== null){
        if(
            props.auctionId.sellingMethod === "bankRuptcy-withoutAuction"
        ){
            return (
                <div className={styles.infoBlock}>
                    <h3>Інформація про Боржника</h3>
                    <div className={styles.orgTitle}>
                        <p className={styles.desc}>Найменування боржника</p>
                        <p>{contactName(props)}</p>
                    </div>
                    <div className={styles.orgContact1}>
                        <p className={styles.desc}>Контактні дані</p>
                        {contactPoint(props)}
                    </div>
                    <div className={styles.orgId}>
                        <p className={styles.desc}>Код ЄДРПОУ або ІПН або паспорт</p>
                        <p>{contactEDRPOU(props)}</p>
                    </div>
                    <div className={styles.orgAdress}>
                        <p className={styles.desc}>Юридична адреса</p>
                        <p>{contactAddress(props)}</p>
                    </div>
                    {contactKOATUU(props)}
                    {currentContractTime(props)}
                </div>
            )
        }
    }
    return null
}

function contactKOATUU(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('debtorInformation')){
            if(props.auctionId.debtorInformation !== null){
                if(props.auctionId.debtorInformation.hasOwnProperty('address')){
                    if(props.auctionId.debtorInformation.address !== null){
                        if(props.auctionId.debtorInformation.address.hasOwnProperty('addressID')){
                            if(props.auctionId.debtorInformation.address.addressID !== null){
                                if(props.auctionId.debtorInformation.address.addressID.hasOwnProperty('id')){
                                    if(props.auctionId.debtorInformation.address.addressID.id !== null){
                                        return (
                                            <div className={styles.orgAdress}>
                                                <p className={styles.desc}>КОАТУУ</p>
                                                <p>{props.auctionId.debtorInformation.address.addressID.id}</p>
                                            </div>
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return null
}

function contactName(props){
    let tmp = ''
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('debtorInformation')){
            if(props.auctionId.debtorInformation !== null){
                if(props.auctionId.debtorInformation !== null){
                    if(props.auctionId.debtorInformation.hasOwnProperty('name')){
                        if(props.auctionId.debtorInformation.name !== null){
                            tmp += props.auctionId.debtorInformation.name.uk_UA
                        }
                    }
                }
            }
        }
    }
    return tmp
}

function currentContractTime(props){
    let tmp = ''
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('debtorInformation')){
            if(props.auctionId.debtorInformation !== null){
                if(props.auctionId.debtorInformation !== null){
                    if(props.auctionId.debtorInformation.hasOwnProperty('currentContractTime')){
                        if(props.auctionId.debtorInformation.currentContractTime !== null){
                            if(
                                props.auctionId.debtorInformation.currentContractTime.dateFrom !== null &&
                                props.auctionId.debtorInformation.currentContractTime.dateTill !== null
                            ){
                                return (
                                    <>
                                        <div className={styles.orgAdress}>
                                            <p className={styles.desc}>Дата укладення та закінчення договору оренди</p>
                                            <p></p>
                                        </div>
                                        <div className={styles.orgId}>
                                            <p className={styles.desc}>Дата та час початку</p>
                                            <p>{modifiDateString(props.auctionId.debtorInformation.currentContractTime.dateFrom, 3)}</p>
                                        </div>
                                        <div className={styles.orgId}>
                                            <p className={styles.desc}>Дата та час закінчення</p>
                                            <p>{modifiDateString(props.auctionId.debtorInformation.currentContractTime.dateTill, 3)}</p>
                                        </div>
                                    </>
                                )
                            }
                        }
                    }
                }
            }
        }
    }
    return tmp
}

function contactPoint(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('debtorInformation')){
            if(props.auctionId.debtorInformation !== null){
                if(props.auctionId.debtorInformation.hasOwnProperty('contactPoint')){
                    if(props.auctionId.debtorInformation.contactPoint !== null){
                        return <ContactPoints data={props.auctionId.debtorInformation.contactPoint} />
                    }
                }
            }
        }
    }
    return null
}

// function contactPoint(props, flag){
//     let tmp = ''
//     if(props.auctionId !== null){
//         if(props.auctionId.hasOwnProperty('debtorInformation')){
//             if(props.auctionId.debtorInformation !== null){
//                 if(props.auctionId.debtorInformation !== null){
//                     if(props.auctionId.debtorInformation.hasOwnProperty('contactPoint')){
//                         if(props.auctionId.debtorInformation.contactPoint !== null){
//                             if(flag === 1){
//                                 if(props.auctionId.debtorInformation.contactPoint.hasOwnProperty('name')){
//                                     if(props.auctionId.debtorInformation.contactPoint.name !== null){
//                                         tmp += props.auctionId.debtorInformation.contactPoint.name.uk_UA + ', '
//                                     }
//                                 }
//                             }
//                             if(flag === 2){
//                                 if(props.auctionId.debtorInformation.contactPoint.hasOwnProperty('telephone')){
//                                     if(props.auctionId.debtorInformation.contactPoint.telephone !== null){
//                                         tmp += props.auctionId.debtorInformation.contactPoint.telephone + ', '
//                                     }
//                                 }
//                                 if(props.auctionId.debtorInformation.contactPoint.hasOwnProperty('email')){
//                                     if(props.auctionId.debtorInformation.contactPoint.email !== null){
//                                         tmp += props.auctionId.debtorInformation.contactPoint.email
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
//     return tmp
// }
// function contactPointName(props){
//     let tmp = ''
//     if(props.auctionId !== null){
//         if(props.auctionId.hasOwnProperty('debtorInformation')){
//             if(props.auctionId.debtorInformation !== null){
//                 if(props.auctionId.debtorInformation.hasOwnProperty('contactPoint')){
//                     if(props.auctionId.debtorInformation.contactPoint !== null){
//                         if(props.auctionId.debtorInformation.contactPoint.hasOwnProperty('name')){
//                             if(props.auctionId.debtorInformation.contactPoint.name !== null){
//                                 tmp = props.auctionId.debtorInformation.contactPoint.name.uk_UA
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
//     return tmp
// }
// function contactPointEmail(props){
//     let tmp = ''
//     if(props.auctionId !== null){
//         if(props.auctionId.hasOwnProperty('debtorInformation')){
//             if(props.auctionId.debtorInformation !== null){
//                 if(props.auctionId.debtorInformation.hasOwnProperty('contactPoint')){
//                     if(props.auctionId.debtorInformation.contactPoint !== null){
//                         if(props.auctionId.debtorInformation.contactPoint.hasOwnProperty('email')){
//                             if(props.auctionId.debtorInformation.contactPoint.email !== null){
//                                 tmp = props.auctionId.debtorInformation.contactPoint.email
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
//     return tmp
// }
// function contactPointTelephone(props){
//     let tmp = ''
//     if(props.auctionId !== null){
//         if(props.auctionId.hasOwnProperty('debtorInformation')){
//             if(props.auctionId.debtorInformation !== null){
//                 if(props.auctionId.debtorInformation.hasOwnProperty('contactPoint')){
//                     if(props.auctionId.debtorInformation.contactPoint !== null){
//                         if(props.auctionId.debtorInformation.contactPoint.hasOwnProperty('telephone')){
//                             if(props.auctionId.debtorInformation.contactPoint.telephone !== null){
//                                 tmp = props.auctionId.debtorInformation.contactPoint.telephone
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
//     return tmp
// }
// function contactPointFaxNumber(props){
//     let tmp = ''
//     if(props.auctionId !== null){
//         if(props.auctionId.hasOwnProperty('debtorInformation')){
//             if(props.auctionId.debtorInformation !== null){
//                 if(props.auctionId.debtorInformation.hasOwnProperty('contactPoint')){
//                     if(props.auctionId.debtorInformation.contactPoint !== null){
//                         if(props.auctionId.debtorInformation.contactPoint.hasOwnProperty('faxNumber')){
//                             if(props.auctionId.debtorInformation.contactPoint.faxNumber !== null){
//                                 tmp = props.auctionId.debtorInformation.contactPoint.faxNumber
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
//     return tmp
// }
// function contactPointUrl(props){
//     let tmp = ''
//     if(props.auctionId !== null){
//         if(props.auctionId.hasOwnProperty('debtorInformation')){
//             if(props.auctionId.debtorInformation !== null){
//                 if(props.auctionId.debtorInformation.hasOwnProperty('contactPoint')){
//                     if(props.auctionId.debtorInformation.contactPoint !== null){
//                         if(props.auctionId.debtorInformation.contactPoint.hasOwnProperty('url')){
//                             if(props.auctionId.debtorInformation.contactPoint.url !== null){
//                                 tmp = props.auctionId.debtorInformation.contactPoint.url
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
//     return tmp
// }

function contactEDRPOU(props){
    let tmp = ''
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('debtorInformation')){
            if(props.auctionId.debtorInformation !== null){
                if(props.auctionId.debtorInformation !== null){
                    if(props.auctionId.debtorInformation.hasOwnProperty('identifier')){
                        if(props.auctionId.debtorInformation.identifier !== null){
                            if(props.auctionId.debtorInformation.hasOwnProperty('identifier')){
                                if(props.auctionId.debtorInformation.identifier.id !== null){
                                    tmp += props.auctionId.debtorInformation.identifier.id
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return tmp
}

function contactAddress(props){
    let tmp = ''
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('debtorInformation')){
            if(props.auctionId.debtorInformation !== null){
                if(props.auctionId.debtorInformation !== null){
                    if(props.auctionId.debtorInformation.hasOwnProperty('address')){
                        if(props.auctionId.debtorInformation.address !== null){
                            if(props.auctionId.debtorInformation.address.hasOwnProperty('postalCode')){
                                if(props.auctionId.debtorInformation.address.postalCode !== null){
                                    tmp += props.auctionId.debtorInformation.address.postalCode + ', '
                                }
                            }
                            if(props.auctionId.debtorInformation.address.hasOwnProperty('countryName')){
                                if(props.auctionId.debtorInformation.address.countryName !== null){
                                    tmp += props.auctionId.debtorInformation.address.countryName.uk_UA + ', '
                                }
                            }
                            if(props.auctionId.debtorInformation.address.hasOwnProperty('region')){
                                if(props.auctionId.debtorInformation.address.region !== null){
                                    tmp += props.auctionId.debtorInformation.address.region.uk_UA + ', '
                                }
                            }
                            if(props.auctionId.debtorInformation.address.hasOwnProperty('locality')){
                                if(props.auctionId.debtorInformation.address.locality !== null){
                                    tmp += props.auctionId.debtorInformation.address.locality.uk_UA + ', '
                                }
                            }
                            if(props.auctionId.debtorInformation.address.hasOwnProperty('streetAddress')){
                                if(props.auctionId.debtorInformation.address.streetAddress !== null){
                                    tmp += props.auctionId.debtorInformation.address.streetAddress.uk_UA
                                }
                            }                                    
                        }
                    }
                }
            }
        }
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
        userProfiles: state.start.userProfiles
    }
}

export default connect(mapStateToProps, null)(DebtorInformation)
