import React from 'react';
import styles from './imageAndButtonLine_TextBlock.module.css';

/*import {
    SUBMIT_OFFERS_TILL_LOTS_LANG,
    SUBMIT_OFFERS_LOTS_LANG,
    AUCTION_START_DATE_LOTS_LANG,
} from '../../../../../../../model/lang/catalogLots.js';*/

import {connect} from 'react-redux';

import {modifiDateString} from '../../../../../../../redux/modifiDate'

const SUBMIT_OFFERS_TILL_LOTS_LANG = ['до', 'till'];
const SUBMIT_OFFERS_LOTS_LANG = ['Подання пропозицій', 'Submit offers'];
const AUCTION_START_DATE_LOTS_LANG = ['Дата проведення аукціону', 'Auction start date'];

const ImageAndButtonLine_TextBlock = ( props ) => {
    return (
        <div>
            <div className={styles.identif}>
                <div className={styles.secondBlock_textPrice}>{SUBMIT_OFFERS_LOTS_LANG[props.langFlag]}</div>
                <div className={styles.secondBlock_textPrice + ' ' + styles.textEnd}>
                    <span>{SUBMIT_OFFERS_TILL_LOTS_LANG[props.langFlag]} {viewDate(props.item.tenderEnquiryPeriodEndDate)}</span>
                </div>
            </div>
            <div className={styles.identif}>
                <div className={styles.secondBlock_textPrice}>{AUCTION_START_DATE_LOTS_LANG[props.langFlag]}</div>
                <div className={styles.secondBlock_textPrice + ' ' + styles.textEnd}>
                    <span>{SUBMIT_OFFERS_TILL_LOTS_LANG[props.langFlag]} {viewDate(props.item.tenderTenderPeriodEndDate)}</span>
                </div>
            </div>
        </div>
    )
}

/**
 * Получаем массив периодов и отдаем для вывода дату
 * @param {*} props 
 * @param {*} type //тип даты
 */
function viewDate(type){
    let tmp = null;
    if(type !== null){
        tmp = modifiDateString(type, 3, false)
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
    }
}

export default connect(mapStateToProps, null)(ImageAndButtonLine_TextBlock)