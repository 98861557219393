import React, { useEffect, useState, useRef } from 'react';
import styles from './docTypeSelect.module.css';
import { VolumeDown } from '@material-ui/icons';


// Typical array for options in select
//  { id: '0', name: 'Обрати', style: '', selected: false, disabled: false }

const DocTypeSelect = (props) => {
    const [selectPopDown, setSelectPopDown] = useState(styles.noDisplay)
    const [option, setOption] = useState('')
    const [complete, setComplete] = useState(`${styles.inPutSelect}`)
    const [wrapper, setWrapper] = useState(styles.noDisplay)
    const [value, setValue] = useState(() => {
        if (props.data.target !== undefined && props.data.target !== null)  {
            return props.data.target
        } else { return ''}
    })
    const selectRef = useRef()

    useEffect(() => {
        if (props.data !== null && props.data.target !== null) {
            let tmpList = props.data.docList
            for (let key in tmpList) {
                if (tmpList[key].value === props.data.target) {
                    setOption(tmpList[key].name)
                }
            }
        } else {
            setOption('')
        }
    }, [])

    useEffect(() => {
        let position = selectRef.current.getBoundingClientRect()
        if (selectRef.current.getBoundingClientRect().bottom > window.innerHeight) {
            selectRef.current.style.height = `${window.innerHeight - position.top - 20}px`
        }
    }, [selectPopDown])

    function selectToggle() {
        if (selectPopDown === styles.noDisplay){
            setWrapper(styles.wrapper)
            setSelectPopDown(styles.selectPopdown)
            setComplete(`${styles.inPutSelect} ${styles.focus}`)
        } else {
            setWrapper(styles.noDisplay)
            setSelectPopDown(styles.noDisplay)
            setComplete(`${styles.inPutSelect}`)
        }
    }

    function selection(e){
            setOption(e.name)
            selectToggle()
            setComplete(`${styles.inPutSelect} ${styles.complete}`)
            setValue(e.value)
            props.data.action(e.value)
        
    }

    function listItem(item) {
        if (item.value === value) {
            return <div className={`${styles.somePart} ${styles.active}`}
                            onClick={() => selection(item)}
                            value={item.value}
                            key={item.value}>
                            {item.name}
                        </div>
        }
        return <div className={styles.somePart}
                            onClick={() => selection(item)}
                            value={item.value}
                            key={item.value}>
                            {item.name}
                        </div>
    }

    function setList(list) {
        if (list !== null){
            let main = list.filter(item => { return item.main === true })
            let noMain = list.filter(item => { return item.main === false })
            let defaultDocs = list.filter(item => { return item.main === null || item.main === undefined })
            return <>
                { main.length > 0 ?
                    <div className={styles.sectionWrap}>
                        <div className={styles.mainDocsTitle}>Обов'язкові документи:</div>
                        <div className={styles.mainDocs}>{main.map(item => listItem(item))}</div>
                    </div> : null
                }
                { main.length > 0 && noMain.length > 0 ?
                    <div className={styles.sectionWrap}>
                        <div className={styles.noMainDocsTitle}>Не обов'язкові документи:</div>
                        <div className={styles.noMainDocs}>{noMain.map(item => listItem(item))}</div>
                    </div> : null
                }
                { defaultDocs.length > 0 ?
                    <div className={styles.sectionWrap}>
                            {defaultDocs.map(item => listItem(item))}
                    </div> : null
                }
                { main.length === 0 && noMain.length > 0 ?
                    <div className={styles.sectionWrap}>
                            {noMain.map(item => listItem(item))}
                    </div> : null
                }
                </>
        }
    }

    return (
        <>
            <div className={styles.inPutWrap}>
                <div className={props.data.disabled ? styles.inActive : complete}
                    onClick={props.data.disabled ? null : selectToggle}
                    title={option}
                >
                <div className={styles.textValue}>{option}</div>
                </div>
                {props.data.label ? 
                    <div className={styles.label}>
                    {props.data.necessarily ? `${props.data.label} *` : props.data.label}
                    </div>
                : '' }
                <div className={wrapper} onClick={selectToggle}></div>
                <div className={selectPopDown} ref={selectRef}>
                    {setList(props.data.docList)}
                </div>
            </div>
        </>
    )
}

export default DocTypeSelect