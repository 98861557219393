//AUCTIONS

//GET, POST, PATCH, DELETE
export const toAuction = (obj) => `${process.env.REACT_APP_END_POINT_BD}/api/${obj.version}/${obj.language}/${obj.routType}/${obj.auctionType}`
//GET, PATCH, DELETE
export const toAuctionDraftId = ( auctionDraftId ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-draft/${auctionDraftId}`
//GET, PATCH, DELETE
export const toPublicAuctionId = ( auctionId ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/auction/${auctionId}`
//GET, PATCH, DELETE
export const toPrivateAuctionId = ( auctionId ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${auctionId}`
//POST
export const toPrivateAuctionIdComplete = ( auctionId ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${auctionId}/complete`
//GET
export const toAuctionDraftCopy = ( auctionDraftId ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-draft/${auctionDraftId}/copy`

//AUCTIONS ITEMS

//AUCTIONS DOCUMENTS