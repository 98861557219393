import React from 'react';
import styles from './itemsList.module.css';

import {connect} from 'react-redux';
import {
    changeCreateItemAuctionId,
    changeCreateItemClassificationId,
    changeCreateItemUnitValueCurrency,
    changeCreateItemUnitValueAmount,
    changeCreateItemUnitValueTax,
    changeCreateItemDescription,
    changeAdditionalClassificationIds,
    changeCreateItemQuantity,
    changeCreateItemIds,
    changeLoader,
    getMyAuction,
    changeCreateItemLocationLatitude,
    changeCreateItemLocationLongitude,
    changeCreateItemLocationElevation,
    changeCreateItemAddress,
    changeCreateItemDgfJointPropertyComplexPropsId,
    changeCreateItemDgfJointPropertyComplexPropsQuantityAndNomenclature,
    changeCreateItemDgfJointPropertyComplexPropsWorkplacesQuantity,
    changeCreateItemDgfJointPropertyComplexPropsBuildingsInformation,
    changeCreateItemDgfJointPropertyComplexPropsLandPlotInformation,
    changeCreateItemDgfJointPropertyComplexPropsMainObligations,
    changeCreateItemDgfLandPropsId,
    changeCreateItemDgfLandPropsLandArea,
    changeCreateItemDgfLandPropsCadastralNumber,
    changeCreateItemDgfLandPropsOwnershipType,
    changeCreateItemDgfLandPropsEncumbrances,
    changeCreateItemDgfLandPropsJointOwnership,
    changeCreateItemDgfLandPropsUtilitiesAvailability,
    changeCreateItemDgfLandPropsIntendedUse,
    changeCreateItemDgfVehiclePropsId,
    changeCreateItemDgfVehiclePropsBrand,
    changeCreateItemDgfVehiclePropsModel,
    changeCreateItemDgfVehiclePropsProductionYear,
    changeCreateItemDgfVehiclePropsEngineCapacity,
    changeCreateItemDgfVehiclePropsFuelType,
    changeCreateItemDgfVehiclePropsTransmission,
    changeCreateItemDgfVehiclePropsColor,
    changeCreateItemDgfVehiclePropsKilometrage,
    changeCreateItemDgfVehiclePropsIdentificationNumber,
    changeCreateItemDgfVehiclePropsConfiguration,
    changeCreateItemDgfVehiclePropsCondition,
    changeCreateItemDgfVehiclePropsDamagePresence,
    changeCreateItemDgfVehiclePropsDamagedDescription,
} from '../../../../../redux/actions.js';
import {auctionsGetMy,
    auctionsGetMyId,
    changeAuctionsItemsSwitchWindow,
    changeAuctionsSwitchWindow,
    auctionsGetClassificationsByTypeEnergy,
    auctionsGetClassificationsByGenerationType,
    changeTimberSortment,
    changeTimberSpecies,
    changeTimberClass,
    changeTimberDiameter,
    changeTimberLength,
    changeTimberStorage,
    changeTimberProductionYear,
    changeTimberProductionQuarter,
    changeCreateItemUnitCode,
    changeCreateItemGenerationType,
    auctionsGetClassificationsByTypeDGFAdd,
    seDgfMask,
} from '../../../../../redux/actions/auctions/auctions.js';

import {
    setDataAddress,
} from '../../../../../redux/createAddress';

import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

const ItemsList = ( props ) => {
    let tmp = null;
    /*if(props.auctionsList !== null){
        if(props.auctionsList.length > 0){
            tmp = props.auctionsList
            .filter(
                (i) => {
                    return i.id === props.auctionsMy.id
                }
            )
        }
    }*/

    let tmpArr = null;
    //if(tmp !== null){
    if(props.auctionsMy !== null){
        //if(tmp.length > 0){
            //tmpArr = tmp[0].items.map(
            tmpArr = props.auctionsMy.items.map(
                (i) => {
                    return (
                        <div key={i.id} className={styles.auctionsLine}>
                            <div className={styles.auctionsLineElem}>
                                <div className={styles.itemsId}>Id лота: {i.id}</div>
                                <div>Опис лота: {i.description}</div>
                            </div>
                            <div className={styles.auctionsLineElem}>
                                <div
                                    onClick={
                                        () => {
                                            props.changeAuctionsItemsSwitchWindow(2);
                                            getAdditionalClassificationZU(props)
                                            //concatDataClassification(props)
                                            
                                            
                                            props.changeCreateItemAuctionId(props.auctionsMy.id)
                                            props.setDataAddress(i.id, 'CREATE_ITEMS_LAND_RENTAL_ID')
                                            props.setDataAddress(i.description, 'CREATE_ITEMS_LAND_RENTAL_DESCRIPTION')
                                            if(i.classification !== null){
                                                props.setDataAddress(i.classification.id, 'CREATE_ITEMS_LAND_RENTAL_CLASSIFICATION')
                                            }
                                            if(i.additionalClassifications !== null){
                                                if(i.additionalClassifications.length > 0){
                                                    let tmpAddC = JSON.stringify({scheme: 'kvtspz', classificationId: i.additionalClassifications[0].classificationId})
                                                    props.setDataAddress(tmpAddC, 'CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATION')
                                                    loadMainClassif(props, tmpAddC)
                                                    let tmpCVZU = i.additionalClassifications
                                                    .filter(
                                                        (i) => {
                                                            return i.scheme === 'CVZU'
                                                        }
                                                    )
                                                    .map((i) => {
                                                        return i
                                                    })
                                                    props.setDataAddress(tmpCVZU, 'CREATE_CVZU_ARR')

                                                }
                                            }
                                            if(i.address !== null){
                                                props.setDataAddress(i.address.id, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS')
                                                
                                                props.setDataAddress(i.address.id, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_ID')
                                                props.setDataAddress(i.address.countryName, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_COUNTRY_NAME')
                                                props.setDataAddress(i.address.region, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_REGION')
                                                props.setDataAddress(i.address.locality, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_LOCALITY')
                                                props.setDataAddress(i.address.streetAddress, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_STREET_ADDRESS')
                                                props.setDataAddress(i.address.postalCode, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_POSTAL_CODE')
                                            }
                                            if(i.location !== null){
                                                props.setDataAddress(i.location.latitude, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE')
                                                props.setDataAddress(i.location.longitude, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE')
                                            }
                                            props.setDataAddress(i.quantity, 'CREATE_ITEMS_LAND_RENTAL_QUANTITY')
                                            if(i.hasOwnProperty('unit') === true){
                                                if(i.unit !== null){
                                                    if(i.unit.hasOwnProperty('code') === true) props.setDataAddress(i.unit.code, 'CREATE_ITEMS_LAND_RENTAL_UNIT_CODE')
                                                }
                                            }
                                            if(i.landProps !== null){
                                                props.setDataAddress(i.landProps.landArea, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LAND_AREA')
                                                props.setDataAddress(i.landProps.cadastralNumber, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_CADASTRAL_NUMBER')
                                                props.setDataAddress(i.landProps.ownershipType, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_OWNERSHIP_TYPE')
                                                props.setDataAddress(i.landProps.encumbrances, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_ENCUMBRANCES')
                                                props.setDataAddress(i.landProps.jointOwnership, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_JOINT_OWNERSHIP')
                                                props.setDataAddress(i.landProps.utilitiesAvailability, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_UTILITIES_AVAILABILITY')

                                                props.setDataAddress(i.landProps.hasEncumbrances, 'CREATE_ITEMS_DGF_LANDPROPS_HASENCUMBRANCES')
                                                props.setDataAddress(i.landProps.hasJointOwnership, 'CREATE_ITEMS_DGF_LANDPROPS_HASJOINTOWNERSHIP')
                                                props.setDataAddress(i.landProps.hasUtilitiesAvailability, 'CREATE_ITEMS_DGF_LANDPROPS_HASUTILITIESAVAILABILITY')

                                            }
                                        }
                                    }
                                >
                                    <IconButton aria-label="edit">
                                        <EditIcon />
                                    </IconButton>
                                </div>
                                {/*<div
                                    onClick={
                                        () => {
                                            props.changeLoader(true)
                                            deleteItem(props, props.auctionsMy, i.id)
                                        }
                                    }
                                >
                                    <IconButton aria-label="delete">
                                        <DeleteIcon />
                                    </IconButton>
                                </div>*/}
                            </div>
                        </div>
                    )
                }
            )
        //}
    }
    return tmpArr
}

/*
async function concatDataClassification(props){
    let tmpArr0 = await getDictionaryJointPropertyComplex(props, '06121000-6')
    let tmpArr1 = await tmpArr0.concat(await getDictionaryJointPropertyComplex(props, '06111000-3'))
    let tmpArr2 = await tmpArr1.concat(await getDictionaryJointPropertyComplex(props, '06112000-0'))
    let tmpArr3 = await tmpArr2.concat(await getDictionaryJointPropertyComplex(props, '06122000-3'))
    let tmpArr4 = await tmpArr3.concat(await getDictionaryJointPropertyComplex(props, '06123000-0'))
    let tmpArr5 = await tmpArr4.concat(await getDictionaryJointPropertyComplex(props, '06124000-7'))
    let tmpArr6 = await tmpArr5.concat(await getDictionaryJointPropertyComplex(props, '06125000-4'))
    let tmpArr7 = await tmpArr6.concat(await getDictionaryJointPropertyComplex(props, '06126000-1'))
    let tmpArr8 = await tmpArr7.concat(await getDictionaryJointPropertyComplex(props, '06127000-8'))
    let tmpArr9 = await tmpArr8.concat(await getDictionaryJointPropertyComplex(props, '06128000-5'))
    let tmpArr10 = await tmpArr9.concat(await getDictionaryJointPropertyComplex(props, '06129000-2'))
    props.setDataAddress(tmpArr10, 'SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD')
}*/

async function loadMainClassif(props, data){
    let tmpData = null
    if(data !== null && data !== 'null'){
        data = data.split('"')
        if(data[7].indexOf('.') !== -1){
            let tmp1 = data[7].split('.')
            tmpData = tmp1[0]
        }else{
            tmpData = data[7]
        }
    }
    switch(tmpData){
        case "01":
            getDictionaryJointPropertyComplex(props, '06121000-6')
            break
        case "02":
            getDictionaryJointPropertyComplex(props, '06111000-3')
            break
        case "03":
            getDictionaryJointPropertyComplex(props, '06112000-0')
            break
        case "04":
        case "05":
            getDictionaryJointPropertyComplex(props, '06122000-3')
            break
        case "06":
            getDictionaryJointPropertyComplex(props, '06123000-0')
            break
        case "07":
            getDictionaryJointPropertyComplex(props, '06124000-7')
            break
        case "08":
            getDictionaryJointPropertyComplex(props, '06125000-4')
            break
        case "09":
            getDictionaryJointPropertyComplex(props, '06126000-1')
            break
        case "10":
            getDictionaryJointPropertyComplex(props, '06127000-8')
            break
        case "11":
        case "12":
        case "13":
        case "14":
        case "15":
            getDictionaryJointPropertyComplex(props, '06128000-5')
            break
        case "16":
        case "17":
        case "18":
        case "19":
            getDictionaryJointPropertyComplex(props, '06129000-2')
            break
        default:
            break
    }
}

async function getDictionaryJointPropertyComplex(props, mask){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/autocomplete/classification_id?limit=1000&offset=0&classification_id=${mask}&scheme=CAV&mask=${mask}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        //return await response.json()
        let json = await response.json()
        props.setDataAddress(json, 'SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD')
    }
}


async function getAdditionalClassificationZU(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/dictionaries/kvtspz?limit=1000`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        await props.setDataAddress(json, 'CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATIONS_ARR')
    }
}





async function  deleteItem(props, aucId, itemId){
    /*const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${aucId}/item/${itemId}`;
    const response1 = await fetch(endPoint, {
        method: "delete",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .then(
        (response1) =>{
            if(response1 !== undefined || response1 !== 'undefined'){
                let data = {
                    domen: process.env.REACT_APP_END_POINT_BD,
                    version: 'v1',
                    local: props.langFlag,
                    params: '?limit=20'
                }
                let header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                props.auctionsGetMy(data, header)
                props.auctionsGetMyId(data, header, props.auctionsMy.id)
            }
        }
    )
    .catch(console.error)*/
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${aucId.id}/item/${itemId}`;
    const response1 = await fetch(endPoint, {
        method: "DELETE",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
    }else{
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}`;
        props.changeLoader(true)
        const response2 = await fetch(endPoint2, {
                method: "GET",
                headers: header
        }).catch(console.error)
        if(response2 === undefined || response2 === 'undefined'){
        }else{
            props.changeAuctionsItemsSwitchWindow(0);
            const json2 = await response2.json()
            if(json2.hasOwnProperty('message') === true){
                props.changeLoader(false)
            }else{
                props.getMyAuction(json2)
                props.changeLoader(false)
            }
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy
    }
}

const mapDispatchToProps = {
    changeCreateItemAuctionId,
    changeCreateItemClassificationId,
    changeCreateItemUnitValueCurrency,
    changeCreateItemUnitValueAmount,
    changeCreateItemUnitValueTax,
    changeCreateItemDescription,
    changeAuctionsItemsSwitchWindow,
    auctionsGetMy,
    auctionsGetMyId,
    changeAuctionsSwitchWindow,
    changeAdditionalClassificationIds,
    changeCreateItemQuantity,
    changeCreateItemIds,
    //auctionsGetClassificationsByType,
    auctionsGetClassificationsByTypeEnergy,
    auctionsGetClassificationsByGenerationType,
    //auctionsGetClassificationsByTimberSortment,
    //auctionsGetClassificationsByTimberSpecies,
    //auctionsGetClassificationsByTimberClass,
    //auctionsGetClassificationsByTimberDiameter,
    //auctionsGetClassificationsByTimberLength,
    //auctionsGetClassificationsByTimberStorage,
    //auctionsGetClassificationsByTimberProductionYear,
    //auctionsGetClassificationsByTimberProductionQuarter,
    changeTimberSortment,
    changeTimberSpecies,
    changeTimberClass,
    changeTimberDiameter,
    changeTimberLength,
    changeTimberStorage,
    changeTimberProductionYear,
    changeTimberProductionQuarter,
    changeCreateItemUnitCode,
    changeCreateItemGenerationType,
    changeLoader,
    getMyAuction,
    changeCreateItemLocationLatitude,
    changeCreateItemLocationLongitude,
    changeCreateItemLocationElevation,
    auctionsGetClassificationsByTypeDGFAdd,
    seDgfMask,
    changeCreateItemAddress,
    changeCreateItemDgfJointPropertyComplexPropsId,
    changeCreateItemDgfJointPropertyComplexPropsQuantityAndNomenclature,
    changeCreateItemDgfJointPropertyComplexPropsWorkplacesQuantity,
    changeCreateItemDgfJointPropertyComplexPropsBuildingsInformation,
    changeCreateItemDgfJointPropertyComplexPropsLandPlotInformation,
    changeCreateItemDgfJointPropertyComplexPropsMainObligations,
    changeCreateItemDgfLandPropsId,
    changeCreateItemDgfLandPropsLandArea,
    changeCreateItemDgfLandPropsCadastralNumber,
    changeCreateItemDgfLandPropsOwnershipType,
    changeCreateItemDgfLandPropsEncumbrances,
    changeCreateItemDgfLandPropsJointOwnership,
    changeCreateItemDgfLandPropsUtilitiesAvailability,
    changeCreateItemDgfLandPropsIntendedUse,
    changeCreateItemDgfVehiclePropsId,
    changeCreateItemDgfVehiclePropsBrand,
    changeCreateItemDgfVehiclePropsModel,
    changeCreateItemDgfVehiclePropsProductionYear,
    changeCreateItemDgfVehiclePropsEngineCapacity,
    changeCreateItemDgfVehiclePropsFuelType,
    changeCreateItemDgfVehiclePropsTransmission,
    changeCreateItemDgfVehiclePropsColor,
    changeCreateItemDgfVehiclePropsKilometrage,
    changeCreateItemDgfVehiclePropsIdentificationNumber,
    changeCreateItemDgfVehiclePropsConfiguration,
    changeCreateItemDgfVehiclePropsCondition,
    changeCreateItemDgfVehiclePropsDamagePresence,
    changeCreateItemDgfVehiclePropsDamagedDescription,
    /** */
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemsList)
