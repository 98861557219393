import { 
    //Old site users
    OLD_USER_GET,
    OLD_USERS_GET,
    OLD_USER_POST,
    OLD_USER_PUT,
    OLD_USER_DELETE,
    USER_PROFILES_GET
} from "../../types";

import {getAPI, postAPI, putAPI, deleteAPI} from '../crud';
import {setLocal} from '../support';

//Old site users
export function oldUserPost(_url, token, datas){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/old/user`,
        token: token,
        actionType: OLD_USER_POST,
        data: datas,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return postAPI(data);
}

export function oldUserGet(_url, token){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/old/user/${_url.id}`,
        token: token,
        actionType: OLD_USER_GET,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function oldUserPut(_url, token, datas){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/old/user/${_url.id}`,
        token: token,
        actionType: OLD_USER_PUT,
        data: datas,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return putAPI(data);
}

export function oldUserDelete(_url, token){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/old/user/${_url.id}`,
        token: token,
        actionType: OLD_USER_DELETE,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return deleteAPI(data);
}

export function oldUsersGet(_url, token){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/old/users${_url.params}`,
        token: token,
        actionType: OLD_USERS_GET,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function userProfileGet(_url, header){
    const data = {
        //url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/user/profile`,
        url: `${_url.domen}/api/v1/uk/private/userProfile`,
        actionType: USER_PROFILES_GET,
        header: header
    }
    return getAPI(data);
}