import { checkProp } from "../../../../redux/check"
// EDIT BTN
export function viewContractEditDoc(props) {
    if (props.data.documentOf === 'contract') {
        if (
            props.scope.aucStatus !== 'cancelled' && props.scope.aucStatus !== 'unsuccessful' && props.scope.aucStatus !== 'complete'
        ) {
            if (props.scope.aucOwner === true && props.scope.contractStatus !== "active") return true
        }
    }
    return false
}

//KEP URL Create
export function contractKEPCreate(props) {
    // let url = {}
    let tmpUrlNew = null
    let tmpUrl = null
    let tmpUrlKep = null

    if (checkProp(props.docData, 'id')) {
            tmpUrl = `${props.scopeData.patchNewDocFilesURL}/${props.docData.id}`
            tmpUrlNew = props.scopeData.uploadNewDocURL
            if (checkProp(props.scopeData, 'docList') && props.scopeData.docList.length > 0) {
                for (let key in props.scopeData.docList) {
                    if (props.scopeData.docList[key].relatedDocument === props.docData.id) {
                        // tmpRelatedDocument = props.scopeData.docList[key].documentId
                        tmpUrlKep = `${props.scopeData.patchNewDocFilesURL}/${props.scopeData.docList[key].documentId}`
                    }
                }
            }
    } else {
        tmpUrlNew = props.scopeData.uploadNewDocURL
    }

    return {
        urlNew: tmpUrlNew,
        url: tmpUrl,
        urlKep: tmpUrlKep,
    }
}
