import React from 'react';
import styles from './dgf.module.css';

import {connect} from 'react-redux';
import {
    getMyAuction,
    changeCreateItemAuctionId,
    changeCreateItemClassificationId,
    changeCreateItemUnitValueCurrency,
    changeCreateItemUnitValueAmount,
    changeCreateItemUnitValueTax,
    changeCreateItemDescription,
    changeAdditionalClassificationIds,
    changeCreateItemQuantity,
    changeCreateItemIds,
    changeCreateAuctionTab,
    changeCreateItemAddress,
    changeLoader,
    changeCreateItemClassificationAddIdNadra,
    changeCreateItemLocationLatitude,
    changeCreateItemLocationLongitude,
    changeCreateItemLocationElevation,
    changeCreateItemDgfJointPropertyComplexPropsId,
    changeCreateItemDgfJointPropertyComplexPropsQuantityAndNomenclature,
    changeCreateItemDgfJointPropertyComplexPropsWorkplacesQuantity,
    changeCreateItemDgfJointPropertyComplexPropsBuildingsInformation,
    changeCreateItemDgfJointPropertyComplexPropsLandPlotInformation,
    changeCreateItemDgfJointPropertyComplexPropsMainObligations,
    changeCreateItemDgfLandPropsId,
    changeCreateItemDgfLandPropsLandArea,
    changeCreateItemDgfLandPropsCadastralNumber,
    changeCreateItemDgfLandPropsOwnershipType,
    changeCreateItemDgfLandPropsEncumbrances,
    changeCreateItemDgfLandPropsJointOwnership,
    changeCreateItemDgfLandPropsUtilitiesAvailability,
    changeCreateItemDgfLandPropsIntendedUse,
    changeCreateItemDgfVehiclePropsId,
    changeCreateItemDgfVehiclePropsBrand,
    changeCreateItemDgfVehiclePropsModel,
    changeCreateItemDgfVehiclePropsProductionYear,
    changeCreateItemDgfVehiclePropsEngineCapacity,
    changeCreateItemDgfVehiclePropsFuelType,
    changeCreateItemDgfVehiclePropsTransmission,
    changeCreateItemDgfVehiclePropsColor,
    changeCreateItemDgfVehiclePropsKilometrage,
    changeCreateItemDgfVehiclePropsIdentificationNumber,
    changeCreateItemDgfVehiclePropsConfiguration,
    changeCreateItemDgfVehiclePropsCondition,
    changeCreateItemDgfVehiclePropsDamagePresence,
    changeCreateItemDgfVehiclePropsDamagedDescription,
} from '../../../../redux/actions.js';
import {auctionsGetMy,
    auctionsGetMyId,
    changeAuctionsItemsSwitchWindow,
    changeAuctionsSwitchWindow,
    auctionsGetClassificationsByTypeEnergy,
    auctionsGetClassificationsByGenerationType,
    changeTimberSortment,
    changeTimberSpecies,
    changeTimberClass,
    changeTimberDiameter,
    changeTimberLength,
    changeTimberStorage,
    changeTimberProductionYear,
    changeTimberProductionQuarter,
    changeCreateItemUnitCode,
    changeCreateItemGenerationType,
    setPopUpAuctionMyNull,
    auctionsGetClassificationsByTypeDGFAdd,
    seDgfMask,
} from '../../../../redux/actions/auctions/auctions.js';

import {popUpAddress} from '../../../../redux/actions/address/address.js';

import 'date-fns';
/*import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';*/

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
//import DeleteIcon from '@material-ui/icons/Delete';
//import EditIcon from '@material-ui/icons/Edit';
//import IconButton from '@material-ui/core/IconButton';

import ItemsList from './itemsList/itemsList.js';
//import idLot from '../../../tovar/idLot/idLot';

import ReturnButton from '../../returnButton'

import VehicleProps from './vehicleProps/vehicleProps'
import RealEstateProps from './realEstateProps/realEstateProps'
import LandProps from './landProps/landProps'
import JointPropertyComplexProps from './jointPropertyComplexProps/jointPropertyComplexProps'

const DgfAnyItems = ( props ) => {
    let tmpText = null;
    if(props.createItems.id === null){
        tmpText = 'Створення нового лоту';
    }else{
        tmpText = 'Редагування лоту';
    }
    let formItem = null;
    if(props.auctionsItemsSwitchWindow > 0){
        formItem = (
            <>
                <div className={styles.group}>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                className={styles.textAreaWidth}
                                id="outlined-multiline-static"
                                label="Автокомплит"
                                value={props.setMask}
                                variant="outlined"
                                onChange={(event)=>{
                                    if(event.target.value.length >= 2){
                                        
                                        let header5 = {
                                            'Authorization': props.token.access_token,
                                            'Content-Type': 'application/json'
                                        }
                                        let data5 = {
                                            domen: process.env.REACT_APP_END_POINT_BD,
                                            version: 'v1',
                                            local: props.langFlag,
                                            params: '?limit=100'
                                        }
                                        props.auctionsGetClassificationsByTypeDGFAdd(data5, header5, event.target.value)
                                    }
                                    props.seDgfMask(event.target.value)
                                    props.changeCreateItemClassificationId('null')
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                        <div>
                            <TextField
                                id="outlined-select-currency-native"
                                select
                                label="Основний класифікатор"
                                value={props.createItems.classificationId}
                                onChange={(event)=>{
                                    props.changeCreateItemClassificationId(event.target.value)
                                    {viewTypeItemsDgf(props)}
                                }}
                                SelectProps={{
                                    native: true,
                                }}
                                helperText="Оберіть основний класифікатор"
                                variant="outlined"
                            >
                                <option key='null' selected disabled value="null">Обрати</option>
                                {classificationIdOption(props)}
                            </TextField>
                        </div>
                    </div>
                    {viewTypeItemsDgf(props)}
                    
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                className={styles.textAreaWidth}
                                id="outlined-multiline-static"
                                label="Опис"
                                multiline
                                rows={10}
                                value={props.createItems.description}
                                variant="outlined"
                                onChange={(event)=>{
                                    props.changeCreateItemDescription(event.target.value)
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                id="outlined-select-currency-native"
                                select
                                label="Одиниці виміру"
                                value={props.createItems.unitCode}
                                onChange={(event)=>{
                                    props.changeCreateItemUnitCode(event.target.value)
                                }}
                                SelectProps={{
                                    native: true,
                                }}
                                helperText="Оберіть одиницю виміру"
                                variant="outlined"
                            >
                                <option key='null' selected disabled value="null">Обрати</option>
                                {classificationSubsoilUnitCode(props)}
                            </TextField>
                        </div>
                    </div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                label="Кількість"
                                value={props.createItems.quantity}
                                onChange={(event) => {
                                    props.changeCreateItemQuantity(event.target.value)
                                }}
                                name="numberformat"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                helperText=" "
                            />
                        </div>
                    </div>
                    <div>Географічні координати місця розташування</div>
                    <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                id="outlined-select-currency-native"
                                label="Широта"
                                value={props.createItems.location.latitude}
                                onChange={(event) => {
                                    props.changeCreateItemLocationLatitude(event.target.value)
                                }}
                                name="numberformat"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                helperText=" "
                            />
                        </div>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                id="outlined-select-currency-native"
                                label="Довгота"
                                value={props.createItems.location.longitude}
                                onChange={(event) => {
                                    props.changeCreateItemLocationLongitude(event.target.value)
                                }}
                                name="numberformat"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                helperText=" "
                            />
                        </div>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                id="outlined-select-currency-native"
                                label="Висота"
                                value={props.createItems.location.elevation}
                                onChange={(event) => {
                                    props.changeCreateItemLocationElevation(event.target.value)
                                }}
                                name="numberformat"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                helperText=" "
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                id="outlined-select-currency-native"
                                select
                                label="Адреса"
                                value={props.createItems.addressesGetMy}
                                onChange={(event)=>{
                                    props.changeCreateItemAddress(event.target.value)
                                }}
                                SelectProps={{
                                    native: true,
                                }}
                                helperText="Оберіть адресу"
                                variant="outlined"
                            >
                                <option key='null' selected disabled value="null">Обрати</option>
                                {addressArr(props)}
                            </TextField>
                        </div>
                    </div>
                    <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={
                                    () => {
                                        props.popUpAddress(true)
                                    }
                                }
                            >
                                Створити адресу
                            </Button>
                        </div>
                    </div>

                </div>
            </>
        )
    }
    let formButton = null;
    if(props.auctionsItemsSwitchWindow === 1){
        formButton = (
            <div className={styles.lineButtons}>
                <div className={styles.editButton}>
                    <Button
                        disabled={elementDisabled(props)}
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                createItem(props)
                            }
                        }
                    >
                        Зберегти
                    </Button>
                </div>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                props.changeAuctionsItemsSwitchWindow(0);
                                props.changeCreateItemDescription('')
                                props.changeCreateItemAuctionId('')
                                props.changeAdditionalClassificationIds('')
                                props.changeCreateItemUnitValueCurrency('')
                                props.changeCreateItemUnitValueAmount('')
                                props.changeCreateItemQuantity('')
                                
                                props.changeCreateItemClassificationId('null')
                                props.changeCreateItemUnitCode('null')
                                props.changeCreateItemDescription('')
                                props.changeCreateItemQuantity('')
                            }
                        }
                    >
                        Закрити
                    </Button>
                </div>
            </div>
        )
    }else if(props.auctionsItemsSwitchWindow === 2){
        formButton = (
            <div className={styles.lineButtons}>
                <div className={styles.editButton}>
                    <Button
                        disabled={elementDisabled(props)}
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                saveItem(props)
                            }
                        }
                    >
                        Редагувати
                    </Button>
                </div>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                props.changeAuctionsItemsSwitchWindow(0);
                                props.changeCreateItemDescription('')
                                props.changeCreateItemAuctionId('')
                                props.changeAdditionalClassificationIds('')
                                props.changeCreateItemUnitValueCurrency('')
                                props.changeCreateItemUnitValueAmount('')
                                props.changeCreateItemQuantity('')

                                props.changeCreateItemClassificationId('null')
                                //props.changeCreateItemGenerationType(event.target.value)
                                props.changeCreateItemUnitCode('null')
                                props.changeCreateItemDescription('')
                                props.changeCreateItemQuantity('')
                            }
                        }
                    >
                        Закрити
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className={styles.flexBlock}>
                <div className={styles.flexBlockElem}>
                    <div className={styles.headers}>
                        <Button
                            disabled={elementDisabledCreate(props)}
                            variant="contained"
                            color="primary"
                            onClick={
                                () => {
                                    props.changeAuctionsItemsSwitchWindow(1);
                                    let data = {
                                        domen: process.env.REACT_APP_END_POINT_BD,
                                        version: 'v1',
                                        local: props.langFlag,
                                        params: '?limit=20'
                                    }
                                    let header = {
                                        'Authorization': props.token.access_token,
                                        'Content-Type': 'application/json'
                                    }
                                    props.auctionsGetClassificationsByTypeEnergy(data, header)
                                    props.auctionsGetClassificationsByGenerationType(data, header)
                                    props.changeCreateItemClassificationId('null')
                                    //props.changeCreateItemGenerationType(event.target.value)
                                    props.changeCreateItemUnitCode('null')
                                    props.changeCreateItemDescription('')
                                    props.changeCreateItemQuantity('')
                                }
                            }
                        >
                            Створити новий лот
                        </Button>
                    </div>
                    <ItemsList />
                </div>
                <div className={styles.flexBlockElem}>
                    <div className={styles.headers}>{tmpText}</div>
                    {formItem}
                    {formButton}
                </div>
            </div>
            <div className={styles.bottonBlock}>
                {/*<Button
                    className={styles.buttonExitDocument}
                    variant="contained"
                    color="primary"
                    onClick={
                        () => {
                            props.setPopUpAuctionMyNull()
                            props.changeCreateAuctionTab(0);
                            props.changeAuctionsSwitchWindow(0)
                        }
                    }
                >
                    Повернутися до списку аукціонів
                </Button>*/}
                <ReturnButton />
            </div>
        </div>
    )
}

function addressArr(props){
    if(props.addressGetId !== null){
        return props.addressGetId.map(
            (i, count) => {
                return <option key={count} value={i.id}>{i.postalCode + ' ' + i.region + ' ' + i.locality + ' ' + i.streetAddress}</option>
            }
        )
    }
}

function viewTypeItemsDgf(props){
    let tmp = null
    let typeItem = null
    if(props.createItems.classificationId !== null){
        if(props.classificationsDgf.classificationsByTypeMain !== null){
            tmp = props.classificationsDgf.classificationsByTypeMain
            .filter(
                (i) => {
                    return String(i.id) === String(props.createItems.classificationId)
                }
            )
        }
    }
    if(tmp !== null){
        if(tmp.length > 0){
            typeItem = tmp[0].classificationId.substr(0, 2)
        }
    }
    switch(String(typeItem)){
        case '04':
        case '05':
        case '30':
        case '32':
        case '39':
            return <RealEstateProps />
        case '07':
        case '08':
            return <JointPropertyComplexProps />
        case '16':
        case '34':
        case '42':
            return <VehicleProps />
        case '06':
            return <LandProps />
        default:
            return null
    }
}

function elementDisabled(props){
    let tmpStatus = null;
    if(
        props.createItems.classificationId === null ||
        props.createItems.classificationId === '' ||
        //=== null ||
        //=== '' || тут доп классификатор по земле
        props.createItems.unitCode === null ||
        props.createItems.unitCode === '' ||
        props.createItems.description=== null ||
        props.createItems.description === '' ||
        props.createItems.quantity === null ||
        props.createItems.quantity === ''
    ){
        tmpStatus = 'disabled'
    }
    if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            tmpStatus = 'disabled'
        }
    }
    return tmpStatus
}

function classificationAddIdOption(props){
    if(props.classificationsNadraEnglish.classificationsByShemaSubsoilUsageType !== null){
        return props.classificationsNadraEnglish.classificationsByShemaSubsoilUsageType.map(
            (i, count) => {
                return <option key={count} value={i.id}>{i.scheme + ' ' + i.classificationId + ' ' + i.description}</option>
            }
        )
    }
}

function elementDisabledCreate(props){
    let tmpStatus = null;
    if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            tmpStatus = 'disabled'
        }
    }
    return tmpStatus
}

function classificationIdOption(props){
    if(props.classificationsDgf.classificationsByTypeMain !== null){
        return props.classificationsDgf.classificationsByTypeMain.map(
            (i, count) => {
                return <option key={count} value={i.id}>{i.scheme + ' ' + i.classificationId + ' ' + i.description}</option>
            }
        )
    }
}

function classificationEnergyGenerationType(props){
    if(props.classificationsEnergy.generationType !== null){
        return props.classificationsEnergy.generationType.map(
            (i, count) => {
                return <option key={count} value={i.id}>{i.scheme + ' ' + i.classificationId + ' ' + i.description}</option>
            }
        )
    }
}

function classificationSubsoilUnitCode(props){
    if(props.classificationsNadraEnglish.unitCode !== null){
        return props.classificationsNadraEnglish.unitCode.map(
            (i, count) => {
                return <option key={count} value={i.code}>{i.name}</option>
            }
        )
    }
}

async function createItem(props){
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}/item`;
            /*const arrAddClass = [];
            if(props.createItems.additionalClassificationIds.generationType !== null){
                arrAddClass.push(Number(props.createItems.additionalClassificationIds.generationType))
            }*/
            const response = await fetch(endPoint, {
                method: "POST",
                body: JSON.stringify({
                    classificationId: Number(props.createItems.classificationId), //Тип аукциона                     //+
                    additionalClassificationIds: [],
                    addressId: Number(props.createItems.addressesGetMy),  //+
                    description: props.createItems.description,             //+
                    unitCode: props.createItems.unitCode,         //+               //Мера измерения
                    quantity: props.createItems.quantity, //+                         //Колличество
                    location: {
                        latitude: props.createItems.location.latitude,
                        longitude: props.createItems.location.longitude,
                        elevation: props.createItems.location.elevation
                    }

                }),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
            }else{
                const json = await response.json()
                let tmp1 = null
                let typeItem1 = null
                if(props.createItems.classificationId !== null){
                    if(props.classificationsDgf.classificationsByTypeMain !== null){
                        tmp1 = props.classificationsDgf.classificationsByTypeMain
                        .filter(
                            (i) => {
                                return String(i.id) === String(props.createItems.classificationId)
                            }
                        )
                    }
                }
                if(tmp1 !== null){
                    if(tmp1.length > 0){
                        typeItem1 = tmp1[0].classificationId.substr(0, 2)
                    }
                }
                switch(String(typeItem1)){
                    case '04':
                    case '05':
                    case '30':
                    case '32':
                    case '39':
                        createItemDgfRealEstateProps(props, json.id)
                        break;
                    case '07':
                    case '08':
                        createItemDgfJointPropertyComplexProps(props, json.id)
                        break;
                    case '16':
                    case '34':
                    case '42':
                        createItemDgfVehicleProps(props, json.id)
                        break;
                    case '06':
                        createItemDgfLandProps(props, json.id)
                        break;
                    default:
                        break;
                }
                
            }
        }
    }
}

async function saveItem(props){
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            /*const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/item/${props.createItems.id}`;*/ 
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}/item/${props.createItems.id}`;
            /*const arrAddClass = [];
            if(props.createItems.additionalClassificationIds.generationType !== null){
                arrAddClass.push(Number(props.createItems.additionalClassificationIds.generationType))
            }*/
            const response = await fetch(endPoint, {
                method: "PUT",
                body: JSON.stringify({
                    classificationId: Number(props.createItems.classificationId), //Тип аукциона                     //+
                    additionalClassificationIds: [],
                    addressId: Number(props.createItems.addressesGetMy),  //+
                    description: props.createItems.description,             //+
                    unitCode: props.createItems.unitCode,         //+               //Мера измерения
                    quantity: props.createItems.quantity, //+                         //Колличество
                    location: {
                        latitude: props.createItems.location.latitude,
                        longitude: props.createItems.location.longitude,
                        elevation: props.createItems.location.elevation
                    }
                }),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
            }else{
                /*let header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}`;
                props.changeLoader(true)
                const response2 = await fetch(endPoint2, {
                        method: "GET",
                        headers: header
                }).catch(console.error)
                if(response2 === undefined || response2 === 'undefined'){
                }else{
                    props.changeAuctionsItemsSwitchWindow(0);
                    props.changeCreateItemClassificationId('null')
                    //props.changeCreateItemGenerationType(event.target.value)
                    props.changeCreateItemUnitCode('null')
                    props.changeCreateItemDescription('')
                    props.changeCreateItemQuantity('')
                    const json2 = await response2.json()
                    if(json2.hasOwnProperty('message') === true){
                        props.changeLoader(false)
                    }else{
                        props.getMyAuction(json2)
                        props.changeLoader(false)
                    }
                }*/
                const json = await response.json()
                let tmp1 = null
                let typeItem1 = null
                if(props.createItems.classificationId !== null){
                    if(props.classificationsDgf.classificationsByTypeMain !== null){
                        tmp1 = props.classificationsDgf.classificationsByTypeMain
                        .filter(
                            (i) => {
                                return String(i.id) === String(props.createItems.classificationId)
                            }
                        )
                    }
                }
                if(tmp1 !== null){
                    if(tmp1.length > 0){
                        typeItem1 = tmp1[0].classificationId.substr(0, 2)
                    }
                }
                switch(String(typeItem1)){
                    case '04':
                    case '05':
                    case '30':
                    case '32':
                    case '39':
                        saveItemDgfRealEstateProps(props, props.createItems.id)
                        break;
                    case '07':
                    case '08':
                        saveItemDgfJointPropertyComplexProps(props, props.createItems.id)
                        break;
                    case '16':
                    case '34':
                    case '42':
                        saveItemDgfVehicleProps(props, props.createItems.id)
                        break;
                    case '06':
                        saveItemDgfLandProps(props, props.createItems.id)
                        break;
                    default:
                        break;
                }
            }
        }
    }
}


async function createItemDgfJointPropertyComplexProps(props, itemId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}/item/${itemId}/dgf/JointPropertyComplexProps`;
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify({
            quantityAndNomenclature: props.createItems.dgf.jointPropertyComplexProps.quantityAndNomenclature,
            workplacesQuantity: Number(props.createItems.dgf.jointPropertyComplexProps.workplacesQuantity),
            buildingsInformation: props.createItems.dgf.jointPropertyComplexProps.buildingsInformation,
            landPlotInformation: props.createItems.dgf.jointPropertyComplexProps.landPlotInformation,
            mainObligations: props.createItems.dgf.jointPropertyComplexProps.mainObligations,
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        props.changeCreateItemDgfJointPropertyComplexPropsId('')
        props.changeCreateItemDgfJointPropertyComplexPropsQuantityAndNomenclature('')
        props.changeCreateItemDgfJointPropertyComplexPropsWorkplacesQuantity('')
        props.changeCreateItemDgfJointPropertyComplexPropsBuildingsInformation('')
        props.changeCreateItemDgfJointPropertyComplexPropsLandPlotInformation('')
        props.changeCreateItemDgfJointPropertyComplexPropsMainObligations('')
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        const endPoint2t = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}`;
        props.changeLoader(true)
        const response2t = await fetch(endPoint2t, {
                method: "GET",
                headers: header
        }).catch(console.error)
        if(response2t === undefined || response2t === 'undefined'){
        }else{
            props.changeAuctionsItemsSwitchWindow(0);
            props.changeCreateItemClassificationId('null')
            //props.changeCreateItemGenerationType(event.target.value)
            props.changeCreateItemUnitCode('null')
            props.changeCreateItemDescription('')
            props.changeCreateItemQuantity('')
            const json2t = await response2t.json()
            if(json2t.hasOwnProperty('message') === true){
                props.changeLoader(false)
            }else{
                props.getMyAuction(json2t)
                props.changeLoader(false)
            }
        }
    }
}
async function saveItemDgfJointPropertyComplexProps(props, itemId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}/item/${itemId}/dgf/JointPropertyComplexProps`;
    const response = await fetch(endPoint, {
        method: "PUT",
        body: JSON.stringify({
            id: Number(props.createItems.dgf.jointPropertyComplexProps.id),
            quantityAndNomenclature: props.createItems.dgf.jointPropertyComplexProps.quantityAndNomenclature,
            workplacesQuantity: Number(props.createItems.dgf.jointPropertyComplexProps.workplacesQuantity),
            buildingsInformation: props.createItems.dgf.jointPropertyComplexProps.buildingsInformation,
            landPlotInformation: props.createItems.dgf.jointPropertyComplexProps.landPlotInformation,
            mainObligations: props.createItems.dgf.jointPropertyComplexProps.mainObligations,
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        props.changeCreateItemDgfJointPropertyComplexPropsId('')
        props.changeCreateItemDgfJointPropertyComplexPropsQuantityAndNomenclature('')
        props.changeCreateItemDgfJointPropertyComplexPropsWorkplacesQuantity('')
        props.changeCreateItemDgfJointPropertyComplexPropsBuildingsInformation('')
        props.changeCreateItemDgfJointPropertyComplexPropsLandPlotInformation('')
        props.changeCreateItemDgfJointPropertyComplexPropsMainObligations('')
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        const endPoint2t = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}`;
        props.changeLoader(true)
        const response2t = await fetch(endPoint2t, {
                method: "GET",
                headers: header
        }).catch(console.error)
        if(response2t === undefined || response2t === 'undefined'){
        }else{
            props.changeAuctionsItemsSwitchWindow(0);
            props.changeCreateItemClassificationId('null')
            //props.changeCreateItemGenerationType(event.target.value)
            props.changeCreateItemUnitCode('null')
            props.changeCreateItemDescription('')
            props.changeCreateItemQuantity('')
            const json2t = await response2t.json()
            if(json2t.hasOwnProperty('message') === true){
                props.changeLoader(false)
            }else{
                props.getMyAuction(json2t)
                props.changeLoader(false)
            }
        }
    }
}

async function createItemDgfLandProps(props, itemId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}/item/${itemId}/dgf/LandProps`;
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify({
            landArea: props.createItems.dgf.landProps.landArea,
			cadastralNumber: props.createItems.dgf.landProps.cadastralNumber,
			ownershipType: props.createItems.dgf.landProps.ownershipType,
			encumbrances: props.createItems.dgf.landProps.encumbrances,
			jointOwnership: props.createItems.dgf.landProps.jointOwnership,
			utilitiesAvailability: props.createItems.dgf.landProps.utilitiesAvailability,
			intendedUse: props.createItems.dgf.landProps.intendedUse,
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        props.changeCreateItemDgfLandPropsLandArea('')
		props.changeCreateItemDgfLandPropsCadastralNumber('')
		props.changeCreateItemDgfLandPropsOwnershipType('null')
		props.changeCreateItemDgfLandPropsEncumbrances('null')
		props.changeCreateItemDgfLandPropsJointOwnership('')
		props.changeCreateItemDgfLandPropsUtilitiesAvailability('')
		props.changeCreateItemDgfLandPropsIntendedUse('')
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        const endPoint2t = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}`;
        props.changeLoader(true)
        const response2t = await fetch(endPoint2t, {
                method: "GET",
                headers: header
        }).catch(console.error)
        if(response2t === undefined || response2t === 'undefined'){
        }else{
            props.changeAuctionsItemsSwitchWindow(0);
            props.changeCreateItemClassificationId('null')
            //props.changeCreateItemGenerationType(event.target.value)
            props.changeCreateItemUnitCode('null')
            props.changeCreateItemDescription('')
            props.changeCreateItemQuantity('')
            const json2t = await response2t.json()
            if(json2t.hasOwnProperty('message') === true){
                props.changeLoader(false)
            }else{
                props.getMyAuction(json2t)
                props.changeLoader(false)
            }
        }
    }
}
async function saveItemDgfLandProps(props, itemId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}/item/${itemId}/dgf/LandProps`;
    const response = await fetch(endPoint, {
        method: "PUT",
        body: JSON.stringify({
            id: Number(props.createItems.dgf.landProps.id),
            landArea: props.createItems.dgf.landProps.landArea,
			cadastralNumber: props.createItems.dgf.landProps.cadastralNumber,
			ownershipType: props.createItems.dgf.landProps.ownershipType,
			encumbrances: props.createItems.dgf.landProps.encumbrances,
			jointOwnership: props.createItems.dgf.landProps.jointOwnership,
			utilitiesAvailability: props.createItems.dgf.landProps.utilitiesAvailability,
			intendedUse: props.createItems.dgf.landProps.intendedUse,
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        props.changeCreateItemDgfLandPropsLandArea('')
		props.changeCreateItemDgfLandPropsCadastralNumber('')
		props.changeCreateItemDgfLandPropsOwnershipType('null')
		props.changeCreateItemDgfLandPropsEncumbrances('null')
		props.changeCreateItemDgfLandPropsJointOwnership('')
		props.changeCreateItemDgfLandPropsUtilitiesAvailability('')
		props.changeCreateItemDgfLandPropsIntendedUse('')
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        const endPoint2t = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}`;
        props.changeLoader(true)
        const response2t = await fetch(endPoint2t, {
                method: "GET",
                headers: header
        }).catch(console.error)
        if(response2t === undefined || response2t === 'undefined'){
        }else{
            props.changeAuctionsItemsSwitchWindow(0);
            props.changeCreateItemClassificationId('null')
            //props.changeCreateItemGenerationType(event.target.value)
            props.changeCreateItemUnitCode('null')
            props.changeCreateItemDescription('')
            props.changeCreateItemQuantity('')
            const json2t = await response2t.json()
            if(json2t.hasOwnProperty('message') === true){
                props.changeLoader(false)
            }else{
                props.getMyAuction(json2t)
                props.changeLoader(false)
            }
        }
    }
}

async function createItemDgfVehicleProps(props, itemId){
    let tmpBoolean = false
    if(props.createItems.dgf.vehicleProps.damagePresence === 'true'){
        tmpBoolean = true
    }else{
        tmpBoolean = false
    }
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}/item/${itemId}/dgf/VehicleProps`;
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify({
            brand: props.createItems.dgf.vehicleProps.brand,
			model: props.createItems.dgf.vehicleProps.model,
			productionYear: Number(props.createItems.dgf.vehicleProps.productionYear),
			engineCapacity: props.createItems.dgf.vehicleProps.engineCapacity,
			fuelType: props.createItems.dgf.vehicleProps.fuelType,
			transmission: props.createItems.dgf.vehicleProps.transmission,
			color: props.createItems.dgf.vehicleProps.color,
			kilometrage: props.createItems.dgf.vehicleProps.kilometrage,
			identificationNumber: props.createItems.dgf.vehicleProps.identificationNumber,
			configuration: props.createItems.dgf.vehicleProps.configuration,
			condition: props.createItems.dgf.vehicleProps.condition,
			damagePresence: tmpBoolean,
			damagedDescription: props.createItems.dgf.vehicleProps.damagedDescription,
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        props.changeCreateItemDgfVehiclePropsId('')
		props.changeCreateItemDgfVehiclePropsBrand('')
		props.changeCreateItemDgfVehiclePropsModel('')
		props.changeCreateItemDgfVehiclePropsProductionYear('')
		props.changeCreateItemDgfVehiclePropsEngineCapacity('')
		props.changeCreateItemDgfVehiclePropsFuelType('null')
		props.changeCreateItemDgfVehiclePropsTransmission('null')
		props.changeCreateItemDgfVehiclePropsColor('')
		props.changeCreateItemDgfVehiclePropsKilometrage('')
		props.changeCreateItemDgfVehiclePropsIdentificationNumber('')
		props.changeCreateItemDgfVehiclePropsConfiguration('')
		props.changeCreateItemDgfVehiclePropsCondition('')
		props.changeCreateItemDgfVehiclePropsDamagePresence('null')
		props.changeCreateItemDgfVehiclePropsDamagedDescription('')
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        const endPoint2t = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}`;
        props.changeLoader(true)
        const response2t = await fetch(endPoint2t, {
                method: "GET",
                headers: header
        }).catch(console.error)
        if(response2t === undefined || response2t === 'undefined'){
        }else{
            props.changeAuctionsItemsSwitchWindow(0);
            props.changeCreateItemClassificationId('null')
            //props.changeCreateItemGenerationType(event.target.value)
            props.changeCreateItemUnitCode('null')
            props.changeCreateItemDescription('')
            props.changeCreateItemQuantity('')
            const json2t = await response2t.json()
            if(json2t.hasOwnProperty('message') === true){
                props.changeLoader(false)
            }else{
                props.getMyAuction(json2t)
                props.changeLoader(false)
            }
        }
    }
}
async function saveItemDgfVehicleProps(props, itemId){
    let tmpBoolean = false
    if(props.createItems.dgf.vehicleProps.damagePresence === 'true'){
        tmpBoolean = true
    }else{
        tmpBoolean = false
    }
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}/item/${itemId}/dgf/VehicleProps`;
    const response = await fetch(endPoint, {
        method: "PUT",
        body: JSON.stringify({
            id: Number(props.createItems.dgf.vehicleProps.id),
            brand: props.createItems.dgf.vehicleProps.brand,
			model: props.createItems.dgf.vehicleProps.model,
			productionYear: Number(props.createItems.dgf.vehicleProps.productionYear),
			engineCapacity: props.createItems.dgf.vehicleProps.engineCapacity,
			fuelType: props.createItems.dgf.vehicleProps.fuelType,
			transmission: props.createItems.dgf.vehicleProps.transmission,
			color: props.createItems.dgf.vehicleProps.color,
			kilometrage: props.createItems.dgf.vehicleProps.kilometrage,
			identificationNumber: props.createItems.dgf.vehicleProps.identificationNumber,
			configuration: props.createItems.dgf.vehicleProps.configuration,
			condition: props.createItems.dgf.vehicleProps.condition,
			damagePresence: tmpBoolean,
			damagedDescription: props.createItems.dgf.vehicleProps.damagedDescription,
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        props.changeCreateItemDgfVehiclePropsId('')
		props.changeCreateItemDgfVehiclePropsBrand('')
		props.changeCreateItemDgfVehiclePropsModel('')
		props.changeCreateItemDgfVehiclePropsProductionYear('')
		props.changeCreateItemDgfVehiclePropsEngineCapacity('')
		props.changeCreateItemDgfVehiclePropsFuelType('null')
		props.changeCreateItemDgfVehiclePropsTransmission('null')
		props.changeCreateItemDgfVehiclePropsColor('')
		props.changeCreateItemDgfVehiclePropsKilometrage('')
		props.changeCreateItemDgfVehiclePropsIdentificationNumber('')
		props.changeCreateItemDgfVehiclePropsConfiguration('')
		props.changeCreateItemDgfVehiclePropsCondition('')
		props.changeCreateItemDgfVehiclePropsDamagePresence('null')
		props.changeCreateItemDgfVehiclePropsDamagedDescription('')
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        const endPoint2t = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}`;
        props.changeLoader(true)
        const response2t = await fetch(endPoint2t, {
                method: "GET",
                headers: header
        }).catch(console.error)
        if(response2t === undefined || response2t === 'undefined'){
        }else{
            props.changeAuctionsItemsSwitchWindow(0);
            props.changeCreateItemClassificationId('null')
            //props.changeCreateItemGenerationType(event.target.value)
            props.changeCreateItemUnitCode('null')
            props.changeCreateItemDescription('')
            props.changeCreateItemQuantity('')
            const json2t = await response2t.json()
            if(json2t.hasOwnProperty('message') === true){
                props.changeLoader(false)
            }else{
                props.getMyAuction(json2t)
                props.changeLoader(false)
            }
        }
    }
}

async function createItemDgfRealEstateProps(props, itemId){
    let serviceElectricity = false
    if(props.createItems.dgf.realEstateProps.serviceElectricity === 'true'){
        serviceElectricity = true
    }else{
        serviceElectricity = false
    }
    let serviceWater = false
    if(props.createItems.dgf.realEstateProps.serviceWater === 'true'){
        serviceWater = true
    }else{
        serviceWater = false
    }
    let serviceSewerage = false
    if(props.createItems.dgf.realEstateProps.serviceSewerage === 'true'){
        serviceSewerage = true
    }else{
        serviceSewerage = false
    }
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}/item/${itemId}/dgf/RealEstateProps`;
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify({
            totalBuildingArea: props.createItems.dgf.realEstateProps.totalBuildingArea,
            totalObjectArea: props.createItems.dgf.realEstateProps.totalObjectArea,
            usableArea: props.createItems.dgf.realEstateProps.usableArea,
            constructionYear: Number(props.createItems.dgf.realEstateProps.constructionYear),
            constructionTechnology: props.createItems.dgf.realEstateProps.constructionTechnology,
            livingArea: props.createItems.dgf.realEstateProps.livingArea,
            kitchenArea: props.createItems.dgf.realEstateProps.kitchenArea,
            landArea: props.createItems.dgf.realEstateProps.landArea,
            locationInBuilding: props.createItems.dgf.realEstateProps.locationInBuilding,
            floors: props.createItems.dgf.realEstateProps.floors,
            generalCondition: props.createItems.dgf.realEstateProps.generalCondition,
            serviceElectricity: booleanType(props.createItems.dgf.realEstateProps.serviceElectricity),
            powerSupplyCapacity: props.createItems.dgf.realEstateProps.powerSupplyCapacity,
            powerSupplyClass: props.createItems.dgf.realEstateProps.powerSupplyClass,
            serviceWater: booleanType(props.createItems.dgf.realEstateProps.serviceWater),
            serviceSewerage: booleanType(props.createItems.dgf.realEstateProps.serviceSewerage),
            serviceGas: booleanType(props.createItems.dgf.realEstateProps.serviceGas),
            serviceCentralHeating: booleanType(props.createItems.dgf.realEstateProps.serviceCentralHeating),
            serviceAutonomousHeating: booleanType(props.createItems.dgf.realEstateProps.serviceAutonomousHeating),
            serviceHeatingCounter: booleanType(props.createItems.dgf.realEstateProps.serviceHeatingCounter),
            serviceVentilation: booleanType(props.createItems.dgf.realEstateProps.serviceVentilation),
            serviceAirConditioning: booleanType(props.createItems.dgf.realEstateProps.serviceAirConditioning),
            servicePhone: booleanType(props.createItems.dgf.realEstateProps.servicePhone),
            serviceTV: booleanType(props.createItems.dgf.realEstateProps.serviceTV),
            serviceInternet: booleanType(props.createItems.dgf.realEstateProps.serviceInternet),
            serviceElevator: booleanType(props.createItems.dgf.realEstateProps.serviceElevator),
            serviceSecurityAlarm: booleanType(props.createItems.dgf.realEstateProps.serviceSecurityAlarm),
            serviceFireAlarm: booleanType(props.createItems.dgf.realEstateProps.serviceFireAlarm),
            servicesDescription: props.createItems.dgf.realEstateProps.servicesDescription,
            servicesAccounting: props.createItems.dgf.realEstateProps.servicesAccounting,
            landTax: props.createItems.dgf.realEstateProps.landTax,
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        props.changeCreateItemDgfVehiclePropsId('')
		props.changeCreateItemDgfVehiclePropsBrand('')
		props.changeCreateItemDgfVehiclePropsModel('')
		props.changeCreateItemDgfVehiclePropsProductionYear('')
		props.changeCreateItemDgfVehiclePropsEngineCapacity('')
		props.changeCreateItemDgfVehiclePropsFuelType('null')
		props.changeCreateItemDgfVehiclePropsTransmission('null')
		props.changeCreateItemDgfVehiclePropsColor('')
		props.changeCreateItemDgfVehiclePropsKilometrage('')
		props.changeCreateItemDgfVehiclePropsIdentificationNumber('')
		props.changeCreateItemDgfVehiclePropsConfiguration('')
		props.changeCreateItemDgfVehiclePropsCondition('')
		props.changeCreateItemDgfVehiclePropsDamagePresence('null')
		props.changeCreateItemDgfVehiclePropsDamagedDescription('')
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        const endPoint2t = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}`;
        props.changeLoader(true)
        const response2t = await fetch(endPoint2t, {
                method: "GET",
                headers: header
        }).catch(console.error)
        if(response2t === undefined || response2t === 'undefined'){
        }else{
            props.changeAuctionsItemsSwitchWindow(0);
            props.changeCreateItemClassificationId('null')
            //props.changeCreateItemGenerationType(event.target.value)
            props.changeCreateItemUnitCode('null')
            props.changeCreateItemDescription('')
            props.changeCreateItemQuantity('')
            const json2t = await response2t.json()
            if(json2t.hasOwnProperty('message') === true){
                props.changeLoader(false)
            }else{
                props.getMyAuction(json2t)
                props.changeLoader(false)
            }
        }
    }
}
async function saveItemDgfRealEstateProps(props, itemId){
    let serviceElectricity = false
    if(props.createItems.dgf.realEstateProps.serviceElectricity === 'true'){
        serviceElectricity = true
    }else{
        serviceElectricity = false
    }
    let serviceWater = false
    if(props.createItems.dgf.realEstateProps.serviceWater === 'true'){
        serviceWater = true
    }else{
        serviceWater = false
    }
    let serviceSewerage = false
    if(props.createItems.dgf.realEstateProps.serviceSewerage === 'true'){
        serviceSewerage = true
    }else{
        serviceSewerage = false
    }
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}/item/${itemId}/dgf/RealEstateProps`;
    const response = await fetch(endPoint, {
        method: "PUT",
        body: JSON.stringify({
            id: Number(props.createItems.dgf.realEstateProps.id),
            totalBuildingArea: props.createItems.dgf.realEstateProps.totalBuildingArea,
            totalObjectArea: props.createItems.dgf.realEstateProps.totalObjectArea,
            usableArea: props.createItems.dgf.realEstateProps.usableArea,
            constructionYear: Number(props.createItems.dgf.realEstateProps.constructionYear),
            constructionTechnology: props.createItems.dgf.realEstateProps.constructionTechnology,
            livingArea: props.createItems.dgf.realEstateProps.livingArea,
            kitchenArea: props.createItems.dgf.realEstateProps.kitchenArea,
            landArea: props.createItems.dgf.realEstateProps.landArea,
            locationInBuilding: props.createItems.dgf.realEstateProps.locationInBuilding,
            floors: props.createItems.dgf.realEstateProps.floors,
            generalCondition: props.createItems.dgf.realEstateProps.generalCondition,
            serviceElectricity: booleanType(props.createItems.dgf.realEstateProps.serviceElectricity),
            powerSupplyCapacity: props.createItems.dgf.realEstateProps.powerSupplyCapacity,
            powerSupplyClass: props.createItems.dgf.realEstateProps.powerSupplyClass,
            serviceWater: booleanType(props.createItems.dgf.realEstateProps.serviceWater),
            serviceSewerage: booleanType(props.createItems.dgf.realEstateProps.serviceSewerage),
            serviceGas: booleanType(props.createItems.dgf.realEstateProps.serviceGas),
            serviceCentralHeating: booleanType(props.createItems.dgf.realEstateProps.serviceCentralHeating),
            serviceAutonomousHeating: booleanType(props.createItems.dgf.realEstateProps.serviceAutonomousHeating),
            serviceHeatingCounter: booleanType(props.createItems.dgf.realEstateProps.serviceHeatingCounter),
            serviceVentilation: booleanType(props.createItems.dgf.realEstateProps.serviceVentilation),
            serviceAirConditioning: booleanType(props.createItems.dgf.realEstateProps.serviceAirConditioning),
            servicePhone: booleanType(props.createItems.dgf.realEstateProps.servicePhone),
            serviceTV: booleanType(props.createItems.dgf.realEstateProps.serviceTV),
            serviceInternet: booleanType(props.createItems.dgf.realEstateProps.serviceInternet),
            serviceElevator: booleanType(props.createItems.dgf.realEstateProps.serviceElevator),
            serviceSecurityAlarm: booleanType(props.createItems.dgf.realEstateProps.serviceSecurityAlarm),
            serviceFireAlarm: booleanType(props.createItems.dgf.realEstateProps.serviceFireAlarm),
            servicesDescription: props.createItems.dgf.realEstateProps.servicesDescription,
            servicesAccounting: props.createItems.dgf.realEstateProps.servicesAccounting,
            landTax: props.createItems.dgf.realEstateProps.landTax,
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        props.changeCreateItemDgfVehiclePropsId('')
		props.changeCreateItemDgfVehiclePropsBrand('')
		props.changeCreateItemDgfVehiclePropsModel('')
		props.changeCreateItemDgfVehiclePropsProductionYear('')
		props.changeCreateItemDgfVehiclePropsEngineCapacity('')
		props.changeCreateItemDgfVehiclePropsFuelType('null')
		props.changeCreateItemDgfVehiclePropsTransmission('null')
		props.changeCreateItemDgfVehiclePropsColor('')
		props.changeCreateItemDgfVehiclePropsKilometrage('')
		props.changeCreateItemDgfVehiclePropsIdentificationNumber('')
		props.changeCreateItemDgfVehiclePropsConfiguration('')
		props.changeCreateItemDgfVehiclePropsCondition('')
		props.changeCreateItemDgfVehiclePropsDamagePresence('null')
		props.changeCreateItemDgfVehiclePropsDamagedDescription('')
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        const endPoint2t = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}`;
        props.changeLoader(true)
        const response2t = await fetch(endPoint2t, {
                method: "GET",
                headers: header
        }).catch(console.error)
        if(response2t === undefined || response2t === 'undefined'){
        }else{
            props.changeAuctionsItemsSwitchWindow(0);
            props.changeCreateItemClassificationId('null')
            //props.changeCreateItemGenerationType(event.target.value)
            props.changeCreateItemUnitCode('null')
            props.changeCreateItemDescription('')
            props.changeCreateItemQuantity('')
            const json2t = await response2t.json()
            if(json2t.hasOwnProperty('message') === true){
                props.changeLoader(false)
            }else{
                props.getMyAuction(json2t)
                props.changeLoader(false)
            }
        }
    }
}

function booleanType(data){
    if(data === 'true'){
        return true
    }else{
        return false
    }
}

async function  deleteItem(props, aucId, itemId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${aucId}/item/${itemId}`;
    const response1 = await fetch(endPoint, {
        method: "DELETE",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .then(
        (response1) =>{
            if(response1 === undefined || response1 === 'undefined'){
            }else{
                let data = {
                    domen: process.env.REACT_APP_END_POINT_BD,
                    version: 'v1',
                    local: props.langFlag,
                    params: '?limit=100'
                }
                let header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                props.auctionsGetMy(data, header)
                props.auctionsGetMyId(data, header, props.auctionsMy.id)
            }
        }
    )
    .catch(console.error)
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        getAuctionsMy: state.start.auctionsGetMy,
        classificationsNadraEnglish: state.start.classificationsNadraEnglish,
        addressGetId: state.start.addressGetId,
        setMask: state.start.setMask,
        classificationsDgf: state.start.classificationsDgf,
    }
}

const mapDispatchToProps = {
    changeCreateItemAuctionId,
    changeCreateItemClassificationId,
    changeCreateItemUnitValueCurrency,
    changeCreateItemUnitValueAmount,
    changeCreateItemUnitValueTax,
    changeCreateItemDescription,
    changeAuctionsItemsSwitchWindow,
    auctionsGetMy,
    auctionsGetMyId,
    changeAuctionsSwitchWindow,
    changeAdditionalClassificationIds,
    changeCreateItemQuantity,
    changeCreateItemIds,
    //auctionsGetClassificationsByType,
    auctionsGetClassificationsByTypeEnergy,
    auctionsGetClassificationsByGenerationType,
    //auctionsGetClassificationsByTimberSortment,
    //auctionsGetClassificationsByTimberSpecies,
    //auctionsGetClassificationsByTimberClass,
    //auctionsGetClassificationsByTimberDiameter,
    //auctionsGetClassificationsByTimberLength,
    //auctionsGetClassificationsByTimberStorage,
    //auctionsGetClassificationsByTimberProductionYear,
    //auctionsGetClassificationsByTimberProductionQuarter,
    changeTimberSortment,
    changeTimberSpecies,
    changeTimberClass,
    changeTimberDiameter,
    changeTimberLength,
    changeTimberStorage,
    changeTimberProductionYear,
    changeTimberProductionQuarter,
    changeCreateItemUnitCode,
    changeCreateItemGenerationType,
    getMyAuction,
    changeCreateAuctionTab,
    changeCreateItemAddress,
    popUpAddress,
    changeLoader,
    getMyAuction,
    setPopUpAuctionMyNull,
    changeCreateItemClassificationAddIdNadra,
    auctionsGetClassificationsByTypeDGFAdd,
    seDgfMask,
    changeCreateItemLocationLatitude,
    changeCreateItemLocationLongitude,
    changeCreateItemLocationElevation,
    changeCreateItemDgfJointPropertyComplexPropsId,
    changeCreateItemDgfJointPropertyComplexPropsQuantityAndNomenclature,
    changeCreateItemDgfJointPropertyComplexPropsWorkplacesQuantity,
    changeCreateItemDgfJointPropertyComplexPropsBuildingsInformation,
    changeCreateItemDgfJointPropertyComplexPropsLandPlotInformation,
    changeCreateItemDgfJointPropertyComplexPropsMainObligations,
    changeCreateItemDgfLandPropsId,
    changeCreateItemDgfLandPropsLandArea,
    changeCreateItemDgfLandPropsCadastralNumber,
    changeCreateItemDgfLandPropsOwnershipType,
    changeCreateItemDgfLandPropsEncumbrances,
    changeCreateItemDgfLandPropsJointOwnership,
    changeCreateItemDgfLandPropsUtilitiesAvailability,
    changeCreateItemDgfLandPropsIntendedUse,
    changeCreateItemDgfVehiclePropsId,
    changeCreateItemDgfVehiclePropsBrand,
    changeCreateItemDgfVehiclePropsModel,
    changeCreateItemDgfVehiclePropsProductionYear,
    changeCreateItemDgfVehiclePropsEngineCapacity,
    changeCreateItemDgfVehiclePropsFuelType,
    changeCreateItemDgfVehiclePropsTransmission,
    changeCreateItemDgfVehiclePropsColor,
    changeCreateItemDgfVehiclePropsKilometrage,
    changeCreateItemDgfVehiclePropsIdentificationNumber,
    changeCreateItemDgfVehiclePropsConfiguration,
    changeCreateItemDgfVehiclePropsCondition,
    changeCreateItemDgfVehiclePropsDamagePresence,
    changeCreateItemDgfVehiclePropsDamagedDescription,
}

export default connect(mapStateToProps, mapDispatchToProps)(DgfAnyItems)