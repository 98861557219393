import React from 'react';

import {connect} from 'react-redux';

import TextFull from '../../../elements/viewData/textFull'
import TextFullLink from '../../../elements/viewData/textFullLink'

const RegistryObjectIdString = (props) => {
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('registryObjectId') === true){
            if(props.auctionId.registryObjectId !== null){
                let tmp = null
                let typeZU = null
                if(Number(process.env.REACT_APP_BUILD_STATUS) === 0){
                    tmp = `https://registry.dto.com.ua/lease/object/${props.auctionId.registryObjectId}`
                }else{
                    //tmp = `https://staging-registry.dto.com.ua/lease/object/${props.auctionId.registryObjectId}`
                    tmp = `https://sandbox-registry.dto.com.ua/lease/object/${props.auctionId.registryObjectId}`
                    //tmp = `https://dev-registry.dto.com.ua/lease/object/${props.auctionId.registryObjectId}`
                }
                if(
                    props.auctionId.sellingMethod === "legitimatePropertyLease-english" ||
                    props.auctionId.sellingMethod === "legitimatePropertyLease-english-fast" ||
                    props.auctionId.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                    props.auctionId.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                    props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                    props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                    props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                    props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                    props.auctionId.sellingMethod === "legitimatePropertyLease-dutch" ||
                    props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                    props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                    props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                    props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                    props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                    props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod"
                ){
                    typeZU = <TextFull
                        title="Тип об'єкта переліку"
                        data='Перший'
                    />
                }else if(
                    props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                    props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                    props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                    props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                    props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                    props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                    props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                    props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
                ){
                    typeZU = <TextFull
                        title="Тип об'єкта переліку"
                        data='Невизначено'
                    />
                }
                return (
                    <>
                        <TextFullLink
                            title="Ідентифікатор об'єкта переліку"
                            data={tmp}
                            text={props.auctionId.registryObjectId}
                        />
                        {typeZU}
                    </>
                )
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(RegistryObjectIdString)