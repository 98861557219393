import React from 'react';
import styles from './realEstateItem.module.css';

import {connect} from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '../../../../elements/inputs/checkBox';

import {setDataAddress} from '../../../../../redux/createAddress';

import {
    getMyAuction,
    changeLoader,
} from '../../../../../redux/actions.js';

import {popUpAddress} from '../../../../../redux/actions/address/address.js';
import { DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import ZuMap from '../../../../map/zuMaps'

import {disabletElem} from '../../../disabled'

const RealEstateItem = ( props ) => {

    if(props.auctionsMy.restrictions !== null && props.auctionsMy.restrictions !== ''){
        if(props.createItems.landRental.itemProps.encumbrances === null || props.createItems.landRental.itemProps.encumbrances === ''){
            props.setDataAddress(props.auctionsMy.restrictions, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_ENCUMBRANCES')
        }
    }

    let tmpZuMap = null
    //if(props.createItems.landRental.location !== null){
        /*if(
            (props.createItems.landRental.location.latitude !== null && props.createItems.landRental.location.latitude !== '')  &&
            (props.createItems.landRental.location.longitude !== null && props.createItems.landRental.location.longitude !== '')
        ){*/
            tmpZuMap = <div className={styles.groupElem + ' ' + styles.groupElemCategory + ' ' + styles.groupElemCategoryTwo}>
                <div>
                    <ZuMap
                        latitude = {props.createItems.landRental.location.latitude}
                        longitude = {props.createItems.landRental.location.longitude}
                    />
                </div>
            </div>
        //}
    //}
    let closeDraftElem = false
    if(
        props.auctionsMy.status === null ||
        props.auctionsMy.status === '' ||
        props.auctionsMy.tenderAttempts === '1' ||
        props.auctionsMy.tenderAttempts === 1
    ){
        closeDraftElem = true
    }

    return (
        <>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Тип об'єкта"
                        variant="outlined"
                        value='Земельна ділянка'
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={blokedInput(props, '01')}
                        required
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Опис об'єкта"
                        variant="outlined"
                        multiline
                        rows={10}
                        value={props.createItems.landRental.description}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_DESCRIPTION')
                        }}
                    />
                </div>
            </div>
            {viewMainClassif(props)}
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        disabled={blokedInput(props, '01')}
                        id="outlined-select-currency-native"
                        required
                        select
                        label="Додатковий класифікатор об'єкта"
                        value={props.createItems.landRental.additionalClassifications.id}
                        onChange={(event)=>{
                            props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_CLASSIFICATION')
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATION')
                            loadMainClassif(props, event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Додатковий класифікатор об'єкта"
                        variant="outlined"
                    >
                        <option key='0' selected disabled value="null">Обрати</option>
                        {addClassificationIdOption(props)}
                    </TextField>
                </div>
            </div>
            {props.classificationCVZUArr.length > 0 ? <div className={styles.blokCVZU}>
                <div className={styles.blokCVZUtitle}>Додані значення класифікатора об'єктів CVZU</div> 
                {viewCVZUArr(props)}
            </div> : null}
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        disabled={blokedInput(props, '01')}
                        id="outlined-select-currency-native"
                        required
                        select
                        label="Додатковий класифікатор об'єкта CVZU"
                        value={props.classificationCVZUValue}
                        onChange={(event)=>{
                            let tmp = props.classificationCVZUArr
                            tmp.push(JSON.parse(event.target.value))
                            props.setDataAddress(event.target.value, 'CREATE_CVZU_VALUE')
                            props.setDataAddress(tmp, 'CREATE_CVZU_ARR')
                            props.setDataAddress('null', 'CREATE_CVZU_VALUE')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Додатковий класифікатор об'єкта CVZU"
                        variant="outlined"
                    >
                        <option key='0' selected disabled value="null">Обрати</option>
                        {addClassificationIdOptionDop(props)}
                    </TextField>
                </div>
            </div>
            {/*<div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.landRental.address)}
                        disabled={blokedInput(props, '00')}
                        required
                        id="outlined-select-currency-native"
                        select
                        label="Адреса"
                        value={props.createItems.landRental.address}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Оберіть адресу"
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        {addressArr(props)}
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <Button
                        disabled={blokedInput(props, '00')}
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                props.setDataAddress(true, 'SET_POPUP_ADDRESSES')
                            }
                        }
                    >
                        Створити та редагувати адресу
                    </Button>
                </div>
                    </div>*/}
            
            {tmpZuMap}
            <div className={styles.lineButtons}>
                <div className={styles.editButton}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={
                            ()=>{
                                setNewAddress(props)
                            }
                        }
                    >
                        Додати обрані координати
                    </Button>
                </div>
            </div>
            <div>Адреса</div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled
                        error = {redBlock(props, props.createItems.landRental.addressItem.countryName)}
                        required
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Країна"
                        variant="outlined"
                        value={props.createItems.landRental.addressItem.countryName}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_COUNTRY_NAME')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled
                        error = {redBlock(props.createItems.landRental.addressItem.region)}
                        required
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Область"
                        variant="outlined"
                        value={props.createItems.landRental.addressItem.region}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_REGION')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.landRental.addressItem.locality)}
                        required
                        disabled={blokedInput(props, '11')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Населенний пункт"
                        variant="outlined"
                        value={props.createItems.landRental.addressItem.locality}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_LOCALITY')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.landRental.addressItem.streetAddress)}
                        required
                        disabled={blokedInput(props, '11')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Вулиця, буд. №"
                        variant="outlined"
                        value={props.createItems.landRental.addressItem.streetAddress}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_STREET_ADDRESS')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.landRental.addressItem.postalCode)}
                        required
                        disabled={blokedInput(props, '11')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Поштовий індекс"
                        variant="outlined"
                        value={props.createItems.landRental.addressItem.postalCode}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_POSTAL_CODE')
                        }}
                    />
                </div>
            </div>

            
            <div>Координати</div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.landRental.location.latitude)}
                        disabled
                        id="outlined-select-currency-native"
                        label="Широта"
                        value={props.createItems.landRental.location.latitude}
                        onChange={(event) => {
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE')
                        }}
                        name="numberformat"
                        id="formatted-numberformat-input"
                        variant="outlined"
                        helperText=" "
                    />
                </div>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.landRental.location.longitude)}
                        disabled
                        id="outlined-select-currency-native"
                        label="Довгота"
                        value={props.createItems.landRental.location.longitude}
                        onChange={(event) => {
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE')
                        }}
                        name="numberformat"
                        id="formatted-numberformat-input"
                        variant="outlined"
                        helperText=" "
                    />
                </div>
                {/*<div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        id="outlined-select-currency-native"
                        label="Висота"
                        value={props.createItems.zu.realEstateItem.location.elevation}
                        onChange={(event) => {
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_REAL_ESTATEITEM_LOCATION_ELEVATION')
                        }}
                        name="numberformat"
                        id="formatted-numberformat-input"
                        variant="outlined"
                        helperText=" "
                    />
                </div>*/}
            </div>
            <div>Характеристики земельної ділянки</div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.landRental.itemProps.landArea)}
                        required
                        disabled={blokedInput(props, '11')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Площа ділянки, га"
                        variant="outlined"
                        value={props.createItems.landRental.itemProps.landArea}
                        onChange={(event)=>{
                            if(Number(event.target.value) <= 60362800){
                                let tmp = event.target.value.replace(/,/gi, '.')
                                props.setDataAddress(tmp, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LAND_AREA')
                                props.setDataAddress(tmp, 'CREATE_ITEMS_LAND_RENTAL_QUANTITY')
                            }
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.landRental.itemProps.cadastralNumber)}
                        required
                        disabled={blokedInput(props, '11')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Кадастровий номер"
                        variant="outlined"
                        value={props.createItems.landRental.itemProps.cadastralNumber}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_CADASTRAL_NUMBER')
                        }}
                        helperText="0000000000:00:000:0000"
                    />
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        disabled={blokedInput(props, '00')}
                        id="outlined-select-currency-native"
                        select
                        label="Тип власності"
                        value={props.createItems.landRental.itemProps.ownershipType}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_OWNERSHIP_TYPE')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Оберіть тип власності"
                        variant="outlined"
                    >
                        <option key='0' selected disabled value="null">Обрати</option>
                        <option key='1' selected value="state">Державна</option>
                        <option key='2' selected value="private">Приватна</option>
                        <option key='3' selected value="municipal">Комунальна</option>
                        <option key='4' selected value="compatible">Сумісна</option>
                        {/*<option key='5' selected value="unknown">Невідомо</option>*/}
                    </TextField>
                </div>
            </div>

            <div className={styles.blokInputs}>
            <div className={styles.semiTitle}>Увага! При встановленні відмітки у полі щодо наявності обтяжень/співласників/інженерних мереж обов'язково заповніть відповідні поля з пов'язаною інформацією.</div>
                <div className={styles.groupElem}>
                    <div>
                        <div className={styles.inPutInfo}>
                            <label className={`${styles.check} ${styles.option}`}>
                                <input 
                                    checked={props.createItems.landRental.itemProps.hasEncumbrances}
                                    className={styles.checkInput}
                                    onChange={() => props.setDataAddress(!props.createItems.landRental.itemProps.hasEncumbrances, 'CREATE_ITEMS_DGF_LANDPROPS_HASENCUMBRANCES')}
                                    type='checkbox'
                                >
                                </input>
                                <span className={styles.checkBox}></span>
                            </label>
                            <div className={styles.descript}>Присутні обтяження</div>
                        </div>
                    </div>
                </div>
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            //disabled={blokedInput(props, '11')}
                            disabled={props.createItems.landRental.itemProps.hasEncumbrances ? false : true}
                            className={styles.textAreaWidth}
                            id="outlined-multiline-static"
                            label="Інформація про наявні обтяження"
                            variant="outlined"
                            value={props.createItems.landRental.itemProps.encumbrances}
                            onChange={(event)=>{
                                props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_ENCUMBRANCES')
                            }}
                        />
                    </div>
                </div>
                <div className={styles.groupElem}>
                    <div>
                        <div className={styles.inPutInfo}>
                            <label className={`${styles.check} ${styles.option}`}>
                                <input 
                                    checked={props.createItems.landRental.itemProps.hasJointOwnership}
                                    className={styles.checkInput}
                                    onChange={() => props.setDataAddress(!props.createItems.landRental.itemProps.hasJointOwnership, 'CREATE_ITEMS_DGF_LANDPROPS_HASJOINTOWNERSHIP')}
                                    type='checkbox'
                                >
                                </input>
                                <span className={styles.checkBox}></span>
                            </label>
                            <div className={styles.descript}>Наявні співвласники</div>
                        </div>
                    </div>
                </div>
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            disabled={props.createItems.landRental.itemProps.hasJointOwnership ? false : true}
                            className={styles.textAreaWidth}
                            id="outlined-select-currency-native"
                            label="Інформація про співвласників"
                            variant="outlined"
                            value={props.createItems.landRental.itemProps.jointOwnership}
                            onChange={(event)=>{
                                props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_JOINT_OWNERSHIP')
                            }}
                        />
                    </div>
                </div>
                <div className={styles.groupElem}>
                    <div>
                        <div className={styles.inPutInfo}>
                            <label className={`${styles.check} ${styles.option}`}>
                                <input 
                                    checked={props.createItems.landRental.itemProps.hasUtilitiesAvailability}
                                    className={styles.checkInput}
                                    onChange={() => props.setDataAddress(!props.createItems.landRental.itemProps.hasUtilitiesAvailability, 'CREATE_ITEMS_DGF_LANDPROPS_HASUTILITIESAVAILABILITY')}
                                    type='checkbox'
                                >
                                </input>
                                <span className={styles.checkBox}></span>
                            </label>
                            <div className={styles.descript}>Інженерні мережі наявні</div>
                        </div>
                    </div>
                </div>
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            disabled={props.createItems.landRental.itemProps.hasUtilitiesAvailability ? false : true}
                            className={styles.textAreaWidth}
                            id="outlined-select-currency-native"
                            label="Інформація про інженерні мережі"
                            variant="outlined"
                            value={props.createItems.landRental.itemProps.utilitiesAvailability}
                            onChange={(event)=>{
                                props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_UTILITIES_AVAILABILITY')
                            }}
                        />
                    </div>
                </div>
            </div>



            




            <div className={styles.groupElem}>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.landRental.quantity)}
                        disabled
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Площа земельної ділянки"
                        variant="outlined"
                        value={props.createItems.landRental.quantity}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_QUANTITY')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.landRental.unitCode)}
                        disabled
                        required
                        id="outlined-select-currency-native"
                        select
                        label="Одиниці виміру"
                        value={props.createItems.landRental.unitCode}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_UNIT_CODE')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Оберіть одиницю виміру"
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        {classificationSubsoilUnitCode(props)}
                    </TextField>
                </div>
            </div>
            {viewButtons(props)}
        </>
    )
}

function viewCVZUArr(props){
    let tmp = null
    if(props.classificationCVZUArr.length > 0){
        tmp = props.classificationCVZUArr.map(
            (i, count) => {
                return (
                    <div className={styles.groupElem + ' ' + styles.editButtonTwo}>
                        <div>{`${i.scheme} ${i.classificationId} ${i.description}`}</div>
                        <div className={styles.editButton}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={
                                    ()=>{
                                        let tmp = props.classificationCVZUArr
                                        tmp.splice(count, 1)
                                        props.setDataAddress(null, 'CREATE_CVZU_VALUE')
                                        props.setDataAddress(tmp, 'CREATE_CVZU_ARR')
                                        props.setDataAddress('null', 'CREATE_CVZU_VALUE')
                                    }
                                }
                            >
                                Видалити
                            </Button>
                        </div>
                    </div>
                )
            }
        )
    }
    return tmp
}

function setNewAddress(props) {
    let map = window.parent.document.getElementById("target").contentWindow.document.getElementById('map');
    //let iframe = document.querySelector('iframe')
    //let iframeDocument = iframe.querySelector('iframe')
    //let map2 = map.getElementById('map')
    let tmpLat = map.dataset.lat.split('.')
    let tmpLng = map.dataset.lng.split('.')
    let newAddress = {
        //address: `${map.dataset.city !== '' ? map.dataset.city : ''}${map.dataset.street !== '' ? ', ' + map.dataset.street : ''}${map.dataset.house !== '' ? ', будинок ' + map.dataset.house + ',' : ''}`,
        address: `${map.dataset.street !== '' ? map.dataset.street : ''}${map.dataset.house !== '' ? ', будинок ' + map.dataset.house + ',' : ''}`,
        postcode: map.dataset.postcode,
        region: map.dataset.region,
        //lat: map.dataset.lat,
        lat: `${tmpLat[0]}.${tmpLat[1].slice(0, 5)}`,
        //lng: map.dataset.lng,
        lng: `${tmpLng[0]}.${tmpLng[1].slice(0, 5)}`,
        locality: map.dataset.city,
        street: map.dataset.street
    }
    if (map.dataset.country === 'Україна' || map.dataset.country === 'Донецька область'){
        if(map.dataset.country === 'Донецька область'){
            newAddress.country = 'Україна'
        }else{
            newAddress.country = map.dataset.country
        }
    }
    props.setDataAddress(newAddress.country, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_COUNTRY_NAME')
    props.setDataAddress(newAddress.region, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_REGION')
    props.setDataAddress(newAddress.locality, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_LOCALITY')
    props.setDataAddress(newAddress.address, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_STREET_ADDRESS')
    props.setDataAddress(newAddress.postcode, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_POSTAL_CODE')

    props.setDataAddress(newAddress.lat, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE')
    props.setDataAddress(newAddress.lng, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE')
}


function viewMainClassif(props){
    switch(props.auctionsMy.sellingMethod){
        case 'landRental-english':
        case 'landRental-english-fast':
        case 'landRental-english-fast-manual':
        case 'landRental-english-fast-auction-manual-qualification':
        case 'landRental-english-initial-auction':
        case 'landRental-english-initial-auction-manual':
        case 'landRental-english-initial-qualification':
        case 'landRental-english-initial-qualification-prod':
            return (
                <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                    <div>
                        <TextField
                            disabled={openMainClassif(props)}
                            id="outlined-select-currency-native"
                            select
                            label="Основний класифікатор"
                            value={props.createItems.landRental.classification}
                            onChange={(event)=>{
                                props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_CLASSIFICATION')
                            }}
                            SelectProps={{
                                native: true,
                            }}
                            helperText="Основний класифікатор"
                            variant="outlined"
                        >
                            <option key='0' selected disabled value="null">Обрати</option>
                            {classificationIdOption(props)}
                        </TextField>
                    </div>
                </div>
            )
        case 'landSell-english':
        case 'landSell-english-fast':
        case 'landSell-english-fast-manual':
        case 'landSell-english-fast-auction-manual-qualification':
        case 'landSell-english-initial-auction':
        case 'landSell-english-initial-auction-manual':
        case 'landSell-english-initial-qualification':
        case 'landSell-english-initial-qualification-prod':
        case 'landSell-priorityEnglish':
        case 'landSell-priorityEnglish-fast':
        case 'landSell-priorityEnglish-fast-manual':
        case 'landSell-priorityEnglish-fast-auction-manual-qualification':
        case 'landSell-priorityEnglish-initial-auction':
        case 'landSell-priorityEnglish-initial-auction-manual':
        case 'landSell-priorityEnglish-initial-qualification':
        case 'landSell-priorityEnglish-initial-qualification-prod':
            return (
                <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                    <div>
                        <TextField
                            disabled
                            id="outlined-select-currency-native"
                            select
                            label="Основний класифікатор"
                            value={props.createItems.landRental.classification}
                            onChange={(event)=>{
                                props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_CLASSIFICATION')
                            }}
                            SelectProps={{
                                native: true,
                            }}
                            helperText="Основний класифікатор"
                            variant="outlined"
                        >
                            <option key='0' selected disabled value="null">Обрати</option>
                            {classificationIdOption(props)}
                        </TextField>
                    </div>
                </div>
            )
    }
}

async function loadMainClassif(props, data){
    let tmpData = null
    if(data !== null && data !== 'null'){
        data = data.split('"')
        if(data[7].indexOf('.') !== -1){
            let tmp1 = data[7].split('.')
            tmpData = tmp1[0]
        }else{
            tmpData = data[7]
        }
    }
    switch(tmpData){
        case "01":
            getDictionaryJointPropertyComplex(props, '06121000-6')
            break
        case "02":
            getDictionaryJointPropertyComplex(props, '06111000-3')
            break
        case "03":
            getDictionaryJointPropertyComplex(props, '06112000-0')
            break
        case "04":
        case "05":
            getDictionaryJointPropertyComplex(props, '06122000-3')
            break
        case "06":
            getDictionaryJointPropertyComplex(props, '06123000-0')
            break
        case "07":
            getDictionaryJointPropertyComplex(props, '06124000-7')
            break
        case "08":
            getDictionaryJointPropertyComplex(props, '06125000-4')
            break
        case "09":
            getDictionaryJointPropertyComplex(props, '06126000-1')
            break
        case "10":
            getDictionaryJointPropertyComplex(props, '06127000-8')
            break
        case "11":
        case "12":
        case "13":
        case "14":
        case "15":
            getDictionaryJointPropertyComplex(props, '06128000-5')
            break
        case "16":
        case "17":
        case "18":
        case "19":
            getDictionaryJointPropertyComplex(props, '06129000-2')
            break
        default:
            break
    }
}

async function getDictionaryJointPropertyComplex(props, mask){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/autocomplete/classification_id?limit=1000&offset=0&classification_id=${mask}&scheme=CAV&mask=${mask}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        let json = await response.json()
        props.setDataAddress(json[0].id, 'CREATE_ITEMS_LAND_RENTAL_CLASSIFICATION')
        props.setDataAddress(json, 'SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD')
    }
}


/////////////////////////////////////////////////////////

function openMainClassif(props){
    if(props.createItems.landRental.additionalClassifications.id !== 'null' && props.createItems.landRental.additionalClassifications.id !== null){
        return blokedInput(props, '01')
    }else{
        return 'disablaed'
    }
}

function blokedInput(props, type){
    if(props.createItems.landRental.id !== null){
        return disabletElem(props.auctionsMy, type)
    }else if(
        props.auctionsMy.items === null ||
        props.auctionsMy.items.length === 0
    ){
        return disabletElem(props.auctionsMy, type)
    }else{
        return 'disabled'
    }
}

function classificationIdOption(props){
    if(props.classificationsDgf.classificationsByTypeMain !== null){
        return props.classificationsDgf.classificationsByTypeMain.map(
            (i, count) => {
                return <option key={count} value={i.id}>{i.scheme + ' ' + i.classificationId + ' ' + i.description}</option>
            }
        )
    }
}
function addClassificationIdOption(props){
    if(props.createItems !== null){
        if(props.createItems.landRental !== null){
            if(props.createItems.landRental.additionalClassificationsArr !== null){
                return props.createItems.landRental.additionalClassificationsArr.map(
                    (i, count) => {
                        return <option key={count} value={JSON.stringify({scheme: 'kvtspz', classificationId: i.name})}>{i.description.uk_UA}</option>
                    }
                )
            }
        }
    }
}

function addClassificationIdOptionDop(props){
    if(props.classificationCVZU !== null){
        return props.classificationCVZU.map(
            (i, count) => {
                return <option key={count} value={JSON.stringify({scheme: 'CVZU', classificationId: i.id, description: i.description.uk_UA})}>{i.description.uk_UA}</option>
            }
        )
    }
}

function redBlock(amount){
    if(amount === null || amount === '' || amount === 'null'){
        return 'error'
    }
    return null
}

function viewButtons(props){
    if(
        props.auctionsMy.items === null ||
        props.auctionsMy.items.length === 0
    ){
        if(
            props.createItems.landRental.description !== '' &&
            props.createItems.landRental.additionalClassifications.id !== 'null' &&
            //props.createItems.landRental.address !== 'null' &&
            props.createItems.landRental.itemProps.landArea !== '' &&
            props.createItems.landRental.itemProps.cadastralNumber !== ''
        ){
            if(props.auctionsMy.items === null || props.auctionsMy.items.length === 0){
                return (
                    <div className={styles.lineButtons}>
                        <div className={styles.editButton}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={
                                    () => {
                                        createItemZU(props)
                                    }
                                }
                            >
                                Зберегти зміни
                            </Button>
                        </div>
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={
                                    () => {
                                        props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_DESCRIPTION')
                                        props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_CLASSIFICATION')
                                        props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATION')
                                        props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_ADDRESS')
                                        props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE')
                                        props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE')
                                        props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LAND_AREA')
                                        props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_CADASTRAL_NUMBER')
                                        props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_OWNERSHIP_TYPE')
                                        props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_ENCUMBRANCES')
                                        props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_JOINT_OWNERSHIP')
                                        props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_UTILITIES_AVAILABILITY')
                                        props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_QUANTITY')
                                        //props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_UNIT_CODE')
                                    }
                                }
                            >
                                Закрити
                            </Button>
                        </div>
                    </div>
                )
            }
        }
    }else{
        if(props.createItems.landRental.id !== null){
            if(
                props.createItems.landRental.description !== '' &&
                props.createItems.landRental.additionalClassifications.id !== 'null' &&
                //props.createItems.landRental.address !== 'null' &&
                props.createItems.landRental.itemProps.landArea !== '' &&
                props.createItems.landRental.itemProps.cadastralNumber !== ''
            ){
                return (
                    <div className={styles.lineButtons}>
                        <div className={styles.editButton}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={
                                    () => {
                                        if(props.createItems.landRental.id === null){
                                            createItemZU(props)
                                        }else{
                                            saveItemZU(props)
                                        }
                                    }
                                }
                            >
                                Зберегти зміни
                            </Button>
                        </div>
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={
                                    () => {
                                        props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_DESCRIPTION')
                                        props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_CLASSIFICATION')
                                        props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATION')
                                        props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_ADDRESS')
                                        props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE')
                                        props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE')
                                        props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LAND_AREA')
                                        props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_CADASTRAL_NUMBER')
                                        props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_OWNERSHIP_TYPE')
                                        props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_ENCUMBRANCES')
                                        props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_JOINT_OWNERSHIP')
                                        props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_UTILITIES_AVAILABILITY')
                                        props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_QUANTITY')
                                        //props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_UNIT_CODE')
                                    }
                                }
                            >
                                Закрити
                            </Button>
                        </div>
                    </div>
                )
            }
        }
    }
}

function textData(value){
    let tmp = ''
    if(value !== ''){
        tmp = value.split('T');
        return tmp[0]
    }
    return tmp[0]
}

function elementDisabled(props){
    let tmpStatus = null;
    if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            tmpStatus = 'disabled'
        }
    }
    return tmpStatus
}

function classificationSubsoilUnitCode(props){
    if(props.classificationsZU.unitCode !== null){
        return props.classificationsZU.unitCode
        .filter(
            (i) => {
                return i.code === 'HAR'
            }
        )
        .map(
            (i, count) => {
                return <option key={count} value={i.code}>{i.name}</option>
            }
        )
    }
}

function addressArr(props){
    if(props.addressGetId !== null){
        return props.addressGetId.map(
            (i, count) => {
                return <option key={count} value={i.id}>{i.postalCode + ' ' + i.region + ' ' + i.locality + ' ' + i.streetAddress}</option>
            }
        )
    }
}

function elementDisabledCreate(props){
    let tmpStatus = null;
    if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            tmpStatus = 'disabled'
        }
    }
    return tmpStatus
}


function createSaveArrCVZU(props){
    let tmp = []
    if(props.classificationCVZUArr.length > 0){
        tmp = props.classificationCVZUArr.map(
            (i) => {
                return {scheme: 'CVZU', classificationId: i.classificationId}
            }
        )
    }
    return tmp
}

async function createItemZU(props){
    props.changeLoader(true)
    //let tmpAdress = await createAddress(props)
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}/item`;
            const response = await fetch(endPoint, {
                method: "POST",
                body: JSON.stringify({
                    //title: props.createItems.landRental.title,
                    description: props.createItems.landRental.description,
                    classificationId: Number(props.createItems.landRental.classification),
                    additionalClassifications: [JSON.parse(props.createItems.landRental.additionalClassifications.id), ...createSaveArrCVZU(props)],
                    //addressId: tmpAdress,
                    address: {
                        addressIdentifier: {
                            scheme: 'koatuu',
                            identifier: setKoatuu(props.createItems.landRental.addressItem.region),
                            name: props.createItems.landRental.addressItem.region
                        },
                        streetAddress: props.createItems.landRental.addressItem.streetAddress,
                        locality: props.createItems.landRental.addressItem.locality,
                        region: props.createItems.landRental.addressItem.region,
                        postalCode: props.createItems.landRental.addressItem.postalCode,
                        countryName: props.createItems.landRental.addressItem.countryName
                    },
                    location: {
                        latitude: Number(props.createItems.landRental.location.latitude),
                        longitude: Number(props.createItems.landRental.location.longitude),
                        //elevation: props.createItems.zu.realEstateItem.location.elevation
                    },
                    quantity: props.createItems.landRental.itemProps.landArea,
                    unitCode: props.createItems.landRental.unitCode,
                }),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
                props.changeLoader(false)
            }else{
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    props.changeLoader(false)
                    let tmp = JSON.stringify({title: 'Помилка при створенні лоту', message: JSON.stringify(json)})
                    props.setUniversalError(tmp)
                }else{
                    await saveItemZuVehicleProps(props, json.id, 'POST')
                    //props.setDataAddress('null', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
                }
            }
        }
    }
    await props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_DESCRIPTION')
    await props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_CLASSIFICATION')
    await props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATION')
    await props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_ADDRESS')
    await props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE')
    await props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE')
    await props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LAND_AREA')
    await props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_CADASTRAL_NUMBER')
    await props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_OWNERSHIP_TYPE')
    await props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_ENCUMBRANCES')
    await props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_JOINT_OWNERSHIP')
    await props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_UTILITIES_AVAILABILITY')
    await props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_QUANTITY')
    await props.setDataAddress([], 'CREATE_CVZU_ARR')
    //await props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_UNIT_CODE')
}

async function saveItemZU(props){
    props.changeLoader(true)
    //let tmpAdress = await createAddress(props)
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}/item/${props.createItems.landRental.id}`;
            const response = await fetch(endPoint, {
                method: "PUT",
                body: JSON.stringify({
                    //title: props.createItems.zu.realEstateItem.title,
                    description: props.createItems.landRental.description,
                    classificationId: Number(props.createItems.landRental.classification),
                    additionalClassifications: [JSON.parse(props.createItems.landRental.additionalClassifications.id), ...createSaveArrCVZU(props)],
                    //addressId: tmpAdress,//Number(props.createItems.landRental.address),
                    address: {
                        addressIdentifier: {
                            scheme: 'koatuu',
                            identifier: setKoatuu(props.createItems.landRental.addressItem.region),
                            name: props.createItems.landRental.addressItem.region
                        },
                        streetAddress: props.createItems.landRental.addressItem.streetAddress,
                        locality: props.createItems.landRental.addressItem.locality,
                        region: props.createItems.landRental.addressItem.region,
                        postalCode: props.createItems.landRental.addressItem.postalCode,
                        countryName: props.createItems.landRental.addressItem.countryName
                    },
                    location: {
                        latitude: Number(props.createItems.landRental.location.latitude),
                        longitude: Number(props.createItems.landRental.location.longitude),
                        //elevation: props.createItems.zu.realEstateItem.location.elevation
                    },
                    quantity: props.createItems.landRental.itemProps.landArea,
                    unitCode: props.createItems.landRental.unitCode,

                }),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
                props.changeLoader(false)
            }else{
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    props.changeLoader(false)
                    let tmp = JSON.stringify({title: 'Помилка при оновленні лоту', message: JSON.stringify(json)})
                    props.setUniversalError(tmp)
                }else{
                    await saveItemZuVehicleProps(props, json.id, 'POST')
                    //props.setDataAddress('null', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')

                }
            }
        }
    }
    await props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_DESCRIPTION')
    await props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_CLASSIFICATION')
    await props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATION')
    await props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_ADDRESS')
    await props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE')
    await props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE')
    await props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LAND_AREA')
    await props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_CADASTRAL_NUMBER')
    await props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_OWNERSHIP_TYPE')
    await props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_ENCUMBRANCES')
    await props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_JOINT_OWNERSHIP')
    await props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_UTILITIES_AVAILABILITY')
    await props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_QUANTITY')
    await props.setDataAddress([], 'CREATE_CVZU_ARR')
    //await props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_UNIT_CODE')
    props.setDataAddress(false, 'CREATE_ITEMS_DGF_LANDPROPS_HASENCUMBRANCES')
    props.setDataAddress(false, 'CREATE_ITEMS_DGF_LANDPROPS_HASJOINTOWNERSHIP')
    props.setDataAddress(false, 'CREATE_ITEMS_DGF_LANDPROPS_HASUTILITIESAVAILABILITY')
}

async function saveItemZuVehicleProps(props, itemId, flag){
    let body = {}
    if(props.createItems.landRental.itemProps.landArea !== '') body.landArea = props.createItems.landRental.itemProps.landArea
    if(props.createItems.landRental.itemProps.cadastralNumber !== '') body.cadastralNumber = props.createItems.landRental.itemProps.cadastralNumber
    if(props.createItems.landRental.itemProps.ownershipType !== 'null') body.ownershipType = props.createItems.landRental.itemProps.ownershipType
    

    body.hasEncumbrances = props.createItems.landRental.itemProps.hasEncumbrances
    if(props.createItems.landRental.itemProps.hasEncumbrances){
        body.encumbrances = props.createItems.landRental.itemProps.encumbrances
    }
    body.hasJointOwnership = props.createItems.landRental.itemProps.hasJointOwnership
    if(props.createItems.landRental.itemProps.hasJointOwnership){
        body.jointOwnership = props.createItems.landRental.itemProps.jointOwnership
    }
    body.hasUtilitiesAvailability = props.createItems.landRental.itemProps.hasUtilitiesAvailability
    if(props.createItems.landRental.itemProps.hasUtilitiesAvailability){
        body.utilitiesAvailability = props.createItems.landRental.itemProps.utilitiesAvailability
    }
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}/item/${itemId}/land_props`;
    const response = await fetch(endPoint, {
        method: flag,
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        props.changeLoader(false)
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            props.changeLoader(false)
            let tmp = JSON.stringify({title: 'Помилка при збереженні доданих даних', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            let header = {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
            const endPoint2t = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}`;
            const response2t = await fetch(endPoint2t, {
                    method: "GET",
                    headers: header
            }).catch(console.error)
            if(response2t === undefined || response2t === 'undefined'){
                props.changeLoader(false)
            }else{
                const json2t = await response2t.json()
                if(json2t.hasOwnProperty('message') === true){
                    props.changeLoader(false)
                    let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json2t)})
	                props.setUniversalError(tmp)
                }else{
                    props.getMyAuction(json2t)
                    props.changeLoader(false)
                }
            }
        }
    }
}

function setKoatuu(data){
	switch(data){
        case 'Севастополь': return "8500000000"
        case 'Київ': return "8000000000"
        case 'Чернігівська область': return "7400000000"
        case 'Чернівецька область': return "7300000000"
        case 'Черкаська область': return "7100000000"
        case 'Хмельницька область': return "6800000000"
        case 'Херсонська область': return "6500000000"
        case 'Харківська область': return "6300000000"
        case 'Тернопільська область': return "6100000000"
        case 'Сумська область': return "5900000000"
        case 'Рівненська область': return "5600000000"
        case 'Полтавська область': return "5300000000"
        case 'Одеська область': return "5100000000"
        case 'Миколаївська область': return "4800000000"
        case 'Львівська область': return "4600000000"
        case 'Луганська область': return "4400000000"
        case 'Кіровоградська область': return "3500000000"
        case 'Київська область': return "3200000000"
        case 'Івано-Франківська область': return "2600000000"
        case 'Запорізька область': return "2300000000"
        case 'Закарпатська область': return "2100000000"
        case 'Житомирська область': return "1800000000"
        case 'Донецька область': return "1400000000"
        case 'Дніпропетровська область': return "1200000000"
        case 'Волинська область': return "0700000000"
        case 'Вінницька область': return "0500000000"
        case 'Автономна Республіка Крим': return "0100000000"
	}
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        auctionsMy: state.start.auctionsMy,
        createItems: state.start.createItems,
        dictionaries: state.start.dictionaries,
        addressGetId: state.start.addressGetId,
        classificationsNadraEnglish: state.start.classificationsNadraEnglish,
        userProfiles: state.start.userProfiles,
        classificationsDgf: state.start.classificationsDgf,
        classificationsZU: state.start.classificationsZU,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
        classificationCVZU: state.start.classificationCVZU,
        classificationCVZUArr: state.start.classificationCVZUArr,
        classificationCVZUValue: state.start.classificationCVZUValue,
    }
}

const mapDispatchToProps = {
    setDataAddress,
    changeLoader,
    getMyAuction,
    popUpAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(RealEstateItem)