import React from 'react';

import {connect} from 'react-redux';

import TextHalf from '../../../elements/viewData/textHalf'

import { isLAP, isLRE, isLSE, isLSP } from '../../../../redux/aucTypes';

const MinNumberOfQualifiedBidsString = (props) => {
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('minNumberOfQualifiedBids') === true){
            if(props.auctionId.minNumberOfQualifiedBids !== null){
                return (
                    <TextHalf
                        title={
                            isLAP(props.auctionId.sellingMethod) 
                            || isLRE(props.auctionId.sellingMethod) 
                            || isLSE(props.auctionId.sellingMethod) 
                            || isLSP(props.auctionId.sellingMethod) 
                                ? "Мінімальна кількість учасників" 
                                : "Мінімальна кількість заяв"
                        }
                        data={props.auctionId.minNumberOfQualifiedBids}
                    />
                )
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(MinNumberOfQualifiedBidsString)