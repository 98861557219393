import React from 'react';
import styles from './bankLot.module.css';

import LineTwoElemString from '../lineTwoElemString/lineTwoElemString.js';
import YellowBlock from './yellowBlock/yellowBlock.js';
import BlueBlockRegistr from './blueBlockRegistr/blueBlockRegistr.js';
import GreyLineText from './greyLineText/greyLineText.js';

import {connect} from 'react-redux';

import BlueBlockPayment from './blueBlockPayment/blueBlockPayment.js';

import {modifiDateString} from '../../../redux/modifiDate'

const BankLot = (props) => {
    let blueBlok = null;
    if(props.token === null){
        blueBlok = <BlueBlockRegistr />
    }
    if(props.auction !== null){
        if(props.auction.status === 'unsuccessful' || props.auction.status === 'cancelled' || props.auction.status === 'complete'){
            blueBlok = null
        }
    }

    let startAuc = null;
    if(props.auction !== null){
        startAuc = modifiDateString(props.auction.createdAt, 3, false);
    }



    let name = null;
    let adress = null;
    if(props.profile !== null){
        if(props.profile.organization !== null){
            name = props.profile.organization.name
            if(props.profile.organization.address !== null){
                adress = props.profile.organization.address.postalCode + ', ' +
                    props.profile.organization.address.countryName + ', ' +
                    props.profile.organization.address.locality + ', ' +
                    props.profile.organization.address.region + ', ' +
                    props.profile.organization.address.streetAddress
            }
        }
    }

    let auctionStart = null;
    let companyName = null;
    let companyEDRPOU = null;
    let companyAddress = null;
    if(props.auction !== null){
        for (let key in props.auction.auctionPeriods) {
            if(props.auction.auctionPeriods[key].status === 'auctionPeriod'){
                auctionStart = modifiDateString(props.auction.auctionPeriods[key].startDate, 3, false)
            }
        }
        if(props.hasOwnProperty('item')){
            if(props.item !== null){
                if(props.item.hasOwnProperty('sellingEntity')){
                    if(props.item.sellingEntity !== null){
                        if(props.item.sellingEntity.hasOwnProperty('name')){ 
                            companyName = props.auction.sellingEntity.name
                        }
                        if(props.item.sellingEntity.hasOwnProperty('identifier')){
                            if(props.item.sellingEntityidentifier !== null){
                                companyEDRPOU = props.auction.sellingEntity.identifier.identifier
                            }
                        }
                        if(props.item.sellingEntity.hasOwnProperty('address')){
                            if(props.item.sellingEntity.address !== null){
                                companyAddress = props.auction.sellingEntity.address.postalCode + ', ' +
                                    props.auction.sellingEntity.address.countryName + ', ' +
                                    props.auction.sellingEntity.address.region + ', ' +
                                    props.auction.sellingEntity.address.streetAddress
                            }
                        }
                    }
                }
            }
        }
    }
    if(props.auction !== null){
        if(
            props.auction.sellingMethod === "legitimatePropertyLease-english" ||
            props.auction.sellingMethod === "legitimatePropertyLease-english-fast" ||
            props.auction.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
            props.auction.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
            props.auction.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
            props.auction.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
            props.auction.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
            props.auction.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
            props.auction.sellingMethod === "legitimatePropertyLease-dutch" ||
            props.auction.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
            props.auction.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
            props.auction.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
            props.auction.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
            props.auction.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
            props.auction.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod"
        ){
            companyName = props.auction.relatedOrganizations.sellingEntity.name
            companyEDRPOU = props.auction.relatedOrganizations.sellingEntity.identifier.identifier
            companyAddress = props.auction.relatedOrganizations.sellingEntity.address.postalCode + ', ' +
                                    props.auction.relatedOrganizations.sellingEntity.address.countryName + ', ' +
                                    props.auction.relatedOrganizations.sellingEntity.address.region + ', ' +
                                    props.auction.relatedOrganizations.sellingEntity.address.streetAddress
        }
    }
    return (
        <div>
            <h3 className={styles.terminuTitle}>Інформація про Організатора</h3>
            <div className={styles.bank}>
                <div className={styles.bankText}>
                    <LineTwoElemString 
                        titleString='Найменування організатора'
                        textString={companyName}
                    />
                    <LineTwoElemString 
                        titleString='Код ЄДРПОУ або ІПН або паспорт'
                        textString={companyEDRPOU}
                    />
                    <LineTwoElemString 
                        titleString='Юридична адреса'
                        textString={companyAddress}
                    />
                </div>
                <div className={styles.baseSize}>
                    <div>
                        {/*<GreyLineText
                            title='АУКЦІОН ОГОЛОШЕНО'
                            date={startAuc}
                        />*/}
                        {vievBlokStartAuction(startAuc)}
                        {vievBlokRectificationPeriod(props.auction)}
                        {/*vievBlokEnquiryPeriod(props.auction)*/}
                        {vievBlokQuestionPeriod(props.auction)}
                        {vievBlokTenderPeriod(props.auction)}
                        {vievBlokAuctionPeriod(props.auction)}
                        {vievBlokQualificationPeriod(props.auction)}
                        {vievBlokFailAuccion(props.auction)}
                        {vievBlokFailAuccionCancel(props.auction)}
                        {vievBlokCompliteAuccion(props.auction)}
                        {blueBlok}
                        {viewBlockPayment(props)}
                    </div>
                </div>
            </div>
        </div>
    )
}

function viewBlockPayment(props){
    let tmp = null
    if(props.auction !== null && props.token !== null){
        if(props.auction.status === 'active_tendering'){
            tmp = <BlueBlockPayment />
        }else{
            if(
                props.auction.sellingMethod === "railwayCargo-dutch" ||
                props.auction.sellingMethod === "railwayCargo-dutch-fast" ||
                props.auction.sellingMethod === "railwayCargo-dutch-fast-manual" ||
                props.auction.sellingMethod === "railwayCargo-dutch-initial-auction" ||
                props.auction.sellingMethod === "railwayCargo-dutch-initial-qualification" ||
                props.auction.sellingMethod === "railwayCargo-dutch-fast-auction-manual-qualification" ||
                props.auction.sellingMethod === "railwayCargo-dutch-initial-auction-manual" ||
                props.auction.sellingMethod === "subsoil-dutch" ||
                props.auction.sellingMethod === "subsoil-dutch-fast" ||
                props.auction.sellingMethod === "subsoil-dutch-fast-manual" ||
                props.auction.sellingMethod === "subsoil-dutch-initial-auction" ||
                props.auction.sellingMethod === "subsoil-dutch-initial-qualification" ||
                props.auction.sellingMethod === "subsoil-dutch-initial-auction-manual" ||
                props.auction.sellingMethod === "subsoil-english-fast-auction-manual-qualification" ||
                props.auction.sellingMethod === "dgf-dutch" ||
                props.auction.sellingMethod === "dgf-dutch-fast" ||
                props.auction.sellingMethod === "dgf-dutch-fast-manual" ||
                props.auction.sellingMethod === "dgf-dutch-fast-auction-manual-qualification" ||
                props.auction.sellingMethod === "dgf-dutch-initial-auction" ||
                props.auction.sellingMethod === "dgf-dutch-initial-qualification" ||
                props.auction.sellingMethod === "dgf-dutch-initial-auction-manual" ||
                props.auction.sellingMethod === "legitimatePropertyLease-dutch" ||
                props.auction.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                props.auction.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                props.auction.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                props.auction.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                props.auction.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                props.auction.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod"
            ){
                tmp = <BlueBlockPayment />
            }
        }
    }
    return tmp
}

function vievBlokStartAuction(start){
    return (
        <div className={styles.offBlock}>
            <div className={styles.titleDate}><span>Аукціон оголошено:</span>{start}</div>
        </div>
    )
}

function vievBlokRectificationPeriod(auction){
    let tmp = null;
    let tmpStatus = null;
    if(auction !== null){
        if(auction.auctionPeriods.length > 0){
            for(let i = 0; i < auction.auctionPeriods.length; i++){
                if(auction.auctionPeriods[i].status === 'rectificationPeriod'){
                    let start = null
                    let end = null
                    if(auction.auctionPeriods[i].startDate !== null){
                        start = modifiDateString(auction.auctionPeriods[i].startDate, 3, false)
                    }
                    if(auction.auctionPeriods[i].endDate !== null){
                        end = modifiDateString(auction.auctionPeriods[i].endDate, 3, false)
                    }
                    if(auction.status === 'active_rectification'){
                        tmp = <div className={styles.yellowBlock}>
                            <div className={styles.titleDate}>Період уточнень</div>
                            <div><span>Початок:</span>{start}</div>
                            <div><span>Закінчення:</span>{end}</div>
                        </div>
                    }else{
                        tmp = <div className={styles.offBlock}>
                            <div className={styles.titleDate}>Період уточнень</div>
                            <div><span>Початок:</span>{start}</div>
                            <div><span>Закінчення:</span>{end}</div>
                        </div>
                    }
                }
            }
        }
    }
    return tmp
}

function vievBlokEnquiryPeriod(auction){
    let tmp = null;
    if(auction !== null){
        if(auction.auctionPeriods.length > 0){
            for(let i = 0; i < auction.auctionPeriods.length; i++){
                if(auction.auctionPeriods[i].status === 'enquiryPeriod'){
                    let start = null
                    let end = null
                    let tmpStart = null
                    if(auction.auctionPeriods[i].startDate !== null){
                        start = modifiDateString(auction.auctionPeriods[i].startDate, 3, false)
                        tmpStart = new Date(auction.auctionPeriods[i].startDate)
                    }
                    if(auction.auctionPeriods[i].endDate !== null){
                        end = modifiDateString(auction.auctionPeriods[i].endDate, 3, false)
                    }
                    //Тут сделать проверку по таймстампу ане по статусу
                    //if(auction.status === 'active_enquiry'){
                    
                    if(tmpStart !== null/* && end !== null*/){
                        if(tmpStart.getTime() < Date.now()){
                            tmp = <div className={styles.yellowBlock}>
                                <div className={styles.titleDate}>enquiryPeriod</div>
                                <div>Початок: {start}</div>
                                <div>Закінчення: {end}</div>
                            </div>
                        }else{
                            tmp = <div className={styles.offBlock}>
                                <div className={styles.titleDate}>enquiryPeriod</div>
                                <div>Початок: {start}</div>
                                <div>Закінчення: {end}</div>
                            </div>    
                        }
                    }else{
                        tmp = <div className={styles.offBlock}>
                            <div className={styles.titleDate}>enquiryPeriod</div>
                            <div>Початок: {start}</div>
                            <div>Закінчення: {end}</div>
                        </div>
                    }
                }
            }
        }
    }
    return tmp
}

function vievBlokTenderPeriod(auction){
    let tmp = null;
    if(auction !== null){
        if(auction.auctionPeriods.length > 0){
            for(let i = 0; i < auction.auctionPeriods.length; i++){
                if(auction.auctionPeriods[i].status === 'tenderPeriod'){
                    let start = null
                    let end = null
                    if(auction.auctionPeriods[i].startDate !== null){
                        start = modifiDateString(auction.auctionPeriods[i].startDate, 3, false)
                    }
                    if(auction.auctionPeriods[i].endDate !== null){
                        end = modifiDateString(auction.auctionPeriods[i].endDate, 3, false)
                    }
                    if(auction.status === 'active_tendering'){
                        tmp = <div className={styles.yellowBlock}>
                            <div className={styles.titleDate}>Період подання пропозицій</div>
                            <div><span>Початок:</span>{start}</div>
                            <div><span>Закінчення:</span>{end}</div>
                        </div>
                        break
                    }else{
                        tmp = <div className={styles.offBlock}>
                            <div className={styles.titleDate}>Період подання пропозицій</div>
                            <div><span>Початок:</span>{start}</div>
                            <div><span>Закінчення:</span>{end}</div>
                        </div>
                    }
                }
            }
        }
    }
    return tmp
}

function vievBlokAuctionPeriod(auction){
    let tmp = null;
    if(auction !== null){
        if(auction.auctionPeriods.length > 0){
            for(let i = 0; i < auction.auctionPeriods.length; i++){
                if(auction.auctionPeriods[i].status === 'auctionPeriod'){
                    let start = null
                    if(auction.auctionPeriods[i].startDate !== null){
                        start = modifiDateString(auction.auctionPeriods[i].startDate, 3, false)
                    }
                    if(auction.status === 'active_auction'){
                        tmp = <div className={styles.yellowBlock}>
                            <div className={styles.titleDate}>Період аукціону</div>
                            <div><span>Початок:</span>{start}</div>
                        </div>
                    }else{
                        tmp = <div className={styles.offBlock}>
                            <div className={styles.titleDate}>Період аукціону</div>
                            <div><span>Початок:</span>{start}</div>
                        </div>
                    }
                }
            }
        }
    }
    return tmp
}

function vievBlokQuestionPeriod(auction){
    let tmp = null;
    if(auction !== null){
        if(auction.auctionPeriods.length > 0){
            for(let i = 0; i < auction.auctionPeriods.length; i++){
                if(auction.auctionPeriods[i].status === 'questionPeriod'){
                    let start = null
                    let end = null
                    let dateChatStart = null
                    let dateChatEnd = null
                    if(auction.auctionPeriods[i].startDate !== null){
                        dateChatStart = new Date(auction.auctionPeriods[i].startDate)
                        start = modifiDateString(auction.auctionPeriods[i].startDate, 3, false)
                    }
                    if(auction.auctionPeriods[i].endDate !== null){
                        dateChatEnd = new Date(auction.auctionPeriods[i].endDate)
                        end = modifiDateString(auction.auctionPeriods[i].endDate, 3, false)
                    }
                    let timePoyas = ((1000 * 60) * 60) * 2
                    //let tmpStart = dateChatStart.getTime() + timePoyas
                    //let tmpEnd = dateChatEnd.getTime() + timePoyas
                    let tmpStart = dateChatStart.getTime()
                    let tmpEnd = dateChatEnd.getTime()
                    if(tmpStart < Date.now() && tmpEnd > Date.now()){
                        tmp = <div className={styles.yellowBlock}>
                            <div className={styles.titleDate}>Період подання запитань</div>
                            <div><span>Початок:</span>{start}</div>
                            <div><span>Закінчення:</span>{end}</div>
                        </div>
                    }else{
                        tmp = <div className={styles.offBlock}>
                            <div className={styles.titleDate}>Період подання запитань</div>
                            <div><span>Початок:</span>{start}</div>
                            <div><span>Закінчення:</span>{end}</div>
                        </div>
                    }
                }
            }
        }
    }
    return tmp
}

function vievBlokQualificationPeriod(auction){
    let tmp = null;
    if(auction !== null){
        if(auction.auctionPeriods.length > 0){
            for(let i = 0; i < auction.auctionPeriods.length; i++){
                if(auction.status === 'active_qualification'){
                    let start = null
                    if(auction.auctionPeriods[i].startDate !== null){
                        start = modifiDateString(auction.auctionPeriods[i].startDate, 3, false)
                    }
                    tmp = <div className={styles.yellowBlock}>
                        <div className={styles.titleDate}>Кваліфікація переможця</div>
                    </div>
                }
            }
        }
    }
    return tmp
}

function vievBlokFailAuccion(auction){
    let tmp = null;
    if(auction !== null){
        if(auction.status === 'unsuccessful'){
            if(
                auction.sellingMethod === "dgf-english" ||
                auction.sellingMethod === "dgf-english-fast" ||
                auction.sellingMethod === "dgf-english-fast-manual" ||
                auction.sellingMethod === "dgf-english-fast-auction-manual-qualification" ||
                auction.sellingMethod === "dgf-english-initial-auction" ||
                auction.sellingMethod === "dgf-english-initial-auction-manual" ||
                auction.sellingMethod === "dgf-english-initial-qualification"
            ){
                if(auction.unsuccessfulReason === '1'){
                    tmp = <div className={styles.yellowBlock}>
                        <div className={styles.titleDate1}><span>Аукціон не відбувся</span><br /><span>Дискваліфікація усіх учасників/ка</span></div>
                    </div>
                }else if(auction.unsuccessfulReason === '2'){
                    tmp = <div className={styles.yellowBlock}>
                        <div className={styles.titleDate1}><span>Аукціон не відбувся</span><br /><span>Дискваліфікація переможця та відмова очікуючого</span></div>
                    </div>
                }else if(auction.unsuccessfulReason === '3'){
                    tmp = <div className={styles.yellowBlock}>
                        <div className={styles.titleDate1}><span>Аукціон не відбувся</span><br /><span>Заяви на участь відсутні</span></div>
                    </div>
                }else if(auction.unsuccessfulReason === '4'){
                    tmp = <div className={styles.yellowBlock}>
                        <div className={styles.titleDate1}><span>Аукціон не відбувся</span><br /><span>Валідні ставки відсутні</span></div>
                    </div>
                }else if(auction.unsuccessfulReason === '5'){
                    tmp = <div className={styles.yellowBlock}>
                        <div className={styles.titleDate1}><span>Аукціон не відбувся</span><br /><span>Строк підтвердження договору вичерпано</span></div>
                    </div>
                }
            }else if(
                auction.sellingMethod === "dgf-dutch" ||
                auction.sellingMethod === "dgf-dutch-fast" ||
                auction.sellingMethod === "dgf-dutch-fast-manual" ||
                auction.sellingMethod === "dgf-dutch-fast-auction-manual-qualification" ||
                auction.sellingMethod === "dgf-dutch-initial-auction" ||
                auction.sellingMethod === "dgf-dutch-initial-qualification" ||
                auction.sellingMethod === "dgf-dutch-initial-auction-manual"
            ){
                if(auction.unsuccessfulReason === '1'){
                    tmp = <div className={styles.yellowBlock}>
                        <div className={styles.titleDate1}><span>Аукціон не відбувся</span><br /><span>Дискваліфікація усіх учасників/ка</span></div>
                    </div>
                }else if(auction.unsuccessfulReason === '2'){
                    tmp = <div className={styles.yellowBlock}>
                        <div className={styles.titleDate1}><span>Аукціон не відбувся</span><br /><span>Дискваліфікація переможця та відмова очікуючого</span></div>
                    </div>
                }else if(auction.unsuccessfulReason === '3'){
                    tmp = <div className={styles.yellowBlock}>
                        <div className={styles.titleDate1}><span>Аукціон не відбувся</span><br /><span>Голландський раунд не зупинено</span></div>
                    </div>
                }else if(auction.unsuccessfulReason === '4'){
                    tmp = <div className={styles.yellowBlock}>
                        <div className={styles.titleDate1}><span>Аукціон не відбувся</span><br /><span>Строк підтвердження договору вичерпано</span></div>
                    </div>
                }else if(auction.unsuccessfulReason === '5'){
                    tmp = <div className={styles.yellowBlock}>
                        <div className={styles.titleDate1}><span>Аукціон не відбувся</span><span>Строк підтвердження договору вичерпано</span></div>
                    </div>
                }
            }else{
                tmp = <div className={styles.yellowBlock}>
                    <div className={styles.titleDate}><span>Аукціон не відбувся</span></div>
                </div>
            }
        }
    }
    return tmp
}

function vievBlokFailAuccionCancel(auction){
    let tmp = null;
    if(auction !== null){
        if(auction.status === 'cancelled'){
            tmp = <div className={styles.yellowBlock}>
                <div className={styles.titleDate}><span>Аукціон скасовано</span></div>
            </div>
        }
    }
    return tmp
}

function vievBlokCompliteAuccion(auction){
    let tmp = null;
    if(auction !== null){
        if(auction.status === 'complete'){
            if(auction.auctionPeriods.length > 0){
                for(let i = 0; i < auction.auctionPeriods.length; i++){
                    if(auction.auctionPeriods[i].status === 'auctionPeriod'){
                        let start = null
                        if(auction.auctionPeriods[i].startDate !== null){
                            start = modifiDateString(auction.auctionPeriods[i].endDate, 3, false)
                        }
                        tmp = <div className={styles.yellowBlock}>
                            <div className={styles.titleDate}><span>Аукціон завершено</span>{start}</div>
                        </div>
                    }
                }
            }
        }
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auction: state.start.auctionsGetId,
        langFlag: state.start.langFlag,
        profile: state.start.userProfiles
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(BankLot)
