import React from 'react';
import styles from './auctionDataLine_Right.module.css';

import {reverseStr, createApostrof} from '../../../../../../model/summNumberToSreang.js';
//import {THE_INITIAL_SALE_PRICE_OF_THE_LOT_IS_LOTS_LANG} from '../../../../../../model/lang/catalogLots.js';

import {connect} from 'react-redux';

const THE_INITIAL_SALE_PRICE_OF_THE_LOT_IS_LOTS_LANG = ['Початкова ціна продажу лоту', 'The initial sale price of the lot is'];

const AuctionDataLine_Right = ( props ) => {
    return (
        <div className={styles.secondBlock_cost}>
            <div className={styles.secondBlock_textPrice}>{THE_INITIAL_SALE_PRICE_OF_THE_LOT_IS_LOTS_LANG[props.langFlag]}</div>
            {viewMoney(props)}
        </div>
    )
}

function viewMoney(props){
    let tmp = null;
    let amount = null;
    let stringText = null;
    amount = getMoney(props);
    if(amount !== null){
        amount = amount.split('.');
        tmp = createApostrof(reverseStr(amount[0]));
        tmp = reverseStr(tmp);
    }
    stringText = <div className={styles.secondBlock_numberPrice}><span>{tmp}</span> {amount[1]} грн</div>
    return stringText
}

function getMoney(props){
    let tmp = null;
    if(props.item.hasOwnProperty('tenderValueAmount') === true){
        tmp = String(props.item.tenderValueAmount);
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
    }
}

export default connect(mapStateToProps, null)(AuctionDataLine_Right)