import React from 'react';
import styles from './tabMenu.module.css';

import {connect} from 'react-redux';
import {changeCreateAuctionTab} from '../../../redux/actions.js';
import {changeAuctionsItemsSwitchWindow} from '../../../redux/actions/auctions/auctions.js';

const TabMenu = ( props ) => {
    return (
        <div
            className={styles.tab__item + ' ' + addActiveStyle(props)}
            onClick={
                () => {
                    props.changeCreateAuctionTab(props.count);
                    props.changeAuctionsItemsSwitchWindow(0);
                }
            }
        >
            {viewTabText(props)}
        </div>
    )
}

function viewTabText(props){
    let tmp = null;
    switch(props.count){
        case 0:
            tmp = 'Пропозиція'
            break;
        case 1:
            tmp = 'Сповіщення'
            break;
        case 2:
            tmp = 'Контракти'
            break;
        default:
            tmp = ''
            break;
    }
    return tmp
}

function addActiveStyle(props){
    let tmp = null;
    if(props.count === props.createAuctionTab){
        tmp = styles.active;
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
    }
}

const mapDispatchToProps = {
    changeCreateAuctionTab,
    changeAuctionsItemsSwitchWindow
}

export default connect(mapStateToProps, mapDispatchToProps)(TabMenu)
