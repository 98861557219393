import {addProperty, addPropertyValue, previousAuctionId} from './utils'

export function сreateBodyLSP(data){
    if(data !== null){
        let body = {}
        body = addProperty(data, body, 'title')
        body = addProperty(data, body, 'description')
        body = addProperty(data, body, 'accessDetails')
        body = addProperty(data, body, 'tenderAttempts')
        body = addProperty(data, body, 'lotIdentifier')
        body = addProperty(data, body, 'startDate')
        body = addProperty(data, body, 'sellingMethod')
        body = addProperty(data, body, 'xDocumentRequirements')
        body = addProperty(data, body, 'xAdditionalInformation')
        body = addProperty(data, body, 'minNumberOfQualifiedBids')
        body = addPropertyValue(data, body, 'value')
        //body = addPropertyValue(data, body, 'minimalStep')
        if(data.base.minimalStep !== null
            && data.base.minimalStep !== ''
            && data.base.minimalStep.amount !== null
            && data.base.minimalStep.amount !== ''
            && data.base.minimalStep.amount !== 0
            && data.base.minimalStep.amount !== '0'
        ){
            body = addPropertyValue(data, body, 'minimalStep')
        }else{
            body.minimalStep = null
        }
        body = addProperty(data, body, 'saleType')
        body = addPropertyValue(data, body, 'guarantee')
        body = addPropertyValue(data, body, 'expertMonetaryValuation')
        if(body.hasOwnProperty('expertMonetaryValuation') !== true) body.expertMonetaryValuation = null
        body = addPropertyValue(data, body, 'budgetSpent')
        if(body.hasOwnProperty('budgetSpent') !== true) body.budgetSpent = null
        body = addPropertyValue(data, body, 'normativeMonetaryValuation')
        if(body.hasOwnProperty('normativeMonetaryValuation') !== true) body.normativeMonetaryValuation = null
        body = addPropertyValue(data, body, 'registrationFee')
        body = addProperty(data, body, 'restrictions')
        body = addProperty(data, body, 'conditions')
        body = previousAuctionId(data, body, 'previousAuctionId')
        body.numberOfCurrentTenants = data.base.numberOfCurrentTenants
        if(data.base.hasOwnProperty("currentTenant") === true && data.base.currentTenant !== null && data.base.currentTenant.hasOwnProperty("currentTenants") === true && data.base.currentTenant.currentTenants !== null){
            body.currentTenants = data.base.currentTenant.currentTenants
        }else if(data.base.currentTenants !== null){
            body.currentTenants = data.base.currentTenants
            if(data.base.currentTenants.length === 1){
                if(data.base.currentTenants[0].hasOwnProperty("representativeInfo") === false || data.base.currentTenants[0].representativeInfo === null || data.base.currentTenants[0].representativeInfo === ''){
                    delete body.currentTenants[0].representativeInfo
                }
                if(data.base.currentTenants[0].hasOwnProperty("currentContractTime") === false || data.base.currentTenants[0].currentContractTime === null || data.base.currentTenants[0].currentContractTime.dateFrom === null || data.base.currentTenants[0].currentContractTime.dateTill === null){
                    ///body.currentTenants[0].currentContractTime = null
                    delete body.currentTenants[0].currentContractTime
                }
                if(data.base.currentTenants[0].hasOwnProperty("currentTenantValue") === false || data.base.currentTenants[0].currentTenantValue.amount === null || data.base.currentTenants[0].currentTenantValue.amount === '' || data.base.currentTenants[0].currentTenantValue.amount === 0){
                    ///body.currentTenants[0].currentTenantValue = null
                    delete body.currentTenants[0].currentTenantValue
                }
            }else{
                if(data.base.currentTenants[0].hasOwnProperty("representativeInfo") === false || data.base.currentTenants[0].representativeInfo === null || data.base.currentTenants[0].representativeInfo === ''){
                    delete body.currentTenants[0].representativeInfo
                }
                if(data.base.currentTenants[1].hasOwnProperty("representativeInfo") === false || data.base.currentTenants[1].representativeInfo === null || data.base.currentTenants[1].representativeInfo === ''){
                    delete body.currentTenants[1].representativeInfo
                }
                if(data.base.currentTenants[0].hasOwnProperty("currentContractTime") === false || data.base.currentTenants[0].currentContractTime === null || data.base.currentTenants[0].currentContractTime.dateFrom === null || data.base.currentTenants[0].currentContractTime.dateTill === null){
                    ///body.currentTenants[0].currentContractTime = null
                    delete body.currentTenants[0].currentContractTime
                }
                if(data.base.currentTenants[1].hasOwnProperty("currentContractTime") === false || data.base.currentTenants[1].currentContractTime === null || data.base.currentTenants[1].currentContractTime.dateFrom === null || data.base.currentTenants[1].currentContractTime.dateTill === null){
                    //body.currentTenants[1].currentContractTime = null
                    delete body.currentTenants[1].currentContractTime
                }
                if(data.base.currentTenants[0].hasOwnProperty("currentTenantValue") === false || data.base.currentTenants[0].currentTenantValue === null || data.base.currentTenants[0].currentTenantValue.amount === null || data.base.currentTenants[0].currentTenantValue.amount === '' || data.base.currentTenants[0].currentTenantValue.amount === 0){
                    //body.currentTenants[0].currentTenantValue = null
                    delete body.currentTenants[0].currentTenantValue
                }
                if(data.base.currentTenants[1].hasOwnProperty("currentTenantValue") === false || data.base.currentTenants[1].currentTenantValue === null || data.base.currentTenants[1].currentTenantValue.amount === null || data.base.currentTenants[1].currentTenantValue.amount === '' || data.base.currentTenants[1].currentTenantValue.amount === 0){
                    //body.currentTenants[1].currentTenantValue = null
                    delete body.currentTenants[1].currentTenantValue
                }
            }
        }else{
            body.currentTenants = null
        }
        if(data.base.expertMonetaryValuation === null || data.base.expertMonetaryValuation.amount === null || data.base.expertMonetaryValuation.amount === '' || data.base.expertMonetaryValuation.amount === 0){
            body.expertMonetaryValuation = null
        }
        if(data.base.normativeMonetaryValuation === null || data.base.normativeMonetaryValuation.amount === null || data.base.normativeMonetaryValuation.amount === '' || data.base.normativeMonetaryValuation.amount === 0){
            body.normativeMonetaryValuation = null
        }
        return body
    }
    return null
}