import React from 'react';
import styles from './itemsList.module.css';

import { deleteItem } from '../utils';

import {connect} from 'react-redux';
import {
    changeCreateItemAuctionId,
    changeCreateItemClassificationId,
    changeCreateItemUnitValueCurrency,
    changeCreateItemUnitValueAmount,
    changeCreateItemUnitValueTax,
    changeCreateItemDescription,
    changeAdditionalClassificationIds,
    changeCreateItemQuantity,
    changeCreateItemIds,
    changeLoader,
    getMyAuction,
    changeCreateItemLocationLatitude,
    changeCreateItemLocationLongitude,
    changeCreateItemLocationElevation,
    changeCreateItemAddress,
    changeCreateItemDgfJointPropertyComplexPropsId,
    changeCreateItemDgfJointPropertyComplexPropsQuantityAndNomenclature,
    changeCreateItemDgfJointPropertyComplexPropsWorkplacesQuantity,
    changeCreateItemDgfJointPropertyComplexPropsBuildingsInformation,
    changeCreateItemDgfJointPropertyComplexPropsLandPlotInformation,
    changeCreateItemDgfJointPropertyComplexPropsMainObligations,
    changeCreateItemDgfLandPropsId,
    changeCreateItemDgfLandPropsLandArea,
    changeCreateItemDgfLandPropsCadastralNumber,
    changeCreateItemDgfLandPropsOwnershipType,
    changeCreateItemDgfLandPropsEncumbrances,
    changeCreateItemDgfLandPropsJointOwnership,
    changeCreateItemDgfLandPropsUtilitiesAvailability,
    changeCreateItemDgfLandPropsIntendedUse,
    changeCreateItemDgfVehiclePropsId,
    changeCreateItemDgfVehiclePropsBrand,
    changeCreateItemDgfVehiclePropsModel,
    changeCreateItemDgfVehiclePropsProductionYear,
    changeCreateItemDgfVehiclePropsEngineCapacity,
    changeCreateItemDgfVehiclePropsFuelType,
    changeCreateItemDgfVehiclePropsTransmission,
    changeCreateItemDgfVehiclePropsColor,
    changeCreateItemDgfVehiclePropsKilometrage,
    changeCreateItemDgfVehiclePropsIdentificationNumber,
    changeCreateItemDgfVehiclePropsConfiguration,
    changeCreateItemDgfVehiclePropsCondition,
    changeCreateItemDgfVehiclePropsDamagePresence,
    changeCreateItemDgfVehiclePropsDamagedDescription,
} from '../../../../../redux/actions.js';
import {auctionsGetMy,
    auctionsGetMyId,
    changeAuctionsItemsSwitchWindow,
    changeAuctionsSwitchWindow,
    auctionsGetClassificationsByTypeEnergy,
    auctionsGetClassificationsByGenerationType,
    changeTimberSortment,
    changeTimberSpecies,
    changeTimberClass,
    changeTimberDiameter,
    changeTimberLength,
    changeTimberStorage,
    changeTimberProductionYear,
    changeTimberProductionQuarter,
    changeCreateItemUnitCode,
    changeCreateItemGenerationType,
    auctionsGetClassificationsByTypeDGFAdd,
    seDgfMask,
} from '../../../../../redux/actions/auctions/auctions.js';

import {
    setDataAddress,
} from '../../../../../redux/createAddress';

import 'date-fns';

import AucLotItem from './aucLotItem/aucLotItem';

const ItemsList = ( props ) => {
    let tmp = null;
    /*if(props.auctionsList !== null){
        if(props.auctionsList.length > 0){
            tmp = props.auctionsList
            .filter(
                (i) => {
                    return i.id === props.auctionsMy.id
                }
            )
        }
    }*/

    let tmpArr = null;
    //if(tmp !== null){
    if(props.auctionsMy !== null && props.auctionsMy.items !== null){
        //if(tmp.length > 0){
            //tmpArr = tmp[0].items.map(
            tmpArr = props.auctionsMy.items.map(
                (i) => {
                    return <AucLotItem 
                                data={i} 
                                edit={async () => {   props.setEdit(i)
                                                props.changeAuctionsItemsSwitchWindow(2);
                                                //props.setDataAddress(null, 'SET_CLASSIFICATIONS_BSE_BSD')
                                                props.changeCreateItemAuctionId(props.auctionsMy.id)
                                                await combain(props, i)
                                                props.edit(true)
                                }}
                                del={ ()=> {
                                                props.changeLoader(true)
                                                deleteItem(props, props.auctionsMy.id, i.id)
                                                // props.edit(true)
                                }}
                                props={props}
                            />
                }
            )
        //}
    }
    return tmpArr
}

function addStatusBaseClasif(props, i){
    let tmpL = `${i.classification.id[0]}${i.classification.id[1]}`
    props.setDataAddress(tmpL, 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    // if(i.classification.id[0] === '0'){
    //     if(i.classification.id[1] === '3'){
    //         props.setDataAddress('03', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '03')
    //     }else if(i.classification.id[1] === '4'){
    //         props.setDataAddress('04', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '04')
    //     }else if(i.classification.id[1] === '5'){
    //         props.setDataAddress('05', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '05')
    //     }else if(i.classification.id[1] === '6'){
    //         props.setDataAddress('06', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '06')
    //     }else if(i.classification.id[1] === '7'){
    //         props.setDataAddress('07', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '07')
    //     }else if(i.classification.id[1] === '8'){
    //         props.setDataAddress('08', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '08')
    //     }else if(i.classification.id[1] === '9'){
    //         props.setDataAddress('09', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '09')
    //     }
    // }else if(i.classification.id[0] === '1'){
    //     if(i.classification.id[1] === '4'){
    //         props.setDataAddress('14', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '14')
    //     }else if(i.classification.id[1] === '5'){
    //         props.setDataAddress('15', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '15')
    //     }else if(i.classification.id[1] === '6'){
    //         props.setDataAddress('16', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '16')
    //     }else if(i.classification.id[1] === '8'){
    //         props.setDataAddress('18', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '18')
    //     }else if(i.classification.id[1] === '9'){
    //         props.setDataAddress('19', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '19')
    //     }
    // }else if(i.classification.id[0] === '3'){
    //     if(i.classification.id[1] === '0'){
    //         props.setDataAddress('30', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '30')
    //     }else if(i.classification.id[1] === '2'){
    //         props.setDataAddress('32', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '32')
    //     }else if(i.classification.id[1] === '4'){
    //         props.setDataAddress('34', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '34')
    //     }else if(i.classification.id[1] === '9'){
    //         props.setDataAddress('39', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '39')
    //     }
    // }else if(i.classification.id[0] === '4'){
    //     if(i.classification.id[1] === '2'){
    //         props.setDataAddress('42', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '42')
    //     }else if(i.classification.id[1] === '5'){
    //         props.setDataAddress('45', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '45')
    //     }else if(i.classification.id[1] === '8'){
    //         props.setDataAddress('48', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '48')
    //     }
    // }else if(i.classification.id[0] === '5'){
    //     if(i.classification.id[1] === '0'){
    //         props.setDataAddress('50', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '50')
    //     }else if(i.classification.id[1] === '1'){
    //         props.setDataAddress('51', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '51')
    //     }else if(i.classification.id[1] === '5'){
    //         props.setDataAddress('55', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '55')
    //     }
    // }else if(i.classification.id[0] === '6'){
    //     if(i.classification.id[1] === '0'){
    //         props.setDataAddress('60', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '60')
    //     }else if(i.classification.id[1] === '3'){
    //         props.setDataAddress('63', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '63')
    //     }else if(i.classification.id[1] === '4'){
    //         props.setDataAddress('64', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '64')
    //     }else if(i.classification.id[1] === '5'){
    //         props.setDataAddress('65', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '65')
    //     }else if(i.classification.id[1] === '6'){
    //         props.setDataAddress('66', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '66')
    //     }
    // }else if(i.classification.id[0] === '7'){
    //     if(i.classification.id[1] === '1'){
    //         props.setDataAddress('71', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '71')
    //     }else if(i.classification.id[1] === '2'){
    //         props.setDataAddress('72', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '72')
    //     }else if(i.classification.id[1] === '3'){
    //         props.setDataAddress('73', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '73')
    //     }else if(i.classification.id[1] === '5'){
    //         props.setDataAddress('75', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '75')
    //     }else if(i.classification.id[1] === '6'){
    //         props.setDataAddress('76', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '76')
    //     }else if(i.classification.id[1] === '7'){
    //         props.setDataAddress('77', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '77')
    //     }else if(i.classification.id[1] === '9'){
    //         props.setDataAddress('79', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '79')
    //     }
    // }else if(i.classification.id[0] === '8'){
    //     if(i.classification.id[1] === '0'){
    //         props.setDataAddress('80', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '80')
    //     }else if(i.classification.id[1] === '5'){
    //         props.setDataAddress('85', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '85')
    //     }
    // }else if(i.classification.id[0] === '9'){
    //     if(i.classification.id[1] === '0'){
    //         props.setDataAddress('90', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '90')
    //     }else if(i.classification.id[1] === '2'){
    //         props.setDataAddress('92', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '92')
    //     }else if(i.classification.id[1] === '8'){
    //         props.setDataAddress('98', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    //         smartClasif(props, '98')
    //     }
    // }
}

async function smartClasif(props, data){
    let tmp0 = await getDictionaryJointPropertyComplex(props, data)
    props.setDataAddress(tmp0, 'SET_CLASSIFICATIONS_BSE_BSD')
}

async function combain(props, i){
    await addStatusBaseClasif(props, i)
    await uploadDataLot(props, i)
}

function uploadDataLot(props, i){
    let tmp = i.classification
    /*if(props.classificationsBseBsd !== null){
        tmp = props.classificationsBseBsd.filter(
            (it) => {
                return it.id === Number(i.classification.id)
            }
        )
    }*/
    props.setDataAddress(i.description, 'CREATE_ITEMS_LAND_RENTAL_DESCRIPTION')

    //props.setDataAddress(tmp[0].id, 'CREATE_ITEMS_LAND_RENTAL_CLASSIFICATION')
    props.setDataAddress(i.classification.id, 'CREATE_ITEMS_LAND_RENTAL_CLASSIFICATION')
    if(i.address !== null){
        props.setDataAddress(i.address.id, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS')
        
        props.setDataAddress(i.address.id, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_ID')
        props.setDataAddress(i.address.countryName, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_COUNTRY_NAME')
        props.setDataAddress(i.address.region, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_REGION')
        props.setDataAddress(i.address.locality, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_LOCALITY')
        props.setDataAddress(i.address.streetAddress, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_STREET_ADDRESS')
        props.setDataAddress(i.address.postalCode, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_POSTAL_CODE')
    }
    if(i.location !== null){
        props.setDataAddress(i.location.latitude, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE')
        props.setDataAddress(i.location.longitude, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE')
    }
    if(tmp !== null){
        if(tmp.id !== null && tmp.id !== 'null'){
            if(
                tmp.id === '07121000-3' ||
                tmp.id === '07221000-4' ||
                (tmp.id[0] === '0' && tmp.id[1] === '4')
            ){
                dataRealEstate(props, i)
            }else if(
                (tmp.id[0] === '0' && tmp.id[1] === '6') ||
                (
                    tmp.id[0] === '0' &&
                    tmp.id[1] === '7' &&
                    tmp.id[2] === '1' &&
                    tmp.id[3] === '2' &&
                    tmp.id[4] === '3'
                ) ||
                (
                    tmp.id[0] === '0' &&
                    tmp.id[1] === '7' &&
                    tmp.id[2] === '2' &&
                    tmp.id[3] === '2' &&
                    tmp.id[4] === '3'
                )
            ){
                dataLandProperty(props, i)
            }else if(
                tmp.id === '07126000-8' ||
                tmp.id === '07226000-9' ||
                (tmp.id[0] === '3' && tmp.id[1] === '4')
            ){
                dataVehicle(props, i)
            }else if(
                tmp.id === '07122000-0' ||
                tmp.id === '07222000-1' ||
                (tmp.id[0] === '0' && tmp.id[1] === '5')
            ){
                dataJointPropertyComplex(props, i)
            }else{
                dataOtherPropertyNew(props, i)
            }
        }
    }
}

function dataRealEstate(props, i){
    props.setDataAddress(i.id, 'CREATE_ITEMS_REAL_ESTATEITEM_ID')
    props.setDataAddress('realEstate', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
    // props.setDataAddress(i.quantity, 'CREATE_ITEMS_REAL_ESTATEITEM_QUANTITY')
    // if(i.hasOwnProperty('unit') === true){
    //     if(i.unit !== null){
    //         if(i.unit.hasOwnProperty('code') === true) props.setDataAddress(i.unit.code, 'CREATE_ITEMS_REAL_ESTATEITEM_UNIT_CODE')
    //     }
    // }
    // if(i.registrationDetails !== null){
    //     props.setDataAddress(i.registrationDetails.registrationStatus, 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS')
    //     props.setDataAddress(i.registrationDetails.registrationID, 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_ID')
    //     props.setDataAddress(i.registrationDetails.registrationDate, 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_DATE')
    // }
    // if(i.realEstateProps !== null){
    //     props.setDataAddress(i.realEstateProps.totalBuildingArea, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_TOTAL_BUILDING_AREA')
    //     props.setDataAddress(i.realEstateProps.totalObjectArea, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_TOTAL_OBJECT_AREA')
    //     props.setDataAddress(i.realEstateProps.usableArea, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_USABLE_AREA')
    //     props.setDataAddress(i.realEstateProps.constructionYear, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_CONSTRUCTION_YEAR')
    //     props.setDataAddress(i.realEstateProps.constructionTechnology, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_CONSTRUCTION_TECHNOLOGY')
    //     props.setDataAddress(i.realEstateProps.livingArea, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LIVING_AREA')
    //     props.setDataAddress(i.realEstateProps.kitchenArea, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_KITCHEN_AREA')
    //     props.setDataAddress(i.realEstateProps.landArea, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LAND_AREA')
    //     props.setDataAddress(i.realEstateProps.locationInBuilding, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LOCATION_IN_BUILDING')
    //     props.setDataAddress(i.realEstateProps.floors, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_FLOORS')
    //     props.setDataAddress(i.realEstateProps.generalCondition, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_GENERAL_CONDITION')
    //     props.setDataAddress(i.realEstateProps.serviceElectricity, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_ELECTRICITY')
    //     props.setDataAddress(i.realEstateProps.powerSupplyCapacity, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SUPPLY_CAPACITY')
    //     props.setDataAddress(i.realEstateProps.powerSupplyClass, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SUPPLY_CLASS')
    //     props.setDataAddress(i.realEstateProps.serviceWater, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_WATER')
    //     props.setDataAddress(i.realEstateProps.serviceSewerage, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_SEWERAGE')
    //     props.setDataAddress(i.realEstateProps.serviceGas, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_GAS')
    //     props.setDataAddress(i.realEstateProps.serviceCentralHeating, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_CENTRAL_HEATING')
    //     props.setDataAddress(i.realEstateProps.serviceAutonomousHeating, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_AUTONOMOUS_HEATING')
    //     props.setDataAddress(i.realEstateProps.serviceHeatingCounter, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_HEATING_COUNTER')
    //     props.setDataAddress(i.realEstateProps.serviceVentilation, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_VENTILATION')
    //     props.setDataAddress(i.realEstateProps.serviceAirConditioning, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_AIR_CONDITIONING')
    //     props.setDataAddress(i.realEstateProps.servicePhone, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_PHONE')
    //     props.setDataAddress(i.realEstateProps.serviceTV, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_TV')
    //     props.setDataAddress(i.realEstateProps.serviceInternet, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_INTERNET')
    //     props.setDataAddress(i.realEstateProps.serviceElevator, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_ELEVATOR')
    //     props.setDataAddress(i.realEstateProps.serviceSecurityAlarm, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_SECURITY_ALARM')
    //     props.setDataAddress(i.realEstateProps.serviceFireAlarm, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_FIRE_ALARM')
    //     props.setDataAddress(i.realEstateProps.servicesDescription, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SERVICES_DESCRIPTION')
    //     props.setDataAddress(i.realEstateProps.servicesAccounting, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SERVICES_ACCOUNTING')
    //     props.setDataAddress(i.realEstateProps.landTax, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LAND_TAX')
    // }
}

function dataVehicle(props, i){
    props.setDataAddress(i.id, 'CREATE_ITEMS_VEHICLE_ITEM_ID')
    props.setDataAddress('vehicle', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
    // props.setDataAddress(i.quantity, 'CREATE_ITEMS_VEHICLE_ITEM_QUANTITY')
    // if(i.hasOwnProperty('unit') === true){
    //     if(i.unit !== null){
    //         if(i.unit.hasOwnProperty('code') === true) props.setDataAddress(i.unit.code, 'CREATE_ITEMS_VEHICLE_ITEM_UNIT_CODE')
    //     }else{
    //         props.setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_UNIT_CODE')
    //     }
    // }
    // if(i.registrationDetails !== null){
    //     props.setDataAddress(i.registrationDetails.registrationStatus, 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS')
    //     props.setDataAddress(i.registrationDetails.registrationID, 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_ID')
    //     props.setDataAddress(i.registrationDetails.registrationDate, 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_DATE')
    // }
    // if(i.vehicleProps !== null){
    //     props.setDataAddress(i.vehicleProps.brand, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_BRAND')
    //     props.setDataAddress(i.vehicleProps.model, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_MODEL')
    //     props.setDataAddress(i.vehicleProps.productionYear, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_PRODUCTION_YEAR')
    //     props.setDataAddress(i.vehicleProps.engineCapacity, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_ENGINE_CAPACITY')
    //     props.setDataAddress(i.vehicleProps.fuelType, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_FUEL_TYPE')
    //     props.setDataAddress(i.vehicleProps.transmission, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_TRANSMISSION')
    //     props.setDataAddress(i.vehicleProps.color, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_COLOR')
    //     props.setDataAddress(i.vehicleProps.kilometrage, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_KILOMETRAGE')
    //     props.setDataAddress(i.vehicleProps.identificationNumber, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_IDENTIFICATION_NUMBER')
    //     props.setDataAddress(i.vehicleProps.configuration, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_CONFIGURATION')
    //     props.setDataAddress(i.vehicleProps.condition, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_CONDITION')
    //     props.setDataAddress(i.vehicleProps.damagePresence, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_DAMAGE_PRESENCE')
    //     props.setDataAddress(i.vehicleProps.damagedDescription, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_DAMAGED_DESCRIPTION')
    // }
}

function dataJointPropertyComplex(props, i){
    props.setDataAddress(i.id, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ID')
    props.setDataAddress('jointPropertyComplex', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
    // props.setDataAddress(i.quantity, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_QUANTITY')
    // if(i.hasOwnProperty('unit') === true){
    //     if(i.unit !== null){
    //         if(i.unit.hasOwnProperty('code') === true) props.setDataAddress(i.unit.code, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_UNIT_CODE')
    //     }
    // }
    // if(i.registrationDetails !== null){
    //     props.setDataAddress(i.registrationDetails.registrationStatus, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS')
    //     props.setDataAddress(i.registrationDetails.registrationID, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_REGISTRATION_DETAILS_REGISTRATION_ID')
    //     props.setDataAddress(i.registrationDetails.registrationDate, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_REGISTRATION_DETAILS_REGISTRATION_DATE')
    // }
    // if(i.jointPropertyComplexProps !== null){
    //     props.setDataAddress(i.jointPropertyComplexProps.buildingsInformation, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_BUILDINGS_INFORMATION')
    //     props.setDataAddress(i.jointPropertyComplexProps.landPlotInformation, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PLOT_INFORMATION')
    //     props.setDataAddress(i.jointPropertyComplexProps.mainObligations, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_MAIN_OBLIGATIONS')
    //     props.setDataAddress(i.jointPropertyComplexProps.quantityAndNomenclature, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_QUANTITY_AND_NOMENCLATURE')
    //     props.setDataAddress(i.jointPropertyComplexProps.workplacesQuantity, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_WORKPLACES_QUANTITY')
    //     if(i.jointPropertyComplexProps.landProps !== null){
    //         props.setDataAddress(i.jointPropertyComplexProps.landProps.cadastralNumber, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_CADASTRAL_NUMBER')
    //         props.setDataAddress(i.jointPropertyComplexProps.landProps.encumbrances, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_ENCUMBRANCES')
    //         props.setDataAddress(i.jointPropertyComplexProps.landProps.jointOwnership, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_JOINT_OWNERSHIP')
    //         props.setDataAddress(i.jointPropertyComplexProps.landProps.landArea, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_LAND_AREA')
    //         props.setDataAddress(i.jointPropertyComplexProps.landProps.landIntendedUse, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_LAND_INTENDED_USE')
    //         props.setDataAddress(i.jointPropertyComplexProps.landProps.ownershipType, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_OWNERSHIP_TYPE')
    //         props.setDataAddress(i.jointPropertyComplexProps.landProps.utilitiesAvailability, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_UTILITIES_AVAILABILITY')
    //     }
    // }
    /*if(i.landProps !== null){
        props.setDataAddress(i.landProps.cadastralNumber, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_CADASTRAL_NUMBER')
        props.setDataAddress(i.landProps.encumbrances, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_ENCUMBRANCES')
        props.setDataAddress(i.landProps.jointOwnership, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_JOINT_OWNERSHIP')
        props.setDataAddress(i.landProps.landArea, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_LAND_AREA')
        props.setDataAddress(i.landProps.landIntendedUse, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_LAND_INTENDED_USE')
        props.setDataAddress(i.landProps.ownershipType, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_OWNERSHIP_TYPE')
        props.setDataAddress(i.landProps.utilitiesAvailability, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_UTILITIES_AVAILABILITY')
    }*/
}

function dataOtherPropertyNew(props, i){
    props.setDataAddress(i.id, 'CREATE_ITEMS_LAND_RENTAL_ID')
    props.setDataAddress(null, 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
    if(i.registrationDetails !== null){
        props.setDataAddress(i.registrationDetails.registrationStatus, 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_REGISTRATION_DETAILS_REGISTRATION_STATUS')
        props.setDataAddress(i.registrationDetails.registrationID, 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_REGISTRATION_DETAILS_REGISTRATION_ID')
        props.setDataAddress(i.registrationDetails.registrationDate, 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_REGISTRATION_DETAILS_REGISTRATION_DATE')
    }
    props.setDataAddress(i.quantity, 'CREATE_ITEMS_LAND_RENTAL_QUANTITY')
    if(i.hasOwnProperty('unit') === true){
        if(i.unit !== null){
            if(i.unit.hasOwnProperty('code') === true) props.setDataAddress(i.unit.code, 'CREATE_ITEMS_LAND_RENTAL_UNIT_CODE')
        }
    }
}

function dataLandProperty(props, i){
    props.setDataAddress(i.id, 'CREATE_ITEMS_LAND_RENTAL_ID')
    props.setDataAddress('otherProperty', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
//     props.setDataAddress(i.quantity, 'CREATE_ITEMS_LAND_RENTAL_QUANTITY')
//     if(i.hasOwnProperty('unit') === true){
//         if(i.unit !== null){
//             if(i.unit.hasOwnProperty('code') === true) props.setDataAddress(i.unit.code, 'CREATE_ITEMS_LAND_RENTAL_UNIT_CODE')
//         }
//     }
//     if(i.registrationDetails !== null){
//         props.setDataAddress(i.registrationDetails.registrationStatus, 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_REGISTRATION_DETAILS_REGISTRATION_STATUS')
//         props.setDataAddress(i.registrationDetails.registrationID, 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_REGISTRATION_DETAILS_REGISTRATION_ID')
//         props.setDataAddress(i.registrationDetails.registrationDate, 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_REGISTRATION_DETAILS_REGISTRATION_DATE')
//     }
//     if(i.landProps !== null){
//         props.setDataAddress(i.landProps.landArea, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LAND_AREA')
//         props.setDataAddress(i.landProps.cadastralNumber, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_CADASTRAL_NUMBER')
//         props.setDataAddress(i.landProps.ownershipType, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_OWNERSHIP_TYPE')
//         props.setDataAddress(i.landProps.encumbrances, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_ENCUMBRANCES')
//         props.setDataAddress(i.landProps.jointOwnership, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_JOINT_OWNERSHIP')
//         props.setDataAddress(i.landProps.utilitiesAvailability, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_UTILITIES_AVAILABILITY')
//         props.setDataAddress(i.landProps.landIntendedUse, 'CREATE_ITEMS_LAND_RENTAL_LAND_INTENDED_USE')
//     }
}

async function loadMainClassif(props, data){
    let tmpData = null
    if(data !== null && data !== 'null'){
        data = data.split('"')
        if(data[7].indexOf('.') !== -1){
            let tmp1 = data[7].split('.')
            tmpData = tmp1[0]
        }else{
            tmpData = data[7]
        }
    }
    switch(tmpData){
        case "01":
            getDictionaryJointPropertyComplex(props, '06121000-6')
            break
        case "02":
            getDictionaryJointPropertyComplex(props, '06111000-3')
            break
        case "03":
            getDictionaryJointPropertyComplex(props, '06112000-0')
            break
        case "04":
        case "05":
            getDictionaryJointPropertyComplex(props, '06122000-3')
            break
        case "06":
            getDictionaryJointPropertyComplex(props, '06123000-0')
            break
        case "07":
            getDictionaryJointPropertyComplex(props, '06124000-7')
            break
        case "08":
            getDictionaryJointPropertyComplex(props, '06125000-4')
            break
        case "09":
            getDictionaryJointPropertyComplex(props, '06126000-1')
            break
        case "10":
            getDictionaryJointPropertyComplex(props, '06127000-8')
            break
        case "11":
        case "12":
        case "13":
        case "14":
        case "15":
            getDictionaryJointPropertyComplex(props, '06128000-5')
            break
        case "16":
        case "17":
        case "18":
        case "19":
            getDictionaryJointPropertyComplex(props, '06129000-2')
            break
        default:
            break
    }
}

function viewSelectTypeItems(props, data){
    additionalClassificationZU(props)
    if(data === 'otherProperty'){
        concatDataOtherProperty(props)
    }else if(data === 'jointPropertyComplex'){
        concatDataJointPropertyComplex(props)
    }else if(data === "vehicle"){
        concatDataVehicle(props)
    }else if(data === 'realEstate'){
        concatDataRealEstate(props)
    }
}

async function additionalClassificationZU(props){
    let tmpArr = []
    tmpArr[0] = await getAdditionalClassificationZU(props, 'QB29-3')
    tmpArr[1] = await getAdditionalClassificationZU(props, 'PA01-7')
    props.setDataAddress(tmpArr, 'SET_CLASSIFICATIONS_ZU_ADD')
}

async function concatDataOtherProperty(props){
    /*//let tmpArr0 = await getDictionaryJointPropertyComplex(props, '16000000-5')
    let tmpArr0 = await getDictionaryJointPropertyComplex(props, '16')
    //let tmpArr1 = await tmpArr0.concat(await getDictionaryJointPropertyComplex(props, '30000000-9'))
    let tmpArr1 = await tmpArr0.concat(await getDictionaryJointPropertyComplex(props, '30'))
    //let tmpArr2 = await tmpArr1.concat(await getDictionaryJointPropertyComplex(props, '32000000-3'))
    let tmpArr2 = await tmpArr1.concat(await getDictionaryJointPropertyComplex(props, '32'))
    //let tmpArr3 = await tmpArr2.concat(await getDictionaryJointPropertyComplex(props, '34000000-7'))
    //let tmpArr3 = await tmpArr2.concat(await getDictionaryJointPropertyComplex(props, '34000000-7'))
    let tmpArr3 = await tmpArr2.concat(await getDictionaryJointPropertyComplex(props, '340'))
    let tmpArr4 = await tmpArr3.concat(await getDictionaryJointPropertyComplex(props, '342'))
    let tmpArr5 = await tmpArr4.concat(await getDictionaryJointPropertyComplex(props, '343'))
    let tmpArr6 = await tmpArr5.concat(await getDictionaryJointPropertyComplex(props, '348'))
    let tmpArr8 = await tmpArr6.concat(await getDictionaryJointPropertyComplex(props, '349'))
        //let tmpArr4 = await tmpArr3.concat(await getDictionaryJointPropertyComplex(props, '34100000-8'))
        //let tmpArr5 = await tmpArr4.concat(await getDictionaryJointPropertyComplex(props, '34400000-1'))
        //let tmpArr6 = await tmpArr5.concat(await getDictionaryJointPropertyComplex(props, '34500000-2'))
        //let tmpArr7 = await tmpArr6.concat(await getDictionaryJointPropertyComplex(props, '34600000-3'))
        //let tmpArr8 = await tmpArr7.concat(await getDictionaryJointPropertyComplex(props, '34700000-4'))
    //let tmpArr9 = await tmpArr8.concat(await getDictionaryJointPropertyComplex(props, '39000000-2'))
    let tmpArr9 = await tmpArr8.concat(await getDictionaryJointPropertyComplex(props, '39'))
    //let tmpArr10 = await tmpArr9.concat(await getDictionaryJointPropertyComplex(props, '42000000-6'))
    let tmpArr10 = await tmpArr9.concat(await getDictionaryJointPropertyComplex(props, '42'))
    //let tmpArr11 = await tmpArr10.concat(await getDictionaryJointPropertyComplex(props, '18000000-9'))
    let tmpArr11 = await tmpArr10.concat(await getDictionaryJointPropertyComplex(props, '18'))
    //let tmpArr12 = await tmpArr11.concat(await getDictionaryJointPropertyComplex(props, '19000000-6'))
    let tmpArr12 = await tmpArr11.concat(await getDictionaryJointPropertyComplex(props, '19'))
    //let tmpArr13 = await tmpArr12.concat(await getDictionaryJointPropertyComplex(props, '22000000-0'))
    let tmpArr13 = await tmpArr12.concat(await getDictionaryJointPropertyComplex(props, '22'))
    //let tmpArr14 = await tmpArr13.concat(await getDictionaryJointPropertyComplex(props, '31000000-6'))
    let tmpArr14 = await tmpArr13.concat(await getDictionaryJointPropertyComplex(props, '31'))
    //let tmpArr15 = await tmpArr14.concat(await getDictionaryJointPropertyComplex(props, '33000000-0'))
    let tmpArr15 = await tmpArr14.concat(await getDictionaryJointPropertyComplex(props, '33'))
    //let tmpArr16 = await tmpArr15.concat(await getDictionaryJointPropertyComplex(props, '35000000-4'))
    let tmpArr16 = await tmpArr15.concat(await getDictionaryJointPropertyComplex(props, '35'))
    //let tmpArr17 = await tmpArr16.concat(await getDictionaryJointPropertyComplex(props, '37000000-8'))
    let tmpArr17 = await tmpArr16.concat(await getDictionaryJointPropertyComplex(props, '37'))
    //let tmpArr18 = await tmpArr17.concat(await getDictionaryJointPropertyComplex(props, '38000000-5'))
    let tmpArr18 = await tmpArr17.concat(await getDictionaryJointPropertyComplex(props, '38'))
    //let tmpArr19 = await tmpArr18.concat(await getDictionaryJointPropertyComplex(props, '43000000-3'))
    let tmpArr19 = await tmpArr18.concat(await getDictionaryJointPropertyComplex(props, '43'))
    //let tmpArr20 = await tmpArr19.concat(await getDictionaryJointPropertyComplex(props, '44000000-0'))
    let tmpArr20 = await tmpArr19.concat(await getDictionaryJointPropertyComplex(props, '44'))
    //let tmpArr21 = await tmpArr20.concat(await getDictionaryJointPropertyComplex(props, '48000000-8'))
    let tmpArr21 = await tmpArr20.concat(await getDictionaryJointPropertyComplex(props, '48'))
    props.setDataAddress(tmpArr21, 'SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD')*/
    let tmp = await getDictionaryJointPropertyComplex(props, '06')
    let tmp2 = await getDictionaryJointPropertyComplex(props, '07123')
    let tmp3 = await getDictionaryJointPropertyComplex(props, '07223')
    let tmpArr = [...tmp, ...tmp2, ...tmp3]
    await props.setDataAddress(tmpArr, 'SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD')
}

async function concatDataJointPropertyComplex(props){
    /*let tmpArr1 = await getDictionaryJointPropertyComplex(props, '07')
    let tmpArr2 = await getDictionaryJointPropertyComplex(props, '08')
    let tmpArr3 = tmpArr1.concat(tmpArr2)
    props.setDataAddress(tmpArr3, 'SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD')*/
    let tmpArr1 = await getDictionaryJointPropertyComplex(props, '05')
    let tmpArr2 = await getDictionaryJointPropertyComplex(props, '07122000-0')
    let tmpArr3 = await getDictionaryJointPropertyComplex(props, '07222000-1')
    let tmpArr4 = tmpArr1.concat(tmpArr2)
    let tmpArr5 = tmpArr4.concat(tmpArr3)
    props.setDataAddress(tmpArr5, 'SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD')
}

async function concatDataVehicle(props){
    /*let tmpArr0 = await getDictionaryJointPropertyComplex(props, '34000000-7')
    let tmpArr1 = await tmpArr0.concat(await getDictionaryJointPropertyComplex(props, '34100000-8'))
    let tmpArr2 = await tmpArr1.concat(await getDictionaryJointPropertyComplex(props, '34400000-1'))
    let tmpArr3 = await tmpArr2.concat(await getDictionaryJointPropertyComplex(props, '34500000-2'))
    let tmpArr4 = await tmpArr3.concat(await getDictionaryJointPropertyComplex(props, '34600000-3'))
    let tmpArr5 = await tmpArr4.concat(await getDictionaryJointPropertyComplex(props, '34700000-4'))
    props.setDataAddress(tmpArr5, 'SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD')*/
    let tmpArr0 = await getDictionaryJointPropertyComplex(props, '34')
    let tmpArr1 = await tmpArr0.concat(await getDictionaryJointPropertyComplex(props, '07126000-8'))
    let tmpArr2 = await tmpArr1.concat(await getDictionaryJointPropertyComplex(props, '07226000-9'))
    props.setDataAddress(tmpArr2, 'SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD')
}

async function concatDataRealEstate(props){
    /*let tmpArr = await getDictionaryJointPropertyComplex(props, '04')
    props.setDataAddress(tmpArr, 'SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD')*/
    let tmpArr = await getDictionaryJointPropertyComplex(props, '04')
    let tmpArr1 = await tmpArr.concat(await getDictionaryJointPropertyComplex(props, '07121000-3'))
    let tmpArr2 = await tmpArr1.concat(await getDictionaryJointPropertyComplex(props, '07221000-4'))
    props.setDataAddress(tmpArr2, 'SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD')
}

async function getDictionaryJointPropertyComplex(props, mask){
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/autocomplete/classification_id?limit=1000&offset=0&classification_id=${mask}&scheme=CAV&mask=${mask}`;
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/classifications/autocomplete/classification_id?limit=1000&offset=0&classification_id=${mask}&scheme=CAV&mask=${mask}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        return await response.json()
    }
}

async function getAdditionalClassificationZU(props, mask){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/autocomplete/keyword?keyword=${mask}&scheme=CPVS&limit=100`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        return await response.json()
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy,
        classificationsBseBsd: state.start.classificationsBseBsd,
    }
}

const mapDispatchToProps = {
    changeCreateItemAuctionId,
    changeCreateItemClassificationId,
    changeCreateItemUnitValueCurrency,
    changeCreateItemUnitValueAmount,
    changeCreateItemUnitValueTax,
    changeCreateItemDescription,
    changeAuctionsItemsSwitchWindow,
    auctionsGetMy,
    auctionsGetMyId,
    changeAuctionsSwitchWindow,
    changeAdditionalClassificationIds,
    changeCreateItemQuantity,
    changeCreateItemIds,
    //auctionsGetClassificationsByType,
    auctionsGetClassificationsByTypeEnergy,
    auctionsGetClassificationsByGenerationType,
    //auctionsGetClassificationsByTimberSortment,
    //auctionsGetClassificationsByTimberSpecies,
    //auctionsGetClassificationsByTimberClass,
    //auctionsGetClassificationsByTimberDiameter,
    //auctionsGetClassificationsByTimberLength,
    //auctionsGetClassificationsByTimberStorage,
    //auctionsGetClassificationsByTimberProductionYear,
    //auctionsGetClassificationsByTimberProductionQuarter,
    changeTimberSortment,
    changeTimberSpecies,
    changeTimberClass,
    changeTimberDiameter,
    changeTimberLength,
    changeTimberStorage,
    changeTimberProductionYear,
    changeTimberProductionQuarter,
    changeCreateItemUnitCode,
    changeCreateItemGenerationType,
    changeLoader,
    getMyAuction,
    changeCreateItemLocationLatitude,
    changeCreateItemLocationLongitude,
    changeCreateItemLocationElevation,
    auctionsGetClassificationsByTypeDGFAdd,
    seDgfMask,
    changeCreateItemAddress,
    changeCreateItemDgfJointPropertyComplexPropsId,
    changeCreateItemDgfJointPropertyComplexPropsQuantityAndNomenclature,
    changeCreateItemDgfJointPropertyComplexPropsWorkplacesQuantity,
    changeCreateItemDgfJointPropertyComplexPropsBuildingsInformation,
    changeCreateItemDgfJointPropertyComplexPropsLandPlotInformation,
    changeCreateItemDgfJointPropertyComplexPropsMainObligations,
    changeCreateItemDgfLandPropsId,
    changeCreateItemDgfLandPropsLandArea,
    changeCreateItemDgfLandPropsCadastralNumber,
    changeCreateItemDgfLandPropsOwnershipType,
    changeCreateItemDgfLandPropsEncumbrances,
    changeCreateItemDgfLandPropsJointOwnership,
    changeCreateItemDgfLandPropsUtilitiesAvailability,
    changeCreateItemDgfLandPropsIntendedUse,
    changeCreateItemDgfVehiclePropsId,
    changeCreateItemDgfVehiclePropsBrand,
    changeCreateItemDgfVehiclePropsModel,
    changeCreateItemDgfVehiclePropsProductionYear,
    changeCreateItemDgfVehiclePropsEngineCapacity,
    changeCreateItemDgfVehiclePropsFuelType,
    changeCreateItemDgfVehiclePropsTransmission,
    changeCreateItemDgfVehiclePropsColor,
    changeCreateItemDgfVehiclePropsKilometrage,
    changeCreateItemDgfVehiclePropsIdentificationNumber,
    changeCreateItemDgfVehiclePropsConfiguration,
    changeCreateItemDgfVehiclePropsCondition,
    changeCreateItemDgfVehiclePropsDamagePresence,
    changeCreateItemDgfVehiclePropsDamagedDescription,
    /** */
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemsList)
