import React, { useState, useEffect} from 'react';
import styles from '../procedure.module.css'

import {useSelector} from 'react-redux';
import {PROCEDURE_NAME} from '../../../../../../redux/procedure_v3.35.2.uk';

import { isMongo, checkProp } from '../../../../../../redux/check';

import TextArea from '../../../../../elements/inputs/textArea'
import Input from '../../../../../elements/inputs/input';
import Select from '../../../../../elements/inputs/inputSelect'
import Button from '../../../../../elements/buttons/button';
import InputAmount from '../../../../../elements/inputs/inputAmountBreBrd';
import InputDate from '../../../../../elements/inputs/inputDate';
import Checkbox from '../../../../../elements/inputs/checkBox';
import BankAccount from '../../bankAccount/bankAccount';

import { disabletElem } from '../../../../disabled';

//import { setTenderAttemptList, сreateBodyCSE } from './utils'; 
import { сreateBody } from '../lib/createBody';
import { setTenderAttemptListBREBRD } from '../lib/utils';

import {editAuc, createAuc, editAucPatch} from '../../../createEditAndCloneAuction/edit/editTabMonga'

import LocationOrg from '../../../../elems/localization/locationOrg';

const AuctionBRE = ( {data, ...props} ) => {
    const auction = useSelector(state => state.start.auctionsMy)
    const [auctionBank, setAuctionBank] = useState(null)


    const [lotId, setLotId] = useState(checkProp(data, 'lotId') ? data.lotId : null)
    const [title, setTitle] = useState(checkProp(data, 'title') ? isMongo(data.title) : null)
    const [description, setDescription] = useState(checkProp(data, 'description') ? isMongo(data.description) : null)
    const [tenderAttempts, setTenderAttempts] = useState(checkProp(data, 'tenderAttempts') ? data.tenderAttempts : null)
    const [accessDetails, setAccessDetails] = useState(checkProp(data, 'accessDetails') ? isMongo(data.accessDetails) : null)
    const [x_documentRequirements, setX_documentRequirements] = useState(checkProp(data, 'x_documentRequirements') ? isMongo(data.x_documentRequirements) : null)
    const [x_additionalInformation, setX_additionalInformation] = useState(checkProp(data, 'x_additionalInformation') ? isMongo(data.x_additionalInformation) : null)
    const [minNumberOfQualifiedBids, setMinNumberOfQualifiedBids] = useState(checkProp(data, 'minNumberOfQualifiedBids') ? data.minNumberOfQualifiedBids : 1)
    const [previousAuctionId, setPreviousAuctionId] = useState(checkProp(data, 'previousAuctionId') ? data.previousAuctionId : null)
    const [discount, setDiscount] = useState(checkProp(data,'discount') && checkProp(data.discount,'discount') ? data.discount.discount : false)
    const [discountPercent, setDiscountPercent] = useState(checkProp(data,'discount') && checkProp(data.discount,'discountPercent') ? data.discount.discountPercent : null)
    const [previousAuctionValue, setPreviousAuctionValue] = useState(checkProp(data,'discount') && checkProp(data.discount,'previousAuctionValue') ? data.discount.previousAuctionValue : null)
    const [value, setValue] = useState(checkProp(data, 'value') ? data.value : null)
    const [guarantee, setGuarantee] = useState(checkProp(data, 'guarantee') ? data.guarantee : null)
    const [minimalStep, setMinimalStep] = useState(checkProp(data, 'minimalStep') ? data.minimalStep : null)
    const [registrationFee, setRegistrationFee] = useState(checkProp(data, 'registrationFee') ? data.registrationFee : null)
    const [valueAddedTaxCharged, setValueAddedTaxCharged] = useState(checkProp(data, 'valueAddedTaxCharged') ? data.valueAddedTaxCharged : null)
    const [startDate, setStartDate] = useState(checkProp(data, 'auctionPeriod') && checkProp(data.auctionPeriod, 'startDate') ? data.auctionPeriod.startDate : null)

    const [restrictions, setRestrictions] = useState(checkProp(data, 'restrictions') ? isMongo(data.restrictions) : null)
    const [isTaxInvoiceProvided, setIsTaxInvoiceProvided] = useState(checkProp(data, 'isTaxInvoiceProvided') ? data.isTaxInvoiceProvided : false)
    const [economicCourtName, setEconomicCourtName] = useState(checkProp(data, 'economicCourtName') ? isMongo(data.economicCourtName) : null)
    const [bankruptcyCaseNumber, setBankruptcyCaseNumber] = useState(checkProp(data, 'bankruptcyCaseNumber') ? isMongo(data.bankruptcyCaseNumber) : null)
    const [sellingMethodType, setSellingMethodType] = useState(checkProp(data, 'sellingMethodType') ? isMongo(data.sellingMethodType) : null)
    const [bankAccounts, setBankAccounts] = useState(checkProp(data,'bankAccounts') ? data.bankAccounts : [])

    const [debtorInformationName, setDebtorInformationName] = useState((checkProp(data, 'debtorInformation') &&  checkProp(data.debtorInformation, 'name')) ? isMongo(data.debtorInformation.name) : null)
    const [representativeInfo, setRepresentativeInfo] = useState((checkProp(data, 'debtorInformation') && checkProp(data.debtorInformation, 'representativeInfo')) ? data.debtorInformation.representativeInfo : null)
    
    const [contactPointName, setContactPointName] = useState((checkProp(data, 'debtorInformation') && checkProp(data.debtorInformation, 'contactPoint') && checkProp(data.debtorInformation.contactPoint, 'name')) ? isMongo(data.debtorInformation.contactPoint.name) : null)
    const [contactPointEmail, setContactPointEmail] = useState((checkProp(data, 'debtorInformation') && checkProp(data.debtorInformation, 'contactPoint') && checkProp(data.debtorInformation.contactPoint, 'email')) ? data.debtorInformation.contactPoint.email : null)
    const [contactPointTelephone, setContactPointTelephone] = useState((checkProp(data, 'debtorInformation') && checkProp(data.debtorInformation, 'contactPoint') && checkProp(data.debtorInformation.contactPoint, 'telephone')) ? data.debtorInformation.contactPoint.telephone : null)
    const [contactPointFaxNumber, setContactPointFaxNumber] = useState((checkProp(data, 'debtorInformation') && checkProp(data.debtorInformation, 'contactPoint') && checkProp(data.debtorInformation.contactPoint, 'faxNumber')) ? data.debtorInformation.contactPoint.faxNumber : null)
    const [contactPointUrl, setContactPointUrl] = useState((checkProp(data, 'debtorInformation') && checkProp(data.debtorInformation, 'contactPoint') && checkProp(data.debtorInformation.contactPoint, 'url')) ? data.debtorInformation.contactPoint.url : null)

    const [identifierLegalName, setIdentifierLegalName] = useState((checkProp(data, 'debtorInformation') && checkProp(data.debtorInformation, 'identifier') && checkProp(data.debtorInformation.identifier, 'legalName')) ? isMongo(data.debtorInformation.identifier.legalName) : null)
    const [identifierScheme, setIdentifierScheme] = useState((checkProp(data, 'debtorInformation') && checkProp(data.debtorInformation, 'identifier') && checkProp(data.debtorInformation.identifier, 'scheme')) ? data.debtorInformation.identifier.scheme : null)
    const [identifierId, setIdentifierId] = useState((checkProp(data, 'debtorInformation') && checkProp(data.debtorInformation, 'identifier') && checkProp(data.debtorInformation.identifier, 'id')) ? data.debtorInformation.identifier.id : null)

    const [address, setAdress] = useState(
        (checkProp(data, 'debtorInformation')
        && checkProp(data.debtorInformation, 'address')
    ) ? data.debtorInformation.address : null)


    const [addressCountryName, setAddressCountryName] = useState((checkProp(data, 'debtorInformation') && checkProp(data.debtorInformation, 'address') && checkProp(data.debtorInformation.address, 'countryName')) ? isMongo(data.debtorInformation.address.countryName) : null)
    const [addressRegion, setAddressRegion] = useState((checkProp(data, 'debtorInformation') && checkProp(data.debtorInformation, 'address') && checkProp(data.debtorInformation.address, 'region')) ? isMongo(data.debtorInformation.address.region) : null)
    const [addressLocality, setAddressLocality] = useState((checkProp(data, 'debtorInformation') && checkProp(data.debtorInformation, 'address') && checkProp(data.debtorInformation.address, 'locality')) ? isMongo(data.debtorInformation.address.locality) : null)
    const [addressStreetAddress, setAddressStreetAddress] = useState((checkProp(data, 'debtorInformation') && checkProp(data.debtorInformation, 'address') && checkProp(data.debtorInformation.address, 'streetAddress')) ? isMongo(data.debtorInformation.address.streetAddress) : null)
    const [addressPostalCode, setAddressPostalCode] = useState((checkProp(data, 'debtorInformation') && checkProp(data.debtorInformation, 'address') && checkProp(data.debtorInformation.address, 'postalCode')) ? data.debtorInformation.address.postalCode : null)

    const [representativeCertificateNumber, setRepresentativeCertificateNumber] = useState((checkProp(data, 'sellingEntity') && checkProp(data.sellingEntity, 'representativeCertificateNumber')) ? data.sellingEntity.representativeCertificateNumber : null)
    const [representativeCertificateDate, setRepresentativeCertificateDate] = useState((checkProp(data, 'sellingEntity') && checkProp(data.sellingEntity, 'representativeCertificateDate')) ? data.sellingEntity.representativeCertificateDate : null)


    

    useEffect(() => {
        if(tenderAttempts === 1){
            setSellingMethodType('Aукціон - без можливості зниження початкової ціни')
        }else if(tenderAttempts === 2){
            setSellingMethodType('Перший повторний аукціон - без можливості зниження початкової ціни')
        }else if(tenderAttempts === 3){
            setSellingMethodType('Другий повторний аукціон - з можливістю зниження початкової ціни')
        }
        setAuctionBank(JSON.stringify(auction))
    }, [])

    let requestBody = {
        title: {
            uk_UA: title
        },
        description: {
            uk_UA: description
        },
        accessDetails: accessDetails === null || accessDetails === ''
            ? null
            : {uk_UA: accessDetails},
        tenderAttempts: +tenderAttempts,
        lotId: lotId,
        auctionPeriod: {
            startDate: startDate
        },
        sellingMethod: data.sellingMethod,
        x_documentRequirements: x_documentRequirements === null || x_documentRequirements === ""
            ? null
            : {uk_UA: x_documentRequirements},
        x_additionalInformation: x_additionalInformation === null || x_additionalInformation === ""
            ? null
            : {uk_UA: x_additionalInformation},
        minNumberOfQualifiedBids: +minNumberOfQualifiedBids,
        valueAddedTaxCharged: valueAddedTaxCharged,
        value: {
            currency: checkProp(value,'currency') ? value.currency : null,
            amount: checkProp(value,'amount') ? +value.amount : null,
            valueAddedTaxIncluded: checkProp(value,'addTax') ? value.addTax : null
        },
        minimalStep: {
            currency: checkProp(minimalStep,'currency') ? minimalStep.currency : null,
            amount: checkProp(minimalStep,'amount') ? +minimalStep.amount : null,
        },
        guarantee: {
            currency: checkProp(guarantee,'currency') ? guarantee.currency : null,
            amount: checkProp(guarantee,'amount') ? +guarantee.amount : null
        },
        registrationFee: {
            currency: checkProp(registrationFee,'currency') ? registrationFee.currency : null,
            amount: checkProp(registrationFee,'amount') ? +registrationFee.amount : null
        },
        previousAuctionId: previousAuctionId,
        discount: {
            discount: discount,
            discountPercent: discountPercent,
            previousAuctionValue:{
                currency: checkProp(previousAuctionValue,'currency') ? previousAuctionValue.currency : null,
                amount: checkProp(previousAuctionValue,'amount') ? +previousAuctionValue.amount : null,
                valueAddedTaxIncluded: checkProp(previousAuctionValue,'addTax') ? previousAuctionValue.addTax : null
            }
        },
        bankAccounts: bankAccounts,
        restrictions: restrictions === null || restrictions === ''
            ? null
            : {
                uk_UA: restrictions
            },
        isTaxInvoiceProvided: isTaxInvoiceProvided,
        economicCourtName: {
            uk_UA: economicCourtName
        },
        bankruptcyCaseNumber: {
            uk_UA: bankruptcyCaseNumber
        },
        sellingMethodType: {
            uk_UA: sellingMethodType
        },
        debtorInformation: {
            name: debtorInformationName,
            identifier: {
                scheme: identifierScheme,
                id: identifierId,
                legalName: {
                    uk_UA: identifierLegalName
                }
            },
            /*address: {
                countryName: {
                    uk_UA: addressCountryName
                },
                region: {
                    uk_UA: addressRegion
                },
                locality: {
                    uk_UA: addressLocality
                },
                streetAddress: {
                    uk_UA: addressStreetAddress
                },
                postalCode: addressPostalCode
            },*/
            address: address,
            representativeInfo: representativeInfo,
            contactPoint: {
                name: {
                    uk_UA: contactPointName
                },
                email: contactPointEmail,
                telephone: contactPointTelephone,
                faxNumber: contactPointFaxNumber,
                url: contactPointUrl
            }
        },
        sellingEntity: {
            representativeCertificateNumber: representativeCertificateNumber,
            representativeCertificateDate: representativeCertificateDate
        }
    }

    function disabledInput(auction, cod, type){
        if(
            auction.status === 'complete' ||
            auction.status === 'cancelled' ||
            auction.status === 'unsuccessful'
        ){
            return true
        }
        if(
            auction.status === 'active_auction' ||
            auction.status === 'active_qualification' ||
            auction.status === 'pending_payment' ||
            auction.status === 'active_awarded'
        ){
            if(!type){
                return false
            }else{
                return true
            }
        }
        return disabletElem(auction, cod)
    }

    function discountBlock(){
        if (tenderAttempts > 1){
            return(
                <div className={styles.costBlock}>
                    <div className={styles.costBlockTitle}>Дані попереднього аукціону</div>
                    <div className={styles.twoInLine}>
                        <Input //"Ідентифікатор попереднього аукціону"
                            label={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.previousAuctionId.legalName} 
                            value={previousAuctionId}
                            onChange={(e)=>{
                                setPreviousAuctionId(e)
                            }}
                            disabled={disabledInput(auction, '01', true)}
                            required
                        />
                    </div>
                    {
                        discount 
                        ? 
                        <>
                            <Checkbox 
                                value={discount}
                                onChange={setDiscount}
                                required
                                disabled={disabledInput(auction, '01', true)}
                            >
                            Застосувати знижку
                            </Checkbox>
                            <div 
                            // className={styles.twoInLine}
                                style={{width: '60%', height: '180px', display: 'flex', flexFlow: 'column', justifyContent: 'space-between'}}
                            >
                                <InputAmount //"Стартова вартість попереднього аукціону"
                                    title={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.discount.previousAuctionValue.legalName}
                                    lable={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.discount.previousAuctionValue.amount.legalName}
                                    data={{
                                        amount: checkProp(previousAuctionValue,'amount') ? previousAuctionValue.amount : null,
                                        currency: checkProp(previousAuctionValue,'currency') ? previousAuctionValue.currency : null,
                                        addTax: checkProp(previousAuctionValue,'valueAddedTaxIncluded') ? previousAuctionValue.valueAddedTaxIncluded : true,
                                        auctionType: 'brd'
                                    }}
                                    onChange={(e) => {
                                        setPreviousAuctionValue(e)
                                        if(discount !== null
                                            && discount === true
                                            && discountPercent !== null
                                            && discountPercent !== ''
                                            && e.amount !== null
                                            && e.amount !== ''
                                        ){
                                            let tmpDisValue = {
                                                currency: value.currency,
                                                amount: Number(e.amount) - (Number(e.amount)/100)*Number(discountPercent),
                                                addTax: value.valueAddedTaxIncluded
                                            }
                                            setValue(tmpDisValue)
                                            if(Number(tmpDisValue.amount) <= 20000){
                                                setRegistrationFee({registrationFee, amount: 17})
                                            }else if(Number(tmpDisValue.amount) <= 50000){
                                                setRegistrationFee({registrationFee, amount: 119})
                                            }else if(Number(tmpDisValue.amount) <= 200000){
                                                setRegistrationFee({registrationFee, amount: 340})
                                            }else if(Number(tmpDisValue.amount) <= 1000000){
                                                setRegistrationFee({registrationFee, amount: 510})
                                            }else if(Number(tmpDisValue.amount) > 1000000){
                                                setRegistrationFee({registrationFee, amount: 1700})
                                            }
                                        }
                                    }}
                                    disabled={disabledInput(auction, '01', true)}
                                    required
                                    tax
                                />
                                <Input //"Розмір знижки від попереднього аукціону, %"
                                    label={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.discount.discountPercent.legalName} 
                                    value={discountPercent}
                                    type='number'
                                    onChange={(e)=>{
                                        if(Number(e) <= 100){
                                            setDiscountPercent(e)
                                            if(discount !== null
                                                && discount === true
                                                && e !== null
                                                && e !== ''
                                                && previousAuctionValue.amount !== null
                                                && previousAuctionValue.amount !== ''
                                            ){
                                                let tmpDisValue = {
                                                    currency: value.currency,
                                                    amount: Number(previousAuctionValue.amount) - (Number(previousAuctionValue.amount)/100)*Number(e),
                                                    addTax: value.valueAddedTaxIncluded
                                                }
                                                setValue(tmpDisValue)
                                                if(Number(tmpDisValue.amount) <= 20000){
                                                    setRegistrationFee({registrationFee, amount: 17})
                                                }else if(Number(tmpDisValue.amount) <= 50000){
                                                    setRegistrationFee({registrationFee, amount: 119})
                                                }else if(Number(tmpDisValue.amount) <= 200000){
                                                    setRegistrationFee({registrationFee, amount: 340})
                                                }else if(Number(tmpDisValue.amount) <= 1000000){
                                                    setRegistrationFee({registrationFee, amount: 510})
                                                }else if(Number(tmpDisValue.amount) > 1000000){
                                                    setRegistrationFee({registrationFee, amount: 1700})
                                                }
                                            }
                                        }
                                    }}
                                    disabled={disabledInput(auction, '01', true)}
                                    required
                                />
                            </div>
                        </>
                        : 
                        <Checkbox 
                            value={discount}
                            onChange={setDiscount}
                            disabled={disabledInput(auction, '01', true)}
                        >
                            Застосувати знижку
                        </Checkbox>
                    }
                </div>
            )
        } else {
            requestBody.discount = null
        }
        return null
    }

    function openSaveText(){
        // let tmp = null
        // let bodyObj = {
        //     base: requestBody,
        // }
        // if(auction !== null && auction.id !== null && auction.id !== ''){
        //     tmp = сreateBody(bodyObj, JSON.parse(auctionBank))
        //     if(JSON.stringify(tmp).length < 3){
        //         return 'Зміни відсутні'
        //     }
        // }
        return 'Зберегти зміни'
    }

    function openSaveColor(){
        // let tmp = null
        // let bodyObj = {
        //     base: requestBody,
        // }
        // if(auction !== null && auction.id !== null && auction.id !== ''){
        //     tmp = сreateBody(bodyObj, JSON.parse(auctionBank))
        //     if(JSON.stringify(tmp).length < 3){
        //         return true
        //     }
        // }
        return false
    }

    return (
        <>
            <Input //"Номер лота" 
                label={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.lotId.legalName} 
                value={lotId}
                onChange={(e)=>{ setLotId(e) }}
                disabled={disabledInput(auction, '01', true)}
                required
            />
            <Input //Назва аукціону"
                label={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.title.legalName} 
                value={title}
                onChange={(e)=>{ setTitle(e) }}
                disabled={disabledInput(auction, '01', true)}
                required
            />
            <TextArea //"Опис аукціону"
                value={description}
                disabled={disabledInput(auction, '01', true)}
                label={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.description.legalName} 
                required
                onChange={(e)=>{ setDescription(e) }}
            />


            <Select //'Лот виставляється',
                data={{
                    label: PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.tenderAttempts.legalName, 
                    target: tenderAttempts,
                    // info: 'Отака от штука-дрюка',
                    required: true,
                }}
                disabled={disabledInput(auction, '01', true)}
                onChange={(e) => { 
                        setTenderAttempts(e)
                        if(e === '1'){
                            setSellingMethodType('Aукціон - без можливості зниження початкової ціни')
                        }else if(e === '2'){
                            setSellingMethodType('Перший повторний аукціон - без можливості зниження початкової ціни')
                        }else{
                            setSellingMethodType('Aукціон - без можливості зниження початкової ціни')
                        }
                    }
                }
                options={setTenderAttemptListBREBRD()}
            />
            <Input //Підтип процедури
                label='Підтип процедури' 
                value={sellingMethodType}
                disabled={true}
            />



            <Input //"Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
                label={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.accessDetails.legalName} 
                value={accessDetails}
                onChange={(e)=>{ setAccessDetails(e) }}
                disabled={disabledInput(auction, '01', true)}
            />
            <Input //"Перелік та вимоги до оформлення документів"
                label={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.x_documentRequirements.legalName} 
                value={x_documentRequirements}
                onChange={(e)=>{ setX_documentRequirements(e) }}
                disabled={disabledInput(auction, '01', true)}
            />
            <Input
                label={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.x_additionalInformation.legalName} //"Додаткові відомості"
                value={x_additionalInformation}
                onChange={(e)=>{ setX_additionalInformation(e) }}
                disabled={disabledInput(auction, '01', true)}
            />
            {/*<Input
                label={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.minNumberOfQualifiedBids.legalName} //"Мінімальна кількість заяв"
                value={minNumberOfQualifiedBids}
                type='number'
                onChange={(e)=>{ setMinNumberOfQualifiedBids(e) }}
                disabled={true}
            />*/}
            {/*<Select //"Мінімальна кількість заяв" 
                data={{
                    label: PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.minNumberOfQualifiedBids.legalName, 
                    target: minNumberOfQualifiedBids,
                    // info: 'Отака от штука-дрюка',
                    // required: true,
                }}
                disabled={true}
                onChange={(e) => { setMinNumberOfQualifiedBids(e) }}
                options={
                    [
                        {value: 'null', name: 'Обрати', selected: true},
                        {value: '1', name: '1'},
                        {value: '2', name: '2'}
                    ]
                }
            />*/}

            <Input //Обмеження"
                label='Обмеження' 
                value={restrictions}
                onChange={(e)=>{ setRestrictions(e) }}
                disabled={disabledInput(auction, '01', true)}
            />
            <Checkbox 
                value={isTaxInvoiceProvided}
                onChange={(e) => {
                    setIsTaxInvoiceProvided(e)
                }}
                disabled={disabledInput(auction, '01', true)}
                required
            >
                Податкова накладна надається?
            </Checkbox>
            <Input //Найменування господарського суду
                label='Найменування господарського суду' 
                value={economicCourtName}
                onChange={(e)=>{ setEconomicCourtName(e) }}
                disabled={disabledInput(auction, '01', true)}
                required
            />
            <Input //№ справи про банкрутство
                label='№ справи про банкрутство' 
                value={bankruptcyCaseNumber}
                onChange={(e)=>{ setBankruptcyCaseNumber(e) }}
                disabled={disabledInput(auction, '01', true)}
                required
            />

            <div className={styles.costBlock}>
                <div className={styles.costBlockTitle}>Інформація про Організатора аукціону</div>
                <Input //№ свідоцтва про право на провадження діяльності арбітражного керуючого
                    label='№ свідоцтва про право на провадження діяльності арбітражного керуючого'
                    value={representativeCertificateNumber}
                    onChange={(e)=>{ setRepresentativeCertificateNumber(e) }}
                    required
                />
                <InputDate
                    data={{
                        label:'Дата видачі про право на провадження діяльності арбітражного керуючого',
                        target: representativeCertificateDate,
                        time: false
                    }}
                    onChange={ e => setRepresentativeCertificateDate(e) }
                    required
                />
            </div>

            <div className={styles.costBlock}>
                <div className={styles.costBlockTitle}>Інформація про боржника</div>
                <Input //Повна юридична назва організації або ПІБ
                    label='Повна юридична назва організації або ПІБ'
                    value={identifierLegalName}
                    onChange={(e)=>{ setDebtorInformationName(e) }}
                    disabled='true'
                />
                <div className={styles.orgBlock}>
                    <div className={styles.costBlockTitle}>Ідентифікатори організації або особи</div>
                    <Input //Ідентифікатори організації
                        label='Повна юридична назва організації'
                        value={identifierLegalName}
                        onChange={(e)=>{ setIdentifierLegalName(e) }}
                        disabled={disabledInput(auction, '01', true)}
                        required
                    />
                    <Select 
                        data={{
                            label: 'Ідентифікатори організації',
                            target: identifierScheme,
                            // info: 'Отака от штука-дрюка',
                            required: true,
                        }}
                        onChange={(e) => { setIdentifierScheme(e) }}
                        options={[
                            {name: 'Обрати', value: null},
                            {name: 'ЄДРПОУ', value: 'UA-EDR'},
                            {name: 'ІПН/РНОКПП', value: 'UA-IPN'},
                            {name: 'Паспорт', value: 'UA-PASSPORT'},
                            {name: 'ID-карта', value: 'UA-ID-CARD'},
                            {name: 'ІПН/РНОКПП (ФОП)', value: 'UA-IPN-FOP'}
                        ]}
                        disabled={disabledInput(auction, '01', true)}
                    />
                    <Input //Код ЄДРПОУ або ІПН або паспорт
                        label='Код ЄДРПОУ або ІПН або паспорт'
                        value={identifierId}
                        onChange={(e)=>{ setIdentifierId(e) }}
                        disabled={disabledInput(auction, '01', true)}
                        required
                    />
                </div>
                <LocationOrg
                    address={address}
                    setAdress={setAdress}
                    dataText='Місцезнаходження боржника'
                    disabled={disabledInput(auction, '01', true)}
                />
                <div className={styles.orgBlock}>
                    <div className={styles.costBlockTitle}>Контактна особа</div>
                    <Input //ПІБ
                        label='ПІБ'
                        value={contactPointName}
                        onChange={(e)=>{ setContactPointName(e) }}
                        disabled={disabledInput(auction, '01', true)}
                        required
                    />
                    <Input //Адреса електронної пошти
                        label='Адреса електронної пошти'
                        value={contactPointEmail}
                        onChange={(e)=>{ setContactPointEmail(e) }}
                        disabled={disabledInput(auction, '01', true)}
                        required
                    />
                    <Input //Номер телефону
                        label='Номер телефону'
                        value={contactPointTelephone}
                        onChange={(e)=>{ setContactPointTelephone(e) }}
                        disabled={disabledInput(auction, '01', true)}
                        required
                    />
                    <Input //Номер факсу
                        label='Номер факсу'
                        value={contactPointFaxNumber}
                        onChange={(e)=>{ setContactPointFaxNumber(e) }}
                        disabled={disabledInput(auction, '01', true)}
                    />
                    <Input //Веб адреса
                        label='Веб адреса'
                        value={contactPointUrl}
                        onChange={(e)=>{ setContactPointUrl(e) }}
                        disabled={disabledInput(auction, '01', true)}
                    />
                </div>
            </div>



            {discountBlock()}
            <div className={styles.costBlock}>
                <div className={styles.costBlockTitle}>Вартісні характеристики аукціону</div>
                <InputAmount //"Стартова ціна лота"
                    title={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.value.legalName}
                    lable={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.value.amount.legalName}
                    data={{
                        amount: checkProp(value,'amount') ? value.amount : null,
                        currency: checkProp(value,'currency') ? value.currency : null,
                        addTax: checkProp(value,'valueAddedTaxIncluded') ? value.valueAddedTaxIncluded : true,
                        auctionType: 'brd'
                    }}
                    onChange={(e) => {
                        setValue(e)
                        if(Number(e.amount) <= 20000){
                            setRegistrationFee({registrationFee, amount: 17})
                        }else if(Number(e.amount) <= 50000){
                            setRegistrationFee({registrationFee, amount: 119})
                        }else if(Number(e.amount) <= 200000){
                            setRegistrationFee({registrationFee, amount: 340})
                        }else if(Number(e.amount) <= 1000000){
                            setRegistrationFee({registrationFee, amount: 510})
                        }else if(Number(e.amount) > 1000000){
                            setRegistrationFee({registrationFee, amount: 1700})
                        }
                    }}
                    disabled={disabledInput(auction, '01', true)}
                    tax
                />
                <InputAmount //"Гарантійний внесок"
                    title={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.guarantee.legalName}
                    lable={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.guarantee.amount.legalName}
                    data={{
                        amount: checkProp(guarantee,'amount') ? guarantee.amount : null,
                        currency: checkProp(guarantee,'currency') ? guarantee.currency : null,
                        auctionType: 'brd'
                    }}
                    onChange={(e) => { setGuarantee(e) }}
                    disabled={disabledInput(auction, '01', true)}
                />
                <InputAmount //"Розмір кроку аукціону"
                    title={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.minimalStep.legalName}
                    lable={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.minimalStep.amount.legalName}
                    data={{
                        amount: checkProp(minimalStep,'amount') ? minimalStep.amount : null,
                        currency: checkProp(minimalStep,'currency') ? minimalStep.currency : null,
                        auctionType: 'brd'
                    }}
                    onChange={(e) => { setMinimalStep(e) }}
                    disabled={disabledInput(auction, '01', true)}
                />
                {/*<InputAmount //"Реєстраційний внесок"
                    title={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.registrationFee.legalName}
                    lable={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.registrationFee.amount.legalName}
                    data={{
                        amount: checkProp(registrationFee,'amount') ? registrationFee.amount : null,
                        currency: checkProp(registrationFee,'currency') ? registrationFee.currency : null,
                    }}
                    onChange={(e) => { setRegistrationFee(e) }}
                    disabled={disabletElem(auction, '01')}
                />*/}
                <div style={{height: '20px'}}></div>
                <Checkbox 
                        value={valueAddedTaxCharged}
                        onChange={setValueAddedTaxCharged}
                        disabled={disabledInput(auction, '01', true)}
                    >
                        На фінальну суму нараховується ПДВ
                </Checkbox>
            </div>
            <div className={styles.startDate}>
                <div className={styles.costBlockTitle}>
                    {`${PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.auctionPeriod.startDate.legalName} аукціону`}
                </div>
                <InputDate
                    data={{
                        target: startDate,
                        time: true
                    }}
                    onChange={ e => setStartDate(e) }
                    disabled={disabledInput(auction, '11', true)}
                />
            </div>
            <BankAccount bankAccounts={bankAccounts} setBankAccounts={setBankAccounts} />

            <div className={styles.bottomButtonBlock}>
                <Button
                    height={'50px'}
                    font={'16px'}
                    color='blue'
                    data={'Зберегти зміни'}
                    disabled={title !== null && title !== '' && description !== null && description !== '' ? false : true}
                    onClick={
                        () =>{
                            let tmp = null
                            let bodyObj = {
                                base: requestBody,
                            }

                            
                            if(auction.id !== null && auction.id !== ''){
                                tmp = сreateBody(bodyObj, JSON.parse(auctionBank))
                                //if(Object.keys(tmp).length !== 0){
                                    if(auction.status === null){
                                        editAuc(props.updateAuc, tmp)
                                    }else{
                                        editAucPatch(props.updateAuc, tmp) 
                                    }
                                //}
                            }else{
                                tmp = сreateBody(bodyObj)
                                createAuc(props.updateAuc, tmp)
                            }
                        }
                    }
                />
            </div>
        </>
    )
}

export default AuctionBRE