import React from 'react';
import styles from './imageAndButtonLine_Left.module.css';

import {connect} from 'react-redux';

import ImageAndButtonLineImg from './imageAndButtonLine_Img/imageAndButtonLine_Img.js';
import ImageAndButtonLineText from './imageAndButtonLine_Text/imageAndButtonLine_Text.js';

const ImageAndButtonLine_Left = ( props ) => {
    return (
        <div className={styles.threeBlock_text}>
            <ImageAndButtonLineImg item={props.item} />
            <ImageAndButtonLineText item={props.item} />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
    }
}

export default connect(mapStateToProps, null)(ImageAndButtonLine_Left)