import React from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import { isBRD, isBRE, isALE, isBSD, isBSE, isCSD, isCSE,
    isLRE, isLSE, isLSP, isAAE, isNLE, isNLD,
    isLAE, isLAW, isLAP
} from '../../../../redux/aucTypes';
import {changeLoader} from '../../../../redux/actions.js';
import {setDataAddress} from '../../../../redux/createAddress';
import {bankAccountGet} from '../../../../redux/actions/bankAccount/bankAccount.js';
import {setUniversalError} from '../../../../redux/actions.js';
import {setPopUpAuctionCreate} from '../../../../redux/actions/auctions/auctions.js';

import Procedure from '../createAuction/procedure/procedure';
import EditAuctionTab from './edit/navTitleBar/navTitleBarMonga'

import CreateAuction from './create/createAuction'
import EditAuction from './edit/editAuction'
import CloneAuction from './clone/cloneAuction'

const SwitcherTypeWorkAuction = ( props ) => {
    if(props.auctionsMy !== null){
        if(props.auctionsSwitchWindow !== null){
            switch(props.auctionsSwitchWindow){
                case 1:
                    return <EditAuction />
                case 2:
                    return <CreateAuction />
                case 3:
                    return <CloneAuction />
                default:
                    return null
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        auctionsMy: state.start.auctionsMy,
    }
}

const mapDispatchToProps = {
    changeLoader,
    setDataAddress,
    bankAccountGet,
    setUniversalError,
    setPopUpAuctionCreate,
}

export default connect(mapStateToProps, mapDispatchToProps)(SwitcherTypeWorkAuction)