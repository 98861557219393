import React from 'react';
import styles from './lotInformation.module.css';

import {connect} from 'react-redux';

import TextFull from './textFull'

import AccessDetailsString from './auctionElements/accessDetails'
import PreviousAuctionIdString from './auctionElements/previousAuctionId'
import NormativeMonetaryValuationString from './auctionElements/normativeMonetaryValuation'
import ExpertMonetaryValuationString from './auctionElements/expertMonetaryValuation'
import BudgetSpentString from './auctionElements/budgetSpent'
import RegistrationFeeString from './auctionElements/registrationFee'
import ValueAddedTaxChargedString from './auctionElements/valueAddedTaxCharged'
import LeaseDurationString from './auctionElements/leaseDuration'
import AuctionUrlString from './auctionElements/auctionUrl'
import GuaranteeString from './auctionElements/guarantee'
import MinimalStepString from './auctionElements/minimalStep_old'
import XAdditionalInformationString from './auctionElements/xAdditionalInformation'
import MinNumberOfQualifiedBidsString from './auctionElements/minNumberOfQualifiedBids'
import SellingMethodString from './auctionElements/sellingMethod'
import LotIdentifierString from './auctionElements/lotIdentifier'
import TenderAttemptsString from './auctionElements/tenderAttempts'
import AuctionIdString from './auctionElements/auctionId'
import DescriptionString from './auctionElements/description'
import ValueString from './auctionElements/value'
import LeaseTypeString from './auctionElements/leaseType'
import SaleTypeString from './auctionElements/saleType'
import ConditionsString from './auctionElements/conditions'
import RestrictionsString from './auctionElements/restrictions'
import LotCurrentTenantString from './auctionElements/currentTenant'
import LotPreviousAuctionBidderString from './auctionElements/previousAuctionBidder'
import XPlatformLegalDetailsString from './auctionElements/xPlatformLegalDetailsNew'
import XDocumentRequirements from './auctionElements/xDocumentRequirements'
import NonRemovable from './auctionElements/nonRemovable'
import DatePublished from './auctionElements/datePublished'
import DateModified from './auctionElements/dateModified'

import SlimmerBlock from '../../elements/slimmerBlock/slimmerBlock';
import {viewStringValue} from '../../../api/valueToString.js'
const LotInformation = (props) => {
    let tmp = <>
        <ValueString />
        <DescriptionString />
        <AuctionIdString />
        <TenderAttemptsString />
        <LotIdentifierString />
        <SellingMethodString />
        <XAdditionalInformationString />
        <MinNumberOfQualifiedBidsString />
        <MinimalStepString />
        <GuaranteeString />
        <AccessDetailsString />
        <XDocumentRequirements />
        {strAuctionUrl(props)}
        {props.auctionId.dutchStep !== null ? <div className={styles.lotGaranty}>
            <p className={styles.desc}>Кількість кроків</p>
            <p>{strDutchStepQuantity(props)}</p>
        </div> : null}
        <div className={styles.lotGaranty}>
            <p className={styles.desc}>Відсоток зниження, %</p>
            <p>{strDutchStepPercent(props)}</p>
        </div>
        <div className={styles.lotGaranty}>
            <p className={styles.desc}>Сума попереднього аукціона</p>
            <p>{strDiscountPreviousAuctionValue(props)}</p>
        </div>
        <div className={styles.lotGaranty}>
            <p className={styles.desc}>Знижка</p>
            <p>{strDiscountPercent(props)}</p>
        </div>
        <div className={styles.lotGaranty}>
            <p className={styles.desc}>Попередній аукціон</p>
            <p>{strPreviousAuctionId(props)}</p>
        </div>
        <div className={styles.lotGaranty}>
            <p className={styles.desc}>Розмір кроку голландського раунду</p>
            <p>{strDutchStepValue(props)}</p>
        </div>
    </>
    return (
        <SlimmerBlock
            title='Інформація про лот'
            content={tmp}
            slim={true}
        />
    )
}

function strDutchStepValue(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('dutchStep') === true){
            if(props.auctionId.dutchStep !== null){
                if(props.auctionId.dutchStep.dutchStepValue !== null){
                    if(props.auctionId.dutchStep.dutchStepValue.amount !== null){
                        let tmp = viewStringValue(props.auctionId.dutchStep.dutchStepValue)
                        return tmp
                    }
                }
            }
        }
    }
    return null
}

function strPreviousAuctionId(props){
    if(props.auctionId !== null){
        if(props.auctionId.previousAuctionId !== null){
            return props.auctionId.previousAuctionId
        }
    }
    return null
}

function strDiscountPercent(props){
    if(props.auctionId !== null){
        if(props.auctionId.discount !== null){
            if(props.auctionId.discount.discountPercent !== null){
                return props.auctionId.discount.discountPercent + '%'
            }
        }
    }
    return null
}

function strDiscountPreviousAuctionValue(props){
    if(props.auctionId !== null){
        if(props.auctionId.discount !== null){
            if(props.auctionId.discount.previousAuctionValue !== null){
                let tmp = viewStringValue(props.auctionId.discount.previousAuctionValue)
                return tmp
            }
        }
    }
    return null
}

function strDutchStepPercent(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('dutchStep') === true){
            if(props.auctionId.dutchStep !== null){
                return props.auctionId.dutchStep.dutchStepPercent
            }
        }
    }
    return null
}

function strDutchStepQuantity(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('dutchStep') === true){
            if(props.auctionId.dutchStep !== null){
                return props.auctionId.dutchStep.dutchStepQuantity
            }
        }
    }
    return null
}

function strAuctionUrl(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('auctionUrl') === true){
            if(props.auctionId.auctionUrl !== null){
                return (
                    <TextFull
                        title="Веб-адреса для перегляду аукціону"
                        data={props.auctionId.auctionUrl}
                    />
                )
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(LotInformation)
