export function disabletElem(data, status){
    // if(props.auctionsMy.sellingMethod === "subsoil-english"
    // || props.auctionsMy.sellingMethod === "subsoil-english-fast"
    // || props.auctionsMy.sellingMethod === "subsoil-english-fast-manual"
    // || props.auctionsMy.sellingMethod === "subsoil-english-initial-auction"
    // || props.auctionsMy.sellingMethod === "subsoil-english-initial-qualification"
    // || props.auctionsMy.sellingMethod === "subsoil-dutch"
    // || props.auctionsMy.sellingMethod === "subsoil-dutch-fast"
    // || props.auctionsMy.sellingMethod === "subsoil-dutch-fast-manual"
    // || props.auctionsMy.sellingMethod === "subsoil-dutch-initial-auction"
    // || props.auctionsMy.sellingMethod === "subsoil-dutch-initial-qualification"
    // || props.auctionsMy.sellingMethod === "subsoil-dutch-initial-auction-manual"
    // || props.auctionsMy.sellingMethod === "subsoil-english-fast-auction-manual-qualification"){
    //     if(data.status === null){
    //         return null
    //     }else if(data.status === 'active_rectification'){
    //         switch(status){
    //             case '11':
    //                 return 'disabled'
    //             case '11':
    //                 return 'disabled'
    //             default:
    //                 return null
    //         }
    //     }
    // }
    if(
        data.sellingMethod === "legitimatePropertyLease-english" ||
        data.sellingMethod === "legitimatePropertyLease-english-fast" ||
        data.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        data.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        data.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        data.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        data.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        data.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod"
    ){
        if(data.status === null){
            if(data.tenderAttempts === 1){
                switch(status){
                    case '00':
                        return null
                    case '01':
                        if(data.status === null) return 'disabled'
                        if(data.status === 'active_rectification') return null
                        return null
                    case '10':
                        if(data.status === null) return null
                        if(data.status === 'active_rectification') return 'disabled'
                        return 'disabled'
                    case '11':
                        return null
                    default:
                        return null
                }
            }else{
                switch(status){
                    case '00':
                        return null
                    case '01':
                        return null
                    case '10':
                        if(data.status === null) return null
                        if(data.status === 'active_rectification') return 'disabled'
                        return 'disabled'
                    case '11':
                        //return 'disabled'
                        return null
                    default:
                        return null
                }
            }
        }else if(data.status === 'active_rectification'){
            if(data.tenderAttempts === 1){
                switch(status){
                    case '00':
                        return null
                    case '01':
                        if(data.status === null) return 'disabled'
                        if(data.status === 'active_rectification') return null
                        return null
                    case '10':
                        if(data.status === null) return null
                        if(data.status === 'active_rectification') return 'disabled'
                        return 'disabled'
                    case '11':
                        //return 'disabled'
                        return null
                    default:
                        return null
                }
            }else{
                switch(status){
                    case '00':
                        return null
                    case '01':
                        return null
                    case '10':
                        if(data.status === null) return null
                        if(data.status === 'active_rectification') return 'disabled'
                        return 'disabled'
                    case '11':
                        //return 'disabled'
                        return null
                    default:
                        return null
                }
            }
        }else{
            return 'disabled'
        }
    }else if(
        data.sellingMethod === "legitimatePropertyLease-dutch" ||
        data.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        data.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        data.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        data.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        data.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        data.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        data.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        data.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        data.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        data.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        data.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        data.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        data.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        data.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        if(data.status === null || data.status === 'active_rectification'){
            if(data.tenderAttempts === 1){
                switch(status){
                    case '00':
                        return null
                    case '01':
                        if(data.status === null) return 'disabled'
                        if(data.status === 'active_rectification') return null
                        return null
                    case '10':
                        if(data.status === null) return null
                        if(data.status === 'active_rectification') return 'disabled'
                        return 'disabled'
                    case '11':
                        return null
                    default:
                        return null
                }
            }else{
                switch(status){
                    case '00':
                        return null
                    case '01':
                        return null
                    case '10':
                        if(data.status === null) return null
                        if(data.status === 'active_rectification') return 'disabled'
                        return 'disabled'
                    case '11':
                        return null
                    default:
                        return null
                }
            }
        }else{
            return 'disabled'
        }
    }else{
        if(data.status === null){
            return null
        }else if(data.status === 'active_rectification'){
            switch(status){
                case '11':
                    return 'disabled'
                default:
                    return null
            }
        }else{
            let tmpStartData = null
            let tmpEndData = null
            if(data.hasOwnProperty('auctionPeriods')){
                for(let tt = 0; tt < data.auctionPeriods.length; tt++){
                    if(data.auctionPeriods[tt].status === 'rectificationPeriod'){
                        tmpStartData = new Date(data.auctionPeriods[tt].startDate).getTime()
                        tmpEndData = new Date(data.auctionPeriods[tt].endDate).getTime()
                    }
                }
            }else{
                if(data.hasOwnProperty('rectificationPeriod')){
                    if(data.rectificationPeriod !== null){
                        tmpStartData = new Date(data.rectificationPeriod.startDate).getTime()
                        tmpEndData = new Date(data.rectificationPeriod.endDate).getTime()
                    }
                }
            }
            if(
                (data.sellingMethod === 'basicSell-english' ||
                data.sellingMethod === 'basicSell-english-fast' ||
                data.sellingMethod === 'basicSell-english-fast-manual' ||
                data.sellingMethod === 'basicSell-english-fast-auction-manual-qualification' ||
                data.sellingMethod === 'basicSell-english-initial-auction' ||
                data.sellingMethod === 'basicSell-english-initial-auction-manual' ||
                data.sellingMethod === 'basicSell-english-initial-qualification' ||
                data.sellingMethod === 'basicSell-english-initial-qualification-prod' ||
                data.sellingMethod === 'basicSell-dutch' ||
                data.sellingMethod === 'basicSell-dutch-fast' ||
                data.sellingMethod === 'basicSell-dutch-fast-manual' ||
                data.sellingMethod === 'basicSell-dutch-fast-auction-manual-qualification' ||
                data.sellingMethod === 'basicSell-dutch-initial-auction' ||
                data.sellingMethod === 'basicSell-dutch-initial-auction-manual' ||
                data.sellingMethod === 'basicSell-dutch-initial-qualification' ||
                data.sellingMethod === 'basicSell-dutch-initial-qualification-prod' ||
                data.sellingMethod === 'alienation-english' ||
                data.sellingMethod === 'alienation-english-fast' ||
                data.sellingMethod === 'alienation-english-fast-manual' ||
                data.sellingMethod === 'alienation-english-fast-auction-manual-qualification' ||
                data.sellingMethod === 'alienation-english-initial-auction' ||
                data.sellingMethod === 'alienation-english-initial-auction-manual' ||
                data.sellingMethod === 'alienation-english-initial-qualification' ||
                data.sellingMethod === 'alienation-english-initial-qualification-prod' ||
                data.sellingMethod === 'commercialSell-english' ||
                data.sellingMethod === 'commercialSell-english-fast' ||
                data.sellingMethod === 'commercialSell-english-fast-manual' ||
                data.sellingMethod === 'commercialSell-english-fast-auction-manual-qualification' ||
                data.sellingMethod === 'commercialSell-english-initial-auction' ||
                data.sellingMethod === 'commercialSell-english-initial-auction-manual' ||
                data.sellingMethod === 'commercialSell-english-initial-qualification' ||
                data.sellingMethod === 'commercialSell-english-initial-qualification-prod' ||
                data.sellingMethod === 'bankRuptcy-english' ||
                data.sellingMethod === 'bankRuptcy-english-fast' ||
                data.sellingMethod === 'bankRuptcy-english-fast-manual' ||
                data.sellingMethod === 'bankRuptcy-english-fast-auction-manual-qualification' ||
                data.sellingMethod === 'bankRuptcy-english-initial-auction' ||
                data.sellingMethod === 'bankRuptcy-english-initial-auction-manual' ||
                data.sellingMethod === 'bankRuptcy-english-initial-qualification' ||
                data.sellingMethod === 'bankRuptcy-english-initial-qualification-prod' ||
                data.sellingMethod === 'bankRuptcy-dutch' ||
                data.sellingMethod === 'bankRuptcy-dutch-fast' ||
                data.sellingMethod === 'bankRuptcy-dutch-fast-manual' ||
                data.sellingMethod === 'bankRuptcy-dutch-fast-auction-manual-qualification' ||
                data.sellingMethod === 'bankRuptcy-dutch-initial-auction' ||
                data.sellingMethod === 'bankRuptcy-dutch-initial-auction-manual' ||
                data.sellingMethod === 'bankRuptcy-dutch-initial-qualification' ||
                data.sellingMethod === 'bankRuptcy-dutch-initial-qualification-prod' ||
                
                
                data.sellingMethod === 'commercialPropertyLease-english' ||
                data.sellingMethod === 'commercialPropertyLease-english-ultra-fast' ||
                data.sellingMethod === 'commercialPropertyLease-english-fast' ||
                data.sellingMethod === 'commercialPropertyLease-english-fast-manual' ||
                data.sellingMethod === 'commercialPropertyLease-english-fast-auction-manual-qualification' ||
                data.sellingMethod === 'commercialPropertyLease-english-fast-auction-prod' ||
                data.sellingMethod === 'commercialPropertyLease-english-initial-auction' ||
                data.sellingMethod === 'commercialPropertyLease-english-initial-auction-manual' ||
                data.sellingMethod === 'commercialPropertyLease-english-initial-qualification' ||
                data.sellingMethod === 'commercialPropertyLease-english-initial-qualification-prod' ||
                data.sellingMethod === 'commercialPropertyLease-dutch' ||
                data.sellingMethod === 'commercialPropertyLease-dutch-ultra-fast' ||
                data.sellingMethod === 'commercialPropertyLease-dutch-fast' ||
                data.sellingMethod === 'commercialPropertyLease-dutch-fast-manual' ||
                data.sellingMethod === 'commercialPropertyLease-dutch-fast-auction-manual-qualification' ||
                data.sellingMethod === 'commercialPropertyLease-dutch-fast-auction-prod' ||
                data.sellingMethod === 'commercialPropertyLease-dutch-initial-auction' ||
                data.sellingMethod === 'commercialPropertyLease-dutch-initial-auction-manual' ||
                data.sellingMethod === 'commercialPropertyLease-dutch-initial-qualification' ||
                data.sellingMethod === 'commercialPropertyLease-dutch-initial-qualification-prod' ||
                data.sellingMethod === 'regulationsPropertyLease-english' ||
                data.sellingMethod === 'regulationsPropertyLease-english-ultra-fast' ||
                data.sellingMethod === 'regulationsPropertyLease-english-fast' ||
                data.sellingMethod === 'regulationsPropertyLease-english-fast-manual' ||
                data.sellingMethod === 'regulationsPropertyLease-english-fast-auction-manual-qualification' ||
                data.sellingMethod === 'regulationsPropertyLease-english-fast-auction-prod' ||
                data.sellingMethod === 'regulationsPropertyLease-english-initial-auction' ||
                data.sellingMethod === 'regulationsPropertyLease-english-initial-auction-manual' ||
                data.sellingMethod === 'regulationsPropertyLease-english-initial-qualification' ||
                data.sellingMethod === 'regulationsPropertyLease-english-initial-qualification-prod' ||
                data.sellingMethod === 'regulationsPropertyLease-dutch' ||
                data.sellingMethod === 'regulationsPropertyLease-dutch-ultra-fast' ||
                data.sellingMethod === 'regulationsPropertyLease-dutch-fast' ||
                data.sellingMethod === 'regulationsPropertyLease-dutch-fast-manual' ||
                data.sellingMethod === 'regulationsPropertyLease-dutch-fast-auction-manual-qualification' ||
                data.sellingMethod === 'regulationsPropertyLease-dutch-fast-auction-prod' ||
                data.sellingMethod === 'regulationsPropertyLease-dutch-initial-auction' ||
                data.sellingMethod === 'regulationsPropertyLease-dutch-initial-auction-manual' ||
                data.sellingMethod === 'regulationsPropertyLease-dutch-initial-qualification' ||
                data.sellingMethod === 'regulationsPropertyLease-dutch-initial-qualification-prod' ||
                data.sellingMethod === 'nonperformingLoans-english' ||
                data.sellingMethod === 'nonperformingLoans-english-fast' ||
                data.sellingMethod === 'nonperformingLoans-english-fast-manual' ||
                data.sellingMethod === 'nonperformingLoans-english-fast-auction-manual-qualification' ||
                data.sellingMethod === 'nonperformingLoans-english-fast-auction-prod' ||
                data.sellingMethod === 'nonperformingLoans-english-initial-qualification' ||
                data.sellingMethod === 'nonperformingLoans-english-initial-qualification-prod' ||
                data.sellingMethod === 'nonperformingLoans-english-initial-auction' ||
                data.sellingMethod === 'nonperformingLoans-english-initial-auction-manual' ||
                data.sellingMethod === 'nonperformingLoans-dutch' ||
                data.sellingMethod === 'nonperformingLoans-dutch-fast' ||
                data.sellingMethod === 'nonperformingLoans-dutch-fast-manual' ||
                data.sellingMethod === 'nonperformingLoans-dutch-fast-auction-manual-qualification' ||
                data.sellingMethod === 'nonperformingLoans-dutch-fast-auction-prod' ||
                data.sellingMethod === 'nonperformingLoans-dutch-initial-auction' ||
                data.sellingMethod === 'nonperformingLoans-dutch-initial-auction-manual' ||
                data.sellingMethod === 'nonperformingLoans-dutch-initial-qualification' ||
                data.sellingMethod === 'nonperformingLoans-dutch-initial-qualification-prod'

                ) &&
                (tmpStartData < Date.now() && tmpEndData > Date.now())
            ){
                switch(status){
                    case '11':
                        return 'disabled'
                    default:
                        return null
                }
            }else if(
                (data.sellingMethod === 'commercialSell-dutch' ||
                data.sellingMethod === 'commercialSell-dutch-fast' ||
                data.sellingMethod === 'commercialSell-dutch-fast-manual' ||
                data.sellingMethod === 'commercialSell-dutch-fast-auction-manual-qualification' ||
                data.sellingMethod === 'commercialSell-dutch-initial-auction' ||
                data.sellingMethod === 'commercialSell-dutch-initial-auction-manual' ||
                data.sellingMethod === 'commercialSell-dutch-initial-qualification' ||
                data.sellingMethod === 'commercialSell-dutch-initial-qualification-prod') &&
                (tmpStartData < Date.now() && tmpEndData > Date.now())
            ){
                switch(status){
                    case '11':
                        return 'disabled'
                    case '17':
                        return 'disabled'
                    default:
                        return null
                }
            }else{
                return 'disabled'
            }
        }
    }
}

export function onOffCurrentTenat(data){
    if(data.status === null || data.status === 'active_rectification'){
        return null
    }else{
        if(
            data.sellingMethod === 'landRental-english' ||
            data.sellingMethod === 'landRental-english-fast' ||
            data.sellingMethod === 'landRental-english-fast-manual' ||
            data.sellingMethod === 'landRental-english-fast-auction-manual-qualification' ||
            data.sellingMethod === 'landRental-english-initial-auction' ||
            data.sellingMethod === 'landRental-english-initial-auction-manual' ||
            data.sellingMethod === 'landRental-english-initial-qualification' ||
            data.sellingMethod === 'landRental-english-initial-qualification-prod' ||
            data.sellingMethod === 'landSell-english' ||
            data.sellingMethod === 'landSell-english-fast' ||
            data.sellingMethod === 'landSell-english-fast-manual' ||
            data.sellingMethod === 'landSell-english-fast-auction-manual-qualification' ||
            data.sellingMethod === 'landSell-english-initial-auction' ||
            data.sellingMethod === 'landSell-english-initial-auction-manual' ||
            data.sellingMethod === 'landSell-english-initial-qualification' ||
            data.sellingMethod === 'landSell-english-initial-qualification-prod' ||
            data.sellingMethod === 'landSell-priorityEnglish' ||
            data.sellingMethod === 'landSell-priorityEnglish-fast' ||
            data.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
            data.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
            data.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
            data.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
            data.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
            data.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod'
        ){
            return auctionPeriodOff(data)    
        }
    }
    return 'disabled'
}

function auctionPeriodOff(data){
    let tmp = new Date().getTime()
    let tmpEnd = -1
    if(data !== null){
        if(data.auctionPeriods !== null){
            if(data.auctionPeriods.length > 0){
                for(let tm = 0; tm < data.auctionPeriods.length; tm++){
                    if(data.auctionPeriods[tm].status === "transferPriorityPeriod") {
                        tmpEnd = new Date(data.auctionPeriods[tm].endDate)
                    }
                }
            }
        }
    }
    let tmpEnd2 = tmpEnd
    if(tmpEnd2 !== -1){
        tmpEnd2 = tmpEnd.getTime()
    }
    if(tmpEnd !== -1 && tmpEnd2 > tmp) return null
    return 'disabled'
}