import React from 'react';
import styles from './itemsList.module.css';

import {connect} from 'react-redux';
import {
    changeCreateItemAuctionId,
    changeCreateItemClassificationId,
    changeCreateItemUnitValueCurrency,
    changeCreateItemUnitValueAmount,
    changeCreateItemUnitValueTax,
    changeCreateItemDescription,
    changeAdditionalClassificationIds,
    changeCreateItemQuantity,
    changeCreateItemIds,
    changeLoader,
    getMyAuction,
    changeCreateItemLocationLatitude,
    changeCreateItemLocationLongitude,
    changeCreateItemLocationElevation,
    changeCreateItemAddress,
    changeCreateItemDgfJointPropertyComplexPropsId,
    changeCreateItemDgfJointPropertyComplexPropsQuantityAndNomenclature,
    changeCreateItemDgfJointPropertyComplexPropsWorkplacesQuantity,
    changeCreateItemDgfJointPropertyComplexPropsBuildingsInformation,
    changeCreateItemDgfJointPropertyComplexPropsLandPlotInformation,
    changeCreateItemDgfJointPropertyComplexPropsMainObligations,
    changeCreateItemDgfLandPropsId,
    changeCreateItemDgfLandPropsLandArea,
    changeCreateItemDgfLandPropsCadastralNumber,
    changeCreateItemDgfLandPropsOwnershipType,
    changeCreateItemDgfLandPropsEncumbrances,
    changeCreateItemDgfLandPropsJointOwnership,
    changeCreateItemDgfLandPropsUtilitiesAvailability,
    changeCreateItemDgfLandPropsIntendedUse,
    changeCreateItemDgfVehiclePropsId,
    changeCreateItemDgfVehiclePropsBrand,
    changeCreateItemDgfVehiclePropsModel,
    changeCreateItemDgfVehiclePropsProductionYear,
    changeCreateItemDgfVehiclePropsEngineCapacity,
    changeCreateItemDgfVehiclePropsFuelType,
    changeCreateItemDgfVehiclePropsTransmission,
    changeCreateItemDgfVehiclePropsColor,
    changeCreateItemDgfVehiclePropsKilometrage,
    changeCreateItemDgfVehiclePropsIdentificationNumber,
    changeCreateItemDgfVehiclePropsConfiguration,
    changeCreateItemDgfVehiclePropsCondition,
    changeCreateItemDgfVehiclePropsDamagePresence,
    changeCreateItemDgfVehiclePropsDamagedDescription,
} from '../../../../../redux/actions.js';
import {auctionsGetMy,
    auctionsGetMyId,
    changeAuctionsItemsSwitchWindow,
    changeAuctionsSwitchWindow,
    auctionsGetClassificationsByTypeEnergy,
    auctionsGetClassificationsByGenerationType,
    changeTimberSortment,
    changeTimberSpecies,
    changeTimberClass,
    changeTimberDiameter,
    changeTimberLength,
    changeTimberStorage,
    changeTimberProductionYear,
    changeTimberProductionQuarter,
    changeCreateItemUnitCode,
    changeCreateItemGenerationType,
    auctionsGetClassificationsByTypeDGFAdd,
    seDgfMask,
} from '../../../../../redux/actions/auctions/auctions.js';

import {
    setDataAddress,
} from '../../../../../redux/createAddress';

import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

const ItemsList = ( props ) => {
    let tmp = null;
    /*if(props.auctionsList !== null){
        if(props.auctionsList.length > 0){
            tmp = props.auctionsList
            .filter(
                (i) => {
                    return i.id === props.auctionsMy.id
                }
            )
        }
    }*/

    let tmpArr = null;
    //if(tmp !== null){
    if(props.auctionsMy !== null){
        //if(tmp.length > 0){
            //tmpArr = tmp[0].items.map(
            tmpArr = props.auctionsMy.items.map(
                (i) => {
                    return (
                        <div key={i.id} className={styles.auctionsLine}>
                            <div className={styles.auctionsLineElem}>
                                <div className={styles.itemsId}>Id лота: {i.id}</div>
                                <div>Опис лота: {i.description}</div>
                            </div>
                            <div className={styles.auctionsLineElem}>
                                <div
                                    onClick={
                                        () => {
                                            props.changeAuctionsItemsSwitchWindow(2);
                                            
                                            props.changeCreateItemAuctionId(props.auctionsMy.id)
                                            
                                            props.setDataAddress(i.itemType, 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
                                            viewSelectTypeItems(props, i.itemType)
                                            if(i.itemType === 'otherProperty'){
                                                props.setDataAddress(i.id, 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_ID')
                                                props.setDataAddress(i.title, 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_TITLE')
                                                props.setDataAddress(i.description, 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_DESCRIPTION')
                                                if(i.classification !== null){
                                                    props.setDataAddress(i.classification.id, 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_CLASSIFICATION')
                                                }
                                                /*if(i.additionalClassifications !== null){
                                                    if(i.additionalClassifications.length > 0){
                                                        props.setDataAddress(i.additionalClassifications[0].id, 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_ADDITIONAL_CLASSIFICATION')
                                                    }
                                                }*/
                                                /*if(i.address !== null){
                                                    props.setDataAddress(i.address.id, 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_ADDRESS')
                                                }
                                                if(i.location !== null){
                                                    props.setDataAddress(i.location.latitude, 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_LOCATION_LATITUDE')
                                                    props.setDataAddress(i.location.longitude, 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_LOCATION_LONGITUDE')
                                                }*/
                                                if(i.address !== null){
                                                    props.setDataAddress(i.address.id, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS')
                                                    
                                                    props.setDataAddress(i.address.id, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_ID')
                                                    props.setDataAddress(i.address.countryName, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_COUNTRY_NAME')
                                                    props.setDataAddress(i.address.region, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_REGION')
                                                    props.setDataAddress(i.address.locality, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_LOCALITY')
                                                    props.setDataAddress(i.address.streetAddress, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_STREET_ADDRESS')
                                                    props.setDataAddress(i.address.postalCode, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_POSTAL_CODE')
                                                }
                                                if(i.location !== null){
                                                    props.setDataAddress(i.location.latitude, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE')
                                                    props.setDataAddress(i.location.longitude, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE')
                                                }

                                                if(i.registrationDetails !== null){
                                                    props.setDataAddress(i.registrationDetails.registrationStatus, 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_REGISTRATION_DETAILS_REGISTRATION_STATUS')
                                                    props.setDataAddress(i.registrationDetails.registrationID, 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_REGISTRATION_DETAILS_REGISTRATION_ID')
                                                    props.setDataAddress(i.registrationDetails.registrationDate, 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_REGISTRATION_DETAILS_REGISTRATION_DATE')
                                                }

                                                props.setDataAddress(i.quantity, 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_QUANTITY')
                                                if(i.hasOwnProperty('unit') === true){
                                                    if(i.unit !== null){
                                                        if(i.unit.hasOwnProperty('code') === true) props.setDataAddress(i.unit.code, 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_UNIT_CODE')
                                                    }
                                                }
                                            }else if(i.itemType === 'jointPropertyComplex'){
                                                props.setDataAddress(i.id, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ID')
                                                props.setDataAddress(i.title, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_TITLE')
                                                props.setDataAddress(i.description, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_DESCRIPTION')
                                                if(i.classification !== null){
                                                    props.setDataAddress(i.classification.id, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_CLASSIFICATION')
                                                }
                                                /*if(i.additionalClassifications !== null){
                                                    if(i.additionalClassifications.length > 0){
                                                        props.setDataAddress(i.additionalClassifications[0].id, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ADDITIONAL_CLASSIFICATION')
                                                    }
                                                }*/
                                                /*if(i.address !== null){
                                                    props.setDataAddress(i.address.id, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ADDRESS')
                                                }
                                                if(i.location !== null){
                                                    props.setDataAddress(i.location.latitude, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_LOCATION_LATITUDE')
                                                    props.setDataAddress(i.location.longitude, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_LOCATION_LONGITUDE')
                                                }*/
                                                if(i.address !== null){
                                                    props.setDataAddress(i.address.id, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS')
                                                    
                                                    props.setDataAddress(i.address.id, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_ID')
                                                    props.setDataAddress(i.address.countryName, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_COUNTRY_NAME')
                                                    props.setDataAddress(i.address.region, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_REGION')
                                                    props.setDataAddress(i.address.locality, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_LOCALITY')
                                                    props.setDataAddress(i.address.streetAddress, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_STREET_ADDRESS')
                                                    props.setDataAddress(i.address.postalCode, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_POSTAL_CODE')
                                                }
                                                if(i.location !== null){
                                                    props.setDataAddress(i.location.latitude, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE')
                                                    props.setDataAddress(i.location.longitude, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE')
                                                }
                                                if(i.registrationDetails !== null){
                                                    props.setDataAddress(i.registrationDetails.registrationStatus, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS')
                                                    props.setDataAddress(i.registrationDetails.registrationID, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_REGISTRATION_DETAILS_REGISTRATION_ID')
                                                    props.setDataAddress(i.registrationDetails.registrationDate, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_REGISTRATION_DETAILS_REGISTRATION_DATE')
                                                }
                                                props.setDataAddress(i.quantity, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_QUANTITY')
                                                if(i.hasOwnProperty('unit') === true){
                                                    if(i.unit !== null){
                                                        if(i.unit.hasOwnProperty('code') === true) props.setDataAddress(i.unit.code, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_UNIT_CODE')
                                                    }
                                                }
                                                if(i.jointPropertyComplexProps !== null){
                                                    props.setDataAddress(i.jointPropertyComplexProps.buildingsInformation, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_BUILDINGS_INFORMATION')
                                                    props.setDataAddress(i.jointPropertyComplexProps.landPlotInformation, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PLOT_INFORMATION')
                                                    props.setDataAddress(i.jointPropertyComplexProps.mainObligations, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_MAIN_OBLIGATIONS')
                                                    props.setDataAddress(i.jointPropertyComplexProps.quantityAndNomenclature, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_QUANTITY_AND_NOMENCLATURE')
                                                    props.setDataAddress(i.jointPropertyComplexProps.workplacesQuantity, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_WORKPLACES_QUANTITY')
                                                    if(i.jointPropertyComplexProps.landProps !== null){
                                                        props.setDataAddress(i.jointPropertyComplexProps.landProps.cadastralNumber, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_CADASTRAL_NUMBER')
                                                        props.setDataAddress(i.jointPropertyComplexProps.landProps.encumbrances, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_ENCUMBRANCES')
                                                        props.setDataAddress(i.jointPropertyComplexProps.landProps.jointOwnership, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_JOINT_OWNERSHIP')
                                                        props.setDataAddress(i.jointPropertyComplexProps.landProps.landArea, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_LAND_AREA')
                                                        props.setDataAddress(i.jointPropertyComplexProps.landProps.landIntendedUse, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_LAND_INTENDED_USE')
                                                        props.setDataAddress(i.jointPropertyComplexProps.landProps.ownershipType, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_OWNERSHIP_TYPE')
                                                        props.setDataAddress(i.jointPropertyComplexProps.landProps.utilitiesAvailability, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_UTILITIES_AVAILABILITY')
                                                    }
                                                }
                                            }else if(i.itemType === 'vehicle'){
                                                props.setDataAddress(i.id, 'CREATE_ITEMS_VEHICLE_ITEM_ID')
                                                props.setDataAddress(i.title, 'CREATE_ITEMS_VEHICLE_ITEM_TITLE')
                                                props.setDataAddress(i.description, 'CREATE_ITEMS_VEHICLE_ITEM_DESCRIPTION')
                                                if(i.classification !== null){
                                                    props.setDataAddress(i.classification.id, 'CREATE_ITEMS_VEHICLE_ITEM_CLASSIFICATION')
                                                }
                                                /*if(i.additionalClassifications !== null){
                                                    if(i.additionalClassifications.length > 0){
                                                        props.setDataAddress(i.additionalClassifications[0].id, 'CREATE_ITEMS_VEHICLE_ITEM_ADDITIONAL_CLASSIFICATION')
                                                    }
                                                }*/
                                                /*if(i.address !== null){
                                                    props.setDataAddress(i.address.id, 'CREATE_ITEMS_VEHICLE_ITEM_ADDRESS')
                                                }
                                                if(i.location !== null){
                                                    props.setDataAddress(i.location.latitude, 'CREATE_ITEMS_VEHICLE_ITEM_LOCATION_LATITUDE')
                                                    props.setDataAddress(i.location.longitude, 'CREATE_ITEMS_VEHICLE_ITEM_LOCATION_LONGITUDE')
                                                }*/
                                                if(i.address !== null){
                                                    props.setDataAddress(i.address.id, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS')
                                                    
                                                    props.setDataAddress(i.address.id, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_ID')
                                                    props.setDataAddress(i.address.countryName, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_COUNTRY_NAME')
                                                    props.setDataAddress(i.address.region, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_REGION')
                                                    props.setDataAddress(i.address.locality, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_LOCALITY')
                                                    props.setDataAddress(i.address.streetAddress, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_STREET_ADDRESS')
                                                    props.setDataAddress(i.address.postalCode, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_POSTAL_CODE')
                                                }
                                                if(i.location !== null){
                                                    props.setDataAddress(i.location.latitude, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE')
                                                    props.setDataAddress(i.location.longitude, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE')
                                                }
                                                if(i.registrationDetails !== null){
                                                    props.setDataAddress(i.registrationDetails.registrationStatus, 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS')
                                                    props.setDataAddress(i.registrationDetails.registrationID, 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_ID')
                                                    props.setDataAddress(i.registrationDetails.registrationDate, 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_DATE')
                                                }
                                                props.setDataAddress(i.quantity, 'CREATE_ITEMS_VEHICLE_ITEM_QUANTITY')
                                                if(i.hasOwnProperty('unit') === true){
                                                    if(i.unit !== null){
                                                        if(i.unit.hasOwnProperty('code') === true) props.setDataAddress(i.unit.code, 'CREATE_ITEMS_VEHICLE_ITEM_UNIT_CODE')
                                                    }
                                                }
                                                if(i.vehicleProps !== null){
                                                    props.setDataAddress(i.vehicleProps.brand, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_BRAND')
                                                    props.setDataAddress(i.vehicleProps.model, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_MODEL')
                                                    props.setDataAddress(i.vehicleProps.productionYear, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_PRODUCTION_YEAR')
                                                    props.setDataAddress(i.vehicleProps.engineCapacity, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_ENGINE_CAPACITY')
                                                    props.setDataAddress(i.vehicleProps.fuelType, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_FUEL_TYPE')
                                                    props.setDataAddress(i.vehicleProps.transmission, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_TRANSMISSION')
                                                    props.setDataAddress(i.vehicleProps.color, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_COLOR')
                                                    props.setDataAddress(i.vehicleProps.kilometrage, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_KILOMETRAGE')
                                                    props.setDataAddress(i.vehicleProps.identificationNumber, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_IDENTIFICATION_NUMBER')
                                                    props.setDataAddress(i.vehicleProps.configuration, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_CONFIGURATION')
                                                    props.setDataAddress(i.vehicleProps.condition, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_CONDITION')
                                                    props.setDataAddress(i.vehicleProps.damagePresence, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_DAMAGE_PRESENCE')
                                                    props.setDataAddress(i.vehicleProps.damagedDescription, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_DAMAGED_DESCRIPTION')
                                                }
                                            }else if(i.itemType === 'realEstate'){
                                                props.setDataAddress(i.id, 'CREATE_ITEMS_REAL_ESTATEITEM_ID')
                                                props.setDataAddress(i.title, 'CREATE_ITEMS_REAL_ESTATEITEM_TITLE')
                                                props.setDataAddress(i.description, 'CREATE_ITEMS_REAL_ESTATEITEM_DESCRIPTION')
                                                if(i.classification !== null){
                                                    props.setDataAddress(i.classification.id, 'CREATE_ITEMS_REAL_ESTATEITEM_CLASSIFICATION')
                                                }
                                                /*if(i.additionalClassifications !== null){
                                                    if(i.additionalClassifications.length > 0){
                                                        props.setDataAddress(i.additionalClassifications[0].id, 'CREATE_ITEMS_REAL_ESTATEITEM_ADDITIONAL_CLASSIFICATION')
                                                    }
                                                }*/
                                                /*if(i.address !== null){
                                                    props.setDataAddress(i.address.id, 'CREATE_ITEMS_REAL_ESTATEITEM_ADDRESS')
                                                }
                                                if(i.location !== null){
                                                    props.setDataAddress(i.location.latitude, 'CREATE_ITEMS_REAL_ESTATEITEM_LOCATION_LATITUDE')
                                                    props.setDataAddress(i.location.longitude, 'CREATE_ITEMS_REAL_ESTATEITEM_LOCATION_LONGITUDE')
                                                }*/
                                                if(i.address !== null){
                                                    props.setDataAddress(i.address.id, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS')
                                                    
                                                    props.setDataAddress(i.address.id, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_ID')
                                                    props.setDataAddress(i.address.countryName, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_COUNTRY_NAME')
                                                    props.setDataAddress(i.address.region, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_REGION')
                                                    props.setDataAddress(i.address.locality, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_LOCALITY')
                                                    props.setDataAddress(i.address.streetAddress, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_STREET_ADDRESS')
                                                    props.setDataAddress(i.address.postalCode, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_POSTAL_CODE')
                                                }
                                                if(i.location !== null){
                                                    props.setDataAddress(i.location.latitude, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE')
                                                    props.setDataAddress(i.location.longitude, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE')
                                                }
                                                if(i.registrationDetails !== null){
                                                    props.setDataAddress(i.registrationDetails.registrationStatus, 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS')
                                                    props.setDataAddress(i.registrationDetails.registrationID, 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_ID')
                                                    props.setDataAddress(i.registrationDetails.registrationDate, 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_DATE')
                                                }
                                                props.setDataAddress(i.quantity, 'CREATE_ITEMS_REAL_ESTATEITEM_QUANTITY')
                                                if(i.hasOwnProperty('unit') === true){
                                                    if(i.unit !== null){
                                                        if(i.unit.hasOwnProperty('code') === true) props.setDataAddress(i.unit.code, 'CREATE_ITEMS_REAL_ESTATEITEM_UNIT_CODE')
                                                    }
                                                }
                                                if(i.realEstateProps !== null){
                                                    props.setDataAddress(i.realEstateProps.totalBuildingArea, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_TOTAL_BUILDING_AREA')
                                                    props.setDataAddress(i.realEstateProps.totalObjectArea, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_TOTAL_OBJECT_AREA')
                                                    props.setDataAddress(i.realEstateProps.usableArea, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_USABLE_AREA')
                                                    props.setDataAddress(i.realEstateProps.constructionYear, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_CONSTRUCTION_YEAR')
                                                    props.setDataAddress(i.realEstateProps.constructionTechnology, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_CONSTRUCTION_TECHNOLOGY')
                                                    props.setDataAddress(i.realEstateProps.livingArea, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LIVING_AREA')
                                                    props.setDataAddress(i.realEstateProps.kitchenArea, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_KITCHEN_AREA')
                                                    props.setDataAddress(i.realEstateProps.landArea, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LAND_AREA')
                                                    props.setDataAddress(i.realEstateProps.locationInBuilding, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LOCATION_IN_BUILDING')
                                                    props.setDataAddress(i.realEstateProps.floors, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_FLOORS')
                                                    props.setDataAddress(i.realEstateProps.generalCondition, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_GENERAL_CONDITION')
                                                    props.setDataAddress(i.realEstateProps.serviceElectricity, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_ELECTRICITY')
                                                    props.setDataAddress(i.realEstateProps.powerSupplyCapacity, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SUPPLY_CAPACITY')
                                                    props.setDataAddress(i.realEstateProps.powerSupplyClass, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SUPPLY_CLASS')
                                                    props.setDataAddress(i.realEstateProps.serviceWater, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_WATER')
                                                    props.setDataAddress(i.realEstateProps.serviceSewerage, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_SEWERAGE')
                                                    props.setDataAddress(i.realEstateProps.serviceGas, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_GAS')
                                                    props.setDataAddress(i.realEstateProps.serviceCentralHeating, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_CENTRAL_HEATING')
                                                    props.setDataAddress(i.realEstateProps.serviceAutonomousHeating, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_AUTONOMOUS_HEATING')
                                                    props.setDataAddress(i.realEstateProps.serviceHeatingCounter, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_HEATING_COUNTER')
                                                    props.setDataAddress(i.realEstateProps.serviceVentilation, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_VENTILATION')
                                                    props.setDataAddress(i.realEstateProps.serviceAirConditioning, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_AIR_CONDITIONING')
                                                    props.setDataAddress(i.realEstateProps.servicePhone, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_PHONE')
                                                    props.setDataAddress(i.realEstateProps.serviceTV, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_TV')
                                                    props.setDataAddress(i.realEstateProps.serviceInternet, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_INTERNET')
                                                    props.setDataAddress(i.realEstateProps.serviceElevator, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_ELEVATOR')
                                                    props.setDataAddress(i.realEstateProps.serviceSecurityAlarm, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_SECURITY_ALARM')
                                                    props.setDataAddress(i.realEstateProps.serviceFireAlarm, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_FIRE_ALARM')
                                                    props.setDataAddress(i.realEstateProps.servicesDescription, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SERVICES_DESCRIPTION')
                                                    props.setDataAddress(i.realEstateProps.servicesAccounting, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SERVICES_ACCOUNTING')
                                                    props.setDataAddress(i.realEstateProps.landTax, 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LAND_TAX')
                                                }
                                            }
                                        }
                                    }
                                >
                                    <IconButton aria-label="edit">
                                        <EditIcon />
                                    </IconButton>
                                </div>
                                {/*<div
                                    onClick={
                                        () => {
                                            props.changeLoader(true)
                                            deleteItem(props, props.auctionsMy, i.id)
                                        }
                                    }
                                >
                                    <IconButton aria-label="delete">
                                        <DeleteIcon />
                                    </IconButton>
                                </div>*/}
                            </div>
                        </div>
                    )
                }
            )
        //}
    }
    return tmpArr
}

function viewSelectTypeItems(props, data){
    //additionalClassificationZU(props)
    if(data === 'otherProperty'){
        concatDataOtherProperty(props)
    }else if(data === 'jointPropertyComplex'){
        concatDataJointPropertyComplex(props)
    }else if(data === "vehicle"){
        concatDataVehicle(props)
    }else if(data === 'realEstate'){
        concatDataRealEstate(props)
    }
}

async function additionalClassificationZU(props){
    let tmpArr = []
    tmpArr[0] = await getAdditionalClassificationZU(props, 'QB29-3')
    tmpArr[1] = await getAdditionalClassificationZU(props, 'PA01-7')
    props.setDataAddress(tmpArr, 'SET_CLASSIFICATIONS_ZU_ADD')
}

async function concatDataOtherProperty(props){
    //let tmpArr0 = await getDictionaryJointPropertyComplex(props, '16000000-5')
    let tmpArr0 = await getDictionaryJointPropertyComplex(props, '16')
    //let tmpArr1 = await tmpArr0.concat(await getDictionaryJointPropertyComplex(props, '30000000-9'))
    let tmpArr1 = await tmpArr0.concat(await getDictionaryJointPropertyComplex(props, '30'))
    //let tmpArr2 = await tmpArr1.concat(await getDictionaryJointPropertyComplex(props, '32000000-3'))
    let tmpArr2 = await tmpArr1.concat(await getDictionaryJointPropertyComplex(props, '32'))
    //let tmpArr3 = await tmpArr2.concat(await getDictionaryJointPropertyComplex(props, '34000000-7'))
    //let tmpArr3 = await tmpArr2.concat(await getDictionaryJointPropertyComplex(props, '34000000-7'))
    let tmpArr3 = await tmpArr2.concat(await getDictionaryJointPropertyComplex(props, '340'))
    let tmpArr4 = await tmpArr3.concat(await getDictionaryJointPropertyComplex(props, '342'))
    let tmpArr5 = await tmpArr4.concat(await getDictionaryJointPropertyComplex(props, '343'))
    let tmpArr6 = await tmpArr5.concat(await getDictionaryJointPropertyComplex(props, '348'))
    let tmpArr8 = await tmpArr6.concat(await getDictionaryJointPropertyComplex(props, '349'))
        //let tmpArr4 = await tmpArr3.concat(await getDictionaryJointPropertyComplex(props, '34100000-8'))
        //let tmpArr5 = await tmpArr4.concat(await getDictionaryJointPropertyComplex(props, '34400000-1'))
        //let tmpArr6 = await tmpArr5.concat(await getDictionaryJointPropertyComplex(props, '34500000-2'))
        //let tmpArr7 = await tmpArr6.concat(await getDictionaryJointPropertyComplex(props, '34600000-3'))
        //let tmpArr8 = await tmpArr7.concat(await getDictionaryJointPropertyComplex(props, '34700000-4'))
    //let tmpArr9 = await tmpArr8.concat(await getDictionaryJointPropertyComplex(props, '39000000-2'))
    let tmpArr9 = await tmpArr8.concat(await getDictionaryJointPropertyComplex(props, '39'))
    //let tmpArr10 = await tmpArr9.concat(await getDictionaryJointPropertyComplex(props, '42000000-6'))
    let tmpArr10 = await tmpArr9.concat(await getDictionaryJointPropertyComplex(props, '42'))
    //let tmpArr11 = await tmpArr10.concat(await getDictionaryJointPropertyComplex(props, '18000000-9'))
    let tmpArr11 = await tmpArr10.concat(await getDictionaryJointPropertyComplex(props, '18'))
    //let tmpArr12 = await tmpArr11.concat(await getDictionaryJointPropertyComplex(props, '19000000-6'))
    let tmpArr12 = await tmpArr11.concat(await getDictionaryJointPropertyComplex(props, '19'))
    //let tmpArr13 = await tmpArr12.concat(await getDictionaryJointPropertyComplex(props, '22000000-0'))
    let tmpArr13 = await tmpArr12.concat(await getDictionaryJointPropertyComplex(props, '22'))
    //let tmpArr14 = await tmpArr13.concat(await getDictionaryJointPropertyComplex(props, '31000000-6'))
    let tmpArr14 = await tmpArr13.concat(await getDictionaryJointPropertyComplex(props, '31'))
    //let tmpArr15 = await tmpArr14.concat(await getDictionaryJointPropertyComplex(props, '33000000-0'))
    let tmpArr15 = await tmpArr14.concat(await getDictionaryJointPropertyComplex(props, '33'))
    //let tmpArr16 = await tmpArr15.concat(await getDictionaryJointPropertyComplex(props, '35000000-4'))
    let tmpArr16 = await tmpArr15.concat(await getDictionaryJointPropertyComplex(props, '35'))
    //let tmpArr17 = await tmpArr16.concat(await getDictionaryJointPropertyComplex(props, '37000000-8'))
    let tmpArr17 = await tmpArr16.concat(await getDictionaryJointPropertyComplex(props, '37'))
    //let tmpArr18 = await tmpArr17.concat(await getDictionaryJointPropertyComplex(props, '38000000-5'))
    let tmpArr18 = await tmpArr17.concat(await getDictionaryJointPropertyComplex(props, '38'))
    //let tmpArr19 = await tmpArr18.concat(await getDictionaryJointPropertyComplex(props, '43000000-3'))
    let tmpArr19 = await tmpArr18.concat(await getDictionaryJointPropertyComplex(props, '43'))
    //let tmpArr20 = await tmpArr19.concat(await getDictionaryJointPropertyComplex(props, '44000000-0'))
    let tmpArr20 = await tmpArr19.concat(await getDictionaryJointPropertyComplex(props, '44'))
    //let tmpArr21 = await tmpArr20.concat(await getDictionaryJointPropertyComplex(props, '48000000-8'))
    let tmpArr21 = await tmpArr20.concat(await getDictionaryJointPropertyComplex(props, '48'))
    props.setDataAddress(tmpArr21, 'SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD')
}

async function concatDataJointPropertyComplex(props){
    let tmpArr1 = await getDictionaryJointPropertyComplex(props, '07')
    let tmpArr2 = await getDictionaryJointPropertyComplex(props, '08')
    let tmpArr3 = tmpArr1.concat(tmpArr2)

    props.setDataAddress(tmpArr3, 'SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD')
}

async function concatDataVehicle(props){
    let tmpArr0 = await getDictionaryJointPropertyComplex(props, '34000000-7')
    let tmpArr1 = await tmpArr0.concat(await getDictionaryJointPropertyComplex(props, '34100000-8'))
    let tmpArr2 = await tmpArr1.concat(await getDictionaryJointPropertyComplex(props, '34400000-1'))
    let tmpArr3 = await tmpArr2.concat(await getDictionaryJointPropertyComplex(props, '34500000-2'))
    let tmpArr4 = await tmpArr3.concat(await getDictionaryJointPropertyComplex(props, '34600000-3'))
    let tmpArr5 = await tmpArr4.concat(await getDictionaryJointPropertyComplex(props, '34700000-4'))
    props.setDataAddress(tmpArr5, 'SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD')
}

async function concatDataRealEstate(props){
    let tmpArr = await getDictionaryJointPropertyComplex(props, '04')
    props.setDataAddress(tmpArr, 'SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD')
}

async function getDictionaryJointPropertyComplex(props, mask){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/autocomplete/classification_id?limit=1000&offset=0&classification_id=${mask}&scheme=CAV&mask=${mask}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        return await response.json()
    }
}
async function getAdditionalClassificationZU(props, mask){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/autocomplete/keyword?keyword=${mask}&scheme=CPVS&limit=100`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        return await response.json()
    }
}





async function  deleteItem(props, aucId, itemId){
    /*const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${aucId}/item/${itemId}`;
    const response1 = await fetch(endPoint, {
        method: "delete",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .then(
        (response1) =>{
            if(response1 !== undefined || response1 !== 'undefined'){
                let data = {
                    domen: process.env.REACT_APP_END_POINT_BD,
                    version: 'v1',
                    local: props.langFlag,
                    params: '?limit=20'
                }
                let header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                props.auctionsGetMy(data, header)
                props.auctionsGetMyId(data, header, props.auctionsMy.id)
            }
        }
    )
    .catch(console.error)*/
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${aucId}/item/${itemId}`;
    const response1 = await fetch(endPoint, {
        method: "delete",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
    }else{
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}`;
        props.changeLoader(true)
        const response2 = await fetch(endPoint2, {
                method: "GET",
                headers: header
        }).catch(console.error)
        if(response2 === undefined || response2 === 'undefined'){
        }else{
            props.changeAuctionsItemsSwitchWindow(0);
            const json2 = await response2.json()
            if(json2.hasOwnProperty('message') === true){
                props.changeLoader(false)
            }else{
                props.getMyAuction(json2)
                props.changeLoader(false)
            }
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy
    }
}

const mapDispatchToProps = {
    changeCreateItemAuctionId,
    changeCreateItemClassificationId,
    changeCreateItemUnitValueCurrency,
    changeCreateItemUnitValueAmount,
    changeCreateItemUnitValueTax,
    changeCreateItemDescription,
    changeAuctionsItemsSwitchWindow,
    auctionsGetMy,
    auctionsGetMyId,
    changeAuctionsSwitchWindow,
    changeAdditionalClassificationIds,
    changeCreateItemQuantity,
    changeCreateItemIds,
    //auctionsGetClassificationsByType,
    auctionsGetClassificationsByTypeEnergy,
    auctionsGetClassificationsByGenerationType,
    //auctionsGetClassificationsByTimberSortment,
    //auctionsGetClassificationsByTimberSpecies,
    //auctionsGetClassificationsByTimberClass,
    //auctionsGetClassificationsByTimberDiameter,
    //auctionsGetClassificationsByTimberLength,
    //auctionsGetClassificationsByTimberStorage,
    //auctionsGetClassificationsByTimberProductionYear,
    //auctionsGetClassificationsByTimberProductionQuarter,
    changeTimberSortment,
    changeTimberSpecies,
    changeTimberClass,
    changeTimberDiameter,
    changeTimberLength,
    changeTimberStorage,
    changeTimberProductionYear,
    changeTimberProductionQuarter,
    changeCreateItemUnitCode,
    changeCreateItemGenerationType,
    changeLoader,
    getMyAuction,
    changeCreateItemLocationLatitude,
    changeCreateItemLocationLongitude,
    changeCreateItemLocationElevation,
    auctionsGetClassificationsByTypeDGFAdd,
    seDgfMask,
    changeCreateItemAddress,
    changeCreateItemDgfJointPropertyComplexPropsId,
    changeCreateItemDgfJointPropertyComplexPropsQuantityAndNomenclature,
    changeCreateItemDgfJointPropertyComplexPropsWorkplacesQuantity,
    changeCreateItemDgfJointPropertyComplexPropsBuildingsInformation,
    changeCreateItemDgfJointPropertyComplexPropsLandPlotInformation,
    changeCreateItemDgfJointPropertyComplexPropsMainObligations,
    changeCreateItemDgfLandPropsId,
    changeCreateItemDgfLandPropsLandArea,
    changeCreateItemDgfLandPropsCadastralNumber,
    changeCreateItemDgfLandPropsOwnershipType,
    changeCreateItemDgfLandPropsEncumbrances,
    changeCreateItemDgfLandPropsJointOwnership,
    changeCreateItemDgfLandPropsUtilitiesAvailability,
    changeCreateItemDgfLandPropsIntendedUse,
    changeCreateItemDgfVehiclePropsId,
    changeCreateItemDgfVehiclePropsBrand,
    changeCreateItemDgfVehiclePropsModel,
    changeCreateItemDgfVehiclePropsProductionYear,
    changeCreateItemDgfVehiclePropsEngineCapacity,
    changeCreateItemDgfVehiclePropsFuelType,
    changeCreateItemDgfVehiclePropsTransmission,
    changeCreateItemDgfVehiclePropsColor,
    changeCreateItemDgfVehiclePropsKilometrage,
    changeCreateItemDgfVehiclePropsIdentificationNumber,
    changeCreateItemDgfVehiclePropsConfiguration,
    changeCreateItemDgfVehiclePropsCondition,
    changeCreateItemDgfVehiclePropsDamagePresence,
    changeCreateItemDgfVehiclePropsDamagedDescription,
    /** */
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemsList)
