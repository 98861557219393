import React from 'react';

import {connect} from 'react-redux';

const pagingButtonPrev = ( props ) => {
    return (
        <svg enableBackground="new 0 0 32 32"  id="Слой_1" version="1.1" viewBox="0 0 32 32"  xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <path clipRule="evenodd" d="M20.273,5.278l-9.977,9.999  c-0.394,0.395-0.394,1.034,0,1.429h0v0l9.97,9.991c0.634,0.66,1.748,0.162,1.723-0.734V6.02C22.013,5.127,20.907,4.643,20.273,5.278  z M12.434,15.991l7.55-7.566v15.133L12.434,15.991z" fill="#121313" fillRule="evenodd" id="Arrow_Drop_Left"/>
            <g/><g/><g/><g/><g/><g/>
        </svg>
    )
}

export default connect(null, null)(pagingButtonPrev)
