import { 
    //Classifications
    CLASSIFICATION_GET,
    CLASSIFICATIONS_GET,
    CLASSIFICATION_POST,
    CLASSIFICATION_PUT,
    CLASSIFICATION_DELETE,
    CLASSIFICATIONS_BY_SCHEME_GET,
} from "../../types";

import {getAPI, postAPI, putAPI, deleteAPI} from '../crud';
import {setLocal} from '../support';

//Classifications
export function classificationPost(_url, token, datas){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/classification`,
        token: token,
        actionType: CLASSIFICATION_POST,
        data: datas,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return postAPI(data);
}

export function classificationGet(_url, token){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/classification/${_url.id}`,
        token: token,
        actionType: CLASSIFICATION_GET,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function classificationPut(_url, token, datas){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/classification/${_url.id}`,
        token: token,
        actionType: CLASSIFICATION_PUT,
        data: datas,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return putAPI(data);
}

export function ClassificationDelete(_url, token){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/classification/${_url.id}`,
        token: token,
        actionType: CLASSIFICATION_DELETE,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return deleteAPI(data);
}

export function classificationsGet(_url, header){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/classifications${_url.params}`,
        actionType: CLASSIFICATIONS_GET,
        header: header
    }
    return getAPI(data);
}

//Тут сделать выборку по типа аукциона дерево
export function classificationsGetByScheme(_url, header){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/classifications/byScheme/${_url.params}`,
        actionType: CLASSIFICATIONS_BY_SCHEME_GET,
        header: header
    }
    return getAPI(data);
}