import React from 'react';

import {connect} from 'react-redux';

import TextHalf from '../../../elements/viewData/textHalf'

import {isLAE, isLAW, isLAP} from '../../../../redux/aucTypes'

const PreviousAuctionIdString = (props) => {
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('previousAuctionId') === true){
            if(props.auctionId.previousAuctionId !== null){
                return (
                    <TextHalf
                        title={getTitlePerviousAuctionId(props)}
                        data={props.auctionId.previousAuctionId}
                    />
                )
            }
        }
    }
    return null
}

function getTitlePerviousAuctionId(props){
    if(isLAE(props.auctionId.sellingMethod) || isLAW(props.auctionId.sellingMethod) || isLAP(props.auctionId.sellingMethod)){
        return "Ідентифікатор попереднього аукціону"
    }
    return "Номер попереднього аукціону"
}

const mapStateToProps = state => {
    return {
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(PreviousAuctionIdString)