import React from 'react';
import styles from './helpfulMenu.module.css';

/*import {
    CURRENT_AUCTIONS,
    ALL_AUCTIONS,
    REGISTRY_OF_OBJECTS_OF_PRIVATIZATION,
    REGISTRY_OF_LOTS_OF_PRIVATIZATION,
    COMPLETION_OF_PRIVATIZATION,
    MAP_OF_OBJECTS_OF_PRIVATIZATION
} from '../../../model/lang/secondMenu.js';*/
import {connect} from 'react-redux';

const SecondMenu = (props) => {
    return (
        <div className={styles.aukcionmenu}>
            <div className={styles.aukcontent}>
                <div className={styles.auktionmenublok}>ІНФОРМАЦІЙНІ МАТЕРІАЛИ</div>
                <div className={styles.auktionmenublok_off}>ЗАПИТАННЯ ТА ВІДПОВІДІ</div>
                <div className={styles.auktionmenublok_off}>НОРМАТИВНІ ДОКУМЕНТИ</div>
                <div className={styles.auktionmenublok_off}>МИ НА FACEBOOK</div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag
    }
}

export default connect(mapStateToProps, null)(SecondMenu)