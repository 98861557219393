import React,{useState} from 'react';
import styles from '../newContract.module.css';

import {connect} from 'react-redux';

import { checkProp } from '../../../../redux/check';
import { isTIE, isSUE, isSUD, isRCE, isRCD, isGFE, isGFD, isLLE, isLLD,
            isLLP, isLRE, isLSE, isLSP, isRMA, isBSE, isBSD, isALE, isCSE,
            isCSD, isBRE, isBRD, isBRW, isRLE, isRLD, isCLE, isCLD, isSPE, isSPD,
            isLPE, isAPE, isAPD
        } from '../../../../redux/aucTypes';
    
import {auctionsGetAwards, auctionsGetAwardsId, changeAwardRejectPopUpStatus, changeAwardApprowePopUpStatus, changeAwardView} from '../../../../redux/actions/awards/awards.js';

import { setAwardAllUserPopUp, setAwardAllUserPopUpUser } from '../../../../redux/actions.js';
import { getUpdateDataAuction } from '../../../product/updateData';
import { setDataAddress } from '../../../../redux/createAddress';

// import AwardDataPopUp from '../popUpDataAward/popUpDataAward.js';
// import AwardDataPopUpUser from '../popUpDataAward/popUpDataAwardUser.js';

// import {
//     changeContractApprowePopUpStatus,
//     setContractTitle,
//     setContractDescription,
//     setContractContractNumber,
//     setContractDateFrom,
//     setContractDateTill,
//     setContractStartDate,
//     setContractEndDate,
//     setContractCurrency,
//     setContractAmount,
//     setContractDateSigned,
//     setContractDatePaid,
// } from '../../../../redux/actions/contracts/contracts.js';

import {
    changeContractApprowePopUpStatus,
    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,
    setContractDatePaid,
    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
} from '../../../../redux/actions/contracts/contracts.js';

import TextHalf from '../../../elements/viewData/textHalf'
import TextFull from '../../../elements/viewData/textFull'
import PopDown from '../../../elements/viewData/popdownBlock/popDownBlock'
import DocItem from '../../../documents/docItem';
import AddDocPopup from '../../../documents/addDocPopup/addDocPopup';

import {
    histeryAuctions,
    histeryAwards,
    histeryContrasts,
    histeryBids,
} from '../../../../redux/histery.js';
import {setUniversalError, changeLoader} from '../../../../redux/actions.js';
import {contractsAuctionIdGet} from '../../../../redux/actions/contracts/contracts.js';

import ContractComponentsDate from '../contractComponents/contractComponentsDate/contractComponentsDate'

import {modifiDateString} from '../../../../redux/modifiDate'
import { CONTACTS_TITLE_BOTTOM } from '../../../../model/lang/topMenu';

import Input from '../../../elements/inputs/input';
import InputDate from '../../../elements/inputs/inputDate';
import InputText from '../../../elements/inputs/input';
import TextArea from '../../../elements/inputs/textArea';
import InputAmount from '../../../elements/inputs/inputAmount';

import { is } from 'date-fns/locale';
import {startCostAuction} from '../../util';
import {viewStringValue} from '../../../../api/valueToString.js'

const ContractAPE = (props) => {
    const [slim, setSlim] = useState({ state: false, text: 'Розгорнути' })
    const [popAddDoc, setPopAddDoc] = useState(false)
    
    function Slimmer() {
        slim.state ? setSlim({ state: false, text: 'Розгорнути'}) : setSlim({ state: true, text: 'Згорнути'})
    }

    const docScopeData = {}
    docScopeData.typeOf = 'contract'
    docScopeData.aucOwner = props.auctionId.privateData.isOwner
    
    docScopeData.reload = () => { getUpdateDataAuction(props, props.auctionId.id) }
    docScopeData.aucId = props.auctionId.id
    docScopeData.aucStatus = props.auctionId.status
    docScopeData.aucType = props.auctionId.sellingMethod
    if(props.auctionId.contracts !== null && props.auctionId.contracts.length > 0){
        docScopeData.docList = props.auctionId.contracts[0].documents
    }
    // docScopeData.patchNewDocFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/contract/${props.auctionId.contracts[0].id}/documents`
    // docScopeData.patchNewDocWoutFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/contract/${props.auctionId.contracts[0].id}/documents`
    // docScopeData.deleteURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.elemBid.id}/documents`
    // docScopeData.historyUrl = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auctionId.id}/contracts/${props.auctionId.contracts[0].id}/documents`

    let tmp = null;
    if(props.auctionId !== null){
        if(props.auctionId === undefined || props.auctionId === 'undefined'){
        }else{
            if(
                props.auctionId.status === 'active_auction' ||
                props.auctionId.status === 'active_awarded' ||
                props.auctionId.status === 'active_qualification' ||
                props.auctionId.status === 'pending_payment' ||
                props.auctionId.status === 'pending_admission' ||
                props.auctionId.status === 'cancelled' ||
                props.auctionId.status === 'complete' ||
                props.auctionId.status === 'unsuccessful'
            ){
                if(props.auctionId.contracts !== null){
                    tmp = (
                        <div className={styles.contractBlock}>
                            <div className={styles.contractBlock_title}>Кваліфікація учасників договір</div>
                            <div className={styles.slimmer} onClick={() => Slimmer()}>{slim.text}</div>
                            {slim.state ?
                                <div className={styles.contractBlockContent}>
                                    {viewDataContract(props, docScopeData, setPopAddDoc, popAddDoc)}
                                </div> : null
                            }
                        </div>
                    )
                }
            }
        }
    }
    return tmp
}

function viewDataContract(props, docScopeData, setPopAddDoc, popAddDoc){
    if(checkProp(props, 'auctionId')){
        //if(props.auctionsGetId.status === 'complete'){
            if(props.auctionId !== null){
                return props.auctionId.contracts.map(
                    (i, count) => {
                        let tmpValueContracts = null
                        let tmpDateContracts = null
                        if(i.value !== null){
                            let tmpValueAddedTaxIncluded = null
                            if(props.auctionId.value.valueAddedTaxIncluded){
                                if(!isSPE(props.auctionId.sellingMethod) && !isSPD(props.auctionId.sellingMethod) && !isLPE(props.auctionId.sellingMethod)) tmpValueAddedTaxIncluded = ', з ПДВ'
                            }else{
                                if(!isSPE(props.auctionId.sellingMethod) && !isSPD(props.auctionId.sellingMethod) && !isLPE(props.auctionId.sellingMethod)) tmpValueAddedTaxIncluded = ', без ПДВ'
                            }
                            tmpValueContracts = <div>{viewStringValue(i.value)}</div>
                        }else{
                            tmpValueContracts = <div></div>
                        }
                        /*if(i.x_prepaymentDetailsDate !== null){
                            //tmpDateContracts = <div>Сумма договору: {i.x_prepaymentDetailsValue.amount} {i.x_prepaymentDetailsValue.currency}</div>
                        }else{
                            //tmpDateContracts = <div>Сумма договору:</div>
                        }*/
                        let startDate = null
                        let endDate = null
                        let signedDate = null
                        let prepaynentDate = null
                        let x_prepaymentDetails = null
                        
                        if(i.x_prepaymentDetails !== null){
                            x_prepaymentDetails = modifiDateString(i.x_prepaymentDetails.date, 3, false)
                        }
                        let tmpX_prepaymentDetails = null
                        let tmpX_prepaymentDetailsAmount = null
                        if(x_prepaymentDetails !== null){
                            tmpX_prepaymentDetails = <TextHalf
                                title='Дата передоплати'
                                data={x_prepaymentDetails}
                            />
                            tmpX_prepaymentDetailsAmount = <TextHalf
                                title='Розмір передоплати в грн.'
                                data={viewStringValue(i.x_prepaymentDetails.prepayment)}
                            />
                        }
                        if(i.contractTime !== null){
                            if(i.contractTime.dateFrom !== null){
                                //startDate = dataDayCreate(i.contractTime.startDate)
                                startDate = i.contractTime.dateFrom
                            }
                            if(i.contractTime.dateTill !== null){
                                //endDate = dataDayCreate(i.contractTime.endDate)
                                endDate = i.contractTime.dateTill
                            }
                        }
                        if(i.dateSigned !== null){
                            signedDate = modifiDateString(i.dateSigned, 3, false)
                        }
                        if(i.datePaid !== null){
                            prepaynentDate = modifiDateString(i.datePaid, 3, false)
                        }

                        let tmpStatusContracts = null
                        if(props.auctionId !== null){
                            if (
                                isSUE(props.auctionId.sellingMethod) ||
                                isSUD(props.auctionId.sellingMethod)
                            ){
                                tmpStatusContracts = statusContracts(props, i.status)
                            }
                        }
                        let tmpDataPaid = null
                        let tmpEndValueContracts = null
                        if(
                            isGFE(props.auctionId.sellingMethod) || isGFD(props.auctionId.sellingMethod)
                        ){
                            tmpDataPaid = <TextHalf
                                                title='Дата сплати коштів за лот'
                                                data={prepaynentDate}
                                            />
                            if(i.contractTotalValue !== null){
                                tmpEndValueContracts = <TextHalf
                                                            title='Заключна вартість договору'
                                                            data={viewStringValue(i.contractTotalValue)}
                                                        />
                            }
                        } else if(
                            isBSE(props.auctionId.sellingMethod) ||
                            isBSD(props.auctionId.sellingMethod) ||
                            isALE(props.auctionId.sellingMethod) ||
                            isTIE(props.auctionId.sellingMethod) ||
                            isBRE(props.auctionId.sellingMethod) ||
                            isBRD(props.auctionId.sellingMethod) ||
                            isBRW(props.auctionId.sellingMethod) ||
                            isSPE(props.auctionId.sellingMethod) ||
                            isSPD(props.auctionId.sellingMethod) ||
                            isLPE(props.auctionId.sellingMethod)
                        ){
                            tmpDataPaid = null
                            if(i.contractTotalValue !== null){
                                if(i.contractTotalValue.amount !== null){
                                    let tmpValueAddedTaxIncluded = ''
                                    if(props.auctionId.value.valueAddedTaxIncluded){
                                        if(!isSPE(props.auctionId.sellingMethod) && !isSPD(props.auctionId.sellingMethod) && !isLPE(props.auctionId.sellingMethod)) tmpValueAddedTaxIncluded = ', з ПДВ'
                                    }else{
                                        if(!isSPE(props.auctionId.sellingMethod) && !isSPD(props.auctionId.sellingMethod) && !isLPE(props.auctionId.sellingMethod)) tmpValueAddedTaxIncluded = ', без ПДВ'
                                    }
                                    tmpEndValueContracts = <TextHalf
                                                        title='Заключна вартість договору'
                                                        data={viewStringValue(i.contractTotalValue)}
                                                    />
                                }
                            }
                        } else if (
                            isLLE(props.auctionId.sellingMethod) ||
                            isLLD(props.auctionId.sellingMethod) ||
                            isLLP(props.auctionId.sellingMethod) ||
                            isRLE(props.auctionId.sellingMethod) ||
                            isRLD(props.auctionId.sellingMethod) ||
                            isCLE(props.auctionId.sellingMethod) ||
                            isCLD(props.auctionId.sellingMethod)
                        ){
                            tmpDataPaid = null
                            if(i.contractTotalValue !== null){
                                if(i.contractTotalValue.amount !== null){
                                    tmpEndValueContracts = <TextHalf
                                                        title='Заключна вартість договору'
                                                        data={viewStringValue(i.contractTotalValue)}
                                                    />
                                }
                            }
                        } else if (
                            isLRE(props.auctionId.sellingMethod) ||
                            isLSE(props.auctionId.sellingMethod) ||
                            isLSP(props.auctionId.sellingMethod) ||
                            isAPE(props.auctionId.sellingMethod)
                            || isAPD(props.auctionId.sellingMethod)
                        ){
                            if(i.x_prepaymentDetailsDate !== null) prepaynentDate = modifiDateString(i.x_prepaymentDetailsDate, 3, false)
                            if(prepaynentDate !== null){
                                /***tmpDataPaid = <TextHalf
                                    title='Дата передплати'
                                    data={prepaynentDate}
                                />*/
                                tmpDataPaid = null
                            }else{
                                tmpDataPaid = null
                            }
                        }else{
                            if(prepaynentDate){
                                tmpDataPaid = <TextHalf
                                                title='Дата сплати до державного бюджету України'
                                                data={prepaynentDate}
                                            />
                            }else{
                                tmpDataPaid = null
                            }
                        }

                        if (
                            isLRE(props.auctionId.sellingMethod) ||
                            isLSE(props.auctionId.sellingMethod) ||
                            isLSP(props.auctionId.sellingMethod) ||
                            isAPE(props.auctionId.sellingMethod)
                            || isAPD(props.auctionId.sellingMethod)
                        ){
                            tmpDataPaid = null
                            if(i.contractTotalValue !== null){
                                if(i.contractTotalValue.amount !== null){
                                    tmpEndValueContracts = <TextHalf
                                                        title='Заключна вартість договору'
                                                        data={`${viewStringValue(i.contractTotalValue)}`}
                                                    />
                                }
                            }
                        }

                        let tmpStartDate = null
                        if(startDate !== null){
                            tmpStartDate = <ContractComponentsDate 
                                            dateText='Строк дії акту/договору з:'
                                            dateData={startDate}
                                        />
                        }
                        let tmpEndDate = null
                        if(endDate !== null){
                            tmpEndDate = <ContractComponentsDate 
                                dateText='Строк дії акту/договору по:'
                                dateData={endDate}
                            />
                        }
                        let dateCreateContract = null
                        if(i.datePublished !== null) dateCreateContract = modifiDateString(i.datePublished, 3, false)
                        let dateModifContract = null
                        if(i.dateModified !== null) dateModifContract = modifiDateString(i.dateModified, 3, false)
                        return (<>
                                {
                                    popAddDoc ?
                                        <AddDocPopup
                                            scopeData={docScopeData}
                                            actionClose={() => {
                                                setPopAddDoc(false)
                                            }}
                                            actionNo={() => {
                                                setPopAddDoc(false)
                                            }}
                                            // bidId={props.elemBid.id}
                                            // elemBid={props.elemBid}
                                        />
                                    : null
                                }
                                <div className={styles.contractBlock}>
                                    {viewName(i.buyers[0])}
                                    {viewContractNumber(i.contractNumber)}
                                    {viewTitle(i.title)}
                                    {viewDescription(i.description)}
                                    {viewStatus(props, i.status)}
                                    {tmpValueContracts ? <TextHalf
                                        title='Вартість договору'
                                        data={tmpValueContracts}
                                    /> : null}
                                    {tmpEndValueContracts}
                                    {dateCreateContract !== null ? <TextHalf
                                        title="Дата створення об'єкта договору"
                                        data={dateCreateContract}
                                    /> : null}
                                    {dateModifContract !== null ? <TextHalf
                                        title="Остання дата редагування договору"
                                        data={dateModifContract}
                                    /> : null}

                                    {tmpStartDate}
                                    {tmpEndDate}
                                    {signedDate ? <TextHalf
                                        title='Дата підписання договору'
                                        data={signedDate}
                                    /> : null}
                                    {tmpX_prepaymentDetails}
                                    {tmpX_prepaymentDetailsAmount}
                                    {tmpDataPaid}
                                    {(isSPE(props.auctionId.sellingMethod) || isSPD(props.auctionId.sellingMethod) && isLPE(props.auctionId.sellingMethod)) && i.preliminaryContract !== null ? <div className={styles.contractBlock}>
                                        {i.preliminaryContract !== null ? viewPreliminaryContract(props, i) : null}
                                    </div> : null}
                                    {(isLRE(props.auctionId.sellingMethod) || isLSE(props.auctionId.sellingMethod) || isLSP(props.auctionId.sellingMethod)
                                        || isAPE(props.auctionId.sellingMethod)|| isAPD(props.auctionId.sellingMethod)) && i.lotPaymentConfirmation !== null ?
                                        i.lotPaymentConfirmation !== null
                                        ? <TextHalf
                                            title='Підтвердження сплати за лот'
                                            data={i.lotPaymentConfirmation ? "Оплату підтверджено" : "Оплату не підтверджено"}
                                        />
                                        : null
                                    : null}
                                    {(isLRE(props.auctionId.sellingMethod) || isLSE(props.auctionId.sellingMethod) || isLSP(props.auctionId.sellingMethod)
                                        || isAPE(props.auctionId.sellingMethod) || isAPD(props.auctionId.sellingMethod)) && i.lotPaymentConfirmation === false ? <div className={styles.contractBlock}>
                                        {i.lotPaymentConfirmation === false ? viewPreliminaryContractLSP(props, i) : null}
                                    </div> : null}
                                    <div className={styles.btnBlock}>
                                        {buttonContractPopUp(props, i, count, docScopeData, setPopAddDoc, popAddDoc)}
                                    </div>
                                    {viewDateSUESUD(props, {
                                                        aucId: props.auctionId.id,
                                                        contractId: i.id,
                                                        sellingMethod: props.auctionId.sellingMethod,
                                                        count: count,
                                                        fullAuction: props.auctionId,
                                                        typeView: 2,
                                                    })}
                                    {viewContractDocuments(props, i, count, docScopeData, setPopAddDoc)}
                                </div>
                            </>
                        )
                    }
                )
            }
        //}
    }
}

function viewPreliminaryContractLSP(props, i){
    return (
        !i.lotPaymentConfirmation
        ? <TextFull
            title='Важлива інформація'
            data={`Обраний вами варіант підтверджує, що оплата за лот не здійснена учасником ${i.buyers[0].name.uk_UA}, необхідно його дискваліфікувати`}
        /> : null
    )
}

function viewPreliminaryContract(props, i){
    if(isSPE(props.auctionId.sellingMethod) || isSPD(props.auctionId.sellingMethod) || isLPE(props.auctionId.sellingMethod)){
        return (
            <>
                <TextHalf
                    title='Номер попереднього договору'
                    data={i.preliminaryContract.contractNumber}
                />
                <TextHalf
                    title='Назва попереднього договору'
                    data={i.preliminaryContract.title !== null ? i.preliminaryContract.title.uk_UA : ''}
                />
                <TextHalf
                    title='Опис попереднього договору'
                    data={i.preliminaryContract.description !== null ? i.preliminaryContract.description.uk_UA : ''}
                />
                <TextHalf
                    title="Дата підписання попереднього договору"
                    data={modifiDateString(i.preliminaryContract.dateSigned, 3, false)}
                />
            </>
        )
    }
    return null
}

function buttonContractPopUp(props, i, count, docScopeData, setPopAddDoc, popAddDoc){
    if(checkProp(props, 'auctionId') && checkProp(props.auctionId, 'privateData')){
        if(props.auctionId.privateData.isOwner === true){
            let tmp = null
            let flag = false
            if(i.documents !== null && i.documents.length > 0){
                for(let tt=0; tt < i.documents.length; tt++){
                    if(i.documents[tt].documentType === "preliminaryContract") flag = true
                }
            }
            if(
                (isSPE(props.auctionId.sellingMethod) || isSPD(props.auctionId.sellingMethod) || isLPE(props.auctionId.sellingMethod))
                && (
                    props.auctionId.status === 'active_awarded'
                    && i.status === 'pending'
                    //&& i.status !== 'cancelled'
                    && i.preliminaryContractSigned === null
                    && flag
                )
            ){
                tmp = <ViewApproveContractFirst info={{
                    data: props,
                    elem: i,
                    count
                }} />
            }
            let tmp1 = null
            if((isSPE(props.auctionId.sellingMethod) || isSPD(props.auctionId.sellingMethod) || isLPE(props.auctionId.sellingMethod))
                &&
                (i.preliminaryContract !== null
                && i.preliminaryContract.contractNumber !== null && i.preliminaryContract.contractNumber !== ''
                && i.preliminaryContract.dateSigned !== null && i.preliminaryContract.dateSigned !== ''
                && i.preliminaryContract.title !== null
                && i.preliminaryContract.title.uk_UA !== null && i.preliminaryContract.title.uk_UA !== ''
                && i.preliminaryContractSigned === false
                && props.auctionId.status === 'active_awarded'
                && i.status === 'pending'
                && flag)
            ){
                let body = {
                    preliminaryContractSigned: true
                }
                tmp1 = <div className={styles.btnAccept} onClick={()=>{updateContractData1(props, body, i.id)}}>Підтвердити договір</div>
            }

            return (
                <>
                    {viewApproveContractDoc(props, i, count, 1, docScopeData, setPopAddDoc, popAddDoc)}
                    {tmp}
                    {tmp1}
                    {viewApproveContractData(props, i, count, 2)}
                    {LREButton(props,
                        {
                            aucId: props.auctionId.id,
                            contractId: i.id,
                            sellingMethod: props.auctionId.sellingMethod,
                            count: count,
                            fullAuction: props.auctionId,
                            typeView: 2,
                        }
                    )}
                    {viewApproveContract(
                        props,
                        {
                            aucId: props.auctionId.id,
                            contractId: i.id,
                            sellingMethod: props.auctionId.sellingMethod,
                            count: count,
                            fullAuction: props.auctionId,
                            typeView: 2,
                        }
                    )}
                </>
            )
        }
    }
    return null
}

//25.11.22 Добить логику появления кнопки
function ViewApproveContractFirst(props){
    const [contractNumber, setContractNumber] = useState(props.info.elem.preliminaryContract !== null && props.info.elem.preliminaryContract.contractNumber !== null ? props.info.elem.preliminaryContract.contractNumber : null)
    const [title, setTitle] = useState(props.info.elem.preliminaryContract !== null && props.info.elem.preliminaryContract.title !== null ? props.info.elem.preliminaryContract.title.uk_UA : null)
    const [description, setDescription] = useState(props.info.elem.preliminaryContract !== null && props.info.elem.preliminaryContract.description !== null ? props.info.elem.preliminaryContract.description.uk_UA : null)
    const [startDate, setStartDate] = useState(props.info.elem.preliminaryContract !== null && props.info.elem.preliminaryContract.dateSigned !== null ? props.info.elem.preliminaryContract.dateSigned : null)
    const [onOff, setOnOff] = useState(true)
    if(onOff){
        return (
            <div className={styles.btnAccept} onClick={()=>{setOnOff(!onOff)}}>Попередній договір</div>
        )
    }
    let body = {
        preliminaryContract: {
            contractNumber: contractNumber,
            title: {uk_UA: title},
            description: {uk_UA: description},
            dateSigned: startDate
        }
    }

    let tmp = false
    if(
        contractNumber !== null && contractNumber !== ''
        && title !== null && title !== ''
        && startDate !== null && startDate !== ''
    ) tmp = true
    return (
        <div className={styles.contractFirst}>
            <div className={`${styles.contractBlock_title}`}>Попередній договір</div>
            <div className={styles.viewPreliminaryContract}>
                <Input
                    label='Номер попереднього договору'
                    value={contractNumber}
                    onChange={(e)=>{ setContractNumber(e) }}
                    required
                />
                <Input
                    label='Назва попереднього договору'
                    value={title}
                    onChange={(e)=>{ setTitle(e) }}
                    required
                />
                <Input
                    label='Опис попереднього договору'
                    value={description}
                    onChange={(e)=>{ setDescription(e) }}
                />
                <div className={styles.contractData}>Дата підписання попереднього договору</div>
                <InputDate
                    data={{
                        target: startDate,
                        time: true
                    }}
                    onChange={ e => setStartDate(e) }
                />
            </div>

            <div className={styles.btnBlock}>
                <div className={styles.btnAccept} onClick={()=>{setOnOff(!onOff)}}>Закрити</div>
                <div className={tmp ? styles.btnAccept : styles.btnToList}
                     onClick={()=>{
                        updateContractData(props.info.data, body, props.info.elem.id)
                        setOnOff(!onOff)
                    }}
                >
                    Зберегти
                </div>
            </div>
        </div>
    )
}


async function updateContractData(props, body, contractId){
    //await props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/contract/${contractId}`
   
    const response = await fetch(endPoint, {
        method: "PATCH",
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }

    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            /*let tmp = JSON.stringify({title: 'Помилка оновлення данних контракту', message: JSON.stringify(json)})
            if(json.message.hasOwnProperty('x_prepaymentDetails') === true){
                if(json.message.x_prepaymentDetails.hasOwnProperty('date') === true){
                    if(json.message.x_prepaymentDetails.date === 'Value should be greater than auctionPeriod.endDate and less than now'){
                        tmp = JSON.stringify({title: 'Помилка оновлення данних контракту', message: 'Дата контракту не правильно виставлена'})
                    }
                }
            }*/
            //props.setUniversalError(tmp)
            //await props.changeLoader(false)
        }else{
            //await getUpdateDataAuction(props, props.auctions.auctionId)
            await setTimeout(() => syncPromisContract(props, body), 2000)
            //await props.changeLoader(false)
        }
    }
}
async function updateContractData1(props, body, contractId){
    //await props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/contract/${contractId}`
   
    const response = await fetch(endPoint, {
        method: "PATCH",
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }

    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            /*let tmp = JSON.stringify({title: 'Помилка оновлення данних контракту', message: JSON.stringify(json)})
            if(json.message.hasOwnProperty('x_prepaymentDetails') === true){
                if(json.message.x_prepaymentDetails.hasOwnProperty('date') === true){
                    if(json.message.x_prepaymentDetails.date === 'Value should be greater than auctionPeriod.endDate and less than now'){
                        tmp = JSON.stringify({title: 'Помилка оновлення данних контракту', message: 'Дата контракту не правильно виставлена'})
                    }
                }
            }*/
            //props.setUniversalError(tmp)
            //await props.changeLoader(false)
        }else{
            //await getUpdateDataAuction(props, props.auctions.auctionId)
            await setTimeout(() => syncPromisContract(props, body), 2000)
            //await props.changeLoader(false)
        }
    }
}




function viewApproveContractDoc(props, data, count, type, docScopeData, setPopAddDoc, popAddDoc) {
    if(props.auctionId.contracts !== null){
        if(props.auctionId.contracts.length > 0){
            if(/*data.status === 'pending' ||*/ data.status === 'signed'){
                // if
                //     // (data.lotPaymentConfirmation === null) //TODO: recomment
                //     (!data.lotPaymentConfirmation)
                // {
                    docScopeData.docTypesList = listContractDoc(props, data)
                    // docScopeData.reload = () => { getUpdateDataBid(props, props.elemBid.id) } 
                    docScopeData.contractId = data.id
                    // docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/contract/${data.contractId}/document`
                    docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/contract/${data.id}/documents`
                    // docScopeData.patchNewDocFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/award/${i.id}/documents`
                    // docScopeData.patchNewDocWoutFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/award/${i.id}/documents`
                    return (
                            <>
                                {
                                    popAddDoc ?
                                        <AddDocPopup
                                            scopeData={docScopeData}
                                            actionClose={() => {
                                                setPopAddDoc(false)
                                            }}
                                            actionNo={() => {
                                                setPopAddDoc(false)
                                            }}
                                            // bidId={props.elemBid.id}
                                            // elemBid={props.elemBid}
                                        />
                                    : null
                                }
                            <div
                                className={styles.btnAccept}
                                onClick={
                                    () => {
                                        // if(checkProp(data, 'title')){
                                        //     if(props.timberContractPut.title === '') props.setContractTitle(data.title)
                                        // }
                                        // if(checkProp(data, 'description')){
                                        //     if(props.timberContractPut.description === '') props.setContractDescription(data.description)
                                        // }
                                        // if(checkProp(data, 'contractNumber')){
                                        //     if(props.timberContractPut.contractNumber === '') props.setContractContractNumber(data.contractNumber)
                                        // }
                                        // if(checkProp(data.value, 'amount')){
                                        //     if(props.timberContractPut.xValueUAH.amount === null) props.setContractAmount(data.value.amount)
                                        // }
                                        // if(checkProp(data, 'dateSigned')){
                                        //     if(props.timberContractPut.dateSigned === '') {
                                        //         let tmp = data.dateSigned.split('+')
                                        //         props.setContractDateSigned(tmp[0])
                                        //     }
                                        // }
                                        // if(checkProp(data, 'x_prepaymentDetailsDate')){
                                        //     if(props.timberContractPut.contractTime.dateFrom === '') {
                                        //         let tmp = data.x_prepaymentDetailsDate.split('+')
                                        //         props.setContractDateFrom(tmp[0])
                                        //     }
                                        // }
                                        // if(checkProp(data, 'contractTime')){
                                        //     if(checkProp(data.contractTime, 'dateTill')){
                                        //         if(props.timberContractPut.contractTime.dateTill === '') {
                                        //             let tmp = data.contractTime.dateTill.split('+')
                                        //             props.setContractEndDate(tmp[0])
                                        //         }
                                        //     }
                                        //     if(checkProp(data.contractTime, 'dateFrom')){
                                        //         if(props.timberContractPut.contractTime.dateFrom === '') {
                                        //             let tmp = data.contractTime.dateFrom.split('+')
                                        //             props.setContractStartDate(tmp[0])
                                        //         }
                                        //     }
                                        // }
                                        setPopAddDoc(true)
                                        // props.changeContractApprowePopUpStatus({
                                        //     aucId: props.auctionId.id,
                                        //     contractId: data.id,
                                        //     sellingMethod: props.auctionId.sellingMethod,
                                        //     count: count,
                                        //     fullAuction: props.auctionId,
                                        //     typeView: type,
                                        // })
                                    }
                                }
                            >
                                Документи договору
                            </div>
                        </>
                    )
                //}
            }
        }
    }
    return (
        <div className={styles.btnToList}>
            Документи договору
        </div>
    )
}

function viewApproveContractData(props, data, count, type){
    if(props.auctionId !== null){
        if(props.auctionId.contracts.length > 0){
            if(/*props.auctionId.contracts[count].status === 'pending' ||*/ props.auctionId.contracts[count].status === 'signed'){
                return (
                    <div
                        className={styles.btnAccept}
                        onClick={
                            () => {
                                if(data.title !== null){
                                    if(props.timberContractPut.title === '' && props.timberContractPut.title !== null) props.setContractTitle(data.title.uk_UA)
                                }
                                if(data.description !== null){
                                    if(props.timberContractPut.description === '') props.setContractDescription(data.description.uk_UA)
                                }
                                if(data.contractNumber !== null){
                                    if(props.timberContractPut.contractNumber === '') props.setContractContractNumber(data.contractNumber)
                                }
                                if(data.value !== null){
                                    props.setDataAddress(data.value.currency, 'SET_CONTRACT_VALUE_CURRENCY')
                                    props.setDataAddress(data.value.amount, 'SET_CONTRACT_VALUE_AMOUNT')
                                    props.setDataAddress(data.value.valueAddedTaxIncluded, 'SET_CONTRACT_VALUE_TAX')
                                }
                                if(
                                    isBRE(props.auctionId.sellingMethod) ||
                                    isBRD(props.auctionId.sellingMethod) ||
                                    isBRW(props.auctionId.sellingMethod)
                                ){
                                    if(data.contractTotalValue !== null){
                                        props.setDataAddress(data.contractTotalValue.currency, 'SET_CONTRACT_CURRENCY')
                                        props.setDataAddress(data.contractTotalValue.amount, 'SET_CONTRACT_AMOUNT')
                                        props.setDataAddress(data.contractTotalValue.valueAddedTaxIncluded, 'SET_CONTRACT_TAX')
                                    }
                                }
                                //if(data.x_prepaymentDetails !== null){
                                    //props.setDataAddress('UAH', 'SET_CONTRACT_CURRENCY')
                                    //props.setDataAddress('', 'SET_CONTRACT_X_PREPAYMENTDETAILS_PREPAYMENT_AMOUNT')
                                    //props.setDataAddress(true, 'SET_CONTRACT_TAX')
                                //}
                                if(data.dateSigned !== null){
                                    if(props.timberContractPut.dateSigned === '') {
                                        let tmp = data.dateSigned.split('+')
                                        props.setContractDateSigned(tmp[0])
                                    }
                                }
                                if(data.x_prepaymentDetailsDate !== null){
                                    if(props.timberContractPut.contractTime.dateFrom === '') {
                                        let tmp = data.x_prepaymentDetailsDate.split('+')
                                        props.setContractDateFrom(tmp[0])
                                    }
                                }
                                if(data.contractTime !== null){
                                    if(data.contractTime.hasOwnProperty('dateTill') && data.contractTime.dateTill !== null){
                                        if(props.timberContractPut.contractTime.dateTill === '') {
                                            let tmp = data.contractTime.dateTill.split('+')
                                            props.setContractEndDate(tmp[0])
                                        }
                                    }
                                    if(data.contractTime.hasOwnProperty('dateFrom') && data.contractTime.dateFrom !== null){
                                        if(props.timberContractPut.contractTime.dateFrom === '') {
                                            let tmp = data.contractTime.dateFrom.split('+')
                                            props.setContractStartDate(tmp[0])
                                        }
                                    }
                                }
                                if(data.datePaid !== null){
                                    if(props.timberContractPut.datePaid === '') {
                                        let tmp = data.datePaid.split('+')
                                        props.setContractDatePaid(tmp[0])
                                    }
                                }
                                props.changeContractApprowePopUpStatus({
                                    aucId: props.auctionId.id,
                                    contractId: data.id,
                                    sellingMethod: props.auctionId.sellingMethod,
                                    count: count,
                                    fullAuction: props.auctionId,
                                    typeView: type,
                                    typePopUp: 'contracts'
                                })
                            }
                        }
                    >
                        Дані договору
                    </div>
                )
            }
        }
    }
    return <div className={styles.btnToList}>
                Дані договору
            </div>
}

function viewApproveContract(props, data){
    // if(props.auctionId !== null){
    //     if(props.auctionId.contracts.length > 0){
    //         if(props.auctionId.contracts[data.count].status === 'pending'){
    //             let tmpMainDoc = false
    //             if(props.auctionId.contracts[data.count].documents !== null){
    //                 if(props.auctionId.contracts[data.count].documents.length > 0){
    //                     for(let t = 0; t < props.auctionId.contracts[data.count].documents.length; t++){
    //                         if(props.auctionId.contracts[data.count].documents[t].documentType === 'contractSigned'){
    //                             //if(props.contractAuctionIdGet[data.count].documents[t].createdByCurrentUser === true){
    //                                 //tmpMainDoc = true/////++///
    //                             //}
    //                             if(props.auctionId !== null){
    //                                 if(props.auctionId.privateData.isOwner === true){
    //                                     tmpMainDoc = true
    //                                 }
    //                             }
    //                         }
    //                     }
    //                 }
    //             }
    //             if(
    //                 props.auctionId.contracts[data.count].title !== null &&
    //                 props.auctionId.contracts[data.count].title !== '' &&
    //                 props.auctionId.contracts[data.count].contractNumber !== null &&
    //                 props.auctionId.contracts[data.count].contractNumber !== '' &&
    //                 props.auctionId.contracts[data.count].dateSigned !== null &&
    //                 props.auctionId.contracts[data.count].dateSigned !== '' &&
    //                 tmpMainDoc === true
    //             ){
    //                 return (
    //                     <div
    //                         className={styles.btnAccept}
    //                         onClick={
    //                             () => {
    //                                 promisApproveContract(props, data)
    //                             }
    //                         }
    //                     >
    //                         {nameButtonAppruveContract(props, data)}
    //                     </div>
    //                 )
    //             }else{
    //                 return (
    //                     <div className={styles.btnToList}>
    //                         {nameButtonAppruveContract(props, data)}
    //                     </div>
    //                 )
    //             }
    //         }else if (props.auctionId.contracts[data.count].status === 'paid') {
    //             if(
    //                 isSUE(props.auctionId.sellingMethod) || isSUD(props.auctionId.sellingMethod)
    //             ){
    //                 //Внес правку в логику, добавлен клик и смена статуса если ок коммент удалить/////
    //                 return (
    //                     <div
    //                         className={styles.btnAccept}
    //                         onClick={()=>{
    //                             promisApproveContractLR(props, data)
    //                         }}
    //                     >
    //                         {nameButtonAppruveContract(props, data, true)}
    //                     </div>
    //                 )
    //             }
    //             let tmpMainDoc = false
    //             if(props.auctionId.contracts[data.count].documents !== null){
    //                 if(props.auctionId.contracts[data.count].documents.length > 0){
    //                     for(let t = 0; t < props.auctionId.contracts[data.count].documents.length; t++){
    //                         if(props.auctionId.contracts[data.count].documents[t].documentType === 'contractSigned'){
    //                             //if(props.contractAuctionIdGet[data.count].documents[t].createdByCurrentUser === true){
    //                                 //tmpMainDoc = true
    //                             //}
    //                             if(props.auctionId !== null){
    //                                 if(props.auctionId.privateData.isOwner === true){
    //                                     tmpMainDoc = true
    //                                 }
    //                             }
    //                         }
    //                     }
    //                 }
    //             }
    //             if(
    //                 props.auctionId.contracts[data.count].title !== null &&
    //                 props.auctionId.contracts[data.count].title !== '' &&
    //                 props.auctionId.contracts[data.count].contractNumber !== null &&
    //                 props.auctionId.contracts[data.count].contractNumber !== '' &&
    //                 props.auctionId.contracts[data.count].dateSigned !== null &&
    //                 props.auctionId.contracts[data.count].dateSigned !== '' &&
    //                 tmpMainDoc === true
    //             ){
    //                 return (
    //                     <div
    //                         className={styles.btnAccept}
    //                         onClick={
    //                             () => {
    //                                 promisApproveContract(props, data)
    //                             }
    //                         }
    //                     >
    //                         {nameButtonAppruveContract(props, data)}
    //                     </div>
    //                 )
    //             }else{
    //                 return (
    //                     <div className={styles.btnToList}>
    //                         {nameButtonAppruveContract(props, data)}
    //                     </div>
    //                 )
    //             }
    //         }
    //     }
    // }
    if(props.auctionId.contracts[data.count].status === 'signed'){
        if (
            isLRE(props.auctionId.sellingMethod) ||
            isLSE(props.auctionId.sellingMethod) ||
            isLSP(props.auctionId.sellingMethod) ||
            isAPE(props.auctionId.sellingMethod)
            || isAPD(props.auctionId.sellingMethod)
        ){
            if(
                //props.auctionId.contracts[data.count].lotPaymentConfirmation === null &&
                props.auctionId.contracts[data.count].status !== 'cancelled'
            ){
                let tmpMainDoc = false
                if(props.auctionId.contracts[data.count].documents !== null){
                    if(props.auctionId.contracts[data.count].documents.length > 0){
                        for(let t = 0; t < props.auctionId.contracts[data.count].documents.length; t++){
                            if(props.auctionId.contracts[data.count].documents[t].documentType === 'contractSigned'){
                                //if(props.contractAuctionIdGet[data.count].documents[t].createdByCurrentUser === true){
                                    //tmpMainDoc = true
                                //}
                                if(props.auctionId !== null){
                                    if(props.auctionId.privateData.isOwner === true){
                                        tmpMainDoc = true
                                    }
                                }
                            }
                        }
                    }
                }
                return (
                    <>
                        
                        {props.auctionId.contracts[data.count].title !== null &&
                            props.auctionId.contracts[data.count].title !== '' &&
                            props.auctionId.contracts[data.count].contractNumber !== null &&
                            props.auctionId.contracts[data.count].contractNumber !== '' &&
                            props.auctionId.contracts[data.count].dateSigned !== null &&
                            props.auctionId.contracts[data.count].dateSigned !== '' &&
                            tmpMainDoc === true
                            && props.auctionId.contracts[data.count].lotPaymentConfirmation
                            && props.auctionId.contracts[data.count].status
                            ? <div
                                className={styles.btnAccept}
                                onClick={
                                    () => {
                                        //promisApproveContract(props, data)
                                        promisApproveContractLR(props, data, true)
                                    }
                                }
                            >
                                Підтвердити договір
                            </div>
                            : <div className={styles.btnToList}>
                                Підтвердити договір
                            </div>
                            }
                    </>
                )
            }
        }
    }
    // return (
    //     <div className={styles.btnToList}>
    //         {nameButtonAppruveContract(props, data)}
    //     </div>
    // )
}

function LREButton(props, data){
    if(props.auctionId !== null){
        if(props.auctionId.contracts.length > 0){
            if(props.auctionId.contracts[data.count].status === 'pending'/* || props.auctionId.contracts[data.count].status === 'signed'*/){
                if(
                    //props.auctionId.contracts[data.count].lotPaymentConfirmation === null &&
                    props.auctionId.contracts[data.count].status !== 'cancelled'
                ){
                    let tmpMainDoc = false
                    if(props.auctionId.contracts[data.count].documents !== null){
                        if(props.auctionId.contracts[data.count].documents.length > 0){
                            for(let t = 0; t < props.auctionId.contracts[data.count].documents.length; t++){
                                if(props.auctionId.contracts[data.count].documents[t].documentType === 'contractSigned'){
                                    //if(props.contractAuctionIdGet[data.count].documents[t].createdByCurrentUser === true){
                                        //tmpMainDoc = true
                                    //}
                                    if(props.auctionId !== null){
                                        if(props.auctionId.privateData.isOwner === true){
                                            tmpMainDoc = true
                                        }
                                    }
                                }
                            }
                        }
                    }
                    return (
                        <>
                            <div className={styles.buttonsBlockYesNo}>
                                <div className={styles.titleTextButtons}>Підтвердження сплати за лот</div>
                                <div className={styles.buttonsBlokFlex}>
                                    <div className={styles.btnAccept}
                                        onClick={
                                            () => {
                                                // if(props.auctionId.contracts[data.count].lotPaymentConfirmation === null){
                                                    promisContractLRUpdate(props, data, true)
                                                // }else{
                                                //     if(props.auctionId.contracts[data.count].lotPaymentConfirmation === true){
                                                //         promisApproveContractLR(props, data, true)
                                                //     }
                                                // }
                                            }
                                        }
                                    >
                                        Так
                                    </div>
                                    <div className={styles.btnDecline}
                                        onClick={
                                            () => {
                                                promisContractLRUpdate(props, data, false)
                                                // props.setDataAddress(data, 'SET_CONTRACT_POPUP_DEL')
                                            }
                                        }
                                    >
                                        Ні
                                    </div>
                                </div>
                            </div>
                            {/* {props.auctionId.contracts[data.count].title !== null
                                && props.auctionId.contracts[data.count].title !== ''
                                && props.auctionId.contracts[data.count].contractNumber !== null
                                && props.auctionId.contracts[data.count].contractNumber !== ''
                                && props.auctionId.contracts[data.count].dateSigned !== null
                                && props.auctionId.contracts[data.count].dateSigned !== ''
                                && tmpMainDoc === true
                                && */}
                                {props.auctionId.contracts[data.count].lotPaymentConfirmation
                                ? <div
                                    className={styles.btnAccept}
                                    onClick={
                                        () => {
                                            promisApproveContract(props, data)
                                            //promisApproveContractLR(props, data, true)
                                        }
                                    }
                                >
                                    Підтвердження, що акт/договір готовий до підписання
                                </div>
                                : null
                                }
                        </>
                    )
                }
            }
        }
    }
    return null
}

function listContractDoc(props, data) {
    if(data.status === 'pending'){
        return [
            { value: 'contractSigned', name: 'Підписаний акт/договір' },
            //{ value: 'contractNotice', name: 'Повідомлення про договір' },
            { value: 'contractAnnexe', name: 'Додатки до акту/договору' },
            {/* value: 'paymentInformation', name: 'Відомості про сплату' */}
        ]
    }else if(data.status === 'signed'){
    //     return [{ value: 'paymentInformation', name: 'Відомості про сплату'}]
    // }else{
        return [
            { value: 'contractSigned', name: 'Підписаний акт/договір' },
            //{ value: 'contractNotice', name: 'Повідомлення про договір' },
            { value: 'contractAnnexe', name: 'Додатки до акту/договору' }
        ]
    }
}

function viewDateSUESUD(props, data){
    if (props.auctionId.contracts[data.count].status === 'signed') {
        if(props.auctionId.privateData.isOwner === true){
            if (
                props.auctionId.sellingMethod === "subsoil-english" ||
                props.auctionId.sellingMethod === "subsoil-english-fast" ||
                props.auctionId.sellingMethod === "subsoil-english-fast-manual" ||
                props.auctionId.sellingMethod === "subsoil-english-initial-auction" ||
                props.auctionId.sellingMethod === "subsoil-english-initial-qualification" ||
                props.auctionId.sellingMethod === "subsoil-dutch" ||
                props.auctionId.sellingMethod === "subsoil-dutch-fast" ||
                props.auctionId.sellingMethod === "subsoil-dutch-fast-manual" ||
                props.auctionId.sellingMethod === "subsoil-dutch-initial-auction" ||
                props.auctionId.sellingMethod === "subsoil-dutch-initial-qualification" ||
                props.auctionId.sellingMethod === "subsoil-dutch-initial-auction-manual" ||
                props.auctionId.sellingMethod === "subsoil-english-fast-auction-manual-qualification"
            ) {
                let dataContractTimeStartDate = {
                    targetType: 'SET_CONTRACT_DATE_PAID',
                    target: props.timberContractPut.datePaid,
                    typePopUp: 'contracts',
                    functions: props.setContractDatePaid,
                    label: `Кошти до бюджету сплачено`,
                    necessarily: true,
                    time: true,
                    desc: 'Ведіть дату внесення коштів'
                }

                const PaidButton = () => {
                    if (props.timberContractPut.datePaid !== null && props.timberContractPut.datePaid !== '') {
                        return <div className={styles.btnPaid}
                            onClick={
                                () => {
                                    //promisApproveContract(props, data)
                                    updateContractDatePaid(props, data)
                                }
                            }
                        >
                            {nameButtonAppruveContract(props, data, true)}
                        </div>
                    }
                    return <div className={styles.btnPaidInact}>
                            {nameButtonAppruveContract(props, data, true)}
                        </div>
                    
                }

                return (
                    <div className={styles.btnBlockDate}>
                        <InputDate 
                            data={dataContractTimeStartDate}
                            onChange={ e => props.setContractDatePaid(e)}
                        />
                        {PaidButton()}
                    </div>
                )
            }
        }
    }
    return null
}

function nameButtonAppruveContract(props, data, subsuoil){
    if(
        isSUE(props.auctionId.sellingMethod) || isSUD(props.auctionId.sellingMethod)
    ){
        if(props.auctionId.contracts[data.count].status === 'signed' && subsuoil){
            return 'Кошти до бюджету сплачено'
        }
        return 'Умови договору виконано'
    }
    return 'Затвердити договір'
}

async function updateContractDatePaid(props, data){
    await props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/contract/${props.auctionId.contracts[data.count].id}`
    const response = await fetch(endPoint, {
        method: "PATCH",
        body: JSON.stringify({
            'datePaid': props.timberContractPut.datePaid
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }

    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка оновлення данних контракту', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            await props.changeLoader(false)
        }else{
            //Добавить очистку полей
            await postEndingContract(props, data)
            let data1 = {
                domen: process.env.REACT_APP_END_POINT_BD,
                version: 'v1',
                local: props.langFlag
            }
            let headers1 = {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
            //await props.contractsAuctionIdGet(data1, headers1, props.popUpContractApprowe.aucId)
            await setTimeout(() => syncPromisContract(props, data), 2000)
        }
    }
}

async function postEndingContract(props, data){
    await props.changeLoader(true)
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/contract/${props.auctionId.contracts[data.count].id}/approve/set_status/paid`
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/contract/${props.auctionId.contracts[data.count].id}/set_status/paid`
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }

    }).catch(console.error)
    if(response === undefined || response === 'undefined'){

    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка підпису контракту', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            await props.changeLoader(false)
        }else{
            let data1 = {
                domen: process.env.REACT_APP_END_POINT_BD,
                version: 'v1',
                local: props.langFlag
            }
            let headers1 = {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
            //await props.contractsAuctionIdGet(data1, headers1, props.popUpContractApprowe.aucId)
            await setTimeout(() => syncPromisContract(props, data), 2000)
        }
    }    
}

/////////////////////////////////////////////////////////////////////
async function baseSyncAuc(props, data){
    const url2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${data.aucId}/sync`;
    const response2 = await fetch(url2, {
        method: "GET",
        headers: {
            //'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    //if(response2 === undefined || response2 === 'undefined'){
    //}else{
    //}
}
/////////////////////////////////////////////////////////////////////
async function promisContractLRUpdate(props, data, status){
    props.changeLoader(true)
    let endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${data.aucId}/contract/${data.contractId}/confirm_payment?lotPaymentConfirmation=${status}`
    let method = null
    let body = {}
    body.lotPaymentConfirmation = status
    const response = await fetch(endPoint, {
        method: 'PATCH',
        /*body: JSON.stringify(body),*/
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при затвердженні контракту', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            props.changeLoader(false)
        }else{
            // if(status){
            //     await promisApproveContractLR(props, data, status)
            // }else{
                /*let data1 = {
                    domen: process.env.REACT_APP_END_POINT_BD,
                    version: 'v1',
                    local: props.langFlag
                }
                let headers1 = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                await props.contractsAuctionIdGet(data1, headers1, data.aucId)
                await props.changeLoader(false)*/
                await setTimeout(() => syncPromisContract(props, data), 2000)
            // }
        }
    }
    await props.changeLoader(false)
}

async function promisApproveContract(props, data){
    props.changeLoader(true)
    let endPoint = null
    if (
        isSUE(props.auctionId.sellingMethod) || isSUD(props.auctionId.sellingMethod) ||
        isLRE(props.auctionId.sellingMethod) || isLSE(props.auctionId.sellingMethod) ||
        isLSP(props.auctionId.sellingMethod)
        || isAPE(props.auctionId.sellingMethod) || isAPD(props.auctionId.sellingMethod)
    ){
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${data.aucId}/contract/${data.contractId}/set_status/signed`
    }else{
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${data.aucId}/contract/${data.contractId}/set_status/active`
    }
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        props.changeLoader(false)
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при затвердженні контракту', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            props.changeLoader(false)
        }else{
            await props.changeLoader(false)
            await setTimeout(() => syncPromisContract(props, data), 2000)
        }
    }
    await props.changeLoader(false)
}

async function syncPromisContract(props, data){
    let data1 = {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag
    }
    let headers1 = {
        'Authorization': props.token.access_token,
        'Content-Type': 'application/json'
    }
    await props.changeLoader(true)
    //await baseSyncAuc(props, data)
    //await props.contractsAuctionIdGet(data1, headers1, data.aucId)
    await getUpdateDataAuction(props, props.auctionId.id)
    await props.changeLoader(false)
}
/////////////////////////////////////////////////////////////////////

async function promisApproveContractLR(props, data, status){
    props.changeLoader(true)
    let endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${data.aucId}/contract/${data.contractId}/set_status/active`
    const response = await fetch(endPoint, {
        method: 'POST',
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        props.changeLoader(false)
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при затвердженні контракту', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            props.changeLoader(false)
        }else{
            await setTimeout(() => syncPromisContract(props, data), 2000)
        }
    }
    await props.changeLoader(false)
}


function viewContractDocuments(props, data, count, docScopeData, setPopAddDoc){
    if(data !== null){
        if(data.documents !== null){
            if(data.documents.length > 0){
                return <PopDown
                    title={`Документи та додатки повʼязані з актом/договором (${data.documents.length})`}
                    content={
                        <>
                            {viewDocContract(data, count, props, docScopeData, setPopAddDoc)}
                            {/* {viewAllBidsDocHis(props)} */}
                        </>
                    }
                />
            }
        }
    }
    return null
}

function viewName(data){
    if(data !== null){
        return <TextHalf
            title='Переможець'
            data={
                <>
                    <div>{data.name.uk_UA}</div>
                    <div>{data.identifier.scheme}, {data.identifier.id}, {data.identifier.legalName.uk_UA}</div>
                </>
            }
        />
    }
    return null
}

function viewStatus(props, data){
    if(data !== null){
        return <TextHalf
            title='Статус акту/договору'
            data={typeStatus(props, data)}
        />
    }
    return null
}

function typeStatus(props, data){
    switch(data){
        case 'pending': return 'Очікується оплата'
        case 'signed': return 'Очікується підписання акту/договору'
        case 'active': return 'Акт/договір підписано'
        case 'cancelled': return 'Акт/договір скасовано'
        case 'unsuccessful': return 'Статус не використовується'
    }
}

function viewContractNumber(data){
    if(data !== null){
        return <TextHalf
            title='Номер договору'
            data={data}
        />
    }
    return null
}

function viewTitle(data){
    if(data !== null){
        return <TextHalf
            title='Назва договору'
            data={data.uk_UA}
        />
    }
    return null
}

function viewDescription(data){
    if(data !== null){
        return <TextHalf
            title='Опис договору'
            data={data.uk_UA}
        />
    }
    return null
}

function statusContracts(props, data){
    let tmp = null
    switch(data){
        case 'pending':
            if(isBRE(props.auctionId.sellingMethod) || isBRD(props.auctionId.sellingMethod) || isBRW(props.auctionId.sellingMethod)){
                tmp = <div className={styles.lineWrapAwards}>
                    <div className={styles.lineWrapAwards_titleContract}>Статус акту/договору:</div><div className={styles.lineWrapAwards_content}>Очікується акт/договір</div>
                </div>
            }else{
                tmp = <div className={styles.lineWrapAwards}>
                    <div className={styles.lineWrapAwards_titleContract}>Статус акту/договору:</div><div className={styles.lineWrapAwards_content}>Очікується договір</div>
                </div>
            }
            break
        case 'signed':
            tmp = <div className={styles.lineWrapAwards}>
                <div className={styles.lineWrapAwards_titleContract}>Статус акту/договору:</div><div className={styles.lineWrapAwards_content}>Договір підписано</div>
            </div>
            break
        case 'paid':
            tmp = <div className={styles.lineWrapAwards}>
                <div className={styles.lineWrapAwards_titleContract}>Статус акту/договору:</div><div className={styles.lineWrapAwards_content}>Кошти до бюджету сплачено</div>
            </div>
            break
        case 'active':
            if(isBRE(props.auctionId.sellingMethod) || isBRD(props.auctionId.sellingMethod) || isBRW(props.auctionId.sellingMethod)){
                tmp = <div className={styles.lineWrapAwards}>
                    <div className={styles.lineWrapAwards_titleContract}>Статус акту/договору:</div><div className={styles.lineWrapAwards_content}>Акт/договір підтверджено</div>
                </div>
            }else{
                tmp = <div className={styles.lineWrapAwards}>
                    <div className={styles.lineWrapAwards_titleContract}>Статус акту/договору:</div><div className={styles.lineWrapAwards_content}>Умови договору виконано</div>
                </div>
            }
            break
        case 'cancelled':
            if(isBRE(props.auctionId.sellingMethod) || isBRD(props.auctionId.sellingMethod) || isBRW(props.auctionId.sellingMethod)){
                tmp = <div className={styles.lineWrapAwards}>
                    <div className={styles.lineWrapAwards_titleContract}>Статус акту/договору:</div><div className={styles.lineWrapAwards_content}>Акт/договір скасовано</div>
                </div>
            }else{
                tmp = <div className={styles.lineWrapAwards}>
                    <div className={styles.lineWrapAwards_titleContract}>Статус акту/договору:</div><div className={styles.lineWrapAwards_content}>Договір скасовано</div>
                </div>
            }
            break
        case 'unsuccessful':
            tmp = <div className={styles.lineWrapAwards}>
                <div className={styles.lineWrapAwards_titleContract}>Статус акту/договору:</div><div className={styles.lineWrapAwards_content}>Статус не використовується</div>
            </div>
            break
        default:
            break
    }
    return tmp
}

function viewDocContract(data, countContract, props, docScopeData, setPopAddDoc) {
    if(data !== null){
        if(data === undefined || data === 'undefined'){
        } else {
            docScopeData.contractStatus = data.status
                docScopeData.patchNewDocFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/contract/${data.id}/documents`
                docScopeData.patchNewDocWoutFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/contract/${data.id}/documents`
                // docScopeData.deleteURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.elemBid.id}/documents`
                docScopeData.historyUrl = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auctionId.id}/contracts/${data.id}/documents`
            let tmp = data.documents.map(
                (t, count) => {
                    let datePublished = null
                    let dateModified = null
                    if (t.datePublished !== null) {
                        datePublished = modifiDateString(t.datePublished, 3, false)
                    }
                    if (t.dateModified !== null) {
                        dateModified = modifiDateString(t.dateModified, 3, false)
                    }
                    let tmpURL = `${t.url}`
                    if (t.hasOwnProperty('token') === true) {
                        if (t.token !== null) {
                            if (t.token !== '') {
                                tmpURL = `${t.url}?token=${t.token}`
                            }
                        }
                    }
                    docScopeData.docTypesList = listContractDoc(props, data)
                    return (
                        <>
                            <DocItem
                                data={t}
                                // contractData={data}
                                scopeData={docScopeData}
                                dataFuncOpenPopUp={setPopAddDoc}
                                // dataECP={ dataECPMapper(props.elemBid, i) }
                                // edit={() => {
                                //         props.changeContractApprowePopUpStatus({
                                //         aucId: props.auctionId.id,
                                //         contractId: data.id,
                                //         sellingMethod: props.auctionId.sellingMethod,
                                //         count: countContract,
                                //         fullAuction: props.auctionId,
                                //         typeView: 1,
                                //     })
                                //     props.setContractDocId(t.id)
                                //     props.setContractDocTitle(t.title)
                                //     props.setContractDocType(t.documentType)
                                //     props.setContractDocDescription(t.description)
                                // }}
                                key={count}
                            />
                    </>
                    )
                }
            )
            return tmp
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        langFlag: state.start.langFlag,
        auctionAwards: state.start.auctionAwards,
        allUserPopUp: state.start.allUserPopUp,
        allUserPopUpUser: state.start.allUserPopUpUser,
        contractAuctionIdGet: state.start.contractAuctionIdGet,
        timberContractPut: state.start.timberContractPut,
        pageNumber: state.start.pageNumber,
        auctionsGetId: state.start.auctionsGetId,
        histery: state.start.histery,
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
        historyDocsContracts: state.start.historyDocsContracts,
    }
}

const mapDispatchToProps = {
    changeAwardRejectPopUpStatus,
    setAwardAllUserPopUp,
    setAwardAllUserPopUpUser,

    changeContractApprowePopUpStatus,

    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,

    histeryAuctions,
    histeryAwards,
    histeryContrasts,
    histeryBids,

    setUniversalError,
    changeLoader,
    contractsAuctionIdGet,
    setContractDatePaid,

    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractAPE)
