import React from 'react';
import styles from './pages.module.css';

import { Link } from 'react-router-dom';

import Catalog from './catalog/catalog.js';
import NotFound from './notFound/notFound.js';

import {connect} from 'react-redux';
import {setAuctionPath} from '../../redux/actions/support.js';
import {setHeaderApiSignAndNotSign} from '../../redux/apiFunctions.js';
import {
    auctionsGet,
    changeLotsFlag,
    auctionsSearch,
    auctionsSearchOld,
    auctionsSearchСount,
    auctionsSearchСountOld,
    changeCurentPage,
    auctionsSearchMin,
    changeStatusAuction,
} from '../../redux/actions/auctions/auctions';
import {changeSecondMenu} from '../../redux/actions.js';

import Pagination from './pagination/pagination.js';
import PaginationTwo from './paginationTwo/paging.js';
import {setLogo, setAuctionCategory} from '../../redux/actions/support.js';
import CreateAuctionLoader from '../createAuction/createAuctionLoader.js';

const Pages = ( props ) => {
    if(props.logo === null){
        props.setLogo(window.location.pathname.length)
    }else{
        if(window.location.pathname.length === 1){
            if(props.logo !== window.location.pathname.length){
                props.setLogo(null)
            }
        }else{
            if(props.logo !== window.location.pathname.length){
                props.setLogo(window.location.pathname.length)
            }
        }
    }

    let data = {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag,
        params: '?offset=' + props.searchCount.curentPage * 10
    }
    let dataS = {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag,
        params: ''
    }
    let dataAuc = {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag,
        params: ''
    }
    let header = null;
    if(props.auctionsFlag === false){
        header = setHeaderApiSignAndNotSign(props)
        /*if(props.token !== null){
            header = {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
        }else{
            header = {
                'Content-Type': 'application/json'
            }
        }*/
        let keyword, authorName, classification, auctionId = null;
        let selectTypeKeyword = null;
        let startDateFrom = null;
        let startDateTo = null;
        if(props.search.selectTypeKeyword !== ''){
            selectTypeKeyword = props.search.selectTypeKeyword
            if(props.search.startDateFrom !== '' && props.search.startDateFrom !== null) startDateFrom = props.search.startDateFrom
            if(props.search.startDateTo !== '' && props.search.startDateTo !== null) startDateTo = props.search.startDateTo
        }
        switch (selectTypeKeyword){
            case '0':
                keyword = props.search.textSting;
                authorName = null;
                classification = null;
                auctionId = null;
                break;
            case '1':
                keyword = null;
                authorName = null;
                classification = null;
                auctionId = props.search.textSting;
                break;
            case '2':
                keyword = props.search.textSting;
                authorName = null;
                classification = null;
                auctionId = null;
                break;
            case '3':
                keyword = null;
                authorName = props.search.textSting;
                classification = null;
                auctionId = null;
                break;
            default:
                keyword = props.search.textSting;
                authorName = null;
                classification = null;
                auctionId = null;
                break;
        }

        let dataSearch = {}
        let tmpUrlSearch = window.location.search.slice(1)
        if(tmpUrlSearch.indexOf('keyword=') !== -1){
            let tmp = tmpUrlSearch.split('keyword=')
            let tmpCategoryType = tmp[1].split('&')
            dataSearch.keyword = tmpCategoryType[0]
        }else{
            dataSearch.keyword = keyword
        }
        if(tmpUrlSearch.indexOf('authorName=') !== -1){
            let tmp = tmpUrlSearch.split('authorName=')
            let tmpCategoryType = tmp[1].split('&')
            dataSearch.authorName = tmpCategoryType[0]
        }else{
            dataSearch.authorName = authorName
        }
        if(tmpUrlSearch.indexOf('classification=') !== -1){
            let tmp = tmpUrlSearch.split('classification=')
            let tmpCategoryType = tmp[1].split('&')
            dataSearch.classification = tmpCategoryType[0]
        }else{
            dataSearch.classification = classification
        }
        if(tmpUrlSearch.indexOf('auctionId=') !== -1){
            let tmp = tmpUrlSearch.split('auctionId=')
            let tmpCategoryType = tmp[1].split('&')
            dataSearch.auctionId = tmpCategoryType[0]
        }else{
            dataSearch.auctionId = auctionId
        }
        if(tmpUrlSearch.indexOf('status=') !== -1){
            let tmp = tmpUrlSearch.split('status=')
            let tmpCategoryType = tmp[1].split('&')
            dataSearch.status = tmpCategoryType[0]
        }else{
            dataSearch.status = props.search.status
        }
        if(tmpUrlSearch.indexOf('itemType=') !== -1){
            let tmp = tmpUrlSearch.split('itemType=')
            let tmpCategoryType = tmp[1].split('&')
            dataSearch.itemType = tmpCategoryType[0]
        }else{
            dataSearch.itemType = props.search.itemType
        }

        if(tmpUrlSearch.indexOf('startDateFrom=') !== -1){
            let tmp = tmpUrlSearch.split('startDateFrom=')
            let tmpCategoryType = tmp[1].split('&')
            dataSearch.startDateFrom = tmpCategoryType[0]
        }else{
            dataSearch.startDateFrom = startDateFrom
        }
        if(tmpUrlSearch.indexOf('startDateTo=') !== -1){
            let tmp = tmpUrlSearch.split('startDateTo=')
            let tmpCategoryType = tmp[1].split('&')
            dataSearch.startDateTo = tmpCategoryType[0]
        }else{
            dataSearch.startDateTo = startDateTo
        }

        switch (true) {
            case (window.location.pathname === '/search'):
                if (tmpUrlSearch.indexOf('category=') !== -1) {
                    let tmp = tmpUrlSearch.split('category=')
                    let tmpCategoryType = tmp[1].split('&')
                    dataSearch.category = tmpCategoryType[0]
                }
                props.auctionsSearch(data, header, dataSearch);
                break;

            case (window.location.pathname.indexOf('/dgf') !== -1):
                props.setAuctionCategory('dgf');
                if (tmpUrlSearch.indexOf('category=') !== -1) {
                    let tmp = tmpUrlSearch.split('category=')
                    let tmpCategoryType = tmp[1].split('&')
                    dataSearch.category = tmpCategoryType[0]
                } else {
                    dataSearch.category = 'dgf'
                }
                props.auctionsSearch(data, header, dataSearch);
                break;

            case (window.location.pathname.indexOf('/subsoil') !== -1):
                props.setAuctionCategory('subsoil-all');
                if (tmpUrlSearch.indexOf('category=') !== -1) {
                    let tmp = tmpUrlSearch.split('category=')
                    let tmpCategoryType = tmp[1].split('&')
                    dataSearch.category = tmpCategoryType[0]
                } else {
                    dataSearch.category = 'subsoil-all'
                }
                props.auctionsSearch(data, header, dataSearch);
                break;

            case (window.location.pathname.indexOf('/railway-cargo') !== -1):
                props.setAuctionCategory('railwayCargo-all');
                if (tmpUrlSearch.indexOf('category=') !== -1) {
                    let tmp = tmpUrlSearch.split('category=')
                    let tmpCategoryType = tmp[1].split('&')
                    dataSearch.category = tmpCategoryType[0]
                } else {
                    dataSearch.category = 'railwayCargo-all'
                }
                props.auctionsSearch(data, header, dataSearch);
                break;

            case (window.location.pathname.indexOf('/timber') !== -1):
                props.setAuctionCategory('timber-english');
                if (tmpUrlSearch.indexOf('category=') !== -1) {
                    let tmp = tmpUrlSearch.split('category=')
                    let tmpCategoryType = tmp[1].split('&')
                    dataSearch.category = tmpCategoryType[0]
                } else {
                    dataSearch.category = 'timber-english'
                }
                props.auctionsSearch(data, header, dataSearch);
                break

            case (window.location.pathname.indexOf('/legitimatePropertyLease') !== -1):
                props.setAuctionCategory('legitimatePropertyLease');
                if (tmpUrlSearch.indexOf('category=') !== -1) {
                    let tmp = tmpUrlSearch.split('category=')
                    let tmpCategoryType = tmp[1].split('&')
                    dataSearch.category = tmpCategoryType[0]
                } else {
                    dataSearch.category = 'legitimatePropertyLease'
                }
                props.auctionsSearch(data, header, dataSearch);
                break

            case (window.location.pathname.indexOf('/legitimatePropertyLease-english') !== -1):
                props.setAuctionCategory('legitimatePropertyLease-english');
                if (tmpUrlSearch.indexOf('category=') !== -1) {
                    let tmp = tmpUrlSearch.split('category=')
                    let tmpCategoryType = tmp[1].split('&')
                    dataSearch.category = tmpCategoryType[0]
                } else {
                    dataSearch.category = 'legitimatePropertyLease-english'
                }
                props.auctionsSearch(data, header, dataSearch);
                break

            case (window.location.pathname.indexOf('/legitimatePropertyLease-dutch') !== -1):
                props.setAuctionCategory('legitimatePropertyLease-dutch');
                if (tmpUrlSearch.indexOf('category=') !== -1) {
                    let tmp = tmpUrlSearch.split('category=')
                    let tmpCategoryType = tmp[1].split('&')
                    dataSearch.category = tmpCategoryType[0]
                } else {
                    dataSearch.category = 'legitimatePropertyLease-dutch'
                }
                props.auctionsSearch(data, header, dataSearch);
                break

            case (window.location.pathname.indexOf('/legitimatePropertyLease-priorityEnglish') !== -1):
                props.setAuctionCategory('legitimatePropertyLease-priorityEnglish');
                if (tmpUrlSearch.indexOf('category=') !== -1) {
                    let tmp = tmpUrlSearch.split('category=')
                    let tmpCategoryType = tmp[1].split('&')
                    dataSearch.category = tmpCategoryType[0]
                } else {
                    dataSearch.category = 'legitimatePropertyLease-priorityEnglish'
                }
                props.auctionsSearch(data, header, dataSearch);
                break

            case (window.location.pathname.indexOf('/landRental') !== -1):
                props.setAuctionCategory('landRental');
                if (tmpUrlSearch.indexOf('category=') !== -1) {
                    let tmp = tmpUrlSearch.split('category=')
                    let tmpCategoryType = tmp[1].split('&')
                    dataSearch.category = tmpCategoryType[0]
                } else {
                    dataSearch.category = 'landRental'
                }
                props.auctionsSearch(data, header, dataSearch);
                break

            case (window.location.pathname.indexOf('/landRental-english') !== -1):
                props.setAuctionCategory('landRental-english');
                if (tmpUrlSearch.indexOf('category=') !== -1) {
                    let tmp = tmpUrlSearch.split('category=')
                    let tmpCategoryType = tmp[1].split('&')
                    dataSearch.category = tmpCategoryType[0]
                } else {
                    dataSearch.category = 'landRental-english'
                }
                props.auctionsSearch(data, header, dataSearch);
                break

            case (window.location.pathname.indexOf('/landSell') !== -1):
                props.setAuctionCategory('landSell');
                if (tmpUrlSearch.indexOf('category=') !== -1) {
                    let tmp = tmpUrlSearch.split('category=')
                    let tmpCategoryType = tmp[1].split('&')
                    dataSearch.category = tmpCategoryType[0]
                } else {
                    dataSearch.category = 'landSell'
                }
                props.auctionsSearch(data, header, dataSearch);
                break

            case (window.location.pathname.indexOf('/landSell-english') !== -1):
                props.setAuctionCategory('landSell-english');
                if (tmpUrlSearch.indexOf('category=') !== -1) {
                    let tmp = tmpUrlSearch.split('category=')
                    let tmpCategoryType = tmp[1].split('&')
                    dataSearch.category = tmpCategoryType[0]
                } else {
                    dataSearch.category = 'landSell-english'
                }
                props.auctionsSearch(data, header, dataSearch);
                break

            case (window.location.pathname.indexOf('/landSell-priorityEnglish') !== -1):
                props.setAuctionCategory('landSell-priorityEnglish');
                if (tmpUrlSearch.indexOf('category=') !== -1) {
                    let tmp = tmpUrlSearch.split('category=')
                    let tmpCategoryType = tmp[1].split('&')
                    dataSearch.category = tmpCategoryType[0]
                } else {
                    dataSearch.category = 'landSell-priorityEnglish'
                }
                props.auctionsSearch(data, header, dataSearch);
                break

            case (window.location.pathname.indexOf('/legacy') !== -1):
                props.setAuctionCategory('legacy');
                dataSearch.isLegacy = true
                if (tmpUrlSearch.indexOf('category=') !== -1) {
                    let tmp = tmpUrlSearch.split('category=')
                    let tmpCategoryType = tmp[1].split('&')
                    dataSearch.category = tmpCategoryType[0]
                } else {
                    dataSearch.category = 'legacy'
                }
                dataSearch.status = null
                props.auctionsSearch(data, header, dataSearch);
                break

            default:
                props.auctionsSearch(data, header, dataSearch);
        }

        props.changeLotsFlag(true);
    }

    let buttonsBlock = null;
    if(window.location.pathname.indexOf('/search') === -1){
        //buttonsBlock = <Link to={`/search/all`}><div
        buttonsBlock = <Link to={`/search`}><div
            className={styles.bottonAllAuctions}
            onClick={
                () => {
                    props.changeSecondMenu(true)
                    props.changeCurentPage(0)
                    props.changeStatusAuction(null)
                    props.changeLotsFlag(false)
                    //props.setAuctionPath(`/search/all/`)
                    props.setAuctionPath(`/search`)
                }
            }
        >ВСІ АУКЦІОНИ</div></Link>
    }else{
        if(props.togetherApi !== true) {
            buttonsBlock = <>
                {/*<Pagination />*/}
                <PaginationTwo />
            </>
        }
    }

    return (
        <>
            <div className={styles.wrap} >
                <div className={styles.contentPages}>
                    <div className={styles.content}>
                        {setPage(props)}
                        {buttonsBlock}
                    </div>
                </div>
            </div>
        </>
    )
}

function setPage(props){
    if(props.auctions !== null){
        if(props.auctions.length > 0){
            return <Catalog />
        }else{
            return <NotFound />
        }
    }else{
        return <CreateAuctionLoader />
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        auctionsFlag: state.start.auctionsGetFlag,
        langFlag: state.start.langFlag,
        search: state.start.search,
        searchCount: state.start.searchCount,
        togetherApi: state.start.togetherApi,
    }
}

const mapDispatchToProps = {
    auctionsGet,
    changeLotsFlag,
    auctionsSearch,
    auctionsSearchOld,
    auctionsSearchСount,
    auctionsSearchСountOld,
    changeCurentPage,
    auctionsSearchMin,
    changeSecondMenu,
    changeStatusAuction,
    setAuctionPath,
    setLogo,
    setAuctionCategory,
    setHeaderApiSignAndNotSign,
}

export default connect(mapStateToProps, mapDispatchToProps)(Pages)