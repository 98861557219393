import React, { useEffect, useState, useRef } from 'react';
import styles from './inputSelect.module.css';
import InputInfo from './inputInfoBlock';


// Typical array for select
//  { value: '0', name: 'Обрати', style: '', selected: true, disabled: true }

const Select = ({ data, options, onChange, dictionary, error, disabled }) => {
    const [dropDown, setDropDown] = useState(false)
    const [option, setOption] = useState(() => {
        for (let key in options) {
            if (options[key].selected) {
                return options[key].name
            }
            return 'Оберіть'
        }
    })
    const [info, setInfo] = useState(`${styles.inPutInfo}`)
    // const [value, setValue] = useState(() => {
    //     if (data.target !== undefined && data.target !== null) { return data.target }
    //     else { return '' }
    // })

    const selectRef = useRef()
    const inputRef = useRef()

    useEffect(() => {
        if(error) {
            setInfo(`${styles.inPutInfo} ${styles.attent}`);
        } else {
            setInfo(`${styles.inPutInfo}`);
        }
    });

    useEffect(() => {
        if (data !== null && data.target !== null) {
            for (let key in options) {
                if (options[key].value === data.target) {
                    setOption(options[key].name)
                }
            }
        } else {
            setOption('Оберіть')
        }
    }, [data.target])

    useEffect(() => {
        if (dropDown) {
            let position = selectRef.current.getBoundingClientRect()
                if (selectRef.current.getBoundingClientRect().bottom > window.innerHeight) {
                    selectRef.current.style.height = `${window.innerHeight - position.top - 20}px`
            }
            selectRef.current.style.width = `${inputRef.current.getBoundingClientRect().width}px`
        }
    }, [dropDown])

    function selectToggle() {
        if (disabled) return
        if (!dropDown) {
            setDropDown(!dropDown)
        } else {
            setDropDown(!dropDown)
        }
    }

    function selection(e){
        if (dictionary) {
            setOption(e.description.uk_UA)
            selectToggle()
            // setComplete(`${styles.inPutSelect} ${styles.complete}`)
            // setValue(e.name)
            if (onChange) {
                onChange(e.name)
            }
        } else {
            setOption(e.name)
            selectToggle()
            // setComplete(`${styles.inPutSelect} ${styles.complete}`)
            // setValue(e.id)
            onChange(e.value)
        }
    }

    function choosen(val) {
        if (val.disabled) return styles.disabled
        if (val.style === 'bold') return  `${styles.somePart} ${styles.bold}`
        if (val.styleFontSize) return  `${styles.somePart} ${styles.fontSize}`
        if (val.value === data.target) {
            return `${styles.somePart} ${styles.active}`
        }
        else return styles.somePart
    }

    function listItem(item) {
        return <div className={choosen(item)}
                            onClick={() => selection(item)}
                            value={item.value}
                            key={item.value}>
                            {item.name}
                        </div>
    }

    function setList(list) {
        let selectList = []

        if (list !== null && list !== undefined) {
            if (!!dictionary) {
                selectList = list.map((item) => <div className={choosen(item.name)}
                    onClick={() => selection(item)}
                    value={item.name} key={item.name} >
                    {item.description.uk_UA}
                </div>)
            } else {
                selectList = list.map(item => listItem(item) )
            }
        }
        return selectList
    }
    
    return (
        <div className={styles.selectWrap}>
            <div className={info}>
                <div className={styles.inPutWrap}>
                    <div className={disabled ? styles.disabledSelect : styles.inPutSelect}
                        ref={inputRef}
                        onClick={selectToggle}
                        title={option}
                    >
                        <div className={option !== 'Оберіть' ? styles.textValue : styles.dafault} title={option}>
                            {data.target !== null || data.target !== undefined ? option : 'Оберіть' }
                        </div>
                    </div>
                    {data.label
                        ? <div className={styles.label}>
                            {!!data.required ? data.label + ' *': data.label}
                        </div>
                        : null}
                    { dropDown
                        ? <>
                            <div className={styles.wrapper} onClick={selectToggle}></div>
                            <div className={styles.selectPopdown} ref={selectRef}>
                                <div className={styles.listContent}>
                                    {setList(options)}
                                </div>
                            </div>
                        </>
                        : null
                    }
                    {!!data.info ? 
                    <InputInfo data={data.info} />
                     : null }
                </div>
                
            </div>
            {error ? <p className={styles.descript}>{error}</p> : data.descr ? <p className={styles.descript}>{data.descr}</p> : ''}
        </div>
    )
}

export default Select