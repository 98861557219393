import React from 'react';
import styles from './filterBlock.module.css';

import CategoryMenu from '../categoryMenu/categoryMenu.js';
//import TypeSubCategory from '../typeSubCategory/typeSubCayegory.js';
import MainFind from '../mainFind/mainFind.js';
//import AdvancedFind from '../advancedFind/advancedFind.js';

export default function Find (){
    return (
        <div className={styles.auction_main}>
            <div className={styles.auction_main_wrap}>
                <MainFind />
                <CategoryMenu />
                {/*<TypeSubCategory />*/}
                {/*<AdvancedFind />*/}
            </div>
        </div>
    )
}