import { 
    //Contracts
    CONTRACT_GET,
    CONTRACT_AUCTION_ID_GET,
    CONTRACT_POST,
    CONTRACT_PUT,
    CONTRACT_DELETE,
    SET_POPUP_CONTRACT_APPROWE,
    SET_POPUP_CONTRACT_ERROR,

    SET_CONTRACT_TITLE,
    SET_CONTRACT_DESCRIPTION,
    SET_CONTRACT_CONTRACT_NUMBER,
    SET_CONTRACT_DATE_FROM,
    SET_CONTRACT_DATE_TILL,
    SET_CONTRACT_START_DATE,
    SET_CONTRACT_END_DATE,
    SET_CONTRACT_CURRENCY,
    SET_CONTRACT_AMOUNT,
    SET_CONTRACT_TOTAL_AMOUNT,
    SET_CONTRACT_DATE_SIGNED,

    POPUP_CONTRACT_DOC_ID,
    POPUP_CONTRACT_DOC_TITLE,
    POPUP_CONTRACT_DOC_TYPE,
    POPUP_CONTRACT_DOC_DESCRIPTION,
    SET_CONTRACT_OFF,
    SET_CONTRACT_DATE_PAID,
    SAVE_HISTORY_DOCS_CONTRACT,

    SET_CONTRACT_TIME_ADDED_DATE_FROM,
    SET_CONTRACT_TIME_ADDED_DATE_TILL
} from "../../types";

import {getAPI, postAPI, putAPI, deleteAPI} from '../crud';
import {setLocal} from '../support';

//Contracts
export function contractPost(_url, token, datas){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/contract`,
        token: token,
        actionType: CONTRACT_POST,
        data: datas,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return postAPI(data);
}

export function contractGet(_url, token){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/contract/${_url.id}`,
        token: token,
        actionType: CONTRACT_GET,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function contractPut(_url, token, datas){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/contract/${_url.id}`,
        token: token,
        actionType: CONTRACT_PUT,
        data: datas,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return putAPI(data);
}

export function contractDelete(_url, token){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/contract/${_url.id}`,
        token: token,
        actionType: CONTRACT_DELETE,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return deleteAPI(data);
}

export function contractsAuctionIdGet(_url, header, id){
    const data = {
        url: `${_url.domen}/api/v1/uk/auction/${id}/contracts?limit=20&offset=0`,
        actionType: CONTRACT_AUCTION_ID_GET,
        header: header
    }
    return getAPI(data);
}


export function changeContractApprowePopUpStatus(value) {
    return {
        type: SET_POPUP_CONTRACT_APPROWE,
        payload: value
    }
}

export function changeContractErrorPopUpStatus(value) {
    return {
        type: SET_POPUP_CONTRACT_ERROR,
        payload: value
    }
}

export function setContractTitle(value) {
    return {
        type: SET_CONTRACT_TITLE,
        payload: value
    }
}

export function setContractDescription(value) {
    return {
        type: SET_CONTRACT_DESCRIPTION,
        payload: value
    }
}

export function setContractDocDocumentOf(value) {
    return {
        type: SET_CONTRACT_OFF,
        payload: value
    }
}

export function setContractContractNumber(value) {
    return {
        type: SET_CONTRACT_CONTRACT_NUMBER,
        payload: value
    }
}

export function setContractDateFrom(value) {
    return {
        type: SET_CONTRACT_DATE_FROM,
        payload: value
    }
}

export function setContractContractTimeAddedDatePaid(value) {
    return {
        type: SET_CONTRACT_DATE_PAID,
        payload: value
    }
}

export function setContractDateTill(value) {
    return {
        type: SET_CONTRACT_DATE_TILL,
        payload: value
    }
}

export function setContractStartDate(value) {
    return {
        type: SET_CONTRACT_START_DATE,
        payload: value
    }
}

export function setContractEndDate(value) {
    return {
        type: SET_CONTRACT_END_DATE,
        payload: value
    }
}

export function setContractCurrency(value) {
    return {
        type: SET_CONTRACT_CURRENCY,
        payload: value
    }
}

export function setContractAmount(value) {
    return {
        type: SET_CONTRACT_AMOUNT,
        payload: value
    }
}

export function setContractTotalAmount(value) {
    return {
        type: SET_CONTRACT_TOTAL_AMOUNT,
        payload: value
    }
}

export function setContractDateSigned(value) {
    return {
        type: SET_CONTRACT_DATE_SIGNED,
        payload: value
    }
}

export function setContractContractTimeAddedDateFrom(value) {
    return {
        type: SET_CONTRACT_TIME_ADDED_DATE_FROM,
        payload: value
    }
}
export function setContractContractTimeAddedDateTill(value) {
    return {
        type: SET_CONTRACT_TIME_ADDED_DATE_TILL,
        payload: value
    }
}

export function setContractDocId(value) {
    return {
        type: POPUP_CONTRACT_DOC_ID,
        payload: value
    }
}

export function setContractDocTitle(value) {
    return {
        type: POPUP_CONTRACT_DOC_TITLE,
        payload: value
    }
}

export function setContractDocType(value) {
    return {
        type: POPUP_CONTRACT_DOC_TYPE,
        payload: value
    }
}

export function setContractDocDescription(value) {
    return {
        type: POPUP_CONTRACT_DOC_DESCRIPTION,
        payload: value
    }
}

export function setContractDatePaid(value) {
    return {
        type: SET_CONTRACT_DATE_PAID,
        payload: value
    }
}

export function saveHistoryDocsContracts(value) {
    return {
        type: SAVE_HISTORY_DOCS_CONTRACT,
        payload: value
    }
}