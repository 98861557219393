import { 
    //Questions
    QUESTION_GET,
    QUESTIONS_AUCTION_ID_GET,
    QUESTION_POST,
    QUESTION_PUT,
    QUESTION_DELETE,

    ANSWER_TITLE,
    ANSWER_DISCRIPTION
} from "../../types";

import {getAPI, postAPI, putAPI, deleteAPI} from '../crud';
import {setLocal} from '../support';

//Questions
export function questionPost(_url, token, datas){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/auction/${_url.id}/question`,
        token: token,
        actionType: QUESTION_POST,
        data: datas,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return postAPI(data);
}

export function questionGet(_url, token){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/question/${_url.id}`,
        token: token,
        actionType: QUESTION_GET,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return getAPI(data);
}

export function questionPut(_url, token, datas){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/question/${_url.id}`,
        token: token,
        actionType: QUESTION_PUT,
        data: datas,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return putAPI(data);
}

export function questionDelete(_url, token){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/question/${_url.id}`,
        token: token,
        actionType: QUESTION_DELETE,
        header: {
            'Authorization': token,
            'Content-Type': 'application/json'
        }
    }
    return deleteAPI(data);
}

export function questionsGetId(_url, header){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/auction/${_url.id}/questions${_url.params}`,
        actionType: QUESTIONS_AUCTION_ID_GET,
        header: header
    }
    return getAPI(data);
}

export function answerTitle(value) {
    return {
        type: ANSWER_TITLE,
        payload: value
    }
}

export function answerDiscription(value) {
    return {
        type: ANSWER_DISCRIPTION,
        payload: value
    }
}