import {
    SET_DICTIONARIES_OWNERSHIPTYPE,
    SET_DICTIONARIES_ENCUMBRANCES,
    SET_DICTIONARIES_POWERSUPPLYCLASS,
    SET_DICTIONARIES_FLOORTYPE,
    SET_DICTIONARIES_CONSTRUCTIONTECHNOLOGY,
    SET_DICTIONARIES_FUELTYPE,
    SET_DICTIONARIES_TRANSMISSIONTYPE,
    SET_DICTIONARIES_ELIGIBILITYCRITERIA,
} from "./types";

export function setDictionariesOwnershipType(value) {
    return {
        type: SET_DICTIONARIES_OWNERSHIPTYPE,
        payload: value
    }
}

export function setDictionariesEncumbrances(value) {
    return {
        type: SET_DICTIONARIES_ENCUMBRANCES,
        payload: value
    }
}

export function setDictionariesPowerSupplyClass(value) {
    return {
        type: SET_DICTIONARIES_POWERSUPPLYCLASS,
        payload: value
    }
}

export function setDictionariesFloorType(value) {
    return {
        type: SET_DICTIONARIES_FLOORTYPE,
        payload: value
    }
}

export function setDictionariesConstructionTechnology(value) {
    return {
        type: SET_DICTIONARIES_CONSTRUCTIONTECHNOLOGY,
        payload: value
    }
}

export function setDictionariesFuelType(value) {
    return {
        type: SET_DICTIONARIES_FUELTYPE,
        payload: value
    }
}

export function setDictionariesTransmissionType(value) {
    return {
        type: SET_DICTIONARIES_TRANSMISSIONTYPE,
        payload: value
    }
}

export function setDictionariesEligibilityCriteria(value) {
    return {
        type: SET_DICTIONARIES_ELIGIBILITYCRITERIA,
        payload: value
    }
}