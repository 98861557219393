import React from 'react';
import styles from './pagination.module.css';

//import { Link } from 'react-router-dom';

import {connect} from 'react-redux';
import {setAuctionPath} from '../../../redux/actions/support.js';
import {
    auctionsGet,
    changeLotsFlag,
    auctionsSearch,
    auctionsSearchOld,
    auctionsSearchСount,
    auctionsSearchСountOld,
    changeCurentPage,
    auctionsSearchMin,
    changeStatusAuction,
} from '../../../redux/actions/auctions/auctions';
import {changeSecondMenu} from '../../../redux/actions.js';

import PaginationButton from './paginationButton/paginationButton.js';

const Pagination = ( props ) => {
    let tmp = null;
    let pageNum = 0;

    if(props.searchCount.count !== null && props.searchCount.countOld !== null){
        if(props.searchCount.count > props.searchCount.countOld){
            pageNum = Math.trunc(props.searchCount.count / 10)
            if(props.searchCount.count % 10 === 0){
                pageNum -= 1
            }
        }else if(props.searchCount.count < props.searchCount.countOld){
            pageNum = Math.trunc(props.searchCount.countOld / 10)
            if(props.searchCount.countOld % 10 === 0){
                pageNum -= 1
            }
        }else{
            pageNum = Math.trunc(props.searchCount.count / 10)
            if(props.searchCount.count % 10 === 0){
                pageNum -= 1
            }
        }        
    }else if(props.searchCount.count !== null && props.searchCount.countOld === null){
        pageNum = Math.trunc(props.searchCount.count / 10)
        if(props.searchCount.count % 10 === 0){
            pageNum -= 1
        }
    }else if(props.searchCount.count === null && props.searchCount.countOld !== null){
        pageNum = Math.trunc(props.searchCount.countOld / 10)
        if(props.searchCount.countOld % 10 === 0){
            pageNum -= 1
        }
    }



    let arrPage = [];
    let lastPage = null;
    let firstPage = null;
    let active = null;
    let tmpPage = 5;
    if(props.searchCount.curentPage === 0){
        if(pageNum < 5){
            tmpPage = pageNum + 1;
        }
        for(let i = 0; i < tmpPage; i++){
            active = changeActiveStyle(props, i, styles);
            arrPage[i] = <PaginationButton 
                key={i}
                styleData = {active}
                pageGet = {i}
                pageChange = {i}
            />
        }
        if(pageNum > 5){
            lastPage = <PaginationButton
                key={pageNum}
                styleData = {styles.pagination_lastPage}
                pageGet = {pageNum}
                pageChange = {pageNum}
            />
        }
    }else if(props.searchCount.curentPage === 1){
        if(pageNum < 5){
            tmpPage = pageNum + 1;
        }
        for(let i = 0; i < tmpPage; i++){
            active = changeActiveStyle(props, i, styles);
            arrPage[i] = <PaginationButton 
                key={i}
                styleData = {active}
                pageGet = {i}
                pageChange = {i}
            />
        }
        if(pageNum > 5){
            lastPage = <PaginationButton
                key={pageNum}
                styleData = {styles.pagination_lastPage}
                pageGet = {pageNum}
                pageChange = {pageNum}
            />
        }
    }else if(props.searchCount.curentPage === 2){
        if(pageNum < 5){
            tmpPage = pageNum + 1;
        }
        for(let i = 0; i < tmpPage; i++){
            active = changeActiveStyle(props, i, styles);
            arrPage[i] = <PaginationButton 
                key={i}
                styleData = {active}
                pageGet = {i}
                pageChange = {i}
            />
        }
        if(pageNum > 5){
            lastPage = <PaginationButton
                key={pageNum}
                styleData = {styles.pagination_lastPage}
                pageGet = {pageNum}
                pageChange = {pageNum}
            />
        }
    }else if(props.searchCount.curentPage === pageNum){
        for(let i = pageNum-4; i <= pageNum; i++){
            active = changeActiveStyle(props, i, styles);
            arrPage[i] = <PaginationButton 
                key={i}
                styleData = {active}
                pageGet = {i}
                pageChange = {i}
            />
        }
        firstPage = <PaginationButton
            key={0}
            styleData = {styles.pagination_firstPage}
            pageGet = {0}
            pageChange = {0}
        />
    }else if(props.searchCount.curentPage === pageNum-1){
        for(let i = pageNum-4; i <= pageNum; i++){
            active = changeActiveStyle(props, i, styles);
            arrPage[i] = <PaginationButton 
                key={i}
                styleData = {active}
                pageGet = {i}
                pageChange = {i}
            />
        }
        firstPage = <PaginationButton
            key={0}
            styleData = {styles.pagination_firstPage}
            pageGet = {0}
            pageChange = {0}
        />
    }else if(props.searchCount.curentPage === pageNum-2){
        for(let i = pageNum-4; i <= pageNum; i++){
            active = changeActiveStyle(props, i, styles);
            arrPage[i] = <PaginationButton 
                key={i}
                styleData = {active}
                pageGet = {i}
                pageChange = {i}
            />
        }
        firstPage = <PaginationButton
            key={0}
            styleData = {styles.pagination_firstPage}
            pageGet = {0}
            pageChange = {0}
        />
    }else{
        for(let i = props.searchCount.curentPage-2; i < props.searchCount.curentPage+3; i++){
            active = changeActiveStyle(props, i, styles);
            arrPage[i] = <PaginationButton
                key={i}
                styleData = {active}
                pageGet = {i}
                pageChange = {i}
            />
        }
        lastPage = <PaginationButton
            key={pageNum}
            styleData = {styles.pagination_lastPage}
            pageGet = {pageNum}
            pageChange = {pageNum}
        />
        firstPage = <PaginationButton
            key={0}
            styleData = {styles.pagination_firstPage}
            pageGet = {0}
            pageChange = {0}
        />
    }
    
    tmp = (
        <div className={styles.pagination_wrap}>
            {firstPage}
            {arrPage}
            {lastPage}
        </div>
    )
    return tmp
}

function changeActiveStyle(props, i, styles){
    let active = null;
    if(i === props.searchCount.curentPage){
        active = styles.activPG
    }else{
        active = null;
    }
    return active
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        auctionsFlag: state.start.auctionsGetFlag,
        langFlag: state.start.langFlag,
        search: state.start.search,
        searchCount: state.start.searchCount,
    }
}

const mapDispatchToProps = {
    auctionsGet,
    changeLotsFlag,
    auctionsSearch,
    auctionsSearchOld,
    auctionsSearchСount,
    auctionsSearchСountOld,
    changeCurentPage,
    auctionsSearchMin,
    changeSecondMenu,
    changeStatusAuction,
    setAuctionPath,
}

export default connect(mapStateToProps, mapDispatchToProps)(Pagination)