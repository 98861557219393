import React,{useState} from 'react';
import styles from './newAward.module.css';

import {connect} from 'react-redux';

import {auctionsGetAwards, auctionsGetAwardsId, changeAwardRejectPopUpStatus, changeAwardApprowePopUpStatus, changeAwardView} from '../../../redux/actions/awards/awards.js';

import { setAwardAllUserPopUp, setAwardAllUserPopUpUser } from '../../../redux/actions.js';
import { isTIE, isSUE, isSUD, isRCE, isRCD, isGFE, isGFD, isLLE, isLLD,
    isLLP, isLRE, isLSE, isLSP, isRMA, isBSE, isBSD, isALE, isCSE, isCSD,
    isBRE, isBRD, isBRW, isNLE, isNLD, isSPE, isSPD, isLPE } from '../../../redux/aucTypes';

// import AwardDataPopUp from './popUpDataAward/popUpDataAward.js';
// import AwardDataPopUpUser from './popUpDataAward/popUpDataAwardUser.js';

import {
    changeContractApprowePopUpStatus,
    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,
    setContractDatePaid,
    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
    setContractDocDocumentOf,
} from '../../../redux/actions/contracts/contracts.js';

import { getUpdateDataAuction, getUpdateDataBid } from '../../product/updateData';
import { saveArrBids } from '../../../redux/allAuctionBids';
import { setDataAddress } from '../../../redux/createAddress';

import TextFull from '../../elements/viewData/textFull'
import TextHalf from '../../elements/viewData/textHalf'
import PopDown from '../../elements/viewData/popdownBlock/popDownBlock'
// import ContractDocItem from './awardComponents/docItems/awardDocItem';
import AddDocPopup from '../../documents/addDocPopup/addDocPopup';
import DocItem from '../../documents/docItem';

import {
    histeryAuctions,
    histeryAwards,
    histeryContrasts,
    histeryBids,
} from '../../../redux/histery.js';
import {setUniversalError, changeLoader} from '../../../redux/actions.js';
import {contractsAuctionIdGet} from '../../../redux/actions/contracts/contracts.js';

import {modifiDateString} from '../../../redux/modifiDate'
import { CONTACTS_TITLE_BOTTOM } from '../../../model/lang/topMenu';
import { checkProp } from '../../../redux/check';

const Contract = (props) => {
    const [slim, setSlim] = useState({ state: false, text: 'Розгорнути' })
    const [popAddDoc, setPopAddDoc] = useState(false)
    
    function Slimmer() {
        slim.state ? setSlim({ state: false, text: 'Розгорнути'}) : setSlim({ state: true, text: 'Згорнути'})
    }

    if(props.auctionId !== null){
        if(props.auctionId === undefined || props.auctionId === 'undefined'){

        }else{
            if(
                props.auctionId.status === 'cancelled'
            ){
                if(props.auctionId.cancellations !== null){
                    return (<>
                                
                                <div className={styles.contractBlock}>
                                    <div className={styles.contractBlock_title}>Скасування Аукціону</div>
                                    <div className={styles.slimmer} onClick={() => Slimmer()}>{slim.text}</div>
                                    {slim.state ?
                                        <div className={styles.contractBlockContent}>
                                            {viewAwardArr(props)}
                                        </div> : null
                                    }
                                </div>
                            </>
                    )
                }
            }
        }
    }
    return null
}

function viewAwardArr(props){
    let tmp = null;
    if(props.auctionId !== null){
        if(props.auctionId.cancellations.length > 0){
            tmp = props.auctionId.cancellations.map(
                (i, count) => {
                    return (<>
                        <div className={styles.contractBlock}>
                            {viewTitle(i, props)}
                            {viewData(i)}
                            {viewContractDocuments(props, i, count)}
                        </div>
                        </>
                    )
                }
            )
        }
    }
    return tmp
}


function viewTitle(i, props){
    if(i.reason !== null && i.reason !== ''){
        return (
            <TextFull
                title='Причина скасування Аукціону'
                data={reasonDictionary(i.reason.uk_UA, props)}
            />
        )
    }
    return null
}

function reasonDictionary(data, props){
    if(isSUE(props.auctionId.sellingMethod) || isSUD(props.auctionId.sellingMethod)){
        switch(data){
            case "1": return "Наявність рішення суду, яке набрало законної сили"
            case "2": return "Визнання в установленому законодавством порядку протиправними дій та/або скасування рішень стосовно пропозицій щодо визначення переліку ділянок надр, дозволи на користування якими виставляються на аукціон."
            case "3": return "Визнання в установленому законодавством порядку протиправними дій та/або скасування рішень Міндовкілля стосовно погоджень щодо визначення переліку ділянок надр, дозволи на користування якими виставляються на аукціон для надання спеціального дозволу на геологічне вивчення, у тому числі дослідно-промислову розробку, корисних копалин з подальшим видобуванням корисних копалин (промислову розробку родовищ)."
            case "4": return "Зміна виду користування надрами, визначеного законодавчими актами."
            default: return data
        }
    }else if(isLLE(props.auctionId.sellingMethod) || isLLD(props.auctionId.sellingMethod) || isLLP(props.auctionId.sellingMethod)){
        switch(data){
            case "1": return "Скасування рішення про включення об’єкта до Переліку першого типу"
            case "2": return "Прийняття рішення про виключення об’єкта з Переліку першого типу"
            case "3": return "Зміна рішення про включення об’єкта до Переліку першого типу"
            case "4": return "Порушення порядку підготовки аукціону"
            case "5": return "Виникнення технічного збою, підтвердженого адміністратором електронної торгової системи"
            case "6": return "Рішення суду або інші підстави"
            default: return data
        }
    }else if(isSPE(props.auctionId.sellingMethod) || isSPD(props.auctionId.sellingMethod)){
        switch(data){
            case "1": return "Включення об’єкта приватизації до переліку об’єктів права державної власності, що не підлягають приватизації"
            case "2": return "Ухвалення місцевою радою рішення про скасування попереднього рішення про приватизацію відповідного об’єкта комунальної власності"
            case "3": return "Порушено порядок підготовки до аукціону"
            case "4": return "Виникнення технічного збою, підтвердженого адміністратором електронної торгової системи"
            case "5": return "Рішення суду або інші підстави"
            case "6": return "Рішення суду"
            case "7": return "Інша підстава за рішенням органу приватизації"
            default: return data
        }
    }else if(isLPE(props.auctionId.sellingMethod)){
        switch(data){
            case "1": return "Включення об’єкта приватизації до переліку об’єктів права державної власності, що не підлягають приватизації"
            case "2": return "Ухвалення місцевою радою рішення про скасування попереднього рішення про приватизацію відповідного об’єкта комунальної власності"
            case "3": return "Порушено порядок підготовки до аукціону"
            case "4": return "Виникнення технічного збою, підтвердженого адміністратором електронної торгової системи"
            case "5": return "Рішення суду або інші підстави"
            case "6": return "Рішення суду"
            case "7": return "Інша підстава за рішенням органу приватизації"
            default: return data
        }
    }
    return data
}

function viewData(data){
    if(data.hasOwnProperty('datePublished')){
        if(data.datePublished !== null){
            let tmp = `${modifiDateString(data.datePublished, 3)}`
            return (
                <TextHalf
                    title='Дата скасування'
                    data={tmp}
                />
            )
        }
    }
    return null
}

function viewBiddersName(i){
    if(i.buyers !== null){
        if(i.buyers.length > 0){
            if(i.buyers[0].name !== null && i.buyers[0].name !== ''){
                return (
                    <TextHalf
                        title='Назва учасника'
                        data={i.buyers[0].name.uk_UA}
                    />
                )
            }
        }
    }
    return null
}

function setAwardDocTypes(props, i) {
    return [{ value: 'cancellationDetails', name: 'Причини скасування', main: null }]
}

function viewBidValue(props, i){
    if(props.auctionId !== null){
        if(props.auctionId.bids !== null){
            if(props.auctionId.bids.length > 0){
                for(let tmp = 0; tmp < props.auctionId.bids.length; tmp++){
                    if(props.auctionId.bids[tmp].value !== null){
                        if(props.auctionId.bids[tmp].id === i.bidId){
                            return (
                                <TextHalf
                                    title='Розмір цінової пропозиції'
                                    data={`${props.auctionId.bids[tmp].value.amount} грн.`}
                                />
                            )
                        }
                    }
                }
            }
        }
    }
    return null
}

function initialValueAmount(props, i){
    if(props.auctionId !== null){
        if(props.auctionId.bids !== null){
            if(props.auctionId.bids.length > 0){
                for(let tmp = 0; tmp < props.auctionId.bids.length; tmp++){
                    if(props.auctionId.bids[tmp].value !== null){
                        if(props.auctionId.bids[tmp].id === i.bidId){
                            return props.auctionId.bids[tmp].initialValueAmount ? <TextHalf
                                    title='Розмір закритої цінової пропозиції'
                                    data={`${props.auctionId.bids[tmp].initialValueAmount} грн.`}
                                /> : null
                        }
                    }
                }
            }
        }
    }
    return null
}

function viewTerminationReason(props, data){
    if(data.terminationReason !== null && data.terminationReason !== '' && data.terminationReason !== 'null'){
        return (
            <TextHalf
                title='Причина відмови'
                data={viewTypeTerminationReason(props, data.terminationReason)}
            />
        )
    }
    return null
}

function viewTypeTerminationReason(props, data){
    if(props.auctionId !== null){
        switch(true){
            case isSUE(props.auctionId.sellingMethod):
            case isSUD(props.auctionId.sellingMethod):
                switch(data){
                    case '1': return "Непідписання протоколу"
                    case '2': return "Непідписання договору"
                    case '3': return "Поширюються обмежувальні заходи (санкції)"
                    case '4': return "Непроведення реєстрації представництва"
                    case '5': return "Невнесення сплати в установлений строк"
                    case '6': return "Неотримання висновку з оцінки впливу на довкілля"
                    case '7': return "Отримання висновку з оцінки впливу на довкілля з обґрунтуванням недопустимості провадження планованої діяльності"
                    default: return data
                }
            case isLLE(props.auctionId.sellingMethod):
            case isLLD(props.auctionId.sellingMethod):
                switch(data){
                    case '1': return 'Непідписання протоколу'
                    case '2': return `Непідписання договору`
                    case '3': return 'Невідповідність вимогам статті 4 ЗУ'
                    case '4': return `Неподання обов\'язкових документів або відомостей`
                    case '5': return 'Подання неправдивих відомостей'
                    case '6': return 'Судові рішення та інші підстави'
                    default: return data
                }
            case isGFE(props.auctionId.sellingMethod):
            case isGFD(props.auctionId.sellingMethod):
                switch(data){
                    case '1': return 'Відмовився від підписання договору'
                    case '2': return `Переможець аукціону є боржником та/або поручителем за кредитними договорами та договорами забезпечення виконання зобов\'язань`
                    case '3': return 'Повна оплата коштів за лот не здійснена в строк'
                    default: return data
                }
            case isLRE(props.auctionId.sellingMethod):
            case isLSE(props.auctionId.sellingMethod):
            case isLSP(props.auctionId.sellingMethod):
                switch(data){
                    case "1": return "Відмовився від підписання протоколу"
                    case "2": return "Відмовився від укладання договору"
                    case "3": return "Не надав обов’язкові документи або відомості /надав неправдиві відомості"
                    case "4": return "Не сплатив належну суму за придбаний лот та/або суму витрат на підготовку лота до продажу"
                    case "5": return "Не відповідає встановленим Кодексом вимогам"
                    case "6": return "Не отримав необхідне погодження ВРУ або КМУ"
                    default: return data
                }
            case isBSE(props.auctionId.sellingMethod):
            case isBSD(props.auctionId.sellingMethod):
            case isALE(props.auctionId.sellingMethod):
            case isCSE(props.auctionId.sellingMethod):
            case isCSD(props.auctionId.sellingMethod):
            case isBRE(props.auctionId.sellingMethod):
            case isBRD(props.auctionId.sellingMethod):
            case isBRW(props.auctionId.sellingMethod):
                switch(data){
                    case "1": return "Відмова або непідписання протоколу"
                    case "2": return "Відмова або непідписання договору"
                    case "3": return "Відсутні обов’язкові документи"
                    case "4": return "Невнесення передоплати або оплати за договором"
                    case "5": return "Участь в аукціоні з більше, ніж 1 майданчика"
                    default: return data
                }
            case isNLE(props.auctionId.sellingMethod):
            case isNLD(props.auctionId.sellingMethod):
                switch(data){
                    case "1": return "Відмовився від підписання договору/протоколу"
                    case "2": return "Переможець аукціону є боржником та/або поручителем за кредитними договорами та договорами забезпечення виконання зобов'язань"
                    case "3": return "Свідомо надав неправдиву інформацію"
                    case "4": return "Повна оплата коштів за лот не здійснена в строк/відмовився від оплати"
                    case "5": return "Переможець аукціону брав участь в аукціонах з кількох майданчиків"
                    case "6": return "Інше"
                    default: return data
                }
            default:
                switch(data){
                    case '1': return 'Відмовився від підписання протоколу'
                    case '2': return 'Невідповідність встановленим вимогам'
                    case '3': return 'Відмовився від підписання договору купівлі-продажу лота'
                    case '4': return 'Не підписав договір купівлі-продажу лота в установлений строк'
                    case '5': return 'Вчасно не здійснив передоплату за договором купівлі-продажу лота'
                    default: return data
                }
        }
    }
}

function viewStatus(props, data){
    if(data.status !== null && data.status !== '' && data.status !== 'null'){
        return (
            <TextHalf
                title='Статус'
                data={viewStatusAwards(props, data.status)}
            />
        )
    }
    return null
}

function viewStatusAwards(props, status){
    switch(status){
        case 'pending':
            return 'Очікується протокол'
        case 'pending_waiting':
            return 'Очікується рішення'
        case 'active':
            if(props.auctionId.status === "complete"){
                return 'Переможець. Аукціон завершено. Договір підписано'
            }else{
                return 'Переможець. Очікується договір'
            }
        case 'unsuccessful':
            return 'Дискваліфіковано'
        case 'cancelled':
            return 'Учасник не став переможцем'
    }
    return null
}

function viewAddress(data){
    if(createAddress(data) !== null){
        return (
            <TextHalf
                title='Юридична адреса'
                data={createAddress(data)}
            />
        )
    }
    return null
}

function createAddress(i){
    let tmp = null
    if(i.buyers !== null){
        if(i.buyers !== null){
            if(i.buyers.length > 0){
                tmp = i.buyers[0].address.countryName.uk_UA + ', ' + i.buyers[0].address.postalCode + ', ' + i.buyers[0].address.locality.uk_UA + ', ' + i.buyers[0].address.region.uk_UA + ', ' + i.buyers[0].address.streetAddress.uk_UA
            }
        }
    }
    return tmp
}

function dataViewVerificationPeriod(data){
    if(data.hasOwnProperty('verificationPeriod')){
        if(data.verificationPeriod !== null){
            let tmp = `${modifiDateString(data.verificationPeriod.startDate, 3)} - ${modifiDateString(data.verificationPeriod.endDate, 3)}`
            return (
                <TextHalf
                    title='Термін на завантаження та підтвердження протоколу'
                    data={tmp}
                />
            )
        }
    }
    return null
}

function dataViewSigningPeriod(data){
    if(data.hasOwnProperty('signingPeriod')){
        if(data.signingPeriod !== null){
            let tmp = `${modifiDateString(data.signingPeriod.startDate, 3)} - ${modifiDateString(data.signingPeriod.endDate, 3)}`
            return (
                <TextHalf
                    title='Термін на завантаження та підтвердження договору'
                    data={tmp}
                />
            )
        }
    }
    return null
}

function viewContractDocuments(props, data, count, setPopAddDoc){
    if(data !== null){
        if (data.documents !== null) {
            let TmpBidDocs = viewDocAwardBid(props, data)
            let tmpArr =  TmpBidDocs !== null ? TmpBidDocs.length : null
            if((data.documents !== null && data.documents.length > 0) || (TmpBidDocs !== null && TmpBidDocs.length > 0)){// || viewDocAwardBidCount(props, data) !== null){
                let docOrg = null
                let docUch = null
                if(data.documents.length !== 0) docOrg = <div className={styles.blockHeader}>Документи організатора:</div>
                if(viewDocAwardBid(props, data) !== null && viewDocAwardBid(props, data).length !== 0) docUch = <div className={styles.blockHeader}>Документи учасника:</div>
                return <PopDown
                    title={`Додані документи (${data.documents.length + tmpArr})`}
                    content={
                        <>
                            {viewDocContract(data, count, props, setPopAddDoc)}
                        </>
                    }
                />
            }
        }
    }
    return null
}

function viewDocContract(data, countContract, props, setPopAddDoc) { // документы организатора
    
    function dataECPMapper(data, t) {
        let tmp = data
            .filter(
            (item) => {
                return item.documentType === 'digitalSignature' && item.relatedDocument === t.id
            })
        if (tmp.length === 0) return null
        return tmp
    }
    
    if(data !== null){
        if(data === undefined || data === 'undefined'){
        }else{
            let tmp = data.documents
            .filter(
                (t) => {
                    return t.documentType !== 'digitalSignature'
                }
            )
            .map(
                (t, count) => {

                        const getHistoryURL = (props, data) => {
                            if (t.documentOf === 'award') {
                                    return `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auctionId.id}/awards/${data.id}/documents`
                                }
                                if (t.documentOf === 'bid') {
                                    return `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auctionId.id}/bids/${data.bidId}/documents`
                                }
                        }
                        
                        const getBidOwner = (props, data) => {
                            if (props.auctionId.privateData.hasBids) {
                                for (let key in props.auctionId.privateData.bids) {
                                    if (data.bidId === props.auctionId.privateData.bids[key].bidId) return true
                                }
                            }
                            return false
                        }
                    
                        let tmp = null
                        const docScopeData = {}
                        docScopeData.aucId = props.auctionId.id
                        docScopeData.aucStatus = props.auctionId.status
                        docScopeData.aucType = props.auctionId.sellingMethod
                        docScopeData.reload = () => {getUpdateDataAuction(props, props.auctionId.id)}
                        docScopeData.aucOwner = props.auctionId.privateData.isOwner
                        docScopeData.historyUrl = getHistoryURL(props, data)
                        
                           
                        docScopeData.bidOwner = getBidOwner( props, data)
                        docScopeData.docTypesList = [{ value: '"cancellationDetails"', name: 'Причини скасування', main: null }]
                        docScopeData.awardStatus = data.status
                        docScopeData.docList = data.documents

                        if(props.auctionId.privateData.isOwner){
                            docScopeData.typeOf = 'cancellation'
                            //docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/cancellation/${data.id}/document`
                            //docScopeData.patchNewDocFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/cancellation/${data.id}/document`
                            //docScopeData.patchNewDocWoutFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/cancellation/${data.id}/document`

                            docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/cancelation/${data.id}/document`
                            docScopeData.patchNewDocFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/cancelation/${data.id}/document`
                            docScopeData.patchNewDocWoutFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/cancelation/${data.id}/document`

                            
                        }
                        // else if (props.auctionId.privateData.hasBids && docScopeData.bidOwner) {
                        //     docScopeData.typeOf = 'bidAward'
                        //     docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.auctionId.privateData.bids[0].id}/documents`
                        //     docScopeData.patchNewDocFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.auctionId.privateData.bids[0].id}/documents`
                        //     docScopeData.patchNewDocWoutFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.auctionId.privateData.bids[0].id}/documents`
                        // }
                        // else {
                        //     docScopeData.typeOf = 'bidAward'
                        // }
                    
                        return (
                            <DocItem
                                data={t}
                                scopeData={docScopeData}
                                dataECP={ dataECPMapper(data.documents, t) }
                                key={count}
                            />
                        )
                }
            )
            return tmp
        }
    }
}

function viewDocAwardBid(props, data) { //документы участника
    const getBidOwner = (props, data) => {
        if (props.auctionId.privateData.hasBids) {
            for (let key in props.auctionId.privateData.bids) {
                if (data.bidId === props.auctionId.privateData.bids[key].bidId) return true
            }
        }
        return false
    }

    function dataECPMapper(data, t) {
        let tmp = data
            .filter(
            (item) => {
                return item.documentType === 'digitalSignature' &&  item.relatedDocument === t.documentId
            })
        if (tmp.length === 0) return null
        return tmp
    }

    //TODO: Пофиксить вывод документов бида
    let tmpArr = null
    let ecpArr = null
    if (checkProp(props.auctionId, 'bids') && props.auctionId.bids.length > 0) {
        for (let key in props.auctionId.bids) {
            if (props.auctionId.bids[key].id === data.bidId) {
                if (checkProp(props.auctionId.bids[key], 'documents') && props.auctionId.bids[key].documents.length > 0) {
                    // ecpArr = props.auctionId.bids[key].documents
                    tmpArr = props.auctionId.bids[key].documents.filter(
                        (t) => {
                            return t.documentType === 'auctionProtocol'
                                // || t.documentType === "digitalSignature"
                                            }
                    )
                    break
                }
            }
        }
    }
    
    if (tmpArr !== null) {
        let tmp = tmpArr.map(
            (t, count) => {
                const docScopeData = {}
                docScopeData.aucId = props.auctionId.id
                docScopeData.aucStatus = props.auctionId.status
                docScopeData.aucType = props.auctionId.sellingMethod
                docScopeData.reload = () => {
                    // await getUpdateDataBid(props, props.auctionId.privateData.bids[0].id)
                    getUpdateDataAuction(props, props.auctionId.id)
                }
                docScopeData.aucOwner = props.auctionId.privateData.isOwner
                
                docScopeData.bidOwner = getBidOwner( props, data)
                docScopeData.docTypesList = [{ value: 'cancellationDetails', name: 'Причини скасування', main: null }]
                docScopeData.awardStatus = data.status
                docScopeData.typeOf = 'cancellation'
                docScopeData.docList = data.documents

                function getOldDocId(props, t){ // Достаем старый айдишник документа для старого запроса
                    if (checkProp(props.auctionsAllBids, 'arr') && checkProp(props.auctionsAllBids.arr, 'documents') && props.auctionsAllBids.arr.documents.length > 0) {
                        for (let key in props.auctionsAllBids.arr.documents) {
                            if (props.auctionsAllBids.arr.documents[key].documentId === t.id) return props.auctionsAllBids.arr.documents[key].id
                        }
                    }
                }

                const docOldId = getOldDocId(props, t)
                docScopeData.historyUrl = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auctionId.id}/bids/${data.bidId}/documents`
                if (checkProp(props.auctionId.privateData, 'bids') && props.auctionId.privateData.bids.length > 0 ){
                // docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.auctionId.privateData.bids[0].id}/documents`
                    docScopeData.patchNewDocFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.auctionId.privateData.bids[0].id}/documents/${docOldId}`
                    docScopeData.patchNewDocWoutFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.auctionId.privateData.bids[0].id}/documents/${docOldId}`
                }
                return (
                    <>
                        <DocItem
                            data={t}
                            scopeData={docScopeData}
                            key={count}
                            dataECP={dataECPMapper(tmpArr, t)}
                        />
                        {/* {viewDocAwardBidKEP(data, countContract, props, data.relatedDocument)} */}
                    </>
                )
            }
        )
        return tmp
    }
    return null
}

//////////////////////////////////////////
function viewDocAwardBidKEP(data, countContract, props, relatedDocument){
    if(data !== null){
        if(data === undefined || data === 'undefined'){
        }else{
            let tmp = data.bid.documents
            .filter(
                (t) => {
                    return t.documentType === "digitalSignature"
                }
            )
            .filter(
                (t) => {
                    return t.relatedDocument === relatedDocument
                }
            )
            .map(
                (t, count) => {
                    let datePublished = null
                    let dateModified = null
                    if (t.datePublished !== null) {
                        datePublished = modifiDateString(t.datePublished, 3, false)
                    }
                    if (t.dateModified !== null) {
                        dateModified = modifiDateString(t.dateModified, 3, false)
                    }
                    let tmpURL = `${t.url}`
                    if (t.hasOwnProperty('token') === true) {
                        if (t.token !== null) {
                            if (t.token !== '') {
                                tmpURL = `${t.url}?token=${t.token}`
                            }
                        }
                    }
                    let tmpDataBid = null

                    if(data.bid.createdByCurrentUser === true){
                        tmpDataBid = () => {
                                props.changeContractApprowePopUpStatus({
                                aucId: props.auctionId.id,
                                // contractId: data.id,
                                sellingMethod: props.auctionId.sellingMethod,
                                count: countContract,
                                fullAuction: data,
                                typeView: 3,
                                currentUser: data
                            })
                            props.setContractDocId(t.id)
                            props.setContractDocTitle(t.title)
                            props.setContractDocType(t.documentType)
                            props.setContractDocDescription(t.description)
                            //props.setContractDocDocumentOf(t.documentOf)
                            props.setDataAddress(t.documentOf, 'SET_TYPE_DOCUMENT_OF')
                        }
                    }
                    return (
                        <DocItem
                            data={t}
                            // scopeData={docScopeData}
                            // dataFuncOpenPopUp={setPopAddDoc}
                            awardData={data}
                            key={count}
                        />
                    )
                }
            )
            return tmp
        }
    }
}

/*function buttonContractPopUp(props, i, count, setPopAddDoc){
    if(props.auctionId !== null){
        return (
            <>
                {viewApproveContractDoc(props, i, count, setPopAddDoc)}
            </>
        )
    }
    return null
}

function viewApproveContractDoc(props, i, count, setPopAddDoc){
    let tmp = null
    if(
        !isLRE(props.auctionId.sellingMethod) &&
        !isLSE(props.auctionId.sellingMethod) &&
        !isLSP( props.auctionId.sellingMethod)
    ){
        if(props.auctionId.privateData.isOwner === true){
            if(
                props.auctionId.status !== 'cancelled' &&
                props.auctionId.status !== 'unsuccessful' &&
                props.auctionId.status !== 'complete' &&
                i.status !== 'cancelled' &&
                i.status !== 'unsuccessful'
            ){
                tmp = (
                    <>
                        <div
                            className={styles.btnAccept}
                            onClick={
                                () => {
                                    setPopAddDoc(true)
                                }
                            }
                        >
                            Документи кваліфікації
                        </div>
                        {viewApprove(props, i, count, 4)}
                    </>
                )
            }
            if(i.status === 'pending_waiting') tmp = null
        }else if(props.auctionId.privateData !== null){
            if(
                props.auctionId.status !== 'cancelled' &&
                props.auctionId.status !== 'unsuccessful' &&
                props.auctionId.status !== 'complete' &&
                i.status !== 'cancelled' &&
                i.status !== 'unsuccessful'
            ){
                if(props.auctionId.privateData.bids !== null && props.auctionId.privateData.bids.length > 0){
                    if(i.bidId === props.auctionId.privateData.bids[0].bidId){
                        tmp = (
                            <>
                                <div
                                    className={styles.btnAccept}
                                    onClick={
                                        () => {
                                            setPopAddDoc(true)
                                        }
                                    }
                                >
                                    Документи кваліфікації
                                </div>
                                {viewApprove(props, i, count)}
                            </>
                        )
                    }
                }
            }
        }
    }else{
        if(props.auctionId.privateData.isOwner === true){
            if(
                props.auctionId.status !== 'cancelled' &&
                props.auctionId.status !== 'unsuccessful' &&
                props.auctionId.status !== 'complete' &&
                i.status !== 'cancelled' &&
                i.status !== 'unsuccessful'
            ){
                tmp = (
                    <>
                        <div
                            className={styles.btnAccept}
                            onClick={
                                () => {
                                    setPopAddDoc(true)
                                }
                            }
                        >
                            Документи кваліфікації
                        </div>
                        {viewApprove(props, i, count, 4)}
                    </>
                )
            }
            if(i.status === 'pending_waiting') tmp = null
        }else if(props.auctionId.privateData.bids !== null && props.auctionId.privateData.bids.length > 0){
            if(i.bidId === props.auctionId.privateData.bids[0].bidId){
                if(
                    i.auction.status !== 'cancelled' &&
                    i.auction.status !== 'unsuccessful' &&
                    i.auction.status !== 'complete' &&
                    i.status !== 'cancelled' &&
                    i.status !== 'unsuccessful'
                ){
                    if(i.status === 'pending'){
                        tmp = (
                            <>
                                <div
                                    className={styles.btnAccept}
                                    onClick={
                                        () => {
                                            setPopAddDoc(true)
                                        }
                                    }
                                >
                                    Документи кваліфікації
                                </div>
                            </>
                        )
                    }else if(i.status === 'pending_waiting'){
                        tmp = (
                            <>
                                <div
                                    className={styles.btnAccept}
                                    onClick={
                                        () => {
                                            setPopAddDoc(true)
                                        }
                                    }
                                >
                                    Документи кваліфікації
                                </div>
                                {viewApprove(props, i, count)}
                            </>
                        )
                    }else{
                        tmp = (
                            <>
                                {viewApprove(props, i, count)}
                            </>
                        )
                    }
                }
            }
        }
    }
    return tmp
}*/

function viewApprove(props, i, count){
    if(i.status !== 'active'){
        if(
            !isLRE(props.auctionId.sellingMethod) &&
            !isLSE(props.auctionId.sellingMethod) &&
            !isLSP( props.auctionId.sellingMethod)
        ){
            if(
                props.auctionId.status !== 'cancelled' &&
                props.auctionId.status !== 'unsuccessful' &&
                props.auctionId.status !== 'complete' &&
                i.status !== 'cancelled' &&
                i.status !== 'unsuccessful' //&&
                //i.status !== 'active'
            ){
                if(props.auctionId.privateData.bids !== null && props.auctionId.privateData.bids.length > 0){
                    if(i.bidId === props.auctionId.privateData.bids[0].bidId){
                        if(i.status === 'pending_waiting'){
                            return (
                                <div
                                    className={styles.btnAccept}
                                    onClick={
                                        () => {
                                            props.changeAwardRejectPopUpStatus({
                                                aucId: props.auctionId.id,
                                                awardId: i.id,
                                                status: i.status,
                                                awards: count,
                                                sellingMethod: props.auctionId.sellingMethod
                                            })
                                            /*props.changeContractApprowePopUpStatus({
                                                aucId: props.auctionsGetId.id,
                                                contractId: i.id,
                                                sellingMethod: props.auctionsGetId.sellingMethod,
                                                count: count,
                                                fullAuction: props.auctionsGetId,
                                                typeView: 4,
                                                bid: i
                                            })*/
                                        }
                                    }
                                >
                                    Дії з учасником
                                </div>
                            )
                        }
                    }
                }else{
                    if(i.documents !== null){
                        if(i.documents.length > 0){
                            return (
                                <div
                                    className={styles.btnAccept}
                                    onClick={
                                        () => {
                                            //props.changeAwardRejectPopUpStatus({aucId: props.auctionId.id, awardId: i.id, status: i.status, awards: count, sellingMethod: props.auctionId.sellingMethod})
                                            props.changeAwardRejectPopUpStatus({
                                                aucId: props.auctionId.id,
                                                awardId: i.id,
                                                status: i.status,
                                                awards: count,
                                                sellingMethod: props.auctionId.sellingMethod
                                            })
                                            /*props.changeContractApprowePopUpStatus({
                                                aucId: props.auctionsGetId.id,
                                                contractId: i.id,
                                                sellingMethod: props.auctionsGetId.sellingMethod,
                                                count: count,
                                                fullAuction: props.auctionsGetId,
                                                typeView: 4,
                                                bid: i
                                            })*/
                                        }
                                    }
                                >
                                    Дії з учасником
                                </div>
                            )
                        }
                    }
                }
            }
            return (
                <div className={styles.btnDecline}>
                    Дії з учасником
                </div>
            )
        }else{
            if(
                props.auctionId.status !== 'cancelled' &&
                props.auctionId.status !== 'unsuccessful' &&
                props.auctionId.status !== 'complete' &&
                i.status !== 'cancelled' &&
                i.status !== 'unsuccessful' //&&
                //i.status !== 'active'
            ){
                if(i.documents !== null){
                    if(i.documents.length > 0){
                        if(i.status !== 'active'){
                            return (
                                <div
                                    className={styles.btnAccept}
                                    onClick={
                                        () => {
                                            props.changeAwardRejectPopUpStatus({
                                                aucId: props.auctionId.id,
                                                awardId: i.id,
                                                status: i.status,
                                                awards: count,
                                                sellingMethod: props.auctionId.sellingMethod
                                            })
                                        }
                                    }
                                >
                                    Дії з учасником
                                </div>
                            )
                        }else{
                            if(props.auctionId.privateData.isOwner === true){
                                //Тут если статус актив надо взять время аварда и проверить время создания документов если есть док с временем больше аварда открыть кнопку
                                let tmpDataAward = ''
                                if(i.dateModified !== null){
                                    let tmpNow = new Date(i.datePublished)
                                    tmpDataAward = tmpNow.getTime()
                                }
                                for(let tm = 0; tm < i.documents.length; tm++){
                                    let tmpDoc = new Date(i.documents[tm].datePublished)
                                    if(
                                        i.documents[tm].documentType === 'rejectionProtocol' || 
                                        i.documents[tm].documentType === 'act'
                                    ){
                                        if(tmpDataAward < tmpDoc.getTime()){
                                            return (
                                                <div
                                                    className={styles.btnAccept}
                                                    onClick={
                                                        () => {
                                                            /*props.changeAwardRejectPopUpStatus({
                                                                aucId: props.auctionId.id,
                                                                awardId: i.id,
                                                                status: i.status,
                                                                awards: count,
                                                                sellingMethod: props.auctionId.sellingMethod
                                                            })*/
                                                            props.changeAwardRejectPopUpStatus({
                                                                aucId: props.auctionId.id,
                                                                awardId: i.id,
                                                                status: i.status,
                                                                awards: count,
                                                                sellingMethod: props.auctionId.sellingMethod
                                                            })
                                                        }
                                                    }
                                                >
                                                    Дії з учасником
                                                </div>
                                            )
                                        }
                                    }
                                }
                            }
                            return (
                                <div className={styles.btnDecline}>
                                    Дії з учасником
                                </div>
                            )
                            
                        }
                    //}else if(i.bid.createdByCurrentUser === true){
                    }else if(!props.auctionId.privateData.isOwner && (props.auctionId.privateData.bids[0].bidId === i.bidId)){
                        return (
                            <div
                                className={styles.btnAccept}
                                onClick={
                                    () => {
                                        /*props.changeAwardRejectPopUpStatus({
                                            aucId: props.auctionId.id,
                                            awardId: i.id,
                                            status: i.status,
                                            awards: count,
                                            sellingMethod: props.auctionId.sellingMethod
                                        })*/
                                        props.changeAwardRejectPopUpStatus({
                                            aucId: props.auctionId.id,
                                            awardId: i.id,
                                            status: i.status,
                                            awards: count,
                                            sellingMethod: props.auctionId.sellingMethod
                                        })
                                    }
                                }
                            >
                                Дії з учасником
                            </div>
                        )
                    }
                //}else if(i.bid.createdByCurrentUser === true){
                }else if(!props.auctionId.privateData.isOwner && (props.auctionId.privateData.bids[0].bidId === i.bidId)){
                    return (
                        <div
                            className={styles.btnAccept}
                            onClick={
                                () => {
                                    props.changeAwardRejectPopUpStatus({
                                        aucId: props.auctionId.id,
                                        awardId: i.id,
                                        status: i.status,
                                        awards: count,
                                        sellingMethod: props.auctionId.sellingMethod
                                    })
                                }
                            }
                        >
                            Дії з учасником
                        </div>
                    )
                }
            }else{
                if(openButtonWithContract(props, i) === true){
                    if(i.documents !== null){
                        if(i.documents.length > 0){
                            return (
                                <div
                                    className={styles.btnAccept}
                                    onClick={
                                        () => {
                                            props.changeAwardRejectPopUpStatus({
                                                aucId: props.auctionId.id,
                                                awardId: i.id,
                                                status: i.status,
                                                awards: count,
                                                sellingMethod: props.auctionId.sellingMethod
                                            })
                                            /*props.changeContractApprowePopUpStatus({
                                                aucId: props.auctionsGetId.id,
                                                contractId: i.id,
                                                sellingMethod: props.auctionsGetId.sellingMethod,
                                                count: count,
                                                fullAuction: props.auctionsGetId,
                                                typeView: 4,
                                                bid: i
                                            })*/
                                        }
                                    }
                                >
                                    Дії з учасником
                                </div>
                            )
                        //}else if(i.bid.createdByCurrentUser === true){
                        }else if(props.auctionId.privateData.bids[0].bidId === i.bidId){
                            return (
                                <div
                                    className={styles.btnAccept}
                                    onClick={
                                        () => {
                                            props.changeAwardRejectPopUpStatus({
                                                aucId: props.auctionId.id,
                                                awardId: i.id,
                                                status: i.status,
                                                awards: count,
                                                sellingMethod: props.auctionId.sellingMethod
                                            })
                                            /*props.changeContractApprowePopUpStatus({
                                                aucId: props.auctionsGetId.id,
                                                contractId: i.id,
                                                sellingMethod: props.auctionsGetId.sellingMethod,
                                                count: count,
                                                fullAuction: props.auctionsGetId,
                                                typeView: 4,
                                                bid: i
                                            })*/
                                        }
                                    }
                                >
                                    Дії з учасником
                                </div>
                            )
                        }
                    //}else if(i.bid.createdByCurrentUser === true){
                    }else if(props.auctionId.privateData.bids[0].bidId === i.bidId){
                        return (
                            <div
                                className={styles.btnAccept}
                                onClick={
                                    () => {
                                        props.changeAwardRejectPopUpStatus({
                                            aucId: props.auctionId.id,
                                            awardId: i.id,
                                            status: i.status,
                                            awards: count,
                                            sellingMethod: props.auctionId.sellingMethod
                                        })
                                        /*props.changeContractApprowePopUpStatus({
                                            aucId: props.auctionsGetId.id,
                                            contractId: i.id,
                                            sellingMethod: props.auctionsGetId.sellingMethod,
                                            count: count,
                                            fullAuction: props.auctionsGetId,
                                            typeView: 4,
                                            bid: i
                                        })*/
                                    }
                                }
                            >
                                Дії з учасником
                            </div>
                        )
                    }
                }
            }
            return (
                <div className={styles.btnDecline}>
                    Дії з учасником
                </div>
            )
        }
    }else{
        let tmp = false
        for(let tt = 0; tt < i.documents.length; tt++){
            if(i.documents[tt].documentType === 'rejectionProtocol' || i.documents[tt].documentType === 'act'){
                tmp = true
            }
        }
        if(tmp){
            if(
                !isLRE(props.auctionId.sellingMethod) &&
                !isLSE(props.auctionId.sellingMethod) &&
                !isLSP( props.auctionId.sellingMethod)
            ){
                if(
                    props.auctionId.status !== 'cancelled' &&
                    props.auctionId.status !== 'unsuccessful' &&
                    props.auctionId.status !== 'complete' &&
                    i.status !== 'cancelled' &&
                    i.status !== 'unsuccessful' //&&
                    //i.status !== 'active'
                ){
                    if(props.auctionId.privateData.bids !== null && props.auctionId.privateData.bids.length > 0){
                        if(i.bidId === props.auctionId.privateData.bids[0].bidId){
                            if(i.status === 'pending_waiting'){
                                return (
                                    <div
                                        className={styles.btnAccept}
                                        onClick={
                                            () => {
                                                props.changeAwardRejectPopUpStatus({
                                                    aucId: props.auctionId.id,
                                                    awardId: i.id,
                                                    status: i.status,
                                                    awards: count,
                                                    sellingMethod: props.auctionId.sellingMethod
                                                })
                                                /*props.changeContractApprowePopUpStatus({
                                                    aucId: props.auctionsGetId.id,
                                                    contractId: i.id,
                                                    sellingMethod: props.auctionsGetId.sellingMethod,
                                                    count: count,
                                                    fullAuction: props.auctionsGetId,
                                                    typeView: 4,
                                                    bid: i
                                                })*/
                                            }
                                        }
                                    >
                                        Дії з учасником
                                    </div>
                                )
                            }
                        }
                    }else{
                        if(i.documents !== null){
                            if(i.documents.length > 0){
                                return (
                                    <div
                                        className={styles.btnAccept}
                                        onClick={
                                            () => {
                                                //props.changeAwardRejectPopUpStatus({aucId: props.auctionId.id, awardId: i.id, status: i.status, awards: count, sellingMethod: props.auctionId.sellingMethod})
                                                props.changeAwardRejectPopUpStatus({
                                                    aucId: props.auctionId.id,
                                                    awardId: i.id,
                                                    status: i.status,
                                                    awards: count,
                                                    sellingMethod: props.auctionId.sellingMethod
                                                })
                                                /*props.changeContractApprowePopUpStatus({
                                                    aucId: props.auctionsGetId.id,
                                                    contractId: i.id,
                                                    sellingMethod: props.auctionsGetId.sellingMethod,
                                                    count: count,
                                                    fullAuction: props.auctionsGetId,
                                                    typeView: 4,
                                                    bid: i
                                                })*/
                                            }
                                        }
                                    >
                                        Дії з учасником
                                    </div>
                                )
                            }
                        }
                    }
                }
                return (
                    <div className={styles.btnDecline}>
                        Дії з учасником
                    </div>
                )
            }else{
                if(
                    props.auctionId.status !== 'cancelled' &&
                    props.auctionId.status !== 'unsuccessful' &&
                    props.auctionId.status !== 'complete' &&
                    i.status !== 'cancelled' &&
                    i.status !== 'unsuccessful' //&&
                    //i.status !== 'active'
                ){
                    if(i.documents !== null){
                        if(i.documents.length > 0){
                            if(i.status !== 'active'){
                                return (
                                    <div
                                        className={styles.btnAccept}
                                        onClick={
                                            () => {
                                                props.changeAwardRejectPopUpStatus({
                                                    aucId: props.auctionId.id,
                                                    awardId: i.id,
                                                    status: i.status,
                                                    awards: count,
                                                    sellingMethod: props.auctionId.sellingMethod
                                                })
                                            }
                                        }
                                    >
                                        Дії з учасником
                                    </div>
                                )
                            }else{
                                if(props.auctionId.privateData.isOwner === true){
                                    //Тут если статус актив надо взять время аварда и проверить время создания документов если есть док с временем больше аварда открыть кнопку
                                    let tmpDataAward = ''
                                    if(i.dateModified !== null){
                                        let tmpNow = new Date(i.datePublished)
                                        tmpDataAward = tmpNow.getTime()
                                    }
                                    for(let tm = 0; tm < i.documents.length; tm++){
                                        let tmpDoc = new Date(i.documents[tm].datePublished)
                                        if(
                                            i.documents[tm].documentType === 'rejectionProtocol' || 
                                            i.documents[tm].documentType === 'act'
                                        ){
                                            if(tmpDataAward < tmpDoc.getTime()){
                                                return (
                                                    <div
                                                        className={styles.btnAccept}
                                                        onClick={
                                                            () => {
                                                                /*props.changeAwardRejectPopUpStatus({
                                                                    aucId: props.auctionId.id,
                                                                    awardId: i.id,
                                                                    status: i.status,
                                                                    awards: count,
                                                                    sellingMethod: props.auctionId.sellingMethod
                                                                })*/
                                                                props.changeAwardRejectPopUpStatus({
                                                                    aucId: props.auctionId.id,
                                                                    awardId: i.id,
                                                                    status: i.status,
                                                                    awards: count,
                                                                    sellingMethod: props.auctionId.sellingMethod
                                                                })
                                                            }
                                                        }
                                                    >
                                                        Дії з учасником
                                                    </div>
                                                )
                                            }
                                        }
                                    }
                                }
                                return (
                                    <div className={styles.btnDecline}>
                                        Дії з учасником
                                    </div>
                                )
                                
                            }
                        //}else if(i.bid.createdByCurrentUser === true){
                        }else if(!props.auctionId.privateData.isOwner && (props.auctionId.privateData.bids[0].bidId === i.bidId)){
                            return (
                                <div
                                    className={styles.btnAccept}
                                    onClick={
                                        () => {
                                            /*props.changeAwardRejectPopUpStatus({
                                                aucId: props.auctionId.id,
                                                awardId: i.id,
                                                status: i.status,
                                                awards: count,
                                                sellingMethod: props.auctionId.sellingMethod
                                            })*/
                                            props.changeAwardRejectPopUpStatus({
                                                aucId: props.auctionId.id,
                                                awardId: i.id,
                                                status: i.status,
                                                awards: count,
                                                sellingMethod: props.auctionId.sellingMethod
                                            })
                                        }
                                    }
                                >
                                    Дії з учасником
                                </div>
                            )
                        }
                    //}else if(i.bid.createdByCurrentUser === true){
                    }else if(!props.auctionId.privateData.isOwner && (props.auctionId.privateData.bids[0].bidId === i.bidId)){
                        return (
                            <div
                                className={styles.btnAccept}
                                onClick={
                                    () => {
                                        props.changeAwardRejectPopUpStatus({
                                            aucId: props.auctionId.id,
                                            awardId: i.id,
                                            status: i.status,
                                            awards: count,
                                            sellingMethod: props.auctionId.sellingMethod
                                        })
                                    }
                                }
                            >
                                Дії з учасником
                            </div>
                        )
                    }
                }else{
                    if(openButtonWithContract(props, i) === true){
                        if(i.documents !== null){
                            if(i.documents.length > 0){
                                return (
                                    <div
                                        className={styles.btnAccept}
                                        onClick={
                                            () => {
                                                props.changeAwardRejectPopUpStatus({
                                                    aucId: props.auctionId.id,
                                                    awardId: i.id,
                                                    status: i.status,
                                                    awards: count,
                                                    sellingMethod: props.auctionId.sellingMethod
                                                })
                                                /*props.changeContractApprowePopUpStatus({
                                                    aucId: props.auctionsGetId.id,
                                                    contractId: i.id,
                                                    sellingMethod: props.auctionsGetId.sellingMethod,
                                                    count: count,
                                                    fullAuction: props.auctionsGetId,
                                                    typeView: 4,
                                                    bid: i
                                                })*/
                                            }
                                        }
                                    >
                                        Дії з учасником
                                    </div>
                                )
                            //}else if(i.bid.createdByCurrentUser === true){
                            }else if(!props.auctionId.privateData.isOwner && (props.auctionId.privateData.bids[0].bidId === i.bidId)){
                                return (
                                    <div
                                        className={styles.btnAccept}
                                        onClick={
                                            () => {
                                                props.changeAwardRejectPopUpStatus({
                                                    aucId: props.auctionId.id,
                                                    awardId: i.id,
                                                    status: i.status,
                                                    awards: count,
                                                    sellingMethod: props.auctionId.sellingMethod
                                                })
                                                /*props.changeContractApprowePopUpStatus({
                                                    aucId: props.auctionsGetId.id,
                                                    contractId: i.id,
                                                    sellingMethod: props.auctionsGetId.sellingMethod,
                                                    count: count,
                                                    fullAuction: props.auctionsGetId,
                                                    typeView: 4,
                                                    bid: i
                                                })*/
                                            }
                                        }
                                    >
                                        Дії з учасником
                                    </div>
                                )
                            }
                        //}else if(i.bid.createdByCurrentUser === true){
                        }else if(!props.auctionId.privateData.isOwner && (props.auctionId.privateData.bids[0].bidId === i.bidId)){
                            return (
                                <div
                                    className={styles.btnAccept}
                                    onClick={
                                        () => {
                                            props.changeAwardRejectPopUpStatus({
                                                aucId: props.auctionId.id,
                                                awardId: i.id,
                                                status: i.status,
                                                awards: count,
                                                sellingMethod: props.auctionId.sellingMethod
                                            })
                                            /*props.changeContractApprowePopUpStatus({
                                                aucId: props.auctionsGetId.id,
                                                contractId: i.id,
                                                sellingMethod: props.auctionsGetId.sellingMethod,
                                                count: count,
                                                fullAuction: props.auctionsGetId,
                                                typeView: 4,
                                                bid: i
                                            })*/
                                        }
                                    }
                                >
                                    Дії з учасником
                                </div>
                            )
                        }
                    }
                }
                return (
                    <div className={styles.btnDecline}>
                        Дії з учасником
                    </div>
                )
            }
        }
    }
    return null
}

function openButtonWithContract(props, data){
    if(props.contractAuctionIdGet !== null){
        if(props.contractAuctionIdGet.length > 0){
            for(let i = 0; i < props.contractAuctionIdGet.length; i++){
                if(props.contractAuctionIdGet[i].award !== null){
                    if(props.contractAuctionIdGet[i].award.id === data.id){
                        if(
                            props.contractAuctionIdGet[i].status === 'signed' &&
                            props.contractAuctionIdGet[i].lotPaymentConfirmation === false
                        ) return true
                    }
                }
            }
        }   
    }
    return false
}

function viewOption(props, type, typeText){
    return <option value={type}>{typeText}</option>
}

function viewApproveContractData(props, data, count, type){
    if(props.contractAuctionIdGet !== null){
        if(props.contractAuctionIdGet.length > 0){
            if(props.contractAuctionIdGet[count].status === 'pending' || props.contractAuctionIdGet[count].status === 'signed'){
                return (
                    <div
                        className={styles.btnAccept}
                        onClick={
                            () => {
                                if(data.title !== null){
                                    if(props.timberContractPut.title === '') props.setContractTitle(data.title)
                                }
                                if(data.description !== null){
                                    if(props.timberContractPut.description === '') props.setContractDescription(data.description)
                                }
                                if(data.contractNumber !== null){
                                    if(props.timberContractPut.contractNumber === '') props.setContractContractNumber(data.contractNumber)
                                }
                                if(data.value !== null){
                                    if(data.value.amount !== null){
                                        if(data.contractTotalValue !== null){
                                            if(data.contractTotalValue.amount !== null){
                                                if(props.timberContractPut.xValueUAH.amount === null) props.setContractAmount(data.contractTotalValue.amount)
                                            }else{
                                                if(props.timberContractPut.xValueUAH.amount === null) props.setContractAmount(data.value.amount)
                                            }
                                        }else{
                                            if(props.timberContractPut.xValueUAH.amount === null) props.setContractAmount(data.value.amount)
                                        }
                                    }
                                }
                                if(data.dateSigned !== null){
                                    if(props.timberContractPut.dateSigned === '') {
                                        let tmp = data.dateSigned.split('+')
                                        props.setContractDateSigned(tmp[0])
                                    }
                                }
                                if(data.x_prepaymentDetailsDate !== null){
                                    if(props.timberContractPut.contractTime.dateFrom === '') {
                                        let tmp = data.x_prepaymentDetailsDate.split('+')
                                        props.setContractDateFrom(tmp[0])
                                    }
                                }
                                if(data.contractTime !== null){
                                    if(data.contractTime.endDate !== null){
                                        if(props.timberContractPut.contractTime.endDate === '') {
                                            let tmp = data.contractTime.endDate.split('+')
                                            props.setContractEndDate(tmp[0])
                                        }
                                    }
                                    if(data.contractTime.startDate !== null){
                                        if(props.timberContractPut.contractTime.startDate === '') {
                                            let tmp = data.contractTime.startDate.split('+')
                                            props.setContractStartDate(tmp[0])
                                        }
                                    }
                                }
                                if(data.datePaid !== null){
                                    if(props.timberContractPut.datePaid === '') {
                                        let tmp = data.datePaid.split('+')
                                        props.setContractDatePaid(tmp[0])
                                    }
                                }
                                props.changeContractApprowePopUpStatus({
                                    aucId: data.award.auction.id,
                                    contractId: data.id,
                                    sellingMethod: data.award.auction.sellingMethod,
                                    count: count,
                                    fullAuction: data.award.auction,
                                    typeView: type,
                                    typePopUp: 'contracts'
                                })
                            }
                        }
                    >
                        Дані контракту
                    </div>
                )
            }
        }
    }
    return <div className={styles.btnToList}>
                Дані контракту
            </div>
}

function viewApproveContract(props, data){
    if(props.contractAuctionIdGet !== null){
        if(props.contractAuctionIdGet.length > 0){
            if(props.contractAuctionIdGet[data.count].status === 'pending' ||  props.contractAuctionIdGet[data.count].status === 'paid'){
                let tmpMainDoc = false
                if(props.contractAuctionIdGet[data.count].documents !== null){
                    if(props.contractAuctionIdGet[data.count].documents.length > 0){
                        for(let t = 0; t < props.contractAuctionIdGet[data.count].documents.length; t++){
                            if(props.contractAuctionIdGet[data.count].documents[t].documentType === 'contractSigned'){
                                //if(props.contractAuctionIdGet[data.count].documents[t].createdByCurrentUser === true){
                                    //tmpMainDoc = true
                                //}
                                if(props.auctionsGetId !== null){
                                    if(props.auctionsGetId.createdByCurrentUser === true){
                                        tmpMainDoc = true
                                    }
                                }
                            }
                        }
                    }
                }
                if(
                    props.contractAuctionIdGet[data.count].title !== null &&
                    props.contractAuctionIdGet[data.count].title !== '' &&
                    props.contractAuctionIdGet[data.count].contractNumber !== null &&
                    props.contractAuctionIdGet[data.count].contractNumber !== '' &&
                    props.contractAuctionIdGet[data.count].dateSigned !== null &&
                    props.contractAuctionIdGet[data.count].dateSigned !== '' &&
                    tmpMainDoc === true
                ){
                    return (
                        <div
                            className={styles.btnAccept}
                            onClick={
                                () => {
                                    promisApproveContract(props, data)
                                }
                            }
                        >
                            Затвердити контракт
                        </div>
                    )
                }else{
                    return (
                        <div className={styles.btnToList}>
                            Затвердити контракт
                        </div>
                    )
                }
            }
        }
    }
    if(props.contractAuctionIdGet[data.count].status !== 'active'){
        if(
            props.auctionId.sellingMethod === 'landRental-english' ||
            props.auctionId.sellingMethod === 'landRental-english' ||
            props.auctionId.sellingMethod === 'landRental-english-fast' ||
            props.auctionId.sellingMethod === 'landRental-english-fast-manual' ||
            props.auctionId.sellingMethod === 'landRental-english-fast-auction-manual-qualification' ||
            props.auctionId.sellingMethod === 'landRental-english-initial-auction' ||
            props.auctionId.sellingMethod === 'landRental-english-initial-auction-manual' ||
            props.auctionId.sellingMethod === 'landRental-english-initial-qualification' ||
            props.auctionId.sellingMethod === 'landRental-english-initial-qualification-prod'

        ){
            if(props.contractAuctionIdGet[data.count].lotPaymentConfirmation === null){
                return (
                    <div className={styles.buttonsBlockYesNo}>
                        <div className={styles.titleTextButtons}>Підтвердження сплати за лот</div>
                        <div className={styles.buttonsBlokFlex}>
                            <div className={styles.btnAccept}
                                onClick={
                                    () => {
                                        if(props.contractAuctionIdGet[0].lotPaymentConfirmation === null){
                                            promisContractLRUpdate(props, data, true)
                                        }else{
                                            if(props.contractAuctionIdGet[0].lotPaymentConfirmation === true){
                                                promisApproveContractLR(props, data, true)
                                            }
                                        }
                                    }
                                }
                            >
                                Так
                            </div>
                            <div className={styles.btnDecline}
                                onClick={
                                    () => {
                                        promisContractLRUpdate(props, data, false)
                                        //props.setDataAddress(data, 'SET_CONTRACT_POPUP_DEL')
                                    }
                                }
                            >
                                Ні
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
    return (
        <div className={styles.btnToList}>
            Затвердити контракт
        </div>
    )
}

async function promisContractLRUpdate(props, data, status){
    props.changeLoader(true)
    let endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${data.aucId}/contract/${data.contractId}`
    let method = null
    let body = {}
    //body.contractNumber = props.contractAuctionIdGet[0].contractNumber
    //body.title = props.contractAuctionIdGet[0].title
    //body.description = props.contractAuctionIdGet[0].description
    //body.dateSigned = props.contractAuctionIdGet[0].dateSigned
    //body.contractTime = props.contractAuctionIdGet[0].contractTime
    body.lotPaymentConfirmation = status
    const response = await fetch(endPoint, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при затвердженні контракту', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            props.changeLoader(false)
        }else{
            if(status){
                await promisApproveContractLR(props, data, status)
            }else{
                let data1 = {
                    domen: process.env.REACT_APP_END_POINT_BD,
                    version: 'v1',
                    local: props.langFlag
                }
                let headers1 = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                await props.contractsAuctionIdGet(data1, headers1, data.aucId)
                await props.changeLoader(false)
            }
        }
    }
    await props.changeLoader(false)
}

async function promisApproveContractLR(props, data, status){
    props.changeLoader(true)
    let endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${data.aucId}/contract/${data.contractId}/approve/set_status/active`
    const response = await fetch(endPoint, {
        method: 'POST',
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        props.changeLoader(false)
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при затвердженні контракту', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            props.changeLoader(false)
        }else{
            let data1 = {
                domen: process.env.REACT_APP_END_POINT_BD,
                version: 'v1',
                local: props.langFlag
            }
            let headers1 = {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
            await props.contractsAuctionIdGet(data1, headers1, data.aucId)
            await props.changeLoader(false)
        }
    }
    await props.changeLoader(false)
}

async function promisApproveContract(props, data){
    props.changeLoader(true)
    let endPoint = null
    if(
       props.auctionId.sellingMethod === 'landRental-english' ||
       props.auctionId.sellingMethod === 'landRental-english-fast' ||
       props.auctionId.sellingMethod === 'landRental-english-fast-manual' ||
       props.auctionId.sellingMethod === 'landRental-english-fast-auction-manual-qualification' ||
       props.auctionId.sellingMethod === 'landRental-english-initial-auction' ||
       props.auctionId.sellingMethod === 'landRental-english-initial-auction-manual' ||
       props.auctionId.sellingMethod === 'landRental-english-initial-qualification' ||
       props.auctionId.sellingMethod === 'landRental-english-initial-qualification-prod'
    ){
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${data.aucId}/contract/${data.contractId}/approve/set_status/signed`
    }else{
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${data.aucId}/contract/${data.contractId}/approve/set_status/active`
    }
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        props.changeLoader(false)
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при затвердженні контракту', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            props.changeLoader(false)
        }else{
            let data1 = {
                domen: process.env.REACT_APP_END_POINT_BD,
                version: 'v1',
                local: props.langFlag
            }
            let headers1 = {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
            await props.contractsAuctionIdGet(data1, headers1, data.aucId)
            await props.changeLoader(false)
        }
    }
    await props.changeLoader(false)
}



const mapStateToProps = state => {
    return {
        token: state.start.token,
        langFlag: state.start.langFlag,
        auctionAwards: state.start.auctionAwards,
        allUserPopUp: state.start.allUserPopUp,
        allUserPopUpUser: state.start.allUserPopUpUser,
        contractAuctionIdGet: state.start.contractAuctionIdGet,
        timberContractPut: state.start.timberContractPut,
        pageNumber: state.start.pageNumber,
        auctionsGetId: state.start.auctionsGetId,
        auctionsAllBids: state.start.auctionsAllBids,
        // histery: state.start.histery,
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
        bidEdit: state.start.bidEdit,
        userProfiles: state.start.userProfiles
        // historyDocsContracts: state.start.historyDocsContracts,
    }
}

const mapDispatchToProps = {
    changeAwardRejectPopUpStatus,
    setAwardAllUserPopUp,
    setAwardAllUserPopUpUser,

    changeContractApprowePopUpStatus,

    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,

    histeryAuctions,
    histeryAwards,
    histeryContrasts,
    histeryBids,

    setUniversalError,
    changeLoader,
    contractsAuctionIdGet,
    setContractDatePaid,

    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
    setContractDocDocumentOf,
    setDataAddress,
    saveArrBids
}

export default connect(mapStateToProps, mapDispatchToProps)(Contract)
