export const SET_NEW_CURRENT_TENANT = "SET_NEW_CURRENT_TENANT"
export const SET_NUMBER_OF_CURRENT_TENANTS = "SET_NUMBER_OF_CURRENT_TENANTS"
export const CREATE_SEARCH_NUMBER_OF_CURRENT_TENANTS = "CREATE_SEARCH_NUMBER_OF_CURRENT_TENANTS"
export const CREATE_AUCTION_MARTIAL_LAW_DISCLAIMER = 'CREATE_AUCTION_MARTIAL_LAW_DISCLAIMER'
export const CLEAR_DISCOUNT = 'CLEAR_DISCOUNT'
export const SET_CONTRACT_X_PREPAYMENTDETAILS_DATE = 'SET_CONTRACT_X_PREPAYMENTDETAILS_DATE'
export const SET_CONTRACT_X_PREPAYMENTDETAILS_PREPAYMENT_AMOUNT = 'SET_CONTRACT_X_PREPAYMENTDETAILS_PREPAYMENT_AMOUNT'

export const POPUP_AUCTION_MONGA = 'POPUP_AUCTION_MONGA'

export const SET_DELETE_BANK_GROOPS = 'SET_DELETE_BANK_GROOPS'
export const SET_DELETE_CURENT_BANK = 'SET_DELETE_CURENT_BANK'

export const CREATE_CVZU_DATA = 'CREATE_CVZU_DATA'
export const CREATE_CVZU_ARR = 'CREATE_CVZU_ARR'
export const CREATE_CVZU_VALUE = 'CREATE_CVZU_VALUE'
export const CREATE_AUCTION_RAILWAY_DUTCH_STEP_VALUE_ADDED_TAX_INCLUDED = 'CREATE_AUCTION_RAILWAY_DUTCH_STEP_VALUE_ADDED_TAX_INCLUDED'
export const CREATE_ITEMS_LAND_RENTAL_LAND_INTENDED_USE = 'CREATE_ITEMS_LAND_RENTAL_LAND_INTENDED_USE'
export const SERCH_IS_PERISHABLE = 'SERCH_IS_PERISHABLE'
export const SERCH_ITEM_TYPE = 'SERCH_ITEM_TYPE'

export const SET_IS_PERISHABLE = 'SET_IS_PERISHABLE'
export const SET_VALUE_ADDED_TAX_CHARGED_BASIC = 'SET_VALUE_ADDED_TAX_CHARGED_BASIC'

export const CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION = 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION'

export const SET_CLASSIFICATIONS_BSE_BSD = 'SET_CLASSIFICATIONS_BSE_BSD'

export const SET_CONTRACT_TAX = 'SET_CONTRACT_TAX'
export const SET_CONTRACT_VALUE_CURRENCY = 'SET_CONTRACT_VALUE_CURRENCY'
export const SET_CONTRACT_VALUE_AMOUNT = 'SET_CONTRACT_VALUE_AMOUNT'
export const SET_CONTRACT_VALUE_TAX = 'SET_CONTRACT_VALUE_TAX'

export const SET_BID_NEEDED_SUMM = 'SET_BID_NEEDED_SUMM'
export const CHECKBOX_BID_ALL_1 = 'CHECKBOX_BID_ALL_1'
export const CHECKBOX_BID_ALL_2 = 'CHECKBOX_BID_ALL_2'
export const CHECKBOX_BID_ALL_3 = 'CHECKBOX_BID_ALL_3'

export const CREATE_ITEMS_LAND_RENTAL_ADDRESS_ID = 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_ID'
export const CREATE_ITEMS_LAND_RENTAL_ADDRESS_COUNTRY_NAME = 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_COUNTRY_NAME'
export const CREATE_ITEMS_LAND_RENTAL_ADDRESS_REGION = 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_REGION'
export const CREATE_ITEMS_LAND_RENTAL_ADDRESS_LOCALITY = 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_LOCALITY'
export const CREATE_ITEMS_LAND_RENTAL_ADDRESS_STREET_ADDRESS = 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_STREET_ADDRESS'
export const CREATE_ITEMS_LAND_RENTAL_ADDRESS_POSTAL_CODE = 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_POSTAL_CODE'

export const SET_CADASTRAL_NUMBER = 'SET_CADASTRAL_NUMBER'
export const SET_LAND_AREA_TO = 'SET_LAND_AREA_TO'
export const SET_LAND_AREA_FROM = 'SET_LAND_AREA_FROM'

export const PUSH_NEW_LINK = 'PUSH_NEW_LINK'
export const SET_ID_FULL_AUCTION = 'SET_ID_FULL_AUCTION'

export const SET_TYPE_DOCUMENT_OF = 'SET_TYPE_DOCUMENT_OF'
export const SET_FULL_AUCTION = 'SET_FULL_AUCTION'
export const SET_LAND_CURRENT_TENANT_NAME = 'SET_LAND_CURRENT_TENANT_NAME'
export const SET_LAND_CURRENT_TENANT_REPRESENTATIVE_INFO = 'SET_LAND_CURRENT_TENANT_REPRESENTATIVE_INFO'
export const SET_LAND_CURRENT_TENANT_VALUE_AMOUNT = 'SET_LAND_CURRENT_TENANT_VALUE_AMOUNT'
export const SET_LAND_CURRENT_TENANT_VALUE_CURRENCY = 'SET_LAND_CURRENT_TENANT_VALUE_CURRENCY'
export const SET_LAND_CURRENT_TENANT_IDENTIFIER_ID = 'SET_LAND_CURRENT_TENANT_IDENTIFIER_ID'
export const SET_LAND_CURRENT_TENANT_IDENTIFIER_LEGAL_NAME = 'SET_LAND_CURRENT_TENANT_IDENTIFIER_LEGAL_NAME'
export const SET_LAND_CURRENT_TENANT_IDENTIFIER_SCHEME = 'SET_LAND_CURRENT_TENANT_IDENTIFIER_SCHEME'
export const SET_LAND_CURRENT_TENANT_CURRENT_CONTRACT_TIME_DATE_FROM = 'SET_LAND_CURRENT_TENANT_CURRENT_CONTRACT_TIME_DATE_FROM'
export const SET_LAND_CURRENT_TENANT_CURRENT_CONTRACT_TIME_DATE_TO = 'SET_LAND_CURRENT_TENANT_CURRENT_CONTRACT_TIME_DATE_TO'
export const SET_LAND_CURRENT_TENANT_ADDRESS_COUNTRY_NAME = 'SET_LAND_CURRENT_TENANT_ADDRESS_COUNTRY_NAME'
export const SET_LAND_CURRENT_TENANT_ADDRESS_POSTAL_CODE = 'SET_LAND_CURRENT_TENANT_ADDRESS_POSTAL_CODE'
export const SET_LAND_CURRENT_TENANT_ADDRESS_STREET_ADDRESS = 'SET_LAND_CURRENT_TENANT_ADDRESS_STREET_ADDRESS'
export const SET_LAND_CURRENT_TENANT_ADDRESS_LOCALITY = 'SET_LAND_CURRENT_TENANT_ADDRESS_LOCALITY'
export const SET_LAND_CURRENT_TENANT_ADDRESS_REGION = 'SET_LAND_CURRENT_TENANT_ADDRESS_REGION'


export const POPUP_CONTRACT_DOC_ID = 'POPUP_CONTRACT_DOC_ID'
export const SET_CONTRACT_POPUP_DEL = 'SET_CONTRACT_POPUP_DEL'

export const SET_EDIT_BID_VALUE = 'SET_EDIT_BID_VALUE'
export const SET_TOGETHER_API_HELP_POPUP = 'SET_TOGETHER_API_HELP_POPUP'

export const SET_AUCTION_RESTRICTIONS = 'SET_AUCTION_RESTRICTIONS'
export const SET_AUCTION_CONDITIONS = 'SET_AUCTION_CONDITIONS'
export const SET_AUCTION_BUDGET_SPENT = 'SET_AUCTION_BUDGET_SPENT'
export const SET_AUCTION_EXPERT_MONETARY_VALUATION = 'SET_AUCTION_EXPERT_MONETARY_VALUATION'
export const SET_AUCTION_NORMATIVE_MONETARY_VALUATION = 'SET_AUCTION_NORMATIVE_MONETARY_VALUATION'
export const AUCTION_lEASE_TYPE = 'AUCTION_lEASE_TYPE'
export const AUCTION_SALE_TYPE = 'AUCTION_SALE_TYPE'
export const CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATIONS_ARR = 'CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATIONS_ARR'
export const CREATE_ITEMS_LAND_RENTAL_ID = 'CREATE_ITEMS_LAND_RENTAL_ID'
export const CREATE_ITEMS_LAND_RENTAL_DESCRIPTION = 'CREATE_ITEMS_LAND_RENTAL_DESCRIPTION'
export const CREATE_ITEMS_LAND_RENTAL_CLASSIFICATION = 'CREATE_ITEMS_LAND_RENTAL_CLASSIFICATION'
export const CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATION = 'CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATION'
export const CREATE_ITEMS_LAND_RENTAL_ADDRESS = 'CREATE_ITEMS_LAND_RENTAL_ADDRESS'
export const CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE = 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE'
export const CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE = 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE'
export const CREATE_ITEMS_LAND_RENTAL_QUANTITY = 'CREATE_ITEMS_LAND_RENTAL_QUANTITY'
export const CREATE_ITEMS_LAND_RENTAL_UNIT_CODE = 'CREATE_ITEMS_LAND_RENTAL_UNIT_CODE'
export const CREATE_ITEMS_LAND_RENTAL_LOCATION_LAND_AREA = 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LAND_AREA'
export const CREATE_ITEMS_LAND_RENTAL_LOCATION_CADASTRAL_NUMBER = 'CREATE_ITEMS_LAND_RENTAL_LOCATION_CADASTRAL_NUMBER'
export const CREATE_ITEMS_LAND_RENTAL_LOCATION_OWNERSHIP_TYPE = 'CREATE_ITEMS_LAND_RENTAL_LOCATION_OWNERSHIP_TYPE'
export const CREATE_ITEMS_LAND_RENTAL_LOCATION_ENCUMBRANCES = 'CREATE_ITEMS_LAND_RENTAL_LOCATION_ENCUMBRANCES'
export const CREATE_ITEMS_LAND_RENTAL_LOCATION_JOINT_OWNERSHIP = 'CREATE_ITEMS_LAND_RENTAL_LOCATION_JOINT_OWNERSHIP'
export const CREATE_ITEMS_LAND_RENTAL_LOCATION_UTILITIES_AVAILABILITY = 'CREATE_ITEMS_LAND_RENTAL_LOCATION_UTILITIES_AVAILABILITY'

export const CHANGE_DOCUMENT_INDEX = 'CHANGE_DOCUMENT_INDEX'
export const CLONE_AUCTIONS_STATUS_CHANGE = 'CLONE_AUCTIONS_STATUS_CHANGE'
export const CREATE_ADDRESS_TYPE_ORG = 'CREATE_ADDRESS_TYPE_ORG'

export const SET_POP_UP_EDIT_AUCTION = 'SET_POP_UP_EDIT_AUCTION'

export const SET_SEARCH_REGISTRY_ID = 'SET_SEARCH_REGISTRY_ID'

export const SET_MY_AUCTIONS_STATUS = 'SET_MY_AUCTIONS_STATUS'


export const SET_CABINET_PAGE_COUNTER = 'SET_CABINET_PAGE_COUNTER'

export const UNITS_GET_BY_TYPE_ZU = 'UNITS_GET_BY_TYPE_ZU'

export const SET_BRIDGE_OPEN_POPUP = 'SET_BRIDGE_OPEN_POPUP'
export const SET_BRIDGE_OPEN_BUTTON = 'SET_BRIDGE_OPEN_BUTTON'

export const SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_START_DATE = 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_START_DATE'
export const SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_END_DATE = 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_END_DATE'

export const SET_CURRENT_TENANT_GUARANTEE_FEE_VALUE = 'SET_CURRENT_TENANT_GUARANTEE_FEE_VALUE'

export const SET_SHEDULE_PERIOD_ID = 'SET_SHEDULE_PERIOD_ID'
export const SET_SCHEDULE_TIME_START = 'SET_SCHEDULE_TIME_START'
export const SET_SCHEDULE_TIME_END = 'SET_SCHEDULE_TIME_END'

export const SET_SHEDULE_PERIOD = 'SET_SHEDULE_PERIOD'
export const SET_NEW_PERIOD_ARR = 'SET_NEW_PERIOD_ARR'
export const SET_NEW_FULL_PERIOD_ARR = 'SET_NEW_FULL_PERIOD_ARR'

export const SET_SCHEDULE_WEEKDAY = 'SET_SCHEDULE_WEEKDAY'

export const SET_CREATE_AUCTION_ZU_FLAG = 'SET_CREATE_AUCTION_ZU_FLAG'
export const SET_CREATE_AUCTION_ZU_DATA = 'SET_CREATE_AUCTION_ZU_DATA'
export const SET_CREATE_AUCTION_ZU_OBJECT_ID = 'SET_CREATE_AUCTION_ZU_OBJECT_ID'

export const SET_OBJECT_REESTR = 'SET_OBJECT_REESTR'

export const SET_SCHEDULE_FLAG = 'SET_SCHEDULE_FLAG'

export const SET_ID_OBJECT_CLONE_AUCTIONS = 'SET_ID_OBJECT_CLONE_AUCTIONS'

export const SET_POP_UP_AUCTIONS_REASON = 'SET_POP_UP_AUCTIONS_REASON'

export const CREATE_BANK_ACCOUNT_ZU_GROUPE = 'CREATE_BANK_ACCOUNT_ZU_GROUPE'
export const CREATE_BANK_ACCOUNT_ZU_HOLDER_TYPE = 'CREATE_BANK_ACCOUNT_ZU_HOLDER_TYPE'
export const CREATE_BANK_ACCOUNT_ZU_PERCENT = 'CREATE_BANK_ACCOUNT_ZU_PERCENT'

export const SET_POP_UP_BANK_ACCOUNT_AH_SHEME = "SET_POP_UP_BANK_ACCOUNT_AH_SHEME"
export const SET_POP_UP_BANK_ACCOUNT_AH_LN = "SET_POP_UP_BANK_ACCOUNT_AH_LN"
export const SET_POP_UP_BANK_ACCOUNT_AH_ID = "SET_POP_UP_BANK_ACCOUNT_AH_ID"


export const SET_POP_UP_BANK_ACCOUNT_CURRENCY = 'SET_POP_UP_BANK_ACCOUNT_CURRENCY'

export const SET_POPUP_ORGANIZATIONS = 'SET_POPUP_ORGANIZATIONS'
export const SET_POP_UP_ADDRESS_ID = 'SET_POP_UP_ADDRESS_ID'

export const SET_POPUP_ADDRESSES = 'SET_POPUP_ADDRESSES'

export const CHECKBOX_AGREEMENT = 'CHECKBOX_AGREEMENT'
export const CHECKBOX_WAIT = 'CHECKBOX_WAIT'

export const SEARCH_LEASE_DURATION_FROM_YEAR = 'SEARCH_LEASE_DURATION_FROM_YEAR'
export const SEARCH_LEASE_DURATION_FROM_MONTH = 'SEARCH_LEASE_DURATION_FROM_MONTH'
export const SEARCH_LEASE_DURATION_FROM_DAY = 'SEARCH_LEASE_DURATION_FROM_DAY'
export const SEARCH_LEASE_DURATION_TO_YEAR = 'SEARCH_LEASE_DURATION_TO_YEAR'
export const SEARCH_LEASE_DURATION_TO_MONTH = 'SEARCH_LEASE_DURATION_TO_MONTH'
export const SEARCH_LEASE_DURATION_TO_DAY = 'SEARCH_LEASE_DURATION_TO_DAY'

export const SEARCH_AREA_FROM = 'SEARCH_AREA_FROM'
export const SEARCH_AREA_TO = 'SEARCH_AREA_TO'

export const SERCH_CLASSIFICATION = 'SERCH_CLASSIFICATION'
export const SERCH_ADDITIONAL_CLASSIFICATION = 'SERCH_ADDITIONAL_CLASSIFICATION'
export const SET_CLASSIFICATIONS_ZU_ADD = 'SET_CLASSIFICATIONS_ZU_ADD'

export const SET_POP_UP_BANK_ACCOUNT_ZU_TIMESTAMP = 'SET_POP_UP_BANK_ACCOUNT_ZU_TIMESTAMP'

export const CREATE_AUCTIONS_ZU_ATTEMP = 'CREATE_AUCTIONS_ZU_ATTEMP'

export const CREATE_ITEMS_OTHER_PROPERTY_ITEMS_ID = 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_ID'
export const CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ID = 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ID'
export const CREATE_ITEMS_VEHICLE_ITEM_ID = 'CREATE_ITEMS_VEHICLE_ITEM_ID'
export const CREATE_ITEMS_REAL_ESTATEITEM_ID = 'CREATE_ITEMS_REAL_ESTATEITEM_ID'

export const CREATE_BANK_ACCOUNT_ZU_GROUP_TYPE = 'CREATE_BANK_ACCOUNT_ZU_GROUP_TYPE'

export const SET_SCHEDULE_HOURS_BY_VALUE = 'SET_SCHEDULE_HOURS_BY_VALUE'
export const SET_SCHEDULE_HOURS_BY_BY = 'SET_SCHEDULE_HOURS_BY_BY'
export const SET_SCHEDULE_DAYS_BY_VALUE = 'SET_SCHEDULE_DAYS_BY_VALUE'
export const SET_SCHEDULE_DAYS_BY_BY = 'SET_SCHEDULE_DAYS_BY_BY'
export const SET_SCHEDULE_PERIOD_START_DATE = 'SET_SCHEDULE_PERIOD_START_DATE'
export const SET_SCHEDULE_PERIOD_END_DATE = 'SET_SCHEDULE_PERIOD_END_DATE'
export const SET_SCHEDULE_OTHER = 'SET_SCHEDULE_OTHER'

export const SET_AUCTION_LEASE_RULES = 'SET_AUCTION_LEASE_RULES'
export const SET_AUCTION_RELATED_ORGANIZATIONS = 'SET_AUCTION_RELATED_ORGANIZATIONS'

export const CREATE_BANK_ACCOUNT_ZU_ID = 'CREATE_BANK_ACCOUNT_ZU_ID'
export const CREATE_BANK_ACCOUNT_ZU_GROUP = 'CREATE_BANK_ACCOUNT_ZU_GROUP'
export const CREATE_BANK_ACCOUNT_ZU_TYPE = 'CREATE_BANK_ACCOUNT_ZU_TYPE'
export const SET_POP_UP_BANK_ACCOUNT_ZU = 'SET_POP_UP_BANK_ACCOUNT_ZU'

export const CREATE_ITEMS_REAL_ESTATEITEM_TITLE = 'CREATE_ITEMS_REAL_ESTATEITEM_TITLE'
export const CREATE_ITEMS_REAL_ESTATEITEM_DESCRIPTION = 'CREATE_ITEMS_REAL_ESTATEITEM_DESCRIPTION'
export const CREATE_ITEMS_REAL_ESTATEITEM_CLASSIFICATION = 'CREATE_ITEMS_REAL_ESTATEITEM_CLASSIFICATION'
export const CREATE_ITEMS_REAL_ESTATEITEM_ADDITIONAL_CLASSIFICATION = 'CREATE_ITEMS_REAL_ESTATEITEM_ADDITIONAL_CLASSIFICATION'
export const CREATE_ITEMS_REAL_ESTATEITEM_ADDRESS = 'CREATE_ITEMS_REAL_ESTATEITEM_ADDRESS'
export const CREATE_ITEMS_REAL_ESTATEITEM_LOCATION_LATITUDE = 'CREATE_ITEMS_REAL_ESTATEITEM_LOCATION_LATITUDE'
export const CREATE_ITEMS_REAL_ESTATEITEM_LOCATION_LONGITUDE = 'CREATE_ITEMS_REAL_ESTATEITEM_LOCATION_LONGITUDE'
export const CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS = 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS'
export const CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_ID = 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_ID'
export const CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_DATE = 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_DATE'
export const CREATE_ITEMS_REAL_ESTATEITEM_QUANTITY = 'CREATE_ITEMS_REAL_ESTATEITEM_QUANTITY'
export const CREATE_ITEMS_REAL_ESTATEITEM_UNIT_CODE = 'CREATE_ITEMS_REAL_ESTATEITEM_UNIT_CODE'

export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_TOTAL_BUILDING_AREA = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_TOTAL_BUILDING_AREA'
export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_TOTAL_OBJECT_AREA = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_TOTAL_OBJECT_AREA'
export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_USABLE_AREA = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_USABLE_AREA'
export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_CONSTRUCTION_YEAR = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_CONSTRUCTION_YEAR'
export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_CONSTRUCTION_TECHNOLOGY = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_CONSTRUCTION_TECHNOLOGY'
export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LIVING_AREA = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LIVING_AREA'
export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_KITCHEN_AREA = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_KITCHEN_AREA'
export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LAND_AREA = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LAND_AREA'
export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LOCATION_IN_BUILDING = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LOCATION_IN_BUILDING'
export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_FLOORS = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_FLOORS'
export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_GENERAL_CONDITION = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_GENERAL_CONDITION'
export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_ELECTRICITY = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_ELECTRICITY'
export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SUPPLY_CAPACITY = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SUPPLY_CAPACITY'
export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SUPPLY_CLASS = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SUPPLY_CLASS'
export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_WATER = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_WATER'
export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_GAS = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_GAS'
export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_CENTRAL_HEATING = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_CENTRAL_HEATING'
export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_AUTONOMOUS_HEATING = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_AUTONOMOUS_HEATING'
export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_HEATING_COUNTER = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_HEATING_COUNTER'
export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_VENTILATION = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_VENTILATION'
export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_AIR_CONDITIONING = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_AIR_CONDITIONING'
export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_PHONE = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_PHONE'
export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_TV = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_TV'
export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_INTERNET = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_INTERNET'
export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_ELEVATOR = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_ELEVATOR'
export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_SECURITY_ALARM = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_SECURITY_ALARM'
export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_FIRE_ALARM = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_FIRE_ALARM'
export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SERVICES_DESCRIPTION = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SERVICES_DESCRIPTION'
export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SERVICES_ACCOUNTING = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SERVICES_ACCOUNTING'
export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LAND_TAX = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LAND_TAX'
export const CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_SEWERAGE = 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_SEWERAGE'

export const CREATE_ITEMS_VEHICLE_ITEM_TITLE = 'CREATE_ITEMS_VEHICLE_ITEM_TITLE'
export const CREATE_ITEMS_VEHICLE_ITEM_DESCRIPTION = 'CREATE_ITEMS_VEHICLE_ITEM_DESCRIPTION'
export const CREATE_ITEMS_VEHICLE_ITEM_CLASSIFICATION = 'CREATE_ITEMS_VEHICLE_ITEM_CLASSIFICATION'
export const CREATE_ITEMS_VEHICLE_ITEM_ADDITIONAL_CLASSIFICATION = 'CREATE_ITEMS_VEHICLE_ITEM_ADDITIONAL_CLASSIFICATION'
export const CREATE_ITEMS_VEHICLE_ITEM_ADDRESS = 'CREATE_ITEMS_VEHICLE_ITEM_ADDRESS'
export const CREATE_ITEMS_VEHICLE_ITEM_LOCATION_LATITUDE = 'CREATE_ITEMS_VEHICLE_ITEM_LOCATION_LATITUDE'
export const CREATE_ITEMS_VEHICLE_ITEM_LOCATION_LONGITUDE = 'CREATE_ITEMS_VEHICLE_ITEM_LOCATION_LONGITUDE'
export const CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS = 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS'
export const CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_ID = 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_ID'
export const CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_DATE = 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_DATE'
export const CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_BRAND = 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_BRAND'
export const CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_MODEL = 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_MODEL'
export const CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_PRODUCTION_YEAR = 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_PRODUCTION_YEAR'
export const CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_ENGINE_CAPACITY = 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_ENGINE_CAPACITY'
export const CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_FUEL_TYPE = 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_FUEL_TYPE'
export const CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_TRANSMISSION = 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_TRANSMISSION'
export const CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_COLOR = 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_COLOR'
export const CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_KILOMETRAGE = 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_KILOMETRAGE'
export const CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_IDENTIFICATION_NUMBER = 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_IDENTIFICATION_NUMBER'
export const CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_CONFIGURATION = 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_CONFIGURATION'
export const CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_CONDITION = 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_CONDITION'
export const CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_DAMAGED_DESCRIPTION = 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_DAMAGED_DESCRIPTION'
export const CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_DAMAGE_PRESENCE = 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_DAMAGE_PRESENCE'
export const CREATE_ITEMS_VEHICLE_ITEM_QUANTITY = 'CREATE_ITEMS_VEHICLE_ITEM_QUANTITY'
export const CREATE_ITEMS_VEHICLE_ITEM_UNIT_CODE = 'CREATE_ITEMS_VEHICLE_ITEM_UNIT_CODE'

export const CREATE_ITEMS_ZU_CHOUSEN_TYPE = 'CREATE_ITEMS_ZU_CHOUSEN_TYPE'

export const CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_TITLE = 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_TITLE'
export const CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_DESCRIPTION = 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_DESCRIPTION'
export const CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_CLASSIFICATION = 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_CLASSIFICATION'
export const CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ADDITIONAL_CLASSIFICATION = 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ADDITIONAL_CLASSIFICATION'
export const CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ADDRESS = 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ADDRESS'
export const CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_LOCATION_LATITUDE = 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_LOCATION_LATITUDE'
export const CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_LOCATION_LONGITUDE = 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_LOCATION_LONGITUDE'
export const CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS = 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS'
export const CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_REGISTRATION_DETAILS_REGISTRATION_ID = 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_REGISTRATION_DETAILS_REGISTRATION_ID'
export const CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_REGISTRATION_DETAILS_REGISTRATION_DATE = 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_REGISTRATION_DETAILS_REGISTRATION_DATE'
export const CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_QUANTITY_AND_NOMENCLATURE = 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_QUANTITY_AND_NOMENCLATURE'
export const CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_WORKPLACES_QUANTITY = 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_WORKPLACES_QUANTITY'
export const CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_BUILDINGS_INFORMATION = 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_BUILDINGS_INFORMATION'
export const CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PLOT_INFORMATION = 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PLOT_INFORMATION'
export const CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_LAND_AREA = 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_LAND_AREA'
export const CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_CADASTRAL_NUMBER = 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_CADASTRAL_NUMBER'
export const CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_OWNERSHIP_TYPE = 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_OWNERSHIP_TYPE'
export const CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_ENCUMBRANCES = 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_ENCUMBRANCES'
export const CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_JOINT_OWNERSHIP = 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_JOINT_OWNERSHIP'
export const CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_UTILITIES_AVAILABILITY = 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_UTILITIES_AVAILABILITY'
export const CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_LAND_INTENDED_USE = 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_LAND_INTENDED_USE'
export const CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_MAIN_OBLIGATIONS = 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_MAIN_OBLIGATIONS'
export const CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_QUANTITY = 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_QUANTITY'
export const CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_UNIT_CODE = 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_UNIT_CODE'


export const CREATE_ITEMS_OTHER_PROPERTY_ITEMS_TITLE = 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_TITLE'
export const CREATE_ITEMS_OTHER_PROPERTY_ITEMS_DESCRIPTION = 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_DESCRIPTION'
export const CREATE_ITEMS_OTHER_PROPERTY_ITEMS_CLASSIFICATION = 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_CLASSIFICATION'
export const CREATE_ITEMS_OTHER_PROPERTY_ITEMS_ADDITIONAL_CLASSIFICATION = 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_ADDITIONAL_CLASSIFICATION'
export const CREATE_ITEMS_OTHER_PROPERTY_ITEMS_ADDRESS = 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_ADDRESS'
export const CREATE_ITEMS_OTHER_PROPERTY_ITEMS_LOCATION_LATITUDE = 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_LOCATION_LATITUDE'
export const CREATE_ITEMS_OTHER_PROPERTY_ITEMS_LOCATION_LONGITUDE = 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_LOCATION_LONGITUDE'
export const CREATE_ITEMS_OTHER_PROPERTY_ITEMS_LOCATION_ELEVATION = 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_LOCATION_ELEVATION'
export const CREATE_ITEMS_OTHER_PROPERTY_ITEMS_REGISTRATION_DETAILS_REGISTRATION_STATUS = 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_REGISTRATION_DETAILS_REGISTRATION_STATUS'
export const CREATE_ITEMS_OTHER_PROPERTY_ITEMS_REGISTRATION_DETAILS_REGISTRATION_ID = 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_REGISTRATION_DETAILS_REGISTRATION_ID'
export const CREATE_ITEMS_OTHER_PROPERTY_ITEMS_REGISTRATION_DETAILS_REGISTRATION_DATE = 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_REGISTRATION_DETAILS_REGISTRATION_DATE'
export const CREATE_ITEMS_OTHER_PROPERTY_ITEMS_ITEM_TYPE = 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_ITEM_TYPE'
export const CREATE_ITEMS_OTHER_PROPERTY_ITEMS_QUANTITY = 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_QUANTITY'
export const CREATE_ITEMS_OTHER_PROPERTY_ITEMS_UNIT_CODE = 'CREATE_ITEMS_OTHER_PROPERTY_ITEMS_UNIT_CODE'

export const SET_SCHEDULE_LENGTH_YEARS = 'SET_SCHEDULE_LENGTH_YEARS'
export const SET_SCHEDULE_LENGTH_MONTHS = 'SET_SCHEDULE_LENGTH_MONTHS'
export const SET_SCHEDULE_LENGTH_DAYS = 'SET_SCHEDULE_LENGTH_DAYS'
export const SET_SCHEDULE_MONDAY = 'SET_SCHEDULE_MONDAY'
export const SET_SCHEDULE_TUESDAY = 'SET_SCHEDULE_TUESDAY'
export const SET_SCHEDULE_WEDNESDAY = 'SET_SCHEDULE_WEDNESDAY'
export const SET_SCHEDULE_THURSDAY = 'SET_SCHEDULE_THURSDAY'
export const SET_SCHEDULE_FRIDAY = 'SET_SCHEDULE_FRIDAY'
export const SET_SCHEDULE_SATURDAY = 'SET_SCHEDULE_SATURDAY'
export const SET_SCHEDULE_SUNDAY = 'SET_SCHEDULE_SUNDAY'
export const SET_SCHEDULE_PLANNED_EVENTS = 'SET_SCHEDULE_PLANNED_EVENTS'

export const SET_AUCTION_PROPERTY_OWNER_ID = 'SET_AUCTION_PROPERTY_OWNER_ID'
export const SET_AUCTION_SELLING_ENTITY_ID = 'SET_AUCTION_SELLING_ENTITY_ID'
export const SET_AUCTION_CURRENT_TENANT_ID = 'SET_AUCTION_CURRENT_TENANT_ID'
export const SET_AUCTION_OWNERSHIP_TYPE = 'SET_AUCTION_OWNERSHIP_TYPE'

export const GET_ADDRESS_NEW_BOOKS = 'GET_ADDRESS_NEW_BOOKS'
export const CREATE_ADDRESS_NEW_OPEN_CLOSE = 'CREATE_ADDRESS_NEW_OPEN_CLOSE'

export const CREATE_ADDRESS_NEW_ID = 'CREATE_ADDRESS_NEW_ID'
export const CREATE_ADDRESS_NEW_NAME = 'CREATE_ADDRESS_NEW_NAME'
export const CREATE_ADDRESS_NEW_IDENTIFIER_ID = 'CREATE_ADDRESS_NEW_IDENTIFIER_ID'
export const CREATE_ADDRESS_NEW_IDENTIFIER_IDENTIFIER = 'CREATE_ADDRESS_NEW_IDENTIFIER_IDENTIFIER'
export const CREATE_ADDRESS_NEW_IDENTIFIER_SCHEME = 'CREATE_ADDRESS_NEW_IDENTIFIER_SCHEME'
export const CREATE_ADDRESS_NEW_IDENTIFIER_LEGAL_NAME = 'CREATE_ADDRESS_NEW_IDENTIFIER_LEGAL_NAME'
export const CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_ID = 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_ID'
export const CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_IDENTIFIER = 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_IDENTIFIER'
export const CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_SCHEME = 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_SCHEME'
export const CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_LEGAL_NAME = 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_LEGAL_NAME'
export const CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_ID = 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_ID'
export const CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_IDENTIFIER = 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_IDENTIFIER'
export const CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_SCHEME = 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_SCHEME'
export const CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_LEGAL_NAME = 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_LEGAL_NAME'
export const CREATE_ADDRESS_NEW_ADDRESS_ID = 'CREATE_ADDRESS_NEW_ADDRESS_ID'
export const CREATE_ADDRESS_NEW_ADDRESS_STREET_ADDRESS = 'CREATE_ADDRESS_NEW_ADDRESS_STREET_ADDRESS'
export const CREATE_ADDRESS_NEW_ADDRESS_LOCALITY = 'CREATE_ADDRESS_NEW_ADDRESS_LOCALITY'
export const CREATE_ADDRESS_NEW_ADDRESS_REGION = 'CREATE_ADDRESS_NEW_ADDRESS_REGION'
export const CREATE_ADDRESS_NEW_ADDRESS_POSTAL_CODE = 'CREATE_ADDRESS_NEW_ADDRESS_POSTAL_CODE'
export const CREATE_ADDRESS_NEW_ADDRESS_COUNTRY_NAME = 'CREATE_ADDRESS_NEW_ADDRESS_COUNTRY_NAME'
export const CREATE_ADDRESS_NEW_CONTACT_POINT_ID = 'CREATE_ADDRESS_NEW_CONTACT_POINT_ID'
export const CREATE_ADDRESS_NEW_CONTACT_POINT_NAME = 'CREATE_ADDRESS_NEW_CONTACT_POINT_NAME'
export const CREATE_ADDRESS_NEW_CONTACT_POINT_EMAIL = 'CREATE_ADDRESS_NEW_CONTACT_POINT_EMAIL'
export const CREATE_ADDRESS_NEW_CONTACT_POINT_TELEPHONE = 'CREATE_ADDRESS_NEW_CONTACT_POINT_TELEPHONE'
export const CREATE_ADDRESS_NEW_CONTACT_POINT_FAX_NUMBER = 'CREATE_ADDRESS_NEW_CONTACT_POINT_FAX_NUMBER'
export const CREATE_ADDRESS_NEW_CONTACT_POINT_URL = 'CREATE_ADDRESS_NEW_CONTACT_POINT_URL'
export const CREATE_ADDRESS_NEW_REPRESENTATIVE_INFO = 'CREATE_ADDRESS_NEW_REPRESENTATIVE_INFO'

export const CREATE_LEASE_RULES_INTENDED_USE = 'CREATE_LEASE_RULES_INTENDED_USE'
export const CREATE_LEASE_RULES_INTENDED_USE_RESTRICTION_METHOD = 'CREATE_LEASE_RULES_INTENDED_USE_RESTRICTION_METHOD'
export const CREATE_LEASE_RULES_INTENDED_USE_RESTRICTION_DESCRIPTION = 'CREATE_LEASE_RULES_INTENDED_USE_RESTRICTION_DESCRIPTION'
export const CREATE_LEASE_RULES_ADDITIONAL_LEASE_CONDITIONS = 'CREATE_LEASE_RULES_ADDITIONAL_LEASE_CONDITIONS'
export const CREATE_LEASE_RULES_SUBLEASE_ALLOWED = 'CREATE_LEASE_RULES_SUBLEASE_ALLOWED'
export const CREATE_VALUE_PERIOD = 'CREATE_VALUE_PERIOD'
export const CREATE_REGISTRATION_FEE = 'CREATE_REGISTRATION_FEE'
export const CREATE_REGISTRATION_FEE_TAX = 'CREATE_REGISTRATION_FEE_TAX'
export const CREATE_REGISTRY_ID = 'CREATE_REGISTRY_ID'
export const CREATE_VALUE_ADDED_TAX_CHARGED = 'CREATE_VALUE_ADDED_TAX_CHARGED';
export const CREATE_CALC_ON_BOOK_VALUE = 'CREATE_CALC_ON_BOOK_VALUE';

export const CREATE_ITEMS_DGF_MINNUMBEROFQUALIFIEDBIDS = 'CREATE_ITEMS_DGF_MINNUMBEROFQUALIFIEDBIDS';
export const CREATE_ITEMS_DGF_VIRTUALDATAROOM = 'CREATE_ITEMS_DGF_VIRTUALDATAROOM';
export const CREATE_ITEMS_DGF_ELIGIBILITYCRITERIA = 'CREATE_ITEMS_DGF_ELIGIBILITYCRITERIA';
export const CREATE_ITEMS_DGF_X_DGFPLATFORMLEGALDETAILS = 'CREATE_ITEMS_DGF_X_DGFPLATFORMLEGALDETAILS';
export const CREATE_ITEMS_DGF_X_DGFDECISION_ID = 'CREATE_ITEMS_DGF_X_DGFDECISION_ID';
export const CREATE_ITEMS_DGF_X_DGFDECISION_DATE = 'CREATE_ITEMS_DGF_X_DGFDECISION_DATE';

export const SET_MY_CURRENT_PAGE = 'SET_MY_CURRENT_PAGE';
export const SET_MY_CABINET_PAGE = 'SET_MY_CABINET_PAGE';

export const SET_DICTIONARIES_OWNERSHIPTYPE = 'SET_DICTIONARIES_OWNERSHIPTYPE';
export const SET_DICTIONARIES_ENCUMBRANCES = 'SET_DICTIONARIES_ENCUMBRANCES';
export const SET_DICTIONARIES_POWERSUPPLYCLASS = 'SET_DICTIONARIES_POWERSUPPLYCLASS';
export const SET_DICTIONARIES_FLOORTYPE = 'SET_DICTIONARIES_FLOORTYPE';
export const SET_DICTIONARIES_CONSTRUCTIONTECHNOLOGY = 'SET_DICTIONARIES_CONSTRUCTIONTECHNOLOGY';
export const SET_DICTIONARIES_FUELTYPE = 'SET_DICTIONARIES_FUELTYPE';
export const SET_DICTIONARIES_TRANSMISSIONTYPE = 'SET_DICTIONARIES_TRANSMISSIONTYPE';
export const SET_DICTIONARIES_LOCATIONINBUILDING = 'SET_DICTIONARIES_LOCATIONINBUILDING';
export const SET_DICTIONARIES_ELIGIBILITYCRITERIA = 'SET_DICTIONARIES_ELIGIBILITYCRITERIA';

export const CREATE_ITEMS_DGF_REALESTATEPROPS_ID = 'CREATE_ITEMS_DGF_REALESTATEPROPS_ID';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_TOTALBUILDINGAREA = 'CREATE_ITEMS_DGF_REALESTATEPROPS_TOTALBUILDINGAREA';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_TOTALOBJECTAREA = 'CREATE_ITEMS_DGF_REALESTATEPROPS_TOTALOBJECTAREA';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_USABLEAREA = 'CREATE_ITEMS_DGF_REALESTATEPROPS_USABLEAREA';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_CONSTRUCTIONYEAR = 'CREATE_ITEMS_DGF_REALESTATEPROPS_CONSTRUCTIONYEAR';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_CONSTRUCTIONTECHNOLOGY = 'CREATE_ITEMS_DGF_REALESTATEPROPS_CONSTRUCTIONTECHNOLOGY';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_LIVINGAREA = 'CREATE_ITEMS_DGF_REALESTATEPROPS_LIVINGAREA';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_KITCHENAREA = 'CREATE_ITEMS_DGF_REALESTATEPROPS_KITCHENAREA';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_LANDAREA = 'CREATE_ITEMS_DGF_REALESTATEPROPS_LANDAREA';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_LOCATIONINBUILDING = 'CREATE_ITEMS_DGF_REALESTATEPROPS_LOCATIONINBUILDING';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_FLOORS = 'CREATE_ITEMS_DGF_REALESTATEPROPS_FLOORS';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_GENERALCONDITION = 'CREATE_ITEMS_DGF_REALESTATEPROPS_GENERALCONDITION';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEELECTRICITY = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEELECTRICITY';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_POWERSUPPLYCAPACITY = 'CREATE_ITEMS_DGF_REALESTATEPROPS_POWERSUPPLYCAPACITY';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_POWERSUPPLYCLASS = 'CREATE_ITEMS_DGF_REALESTATEPROPS_POWERSUPPLYCLASS';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEWATER = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEWATER';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESEWERAGE = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESEWERAGE';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEGAS = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEGAS';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICECENTRALHEATING = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICECENTRALHEATING';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEAUTONOMOUSHEATING = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEAUTONOMOUSHEATING';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEHEATINGCOUNTER = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEHEATINGCOUNTER';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEVENTILATION = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEVENTILATION';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEAIRCONDITIONING = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEAIRCONDITIONING';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEPHONE = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEPHONE';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICETV = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICETV';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEINTERNET = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEINTERNET';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEELEVATOR = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEELEVATOR';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESECURITYALARM = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESECURITYALARM';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEFIREALARM = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEFIREALARM';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESDESCRIPTION = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESDESCRIPTION';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESACCOUNTING = 'CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESACCOUNTING';
export const CREATE_ITEMS_DGF_REALESTATEPROPS_LANDTAX = 'CREATE_ITEMS_DGF_REALESTATEPROPS_LANDTAX';

export const CREATE_ITEMS_DGF_VEHICLEPROPS_ID = 'CREATE_ITEMS_DGF_VEHICLEPROPS_ID';
export const CREATE_ITEMS_DGF_VEHICLEPROPS_BRAND = 'CREATE_ITEMS_DGF_VEHICLEPROPS_BRAND';
export const CREATE_ITEMS_DGF_VEHICLEPROPS_MODEL = 'CREATE_ITEMS_DGF_VEHICLEPROPS_MODEL';
export const CREATE_ITEMS_DGF_VEHICLEPROPS_PRODUCTIONYEAR = 'CREATE_ITEMS_DGF_VEHICLEPROPS_PRODUCTIONYEAR';
export const CREATE_ITEMS_DGF_VEHICLEPROPS_ENGINECAPACITY = 'CREATE_ITEMS_DGF_VEHICLEPROPS_ENGINECAPACITY';
export const CREATE_ITEMS_DGF_VEHICLEPROPS_FUELTYPE = 'CREATE_ITEMS_DGF_VEHICLEPROPS_FUELTYPE';
export const CREATE_ITEMS_DGF_VEHICLEPROPS_TRANSMISSION = 'CREATE_ITEMS_DGF_VEHICLEPROPS_TRANSMISSION';
export const CREATE_ITEMS_DGF_VEHICLEPROPS_COLOR = 'CREATE_ITEMS_DGF_VEHICLEPROPS_COLOR';
export const CREATE_ITEMS_DGF_VEHICLEPROPS_KILOMETRAGE = 'CREATE_ITEMS_DGF_VEHICLEPROPS_KILOMETRAGE';
export const CREATE_ITEMS_DGF_VEHICLEPROPS_IDENTIFICATIONNUMBER = 'CREATE_ITEMS_DGF_VEHICLEPROPS_IDENTIFICATIONNUMBER';
export const CREATE_ITEMS_DGF_VEHICLEPROPS_CONFIGURATION = 'CREATE_ITEMS_DGF_VEHICLEPROPS_CONFIGURATION';
export const CREATE_ITEMS_DGF_VEHICLEPROPS_CONDITION = 'CREATE_ITEMS_DGF_VEHICLEPROPS_CONDITION';
export const CREATE_ITEMS_DGF_VEHICLEPROPS_DAMAGEPRESENCE = 'CREATE_ITEMS_DGF_VEHICLEPROPS_DAMAGEPRESENCE';
export const CREATE_ITEMS_DGF_VEHICLEPROPS_DAMAGEDDESCRIPTION = 'CREATE_ITEMS_DGF_VEHICLEPROPS_DAMAGEDDESCRIPTION';

export const CREATE_ITEMS_DGF_LANDPROPS_ID = 'CREATE_ITEMS_DGF_LANDPROPS_ID';
export const CREATE_ITEMS_DGF_LANDPROPS_LANDAREA = 'CREATE_ITEMS_DGF_LANDPROPS_LANDAREA';
export const CREATE_ITEMS_DGF_LANDPROPS_CADASTRALNUMBER = 'CREATE_ITEMS_DGF_LANDPROPS_CADASTRALNUMBER';
export const CREATE_ITEMS_DGF_LANDPROPS_OWNERSHIPTYPE = 'CREATE_ITEMS_DGF_LANDPROPS_OWNERSHIPTYPE';
export const CREATE_ITEMS_DGF_LANDPROPS_ENCUMBRANCES = 'CREATE_ITEMS_DGF_LANDPROPS_ENCUMBRANCES';
export const CREATE_ITEMS_DGF_LANDPROPS_JOINTOWNERSHIP = 'CREATE_ITEMS_DGF_LANDPROPS_JOINTOWNERSHIP';
export const CREATE_ITEMS_DGF_LANDPROPS_UTILITIESAVAILABILITY = 'CREATE_ITEMS_DGF_LANDPROPS_UTILITIESAVAILABILITY';

export const CREATE_ITEMS_DGF_LANDPROPS_HASENCUMBRANCES = "CREATE_ITEMS_DGF_LANDPROPS_HASENCUMBRANCES";
export const CREATE_ITEMS_DGF_LANDPROPS_HASJOINTOWNERSHIP = "CREATE_ITEMS_DGF_LANDPROPS_HASJOINTOWNERSHIP";
export const CREATE_ITEMS_DGF_LANDPROPS_HASUTILITIESAVAILABILITY = "CREATE_ITEMS_DGF_LANDPROPS_HASUTILITIESAVAILABILITY";

export const CREATE_ITEMS_DGF_LANDPROPS_INTENDEDUSE = 'CREATE_ITEMS_DGF_LANDPROPS_INTENDEDUSE';

export const CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_ID = 'CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_ID';
export const CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_QUANTITYANDNOMENCLATURE = 'CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_QUANTITYANDNOMENCLATURE';
export const CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_WORKPLACESQUANTITY = 'CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_WORKPLACESQUANTITY';
export const CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_BUILDINGSINFORMATION = 'CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_BUILDINGSINFORMATION';
export const CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_LANDPLOTINFORMATION = 'CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_LANDPLOTINFORMATION';
export const CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_MAINOBLIGATIONS = 'CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_MAINOBLIGATIONS';
export const CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_LEASEAPPROVERWEBSITE = 'CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_LEASEAPPROVERWEBSITE';

export const CREATE_ITEMS_LOCATION_LATITUDE = 'CREATE_ITEMS_LOCATION_LATITUDE';
export const CREATE_ITEMS_LOCATION_LONGITUDE = 'CREATE_ITEMS_LOCATION_LONGITUDE';
export const CREATE_ITEMS_LOCATION_ELEVATION = 'CREATE_ITEMS_LOCATION_ELEVATION';

export const SET_DGF_MASK = 'SET_DGF_MASK';

export const SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD = 'SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD';

export const OPEN_BANK_WINDOW = 'OPEN_BANK_WINDOW';

export const SET_ACCORDIONS_BIDS = 'SET_ACCORDIONS_BIDS';
export const SET_ACCORDIONS_AWARDS = 'SET_ACCORDIONS_AWARDS';
export const SET_ACCORDIONS_CONTRACTS = 'SET_ACCORDIONS_CONTRACTS';
export const SET_ACCORDIONS_CHANCEL = 'SET_ACCORDIONS_CHANCEL';

export const SAVE_HISTORY_DOCS_CONTRACT = 'SAVE_HISTORY_DOCS_CONTRACT';


export const SET_CONTRACT_TIME_ADDED_DATE_FROM = 'SET_CONTRACT_TIME_ADDED_DATE_FROM';
export const SET_CONTRACT_TIME_ADDED_DATE_TILL = 'SET_CONTRACT_TIME_ADDED_DATE_TILL';


export const SET_SINGL_PASSWORD = 'SET_SINGL_PASSWORD';

export const WORK_BANK_ACCOUNT_SAVE = 'WORK_BANK_ACCOUNT_SAVE';
export const WORK_BANK_ACCOUNT_ADD = 'WORK_BANK_ACCOUNT_ADD';
export const WORK_BANK_ACCOUNT_DELETE = 'WORK_BANK_ACCOUNT_DELETE';


export const PAYMENT_POP_UP_SET_BANK = 'PAYMENT_POP_UP_SET_BANK';

export const SET_NADRA_CLASSIFICATOR_ADD = 'SET_NADRA_CLASSIFICATOR_ADD';

export const PAYMENT_POP_UP_GET_DATA = 'PAYMENT_POP_UP_GET_DATA';
export const PAYMENT_POP_UP = 'PAYMENT_POP_UP';
export const PAYMENT_POP_UP1 = 'PAYMENT_POP_UP1';
export const PAYMENT_POP_UP_CREATE_PDF = 'PAYMENT_POP_UP_CREATE_PDF';

export const ALL_AUCTION_BIDS_ARR = 'ALL_AUCTION_BIDS_ARR';
export const ALL_AUCTION_BIDS_AUCTION_ID = 'ALL_AUCTION_BIDS_AUCTION_ID';

export const CHECKBOX_BID_PUBLISH = 'CHECKBOX_BID_PUBLISH';

export const POPUP_EDIT_APPROVE_DOC = 'POPUP_EDIT_APPROVE_DOC';
export const POPUP_EDIT_APPROVE_DOC_TITLE = 'POPUP_EDIT_APPROVE_DOC_TITLE';
export const POPUP_EDIT_APPROVE_DOC_DISCRIP = 'POPUP_EDIT_APPROVE_DOC_DISCRIP';
export const POPUP_EDIT_APPROVE_DOC_FILES = 'POPUP_EDIT_APPROVE_DOC_FILES';

export const TOGETHER_API_PAGE_AUCTION_END_PAY = 'TOGETHER_API_PAGE_AUCTION_END_PAY';

export const WORK_MY_PAGE_CURRENT_AUCTION = 'WORK_MY_PAGE_CURRENT_AUCTION';
export const WORK_MY__API_PAGE_NEXT_AUCTION = 'WORK_MY__API_PAGE_NEXT_AUCTION';
export const TOGETHER_API_PAGE_CURRENT_AUCTION = 'TOGETHER_API_PAGE_CURRENT_AUCTION';
export const TOGETHER_API_PAGE_NEXT_AUCTION = 'TOGETHER_API_PAGE_NEXT_AUCTION';

export const EDIT_AUCTION_FROM_ID = 'EDIT_AUCTION_FROM_ID';
export const POP_UP_ADDRESS_ID = 'POP_UP_ADDRESS_ID';

export const SET_POP_UP_BANK_ACCOUNT_EDIT_ID = 'SET_POP_UP_BANK_ACCOUNT_EDIT_ID';

export const CREATE_AUCTION_RAILWAY_DUTCH_STEP_QUANTITY = 'CREATE_AUCTION_RAILWAY_DUTCH_STEP_QUANTITY';
export const CREATE_AUCTION_RAILWAY_DUTCH_STEP_PERCENT = 'CREATE_AUCTION_RAILWAY_DUTCH_STEP_PERCENT';
export const CREATE_AUCTION_RAILWAY_DUTCH_STEP_VALUE_AMOUNT = 'CREATE_AUCTION_RAILWAY_DUTCH_STEP_VALUE_AMOUNT';

export const SET_END_POINT = 'SET_END_POINT';
export const DISCOUNT_ON_OFF = 'DISCOUNT_ON_OFF';

export const AWARD_DOCUMENT_STATUS = 'AWARD_DOCUMENT_STATUS';

export const HISTERY_AUCTIONS = 'HISTERY_AUCTIONS';
export const HISTERY_AWARDS = 'HISTERY_AWARDS';
export const HISTERY_CONTRACTS = 'HISTERY_CONTRACTS';
export const HISTERY_BIDS = 'HISTERY_BIDS';

export const PROXY_TIMBER_SPECIES = 'PROXY_TIMBER_SPECIES';
export const PROXY_TIMBER_SORTMENT = 'PROXY_TIMBER_SORTMENT';
export const PROXY_TIMBER_STORAGE = 'PROXY_TIMBER_STORAGE';
export const PROXY_TIMBER_DIAMETER = 'PROXY_TIMBER_DIAMETER';
export const PROXY_TIMBER_CLASS = 'PROXY_TIMBER_CLASS';


export const ECP_API_PASS = 'ECP_API_PASS';
export const TOGETHER_API_AUCTION_NULL = 'TOGETHER_API_AUCTION_NULL';
export const TOGETHER_API_LIST = 'TOGETHER_API_LIST';
export const TOGETHER_API_LIST_CA = 'TOGETHER_API_LIST_CA';

export const TOGETHER_API_PAGE_SWITCH = 'TOGETHER_API_PAGE_SWITCH';
export const TOGETHER_API_BIDS_ARR = 'TOGETHER_API_BIDS_ARR';
export const TOGETHER_API_POPUP_ONOFF = 'TOGETHER_API_POPUP_ONOFF';
export const TOGETHER_API_DOC_TITLE = 'TOGETHER_API_DOC_TITLE';
export const TOGETHER_API_DOC_DESCRIPTION = 'TOGETHER_API_DOC_DESCRIPTION';
export const TOGETHER_API_DOC_TYPE = 'TOGETHER_API_DOC_TYPE';
export const TOGETHER_API_DOC_LANG = 'TOGETHER_API_DOC_LANG';
export const TOGETHER_API_DOC_FILENAME = 'TOGETHER_API_DOC_FILENAME';
export const TOGETHER_API_DOC_FILENAME_ECP = 'TOGETHER_API_DOC_FILENAME_ECP';
export const TOGETHER_API_ECP = 'TOGETHER_API_ECP';
export const TOGETHER_API_DRAFT_BID_SUMM = 'TOGETHER_API_DRAFT_BID_SUMM';
export const TOGETHER_API_BIDS_WORK_ARR = 'TOGETHER_API_BIDS_WORK_ARR';
export const TOGETHER_API_PULL_AUC_EI = 'TOGETHER_API_PULL_AUC_EI';
export const TOGETHER_API_BASE_PULL_AUC_EI = 'TOGETHER_API_BASE_PULL_AUC_EI';
export const TOGETHER_API_SAVE_DATA_LOAD = 'TOGETHER_API_SAVE_DATA_LOAD';
export const TOGETHER_API_SAVE_VALUE = 'TOGETHER_API_SAVE_VALUE';

export const TOGETHER_API_TIMER_ROUND = 'TOGETHER_API_TIMER_ROUND';
export const TOGETHER_API_TIMER_TIME = 'TOGETHER_API_TIMER_TIME'
export const TOGETHER_API_TIMER_TIMELEFT = 'TOGETHER_API_TIMER_TIMELEFT'

export const SET_MIN_NUMBER_OF_QUALIFIED_BIDS = 'SET_MIN_NUMBER_OF_QUALIFIED_BIDS';

export const POPUP_CONTRACT_DOC_TITLE = 'POPUP_CONTRACT_DOC_TITLE';
export const POPUP_CONTRACT_DOC_TYPE = 'POPUP_CONTRACT_DOC_TYPE';
export const POPUP_CONTRACT_DOC_DESCRIPTION = 'POPUP_CONTRACT_DOC_DESCRIPTION';

export const POPUP_DATE_DELETE_AUCTION = 'POPUP_DATE_DELETE_AUCTION';
export const UNIVERSAL_ERROR = 'UNIVERSAL_ERROR';

export const ECP_POPUP = 'ECP_POPUP';
export const ECP_LINK = 'ECP_LINK';

export const POPUP_AUCTION_DELETE_TITLE = 'POPUP_AUCTION_DELETE_TITLE';

export const BID_POPUP_EDIT_DOC = 'BID_POPUP_EDIT_DOC';
export const BID_POPUP_EDIT_DOC_TITLE = 'BID_POPUP_EDIT_DOC_TITLE';
export const BID_POPUP_EDIT_DOC_DESCRIPTION = 'BID_POPUP_EDIT_DOC_DESCRIPTION';
export const BID_POPUP_EDIT_DOC_TYPE = 'BID_POPUP_EDIT_DOC_TYPE';

export const KOATUU_REGION = 'KOATUU_REGION';
export const KOATUU_GOROD = 'KOATUU_GOROD';

export const BID_POPUP_HELP_PUBLISH = 'BID_POPUP_HELP_PUBLISH';

export const CHANGE_GEOLOGIV_DATA_VALUE = 'CHANGE_GEOLOGIV_DATA_VALUE';
export const CHANGE_DOCUMENTATION_VALUE = 'CHANGE_DOCUMENTATION_VALUE';
export const CHANGE_DATE_USES = 'CHANGE_DATE_USES';

export const CHANGE_LOADER = 'CHANGE_LOADER';

export const STREET_ADDRESS = 'STREET_ADDRESS';
export const LOCALITY_ADDRESS = 'LOCALITY_ADDRESS';
export const REGION_ADDRESS = 'REGION_ADDRESS';
export const POSTAL_CODE_ADDRESS = 'POSTAL_CODE_ADDRESS';
export const COUNTRY_NAME_ADDRESS = 'COUNTRY_NAME_ADDRESS';
export const POP_UP_ADDRESS = 'POP_UP_ADDRESS';
export const SET_POP_UP_ADDRESS_ERROR = 'SET_POP_UP_ADDRESS_ERROR';
export const SET_POP_UP_ADDRESS_COMPLITE = 'SET_POP_UP_ADDRESS_COMPLITE';

export const SET_AWARD_REJECT_TITLE = 'SET_AWARD_REJECT_TITLE';
export const SET_AWARD_REJECT_DESCRIPTION = 'SET_AWARD_REJECT_DESCRIPTION';
export const AWARD_DOCUMENT_RESTRICT = 'AWARD_DOCUMENT_RESTRICT';
export const SET_AWARD_REJECT_DOCUMENT_TYPE = 'SET_AWARD_REJECT_DOCUMENT_TYPE';

export const SET_KEY_INTERVAL = 'SET_KEY_INTERVAL';
export const SET_LOGO = 'SET_LOGO';
export const SET_POPUP_LOGIN = 'SET_POPUP_LOGIN';
export const SET_SEARCH_TEXT_STRING = 'SET_SEARCH_TEXT_STRING';
export const SET_SEARCH_TYPE_KEYWORD = 'SET_SEARCH_TYPE_KEYWORD';
export const SET_SEARCH_DATE_START = 'SET_SEARCH_DATE_START';
export const SET_SEARCH_DATE_END = 'SET_SEARCH_DATE_END';
export const SET_SEARCH_SORT = 'SET_SEARCH_SORT';
export const AUCTIONS_GET_SEND = 'AUCTIONS_GET_SEND';

export const SET_AUCTIONS_PATH = 'SET_AUCTIONS_PATH';
export const SET_CATEGORY_AUCTION = 'SET_CATEGORY_AUCTION';
export const SET_PRICE_FROM = 'SET_PRICE_FROM';
export const SET_PRICE_FROM_BAG = 'SET_PRICE_FROM_BAG';
export const SET_PRICE_TO = 'SET_PRICE_TO';
export const SET_PRICE_TO_BAG = 'SET_PRICE_TO_BAG';

export const DOCUMENT_COUNNT_ARR_FILES = 'DOCUMENT_COUNNT_ARR_FILES';
export const DOCUMENT_COUNNT_FILES = 'DOCUMENT_COUNNT_FILES';

export const DOCUMENT_BID_BLOCK_STATUS = 'DOCUMENT_BID_BLOCK_STATUS';
export const DOCUMENT_BID_PUBLIC_NAME = 'DOCUMENT_BID_PUBLIC_NAME';

export const CHANGE_MENU = 'CHANGE_MENU';
export const CHANGE_WINDOW = 'CHANGE_WINDOW';
export const CHANGE_LANG = 'CHANGE_LANG';
export const CHANGE_ADVANCED_FIND = 'CHANGE_ADVANCED_FIND';
export const CHANGE_MENU_ON_OFF = 'CHANGE_MENU_ON_OFF';
export const BID_AWARDS = 'BID_AWARDS';
export const BID_QUANTITY = 'BID_QUANTITY';
export const BID_POPUP_EDIT = 'BID_POPUP_EDIT';

export const AUC_TEST = 'AUC_TEST';


export const CREATE_AUCTION_TAB = 'CREATE_AUCTION_TAB';
//Создание аукциона
export const CREATE_CHANGET_AUCTION = 'CREATE_CHANGET_AUCTION';
export const CREATE_AUCTION_TITLE = 'CREATE_AUCTION_TITLE';
export const CREATE_AUCTION_DESCRIPTION = 'CREATE_AUCTION_DESCRIPTION';
export const CREATE_AUCTION_TYPE = 'CREATE_AUCTION_TYPE';
export const CREATE_AUCTION_TENDER_ATTEMPTS = 'CREATE_AUCTION_TENDER_ATTEMPTS';
export const CREATE_AUCTION_ACCESS_DETAILS = 'CREATE_AUCTION_ACCESS_DETAILS';
export const CREATE_AUCTION_XDOCUMENT_REQUIREMENTS = 'CREATE_AUCTION_XDOCUMENT_REQUIREMENTS';
export const CREATE_AUCTION_XADDITIONAL_INFORMATION = 'CREATE_AUCTION_XADDITIONAL_INFORMATION';
export const CREATE_AUCTION_VALUE_CURRENCY = 'CREATE_AUCTION_VALUE_CURRENCY';
export const CREATE_AUCTION_VALUE_AMOUNT = 'CREATE_AUCTION_VALUE_AMOUNT';
export const CREATE_AUCTION_VALUE_TAX = 'CREATE_AUCTION_VALUE_TAX';
export const CREATE_AUCTION_GUARANTEE_CURRENCY = 'CREATE_AUCTION_GUARANTEE_CURRENCY';
export const CREATE_AUCTION_GUARANTEE_AMOUNT = 'CREATE_AUCTION_GUARANTEE_AMOUNT';
export const CREATE_AUCTION_GUARANTEE_TAX = 'CREATE_AUCTION_GUARANTEE_TAX';
export const CREATE_AUCTION_MINIMAL_STEP_CURRENCY = 'CREATE_AUCTION_MINIMAL_STEP_CURRENCY';
export const CREATE_AUCTION_MINIMAL_STEP_AMOUNT = 'CREATE_AUCTION_MINIMAL_STEP_AMOUNT';
export const CREATE_AUCTION_MINIMAL_STEP_TAX = 'CREATE_AUCTION_MINIMAL_STEP_TAX';
export const CREATE_AUCTION_DATE_START = 'CREATE_AUCTION_DATE_START';
export const CREATE_AUCTION_DATE_END = 'CREATE_AUCTION_DATE_END';
export const CREATE_AUCTION_ID = 'CREATE_AUCTION_ID';

export const CREATE_DOCUMENT_FILE_NAME = 'CREATE_DOCUMENT_FILE_NAME';

export const CREATE_ITEMS_AUCTION_ID = 'CREATE_ITEMS_AUCTION_ID';
export const CREATE_ITEMS_CLASSIFICATION_ID = 'CREATE_ITEMS_CLASSIFICATION_ID';
export const CREATE_ITEMS_UNIT_VALUE_CURRENCY = 'CREATE_ITEMS_UNIT_VALUE_CURRENCY';
export const CREATE_ITEMS_UNIT_VALUE_AMOUNT = 'CREATE_ITEMS_UNIT_VALUE_AMOUNT';
export const CREATE_ITEMS_UNIT_VALUE_TAX = 'CREATE_ITEMS_UNIT_VALUE_TAX';
export const CREATE_ITEMS_DESCRIPTION = 'CREATE_ITEMS_DESCRIPTION';

export const NUMBER_PAGE_LOT = 'NUMBER_PAGE_LOT';

export const SET_POP_UP_SEND_AUCTION = 'SET_POP_UP_SEND_AUCTION';
export const SET_POP_UP_DEL_AUCTION = 'SET_POP_UP_DEL_AUCTION';
export const AUCTIONS_GET_MY_ID = 'AUCTIONS_GET_MY_ID';

//Address
export const ADDRESS_GET = 'ADDRESS_GET';
export const ADDRESS_GET_MY = 'ADDRESS_GET_MY';
export const ADDRESS_POST = 'ADDRESS_POST';
export const ADDRESS_PUT = 'ADDRESS_PUT';
export const ADDRESS_DELETE = 'ADDRESS_DELETE';

//Auctions
export const AUCTIONS_GET = 'AUCTIONS_GET';
export const AUCTIONS_GET_FLAG = 'AUCTIONS_GET_FLAG';
export const AUCTIONS_GET_ID = 'AUCTIONS_GET_ID';
export const AUCTIONS_POST = 'AUCTIONS_POST';
export const AUCTIONS_PUT = 'AUCTIONS_PUT';
export const AUCTIONS_DELETE = 'AUCTIONS_DELETE';
export const AUCTIONS_SEARCH = 'AUCTIONS_SEARCH';
export const AUCTIONS_SEARCH_OLD = 'AUCTIONS_SEARCH_OLD';
export const AUCTIONS_SEARCH_MIN = 'AUCTIONS_SEARCH_MIN';
export const AUCTIONS_SEARCH_COUNT = 'AUCTIONS_SEARCH_COUNT';
export const AUCTIONS_SEARCH_OLD_COUNT = 'AUCTIONS_SEARCH_OLD_COUNT';
export const AUCTIONS_GET_MY = 'AUCTIONS_GET_MY';
export const AUCTIONS_SWITCH_WINDOW = 'AUCTIONS_SWITCH_WINDOW';
export const AUCTIONS_ITEMS_SWITCH_WINDOW = 'AUCTIONS_ITEMS_SWITCH_WINDOW';
export const AUCTIONS_CURENT_PAGE = 'AUCTIONS_CURENT_PAGE';
export const AUCTIONS_STATUS = 'AUCTIONS_STATUS';
export const SET_POP_UP_ERROR_AUCTION = 'SET_POP_UP_ERROR_AUCTION';
export const SET_DELETE_AUCTION_DESCRIPTION = 'SET_DELETE_AUCTION_DESCRIPTION';
export const SET_POP_UP_ANSWER_AUCTION = 'SET_POP_UP_ANSWER_AUCTION';
export const CHANGE_POP_UP_AUCTION_CREATE = 'CHANGE_POP_UP_AUCTION_CREATE';

//Auction Items
export const AUCTION_ITEMS_GET = 'AUCTION_ITEMS_GET';
export const AUCTION_ID_ITEMS_GET = 'AUCTION_ID_ITEMS_GET';
export const AUCTION_ITEMS_POST = 'AUCTION_ITEMS_POST';
export const AUCTION_ITEMS_PUT = 'AUCTION_ITEMS_PUT';
export const AUCTION_ITEMS_DELETE = 'AUCTION_ITEMS_DELETE';
export const CREATE_ITEMS_ADDITIONAL_CLASSIFICATION_IDS = 'CREATE_ITEMS_ADDITIONAL_CLASSIFICATION_IDS';
export const CREATE_ITEMS_QUANTITY = 'CREATE_ITEMS_QUANTITY';
export const CREATE_ITEMS_IDS = 'CREATE_ITEMS_IDS';
export const CREATE_ITEMS_ADDRESS = 'CREATE_ITEMS_ADDRESS';

//Awards
export const SET_AWARDS = 'SET_AWARDS';
export const SET_AWARD_ID = 'SET_AWARD_ID';
export const SET_POPUP_AWARD_REJECT = 'SET_POPUP_AWARD_REJECT';
export const SET_POPUP_AWARD_ERROR = 'SET_POPUP_AWARD_ERROR';
export const SET_POPUP_AWARD_APPROWE = 'SET_POPUP_AWARD_APPROWE';
export const AWARD_POPUP_VIEW = 'AWARD_POPUP_VIEW';

//Auction Bid's
export const AUCTION_BID_GET = 'AUCTION_BID_GET';
export const AUCTION_ID_BID_GET = 'AUCTION_ID_BID_GET';
export const AUCTION_BID_POST = 'AUCTION_BID_POST';
export const AUCTION_BID_PUT = 'AUCTION_BID_PUT';
export const AUCTION_BID_DELETE = 'AUCTION_BID_DELETE';

export const SET_MY_BIDS = 'SET_MY_BIDS';
export const SET_CURENT_BID = 'SET_CURENT_BID';
export const SET_CURENT_BID_DOC_CVALIF = 'SET_CURENT_BID_DOC_CVALIF';
export const SET_AUCTION_ID_BID = 'SET_AUCTION_ID_BID';

export const SET_CREATE_BID_ERROR = 'SET_CREATE_BID_ERROR';

export const SET_GET_BANK_ACCOUNT = 'SET_GET_BANK_ACCOUNT';
export const SET_POP_UP_BANK_ACCOUNT = 'SET_POP_UP_BANK_ACCOUNT';
export const SET_POP_UP_BANK_ACCOUNT_COMPLITE = 'SET_POP_UP_BANK_ACCOUNT_COMPLITE';
export const SET_POP_UP_BANK_ACCOUNT_ERROR = 'SET_POP_UP_BANK_ACCOUNT_ERROR';
export const SET_POP_UP_BANK_ACCOUNT_SHEME = 'SET_POP_UP_BANK_ACCOUNT_SHEME';

export const SET_POP_UP_BANK_ACCOUNT_OPUS = 'SET_POP_UP_BANK_ACCOUNT_OPUS';
export const SET_POP_UP_BANK_ACCOUNT_NAZVA_BANK = 'SET_POP_UP_BANK_ACCOUNT_NAZVA_BANK';
export const SET_POP_UP_BANK_ACCOUNT_NAZVA = 'SET_POP_UP_BANK_ACCOUNT_NAZVA';
export const SET_POP_UP_BANK_ACCOUNT_IDENTIF = 'SET_POP_UP_BANK_ACCOUNT_IDENTIF';
export const SET_POP_UP_BANK_ACCOUNT_SHEME_ID = 'SET_POP_UP_BANK_ACCOUNT_SHEME_ID';
export const SET_POP_UP_BANK_ACCOUNT_ID = 'SET_POP_UP_BANK_ACCOUNT_ID';
export const SET_POP_UP_BANK_ACCOUNT_ID_ARR = 'SET_POP_UP_BANK_ACCOUNT_ID_ARR';

//Classifications
export const CLASSIFICATION_GET = 'CLASSIFICATION_GET';
export const CLASSIFICATIONS_GET = 'CLASSIFICATIONS_GET';
export const CLASSIFICATION_POST = 'CLASSIFICATIONS_POST';
export const CLASSIFICATION_PUT = 'CLASSIFICATIONS_PUT';
export const CLASSIFICATION_DELETE = 'CLASSIFICATIONS_DELETE';
export const CLASSIFICATIONS_BY_SCHEME_GET = 'CLASSIFICATIONS_BY_SCHEME_GET';

export const SET_CLASSIFICATIONS_BY_TYPE = 'SET_CLASSIFICATIONS_BY_TYPE';
export const SET_CLASSIFICATIONS_BY_TIMBER_SORTMENT = 'SET_CLASSIFICATIONS_BY_TIMBER_SORTMENT';
export const SET_CLASSIFICATIONS_BY_TYPE_ENERGY = 'SET_CLASSIFICATIONS_BY_TYPE_ENERGY';
export const SET_CLASSIFICATIONS_BY_ENERGY_GENERATION_TYPE = 'SET_CLASSIFICATIONS_BY_ENERGY_GENERATION_TYPE';
export const CHANGE_CLASSIFICATIONS_BY_TIMBER_SORTMENT = 'CHANGE_CLASSIFICATIONS_BY_TIMBER_SORTMENT';
export const SET_CLASSIFICATIONS_BY_TIMBER_SPECIES = 'SET_CLASSIFICATIONS_BY_TIMBER_SPECIES';
export const CHANGE_CLASSIFICATIONS_BY_TIMBER_SPECIES = 'CHANGE_CLASSIFICATIONS_BY_TIMBER_SPECIES';
export const SET_CLASSIFICATIONS_BY_TIMBER_CLASS = 'SET_CLASSIFICATIONS_BY_TIMBER_CLASS';
export const CHANGE_CLASSIFICATIONS_BY_TIMBER_CLASS = 'CHANGE_CLASSIFICATIONS_BY_TIMBER_CLASS';
export const SET_CLASSIFICATIONS_BY_TIMBER_DIAMETER = 'SET_CLASSIFICATIONS_BY_TIMBER_DIAMETER';
export const CHANGE_CLASSIFICATIONS_BY_TIMBER_DIAMETER = 'CHANGE_CLASSIFICATIONS_BY_TIMBER_DIAMETER';
export const SET_CLASSIFICATIONS_BY_TIMBER_LENGTH = 'SET_CLASSIFICATIONS_BY_TIMBER_LENGTH';
export const CHANGE_CLASSIFICATIONS_BY_TIMBER_LENGTH1 = 'CHANGE_CLASSIFICATIONS_BY_TIMBER_LENGTH1';
export const CHANGE_CLASSIFICATIONS_BY_TIMBER_LENGTH2 = 'CHANGE_CLASSIFICATIONS_BY_TIMBER_LENGTH2';
export const SET_CLASSIFICATIONS_BY_TIMBER_STORAGE = 'SET_CLASSIFICATIONS_BY_TIMBER_STORAGE';
export const CHANGE_CLASSIFICATIONS_BY_TIMBER_STORAGE = 'CHANGE_CLASSIFICATIONS_BY_TIMBER_STORAGE';
export const SET_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_YEAR = 'SET_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_YEAR';
export const CHANGE_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_YEAR = 'CHANGE_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_YEAR';
export const SET_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_QUARTER = 'SET_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_QUARTER';
export const CHANGE_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_QUARTER = 'CHANGE_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_QUARTER';
export const CHANGE_CLASSIFICATIONS_BY_UNIT_CODE = 'CHANGE_CLASSIFICATIONS_BY_UNIT_CODE';
export const CHANGE_UNIT_CODE = 'CHANGE_UNIT_CODE';
export const CHANGE_CLASSIFICATIONS_BY_GENERATION_TYPE = 'CHANGE_CLASSIFICATIONS_BY_GENERATION_TYPE';
export const CREATE_AUCTION_RAILWAY_LOT_TYPE = 'CREATE_AUCTION_RAILWAY_LOT_TYPE';

export const CREATE_AUCTION_LOT_ID = 'CREATE_AUCTION_LOT_ID';
export const SET_CLASSIFICATIONS_BY_CARRY_TYPE = 'SET_CLASSIFICATIONS_BY_CARRY_TYPE';

export const SET_CARRY_TYPE = 'SET_CARRY_TYPE';
export const SET_WAGON_SPECIES = 'SET_WAGON_SPECIES';
export const SET_LOADING_RESTRICTION = 'SET_LOADING_RESTRICTION';
export const SET_WAGON_TYPES = 'SET_WAGON_TYPES';
export const SET_DATE_FROM_1 = 'SET_DATE_FROM_1';
export const SET_DATE_TILL_1 = 'SET_DATE_TILL_1';
export const SET_DATE_FROM_2 = 'SET_DATE_FROM_2';
export const SET_DATE_TILL_2 = 'SET_DATE_TILL_2';
export const SET_ROUTE_QUANTITY = 'SET_ROUTE_QUANTITY';
export const SET_ROUTE_ACCOUNTING_ROUTE_NUMBER = 'SET_ROUTE_ACCOUNTING_ROUTE_NUMBER';
export const SET_ROUTE_ACCOUNTING_WAGONS_PER_ROUTE_QUANTITY = 'SET_ROUTE_ACCOUNTING_WAGONS_PER_ROUTE_QUANTITY';
export const SET_CLASSIFICATIONS_BY_TYPE_RAILWAY_CARGO_ADD = 'SET_CLASSIFICATIONS_BY_TYPE_RAILWAY_CARGO_ADD';
export const SET_RAILWAY_CLASSIFICATOR_ADD = 'SET_RAILWAY_CLASSIFICATOR_ADD';
export const SET_CLASSIFICATIONS_BY_TYPE_RAILWAY_RESTRICT = 'SET_CLASSIFICATIONS_BY_TYPE_RAILWAY_RESTRICT';
export const SET_CLASSIFICATIONS_BY_TYPE_RAILWAY_RESTRICT_TYPE = 'SET_CLASSIFICATIONS_BY_TYPE_RAILWAY_RESTRICT_TYPE';

export const SET_CREATE_ITEM_RESTRICT = 'SET_CREATE_ITEM_RESTRICT';
export const CHANGE_TOGETHER_API = 'CHANGE_TOGETHER_API';


export const SET_TIMBER_SORTMENT = 'SET_TIMBER_SORTMENT';
export const SET_TIMBER_SPECIES = 'SET_TIMBER_SPECIES';
export const SET_TIMBER_CLASS = 'SET_TIMBER_CLASS';
export const SET_TIMBER_DIAMETER = 'SET_TIMBER_DIAMETER';
export const SET_TIMBER_STORAGE = 'SET_TIMBER_STORAGE';
export const SET_TIMBER_PRODUCTION_YEAR = 'SET_TIMBER_PRODUCTION_YEAR';
export const SET_TIMBER_PRODUCTION_QUARTER = 'SET_TIMBER_PRODUCTION_QUARTER';
export const SET_POPUP_BID_TOGETHER_API = 'SET_POPUP_BID_TOGETHER_API';
export const SET_TIMBER_LENGTH1 = 'SET_TIMBER_LENGTH1';
export const SET_TIMBER_LENGTH2 = 'SET_TIMBER_LENGTH2';

export const AUCTIONS_SEARCH_ELEM_ARR = 'AUCTIONS_SEARCH_ELEM_ARR';

export const AWARD_ALL_USER_POPUP = 'AWARD_ALL_USER_POPUP';
export const AWARD_ALL_USER_POPUP_USER = 'AWARD_ALL_USER_POPUP_USER';


export const SET_CREATE_RESTRICTION_FLAG = 'SET_CREATE_RESTRICTION_FLAG';
export const SET_CREATE_RESTRICTION_LOAD_OBJECT = 'SET_CREATE_RESTRICTION_LOAD_OBJECT';
export const SET_CREATE_RESTRICTION_LOAD_OBJECT_CODE = 'SET_CREATE_RESTRICTION_LOAD_OBJECT_CODE';
export const SET_CREATE_RESTRICTION_LOAD_OBJECT_NAME = 'SET_CREATE_RESTRICTION_LOAD_OBJECT_NAME';
export const SET_CREATE_RESTRICTION_UNLOAD_OBJECT = 'SET_CREATE_RESTRICTION_UNLOAD_OBJECT';
export const SET_CREATE_RESTRICTION_UNLOAD_OBJECT_CODE = 'SET_CREATE_RESTRICTION_UNLOAD_OBJECT_CODE';
export const SET_CREATE_RESTRICTION_UNLOAD_OBJECT_NAME = 'SET_CREATE_RESTRICTION_UNLOAD_OBJECT_NAME';

export const SET_CREATE_RESTRICTION_EXCLUSIONS_FLAF = 'SET_CREATE_RESTRICTION_EXCLUSIONS_FLAF';
export const SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT = 'SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT';
export const SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT_TYPE = 'SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT_TYPE';
export const SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT_CODE = 'SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT_CODE';
export const SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT_NAME = 'SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT_NAME';

export const SET_CLASSIFICATIONS_BY_TYPE_RAILWAY = 'SET_CLASSIFICATIONS_BY_TYPE_RAILWAY';
export const UNITS_GET_BY_TYPE_RAILWAY = 'UNITS_GET_BY_TYPE_RAILWAY';
export const UNITS_GET_BY_TYPE_SUBSOIL = 'UNITS_GET_BY_TYPE_SUBSOIL';

export const SET_CLASSIFICATIONS_BY_WAGON_SPECIES = 'SET_CLASSIFICATIONS_BY_WAGON_SPECIES';

export const SET_CLASSIFICATIONS_BY_TYPE_NADRA_ENGLISH = 'SET_CLASSIFICATIONS_BY_TYPE_NADRA_ENGLISH';
export const SET_CLASSIFICATIONS_BY_TYPE_SUBSOIL_USAGE_TYPE = 'SET_CLASSIFICATIONS_BY_TYPE_SUBSOIL_USAGE_TYPE';

//Contacts
export const CONTACT_GET = 'CONTACT_GET';
export const CONTACTS_GET = 'CONTACTS_GET';
export const CONTACT_POST = 'CONTACT_POST';
export const CONTACT_PUT = 'CONTACT_PUT';
export const CONTACT_DELETE = 'CONTACT_DELETE';

//Contracts
export const CONTRACT_GET = 'CONTRACT_GET';
export const CONTRACT_AUCTION_ID_GET = 'CONTRACT_AUCTION_ID_GET';
export const CONTRACT_POST = 'CONTRACT_POST';
export const CONTRACT_PUT = 'CONTRACT_PUT';
export const CONTRACT_DELETE = 'CONTRACT_DELETE';

export const SET_CONTRACT_TITLE = 'SET_CONTRACT_TITLE';
export const SET_CONTRACT_DESCRIPTION = 'SET_CONTRACT_DESCRIPTION';
export const SET_CONTRACT_OFF = 'SET_CONTRACT_OFF'
export const SET_CONTRACT_CONTRACT_NUMBER = 'SET_CONTRACT_CONTRACT_NUMBER';
export const SET_CONTRACT_DATE_FROM = 'SET_CONTRACT_DATE_FROM';
export const SET_CONTRACT_DATE_TILL = 'SET_CONTRACT_DATE_TILL';
export const SET_CONTRACT_DATE_PAID = 'SET_CONTRACT_DATE_PAID';
export const SET_CONTRACT_START_DATE = 'SET_CONTRACT_START_DATE';
export const SET_CONTRACT_END_DATE = 'SET_CONTRACT_END_DATE';
export const SET_CONTRACT_CURRENCY = 'SET_CONTRACT_CURRENCY';
export const SET_CONTRACT_AMOUNT = 'SET_CONTRACT_AMOUNT';
export const SET_CONTRACT_TOTAL_AMOUNT = 'SET_CONTRACT_TOTAL_AMOUNT';
export const SET_CONTRACT_DATE_SIGNED = 'SET_CONTRACT_DATE_SIGNED';

export const SET_POPUP_CONTRACT_APPROWE = 'SET_POPUP_CONTRACT_APPROWE';
export const SET_POPUP_CONTRACT_ERROR = 'SET_POPUP_CONTRACT_ERROR';

//Documents
export const DOCUMENT_GET = 'DOCUMENT_GET';
export const DOCUMENTS_GET = 'DOCUMENTS_GET';
export const DOCUMENT_POST = 'DOCUMENT_POST';
export const DOCUMENT_PUT = 'DOCUMENT_PUT';
export const DOCUMENT_DELETE = 'DOCUMENT_DELETE';
export const CHANGE_DOCUMENT_ID = 'CHANGE_DOCUMENT_ID';
export const CHANGE_DOCUMENT_TITLE = 'CHANGE_DOCUMENT_TITLE';
export const CHANGE_DOCUMENT_DESCRIPTION = 'CHANGE_DOCUMENT_DESCRIPTION';
export const CHANGE_DOCUMENT_LANG = 'CHANGE_DOCUMENT_LANG';
export const CHANGE_DOCUMENT_TYPE = 'CHANGE_DOCUMENT_TYPE';
export const OLD_ARR_DOCUMENT_GET = 'OLD_ARR_DOCUMENT_GET';

//Discount
export const CHANGE_DISCOUNT_ID = 'CHANGE_DISCOUNT_ID';
export const CHANGE_DISCOUNT_STATUS = 'CHANGE_DISCOUNT_STATUS';
export const CHANGE_DISCOUNT_PERCENT = 'CHANGE_DISCOUNT_PERCENT';
export const CHANGE_DISCOUNT_PERVIOUS_AUCTION_CURRENCY = 'CHANGE_DISCOUNT_PERVIOUS_AUCTION_CURRENCY';
export const CHANGE_DISCOUNT_PERVIOUS_AUCTION_AMOUNT = 'CHANGE_DISCOUNT_PERVIOUS_AUCTION_AMOUNT';
export const CHANGE_DISCOUNT_PERVIOUS_AUCTION_ID = 'CHANGE_DISCOUNT_PERVIOUS_AUCTION_ID';

//Identifier
export const IDENTIFIER_GET = 'IDENTIFIER_GET';
export const IDENTIFIERS_GET = 'IDENTIFIERS_GET';
export const IDENTIFIER_POST = 'IDENTIFIER_POST';
export const IDENTIFIER_PUT = 'IDENTIFIER_PUT';
export const IDENTIFIER_DELETE = 'IDENTIFIER_DELETE';

//default
export const DOC_GET = 'DOC_GET';
export const AUTHORIZE_GET = 'AUTHORIZE_GET';
export const TOKEN_POST = 'TOKEN_POST';
export const TOKEN_CLEAR = 'TOKEN_CLEAR';
export const SET_PROFILE_NULL = 'SET_PROFILE_NULL';

//Old site users
export const OLD_USER_GET = 'OLD_USER_GET';
export const OLD_USERS_GET = 'OLD_USERS_GET';
export const OLD_USER_POST = 'OLD_USER_POST';
export const OLD_USER_PUT = 'OLD_USER_PUT';
export const OLD_USER_DELETE = 'OLD_USER_DELETE';
export const USER_PROFILES_GET = 'USER_PROFILES_GET';

//Organizations
export const ORGANIZATION_GET = 'ORGANIZATION_GET';
export const ORGANIZATIONS_GET = 'ORGANIZATIONS_GET';
export const ORGANIZATION_POST = 'ORGANIZATION_POST';
export const ORGANIZATION_PUT = 'ORGANIZATION_PUT';
export const ORGANIZATION_DELETE = 'ORGANIZATION_DELETE';

//Procuring Entity
export const PROCURING_ENTITY_GET = 'PROCURING_ENTITY_GET';
export const PROCURING_ENTITIES_GET = 'PROCURING_ENTITIES_GET';
export const PROCURING_ENTITY_POST = 'PROCURING_ENTITY_POST';
export const PROCURING_ENTITY_PUT = 'PROCURING_ENTITY_PUT';
export const PROCURING_ENTITY_DELETE = 'PROCURING_ENTITY_DELETE';

//Questions
export const QUESTION_GET = 'QUESTION_GET';
export const QUESTIONS_AUCTION_ID_GET = 'QUESTIONS_AUCTION_ID_GET';
export const QUESTION_POST = 'QUESTION_POST';
export const QUESTION_PUT = 'QUESTION_PUT';
export const QUESTION_DELETE = 'QUESTION_DELETE';

export const ANSWER_TITLE = 'ANSWER_TITLE';
export const ANSWER_DISCRIPTION = 'ANSWER_DISCRIPTION';

//Item Units
export const UNIT_GET = 'UNIT_GET';
export const UNITS_GET = 'UNITS_GET';
export const UNIT_POST = 'UNIT_POST';
export const UNIT_PUT = 'UNIT_PUT';
export const UNIT_DELETE = 'UNIT_DELETE';
export const UNITS_CHANGE = 'UNITS_CHANGE';
export const CHANGE_MEASURES = 'CHANGE_MEASURES';
export const UNITS_GET_BY_TYPE = 'UNITS_GET_BY_TYPE';
export const UNITS_GET_BY_TYPE_ENERGY = 'UNITS_GET_BY_TYPE_ENERGY';

//Media
export const MEDIA_GET = 'MEDIA_GET';
export const MEDIA_ID_GET = 'MEDIA_ID_GET';
export const MEDIA_FORMATS_GET = 'MEDIA_FORMATS_GET';
export const MEDIA_BINARIES_GET = 'MEDIA_BINARIES_GET';
export const MEDIA_POST = 'MEDIA_POST';
export const MEDIA_PUT = 'MEDIA_PUT';
export const MEDIA_BINARY_CONTENT_PUT = 'MEDIA_BINARY_CONTENT_PUT';
export const MEDIA_DELETE = 'MEDIA_DELETE';


//REGEXP
export const REG_EXP_TIMBER_DIAMETR = 'REG_EXP_TIMBER_DIAMETR';