import React from 'react';
import styles from './paging.module.css';

import {connect} from 'react-redux';

const pagingButtonPrev = ( props ) => {
    return (
        <svg width="7" height="12" xmlns="http://www.w3.org/2000/svg" className={styles.svgIcon} viewBox="0 0 7 12">
            <defs></defs>
            <g id="Слой_2" data-name="Слой 2">
                <g id="Слой_1-2" data-name="Слой 1">
                    <path className={styles.svgIcon} d="M2.41,6l4.3,4.29a1,1,0,1,1-1.42,1.42l-5-5a1,1,0,0,1,0-1.42l5-5A1,1,0,0,1,6.71,1.71Z" fill="#92929D"/>
                </g>
            </g>
        </svg>
    )
}

export default connect(null, null)(pagingButtonPrev)
