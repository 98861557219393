import React from 'react';
import styles from './opisanie.module.css';

import StructureLot from '../structureLot/structureLot.js';
import Documents from '../../documents/AucDocuments'
//import DateAndTermins from '../dateAndTermins/dateAndTermins.js';
import MessageLot from '../messageLot/messageLot.js';
import BidLotNew from '../newBidLot/bidLot.js';
import ActiveAuctionLinkNew from '../activeAuctionLinkNew/activeAuctionLinkNew.js';

/**NEW ELEMENTS */
import Title from '../title/title'
import Tabs from '../tabs/tabs'
import InformationAboutTheOrganizer from '../informationAboutTheOrganizer/informationAboutTheOrganizer'
import PropertyOwner from '../informationAboutTheOrganizer/propertyOwner'
import CurrentTenant from '../informationAboutTheOrganizer/currentTenant'
import CurrentTenantLAE from '../informationAboutTheOrganizer/currentTenantLAE'
import DebtorInformation from '../informationAboutTheOrganizer/debtorInformation'
import OrganizationEntity from '../informationAboutTheOrganizer/organizationEntity'
import ContactPoint from '../informationAboutTheOrganizer/contactPoint'

import BanksGroupe from '../informationAboutTheOrganizer/banksGroupe'
import Arrests from '../informationAboutTheOrganizer/arrests'
import AdditionalInformation from '../informationAboutTheOrganizer/additionalInformation'
import LotInformation from '../lotInformation/lotInformation'
import RightBlock from '../rightBlock/rightBlock'
import DisqualifiedBidsDetails from '../disqualifiedBidsDetails/disqualifiedBidsDetails'
import { isTIE, isSUE, isSUD, isRCE, isRCD, isGFE, isGFD, 
    isLLE, isLLD, isLLP, isLRE, isLSE, isLSP,
    isRMA, isBSE, isBSD, isALE, isCSE, isCSD, isAAE, isBRE, isBRD, isBRW,
    isRLE, isRLD, isCLE, isCLD, isSPE, isSPD, isLAE, isLAW, isLAP,
    isLPE } from "../../../redux/aucTypes";

import {connect} from 'react-redux';

import SidebarRightViewProcedure from "../../sidebarRightViewProcedure"

const DescriptionLot = (props) => {
    if(props.auctionId !== null){
        return (
            <>
                <div className={styles.wrapNew}>
                    <div className={styles.mainBlock}>
                        <Title />
                        <div className={styles.aucProperties}>
                            <Tabs />
                            <InformationAboutTheOrganizer />
                            <DisqualifiedBidsDetails />
                            <PropertyOwner />
                            {isLAP(props.auctionId.sellingMethod)
                                ? <CurrentTenantLAE />
                                : <CurrentTenant />
                            }
                            <DebtorInformation />
                            <OrganizationEntity />
                            <ContactPoint />
                            <BanksGroupe />
                            <Arrests />
                            {props.auctionId.hasOwnProperty('additionalInformation') === true
                            && props.auctionId.additionalInformation !== null
                            && props.auctionId.additionalInformation.length > 0
                                ? <AdditionalInformation />
                                : null
                            }
                            <LotInformation />
                            <StructureLot />
                            <Documents data={props.auctionId}/>
                            <MessageLot
                                content={
                                        <>Даний аукціон оголошено і проводиться в рамках&nbsp;
                                        <a href="https://www.dto.com.ua/info/rules" target="blank">Регламенту роботи ЕТС Prozorro:Продажі</a>
                                    </>
                                }
                            />
                            {blockLink(props)}
                            {blockBid(props)}
                        </div>
                    </div>
                    {/*<RightBlock />*/}
                    <SidebarRightViewProcedure />
                </div>
            </>
        )
    }
    return null
}

function blockBid(props){
    //if(isSPD(props.auctionId.sellingMethod)) return null
    if(props.token !== null){
        if(props.auctionId !== null){
            if(props.userProfiles !== null){
                if(props.userProfiles.canCreateBid === true){
                    if(props.auctionId.tenderPeriod !== null){
                        let dateTenderStart = new Date(props.auctionId.tenderPeriod.startDate)
                        let tmpStart = dateTenderStart.getTime()
                        let dateTenderEnd = new Date(props.auctionId.tenderPeriod.endDate)
                        let tmpEnd = dateTenderEnd.getTime()
                        if(tmpStart < Date.now() && Date.now() < tmpEnd){
                            return <BidLotNew />
                        }
                    }
                }
            }
        }
    }
    return null
}

function blockLink(props){
    //if(props.auctionId.status === 'active_auction'){
    return <ActiveAuctionLinkNew />
    //}
    //return null
}

const mapStateToProps = state => {
    return {
        token:  state.start.token,
        auctionId: state.start.auctionFull,
        userProfiles: state.start.userProfiles,
    }
}

export default connect(mapStateToProps, null)(DescriptionLot)