import React from 'react';
import styles from './auctionType.module.css';

import {connect} from 'react-redux';

const StructureLot = (props) => {
    let tmp = null;
    if(props.auction !== null){
        if(props.auction.items.length === 0){
            tmp = <div className={styles.documentElem + ' ' + styles.scladLotuElem}>Додані товари відсутні</div>
        }else{
            tmp = props.auction.items
            .map(
                (i, count) => {
                    let tmpAddClassificationId = null;
                    let tmpAddClassificationDescription = null;
                    if(i.additionalClassifications.length > 0){
                        tmpAddClassificationId = i.additionalClassifications[0].classificationId;
                        tmpAddClassificationDescription = i.additionalClassifications[0].description;
                    }
                    let tmpAddresPostalCode = null;
                    let tmpAddresCountryName = null;
                    let tmpAddresRegion = null;
                    let tmpAddresLlocality = null;
                    let tmpAddresIstreetAddress = null;
                    let codKTU = null;
                    if(i.hasOwnProperty('address') === true){
                        if(i.address !== null){
                            if(i.address.hasOwnProperty('postalCode') === true){
                                tmpAddresPostalCode = i.address.postalCode;
                            }
                            if(i.address.hasOwnProperty('countryName') === true){
                                tmpAddresCountryName = i.address.countryName;
                            }
                            if(i.address.hasOwnProperty('region') === true){
                                tmpAddresRegion = i.address.region;
                            }
                            if(i.address.hasOwnProperty('locality') === true){
                                tmpAddresLlocality = i.address.locality;
                            }
                            if(i.address.hasOwnProperty('istreetAddress') === true){
                                tmpAddresIstreetAddress = i.address.istreetAddress;
                            }
                            if(i.address.hasOwnProperty('addressIdentifier') === true){
                                if(i.address.addressIdentifier !== null){
                                    if(i.address.addressIdentifier.hasOwnProperty('identifier') === true){
                                        if(i.address.addressIdentifier.identifier !== null){
                                            codKTU = i.address.addressIdentifier.identifier
                                        }
                                    }
                                }
                            }
                        }
                    }
                    return (
                        <div className={styles.documentElem + ' ' + styles.scladLotuElem}>
                            <div className={styles.scladLotuNumber}>{count+1})</div>
                            <div className={styles.scladlotunMain}>
                                <div className={styles.scladlotuname}>{i.description}</div>
                                <div className={styles.terminuTitle}>Класифікація згідно {i.classification.scheme}: <span>{i.classification.classificationId} - {i.classification.description}</span></div>
                                <div className={styles.terminuTitle}>Код CPVs: <span>{tmpAddClassificationId} - {tmpAddClassificationDescription}</span></div>
                                <div className={styles.terminuTitle}>Адреса місцезнаходження майна: <span>{tmpAddresPostalCode}, {tmpAddresCountryName}, {tmpAddresRegion}, {tmpAddresLlocality}, {tmpAddresIstreetAddress}</span></div>
                                <div className={styles.terminuTitle}>Код об’єктів адміністративно-територіального устрою України: <span>{codKTU}</span></div>
                            </div>
                            <div className={styles.scladlotuname + ' ' + styles.scladLotuColl}>
                                {i.quantity} {i.unit.name}
                            </div>
                        </div>
                    )
                }
            )
        }
    }
    return (
        <div className={styles.terminu}>
            <h3 className={styles.terminuTitle}>СКЛАД ЛОТУ</h3>
            {tmp}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        //auction: state.start.auctionsGetId,
        auction: state.start.auctionFull,
        langFlag: state.start.langFlag,
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(StructureLot)
