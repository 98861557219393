import React from 'react';
import styles from './participants.module.css';

import {connect} from 'react-redux';

import DataWinner from './participant'

const Participants = (props) => {
    if(props.auctionId === null) return null
    if(props.auctionId === undefined || props.auctionId === 'undefined' || props.auctionId.bids.length === 0) return null
    if(
        props.auctionId.status !== 'active_auction'
        && props.auctionId.status !== 'active_awarded'
        && props.auctionId.status !== 'active_qualification'
        && props.auctionId.status !== 'pending_payment'
        && props.auctionId.status !== 'pending_admission'
        && props.auctionId.status !== 'cancelled'
        && props.auctionId.status !== 'complete'
        && props.auctionId.status !== 'unsuccessful'
    ) return null
    return (
        <div className={styles.infoBlock}>
            <div className={styles.title_wrap}>{props.type ? "Переможці" : "Всі учасники"}</div>
            {props.type ? sortAwards(props) : viewAll(props)}
        </div>
    )
}

function sortAwards(props){
    if(props.auctionId.awards === null) return null
    let tmp = []
    for(let t = 0; t < props.auctionId.awards.length; t++){
        tmp[t] = viewWinners(props, props.auctionId.awards[t])
    }
    return tmp
}

function viewWinners(props, awards){
    if(props.auctionId.bids === null) return null
    if(props.auctionId.bids.length === 0) return null
    if(awards === 0) return null
    return props.auctionId.bids
    .filter( (e) => { if(e.id === awards.bidId){ return e } } )
    .map( (e) => { return <DataWinner key={e.id} e={e} awards={awards} type={props.type} /> } )
}

function viewAll(props){
    if(props.auctionId === null) return null
    if(props.auctionId.bids === null) return null
    if(props.auctionId.bids.length === 0) return null
    return props.auctionId.bids
    .filter( (e) => { return e.status !== 'deleted' } )
    .filter( (e) => { return e.status !== 'draft' } )
    .map( (e) => { return <DataWinner key={e.id} e={e} awards={null} type={props.type} /> } )
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(Participants)