import React from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import {
    setDataAddress,
} from '../../../redux/createAddress';

import {
    getMyAuction,
} from '../../../redux/actions.js';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { checkProp, isMongo } from '../../../redux/check';

import { isBRD, isBRE, isALE, isBSD, isBSE, isCSD, isCSE, isLRE, isLSE, isLSP, isAAE } from '../../../redux/aucTypes';

const BankAccountsGroups = ( props ) => {
    let tmp = null;
    if(props.auctionsMy !== null){
        if(props.auctionsMy.bankAccounts !== null){
            tmp = props.auctionsMy.bankAccounts.map(
                (i) => {
                    let obj = {}
                    obj.tmpText = 'Видалити групу'
                    obj.tmpStyleDelete = ''
                    obj.tmpColorButton = 'primary'
                    /*if(props.deleteBankGroops !== null && props.deleteBankGroops.length > 0){
                        for(let e=0; e < props.deleteBankGroops.length; e++){
                            if(props.deleteBankGroops[e].type === i.accountType && props.deleteBankGroops[e].status === true){
                                obj.tmpText = 'Відмінити'
                                obj.tmpStyleDelete = styles.bankDelete
                                obj.tmpColorButton = 'error'
                            }
                        }
                    }*/
                    return (
                        <div className={styles.groupElem}>
                            <div>
                                {viewTextData(props, i, obj)}
                            </div>
                        </div>
                    )
                }
            )
        }
    }
    return (
        <>
            {tmp}
            <div>
                <div>Банківскі акаунти що будуть додані до процедури</div>
                {viewTextDataNew(props)}
            </div>
            {helpMessageNeedBanks(props)}
        </>
    )
}

function helpMessageNeedBanks(props){
    let tmpAP = 0
    let tmpL = 0
    if(props.auctionsMy !== null){
        if(props.auctionsMy.bankAccounts !== null){
            if(props.auctionsMy.bankAccounts.length > 0){
                for(let i = 0; i < props.auctionsMy.bankAccounts.length; i++){
                    if(props.auctionsMy.bankAccounts[i].accountType === "advancePayment"){
                        if(props.auctionsMy.bankAccounts[i].holderType !== null || props.auctionsMy.bankAccounts[i].percent !== null){
                            tmpAP = tmpAP + 1
                        }
                    }
                    if(props.auctionsMy.bankAccounts[i].accountType === "lease"){
                        if(props.auctionsMy.bankAccounts[i].holderType !== null || props.auctionsMy.bankAccounts[i].percent !== null){
                            tmpL = tmpL + 1
                        }
                    }
                }
            }
        }
    }
    if(props.bankAccountZU !== null){
        if(props.bankAccountZU.length !== null){
            for(let i = 0; i < props.bankAccountZU.length; i++){
                if(props.bankAccountZU[i].groupe === "advancePayment"){
                    if(props.bankAccountZU[i].holderType !== 'null' || props.bankAccountZU[i].percent !== ''){
                        tmpAP = tmpAP + 1
                    }
                }
                if(props.bankAccountZU[i].groupe === "lease"){
                    if(props.bankAccountZU[i].holderType !== 'null' || props.bankAccountZU[i].percent !== ''){
                        tmpL = tmpL + 1
                    }
                }
            }
        }
    }
    let tmp = null
    let tmp1 = null
    if(tmpAP === 1){
        tmp = <div className={styles.errorHelp}>Необхідно додати рахунок для авансового внеску з другим типом утримувача</div>
    }else if(tmpL === 1){
        tmp1 = <div className={styles.errorHelp}>Необхідно додати рахунок для оплати оренди з другим типом утримувача</div>
    }
    return (
        <>
            <div>{tmp}</div>
            <div>{tmp1}</div>
        </>
    )
}


function viewTextData(props, data, obj){
    let name = null //TODO: тут постоянно падало, но зачем на этот нейм, если мы его не используем?
    if(data.accounts !== null){
        if(data.accounts.length > 0){
            name = checkProp(data.accounts[0], 'bankName') ? isMongo(data.accounts[0].bankName) : null
        }
    }
    let tmpHolderType = null
    if(data.hasOwnProperty('holderType')){
        if(data.holderType === 'balanceHolder'){
            tmpHolderType = 'Балансоутримувач'
        }else if(data.holderType === 'landlord'){
            tmpHolderType = 'Орендодавець'
        }else if(data.holderType === 'budget'){
            tmpHolderType = 'Бюджет'
        }
    }

    let tmpAccountType = null
    if(data.accountType === 'registrationFee'){
        tmpAccountType = 'Рахунок для реєстраційного внеску'
    }else if(data.accountType === 'guarantee'){
        tmpAccountType = 'Рахунок для гарантійного внеску'
    }else if(data.accountType === 'securityDeposit'){
        tmpAccountType = 'Рахунок для забезпечувального депозиту'
    }else if(data.accountType === 'improvementCompensation'){
        tmpAccountType = 'Рахунок для компенсації невід\'ємних поліпшень'
    }else if(data.accountType === 'other'){
        tmpAccountType = 'Інші банківські рахунки'
    }else if(data.accountType === 'advancePayment'){
        tmpAccountType = 'Рахунок для авансового внеску'
    }else if(data.accountType === 'lease'){
        tmpAccountType = 'Рахунок для оплати оренди'
    }


    return (
        <div>
            <div className={styles.deleteBank + ' ' + obj.tmpStyleDelete}>
                <div className={styles.banksNameLine}>
                    <div>{tmpAccountType}</div>
                </div>
                <Button
                    disabled={disabledButton(props)}
                    variant="contained"
                    color={obj.tmpColorButton}
                    onClick={
                        () => {
                            deleteBankGrp(props, data.accountType)
                        }
                    }
                >
                    {obj.tmpText}
                </Button>
            </div>
            {viewDeleteBanks(props, data.accounts, data.accountType, obj.tmpColorButton)}
        </div>
    )
}

function deleteBankGrp(props, accountType){
    let tmp = props.deleteBankGroops
    for(let i=0; i<tmp.length; i++){
        if(accountType === tmp[i].type) tmp[i].status = true
    }
    props.setDataAddress(tmp, 'SET_DELETE_BANK_GROOPS')
}

function disabledButton(props){
    if(props.auctionsMy.status === "active_tendering"){
        let tmpStartData = null
        let tmpEndData = null
        if(props.auctionsMy.hasOwnProperty('auctionPeriods')){
            for(let tt = 0; tt < props.auctionsMy.auctionPeriods.length; tt++){
                if(props.auctionsMy.auctionPeriods[tt].status === 'rectificationPeriod'){
                    tmpStartData = new Date(props.auctionsMy.auctionPeriods[tt].startDate).getTime()
                    tmpEndData = new Date(props.auctionsMy.auctionPeriods[tt].endDate).getTime()
                }
            }
        }else{
            if(props.auctionsMy.hasOwnProperty('rectificationPeriod')){
                if(props.auctionsMy.rectificationPeriod !== null){
                    tmpStartData = new Date(props.auctionsMy.rectificationPeriod.startDate).getTime()
                    tmpEndData = new Date(props.auctionsMy.rectificationPeriod.endDate).getTime()
                }
            }
        }
        if(
            (isBSE(props.auctionsMy.sellingMethod) || isBSD(props.auctionsMy.sellingMethod) ||
            isALE(props.auctionsMy.sellingMethod) ||
            isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
            isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod)
            ) &&
            (tmpStartData < Date.now() && tmpEndData > Date.now())
        ){
            return null
        }else{
            return 'disabled'
        }
    }
    return null
}

function viewDeleteBanks(props, data, type, tmpColorButton1){
    if(data.length > 0){
        return data.map(
            (i, count) => {
                let tmpBankName = i.bankName !== null ? i.bankName.hasOwnProperty('uk_UA') ? i.bankName.uk_UA : i.bankName : null
                let tmpBankDescription = i.description !== null ? i.description.hasOwnProperty('uk_UA') ? i.description.uk_UA : i.description : null
                let tmpText = 'Видалити'
                let tmpStyleDelete = ''
                let tmpColorButton = 'primary'
                if(props.deleteCurentBank !== null && props.deleteCurentBank.length > 0){
                    for(let e=0; e < props.deleteCurentBank.length; e++){
                        if(props.deleteCurentBank[e] === i.id){
                            tmpText = 'Відмінити'
                            tmpStyleDelete = styles.bankDelete
                            tmpColorButton = 'error'
                        }
                    }
                }
                if(tmpColorButton1 === 'error'){
                    //tmpText = 'Відмінити'
                    tmpStyleDelete = styles.bankDelete
                    tmpColorButton = 'error'
                }
                return (
                    <div className={styles.deleteBank}>
                        <div className={styles.banksNameLine + ' ' + tmpStyleDelete}>
                            <div>{tmpBankName}</div>
                            <div>{i.currency}</div>
                            <div>{tmpBankDescription}</div>
                        </div>
                        <Button
                            disabled={disabledButton(props)}
                            variant="contained"
                            color={tmpColorButton}
                            onClick={
                                () => {
                                    if(tmpColorButton1 !== 'error'){
                                        if(tmpText !== 'Відмінити'){
                                            let tmp = [...props.deleteCurentBank]
                                            tmp.push(i.id)
                                            props.setDataAddress(tmp, 'SET_DELETE_CURENT_BANK')
                                        }else{
                                            let tmp = [...props.deleteCurentBank]
                                            let tmp1 = []
                                            let value1 = 0
                                            for (let value=0; value < tmp.length; value++){
                                                if(tmp[value] !== i.id){
                                                    tmp1[value1] = tmp[value]
                                                    value1++
                                                }
                                            }
                                            props.setDataAddress(tmp1, 'SET_DELETE_CURENT_BANK')
                                        }
                                    }
                                }
                            }
                        >
                            {tmpText}
                        </Button>
                    </div>
                )
            }
        )
    }
}


function viewTextDataNew(props){
    let tmp = null
    if(props.bankAccountZU !== null){
        if(props.bankAccountZU.length > 0){
            tmp = props.bankAccountZU.map(
                (i, count) => {
                    let tmpAccountType = null
                    if(i.groupe === 'registrationFee'){
                        tmpAccountType = 'Рахунок для реєстраційного внеску'
                    }else if(i.groupe === 'guarantee'){
                        tmpAccountType = 'Рахунок для гарантійного внеску'
                    }else if(i.groupe === 'securityDeposit'){
                        tmpAccountType = 'Рахунок для забезпечувального депозиту'
                    }else if(i.groupe === 'improvementCompensation'){
                        tmpAccountType = 'Рахунок для компенсації невід\'ємних поліпшень'
                    }else if(i.groupe === 'other'){
                        tmpAccountType = 'Інші банківські рахунки'
                    }else if(i.groupe === 'advancePayment'){
                        tmpAccountType = 'Рахунок для авансового внеску'
                    }else if(i.groupe === 'lease'){
                        tmpAccountType = 'Рахунок для оплати оренди'
                    }
                    return (
                        <div>
                            <div>{tmpAccountType}</div>
                            <div className={styles.deleteBank}>
                                {selectBankAuction(props, i)}
                                <Button
                                    disabled={disabledButton(props)}
                                    variant="contained"
                                    color="primary"
                                    onClick={
                                        () => {
                                            let tmp = []
                                            let count2 = 0
                                            for(let k = 0; k < props.bankAccountZU.length; k++){
                                                if(k !== count){
                                                    tmp[count2] = props.bankAccountZU[k]
                                                    count2++
                                                }
                                            }
                                            handle(props, tmp)
                                        }
                                    }
                                >
                                    Видалити
                                </Button>
                            </div>
                        </div>
                    )
                }
            )
        }
    }
    return tmp
}

function selectBankAuction(props, data){
    let tmp = null;
    if(props.bankAccount !== null){
        tmp = props.bankAccount
        .filter(
            (i) => {
                return i.id === Number(data.id)
            }
        )
        .map(
            (i, count) => {
                let tmpHolderType = null
                if(data.holderType === 'balanceHolder'){
                    tmpHolderType = 'Балансоутримувач'
                }else if(data.holderType === 'landlord'){
                    tmpHolderType = 'Орендодавець'
                }else if(data.holderType === 'budget'){
                    tmpHolderType = 'Бюджет'
                }
                return (
                    <div className={styles.banksNameLine}>
                        <div>{i.bankName}</div>
                        <div>{tmpHolderType}</div>
                        <div>{data.percent}</div>
                    </div>
                )
            }
        )
    }
    return tmp
}

async function handle(props, data){
    await addArrayBankAccounts(props, data)
    await addBankAccountZUtimestamp(props)
}

async function addArrayBankAccounts(props, data){
    props.setDataAddress(data, 'SET_POP_UP_BANK_ACCOUNT_ZU')
}
async function addBankAccountZUtimestamp(props){
    let tmp = new Date()
    props.setDataAddress(String(tmp), 'SET_POP_UP_BANK_ACCOUNT_ZU_TIMESTAMP')
}
async function deleteZUBanksPOST(props, data){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}/bank_account_groups/${data.groupe}`
    //bank_account_groups/advancePaymentAccounts/leaseHolderAccounts
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}/bank_account_info/${data.group}/${data.type}`
    const response = await fetch(endPoint, {
        //method: "DELETE",
        method: "POST",
        body: JSON.stringify({
            bankAccountIds: data.arr
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            //let tmp = JSON.stringify({title: "Помилка при створенні Тип переліку, до якого віднесено об'єкт", message: JSON.stringify(json)})
            //props.setUniversalError(tmp)
        }else{
            let header = {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
            const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}`;
            //props.changeLoader(true)
            const response2 = await fetch(endPoint2, {
                    method: "GET",
                    headers: header
            }).catch(console.error)
            if(response2 === undefined || response2 === 'undefined'){
            }else{
                const json2 = await response2.json()
                if(json2.hasOwnProperty('message') === true){
                    //props.changeLoader(false)
                }else{
                    props.getMyAuction(json2)
                    //props.changeLoader(false)
                }
            }
        }
    }
}

async function deleteZUBanksGroups(props, data){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}/bank_account_groups/${data.groupe}`
    const response = await fetch(endPoint, {
        //method: "DELETE",
        method: "DELETE",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            //let tmp = JSON.stringify({title: "Помилка при створенні Тип переліку, до якого віднесено об'єкт", message: JSON.stringify(json)})
            //props.setUniversalError(tmp)
        }else{
            let header = {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
            const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}`;
            //props.changeLoader(true)
            const response2 = await fetch(endPoint2, {
                    method: "GET",
                    headers: header
            }).catch(console.error)
            if(response2 === undefined || response2 === 'undefined'){
            }else{
                const json2 = await response2.json()
                if(json2.hasOwnProperty('message') === true){
                    //props.changeLoader(false)
                }else{
                    props.getMyAuction(json2)
                    //props.changeLoader(false)
                }
            }
        }
    }
}

async function createZUBanksPOST(props, data){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}/bank_account_groups/${data.group}/${data.type}`
    //bank_account_groups/advancePaymentAccounts/leaseHolderAccounts
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}/bank_account_info/${data.group}/${data.type}`
    const response = await fetch(endPoint, {
        method: "DELETE",
        /*body: JSON.stringify({
            bankAccountIds: []
        }),*/
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            //let tmp = JSON.stringify({title: "Помилка при створенні Тип переліку, до якого віднесено об'єкт", message: JSON.stringify(json)})
            //props.setUniversalError(tmp)
        }else{
            let header = {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
            const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}`;
            //props.changeLoader(true)
            const response2 = await fetch(endPoint2, {
                    method: "GET",
                    headers: header
            }).catch(console.error)
            if(response2 === undefined || response2 === 'undefined'){
            }else{
                const json2 = await response2.json()
                if(json2.hasOwnProperty('message') === true){
                    //props.changeLoader(false)
                }else{
                    props.getMyAuction(json2)
                    //props.changeLoader(false)
                }
            }
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctionsMy: state.start.auctionsMy,
        dictionaries: state.start.dictionaries,
        addressNew: state.start.addressNew,
        schedule: state.start.schedule,
        bankAccountZU: state.start.bankAccountZU,
        bankAccount: state.start.bankAccount,
        createBankAccountZU: state.start.createBankAccountZU,
        createBankAccountZUtimestamp: state.start.createBankAccountZUtimestamp,
        deleteCurentBank: state.start.deleteCurentBank,
        deleteBankGroops: state.start.deleteBankGroops
    }
}

const mapDispatchToProps = {
    setDataAddress,
    getMyAuction,
}

export default connect(mapStateToProps, mapDispatchToProps)(BankAccountsGroups)
