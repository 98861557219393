import React from 'react';
import styles from './auctionDataLine_Title.module.css';

import { Link } from 'react-router-dom';

import {connect} from 'react-redux';
import {changePageNumber} from '../../../../../../../redux/actions.js';
import {auctionGetId} from '../../../../../../../redux/actions/auctions/auctions';

const AuctionDataLine_Title = ( props ) => {
    let tmp = null;
    if(props.item.hasOwnProperty('id') === true){
        tmp = (
            <h2
                className={styles.secondBlock_headTovar}
                onClick={
                    () => {
                        props.changePageNumber(props.item.id)
                    }
                }
            >
                <Link to={`/tenders/${props.item.id}`}>{viewTitle(props)}</Link>
            </h2>
        )
    }else{
        tmp = (
            <h2 className={styles.secondBlock_headTovar}>
                {viewTitle(props)}
            </h2>
        )
    }
    return tmp
}

function viewTitle(props){
    let tmp = null;
    if(props.item.hasOwnProperty('tenderTitle') === true){
        tmp = props.item.tenderTitle
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
    }
}

const mapDispatchToProps = {
    changePageNumber,
    auctionGetId
}

export default connect(mapStateToProps, mapDispatchToProps)(AuctionDataLine_Title)