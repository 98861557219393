import React from 'react';
import styles from './warehouseComposition.module.css';

import {connect} from 'react-redux';

import TextHalf from './textHalf'
import TextFull from './textFull'

const StructureLot = (props) => {
    let tmp = null;
    if(props.auction !== null){
        if(props.auction.items.length === 0){
            tmp = <div className={styles.documentElem + ' ' + styles.scladLotuElem}>Додані товари відсутні</div>
        }else{
            if(props.auction.items !== null){
                tmp = props.auction.items
                .map(
                    (i, count) => {
                        return viewTypeAuction(i, count)
                    }
                )
            }
        }
    }
    return (
        <div className={styles.infoBlock}>
            <h3>СКЛАД ЛОТУ</h3>
            {tmp}
        </div>
    )
}

function viewTypeAuction(data, count){
    let tmpAddresPostalCode = null;
    let tmpAddresCountryName = null;
    let tmpAddresRegion = null;
    let tmpAddresLlocality = null;
    let tmpAddresIstreetAddress = null;
    if(data.hasOwnProperty('address') === true){
        if(data.address !== null){
            if(data.address.hasOwnProperty('postalCode') === true){
                tmpAddresPostalCode = data.address.postalCode;
            }
            if(data.address.hasOwnProperty('countryName') === true){
                tmpAddresCountryName = data.address.countryName.uk_UA;
            }
            if(data.address.hasOwnProperty('region') === true){
                tmpAddresRegion = data.address.region.uk_UA;
            }
            if(data.address.hasOwnProperty('locality') === true){
                tmpAddresLlocality = data.address.locality.uk_UA;
            }
            if(data.address.hasOwnProperty('streetAddress') === true){
                tmpAddresIstreetAddress = data.address.streetAddress.uk_UA;
            }
        }
    }

    let tmpClassificationScheme = null
    let tmpClassificationId = null
    let tmpClassificationDescription = null
    if(data.classification !== null){
        if(data.classification.hasOwnProperty('description')){
            tmpClassificationScheme = data.classification.scheme
            tmpClassificationId = data.classification.id
            tmpClassificationDescription = data.classification.description.uk_UA
        }
    }
    let address = `${tmpAddresPostalCode}, ${tmpAddresCountryName}, ${tmpAddresRegion}, ${tmpAddresLlocality}, ${tmpAddresIstreetAddress}`
    let tmpClassificationNameStr = `Класифікатор об'єкта ${tmpClassificationScheme}`
    let tmpClassificationSchemeStr = `${tmpClassificationId} - ${tmpClassificationDescription}`
    return (
        <>
            <div className={styles.borderLine}></div>
            <TextFull
                title="Опис об'єкта"
                data={data.description.uk_UA}
            />
            <TextFull
                title={tmpClassificationNameStr}
                data={tmpClassificationSchemeStr}
            />
            <TextFull
                title="Код CPVs:"
                data={additionalClassificationsId(data)}
            />
            <TextHalf
                title='Місцезнаходження'
                data={address}
            />
            {viewAdditionalClassifications(data)}
            <TextHalf
                title="Кількість одиниць"
                data={data.quantity}
            />
            {unitName(data)}
        </>
    )
}

function additionalClassificationsId(data){
    if(data.hasOwnProperty('additionalClassifications')){
        if(data.additionalClassifications !== null){
            if(data.additionalClassifications.length > 0){
                return `${data.additionalClassifications[0].id} - ${data.additionalClassifications[0].description.uk_UA}`
            }
        }
    }
    return null
}

function viewAdditionalClassifications(items){
    let tmp = null
    if(items !== null){
        if(items.hasOwnProperty('additionalClassifications')){
            if(items.additionalClassifications !== null){
                if(items.additionalClassifications.length > 0){
                    tmp = items.additionalClassifications.map(
                        (t) => {
                            return viewLineAdditionalClassifications(t)
                        }
                    )
                }
            }
        }
    }
    return <>{tmp}</>
}

function viewLineAdditionalClassifications(e){
    let tmp = null;
    switch(e.scheme){
        case "timberSpecies":
            tmp = <TextHalf
                title="Порода"
                data={e.description.uk_UA}
            />
        break;
        case "timberStorage":
            tmp = <TextHalf
                title="Склад"
                data={e.description.uk_UA}
            />
        break;
        case "timberSortment":
            tmp = <TextHalf
                title="Асортимент"
                data={e.description.uk_UA}
            />
        break;
        case "timberDiameter":
            tmp = <TextHalf
                title="Група діаметрів"
                data={e.description.uk_UA}
            />
        break;
        case "timberClass":
            tmp = <TextHalf
                title="Клас якості"
                data={e.description.uk_UA}
            />
        break;
        case "timberProductionQuarter":
            tmp = <TextHalf
                title={e.description.uk_UA}
                data={e.id}
            />
        break;
        case "timberProductionYear":
            tmp = <TextHalf
                title={e.description.uk_UA}
                data={e.id}
            />
        break;
        case "timberLength":
            tmp = <TextHalf
                title={e.description.uk_UA}
                data={e.id}
            />
        break;
        default:
            tmp = null;
        break;
    }
    return tmp
}

function unitName(data){
    if(data !== null){
        if(data.unit !== null){
            if(data.unit.name !== null){
                return <TextHalf
                    title="Одиниці виміру"
                    data={data.unit.name.uk_UA}
                />
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        auction: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(StructureLot)
