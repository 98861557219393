import React from 'react';

import {connect} from 'react-redux';

import ContractApprowe from './popupContractApp.js';
import ContractDocumentsNew from '../../product/contractNew/bidAddDocNewPopup';
import AwardDocumentsNew from '../../product/awardNew/bidAddDocNewPopup';

const DeleteBidDocument = ( props ) => {
    if(props.popUpContractApprowe !== null){
        if(props.popUpContractApprowe.typeView === 1){
            return <ContractDocumentsNew />
        }else if(props.popUpContractApprowe.typeView === 2){
            return <ContractApprowe />
        }else if(props.popUpContractApprowe.typeView === 3){
            return <AwardDocumentsNew />
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        popUpContractApprowe: state.start.popUpContractApprowe,
    }
}

export default connect(mapStateToProps, null)(DeleteBidDocument)