import React from 'react';
import styles from './imageAndButtonLine_Right.module.css';

import {connect} from 'react-redux';

import ImageAndButtonLineTextBlock from './imageAndButtonLine_TextBlock/imageAndButtonLine_TextBlock.js';
import ImageAndButtonLineButtonBlock from './imageAndButtonLine_ButtonBlock/imageAndButtonLine_ButtonBlock.js';

const ImageAndButtonLine_Right = ( props ) => {
    return (
        <div className={styles.secondBlock_costButton}>
            <ImageAndButtonLineTextBlock item={props.item} />
            <ImageAndButtonLineButtonBlock item={props.item} />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
    }
}

export default connect(mapStateToProps, null)(ImageAndButtonLine_Right)