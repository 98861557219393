import React, { useState, useEffect } from 'react';
import styles from './bankAccounts.module.css';

import {connect} from 'react-redux';

import {
    setDataAddress,
} from '../../../../../redux/createAddress';

import {
    getMyAuction,
} from '../../../../../redux/actions.js';

import { checkProp, isMongo } from '../../../../../redux/check';
import { isBSE, isBSD, isCSE, isCSD, isALE, isBRE, isBRD, isBRW,
    isRLE, isRLD, isCLE, isCLD, isSPE, isSPD, isNLE, isNLD,
    isLAE, isLAW, isLAP, isLPE, isAPE, isAPD, isLLE, isLLD, isLLP
} from '../../../../../redux/aucTypes';
// import DeleteIcon
//  from '../../../../elements/buttons/svg/deleteIcon';
// import CancelIcon from '../../../../elements/buttons/svg/cancelIcon';
import BankGroup from './bankGroup';

const BankAccountsGroups = ( { bankAccounts, setBankAccounts, newBanks, setNewBankArr, deleteBanks, setDelBankArr, ...props} ) => {

    let tmp = null;

    if(bankAccounts !== null){
        tmp = bankAccounts.map(
            (i) => {
                let obj = {}
                obj.tmpText = 'Видалити групу'
                obj.tmpStyleDelete = ''

                return <BankGroup
                            data={i}
                            key={i.id}
                            bankAccounts={bankAccounts}
                            setBankAccounts={setBankAccounts}
                            disabledBtn={disabledButton(props)}
                        />
            }
        )
    }
    return (
        <div className={styles.bankAccountsGroups}>
            {tmp}
            {helpMessageNeedBanks(props)}
        </div>
    )
}

function helpMessageNeedBanks(props){
    let tmpAP = 0
    let tmpL = 0
    if(props.auctionsMy !== null){
        if(checkProp(props.auctionsMy, 'bankAccounts')){
            if(props.auctionsMy.bankAccounts.length > 0){
                for(let i = 0; i < props.auctionsMy.bankAccounts.length; i++){
                    if(props.auctionsMy.bankAccounts[i].accountType === "advancePayment"){
                        if(props.auctionsMy.bankAccounts[i].holderType !== null || props.auctionsMy.bankAccounts[i].percent !== null){
                            tmpAP = tmpAP + 1
                        }
                    }
                    if(props.auctionsMy.bankAccounts[i].accountType === "lease"){
                        if(props.auctionsMy.bankAccounts[i].holderType !== null || props.auctionsMy.bankAccounts[i].percent !== null){
                            tmpL = tmpL + 1
                        }
                    }
                }
            }
        }
    }
    if(props.bankAccountZU !== null){
        if(props.bankAccountZU.length !== null){
            for(let i = 0; i < props.bankAccountZU.length; i++){
                if(props.bankAccountZU[i].groupe === "advancePayment"){
                    if(props.bankAccountZU[i].holderType !== 'null' || props.bankAccountZU[i].percent !== ''){
                        tmpAP = tmpAP + 1
                    }
                }
                if(props.bankAccountZU[i].groupe === "lease"){
                    if(props.bankAccountZU[i].holderType !== 'null' || props.bankAccountZU[i].percent !== ''){
                        tmpL = tmpL + 1
                    }
                }
            }
        }
    }
    let tmp = null
    let tmp1 = null
    if(
        !isRLE(props.auctionsMy.sellingMethod)
        && !isRLD(props.auctionsMy.sellingMethod)
        && !isCLE(props.auctionsMy.sellingMethod)
        && !isCLD(props.auctionsMy.sellingMethod)){
        if(tmpAP === 1){
            tmp = <div className={styles.errorHelp}>Необхідно додати рахунок для авансового внеску з другим типом утримувача</div>
        }else if(tmpL === 1){
            tmp1 = <div className={styles.errorHelp}>Необхідно додати рахунок для оплати оренди з другим типом утримувача</div>
        }
    }
    return (
        <>
            <div>{tmp}</div>
            <div>{tmp1}</div>
        </>
    )
}

function disabledButton(props){
    if(props.auctionsMy.status === "active_tendering"){
        let tmpStartData = null
        let tmpEndData = null
        if(props.auctionsMy.hasOwnProperty('auctionPeriods')){
            for(let tt = 0; tt < props.auctionsMy.auctionPeriods.length; tt++){
                if(props.auctionsMy.auctionPeriods[tt].status === 'rectificationPeriod'){
                    tmpStartData = new Date(props.auctionsMy.auctionPeriods[tt].startDate).getTime()
                    tmpEndData = new Date(props.auctionsMy.auctionPeriods[tt].endDate).getTime()
                }
            }
        }else{
            if(props.auctionsMy.hasOwnProperty('rectificationPeriod')){
                if(props.auctionsMy.rectificationPeriod !== null){
                    tmpStartData = new Date(props.auctionsMy.rectificationPeriod.startDate).getTime()
                    tmpEndData = new Date(props.auctionsMy.rectificationPeriod.endDate).getTime()
                }
            }
        }
        if(
            (
                isBSE(props.auctionsMy.sellingMethod) || isBSD(props.auctionsMy.sellingMethod) ||
                isALE(props.auctionsMy.sellingMethod) ||
                isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
                isRLE(props.auctionsMy.sellingMethod) || isRLD(props.auctionsMy.sellingMethod) ||
                isCLE(props.auctionsMy.sellingMethod) || isCLD(props.auctionsMy.sellingMethod) ||
                isSPE(props.auctionsMy.sellingMethod) || isSPD(props.auctionsMy.sellingMethod) ||
                isNLE(props.auctionsMy.sellingMethod) || isNLD(props.auctionsMy.sellingMethod) ||
                isLAE(props.auctionsMy.sellingMethod) || isLAP(props.auctionsMy.sellingMethod) ||
                isLPE(props.auctionsMy.sellingMethod) ||
                isAPE(props.auctionsMy.sellingMethod) || isAPD(props.auctionsMy.sellingMethod)
                || isLLE(props.auctionsMy.sellingMethod) || isLLD(props.auctionsMy.sellingMethod) || isLLP(props.auctionsMy.sellingMethod)
            ) && (
                tmpStartData < Date.now() && tmpEndData > Date.now()
                )
        ){
            return null
        }else if(isLAW(props.auctionsMy.sellingMethod)){
            if(
                props.auctionsMy.status === 'complete' ||
                props.auctionsMy.status === 'cancelled' ||
                props.auctionsMy.status === 'unsuccessful' 
            ){
                return 'disabled'
            }
            return null
        }else if(isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod) || isBRW(props.auctionsMy.sellingMethod)){
            if(
                props.auctionsMy.status === 'complete' ||
                props.auctionsMy.status === 'cancelled' ||
                props.auctionsMy.status === 'unsuccessful' 
            ){
                return 'disabled'
            }else if(
                props.auctionsMy.status === 'active_auction' ||
                props.auctionsMy.status === 'pending_payment' ||
                props.auctionsMy.status === 'active_qualification'
            ){
                return null
            }else if(props.auctionsMy.status === 'active_awarded'){
                if(props.auctionsMy.contracts !== null && props.auctionsMy.contracts.length > 0){
                    let tmp = props.auctionsMy.contracts.filter(
                        (e) => {
                            return (e.status === 'pending')
                        }
                    )
                    
                    if(tmp.length > 0) return 'disabled'
                }
            }
            return null
        }else{
            return 'disabled'
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctionsMy: state.start.auctionsMy,
        dictionaries: state.start.dictionaries,
        addressNew: state.start.addressNew,
        schedule: state.start.schedule,
        bankAccountZU: state.start.bankAccountZU,
        bankAccount: state.start.bankAccount,
        createBankAccountZU: state.start.createBankAccountZU,
        createBankAccountZUtimestamp: state.start.createBankAccountZUtimestamp,
        deleteCurentBank: state.start.deleteCurentBank,
        deleteBankGroops: state.start.deleteBankGroops
    }
}

const mapDispatchToProps = {
    setDataAddress,
    getMyAuction,
}

export default connect(mapStateToProps, mapDispatchToProps)(BankAccountsGroups)
