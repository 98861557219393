import React,{useState} from 'react';
import styles from './newContract.module.css';

import {connect} from 'react-redux';

import { checkProp } from '../../../redux/check';
import { isTIE, isSUE, isSUD, isRCE, isRCD, isGFE, isGFD, isLLE, isLLD,
            isLLP, isLRE, isLSE, isLSP, isRMA, isBSE, isBSD, isALE, isCSE,
            isCSD, isBRE, isBRD, isBRW, isRLE, isRLD, isCLE, isCLD, isSPE, isSPD,
            isNLE, isNLD, isLAE, isLAW, isLAP, isLPE, isAPE, isAPD
        } from '../../../redux/aucTypes';
    
import {auctionsGetAwards, auctionsGetAwardsId, changeAwardRejectPopUpStatus, changeAwardApprowePopUpStatus, changeAwardView} from '../../../redux/actions/awards/awards.js';

import { setAwardAllUserPopUp, setAwardAllUserPopUpUser } from '../../../redux/actions.js';
import { getUpdateDataAuction } from '../../product/updateData';
import { setDataAddress } from '../../../redux/createAddress';

// import AwardDataPopUp from './popUpDataAward/popUpDataAward.js';
// import AwardDataPopUpUser from './popUpDataAward/popUpDataAwardUser.js';

// import {
//     changeContractApprowePopUpStatus,
//     setContractTitle,
//     setContractDescription,
//     setContractContractNumber,
//     setContractDateFrom,
//     setContractDateTill,
//     setContractStartDate,
//     setContractEndDate,
//     setContractCurrency,
//     setContractAmount,
//     setContractDateSigned,
//     setContractDatePaid,
// } from '../../../redux/actions/contracts/contracts.js';

import {
    changeContractApprowePopUpStatus,
    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,
    setContractDatePaid,
    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
} from '../../../redux/actions/contracts/contracts.js';

import TextHalf from '../../elements/viewData/textHalf'
import TextFull from '../../elements/viewData/textFull'
import PopDown from '../../elements/viewData/popdownBlock/popDownBlock'
import DocItem from '../../documents/docItem';
import AddDocPopup from '../../documents/addDocPopup/addDocPopup';

import {
    histeryAuctions,
    histeryAwards,
    histeryContrasts,
    histeryBids,
} from '../../../redux/histery.js';
import {setUniversalError, changeLoader} from '../../../redux/actions.js';
import {contractsAuctionIdGet} from '../../../redux/actions/contracts/contracts.js';

import ContractComponentsDate from './contractComponents/contractComponentsDate/contractComponentsDate'

import {modifiDateString} from '../../../redux/modifiDate'
import { CONTACTS_TITLE_BOTTOM } from '../../../model/lang/topMenu';

import Input from '../../elements/inputs/input';
import InputDate from '../../elements/inputs/inputDate';
import InputText from '../../elements/inputs/input';
import TextArea from '../../elements/inputs/textArea';
import InputAmount from '../../elements/inputs/inputAmount';

import { is } from 'date-fns/locale';

import ConractOther from './contractForm/other'
import ContractLreLseLsp from './contractForm/LreLspLse'
import ContractBRW from './contractForm/Brw'
import ContractSPE from './contractForm/Spe'
import ContractNLE from './contractForm/nle'
import ContractLAE from './contractForm/lae'
import ContractLAP from './contractForm/lap'
import ContractLPE from './contractForm/lpe'
import ContractAPE from './contractForm/ape'

const Contract = (props) => {
    if(props.auctionId !== null){
        if(isRCE(props.auctionId.sellingMethod) || isRCD(props.auctionId.sellingMethod)) return null
        if(isBRW(props.auctionId.sellingMethod)){
            return <ContractBRW />
        }else if(isSPE(props.auctionId.sellingMethod) || isSPD(props.auctionId.sellingMethod)){
            return <ContractSPE />
        }else if(
            isLRE(props.auctionId.sellingMethod)
            || isLSE(props.auctionId.sellingMethod)
            || isLSP(props.auctionId.sellingMethod)
        ){
            return <ContractLreLseLsp />
        }else if(isNLE(props.auctionId.sellingMethod) || isNLD(props.auctionId.sellingMethod)){
            return <ContractNLE />
        }else if(isLAE(props.auctionId.sellingMethod) || isLAW(props.auctionId.sellingMethod)){
            return <ContractLAE />
        }else if(isLAP(props.auctionId.sellingMethod)){
            return <ContractLAP />
        }else if(isLPE(props.auctionId.sellingMethod)){
            return <ContractLPE />
        }else if(isAPE(props.auctionId.sellingMethod) || isAPD(props.auctionId.sellingMethod)){
            return <ContractAPE />
        }else{
            return <ConractOther />
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

const mapDispatchToProps = {
    changeAwardRejectPopUpStatus,
    setAwardAllUserPopUp,
    setAwardAllUserPopUpUser,

    changeContractApprowePopUpStatus,

    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,

    histeryAuctions,
    histeryAwards,
    histeryContrasts,
    histeryBids,

    setUniversalError,
    changeLoader,
    contractsAuctionIdGet,
    setContractDatePaid,

    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(Contract)
