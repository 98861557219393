import React from 'react';
import styles from './vehicleItem.module.css';

import {connect} from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import {
    setDataAddress,
} from '../../../../../redux/createAddress';

import {
    getMyAuction,
    changeLoader,
    setUniversalError,
} from '../../../../../redux/actions.js';

import {popUpAddress} from '../../../../../redux/actions/address/address.js';
import { DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";

import ZuMap from '../../../../map/zuMaps'

import {disabletElem} from '../../../disabled'

const VehicleItem = ( props ) => {
    /*let tmp = 'null'
    if(props.createItems.zu.vehicleItem.additionalClassifications !== null){
        tmp = props.createItems.zu.vehicleItem.additionalClassifications.id
    }*/
    let tmpZuMap = null
    /*if(props.createItems.zu.vehicleItem.location !== null){
        if(
            (props.createItems.zu.vehicleItem.location.latitude !== null && props.createItems.zu.vehicleItem.location.latitude !== '')  &&
            (props.createItems.zu.vehicleItem.location.longitude !== null && props.createItems.zu.vehicleItem.location.longitude !== '')){
            tmpZuMap = <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <ZuMap
                        latitude = {props.createItems.zu.vehicleItem.location.latitude}
                        longitude = {props.createItems.zu.vehicleItem.location.longitude}
                    />
                </div>
            </div>
        }
    }*/
    tmpZuMap = <div className={styles.groupElem + ' ' + styles.groupElemCategory+ ' ' + styles.groupElemCategoryTwo}>
        <div>
            <ZuMap
                latitude = {props.createItems.landRental.location.latitude}
                longitude = {props.createItems.landRental.location.longitude}
            />
        </div>
    </div>
    let closeDraftElem = false
    if(
        props.auctionsMy.status === null ||
        props.auctionsMy.status === '' ||
        props.auctionsMy.tenderAttempts === '1' ||
        props.auctionsMy.tenderAttempts === 1
    ){
        closeDraftElem = true
    }
    return (
        <>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Назва об'єкта"
                        variant="outlined"
                        value={props.createItems.zu.vehicleItem.title}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_VEHICLE_ITEM_TITLE')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Опис об'єкта"
                        variant="outlined"
                        multiline
                        rows={10}
                        value={props.createItems.zu.vehicleItem.description}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_VEHICLE_ITEM_DESCRIPTION')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        id="outlined-select-currency-native"
                        select
                        label="Основний класифікатор"
                        value={props.createItems.zu.vehicleItem.classification}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_VEHICLE_ITEM_CLASSIFICATION')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Основний класифікатор"
                        variant="outlined"
                    >
                        <option key='0' selected disabled value="null">Обрати</option>
                        {classificationIdOption(props)}
                    </TextField>
                </div>
            </div>
            {/*<div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        label="Додатковий класифікатор об'єкта"
                        value={tmp}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_VEHICLE_ITEM_ADDITIONAL_CLASSIFICATION')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Додатковий класифікатор об'єкта"
                        variant="outlined"
                    >
                        <option key='0' selected disabled value="null">Обрати</option>
                        {addClassificationIdOption(props)}
                    </TextField>
                </div>
            </div>*/}
            {/*<div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.zu.vehicleItem.address)}
                        disabled={disabletElem(props.auctionsMy, '00')}
                        required
                        id="outlined-select-currency-native"
                        select
                        label="Адреса"
                        value={props.createItems.zu.vehicleItem.address}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_VEHICLE_ITEM_ADDRESS')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Оберіть адресу"
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        {addressArr(props)}
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <Button
                        disabled={disabletElem(props.auctionsMy, '00')}
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                props.setDataAddress(true, 'SET_POPUP_ADDRESSES')
                            }
                        }
                    >
                        Створити та редагувати адресу
                    </Button>
                </div>
            </div>
            <div>Координати</div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.zu.vehicleItem.location.latitude)}
                        required
                        disabled={disabletElem(props.auctionsMy, '11')}
                        id="outlined-select-currency-native"
                        label="Широта"
                        value={props.createItems.zu.vehicleItem.location.latitude}
                        onChange={(event) => {
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_VEHICLE_ITEM_LOCATION_LATITUDE')
                        }}
                        name="numberformat"
                        id="formatted-numberformat-input"
                        variant="outlined"
                        helperText=" "
                    />
                </div>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.zu.vehicleItem.location.longitude)}
                        required
                        disabled={disabletElem(props.auctionsMy, '11')}
                        id="outlined-select-currency-native"
                        label="Довгота"
                        value={props.createItems.zu.vehicleItem.location.longitude}
                        onChange={(event) => {
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_VEHICLE_ITEM_LOCATION_LONGITUDE')
                        }}
                        name="numberformat"
                        id="formatted-numberformat-input"
                        variant="outlined"
                        helperText=" "
                    />
                </div>
            </div>*/}
            {tmpZuMap}
            <div className={styles.lineButtons}>
                <div className={styles.editButton}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={
                            ()=>{
                                setNewAddress(props)
                            }
                        }
                    >
                        Додати обрані координати
                    </Button>
                </div>
            </div>
            <div>Адреса</div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled
                        error = {redBlock(props, props.createItems.landRental.addressItem.countryName)}
                        required
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Країна"
                        variant="outlined"
                        value={props.createItems.landRental.addressItem.countryName}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_COUNTRY_NAME')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled
                        error = {redBlock(props.createItems.landRental.addressItem.region)}
                        required
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Область"
                        variant="outlined"
                        value={props.createItems.landRental.addressItem.region}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_REGION')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.landRental.addressItem.locality)}
                        required
                        disabled={disabletElem(props.auctionsMy, '01')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Населенний пункт"
                        variant="outlined"
                        value={props.createItems.landRental.addressItem.locality}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_LOCALITY')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.landRental.addressItem.streetAddress)}
                        required
                        disabled={disabletElem(props.auctionsMy, '01')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Вулиця, буд. №"
                        variant="outlined"
                        value={props.createItems.landRental.addressItem.streetAddress}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_STREET_ADDRESS')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.landRental.addressItem.postalCode)}
                        required
                        disabled={disabletElem(props.auctionsMy, '00')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Поштовий індекс"
                        variant="outlined"
                        value={props.createItems.landRental.addressItem.postalCode}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_POSTAL_CODE')
                        }}
                    />
                </div>
            </div>


            <div>Координати</div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.landRental.location.latitude)}
                        disabled
                        id="outlined-select-currency-native"
                        label="Широта"
                        value={props.createItems.landRental.location.latitude}
                        onChange={(event) => {
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE')
                        }}
                        name="numberformat"
                        id="formatted-numberformat-input"
                        variant="outlined"
                        helperText=" "
                    />
                </div>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.landRental.location.longitude)}
                        disabled
                        id="outlined-select-currency-native"
                        label="Довгота"
                        value={props.createItems.landRental.location.longitude}
                        onChange={(event) => {
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE')
                        }}
                        name="numberformat"
                        id="formatted-numberformat-input"
                        variant="outlined"
                        helperText=" "
                    />
                </div>
            </div>


            <div>Інформація щодо реєстрації</div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.zu.vehicleItem.registrationDetails.registrationStatus)}
                        required
                        disabled={disabletElem(props.auctionsMy, '00')}
                        id="outlined-select-currency-native"
                        select
                        label="Стан державної реєстрації об'єкту"
                        value={props.createItems.zu.vehicleItem.registrationDetails.registrationStatus}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Оберіть стан державної реєстрації об''єкту"
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="notRegistered">Не зареєстровано</option>
                        <option key='2' value="oldRegistration">Зареєстровано до 1 січня 2013 р. відповідно до законодавства, що діяло на момент виникнення речового права на майно</option>
                        <option key='3' value="properlyRegistered">Зареєстровано в Державному реєстрі речових прав на нерухоме майно</option>
                        <option key='4' value="registrationIsNotRequired">Реєстрація не вимагається</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '00')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Номер запису"
                        variant="outlined"
                        value={props.createItems.zu.vehicleItem.registrationDetails.registrationID}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_ID')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>Дата реєстрації</div>
                <div>
                    <KeyboardDateTimePicker
                        disabled={disabletElem(props.auctionsMy, '00')}
                        className={styles.dateSearch}
                        value={props.createItems.zu.vehicleItem.registrationDetails.registrationDate}
                        onChange={(e)=>{
                            //let tmpData = new Date(e)
                            //let tmpString = `${tmpData.getFullYear()}-${tmpData.getMonth()+1}-${tmpData.getDate()}T${tmpData.getHours()}:${tmpData.getMinutes()}:00+00:00`
                            props.setDataAddress(e, 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_DATE')
                        }}
                        onError={console.log}
                        minDate={new Date("1900-01-01T00:00")}
                        ampm={false}
                        format="yyyy/MM/dd HH:mm"
                        helperText="Формат дати: гггг/мм/дд чч:мм"
                    />
                    {/*<TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Дата реєстрації"
                        variant="outlined"
                        value={props.createItems.zu.vehicleItem.registrationDetails.registrationDate}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_DATE')
                        }}
                    />*/}
                </div>
            </div>
            {/*<div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Тип об'єкта майна"
                        variant="outlined"
                        value={props.createItems.zu.vehicleItem.itemType}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_VEHICLE_ITEM_ITEM_TYPE')
                        }}
                    />
                </div>
            </div>*/}
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Марка транспортного засобу"
                        variant="outlined"
                        value={props.createItems.zu.vehicleItem.vehicleProps.brand}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_BRAND')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Модель транспортного засобу"
                        variant="outlined"
                        value={props.createItems.zu.vehicleItem.vehicleProps.model}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_MODEL')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Рік випуску"
                        variant="outlined"
                        value={props.createItems.zu.vehicleItem.vehicleProps.productionYear}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_PRODUCTION_YEAR')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Об’єм двигуна, см³"
                        variant="outlined"
                        value={props.createItems.zu.vehicleItem.vehicleProps.engineCapacity}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_ENGINE_CAPACITY')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        id="outlined-select-currency-native"
                        select
                        label="Тип палива"
                        value={props.createItems.zu.vehicleItem.vehicleProps.fuelType}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_FUEL_TYPE')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Оберіть тип палива"
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="diesel">Дизель</option>
                        <option key='2' value="electric">Електро</option>
                        <option key='3' value="gasoline">Бензин</option>
                        <option key='4' value="hybrid">Гібридний</option>
                        <option key='5' value="liquefiedPetroleumGas">Зріджений нафтовий газ</option>
                        <option key='6' value="naturalGas">Газ</option>
                        <option key='7' value="other">Інший</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        id="outlined-select-currency-native"
                        select
                        label="Тип коробки передач"
                        value={props.createItems.zu.vehicleItem.vehicleProps.transmission}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_TRANSMISSION')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Оберіть тип коробки передач"
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="automatic">Автоматична</option>
                        <option key='2' value="manual">Механічна</option>
                        <option key='3' value="other">Інше</option>
                        <option key='4' value="semiAutomatic">Напівавтоматична</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Колір"
                        variant="outlined"
                        value={props.createItems.zu.vehicleItem.vehicleProps.color}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_COLOR')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Пробіг, км"
                        variant="outlined"
                        value={props.createItems.zu.vehicleItem.vehicleProps.kilometrage}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_KILOMETRAGE')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Номер кузова або шасі (VIN-код)"
                        variant="outlined"
                        value={props.createItems.zu.vehicleItem.vehicleProps.identificationNumber}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_IDENTIFICATION_NUMBER')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Комплектація"
                        variant="outlined"
                        value={props.createItems.zu.vehicleItem.vehicleProps.configuration}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_CONFIGURATION')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Технічний стан"
                        variant="outlined"
                        value={props.createItems.zu.vehicleItem.vehicleProps.condition}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_CONDITION')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        id="outlined-select-currency-native"
                        select
                        label="Потреба у ремонті"
                        value={props.createItems.zu.vehicleItem.vehicleProps.damagePresence}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_DAMAGE_PRESENCE')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Оберіть потреба у ремонті"
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1'value={false}>Ні</option>
                        <option key='2'value={true}>Так</option>
                    </TextField>
                </div>
            </div>
            {onOffDamagedDescription(props, closeDraftElem)}
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled='disabled'
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Кількість одиниць"
                        variant="outlined"
                        value='1'
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_VEHICLE_ITEM_QUANTITY')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        error = {redBlock(props, props.createItems.zu.vehicleItem.unitCode)}
                        disabled={disabletElem(props.auctionsMy, '00')}
                        required
                        id="outlined-select-currency-native"
                        select
                        label="Одиниці виміру"
                        value={props.createItems.zu.vehicleItem.unitCode}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_VEHICLE_ITEM_UNIT_CODE')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Оберіть одиницю виміру"
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        {classificationSubsoilUnitCode(props)}
                    </TextField>
                </div>
            </div>
            {viewButtons(props)}
        </>
    )
}

function setNewAddress(props) {
    let map = window.parent.document.getElementById("target").contentWindow.document.getElementById('map');
    let tmpLat = map.dataset.lat.split('.')
    let tmpLng = map.dataset.lng.split('.')
    let newAddress = {
        //address: `${map.dataset.city !== '' ? map.dataset.city : ''}${map.dataset.street !== '' ? ', ' + map.dataset.street : ''}${map.dataset.house !== '' ? ', будинок ' + map.dataset.house + ',' : ''}`,
        address: `${map.dataset.street !== '' ? map.dataset.street : ''}${map.dataset.house !== '' ? ', будинок ' + map.dataset.house + ',' : ''}`,
        postcode: map.dataset.postcode,
        region: map.dataset.region,
        //lat: map.dataset.lat,
        lat: `${tmpLat[0]}.${tmpLat[1].slice(0, 5)}`,
        //lng: map.dataset.lng,
        lng: `${tmpLng[0]}.${tmpLng[1].slice(0, 5)}`,
        locality: map.dataset.city,
        street: map.dataset.street
    }
    if (map.dataset.country === 'Україна' || map.dataset.country === 'Донецька область'){
        if(map.dataset.country === 'Донецька область'){
            newAddress.country = 'Україна'
        }else{
            newAddress.country = map.dataset.country
        }
    }
    props.setDataAddress(newAddress.country, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_COUNTRY_NAME')
    props.setDataAddress(newAddress.region, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_REGION')
    props.setDataAddress(newAddress.locality, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_LOCALITY')
    props.setDataAddress(newAddress.address, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_STREET_ADDRESS')
    props.setDataAddress(newAddress.postcode, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_POSTAL_CODE')

    props.setDataAddress(newAddress.lat, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE')
    props.setDataAddress(newAddress.lng, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE')
}

function blokedInput(props, type){
    if(props.createItems.landRental.id !== null){
        return disabletElem(props.auctionsMy, type)
    }else if(
        props.auctionsMy.items === null ||
        props.auctionsMy.items.length === 0
    ){
        return disabletElem(props.auctionsMy, type)
    }else{
        return 'disabled'
    }
}

function onOffDamagedDescription(props, closeDraftElem){
    if(
        props.createItems.zu.vehicleItem.vehicleProps.damagePresence === true ||
        props.createItems.zu.vehicleItem.vehicleProps.damagePresence === 'true'
    ){
        return (
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '01')}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Опис пошкоджень"
                        variant="outlined"
                        value={props.createItems.zu.vehicleItem.vehicleProps.damagedDescription}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_DAMAGED_DESCRIPTION')
                        }}
                    />
                </div>
            </div>
        )
    }
    return null
}

function blockedInput(props){
    if(props.auctionsMy.status !== 'active_rectification'){
        return 'disabled'
    }
    return null
}

function classificationIdOption(props){
    if(props.classificationsDgf.classificationsByTypeMain !== null){
        return props.classificationsDgf.classificationsByTypeMain.map(
            (i, count) => {
                return <option key={count} value={i.id}>{i.scheme + ' ' + i.classificationId + ' ' + i.description}</option>
            }
        )
    }
}
function addClassificationIdOption(props){
    if(props.classificationsDgf.classificationsAdd !== null){
        return props.classificationsDgf.classificationsAdd.map(
            (i, count) => {
                return <option key={count} value={i[0].id}>{i[0].scheme + ' ' + i[0].classificationId + ' ' + i[0].description}</option>
            }
        )
    }
}

function viewButtons(props){
    if(props.auctionsMy.items === null || props.auctionsMy.items.length === 0){
        if(
            //props.createItems.zu.vehicleItem.address !== 'null' &&
            //props.createItems.zu.vehicleItem.location.latitude !== '' &&
            //props.createItems.zu.vehicleItem.location.longitude !== '' &&
            props.createItems.zu.vehicleItem.quantity !== '' &&
            props.createItems.zu.vehicleItem.unitCode !== 'null'
        ){  
            return (
                <div className={styles.lineButtons}>
                    <div className={styles.editButton}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={
                                () => {
                                    createItemZU(props)
                                }
                            }
                        >
                            Зберегти зміни
                        </Button>
                    </div>
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={
                                () => {
                                    props.setDataAddress('null', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')

                                    props.setDataAddress(null, 'CREATE_ITEMS_VEHICLE_ITEM_ID')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_TITLE')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_DESCRIPTION')
                                    props.setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_CLASSIFICATION')
                                    props.setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_ADDRESS')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_LOCATION_LATITUDE')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_LOCATION_LONGITUDE')
                                    props.setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_ID')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_DATE')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_QUANTITY')
                                    props.setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_UNIT_CODE')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_BRAND')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_MODEL')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_PRODUCTION_YEAR')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_ENGINE_CAPACITY')
                                    props.setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_FUEL_TYPE')
                                    props.setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_TRANSMISSION')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_COLOR')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_KILOMETRAGE')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_IDENTIFICATION_NUMBER')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_CONFIGURATION')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_CONDITION')
                                    props.setDataAddress(false, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_DAMAGE_PRESENCE')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_DAMAGED_DESCRIPTION')
                                }
                            }
                        >
                            Закрити
                        </Button>
                    </div>
                </div>
            )
        }
    }else{
        if(
            //props.createItems.zu.vehicleItem.address !== 'null' &&
            //props.createItems.zu.vehicleItem.location.latitude !== '' &&
            //props.createItems.zu.vehicleItem.location.longitude !== '' &&
            props.createItems.zu.vehicleItem.quantity !== '' &&
            props.createItems.zu.vehicleItem.unitCode !== 'null'
        ){ 
            return (
                <div className={styles.lineButtons}>
                    <div className={styles.editButton}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={
                                () => {
                                    if(props.createItems.zu.vehicleItem.id === null){
                                        createItemZU(props)
                                    }else{
                                        saveItemZU(props)
                                    }
                                }
                            }
                        >
                            Зберегти зміни
                        </Button>
                    </div>
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={
                                () => {
                                    props.setDataAddress('null', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')

                                    props.setDataAddress(null, 'CREATE_ITEMS_VEHICLE_ITEM_ID')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_TITLE')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_DESCRIPTION')
                                    props.setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_CLASSIFICATION')
                                    props.setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_ADDRESS')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_LOCATION_LATITUDE')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_LOCATION_LONGITUDE')
                                    props.setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_ID')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_DATE')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_QUANTITY')
                                    props.setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_UNIT_CODE')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_BRAND')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_MODEL')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_PRODUCTION_YEAR')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_ENGINE_CAPACITY')
                                    props.setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_FUEL_TYPE')
                                    props.setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_TRANSMISSION')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_COLOR')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_KILOMETRAGE')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_IDENTIFICATION_NUMBER')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_CONFIGURATION')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_CONDITION')
                                    props.setDataAddress(false, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_DAMAGE_PRESENCE')
                                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_DAMAGED_DESCRIPTION')
                                }
                            }
                        >
                            Закрити
                        </Button>
                    </div>
                </div>
            )
        }
    }
}

function textData(value){
    let tmp = ''
    if(value !== ''){
        tmp = value.split('T');
        return tmp[0]
    }
    return tmp[0]
}

function elementDisabled(props){
    let tmpStatus = null;
    if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            tmpStatus = 'disabled'
        }
    }
    return tmpStatus
}

function classificationSubsoilUnitCode(props){
    if(props.classificationsZU.unitCode !== null){
        return props.classificationsZU.unitCode
        .filter(
            (i) => {
                return i.code === 'H87'
            }
        )
        .map(
            (i, count) => {
                return <option key={count} value={i.code}>{i.name}</option>
            }
        )
    }
}

function addressArr(props){
    if(props.addressGetId !== null){
        return props.addressGetId.map(
            (i, count) => {
                return <option key={count} value={i.id}>{i.postalCode + ' ' + i.region + ' ' + i.locality + ' ' + i.streetAddress}</option>
            }
        )
    }
}

function elementDisabledCreate(props){
    let tmpStatus = null;
    if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            tmpStatus = 'disabled'
        }
    }
    return tmpStatus
}

function redBlock(props, amount){
    if(props.auctionsMy === undefined || props.auctionsMy === 'undefined'){
        return null
    }else{
        if(
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
        ){
            if(amount === null || amount === '' || amount === 'null'){
                return 'error'
            }
            return null
        }
    }
    return null
}

async function createItemZU(props){
    props.changeLoader(true)
    //let tmpAdress = await createAddress(props)
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}/item`;
            const response = await fetch(endPoint, {
                method: "POST",
                body: JSON.stringify({
                    title: props.createItems.zu.vehicleItem.title,
                    description: props.createItems.zu.vehicleItem.description,
                    classificationId: Number(props.createItems.zu.vehicleItem.classification),
                    //additionalClassificationIds: [props.createItems.zu.vehicleItem.additionalClassification],
                    /*addressId: Number(props.createItems.zu.vehicleItem.address),
                    location: {
                        latitude: Number(props.createItems.zu.vehicleItem.location.latitude),
                        longitude: Number(props.createItems.zu.vehicleItem.location.longitude),
                        //elevation: props.createItems.zu.vehicleItem.location.elevation
                    },*/
                    //addressId: tmpAdress,
                    address: {
                        addressIdentifier: {
                            scheme: 'koatuu',
                            identifier: setKoatuu(props.createItems.landRental.addressItem.region),
                            name: props.createItems.landRental.addressItem.region
                        },
                        streetAddress: props.createItems.landRental.addressItem.streetAddress,
                        locality: props.createItems.landRental.addressItem.locality,
                        region: props.createItems.landRental.addressItem.region,
                        postalCode: props.createItems.landRental.addressItem.postalCode,
                        countryName: props.createItems.landRental.addressItem.countryName
                    },
                    location: {
                        latitude: Number(props.createItems.landRental.location.latitude),
                        longitude: Number(props.createItems.landRental.location.longitude),
                    },
                    itemType: props.createItems.zu.vehicleItem.itemType,
                    quantity: 1,
                    unitCode: props.createItems.zu.vehicleItem.unitCode,
                }),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
                props.changeLoader(false)
            }else{
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    props.changeLoader(false)
                    let tmp = JSON.stringify({title: 'Помилка при створенні лоту', message: JSON.stringify(json)})
                    props.setUniversalError(tmp)
                }else{
                    await createRegDetal(props, json.id)
                    await saveItemZuVehicleProps(props, json.id, 'POST')
                    props.setDataAddress('null', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')

                    props.setDataAddress(null, 'CREATE_ITEMS_VEHICLE_ITEM_ID')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_TITLE')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_DESCRIPTION')
                    props.setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_CLASSIFICATION')
                    props.setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_ADDRESS')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_LOCATION_LATITUDE')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_LOCATION_LONGITUDE')
                    props.setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_ID')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_DATE')
                    props.setDataAddress('1', 'CREATE_ITEMS_VEHICLE_ITEM_QUANTITY')
                    props.setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_UNIT_CODE')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_BRAND')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_MODEL')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_PRODUCTION_YEAR')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_ENGINE_CAPACITY')
                    props.setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_FUEL_TYPE')
                    props.setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_TRANSMISSION')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_COLOR')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_KILOMETRAGE')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_IDENTIFICATION_NUMBER')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_CONFIGURATION')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_CONDITION')
                    props.setDataAddress(false, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_DAMAGE_PRESENCE')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_DAMAGED_DESCRIPTION')
                }
            }
        }
    }
}

async function saveItemZU(props){
    props.changeLoader(true)
    //let tmpAdress = await createAddress(props)
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}/item/${props.createItems.zu.vehicleItem.id}`;
            const response = await fetch(endPoint, {
                method: "PUT",
                body: JSON.stringify({
                    title: props.createItems.zu.vehicleItem.title,
                    description: props.createItems.zu.vehicleItem.description,
                    classificationId: Number(props.createItems.zu.vehicleItem.classification),
                    //additionalClassificationIds: [props.createItems.zu.vehicleItem.additionalClassifications.id],
                    /*addressId: Number(props.createItems.zu.vehicleItem.address),
                    location: {
                        latitude: Number(props.createItems.zu.vehicleItem.location.latitude),
                        longitude: Number(props.createItems.zu.vehicleItem.location.longitude),
                        //elevation: props.createItems.zu.vehicleItem.location.elevation..
                    },*/
                    //addressId: tmpAdress,
                    address: {
                        addressIdentifier: {
                            scheme: 'koatuu',
                            identifier: setKoatuu(props.createItems.landRental.addressItem.region),
                            name: props.createItems.landRental.addressItem.region
                        },
                        streetAddress: props.createItems.landRental.addressItem.streetAddress,
                        locality: props.createItems.landRental.addressItem.locality,
                        region: props.createItems.landRental.addressItem.region,
                        postalCode: props.createItems.landRental.addressItem.postalCode,
                        countryName: props.createItems.landRental.addressItem.countryName
                    },
                    location: {
                        latitude: Number(props.createItems.landRental.location.latitude),
                        longitude: Number(props.createItems.landRental.location.longitude),
                    },
                    itemType: props.createItems.zu.vehicleItem.itemType,
                    quantity: 1,
                    unitCode: props.createItems.zu.vehicleItem.unitCode,

                }),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
                props.changeLoader(false)
            }else{
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    props.changeLoader(false)
                    let tmp = JSON.stringify({title: 'Помилка при оновленні лоту', message: JSON.stringify(json)})
                    props.setUniversalError(tmp)
                }else{
                    await createRegDetal(props, json.id)
                    await saveItemZuVehicleProps(props, json.id, 'POST')
                    props.setDataAddress('null', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')

                    props.setDataAddress(null, 'CREATE_ITEMS_VEHICLE_ITEM_ID')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_TITLE')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_DESCRIPTION')
                    props.setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_CLASSIFICATION')
                    props.setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_ADDRESS')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_LOCATION_LATITUDE')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_LOCATION_LONGITUDE')
                    props.setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_ID')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_DATE')
                    props.setDataAddress('1', 'CREATE_ITEMS_VEHICLE_ITEM_QUANTITY')
                    props.setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_UNIT_CODE')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_BRAND')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_MODEL')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_PRODUCTION_YEAR')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_ENGINE_CAPACITY')
                    props.setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_FUEL_TYPE')
                    props.setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_TRANSMISSION')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_COLOR')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_KILOMETRAGE')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_IDENTIFICATION_NUMBER')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_CONFIGURATION')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_CONDITION')
                    props.setDataAddress(false, 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_DAMAGE_PRESENCE')
                    props.setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_DAMAGED_DESCRIPTION')
                }
            }
        }
    }
}

async function createRegDetal(props, jsonId){
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            let body = {}
            if(
                props.createItems.zu.vehicleItem.registrationDetails.registrationStatus !== '' &&
                props.createItems.zu.vehicleItem.registrationDetails.registrationStatus !== 'null' &&
                props.createItems.zu.vehicleItem.registrationDetails.registrationStatus !== null
            ){
                body.registrationStatus = props.createItems.zu.vehicleItem.registrationDetails.registrationStatus
            }
            if(
                props.createItems.zu.vehicleItem.registrationDetails.registrationID !== '' &&
                props.createItems.zu.vehicleItem.registrationDetails.registrationID !== 'null' &&
                props.createItems.zu.vehicleItem.registrationDetails.registrationID !== null
            ){
                body.registrationID = props.createItems.zu.vehicleItem.registrationDetails.registrationID
            }
            if(
                props.createItems.zu.vehicleItem.registrationDetails.registrationDate !== '' &&
                props.createItems.zu.vehicleItem.registrationDetails.registrationDate !== 'null' &&
                props.createItems.zu.vehicleItem.registrationDetails.registrationDate !== null
            ){
                body.registrationDate = props.createItems.zu.vehicleItem.registrationDetails.registrationDate
            }
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}/item/${jsonId}/registration_details`;
            const response = await fetch(endPoint, {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
                props.changeLoader(false)
            }else{
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    props.changeLoader(false)
                    let tmp = JSON.stringify({title: 'Помилка при збереженні даних реєстрації', message: JSON.stringify(json)})
                    props.setUniversalError(tmp)
                }
            }
        }
    }
}

async function saveItemZuVehicleProps(props, itemId, flag){
    let body = {}
    if(props.createItems.zu.vehicleItem.vehicleProps.brand !== '') body.brand = props.createItems.zu.vehicleItem.vehicleProps.brand
    if(props.createItems.zu.vehicleItem.vehicleProps.model !== '') body.model = props.createItems.zu.vehicleItem.vehicleProps.model
    if(props.createItems.zu.vehicleItem.vehicleProps.productionYear !== '') body.productionYear = props.createItems.zu.vehicleItem.vehicleProps.productionYear
    if(props.createItems.zu.vehicleItem.vehicleProps.engineCapacity !== '') body.engineCapacity = props.createItems.zu.vehicleItem.vehicleProps.engineCapacity
    if(props.createItems.zu.vehicleItem.vehicleProps.fuelType !== 'null') body.fuelType = props.createItems.zu.vehicleItem.vehicleProps.fuelType
    if(props.createItems.zu.vehicleItem.vehicleProps.transmission !== 'null') body.transmission = props.createItems.zu.vehicleItem.vehicleProps.transmission
    if(props.createItems.zu.vehicleItem.vehicleProps.color !== '') body.color = props.createItems.zu.vehicleItem.vehicleProps.color
    if(props.createItems.zu.vehicleItem.vehicleProps.kilometrage !== '') body.kilometrage = props.createItems.zu.vehicleItem.vehicleProps.kilometrage
    if(props.createItems.zu.vehicleItem.vehicleProps.identificationNumber !== '') body.identificationNumber = props.createItems.zu.vehicleItem.vehicleProps.identificationNumber
    if(props.createItems.zu.vehicleItem.vehicleProps.configuration !== '') body.configuration = props.createItems.zu.vehicleItem.vehicleProps.configuration
    if(props.createItems.zu.vehicleItem.vehicleProps.condition !== '') body.condition = props.createItems.zu.vehicleItem.vehicleProps.condition
    if(props.createItems.zu.vehicleItem.vehicleProps.damagedDescription !== '') body.damagedDescription = props.createItems.zu.vehicleItem.vehicleProps.damagedDescription
    if(props.createItems.zu.vehicleItem.vehicleProps.damagePresence !== 'null') body.damagePresence = props.createItems.zu.vehicleItem.vehicleProps.damagePresence
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}/item/${itemId}/property_props/VehicleProps`;
    const response = await fetch(endPoint, {
        method: flag,
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        props.changeLoader(false)
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            props.changeLoader(false)
            let tmp = JSON.stringify({title: 'Помилка при збереженні доданих даних', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            let header = {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
            const endPoint2t = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}`;
            const response2t = await fetch(endPoint2t, {
                    method: "GET",
                    headers: header
            }).catch(console.error)
            if(response2t === undefined || response2t === 'undefined'){
                props.changeLoader(false)
            }else{
                const json2t = await response2t.json()
                if(json2t.hasOwnProperty('message') === true){
                    props.changeLoader(false)
                    let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json2t)})
	                props.setUniversalError(tmp)
                }else{
                    props.getMyAuction(json2t)
                    props.changeLoader(false)
                }
            }
        }
    }
}

async function createAddress(props){
    let endPoint = null
    let tmpMetod = null
    if(props.createItems.landRental.addressItem.id === null){
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/address`;
        tmpMetod = 'POST'
    }else{
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/address/${props.createItems.landRental.addressItem.id}`;
        tmpMetod = 'PUT'
    }
    const response = await fetch(endPoint, {
        method: tmpMetod,
        body: JSON.stringify({
            addressIdentifier: {
                scheme: 'koatuu',
                identifier: setKoatuu(props.createItems.landRental.addressItem.region),
                name: props.createItems.landRental.addressItem.region
            },
            streetAddress: props.createItems.landRental.addressItem.streetAddress,
            locality: props.createItems.landRental.addressItem.locality,
            region: props.createItems.landRental.addressItem.region,
            postalCode: props.createItems.landRental.addressItem.postalCode,
            countryName: props.createItems.landRental.addressItem.countryName
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json1 = await response.json()
        if(json1.hasOwnProperty('message') === true){
            //await props.setPopAddressError(json1)
        }else{
            return await json1.id
        }
    }
}

function setKoatuu(data){
	switch(data){
        case 'Севастополь': return "8500000000"
        case 'Київ': return "8000000000"
        case 'Чернігівська область': return "7400000000"
        case 'Чернівецька область': return "7300000000"
        case 'Черкаська область': return "7100000000"
        case 'Хмельницька область': return "6800000000"
        case 'Херсонська область': return "6500000000"
        case 'Харківська область': return "6300000000"
        case 'Тернопільська область': return "6100000000"
        case 'Сумська область': return "5900000000"
        case 'Рівненська область': return "5600000000"
        case 'Полтавська область': return "5300000000"
        case 'Одеська область': return "5100000000"
        case 'Миколаївська область': return "4800000000"
        case 'Львівська область': return "4600000000"
        case 'Луганська область': return "4400000000"
        case 'Кіровоградська область': return "3500000000"
        case 'Київська область': return "3200000000"
        case 'Івано-Франківська область': return "2600000000"
        case 'Запорізька область': return "2300000000"
        case 'Закарпатська область': return "2100000000"
        case 'Житомирська область': return "1800000000"
        case 'Донецька область': return "1400000000"
        case 'Дніпропетровська область': return "1200000000"
        case 'Волинська область': return "0700000000"
        case 'Вінницька область': return "0500000000"
        case 'Автономна Республіка Крим': return "0100000000"
	}
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        auctionsMy: state.start.auctionsMy,
        createItems: state.start.createItems,
        dictionaries: state.start.dictionaries,
        addressGetId: state.start.addressGetId,
        classificationsNadraEnglish: state.start.classificationsNadraEnglish,
        userProfiles: state.start.userProfiles,
        classificationsDgf: state.start.classificationsDgf,
        classificationsZU: state.start.classificationsZU,
        auctionsZUAttemp: state.start.auctionsZUAttemp
    }
}

const mapDispatchToProps = {
    setDataAddress,
    changeLoader,
    getMyAuction,
    popUpAddress,
    setUniversalError,
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleItem)