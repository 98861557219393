import {isNLE, isNLD, isLAE, isLAW, isLAP} from "../../../../../../redux/aucTypes";

export function addProperty(data, body, property) {
    let tmp = body
    if (data.base[property] !== null && data.base[property] !== '' && data.base[property] !== 'null') {
        if (data.base[property] === 'true' || data.base[property] === true) {
            tmp[property] = true
        } else if (data.base[property] === 'false' || data.base[property] === false) {
            tmp[property] = false
        } else {
            if (property === "leaseDuration" && data.base[property] === "P") {
                tmp[property] = null
                return tmp
            }
            tmp[property] = data.base[property]
        }
        return tmp
    } else if (data.base[property] === null || data.base[property] === '' || data.base[property] === 'null') {
        tmp[property] = null
        return tmp
    }
    return body
}

export function addPropertyMonga(data, body, property) {
    let tmp = body
    if (data.base[property] === undefined || data.base[property] === 'undefined') return body
    if (data.base[property] !== null && data.base[property] !== '' && data.base[property] !== 'null') {
        if (data.base[property].hasOwnProperty('uk_UA')) {
            if (data.base[property].uk_UA !== null) {
                tmp[property] = {}
                tmp[property] = data.base[property]
                return tmp
            } else if (data.base[property].uk_UA === null || data.base[property].uk_UA === "" || data.base[property].uk_UA === "null") {
                tmp[property] = null
                return tmp
            }
        } else {
            if (data.base[property] !== null) {
                tmp[property] = {}
                tmp[property].uk_UA = data.base[property]
                return tmp
            } else if (data.base[property].uk_UA === null || data.base[property].uk_UA === "" || data.base[property].uk_UA === "null") {
                tmp[property] = null
                return tmp
            }
        }
    } else if (data.base[property] === null || data.base[property] === '' || data.base[property] === 'null') {
        tmp[property] = null
        return tmp
    }
    return body
}

export function addAuctionPeriodProperty(data, body, property) {
    let tmp = body
    if (data.base[property] !== null && data.base[property] !== '' && data.base[property] !== 'null') {
        if (data.base.hasOwnProperty('startDate') && data.base["startDate"] !== null && data.base["startDate"] !== '' && data.base["startDate"] !== 'null') {
            let auctionPeriod = {}
            auctionPeriod.startDate = data.base["startDate"]
            tmp.auctionPeriod = auctionPeriod
        } else {
            let auctionPeriod = {}
            auctionPeriod.startDate = data.base[property].startDate
            tmp.auctionPeriod = auctionPeriod
        }
        return tmp
    }
    return body
}

export function addPropertyValue(data, body, property) {
    let tmp = body
    if (data.base[property] !== null && data.base[property] !== '' && data.base[property] !== 'null') {
        if (data.base[property].amount !== null && data.base[property].amount !== '') {
            switch (true) {
                case property === 'value':
                case property === "preparationValue":
                    if (data.base[property].valueAddedTaxIncluded === true || data.base[property].valueAddedTaxIncluded === 'true') {
                        tmp[property] = {
                            currency: (data.base[property].currency !== null && data.base[property].currency !== "" && data.base[property].currency !== "null") ? data.base[property].currency : "UAH",
                            amount: Number(data.base[property].amount),
                            valueAddedTaxIncluded: true
                        }
                    } else {
                        tmp[property] = {
                            currency: (data.base[property].currency !== null && data.base[property].currency !== "" && data.base[property].currency !== "null") ? data.base[property].currency : "UAH",
                            amount: Number(data.base[property].amount),
                            valueAddedTaxIncluded: false
                        }
                    }
                    break;

                case property === 'budgetSpent':
                    if (Number(data.base[property].amount) > 0) {
                        tmp[property] = {
                            currency: (data.base[property].currency !== null && data.base[property].currency !== "" && data.base[property].currency !== "null") ? data.base[property].currency : "UAH",
                            amount: Number(data.base[property].amount),
                        }
                        if (isLAE(data.base.sellingMethod) || isLAW(data.base.sellingMethod) || isLAP(data.base.sellingMethod)) {
                            if (data.base[property].valueAddedTaxIncluded === true || data.base[property].valueAddedTaxIncluded === 'true') {
                                tmp[property].valueAddedTaxIncluded = true
                            } else {
                                tmp[property].valueAddedTaxIncluded = false
                            }
                        }
                    } else {
                        tmp[property] = null
                    }
                    break;

                default:
                    tmp[property] = {
                        currency: (data.base[property].currency !== null && data.base[property].currency !== "" && data.base[property].currency !== "null") ? data.base[property].currency : "UAH",
                        amount: Number(data.base[property].amount),
                    }
            }
            return tmp
        } else {
            switch (true) {
                case property === 'value':
                case property === 'preparationValue':
                    if (data.base[property].valueAddedTaxIncluded === true || data.base[property].valueAddedTaxIncluded === 'true') {
                        tmp[property] = {
                            currency: (data.base[property].currency !== null && data.base[property].currency !== "" && data.base[property].currency !== "null") ? data.base[property].currency : "UAH",
                            amount: 0.01,
                            valueAddedTaxIncluded: true
                        }
                    } else {
                        tmp[property] = {
                            currency: (data.base[property].currency !== null && data.base[property].currency !== "" && data.base[property].currency !== "null") ? data.base[property].currency : "UAH",
                            amount: 0.01,
                            valueAddedTaxIncluded: false
                        }
                    }
                    if (isNLE(data.base.sellingMethod) || isNLD(data.base.sellingMethod)) {
                        if (data.base[property].valueAddedTaxIncluded === true || data.base[property].valueAddedTaxIncluded === 'true') {
                            tmp[property] = {
                                currency: (data.base[property].currency !== null && data.base[property].currency !== "" && data.base[property].currency !== "null") ? data.base[property].currency : "UAH",
                                amount: (data.base[property].amount !== null && data.base[property].amount !== "" && data.base[property].amount !== "null") ? data.base[property].amount : null,
                                valueAddedTaxIncluded: true
                            }
                        } else {
                            tmp[property] = {
                                currency: (data.base[property].currency !== null && data.base[property].currency !== "" && data.base[property].currency !== "null") ? data.base[property].currency : "UAH",
                                amount: (data.base[property].amount !== null && data.base[property].amount !== "" && data.base[property].amount !== "null") ? data.base[property].amount : null,
                                valueAddedTaxIncluded: false
                            }
                        }
                    }
                    break;
                case (property === 'budgetSpent'):
                    tmp[property] = null
                    break
                case(property === 'guarantee'):
                case(property === 'registrationFee'):
                    if (!isNLE(data.base.sellingMethod) && !isNLD(data.base.sellingMethod)) {
                        tmp[property] = {
                            currency: 'UAH',
                            amount: 0.01,
                        }
                    }
                    break
                case property === 'minimalStep':
                    tmp[property] = null
                    break
            }
            return tmp
        }
    }
    return body
}

export function addPropertyDiscount(data, body, property) {
    let tmp = body
    if (data.base[property] === null || data.base[property].discount === null || !data.base[property].discount) {
        tmp.discount = null
        return tmp
    }
    if (data.base[property] !== null && data.previousAuctionId !== '' && data.previousAuctionId !== null) {
        if (
            data.base[property].discountPercent !== ''
            && data.base[property].discountPercent !== null
            && data.base[property].discountPercent !== 0
            && data.base[property].discountPercent !== '0'
        ) {
            tmp.discount = {}
            tmp.discount.discount = data.base[property].discount
            if (data.base[property].discountPercent !== '' && data.base[property].discountPercent !== null) {
                tmp.discount.discountPercent = Number(data.base[property].discountPercent)
            } else {
                tmp.discountPercent = 0
            }
            if (data.base[property].previousAuctionValue.amount !== '' && data.base[property].previousAuctionValue.amount !== null) {
                tmp.discount.previousAuctionValue = {}
                tmp.discount.previousAuctionValue.currency = 'UAH'
                tmp.discount.previousAuctionValue.amount = Number(data.base[property].previousAuctionValue.amount)
                if (
                    (data.base[property].previousAuctionValue.valueAddedTaxIncluded === 'null' &&
                        data.base[property].previousAuctionValue.valueAddedTaxIncluded === null) ||
                    (data.base.discount.previousAuctionValue.valueAddedTaxIncluded === 'true' ||
                        data.base.discount.previousAuctionValue.valueAddedTaxIncluded === true)
                ) {
                    tmp.discount.previousAuctionValue.valueAddedTaxIncluded = true
                } else {
                    tmp.discount.previousAuctionValue.valueAddedTaxIncluded = false
                }
            }
            return tmp
        } else {
            tmp.discount = {}
            tmp.discount = null
            return tmp
        }
    }
    return body
}

export function addBankProperty(data, body) {
    let tmpBanks = JSON.parse(JSON.stringify(data.base.bankAccounts))
    for (let key in tmpBanks) {
        tmpBanks[key].accounts = tmpBanks[key].accounts.filter(i => !i.hasOwnProperty('del'))
        for (let i in tmpBanks[key].accounts) {
            if (tmpBanks[key].accounts[i].hasOwnProperty('new') && tmpBanks[key].accounts[i].new) {
                delete tmpBanks[key].accounts[i].new
                delete tmpBanks[key].accounts[i].id
                delete tmpBanks[key].accounts[i].createdByCurrentUser
                delete tmpBanks[key].accounts[i].apiIdentifier
                tmpBanks[key].accounts[i].bankName = {uk_UA: tmpBanks[key].accounts[i].bankName}
                tmpBanks[key].accounts[i].description = {uk_UA: tmpBanks[key].accounts[i].description}
            }
        }
    }
    for (let key in tmpBanks) {
        if (tmpBanks[key].accounts.length === 0) {
            const index = tmpBanks.indexOf(tmpBanks[key])
            tmpBanks.splice(index, 1)
        }
    }
    if (tmpBanks.length === 0) tmpBanks = null//return body.bankAccounts = null
    let tmp = body
    let bankAccounts = {}
    bankAccounts = tmpBanks
    tmp.bankAccounts = bankAccounts
    //body.bankAccounts = tmpBanks///
    return tmp
}

export function addDebtorInformation(data, body, property) {
    let tmp = body
    if (data.base[property] === undefined || data.base[property] === 'undefined') return body
    if (data.base.hasOwnProperty('debtorInformation') === true && data.base.debtorInformation !== null) {
        let debtorInformation = {}
        if (data.base.debtorInformation.hasOwnProperty('name') === true && data.base.debtorInformation.name !== null) {
            let name = {}
            if (data.base.debtorInformation.name.hasOwnProperty('uk_UA') === true && data.base.debtorInformation.name.uk_UA !== null) {
                name.uk_UA = data.base.debtorInformation.name.uk_UA
                debtorInformation.name = name
            }
        }
        //if(data.base.debtorInformation.hasOwnProperty('representativeInfo') === true && data.base.debtorInformation.representativeInfo !== null) debtorInformation.representativeInfo = data.base[property].representativeInfo
        if (data.base.debtorInformation.hasOwnProperty('identifier') === true && data.base.debtorInformation.identifier !== null) {
            let identifier = null
            if (data.base.debtorInformation.identifier.hasOwnProperty('scheme') === true && data.base.debtorInformation.identifier.scheme !== null) {
                identifier = {}
                identifier.scheme = data.base.debtorInformation.identifier.scheme
            }
            if (data.base.debtorInformation.identifier.hasOwnProperty('id') === true && data.base.debtorInformation.identifier.id !== null) {
                if (identifier === null) identifier = {}
                identifier.id = Number(data.base.debtorInformation.identifier.id)
            }
            if (data.base.debtorInformation.identifier.hasOwnProperty('legalName') === true && data.base.debtorInformation.identifier.legalName !== null) {
                let legalName = {}
                if (data.base.debtorInformation.identifier.legalName.hasOwnProperty('uk_UA') === true && data.base.debtorInformation.identifier.legalName.uk_UA !== null) {
                    if (identifier === null) identifier = {}
                    legalName.uk_UA = data.base.debtorInformation.identifier.legalName.uk_UA
                    identifier.legalName = legalName
                }
            }
            debtorInformation.identifier = identifier
        }
        if (data.base.debtorInformation.hasOwnProperty('contactPoint') === true && data.base.debtorInformation.contactPoint !== null) {
            let contactPoint = null

            if (data.base.debtorInformation.contactPoint.hasOwnProperty('name') === true && data.base.debtorInformation.contactPoint.name !== null) {
                let name = {}
                if (data.base.debtorInformation.contactPoint.name.hasOwnProperty('uk_UA') === true && data.base.debtorInformation.contactPoint.name.uk_UA !== null) {
                    contactPoint = {}
                    name.uk_UA = data.base.debtorInformation.contactPoint.name.uk_UA
                    contactPoint.name = name
                }
            }
            if (data.base.debtorInformation.contactPoint.hasOwnProperty('email') === true && data.base.debtorInformation.contactPoint.email !== null) {
                if (contactPoint === null) contactPoint = {}
                contactPoint.email = data.base.debtorInformation.contactPoint.email
            }
            if (data.base.debtorInformation.contactPoint.hasOwnProperty('telephone') === true && data.base.debtorInformation.contactPoint.telephone !== null) {
                if (contactPoint === null) contactPoint = {}
                contactPoint.telephone = data.base.debtorInformation.contactPoint.telephone
            }
            if (data.base.debtorInformation.contactPoint.hasOwnProperty('faxNumber') === true && data.base.debtorInformation.contactPoint.faxNumber !== null) {
                if (contactPoint === null) contactPoint = {}
                contactPoint.faxNumber = data.base.debtorInformation.contactPoint.faxNumber
            }
            if (data.base.debtorInformation.contactPoint.hasOwnProperty('url') === true && data.base.debtorInformation.contactPoint.url !== null) {
                if (contactPoint === null) contactPoint = {}
                contactPoint.url = data.base.debtorInformation.contactPoint.url
            }
            debtorInformation.contactPoint = contactPoint
        }
        if (data.base.debtorInformation.hasOwnProperty('address') === true && data.base.debtorInformation.address !== null) {
            let address = null
            if (data.base.debtorInformation.address.hasOwnProperty('countryName') === true && data.base.debtorInformation.address.countryName !== null) {
                let countryName = {}
                if (data.base.debtorInformation.address.countryName.hasOwnProperty('uk_UA') === true && data.base.debtorInformation.address.countryName.uk_UA !== null) {
                    address = {}
                    countryName.uk_UA = data.base.debtorInformation.address.countryName.uk_UA
                    address.countryName = countryName
                }
            }
            if (data.base.debtorInformation.address.hasOwnProperty('region') === true && data.base.debtorInformation.address.region !== null) {
                let region = {}
                if (data.base.debtorInformation.address.region.hasOwnProperty('uk_UA') === true && data.base.debtorInformation.address.region.uk_UA !== null) {
                    if (address === null) address = {}
                    region.uk_UA = data.base.debtorInformation.address.region.uk_UA
                    address.region = region
                }
            }
            if (data.base.debtorInformation.address.hasOwnProperty('locality') === true && data.base.debtorInformation.address.locality !== null) {
                let locality = {}
                if (data.base.debtorInformation.address.locality.hasOwnProperty('uk_UA') === true && data.base.debtorInformation.address.locality.uk_UA !== null) {
                    if (address === null) address = {}
                    locality.uk_UA = data.base.debtorInformation.address.locality.uk_UA
                    address.locality = locality
                }
            }
            if (data.base.debtorInformation.address.hasOwnProperty('streetAddress') === true && data.base.debtorInformation.address.streetAddress !== null) {
                let streetAddress = {}
                if (data.base.debtorInformation.address.streetAddress.hasOwnProperty('uk_UA') === true && data.base.debtorInformation.address.streetAddress.uk_UA !== null) {
                    if (address === null) address = {}
                    streetAddress.uk_UA = data.base.debtorInformation.address.streetAddress.uk_UA
                    address.streetAddress = streetAddress
                }
            }
            if (data.base.debtorInformation.address.hasOwnProperty('postalCode') === true && data.base.debtorInformation.address.postalCode !== null) {
                if (address === null) address = {}
                address.postalCode = data.base.debtorInformation.address.postalCode
            }
            debtorInformation.address = address
        }

        tmp.debtorInformation = debtorInformation
        return tmp
    } else if (data.base.hasOwnProperty('sellingEntity') === true && data.base.sellingEntity !== null) {
        let sellingEntity = {}
        if (data.base.sellingEntity.hasOwnProperty('name') === true && data.base.sellingEntity.name !== null) {
            let name = {}
            if (data.base.sellingEntity.name.hasOwnProperty('uk_UA') === true && data.base.sellingEntity.name.uk_UA !== null) {
                name.uk_UA = data.base.sellingEntity.name.uk_UA
                sellingEntity.name = name
            }
        }
        if (data.base.sellingEntity.hasOwnProperty('representativeInfo') === true && data.base.sellingEntity.representativeInfo !== null) sellingEntity.representativeInfo = data.base[property].representativeInfo
        if (data.base.sellingEntity.hasOwnProperty('identifier') === true && data.base.sellingEntity.identifier !== null) {
            let identifier = null
            if (data.base.sellingEntity.identifier.hasOwnProperty('scheme') === true && data.base.sellingEntity.identifier.scheme !== null) {
                identifier = {}
                identifier.scheme = data.base.sellingEntity.identifier.scheme
            }
            if (data.base.sellingEntity.identifier.hasOwnProperty('id') === true && data.base.sellingEntity.identifier.id !== null) {
                if (identifier === null) identifier = {}
                identifier.id = Number(data.base.sellingEntity.identifier.id)
            }
            if (data.base.sellingEntity.identifier.hasOwnProperty('legalName') === true && data.base.sellingEntity.identifier.legalName !== null) {
                let legalName = {}
                if (data.base.sellingEntity.identifier.legalName.hasOwnProperty('uk_UA') === true && data.base.sellingEntity.identifier.legalName.uk_UA !== null) {
                    if (identifier === null) identifier = {}
                    legalName.uk_UA = data.base.sellingEntity.identifier.legalName.uk_UA
                    identifier.legalName = legalName
                }
            }
            sellingEntity.identifier = identifier
        }
        if (data.base.sellingEntity.hasOwnProperty('contactPoint') === true && data.base.sellingEntity.contactPoint !== null) {
            let contactPoint = null

            if (data.base.sellingEntity.contactPoint.hasOwnProperty('name') === true && data.base.sellingEntity.contactPoint.name !== null) {
                let name = {}
                if (data.base.sellingEntity.contactPoint.name.hasOwnProperty('uk_UA') === true && data.base.sellingEntity.contactPoint.name.uk_UA !== null) {
                    contactPoint = {}
                    name.uk_UA = data.base.sellingEntity.contactPoint.name.uk_UA
                    contactPoint.name = name
                }
            }
            if (data.base.sellingEntity.contactPoint.hasOwnProperty('email') === true && data.base.sellingEntity.contactPoint.email !== null) {
                if (contactPoint === null) contactPoint = {}
                contactPoint.email = data.base.sellingEntity.contactPoint.email
            }
            if (data.base.sellingEntity.contactPoint.hasOwnProperty('telephone') === true && data.base.sellingEntity.contactPoint.telephone !== null) {
                if (contactPoint === null) contactPoint = {}
                contactPoint.telephone = data.base.sellingEntity.contactPoint.telephone
            }
            if (data.base.sellingEntity.contactPoint.hasOwnProperty('faxNumber') === true && data.base.sellingEntity.contactPoint.faxNumber !== null) {
                if (contactPoint === null) contactPoint = {}
                contactPoint.faxNumber = data.base.sellingEntity.contactPoint.faxNumber
            }
            if (data.base.sellingEntity.contactPoint.hasOwnProperty('url') === true && data.base.sellingEntity.contactPoint.url !== null) {
                if (contactPoint === null) contactPoint = {}
                contactPoint.url = data.base.sellingEntity.contactPoint.url
            }
            sellingEntity.contactPoint = contactPoint
        }
        if (data.base.sellingEntity.hasOwnProperty('address') === true && data.base.sellingEntity.address !== null) {
            let address = null
            if (data.base.sellingEntity.address.hasOwnProperty('countryName') === true && data.base.sellingEntity.address.countryName !== null) {
                let countryName = {}
                if (data.base.sellingEntity.address.countryName.hasOwnProperty('uk_UA') === true && data.base.sellingEntity.address.countryName.uk_UA !== null) {
                    address = {}
                    countryName.uk_UA = data.base.sellingEntity.address.countryName.uk_UA
                    address.countryName = countryName
                }
            }
            if (data.base.sellingEntity.address.hasOwnProperty('region') === true && data.base.sellingEntity.address.region !== null) {
                let region = {}
                if (data.base.sellingEntity.address.region.hasOwnProperty('uk_UA') === true && data.base.sellingEntity.address.region.uk_UA !== null) {
                    if (address === null) address = {}
                    region.uk_UA = data.base.sellingEntity.address.region.uk_UA
                    address.region = region
                }
            }
            if (data.base.sellingEntity.address.hasOwnProperty('locality') === true && data.base.sellingEntity.address.locality !== null) {
                let locality = {}
                if (data.base.sellingEntity.address.locality.hasOwnProperty('uk_UA') === true && data.base.sellingEntity.address.locality.uk_UA !== null) {
                    if (address === null) address = {}
                    locality.uk_UA = data.base.sellingEntity.address.locality.uk_UA
                    address.locality = locality
                }
            }
            if (data.base.sellingEntity.address.hasOwnProperty('streetAddress') === true && data.base.sellingEntity.address.streetAddress !== null) {
                let streetAddress = {}
                if (data.base.sellingEntity.address.streetAddress.hasOwnProperty('uk_UA') === true && data.base.sellingEntity.address.streetAddress.uk_UA !== null) {
                    if (address === null) address = {}
                    streetAddress.uk_UA = data.base.sellingEntity.address.streetAddress.uk_UA
                    address.streetAddress = streetAddress
                }
            }
            if (data.base.sellingEntity.address.hasOwnProperty('postalCode') === true && data.base.sellingEntity.address.postalCode !== null) {
                if (address === null) address = {}
                address.postalCode = data.base.sellingEntity.address.postalCode
            }
            sellingEntity.address = address
        }

        tmp.sellingEntity = sellingEntity
        return tmp
    } else if (data.base.hasOwnProperty('assetHolder') === true && data.base.assetHolder !== null) {
        let assetHolder = {}
        if (data.base.assetHolder.hasOwnProperty('name') === true && data.base.assetHolder.name !== null) {
            let name = {}
            if (data.base.assetHolder.name.hasOwnProperty('uk_UA') === true && data.base.assetHolder.name.uk_UA !== null) {
                name.uk_UA = data.base.assetHolder.name.uk_UA
                assetHolder.name = name
            }
        }
        if (data.base.assetHolder.hasOwnProperty('representativeInfo') === true && data.base.assetHolder.representativeInfo !== null) assetHolder.representativeInfo = data.base[property].representativeInfo
        if (data.base.assetHolder.hasOwnProperty('identifier') === true && data.base.assetHolder.identifier !== null) {
            let identifier = null
            if (data.base.assetHolder.identifier.hasOwnProperty('scheme') === true && data.base.assetHolder.identifier.scheme !== null) {
                identifier = {}
                identifier.scheme = data.base.assetHolder.identifier.scheme
            }
            if (data.base.assetHolder.identifier.hasOwnProperty('id') === true && data.base.assetHolder.identifier.id !== null) {
                if (identifier === null) identifier = {}
                identifier.id = Number(data.base.assetHolder.identifier.id)
            }
            if (data.base.assetHolder.identifier.hasOwnProperty('legalName') === true && data.base.assetHolder.identifier.legalName !== null) {
                let legalName = {}
                if (data.base.assetHolder.identifier.legalName.hasOwnProperty('uk_UA') === true && data.base.assetHolder.identifier.legalName.uk_UA !== null) {
                    if (identifier === null) identifier = {}
                    legalName.uk_UA = data.base.assetHolder.identifier.legalName.uk_UA
                    identifier.legalName = legalName
                }
            }
            assetHolder.identifier = identifier
        }
        if (data.base.assetHolder.hasOwnProperty('contactPoint') === true && data.base.assetHolder.contactPoint !== null) {
            let contactPoint = null

            if (data.base.assetHolder.contactPoint.hasOwnProperty('name') === true && data.base.assetHolder.contactPoint.name !== null) {
                let name = {}
                if (data.base.assetHolder.contactPoint.name.hasOwnProperty('uk_UA') === true && data.base.assetHolder.contactPoint.name.uk_UA !== null) {
                    contactPoint = {}
                    name.uk_UA = data.base.assetHolder.contactPoint.name.uk_UA
                    contactPoint.name = name
                }
            }
            if (data.base.assetHolder.contactPoint.hasOwnProperty('email') === true && data.base.assetHolder.contactPoint.email !== null) {
                if (contactPoint === null) contactPoint = {}
                contactPoint.email = data.base.assetHolder.contactPoint.email
            }
            if (data.base.assetHolder.contactPoint.hasOwnProperty('telephone') === true && data.base.assetHolder.contactPoint.telephone !== null) {
                if (contactPoint === null) contactPoint = {}
                contactPoint.telephone = data.base.assetHolder.contactPoint.telephone
            }
            if (data.base.assetHolder.contactPoint.hasOwnProperty('faxNumber') === true && data.base.assetHolder.contactPoint.faxNumber !== null) {
                if (contactPoint === null) contactPoint = {}
                contactPoint.faxNumber = data.base.assetHolder.contactPoint.faxNumber
            }
            if (data.base.assetHolder.contactPoint.hasOwnProperty('url') === true && data.base.assetHolder.contactPoint.url !== null) {
                if (contactPoint === null) contactPoint = {}
                contactPoint.url = data.base.assetHolder.contactPoint.url
            }
            assetHolder.contactPoint = contactPoint
        }
        if (data.base.assetHolder.hasOwnProperty('address') === true && data.base.assetHolder.address !== null) {
            let address = null
            if (data.base.assetHolder.address.hasOwnProperty('countryName') === true && data.base.assetHolder.address.countryName !== null) {
                let countryName = {}
                if (data.base.assetHolder.address.countryName.hasOwnProperty('uk_UA') === true && data.base.assetHolder.address.countryName.uk_UA !== null) {
                    address = {}
                    countryName.uk_UA = data.base.assetHolder.address.countryName.uk_UA
                    address.countryName = countryName
                }
            }
            if (data.base.assetHolder.address.hasOwnProperty('region') === true && data.base.assetHolder.address.region !== null) {
                let region = {}
                if (data.base.assetHolder.address.region.hasOwnProperty('uk_UA') === true && data.base.assetHolder.address.region.uk_UA !== null) {
                    if (address === null) address = {}
                    region.uk_UA = data.base.assetHolder.address.region.uk_UA
                    address.region = region
                }
            }
            if (data.base.assetHolder.address.hasOwnProperty('locality') === true && data.base.assetHolder.address.locality !== null) {
                let locality = {}
                if (data.base.assetHolder.address.locality.hasOwnProperty('uk_UA') === true && data.base.assetHolder.address.locality.uk_UA !== null) {
                    if (address === null) address = {}
                    locality.uk_UA = data.base.assetHolder.address.locality.uk_UA
                    address.locality = locality
                }
            }
            if (data.base.assetHolder.address.hasOwnProperty('streetAddress') === true && data.base.assetHolder.address.streetAddress !== null) {
                let streetAddress = {}
                if (data.base.assetHolder.address.streetAddress.hasOwnProperty('uk_UA') === true && data.base.assetHolder.address.streetAddress.uk_UA !== null) {
                    if (address === null) address = {}
                    streetAddress.uk_UA = data.base.assetHolder.address.streetAddress.uk_UA
                    address.streetAddress = streetAddress
                }
            }
            if (data.base.assetHolder.address.hasOwnProperty('postalCode') === true && data.base.assetHolder.address.postalCode !== null) {
                if (address === null) address = {}
                address.postalCode = data.base.assetHolder.address.postalCode
            }
            assetHolder.address = address
        }

        tmp.assetHolder = assetHolder
        return tmp
    } else if (data.base.hasOwnProperty('currentTenants') === true && data.base.currentTenants !== null) {
        let currentTenants = {}
        if (data.base.currentTenants.hasOwnProperty('name') === true && data.base.currentTenants.name !== null) {
            let name = {}
            if (data.base.currentTenants.name.hasOwnProperty('uk_UA') === true && data.base.currentTenants.name.uk_UA !== null) {
                name.uk_UA = data.base.currentTenants.name.uk_UA
                currentTenants.name = name
            }
        }
        if (data.base.currentTenants.hasOwnProperty('representativeInfo') === true && data.base.currentTenants.representativeInfo !== null) currentTenants.representativeInfo = data.base[property].representativeInfo
        if (data.base.currentTenants.hasOwnProperty('identifier') === true && data.base.currentTenants.identifier !== null) {
            let identifier = null
            if (data.base.currentTenants.identifier.hasOwnProperty('scheme') === true && data.base.currentTenants.identifier.scheme !== null) {
                identifier = {}
                identifier.scheme = data.base.currentTenants.identifier.scheme
            }
            if (data.base.currentTenants.identifier.hasOwnProperty('id') === true && data.base.currentTenants.identifier.id !== null) {
                if (identifier === null) identifier = {}
                identifier.id = Number(data.base.currentTenants.identifier.id)
            }
            if (data.base.currentTenants.identifier.hasOwnProperty('legalName') === true && data.base.currentTenants.identifier.legalName !== null) {
                let legalName = {}
                if (data.base.currentTenants.identifier.legalName.hasOwnProperty('uk_UA') === true && data.base.currentTenants.identifier.legalName.uk_UA !== null) {
                    if (identifier === null) identifier = {}
                    legalName.uk_UA = data.base.currentTenants.identifier.legalName.uk_UA
                    identifier.legalName = legalName
                }
            }
            currentTenants.identifier = identifier
        }
        if (data.base.currentTenants.hasOwnProperty('contactPoint') === true && data.base.currentTenants.contactPoint !== null) {
            let contactPoint = null

            if (data.base.currentTenants.contactPoint.hasOwnProperty('name') === true && data.base.currentTenants.contactPoint.name !== null) {
                let name = {}
                if (data.base.currentTenants.contactPoint.name.hasOwnProperty('uk_UA') === true && data.base.currentTenants.contactPoint.name.uk_UA !== null) {
                    contactPoint = {}
                    name.uk_UA = data.base.currentTenants.contactPoint.name.uk_UA
                    contactPoint.name = name
                }
            }
            if (data.base.currentTenants.contactPoint.hasOwnProperty('email') === true && data.base.currentTenants.contactPoint.email !== null) {
                if (contactPoint === null) contactPoint = {}
                contactPoint.email = data.base.currentTenants.contactPoint.email
            }
            if (data.base.currentTenants.contactPoint.hasOwnProperty('telephone') === true && data.base.currentTenants.contactPoint.telephone !== null) {
                if (contactPoint === null) contactPoint = {}
                contactPoint.telephone = data.base.currentTenants.contactPoint.telephone
            }
            if (data.base.currentTenants.contactPoint.hasOwnProperty('faxNumber') === true && data.base.currentTenants.contactPoint.faxNumber !== null) {
                if (contactPoint === null) contactPoint = {}
                contactPoint.faxNumber = data.base.currentTenants.contactPoint.faxNumber
            }
            if (data.base.currentTenants.contactPoint.hasOwnProperty('url') === true && data.base.currentTenants.contactPoint.url !== null) {
                if (contactPoint === null) contactPoint = {}
                contactPoint.url = data.base.currentTenants.contactPoint.url
            }
            currentTenants.contactPoint = contactPoint
        }
        if (data.base.currentTenants.hasOwnProperty('address') === true && data.base.currentTenants.address !== null) {
            let address = null
            if (data.base.currentTenants.address.hasOwnProperty('countryName') === true && data.base.currentTenants.address.countryName !== null) {
                let countryName = {}
                if (data.base.currentTenants.address.countryName.hasOwnProperty('uk_UA') === true && data.base.currentTenants.address.countryName.uk_UA !== null) {
                    address = {}
                    countryName.uk_UA = data.base.currentTenants.address.countryName.uk_UA
                    address.countryName = countryName
                }
            }
            if (data.base.currentTenants.address.hasOwnProperty('region') === true && data.base.currentTenants.address.region !== null) {
                let region = {}
                if (data.base.currentTenants.address.region.hasOwnProperty('uk_UA') === true && data.base.currentTenants.address.region.uk_UA !== null) {
                    if (address === null) address = {}
                    region.uk_UA = data.base.currentTenants.address.region.uk_UA
                    address.region = region
                }
            }
            if (data.base.currentTenants.address.hasOwnProperty('locality') === true && data.base.currentTenants.address.locality !== null) {
                let locality = {}
                if (data.base.currentTenants.address.locality.hasOwnProperty('uk_UA') === true && data.base.currentTenants.address.locality.uk_UA !== null) {
                    if (address === null) address = {}
                    locality.uk_UA = data.base.currentTenants.address.locality.uk_UA
                    address.locality = locality
                }
            }
            if (data.base.currentTenants.address.hasOwnProperty('streetAddress') === true && data.base.currentTenants.address.streetAddress !== null) {
                let streetAddress = {}
                if (data.base.currentTenants.address.streetAddress.hasOwnProperty('uk_UA') === true && data.base.currentTenants.address.streetAddress.uk_UA !== null) {
                    if (address === null) address = {}
                    streetAddress.uk_UA = data.base.currentTenants.address.streetAddress.uk_UA
                    address.streetAddress = streetAddress
                }
            }
            if (data.base.currentTenants.address.hasOwnProperty('postalCode') === true && data.base.currentTenants.address.postalCode !== null) {
                if (address === null) address = {}
                address.postalCode = data.base.currentTenants.address.postalCode
            }
            currentTenants.address = address
        }

        tmp.currentTenants = [currentTenants]
        return tmp
    }
    return body
}

export function addAssetHolder(data, body, property) {
    let tmp = body
    if (data.base[property] === undefined || data.base[property] === 'undefined') return body
    if (data.base.hasOwnProperty('assetHolder') === true && data.base.assetHolder !== null) {
        let assetHolder = {}
        if (data.base.assetHolder.hasOwnProperty('name') === true && data.base.assetHolder.name !== null) {
            let name = {}
            if (data.base.assetHolder.name.hasOwnProperty('uk_UA') === true && data.base.assetHolder.name.uk_UA !== null) {
                name.uk_UA = data.base.assetHolder.name.uk_UA
                assetHolder.name = name
            }
        }
        if (data.base.assetHolder.hasOwnProperty('representativeInfo') && data.base.assetHolder.representativeInfo !== null) {
            assetHolder.representativeInfo = data.base.assetHolder.representativeInfo
        }
        if (data.base.assetHolder.hasOwnProperty('identifier') && data.base.assetHolder.identifier !== null) {
            let identifier = null
            if (data.base.assetHolder.identifier.hasOwnProperty('scheme') && data.base.assetHolder.identifier.scheme !== null) {
                identifier = {}
                identifier.scheme = data.base.assetHolder.identifier.scheme
            }
            if (data.base.assetHolder.identifier.hasOwnProperty('id') === true && data.base.assetHolder.identifier.id !== null) {
                if (identifier === null) identifier = {}
                identifier.id = Number(data.base.assetHolder.identifier.id)
            }
            if (data.base.assetHolder.identifier.hasOwnProperty('legalName') === true && data.base.assetHolder.identifier.legalName !== null) {
                let legalName = {}
                if (data.base.assetHolder.identifier.legalName.hasOwnProperty('uk_UA') === true && data.base.assetHolder.identifier.legalName.uk_UA !== null) {
                    if (identifier === null) identifier = {}
                    legalName.uk_UA = data.base.assetHolder.identifier.legalName.uk_UA
                    identifier.legalName = legalName
                }
            }
            assetHolder.identifier = identifier
        }
        if (data.base.assetHolder.hasOwnProperty('contactPoint') === true && data.base.assetHolder.contactPoint !== null) {
            let contactPoint = null

            if (data.base.assetHolder.contactPoint.hasOwnProperty('name') === true && data.base.assetHolder.contactPoint.name !== null) {
                let name = {}
                if (data.base.assetHolder.contactPoint.name.hasOwnProperty('uk_UA') === true && data.base.assetHolder.contactPoint.name.uk_UA !== null) {
                    contactPoint = {}
                    name.uk_UA = data.base.assetHolder.contactPoint.name.uk_UA
                    contactPoint.name = name
                }
            }
            if (data.base.assetHolder.contactPoint.hasOwnProperty('email') === true && data.base.assetHolder.contactPoint.email !== null) {
                if (contactPoint === null) contactPoint = {}
                contactPoint.email = data.base.assetHolder.contactPoint.email
            }
            if (data.base.assetHolder.contactPoint.hasOwnProperty('telephone') === true && data.base.assetHolder.contactPoint.telephone !== null) {
                if (contactPoint === null) contactPoint = {}
                contactPoint.telephone = data.base.assetHolder.contactPoint.telephone
            }
            if (data.base.assetHolder.contactPoint.hasOwnProperty('faxNumber') === true && data.base.assetHolder.contactPoint.faxNumber !== null) {
                if (contactPoint === null) contactPoint = {}
                contactPoint.faxNumber = data.base.assetHolder.contactPoint.faxNumber
            }
            if (data.base.assetHolder.contactPoint.hasOwnProperty('url') === true && data.base.assetHolder.contactPoint.url !== null) {
                if (contactPoint === null) contactPoint = {}
                contactPoint.url = data.base.assetHolder.contactPoint.url
            }
            assetHolder.contactPoint = contactPoint
        }
        if (data.base.assetHolder.hasOwnProperty('address') === true && data.base.assetHolder.address !== null) {
            let address = null
            if (data.base.assetHolder.address.hasOwnProperty('countryName') === true && data.base.assetHolder.address.countryName !== null) {
                let countryName = {}
                if (data.base.assetHolder.address.countryName.hasOwnProperty('uk_UA') === true && data.base.assetHolder.address.countryName.uk_UA !== null) {
                    address = {}
                    countryName.uk_UA = data.base.assetHolder.address.countryName.uk_UA
                    address.countryName = countryName
                }
            }
            if (data.base.assetHolder.address.hasOwnProperty('region') === true && data.base.assetHolder.address.region !== null) {
                let region = {}
                if (data.base.assetHolder.address.region.hasOwnProperty('uk_UA') === true && data.base.assetHolder.address.region.uk_UA !== null) {
                    if (address === null) address = {}
                    region.uk_UA = data.base.assetHolder.address.region.uk_UA
                    address.region = region
                }
            }
            if (data.base.assetHolder.address.hasOwnProperty('locality') === true && data.base.assetHolder.address.locality !== null) {
                let locality = {}
                if (data.base.assetHolder.address.locality.hasOwnProperty('uk_UA') === true && data.base.assetHolder.address.locality.uk_UA !== null) {
                    if (address === null) address = {}
                    locality.uk_UA = data.base.assetHolder.address.locality.uk_UA
                    address.locality = locality
                }
            }
            if (data.base.assetHolder.address.hasOwnProperty('streetAddress') === true && data.base.assetHolder.address.streetAddress !== null) {
                let streetAddress = {}
                if (data.base.assetHolder.address.streetAddress.hasOwnProperty('uk_UA') === true && data.base.assetHolder.address.streetAddress.uk_UA !== null) {
                    if (address === null) address = {}
                    streetAddress.uk_UA = data.base.assetHolder.address.streetAddress.uk_UA
                    address.streetAddress = streetAddress
                }
            }
            if (data.base.assetHolder.address.hasOwnProperty('postalCode') === true && data.base.assetHolder.address.postalCode !== null) {
                if (address === null) address = {}
                address.postalCode = data.base.assetHolder.address.postalCode
            }
            assetHolder.address = address
        }

        tmp.assetHolder = assetHolder
        return tmp
    }
    return body
}

export function addDutchStep(data, body, property) {
    let tmp = body
    if (data.base[property] !== null && data.base[property] !== '' && data.base[property] !== 'null') {
        let dutchStep = {}
        dutchStep.dutchStepQuantity = Number(data.base[property].dutchStepQuantity)
        /** Открыл для csd */
        dutchStep.dutchStepPercent = data.base[property].dutchStepPercent
        /*if(data.base[property].hasOwnProperty('dutchStepValue')){
            if(data.base[property].dutchStepValue !== null){
                if(data.base[property].dutchStepValue.hasOwnProperty('amount')){
                    if(data.base[property].dutchStepValue.amount !== null){
                        dutchStep.dutchStepValue = {}
                        dutchStep.dutchStepValue.currency = 'UAH'
                        dutchStep.dutchStepValue.amount = Number(data.base[property].dutchStepValue.amount)
                    }
                }
            }
        }
        /*** */
        tmp.dutchStep = dutchStep
        return tmp
    }
    return body
}

export function addDutchStepFull(data, body, property) {
    let tmp = body
    if (data.base[property] !== null && data.base[property] !== '' && data.base[property] !== 'null') {
        let dutchStep = {}
        dutchStep.dutchStepQuantity = Number(data.base[property].dutchStepQuantity)
        /** Открыл для csd */
        dutchStep.dutchStepPercent = data.base[property].dutchStepPercent
        if (data.base[property].hasOwnProperty('dutchStepValue')) {
            if (data.base[property].dutchStepValue !== null) {
                if (data.base[property].dutchStepValue.hasOwnProperty('amount')) {
                    if (data.base[property].dutchStepValue.amount !== null) {
                        dutchStep.dutchStepValue = {}
                        dutchStep.dutchStepValue.currency = 'UAH'
                        dutchStep.dutchStepValue.amount = Number(data.base[property].dutchStepValue.amount)
                    }
                }
            }
        }
        tmp.dutchStep = dutchStep
        return tmp
    }
    return body
}

export function addSellingEntity(data, body, property) {
    let tmp = body
    if (data.base[property] === undefined || data.base[property] === 'undefined') return body
    if (data.base.hasOwnProperty('sellingEntity') === true && data.base.sellingEntity !== null) {
        let sellingEntity = {}
        if (data.base.sellingEntity.hasOwnProperty('representativeCertificateNumber') === true && data.base.sellingEntity.representativeCertificateNumber !== null) sellingEntity.representativeCertificateNumber = data.base[property].representativeCertificateNumber
        if (data.base.sellingEntity.hasOwnProperty('representativeCertificateDate') === true && data.base.sellingEntity.representativeCertificateDate !== null) sellingEntity.representativeCertificateDate = data.base[property].representativeCertificateDate
        tmp.sellingEntity = sellingEntity
        return tmp
    }
    return body
}

export function addSchedule(data, body, property) {
    let tmp = body
    if (data.base.hasOwnProperty('schedule') === true && data.base.schedule !== null) {
        let schedule = {}
        if (data.base.schedule.hasOwnProperty('hoursBy') === true && data.base.schedule.hoursBy !== null) {
            if (
                data.base.schedule.hoursBy.hasOwnProperty('value') === true && data.base.schedule.hoursBy.value !== null &&
                data.base.schedule.hoursBy.hasOwnProperty('by') === true && data.base.schedule.hoursBy.by !== null
            ) {
                schedule.hoursBy = {
                    value: data.base.schedule.hoursBy.value,
                    by: data.base.schedule.hoursBy.by
                }
            }
        } else {
            schedule.hoursBy = null
        }

        if (data.base.schedule.hasOwnProperty('daysBy') === true && data.base.schedule.daysBy !== null) {
            if (
                data.base.schedule.daysBy.hasOwnProperty('value') === true && data.base.schedule.daysBy.value !== null &&
                data.base.schedule.daysBy.hasOwnProperty('by') === true && data.base.schedule.daysBy.by !== null
            ) {
                schedule.daysBy = {
                    value: data.base.schedule.daysBy.value,
                    by: data.base.schedule.daysBy.by
                }
            }
        } else {
            schedule.daysBy = null
        }

        schedule.period = []
        if (data.base.schedule.hasOwnProperty('period') === true && data.base.schedule.period !== null && data.base.schedule.period.length > 0) {
            for (let i = 0; i < data.base.schedule.period.length; i++) {
                if (data.base.schedule.period[i].startDate && data.base.schedule.period[i].endDate) {
                    let tmpWeekdays = []
                    if (
                        data.base.schedule.period[i].hasOwnProperty('weekdays')
                        && data.base.schedule.period[i].weekdays !== null
                        && data.base.schedule.period[i].weekdays.length > 0
                    ) {
                        for (let e = 0; e < data.base.schedule.period[i].weekdays.length; e++) {
                            if (
                                data.base.schedule.period[i].weekdays[e].startTime !== null || data.base.schedule.period[i].weekdays[e].startTime !== '' ||
                                data.base.schedule.period[i].weekdays[e].endTime !== null || data.base.schedule.period[i].weekdays[e].endTime !== '' ||
                                data.base.schedule.period[i].weekdays[e].weekday !== null || data.base.schedule.period[i].weekdays[e].weekday !== ''
                            ) {
                                if (data.base.schedule.period[i].weekdays[e].weekday.hasOwnProperty('uk_UA')) {
                                    tmpWeekdays[e] = {
                                        startTime: data.base.schedule.period[i].weekdays[e].startTime,
                                        endTime: data.base.schedule.period[i].weekdays[e].endTime,
                                        weekday: data.base.schedule.period[i].weekdays[e].weekday
                                    }
                                } else {
                                    tmpWeekdays[e] = {
                                        startTime: data.base.schedule.period[i].weekdays[e].startTime,
                                        endTime: data.base.schedule.period[i].weekdays[e].endTime,
                                        weekday: {
                                            uk_UA: data.base.schedule.period[i].weekdays[e].weekday
                                        }
                                    }
                                }
                            }
                        }
                    }

                    schedule.period[i] = {
                        startDate: data.base.schedule.period[i].startDate,
                        endDate: data.base.schedule.period[i].endDate,
                        weekdays: tmpWeekdays
                    }
                }
            }
        }

        //Если что убрать
        if (schedule.period !== null && schedule.period.length === 0) {
            schedule.period = null
        }

        if (data.base.schedule.hasOwnProperty('other') === true && data.base.schedule.other !== null && data.base.schedule.other !== '') {
            schedule.daysBy = null
            schedule.hoursBy = null
            schedule.period = null
            schedule.other = data.base[property].other
        } else {
            schedule.other = null
        }
        tmp.schedule = schedule
        return tmp
    }
    return body
}

export function addArrest(data, body, property) {
    let tmp = body
    if (data.base.hasOwnProperty('arrests') !== true) return body
    if (data.base.arrests === null) return body
    let arrests = []
    let count = 0
    for (let i = 0; i < data.base.arrests.length; i++) {
        if (data.base.arrests[i].status !== true) {
            arrests[count] = {
                statementID: data.base.arrests[i].statementID,
                restrictionDate: data.base.arrests[i].restrictionDate,
                restrictionOrganization: data.base.arrests[i].restrictionOrganization,
                isRemovable: data.base.arrests[i].isRemovable
            }
            count++
        }
    }
    tmp.arrests = arrests
    return tmp
}

export function addCurrentTenants(data, body, property) {
    //let tmp = body
    //if(data.base[property] === undefined || data.base[property] === 'undefined') return body
    if (data.base.hasOwnProperty('currentTenants') === true && data.base.currentTenants !== null) {
        let currentTenants = {}
        if (data.base.currentTenants.hasOwnProperty('representativeInfo') === true && data.base.currentTenants.representativeInfo !== null) currentTenants.representativeInfo = data.base[property].representativeInfo
        if (data.base.currentTenants.hasOwnProperty('priority') === true && data.base.currentTenants.priority !== null) currentTenants.priority = data.base[property].priority
        if (data.base.currentTenants.hasOwnProperty('identifier') === true && data.base.currentTenants.identifier !== null) {
            let identifier = null
            if (data.base.currentTenants.identifier.hasOwnProperty('scheme') === true && data.base.currentTenants.identifier.scheme !== null) {
                identifier = {}
                identifier.scheme = data.base.currentTenants.identifier.scheme
            }
            if (data.base.currentTenants.identifier.hasOwnProperty('id') === true && data.base.currentTenants.identifier.id !== null) {
                if (identifier === null) identifier = {}
                identifier.id = data.base.currentTenants.identifier.id
            }
            if (data.base.currentTenants.identifier.hasOwnProperty('legalName') === true && data.base.currentTenants.identifier.legalName !== null) {
                let legalName = {}
                if (data.base.currentTenants.identifier.legalName.hasOwnProperty('uk_UA') === true && data.base.currentTenants.identifier.legalName.uk_UA !== null) {
                    if (identifier === null) identifier = {}
                    legalName.uk_UA = data.base.currentTenants.identifier.legalName.uk_UA
                    identifier.legalName = legalName
                }
            }
            currentTenants.identifier = identifier
        }

        if (data.base.currentTenants.hasOwnProperty('address') === true && data.base.currentTenants.address !== null) {
            let address = null
            if (data.base.currentTenants.address.hasOwnProperty('countryName') === true && data.base.currentTenants.address.countryName !== null) {
                let countryName = {}
                if (data.base.currentTenants.address.countryName.hasOwnProperty('uk_UA') === true && data.base.currentTenants.address.countryName.uk_UA !== null) {
                    address = {}
                    countryName.uk_UA = data.base.currentTenants.address.countryName.uk_UA
                    address.countryName = countryName
                }
            }
            if (data.base.currentTenants.address.hasOwnProperty('region') === true && data.base.currentTenants.address.region !== null) {
                let region = {}
                if (data.base.currentTenants.address.region.hasOwnProperty('uk_UA') === true && data.base.currentTenants.address.region.uk_UA !== null) {
                    if (address === null) address = {}
                    region.uk_UA = data.base.currentTenants.address.region.uk_UA
                    address.region = region
                }
            }
            if (data.base.currentTenants.address.hasOwnProperty('locality') === true && data.base.currentTenants.address.locality !== null) {
                let locality = {}
                if (data.base.currentTenants.address.locality.hasOwnProperty('uk_UA') === true && data.base.currentTenants.address.locality.uk_UA !== null) {
                    if (address === null) address = {}
                    locality.uk_UA = data.base.currentTenants.address.locality.uk_UA
                    address.locality = locality
                }
            }
            if (data.base.currentTenants.address.hasOwnProperty('streetAddress') === true && data.base.currentTenants.address.streetAddress !== null) {
                let streetAddress = {}
                if (data.base.currentTenants.address.streetAddress.hasOwnProperty('uk_UA') === true && data.base.currentTenants.address.streetAddress.uk_UA !== null) {
                    if (address === null) address = {}
                    streetAddress.uk_UA = data.base.currentTenants.address.streetAddress.uk_UA
                    address.streetAddress = streetAddress
                }
            }
            if (data.base.currentTenants.address.hasOwnProperty('postalCode') === true && data.base.currentTenants.address.postalCode !== null) {
                if (address === null) address = {}
                address.postalCode = data.base.currentTenants.address.postalCode
            }
            currentTenants.address = address
        }
        if (data.base.currentTenants.hasOwnProperty('value') === true && data.base.currentTenants.value !== null) {
            let value = null
            value = {
                currency: (data.base[property].currency !== null && data.base.currentTenants.value.currency !== "" && data.base.currentTenants.value.currency !== "null") ? data.base.currentTenants.value.currency : "UAH",
                amount: Number(data.base.currentTenants.value.amount),
            }
            currentTenants.currentTenantValue = value
        }
        if (data.base.currentTenants.hasOwnProperty('currentContractTime') === true && data.base.currentTenants.currentContractTime !== null) {
            let currentContractTime = null
            if (
                data.base.currentTenants.currentContractTime.dateFrom !== null && data.base.currentTenants.currentContractTime.dateFrom !== ''
                && data.base.currentTenants.currentContractTime.dateTill !== null && data.base.currentTenants.currentContractTime.dateTill !== ''
            ) {
                currentContractTime = {
                    dateFrom: data.base.currentTenants.currentContractTime.dateFrom,
                    dateTill: data.base.currentTenants.currentContractTime.dateTill
                }
            }
            currentTenants.currentContractTime = currentContractTime
        }

        if (data.base.currentTenants.hasOwnProperty('priorityTransferInfo') === true && data.base.currentTenants.priorityTransferInfo !== null) {
            let priorityTransferInfo = {}
            if (data.base.currentTenants.priorityTransferInfo.hasOwnProperty('identifier') === true
                && data.base.currentTenants.priorityTransferInfo.identifier !== null
                && data.base.currentTenants.priorityTransferInfo.identifier.hasOwnProperty('id') === true
                && data.base.currentTenants.priorityTransferInfo.identifier.id !== null
                && data.base.currentTenants.priorityTransferInfo.identifier.id !== 'null'
            ) {
                let identifier = null
                if (data.base.currentTenants.priorityTransferInfo.identifier.hasOwnProperty('scheme') === true && data.base.currentTenants.priorityTransferInfo.identifier.scheme !== null) {
                    identifier = {}
                    identifier.scheme = data.base.currentTenants.priorityTransferInfo.identifier.scheme
                }
                if (data.base.currentTenants.priorityTransferInfo.identifier.hasOwnProperty('id') === true && data.base.currentTenants.priorityTransferInfo.identifier.id !== null) {
                    if (identifier === null) identifier = {}
                    identifier.id = data.base.currentTenants.priorityTransferInfo.identifier.id
                }
                if (data.base.currentTenants.priorityTransferInfo.identifier.hasOwnProperty('legalName') === true && data.base.currentTenants.priorityTransferInfo.identifier.legalName !== null) {
                    let legalName = {}
                    if (data.base.currentTenants.priorityTransferInfo.identifier.legalName.hasOwnProperty('uk_UA') === true && data.base.currentTenants.priorityTransferInfo.identifier.legalName.uk_UA !== null) {
                        if (identifier === null) identifier = {}
                        legalName.uk_UA = data.base.currentTenants.priorityTransferInfo.identifier.legalName.uk_UA
                        identifier.legalName = legalName
                    }
                }
                priorityTransferInfo.identifier = identifier
                if (data.base.currentTenants.priorityTransferInfo.hasOwnProperty('transferredFrom') === true && data.base.currentTenants.priorityTransferInfo.representativeInfo !== null) priorityTransferInfo.transferredFrom = data.base[property].priorityTransferInfo.transferredFrom
                // if(data.base.currentTenants.priorityTransferInfo.hasOwnProperty('name') === true && data.base.currentTenants.priorityTransferInfo.name !== null){
                //     if(data.base.currentTenants.priorityTransferInfo.name.hasOwnProperty('uk_UA') === true && data.base.currentTenants.priorityTransferInfo.name.uk_UA !== null){
                //         let name = {}
                //         name.uk_UA = data.base.currentTenants.priorityTransferInfo.name.uk_UA
                //         priorityTransferInfo.name = name
                //     }
                // }

                currentTenants.priorityTransferInfo = priorityTransferInfo
            }
        }

        //tmp.currentTenants = currentTenants
        return currentTenants
    }
    //return body
}

export function addCurrentTenants2(data, body, property) {
    //if(data.base[property] === undefined || data.base[property] === 'undefined') return body
    if (data.base.hasOwnProperty('currentTenants2') === true && data.base.currentTenants2 !== null) {
        let currentTenants = {}
        if (data.base.currentTenants2.hasOwnProperty('representativeInfo') === true && data.base.currentTenants2.representativeInfo !== null) currentTenants.representativeInfo = data.base[property].representativeInfo
        if (data.base.currentTenants2.hasOwnProperty('priority') === true && data.base.currentTenants2.priority !== null) currentTenants.priority = data.base[property].priority
        if (data.base.currentTenants2.hasOwnProperty('identifier') === true && data.base.currentTenants2.identifier !== null) {
            let identifier = null
            if (data.base.currentTenants2.identifier.hasOwnProperty('scheme') === true && data.base.currentTenants2.identifier.scheme !== null) {
                identifier = {}
                identifier.scheme = data.base.currentTenants2.identifier.scheme
            }
            if (data.base.currentTenants2.identifier.hasOwnProperty('id') === true && data.base.currentTenants2.identifier.id !== null) {
                if (identifier === null) identifier = {}
                identifier.id = data.base.currentTenants2.identifier.id
            }
            if (data.base.currentTenants2.identifier.hasOwnProperty('legalName') === true && data.base.currentTenants2.identifier.legalName !== null) {
                let legalName = {}
                if (data.base.currentTenants2.identifier.legalName.hasOwnProperty('uk_UA') === true && data.base.currentTenants2.identifier.legalName.uk_UA !== null) {
                    if (identifier === null) identifier = {}
                    legalName.uk_UA = data.base.currentTenants2.identifier.legalName.uk_UA
                    identifier.legalName = legalName
                }
            }
            currentTenants.identifier = identifier
        }

        if (data.base.currentTenants2.hasOwnProperty('address') === true && data.base.currentTenants2.address !== null) {
            let address = null
            if (data.base.currentTenants2.address.hasOwnProperty('countryName') === true && data.base.currentTenants2.address.countryName !== null) {
                let countryName = {}
                if (data.base.currentTenants2.address.countryName.hasOwnProperty('uk_UA') === true && data.base.currentTenants2.address.countryName.uk_UA !== null) {
                    address = {}
                    countryName.uk_UA = data.base.currentTenants2.address.countryName.uk_UA
                    address.countryName = countryName
                }
            }
            if (data.base.currentTenants2.address.hasOwnProperty('region') === true && data.base.currentTenants2.address.region !== null) {
                let region = {}
                if (data.base.currentTenants2.address.region.hasOwnProperty('uk_UA') === true && data.base.currentTenants2.address.region.uk_UA !== null) {
                    if (address === null) address = {}
                    region.uk_UA = data.base.currentTenants2.address.region.uk_UA
                    address.region = region
                }
            }
            if (data.base.currentTenants2.address.hasOwnProperty('locality') === true && data.base.currentTenants2.address.locality !== null) {
                let locality = {}
                if (data.base.currentTenants2.address.locality.hasOwnProperty('uk_UA') === true && data.base.currentTenants2.address.locality.uk_UA !== null) {
                    if (address === null) address = {}
                    locality.uk_UA = data.base.currentTenants2.address.locality.uk_UA
                    address.locality = locality
                }
            }
            if (data.base.currentTenants2.address.hasOwnProperty('streetAddress') === true && data.base.currentTenants2.address.streetAddress !== null) {
                let streetAddress = {}
                if (data.base.currentTenants2.address.streetAddress.hasOwnProperty('uk_UA') === true && data.base.currentTenants2.address.streetAddress.uk_UA !== null) {
                    if (address === null) address = {}
                    streetAddress.uk_UA = data.base.currentTenants2.address.streetAddress.uk_UA
                    address.streetAddress = streetAddress
                }
            }
            if (data.base.currentTenants2.address.hasOwnProperty('postalCode') === true && data.base.currentTenants2.address.postalCode !== null) {
                if (address === null) address = {}
                address.postalCode = data.base.currentTenants2.address.postalCode
            }
            currentTenants.address = address
        }
        if (data.base.currentTenants2.hasOwnProperty('value') === true && data.base.currentTenants2.value !== null) {
            let value = null
            value = {
                currency: (data.base[property].currency !== null && data.base.currentTenants2.value.currency !== "" && data.base.currentTenants2.value.currency !== "null") ? data.base.currentTenants2.value.currency : "UAH",
                amount: Number(data.base.currentTenants2.value.amount),
            }
            currentTenants.currentTenantValue = value
        }
        if (data.base.currentTenants2.hasOwnProperty('currentContractTime') === true && data.base.currentTenants2.currentContractTime !== null) {
            let currentContractTime = null
            if (
                data.base.currentTenants2.currentContractTime.dateFrom !== null && data.base.currentTenants2.currentContractTime.dateFrom !== ''
                && data.base.currentTenants2.currentContractTime.dateTill !== null && data.base.currentTenants2.currentContractTime.dateTill !== ''
            ) {
                currentContractTime = {
                    dateFrom: data.base.currentTenants2.currentContractTime.dateFrom,
                    dateTill: data.base.currentTenants2.currentContractTime.dateTill
                }
            }
            currentTenants.currentContractTime = currentContractTime
        }
        if (data.base.currentTenants2.priorityTransferInfo.hasOwnProperty('identifier') === true
            && data.base.currentTenants2.priorityTransferInfo.identifier !== null
            && data.base.currentTenants2.priorityTransferInfo.identifier.hasOwnProperty('scheme') === true
            && data.base.currentTenants2.priorityTransferInfo.identifier.scheme !== null
            && data.base.currentTenants2.priorityTransferInfo.identifier.scheme !== 'null'
        ) {
            let priorityTransferInfo = {}
            if (data.base.currentTenants2.priorityTransferInfo.hasOwnProperty('identifier') === true && data.base.currentTenants2.priorityTransferInfo.identifier !== null) {
                let identifier = null
                if (data.base.currentTenants2.priorityTransferInfo.identifier.hasOwnProperty('scheme') === true && data.base.currentTenants2.priorityTransferInfo.identifier.scheme !== null) {
                    identifier = {}
                    identifier.scheme = data.base.currentTenants2.priorityTransferInfo.identifier.scheme
                }
                if (data.base.currentTenants2.priorityTransferInfo.identifier.hasOwnProperty('id') === true && data.base.currentTenants2.priorityTransferInfo.identifier.id !== null) {
                    if (identifier === null) identifier = {}
                    identifier.id = data.base.currentTenants2.priorityTransferInfo.identifier.id
                }
                if (data.base.currentTenants2.priorityTransferInfo.identifier.hasOwnProperty('legalName') === true && data.base.currentTenants2.priorityTransferInfo.identifier.legalName !== null) {
                    let legalName = {}
                    if (data.base.currentTenants2.priorityTransferInfo.identifier.legalName.hasOwnProperty('uk_UA') === true && data.base.currentTenants2.priorityTransferInfo.identifier.legalName.uk_UA !== null) {
                        if (identifier === null) identifier = {}
                        legalName.uk_UA = data.base.currentTenants2.priorityTransferInfo.identifier.legalName.uk_UA
                        identifier.legalName = legalName
                    }
                }
                priorityTransferInfo.identifier = identifier
                if (data.base.currentTenants2.priorityTransferInfo.hasOwnProperty('transferredFrom') === true && data.base.currentTenants2.priorityTransferInfo.transferredFrom !== null) priorityTransferInfo.transferredFrom = data.base[property].priorityTransferInfo.transferredFrom
                // if(data.base.currentTenants2.priorityTransferInfo.hasOwnProperty('name') === true && data.base.currentTenants2.priorityTransferInfo.name !== null){
                //     if(data.base.currentTenants2.priorityTransferInfo.name.hasOwnProperty('uk_UA') === true && data.base.currentTenants2.priorityTransferInfo.name.uk_UA !== null){
                //         let name = {}
                //         name.uk_UA = data.base.currentTenants2.priorityTransferInfo.name.uk_UA
                //         priorityTransferInfo.name = name
                //     }
                // }

                currentTenants.priorityTransferInfo = priorityTransferInfo
            }
        }

        return currentTenants
    }
    //return body
}

export function addOrganizationEntity(data, body, property) {
    let tmp = body
    if (data.base[property] === undefined || data.base[property] === 'undefined') return body
    if (data.base.hasOwnProperty('organizationEntity') === true && data.base.organizationEntity !== null) {
        let organizationEntity = {}
        if (data.base.organizationEntity.hasOwnProperty('name') === true && data.base.organizationEntity.name !== null) {
            let name = {}
            if (data.base.organizationEntity.name.hasOwnProperty('uk_UA') === true && data.base.organizationEntity.name.uk_UA !== null) {
                name.uk_UA = data.base.organizationEntity.name.uk_UA
                organizationEntity.name = name
            }
        }
        if (data.base.organizationEntity.hasOwnProperty('representativeInfo') && data.base.organizationEntity.representativeInfo !== null) {
            organizationEntity.representativeInfo = data.base.organizationEntity.representativeInfo
        }
        if (data.base.organizationEntity.hasOwnProperty('identifier') && data.base.organizationEntity.identifier !== null) {
            let identifier = null
            if (data.base.organizationEntity.identifier.hasOwnProperty('scheme') && data.base.organizationEntity.identifier.scheme !== null) {
                identifier = {}
                identifier.scheme = data.base.organizationEntity.identifier.scheme
            }
            if (data.base.organizationEntity.identifier.hasOwnProperty('id') === true && data.base.organizationEntity.identifier.id !== null) {
                if (identifier === null) identifier = {}
                identifier.id = Number(data.base.organizationEntity.identifier.id)
            }
            if (data.base.organizationEntity.identifier.hasOwnProperty('legalName') === true && data.base.organizationEntity.identifier.legalName !== null) {
                let legalName = {}
                if (data.base.organizationEntity.identifier.legalName.hasOwnProperty('uk_UA') === true && data.base.organizationEntity.identifier.legalName.uk_UA !== null) {
                    if (identifier === null) identifier = {}
                    legalName.uk_UA = data.base.organizationEntity.identifier.legalName.uk_UA
                    identifier.legalName = legalName
                }
            }
            organizationEntity.identifier = identifier
        }
        if (data.base.organizationEntity.hasOwnProperty('contactPoint') === true && data.base.organizationEntity.contactPoint !== null) {
            let contactPoint = null

            if (data.base.organizationEntity.contactPoint.hasOwnProperty('name') === true && data.base.organizationEntity.contactPoint.name !== null) {
                let name = {}
                if (data.base.organizationEntity.contactPoint.name.hasOwnProperty('uk_UA') === true && data.base.organizationEntity.contactPoint.name.uk_UA !== null) {
                    contactPoint = {}
                    name.uk_UA = data.base.organizationEntity.contactPoint.name.uk_UA
                    contactPoint.name = name
                }
            }
            if (data.base.organizationEntity.contactPoint.hasOwnProperty('email') === true && data.base.organizationEntity.contactPoint.email !== null) {
                if (contactPoint === null) contactPoint = {}
                contactPoint.email = data.base.organizationEntity.contactPoint.email
            }
            if (data.base.organizationEntity.contactPoint.hasOwnProperty('telephone') === true && data.base.organizationEntity.contactPoint.telephone !== null) {
                if (contactPoint === null) contactPoint = {}
                contactPoint.telephone = data.base.organizationEntity.contactPoint.telephone
            }
            if (data.base.organizationEntity.contactPoint.hasOwnProperty('faxNumber') === true && data.base.organizationEntity.contactPoint.faxNumber !== null) {
                if (contactPoint === null) contactPoint = {}
                contactPoint.faxNumber = data.base.organizationEntity.contactPoint.faxNumber
            }
            if (data.base.organizationEntity.contactPoint.hasOwnProperty('url') === true && data.base.organizationEntity.contactPoint.url !== null) {
                if (contactPoint === null) contactPoint = {}
                contactPoint.url = data.base.organizationEntity.contactPoint.url
            }
            organizationEntity.contactPoint = contactPoint
        }
        if (data.base.organizationEntity.hasOwnProperty('address') === true && data.base.organizationEntity.address !== null) {
            let address = null
            if (data.base.organizationEntity.address.hasOwnProperty('countryName') === true && data.base.organizationEntity.address.countryName !== null) {
                let countryName = {}
                if (data.base.organizationEntity.address.countryName.hasOwnProperty('uk_UA') === true && data.base.organizationEntity.address.countryName.uk_UA !== null) {
                    address = {}
                    countryName.uk_UA = data.base.organizationEntity.address.countryName.uk_UA
                    address.countryName = countryName
                }
            }
            if (data.base.organizationEntity.address.hasOwnProperty('region') === true && data.base.organizationEntity.address.region !== null) {
                let region = {}
                if (data.base.organizationEntity.address.region.hasOwnProperty('uk_UA') === true && data.base.organizationEntity.address.region.uk_UA !== null) {
                    if (address === null) address = {}
                    region.uk_UA = data.base.organizationEntity.address.region.uk_UA
                    address.region = region
                }
            }
            if (data.base.organizationEntity.address.hasOwnProperty('locality') === true && data.base.organizationEntity.address.locality !== null) {
                let locality = {}
                if (data.base.organizationEntity.address.locality.hasOwnProperty('uk_UA') === true && data.base.organizationEntity.address.locality.uk_UA !== null) {
                    if (address === null) address = {}
                    locality.uk_UA = data.base.organizationEntity.address.locality.uk_UA
                    address.locality = locality
                }
            }
            if (data.base.organizationEntity.address.hasOwnProperty('streetAddress') === true && data.base.organizationEntity.address.streetAddress !== null) {
                let streetAddress = {}
                if (data.base.organizationEntity.address.streetAddress.hasOwnProperty('uk_UA') === true && data.base.organizationEntity.address.streetAddress.uk_UA !== null) {
                    if (address === null) address = {}
                    streetAddress.uk_UA = data.base.organizationEntity.address.streetAddress.uk_UA
                    address.streetAddress = streetAddress
                }
            }
            if (data.base.organizationEntity.address.hasOwnProperty('postalCode') === true && data.base.organizationEntity.address.postalCode !== null) {
                if (address === null) address = {}
                address.postalCode = data.base.organizationEntity.address.postalCode
            }
            organizationEntity.address = address
        }

        tmp.organizationEntity = organizationEntity
        return tmp
    } else {
        tmp.organizationEntity = null
    }
    return body
}

export function addContactPoint(data, body, property) {
    let tmp = body
    if (data.base[property] === undefined || data.base[property] === 'undefined') return body
    if (data.base.hasOwnProperty('contactPoint') === true && data.base.contactPoint !== null) {
        let contactPoints = {}

        if (data.base.hasOwnProperty('contactPoint') === true && data.base.contactPoint !== null) {
            let contactPoint = null

            if (data.base.contactPoint.hasOwnProperty('name') === true && data.base.contactPoint.name !== null) {
                let name = {}
                if (data.base.contactPoint.name.hasOwnProperty('uk_UA') === true && data.base.contactPoint.name.uk_UA !== null) {
                    contactPoint = {}
                    name.uk_UA = data.base.contactPoint.name.uk_UA
                    contactPoint.name = name
                }
            }
            if (data.base.contactPoint.hasOwnProperty('email') === true && data.base.contactPoint.email !== null) {
                if (contactPoint === null) contactPoint = {}
                contactPoint.email = data.base.contactPoint.email
            }
            if (data.base.contactPoint.hasOwnProperty('telephone') === true && data.base.contactPoint.telephone !== null) {
                if (contactPoint === null) contactPoint = {}
                contactPoint.telephone = data.base.contactPoint.telephone
            }
            if (data.base.contactPoint.hasOwnProperty('faxNumber') === true && data.base.contactPoint.faxNumber !== null) {
                if (contactPoint === null) contactPoint = {}
                contactPoint.faxNumber = data.base.contactPoint.faxNumber
            }
            if (data.base.contactPoint.hasOwnProperty('url') === true && data.base.contactPoint.url !== null) {
                if (contactPoint === null) contactPoint = {}
                contactPoint.url = data.base.contactPoint.url
            }
            contactPoints = contactPoint
        }


        tmp.contactPoint = contactPoints
        return tmp
    }
    return body
}

export function addAdditionalInformation(data, body, property) {
    let tmp = body
    if (data.base.hasOwnProperty('additionalInformation') !== true) return body
    if (data.base.additionalInformation === null) return body
    let additionalInformation = []
    let count = 0
    for (let i = 0; i < data.base.additionalInformation.length; i++) {
        if (data.base.additionalInformation[i].status !== true) {
            additionalInformation[count] = {
                description: data.base.additionalInformation[i].description,
                initiator: data.base.additionalInformation[i].initiator,
                initiatorOther: data.base.additionalInformation[i].initiatorOther,
                reason: data.base.additionalInformation[i].reason,
                reasonOther: data.base.additionalInformation[i].reasonOther
            }
            count++
        }
    }
    tmp.additionalInformation = additionalInformation
    return tmp
}

export function addBanksNew(data, body, property) {
    let tmp = body
    if (data.base.hasOwnProperty('bankAccounts') !== true) {
        tmp.bankAccounts = null
        return tmp
    }
    if (data.base.bankAccounts === null) {
        tmp.bankAccounts = null
        return tmp
    }
    if (data.base.bankAccounts.length === 0) {
        tmp.bankAccounts = null
        return tmp
    }

    let bankAccounts = data.base.bankAccounts
        .filter((e) => {
            return e.status !== true
        })
        .filter((e) => {
            return e.accountType !== null
        })
        .filter((e) => {
            return e.accounts !== null && e.accounts.length !== 0
        })
        .filter((e) => {
            if ((e.accountType === "advancePayment" || e.accountType === "lease")
                && ((e.holderType === null && e.percent === null)
                    || ((e.holderType !== null && e.holderType !== '') && (e.percent !== null && e.percent !== ''))
                )
            ) {
                return e
            }
            return e
        })
        .map((e) => {
            if (e.accountType === "advancePayment" || e.accountType === "lease") {
                return {
                    accountType: e.accountType,
                    accounts: e.accounts,
                    holderType: e.holderType,
                    percent: e.percent
                }
            }
            return {
                accountType: e.accountType,
                accounts: e.accounts,
            }
        })
    if (bankAccounts.length === 0) bankAccounts = null
    tmp.bankAccounts = bankAccounts
    return tmp
}