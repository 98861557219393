import React from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import {changeCreateAuctionMinimalStepCurrency, changeCreateAuctionMinimalStepAmount, changeCreateAuctionMinimalStepTax} from '../../../redux/actions.js';

import TextField from '@material-ui/core/TextField';
import {PROCEDURE_NAME} from '../../../redux/procedure_v3.35.2.uk';

import InputAmount from '../../elements/inputs/inputAmount';
import { isLLD, isLLE, isLLP, isRCE, isRCD, isSUE, isSUD, isLRE,
    isALE, isCSE, isCSD, isLSE, isLSP, isBSE, isBSD, isBRE, isBRD } from '../../../redux/aucTypes';

import {disabletElem} from '../disabled'

const WindowAuctionMinimalStep = ( props ) => {
    return summZU(props)
}

function viewOptionCurrentCost(props, position){
    let tmp = null;
    switch(props.auctionsMy.sellingMethod){
        case "renewables":
        case "renewables-fast":
        case "renewables-fast-manual":
        case "renewables-initial-auction":
        case "renewables-initial-qualification":
        case "renewables-initial-qualification-fast":
            if(position === 1){
                tmp = <>
                    <option key={4} value="eurocent">eurocent</option>
                </>
                //props.changeCreateAuctionMinimalStepCurrency("eurocent")
            }else if(position === 2){
                tmp = <>
                    <option key={3} value="EUR">EUR</option>
                </>
                //props.changeCreateAuctionMinimalStepCurrency("EUR")
            }
            break;
        case "timber-english":
        case "timber-english-fast":
        case "timber-english-fast-manual":
        case "timber-english-initial-auction":
        case "timber-english-initial-qualification":
        case "timber-english-initial-qualification-fast":
        case "subsoil-english":
        case "subsoil-english-fast":
        case "subsoil-english-fast-manual":
        case "subsoil-english-initial-auction":
        case "subsoil-english-initial-qualification":
        case "railwayCargo-english":
        case "railwayCargo-english-fast":
        case "railwayCargo-english-fast-manual":
        case "railwayCargo-english-initial-auction":
        case "railwayCargo-english-initial-qualification":
        case "dgf-english":
        case "dgf-english-fast":
        case "dgf-english-fast-manual":
        case "dgf-english-fast-auction-manual-qualification":
        case "dgf-english-initial-auction":
        case "dgf-english-initial-auction-manual":
        case "dgf-english-initial-qualification":
            tmp = <>
                <option key={1} value="UAH">ГРН</option>
                {/*<option key={2} value="USD">USD</option>
                <option key={3} value="EUR">EUR</option>*/}
            </>
            //props.changeCreateAuctionMinimalStepCurrency("UAH")
            break;
        default:
            tmp = <>
                <option key={1} value="UAH">ГРН</option>
                {/*<option key={2} value="USD">USD</option>
                <option key={3} value="EUR">EUR</option>*/}
            </>
            //props.changeCreateAuctionMinimalStepCurrency("UAH")
            break;
    }
    return tmp
}

function summZU(props){
    let minStep = null;
    let minStepAmo = null;
    if(props.auctionsMy.minimalStep !== null){
        if(props.auctionsMy.minimalStep.currency !== null){
            minStep = props.auctionsMy.minimalStep.currency
        }
        if(props.auctionsMy.minimalStep.amount !== null){
            minStepAmo = props.auctionsMy.minimalStep.amount
        }
    }
    if(
        isLLE(props.auctionsMy.sellingMethod) ||
        isLLD(props.auctionsMy.sellingMethod) ||
        isLLP(props.auctionsMy.sellingMethod) 
    ){
        let tmpMinStep = 0
        if(props.auctionsMy.minimalStep !== null){
            if(props.auctionsMy.minimalStep.currency !== null){
                tmpMinStep = props.auctionsMy.minimalStep.currency
            }
            if(props.auctionsMy.minimalStep.amount !== null){
                minStepAmo = props.auctionsMy.minimalStep.amount
            }
        }
        if(props.auctionsZUAttemp !== null){
            if(props.auctionsZUAttemp.tenderAttempts === 1 && props.auctionsMy.tenderAttempts === '2'){
                return (
                    <div className={styles.group}>
                        <div className={styles.titleValue}>
                            {nameInput(props)}
                            {requiredText(props)}
                        </div>
                        <div className={styles.groupElem}>
                            <div className={styles.valuta}>
                                <div>{PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.minimalStep.currency.legalName}</div>
                                <TextField
                                    className={styles.valutaInput}
                                    disabled
                                    id="outlined-select-currency-native"
                                    select
                                    value={tmpMinStep}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    helperText="Тип валюти"
                                    variant="outlined"
                                >
                                    {viewOptionCurrentCost(props, 2)}
                                </TextField>
                            </div>
                            <div>
                                <div>{PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.minimalStep.amount.legalName}</div>
                                <TextField
                                    error = {redBlock(props, minStepAmo)}
                                    disabled={closeCSD(props, disabletElem(props.auctionsMy, '00'))}
                                    value={minStepAmo}
                                    onChange={(event) => {
                                        let reg=/[a-zA-Zа-яА-Яа-яіІїЇєЄ]/;
                                        if(reg.test(event.target.value) !== true) props.changeCreateAuctionMinimalStepAmount(convertStrToNumber(event.target.value))
                                    }}
                                    variant="outlined"
                                    helperText=" "
                                />
                            </div>
                            {viewText(props)}
                        </div>
                    </div>
                )
            }else{
                if(props.typeElem === 1){
                    return (
                        <div className={styles.group}>
                            <div className={styles.titleValue}>
                                {nameInput(props)}
                                {requiredText(props)}
                            </div>
                            <div className={styles.groupElem}>
                                <div className={styles.valuta}>
                                <div>{PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.minimalStep.currency.legalName}</div>
                                    <TextField
                                        className={styles.valutaInput}
                                        disabled
                                        id="outlined-select-currency-native"
                                        select
                                        value={tmpMinStep}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        helperText="Тип валюти"
                                        variant="outlined"
                                    >
                                        {viewOptionCurrentCost(props, 2)}
                                    </TextField>
                                </div>
                                <div>
                                <div>{PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.minimalStep.amount.legalName}</div>
                                    <TextField
                                        error = {redBlock(props, minStepAmo)}
                                        disabled={closeCSD(props, disabletElem(props.auctionsMy, '00'))}
                                        value={minStepAmo}
                                        onChange={(event) => {
                                            let reg=/[a-zA-Zа-яА-Яа-яіІїЇєЄ]/;
                                            if(reg.test(event.target.value) !== true) props.changeCreateAuctionMinimalStepAmount(convertStrToNumber(event.target.value))
                                        }}
                                        variant="outlined"
                                        helperText=" "
                                    />
                                </div>
                                {viewText(props)}
                            </div>
                        </div>
                    )
                }else{
                    return (
                        <div className={styles.group}>
                            <div className={styles.titleValue}>
                                {nameInput(props)}
                                {requiredText(props)}
                            </div>
                            <div className={styles.groupElem}>
                                <div className={styles.valuta}>
                                    <div>{PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.minimalStep.currency.legalName}</div>
                                    <TextField
                                        className={styles.valutaInput}
                                        disabled
                                        id="outlined-select-currency-native"
                                        select
                                        value={tmpMinStep}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        helperText="Тип валюти"
                                        variant="outlined"
                                    >
                                        {viewOptionCurrentCost(props, 2)}
                                    </TextField>
                                </div>
                                <div>
                                <div>{PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.minimalStep.amount.legalName}</div>
                                    <TextField
                                        error = {redBlock(props, minStepAmo)}
                                        disabled={closeCSD(props, disabletElem(props.auctionsMy, '00'))}
                                        value={minStepAmo}
                                        onChange={(event) => {
                                            let reg=/[a-zA-Zа-яА-Яа-яіІїЇєЄ]/;
                                            if(reg.test(event.target.value) !== true) props.changeCreateAuctionMinimalStepAmount(convertStrToNumber(event.target.value))
                                        }}
                                        variant="outlined"
                                        helperText=" "
                                    />
                                </div>
                                {viewText(props)}
                            </div>
                        </div>
                    )
                }
            }
        }else{
            if(props.typeElem === 1){
                return (
                    <div className={styles.group}>
                        <div className={styles.titleValue}>
                            {nameInput(props)}
                            {requiredText(props)}
                        </div>
                        <div className={styles.groupElem}>
                            <div className={styles.valuta}>
                            <div>{PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.minimalStep.currency.legalName}</div>
                                <TextField
                                    className={styles.valutaInput}
                                    disabled
                                    id="outlined-select-currency-native"
                                    select
                                    value={tmpMinStep}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    helperText="Тип валюти"
                                    variant="outlined"
                                >
                                    {viewOptionCurrentCost(props, 2)}
                                </TextField>
                            </div>
                            <div>
                            <div>{PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.minimalStep.amount.legalName}</div>
                                <TextField
                                    error = {redBlock(props, minStepAmo)}
                                    disabled={closeCSD(props, disabletElem(props.auctionsMy, '00'))}
                                    value={minStepAmo}
                                    onChange={(event) => {
                                        let reg=/[a-zA-Zа-яА-Яа-яіІїЇєЄ]/;
                                        if(reg.test(event.target.value) !== true) props.changeCreateAuctionMinimalStepAmount(convertStrToNumber(event.target.value))
                                    }}
                                    variant="outlined"
                                    helperText=" "
                                />
                            </div>
                            {viewText(props)}
                        </div>
                    </div>
                )
            }else{
                return (
                    <div className={styles.group}>
                        <div className={styles.titleValue}>
                            {nameInput(props)}
                            {requiredText(props)}
                        </div>
                        <div className={styles.groupElem}>
                            <div className={styles.valuta}>
                                <div>{PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.minimalStep.currency.legalName}</div>
                                <TextField
                                    className={styles.valutaInput}
                                    disabled
                                    id="outlined-select-currency-native"
                                    select
                                    value={tmpMinStep}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    helperText="Тип валюти"
                                    variant="outlined"
                                >
                                    {viewOptionCurrentCost(props, 2)}
                                </TextField>
                            </div>
                            <div>
                            <div>{PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.minimalStep.amount.legalName}</div>
                                <TextField
                                    error = {redBlock(props, minStepAmo)}
                                    disabled={closeCSD(props, disabletElem(props.auctionsMy, '00'))}
                                    value={minStepAmo}
                                    onChange={(event) => {
                                        let reg=/[a-zA-Zа-яА-Яа-яіІїЇєЄ]/;
                                        if(reg.test(event.target.value) !== true) props.changeCreateAuctionMinimalStepAmount(convertStrToNumber(event.target.value))
                                    }}
                                    variant="outlined"
                                    helperText=" "
                                />
                            </div>
                            {viewText(props)}
                        </div>
                    </div>
                )
            }
        }
    }else{
        if(props.typeElem === 1){
            return (
                <div className={styles.group}>
                    <InputAmount
                        title={`${nameInput(props)}${requiredText(props)}`}
                        data={
                            {amount: minStepAmo,
                            currency: minStep}
                        }
                        onChange={(event) => {
                            props.changeCreateAuctionMinimalStepAmount(event.amount)
                        }}
                        disabled={disabletElem(props.auctionsMy, '00')}
                    />
                        {viewText(props)}
                </div>
            )
        }else{
            return (
                <div className={styles.group}>
                    <div className={styles.titleValue}>
                        {nameInput(props)}
                        {requiredText(props)}
                    </div>
                    <div className={styles.groupElem}>
                        <div className={styles.valuta}>
                            <div>{PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.minimalStep.currency.legalName}</div>
                            <TextField
                                className={styles.valutaInput}
                                disabled
                                id="outlined-select-currency-native"
                                select
                                value={minStep}
                                SelectProps={{
                                    native: true,
                                }}
                                helperText="Тип валюти"
                                variant="outlined"
                            >
                                {viewOptionCurrentCost(props, 2)}
                            </TextField>
                        </div>
                        <div>
                        <div>{PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.minimalStep.amount.legalName}</div>
                            <TextField
                                error = {redBlock(props, minStepAmo)}
                                disabled={closeCSD(props, disabletElem(props.auctionsMy, '00'))}
                                value={minStepAmo}
                                onChange={(event) => {
                                    let reg=/[a-zA-Zа-яА-Яа-яіІїЇєЄ]/;
                                    if(reg.test(event.target.value) !== true) props.changeCreateAuctionMinimalStepAmount(convertStrToNumber(event.target.value))
                                }}
                                variant="outlined"
                                helperText=" "
                            />
                        </div>
                        {viewText(props)}
                    </div>
                </div>
            )
        }
    }
}

function nameInput(props){
    if(
        isLLE(props.auctionsMy.sellingMethod) ||
        isLLD(props.auctionsMy.sellingMethod) ||
        isLLP(props.auctionsMy.sellingMethod) ||
        isLRE(props.auctionsMy.sellingMethod)
    ){
        return PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.minimalStep.legalName
    }else if(
        isSUE(props.auctionsMy.sellingMethod) || isSUD(props.auctionsMy.sellingMethod) ||
        isBSE(props.auctionsMy.sellingMethod) || isBSD(props.auctionsMy.sellingMethod) ||
        isALE(props.auctionsMy.sellingMethod) ||
        isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
        isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod)
    ){
        return "Розмір кроку аукціону"
    }
    return 'Мінімальний крок'
}

function viewText(props){
    if(
        isLLE(props.auctionsMy.sellingMethod) || isLLD(props.auctionsMy.sellingMethod) || isLLP(props.auctionsMy.sellingMethod) ||
        isLRE(props.auctionsMy.sellingMethod) ||
        isLSE(props.auctionsMy.sellingMethod) || isLSP(props.auctionsMy.sellingMethod) ||
        isBSE(props.auctionsMy.sellingMethod) || isBSD(props.auctionsMy.sellingMethod) ||
        isALE(props.auctionsMy.sellingMethod) ||
        isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
        isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod)
    ){
        return null
    }else{
        return <div className={styles.helperText}>1% від стартової ціни лоту, розраховується автоматично</div>
    }
}


function redBlock(props, amount){
    if(
        isLLE(props.auctionsMy.sellingMethod) || isLLD(props.auctionsMy.sellingMethod) || isLLP(props.auctionsMy.sellingMethod)
    ){
        if(amount === null || amount === '' || amount === 'null'){
            return 'error'
        }
        return null
    }
    return null
}

function convertStrToNumber(data){
    let str = ''
    if(data.indexOf(',') !== -1){
        str = data.replace(',', '.')
    }else{
        str = data
    }
    let strArr = null
    if(str.indexOf('.') !== -1){
        if(Number(str) < 0.01) return 0.01
        strArr = str.split('.')
        if(strArr.length < 3){
            if(strArr[1].length < 3){
                return str
            }else{
                return strArr[0] + '.' + strArr[1].slice(0, 2)
            }
        }else{
            if(strArr[1].length === 0){
                return strArr[0] + '.'
            }else if(strArr[1].length === 1){
                return strArr[0] + '.' + strArr[1].slice(0, 1)
            }else if(strArr[1].length === 2){
                return strArr[0] + '.' + strArr[1].slice(0, 2)
            }    
        }
    }
    //if(Number(str) === 0) str = 0.01
    //if(Number(str) === 0.0) str = 0.01
    return str
}

function closeCSD(props, data){
    if(
       isCSE(props.auctionsMy.sellingMethod) ||
       isBRE(props.auctionsMy.sellingMethod)
    ){
        return 'disabled'
    }
    return data
}

function requiredText(props){
    if(
        isLLE(props.auctionsMy.sellingMethod) ||
        isLLD(props.auctionsMy.sellingMethod) ||
        isLLP(props.auctionsMy.sellingMethod)
    ){
        return '*'
    }
    return ''
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
    }
}

const mapDispatchToProps = {
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuctionMinimalStep)