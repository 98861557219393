import React from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import {
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionRegistrationFeeAmount,
} from '../../../redux/actions.js';
import {
    setDataAddress,
} from '../../../redux/createAddress';

import TextField from '@material-ui/core/TextField';
import InputAmount from '../../elements/inputs/inputAmount';

import {PROCEDURE_NAME} from '../../../redux/procedure_v3.35.2.uk';

import {disabletElem} from '../disabled'

const WindowAuctionGaranty = ( props ) => {
    let tmp = null;
    let tmpCurrent = 'UAH'
    let tmpAmount = ''
    let tmpTax = 'null'
    if(props.auctionsMy !== null){
        if(
            props.auctionsMy.sellingMethod === 'landRental-english' ||
            props.auctionsMy.sellingMethod === 'landRental-english-fast' ||
            props.auctionsMy.sellingMethod === 'landRental-english-fast-manual' ||
            props.auctionsMy.sellingMethod === 'landRental-english-fast-auction-manual-qualification' ||
            props.auctionsMy.sellingMethod === 'landRental-english-initial-auction' ||
            props.auctionsMy.sellingMethod === 'landRental-english-initial-auction-manual' ||
            props.auctionsMy.sellingMethod === 'landRental-english-initial-qualification' ||
            props.auctionsMy.sellingMethod === 'landRental-english-initial-qualification-prod' ||
            props.auctionsMy.sellingMethod === 'landSell-english' ||
            props.auctionsMy.sellingMethod === 'landSell-english-fast' ||
            props.auctionsMy.sellingMethod === 'landSell-english-fast-manual' ||
            props.auctionsMy.sellingMethod === 'landSell-english-fast-auction-manual-qualification' ||
            props.auctionsMy.sellingMethod === 'landSell-english-initial-auction' ||
            props.auctionsMy.sellingMethod === 'landSell-english-initial-auction-manual' ||
            props.auctionsMy.sellingMethod === 'landSell-english-initial-qualification' ||
            props.auctionsMy.sellingMethod === 'landSell-english-initial-qualification-prod' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod'
        ){
            if(props.auctionsMy.registrationFee !== null){
                tmpCurrent = props.auctionsMy.registrationFee.currency
                tmpAmount = props.auctionsMy.registrationFee.amount
                tmpTax = props.auctionsMy.registrationFee.valueAddedTaxIncluded
            }
            tmp = <div className={styles.group}>
                <div className={styles.titleValue}>
                    {PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.registrationFee.legalName}
                    {requiredText(props)}
                </div>
                <div className={styles.groupElem}>
                    <div className={styles.valuta}>
                        <div className={styles.titleValue}>{PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.registrationFee.currency.legalName}</div>
                        <TextField
                            className={styles.valutaInput}
                            disabled
                            id="outlined-select-currency-native"
                            select
                            value={tmpCurrent}
                            SelectProps={{
                                native: true,
                            }}
                            helperText="Тип валюти"
                            variant="outlined"
                        >
                            {viewOptionCurrentCost(props, 2)}
                        </TextField>
                    </div>
                    <div>
                        <div className={styles.titleValue}>{PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.registrationFee.amount.legalName}</div>
                        <TextField
                            required
                            error = {redBlock(props, tmpAmount)}
                            disabled={disabletElem(props.auctionsMy, '00')}
                            value={tmpAmount}
                            onChange={(event) => {
                                let reg=/[a-zA-Zа-яА-Яа-яіІїЇєЄ]/;
                                if(reg.test(event.target.value) !== true) props.changeCreateAuctionRegistrationFeeAmount(convertStrToNumber(event.target.value))
                            }}
                            variant="outlined"
                            helperText=" "
                        />
                    </div>
                </div>
            </div>
        }else{
            if(props.auctionsMy.registrationFee !== null){
                tmpCurrent = props.auctionsMy.registrationFee.currency
                tmpAmount = props.auctionsMy.registrationFee.amount
                tmpTax = props.auctionsMy.registrationFee.valueAddedTaxIncluded
            }
            tmp = <div className={styles.group}>
                <InputAmount
                    title={PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.registrationFee.legalName}
                    data={
                        {amount: tmpAmount,
                        currency: 'UAH'}
                    }
                    onChange={(event) => {
                        let reg=/[a-zA-Zа-яА-Яа-яіІїЇєЄ]/;
                        if(reg.test(event.amount) !== true) props.changeCreateAuctionRegistrationFeeAmount(event.amount)
                    }}
                    disabled={disabletElem(props.auctionsMy, '00')}
                    error={redBlock(props, tmpAmount)}
                />
            </div>
        }
    }
    return tmp
}

function elementDisabled(props){
    let tmpStatus = null;
    if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            tmpStatus = 'disabled'
        }
    }
    return tmpStatus
}

function viewOptionCurrentCost(props, position){
    let tmp = null;
    switch(props.auctionsMy.sellingMethod){
        case "renewables":
        case "renewables-fast":
        case "renewables-fast-manual":
        case "renewables-initial-auction":
        case "renewables-initial-qualification":
        case "renewables-initial-qualification-fast":
            if(position === 1){
                tmp = <>
                    <option key={4} value="eurocent">eurocent</option>
                </>
            }else if(position === 2){
                tmp = <>
                    <option key={3} value="EUR">EUR</option>
                </>
            }
            break;
        case "timber-english":
        case "timber-english-fast":
        case "timber-english-fast-manual":
        case "timber-english-initial-auction":
        case "timber-english-initial-qualification":
        case "timber-english-initial-qualification-fast":
        case "subsoil-english":
        case "subsoil-english-fast":
        case "subsoil-english-fast-manual":
        case "subsoil-english-initial-auction":
        case "subsoil-english-initial-qualification":
        case "subsoil-dutch":
        case "subsoil-dutch-fast":
        case "subsoil-dutch-fast-manual":
        case "subsoil-dutch-initial-auction":
        case "subsoil-dutch-initial-qualification":
        case "subsoil-dutch-initial-auction-manual":
        case "subsoil-english-fast-auction-manual-qualification":
        case "railwayCargo-english":
        case "railwayCargo-english-fast":
        case "railwayCargo-english-fast-manual":
        case "railwayCargo-english-initial-auction":
        case "railwayCargo-english-initial-qualification":
        case "railwayCargo-dutch":
        case "railwayCargo-dutch-fast":
        case "railwayCargo-dutch-fast-manual":
        case "railwayCargo-dutch-initial-auction":
        case "railwayCargo-dutch-initial-qualification":
        case "railwayCargo-dutch-fast-auction-manual-qualification":
        case "railwayCargo-dutch-initial-auction-manual":
        case "dgf-english":
        case "dgf-english-fast":
        case "dgf-english-fast-manual":
        case "dgf-english-fast-auction-manual-qualification":
        case "dgf-english-initial-auction":
        case "dgf-english-initial-auction-manual":
        case "dgf-english-initial-qualification":
        case "legitimatePropertyLease-english":
        case "legitimatePropertyLease-english-fast":
        case "legitimatePropertyLease-english-fast-manual":
        case "legitimatePropertyLease-english-fast-auction-manual-qualification":
        case "legitimatePropertyLease-english-initial-auction":
        case "legitimatePropertyLease-english-initial-auction-manual":
        case "legitimatePropertyLease-english-initial-qualification":
        case "legitimatePropertyLease-english-initial-qualification-prod":
        case "legitimatePropertyLease-dutch":
        case "legitimatePropertyLease-dutch-fast":
        case "legitimatePropertyLease-dutch-fast-manual":
        case "legitimatePropertyLease-dutch-fast-auction-manual-qualification":
        case "legitimatePropertyLease-dutch-initial-auction":
        case "legitimatePropertyLease-dutch-initial-auction-manual":
        case "legitimatePropertyLease-dutch-initial-qualification-prod":
            tmp = <>
                <option key={1} value="UAH">ГРН</option>
                {/*<option key={2} value="USD">USD</option>
                <option key={3} value="EUR">EUR</option>*/}
            </>
            break;
        default:
            tmp = <>
                <option key={1} value="UAH">ГРН</option>
                {/*<option key={2} value="USD">USD</option>
                <option key={3} value="EUR">EUR</option>*/}
            </>
            break;
    }
    return tmp
}

function convertStrToNumber(data){
    let str = ''
    if(data.indexOf(',') !== -1){
        str = data.replace(',', '.')
    }else{
        str = data
    }
    let strArr = null
    if(str.indexOf('.') !== -1){
        //if(Number(str) < 0.01) return 0.01
        strArr = str.split('.')
        if(strArr.length < 3){
            if(strArr[1].length < 3){
                return str
            }else{
                return strArr[0] + '.' + strArr[1].slice(0, 2)
            }
        }else{
            if(strArr[1].length === 0){
                return strArr[0] + '.'
            }else if(strArr[1].length === 1){
                return strArr[0] + '.' + strArr[1].slice(0, 1)
            }else if(strArr[1].length === 2){
                return strArr[0] + '.' + strArr[1].slice(0, 2)
            }    
        }
    }
    //if(Number(str) === 0) str = 0.01
    //if(Number(str) === 0.0) str = 0.01
    return str
}

function redBlock(props, amount){
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        if(amount === null || amount === '' || amount === 'null'){
            return 'error'
        }
        return null
    }
    return null
}

function requiredText(props){
    if(
        props.auctionsMy.sellingMethod === 'landRental-english' ||
        props.auctionsMy.sellingMethod === 'landRental-english-fast' ||
        props.auctionsMy.sellingMethod === 'landRental-english-fast-manual' ||
        props.auctionsMy.sellingMethod === 'landRental-english-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'landRental-english-initial-auction' ||
        props.auctionsMy.sellingMethod === 'landRental-english-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'landRental-english-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'landRental-english-initial-qualification-prod' ||
        props.auctionsMy.sellingMethod === 'landSell-english' ||
        props.auctionsMy.sellingMethod === 'landSell-english-fast' ||
        props.auctionsMy.sellingMethod === 'landSell-english-fast-manual' ||
        props.auctionsMy.sellingMethod === 'landSell-english-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'landSell-english-initial-auction' ||
        props.auctionsMy.sellingMethod === 'landSell-english-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'landSell-english-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'landSell-english-initial-qualification-prod' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod'
    ){
        return '*'
    }
    return null
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
        auctionsZUAttemp: state.start.auctionsZUAttemp
    }
}

const mapDispatchToProps = {
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionRegistrationFeeAmount,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuctionGaranty)
