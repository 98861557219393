import React from 'react';
import styles from './imageAndButtonLine_TypeAuction.module.css';

/*import {
    LOTS_ARE_EXHIBITED_LOTS_LANG
} from '../../../../../../../../model/lang/catalogLots.js';*/

import {connect} from 'react-redux';

const LOTS_ARE_EXHIBITED_LOTS_LANG = ['Лоти виставляються', 'Lots are exhibited'];

const ImageAndButtonLine_TypeAuction = ( props ) => {
    return (
        <div className={styles.identif}>
            <div className={styles.secondBlock_textPrice}>{LOTS_ARE_EXHIBITED_LOTS_LANG[props.langFlag]}</div>
            <div className={styles.secondBlock_textPrice}>повторно (вдруге)</div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
    }
}

export default connect(mapStateToProps, null)(ImageAndButtonLine_TypeAuction)