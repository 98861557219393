import React from 'react';

import {connect} from 'react-redux';
import {changeBidAward} from '../../../redux/actions.js';
import {auctionsBidsGetId, changeCreateBidDocType, changeBidErrorPopUpStatus} from '../../../redux/actions/auctionBid/auctionBid.js';

import {changeDocumentArrFiles, changeDocumentCountArrFiles, changeDocumentBidBlockStatus, changeDocumentBidPublicName} from '../../../redux/actions/documents/documents.js';

import CreateBidNew from './createBidNew/createBidNew.js';
import CreateBidRenewables from './createBid/createBidRenewables.js';
import ViewBids from './viewBids/viewBids.js';

import {isRLE, isRLD, isCLE, isCLD, isSPE, isSPD, isNLE, isNLD, isLAE, isLAW, isLAP, isLPE, isAPE, isAPD, isBSE, isBSD} from '../../../redux/aucTypes'

const BidLot = ( props ) => {
    let tmpBaseUrl = null;
    let tmpId = null;
    if(window.location.pathname.indexOf('/tenders/') !== -1){
        tmpBaseUrl = window.location.pathname.split('/tenders/');
        tmpId = tmpBaseUrl[1].split('/')
        tmpId = tmpId[0]
    }

    let bidWork = null;
    let bidWorkRenew = null;
    //if((props.curentBidLot === null || props.curentBidLot.length === 0)){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('privateData')){
            if(props.auctionId.privateData !== null){
                if(props.auctionId.privateData.bids === null || props.auctionId.privateData.bids.length === 0){
                    if(props.auctionId.sellingMethod === "renewables"){
                        if(props.auctionId.status === "active_tendering"){
                            bidWork = <CreateBidRenewables />
                        }
                    }else if(
                        props.auctionId.sellingMethod === "renewables-fast" ||
                        props.auctionId.sellingMethod === "renewables-fast-manual" ||
                        props.auctionId.sellingMethod === "renewables-initial-auction" ||
                        props.auctionId.sellingMethod === "renewables-initial-qualification" ||
                        props.auctionId.sellingMethod === "renewables-initial-qualification-fast"
                    ){
                        if(
                            props.auctionId.status === null ||
                            //props.auctionId.status === "active_rectification" ||
                            props.auctionId.status === "active_tendering"
                        ){
                            if(
                                props.auctionId.items !== null ||
                                props.auctionId.items > 0
                            ){  
                                bidWork = <CreateBidRenewables />
                            }
                        }
                    }else{
                        if(props.auctionId.status === "active_tendering"){
                            //TODO: Незабывай включать если лить на прод
                            bidWork = <>
                                {/*<CreateBid />*/}
                                <CreateBidNew />
                            </>
                        }else if(
                            props.auctionId.sellingMethod === "railwayCargo-dutch" ||
                            props.auctionId.sellingMethod === "railwayCargo-dutch-fast" ||
                            props.auctionId.sellingMethod === "railwayCargo-dutch-fast-manual" ||
                            props.auctionId.sellingMethod === "railwayCargo-dutch-initial-auction" ||
                            props.auctionId.sellingMethod === "railwayCargo-dutch-initial-qualification" ||
                            props.auctionId.sellingMethod === "railwayCargo-dutch-fast-auction-manual-qualification" ||
                            props.auctionId.sellingMethod === "railwayCargo-dutch-initial-auction-manual" ||
                            props.auctionId.sellingMethod === "subsoil-dutch" ||
                            props.auctionId.sellingMethod === "subsoil-dutch-fast" ||
                            props.auctionId.sellingMethod === "subsoil-dutch-fast-manual" ||
                            props.auctionId.sellingMethod === "subsoil-dutch-initial-auction" ||
                            props.auctionId.sellingMethod === "subsoil-dutch-initial-qualification" ||
                            props.auctionId.sellingMethod === "subsoil-dutch-initial-auction-manual" ||
                            props.auctionId.sellingMethod === "dgf-dutch" ||
                            props.auctionId.sellingMethod === "dgf-dutch-fast" ||
                            props.auctionId.sellingMethod === "dgf-dutch-fast-manual" ||
                            props.auctionId.sellingMethod === "dgf-dutch-fast-auction-manual-qualification" ||
                            props.auctionId.sellingMethod === "dgf-dutch-initial-auction" ||
                            props.auctionId.sellingMethod === "dgf-dutch-initial-qualification" ||
                            props.auctionId.sellingMethod === "dgf-dutch-initial-auction-manual" ||
                            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch" ||
                            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                            props.auctionId.sellingMethod === 'commercialSell-dutch' ||
                            props.auctionId.sellingMethod === 'commercialSell-dutch-fast' ||
                            props.auctionId.sellingMethod === 'commercialSell-dutch-fast-manual' ||
                            props.auctionId.sellingMethod === 'commercialSell-dutch-fast-auction-manual-qualification' ||
                            props.auctionId.sellingMethod === 'commercialSell-dutch-initial-auction' ||
                            props.auctionId.sellingMethod === 'commercialSell-dutch-initial-auction-manual' ||
                            props.auctionId.sellingMethod === 'commercialSell-dutch-initial-qualification' ||
                            props.auctionId.sellingMethod === 'commercialSell-dutch-initial-qualification-prod' ||
                            props.auctionId.sellingMethod === 'bankRuptcy-dutch' ||
                            props.auctionId.sellingMethod === 'bankRuptcy-dutch-fast' ||
                            props.auctionId.sellingMethod === 'bankRuptcy-dutch-fast-manual' ||
                            props.auctionId.sellingMethod === 'bankRuptcy-dutch-fast-auction-manual-qualification' ||
                            props.auctionId.sellingMethod === 'bankRuptcy-dutch-initial-auction' ||
                            props.auctionId.sellingMethod === 'bankRuptcy-dutch-initial-auction-manual' ||
                            props.auctionId.sellingMethod === 'bankRuptcy-dutch-initial-qualification' ||
                            props.auctionId.sellingMethod === 'bankRuptcy-dutch-initial-qualification-prod' ||
                            isRLE(props.auctionId.sellingMethod) ||
                            isRLD(props.auctionId.sellingMethod) ||
                            isCLE(props.auctionId.sellingMethod) ||
                            isCLD(props.auctionId.sellingMethod) ||
                            isSPD(props.auctionId.sellingMethod) ||
                            isNLD(props.auctionId.sellingMethod)
                            || isAPD(props.auctionId.sellingMethod)
                        ){
                            if(props.auctionId.status === "active_auction"){
                                let dateActiveAuction = null
                                if(props.auctionId.auctionPeriod !== null){
                                    //if(props.auctionId.auctionPeriods.length > 0){
                                        //for(let tm = 0; tm < props.auctionId.auctionPeriods.length; tm++){
                                            //if(props.auctionId.auctionPeriods[tm].status === "active_auction")
                                            dateActiveAuction = new Date(props.auctionId.auctionPeriod.startDate)
                                        //}
                                    //}
                                }
                                let tmpDate = dateActiveAuction.getTime()
                                let timePoyas = ((1000 * 60) * 60) * 2
                                let timeDutch = (((1000 * 60) * 60) * 6) + ((1000 * 60) * 45)
                                let tmpSumm = tmpDate + timePoyas + timeDutch
                                if(tmpSumm > Date.now()){
                                    //TODO: Незабывай включать если лить на прод
                                    bidWork = <>
                                        {/*<CreateBid />*/}
                                        <CreateBidNew />
                                    </>
                                }
                            }
                        }
                        // if (isBSE(props.auctionId.sellingMethod) || isBSD(props.auctionId.sellingMethod)) {
                            if(props.auctionId.tenderPeriod !== null){
                                let dateTenderStart = new Date(props.auctionId.tenderPeriod.startDate)
                                let tmpStart = dateTenderStart.getTime()
                                let dateTenderEnd = new Date(props.auctionId.tenderPeriod.endDate)
                                let tmpEnd = dateTenderEnd.getTime()
                                if(tmpStart < Date.now() && Date.now() < tmpEnd){
                                    return <CreateBidNew />
                                }
                            }
                        // }
                    }
                }else{
                    bidWork = editBids(props)    
                }
            }else{
                bidWork = editBids(props)
            }
        }
    }
    if(props.auctionId !== null){
        if(props.auctionId.items === null || props.auctionId.items.length === 0){
            bidWork = null
        }
    }
    //if(isLAE(props.auctionId.sellingMethod) || isLAW(props.auctionId.sellingMethod) || isLAP(props.auctionId.sellingMethod)) return null
    return (
        <>
            <div>
                {bidWork}
            </div>
        </>
    )
}

function editBids(props){
    let bidWorkRenew = null
    if(props.auctionId.sellingMethod === "renewables"){
        if(props.auctionId.status === "active_tendering"){
            bidWorkRenew = <CreateBidRenewables />
        }
    }else if(
        props.auctionId.sellingMethod === "renewables-fast" ||
        props.auctionId.sellingMethod === "renewables-fast-manual" ||
        props.auctionId.sellingMethod === "renewables-initial-auction" ||
        props.auctionId.sellingMethod === "renewables-initial-qualification" ||
        props.auctionId.sellingMethod === "renewables-initial-qualification-fast"
    ){
        if(
            props.auctionId.status === null ||
            props.auctionId.status === "active_tendering"
        ){
            if(
                props.auctionId.items !== null ||
                props.auctionId.items > 0
            ){  
                bidWorkRenew = <CreateBidRenewables />
            }
        }
    }
    return (
        <>
            <ViewBids />
            {bidWorkRenew}
        </>
    )
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctionId: state.start.auctionFull,
        langFlag: state.start.langFlag,
        pageNumber: state.start.pageNumber,
        auctionsGetIdNotFound: state.start.auctionsGetIdNotFound,
        bidAward: state.start.bidAward,
        documentCount: state.start.documentCount,
        curentBidLot: state.start.curentBidLot,
        bidCvalification: state.start.bidCvalification,
        auctionsAllBids: state.start.auctionsAllBids,
    }
}

const mapDispatchToProps = {
    changeBidAward,
    changeDocumentArrFiles,
    changeDocumentCountArrFiles,
    changeDocumentBidBlockStatus,
    changeDocumentBidPublicName,
    auctionsBidsGetId,
    changeCreateBidDocType,
    changeBidErrorPopUpStatus,
}

export default connect(mapStateToProps, mapDispatchToProps)(BidLot)