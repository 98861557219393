export function checkProp(obj, prop) {
    if (obj !== null && typeof obj !== 'undefined') {
        if (obj.hasOwnProperty(prop)) {
            if (obj[prop] !== null && typeof obj[prop] !== 'undefined') {
                return true
            }
        }
    }
    return false
}

export function getShorter(source, length = 60) {
        let tmp = source
        if (tmp.length > length) {
            tmp = tmp.substring(0,length)
            return `${tmp}...`
        } else {
            return tmp
        }
}

export function isMongo(data) {
    if (data !== null && data !== undefined){
        if (data.hasOwnProperty('uk_UA') && ( data.uk_UA !== null || data.uk_UA !== undefined )) return data.uk_UA
    }
    return data
}

export function getOptions(data, variant){ //TODO: ПЕРЕДАЛАТЬ НА ПО-НОРМАЛЬНОМУ
    switch(variant){
        case 1: return data.map( (i)=> {
                return {value: i.props.value, name: i.props.children}
            })
        default: return data.props.children.map( (i)=> {
            return {value: i.props.value, name: i.props.children}
        })
    }
}

export function getIdentifierType(x){
    switch(x){
        case "UA-EDR": 
            return "Код ЄДРПОУ"
        case "UA-IPN":
            return "ІПН"
        case "UA-PASSPORT":
            return "Паспорт"
        case "UA-ID-CARD":
            return "ID-карта"
        case "UA-IPN-FOP":
            return "ФОП-ІПН"
        default:
            return ""
      }
}

export function getUrAdress(a) {
    let postal = ''
    let country = ''
    let region = ''
    let street = ''
    let city = ''

    if ((checkProp(a.address, 'postalCode') && a.address.postalCode !== null)) {
        postal = a.address.postalCode
    }
    if (checkProp(a.address, 'locality')) {
        if ((checkProp(a.address.locality, 'uk_UA') && a.address.locality.uk_UA !== null)) {
            city = a.address.locality.uk_UA
        } else {
            city = a.address.locality
        }
    } 
    if (checkProp(a.address, 'region')) {
        if ((checkProp(a.address.region, 'uk_UA') && a.address.region.uk_UA !== null)) {
            region = a.address.region.uk_UA
        } else {
            region = a.address.region
        }
    }
    if (checkProp(a.address, 'countryName')) {
        if ((checkProp(a.address.countryName, 'uk_UA') && a.address.countryName.uk_UA !== null)) {
            country = a.address.countryName.uk_UA
        } else {
            country = a.address.countryName
        }
    }
    if (checkProp(a.address, 'streetAddress')) {
        if ((checkProp(a.address.streetAddress, 'uk_UA') && a.address.streetAddress.uk_UA !== null)) {
            street = a.address.streetAddress.uk_UA
        } else {
            street = a.address.streetAddress
        }
    }
    return(`${postal}, ${country}, ${region}, ${city}, ${street}`)
}

export function getCurrency(x){
    switch(x){
        case "UAH": 
            return "грн"
        case "USD":
            return "дол. США"
        case "EUR":
            return "євро"
        default:
            return ""
      }
}

export function getAddedTax(x) { return  x ? 'з ПДВ' : 'без ПДВ' }

export const numFormat = new Intl.NumberFormat() //numFormat.format(x)