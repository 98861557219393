import React from 'react';
import styles from './secondMenu.module.css';

import { Link } from 'react-router-dom';

import {
    CURRENT_AUCTIONS,
    //ALL_AUCTIONS,
    REGISTRY_OF_OBJECTS_OF_PRIVATIZATION,
    REGISTRY_OF_LOTS_OF_PRIVATIZATION,
    COMPLETION_OF_PRIVATIZATION,
    MAP_OF_OBJECTS_OF_PRIVATIZATION
} from '../../../model/lang/secondMenu.js';

import {connect} from 'react-redux';
import {setAuctionPath} from '../../../redux/actions/support.js';
import {changeSecondMenu} from '../../../redux/actions.js';
import {changeLotsFlag, auctionsSearch, auctionsSearchСount, changeCurentPage, changeStatusAuction} from '../../../redux/actions/auctions/auctions';

const SecondMenu = (props) => {
    let tmp = null;
    if(props.menuOnOff === false){
        tmp = (
            <>
                <Link to={`/search/current`}>
                    <div
                        className={styles.auktionmenublok}
                        onClick={
                            () => {
                                props.changeSecondMenu(false)
                                props.changeCurentPage(0)
                                props.changeStatusAuction('active_tendering')
                                props.changeLotsFlag(false)
                                props.setAuctionPath(`/search/current/`)
                            }
                        }
                    >
                        {CURRENT_AUCTIONS[props.langFlag]}
                    </div>
                </Link>
                {/*<Link to={`/search/all`}>
                    <div
                        className={styles.auktionmenublok_off_inner}
                        onClick={
                            () => {
                                props.changeSecondMenu(true)
                                props.changeCurentPage(0)
                                props.changeStatusAuction(null)
                                props.changeLotsFlag(false)
                                props.setAuctionPath(`/search/all/`)
                            }
                        }
                    >
                        {ALL_AUCTIONS[props.langFlag]}
                    </div>
                    </Link>*/}
            </>
        )
    }else{
        tmp = (
            <>
                <Link to={`/search/current`}>
                    <div
                        className={styles.auktionmenublok_off_inner}
                        onClick={
                            () => {
                                props.changeSecondMenu(false)
                                props.changeCurentPage(0)
                                props.changeStatusAuction('active_tendering')
                                props.changeLotsFlag(false)
                                props.setAuctionPath(`/search/current/`)
                            }
                        }
                    >
                        {CURRENT_AUCTIONS[props.langFlag]}
                    </div>
                </Link>
                {/*<Link to={`/search/all`}>
                    <div
                        className={styles.auktionmenublok}
                        onClick={
                            () => {
                                props.changeSecondMenu(true)
                                props.changeCurentPage(0)
                                props.changeStatusAuction(null)
                                props.changeLotsFlag(false)
                                props.setAuctionPath(`/search/all/`)
                            }
                        }
                    >
                        {ALL_AUCTIONS[props.langFlag]}
                    </div>
                </Link>*/}
            </>
        )
    }
    /*return (
        <div className={styles.aukcionmenu}>
            <div className={styles.aukcontent}>
                {tmp}
                <div className={styles.auktionmenublok_off}>
                    <a href="https://www.dto.com.ua/tenders/assets">{REGISTRY_OF_OBJECTS_OF_PRIVATIZATION[props.langFlag]}</a>
                </div>
                <div className={styles.auktionmenublok_off}>
                    <a href="https://www.dto.com.ua/tenders/lots">{REGISTRY_OF_LOTS_OF_PRIVATIZATION[props.langFlag]}</a>
                </div>
                <div className={styles.auktionmenublok_off}>
                    <a href="https://www.dto.com.ua/tenders/contracts">{COMPLETION_OF_PRIVATIZATION[props.langFlag]}</a>
                </div>
                <div className={styles.auktionmenublok_off}>
                    <a href="https://privatization.gov.ua/mapa-ob-yektiv/">{MAP_OF_OBJECTS_OF_PRIVATIZATION[props.langFlag]}</a>
                </div>

                <div className={styles.auktionmenublok_off}>
                    <a href="https://registry.dto.com.ua/uk/management/app/registry-registryobject/list">ПЕРЕЛІК ОБ'ЄКТІВ ОРЕНДИ</a>
                </div>
            </div>
        </div>
    )*/
    return (
        <div className={styles.categoryWrapMain}>
            <div className={styles.categoryWrap}>
                <a href="https://beta.dto.com.ua/"><div className={styles.activeCategory}>Аукціони</div></a>
                <a href="https://registry.dto.com.ua/asset/assets_list"><div className={styles.category}>Об’єкти приватизації</div></a>
                <a href="https://registry.dto.com.ua/lease/objects_list"><div className={styles.category}>Перелік об’єктів оренди</div></a>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        search: state.start.search,
        langFlag: state.start.langFlag,
        menuOnOff: state.start.menuOnOff,
        searchCount: state.start.searchCount,
    }
}

const mapDispatchToProps = {
    changeSecondMenu,
    changeLotsFlag,
    auctionsSearch,
    auctionsSearchСount,
    changeCurentPage,
    changeStatusAuction,
    setAuctionPath
}

export default connect(mapStateToProps, mapDispatchToProps)(SecondMenu)