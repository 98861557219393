import React from 'react';
import styles from './productOld.module.css';

import {connect} from 'react-redux';

import GreyLineTop from './greyLine/greyLine.js';
import AuctionDataLine from './auctionDataLine/auctionDataLine.js';
import ImageAndButtonLine from './imageAndButtonLine/imageAndButtonLine.js';

const ProductOld = ( props ) => {
    let url = 'https://www.dto.com.ua/tenders/' + props.item.id;
    return (
        <section className = { styles.wrap } >
            <a href={url}>
                <GreyLineTop item={props.item} />
                <AuctionDataLine item={props.item} />
                <ImageAndButtonLine item={props.item} />
            </a>
        </section>
    )
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
    }
}

export default connect(mapStateToProps, null)(ProductOld)
