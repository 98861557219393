import React from 'react';
import styles from './togetherAPI.module.css';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';

import {connect} from 'react-redux';

import {setPopUpBidTogetherAPI} from '../../../../redux/actions/auctions/auctions.js';

import {changeLoader, setUniversalError} from '../../../../redux/actions.js';

import {
    togetherApiPullAucEI,
    togetherApiSaveDataLoad,
    togetherApiSaveValue,

    togetherApiTimerRound,
    togetherApiTimerTime,
    togetherApiTimerTimeLeft,
    auctionBidEndPay,
} from '../../../../redux/togetherApi.js';

import TogetherAPITimer from './togetherAPITimer.js';
import TogetherAPIEIrightElem from './togetherAPIEIrightElem.js';
import TogetherAPIEIleftElem from './togetherAPIEIleftElem.js';
import TogetherAPIBidsHeader from './togetherAPIBidsHeader.js';

import InvalidValue from './popUp/invalidValue/invalidValue.js';
import {modifiDateString} from '../../../../redux/modifiDate'

const TogetherAPI = (props) => {
    let tmp = null;
    tmp = <div>
        {listsMyAuctionsBids(props)}
    </div>
    let tmpPopUpInvalidValue = null
    if(props.togetherApiPage.saveValue !== null){
        tmpPopUpInvalidValue = <InvalidValue />
    }
    if(props.togetherApiPage.saveDataSoket !== null){
        tmp = <div>
            {listsMyAuctionsBids(props)}
        </div>
    }
    return (
        <>
            {tmp}
            {tmpPopUpInvalidValue}
        </>
    )
}

function listsMyAuctionsBids(props){
    let tmp = null;
    let tmpObj  = null;
    let tmpObj2  = null;
    let pullActivTorg = null;
    if(props.togetherApiPage.basePullAucEI !== null){
        if(props.togetherApiPage.basePullAucEI.length > 0){
            tmp = props.togetherApiPage.basePullAucEI
            .filter(
                (i) => {return i.auction.sellingMethod !== "renewables"}
            )
            .filter(
                (i) => {return i.auction.sellingMethod !== "renewables-fast"}
            )
            .filter(
                (i) => {return i.auction.sellingMethod !== "renewables-fast-manual"}
            )
            .filter(
                (i) => {return i.auction.sellingMethod !== "renewables-initial-auction"}
            )
            .filter(
                (i) => {return i.auction.sellingMethod !== "renewables-initial-qualification"}
            )
            .filter(
                (i) => {return i.auction.sellingMethod !== "renewables-initial-qualification-fast"}
            )
            .filter(
                (i) => {return i.auction.sellingMethod !== "subsoil-english"}
            )
            .filter(
                (i) => {return i.auction.sellingMethod !== "subsoil-english-fast"}
            )
            .filter(
                (i) => {return i.auction.sellingMethod !== "subsoil-english-fast-manual"}
            )
            .filter(
                (i) => {return i.auction.sellingMethod !== "subsoil-english-initial-auction"}
            )
            .filter(
                (i) => {return i.auction.sellingMethod !== "subsoil-english-initial-qualification"}
            )
            .filter(
                (i) => {return i.auction.sellingMethod !== "railwayCargo-english"}
            )
            .filter(
                (i) => {return i.auction.sellingMethod !== "railwayCargo-english-fast"}
            )
            .filter(
                (i) => {return i.auction.sellingMethod !== "railwayCargo-english-fast-manual"}
            )
            .filter(
                (i) => {return i.auction.sellingMethod !== "railwayCargo-english-initial-auction"}
            )
            .filter(
                (i) => {return i.auction.sellingMethod !== "railwayCargo-english-initial-qualification"}
            )
            .filter(
                (i) => {return i.auction.sellingMethod !== "railwayCargo-dutch-initial-auction-manual"}
            )
            .filter(
                //(i) => {return (i.auction.status === "active_auction" || i.auction.status === "active_tendering")}
                (i) => {return i.auction.status === "active_auction"}
            )
            .filter(
                (i) => {return i.auction.hasOwnProperty('bids') === true}
            )
            .filter(
                (i) => {return i.auction.bids !== null}
            )
            .filter(
                (i) => {
                    let tmpI = null
                    if(i.auction.bids.length > 0){
                        tmpI = i.auction.bids.filter(
                            (cc) => {
                                return cc.createdByCurrentUser === true
                            }
                        )
                    }
                    if(tmpI !== null){
                        if(tmpI.length === 0){
                            tmpI = null
                        }
                    }
                    return tmpI !== null
                }
            )
            .map(
                (i, count) => {
                    tmpObj = { [`${i.auction.auctionId}`]: {
                            acc_token: i.auction.bids[0].ownerToken,
                            bidder_id: i.auction.bids[0].bidIdentifier
                        }
                    }
                    tmpObj2 = { 
                        auctionId: i.auction.auctionId,
                        acc_token: i.auction.bids[0].ownerToken,
                        bidder_id: i.auction.bids[0].bidIdentifier
                    }
                    //Початок аукцыону
                    let startDataAuction = null
                    if(i.auction.auctionPeriods !== null){
                        for(let kk = 0; kk < i.auction.auctionPeriods.length; kk++){
                            if(i.auction.auctionPeriods[kk].status === 'auctionPeriod') startDataAuction = i.auction.auctionPeriods[kk].startDate
                        }
                    }

                    let viewStartAuc = null
                    if(props.togetherApiPage.saveDataSoket.length > 0){
                        let tmpAucCount = null
                        for(let uyu = 0; uyu < props.togetherApiPage.saveDataSoket.length; uyu++){
                            if(props.togetherApiPage.saveDataSoket[uyu].aucion[`${props.togetherApiPage.saveDataSoket[uyu].id}`]._id === i.auction.auctionId){
                                tmpAucCount = props.togetherApiPage.saveDataSoket[uyu]
                            }
                        }
                        if(tmpAucCount !== null){
                            viewStartAuc = viewTorgi(props, i, tmpAucCount, count)
                        }else{
                            viewStartAuc = <div className={styles.onlineAuctionElem}>
                                <div className={styles.infoBlock}>
                                    <div className={styles.infoBlock_title}>Детальніше про аукціон:</div>
                                    <div>Id аукціону: {i.auction.auctionId}</div>
                                    <div>Організатор: {i.auction.sellingEntity.name}</div>
                                    <div>Статус аукціону: {i.auction.status}</div>
                                    <div>Початок аукціону: {modifiDateString(startDataAuction, 3, false)}</div>
                                    <div>
                                        Посилання на сторінку лоту: 
                                        <a href={window.location.origin + '/tenders/' + i.auction.id}  title="Посилання на сторінку аукціону">
                                            <IconButton aria-label="edit">
                                                <LinkIcon/>
                                            </IconButton>
                                        </a>
                                    </div>
                                    <div>
                                        Посилання на Прозоро: 
                                        <a href={i.auction.auctionUrl} target='_blank' title="Посилання на Прозоро">
                                            <IconButton aria-label="edit">
                                                <LinkIcon/>
                                            </IconButton>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        }
                    }else{
                        viewStartAuc = <div className={styles.onlineAuctionElem}>
                            <div className={styles.infoBlock}>
                                <div className={styles.infoBlock_title}>Детальніше про аукціон:</div>
                                <div>Id аукціону: {i.auction.auctionId}</div>
                                <div>Організатор: {i.auction.sellingEntity.name}</div>
                                <div>Статус аукціону: {i.auction.status}</div>
                                <div>Початок аукціону: {modifiDateString(startDataAuction, 3, false)}</div>
                                <div>
                                    Посилання на аукціон: 
                                    <a href={window.location.origin + '/tenders/' + i.auction.id}  title="Посилання на аукціон">
                                        <IconButton aria-label="edit">
                                            <LinkIcon/>
                                        </IconButton>
                                    </a>
                                </div>
                                <div>
                                    Посилання на Прозоро: 
                                    <a href={i.auction.auctionUrl} target='_blank' title="Посилання на Прозоро">
                                        <IconButton aria-label="edit">
                                            <LinkIcon/>
                                        </IconButton>
                                    </a>
                                </div>
                            </div>
                        </div>
                    }
                    return (
                        <>
                            <div className={styles.auctionsLineElem}>
                                {viewStartAuc}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={styles.buttonStart}
                                    onClick={
                                        () => {
                                            let bifTest = {}
                                            for(let i = 0; i < props.togetherApiPage.basePullAucEI.length; i++){
                                                if(props.togetherApiPage.basePullAucEI[i].auction.status === 'active_auction'){
                                                    bifTest[`${props.togetherApiPage.basePullAucEI[i].auction.auctionId}`] = {
                                                        acc_token: props.togetherApiPage.basePullAucEI[i].auction.bids[0].ownerToken,
                                                        bidder_id: props.togetherApiPage.basePullAucEI[i].auction.bids[0].bidIdentifier
                                                    }
                                                }
                                            }
                                            props.togetherApiPullAucEI(i);
                                            //postGetRoomId(props, i.tmpObj, i.tmpObj2)
                                            postGetRoomId(props, bifTest, i.tmpObj2)
                                        }
                                    }
                                >
                                    Приєднатися до торгів
                                </Button>
                            </div>
                        </>
                    )
                }
            )
        }
    }
    return (
        <div className={styles.allActiveAuctions}>
            {tmp}
        </div>
    )
}

function viewTorgi(props, auc, socetData = null, count){
    let pullActivTorg = null
    //if(props.togetherApiPage.saveDataSoket !== null){
    if(socetData !== null){
        let tmpStartBid = null
        let tmpFirstBid = null
        let tmpSecondBid = null
        let tmpThreeBid = null
        let tmpEndBid = null
        tmpStartBid = viewBidersStart1(props, socetData.aucion[`${socetData.id}`].initialBids, auc, socetData.aucion[`${socetData.id}`])
        if(socetData.aucion[`${socetData.id}`].hasOwnProperty('bidsHistory') === true){
            if(socetData.aucion[`${socetData.id}`].bidsHistory.length > 0){
                tmpFirstBid = viewBidersStart(props, socetData.aucion[`${socetData.id}`].bidsHistory[0], auc, socetData.aucion[`${socetData.id}`])
            }
            if(socetData.aucion[`${socetData.id}`].bidsHistory.length >= 2){
                tmpSecondBid = viewBidersStart(props, socetData.aucion[`${socetData.id}`].bidsHistory[1], auc, socetData.aucion[`${socetData.id}`])
            }
            if(socetData.aucion[`${socetData.id}`].length >= 3){
                tmpThreeBid = viewBidersStart(props, socetData.aucion[`${socetData.id}`].bidsHistory[2], auc, socetData.aucion[`${socetData.id}`])
            }
            if(socetData.aucion[`${socetData.id}`].length >= 4){
                tmpEndBid = viewBidersStart(props, socetData.aucion[`${socetData.id}`].bidsHistory[3], auc, socetData.aucion[`${socetData.id}`])
            }
        }
        let raundStart = null
        let raundFirst = null
        let raundTwo = null
        let raunfThree = null
        let raunfEnd = null
        if(socetData.aucion[`${socetData.id}`].public_meta !== null){
            //if(socetData.aucion[`${socetData.id}`].public_meta.timeline.length >= 1){
                raundStart = modifiDateString(socetData.aucion[`${socetData.id}`].public_meta.timeline[0].start, 3, false)
            //}else if(socetData.aucion[`${socetData.id}`].public_meta.timeline.length >= 2){
                raundFirst = modifiDateString(socetData.aucion[`${socetData.id}`].public_meta.timeline[1].start, 3, false) + ' - ' + modifiDateString(socetData.aucion[`${socetData.id}`].public_meta.timeline[1].end, 3, false)
            //}else if(socetData.aucion[`${socetData.id}`].public_meta.timeline.length >= 3){
                raundTwo = modifiDateString(socetData.aucion[`${socetData.id}`].public_meta.timeline[2].start, 3, false) + ' - ' + modifiDateString(socetData.aucion[`${socetData.id}`].public_meta.timeline[2].end, 3, false)
            //}else if(socetData.aucion[`${socetData.id}`].public_meta.timeline.length >= 4){
                raunfThree = modifiDateString(socetData.aucion[`${socetData.id}`].public_meta.timeline[3].start, 3, false) + ' - ' + modifiDateString(socetData.aucion[`${socetData.id}`].public_meta.timeline[3].end, 3, false)
            //}else if(socetData.aucion[`${socetData.id}`].public_meta.timeline.length >= 5){
                raunfEnd = modifiDateString(socetData.aucion[`${socetData.id}`].public_meta.timeline[4].end, 3, false)
            //}
        }
        pullActivTorg = (
            <>
                    <div className={styles.onlineAuctionElem}>
                        <div>Аукціон: {socetData.aucion[`${socetData.id}`]._id}</div>
                        <div>Назва: {auc.auction.title}</div>
                        <div>Учасників торгів: {socetData.aucion[`${socetData.id}`].bidCount}</div>
                        <div>
                            <div>Початкові пропозиції:</div>
                            {tmpStartBid}
                        </div>
                        <div>
                            <div>Старт аукціону: {raundStart}</div>
                            {/*tmpStartBid*/}
                        </div>
                        <div>
                            <div>Раунд 1: {raundFirst}</div>
                            {tmpFirstBid}
                        </div>
                        <div>
                            <div>Раунд 2: {raundTwo}</div>
                            {tmpSecondBid}
                        </div>
                        <div>
                            <div>Раунд 3: {raunfThree}</div>
                            {tmpThreeBid}
                        </div>
                        {viewEndAuc(tmpThreeBid)}
                        <div>
                            <div>Оголошення результатів: {raunfEnd}</div>
                            {raundTwo}
                        </div>
                        
                        {/*<div>Найвища пропозиція: {maxBid(socetData.aucion[`${socetData.id}`].fix(2))} грн.</div>*/}
                        <div>Найвища пропозиція: {maxBid(socetData.aucion[`${socetData.id}`])} грн.</div>
                        {/*<div>Ваша остання пропозиція: {lostBid(props, socetData.aucion[`${socetData.id}`]._id.fix(2))}</div>*/}
                        <div>Ваша остання пропозиція: {lostBid(props, socetData.aucion[`${socetData.id}`]._id)}</div>
                        <div>
                            {cheiHod(socetData.aucion[`${socetData.id}`], auc.tmpObj2)}
                        </div>
                        <div className={styles.buttonsBlock}>
                            {viewBlovUpdateBid(props, auc, socetData.aucion[`${socetData.id}`], socetData.id, count, socetData)}
                        </div>
                        <div className={styles.infoBlock1}>
                            <div className={styles.infoBlock_title}>Детальніше про аукціон:</div>
                            <div>Номер лоту: {auc.auction.lotIdentifier}</div>
                            <div>Організатор: {auc.auction.sellingEntity.name}</div>
                            <div>Початкова ціна: {auc.auction.value.amount} грн</div>
                            <div>Крок аукціону: {auc.auction.minimalStep.amount} грн</div>
                            <div>Опис: {auc.auction.description}</div>
                            <div>АКТИВИ</div>
                            <div>Кількість: {auc.auction.items[0].quantity} {auc.auction.items[0].unit.symbol}</div>
                            <div>Адреса: {auc.auction.items[0].address.streetAddress}‚ {auc.auction.items[0].address.locality}‚ {auc.auction.items[0].address.countryName}‚ {auc.auction.items[0].address.postalCode}</div>
                            <div>
                                Посилання на аукціон: 
                                <a href={window.location.origin + '/tenders/' + auc.auction.id}  title="Посилання на аукціон">
                                    <IconButton aria-label="edit">
                                        <LinkIcon/>
                                    </IconButton>
                                </a>
                            </div>
                            <div>
                                Посилання на Прозоро: 
                                <a href={auc.auction.auctionUrl} target='_blank' title="Посилання на Прозоро">
                                    <IconButton aria-label="edit">
                                        <LinkIcon/>
                                    </IconButton>
                                </a>
                            </div>
                        </div>
                    </div>
            </>
        )
    }
    return pullActivTorg
}

function viewEndAuc(data){
    if(data !== null){
        return <div>Аукціон завершено</div>
    }
    return null
}

function lostBid(props, aucionId){
    //Надо доделать выборку последней ставки если аук уже идет
    let flag = null
    if(props.togetherApiPage.arrayAuctionsEndPay !== null){
        for(let mm = 0; mm < props.togetherApiPage.arrayAuctionsEndPay.length; mm++){
            if(String(aucionId) === props.togetherApiPage.arrayAuctionsEndPay[mm].id) flag = mm
        }
        if(flag !== null){
            return props.togetherApiPage.arrayAuctionsEndPay[flag].value
        }
        return null
    }
    return null
}

function cheiHod(soket, tmpObj2){
    if(soket.public_meta.hasOwnProperty('current_bid_id') === true){
        if(soket.public_meta.current_bid_id === tmpObj2.bidder_id){
            return <div className={styles.hodStavki}>Зробіть Вашу пропозицію</div>
        }
        return <div>Хід інших учасників</div>
    }
    return ''
}

function viewBlovUpdateBid(props, auc, soket, socetDataId, count, socetData){
    let tmpMin = null
    let tmpCurent = null
    for(let i = props.togetherApiPage.saveDataSoket.length - 1; i > -1; i--){
        if(props.togetherApiPage.saveDataSoket[i].id === socetDataId && props.togetherApiPage.saveDataSoket[i].aucion[`${socetDataId}`]._id === soket._id){
            tmpCurent = props.togetherApiPage.saveDataSoket[i].aucion[`${socetDataId}`]
            break
        }
    }
    let tmpIdUsers = null
    for(let i = 0; i < props.togetherApiPage.basePullAucEI.length; i++){
        if(props.togetherApiPage.basePullAucEI[i].tmpObj2.auctionId === soket._id){
            tmpIdUsers = props.togetherApiPage.basePullAucEI[i].tmpObj2.bidder_id
            break
        }
    }

    if(tmpCurent.public_meta.hasOwnProperty('round_counter') === true){
        for(let i = 0; i < props.togetherApiPage.saveDataSoket.length; i++){
            if(props.togetherApiPage.saveDataSoket[i].id === socetDataId && props.togetherApiPage.saveDataSoket[i].aucion[`${socetDataId}`]._id === soket._id){
                if(props.togetherApiPage.saveDataSoket[i].aucion[`${socetDataId}`].public_meta.round_counter === tmpCurent.public_meta.round_counter){
                    tmpCurent = props.togetherApiPage.saveDataSoket[i].aucion[`${socetDataId}`]
                    break
                }
            }
        }
    }else{
    }
    
    tmpMin = minBidsMaxTwenti(props, tmpIdUsers, tmpCurent)

    if(soket.public_meta.hasOwnProperty('current_bid_id') === true){
        if(soket.public_meta.current_bid_id === auc.tmpObj2.bidder_id){
            let tmpM = maxBid(soket)
            let textTmp = `Мінімальна пропозиція: ${tmpMin.toFixed(2)}`
            return (
                <>
                    <div>
                        <TextField
                            id={soket._id}
                            label="Пропозиція"
                            onChange={(event) => {
                                /*--let tmpMany = document.getElementById(soket._id)
                                if(Number(tmpMany) < viewMinShag(tmpM, auc.auction.minimalStep.amount)){*/
                                    //props.togetherApiSaveValue(Number(event.target.value))
                                /*}*/
                            }}
                            variant="outlined"
                            helperText={textTmp}
                        />
                    </div>
                    <div>
                        {stavka(props, auc.tmpObj2, soket._id, auc, tmpM, soket, socetDataId)}
                        <Button
                            variant="contained"
                            color="primary"
                            className={styles.buttonActiveAuc}
                            onClick={
                                () => {
                                    postBidCancell(props, auc.tmpObj2.auctionId, auc.tmpObj2.acc_token, auc.tmpObj2.bidder_id)
                                }
                            }
                        >
                            Відміна пропозиція
                        </Button>
                    </div>
                </>
            )
        }else{
            let tmpM = maxBid(soket)
            let textTmp = `Мінімальна пропозиція: ${tmpMin.toFixed(2)}`
            return (
                <>
                    <div>
                        <TextField
                            disabled='disabled'
                            id='stavkaEI'
                            label="Пропозиція"
                            variant="outlined"
                            helperText={textTmp}
                        />
                    </div>
                    <div>
                        <Button
                            disabled='disabled'
                            variant="contained"
                            color="disabled"
                            className={styles.buttonActiveAuc}
                        >
                            Пропозиція
                        </Button>
                        <Button
                            disabled='disabled'
                            variant="contained"
                            color="disabled"
                            className={styles.buttonActiveAuc}
                        >
                            Відміна пропозиції
                        </Button>
                    </div>
                </>
            )
        }
    }else{
        let tmpM = maxBid(soket)
        let textTmp = `Мінімальна пропозиція: ${tmpMin.toFixed(2)}`
        return (
            <>
                <div>
                    <TextField
                        disabled='disabled'
                        id='stavkaEI'
                        label="Пропозиція"
                        variant="outlined"
                        helperText={textTmp}
                    />
                </div>
                <div>
                    <Button
                        disabled='disabled'
                        variant="contained"
                        color="disabled"
                        className={styles.buttonActiveAuc}
                    >
                        Пропозиція
                    </Button>
                    <Button
                        disabled='disabled'
                        variant="contained"
                        color="disabled"
                        className={styles.buttonActiveAuc}
                    >
                        Відміна пропозиції
                    </Button>
                </div>
            </>
        )
    }
}

function minBidsMaxTwenti(props, tmpIdUsers, tmpCurent){
    let tmpMin = null
    if(props.togetherApiPage !== null){
        if(props.togetherApiPage.saveDataSoket !== null){
            if(props.togetherApiPage.saveDataSoket.length === 1){
                if(tmpCurent.hasOwnProperty('initialBids') === true){
                    if(tmpCurent.initialBids[`${tmpIdUsers}`] === undefined || tmpCurent.initialBids[`${tmpIdUsers}`] === 'undefined'){
                        tmpMin = tmpCurent.valueAmount + props.togetherApiPage.pullAucEI.auction.minimalStep.amount
                    }else{
                        tmpMin = tmpCurent.initialBids[`${tmpIdUsers}`].amount + props.togetherApiPage.pullAucEI.auction.minimalStep.amount
                    }
                }else{
                    tmpMin = tmpCurent.valueAmount + props.togetherApiPage.pullAucEI.auction.minimalStep.amount
                }
            }else{
                if(tmpCurent.hasOwnProperty('bids') === true){
                    if(tmpCurent.bids[`${tmpIdUsers}`] === undefined || tmpCurent.bids[`${tmpIdUsers}`] === 'undefined'){
                        if(tmpCurent.hasOwnProperty('initialBids') === true){
                            if(tmpCurent.initialBids[`${tmpIdUsers}`] === undefined || tmpCurent.initialBids[`${tmpIdUsers}`] === 'undefined'){
                                tmpMin = tmpCurent.valueAmount + props.togetherApiPage.pullAucEI.auction.minimalStep.amount
                            }else{
                                tmpMin = tmpCurent.initialBids[`${tmpIdUsers}`].amount + props.togetherApiPage.pullAucEI.auction.minimalStep.amount
                            }
                        }else{
                            tmpMin = tmpCurent.valueAmount + props.togetherApiPage.pullAucEI.auction.minimalStep.amount
                        }
                    }else{
                        tmpMin = tmpCurent.bids[`${tmpIdUsers}`].amount + props.togetherApiPage.pullAucEI.auction.minimalStep.amount
                    }
                }else if(tmpCurent.hasOwnProperty('initialBids') === true){
                    if(tmpCurent.initialBids[`${tmpIdUsers}`] === undefined || tmpCurent.initialBids[`${tmpIdUsers}`] === 'undefined'){
                        tmpMin = tmpCurent.valueAmount + props.togetherApiPage.pullAucEI.auction.minimalStep.amount
                    }else{
                        tmpMin = tmpCurent.initialBids[`${tmpIdUsers}`].amount + props.togetherApiPage.pullAucEI.auction.minimalStep.amount
                    }
                }else{
                    tmpMin = tmpCurent.valueAmount + props.togetherApiPage.pullAucEI.auction.minimalStep.amount
                }
            }
        }
    }
    return tmpMin
}


function viewBiderStartMinimal(props, data, auc, soket){
    let tmp = [];
    let count = 0;
    let tmps = null
    for (let key in data) {
        for (let keys in soket.bidsInfo) {
            if(soket.bidsInfo[keys].id === soket.initialBids[key].id){
                tmps = soket.bidsInfo[keys].bidders[0].identifier.legalName.uk_UA
            }
        }
        tmp[count] = (
            <div className={styles.bidersLine}>
                <div className={styles.bidersLine_count}>{count+1} </div>
                {/*<div>Учасник {count+1} {data[key].amount} грн.</div>*/}
                <div className={styles.bidersLine_buyer}>{tmps} <span>{data[key].amount}</span> грн.</div>
            </div>
        )
        count = count + 1
    }
    return tmp
}

function maxBid(soket){
    let tmp = 0
    if(soket.hasOwnProperty('bidsHistory') === true){
        if(soket.bidsHistory.length > 0){
            for(let t = 0; t < soket.bidsHistory[soket.bidsHistory.length - 1].length; t++){
                if(tmp < soket.bidsHistory[soket.bidsHistory.length - 1][t].amount) {
                    tmp = soket.bidsHistory[soket.bidsHistory.length - 1][t].amount
                }
            }
        }else{
            for (let key in soket.initialBids) {
                if(tmp < soket.initialBids[key].amount) tmp = soket.initialBids[key].amount
            }    
        }
    }else{
        for (let key in soket.initialBids) {
            if(tmp < soket.initialBids[key].amount) tmp = soket.initialBids[key].amount
        }
    }
    return tmp
}

function stavka(props, tmpObj2, data, auc, tmpM, soket, socetDataId){
    //let tmp = tmpM + props.togetherApiPage.pullAucEI.auction.minimalStep.amount
    let tmp = null
    let tmpMinimal = null
    let tmpCurent = null
    for(let i = props.togetherApiPage.saveDataSoket.length - 1; i > -1; i--){
        if(props.togetherApiPage.saveDataSoket[i].id === socetDataId && props.togetherApiPage.saveDataSoket[i].aucion[`${socetDataId}`]._id === soket._id){
            tmpCurent = props.togetherApiPage.saveDataSoket[i].aucion[`${socetDataId}`]
            break
        }
    }
    let tmpIdUsers = null
    for(let i = 0; i < props.togetherApiPage.basePullAucEI.length; i++){
        if(props.togetherApiPage.basePullAucEI[i].tmpObj2.auctionId === soket._id){
            tmpIdUsers = props.togetherApiPage.basePullAucEI[i].tmpObj2.bidder_id
            break
        }
    }
    if(tmpCurent.public_meta.hasOwnProperty('round_counter') === true){
        for(let i = 0; i < props.togetherApiPage.saveDataSoket.length; i++){
            if(props.togetherApiPage.saveDataSoket[i].id === socetDataId && props.togetherApiPage.saveDataSoket[i].aucion[`${socetDataId}`]._id === soket._id){
                if(props.togetherApiPage.saveDataSoket[i].aucion[`${socetDataId}`].public_meta.round_counter === tmpCurent.public_meta.round_counter){
                    tmpCurent = props.togetherApiPage.saveDataSoket[i].aucion[`${socetDataId}`]
                    break
                }
            }
        }
    }else{
    }

    if(props.togetherApiPage !== null){
        if(props.togetherApiPage.saveDataSoket !== null){
            if(props.togetherApiPage.saveDataSoket.length === 1){
                if(tmpCurent.hasOwnProperty('initialBids') === true){
                    if(tmpCurent.initialBids[`${tmpIdUsers}`] === undefined || tmpCurent.initialBids[`${tmpIdUsers}`] === 'undefined'){
                        tmp = tmpCurent.valueAmount
                        tmpMinimal = tmpCurent.valueAmount + props.togetherApiPage.pullAucEI.auction.minimalStep.amount
                    }else{
                        tmp = tmpCurent.initialBids[`${tmpIdUsers}`].amount
                        tmpMinimal = tmpCurent.initialBids[`${tmpIdUsers}`].amount + props.togetherApiPage.pullAucEI.auction.minimalStep.amount
                    }
                }else{
                    tmp = tmpCurent.valueAmount
                    tmpMinimal = tmpCurent.valueAmount + props.togetherApiPage.pullAucEI.auction.minimalStep.amount
                }
            }else{
                if(tmpCurent.hasOwnProperty('bids') === true){
                    if(tmpCurent.bids[`${tmpIdUsers}`] === undefined || tmpCurent.bids[`${tmpIdUsers}`] === 'undefined'){
                        if(tmpCurent.hasOwnProperty('initialBids') === true){
                            if(tmpCurent.initialBids[`${tmpIdUsers}`] === undefined || tmpCurent.initialBids[`${tmpIdUsers}`] === 'undefined'){
                                tmp = tmpCurent.valueAmount
                                tmpMinimal = tmpCurent.valueAmount + props.togetherApiPage.pullAucEI.auction.minimalStep.amount
                            }else{
                                tmp = tmpCurent.initialBids[`${tmpIdUsers}`].amount
                                tmpMinimal = tmpCurent.initialBids[`${tmpIdUsers}`].amount + props.togetherApiPage.pullAucEI.auction.minimalStep.amount
                            }
                        }else{
                            tmp = tmpCurent.valueAmount
                            tmpMinimal = tmpCurent.valueAmount + props.togetherApiPage.pullAucEI.auction.minimalStep.amount
                        }
                    }else{
                        tmp = tmpCurent.bids[`${tmpIdUsers}`].amount
                        tmpMinimal = tmpCurent.bids[`${tmpIdUsers}`].amount + props.togetherApiPage.pullAucEI.auction.minimalStep.amount
                    }
                }else if(tmpCurent.hasOwnProperty('initialBids') === true){
                    if(tmpCurent.initialBids[`${tmpIdUsers}`] === undefined || tmpCurent.initialBids[`${tmpIdUsers}`] === 'undefined'){
                        tmp = tmpCurent.valueAmount
                        tmpMinimal = tmpCurent.valueAmount + props.togetherApiPage.pullAucEI.auction.minimalStep.amount
                    }else{
                        tmp = tmpCurent.initialBids[`${tmpIdUsers}`].amount
                        tmpMinimal = tmpCurent.initialBids[`${tmpIdUsers}`].amount + props.togetherApiPage.pullAucEI.auction.minimalStep.amount
                    }
                }else{
                    tmp = tmpCurent.valueAmount
                    tmpMinimal = tmpCurent.valueAmount + props.togetherApiPage.pullAucEI.auction.minimalStep.amount
                }
            }
        }
    }
    return <Button
        variant="contained"
        color="primary"
        className={styles.buttonActiveAuc}
        onClick={
            () => {
                let tmpMany = document.getElementById(data)
                if(Number(tmpMany.value) < tmpMinimal){
                    props.togetherApiSaveValue({stavka: Number(tmpMany.value), type: 1})
                }else{
                    //
                    //Тут еще просто пропозиція
                    //let tmpNormValue = Number(tmpMany.value) / tmp
                    let tmpNormValue = Number(tmpMany.value) / tmp//maxBid(soket)
                    if(tmpNormValue >= 10){
                        props.togetherApiSaveValue(
                            {stavka: {
                                tmpMany: Number(tmpMany.value),
                                tmpObj: tmpObj2.auctionId,
                                tmpObj2: tmpObj2.acc_token,
                                tmpObj3: tmpObj2.bidder_id,
                                data: data},
                            type: 2}
                        )
                    }else{
                        postBidUpdate(props, tmpObj2.auctionId, tmpObj2.acc_token, tmpObj2.bidder_id, data)
                        let tmpArrBid = []
                        if(props.togetherApiPage.arrayAuctionsEndPay === null){
                            tmpArrBid.push({id: data, value: Number(tmpMany.value)})
                        }else{
                            tmpArrBid = props.togetherApiPage.arrayAuctionsEndPay
                            let flag = null
                            for(let mm = 0; mm < tmpArrBid.length; mm++){
                                if(tmpArrBid[mm].id === data) flag = mm
                            }
                            if(flag !== null){
                                tmpArrBid[flag] = {id: data, value: Number(tmpMany.value)}
                            }else{
                                tmpArrBid.push({id: data, value: Number(tmpMany.value)})
                            }
                        }
                        props.auctionBidEndPay(tmpArrBid)
                    }
                }
            }
        }
    >
        Пропозиція
    </Button>
}

function viewMinShag(data1, data2){
    return (
        <>
            Мінімальна сума ставки: {data1 + data2}
        </>
    )
}

function viewBidersStart(props, data, auc, soket){
    let tmp = null;
    let tmps = null
    tmp = data.map(
        (e, count) => {
            for (let keys in soket.bidsInfo) {
                if(soket.bidsInfo[keys].id === e.id){
                    tmps = soket.bidsInfo[keys].bidders[0].identifier.legalName.uk_UA
                }
            }
            return (
                <div className={styles.bidersLine}>
                    <div className={styles.bidersLine_count}>{count + 1} </div>
                    {/*<div>Учасник {count + 1} {e.amount} грн.</div>*/}
                    <div className={styles.bidersLine_buyer}>{tmps} <span>{e.amount}</span> грн.</div>
                </div>
            )
        }
    )
    return tmp
}

function viewBidersStart1(props, data, auc, soket){
    let tmp = [];
    let count = 0;
    let tmps = null
    for (let key in data) {
        for (let keys in soket.bidsInfo) {
            if(soket.bidsInfo[keys].id === soket.initialBids[key].id){
                tmps = soket.bidsInfo[keys].bidders[0].identifier.legalName.uk_UA
            }
        }
        tmp[count] = (
            <div className={styles.bidersLine}>
                <div className={styles.bidersLine_count}>{count+1} </div>
                {/*<div>Учасник {count+1} {data[key].amount} грн.</div>*/}
                <div className={styles.bidersLine_buyer}>{tmps} <span>{data[key].amount}</span> грн.</div>
            </div>
        )
        count = count + 1
    }
    return tmp
}

function viewTextStatusUkr(status){
    if(status === 'active_rectification'){
        return 'Редагування доступне'
    }else if(status === 'active_tendering'){
        return 'Період подання пропозицій'
    }else if(status === 'active_auction'){
        return 'Аукціон'
    }else if(status === 'active_qualification'){
        return 'Кваліфікація'
    }else if(status === 'active_awarded'){
        return 'Очікується підписання договору'
    }else if(status === 'cancelled'){
        return 'Аукціон скасовано'
    }else if(status === 'unsuccessful'){
        return 'Торги не відбулися'
    }else if(status === 'complete'){
        return 'Аукціон завершено'
    }else if(status === null){
        return 'Чернетка'
    }else{
        return status
    }
}

async function postGetRoomId(props, tmpObj, tmpObj2){
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/room`
    const response1 = await fetch(url, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        },
        /*body: JSON.stringify({
            "TIE001-UA-20201006-52973": {  //Не процедуру айди а аукцион айди
                "bidder_id": "9b08098eaee54e6fa7cf5049d8630211",
                "acc_token": "a9476ee6-39d3-4f69-96e9-438a3ac6fa37"
            }
        })*/
        

        body: JSON.stringify(tmpObj)
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
    }else{
        const json1 = await response1.json()
        if(json1.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка створення кімнати торгів', message: JSON.stringify(json1)})
	        props.setUniversalError(tmp)
        }else{
            for (let key in tmpObj){
                createSocet(props, json1.room_id, tmpObj[key].bidder_id)
            }
            //createSocet(props, json1.room_id, tmpObj2.bidder_id)
        }
    }
}

/**
 * Функция для получения данных о торгах через сокет
 * @param {*} props 
 * @param {*} roomId Id комнаты
 * @param {*} bidder_id Id покупателя
 */
function createSocet(props, roomId, bidder_id){
    //let ws = new WebSocket(`wss://auction-staging.prozorro.sale/api/auctions/rooms/${roomId}/feed?bidder_id=${bidder_id}`);
    let ws = new WebSocket(`${process.env.REACT_APP_ENDPOINT_PROZORO_SOCKET}/api/auctions/rooms/${roomId}/feed?bidder_id=${bidder_id}`);
    //endpointProzoro
    /*ws.onopen = function (event){
        'Соединение уставновлено'
    }*/

    ws.onmessage = ({data}) => {
        let tmp = {}
        tmp[`${bidder_id}`] = JSON.parse(data)
        props.togetherApiSaveDataLoad(tmp)
    }
    // отправляем сообщение 
    //ws.onopen = () => ws.send('Text');
}

function startTimeOut(props, data){
    if(data.public_meta.hasOwnProperty('round_counter') === true){
        props.togetherApiTimerRound(data.public_meta.round_counter)
        props.togetherApiTimerTimeLeft(data.public_meta.bid_round_duration)
        let timerId = setTimeout(function tick() {
            //alert('tick');
            if(props.togetherApiPage.timer.timeLeft !== null){
                let tmp = props.togetherApiPage.timer.timeLeft - 1
                props.togetherApiTimerTimeLeft(tmp)
            }else{
                let tmp = data.public_meta.bid_round_duration - 1
                props.togetherApiTimerTimeLeft(tmp)
            }
            timerId = setTimeout(tick, 1000);
        }, 1000);
    }
}

function startTimer(props, data){
    if(data.public_meta.hasOwnProperty('round_counter') === true){
        props.togetherApiTimerRound(data.public_meta.round_counter)
        props.togetherApiTimerTimeLeft(data.public_meta.bid_round_duration)
        let tmp = setInterval(
            () => {
                let tmpTime = 0
                if(props.togetherApiPage.timer.timeLeft > 0){
                    tmpTime = props.togetherApiPage.timer.timeLeft - 5
                    props.togetherApiTimerTimeLeft(tmpTime)
                }else{
                    clearInterval(props.togetherApiPage.timer.time)                
                }
            }, 5000
        )
        props.togetherApiTimerTime(tmp)
    }
}


async function postBidUpdate(props, auction_id, acc_token, bidder_id, data){
    let tmpMany = document.getElementById(data)
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/room/auctions/${auction_id}/bid?acc_token=${acc_token}&bidder_id=${bidder_id}`
    const response1 = await fetch(url, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            /*"bid": Number(props.togetherApiPage.saveValue)*/
            "bid": Number(tmpMany.value)
        })
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
    }else{
        const json1 = await response1.json()
        if(json1.hasOwnProperty('message') === true){
            let text = `Ставку оновлено на ${tmpMany.value}`
            //let tmp = JSON.stringify({title: 'Апдейт ставки', message: JSON.stringify(json1)})
            let tmp = JSON.stringify({title: 'Оновлення ставки', message: text})
            props.setUniversalError(tmp)
            props.togetherApiSaveValue(null)
            tmpMany.value = ''
        }else{
            props.togetherApiSaveValue(null)
            tmpMany.value = ''
            //Тут надо подключать функцию с сокетом
            //props.togetherApiActiveAuctions(json1)
        }
    }
}

async function postBidCancell(props, auction_id, acc_token, bidder_id){
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/room/auctions/${auction_id}/bid/cancel?acc_token=${acc_token}&bidder_id=${bidder_id}`
    const response1 = await fetch(url, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        },
        //Или ненадо или грузить актуальніе данніе
        body: JSON.stringify({
            [`${auction_id}`]: {  //Не процедуру айди а аукцион айди
                "bidder_id": bidder_id,
                "acc_token": acc_token
            }
        })
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
    }else{
        const json1 = await response1.json()
        if(json1.hasOwnProperty('message') === true){
            let text = json1.message.uk_UA
            //let tmp = JSON.stringify({title: 'Відміна ставки', message: JSON.stringify(json1)})
            let tmp = JSON.stringify({title: 'Відміна ставки', message: text})
	        props.setUniversalError(tmp)
        }else{
            //Тут надо подключать функцию с сокетом
            //props.togetherApiActiveAuctions(json1)
        }
    }
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        auctions: state.start.auctionsGet,
        auctionsOld: state.start.auctionsGetOld,
        search: state.start.search,
        myBids: state.start.myBids,
        token: state.start.token,
        togetherApiPage: state.start.togetherApiPage,
    }
}

const mapDispatchToProps = {
    setPopUpBidTogetherAPI,
    setUniversalError,
    togetherApiPullAucEI,
    togetherApiSaveDataLoad,
    togetherApiSaveValue,
    
    togetherApiTimerRound,
    togetherApiTimerTime,
    togetherApiTimerTimeLeft,

    changeLoader,
    auctionBidEndPay,
}

export default connect(mapStateToProps, mapDispatchToProps)(TogetherAPI)