import React from 'react';
import styles from './imageAndButtonLine_Text.module.css';

import {connect} from 'react-redux';

import ImageAndButtonLineIdAuction from './imageAndButtonLine_IdAuction/imageAndButtonLine_IdAuction.js';
import ImageAndButtonLineNumberAuction from './imageAndButtonLine_NumberAuction/imageAndButtonLine_NumberAuction.js';
import ImageAndButtonLineTypeAuction from './imageAndButtonLine_TypeAuction/imageAndButtonLine_TypeAuction.js';

const ImageAndButtonLine_Text = ( props ) => {
    return (
        <div className={styles.threeBlock_data}>
            <ImageAndButtonLineIdAuction item={props.item} />
            <ImageAndButtonLineNumberAuction item={props.item} />
            <ImageAndButtonLineTypeAuction item={props.item} />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
    }
}

export default connect(mapStateToProps, null)(ImageAndButtonLine_Text)