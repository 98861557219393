import React from 'react';
import styles from './myBidsHeader.module.css';

import {connect} from 'react-redux';

import {setDataAddress} from '../../../../redux/createAddress'

import {
    changeAuctionsSwitchWindow,
    auctionsGetMy,
} from '../../../../redux/actions/auctions/auctions';

import {
    getMyAuction,
    changeCreateAuctionTenderAttempts,
    changeMyCabinetCurentPage,
} from '../../../../redux/actions';

import {setBankAccountSave, setBankAccountAdd, setBankAccountDelete} from '../../../../redux/actions/bankAccount/bankAccount';

const MyBidsHeader = ( props ) => {
    return (
        <div className={styles.wrap_listAuctions}>
            <div className={styles.wrap_listAuctions_topLine}>
                <div className={styles.listAuctions_title}>Мої пропозиції</div>
            </div>
        </div>
    )
}

function handlerCount(props, limit, status){
    let tmp = 0 + 1
    let data = {}
    data.domen = process.env.REACT_APP_END_POINT_BD
    data.version = 'v1'
    data.local = props.langFlag
    data.params = `?limit=${limit}&offset=${tmp}`
    if(status !== 'all'){
        data.body = {status: status}
    }
    let header = {
        'Authorization': props.token.access_token,
        'Content-Type': 'application/json'
    }
    props.setDataAddress(null, 'AUCTIONS_GET_MY')
    props.changeMyCabinetCurentPage(0)
    props.auctionsGetMy(data, header)
}

async function getAddressOrg(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/organizations?limit=30&offset=0`
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            //props.setEditAuctionFromId(json)
            props.setDataAddress(json, 'GET_ADDRESS_NEW_BOOKS')
        }
    }
}

const mapStateToProps = state => {
    return {
        auctionsMyNew: state.start.auctionsMyNew,
        token: state.start.token,
        langFlag: state.start.langFlag,
        addressNew: state.start.addressNew,
        myCabinetPageCounter: state.start.myCabinetPageCounter,
        userProfiles: state.start.userProfiles,
    }
}

const mapDispatchToProps = {
    changeAuctionsSwitchWindow,
    getMyAuction,
    changeCreateAuctionTenderAttempts,

    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,

    setDataAddress,

    auctionsGetMy,
    changeMyCabinetCurentPage,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyBidsHeader)
